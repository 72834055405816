import { LoadState } from "../../../constants/enums";

export interface IAccountingAccountsPayableState {
  list: IAccountsPayable[];
  listLoading: LoadState;
  data: IAccountsPayable;
  loading: LoadState;
  error: string | null;
}

export interface IAccountsPayable {
  accounts_payable_type: string | null;
  vendor_id: string | null;
  customer_id: string | null;
  customer_policy_id: string | null;
  status: string | null;
}

export const initialAccountingAccountsPayableState: IAccountingAccountsPayableState =
  {
    list: [],
    listLoading: LoadState.NotLoaded,
    data: {
      accounts_payable_type: null,
      vendor_id: null,
      customer_id: null,
      customer_policy_id: null,
      status: "Active",
    },
    loading: LoadState.NotLoaded,
    error: null,
  };
