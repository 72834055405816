import React from "react";
import { Dialog } from "../../../../../../../components/Dialogs/Dialog";
import { Grid } from "@mui/material";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useFormik } from "formik";
import {
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { CustomDatePicker } from "../../../../../../../components/formsComponents/CustomDatePicker";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import moment from "moment";
import { SaveState } from "../../../../../../../constants/enums";
import { StandadCard } from "../../../../../../../components/Cards";
import { StandardCellList } from "../../../../../../../components/Standards";
import { formatDate } from "../../../../../../../helpers";
import { IViewLifeInsuranceClaimDialogProps } from "./ViewClaimDialog.types";

export const ViewLifeInsurerClaimDialog: React.FC<
  IViewLifeInsuranceClaimDialogProps
> = (props) => {
  const { open, claim, onClose } = props;

  return (
    <Dialog
      open={open}
      title="Claim Information"
      onClose={onClose}
      size="lg"
      actions={[
        { type: "button", label: "Close", variant: "text", onClick: onClose },
      ]}
    >
      <StandadCard heading="Claim Data">
        <StandardCellList
          colSize={3}
          list={[
            {
              heading: "Customer Policy Id",
              data: { type: "text", value: claim.customer_policy_id },
              colSize: 3,
            },
            {
              heading: "Claim Number",
              data: { type: "text", value: claim.claim_number },
              colSize: 3,
            },
            {
              heading: "Branch",
              data: { type: "text", value: claim.branch },
            },
            {
              heading: "Producer",
              data: { type: "text", value: claim.producer },
            },
            {
              heading: "Amount Paid ($)",
              data: { type: "text", value: claim.amount_paid },
              colSize: 6,
            },
            {
              heading: "Date of Loss",
              data: {
                type: "text",
                value: claim.date_of_loss
                  ? formatDate(claim.date_of_loss)
                  : "--",
              },
            },
            {
              heading: "Type",
              data: { type: "text", value: claim.type },
            },
            {
              heading: "Loss Details",
              data: { type: "text", value: claim.loss_details },
            },
            {
              heading: "Status",
              data: { type: "text", value: claim.status },
            },
          ]}
        />
      </StandadCard>
    </Dialog>
  );
};
