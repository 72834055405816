import { LoadState } from "../../../constants/enums";

export interface IBusinessPolicyRestrutantState {
  data: IBusinessPolicyRestrutant[];
  loading: LoadState;
  restrutant: IBusinessPolicyRestrutant;
  restrutantLoading: LoadState;
  error: string | null;
}

export interface IBusinessPolicyRestrutant {
  customer_policy_id: number;
  incremental_number: number| null;
  hotel_motel: string | null;
  night_club: string | null;
  restaurant: string | null;
  restaurant_with_liquor_license: string | null;
  beer_liquor_store: string | null;
  private_club_regions: string | null;
  longue: string | null;
  pub_sports_bar: string | null;
  seasonal_operation: string | null;
  total_seating_capacity: string | null;
  licennsed_seating_capacity: string | null;
  internal: string | null;
  patio: string | null;
  hours_of_operation: string | null;
  days_of_operation: string | null;
  gross_annual_food_sales: string | null;
  projected_food_sales: string | null;
  liquor_sales: string | null;
  projected_liquor_sales: string | null;
  grill: string | null;
  deep_fat_frying: string | null;
  kitchen_equipped_with_an_automatic_fire_extinguishing_system: string | null;
  sewer_backup_incident_at_your_location: string | null;
  semi_annual_maintence_agreement: string | null;
  year_in_business: string | null;
  resturant_location_ownership: string | null;
  year_of_current_ownership: string | null;
  year_at_this_location: string | null;
  prior_operating_experience: string | null;
  family_run_business: string | null;
  franchisor: string | null;
  video_lottery_terminals: string | null;
  valet_parking: string | null;
  live_band: string | null;
  adult_entertainment: string | null;
  beer_sold_in_jugs: string | null;
  pool_tables: string | null;
  disc_jockey: string | null;
  dance_floor: string | null;
  dance_floor_sq_ft: string | null;
  description: string | null;
  restaurant_submission_checkbox: string | null;
  status: "ACTIVE" | "PENDING";
}

export const initialBusinessPolicyRestrutant: IBusinessPolicyRestrutant = {
  customer_policy_id: 0,
  incremental_number: null,
  hotel_motel: null,
  night_club: null,
  restaurant: null,
  restaurant_with_liquor_license: null,
  beer_liquor_store: null,
  private_club_regions: null,
  longue: null,
  pub_sports_bar: null,
  seasonal_operation: null,
  total_seating_capacity: null,
  licennsed_seating_capacity: null,
  internal: null,
  patio: null,
  hours_of_operation: null,
  days_of_operation: null,
  gross_annual_food_sales: null,
  projected_food_sales: null,
  liquor_sales: null,
  projected_liquor_sales: null,
  grill: null,
  deep_fat_frying: null,
  kitchen_equipped_with_an_automatic_fire_extinguishing_system: null,
  sewer_backup_incident_at_your_location: null,
  semi_annual_maintence_agreement: null,
  year_in_business: null,
  resturant_location_ownership: null,
  year_of_current_ownership: null,
  year_at_this_location: null,
  prior_operating_experience: null,
  family_run_business: null,
  franchisor: null,
  video_lottery_terminals: null,
  valet_parking: null,
  live_band: null,
  adult_entertainment: null,
  beer_sold_in_jugs: null,
  pool_tables: null,
  disc_jockey: null,
  dance_floor: null,
  dance_floor_sq_ft: null,
  description: null,
  restaurant_submission_checkbox: null,
  status: "ACTIVE",
};

export const initialBusinessPolicyRestrutantState: IBusinessPolicyRestrutantState =
  {
    data: [],
    loading: LoadState.NotLoaded,
    restrutant: initialBusinessPolicyRestrutant,
    restrutantLoading: LoadState.NotLoaded,
    error: null,
  };
