import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialCustomerState } from "./customerState";
import {
  ADD_CUSTOMER_CONSENT,
  ADD_CUSTOMER_FULL_DETAILS,
  ADD_CUSTOMER_PROSPECT,
  ADD_CUSTOMER_REL_ACCOUNT,
  ADD_CUSTOMER_SOCIAL,
  ADD_OPPPRTUNITY,
  ADD_PERSONAL_INFORMATION,
  CLEAR_CUSTOMER_STATE,
  FETCH_ACTIVE_USER_POLICY_LIST_FAILED,
  FETCH_ACTIVE_USER_POLICY_LIST_PROGRESS,
  FETCH_ACTIVE_USER_POLICY_LIST_SUCCESS,
  FETCH_CUSTOMER_CONSENT_LIST_FAILED,
  FETCH_CUSTOMER_CONSENT_LIST_PROGRESS,
  FETCH_CUSTOMER_CONSENT_LIST_SUCCESS,
  FETCH_CUSTOMER_CONSENT_PROGRESS,
  FETCH_CUSTOMER_CONTACTS_LIST_FAILED,
  FETCH_CUSTOMER_CONTACTS_LIST_PROGRESS,
  FETCH_CUSTOMER_CONTACTS_LIST_SUCCESS,
  FETCH_CUSTOMER_FULL_DETAILS_PROGRESS,
  FETCH_CUSTOMER_GROUP_LIST_FAILED,
  FETCH_CUSTOMER_GROUP_LIST_PROGRESS,
  FETCH_CUSTOMER_GROUP_LIST_SUCCESS,
  FETCH_CUSTOMER_PROSPECT_PROGRESS,
  FETCH_CUSTOMER_REL_ACCOUNT_PROGRESS,
  FETCH_CUSTOMER_SOCIAL_PROGRESS,
  FETCH_OPPPRTUNITY_PROGRESS,
  FETCH_PERSONAL_INFO_PROGRESS,
  RECORD_NOT_FOUND,
} from "./customerActions";
import { CustomerActions } from ".";

export const customerReducer = (
  state: IStoreState["customer"] = initialCustomerState,
  action: CustomerActions
) => {
  switch (action.type) {
    case ADD_PERSONAL_INFORMATION: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.personalInformation.loading = LoadState.Loaded;
        draftState.personalInformation.data = data;
      });
      return newState;
    }

    case FETCH_PERSONAL_INFO_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.personalInformation.loading = LoadState.InProgress;
      });
      return newState;
    }

    case ADD_CUSTOMER_PROSPECT: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.customerProspect.loading = LoadState.Loaded;
        draftState.customerProspect.data = data;
        if (!data.tax_zone) {
          draftState.customerProspect.data.tax_zone = "ON";
        }
      });
      return newState;
    }

    case FETCH_CUSTOMER_PROSPECT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.customerProspect.loading = LoadState.InProgress;
      });
      return newState;
    }

    case ADD_CUSTOMER_SOCIAL: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.customerSocial.loading = LoadState.Loaded;
        draftState.customerSocial.data = data;
      });
      return newState;
    }

    case FETCH_CUSTOMER_SOCIAL_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.customerSocial.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_CUSTOMER_REL_ACCOUNT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.customerRelatedAccount.loading = LoadState.InProgress;
      });
      return newState;
    }
    case ADD_CUSTOMER_REL_ACCOUNT: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.customerRelatedAccount.loading = LoadState.Loaded;
        draftState.customerRelatedAccount.data = data;
      });
      return newState;
    }

    case ADD_CUSTOMER_CONSENT: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.customerConsent.loading = LoadState.Loaded;
        draftState.customerConsent.data = data;
      });
      return newState;
    }

    case FETCH_CUSTOMER_CONSENT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.customerConsent.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_OPPPRTUNITY_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.opportunity.loading = LoadState.InProgress;
      });
      return newState;
    }

    case ADD_OPPPRTUNITY: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opportunity.loading = LoadState.Loaded;
        draftState.opportunity.data = data;
        draftState.opportunity.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_CUSTOMER_CONSENT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.customerConsentList.data = [];
        draftState.customerConsentList.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CUSTOMER_CONSENT_LIST_SUCCESS: {
      const newState = produce(state, (draftState) => {
        draftState.customerConsentList.data = action.payload.data;
        draftState.customerConsentList.loading = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_CUSTOMER_CONSENT_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.customerConsentList.data = [];
        draftState.customerConsentList.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_CUSTOMER_GROUP_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.customerGroupsList.data = [];
        draftState.customerGroupsList.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CUSTOMER_GROUP_LIST_SUCCESS: {
      const newState = produce(state, (draftState) => {
        draftState.customerGroupsList.data = action.payload.data;
        draftState.customerGroupsList.loading = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_CUSTOMER_GROUP_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.customerGroupsList.data = [];
        draftState.customerGroupsList.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_CUSTOMER_CONTACTS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.customerContacts.data = [];
        draftState.customerContacts.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_CUSTOMER_CONTACTS_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.customerContacts.data = data;
        draftState.customerContacts.loading = LoadState.Loaded;
      });
      return newState;
    }

    case FETCH_CUSTOMER_CONTACTS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.customerContacts.data = [];
        draftState.customerContacts.loading = LoadState.Failed;
      });
      return newState;
    }


    case FETCH_ACTIVE_USER_POLICY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.policies.loading = LoadState.InProgress;
        draftState.policies.data = [];
      });
      return newState;
    }
    
    case FETCH_ACTIVE_USER_POLICY_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.policies.loading = LoadState.Loaded;
        draftState.policies.data = data;
      });
      return newState;
    }

    case FETCH_ACTIVE_USER_POLICY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.policies.loading = LoadState.Failed;
        draftState.policies.data = [];
      });
      return newState;
    }

    case CLEAR_CUSTOMER_STATE: {
      return initialCustomerState;
    }

    case RECORD_NOT_FOUND: {
      const newState = produce(state, (draftState) => {
        draftState.error =
          "Oops! We couldn't find any records associated with your customer id at the moment. Please ensure that the provided information is accurate and try again.";
      });
      return newState;
    }

    default: {
      return state;
    }
  }
};
