import { LoadState } from "../../../constants/enums";

export interface IAccountingInteracEmailTransferState {
  list: IInteracEmailTransfer[];
  listLoading: LoadState;
  data: IInteracEmailTransfer;
  loading: LoadState;
  error: string | null;
  listError: string | null;
}

export interface IInteracEmailTransfer {
  interac_email_transfer: {
    payment_method: string | null;
    interac_email: string | null;
    interac_sender_name: string | null;
    amount: string | null;
    reference: string | null;
    created_by: string | null;
  };
  status: string;
}

export const initialAccountingInteracEmailTransferState: IAccountingInteracEmailTransferState =
  {
    list: [],
    listLoading: LoadState.NotLoaded,
    data: {
      interac_email_transfer: {
        payment_method: null,
        interac_email: null,
        interac_sender_name: null,
        amount: null,
        reference: null,
        created_by: null,
      },
      status: "Active",
    },
    loading: LoadState.NotLoaded,
    error: null,
    listError: null,
  };
