/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../../constants/enums";
import { StandadCard } from "../../../../../../components/Cards";
import {
  Checkbox,
  Divider,
  Grid,
  SelectChangeEvent,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatchWrapper } from "../../../../../../hooks";
import { useFormik } from "formik";
import { TabPanel } from "../../../../../../components/Tabs/TabPanel";

import { TabsList } from "../../../../../../components/Tabs/TabsList";
import {
  AccountCircle,
  AdminPanelSettings,
  Receipt,
  StickyNote2,
} from "@mui/icons-material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../components/formsComponents";
import {
  ILocationResponsePayload,
  LocationAutoComplete,
} from "../../../../../../components/LocationAutoComplete/LocationAutoComplete";
import {
  CustomDatePicker,
  CustomTimePicker,
} from "../../../../../../components/formsComponents/CustomDatePicker";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import produce from "immer";
import moment from "moment";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { HabitationalLiabilityExtensionExclusion } from "../../Habitational/HabitationalLiabilityExtension/HabitationalLiabilityExtension";
import { LabelCard } from "../../../../../../components/LabelCard/LabelCard";
import { IAutomobileAccidentsSuspension, IAutomobileConviction, IAutomobileOperatorOne, IAutomobileOperatorTwo, IAutomobilePolicyReqModelInfo, IAutomobilePolicyReqVehicleInfo } from "../../../../../../redux/automobileCSIOPolicyChangeReq/automobileCSIOPolicyChangeReq.types";
import { clearAutomobilePolicyReqState } from "../../../../../../redux/automobileCSIOPolicyChangeReq/automobileCSIOPolicyChangeReqActions";
export const AutomobilePolicyChangeReqForm: React.FC = () => {
  const { customerId, customerPolicyId, messageGUID } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
    messageGUID?: string;
  };

  const { policyChangeData, loading } = useSelector(
    (storeState: IStoreState) => storeState.automobile.csioPolicyChangeReq
  );

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [tab, setTab] = React.useState(0);

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: policyChangeData,
    validate: (values) => {},
    onSubmit: (values) => {},
  });
  const handleStepChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const onTabChange = (value: number) => {
    window.scrollTo(0, 400);
    setTab(value);
  };

  // React.useEffect(() => {
  //   if (messageGUID) {
  //     dispatch(fetchAutomobileCSIOApplicationPolicyReqAsync(messageGUID));
  //   }
  // }, [messageGUID]);

  React.useEffect(() => {
    setValues(policyChangeData);
  }, [policyChangeData]);

  const handleBrokerAddress = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      broker: {
        ...values.broker,
        broker_agent_address: data.address,
        broker_agent_city: data.city,
        broker_agent_country: data.country,
        broker_agent_state: data.state,
        broker_agent_zip: data.postalCode,
      },
    });
  };
  const handleAddress = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      applicant_details: {
        ...values.applicant_details,
        address: data.address,
        city: data.city,
        country: data.country,
        province_or_state: data.state,
        postalcode: data.postalCode,
      },
    });
  };
  const handleAdditionalInterestAddress = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      additional_interest_details: {
        ...values.additional_interest_details,
        address: data.address,
        city: data.city,
        country: data.country,
        province_or_state: data.state,
        postal_code: data.postalCode,
      },
    });
  };

  const updateVehicleModelInfo = (
    key: string,
    rowIndex: number,
    value: any
  ) => {
    const newValues = produce(values.vehicle_model_info, (draftValues) => {
      //@ts-ignore
      draftValues[rowIndex][key] = value;
    });
    setFieldValue("vehicle_model_info", newValues);
  };

  const handleChangeVehicleModelInfo =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateVehicleModelInfo(key, rowIndex, e.target.value);
    };

  const handleSelectVehicleModelInfo =
    (key: string, rowIndex: number) => (e: SelectChangeEvent<unknown>) => {
      updateVehicleModelInfo(key, rowIndex, e.target.value);
    };

  const describedVehicleModelInfo: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 5,
    uniqueRowKeyName: "key",
    columns: [
      {
        key: "model_cc",
        headerName: "Model or C.C.",
        fieldName: "model_cc",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobilePolicyReqModelInfo) => {
          return (
            <CustomTextField
              fullWidth
              value={row.model_cc}
              onChange={handleChangeVehicleModelInfo(
                "model_cc",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "no_of_cyls",
        headerName: "No. of CYLS.",
        fieldName: "no_of_cyls",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobilePolicyReqModelInfo) => {
          return (
            <CustomTextField
              fullWidth
              value={row.no_of_cyls}
              onChange={handleChangeVehicleModelInfo(
                "no_of_cyls",
                value.column.rowIndex
              )}
            />
          );
        },
      },

      {
        key: "truck_gross_weight",
        headerName: "Truck Gross Vehicle Weight",
        fieldName: "truck_gross_weight",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobilePolicyReqModelInfo) => {
          return (
            <CustomTextField
              fullWidth
              value={row.truck_gross_weight}
              onChange={handleChangeVehicleModelInfo(
                "truck_gross_weight",
                value.column.rowIndex
              )}
            />
          );
        },
      },

      {
        key: "purchase_price",
        headerName: "Purchase Price Include Equipment",
        fieldName: "purchase_price",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobilePolicyReqModelInfo) => {
          return (
            <CustomTextField
              fullWidth
              value={row.purchase_price}
              onChange={handleChangeVehicleModelInfo(
                "purchase_price",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "bodyType",
        headerName: "Purchased By Insured",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobilePolicyReqModelInfo) => {
          return (
            <Stack direction={"row"} spacing={2}>
              <CustomDatePicker
                fullWidth
                value={
                  row.purchased_by_insured_date
                    ? moment(row.purchased_by_insured_date).format("YYYY-MM")
                    : ""
                }
                onChange={(event) => {
                  const newValue = moment(event.target.value).format("YYYY-MM");
                  updateVehicleModelInfo(
                    "purchased_by_insured_date",
                    value.column.rowIndex,
                    newValue
                  );
                }}
              />
              <ControlledCustomSelect
                fullWidth
                value={row.purchased_by_insured}
                name="template"
                onChange={handleSelectVehicleModelInfo(
                  "purchased_by_insured",
                  value.column.rowIndex
                )}
                placeholder="Type"
                displayEmpty
                options={["NEW", "USED", "DEMO"].map((template) => {
                  return { label: template, value: template };
                })}
              ></ControlledCustomSelect>
            </Stack>
          );
        },
      },
    ],
    items: values.vehicle_model_info,
  };

  const updateVehicleInformation = (
    key: string,
    rowIndex: number,
    value: any
  ) => {
    const newValues = produce(values.vehicle_information, (draftValues) => {
      //@ts-ignore
      draftValues[rowIndex][key] = value;
    });
    setFieldValue("vehicle_information", newValues);
  };

  const handleChangeVehicleInformation =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateVehicleInformation(key, rowIndex, e.target.value);
    };

  const describedVehicleInformation: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 5,
    uniqueRowKeyName: "key",
    columns: [
      {
        key: "vehNo",
        headerName: "Veh No.",
        fieldName: "vehNo",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobilePolicyReqVehicleInfo) => {
          return (
            <CustomTextField
              fullWidth
              value={row.vehNo}
              onChange={handleChangeVehicleInformation(
                "vehNo",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "year",
        headerName: "Year",
        fieldName: "year",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobilePolicyReqVehicleInfo) => {
          return (
            <CustomTextField
              fullWidth
              value={row.year}
              onChange={handleChangeVehicleInformation(
                "year",
                value.column.rowIndex
              )}
            />
          );
        },
      },

      {
        key: "trade_name",
        headerName: "Trade Name / Make",
        fieldName: "trade_name",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobilePolicyReqVehicleInfo) => {
          return (
            <CustomTextField
              fullWidth
              value={row.trade_name}
              onChange={handleChangeVehicleInformation(
                "trade_name",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "body_type",
        headerName: "Body Type",
        fieldName: "body_type",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobilePolicyReqVehicleInfo) => {
          return (
            <CustomTextField
              fullWidth
              value={row.body_type}
              onChange={handleChangeVehicleInformation(
                "body_type",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "vin_serial_number",
        headerName: "V.I.N. (Serial Number)",
        fieldName: "vin_serial_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobilePolicyReqVehicleInfo) => {
          return (
            <CustomTextField
              fullWidth
              value={row.vin_serial_number}
              onChange={handleChangeVehicleInformation(
                "vin_serial_number",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.vehicle_information,
  };

  const updateOperatorOne = (key: string, rowIndex: number, value: any) => {
    const newValues = produce(values.described_operators_one, (draftValues) => {
      //@ts-ignore
      draftValues[rowIndex][key] = value;
    });
    setFieldValue("described_operators_one", newValues);
  };

  const handleOperatorOne =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateOperatorOne(key, rowIndex, e.target.value);
    };

  const handleSelectOperatorOne =
    (key: string, rowIndex: number) => (e: SelectChangeEvent<unknown>) => {
      updateOperatorOne(key, rowIndex, e.target.value);
    };

  const describeOperatorOne: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 100,
    uniqueRowKeyName: "key",
    columns: [
      {
        key: "name_of_driver",
        headerName: "Name (As Shown on Driver's Licence) ",
        fieldName: "name_of_driver",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileOperatorOne) => {
          return (
            <CustomTextField
              fullWidth
              value={row.name_of_driver}
              onChange={handleOperatorOne(
                "name_of_driver",
                value.column.rowIndex
              )}
            />
          );
        },
      },

      {
        key: "driver_licence_number",
        headerName: "Driver License Number",
        fieldName: "driver_licence_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileOperatorOne) => {
          return (
            <CustomTextField
              fullWidth
              value={row.driver_licence_number}
              onChange={handleOperatorOne(
                "driver_licence_number",
                value.column.rowIndex
              )}
            />
          );
        },
      },

      {
        key: "sex",
        headerName: "Sex",
        fieldName: "sex",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileOperatorOne) => {
          return (
            <CustomTextField
              fullWidth
              value={row.sex}
              onChange={handleOperatorOne("sex", value.column.rowIndex)}
            />
          );
        },
      },
      {
        key: "marital_status",
        headerName: "Martial Status",
        fieldName: "marital_status",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileOperatorOne) => {
          return (
            <CustomTextField
              fullWidth
              value={row.marital_status}
              onChange={handleOperatorOne(
                "marital_status",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.described_operators_one,
  };
  const updateOperatorTwo = (key: string, rowIndex: number, value: any) => {
    const newValues = produce(values.described_operators_two, (draftValues) => {
      //@ts-ignore
      draftValues[rowIndex][key] = value;
    });
    setFieldValue("described_operators_two", newValues);
  };

  const handleOperatorTwo =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateOperatorTwo(key, rowIndex, e.target.value);
    };

  const handleSelectOperatorTwo =
    (key: string, rowIndex: number) => (e: SelectChangeEvent<unknown>) => {
      updateOperatorTwo(key, rowIndex, e.target.value);
    };

  const describeOperatorTwo: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 100,
    uniqueRowKeyName: "key",
    columns: [
      {
        key: "assigned_vin_number",
        headerName: "Assigned Vehicle V.I.N (Serial Number) ",
        fieldName: "assigned_vin_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileOperatorTwo) => {
          return (
            <CustomTextField
              fullWidth
              value={row.assigned_vin_number}
              onChange={handleOperatorTwo(
                "assigned_vin_number",
                value.column.rowIndex
              )}
            />
          );
        },
      },

      {
        key: "use",
        headerName: "Use % ",
        fieldName: "use",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileOperatorTwo) => {
          return (
            <CustomTextField
              fullWidth
              value={row.use}
              onChange={handleOperatorTwo("use", value.column.rowIndex)}
            />
          );
        },
      },

      {
        key: "date_of_birth",
        headerName: "Date of Birth",
        fieldName: "date_of_birth",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileOperatorTwo) => {
          return (
            <CustomDatePicker
              fullWidth
              value={row.date_of_birth}
              onChange={(newValue) => {
                updateOperatorTwo(
                  "date_of_birth",
                  value.column.rowIndex,
                  newValue
                );
              }}
            />
          );
        },
      },
      {
        key: "license_class_one",
        headerName: "Licence Class",
        fieldName: "marital_status",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileOperatorTwo) => {
          return (
            <Stack direction={"row"} spacing={1}>
              <CustomTextField
                fullWidth
                value={row.license_class_one}
                onChange={handleOperatorTwo(
                  "license_class_one",
                  value.column.rowIndex
                )}
              />
              <CustomTextField
                fullWidth
                value={row.license_class_two}
                onChange={handleOperatorTwo(
                  "license_class_two",
                  value.column.rowIndex
                )}
              />
              <CustomTextField
                fullWidth
                value={row.license_class_three}
                onChange={handleOperatorTwo(
                  "license_class_three",
                  value.column.rowIndex
                )}
              />
            </Stack>
          );
        },
      },
      {
        key: "date_licensed_one",
        headerName: "Date Licensed",
        fieldName: "date_licensed_one",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileOperatorTwo) => {
          return (
            <CustomDatePicker
              fullWidth
              value={row.date_licensed_one}
              onChange={(newValue) => {
                updateOperatorTwo(
                  "date_licensed_one",
                  value.column.rowIndex,
                  newValue
                );
              }}
            />
          );
        },
      },
      {
        key: "date_licensed_two",
        headerName: "Date Licensed",
        fieldName: "date_licensed_two",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileOperatorTwo) => {
          return (
            <CustomDatePicker
              fullWidth
              value={row.date_licensed_two}
              onChange={(newValue) => {
                updateOperatorTwo(
                  "date_licensed_two",
                  value.column.rowIndex,
                  newValue
                );
              }}
            />
          );
        },
      },
      {
        key: "date_licensed_three",
        headerName: "Date Licensed",
        fieldName: "date_licensed_three",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileOperatorTwo) => {
          return (
            <CustomDatePicker
              fullWidth
              value={row.date_licensed_three}
              onChange={(newValue) => {
                updateOperatorTwo(
                  "date_licensed_three",
                  value.column.rowIndex,
                  newValue
                );
              }}
            />
          );
        },
      },
    ],
    items: values.described_operators_two,
  };
  const updateConvictions = (key: string, rowIndex: number, value: any) => {
    const newValues = produce(
      values.convictions_during_the_past_years,
      (draftValues) => {
        //@ts-ignore
        draftValues[rowIndex][key] = value;
      }
    );
    setFieldValue("convictions_during_the_past_years", newValues);
  };

  const handleConvictions =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateConvictions(key, rowIndex, e.target.value);
    };

  const handleSelectConvictions =
    (key: string, rowIndex: number) => (e: SelectChangeEvent<unknown>) => {
      updateConvictions(key, rowIndex, e.target.value);
    };

  const describeConvictions: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 100,
    uniqueRowKeyName: "key",
    columns: [
      {
        key: "driver_no",
        headerName: "Driver No.",
        fieldName: "driver_no",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileConviction) => {
          return (
            <CustomTextField
              fullWidth
              value={row.driver_no}
              onChange={handleConvictions("driver_no", value.column.rowIndex)}
            />
          );
        },
      },
      {
        key: "date1",
        headerName: "Date",
        fieldName: "date1",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileConviction) => {
          return (
            <CustomDatePicker
              fullWidth
              value={row.date1}
              onChange={(newValue) => {
                updateConvictions("date1", value.column.rowIndex, newValue);
              }}
            />
          );
        },
      },
      {
        key: "description1",
        headerName: "Description",
        fieldName: "description1",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileConviction) => {
          return (
            <CustomTextField
              fullWidth
              value={row.description1}
              onChange={handleConvictions(
                "description1",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "date2",
        headerName: "Date",
        fieldName: "date2",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileConviction) => {
          return (
            <CustomDatePicker
              fullWidth
              value={row.date2}
              onChange={(newValue) => {
                updateConvictions("date2", value.column.rowIndex, newValue);
              }}
            />
          );
        },
      },
      {
        key: "description2",
        headerName: "Description",
        fieldName: "description2",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileConviction) => {
          return (
            <CustomTextField
              fullWidth
              value={row.description2}
              onChange={handleConvictions(
                "description2",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "date3",
        headerName: "Date",
        fieldName: "date3",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileConviction) => {
          return (
            <CustomDatePicker
              fullWidth
              value={row.date3}
              onChange={(newValue) => {
                updateConvictions("date3", value.column.rowIndex, newValue);
              }}
            />
          );
        },
      },
      {
        key: "description3",
        headerName: "Description",
        fieldName: "description3",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileConviction) => {
          return (
            <CustomTextField
              fullWidth
              value={row.description3}
              onChange={handleConvictions(
                "description3",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.convictions_during_the_past_years,
  };
  const updateAccidents = (key: string, rowIndex: number, value: any) => {
    const newValues = produce(
      values.convictions_during_the_past_years,
      (draftValues) => {
        //@ts-ignore
        draftValues[rowIndex][key] = value;
      }
    );
    setFieldValue("convictions_during_the_past_years", newValues);
  };

  const handleAccidents =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateAccidents(key, rowIndex, e.target.value);
    };

  const handleSelectAccidents =
    (key: string, rowIndex: number) => (e: SelectChangeEvent<unknown>) => {
      updateAccidents(key, rowIndex, e.target.value);
    };

  const describeAccidents: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 100,
    uniqueRowKeyName: "key",
    columns: [
      {
        key: "driver_no",
        headerName: "Driver No.",
        fieldName: "driver_no",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileAccidentsSuspension) => {
          return (
            <CustomTextField
              fullWidth
              value={row.driver_no}
              onChange={handleConvictions("driver_no", value.column.rowIndex)}
            />
          );
        },
      },
      {
        key: "date1",
        headerName: "Date",
        fieldName: "date1",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileAccidentsSuspension) => {
          return (
            <CustomDatePicker
              fullWidth
              value={row.date1}
              onChange={(newValue) => {
                updateConvictions("date1", value.column.rowIndex, newValue);
              }}
            />
          );
        },
      },
      {
        key: "description1",
        headerName: "Description",
        fieldName: "description1",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileAccidentsSuspension) => {
          return (
            <CustomTextField
              fullWidth
              value={row.description1}
              onChange={handleConvictions(
                "description1",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "date2",
        headerName: "Date",
        fieldName: "date2",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileAccidentsSuspension) => {
          return (
            <CustomDatePicker
              fullWidth
              value={row.date2}
              onChange={(newValue) => {
                updateConvictions("date2", value.column.rowIndex, newValue);
              }}
            />
          );
        },
      },
      {
        key: "description2",
        headerName: "Description",
        fieldName: "description2",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileAccidentsSuspension) => {
          return (
            <CustomTextField
              fullWidth
              value={row.description2}
              onChange={handleConvictions(
                "description2",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "date3",
        headerName: "Date",
        fieldName: "date3",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileAccidentsSuspension) => {
          return (
            <CustomDatePicker
              fullWidth
              value={row.date3}
              onChange={(newValue) => {
                updateConvictions("date3", value.column.rowIndex, newValue);
              }}
            />
          );
        },
      },
      {
        key: "description3",
        headerName: "Description",
        fieldName: "description3",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileAccidentsSuspension) => {
          return (
            <CustomTextField
              fullWidth
              value={row.description3}
              onChange={handleConvictions(
                "description3",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.accidents_suspension_in_the_past_years,
  };

  React.useEffect(() => {
    return () => {
      dispatch(clearAutomobilePolicyReqState());
    };
  }, []);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      <StandadCard sx={{ mt: 2 }} heading="Automobile Policy Change Req Form">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Insurance Company</CustomFormLabel>
              <CustomTextField
                name="insurance_company"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.insurance_company}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Policy/Binder Number</CustomFormLabel>
              <CustomTextField
                name="policy_number"
                variant="outlined"
                size="small"
                type="text"
                value={values.policy_number}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Policy type</CustomFormLabel>
              <CustomTextField
                name="policy_type"
                variant="outlined"
                size="small"
                type="text"
                value={values.policy_type}
                fullWidth
                onChange={handleChange}
              />
            </Grid>

            {/* <Grid item xs={12} lg={2}>
          <CustomFormLabel>Language Preferred</CustomFormLabel>
          <Stack direction={"row"} alignItems={"center"}>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography>English</Typography>
              <Checkbox
                checked={values.language_preferred === "EN"}
                onChange={(e) => {
                  setFieldValue("language_preferred", "English");
                }}
              />
            </Stack>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography>French</Typography>
              <Checkbox
                checked={values.language_preferred === "FN"}
                onChange={(e) => {
                  setFieldValue("language_preferred", "French");
                }}
              />
            </Stack>
          </Stack>
        </Grid> */}
          </Grid>

          <LabelCard heading="Insured Details"/>

          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Customer Name</CustomFormLabel>
              <CustomTextField
                name="applicant_details.name"
                value={values.applicant_details.name}
                fullWidth
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={5}>
              <CustomFormLabel>Address</CustomFormLabel>
              <LocationAutoComplete
                id="applicant_details.address"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.address}
                onLocationChange={handleAddress}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Address 2</CustomFormLabel>
              <CustomTextField
                name="applicant_details.address_two"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.address_two}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Country</CustomFormLabel>
              <CustomTextField
                name="applicant_details.country"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.country}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>City</CustomFormLabel>
              <CustomTextField
                name="applicant_details.city"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.city}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>State</CustomFormLabel>
              <CustomTextField
                name="applicant_details.province_or_state"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.province_or_state}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>Postal Code</CustomFormLabel>
              <CustomTextField
                name="applicant_details.postalcode"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.postalcode}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Contact Number</CustomFormLabel>
              <CustomTextField
                name="applicant_details.contact_number"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.contact_number}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Home</CustomFormLabel>
              <CustomTextField
                name="applicant_details.home"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.home}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>Cell</CustomFormLabel>
              <CustomTextField
                name="applicant_details.cell"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.cell}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Business</CustomFormLabel>
              <CustomTextField
                name="applicant_details.business"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.business}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Fax</CustomFormLabel>
              <CustomTextField
                name="applicant_details.fax"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.fax}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Email</CustomFormLabel>
              <CustomTextField
                name="applicant_details.email"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Website</CustomFormLabel>
              <CustomTextField
                name="applicant_details.website"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.website}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <LabelCard heading="Broker"/>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Broker Agent</CustomFormLabel>
              <CustomTextField
                name="broker.broker_agent"
                value={values.broker.broker_agent}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Address</CustomFormLabel>

              <LocationAutoComplete
                id="broker.broker_agent_address"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker.broker_agent_address}
                onLocationChange={handleBrokerAddress}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Unit/Suite</CustomFormLabel>
              <CustomTextField
                name="broker.broker_agent_unit_or_suite"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker.broker_agent_unit_or_suite}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>City</CustomFormLabel>
              <CustomTextField
                name="broker.broker_agent_city"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker.broker_agent_city}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Province/State</CustomFormLabel>
              <CustomTextField
                name="broker.broker_agent_state"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker.broker_agent_state}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Country</CustomFormLabel>
              <CustomTextField
                name="broker.broker_agent_country"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker.broker_agent_country}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Postal/ZIP Code</CustomFormLabel>
              <CustomTextField
                name="broker.broker_agent_zip"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker.broker_agent_zip}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Broker Code</CustomFormLabel>
              <CustomTextField
                name="broker.broker_code"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker.broker_code}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Broker Subcode</CustomFormLabel>
              <CustomTextField
                name="broker.broker_subcode"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker.broker_subcode}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <LabelCard heading="Policy Peroid"/>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Effective Date</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.policy_period_effective_date || ""}
                onChange={(newValue) => {
                  setFieldValue("policy_period_effective_date", newValue);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Effective Time</CustomFormLabel>
              <CustomTimePicker
                fullWidth
                value={values.policy_period_effective_time}
                onChange={(newValue) => {
                  setFieldValue(
                    "policy_period_effective_time",
                    newValue.toString()
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Expiry Date</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.policy_period_expiry_date || ""}
                onChange={(newValue) => {
                  setFieldValue("policy_period_expiry_date", newValue);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Expiry Time</CustomFormLabel>
              <CustomTimePicker
                fullWidth
                value={values.policy_period_expiry_time}
                onChange={(newValue) => {
                  setFieldValue("policy_period_expiry_time", newValue);
                }}
              />
            </Grid>
          </Grid>

          <LabelCard heading=" Name / Address Change"/>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={12}>
              <CustomFormLabel>
                Name Changed (Refer to Section 1) GIVE REASON FOR CHANGE{" "}
              </CustomFormLabel>
              <CustomTextField
                name="name_changed"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.name_changed}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <CustomFormLabel>
                Address Changed (Refer to Section 1){" "}
              </CustomFormLabel>
              <CustomTextField
                name="address_changed"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.address_changed}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <LabelCard heading="Vehicle"/>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={2.4}>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={values.vehicle_checkbox === "Add"}
                  onChange={(e) => {
                    setFieldValue("vehicle_checkbox", "Add");
                  }}
                />
                <Typography>Add</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={values.vehicle_checkbox === "Delete"}
                  onChange={(e) => {
                    setFieldValue("vehicle_checkbox", "Delete");
                  }}
                />
                <Typography>Delete</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={values.vehicle_checkbox === "Change"}
                  onChange={(e) => {
                    setFieldValue("vehicle_checkbox", "Change");
                  }}
                />
                <Typography>Change</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={2}>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={values.vehicle_checkbox === "Substitute"}
                  onChange={(e) => {
                    setFieldValue("vehicle_checkbox", "Substitute");
                  }}
                />
                <Typography>
                  Substitute
                  {values.vehicle_checkbox === "Substitute" &&
                    ":  Previous V.I.N."}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={2.8}>
              {values.vehicle_checkbox === "Substitute" && (
                <CustomTextField
                  name="previous_vin_number"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.previous_vin_number}
                  onChange={handleChange}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <DataTable {...describedVehicleInformation} />
            </Grid>

            <Grid item xs={12}>
              <DataTable {...describedVehicleModelInfo} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>USE</CustomFormLabel>
              <Stack direction="row" spacing={2} alignItems="center">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Checkbox
                    checked={values.pleasure_business === "Pleasure"}
                    onChange={(e) => {
                      setFieldValue("pleasure_business", "Pleasure");
                    }}
                  />
                  <Typography>Pleasure</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Checkbox
                    checked={values.pleasure_business === "Business"}
                    onChange={(e) => {
                      setFieldValue("pleasure_business", "Business");
                    }}
                  />
                  <Typography>Business</Typography>
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Is Vehicle Used To Commute</CustomFormLabel>
              <Stack direction="row" spacing={2} alignItems="center">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Checkbox
                    checked={values.vehicle_used_commute === "YES"}
                    onChange={(e) => {
                      setFieldValue("vehicle_used_commute", "YES");
                    }}
                  />
                  <Typography>YES</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Checkbox
                    checked={values.vehicle_used_commute === "NO"}
                    onChange={(e) => {
                      setFieldValue("vehicle_used_commute", "NO");
                    }}
                  />
                  <Typography>NO</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <CustomTextField
                    name="km_one_way"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.km_one_way}
                    onChange={handleChange}
                  />
                  <Typography>Km One Way</Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Annual Distance</CustomFormLabel>
              <Stack direction="row" alignItems="center" spacing={1}>
                <CustomTextField
                  name="annual_distance"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.annual_distance}
                  onChange={handleChange}
                />
                <Typography>Km</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={2.6}>
              <CustomFormLabel>Fuel</CustomFormLabel>
              <CustomTextField
                name="fuel"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.fuel}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Divider sx={{ mt: 2, mb: 2 }} />

          <Grid container spacing={2} mt={2} alignItems={"center"}>
            <Grid item xs={12} lg={3.9}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent={"space-between"}
                pr={1}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Checkbox
                    checked={values.coverage_data.bi_pd_checkbox === "Yes"}
                    onChange={(e) => {
                      setFieldValue(
                        "coverage_data.bi_pd_checkbox",
                        e.target.checked ? "Yes" : "No"
                      );
                    }}
                  />
                  <Typography>B.I/P.D</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography>$</Typography>
                  <CustomTextField
                    name="coverage_data.bi_pd_limit"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.coverage_data.bi_pd_limit}
                    onChange={handleChange}
                  />
                  <Typography>Limit</Typography>
                </Stack>
              </Stack>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={12} lg={4}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent={"space-between"}
                pr={1}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Checkbox
                    checked={values.coverage_data.all_perils_checkbox === "Yes"}
                    onChange={(e) => {
                      setFieldValue(
                        "coverage_data.all_perils_checkbox",
                        e.target.checked ? "Yes" : "No"
                      );
                    }}
                  />
                  <Typography>All Perils</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography>$</Typography>
                  <CustomTextField
                    name="coverage_data.all_perils_deductible"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.coverage_data.all_perils_deductible}
                    onChange={handleChange}
                  />
                  <Typography>Deductible</Typography>
                </Stack>
              </Stack>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={12} lg={4}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent={"space-between"}
                pr={1}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Checkbox
                    checked={
                      values.coverage_data.income_replacement_limit === "Yes"
                    }
                    onChange={(e) => {
                      setFieldValue(
                        "coverage_data.income_replacement_limit",
                        e.target.checked ? "Yes" : "No"
                      );
                    }}
                  />
                  <Typography>Income Replacement</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography>$</Typography>
                  <CustomTextField
                    name="coverage_data.income_replacement_checkbox"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.coverage_data.income_replacement_checkbox}
                    onChange={handleChange}
                  />
                  <Typography>Limit</Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          <Divider sx={{ mt: 2, mb: 2 }} />

          <Grid container spacing={2} mt={2} alignItems={"center"}>
            <Grid item xs={12} lg={3.9}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent={"space-between"}
                pr={1}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Checkbox
                    checked={
                      values.coverage_data.accidents_benfits_checkbox === "Yes"
                    }
                    onChange={(e) => {
                      setFieldValue(
                        "coverage_data.accidents_benfits_checkbox",
                        e.target.checked ? "Yes" : "No"
                      );
                    }}
                  />
                  <Typography>Accidents Benefits</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {/* <Typography>$</Typography>
              <CustomTextField
                name="coverage_data.bi_pd_limit"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverage_data.bi_pd_limit}
                onChange={handleChange}
              />
              <Typography>Limit</Typography> */}
                </Stack>
              </Stack>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={12} lg={4}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent={"space-between"}
                pr={1}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Checkbox
                    checked={values.coverage_data.collision_checkbox === "Yes"}
                    onChange={(e) => {
                      setFieldValue(
                        "coverage_data.collision_checkbox",
                        e.target.checked ? "Yes" : "No"
                      );
                    }}
                  />
                  <Typography>Collision</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography>$</Typography>
                  <CustomTextField
                    name="coverage_data.collision_deductible"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.coverage_data.collision_deductible}
                    onChange={handleChange}
                  />
                  <Typography>Deductible</Typography>
                </Stack>
              </Stack>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={12} lg={4}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent={"space-between"}
                pr={1}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Checkbox
                    checked={
                      values.coverage_data.caregiver_dependant_checkbox ===
                      "Yes"
                    }
                    onChange={(e) => {
                      setFieldValue(
                        "coverage_data.caregiver_dependant_checkbox",
                        e.target.checked ? "Yes" : "No"
                      );
                    }}
                  />
                  <Typography>Caregiver & Dependant Care</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {/* <Typography>$</Typography>
              <CustomTextField
                name="coverage_data.caregiver_dependant_deductible"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverage_data.caregiver_dependant_deductible}
                onChange={handleChange}
              />
              <Typography>Limit</Typography> */}
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          <Divider sx={{ mt: 2, mb: 2 }} />

          <Grid container spacing={2} mt={2} alignItems={"center"}>
            <Grid item xs={12} lg={3.9}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent={"space-between"}
                pr={1}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Checkbox
                    checked={
                      values.coverage_data.uninsured_automobile_checkbox ===
                      "Yes"
                    }
                    onChange={(e) => {
                      setFieldValue(
                        "coverage_data.uninsured_automobile_checkbox",
                        e.target.checked ? "Yes" : "No"
                      );
                    }}
                  />
                  <Typography>Uninsured Automobile / U.M</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {/* <Typography>$</Typography>
              <CustomTextField
                name="coverage_data.bi_pd_limit"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverage_data.bi_pd_limit}
                onChange={handleChange}
              />
              <Typography>Limit</Typography> */}
                </Stack>
              </Stack>
            </Grid>

            <Divider orientation="vertical" flexItem />

            <Grid item xs={12} lg={4}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent={"space-between"}
                pr={1}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Checkbox
                    checked={
                      values.coverage_data.comprehensive_checkbox === "Yes"
                    }
                    onChange={(e) => {
                      setFieldValue(
                        "coverage_data.comprehensive_checkbox",
                        e.target.checked ? "Yes" : "No"
                      );
                    }}
                  />
                  <Typography>Comprehensive</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography>$</Typography>
                  <CustomTextField
                    name="coverage_data.comprehensive_deductible"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.coverage_data.comprehensive_deductible}
                    onChange={handleChange}
                  />
                  <Typography>Deductible</Typography>
                </Stack>
              </Stack>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={12} lg={4}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent={"space-between"}
                pr={1}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Checkbox
                    checked={
                      values.coverage_data.medical_rehab_checkbox === "Yes"
                    }
                    onChange={(e) => {
                      setFieldValue(
                        "coverage_data.medical_rehab_checkbox",
                        e.target.checked ? "Yes" : "No"
                      );
                    }}
                  />
                  <Typography>Caregiver & Dependant Care</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {/* <Typography>$</Typography>
              <CustomTextField
                name="coverage_data.caregiver_dependant_deductible"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverage_data.caregiver_dependant_deductible}
                onChange={handleChange}
              />
              <Typography>Limit</Typography> */}
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          <Divider sx={{ mt: 2, mb: 2 }} />

          <Grid container spacing={2} mt={2} alignItems={"center"}>
            <Grid item xs={12} lg={3.9}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent={"space-between"}
                pr={1}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Checkbox
                    checked={values.coverage_data.dcpd_checkbox === "Yes"}
                    onChange={(e) => {
                      setFieldValue(
                        "coverage_data.dcpd_checkbox",
                        e.target.checked ? "Yes" : "No"
                      );
                    }}
                  />
                  <Typography>D.C./P.D. (where applicable)</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography>$</Typography>
                  <CustomTextField
                    name="coverage_data.dcpd_deductible"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.coverage_data.dcpd_deductible}
                    onChange={handleChange}
                  />
                  <Typography>Deductible</Typography>
                </Stack>
              </Stack>
            </Grid>

            <Divider orientation="vertical" flexItem />

            <Grid item xs={12} lg={4}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent={"space-between"}
                pr={1}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Checkbox
                    checked={
                      values.coverage_data.specific_perils_checkbox === "Yes"
                    }
                    onChange={(e) => {
                      setFieldValue(
                        "coverage_data.specific_perils_checkbox",
                        e.target.checked ? "Yes" : "No"
                      );
                    }}
                  />
                  <Typography>Specified Perils</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography>$</Typography>
                  <CustomTextField
                    name="coverage_data.specific_perils_deductible"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.coverage_data.specific_perils_deductible}
                    onChange={handleChange}
                  />
                  <Typography>Deductible</Typography>
                </Stack>
              </Stack>
            </Grid>

            <Divider orientation="vertical" flexItem />

            <Grid item xs={12} lg={4}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent={"space-between"}
                pr={1}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Checkbox
                    checked={
                      values.coverage_data.death_funeral_benefits_checkbox ===
                      "Yes"
                    }
                    onChange={(e) => {
                      setFieldValue(
                        "coverage_data.death_funeral_benefits_checkbox",
                        e.target.checked ? "Yes" : "No"
                      );
                    }}
                  />
                  <Typography>
                    Death and Funeral And/Or Indexation Benefit
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {/* <Typography>$</Typography>
              <CustomTextField
                name="coverage_data.caregiver_dependant_deductible"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverage_data.caregiver_dependant_deductible}
                onChange={handleChange}
              />
              <Typography>Limit</Typography> */}
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          <Divider sx={{ mt: 2, mb: 2 }} />

          <Grid container spacing={2} mt={2} alignItems={"center"}>
            <Grid item xs={12} lg={3}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Checkbox
                  checked={values.coverage_data.endorsements_checkbox === "Yes"}
                  onChange={(e) => {
                    setFieldValue(
                      "coverage_data.endorsements_checkbox",
                      e.target.checked ? "Yes" : "No"
                    );
                  }}
                />
                <Typography>Endorsements (S.E.F, Q.E.F, O.P.C.F.)</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={9}>
              <CustomTextField
                name="coverage_data.endorsements_text"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverage_data.endorsements_text}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <LabelCard heading="Additional Interest"/>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={3}>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={values.additioanl_interest === "Add"}
                  onChange={(e) => {
                    setFieldValue("additioanl_interest", "Add");
                  }}
                />
                <Typography>Add</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={values.additioanl_interest === "Delete"}
                  onChange={(e) => {
                    setFieldValue("additioanl_interest", "Delete");
                  }}
                />
                <Typography>Delete</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={1.8}>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={values.additioanl_interest === "Change"}
                  onChange={(e) => {
                    setFieldValue("additioanl_interest", "Change");
                  }}
                />
                <Typography>
                  Change
                  {values.additioanl_interest === "Change" &&
                    ":  Vehicle V.I.N."}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4.2}>
              {values.additioanl_interest === "Change" && (
                <CustomTextField
                  name="additional_interest_vin_number"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.additional_interest_vin_number}
                  onChange={handleChange}
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={5}>
              <CustomFormLabel>Address</CustomFormLabel>
              <LocationAutoComplete
                id="additional_interest_details.address"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.additional_interest_details.address}
                onLocationChange={handleAdditionalInterestAddress}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Address 2</CustomFormLabel>
              <CustomTextField
                name="additional_interest_details.unit_or_suite"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.additional_interest_details.unit_or_suite}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Country</CustomFormLabel>
              <CustomTextField
                name="additional_interest_details.country"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.additional_interest_details.country}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>City</CustomFormLabel>
              <CustomTextField
                name="additional_interest_details.city"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.additional_interest_details.city}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>State</CustomFormLabel>
              <CustomTextField
                name="additional_interest_details.province_or_state"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.additional_interest_details.province_or_state}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>Postal Code</CustomFormLabel>
              <CustomTextField
                name="additional_interest_details.postal_code"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.additional_interest_details.postal_code}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <LabelCard heading="Nature of Interest"/>
          <Grid container>
            <Grid item xs={12} lg={3}>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={values.nature_of_interest === "Lienholder"}
                  onChange={(e) => {
                    setFieldValue("nature_of_interest", "Lienholder");
                  }}
                />
                <Typography>LIENHOLDER</Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} lg={3}>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={values.nature_of_interest === "Lessor"}
                  onChange={(e) => {
                    setFieldValue("nature_of_interest", "Lessor");
                  }}
                />
                <Typography>LESSOR</Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} lg={3}>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={values.nature_of_interest === "Other"}
                  onChange={(e) => {
                    setFieldValue("nature_of_interest", "Other");
                  }}
                />
                <CustomTextField
                  name="nature_of_interest_text"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.nature_of_interest_text}
                  onChange={handleChange}
                />
              </Stack>
            </Grid>
          </Grid>

          <LabelCard heading="Operator"/>
          <Grid container>
            <Grid item xs={12} lg={3}>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={values.operator === "Add"}
                  onChange={(e) => {
                    setFieldValue("operator", "Add");
                  }}
                />
                <Typography>Add</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={values.operator === "Delete"}
                  onChange={(e) => {
                    setFieldValue("operator", "Delete");
                  }}
                />
                <Typography>Delete</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={1.4}>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={values.operator === "Change"}
                  onChange={(e) => {
                    setFieldValue("operator", "Change");
                  }}
                />
                <Typography>
                  Change
                  {values.operator === "Change" && ":  Name"}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={2.6}>
              {values.operator === "Change" && (
                <CustomTextField
                  name="operator_name"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.operator_name}
                  onChange={handleChange}
                />
              )}
            </Grid>
            <Grid item xs={12} lg={2}>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={values.same_as_section_one === "Section One"}
                  onChange={(e) => {
                    setFieldValue("same_as_section_one", "Section One");
                  }}
                />
                <Typography>Same As Section 1</Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={12}>
              <DataTable {...describeOperatorOne} />
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={12}>
              <DataTable {...describeOperatorTwo} />
            </Grid>
          </Grid>
          <Grid container mt={3} spacing={2}>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>Occupation(where applicable)</CustomFormLabel>
              <CustomTextField
                name="occupation"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.occupation}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>Driver Training Course</CustomFormLabel>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Stack direction={"row"} alignItems={"center"}>
                  <Checkbox
                    checked={values.driver_training_course === "YES"}
                    onChange={(e) => {
                      setFieldValue("driver_training_course", "YES");
                    }}
                  />
                  <Typography>YES</Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"}>
                  <Checkbox
                    checked={values.driver_training_course === "NO"}
                    onChange={(e) => {
                      setFieldValue("driver_training_course", "NO");
                    }}
                  />
                  <Typography>NO</Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography>Attach Driver Training Certificate</Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>
                Current or Previous Insurance Company
              </CustomFormLabel>
              <CustomTextField
                name="current_or_previous_insurance"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.current_or_previous_insurance}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Policy Number</CustomFormLabel>
              <CustomTextField
                name="policy_number"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.policy_number}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Relation to Insured</CustomFormLabel>
              <CustomTextField
                name="relation_to_insured"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.relation_to_insured}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <LabelCard heading="CONVICTIONS DURING THE PAST 3 YEARS"/>
          <Grid container mt={3}>
            <Grid item xs={12}>
              <DataTable {...describeConvictions} />
            </Grid>
          </Grid>

          <LabelCard heading="ACCIDENTS DURING THE PAST 3 YEARS"/>
          <Grid container mt={3}>
            <Grid item xs={12}>
              <DataTable {...describeAccidents} />
            </Grid>

            <Grid item xs={12}>
              <CustomFormLabel>Remarks</CustomFormLabel>
              <CustomTextField
                name="remarks"
                variant="outlined"
                size="small"
                multiline
                rows={3}
                type="text"
                fullWidth
                value={values.remarks}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </form>
      </StandadCard>
    </PageLoader>
  );
};