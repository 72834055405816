import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialAutomoblieSchedule } from "./automobileSchedule.types";
import { AutomobileScheduleActions } from ".";
import {
  CLEAR_AUTOMOBILE_SCHEDULE_STATE,
  FETCH_AUTOMOBILE_SCHEDULE_A_FAILED,
  FETCH_AUTOMOBILE_SCHEDULE_A_LIST_FAILED,
  FETCH_AUTOMOBILE_SCHEDULE_A_LIST_PROGRESS,
  FETCH_AUTOMOBILE_SCHEDULE_A_LIST_SUCCESS,
  FETCH_AUTOMOBILE_SCHEDULE_A_PROGRESS,
  FETCH_AUTOMOBILE_SCHEDULE_A_SUCCESS,
} from "./automobileScheduleActions";

export const AutomobileScheduleReducer = (
  state: IStoreState["automobile"]["schedule"] = initialAutomoblieSchedule,
  action: AutomobileScheduleActions
) => {
  switch (action.type) {
    case FETCH_AUTOMOBILE_SCHEDULE_A_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.scheduleA.loading = LoadState.InProgress;
        draftState.scheduleA.data =
          initialAutomoblieSchedule["scheduleA"]["data"];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_SCHEDULE_A_SUCCESS: {
      const newState = produce(state, (draftState) => {
        draftState.scheduleA.loading = LoadState.Loaded;
        draftState.scheduleA.data = action.payload.data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_SCHEDULE_A_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.scheduleA.loading = LoadState.Failed;
        draftState.scheduleA.data =
          initialAutomoblieSchedule["scheduleA"]["data"];
      });
      return newState;
    }

    case FETCH_AUTOMOBILE_SCHEDULE_A_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.scheduleA.listLoading = LoadState.InProgress;
        draftState.scheduleA.listData = [];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_SCHEDULE_A_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.scheduleA.listLoading = LoadState.Loaded;
        draftState.scheduleA.listData = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_SCHEDULE_A_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.scheduleA.listLoading = LoadState.Failed;
        draftState.scheduleA.listData = [];
      });
      return newState;
    }
    case CLEAR_AUTOMOBILE_SCHEDULE_STATE: {
      return initialAutomoblieSchedule;
    }

    default: {
      return state;
    }
  }
};
