import { action } from "typesafe-actions";
import { ITaxInfo, initialAccountingTaxInfoState } from "./TaxInfo.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";

export const FETCH_ACCOUNTING_TAX_INFO_PROGRESS =
  "FETCH_ACCOUNTING_TAX_INFO_PROGRESS";
export const FETCH_ACCOUNTING_TAX_INFO_SUCCESS =
  "FETCH_ACCOUNTING_TAX_INFO_SUCCESS";
export const FETCH_ACCOUNTING_TAX_INFO_FAILED =
  "FETCH_ACCOUNTING_TAX_INFO_FAILED";
export const CLEAR_ACCOUNTING_TAX_INFO = "CLEAR_ACCOUNTING_TAX_INFO";

export const fetchAccountingTaxInfoProgress = () =>
  action(FETCH_ACCOUNTING_TAX_INFO_PROGRESS);
export const fetchAccountingTaxInfoSuccess = (data: ITaxInfo) =>
  action(FETCH_ACCOUNTING_TAX_INFO_SUCCESS, { data });
export const fetchAccountingTaxInfoFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_TAX_INFO_FAILED, { errorMessage });
export const clearAccountingTaxInfo = () => action(CLEAR_ACCOUNTING_TAX_INFO);

export const fetchAccountingTaxInfoAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingTaxInfoProgress());

      const res = await api.get(`/accounting/get-account-tax-info`);
      const data: ITaxInfo[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAccountingTaxInfoSuccess(data[0]));
      } else {
        dispatch(
          fetchAccountingTaxInfoSuccess(initialAccountingTaxInfoState["data"])
        );
      }
    } catch (err: any) {
      dispatch(fetchAccountingTaxInfoFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingTaxInfoAysnc =
  (
    data: ITaxInfo,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/accounting/edit-account-tax-info", {
        ...data,
      });
      let message = "Tax Info saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };
