import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialCommAutomobileApplicationState } from "./commAutoApplication.types";
import { CommAutomobileApplicationActions } from ".";
import {
  CLEAR_COMM_AUTOMOBILE_APPLICATION_STATE,
  FETCH_COMM_AUTOMOBILE_APPLICATION_FAILED,
  FETCH_COMM_AUTOMOBILE_APPLICATION_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_APPLICATION_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_APPLICATION_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_APPLICATION_PROGRESS,
  FETCH_COMM_AUTOMOBILE_APPLICATION_SUCCESSS,
} from "./commAutoApplicationActions";

export const CommAutomobileApplicationReducer = (
  state: IStoreState["commAuto"]["appication"] = initialCommAutomobileApplicationState,
  action: CommAutomobileApplicationActions
) => {
  switch (action.type) {
    case FETCH_COMM_AUTOMOBILE_APPLICATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.application =
          initialCommAutomobileApplicationState["application"];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_APPLICATION_SUCCESSS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.application = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_APPLICATION_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.application =
          initialCommAutomobileApplicationState["application"];
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_APPLICATION_LIST_PROGRESS: {
      const newState = produce(state , (draftState) => {
        draftState.listLoading = LoadState.InProgress;
        draftState.data = [];
      })
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_APPLICATION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Loaded;
        draftState.data = data;
      })
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_APPLICATION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Failed;
        draftState.data = [];
      })
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_APPLICATION_STATE: {
      return initialCommAutomobileApplicationState;
    }

    default: {
      return state;
    }
  }
};
