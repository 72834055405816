import { LoadState } from "../../../constants/enums";


export interface ITaskVehicleState {
    addVehicle: {
      data: IAddVehicle;
      loading: LoadState;
    }
}

export interface IAddVehicle {

}

export const initialTaskVehicleState: ITaskVehicleState = {
    addVehicle: {
        data: { },
        loading: LoadState.Loaded
    }
}