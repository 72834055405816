import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { ERROR_MESSAGES, LoadState } from "../../../constants/enums";
import {
  ILifeInsuranceAttachment,
  ILifeInsuranceClaims,
  ILifeInsuranceCustomerInfo,
  ILifeInsurancePolicyAdmin,
  ILifeInsurancePolicyAdminManager,
  ILifeInsurancePremium,
  initialLifeInsuranceBasicDetails,
} from "./lifeInsuranceBasicDetail.types";
import { uploadFile } from "../../../helpers";

export const ADD_LIFE_INSURANCE_POLICY_ID = "ADD_LIFE_INSURANCE_POLICY_ID";
export const LIFE_INSURANCE_POLICY_BASIC_DETAILS_NOT_FOUND =
  "LIFE_INSURANCE_POLICY_BASIC_DETAILS_NOT_FOUND";

export const addLifeInsurancePolicyId = () =>
  action(ADD_LIFE_INSURANCE_POLICY_ID);

export const FETCH_LIFE_INSURANCE_CUSTOMER_INFO_PROGRESS =
  "FETCH_LIFE_INSURANCE_CUSTOMER_INFO_PROGRESS";
export const ADD_LIFE_INSURANCE_CUSTOMER_INFO =
  "ADD_LIFE_INSURANCE_CUSTOMER_INFO";
export const FETCH_LIFE_INSURANCE_CUSTOMER_INFO_FAILED =
  "FETCH_LIFE_INSURANCE_CUSTOMER_INFO_FAILED";

export const fetchLifeInsuranceCustomerInfoProgress = () =>
  action(FETCH_LIFE_INSURANCE_CUSTOMER_INFO_PROGRESS);

export const addLifeInsuranceCustomerInfo = (
  customerInfo: ILifeInsuranceCustomerInfo,
  customerPolicyId: number,
  isNewPolicy: boolean
) =>
  action(ADD_LIFE_INSURANCE_CUSTOMER_INFO, {
    customerInfo,
    customerPolicyId,
    isNewPolicy,
  });

export const fetchLifeInsuranceCustomerInfofailed = (errorMessage: string) =>
  action(FETCH_LIFE_INSURANCE_CUSTOMER_INFO_FAILED, { errorMessage });

export const addLifeInsuranceCustomerInfoAsync =
  (
    data: ILifeInsuranceCustomerInfo,
    onCallback: (isSuccess: boolean, policyId?: number) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post(
        "/policy/edit-life-policy-basic-details-customer-info",
        {
          ...data,
          policy_id: data.sub_type,
        }
      );

      const customerPolicyId = res.data.data.customer_policy_id;
      dispatch(
        addLifeInsuranceCustomerInfo(res.data.data, customerPolicyId, true)
      );
      dispatch(
        showMessage({
          type: "success",
          message: "Customer information saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true, customerPolicyId);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchLifeInsuranceCustomerInfoAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().lifeInsurance.basicDetails.customerInfo.Loading ===
      LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchLifeInsuranceCustomerInfoProgress());
        const res = await api.get(
          `/policy/get-life-policy-basic-details-customer-info?customer_policy_id=${customerPolicyId}`
        );
        const data: ILifeInsuranceCustomerInfo[] = res.data.data;
        if (data.length > 0) {
          dispatch(
            addLifeInsuranceCustomerInfo(
              {
                ...data[0],
              },
              customerPolicyId,
              false
            )
          );
        } else {
          dispatch(
            fetchLifeInsuranceCustomerInfofailed(
              "Oops! We couldn't find any records associated with your policy id at the moment. Please ensure that the provided information is accurate and try again."
            )
          );
        }
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      dispatch(
        fetchLifeInsuranceCustomerInfofailed("Something went to be wrong!")
      );
    }
  };

export const FETCH_LIFE_INSURANCE_CLAIM_PROGRESS =
  "FETCH_LIFE_INSURANCE_CLAIM_PROGRESS";
export const ADD_LIFE_INSURANCE_CLAIM = "ADD_LIFE_INSURANCE_CLAIM";
export const FETCH_LIFE_INSURANCE_CLAIM_FAILED =
  "FETCH_LIFE_INSURANCE_CLAIM_FAILED";

export const fetchLifeInsuranceClaimProgress = () =>
  action(FETCH_LIFE_INSURANCE_CLAIM_PROGRESS);
export const addLifeInsuranceClaim = (claim: ILifeInsuranceClaims[]) =>
  action(ADD_LIFE_INSURANCE_CLAIM, { claim });
export const fetchLifeInsuranceClaimFailed = () =>
  action(FETCH_LIFE_INSURANCE_CLAIM_FAILED);

export const addLifeInsuranceClaimAsync =
  (
    data: ILifeInsuranceClaims,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-life-policy-basic-details-claim", {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Policy claim saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
      dispatch(fetchLifeInsuranceClaimsAsync(data.customer_policy_id));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchLifeInsuranceClaimsAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchLifeInsuranceClaimProgress());
      const res = await api.get(
        `/policy/get-life-policy-basic-details-claim?customer_policy_id=${customerPolicyId}`
      );
      const data: ILifeInsuranceClaims[] = res.data.data;

      dispatch(addLifeInsuranceClaim(data));
    } catch (err: any) {
      dispatch(fetchLifeInsuranceClaimFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_LIFE_INSURANCE_PREMIUM_PROGRESS =
  "FETCH_LIFE_INSURANCE_PREMIUM_PROGRESS";
export const ADD_LIFE_INSURANCE_PREMIUM = "ADD_LIFE_INSURANCE_PREMIUM";
export const FETCH_LIFE_INSURANCE_PREMIUM_FAILED =
  "FETCH_LIFE_INSURANCE_PREMIUM_FAILED";

export const fetchLifeInsurancePremiumProgress = () =>
  action(FETCH_LIFE_INSURANCE_PREMIUM_PROGRESS);
export const addLifeInsurancePremium = (premium: ILifeInsurancePremium) =>
  action(ADD_LIFE_INSURANCE_PREMIUM, { premium });
export const fetchLifeInsurancePremiumFailed = () =>
  action(FETCH_LIFE_INSURANCE_PREMIUM_FAILED);

export const addLifeInsurancePremiumAsync =
  (
    data: ILifeInsurancePremium,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-life-policy-basic-details-premium", {
        ...data,
      });
      dispatch(addLifeInsurancePremium(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Policy Premium saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchLifeInsurancePremiumAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().business.basicDetails.premium.loading === LoadState.Loaded;

    try {
      if (!isSaved) {
        const res = await api.get(
          `/policy/get-life-policy-basic-details-premium?customer_policy_id=${customerPolicyId}`
        );
        const data: ILifeInsurancePremium[] = res.data.data;
        if (data.length > 0) {
          dispatch(addLifeInsurancePremium(data[0]));
        } else {
          dispatch(
            addLifeInsurancePremium(
              initialLifeInsuranceBasicDetails["premium"]["data"]
            )
          );
        }
      }
    } catch (err: any) {
      dispatch(fetchLifeInsurancePremiumFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_LIFE_INSURANCE_POLICY_ADMIN_PROGRESS =
  "FETCH_LIFE_INSURANCE_POLICY_ADMIN_PROGRESS";
export const ADD_LIFE_INSURANCE_POLICY_ADMIN =
  "ADD_LIFE_INSURANCE_POLICY_ADMIN";
export const FETCH_LIFE_INSURANCE_POLICY_ADMIN_FAILED =
  "FETCH_LIFE_INSURANCE_POLICY_ADMIN_FAILED";

export const fetchLifeInsurancePolicyAdminProgress = () =>
  action(FETCH_LIFE_INSURANCE_POLICY_ADMIN_PROGRESS);
export const addLifeInsurancePolicyAdmin = (data: ILifeInsurancePolicyAdmin) =>
  action(ADD_LIFE_INSURANCE_POLICY_ADMIN, { data });
export const fetchLifeInsurancePolicyAdminFailed = () =>
  action(FETCH_LIFE_INSURANCE_POLICY_ADMIN_FAILED);

export const addLifeInsurancePolicyAdminAsync =
  (
    data: ILifeInsurancePolicyAdmin,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-life-policy-basic-details-policy-admin", {
        ...data,
      });
      dispatch(addLifeInsurancePolicyAdmin(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Policy Admin saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchLifeInsurancePolicyAdminAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().business.basicDetails.policyAdmin.loading === LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchLifeInsurancePolicyAdminProgress());
        const res = await api.get(
          `/policy/get-life-policy-basic-details-policy-admin?customer_policy_id=${customerPolicyId}`
        );
        const data: ILifeInsurancePolicyAdmin[] = res.data.data;
        if (data.length > 0) {
          dispatch(addLifeInsurancePolicyAdmin(data[0]));
        } else {
          dispatch(
            addLifeInsurancePolicyAdmin(
              initialLifeInsuranceBasicDetails["policyAdmin"]["data"]
            )
          );
        }
      }
    } catch (err: any) {
      dispatch(fetchLifeInsurancePolicyAdminFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_LIFE_INSURANCE_POLICY_ADMIN_MANAGER_PROGRESS =
  "FETCH_LIFE_INSURANCE_POLICY_ADMIN_MANAGER_PROGRESS";
export const FETCH_LIFE_INSURANCE_POLICY_ADMIN_MANAGER_SUCCESS =
  "FETCH_LIFE_INSURANCE_POLICY_ADMIN_MANAGER_SUCCESS";
export const FETCH_LIFE_INSURANCE_POLICY_ADMIN_MANAGER_FAILED =
  "FETCH_LIFE_INSURANCE_POLICY_ADMIN_MANAGER_FAILED";

export const fetchLifeInsurancePolicyAdminManagerProgress = () =>
  action(FETCH_LIFE_INSURANCE_POLICY_ADMIN_MANAGER_PROGRESS);
export const fetchLifeInsurancePolicyAdminManagerSuccess = (
  data: ILifeInsurancePolicyAdminManager
) => action(FETCH_LIFE_INSURANCE_POLICY_ADMIN_MANAGER_SUCCESS, { data });
export const fetchLifeInsurancePolicyAdminManagerFailed = (
  errorMessage: string
) => action(FETCH_LIFE_INSURANCE_POLICY_ADMIN_MANAGER_FAILED, { errorMessage });

export const fetchLifeInsurancePolicyAdminManagerAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchLifeInsurancePolicyAdminManagerProgress());
      const res = await api.get(
        `/policy/get-life-policy-basic-details-policy-admin-manager?customer_policy_id=${customerPolicyId}`
      );
      const data: ILifeInsurancePolicyAdminManager[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchLifeInsurancePolicyAdminManagerSuccess(data[0]));
      } else {
        dispatch(
          fetchLifeInsurancePolicyAdminManagerFailed(
            "Oops! We couldn't find any records associated with your fine art number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchLifeInsurancePolicyAdminManagerFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addLifeInsurancePolicyAdminManagerAsync =
  (
    data: ILifeInsurancePolicyAdminManager,
    callBack: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        "/policy/edit-life-policy-basic-details-policy-admin-manager",
        data
      );
      dispatch(
        showMessage({
          type: "success",
          message: "Policy Admin Manager saved successfully!",
          displayAs: "snackbar",
        })
      );
      callBack(true);
    } catch (err: any) {
      callBack(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_LIFE_INSURANCE_ATTACHMENT_PROGRESS =
  "FETCH_LIFE_INSURANCE_ATTACHMENT_PROGRESS";
export const FETCH_LIFE_INSURANCE_ATTACHMENT_SUCCESS =
  "FETCH_LIFE_INSURANCE_ATTACHMENT_SUCCESS";
export const FETCH_LIFE_INSURANCE_ATTACHMENT_FAILED =
  "FETCH_LIFE_INSURANCE_ATTACHMENT_FAILED";

export const fetchLifeInsuranceAttachmentProgress = () =>
  action(FETCH_LIFE_INSURANCE_ATTACHMENT_PROGRESS);
export const fetchLifeInsuranceAttachmentSuccess = (
  data: ILifeInsuranceAttachment
  // customerPolicyId: number
) => action(FETCH_LIFE_INSURANCE_ATTACHMENT_SUCCESS, { data });
export const fetchLifeInsuranceAttachmentFailed = (errorMessage: string) =>
  action(FETCH_LIFE_INSURANCE_ATTACHMENT_FAILED, { errorMessage });

export const addLifeInsuranceAttachmentAsync =
  (
    data: ILifeInsuranceAttachment,
    customerID: number,
    file: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        customer_id: customerID,
        policy_type: "LIFE_INSURANCE",
        customer_policy_id: data.customer_policy_id,
        page_name: "Life-Insurance Attachment",
      };

      const path = await uploadFile(
        file,
        "POLICY",
        data.file_upload || "",
        asPayload
      );
      await api.post("/policy/edit-life-policy-basic-details-attachment", {
        ...data,
        file_upload: path,
      });
      // const data = res.data.data;

      dispatch(fetchLifeInsuranceAttachmentSuccess(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Attachment saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchLifeInsuranceAttachmentAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().lifeInsurance.basicDetails.attachment.loading ===
      LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchLifeInsuranceAttachmentProgress());
        const res = await api.get(
          `/policy/get-life-policy-basic-details-attachment?customer_policy_id=${customerPolicyId}`
        );
        const data: ILifeInsuranceAttachment[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchLifeInsuranceAttachmentSuccess(data[0]));
        } else {
          dispatch(
            fetchLifeInsuranceAttachmentFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
          );
        }
      }
    } catch (err: any) {
      dispatch(fetchLifeInsuranceAttachmentFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_LIFE_INSURANCE_ATTACHMENT_LIST_PROGRESS =
  "FETCH_LIFE_INSURANCE_ATTACHMENT_LIST_PROGRESS";
export const FETCH_LIFE_INSURANCE_ATTACHMENT_LIST_SUCCESS =
  "FETCH_LIFE_INSURANCE_ATTACHMENT_LIST_SUCCESS";
export const FETCH_LIFE_INSURANCE_ATTACHMENT_LIST_FAILED =
  "FETCH_LIFE_INSURANCE_ATTACHMENT_LIST_FAILED";

export const fetchLifeInsuranceAttachmentListProgress = () =>
  action(FETCH_LIFE_INSURANCE_ATTACHMENT_LIST_PROGRESS);
export const fetchLifeInsuranceAttachmentListSuccess = (
  data: ILifeInsuranceAttachment[]
) => action(FETCH_LIFE_INSURANCE_ATTACHMENT_LIST_SUCCESS, { data });
export const fetchLifeInsuranceAttachmentListFailed = () =>
  action(FETCH_LIFE_INSURANCE_ATTACHMENT_LIST_FAILED);

export const fetchLifeInsuranceAttachmentListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchLifeInsuranceAttachmentListProgress());
      const res = await api.get(
        `/policy/get-life-policy-basic-details-attachment?customer_policy_id=${customerPolicyId}`
      );
      const data: ILifeInsuranceAttachment[] = res.data.data;
      dispatch(fetchLifeInsuranceAttachmentListSuccess(data));
    } catch (err: any) {
      dispatch(fetchLifeInsuranceAttachmentListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addLifeInsuranceAttachmentListAsync =
  (
    data: ILifeInsuranceAttachment,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      await api.post(`/policy/edit-life-policy-basic-details-attachment`, data);
      dispatch(
        showMessage({
          type: "success",
          message: "Attachment saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    }
  };

export const CLAER_LIFE_INSURANCE_POLICY_ATTACHMENT_STATE =
  "CLAER_LIFE_INSURANCE_POLICY_ATTACHMENT_STATE";
export const clearLifeInsurancePolicyAttachment = () =>
  action(CLAER_LIFE_INSURANCE_POLICY_ATTACHMENT_STATE);

/*--------*/
export const FETCH_BUSINESS_ACTIVITY_LIST_PROGRESS =
  "FETCH_BUSINESS_ACTIVITY_LIST_PROGRESS";
export const FETCH_BUSINESS_ACTIVITY_LIST_SUCCESS =
  "FETCH_BUSINESS_ACTIVITY_LIST_SUCCESS";

export const fetchBusinessActivityListProgress = () =>
  action(FETCH_BUSINESS_ACTIVITY_LIST_PROGRESS);
export const fetchBusinessActivityListSuccess = (data: any) =>
  action(FETCH_BUSINESS_ACTIVITY_LIST_SUCCESS);

export const CLEAR_LIFE_INSURANCE_BASIC_DETAILS_STATE =
  "CLEAR_LIFE_INSURANCE_BASIC_DETAILS_STATE";
export const clearLifeInsuranceBasicPolicyState = () =>
  action(CLEAR_LIFE_INSURANCE_BASIC_DETAILS_STATE);
