import { LoadState } from "../../constants/enums";

export interface IMarketingState {
  list: IGroupedMarketing[];
  totalRecords: number;
  loading: LoadState;
}

export interface IGroupedMarketing extends IMarketings {
  childs: IMarketings[];
}

export interface IMarketings {
    policy_business_add_marketing_history_id: number,
    customer_policy_id: number,
    serial_number: number,
    customer_name: string | null,
    quote_number: string | null,
    quote_status: string,
    premium: null,
    submission_date: string | null,
    date_received: string | null,
    insurer: string | null,
    underwriter: string | null,
    attachment: [
      {}
    ],
    notes: [
      {}
    ],
    status: string ,
    created_by_id: number,
    insert_ts: string,
    type: string
}

export const defaultMarketingsState: IMarketingState = {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
}