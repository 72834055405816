import moment, { Moment } from "moment";
import { LoadState } from "../../../constants/enums";


export interface ITaskGaragePlateState {
    addGaragePlate: {
      data: IAddGaragePlate;
      loading: LoadState;
    },
    deleteGaragePlate: {
        data: IDeleteGaragePlate;
        loading: LoadState;
      }
}

export interface IAddGaragePlate {
    task_code: string,
    customer_code: string | null,
    customer_name: string | null,
    policy_number: number | null,
    line_of_business: string | null,
    policy_type: string | null,
    branch: string | null,
    broker_code: string | null,
    insurer: string | null,
    producer: string | null,
    csr: string | null,
    plate_number: string | null,
    plate_type: string | null,
    effective_date_of_change: string | null,
    effective_time_of_change: string,
    details: string | null,
    file_upload: string | null
}

export interface IDeleteGaragePlate {
    task_code: string,
    customer_code: string | null,
    customer_name: string | null,
    policy_number: number | null,
    line_of_business: string | null,
    policy_type: string | null,
    branch: string | null,
    broker_code: string | null,
    insurer: string | null,
    producer: string | null,
    csr: string | null,
    plate: number | null,
    plate_type: string | null,
    effective_date_of_change: string | null,
    effective_time_of_change: string,
    details: string | null,
    file_upload: string | null
}

export const initialTaskGaragePlateState: ITaskGaragePlateState = {
    addGaragePlate: {
        data: {
            task_code: "",
            customer_code: null,
            customer_name: null,
            policy_number: null,
            line_of_business: null,
            policy_type: null,
            branch: null,
            broker_code: null,
            insurer: null,
            producer: null,
            csr: null,
            plate_number: null,
            plate_type: null,
            effective_date_of_change: null,
            effective_time_of_change: moment()
            .startOf("day")
            .add(1, "minute").format(),
            details: null,
            file_upload: null
        },
        loading: LoadState.Loaded
    },
    deleteGaragePlate: {
        data: {
            task_code: "",
            customer_code: null,
            customer_name: null,
            policy_number: null,
            line_of_business: null,
            policy_type: null,
            branch: null,
            broker_code: null,
            insurer: null,
            producer: null,
            csr: null,
            plate: null,
            plate_type: null,
            effective_date_of_change: null,
            effective_time_of_change: moment()
            .startOf("day")
            .add(1, "minute").format(),
            details: null,
            file_upload: null
        },
        loading: LoadState.Loaded
    }
}