import { LoadState } from "../../../constants/enums";

export interface IAccountingTransactionsState {
  list: ITransactions[];
  listLoading: LoadState;
  data: ITransactions;
  loading: LoadState;
  error: string | null;
}

export interface ITransactions {
  customer_id: string | null;
  customer_policy_id: string | null;
  invoice_id: string | null;
  incremental_number: string | null;
  transaction_type: string | null;
  transaction_date: string | null;
  effective_date: string | null;
  transaction_amount: string | null;
  tax_1_percentage: string | null;
  tax_1_amount: string | null;
  tax_2_percentage: string | null;
  tax_2_amount: string | null;
  total_transaction_amount: string | null;
  status: string;
}

export const initialAccountingTransactionsState: IAccountingTransactionsState =
  {
    list: [],
    listLoading: LoadState.NotLoaded,
    data: {
      customer_id: null,
      customer_policy_id: null,
      invoice_id: null,
      incremental_number: null,
      transaction_type: null,
      transaction_date: null,
      effective_date: null,
      transaction_amount: null,
      tax_1_percentage: null,
      tax_1_amount: null,
      tax_2_percentage: null,
      tax_2_amount: null,
      total_transaction_amount: null,
      status: "Active",
    },
    loading: LoadState.NotLoaded,
    error: null,
  };
