import { LoadState } from "../../../constants/enums";

export interface IBillTemplateState {
  loading: LoadState;
  data: IBillTemplate;
  error: string | null;
}

export interface IBillTemplate {
  customer_id: string | null;
  customer_policy_id: string | null;
  incremental_number: string | null;
  bill_notes: string | null;
  bill_footer: string | null;
  status: string;
}

export const initialBillTemplateState: IBillTemplateState = {
  data: {
    customer_id: null,
    customer_policy_id: null,
    incremental_number: null,
    bill_notes: null,
    bill_footer: null,
    status: "ACTIVE",
  },
  loading: LoadState.NotLoaded,
  error: null,
};
