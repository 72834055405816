import { action } from "typesafe-actions";
import { IEft, initialAccountingEftState } from "./Eft.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";

export const FETCH_ACCOUNTING_EFT_PROGRESS = "FETCH_ACCOUNTING_EFT_PROGRESS";
export const FETCH_ACCOUNTING_EFT_SUCCESS = "FETCH_ACCOUNTING_EFT_SUCCESS";
export const FETCH_ACCOUNTING_EFT_FAILURE = "FETCH_ACCOUNTING_EFT_FAILURE";

export const fetchAccountingEftProgress = () =>
  action(FETCH_ACCOUNTING_EFT_PROGRESS);

export const fetchAccountingEftSuccess = (data: IEft) =>
  action(FETCH_ACCOUNTING_EFT_SUCCESS, { data });

export const fetchAccountingEftFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_EFT_FAILURE, { errorMessage });

export const fetchAccountingEftAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingEftProgress());
      const res = await api.get(`/accounting/get-account-eft`);
      const data: IEft[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAccountingEftSuccess(data[0]));
      } else {
        dispatch(fetchAccountingEftSuccess(initialAccountingEftState["data"]));
      }
    } catch (err: any) {
      dispatch(fetchAccountingEftFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingEftAysnc =
  (
    data: IEft,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/accounting/edit-account-eft", {
        ...data,
      });
      let message = "Eft saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const FETCH_ACCOUNTING_EFT_LIST_PROGRESS =
  "FETCH_ACCOUNTING_EFT_LIST_PROGRESS";
export const FETCH_ACCOUNTING_EFT_LIST_SUCCESS =
  "FETCH_ACCOUNTING_EFT_LIST_SUCCESS";
export const FETCH_ACCOUNTING_EFT_LIST_FAILURE =
  "FETCH_ACCOUNTING_EFT_LIST_FAILURE";

export const fetchAccountingEftListProgress = () =>
  action(FETCH_ACCOUNTING_EFT_LIST_PROGRESS);

export const fetchAccountingEftListSuccess = (data: IEft[]) =>
  action(FETCH_ACCOUNTING_EFT_LIST_SUCCESS, { data });

export const fetchAccountingEftListFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_EFT_LIST_FAILURE, { errorMessage });

export const fetchAccountingEftListAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingEftListProgress());
      const res = await api.get(`/accounting/get-account-eft`);
      const data: IEft[] = res.data.data;
      dispatch(fetchAccountingEftListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAccountingEftListFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_ACCOUNTING_EFT = "CLEAR_ACCOUNTING_EFT";
export const clearAccountingEft = () => action(CLEAR_ACCOUNTING_EFT);
