import { action } from "typesafe-actions";
import { IAutomobileSupplement, initialAutomobileSupplement } from "./automobileSupplement.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { LoadState } from "../../constants/enums";
import { api } from "../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";

export const FETCH_AUTOMOBILE_SUPPLEMENT_DETAILS_PROGRESS =
  "FETCH_AUTOMOBILE_SUPPLEMENT_DETAILS_PROGRESS";
export const FETCH_AUTOMOBILE_SUPPLEMENT_DETAILS_SUCCESS =
  "FETCH_AUTOMOBILE_SUPPLEMENT_DETAILS_SUCCESS";
export const FETCH_AUTOMOBILE_SUPPLEMENT_DETAILS_FAILED =
  "FETCH_AUTOMOBILE_SUPPLEMENT_DETAILS_FAILED";

export const fetchAutomobileSupplementDetailsProgress = () =>
  action(FETCH_AUTOMOBILE_SUPPLEMENT_DETAILS_PROGRESS);
export const fetchAutomobileSupplementDetailsSuccess = (
  data: IAutomobileSupplement
) =>
  action(FETCH_AUTOMOBILE_SUPPLEMENT_DETAILS_SUCCESS, {
    data,
  });
export const fetchAutomobileSupplementDetailsFailed = () =>
  action(FETCH_AUTOMOBILE_SUPPLEMENT_DETAILS_FAILED);

export const addAutomobileSupplementDetailsAsync =
  (
    data: IAutomobileSupplement,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/policy/edit-policy-commauto-all-cvs", {
        ...data,
      });

      dispatch(fetchAutomobileSupplementDetailsSuccess(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Automobile supplement saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const fetchAutomobileSupplementDetailsAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileSupplementDetailsProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-all-cvs?customer_policy_id=${customerPolicyId}`
      );
      const data : IAutomobileSupplement[] = res.data.data;
      if (data.length > 0){
        dispatch(fetchAutomobileSupplementDetailsSuccess(data[0]));
      } else {
        dispatch(
          fetchAutomobileSupplementDetailsSuccess(
            initialAutomobileSupplement["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchAutomobileSupplementDetailsFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

