import { action } from "typesafe-actions";
import {
  IAutomobileCSVDetails,
  initialAutomoblieCSV,
} from "./automobileCSV.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";

export const CLEAR_AUTOMOBILE_CSV_STATE = "CLEAR_AUTOMOBILE_CSV_STATE";

export const clearAutomobileCSVState =() => action(CLEAR_AUTOMOBILE_CSV_STATE);

export const FETCH_AUTOMOBILE_CSV_DETAILS_PROGRESS =
  "FETCH_AUTOMOBILE_CSV_DETAILS_PROGRESS";
export const FETCH_AUTOMOBILE_CSV_DETAILS_SUCCESS =
  "FETCH_AUTOMOBILE_CSV_DETAILS_SUCCESS";
export const FETCH_AUTOMOBILE_CSV_DETAILS_FAILED =
  "FETCH_AUTOMOBILE_CSV_DETAILS_FAILED";

export const fetchAutomobileCSVDetailsProgress = () =>
  action(FETCH_AUTOMOBILE_CSV_DETAILS_PROGRESS);
export const fetchAutomobileCSVDetailsSuccess = (data: IAutomobileCSVDetails) =>
  action(FETCH_AUTOMOBILE_CSV_DETAILS_SUCCESS, {data});
export const fetchAutomobileCSVDetailsFailed = () =>
  action(FETCH_AUTOMOBILE_CSV_DETAILS_FAILED);
export const fetchAutomobileCSVDetailsAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileCSVDetailsProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-cvs?customer_policy_id=${customerPolicyId}`
      );
      const data: IAutomobileCSVDetails[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileCSVDetailsSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchAutomobileCSVDetailsSuccess({
            ...initialAutomoblieCSV["details"].data,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      dispatch(fetchAutomobileCSVDetailsFailed());
    }
  };

  export const addAutomobileCSVDetailsAsync =
  (
    data: IAutomobileCSVDetails,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/policy/edit-policy-commauto-cvs", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "CSV details saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchAutomobileCSVDetailsSuccess(data));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };