import { useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../hooks";
import React from "react";
import { Grid, Typography } from "@mui/material";
import { StandardCellList } from "../../../../components/Standards";
import { formatTime } from "../../../../helpers";
import { IDeleteGaragePlate } from "../../../../redux/TaskReducer/GaragePlate/garagePlate.types";
import { fetchDeleteGaragePlateAysnc } from "../../../../redux/TaskReducer/GaragePlate/garagePlateActions";
import { LoadState } from "../../../../constants/enums";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { stripHtml } from "../../../../helpers/stripHtml";
import { QuillEditor } from "../../../../components/QuillEditor/QuillEditor";

export const ViewDeleteGaragePlate: React.FC<{
  taskCode: string;
}> = (props) => {
  const { taskCode } = props;

  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.tasks.garagePlate.deleteGaragePlate
  );

  const dispatch = useDispatchWrapper();
  const [garagePlateDetails, setGaragePlateDetails] =
    React.useState<IDeleteGaragePlate>();

  React.useEffect(() => {
    dispatch(fetchDeleteGaragePlateAysnc(taskCode));
  }, [taskCode]);

  React.useEffect(() => {
    setGaragePlateDetails(data);
  }, [data]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      {garagePlateDetails && (
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Garage Plate"
              colSize={4}
              list={[
                {
                  heading: "Plate number",
                  data: {
                    type: "text",
                    value: garagePlateDetails.plate,
                  },
                },

                {
                  heading: "Effective Date",
                  data: {
                    type: "text",
                    value: garagePlateDetails.effective_date_of_change,
                  },
                },
                {
                  heading: "Effective Time",
                  data: {
                    type: "text",
                    value: formatTime(
                      garagePlateDetails.effective_time_of_change.toString()
                    ),
                  },
                },
                {
                  heading: "Details",
                  colSize: 12,
                  data: {
                    type: "text",
                    value: stripHtml(garagePlateDetails.details || ""),
                  },
                },
              ]}
            >
              <Grid item xs={12} md={12}>
                <Typography fontWeight={500} variant="h4" mb={1}>
                  Details
                </Typography>
                <QuillEditor
                  value={garagePlateDetails.details || ""}
                  onChange={() => {}}
                  readOnly={true}
                />
              </Grid>
            </StandardCellList>
          </Grid>
        </Grid>
      )}
    </PageLoader>
  );
};
