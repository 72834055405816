import { action } from "typesafe-actions";
import {
  ITaskLooBor,
  ITaskPolicyCancellation,
  ITaskPolicyReinstment,
  initialTaskurgentTransactionsState,
} from "./taskUrgentTransactions.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { uploadFile } from "../../helpers";

export const FETCH_TASK_POLICY_CANCELLATION_PROGRESS =
  "FETCH_TASK_POLICY_CANCELLATION_PROGRESS";
export const FETCH_TASK_POLICY_CANCELLATION_SUCCESS =
  "FETCH_TASK_POLICY_CANCELLATION_SUCCESS";
export const FETCH_TASK_POLICY_CANCELLATION_FAILED =
  "FETCH_TASK_POLICY_CANCELLATION_FAILED";

export const fetchTaskPolicyCancellationProgress = () =>
  action(FETCH_TASK_POLICY_CANCELLATION_PROGRESS);
export const fetchTaskPolicyCancellationSuccess = (
  data: ITaskPolicyCancellation
) => action(FETCH_TASK_POLICY_CANCELLATION_SUCCESS, { data });
export const fetchTaskPolicyCancellationFailed = () =>
  action(FETCH_TASK_POLICY_CANCELLATION_FAILED);

export const fetchTaskPolicyCancellationAysnc =
  (taskCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTaskPolicyCancellationProgress());
      const res = await api.get(
        `/tasks/get-tasks-policy-cancellation?task_code=${taskCode}`
      );
      const data: ITaskPolicyCancellation[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchTaskPolicyCancellationSuccess(data[0]));
      } else {
        dispatch(
          fetchTaskPolicyCancellationSuccess(
            initialTaskurgentTransactionsState.policyCancellation["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchTaskPolicyCancellationFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskPolicyCancellationAysnc =
  (
    data: ITaskPolicyCancellation,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/tasks/create-tasks-policy-cancellation", {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Policy cancellation saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_TASK_POLICY_REINSTMENT_PROGRESS =
  "FETCH_TASK_POLICY_REINSTMENT_PROGRESS";
export const FETCH_TASK_POLICY_REINSTMENT_SUCCESS =
  "FETCH_TASK_POLICY_REINSTMENT_SUCCESS";
export const FETCH_TASK_POLICY_REINSTMENT_FAILED =
  "FETCH_TASK_POLICY_REINSTMENT_FAILED";

export const fetchTaskPolicyReinstmentProgress = () =>
  action(FETCH_TASK_POLICY_REINSTMENT_PROGRESS);
export const fetchTaskPolicyReinstmentSuccess = (data: ITaskPolicyReinstment) =>
  action(FETCH_TASK_POLICY_REINSTMENT_SUCCESS, { data });
export const fetchTaskPolicyReinstmentFailed = () =>
  action(FETCH_TASK_POLICY_REINSTMENT_FAILED);

export const fetchTaskPolicyReinstmentAysnc =
  (taskCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTaskPolicyReinstmentProgress());
      const res = await api.get(
        `/tasks/get-tasks-policy-reinstatement?task_code=${taskCode}`
      );
      const data: ITaskPolicyReinstment[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchTaskPolicyReinstmentSuccess(data[0]));
      } else {
        dispatch(
          fetchTaskPolicyReinstmentSuccess(
            initialTaskurgentTransactionsState.policyReinstment["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchTaskPolicyReinstmentFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskPolicyReinstmentAysnc =
  (
    data: ITaskPolicyReinstment,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/tasks/create-tasks-policy-reinstatement", {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Policy reinstment saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_TASK_POLICY_LAO_PROGRESS = "FETCH_TASK_POLICY_LAO_PROGRESS";
export const FETCH_TASK_POLICY_LAO_SUCCESS = "FETCH_TASK_POLICY_LAO_SUCCESS";
export const FETCH_TASK_POLICY_LAO_FAILED = "FETCH_TASK_POLICY_LAO_FAILED";

export const fetchTaskLAOProgress = () =>
  action(FETCH_TASK_POLICY_LAO_PROGRESS);
export const fetchTaskLAOSuccess = (data: ITaskLooBor) =>
  action(FETCH_TASK_POLICY_LAO_SUCCESS, { data });
export const fetchTaskLAOFailed = () => action(FETCH_TASK_POLICY_LAO_FAILED);

export const fetchTaskLAOAysnc =
  (taskCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTaskLAOProgress());
      const res = await api.get(
        `/tasks/get-task-lao-bor?task_code=${taskCode}`
      );
      const data: ITaskLooBor[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchTaskLAOSuccess(data[0]));
      } else {
        dispatch(
          fetchTaskLAOSuccess(initialTaskurgentTransactionsState.loa["data"])
        );
      }
    } catch (err: any) {
      dispatch(fetchTaskLAOFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskLaoAysnc =
  (
    data: ITaskLooBor,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/tasks/upsert-task-lao-bor", {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "LAO/BOR saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_TASK_URGENT_TRANSACTIONS = "CLEAR_TASK_URGENT_TRANSACTIONS";

export const clearTaskUrgentTransactions = () =>
  action(CLEAR_TASK_URGENT_TRANSACTIONS);
