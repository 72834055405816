import React from "react";
import {
  IDataTableProps,
  RenderType,
} from "../../components/Table/interfaces/IDataTableProps";
import { useSelector } from "react-redux";
import { IStoreState } from "../../redux/initialStoreState";
import { ITableTab } from "../../components/Table/components/TableTabs/TableTabs.types";
import { useDispatchWrapper, useRecordCounts } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { useDateFilter } from "../../components/Table/hooks/useDateFilter";
import { LoadState, MODULE_IDS } from "../../constants/enums";
import { DateFilterDropdown } from "../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { CustomDatePicker } from "../../components/formsComponents/CustomDatePicker";
import { RoleBasedCSVExportPlugin } from "../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { RefreshPlugin, SearchPlugin } from "../../components/Table/plugins";
import { ICSIOEDocs } from "../../redux/csioEdocs/csioEdocs.types";
import { StandardTableActions } from "../../components/StandardTableActions/StandardTableActions";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
} from "../../redux/messages/messagesActions";
import { downLoadFile, previewFile } from "../../components/FileUpload/utils";
import { StatusRenderer } from "../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { CustomLink } from "../../components/CustomLink/CustomLink";
import {  processArguments } from "../../helpers";
import { RoleBasedEmailRightPanel } from "../../security/RoleBasedComponents/RoleBasedEmailRightPanel/RoleBasedEmailRightPanel";
import { fetchCsioEdocsListAsync } from "../../redux/csioEdocs/csioEdocsActions";
import { DataTable } from "../../components/Table/DataTable";
import { ICSIORaw } from "../../redux/csio/csio.types";
import { clearCSIOState, fetchCSIOListAsync } from "../../redux/csio/csioActions";
import { CustomSelect } from "../../components/formsComponents";
import { Button, MenuItem } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { DataTableV2RowRenderType, IDataTableV2Props } from "../../components/TableV2/interfaces/IDataTableV2Props";
import { useTableV2State } from "../../components/TableV2/hooks/useTableV2State";
import { DataTableV2 } from "../../components/TableV2/DataTableV2";

interface ICSIOEDocsTableProps {
  customerPolicyId?: number;
}

export const CSIODocsTableList: React.FC<ICSIOEDocsTableProps> = ({customerPolicyId}) => {
  const { list, loading, totalRecords } = useSelector(
    (storeState: IStoreState) => storeState.csio
  );
  const dispatch = useDispatchWrapper();
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });

  const [openCSIO, setOpenCSIO] = React.useState<ICSIORaw | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [openMap, setOpenMap] = React.useState<boolean>(false);

  
  const [messageType, setMessageType] = React.useState<string | "-1">("-1");
  const navigate = useNavigate();

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "last28Days",
      selectedTab: "-1",
    },
  });

  const fetchList = () => {
    let mapvalue;
    if (tabs.selectedTab === "-1") {
      mapvalue = "-1";
    } else if (tabs.selectedTab === "Matched") {
      mapvalue = "1";
    } else {
      mapvalue = "0";
    }
    dispatch(
      fetchCSIOListAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        dateState.dates,
        searchState,
        mapvalue,
        messageType,
        customerPolicyId
      )
    );
  };
  React.useEffect(() => {
    fetchList();
  }, [dateState.dates, tablePagination, searchState, tabs.selectedTab, messageType]);

  const csioTableProps: IDataTableV2Props = {
    isPagination: true,
    isDataLoading: loading === LoadState.InProgress,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    tableCommandBarProps: {
      preDefinedPlugins: {
        dateFilter: {
          state: dateState,
          onChange: setDateState,
        },
        search: {
          state: searchState,
          onChange: setSearchState,
        },
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {
        customPlugins: [
          {
            key: "cl",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={messageType}
                onChange={(e) => setMessageType(e.target.value as string)}
              >
                <MenuItem value={"-1"}>{"All Message Types"}</MenuItem>;
                <MenuItem value="EDI">EDI</MenuItem>
                <MenuItem value="XML">XML</MenuItem>
                <MenuItem value="eDOC">eDOC</MenuItem>
              </CustomSelect>
            ),
          },
        ],
      },
      rightItems: {
        customPlugins: [],
      },
    },
    totalRecords: totalRecords,
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        isFirstColumnSticky: true,
        onRowCellRender: (value, row) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(`/manage-csio-tab/${row.message_guid}`);
              }}
            />
          );
        },
      },
      {
        key: "csio",
        headerName: "",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 20,
        enableSorting: false,

        onRowCellRender: (values, row) => {
          return (
            <Button variant="text" onClick={() => setOpenCSIO(row)}>
              View XML & Map
            </Button>
          );
        },
      },
      {
        key: "edocs",
        headerName: "",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 20,
        enableSorting: false,

        onRowCellRender: (values, row) => {
          return (
            <CustomLink
              to={`/csio-edocs-list/manage-csio-edoc/${row.message_guid}`}
              label={"View Edocs"}
            />
          );
        },
      },
      {
        key: "MessageGUID",
        headerName: "Message ID",
        fieldName: "message_guid",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 15,
        enableSorting: true,
        onRowCellRender: (value, row) => {
          return (
            <CustomLink
              to={"/manage-csio-tab/" + row.message_guid}
              label={row.message_guid}
            />
          );
        },
      },
      {
        key: "MessageType",
        headerName: "Message Type",
        fieldName: "message_type",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 15,
      },
      {
        key: "is_map",
        headerName: "Map",
        fieldName: "is_map",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 15,
        onRowCellRender: (value, row) => {
          return row.is_map === 1 ? (
            <CheckCircleRoundedIcon fontSize="medium" color="success" />
          ) : (
            <CancelIcon fontSize="medium" color="error" />
          );
        },
      },
      {
        key: "FromEmailAddress",
        headerName: "From Email Address",
        fieldName: "from_email_address",
        enableSorting: false,
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 25,
      },
      {
        key: "DateTime",
        headerName: "Date and Time",
        fieldName: "create_ts",
        renderType: DataTableV2RowRenderType.DATE_TIME,
        // exportCellWidth: 20,
        enableSorting: false,
      },
      {
        key: "CreatedDate",
        headerName: "Created Date",
        fieldName: "insert_ts",
        renderType: DataTableV2RowRenderType.DATE,
        // exportCellWidth: 20,
        enableSorting: false,
      },
    ],
    rows: list,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
    tableTabProps: {
      selectedTab: tabs.selectedTab,
      tabs: tabs.tabs,
      onTabChange: (newSelectedTab) => {
        setSelectedTab(newSelectedTab);
      },
    },
  };

  const handleFetch = () => {
    setOpenMap((prev) => !prev);
  };

  const handleMapping = () => {
    setOpen((prev) => !prev);
  };
  React.useEffect(() => {
    return () => {
      dispatch(clearCSIOState());
    };
  }, []);


  return <>
  <DataTableV2 {...csioTableProps} />
  </>;
};
