import moment from "moment";
import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface IHabitationalPolicyChangeState {
  list: IHabitationalPolicyChangeReq[];
  policyChangeData: IHabitationalPolicyChangeReq;
  totalRecords: number;
  loading: LoadState;
  error: string | null;
}

export interface IHabitationalRiskAddress {
  key: string;
  loc_no: string;
  address: string;
}
export interface IHabitationalLossPayee {
  key: string;
  loc_no: string;
  address: string;
  rank: string;
  nature_of_interest: string;
}
export interface IHabitationalOccupancy {
  key: string;
  occupancy: string | null;
  occupancy_value: string;
}
export interface IHabitationalStructureType {
  key: string;
  structure_type: string;
  structure_value: string;
}
export interface IHabitationalOutbuildings {
  key: string;
  outbuilding: string;
  outbuilding_value: string;
}
export interface IHabitationalConstruction {
  key: string;
  construction: string;
  construction_value: string;
}

export interface IHabitationalCoverageFormTable {
  key: string;
  single_limit: string;
  dwelling_building: string;
  detached_private: string;
  personal_property: string;
  additional_living: string;
  legal_liability: string;
  voluntary_medical: string;
  voluntary_property: string;
}
export interface IHabitationalAdditionalCoverage {
  key: string;
  action: string;
  coverage_code: string;
  coverage_description: string;
  limit: string;
  deductible: string;
  deductible_type: string;
  type_of: string;
}
export interface IHabitationalLiabilityExtension {
  key: string;
  action: string;
  code: string;
  liability_coverage_discription: string;
  number_of: string;
  type_of_one: string;
  type_of_two: string;
}
export interface IHabitationalSchedulePersonal {
  key: string;
  action: string;
  hash: number;
  class_code: string;
  description: string;
  coverage_code: string;
  type_of_one: string;
  type_of_two: string;
  purchase: string;
  appraisal_date: string;
  deductible: string;
  disc_applicable: string;
  amount_of_insurance: string;
}
export interface IHabitationalWaterCraftTrailerOne {
  key: string;
  action: string;
  year: string;
  description_make_model: string;
  coverage: string;
  deductible: string;
  identification_serial_number: string;
}
export interface IHabitationalWaterCraftTrailerTwo {
  key: string;
  action: string;
  type: string;
  style: string;
  construction: string;
  length: string;
  horse_power: string;
  purchase_date: string;
  new_or_used: string;
  purchase_price: string;
  replacement_cost: string;
  value_contents_purchase_price: string;
  value_of_non_standard: string;
  maximum_speed: string;
}
export interface IHabitationalDiscountAndSurcharges {
  key: string;
  code: string;
  discount_surcharge_discription: string;
  percentage: string;
  premium_applied_base_one: string;
  premium_applied_base_two: string;
}
export interface IHabitationalHeating {
  key: string;
  heating: string | null;
  fuel: string;
  primary: string;
  auxiliary: string;
  isTypeOther: boolean;
}
export interface IHabitationalAttachment {
  key: string;
  attachments: string | null;
  remarks: string;
  yes_no: string;
  code: string;
  isTypeOther: boolean;
}
export interface IHabitatioanalUpgrades {
  key: string;
  upgrade_name: string;
  full: string | null;
  partial: string | null;
  year: string | null;
}

export interface IHabitationalPolicyChangeReq {
  customer_policy_id: number | null;
  incremental_number: number | null;
  policy_number: string | null;
  insurance_company: string | null;
  policy_type: string | null;
  language_preferred: string | null;
  broker: {
    broker_agent: string | null;
    broker_agent_address: string | null;
    broker_agent_unit_or_suite: string | null;
    broker_agent_city: string | null;
    broker_agent_state: string | null;
    broker_agent_country: string | null;
    broker_agent_zip: string | null;
    broker_contract_number: string | null;
    broker_sub_contract_number: string | null;
    broker_contact_number: string | null;
    broker_home: string | null;
    broker_cell: string | null;
    broker_business: string | null;
    broker_fax: string | null;
    group_programme: string | null;
    broker_client_id: string | null;
    group_id: string | null;
    company_client_id: string | null;
  };
  applicant_details: {
    name: string | null;
    address: string | null;
    address_two: string | null;
    country: string | null;
    city: string | null;
    province_or_state: string | null;
    postalcode: string | null;
    contact_number: string | null;
    home: string | null;
    cell: string | null;
    business: string | null;
    fax: string | null;
    email: string | null;
    website: string | null;
  };
  policy_period_effective_date: string | null;
  policy_period_effective_time: string | null;
  policy_period_expiry_date: string | null;
  policy_period_expiry_time: string | null;
  name_changed: string | null;
  address_changed: string | null;

  risk_address_checkbox: string | null;
  risk_address: IHabitationalRiskAddress[];

  loss_payee_checkbox: string | null;
  loss_payee: IHabitationalLossPayee[];

  year_built: string | null;
  of_storeys: string | null;
  of_families: string | null;
  of_units: string | null;
  groung_floor_area: string | null;
  sqft_sqm: string | null;

  occupancy: string | null;
  structure_type: string | null;
  outbuilding: {
    no_of: string | null;
    use: string | null;
    const: string | null;
    heat: string | null;
    value: string | null;
  };

  construction: string | null;

  fire_protection: {
    distance_to_hydrant: string | null;
    hydrant_type: string | null;
    distance_to_responding_fire_hall: string | null;
    fire_hall_name: string | null;
  };

  oil_tank: {
    year: string | null;
    type: string | null;
  };

  heating_type: IHabitationalHeating[];
  solid_heating_unit: string | null;
  professional_installation: string | null;
  heating_unit_ulc_csa_approved: string | null;
  heating_area_sqm: string | null;
  make: string | null;
  year: string | null;
  no_face_cords_per_year: string | null;
  attachments_type: IHabitationalAttachment[];
  security_system: {
    fire: string | null;
    burglary: string | null;
    security_attendant: string | null;
    sprinkler: string | null;
    smoke_detectors: string | null;
    smoke_detector_type: string | null;
    no_of_detectors: string | null;
    if_any_of_the_above_are_monitored_monitored_by: string | null;
    home_sprinklered: string | null;
    alarm_certificate: string | null;
    premises_type_security_system: string | null;
  };

  renovation_upgrade: IHabitatioanalUpgrades[];
  copper: number | null;
  plastic: number | null;
  other: number | null;
  type: string | null;
  amps: number | null;
  amps_options: string | null;

  swimming_pool: {
    year: string | null;
    pool_type: string | null;
    pool_fenced: string | null;
  };

  coverag_forms: {
    package_form: string | null;
    rating_plan: string | null;
    deductible: string | null;
    coverag_form_table: IHabitationalCoverageFormTable[];
  };
  additional_coverage: IHabitationalAdditionalCoverage[];
  liability_exposures: {
    do_you_own_rent_more_than_one_location: string | null;
    no_of_saddle_draft_animals: string | null;
    if_yes_are_any_location_rented_to_others: string | null;
    is_there_any_kind_of_business_operation: string | null;
    is_there_a_co_occupant_that_requires_coverage: string | null;
    if_yes_describe_business: string | null;
    co_occupant_name: string | null;
    is_there_any_non_family_member_working_as_a_staff: string | null;
    do_you_rent_rooms_to_others: string | null;
    number_of_dogs_in_the_household: string | null;
    breed_of_dogs: string | null;
    is_there_a_daycare_operation: string | null;
    other_exposures: null;
  };
  liability_extensions: IHabitationalLiabilityExtension[];
  discount_and_surcharges: IHabitationalDiscountAndSurcharges[];
  schedule_personal: IHabitationalSchedulePersonal[];
  stand_alone_watercraft_policy: boolean;
  watercraft_and_trailer_one: IHabitationalWaterCraftTrailerOne[];
  watercraft_and_trailer_two: IHabitationalWaterCraftTrailerTwo[];

  remarks: string | null;
  status: string;
}

export const initialHabitationalPolicyChangeReqState: IHabitationalPolicyChangeState =
  {
    policyChangeData: {
      customer_policy_id: null,
      incremental_number: null,
      policy_number: null,
      insurance_company: null,
      policy_type: null,
      language_preferred: null,
      broker: {
        broker_agent: null,
        broker_agent_address: null,
        broker_agent_unit_or_suite: null,
        broker_agent_city: null,
        broker_agent_state: null,
        broker_agent_country: null,
        broker_agent_zip: null,
        broker_contract_number: null,
        broker_sub_contract_number: null,
        broker_contact_number: null,
        broker_home: null,
        broker_cell: null,
        broker_business: null,
        broker_fax: null,
        group_programme: null,
        group_id: null,
        broker_client_id: null,
        company_client_id: null,
      },
      applicant_details: {
        name: null,
        address: null,
        address_two: null,
        country: null,
        city: null,
        province_or_state: null,
        postalcode: null,
        contact_number: null,
        home: null,
        cell: null,
        business: null,
        fax: null,
        email: null,
        website: null,
      },
      policy_period_effective_date: null,
      policy_period_effective_time: null,
      policy_period_expiry_date: null,
      policy_period_expiry_time: null,
      name_changed: null,
      address_changed: null,

      risk_address_checkbox: null,
      risk_address: [
        {
          key: getUniqueId(),
          loc_no: "",
          address: "",
        },
      ],
      loss_payee_checkbox: null,
      loss_payee: [
        {
          key: getUniqueId(),
          loc_no: "",
          address: "",
          rank: "",
          nature_of_interest: "",
        },
      ],
      year_built: null,
      of_storeys: null,
      of_families: null,
      of_units: null,
      groung_floor_area: null,
      sqft_sqm: null,
      occupancy: null,
      structure_type: null,
      construction: null,
      outbuilding: {
        no_of: null,
        use: null,
        const: null,
        heat: null,
        value: null,
      },
      fire_protection: {
        distance_to_hydrant: null,
        hydrant_type: null,
        distance_to_responding_fire_hall: null,
        fire_hall_name: null,
      },
      oil_tank: {
        year: null,
        type: null,
      },
      heating_type: [
        {
          key: getUniqueId(),
          heating: "Furnace (Central)",
          fuel: "",
          primary: "",
          auxiliary: "",
          isTypeOther: false,
        },
        {
          key: getUniqueId(),
          heating: "Combination with Wood",
          fuel: "",
          primary: "",
          auxiliary: "",
          isTypeOther: false,
        },
        {
          key: getUniqueId(),
          heating: "Combination Wood/Oil",
          fuel: "",
          primary: "",
          auxiliary: "",
          isTypeOther: false,
        },
        {
          key: getUniqueId(),
          heating: "Combination without Wood",
          fuel: "",
          primary: "",
          auxiliary: "",
          isTypeOther: false,
        },
        {
          key: getUniqueId(),
          heating: "Furnace (Central) with Add-On WoodBurning Unit",
          fuel: "",
          primary: "",
          auxiliary: "",
          isTypeOther: false,
        },
        {
          key: getUniqueId(),
          heating: "Heat Pump",
          fuel: "",
          primary: "",
          auxiliary: "",
          isTypeOther: false,
        },
        {
          key: getUniqueId(),
          heating: "Space Heater",
          fuel: "",
          primary: "",
          auxiliary: "",
          isTypeOther: false,
        },
        {
          key: getUniqueId(),
          heating: "Electric",
          fuel: "",
          primary: "",
          auxiliary: "",
          isTypeOther: false,
        },
        {
          key: getUniqueId(),
          heating: "Wall Furnace",
          fuel: "",
          primary: "",
          auxiliary: "",
          isTypeOther: false,
        },
        {
          key: getUniqueId(),
          heating: "Floor Furnace",
          fuel: "",
          primary: "",
          auxiliary: "",
          isTypeOther: false,
        },
        {
          key: getUniqueId(),
          heating: "Fireplace Insert",
          fuel: "",
          primary: "",
          auxiliary: "",
          isTypeOther: false,
        },
        {
          key: getUniqueId(),
          heating: "Radiant Floor",
          fuel: "",
          primary: "",
          auxiliary: "",
          isTypeOther: false,
        },
        {
          key: getUniqueId(),
          heating: "Electric Radiant Heat Ceiling",
          fuel: "",
          primary: "",
          auxiliary: "",
          isTypeOther: false,
        },
        {
          key: getUniqueId(),
          heating: null,
          fuel: "",
          primary: "",
          auxiliary: "",
          isTypeOther: true,
        },
      ],
      solid_heating_unit: null,
      professional_installation: null,
      heating_unit_ulc_csa_approved: null,
      heating_area_sqm: null,
      make: null,
      year: null,
      no_face_cords_per_year: null,
      attachments_type: [
        {
          key: getUniqueId(),
          attachments: "Solic Fuel Questionnaire",
          remarks: "",
          yes_no: "",
          code: "",
          isTypeOther: false,
        },
        {
          key: getUniqueId(),
          attachments: "Dwelling Evaluator",
          remarks: "",
          yes_no: "",
          code: "",
          isTypeOther: false,
        },
        {
          key: getUniqueId(),
          attachments: "",
          remarks: "",
          yes_no: "",
          code: "",
          isTypeOther: true,
        },
        {
          key: getUniqueId(),
          attachments: "",
          remarks: "",
          yes_no: "",
          code: "",
          isTypeOther: true,
        },
      ],
      security_system: {
        fire: null,
        burglary: null,
        security_attendant: null,
        sprinkler: null,
        smoke_detectors: null,
        smoke_detector_type: null,
        no_of_detectors: null,
        if_any_of_the_above_are_monitored_monitored_by: null,
        home_sprinklered: null,
        alarm_certificate: null,
        premises_type_security_system: null,
      },

      renovation_upgrade: [
        {
          key: getUniqueId(),
          upgrade_name: "Electrical",
          full: "",
          partial: "",
          year: "",
        },
        {
          key: getUniqueId(),
          upgrade_name: "Heating",
          full: "",
          partial: "",
          year: "",
        },
        {
          key: getUniqueId(),
          upgrade_name: "Plumbing",
          full: "",
          partial: "",
          year: "",
        },
        {
          key: getUniqueId(),
          upgrade_name: "Roofing",
          full: "",
          partial: "",
          year: "",
        },
      ],
      copper: null,
      plastic: null,
      other: null,
      type: null,
      amps: null,
      amps_options: null,

      swimming_pool: {
        year: null,
        pool_type: null,
        pool_fenced: null,
      },
      coverag_forms: {
        package_form: "",
        rating_plan: "",
        deductible: "",
        coverag_form_table: [
          {
            key: getUniqueId(),
            single_limit: "",
            dwelling_building: "",
            detached_private: "",
            personal_property: "",
            additional_living: "",
            legal_liability: "",
            voluntary_medical: "",
            voluntary_property: "",
          },
        ],
      },
      additional_coverage: [
        {
          key: getUniqueId(),
          action: "",
          coverage_code: "",
          coverage_description: "",
          limit: "",
          deductible: "",
          deductible_type: "",
          type_of: "",
        },
        {
          key: getUniqueId(),
          action: "",
          coverage_code: "",
          coverage_description: "",
          limit: "",
          deductible: "",
          deductible_type: "",
          type_of: "",
        },
        {
          key: getUniqueId(),
          action: "",
          coverage_code: "",
          coverage_description: "",
          limit: "",
          deductible: "",
          deductible_type: "",
          type_of: "",
        },
        {
          key: getUniqueId(),
          action: "",
          coverage_code: "",
          coverage_description: "",
          limit: "",
          deductible: "",
          deductible_type: "",
          type_of: "",
        },
        {
          key: getUniqueId(),
          action: "",
          coverage_code: "",
          coverage_description: "",
          limit: "",
          deductible: "",
          deductible_type: "",
          type_of: "",
        },
        {
          key: getUniqueId(),
          action: "",
          coverage_code: "",
          coverage_description: "",
          limit: "",
          deductible: "",
          deductible_type: "",
          type_of: "",
        },
        {
          key: getUniqueId(),
          action: "",
          coverage_code: "",
          coverage_description: "",
          limit: "",
          deductible: "",
          deductible_type: "",
          type_of: "",
        },
      ],
      liability_exposures: {
        do_you_own_rent_more_than_one_location: null,
        no_of_saddle_draft_animals: null,
        if_yes_are_any_location_rented_to_others: null,
        is_there_any_kind_of_business_operation: null,
        is_there_a_co_occupant_that_requires_coverage: null,
        if_yes_describe_business: null,
        co_occupant_name: null,
        is_there_any_non_family_member_working_as_a_staff: null,
        do_you_rent_rooms_to_others: null,
        number_of_dogs_in_the_household: null,
        breed_of_dogs: null,
        is_there_a_daycare_operation: null,
        other_exposures: null,
      },

      liability_extensions: [
        {
          key: getUniqueId(),
          action: "",
          code: "",
          liability_coverage_discription: "",
          number_of: "",
          type_of_one: "",
          type_of_two: "",
        },
        {
          key: getUniqueId(),
          action: "",
          code: "",
          liability_coverage_discription: "",
          number_of: "",
          type_of_one: "",
          type_of_two: "",
        },
        {
          key: getUniqueId(),
          action: "",
          code: "",
          liability_coverage_discription: "",
          number_of: "",
          type_of_one: "",
          type_of_two: "",
        },
        {
          key: getUniqueId(),
          action: "",
          code: "",
          liability_coverage_discription: "",
          number_of: "",
          type_of_one: "",
          type_of_two: "",
        },
        {
          key: getUniqueId(),
          action: "",
          code: "",
          liability_coverage_discription: "",
          number_of: "",
          type_of_one: "",
          type_of_two: "",
        },
        {
          key: getUniqueId(),
          action: "",
          code: "",
          liability_coverage_discription: "",
          number_of: "",
          type_of_one: "",
          type_of_two: "",
        },
        {
          key: getUniqueId(),
          action: "",
          code: "",
          liability_coverage_discription: "",
          number_of: "",
          type_of_one: "",
          type_of_two: "",
        },
        {
          key: getUniqueId(),
          action: "",
          code: "",
          liability_coverage_discription: "",
          number_of: "",
          type_of_one: "",
          type_of_two: "",
        },
        {
          key: getUniqueId(),
          action: "",
          code: "",
          liability_coverage_discription: "",
          number_of: "",
          type_of_one: "",
          type_of_two: "",
        },
        {
          key: getUniqueId(),
          action: "",
          code: "",
          liability_coverage_discription: "",
          number_of: "",
          type_of_one: "",
          type_of_two: "",
        },
        {
          key: getUniqueId(),
          action: "",
          code: "",
          liability_coverage_discription: "",
          number_of: "",
          type_of_one: "",
          type_of_two: "",
        },
        {
          key: getUniqueId(),
          action: "",
          code: "",
          liability_coverage_discription: "",
          number_of: "",
          type_of_one: "",
          type_of_two: "",
        },
        {
          key: getUniqueId(),
          action: "",
          code: "",
          liability_coverage_discription: "",
          number_of: "",
          type_of_one: "",
          type_of_two: "",
        },
        {
          key: getUniqueId(),
          action: "",
          code: "",
          liability_coverage_discription: "",
          number_of: "",
          type_of_one: "",
          type_of_two: "",
        },
      ],
      discount_and_surcharges: [
        {
          key: getUniqueId(),
          code: "DISMG",
          discount_surcharge_discription: "MORTGAGE FREE",
          percentage: "",
          premium_applied_base_one: "",
          premium_applied_base_two: "",
        },
        {
          key: getUniqueId(),
          code: "DISNH",
          discount_surcharge_discription: "NEW HOME",
          percentage: "",
          premium_applied_base_one: "",
          premium_applied_base_two: "",
        },
        {
          key: getUniqueId(),
          code: "DISMI",
          discount_surcharge_discription: "MATURE CITIZEN",
          percentage: "",
          premium_applied_base_one: "",
          premium_applied_base_two: "",
        },
        {
          key: getUniqueId(),
          code: "DISNC",
          discount_surcharge_discription: "NO CLAIMS",
          percentage: "",
          premium_applied_base_one: "",
          premium_applied_base_two: "",
        },
        {
          key: getUniqueId(),
          code: "DISMP",
          discount_surcharge_discription: "MULTI LINE",
          percentage: "",
          premium_applied_base_one: "",
          premium_applied_base_two: "",
        },
        {
          key: getUniqueId(),
          code: "DISSC",
          discount_surcharge_discription: "SENIOR CITIZEN (AGE RELATED)",
          percentage: "",
          premium_applied_base_one: "",
          premium_applied_base_two: "",
        },
        {
          key: getUniqueId(),
          code: "DISVC",
          discount_surcharge_discription: "VALUED/PREFERRED CUSTOMER",
          percentage: "",
          premium_applied_base_one: "",
          premium_applied_base_two: "",
        },
        {
          key: getUniqueId(),
          code: "DISNS",
          discount_surcharge_discription: "NON SMOKER",
          percentage: "",
          premium_applied_base_one: "",
          premium_applied_base_two: "",
        },
        {
          key: getUniqueId(),
          code: "DISAL",
          discount_surcharge_discription: "ALARM DISCOUNT",
          percentage: "",
          premium_applied_base_one: "",
          premium_applied_base_two: "",
        },
        {
          key: getUniqueId(),
          code: "DISOC",
          discount_surcharge_discription: "OCCUPATION",
          percentage: "",
          premium_applied_base_one: "",
          premium_applied_base_two: "",
        },
        {
          key: getUniqueId(),
          code: "DISRD",
          discount_surcharge_discription: "RETIREE",
          percentage: "",
          premium_applied_base_one: "",
          premium_applied_base_two: "",
        },
        {
          key: getUniqueId(),
          code: "SURHE",
          discount_surcharge_discription: "PRIMARY HEATING",
          percentage: "",
          premium_applied_base_one: "",
          premium_applied_base_two: "",
        },
        {
          key: getUniqueId(),
          code: "SURAU",
          discount_surcharge_discription: "SECONDARY AUXILIARY HEATING",
          percentage: "",
          premium_applied_base_one: "",
          premium_applied_base_two: "",
        },
        {
          key: getUniqueId(),
          code: "SURCL",
          discount_surcharge_discription: "CLAIMS",
          percentage: "",
          premium_applied_base_one: "",
          premium_applied_base_two: "",
        },
      ],
      schedule_personal: [
        {
          key: getUniqueId(),
          action: "",
          hash: 1,
          class_code: "",
          description: "",
          coverage_code: "",
          type_of_one: "",
          type_of_two: "",
          purchase: "",
          appraisal_date: "",
          deductible: "",
          disc_applicable: "",
          amount_of_insurance: "",
        },
        {
          key: getUniqueId(),
          action: "",
          hash: 2,
          class_code: "",
          description: "",
          coverage_code: "",
          type_of_one: "",
          type_of_two: "",
          purchase: "",
          appraisal_date: "",
          deductible: "",
          disc_applicable: "",
          amount_of_insurance: "",
        },
        {
          key: getUniqueId(),
          action: "",
          hash: 3,
          class_code: "",
          description: "",
          coverage_code: "",
          type_of_one: "",
          type_of_two: "",
          purchase: "",
          appraisal_date: "",
          deductible: "",
          disc_applicable: "",
          amount_of_insurance: "",
        },
      ],
      stand_alone_watercraft_policy: false,
      watercraft_and_trailer_one: [
        {
          key: getUniqueId(),
          action: "",
          year: "",
          description_make_model: "",
          coverage: "",
          deductible: "",
          identification_serial_number: "",
        },
        {
          key: getUniqueId(),
          action: "",
          year: "",
          description_make_model: "",
          coverage: "",
          deductible: "",
          identification_serial_number: "",
        },
        {
          key: getUniqueId(),
          action: "",
          year: "",
          description_make_model: "",
          coverage: "",
          deductible: "",
          identification_serial_number: "",
        },
      ],
      watercraft_and_trailer_two: [
        {
          key: getUniqueId(),
          action: "",
          type: "",
          style: "",
          construction: "",
          length: "",
          horse_power: "",
          purchase_date: "",
          new_or_used: "",
          purchase_price: "",
          replacement_cost: "",
          value_contents_purchase_price: "",
          value_of_non_standard: "",
          maximum_speed: "",
        },
        {
          key: getUniqueId(),
          action: "",
          type: "",
          style: "",
          construction: "",
          length: "",
          horse_power: "",
          purchase_date: "",
          new_or_used: "",
          purchase_price: "",
          replacement_cost: "",
          value_contents_purchase_price: "",
          value_of_non_standard: "",
          maximum_speed: "",
        },
        {
          key: getUniqueId(),
          action: "",
          type: "",
          style: "",
          construction: "",
          length: "",
          horse_power: "",
          purchase_date: "",
          new_or_used: "",
          purchase_price: "",
          replacement_cost: "",
          value_contents_purchase_price: "",
          value_of_non_standard: "",
          maximum_speed: "",
        },
        {
          key: getUniqueId(),
          action: "",
          type: "",
          style: "",
          construction: "",
          length: "",
          horse_power: "",
          purchase_date: "",
          new_or_used: "",
          purchase_price: "",
          replacement_cost: "",
          value_contents_purchase_price: "",
          value_of_non_standard: "",
          maximum_speed: "",
        },
      ],
      remarks: null,
      status: "ACTIVE",
    },
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  };
