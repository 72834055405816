import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialHabitationalNonCsioBinderState } from "./habitationalNonCsioBinder.types";
import { HabitationalNonCsioBinderAction } from ".";
import { CLEAR_HABITATIONAL_NON_CSIO_BINDER, CLEAR_HABITATIONAL_NON_CSIO_BINDER_STATE, FETCH_HABITATIONAL_NON_CSIO_BINDER_FAILED, FETCH_HABITATIONAL_NON_CSIO_BINDER_LIST_FAILED, FETCH_HABITATIONAL_NON_CSIO_BINDER_LIST_PROGRESS, FETCH_HABITATIONAL_NON_CSIO_BINDER_LIST_SUCCESS, FETCH_HABITATIONAL_NON_CSIO_BINDER_PROGRESS, FETCH_HABITATIONAL_NON_CSIO_BINDER_SUCCESS } from "./habitationalNonCsioBinderActions";

export const HabitationalNonCsioBinderReducer = (
  state: IStoreState["habitational"]["nonCsioBinder"] = initialHabitationalNonCsioBinderState,
  action: HabitationalNonCsioBinderAction
) => {
  switch (action.type) {
    case FETCH_HABITATIONAL_NON_CSIO_BINDER_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_NON_CSIO_BINDER_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_NON_CSIO_BINDER_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.data = [];
      });
      return newState;
    }

    case FETCH_HABITATIONAL_NON_CSIO_BINDER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.nonCsioLoading = LoadState.InProgress;
        draftState.nonCsio = initialHabitationalNonCsioBinderState["nonCsio"];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_NON_CSIO_BINDER_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.nonCsioLoading = LoadState.Loaded;
        draftState.nonCsio = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_NON_CSIO_BINDER_FAILED: {
      const {errorMessage} = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.nonCsioLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
  
    case CLEAR_HABITATIONAL_NON_CSIO_BINDER: {
      const newState = produce(state, (draftState) => {
        draftState.nonCsio = initialHabitationalNonCsioBinderState["nonCsio"];
        draftState.nonCsioLoading = LoadState.NotLoaded;
      });
      return newState;
    }
    case CLEAR_HABITATIONAL_NON_CSIO_BINDER_STATE: {
      return initialHabitationalNonCsioBinderState;
    }
    default: {
      return state;
    }
  }
};
