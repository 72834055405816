import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { ISucessErrorDialogProps } from "./SuccessErrorDialog.types";
import { Close, Done } from "@mui/icons-material";

export const SuccessErrorDialog: React.FC<ISucessErrorDialogProps> = (
  props
) => {
  const { open, content, type, onClose } = props;
  const theme = useTheme();

  return (
    <>
      <Dialog
        open={open}
        title="Success"
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "20px",
          },
        }}
      >
        <DialogContent sx={{ overflowY: "auto", p: 3, minHeight: "30vh" }}>
          <Box display={"flex"} justifyContent={"center"}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                backgroundColor:
                  type === "success"
                    ? theme.palette.primary.main
                    : theme.palette.error.light,
                padding: 3,
                borderRadius: "90%",
              }}
            >
              {type === "success" ? (
                <Done sx={{ color: "#fff", fontSize: "80px" }} />
              ) : (
                <Close sx={{ color: "#fff", fontSize: "80px" }} />
              )}
            </Box>
          </Box>
          <Typography
            variant="h4"
            mt={3}
            fontWeight={800}
            textAlign={"center"}
            sx={{
              color:
                type === "success"
                  ? theme.palette.primary.main
                  : theme.palette.error.light,
            }}
          >
            {type === "success" ? "Success" : "Error"}
          </Typography>
          <Typography
            variant="body1"
            mt={1}
            fontWeight={500}
            fontSize={"1.2rem"}
            textAlign={"center"}
          >
            {content}
          </Typography>
          <Stack direction={"row"} justifyContent={"center"}>
            {type === "success" ? (
              <Button
                variant="contained"
                onClick={onClose}
                sx={{
                  minWidth: "200px",
                  borderRadius: "20px",
                  textTransform: "capitalize",
                  fontWeight: 700,
                  fontSize: "1.2rem",
                  mt: 3,
                }}
                color="primary"
              >
                Continue
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={onClose}
                sx={{
                  minWidth: "200px",
                  borderRadius: "20px",
                  textTransform: "capitalize",
                  fontWeight: 700,
                  fontSize: "1.2rem",
                  backgroundColor:
                     theme.palette.error.light,

                  mt: 3,
                }}
              >
                Try Agarin
              </Button>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};
