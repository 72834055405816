import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { api } from "../../../api/api";
import { ERROR_MESSAGES } from "../../../constants/enums";
import { IHabitationalInterested } from "./habitationalInterested.types";

export const FETCH_HABITATIONAL_INTERESTED_LIST_PROGRESS =
  "FETCH_HABITATIONAL_INTERESTED_LIST_PROGRESS";
export const FETCH_HABITATIONAL_INTERESTED_LIST_SUCCESS =
  "FETCH_HABITATIONAL_INTERESTED_LIST_SUCCESS";
export const FETCH_HABITATIONAL_INTERESTED_LIST_FAILED =
  "FETCH_HABITATIONAL_INTERESTED_LIST_FAILED";

export const fetchHabitationalIntrestedListProgress = () =>
  action(FETCH_HABITATIONAL_INTERESTED_LIST_PROGRESS);
export const fetchHabitationalIntrestedListSuccess = (
  data: IHabitationalInterested[]
) => action(FETCH_HABITATIONAL_INTERESTED_LIST_SUCCESS, { data });
export const fetchHabitationalIntrestedListFailed = () =>
  action(FETCH_HABITATIONAL_INTERESTED_LIST_FAILED);

export const fetchHabitationalIntrestedListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalIntrestedListProgress());
      const res = await api.get(
        `/policy/get-habitational-interested-party?customer_policy_id=${customerPolicyId}`
      );
      const data: IHabitationalInterested[] = res.data.data;
      dispatch(fetchHabitationalIntrestedListSuccess(data));
    } catch (err: any) {
      dispatch(fetchHabitationalIntrestedListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addHabitationalInterestedAsync =
  (
    interested: IHabitationalInterested,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(`/policy/create-habitational-interested-party`, {
        ...interested,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Intrested Parties saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_HABITATIONAL_INTERESTED_PROGRESS =
  "FETCH_HABITATIONAL_INTERESTED_PROGRESS";
export const FETCH_HABITATIONAL_INTERESTED_SUCCESS =
  "FETCH_HABITATIONAL_INTERESTED_SUCCESS";
export const FETCH_HABITATIONAL_INTERESTED_FAILED =
  "FETCH_HABITATIONAL_INTERESTED_FAILED";

export const fetchHabitationalIntrestedProgress = () =>
  action(FETCH_HABITATIONAL_INTERESTED_PROGRESS);
export const fetchHabitationalIntrestedSuccess = (
  data: IHabitationalInterested
) => action(FETCH_HABITATIONAL_INTERESTED_SUCCESS, { data });
export const fetchHabitationalIntrestedFailed = (errorMessage: string) =>
  action(FETCH_HABITATIONAL_INTERESTED_FAILED, { errorMessage });

export const fetchHabitationalIntrestedAsync =
  (
    customerPolicyId: number,
    interestedNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalIntrestedProgress());
      const res = await api.get(
        `/policy/get-habitational-interested-party?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${interestedNumber}`
      );
      const data: IHabitationalInterested[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchHabitationalIntrestedSuccess(data[0]));
      } else {
        dispatch(
          fetchHabitationalIntrestedFailed(
            "Oops! We couldn't find any records associated with your intrested party number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchHabitationalIntrestedFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_HABITATIONAL_INTERESTED_STATE =
  "CLEAR_HABITATIONAL_INTERESTED_STATE";
export const CLEAR_HABITATIONAL_INTERESTED =
  "CLEAR_HABITATIONAL_INTERESTED";

export const clearHabitationalIntrestedState = () =>
  action(CLEAR_HABITATIONAL_INTERESTED_STATE);

export const clearHabitationalIntrested = () =>
  action(CLEAR_HABITATIONAL_INTERESTED);
