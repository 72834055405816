import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { ERROR_MESSAGES } from "../../../constants/enums";
import {
  ICommAutomobileConsentForm,
  ICommAutomobileDwelling,
  ICommAutomobileDwellingDeclaration,
  ICommAutomobileGarageForm,
  ICommAutomobileLiabilityInsurance,
  ICommAutomobileLiabilitySlips,
  ICommAutomobileOCEP16,
  ICommAutomobileOCEP17,
  ICommAutomobileOCEP28A,
  ICommAutomobileOwnerForm,
  ICommAutomobileRetireeDiscount,
  ICommAutomobileSharingFillable,
  ICommAutomobileSupplementaryForm,
} from "./commAutoCertificates.types";

export const FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_FAILED =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_FAILED";

export const fetchCommAutomobileLiabilitySlipProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_PROGRESS);
export const fetchCommAutomobileLiabilitySlipSuccess = (
  data: ICommAutomobileLiabilitySlips
) => action(FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_SUCCESS, { data });
export const fetchCommAutomobileLiabilitySlipFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_FAILED, { errorMessage });

export const addCommAutomobileLiabilitySlipAsync =
  (
    data: ICommAutomobileLiabilitySlips,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post(
          "/policy/edit-policy-commauto-liability-slip-certificate",
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          "/policy/edit-policy-commauto-liability-slip-certificate",
          {
            ...data,
          }
        );
      }
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Liability slip saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileLiabilityAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileLiabilitySlipProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-liability-slip-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileLiabilitySlips[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileLiabilitySlipSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileLiabilitySlipFailed(
            ERROR_MESSAGES.POLICY_NOT_FOUND
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileLiabilitySlipFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_LIABILITY_SLIP =
  "CLEAR_COMM_AUTOMOBILE_LIABILITY_SLIP";
export const clearCommAutomobileLiabiliySlip = () =>
  action(CLEAR_COMM_AUTOMOBILE_LIABILITY_SLIP);

export const FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_FAILED";

export const fetchCommAutomobileLiabilitySlipsListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_PROGRESS);
export const fetchCommAutomobileLiabilitySlipsListSuccess = (
  data: ICommAutomobileLiabilitySlips[]
) => action(FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_SUCCESS, { data });
export const fetchCommAutomobileLiabilitySlipsListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_FAILED);

export const fetchCommAutomobileLiabilitySlipsListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileLiabilitySlipsListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-liability-slip-certificate?customer_policy_id=${customerPolicyId}`
      );
      const data: ICommAutomobileLiabilitySlips[] = res.data.data;
      dispatch(fetchCommAutomobileLiabilitySlipsListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileLiabilitySlipsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadCommAutomobileLiabilitySlipsPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-liability-slip-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileLiabilitySlipsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const previewCommAutomobileLiabilitySlipsPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-liability-slip-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_liability_slip_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileLiabilitySlipsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
  
export const FETCH_COMM_AUTOMOBILE_OCEP16_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_OCEP16_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_OCEP16_SUCCESS =
  "FETCH_AUTOMOBILE_OCEP16_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_OCEP16_FAILED =
  "FETCH_COMM_AUTOMOBILE_OCEP16_FAILED";

export const fetchCommAutomobileOcep16rogress = () =>
  action(FETCH_COMM_AUTOMOBILE_OCEP16_PROGRESS);
export const fetchCommAutomobileOcep16Success = (data: ICommAutomobileOCEP16) =>
  action(FETCH_COMM_AUTOMOBILE_OCEP16_SUCCESS, { data });
export const fetchCommAutomobileOcep16Failed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_OCEP16_FAILED, { errorMessage });

export const addCommAutomobileOcep16Async =
  (
    data: ICommAutomobileOCEP16,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post(
          "/policy/edit-policy-commauto-opcf-sixteen-certificate",
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          "/policy/edit-policy-commauto-opcf-sixteen-certificate",
          {
            ...data,
          }
        );
      }
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "OPCF16 saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileOcep16Async =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileOcep16rogress());
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-sixteen-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileOCEP16[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileOcep16Success({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileOcep16Failed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep16Failed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_OCEP16 = "CLEAR_COMM_AUTOMOBILE_OCEP16";
export const clearCommAutomobileOcep16 = () =>
  action(CLEAR_COMM_AUTOMOBILE_OCEP16);

export const FETCH_COMM_AUTOMOBILE_OCEP16_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_OCEP16_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_OCEP16_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_OCEP16_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_OCEP16_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_OCEP16_LIST_FAILED";

export const fetchCommAutomobileOcep16ListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_OCEP16_LIST_PROGRESS);
export const fetchCommAutomobileOcep16ListSuccess = (
  data: ICommAutomobileOCEP16[]
) => action(FETCH_COMM_AUTOMOBILE_OCEP16_LIST_SUCCESS, { data });
export const fetchCommAutomobileOcep16ListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_OCEP16_LIST_FAILED);

export const fetchCommAutomobileOcep16ListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileOcep16ListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-sixteen-certificate?customer_policy_id=${customerPolicyId}`
      );
      const data: ICommAutomobileOCEP16[] = res.data.data;
      dispatch(fetchCommAutomobileOcep16ListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep16ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadCommAutomobileOcep16PDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-sixteen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep16ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileOcep16PDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-sixteen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_opcf_sixteen_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep16ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_COMM_AUTOMOBILE_CONSENT_FORM_PROGRESS =
 "FETCH_COMM_AUTOMOBILE_CONSENT_FORM_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_CONSENT_FORM_SUCCESS =
  "FETCH_AUTOMOBILE_CONSENT_FORM_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_CONSENT_FORM_FAILED =
  "FETCH_COMM_AUTOMOBILE_CONSENT_FORM_FAILED";

export const fetchCommAutomobileConsentFormProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_CONSENT_FORM_PROGRESS);
export const fetchCommAutomobileConsentFormSuccess = (data: ICommAutomobileConsentForm) =>
  action(FETCH_COMM_AUTOMOBILE_CONSENT_FORM_SUCCESS, { data });
export const fetchCommAutomobileConsentFormFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_CONSENT_FORM_FAILED, { errorMessage });

export const addCommAutomobileConsentFormAsync =
  (
    data: ICommAutomobileConsentForm,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        "/policy/edit-policy-form-privacy-consent-form",
        {
          ...data,
        }
      );
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

  export const fetchCommAutomobileConsentFormAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileConsentFormProgress());
      const res = await api.get(
        `/policy/get-policy-form-privacy-consent-form?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileConsentForm[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileConsentFormSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileConsentFormFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchCommAutomobileConsentFormFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_CONSENT_FORM = "CLEAR_COMM_AUTOMOBILE_CONSENT_FORM";
export const clearCommAutomobileConsentForm = () =>
  action(CLEAR_COMM_AUTOMOBILE_CONSENT_FORM);

export const FETCH_COMM_AUTOMOBILE_CONSENT_FORM_LIST_PROGRESS =
"FETCH_COMM_AUTOMOBILE_CONSENT_FORM_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_CONSENT_FORM_LIST_SUCCESS =
"FETCH_COMM_AUTOMOBILE_CONSENT_FORM_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_CONSENT_FORM_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_CONSENT_FORM_LIST_FAILED";


export const fetchCommAutomobileConsentFormListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_CONSENT_FORM_LIST_PROGRESS);
export const fetchCommAutomobileConsentFormListSuccess = (
  data: ICommAutomobileConsentForm[]
) => action(FETCH_COMM_AUTOMOBILE_CONSENT_FORM_LIST_SUCCESS, { data });
export const fetchCommAutomobileConsentFormListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_CONSENT_FORM_LIST_FAILED);

export const fetchCommAutomobileConsentFormListAsync = (customerPolicyId: number):ThunkAction<void, IStoreState, {}, AnyAction> => 
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileConsentFormListProgress());
      const res = await api.get(`/policy/get-policy-form-privacy-consent-form?customer_policy_id=${customerPolicyId}`);

      const data :ICommAutomobileConsentForm[] =res.data.data;
      dispatch(fetchCommAutomobileConsentFormListSuccess(data))
    } catch (err: any) {
      dispatch(fetchCommAutomobileConsentFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  }

  export const downloadCommAutomobileConsentFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-privacy-consent-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileConsentFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileConsentFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-privacy-consent-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_privacy_consent_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileConsentFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_PROGRESS =
 "FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_SUCCESS =
  "FETCH_AUTOMOBILE_RETIREE_DISCOUNT_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_FAILED =
  "FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_FAILED";

export const fetchCommAutomobileRetireeDiscountProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_PROGRESS);
export const fetchCommAutomobileRetireeDiscountSuccess = (data: ICommAutomobileRetireeDiscount) =>
  action(FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_SUCCESS, { data });
export const fetchCommAutomobileRetireeDiscountFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_FAILED, { errorMessage });

export const addCommAutomobileRetireeDiscountAsync =
  (
    data: ICommAutomobileRetireeDiscount,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        "/policy/edit-policy-form-declaration-for-retiree-discount",
        {
          ...data,
        }
      );
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

  export const fetchCommAutomobileRetireeDiscountAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileRetireeDiscountProgress());
      const res = await api.get(
        `/policy/get-policy-form-declaration-for-retiree-discount?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileRetireeDiscount[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileRetireeDiscountSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileRetireeDiscountFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchCommAutomobileRetireeDiscountFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_RETIREE_DISCOUNT = "CLEAR_COMM_AUTOMOBILE_RETIREE_DISCOUNT";
export const clearCommAutomobileRetireeDiscount = () =>
  action(CLEAR_COMM_AUTOMOBILE_RETIREE_DISCOUNT);

export const FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_LIST_PROGRESS =
"FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_LIST_SUCCESS =
"FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_LIST_FAILED";


export const fetchCommAutomobileRetireeDiscountListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_LIST_PROGRESS);
export const fetchCommAutomobileRetireeDiscountListSuccess = (
  data: ICommAutomobileRetireeDiscount[]
) => action(FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_LIST_SUCCESS, { data });
export const fetchCommAutomobileRetireeDiscountListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_LIST_FAILED);

export const fetchCommAutomobileRetireeDiscountListAsync = (customerPolicyId: number):ThunkAction<void, IStoreState, {}, AnyAction> => 
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileRetireeDiscountListProgress());
      const res = await api.get(`/policy/get-policy-form-declaration-for-retiree-discount?customer_policy_id=${customerPolicyId}`);

      const data :ICommAutomobileRetireeDiscount[] =res.data.data;
      dispatch(fetchCommAutomobileRetireeDiscountListSuccess(data))
    } catch (err: any) {
      dispatch(fetchCommAutomobileRetireeDiscountListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  }

  export const downloadCommAutomobileRetireeDiscountPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-declaration-for-retiree-discount-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileRetireeDiscountListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileRetireeDiscountPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-declaration-for-retiree-discount-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_declaration_for_retiree_discount_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileRetireeDiscountListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_COMM_AUTOMOBILE_GARAGE_FORM_PROGRESS =
 "FETCH_COMM_AUTOMOBILE_GARAGE_FORM_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_GARAGE_FORM_SUCCESS =
  "FETCH_AUTOMOBILE_GARAGE_FORM_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_GARAGE_FORM_FAILED =
  "FETCH_COMM_AUTOMOBILE_GARAGE_FORM_FAILED";

export const fetchCommAutomobileGarageFormProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_GARAGE_FORM_PROGRESS);
export const fetchCommAutomobileGarageFormSuccess = (data: ICommAutomobileGarageForm) =>
  action(FETCH_COMM_AUTOMOBILE_GARAGE_FORM_SUCCESS, { data });
export const fetchCommAutomobileGarageFormFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_GARAGE_FORM_FAILED, { errorMessage });

export const addCommAutomobileGarageFormAsync =
  (
    data: ICommAutomobileGarageForm,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        "/policy/edit-policy-form-alberta-application-garage-form",
        {
          ...data,
        }
      );
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

  export const fetchCommAutomobileGarageFormAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileGarageFormProgress());
      const res = await api.get(
        `/policy/get-policy-form-alberta-application-garage-form?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileGarageForm[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileGarageFormSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileGarageFormFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchCommAutomobileGarageFormFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_GARAGE_FORM = "CLEAR_COMM_AUTOMOBILE_GARAGE_FORM";
export const clearCommAutomobileGarageForm = () =>
  action(CLEAR_COMM_AUTOMOBILE_GARAGE_FORM);

export const FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_PROGRESS =
"FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_SUCCESS =
"FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_FAILED";


export const fetchCommAutomobileGarageFormListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_PROGRESS);
export const fetchCommAutomobileGarageFormListSuccess = (
  data: ICommAutomobileGarageForm[]
) => action(FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_SUCCESS, { data });
export const fetchCommAutomobileGarageFormListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_FAILED);

export const fetchCommAutomobileGarageFormListAsync = (customerPolicyId: number):ThunkAction<void, IStoreState, {}, AnyAction> => 
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileGarageFormListProgress());
      const res = await api.get(`/policy/get-policy-form-alberta-application-garage-form?customer_policy_id=${customerPolicyId}`);

      const data :ICommAutomobileGarageForm[] =res.data.data;
      dispatch(fetchCommAutomobileGarageFormListSuccess(data))
    } catch (err: any) {
      dispatch(fetchCommAutomobileGarageFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  }

  export const downloadCommAutomobileGarageFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-alberta-application-garage-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileGarageFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileGarageFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-alberta-application-garage-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_business_privacy_consent_form_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileGarageFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_PROGRESS =
 "FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_SUCCESS =
  "FETCH_AUTOMOBILE_SHARING_FILLABLE_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_FAILED =
  "FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_FAILED";

export const fetchCommAutomobileSharingFillableProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_PROGRESS);
export const fetchCommAutomobileSharingFillableSuccess = (data: ICommAutomobileSharingFillable) =>
  action(FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_SUCCESS, { data });
export const fetchCommAutomobileSharingFillableFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_FAILED, { errorMessage });

export const addCommAutomobileSharingFillableAsync =
  (
    data: ICommAutomobileSharingFillable,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        "/policy/edit-policy-form-ride-sharing-fillable",
        {
          ...data,
        }
      );
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

  export const fetchCommAutomobileSharingFillableAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileSharingFillableProgress());
      const res = await api.get(
        `/policy/get-policy-form-ride-sharing-fillable?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileSharingFillable[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileSharingFillableSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileSharingFillableFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchCommAutomobileSharingFillableFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_SHARING_FILLABLE = "CLEAR_COMM_AUTOMOBILE_SHARING_FILLABLE";
export const clearCommAutomobileSharingFillable = () =>
  action(CLEAR_COMM_AUTOMOBILE_SHARING_FILLABLE);

export const FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_LIST_PROGRESS =
"FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_LIST_SUCCESS =
"FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_LIST_FAILED";


export const fetchCommAutomobileSharingFillableListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_LIST_PROGRESS);
export const fetchCommAutomobileSharingFillableListSuccess = (
  data: ICommAutomobileSharingFillable[]
) => action(FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_LIST_SUCCESS, { data });
export const fetchCommAutomobileSharingFillableListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_LIST_FAILED);

export const fetchCommAutomobileSharingFillableListAsync = (customerPolicyId: number):ThunkAction<void, IStoreState, {}, AnyAction> => 
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileSharingFillableListProgress());
      const res = await api.get(`/policy/get-policy-form-ride-sharing-fillable?customer_policy_id=${customerPolicyId}`);

      const data :ICommAutomobileSharingFillable[] =res.data.data;
      dispatch(fetchCommAutomobileSharingFillableListSuccess(data))
    } catch (err: any) {
      dispatch(fetchCommAutomobileSharingFillableListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  }

  export const downloadCommAutomobileSharingFillablePDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-ride-sharing-fillable-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileSharingFillableListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileSharingFillablePDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-ride-sharing-fillable-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_ride_sharing_fillable_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileSharingFillableListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_PROGRESS =
 "FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_SUCCESS =
  "FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_FAILED =
  "FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_FAILED";

export const fetchCommAutomobileSupplementaryFormProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_PROGRESS);
export const fetchCommAutomobileSupplementaryFormSuccess = (data: ICommAutomobileSupplementaryForm) =>
  action(FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_SUCCESS, { data });
export const fetchCommAutomobileSupplementaryFormFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_FAILED, { errorMessage });

export const addCommAutomobileSupplementaryFormAsync =
  (
    data: ICommAutomobileSupplementaryForm,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        "/policy/edit-policy-form-garage-automobile-supplementary-form",
        {
          ...data,
        }
      );
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

  export const fetchCommAutomobileSupplementaryFormAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileSupplementaryFormProgress());
      const res = await api.get(
        `/policy/get-policy-form-garage-automobile-supplementary-form?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileSupplementaryForm[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileSupplementaryFormSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileSupplementaryFormFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchCommAutomobileSupplementaryFormFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM = "CLEAR_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM";
export const clearCommAutomobileSupplementaryForm = () =>
  action(CLEAR_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM);

export const FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_PROGRESS =
"FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_SUCCESS =
"FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_FAILED";


export const fetchCommAutomobileSupplementaryFormListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_PROGRESS);
export const fetchCommAutomobileSupplementaryFormListSuccess = (
  data: ICommAutomobileSupplementaryForm[]
) => action(FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_SUCCESS, { data });
export const fetchCommAutomobileSupplementaryFormListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_FAILED);

export const fetchCommAutomobileSupplementaryFormListAsync = (customerPolicyId: number):ThunkAction<void, IStoreState, {}, AnyAction> => 
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileSupplementaryFormListProgress());
      const res = await api.get(`/policy/get-policy-form-garage-automobile-supplementary-form?customer_policy_id=${customerPolicyId}`);

      const data :ICommAutomobileSupplementaryForm[] =res.data.data;
      dispatch(fetchCommAutomobileSupplementaryFormListSuccess(data))
    } catch (err: any) {
      dispatch(fetchCommAutomobileSupplementaryFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  }

  export const downloadCommAutomobileSupplementaryFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-garage-automobile-supplementary-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileSupplementaryFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileSupplementaryFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-garage-automobile-supplementary-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_garage_automobile_supplementary_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileSupplementaryFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const FETCH_COMM_AUTOMOBILE_OWNER_FORM_PROGRESS =
 "FETCH_COMM_AUTOMOBILE_OWNER_FORM_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_OWNER_FORM_SUCCESS =
  "FETCH_AUTOMOBILE_OWNER_FORM_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_OWNER_FORM_FAILED =
  "FETCH_COMM_AUTOMOBILE_OWNER_FORM_FAILED";

export const fetchCommAutomobileOwnerFormProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_OWNER_FORM_PROGRESS);
export const fetchCommAutomobileOwnerFormSuccess = (data: ICommAutomobileOwnerForm) =>
  action(FETCH_COMM_AUTOMOBILE_OWNER_FORM_SUCCESS, { data });
export const fetchCommAutomobileOwnerFormFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_OWNER_FORM_FAILED, { errorMessage });

export const addCommAutomobileOwnerFormAsync =
  (
    data: ICommAutomobileOwnerForm,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        "/policy/edit-policy-form-alberta-application-owner-form",
        {
          ...data,
        }
      );
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

  export const fetchCommAutomobileOwnerFormAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileOwnerFormProgress());
      const res = await api.get(
        `/policy/get-policy-form-alberta-application-owner-form?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileOwnerForm[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileOwnerFormSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileOwnerFormFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchCommAutomobileOwnerFormFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_OWNER_FORM = "CLEAR_COMM_AUTOMOBILE_OWNER_FORM";
export const clearCommAutomobileOwnerForm = () =>
  action(CLEAR_COMM_AUTOMOBILE_OWNER_FORM);

export const FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_PROGRESS =
"FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_SUCCESS =
"FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_FAILED";


export const fetchCommAutomobileOwnerFormListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_PROGRESS);
export const fetchCommAutomobileOwnerFormListSuccess = (
  data: ICommAutomobileOwnerForm[]
) => action(FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_SUCCESS, { data });
export const fetchCommAutomobileOwnerFormListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_FAILED);

export const fetchCommAutomobileOwnerFormListAsync = (customerPolicyId: number):ThunkAction<void, IStoreState, {}, AnyAction> => 
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileOwnerFormListProgress());
      const res = await api.get(`/policy/get-policy-form-alberta-application-owner-form?customer_policy_id=${customerPolicyId}`);

      const data :ICommAutomobileOwnerForm[] =res.data.data;
      dispatch(fetchCommAutomobileOwnerFormListSuccess(data))
    } catch (err: any) {
      dispatch(fetchCommAutomobileOwnerFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  }

  export const downloadCommAutomobileOwnerFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-alberta-application-owner-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileOwnerFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileOwnerFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-alberta-application-owner-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_business_privacy_consent_form_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileOwnerFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };


export const FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_SUCCESS =
   "FETCH_AUTOMOBILE_DWELLING_DECLARATION_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_FAILED =
   "FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_FAILED";
 
export const fetchCommAutomobileDwellingDeclarationProgress = () =>
   action(FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_PROGRESS);
export const fetchCommAutomobileDwellingDeclarationSuccess = (data: ICommAutomobileDwellingDeclaration) =>
   action(FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_SUCCESS, { data });
export const fetchCommAutomobileDwellingDeclarationFailed = (errorMessage: string) =>
   action(FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_FAILED, { errorMessage });
 
export const addCommAutomobileDwellingDeclarationAsync =
   (
     data: ICommAutomobileDwellingDeclaration,
     onCallback: (isSuccess: boolean) => void,
     isDuplicate?: boolean
   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
   async (dispatch, getState) => {
     try {
       dispatch(saveLoaderProgress());
        await api.post(
           "/policy/edit-policy-form-rental-dwelling-declaration",
           {
             ...data,
           }
        );
       onCallback(true);
       dispatch(
         showMessage({
           type: "success",
           message: "Conset Form saved successfully!",
           displayAs: "snackbar",
         })
       );
     } catch (err: any) {
       onCallback(false);
       dispatch(
         showMessage({
           type: "error",
           message: err.response.data.message,
           displayAs: "snackbar",
         })
       );
     } finally {
       dispatch(saveLoaderCompleted());
     }
   };

   export const fetchCommAutomobileDwellingDeclarationAsync =
   (
     customerPolicyId: number,
     incremental_number: number
   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
   async (dispatch, getState) => {
     try {
       dispatch(fetchCommAutomobileDwellingDeclarationProgress());
       const res = await api.get(
         `/policy/get-policy-form-rental-dwelling-declaration?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
       );
       const data: ICommAutomobileDwellingDeclaration[] = res.data.data;
       if (data.length > 0) {
         dispatch(
           fetchCommAutomobileDwellingDeclarationSuccess({
             ...data[0],
           })
         );
       } else {
         dispatch(
           fetchCommAutomobileDwellingDeclarationFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
         );
       }
     } catch (err: any) {
       dispatch(fetchCommAutomobileDwellingDeclarationFailed(ERROR_MESSAGES.SERVER_ERROR));
       dispatch(
         showMessage({
           type: "error",
           message: err.response.data.message,
           displayAs: "snackbar",
         })
       );
     }
   };
 
export const CLEAR_COMM_AUTOMOBILE_DWELLING_DECLARATION = "CLEAR_COMM_AUTOMOBILE_DWELLING_DECLARATION";
export const clearCommAutomobileDwellingDeclaration = () =>
  action(CLEAR_COMM_AUTOMOBILE_DWELLING_DECLARATION);

export const FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_PROGRESS =
"FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_SUCCESS =
"FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_FAILED";


export const fetchCommAutomobileDwellingDeclarationListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_PROGRESS);
export const fetchCommAutomobileDwellingDeclarationListSuccess = (
  data: ICommAutomobileDwellingDeclaration[]
) => action(FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_SUCCESS, { data });
export const fetchCommAutomobileDwellingDeclarationListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_FAILED);

export const fetchCommAutomobileDwellingDeclarationListAsync = (customerPolicyId: number):ThunkAction<void, IStoreState, {}, AnyAction> => 
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileDwellingDeclarationListProgress());
      const res = await api.get(`/policy/get-policy-form-rental-dwelling-declaration?customer_policy_id=${customerPolicyId}`);

      const data :ICommAutomobileDwellingDeclaration[] =res.data.data;
      dispatch(fetchCommAutomobileDwellingDeclarationListSuccess(data))
    } catch (err: any) {
      dispatch(fetchCommAutomobileDwellingDeclarationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  }

  export const downloadCommAutomobileDwellingDeclarationPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-rental-dwelling-declaration-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileDwellingDeclarationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileDwellingDeclarationPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-rental-dwelling-declaration-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_rental_dwelling_declaration_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileDwellingDeclarationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const FETCH_COMM_AUTOMOBILE_DWELLING_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_DWELLING_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_DWELLING_SUCCESS =
   "FETCH_AUTOMOBILE_DWELLING_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_DWELLING_FAILED =
   "FETCH_COMM_AUTOMOBILE_DWELLING_FAILED";
 
export const fetchCommAutomobileDwellingProgress = () =>
   action(FETCH_COMM_AUTOMOBILE_DWELLING_PROGRESS);
export const fetchCommAutomobileDwellingSuccess = (data: ICommAutomobileDwelling) =>
   action(FETCH_COMM_AUTOMOBILE_DWELLING_SUCCESS, { data });
export const fetchCommAutomobileDwellingFailed = (errorMessage: string) =>
   action(FETCH_COMM_AUTOMOBILE_DWELLING_FAILED, { errorMessage });
 
export const addCommAutomobileDwellingAsync =
   (
     data: ICommAutomobileDwelling,
     onCallback: (isSuccess: boolean) => void,
     isDuplicate?: boolean
   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
   async (dispatch, getState) => {
     try {
       dispatch(saveLoaderProgress());
        await api.post(
           "/policy/edit-policy-form-rental-dwelling",
           {
             ...data,
           }
        );
       onCallback(true);
       dispatch(
         showMessage({
           type: "success",
           message: "Conset Form saved successfully!",
           displayAs: "snackbar",
         })
       );
     } catch (err: any) {
       onCallback(false);
       dispatch(
         showMessage({
           type: "error",
           message: err.response.data.message,
           displayAs: "snackbar",
         })
       );
     } finally {
       dispatch(saveLoaderCompleted());
     }
   };

   export const fetchCommAutomobileDwellingAsync =
   (
     customerPolicyId: number,
     incremental_number: number
   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
   async (dispatch, getState) => {
     try {
       dispatch(fetchCommAutomobileDwellingProgress());
       const res = await api.get(
         `/policy/get-policy-form-rental-dwelling?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
       );
       const data: ICommAutomobileDwelling[] = res.data.data;
       if (data.length > 0) {
         dispatch(
           fetchCommAutomobileDwellingSuccess({
             ...data[0],
           })
         );
       } else {
         dispatch(
           fetchCommAutomobileDwellingFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
         );
       }
     } catch (err: any) {
       dispatch(fetchCommAutomobileDwellingFailed(ERROR_MESSAGES.SERVER_ERROR));
       dispatch(
         showMessage({
           type: "error",
           message: err.response.data.message,
           displayAs: "snackbar",
         })
       );
     }
   };
 
export const CLEAR_COMM_AUTOMOBILE_DWELLING = "CLEAR_COMM_AUTOMOBILE_DWELLING";
export const clearCommAutomobileDwelling = () =>
  action(CLEAR_COMM_AUTOMOBILE_DWELLING);

export const FETCH_COMM_AUTOMOBILE_DWELLING_LIST_PROGRESS =
"FETCH_COMM_AUTOMOBILE_DWELLING_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_DWELLING_LIST_SUCCESS =
"FETCH_COMM_AUTOMOBILE_DWELLING_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_DWELLING_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_DWELLING_LIST_FAILED";


export const fetchCommAutomobileDwellingListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_DWELLING_LIST_PROGRESS);
export const fetchCommAutomobileDwellingListSuccess = (
  data: ICommAutomobileDwelling[]
) => action(FETCH_COMM_AUTOMOBILE_DWELLING_LIST_SUCCESS, { data });
export const fetchCommAutomobileDwellingListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_DWELLING_LIST_FAILED);

export const fetchCommAutomobileDwellingListAsync = (customerPolicyId: number):ThunkAction<void, IStoreState, {}, AnyAction> => 
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileDwellingListProgress());
      const res = await api.get(`/policy/get-policy-form-rental-dwelling?customer_policy_id=${customerPolicyId}`);

      const data :ICommAutomobileDwelling[] =res.data.data;
      dispatch(fetchCommAutomobileDwellingListSuccess(data))
    } catch (err: any) {
      dispatch(fetchCommAutomobileDwellingListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  }

  export const downloadCommAutomobileDwellingPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-rental-dwelling-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileDwellingListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileDwellingPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-rental-dwelling-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_rental_dwelling_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileDwellingListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_COMM_AUTOMOBILE_OCEP17_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_OCEP17_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_OCEP17_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_OCEP17_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_OCEP17_FAILED =
  "FETCH_COMM_AUTOMOBILE_OCEP17_FAILED";

export const fetchCommAutomobileOcep17rogress = () =>
  action(FETCH_COMM_AUTOMOBILE_OCEP17_PROGRESS);
export const fetchCommAutomobileOcep17Success = (data: ICommAutomobileOCEP17) =>
  action(FETCH_COMM_AUTOMOBILE_OCEP17_SUCCESS, { data });
export const fetchCommAutomobileOcep17Failed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_OCEP17_FAILED, { errorMessage });

export const addCommAutomobileOcep17Async =
  (
    data: ICommAutomobileOCEP17,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post(
          "/policy/edit-policy-commauto-opcf-seventeen-certificate",
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          "/policy/edit-policy-commauto-opcf-seventeen-certificate",
          {
            ...data,
          }
        );
      }

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "OPCF17 saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileOcep17Async =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileOcep17rogress());
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-seventeen-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileOCEP17[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileOcep17Success({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileOcep17Failed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep17Failed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_OCEP17 = "CLEAR_COMM_AUTOMOBILE_OCEP17";
export const clearCommAutomobileOcep17 = () =>
  action(CLEAR_COMM_AUTOMOBILE_OCEP17);

export const FETCH_COMM_AUTOMOBILE_OCEP17_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_OCEP17_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_OCEP17_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_OCEP17_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_OCEP17_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_OCEP17_LIST_FAILED";

export const fetchCommAutomobileOcep17ListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_OCEP17_LIST_PROGRESS);
export const fetchCommAutomobileOcep17ListSuccess = (
  data: ICommAutomobileOCEP17[]
) => action(FETCH_COMM_AUTOMOBILE_OCEP17_LIST_SUCCESS, { data });
export const fetchCommAutomobileOcep17ListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_OCEP17_LIST_FAILED);

export const fetchCommAutomobileOcep17ListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileOcep17ListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-seventeen-certificate?customer_policy_id=${customerPolicyId}`
      );
      const data: ICommAutomobileOCEP17[] = res.data.data;
      dispatch(fetchCommAutomobileOcep17ListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep17ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadCommAutomobileOcep17PDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-seventeen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep17ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileOcep17PDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-seventeen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_opcf_seventeen_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep17ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const FETCH_COMM_AUTOMOBILE_OCEP28A_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_OCEP28A_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_OCEP28A_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_OCEP28A_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_OCEP28A_FAILED =
  "FETCH_COMM_AUTOMOBILE_OCEP28A_FAILED";

export const fetchCommAutomobileOcep28Arogress = () =>
  action(FETCH_COMM_AUTOMOBILE_OCEP28A_PROGRESS);
export const fetchCommAutomobileOcep28ASuccess = (
  data: ICommAutomobileOCEP28A
) => action(FETCH_COMM_AUTOMOBILE_OCEP28A_SUCCESS, { data });
export const fetchCommAutomobileOcep28AFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_OCEP28A_FAILED, { errorMessage });

export const addCommAutomobileOcep28Async =
  (
    data: ICommAutomobileOCEP28A,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post(
          "/policy/edit-policy-commauto-opcf-twenty-eight-a-certificate",
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          "/policy/edit-policy-commauto-opcf-twenty-eight-a-certificate",
          {
            ...data,
          }
        );
      }

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "OPCF28A saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileOcep28AAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileOcep28Arogress());
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-twenty-eight-a-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileOCEP28A[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileOcep28ASuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileOcep28AFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep28AFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_OCEP28A = "CLEAR_COMM_AUTOMOBILE_OCEP28A";
export const clearCommAutomobileOcep28A = () =>
  action(CLEAR_COMM_AUTOMOBILE_OCEP28A);

export const FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_FAILED";

export const fetchCommAutomobileOcep28AListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_PROGRESS);
export const fetchCommAutomobileOcep28AListSuccess = (
  data: ICommAutomobileOCEP28A[]
) => action(FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_SUCCESS, { data });
export const fetchCommAutomobileOcep28AListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_FAILED);

export const fetchCommAutomobileOcep28AListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileOcep28AListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-twenty-eight-a-certificate?customer_policy_id=${customerPolicyId}`
      );
      const data: ICommAutomobileOCEP28A[] = res.data.data;
      dispatch(fetchCommAutomobileOcep28AListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep28AListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadCommAutomobileOcep28AListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-twenty-eight-a-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep28AListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileOcep28AListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-twenty-eight-a-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_opcf_twenty_eight_a_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep28AListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_FAILED =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_FAILED";

export const fetchCommAutomobileLiabilityInsuranceProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS);
export const fetchCommAutomobileLiabilityInsuranceSuccess = (
  data: ICommAutomobileLiabilityInsurance
) => action(FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS, { data });
export const fetchCommAutomobileLiabilityInsuranceFailed = (
  errorMessage: string
) => action(FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_FAILED, { errorMessage });

export const addCommAutomobileLiabilityInsuranceAsync =
  (
    data: ICommAutomobileLiabilityInsurance,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post(
          "/policy/edit-policy-commauto-add-liability-insurance-certificate",
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          "/policy/edit-policy-commauto-add-liability-insurance-certificate",
          {
            ...data,
          }
        );
      }
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Liability insurance saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileLiabilityInsuranceAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileLiabilityInsuranceProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-add-liability-insurance-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileLiabilityInsurance[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileLiabilityInsuranceSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileLiabilityInsuranceFailed(
            ERROR_MESSAGES.POLICY_NOT_FOUND
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileLiabilityInsuranceFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_LIABILITY_INSURANCE =
  "CLEAR_COMM_AUTOMOBILE_LIABILITY_INSURANCE";
export const clearCommAutomobileLiabiliyInsurance = () =>
  action(CLEAR_COMM_AUTOMOBILE_LIABILITY_INSURANCE);

export const FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_FAILED";

export const fetchCommAutomobileLiabilityInsuranceListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_PROGRESS);
export const fetchCommAutomobileLiabilityInsuranceListSuccess = (
  data: ICommAutomobileLiabilityInsurance[]
) => action(FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_SUCCESS, { data });
export const fetchCommAutomobileLiabilityInsuranceListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_FAILED);

export const fetchCommAutomobileLiabilityInsuranceListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileLiabilityInsuranceListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-add-liability-insurance-certificate?customer_policy_id=${customerPolicyId}`
      );
      const data: ICommAutomobileLiabilityInsurance[] = res.data.data;
      dispatch(fetchCommAutomobileLiabilityInsuranceListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileLiabilityInsuranceListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
// export const downloadCommAutomobileLiabilityInsurancePDFAsync =
// (customerPolicyId: number,incremental_number: number, onCallback: ()=> void): ThunkAction<void, IStoreState, {}, AnyAction> =>
// async (dispatch, getState) => {
//   try {
//     const res = await api.get(
//       `/policy/get-policy-commauto-opcf-sixteen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,{
//         responseType: 'arraybuffer',
//       }
//     );

//     const pdfBuffer = res.data;

//     const pdfBlob = new Blob([pdfBuffer], { type: 'application/pdf' });

//     const pdfUrl = URL.createObjectURL(pdfBlob);

//     const link = document.createElement('a');
//     link.href = pdfUrl;
//     link.download = 'downloaded.pdf';
//     link.click();

//     URL.revokeObjectURL(pdfUrl);
//     onCallback();

//   } catch (err: any) {
//     dispatch(fetchCommAutomobileLiabilityInsuranceListFailed());
//     dispatch(
//       showMessage({
//         type: "error",
//         message: err.response.data.message,
//         displayAs: "snackbar",
//       })
//     );
//   }
// };

// export const previewCommAutomobileLiabilityInsurancePDFAsync =
// (customerPolicyId: number,incremental_number: number, onCallback: ()=> void): ThunkAction<void, IStoreState, {}, AnyAction> =>
// async (dispatch, getState) => {
//   try {
//     const res = await api.get(
//       `/policy/get-policy-commauto-opcf-sixteen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_opcf_sixteen_certificate_preview=true`
//     );

//     for (const key in res.data.data) {
//       const newTab = window.open("", "_blank");
//       if (newTab) {
//         newTab.document.write(res.data.data[key]);
//         newTab.document.close();
//       }
//     }
//     onCallback();

//   } catch (err: any) {
//     dispatch(fetchCommAutomobileLiabilityInsuranceListFailed());
//     dispatch(
//       showMessage({
//         type: "error",
//         message: err.response.data.message,
//         displayAs: "snackbar",
//       })
//     );
//   }
// };

export const FETCH_COMM_AUTOMOBILE_CERTIFICATES_STATE =
  "FETCH_COMM_AUTOMOBILE_CERTIFICATES_STATE";

export const clearCommAutoCertificatesState = () =>
  action(FETCH_COMM_AUTOMOBILE_CERTIFICATES_STATE);
