import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import {
  IPaymentAccountsRecievable,
  initialAccountingPaymentAccountsRecievableState,
} from "./PaymentAccountsRecievable.types";
import { IFileUpload } from "../../../components/FileUpload/FileUpload.type";
import { getUniqueId } from "../../../helpers";
import { uploadMultipleFile } from "../../../components/FileUpload/utils";
import { makeApiCall } from "../../../helpers/postRequest";
import { IDate } from "../../../components/Table/hooks/useDateFilter";
import { IInvoiceInformation } from "../InvoiceInformation/InvoiceInformation.types";

export const FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_LIST_PROGRESS =
  "FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_LIST_PROGRESS";
export const FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_LIST_SUCCESS =
  "FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_LIST_SUCCESS";
export const FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_LIST_FAILURE =
  "FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_LIST_FAILURE";

export const fetchAccountingPaymentAccountsRecievableListProgress = () =>
  action(FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_LIST_PROGRESS);

export const fetchAccountingPaymentAccountsRecievableListSuccess = (
  data: IPaymentAccountsRecievable[],
  totalRecords: number,
) =>
  action(FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_LIST_SUCCESS, { data,totalRecords });

export const fetchAccountingPaymentAccountsRecievableListFailed = (
  errorMessage: string
) =>
  action(FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_LIST_FAILURE, {
    errorMessage,
  });

export const fetchAccountingPaymentAccountsRecievableListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[]
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingPaymentAccountsRecievableListProgress());
      let finalUrl = `/accounting/get-account-payment-accounts-receivable?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;

      if (searchType.length > 0 && searchValue) {
        finalUrl = `/accounting/get-account-payment-accounts-receivable?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}&column=${[searchType]}&value=${searchValue}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }
      const res = await api.get(finalUrl);
      const data: IPaymentAccountsRecievable[] = res.data.data;
      dispatch(fetchAccountingPaymentAccountsRecievableListSuccess(data,res.data.totalRecords));
    } catch (err: any) {
      dispatch(
        fetchAccountingPaymentAccountsRecievableListFailed(
          "Something went to be wrong!"
        )
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_PROGRESS =
  "FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_PROGRESS";
export const FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_SUCCESS =
  "FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_SUCCESS";
export const FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_FAILURE =
  "FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_FAILURE";

export const fetchAccountingPaymentAccountsRecievableProgress = () =>
  action(FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_PROGRESS);

export const fetchAccountingPaymentAccountsRecievableSuccess = (
  data: IPaymentAccountsRecievable
) => action(FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_SUCCESS, { data });

export const fetchAccountingPaymentAccountsRecievableFailed = (
  errorMessage: string
) =>
  action(FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_FAILURE, {
    errorMessage,
  });

export const fetchAccountingPaymentAccountsRecievableAsync =
  (paymentId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingPaymentAccountsRecievableProgress());
      const res = await api.get(
        `/accounting/get-account-payment-accounts-receivable?payment_id=${paymentId}`
      );
      const data: IPaymentAccountsRecievable[] = res.data.data;
      if (data.length > 0) {
        const attachments: IFileUpload[] = [];
        for (const item of (data[0].file_upload || []) as any) {
          attachments.push({
            file: null,
            key: getUniqueId(),
            path: item,
          });
        }
        dispatch(
          fetchAccountingPaymentAccountsRecievableSuccess({
            ...data[0],
            file_upload: attachments,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        fetchAccountingPaymentAccountsRecievableFailed(
          "Something went to be wrong!"
        )
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingPaymentAccountsRecievableAysnc =
  (
    data: IPaymentAccountsRecievable,
    initialData: IPaymentAccountsRecievable,
    isUpdate: boolean,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        invoice_id: data.invoice_id,
      };
      const { paths } = await uploadMultipleFile(
        data.file_upload || [],
        "ACCOUNTING",
        asPayload
      );

      await makeApiCall(
        {
          url: "/accounting/edit-account-payment-accounts-receivable",
          method: "POST",
          automation: {
            isUpdate: isUpdate,
            primaryFieldName: "invoice_id",
          },
        },
        initialData,
        {
          ...data,
          file_upload: paths,
        }
      );

      let message = "Payment Accounts Recievable saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

  export const fetchInvoicesByPolicyNumberAsync =
  (
    policyId: string,
    onCallback: (data: IInvoiceInformation[]) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const finalUrl = `/accounting/get-account-invoice?policy_id=${policyId}&status=ACTIVE`;
      const res = await api.get(finalUrl);
      let data: IInvoiceInformation[] = res.data.data;
      data = data.sort((x,y)=>{
       if((x.invoice_id && y.invoice_id )&&x.invoice_id > y.invoice_id){
         return 1;
       }
       return -1
      })

      onCallback(data);
    } catch (err: any) {
      onCallback([])
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };


  export const fetchInvoicesByuserCodeAsync =
  (
    code: string,
    onCallback: (data: IInvoiceInformation[]) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const finalUrl = `/accounting/get-account-invoice?code=${code}&status=ACTIVE`;
      const res = await api.get(finalUrl);
      let data: IInvoiceInformation[] = res.data.data;
      data = data.sort((x,y)=>{
       if((x.invoice_id && y.invoice_id )&&x.invoice_id > y.invoice_id){
         return 1;
       }
       return -1
      })

      onCallback(data);
    } catch (err: any) {
      onCallback([])
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const CLEAR_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE =
  "CLEAR_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE";
export const clearAccountingPaymentAccountsRecievable = () =>
  action(CLEAR_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE);
