import { action } from "typesafe-actions";

import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { api } from "../../../api/api";
import { IHabitationalCoverages } from "./habitationalCoverages.types";

export const FETCH_HABITATIONAL_COVERAGES_LIST_PROGRESS =
  "FETCH_HABITATIONAL_COVERAGES_LIST_PROGRESS";
export const FETCH_HABITATIONAL_COVERAGES_LIST_SUCCESS =
  "FETCH_HABITATIONAL_COVERAGES_LIST_SUCCESS";
export const FETCH_HABITATIONAL_COVERAGES_LIST_FAILED =
  "FETCH_HABITATIONAL_COVERAGES_LIST_FAILED";

export const fetchHabitationalCoverageListProgress = () =>
  action(FETCH_HABITATIONAL_COVERAGES_LIST_PROGRESS);
export const fetchHabitationalCoverageListSuccess = (
  data: IHabitationalCoverages[]
) => action(FETCH_HABITATIONAL_COVERAGES_LIST_SUCCESS, { data });
export const fetchHabitationalCoverageListFailed = () =>
  action(FETCH_HABITATIONAL_COVERAGES_LIST_FAILED);

export const fetchHabitationalCoverageListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalCoverageListProgress());
      const res = await api.get(
        `/policy/get-habitational-coverage?customer_policy_id=${customerPolicyId}`
      );
      const data: IHabitationalCoverages[] = res.data.data;
      dispatch(fetchHabitationalCoverageListSuccess(data));
    } catch (err: any) {
      dispatch(fetchHabitationalCoverageListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addHabitationalCoverageAsync =
  (
    coverages: IHabitationalCoverages,
    customerPolicyId: number,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(`/policy/create-habitational-coverage`, {
        ...coverages,
        customer_policy_id: customerPolicyId,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Coverage saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_HABITATIONAL_COVERAGES_PROGRESS =
  "FETCH_HABITATIONAL_COVERAGES_PROGRESS";
export const FETCH_HABITATIONAL_COVERAGES_SUCCESS =
  "FETCH_HABITATIONAL_COVERAGES_SUCCESS";
export const FETCH_HABITATIONAL_COVERAGES_FAILED =
  "FETCH_HABITATIONAL_COVERAGES_FAILED";

export const fetchHabitationalCoverageProgress = () =>
  action(FETCH_HABITATIONAL_COVERAGES_PROGRESS);
export const fetchHabitationalCoverageSuccess = (data: IHabitationalCoverages) =>
  action(FETCH_HABITATIONAL_COVERAGES_SUCCESS, { data });
export const fetchHabitationalCoverageFailed = (errorMessage: string) =>
  action(FETCH_HABITATIONAL_COVERAGES_FAILED, { errorMessage });

export const fetchHabitationalCoverageAsync =
  (
    customerPolicyId: number,
    incrementalNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalCoverageProgress());
      const res = await api.get(
        `/policy/get-habitational-coverage?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incrementalNumber}`
      );
      const data: IHabitationalCoverages[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchHabitationalCoverageSuccess({ ...data[0] }));
      } else {
        dispatch(
          fetchHabitationalCoverageFailed(
            "Oops! We couldn't find any records associated with your Coverage number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchHabitationalCoverageFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_HABITATIONAL_COVERAGES_STATE =
  "CLEAR_HABITATIONAL_COVERAGES_STATE";
export const CLEAR_HABITATIONAL_COVERAGES =
  "CLEAR_HABITATIONAL_COVERAGES";

export const clearHabitationalCoverageState = () =>
  action(CLEAR_HABITATIONAL_COVERAGES_STATE);

export const clearHabitationalCoverage = () =>
  action(CLEAR_HABITATIONAL_COVERAGES);
