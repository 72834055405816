import React from "react";
import { Box, Grid } from "@mui/material";
import ReactQuill from "react-quill";
import { IQuillEditorProps } from "./QuillEditor.types";
import "react-quill/dist/quill.snow.css";

export const QuillEditor: React.FC<IQuillEditorProps> = (props) => {
  const { value, onChange, readOnly = false } = props;

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          ".ql-container": {
            minHeight: 200,
          },
        }}
      >
        <ReactQuill theme="snow" value={value} onChange={onChange} readOnly={readOnly} />
      </Box>
    </Grid>
  );
};
