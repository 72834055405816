import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { PolicyCancellationForm } from "../../Cancellation/PolicyCancellationForm";
import { useHabitationalPremium } from "../../../../../../hooks/useHabitationalPremium";
import { POLICIES_NAMES } from "../../../../../../constants/enums";
import { useHabitationalCustomerBasicDetails } from "../../../../../../hooks/useHabitationalCustomerBasicDetail";

export const HabitationalCancellation: React.FC = () => {
  const { taskCode } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
    taskCode?: string;
  };

  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerPolicyId = data[1];

  const customerBasicDetail = useHabitationalCustomerBasicDetails(customerPolicyId)
  const premium = useHabitationalPremium(customerPolicyId);
  const agencyBill = premium.data.agency_bill_or_direct_bill;

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(
      `/habitational-policy/${customerId}/policy-forms-tab/${customerPolicyId}?tab=1`
    );
  };
  return (
    <>
      {taskCode && (
        <PolicyCancellationForm
          customerId={customerId}
          customerPolicyId={customerPolicyId}
          taskCode={taskCode}
          handleNavigate={handleNavigate}
          agencyBill={agencyBill}
          policy={POLICIES_NAMES.HABITATIONAL_POLICY}
          lineOfBusiness={customerBasicDetail.data.line_of_business}
          subType={customerBasicDetail.data.sub_type}
          policyNumber={customerBasicDetail.data.policy_number}
        />
      )}
    </>
  );
};
