import { useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../hooks";
import React from "react";
import { IDeleteDriver } from "../../../../redux/TaskReducer/Driver/driver.types";
import { fetchDeleteDriverAysnc } from "../../../../redux/TaskReducer/Driver/driverActions";
import { StandardCellList } from "../../../../components/Standards";
import { Grid, Stack, Typography } from "@mui/material";
import { formatTime } from "../../../../helpers";
import { LoadState } from "../../../../constants/enums";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { ITaskFileUploadAttachment } from "../../../../redux/tasks/task.types";
import { StandadCard } from "../../../../components/Cards";
import { FileUpload } from "../../../../components/FileUpload/FileUpload";
import { stripHtml } from "../../../../helpers/stripHtml";
import { QuillEditor } from "../../../../components/QuillEditor/QuillEditor";

export const ViewDeleteDriver: React.FC<{
  taskCode: string;
  attachment: ITaskFileUploadAttachment[];
}> = (props) => {
  const { taskCode, attachment } = props;

  const { data, deleteloading } = useSelector(
    (storeState: IStoreState) => storeState.tasks.driver.deleteDriver
  );

  const dispatch = useDispatchWrapper();
  const [driverDetails, setDriverDetails] = React.useState<IDeleteDriver>();

  React.useEffect(() => {
    dispatch(fetchDeleteDriverAysnc(taskCode));
  }, [taskCode]);

  React.useEffect(() => {
    setDriverDetails(data);
  }, [data]);

  return (
    <PageLoader loading={deleteloading === LoadState.InProgress}>
      {driverDetails && (
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Driver Information"
              colSize={3}
              list={[
                {
                  heading: "Driver",
                  colSize: 2,
                  data: {
                    type: "text",
                    value: driverDetails.driver,
                  },
                },
                {
                  heading: "Effective Date",
                  colSize: 2,
                  data: {
                    type: "text",
                    value: driverDetails.effective_date_of_change,
                  },
                },
                {
                  heading: "Effective Time",
                  colSize: 2,
                  data: {
                    type: "text",
                    value: formatTime(
                      driverDetails.effective_time_of_change.toString()
                    ),
                  },
                },
                {
                  heading: "Quote #",
                  colSize: 2,
                  data: {
                    type: "text",
                    value: driverDetails.quote_number,
                  },
                },
                {
                  heading: "Insurance Reports",
                  colSize: 3,
                  data: {
                    type: "text",
                    value: driverDetails.insurance_reports,
                  },
                },
              ]}
            >
              <Grid item xs={12} md={12}>
                <Typography fontWeight={500} variant="h4" mb={1}>
                  Details
                </Typography>
                <QuillEditor
                  value={driverDetails.details || ""}
                  onChange={() => {}}
                  readOnly={true}
                />
              </Grid>
            </StandardCellList>
          </Grid>
        </Grid>
      )}
    </PageLoader>
  );
};
