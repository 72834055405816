import { LoadState } from "../../constants/enums";

export interface IAcitivtyState {
  tasks: {
    list: IBusinessTask[];
    totalRecords: number;
    loading: LoadState;
    data: IBusinessTask[];
    dataLoading: LoadState;
    error: string | null;
  };
  emailConversation: {
    list: IEmailConversationActivity[];
    loading: LoadState;
    error: string | null;
  };
  sms: {
    list: ISMSActivity[];
    loading: LoadState;
    error: string | null;
  };
  whatsApp: {
    list: IWhatsAppActivity[];
    loading: LoadState;
    error: string | null;
  }
}

export interface IEmailConversationActivity {
  email_history_code: string | null;
  email_conversation_id: string | null;
  module_code: string | null;
  module_name: string | null;
  subject: string | null;
  to_mail_ids: string[] | null;
  cc_mail_ids: string[] | null;
  bcc_mail_ids: string[] | null;
  body: { content: string | null };
  attachments: any | null;
  comment: string | null;
  send_received_ts: string | null;
  status: string;
  insert_ts: string;
}

export interface ISMSActivity {
  sms_history_id: number;
  sms_history_code: string;
  sms_conversation_id: string | null;
  module_name: string;
  module_code: string;
  recipients:  {
    to: string;
    objectVariables: {
      [key: string]: string
    };
  }[
   
  ];
  template_name: string;
  comment: string | null;
  created_by_id: string;
  send_received_ts: string | null;
  create_ts: string;
  insert_ts: string;
}

export interface IWhatsAppActivity {
  sms_history_id: number;
  sms_history_code: string;
  sms_conversation_id: string | null;
  module_name: string;
  module_code: string;
  recipients:  {
    to: string;
    objectVariables: {
      [key: string]: string
    };
  }[

    
  ];
  template_name: string;
  comment: string | null;
  created_by_id: string;
  send_received_ts: string | null;
  create_ts: string;
  insert_ts: string;
}
export interface IBusinessTask {
  task_module_wise_code: string | null;
  module_name: string | null;
  sub_module_name: string | null;
  module_reference_column: string | null;
  module_reference_code_or_id: string | null;
  task_name: string | null;
  description: string | null;
  due_date: string | null;
  assigned_to_id: string | null;
  assigned_to_name: string | null;
  task_completed_date: string | null;
  task_priority: string | null;
  created_by_id: number | null;
  created_by_name: string | null;
  status: string;
  create_ts?: any;
  task_type: string | null;
}

export const INITIAL_BUSINESS_TASK: IBusinessTask = {
  task_module_wise_code: null,
  module_name: null,
  sub_module_name: null,
  module_reference_column: null,
  module_reference_code_or_id: null,
  task_name: null,
  description: null,
  due_date: null,
  assigned_to_id: null,
  task_completed_date: null,
  task_priority: null,
  assigned_to_name: null,
  created_by_name: null,
  created_by_id: null,
  task_type: null,
  status: "ACTIVE",
};

export const initialAcitvityState: IAcitivtyState = {
  tasks: {
    list: [],
    loading: LoadState.NotLoaded,
    totalRecords: 0,
    data: [],
    dataLoading: LoadState.NotLoaded,
    error: null,
  },
  emailConversation: {
    list: [],
    loading: LoadState.NotLoaded,
    error: null,
  },
  sms: {
    list: [],
    loading: LoadState.NotLoaded,
    error: null,
  },
  whatsApp: {
    list: [],
    loading: LoadState.NotLoaded,
    error: null,
  }
};
