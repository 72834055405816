import React from "react";
import { IManageBusinessTaskProps } from "./ManageBusinessTask.types";

import { useFormik } from "formik";
import {  Grid } from "@mui/material";
import { IBusinessTask, INITIAL_BUSINESS_TASK } from "../../../redux/activity/activity.types";
import { useDispatchWrapper } from "../../../hooks";
import { useAuth } from "../../../contexts/AuthProvider/AuthProvider";
import { useRoles } from "../../../security/RolesProvider/RolesProvider";
import { fetchBusinessTasksAsync, upsertBusinessTask } from "../../../redux/activity/activityActions";
import { ControlledCustomSelect, CustomFormLabel, CustomTextField } from "../../formsComponents";
import { CustomDatePicker } from "../../formsComponents/CustomDatePicker";
import { UsersAutoSearchByRole } from "../../AutoCompleteSearches/UsersAutoSearchByRole";
import { Dialog } from "../../Dialogs/Dialog";


export const ManageBusinessTask: React.FC<IManageBusinessTaskProps> = (
  props
) => {
  const {
    moduleInformation: { moduleId, moduleName, refColumnName, subModuleName },
    open,
    onClose,
    task = INITIAL_BUSINESS_TASK,
  } = props;
  const isUpdate = props.task ? true : false;

  const dispatch = useDispatchWrapper();
  const {
    user: { userId },
  } = useAuth();
  const { firstName, lastName } = useRoles();
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik<IBusinessTask>({
    initialValues: isUpdate
      ? task
      : {
          ...task,
          created_by_id: userId,
          created_by_name: firstName + " " + lastName,
        },
    validate: (values) => {
      const errors: any = {};

      return errors;
    },
    onSubmit: async (values) => {
      dispatch(
        upsertBusinessTask(
          {
            ...values,
            module_name: moduleName,
            module_reference_code_or_id: moduleId,
            sub_module_name: subModuleName,
            module_reference_column: refColumnName,
          },
          (isSuccess) => {
            if (isSuccess) {
              dispatch(fetchBusinessTasksAsync(moduleId))
              onClose();
            }
          }
        )
      );
    },
  });

  return (
    <Dialog
      open={open}
      title="Task"
      onClose={onClose}
      size="sm"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      actions={[
        {
          type: "button",
          label: "Close",
          variant: "contained",
          onClick: onClose,
        },
        {
          type: "submit",
          label: "Save",
          variant: "contained",
        },
      ]}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <CustomFormLabel>Task Name</CustomFormLabel>
          <CustomTextField
            name="task_name"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.task_name}
            error={errors.task_name ? true : false}
            helperText={errors.task_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <CustomFormLabel>Description</CustomFormLabel>
          <CustomTextField
            name="description"
            variant="outlined"
            size="small"
            type="text"
            multiline
            rows={3}
            fullWidth
            value={values.description}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomFormLabel>Task Priority</CustomFormLabel>
          <ControlledCustomSelect
            name="task_priority"
            fullWidth
            value={values.task_priority}
            options={[
              { label: "High", value: "High" },
              { label: "Medium", value: "Medium" },
              { label: "Low", value: "Low" },
            ]}
            onChange={handleChange}
            displayEmpty
            placeholder="Select one"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomFormLabel>Due Date</CustomFormLabel>
          <CustomDatePicker
            fullWidth
            value={values.due_date || ""}
            onChange={(newvalue) => {
              setFieldValue("due_date", newvalue);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomFormLabel>Assigned To</CustomFormLabel>
          <UsersAutoSearchByRole
            value={values.assigned_to_id || ""}
            onSelect={(newvalue) => {
              setValues({
                ...values,
                assigned_to_id: newvalue.user_fact_id as any,
                assigned_to_name: newvalue.first_name + " " + newvalue.last_name,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomFormLabel>Create By</CustomFormLabel>
          <CustomTextField
            name="created_by_name"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.created_by_name}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomFormLabel>Task Type</CustomFormLabel>
          <ControlledCustomSelect
            name="task_type"
            fullWidth
            value={values.task_type}
            options={[
              { label: "Calls", value: "Calls" },
              { label: "Meeting", value: "Meeting" },
              { label: "Follow up", value: "Follow up" },
              { label: "Events", value: "Events" },
              { label: "Emails", value: "Emails" },
            ]}
            onChange={handleChange}
            displayEmpty
            placeholder="Select one"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomFormLabel>Status</CustomFormLabel>
          <ControlledCustomSelect
            name="type"
            fullWidth
            value={values.status}
            options={[
              { label: "Active", value: "ACTIVE" },
              { label: "Inactive", value: "INACTIVE" },
            ]}
            onChange={handleChange}
            displayEmpty
            placeholder="Select one"
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};
