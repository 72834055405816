/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useFormik } from "formik";
import {
  Box,
  Grid,
} from "@mui/material";
import { useDispatchWrapper } from "../../../../../../hooks";

import { showMessage } from "../../../../../../redux/messages/messagesActions";
import { Dialog } from "../../../../../../components/Dialogs/Dialog";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import {
  CustomCheckBoxWithLabel,
} from "../../../../../../components/formsComponents";
import { api } from "../../../../../../api/api";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { IBusinessPolicyMarketingBinderPdf } from "../../../../../../redux/businessPolicy/businessPolicyMarketing/businessPolicyMarketing.types";
import {  businessPolicyPreviewMarketingAsync } from "../../../../../../redux/businessPolicy/businessPolicyMarketing/businessPolicyMarketingActions";

const INITIAL_MARKETING_PREVIEW: IBusinessPolicyMarketingBinderPdf = {
  isBinderPdf: false,
  isBinderPreview: false,
  isMarketSubmissionPreview: false,
  customer_policy_id: 0,
  generalInformation: true,
  priorPolicy: true,
  underwriting: true,
  claims: true,
  riskLocation: true,
  coverageSummary: true,
  equipment: true,
  toolFloater: true,
  fineArt: true,
  restaurantSupplement: true,
  contractorSupplement: true,
  attachment: true,
};


export const BinderPreview: React.FC<{
  open: boolean;
  customerPolicyId: number;
  onClose: () => void;
  onComplete: () => void;
}> = (props) => {
  const { open, customerPolicyId, onClose } = props;

  const [loadingPreview, setOpenLoadingPreview] = React.useState(false);

  const dispatch = useDispatchWrapper();
  const [loading, setLoading] = React.useState(false);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      req_for_quotation: true,
      preview: INITIAL_MARKETING_PREVIEW,

      email_body: "",
      subject: "",
      bcc_email: "",
    },
    validate: (values) => {
      
    },
    onSubmit: (values) => {
   
    },
  });



  const handlePreview = async () => {
    setOpenLoadingPreview(true);
    dispatch(
      businessPolicyPreviewMarketingAsync(
        { ...values.preview, customer_policy_id: customerPolicyId,isBinderPreview: true },
        (isSuccess, htmlJson) => {
          if (isSuccess && htmlJson) {
            for (const key in htmlJson.data) {
              const newTab = window.open("", "_blank");
              if (newTab) {
                newTab.document.write(htmlJson.data[key]);
                newTab.document.close();
              }
            }
          }
          setOpenLoadingPreview(false);
        }
      )
    );
  };

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get(
          `/policy/get-is-record-exist-in-every-module?customer_policy_id=${customerPolicyId}`
        );
        setValues({
          ...values,
          preview: {
            customer_policy_id: customerPolicyId,
            ...response.data.data,
          },
        });
      } catch (err: any) {
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Dialog
      open={open}
      title="Binder Submission Preview"
      onClose={onClose}
      size="lg"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      actions={[
        {
          type: "button",
          label: loadingPreview ? "Wait..." : "Preview",
          variant: "contained",
          disabled: loadingPreview,
          onClick: handlePreview,
        },
      
      ]}
    >
      <PageLoader loading={loading}>
        <>
 
          <Box sx={{ mt: 2 }}>
         
              <>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Request for Quotation"
                      name="req_for_quotation"
                      checked={values.req_for_quotation}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="General Information"
                      name="preview.generalInformation"
                      checked={values.preview.generalInformation}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Prior Policies"
                      name="preview.priorPolicy"
                      checked={values.preview.priorPolicy}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Underwriting"
                      name="preview.underwriting"
                      checked={values.preview.underwriting}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Claims"
                      name="preview.claims"
                      checked={values.preview.claims}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Risk Locations"
                      name="preview.riskLocation"
                      checked={values.preview.riskLocation}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Coverage Summary"
                      name="preview.coverageSummary"
                      checked={values.preview.coverageSummary}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Restaurant Questionnaire"
                      name="preview.restaurantSupplement"
                      checked={values.preview.restaurantSupplement}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Contractor Questionnaire"
                      name="preview.contractorSupplement"
                      checked={values.preview.contractorSupplement}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Equipments"
                      name="preview.equipment"
                      checked={values.preview.equipment}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Fine Art"
                      name="preview.fineArt"
                      checked={values.preview.fineArt}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Tool Floater"
                      name="preview.toolFloater"
                      checked={values.preview.toolFloater}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Attachments"
                      name="preview.attachment"
                      checked={values.preview.attachment}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </>
            
 
          </Box>
        </>
      </PageLoader>
    </Dialog>
  );
};
