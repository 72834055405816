import React from "react";
import { useNavigate } from "react-router-dom";
import { StandadCard } from "../../components/Cards";
import { PageLoader } from "../../components/PageLoader/PageLoader";
import { PageContainer } from "../../components/container/PageContainer";
import { DataTable } from "../../components/Table/DataTable";
import { IPolicy } from "../../redux/allPolicies/Policies.types";
import { getPolicyUrl } from "../../helpers/policyUrl";
import { Box, Button, Link } from "@mui/material";
import { api } from "../../api/api";
import {
  IDataTableProps,
  RenderType,
} from "../../components/Table/interfaces/IDataTableProps";
import { StatusRenderer } from "../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadState } from "../../constants/enums";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../redux/messages/messagesActions";
import { useDispatchWrapper } from "../../hooks";
import { PageTitleBar } from "../../components/PageTitleBar/PageTitleBar";

interface ContactInfo {
  agencyId: string | null;
  fullName: string | null;
  policyNumber: string | null;
  address: string | null;
}
interface ManageCSIOProps {
  values: any;
  rawDataLoading: any;
}

type TableData = {
  agencyIdData: any[];
  customerName: any[];
  policyNumber: any[];
  addressData: any[];
};

export const CsioPotentialMatches: React.FC<ManageCSIOProps> = ({
  values,
  rawDataLoading,
}) => {
  const navigate = useNavigate();
  const [contactInfo, setContactInfo] = React.useState<ContactInfo>({
    agencyId: null,
    fullName: null,
    policyNumber: null,
    address: null,
  });
  const [tableData, setTableData] = React.useState<TableData>({
    agencyIdData: [],
    customerName: [],
    policyNumber: [],
    addressData: [],
  });
  const [loading, setLoading] = React.useState(false);
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });

  const dispatch = useDispatchWrapper();

  const redirectToPolicy = (row: IPolicy, displayValue: string) => {
    const url = getPolicyUrl(
      row.line_of_business,
      row.customer_id,
      row.customer_policy_id
    );
    return (
      <Link
        sx={{ cursor: "pointer", textDecoration: "none" }}
        onClick={() => navigate(url)}
      >
        {displayValue}
      </Link>
    );
  };

  React.useEffect(() => {
    if (values?.policy_number) {
      setContactInfo((prev) => ({
        ...prev,
        policyNumber: values.policy_number,
      }));
    }
    if (values && values?.xml_json_message) {
      const parsedData = JSON.parse(values?.xml_json_message);
      console.log("parsed Data: ", parsedData);
      const homePolicyAddRs =
        parsedData?.ACORD?.InsuranceSvcRs?.HomePolicyAddRs ||
        parsedData?.ACORD?.InsuranceSvcRs?.HomePolicyModRs;

      const persAutoPolicyAddRs =
        parsedData?.ACORD?.InsuranceSvcRs?.PersAutoPolicyAddRs ||
        parsedData?.ACORD?.InsuranceSvcRs?.PersAutoPolicyModRs;

      const activityNoteRs =
        parsedData?.CommonSvcRs?.ActivityNoteRs ||
        parsedData?.ACORD?.CommonSvcRs?.ActivityNoteRs;
      // for acord:CommonSvcRs
      const acordCommonSvcRs = parsedData?.["acord:CommonSvcRs"];

      if (persAutoPolicyAddRs || homePolicyAddRs) {
        const { PersPolicy, InsuredOrPrincipal } =
          persAutoPolicyAddRs || homePolicyAddRs;

        const customerDetails = Array.isArray(InsuredOrPrincipal)
          ? InsuredOrPrincipal
          : [InsuredOrPrincipal];

        let applicantDetails = {
          address: "",
          name: "",
        };

        const names: any[] = [];
        customerDetails.forEach((detail: any) => {
          const nameInfo = detail?.GeneralPartyInfo?.NameInfo;
          const commercialName = nameInfo?.CommlName;
          const personName = nameInfo?.PersonName;
          if (personName) {
            const fullName =
              `${personName?.GivenName} ${personName?.Surname}`.trim();
            names.push(fullName);
          }
          if (commercialName) {
            names.push(commercialName?.CommercialName);
          }

          const addr = detail?.GeneralPartyInfo?.Addr;
          if (addr) {
            applicantDetails.address = addr?.Addr1;
          }
        });
        applicantDetails.name = names.join(" & ");

        let agencyId: string | null = null;

        if (Array.isArray(InsuredOrPrincipal)) {
          if (InsuredOrPrincipal.length > 0) {
            agencyId = InsuredOrPrincipal[0]?.ItemIdInfo?.AgencyId || null;
          }
        } else {
          agencyId = InsuredOrPrincipal?.ItemIdInfo?.AgencyId || null;
        }
        const fullName = applicantDetails.name;
        const address = applicantDetails.address;
        const policyNumber = PersPolicy?.PolicyNumber || null;

        console.log("agencyId", agencyId);

        setContactInfo({
          agencyId,
          fullName,
          policyNumber,
          address,
        });
      }
      if (activityNoteRs || acordCommonSvcRs) {
        const acordCommonSvcRs = parsedData?.["acord:CommonSvcRs"];
        const acordActivityNoteRs = acordCommonSvcRs?.["acord:ActivityNoteRs"];
        const partialPolicy = acordActivityNoteRs?.["acord:PartialPolicy"];
        const generalPartyInfo =
          acordActivityNoteRs?.["acord:InsuredOrPrincipal"]?.[
            "acord:GeneralPartyInfo"
          ];
        const agencyId = null;
        const fullName =
          activityNoteRs?.InsuredOrPrincipal?.GeneralPartyInfo?.NameInfo
            ?.CommlName?.CommercialName ||
          generalPartyInfo?.["acord:NameInfo"]?.["acord:CommlName"]?.[
            "acord:CommercialName"
          ] ||
          null;
        const policyNumber =
          activityNoteRs?.PartialPolicy?.PolicyNumber ||
          partialPolicy?.["acord:PolicyNumber"] ||
          null;
        const address = null;

        setContactInfo({
          agencyId,
          fullName,
          policyNumber,
          address,
        });
      }
    }
  }, [values]);

  const fetchData = React.useCallback(async () => {
    setLoading(true);
    const { agencyId, fullName, policyNumber, address } = contactInfo;
    const { pageNumber, rowsInPerPage } = tablePagination;

    const fetchDetails = async (column: string, value: string | null) => {
      if (!value) return [];
      try {
        const res = await api.get(
          `/policy/get-all-customer-policy-details?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&column=${column}&value=${value}`
        );
        return res.data.data;
      } catch (err: any) {
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
        return [];
      }
    };

    try {
      const [agencyIdData, customerName, policyNumberData, addressData] =
        await Promise.all([
          fetchDetails("customer_code", agencyId),
          fetchDetails("customer_name", fullName),
          fetchDetails("policy_number", policyNumber),
          fetchDetails("address", address),
        ]);

      setTableData({
        agencyIdData,
        customerName,
        policyNumber: policyNumberData,
        addressData,
      });
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  }, [contactInfo, tablePagination, dispatch]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleViewMapClick = async (row: any) => {
    try {
      dispatch(saveLoaderProgress());
      const payload = {
        message_guid: values.message_guid,
        customer_policy_id: row.customer_policy_id,
      };

      await api.post("/csio/csio-manual-data-mapping", payload);
      dispatch(
        showMessage({
          type: "success",
          message: "Mapping completed successfully",
          displayAs: "dialog",
        })
      );
    } catch (error: any) {
      console.error("API Error:", error);
      dispatch(
        showMessage({
          type: "error",
          message: error.response.data.message,
          displayAs: "dialog",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

  const csioTableProps: IDataTableProps = {
    isPagination: true,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    isDataLoading: loading,
    columns: [
      {
        key: "status",
        headerName: "Status",
        fieldName: "policy_status",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,

        onRowCellRender: (value, row) => {
          return <StatusRenderer status={row.policy_status} />;
        },
      },
      {
        key: "customer_policy_id",
        headerName: "Policy ID",
        fieldName: "customer_policy_id",
        renderType: RenderType.CUSTOM_RENDER,
        enableSorting: true,
        onRowCellRender: (value, row) => {
          return redirectToPolicy(row, value.value);
        },
      },
      {
        key: "customer_name",
        headerName: "Customer Name",
        fieldName: "customer_name",
        renderType: RenderType.CUSTOM_RENDER,
        enableSorting: true,
        onRowCellRender: (value, row: IPolicy) => {
          return redirectToPolicy(
            row,
            `${row.customer_name || "--"} (${row.customer_code || "N/A"})`
          );
        },
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
      },
      {
        key: "branch_code",
        headerName: "Branch Code",
        fieldName: "branch_code",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
        enableSorting: false,
      },
      {
        key: "line_of_business",
        headerName: "Line of Business",
        fieldName: "line_of_business",
        enableSorting: false,
        renderType: RenderType.TEXT,
        exportCellWidth: 25,
      },
      {
        key: "is_map",
        headerName: "Is Mapped?",
        fieldName: "is_map",
        enableSorting: false,
        renderType: RenderType.TEXT,
        exportCellWidth: 25,
        onRowCellRender: (value, row) => {
          return row.customer_policy_id === values.customer_policy_id ? (
            <CheckCircleRoundedIcon fontSize="medium" color="success" />
          ) : (
            <CancelIcon fontSize="medium" color="error" />
          );
        },
      },
      {
        key: "document_type",
        headerName: "Map & Merge",
        fieldName: "document_type",
        enableSorting: false,
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 25,
        onRowCellRender: (value, row) => {
          return (
            <Button variant="contained" onClick={() => handleViewMapClick(row)}>
              Initiate Mapping
            </Button>
          );
        },
      },
    ],
    items: tableData.agencyIdData,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
  };
  const customerTableProps: IDataTableProps = {
    isPagination: true,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    isDataLoading: loading,
    columns: [
      {
        key: "status",
        headerName: "Status",
        fieldName: "policy_status",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,

        onRowCellRender: (value, row) => {
          return <StatusRenderer status={row.policy_status} />;
        },
      },
      {
        key: "customer_policy_id",
        headerName: "Policy ID",
        fieldName: "customer_policy_id",
        renderType: RenderType.CUSTOM_RENDER,
        enableSorting: true,
        onRowCellRender: (value, row) => {
          return redirectToPolicy(row, value.value);
        },
      },
      {
        key: "customer_name",
        headerName: "Customer Name",
        fieldName: "customer_name",
        renderType: RenderType.CUSTOM_RENDER,
        enableSorting: true,
        onRowCellRender: (value, row: IPolicy) => {
          return redirectToPolicy(
            row,
            `${row.customer_name || "--"} (${row.customer_code || "N/A"})`
          );
        },
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
      },
      {
        key: "branch_code",
        headerName: "Branch Code",
        fieldName: "branch_code",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
        enableSorting: false,
      },
      {
        key: "line_of_business",
        headerName: "Line of Business",
        fieldName: "line_of_business",
        enableSorting: false,
        renderType: RenderType.TEXT,
        exportCellWidth: 25,
      },
      {
        key: "is_map",
        headerName: "Is Mapped?",
        fieldName: "is_map",
        enableSorting: false,
        renderType: RenderType.TEXT,
        exportCellWidth: 25,
        onRowCellRender: (value, row) => {
          return row.customer_policy_id === values.customer_policy_id ? (
            <CheckCircleRoundedIcon fontSize="medium" color="success" />
          ) : (
            <CancelIcon fontSize="medium" color="error" />
          );
        },
      },
      {
        key: "document_type",
        headerName: "Map & Merge",
        fieldName: "document_type",
        enableSorting: false,
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 25,
        onRowCellRender: (value, row) => {
          return (
            <Button variant="contained" onClick={() => handleViewMapClick(row)}>
              Initiate Mapping
            </Button>
          );
        },
      },
    ],
    items: tableData.customerName,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
  };
  const policyNumberTableProps: IDataTableProps = {
    isPagination: true,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    isDataLoading: loading,
    columns: [
      {
        key: "status",
        headerName: "Status",
        fieldName: "policy_status",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,

        onRowCellRender: (value, row) => {
          return <StatusRenderer status={row.policy_status} />;
        },
      },
      {
        key: "customer_policy_id",
        headerName: "Policy ID",
        fieldName: "customer_policy_id",
        renderType: RenderType.CUSTOM_RENDER,
        enableSorting: true,
        onRowCellRender: (value, row) => {
          return redirectToPolicy(row, value.value);
        },
      },
      {
        key: "customer_name",
        headerName: "Customer Name",
        fieldName: "customer_name",
        renderType: RenderType.CUSTOM_RENDER,
        enableSorting: true,
        onRowCellRender: (value, row: IPolicy) => {
          return redirectToPolicy(
            row,
            `${row.customer_name || "--"} (${row.customer_code || "N/A"})`
          );
        },
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
      },
      {
        key: "branch_code",
        headerName: "Branch Code",
        fieldName: "branch_code",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
        enableSorting: false,
      },
      {
        key: "line_of_business",
        headerName: "Line of Business",
        fieldName: "line_of_business",
        enableSorting: false,
        renderType: RenderType.TEXT,
        exportCellWidth: 25,
      },
      {
        key: "is_map",
        headerName: "Is Mapped?",
        fieldName: "is_map",
        enableSorting: false,
        renderType: RenderType.TEXT,
        exportCellWidth: 25,
        onRowCellRender: (value, row) => {
          return row.customer_policy_id === values.customer_policy_id ? (
            <CheckCircleRoundedIcon fontSize="medium" color="success" />
          ) : (
            <CancelIcon fontSize="medium" color="error" />
          );
        },
      },
      {
        key: "document_type",
        headerName: "Map & Merge",
        fieldName: "document_type",
        enableSorting: false,
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 25,
        onRowCellRender: (value, row) => {
          return (
            <Button variant="contained" onClick={() => handleViewMapClick(row)}>
              Initiate Mapping
            </Button>
          );
        },
      },
    ],
    items: tableData.policyNumber,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
  };
  const addressTableProps: IDataTableProps = {
    isPagination: true,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    isDataLoading: loading,
    columns: [
      {
        key: "status",
        headerName: "Status",
        fieldName: "policy_status",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,

        onRowCellRender: (value, row) => {
          return <StatusRenderer status={row.policy_status} />;
        },
      },
      {
        key: "customer_policy_id",
        headerName: "Policy ID",
        fieldName: "customer_policy_id",
        renderType: RenderType.CUSTOM_RENDER,
        enableSorting: true,
        onRowCellRender: (value, row) => {
          return redirectToPolicy(row, value.value);
        },
      },
      {
        key: "customer_name",
        headerName: "Customer Name",
        fieldName: "customer_name",
        renderType: RenderType.CUSTOM_RENDER,
        enableSorting: true,
        onRowCellRender: (value, row: IPolicy) => {
          return redirectToPolicy(
            row,
            `${row.customer_name || "--"} (${row.customer_code || "N/A"})`
          );
        },
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
      },
      {
        key: "branch_code",
        headerName: "Branch Code",
        fieldName: "branch_code",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
        enableSorting: false,
      },
      {
        key: "line_of_business",
        headerName: "Line of Business",
        fieldName: "line_of_business",
        enableSorting: false,
        renderType: RenderType.TEXT,
        exportCellWidth: 25,
      },
      {
        key: "is_map",
        headerName: "Is Mapped?",
        fieldName: "is_map",
        enableSorting: false,
        renderType: RenderType.TEXT,
        exportCellWidth: 25,
        onRowCellRender: (value, row) => {
          return row.customer_policy_id === values.customer_policy_id ? (
            <CheckCircleRoundedIcon fontSize="medium" color="success" />
          ) : (
            <CancelIcon fontSize="medium" color="error" />
          );
        },
      },
      {
        key: "document_type",
        headerName: "Map & Merge",
        fieldName: "document_type",
        enableSorting: false,
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 25,
        onRowCellRender: (value, row) => {
          return (
            <Button variant="contained" onClick={() => handleViewMapClick(row)}>
              Initiate Mapping
            </Button>
          );
        },
      },
    ],
    items: tableData.addressData,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
  };
  return (
    <PageLoader loading={rawDataLoading === LoadState.InProgress}>
      <Box mt={4}>
        <PageTitleBar
          sx={{ mt: 2 }}
          heading={"Potential Matches based on Policy Number"}
        />
        <DataTable {...policyNumberTableProps} />
        {values.message_type !== "EDI" && (
          <>
            <PageTitleBar
              sx={{ mt: 2 }}
              heading={"Potential Matches based on Customer Code"}
            />
            <DataTable {...csioTableProps} />

            <PageTitleBar
              sx={{ mt: 2 }}
              heading={"Potential Matches based on Customer Name"}
            />
            <DataTable {...customerTableProps} />

            <PageTitleBar
              sx={{ mt: 2 }}
              heading={"Potential Matches based on Address"}
            />
            <DataTable {...addressTableProps} />
          </>
        )}
      </Box>
    </PageLoader>
  );
};
