import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialTaskLessorState } from "./lessor.types";
import { TaskLessorActions } from ".";
import { FETCH_ADD_LESSOR_FAILED, FETCH_ADD_LESSOR_PROGRESS, FETCH_ADD_LESSOR_SUCCESS, FETCH_CHANGE_LESSOR_FAILED, FETCH_CHANGE_LESSOR_PROGRESS, FETCH_CHANGE_LESSOR_SUCCESS, FETCH_DELETE_LESSOR_FAILED, FETCH_DELETE_LESSOR_PROGRESS, FETCH_DELETE_LESSOR_SUCCESS } from "./lessorActions";

export const taskLessorReducer = (
    state: IStoreState["tasks"]["lessor"] = initialTaskLessorState,
    action: TaskLessorActions
  ) => {
    switch (action.type) {
        case FETCH_ADD_LESSOR_PROGRESS: {
            const newState = produce(state, draftState=>{
                draftState.addLessor.loading = LoadState.InProgress;
                draftState.addLessor = initialTaskLessorState['addLessor'];
            })
            return newState;
        }
        case FETCH_ADD_LESSOR_SUCCESS: {
            const {data} = action.payload;
            const newState = produce(state, draftState=>{
                draftState.addLessor.loading = LoadState.Loaded;
                draftState.addLessor.data = data;
            })
            return newState;
        }
        case FETCH_ADD_LESSOR_FAILED: {
            const newState = produce(state, draftState=>{
                draftState.addLessor.loading = LoadState.Failed;
            })
            return newState;
        }

        case FETCH_DELETE_LESSOR_PROGRESS: {
            const newState = produce(state, draftState=>{
                draftState.deleteLessor.loading = LoadState.InProgress;
                draftState.deleteLessor = initialTaskLessorState['deleteLessor'];
            })
            return newState;
        }
        case FETCH_DELETE_LESSOR_SUCCESS: {
            const {data} = action.payload;
            const newState = produce(state, draftState=>{
                draftState.deleteLessor.loading = LoadState.Loaded;
                draftState.deleteLessor.data = data;
            })
            return newState;
        }
        case FETCH_DELETE_LESSOR_FAILED: {
            const newState = produce(state, draftState=>{
                draftState.deleteLessor.loading = LoadState.Failed;
            })
            return newState;
        }

        case FETCH_CHANGE_LESSOR_PROGRESS: {
            const newState = produce(state, draftState=>{
                draftState.changeLessor.loading = LoadState.InProgress;
                draftState.changeLessor = initialTaskLessorState['changeLessor'];
            })
            return newState;
        }
        case FETCH_CHANGE_LESSOR_SUCCESS: {
            const {data} = action.payload;
            const newState = produce(state, draftState=>{
                draftState.changeLessor.loading = LoadState.Loaded;
                draftState.changeLessor.data = data;
            })
            return newState;
        }
        case FETCH_CHANGE_LESSOR_FAILED: {
            const newState = produce(state, draftState=>{
                draftState.changeLessor.loading = LoadState.Failed;
            })
            return newState;
        }
        
        default:
            return state;
    }
  }