import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { showMessage } from "../../messages/messagesActions";
import { IlifeInsurancePolicyExistingProperty } from "./lifeInsurancePolicyExistingProperty.types";


export const FETCH_LIFE_INSURANCE_EXISTING_PROPERTY_PROGRESS =
  "FETCH_LIFE_INSURANCE_EXISTING_PROPERTY_PROGRESS";
export const FETCH_LIFE_INSURANCE_EXISTING_PROPERTY_SUCCESS =
  "FETCH_LIFE_INSURANCE_EXISTING_PROPERTY_SUCCESS";
export const FETCH_LIFE_INSURANCE_EXISTING_PROPERTY_FAILED =
  "FETCH_LIFE_INSURANCE_EXISTING_PROPERTY_FAILED";

export const fetchlifeInsurancePolicyExistingPropertyProgress = () =>
  action(FETCH_LIFE_INSURANCE_EXISTING_PROPERTY_PROGRESS);
export const fetchlifeInsurancePolicyExistingPropertySuccess = (
  data: IlifeInsurancePolicyExistingProperty
) => action(FETCH_LIFE_INSURANCE_EXISTING_PROPERTY_SUCCESS, { data });
export const fetchlifeInsurancePolicyExistingPropertyFailed = (errorMessage: string) =>
  action(FETCH_LIFE_INSURANCE_EXISTING_PROPERTY_FAILED, { errorMessage });

export const fetchlifeInsurancePolicyExistingPropertyAsync =
  (
    customerPolicyId: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchlifeInsurancePolicyExistingPropertyProgress());
        const res = await api.get(
          `/policy/get-life-policy-additional-information-existing-property?customer_policy_id=${customerPolicyId}`
        );
        const data: IlifeInsurancePolicyExistingProperty[] = res.data.data;
        if (data.length > 0) {
          console.log("data from action",data)
          dispatch(fetchlifeInsurancePolicyExistingPropertySuccess(data[0]));
        }
        else {
          dispatch(
            fetchlifeInsurancePolicyExistingPropertyFailed(
              "Oops! We couldn't find any records associated with your restrutant number at the moment. Please ensure that the provided information is accurate and try again."
            )
          );
        }
      } catch (err: any) {
        dispatch(
          fetchlifeInsurancePolicyExistingPropertyFailed("Something went to be wrong!")
        );
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      }
    };

export const addlifeInsurancePolicyExistingPropertyAsync =
  (
    policy: IlifeInsurancePolicyExistingProperty,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        await api.post(
          `/policy/edit-life-policy-additional-information-existing-property`,
          policy
        );
        dispatch(fetchlifeInsurancePolicyExistingPropertySuccess(policy))
        dispatch(
          showMessage({
            type: "success",
            message: "Policy saved successfully!",
            displayAs: "snackbar",
          })
        );
        onCallback(true);
      } catch (err: any) {
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
        onCallback(false);
      }
    };

export const CLEAR_LIFE_INSURANCE_EXISTING_PROPERTY_STATE =
  "CLEAR_LIFE_INSURANCE_EXISTING_PROPERTY_STATE";
export const CLEAR_LIFE_INSURANCE_EXISTING_PROPERTY =
  "CLEAR_LIFE_INSURANCE_EXISTING_PROPERTY";

export const clearlifeInsurancePolicyExistingPropertyState = () =>
  action(CLEAR_LIFE_INSURANCE_EXISTING_PROPERTY_STATE);

export const clealifeInsurancePolicyExistingProperty = () =>
  action(CLEAR_LIFE_INSURANCE_EXISTING_PROPERTY);




// /policy/edit-life-policy-additional-information-existing-property
// /policy/get-life-policy-additional-information-existing-property