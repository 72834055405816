/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Box,
  CircularProgress,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useCustomerFullInfo, useDispatchWrapper } from "../../../../../../hooks";

import { showMessage } from "../../../../../../redux/messages/messagesActions";
import { Dialog } from "../../../../../../components/Dialogs/Dialog";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import {
  CustomCheckBoxWithLabel,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../components/formsComponents";
import { api } from "../../../../../../api/api";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { IBusinessPolicyMarketingBinderPdf } from "../../../../../../redux/businessPolicy/businessPolicyMarketing/businessPolicyMarketing.types";
import { businessPolicyBinderSendCoverageMailAndHistAsync, businessPolicyGenerateMarketCoveragePdfAsync, businessPolicyPreviewMarketingAsync } from "../../../../../../redux/businessPolicy/businessPolicyMarketing/businessPolicyMarketingActions";
import { downLoadFile } from "../../../../../../components/FileUpload/utils";

const INITIAL_MARKETING_PREVIEW: IBusinessPolicyMarketingBinderPdf = {
  isBinderPdf: false,
  isBinderPreview: false,
  isMarketSubmissionPreview: false,
  customer_policy_id: 0,
  generalInformation: true,
  priorPolicy: true,
  underwriting: true,
  claims: true,
  riskLocation: true,
  coverageSummary: true,
  equipment: true,
  toolFloater: true,
  fineArt: true,
  restaurantSupplement: true,
  contractorSupplement: true,
  attachment: true,
};

const steps = ["Content Selection", "Send Proposal to Insurer"];

export const BinderDialog: React.FC<{
  open: boolean;
  customerPolicyId: number;
  onClose: () => void;
  onComplete: () => void;
}> = (props) => {
  const { open, customerPolicyId, onClose } = props;

  const [activeStep, setActiveStep] = React.useState(0);
  const [loadingPreview, setOpenLoadingPreview] = React.useState(false);

  const dispatch = useDispatchWrapper();
  const [loading, setLoading] = React.useState(false);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const data = useCustomerFullInfo();
  const [filesUrl, setFilesUrl] = useState<string[]>([]);
  // const [filesUrl,setFilesUrl] = useState<string[]>( [
  //   'customer/30/15/Submissions/coverage_report_15_2023-10-02_20:02:18.pdf',
  //   'customer/30/15/Submissions/tool_floater_15_2023-10-02_20:02:26.pdf'
  // ]);
  // console.log(filesUrl)

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      req_for_quotation: true,
      preview: INITIAL_MARKETING_PREVIEW,

      email_body: "",
      subject: "",
      bcc_email: "",
    },
    validate: (values) => {
      if (activeStep === 1) {
        const errors: any = {};
        if (!values.subject) {
          errors.subject = "*Subject is required.";
        } else if (!values.email_body) {
          errors.email_body = "*Body is required.";
        }
        return errors;
      }
    },
    onSubmit: (values) => {
      setSaveLoading(true);
      if (activeStep === 0) {
        dispatch(
          businessPolicyGenerateMarketCoveragePdfAsync(
            { ...values.preview, customer_policy_id: customerPolicyId,isBinderPdf: true },
            (isSuccess: boolean, data: any) => {
              if (isSuccess) {
                setActiveStep(1);
                setFilesUrl([...data?.data]);
              }
              setSaveLoading(false);
            }
          )
        );
      } else {
        if (data) {
          dispatch(
            businessPolicyBinderSendCoverageMailAndHistAsync(
              { ...values.preview, customer_policy_id: customerPolicyId },
              filesUrl,
              values.bcc_email,
              values.subject,
              values.email_body,
              data.email,
              (isSuccess: any) => {
                if (isSuccess) {
                  props.onComplete();
                }
                setSaveLoading(false);
              }
            )
          );
        }
      }
    },
  });

  const handleNext = (step: number) => () => {
    if (activeStep === 1) {
      setActiveStep(step);
    }
  };

  const handlePreview = async () => {
    setOpenLoadingPreview(true);
    dispatch(
      businessPolicyPreviewMarketingAsync(
        { ...values.preview, customer_policy_id: customerPolicyId,isBinderPreview: true },
        (isSuccess, htmlJson) => {
          if (isSuccess && htmlJson) {
            for (const key in htmlJson.data) {
              const newTab = window.open("", "_blank");
              if (newTab) {
                newTab.document.write(htmlJson.data[key]);
                newTab.document.close();
              }
            }
          }
          setOpenLoadingPreview(false);
        }
      )
    );
  };

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get(
          `/policy/get-is-record-exist-in-every-module?customer_policy_id=${customerPolicyId}`
        );
        setValues({
          ...values,
          preview: {
            customer_policy_id: customerPolicyId,
            ...response.data.data,
          },
        });
      } catch (err: any) {
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Dialog
      open={open}
      title="Binder Submission"
      onClose={onClose}
      size="lg"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      actions={[
        {
          type: "button",
          label: loadingPreview ? "Wait..." : "Preview",
          variant: "contained",
          disabled: loadingPreview,
          onClick: handlePreview,
        },
        {
          type: "submit",
          label:
            activeStep === 0 ? (
              "Save & Next"
            ) : saveLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Save"
            ),
          disabled: !values.req_for_quotation || saveLoading,
          variant: "contained",
        },
      ]}
    >
      <PageLoader loading={loading}>
        <>
          <Stepper activeStep={activeStep} variant="outlined">
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};

              return (
                <Step key={label} {...stepProps} onClick={handleNext(index)}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <Box sx={{ mt: 2 }}>
            {activeStep === 0 && (
              <>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Request for Quotation"
                      name="req_for_quotation"
                      checked={values.req_for_quotation}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="General Information"
                      name="preview.generalInformation"
                      checked={values.preview.generalInformation}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Prior Policies"
                      name="preview.priorPolicy"
                      checked={values.preview.priorPolicy}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Underwriting"
                      name="preview.underwriting"
                      checked={values.preview.underwriting}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Claims"
                      name="preview.claims"
                      checked={values.preview.claims}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Risk Locations"
                      name="preview.riskLocation"
                      checked={values.preview.riskLocation}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Coverage Summary"
                      name="preview.coverageSummary"
                      checked={values.preview.coverageSummary}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Restaurant Questionnaire"
                      name="preview.restaurantSupplement"
                      checked={values.preview.restaurantSupplement}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Contractor Questionnaire"
                      name="preview.contractorSupplement"
                      checked={values.preview.contractorSupplement}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Equipments"
                      name="preview.equipment"
                      checked={values.preview.equipment}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Fine Art"
                      name="preview.fineArt"
                      checked={values.preview.fineArt}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Tool Floater"
                      name="preview.toolFloater"
                      checked={values.preview.toolFloater}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Attachments"
                      name="preview.attachment"
                      checked={values.preview.attachment}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {activeStep === 1 && (
              <>
                <Typography variant="h4" fontWeight={"bold"}>
                  Enter the Subject and Message Body Below
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <CustomFormLabel>BCC Emails</CustomFormLabel>
                    <CustomTextField
                      name="bcc_email"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      value={values.bcc_email}
                      // error={errors.name ? true : false}
                      // helperText={errors.name}
                      // disabled={isUpdateLead}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <CustomFormLabel>Subject</CustomFormLabel>
                    <CustomTextField
                      name="subject"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      value={values.subject}
                      error={errors.subject ? true : false}
                      helperText={errors.subject}
                      // disabled={isUpdateLead}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <CustomFormLabel>Email Body</CustomFormLabel>
                    <CustomTextField
                      name="email_body"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      multiline
                      rows={4}
                      value={values.email_body}
                      error={errors.email_body ? true : false}
                      helperText={errors.email_body}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Typography variant="h4" fontWeight={"bold"} sx={{ mt: 2 }}>
                  Download All Files
                </Typography>
                {/* {attachmentLoading === LoadState.InProgress && (
                  <Typography sx={{ color: "primary.main" }}>
                    Please wait....
                  </Typography>
                )} */}
                {filesUrl.map((attachment) => {
                  // const data = attachment.split("/");
                  return (
                    <Typography
                      fontSize={"1rem"}
                      sx={{ color: "primary.main", cursor: "pointer" }}
                      onClick={()=>{
                        downLoadFile(attachment);
                      }}
                    >
                      {attachment}
                    </Typography>
                  );
                })}
              </>
            )}
          </Box>
        </>
      </PageLoader>
    </Dialog>
  );
};
