import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { LoadState } from "../../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";
import { fetchCommAutomobileDriverConvitionsListAsync } from "../../../../../../../redux/commAutoPolicy/commAutoDriver/commAutoDriverActions";
import { ICommAutomobileDriverConviction } from "../../../../../../../redux/commAutoPolicy/commAutoDriver/commAutoDriver.types";

export const CommAutomobileDriverConvictionList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { driverConvictionList , loading } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.driver.driverConviction
  );

  const dispatch = useDispatchWrapper();

  React.useEffect(() => {
    dispatch(fetchCommAutomobileDriverConvitionsListAsync(customerPolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerPolicyId]);

  const automobileDriverTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    `/comm-automobile-policy/${customerId}/driver-tab/${customerPolicyId}/conviction`
                  );
                }}
              >
                Add Driver Conviction
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: ICommAutomobileDriverConviction) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/driver-tab/${customerPolicyId}/conviction/${row.incremental_number}`
                );
              }}
            >
              {row.incremental_number}
            </Link>
          );
        },
      },
      {
        key: "driver_number",
        headerName: "Driver Number",
        fieldName: "driver_number",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
        onRowCellRender: (value, row: ICommAutomobileDriverConviction) => {
          return (
            <Link
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate(
                `/comm-automobile-policy/${customerId}/driver-tab/${customerPolicyId}/conviction/${row.incremental_number}`
              );
            }}
          >
            {row.driver_number}
            </Link>
          );
        },
      },
      {
        key: "date_of_conviction",
        headerName: "Date of Conviction",
        fieldName: "date_of_conviction",
        exportCellWidth: 25,
        renderType: RenderType.DATE,
      },
      {
        key: "conviction_code",
        headerName: "Conviction Code",
        fieldName: "conviction_code",
        exportCellWidth: 25,
        renderType: RenderType.CHIP_WARNING,
      },

      {
        key: "conviction_description",
        headerName: "Conviction Description",
        fieldName: "conviction_description",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "date_of_conviction",
        headerName: "Date Created",
        fieldName: "date_of_conviction",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: 'center',
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobileDriverConviction) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/driver-tab/${customerPolicyId}/conviction/${row.incremental_number}`
                );
              }}
            />
          );
        },
       
      },
    ],
    items: driverConvictionList,
  };

  return (
      <Box sx={{ mt: 2 }}>
        <DataTable {...automobileDriverTableProps} />
      </Box>

  );
};
