import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialPolicyNotesState } from "./notes.types";
import { NotesActions } from ".";
import { CLEAR_NOTE, CLEAR_NOTE_STATE, FETCH_NOTE_FAILED, FETCH_NOTE_LIST_FAILED, FETCH_NOTE_LIST_PROGRESS, FETCH_NOTE_LIST_SUCCESS, FETCH_NOTE_PROGRESS, FETCH_NOTE_SUCCESS } from "./notesActions";

export const notesReducer = (
  state: IStoreState["policyNotes"] = initialPolicyNotesState,
  action: NotesActions
) => {
  switch (action.type) {
    case FETCH_NOTE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }
    case FETCH_NOTE_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
      });
      return newState;
    }
    case FETCH_NOTE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }
    case FETCH_NOTE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.noteLoading = LoadState.InProgress;
        draftState.note = initialPolicyNotesState['note'];
      });
      return newState;
    }
    case FETCH_NOTE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.noteLoading = LoadState.Loaded;
        draftState.note = data;
      });
      return newState;
    }
    case FETCH_NOTE_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.noteLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_NOTE: {
      const newState = produce(state, (draftState) => {
        draftState.noteLoading = LoadState.NotLoaded;
        draftState.note = initialPolicyNotesState["note"];
      });
      return newState;
    }
    case CLEAR_NOTE_STATE: {
      return initialPolicyNotesState;
    }
    default: {
      return state;
    }
  }
};
