import { action } from "typesafe-actions";
import {
  IAutomobileCSIOEDIApplication,
  initialAutomobileCSIOEDIApplicationState,
} from "./automobileCSIOEdiApplication.types";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";

export const FETCH_AUTOMOBILE_EDI_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_EDI_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_EDI_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_EDI_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_EDI_LIST_FAILED =
  "FETCH_AUTOMOBILE_EDI_LIST_FAILED";

export const fetchAutomobileEDIListProgress = () =>
  action(FETCH_AUTOMOBILE_EDI_LIST_PROGRESS);
export const fetchAutomobileEDIListSuccess = (
  data: IAutomobileCSIOEDIApplication[],
  totalRecords: number
) =>
  action(FETCH_AUTOMOBILE_EDI_LIST_SUCCESS, {
    data,
    totalRecords,
  });
export const fetchAutomobileEDIListFailed = () =>
  action(FETCH_AUTOMOBILE_EDI_LIST_FAILED);

export const fetchAutomobileEDIListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string | null,
    date: IDate,
    customerPolicyId: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileEDIListProgress());
      let finalUrl = `/csio/get-csio-application-edi?table=Automobile&pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}&customer_policy_id=${customerPolicyId}`;

      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }

      const res = await api.get(finalUrl);
      const data: IAutomobileCSIOEDIApplication[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchAutomobileEDIListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchAutomobileEDIListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_AUTOMOBILE_APPLICATION_EDI_PROGRESS =
  "FETCH_AUTOMOBILE_APPLICATION_EDI_PROGRESS";
export const FETCH_AUTOMOBILE_APPLICATION_EDI_SUCCESS =
  "FETCH_AUTOMOBILE_APPLICATION_EDI_SUCCESS";
export const FETCH_AUTOMOBILE_APPLICATION_EDI_FAILED =
  "FETCH_AUTOMOBILE_APPLICATION_EDI_FAILED";

export const fetchAutomobileApplicationEDIProgress = () =>
  action(FETCH_AUTOMOBILE_APPLICATION_EDI_PROGRESS);

export const fetchAutomobileApplicationEDISuccess = (
  data: IAutomobileCSIOEDIApplication
) =>
  action(FETCH_AUTOMOBILE_APPLICATION_EDI_SUCCESS, {
    data,
  });
export const fetchAutomobileApplicationEDIFailed = () =>
  action(FETCH_AUTOMOBILE_APPLICATION_EDI_FAILED);

export const fetchAutomobileApplicationEDIAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileApplicationEDIProgress());
      const res = await api.get(
        `/csio/get-csio-application-edi?table=Automobile&customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`
      );
      const data: IAutomobileCSIOEDIApplication[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAutomobileApplicationEDISuccess(data[0]));
      } else {
        dispatch(
          fetchAutomobileApplicationEDISuccess(
            initialAutomobileCSIOEDIApplicationState["edi"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchAutomobileApplicationEDIFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };


  export const CLEAR_AUTOMOBILE_EDI_APPLICATION_STATE =
  "CLEAR_AUTOMOBILE_EDI_APPLICATION_STATE";

export const clearAutomobileApplicationEDIState = () =>
  action(CLEAR_AUTOMOBILE_EDI_APPLICATION_STATE);
