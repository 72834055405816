import { useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../hooks";
import React from "react";
import { Grid } from "@mui/material";
import { StandardCellList } from "../../../../components/Standards";
import { formatDate, formatTime } from "../../../../helpers";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../constants/enums";
import {
  fetchTaskPolicyReinstmentAysnc,
} from "../../../../redux/taskUrgentTransactions/taskUrgentTransactionsActions";
import {
  ITaskPolicyReinstment,
} from "../../../../redux/taskUrgentTransactions/taskUrgentTransactions.types";

export const ViewReinstatement: React.FC<{
  taskCode: string;
}> = (props) => {
  const { taskCode } = props;
  const { data, loading } = useSelector(
    (storeState: IStoreState) =>
      storeState.tasks.urgentTransactions.policyReinstment
  );

  const dispatch = useDispatchWrapper();
  const [reinstatementDetails, setReinstatementDetails] =
    React.useState<ITaskPolicyReinstment>();

  React.useEffect(() => {
    dispatch(fetchTaskPolicyReinstmentAysnc(taskCode));
  }, [taskCode]);

  React.useEffect(() => {
    setReinstatementDetails(data);
  }, [data]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      {reinstatementDetails && (
        <Grid container spacing={1} sx={{ mt: 2 }}>

          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Reinstatement Request"
              colSize={3}
              list={[
                {
                  heading: "Reinstatement Date",
                  data: {
                    type: "text",
                    value: formatDate(
                      reinstatementDetails.reinstatement_date || ""
                    ),
                  },
                },
                {
                  heading: "Reinstatement Time",
                  data: {
                    type: "text",
                    value: formatTime(
                      reinstatementDetails.reinstatement_time || ""
                    ),
                  },
                },
                {
                  heading: "Payment Recieved",
                  data: {
                    type: "text",
                    value:
                      `${reinstatementDetails.full ? "Full" : "--"}` ||
                      `${reinstatementDetails.partial ? "Partial" : "--"}` ||
                      `${
                        reinstatementDetails.certified_cheque
                          ? "Certified Cheque"
                          : "--"
                      }`,
                  },
                },
                {
                  heading: "Balance Owing",
                  data: {
                    type: "text",
                    value: reinstatementDetails.balance_owing,
                  },
                },
                {
                  heading: "Reason for Reinstatement",
                  colSize: 12,
                  data: {
                    type: "text",
                    value: reinstatementDetails.reason_for_reinstatement,
                  },
                },
              ]}
            />
          
          </Grid>
       
        </Grid>
      )}
    </PageLoader>
  );
};
