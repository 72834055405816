import React from "react";
import { StandadCard } from "../../../../../../components/Cards";
import {
  AccountCircle,
  AdminPanelSettings,
  Contacts,
  ManageAccounts,
  Receipt,
  StickyNote2
} from "@mui/icons-material";
import { TabPanel } from "../../../../../../components/Tabs/TabPanel";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../constants/enums";
import { useDispatchWrapper, useQueryParms } from "../../../../../../hooks";
import { HabitationalPriorPolicy } from "./PriorPolicy/PriorPolicy";
import { HabitationalCustomerInfo } from "./CustomerInfo/CustomerInfo";
import { HabitationalPolicyAdmin } from "./PolicyAdmin/PolicyAdmin";
import { HabitationalPremium } from "./Premium/Premium";
import { fetchHabitationalCustomerInfoAsync } from "../../../../../../redux/habitationalPolicy/habitationalBasicDetails/habitationalBasicDetailsActions";
import { TabsList } from "../../../../../../components/Tabs/TabsList";
import { useNavigate, useParams } from "react-router-dom";
import { HabitationalPolicyAdminManager } from "./PolicyAdminManager/PolicyAdminManager";
import { usePremissions } from "../../../../../../security/PremissionsProvider/PremissionsProvider";
import { TabsUncontrolledProvider } from "../../../../../../contexts/TabsContextProvider/TabsContextUncontrolledProvider";
import { HabitationalAdditionalInsured } from "./AdditionalInsured/AdditionalInsured";

export const HabitationalBasicDetails = () => {
  const { customerId, customerPolicyId } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
  };
  const { getParm } = useQueryParms();
  const tabValue = getParm("tab");
  const selectedTab = tabValue ? Number(tabValue) : 0;
  const {
    customerInfo,
    customerNotFoundError,
    customerPoliycId: savedCustomerPolicyId,
  } = useSelector(
    (storeState: IStoreState) => storeState.habitational.basicDetails
  );
  const {premissionsList} = usePremissions();
  const showAdminManager = premissionsList.find((x)=>{
    if(x.module_id === MODULE_IDS.HABITATIONAL_POLICY_ADMIN_MANAGER && x.show_module){
      return true;
    }
    return false;
  })

  const dispatch = useDispatchWrapper();

  const navigate = useNavigate();

  const handleChangetab = (event: React.SyntheticEvent, newValue: number) => {
    navigate(
      `/habitational-policy/${customerId}/basic-details/${customerPolicyId}?tab=${newValue}`
    );
  };
  // const changeStep = (newStep: number) => {
  //   setTab(newStep);
  // }


  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchHabitationalCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  if (!customerId) {
    return null;
  }

  return (
    <PageLoader
      loading={customerInfo.loading === LoadState.InProgress}
      error={customerNotFoundError ? { message: customerNotFoundError } : null}
    >
      {/* <TabsUncontrolledProvider step={tab} changeStep={changeStep}> */}
      <TabsList
          sx={{ mb: 1 }}
          varaint="fullWidth"
          selectedTab={selectedTab}
          tabs={[
            { label: "Customer Info", icon: <AccountCircle /> },
            {
              label: "Additional Insured",
              icon: <Contacts />,
              disabled: !savedCustomerPolicyId,
            },
            {
              label: "Policy Admin",
              icon: <StickyNote2 />,
              disabled: !savedCustomerPolicyId,
            },
            {
              label: "Prior Policy",
              icon: <AdminPanelSettings />,
              disabled: !savedCustomerPolicyId,
            },
         
            {
              label: "Policy Admin Manager",
              icon: <ManageAccounts />,
              disabled: !savedCustomerPolicyId,
              hidden: !showAdminManager
            },
            {
              label: "Premium",
              icon: <Receipt />,
              disabled: !savedCustomerPolicyId,
            },
            // {
            //   label: "Activity/Task",
            //   icon: <Receipt />,
            //   disabled: !savedCustomerPolicyId,
            // },
            // {
            //   label: "History",
            //   icon: <StickyNote2 />,
            //   disabled: !savedCustomerPolicyId,
            // },

            // {
            //   label: "Attachments",
            //   icon: <StickyNote2 />,
            //   disabled: !savedCustomerPolicyId,
            // },
          ]}
          onChange={handleChangetab}
        />
      <StandadCard>
     

        <TabPanel value={selectedTab} index={0}>
          <HabitationalCustomerInfo
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        {savedCustomerPolicyId && (
          <>
            <TabPanel value={selectedTab} index={1}>
              <HabitationalAdditionalInsured
                customerpolicyId={savedCustomerPolicyId}
              />
            </TabPanel>
             <TabPanel value={selectedTab} index={2}>
              <HabitationalPolicyAdmin
                customerpolicyId={savedCustomerPolicyId}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={3}>
              <HabitationalPriorPolicy
                customerpolicyId={savedCustomerPolicyId}
              />
            </TabPanel>

         
            <TabPanel value={selectedTab} index={4}>
              <HabitationalPolicyAdminManager
                customerpolicyId={savedCustomerPolicyId}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={5}>
              <HabitationalPremium customerpolicyId={savedCustomerPolicyId} />
            </TabPanel>
            {/* <TabPanel value={tab} index={4}>
              <HabitationalCreateTask />
            </TabPanel>
            <TabPanel value={tab} index={5}>
              <HabitationalHistory customerpolicyId={savedCustomerPolicyId} />
            </TabPanel>
            <TabPanel value={tab} index={6}>
              <HabitationalAttachmentList
                customerPolicyId={savedCustomerPolicyId}
              />
            </TabPanel> */}
          </>
        )}
      </StandadCard>
      {/* </TabsUncontrolledProvider> */}
    </PageLoader>
  );
};
