import React from "react";
import moment from "moment";
import { IG4SignDocumentRecipientGet } from "../../../../redux/g4SignDocuments/g4SignDocuments.types";
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import {
  ControlledCustomSelect,
  CustomLightTypography,
} from "../../../../components/formsComponents";
import { useDispatchWrapper } from "../../../../hooks";
import { Edit } from "@mui/icons-material";
import { CustomChip } from "../../../../components/CustomChip";
import { upsertG4Recipient } from "../../../../redux/g4SignDocuments/g4SignDocumentsAction";

export const SignOrderSelect: React.FC<{
  recipient: IG4SignDocumentRecipientGet;
  recipientLength: number;
  onComplete: () => void;
}> = (props) => {
  const { recipient, recipientLength } = props;
  const [value, setValue] = React.useState({
    ...recipient,
  });
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();

  const handleAssign = () => {
    setLoading(true);
    dispatch(
      upsertG4Recipient(
        {
          ...value,
        },
        (isSuccess) => {
          if (isSuccess) {
            setOpen(false);
            props.onComplete();
          }
          setLoading(false);
        }
      )
    );
  };

  const handleCancel = () => {
    setOpen(false);
  };

  if (!open) {
    return (
      <Stack direction={"row"} spacing={2} justifyContent={"center"} alignItems={'center'}>

          <CustomChip color="warning" size="medium" content={recipient.sign_order} sx={{color: '#000', borderRadius: '100%'}}></CustomChip>
      

        <Tooltip title="Change Sign Order">
          <Edit color="primary" onClick={() => setOpen(true)} />
        </Tooltip>
      </Stack>
    );
  }
  return (
    <>
      {open && (
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Box sx={{ minWidth: 200 }}>
            <ControlledCustomSelect
              fullWidth
              value={value.sign_order}
              options={Array.from(
                { length: recipientLength },
                (_, index) => index
              ).map((x) => {
                return { label: x+1, value: x+1 };
              })}
              onChange={(e)=> setValue({...value, sign_order: e.target.value as number})}
            />
          </Box>
          <Button
            variant="contained"
            size="small"
            onClick={handleAssign}
            disabled={loading}
          >
            Apply
          </Button>
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Stack>
      )}
    </>
  );
};
