import { LoadState } from "../../constants/enums";
import { IInsurerContactsState } from "./insurerContacts.types";

export const initialInsurerContactsState: IInsurerContactsState = {
    insurerContacts: {
        data: {
            insurer_contacts_id: null,
            insurer_contacts_primary_id: null,
            name: null,
            insurer: "",
            position: "",
            lead_comments: null,
            phone: "",
            phone_ext: null,
            mobile_number: null,
            email: null,
            department: null,
            website: null,
            status: "ACTIVE",
            insert_ts: "",
        },
        loading: LoadState.NotLoaded,
        saveLoading: false,
        error: null,
    },

};
