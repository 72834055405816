import { AddOutlined, AppRegistration, AspectRatio } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import React from "react";
import { api } from "../../api/api";
import { StandadCard } from "../../components/Cards";
import { PageContainer } from "../../components/container/PageContainer";
import { DataTable } from "../../components/Table/DataTable";
import {
  IData,
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../components/Table/interfaces/IDataTableProps";
import { RefreshPlugin, SearchPlugin } from "../../components/Table/plugins";
import { Breadcrumb } from "../../layout/fullLayout/breadcrumb/Breadcrumb";
import { IBreadcrumbProps } from "../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useNavigate } from "react-router-dom";
import { IInsurerUnderWriter } from "../../redux/insurer/insurer.types";
import { useDispatch } from "react-redux";
import { showMessage } from "../../redux/messages/messagesActions";
import { Button, MenuItem } from "@mui/material";
import { CustomSelect } from "../../components/formsComponents";
import {
  createTabsWithRecordcounts,
  formatDateWithTime,
  renderDropdownValue,
} from "../../helpers";
import { ViewUnderWritersDetails } from "./components/UnderWriters/ViewUnderWriters/ViewUnderWritersDetails";
import { IUnderWriter } from "../../redux/underWriter/underWriter.types";
import { CustomLink } from "../../components/CustomLink/CustomLink";
import { StandardTableActions } from "../../components/StandardTableActions/StandardTableActions";
import { StatusRenderer } from "../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { RoleBasedEmailRightPanel } from "../../security/RoleBasedComponents/RoleBasedEmailRightPanel/RoleBasedEmailRightPanel";
import { RoleBasedMessages } from "../../security/RoleBasedComponents/RoleBaseMessages/RoleBaseMessages";
import { MODULE_IDS } from "../../constants/enums";
import { CustomDatePicker } from "../../components/formsComponents/CustomDatePicker";
import { DateFilterDropdown } from "../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { useDateFilter } from "../../components/Table/hooks/useDateFilter";
import { ITableTab } from "../../components/Table/components/TableTabs/TableTabs.types";
import { PageTitleBar } from "../../components/PageTitleBar/PageTitleBar";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../redux/common/commonActions";
import { useDispatchWrapper, useRecordCounts } from "../../hooks";

interface IUnderWriterListBaseProps {
  hideColumnsName?: string[];
  hideTableFilters?: boolean;
  onSelectionUnderwritter?: (
    isAllSelected: boolean,
    rows: IInsurerUnderWriter[]
  ) => void;
}

export const UnderWritersList: React.FC = () => {
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      title: "underwriters",
    },
  ];
  const navigate = useNavigate();

  const handleCreate = () => {
    navigate("/underwriter-composer");
  };

  return (
    <PageContainer title="Underwriter" description="this is innerpage">
      <Breadcrumb title="Underwriters" items={BCrumb} />

      <PageTitleBar
        heading="Underwriters/Insurer Contact"
        rightHeading={
          <Button
            variant="contained"
            onClick={handleCreate}
            startIcon={<AddOutlined />}
          >
            Create
          </Button>
        }
      />
      <UnderWritersListBase />
    </PageContainer>
  );
};

export const UnderWritersListBase: React.FC<IUnderWriterListBaseProps> = (
  props
) => {
  const { hideColumnsName = [], hideTableFilters } = props;
  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "ACTIVE"
  );
  const [list, setList] = React.useState<IUnderWriter[]>([]);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [selectedUnderWriters, setSelectedUnderWriters] = React.useState<{
    isAllSelected: boolean;
    underwriter: IInsurerUnderWriter[];
  }>({
    isAllSelected: false,
    underwriter: [],
  });
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });

  const [openView, setOpenView] = React.useState<IInsurerUnderWriter | null>(
    null
  );
  const [tableTabs, setTableTabs] = React.useState<ITableTab[]>([]);

  const [searchType, setSearchType] = React.useState<string[]>(["name"]);
  const [search, setSearch] = React.useState<string>("");
  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "allTimes" });

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();
  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();
  const fetchList = async () => {
    try {
      setLoading(true);
      // const finalStatus = status === "-1" ? "" : status;
      // const res = await api.get(
      //   `/insurance/get-underwriters?pageNo=${tablePagination.pageNumber}&itemPerPage=${tablePagination.rowsInPerPage}&status=${finalStatus}`
      // );
      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }
      let finalUrl = `/insurance/get-underwriters?pageNo=${tablePagination.pageNumber}&itemPerPage=${tablePagination.rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;
      if (searchType.length > 0 && search) {
        finalUrl = `/insurance/get-underwriters?pageNo=${
          tablePagination.pageNumber
        }&itemPerPage=${tablePagination.rowsInPerPage}${url}&column=${[
          searchType,
        ]}&value=${search}&from_date=${date.fromDate}&to_date=${date.toDate}`;
      }
      const res = await api.get(finalUrl);
      setList(res.data.data);
      setTotalRecords(res.data.totalRecords);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagination, status, search, date]);

  const menuItems =
    selectedUnderWriters.underwriter.length > 0 ||
    selectedUnderWriters.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const underwritersTableProps: IDataTableProps = {
    isPagination: true,
    totalRecords: totalRecords,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    isDataLoading: loading,
    selectionMode: "multiple",
    uniqueRowKeyName: "underwriters_id",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          ...menuItems,

          {
            key: "byDate",
            onRender: () => (
              <DateFilterDropdown
                value={type}
                options={dateDropdownList}
                onChange={handleDateSelectChange}
              />
            ),
          },
          {
            key: "fromDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.fromDate}
                  onChange={(newValue) => {
                    setDate({ ...date, fromDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "toDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.toDate}
                  onChange={(newValue) => {
                    setDate({ ...date, toDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "refresh",
            onRender: () => (
              <RefreshPlugin
                onClick={() => {
                  fetchList();
                }}
              />
            ),
          },
          // {
          //   key: "csvExport",
          //   onRender: (columns, items) => (
          //     <CSVExportPlugin
          //       columns={columns}
          //       items={items}
          //       filePrefixName="Report"
          //     />
          //   ),
          // },
        ],
      },
      rightItems: {
        plugins: {
          // searchField: {
          // searchKeys: [
          //   "contact_no",
          //   "membership_code",
          //   "user_name",
          //   "user_prospect_dim_id",
          //   "status",
          //   "user_id",
          //   "invoice_number",
          //   "user_name",
          //   "user_prospect_id",
          //   "vehicle_no",
          //   "cheque_no",
          //   "cheque_status"
          // ],
          // items: list,
          // },
        },
        customPlugins: [
          {
            key: "search",
            onRender: () => {
              return (
                <SearchPlugin
                  selectedDropdownValue={searchType}
                  dropdownOptions={[
                    { label: "Insurer Code", value: "insurer_code" },
                    { label: "Name", value: "name" },
                    { label: "Phone Number", value: "phone_number" },
                    { label: "Email", value: "email" },
                    { label: "Department Email", value: "department_email" },
                    { label: "Line of Business", value: "line_of_business" },
                  ]}
                  onDropdownChange={(value) => setSearchType(value)}
                  onChange={(newValue) => {
                    setSearch(newValue);
                  }}
                />
              );
            },
          },
        ],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        isFirstColumnSticky: true,
        onRowCellRender: (value: IData, row: IInsurerUnderWriter) => {
          return (
            <StandardTableActions
              onViewClick={() => setOpenView(row)}
              commentBoxTypeId={'underwriters_primary_id'}
              onEditClick={() => {
                navigate(
                  "/underwriter-composer/" +
                    (row as IInsurerUnderWriter).underwriters_primary_id
                );
              }}
              // more={{ menuItems: menuItems }}
            />
          );
        },
      },

      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        exportCellWidth: 25,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value: any, row: IInsurerUnderWriter) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "type",
        headerName: "Type",
        fieldName: "type",
        exportCellWidth: 25,
        renderType: RenderType.TEXT_DARK,
        enableSorting: true,
      },
      {
        key: "insurer_code",
        headerName: "Insurer Code",
        fieldName: "insurer_code",
        renderType: RenderType.TEXT_DARK,
        enableSorting: true,
      },
      {
        key: "name",
        headerName: "Name",
        fieldName: "name",
        renderType: RenderType.CUSTOM_RENDER,
        enableSorting: true,
        onRowCellRender: (value: IData, row: IInsurerUnderWriter) => {
          return (
            <CustomLink
              to={
                "/underwriter-composer/" +
                (row as IInsurerUnderWriter).underwriters_primary_id
              }
              label={value.value}
            />
          );
        },
      },

      {
        key: "phone",
        headerName: "Phone",
        fieldName: "phone_number",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
        enableSorting: true,
        onRowCellRender: (value: IData, row: IInsurerUnderWriter) => {
          return (
            <RoleBasedMessages
              phone={row.phone_number}
              moduleId={MODULE_IDS.UNDERWRITERS}
            />
          );
        },
      },

      {
        key: "email",
        headerName: "Email",
        fieldName: "email",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
        enableSorting: true,
        onRowCellRender: (value: IData, row: IInsurerUnderWriter) => {
          return (
            <RoleBasedEmailRightPanel
              displayLabel={row.email}
              toEmail={row.email}
              moduleId={MODULE_IDS.UNDERWRITERS}
            />
          );
        },
      },

      {
        key: "department_email",
        headerName: "Department Email",
        fieldName: "department_email",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
        enableSorting: true,
      },
      {
        key: "line_of_business",
        headerName: "Line of Business",
        fieldName: "line_of_business",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
        enableSorting: true,
      },

      {
        key: "insert_ts",
        headerName: "Date",
        fieldName: "insert_ts",
        renderType: RenderType.DATE_TIME_DARK_COLOR,
        enableSorting: true,
        exportCellWidth: 25,
        onExportRender: (values: any) => {
          return formatDateWithTime(values.value);
        },
      },
    ].filter((x) => !hideColumnsName.includes(x.headerName)),
    items: list,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
    onSelection: (isAllSelected: boolean, rows: IInsurerUnderWriter[]) => {
      setSelectedUnderWriters({
        isAllSelected: isAllSelected,
        underwriter: rows,
      });
      if (props.onSelectionUnderwritter) {
        props.onSelectionUnderwritter(isAllSelected, rows);
      }
    },
    tableTabProps: {
      selectedTab: status,
      tabs: tableTabs,
      onTabChange: (newSelectedTab) => {
        setStatus(newSelectedTab);
      },
    },
  };

  React.useEffect(() => {
    dispatch(
      fetchRecordCountsAsync(
        "latest_underwriters",
        "status",
        date.fromDate,
        date.toDate
      )
    );
    return () => {
      dispatch(clearRecordCounts());
    };
  }, [date.fromDate, date.toDate]);

  React.useEffect(() => {
    const tabsData = createTabsWithRecordcounts(
      [
        { label: "Active", value: "ACTIVE", variant: "success" },
        { label: "Inactive", value: "INACTIVE", variant: "error" },
      ],
      recordsCounts
    );
    setTableTabs(tabsData);
  }, [recordsCounts]);

  return (
    <>
      <DataTable {...underwritersTableProps} />
      {openView && (
        <ViewUnderWritersDetails
          open={true}
          underwriter={openView}
          onClose={() => setOpenView(null)}
        />
      )}
    </>
  );
};
