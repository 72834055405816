import moment from "moment";
import { LoadState } from "../../constants/enums";
import { formatDate } from "../../helpers";

export interface IEquipmentState {
  list: IEquipment[];
   totalRecords: number;
  loading: LoadState;
  data: IEquipment;
  dataLoading: LoadState;
  error: string | null;
}

export interface IEquipment {
  "brokerage_equipment_code": string | null,
  user_fact_id: string | null;
  "username": string | null;
  "date_issue": string | null;
  "equipment": string | null;
  "brand": string | null;
  "equipment_name": string | null;
  "equipment_condition": string | null;
  "purchase_price": string | null;
  "vendor": string | null;
  "serial_number": string | null;
  "date_purchase": string | null;
  "warranty_period": string | null;
  "attachments": any;
  warranty_end: string | null;
  "status": string;
}


export const initialEquipmentState: IEquipmentState = {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    data: {
      user_fact_id: null,
        serial_number: null,
        attachments: null,
        brand: null,
        date_issue: formatDate(moment().format()),
        date_purchase: null,
        equipment: null,
        equipment_condition: null,
        equipment_name: null,
        purchase_price: null,
        status: "Spare",
        username: null,
        vendor: null,
        warranty_end: null,
        warranty_period: null,
        brokerage_equipment_code: null,
    },
    dataLoading: LoadState.NotLoaded,
    error: null,
}