import React from "react";
import { StandadCard } from "../../../../../../components/Cards";
import { AccountCircle, Receipt, StickyNote2 } from "@mui/icons-material";
import { TabPanel } from "../../../../../../components/Tabs/TabPanel";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../hooks";
import { LifeInsuranceCustomerInfo } from "./CustomerInfo/CustomerInfo";
import { fetchAutomobileCustomerInfoAsync } from "../../../../../../redux/automobileBasicDetails/automobileBasicDetailsActions";
import { TabsList } from "../../../../../../components/Tabs/TabsList";
import { LifeInsurancePolicyAdmin } from "./PolicyAdmin/PolicyAdmin";
import { LifeInsurancePremium } from "./Premium/Premium";
import { LifeInsurerClaim } from "./Claims/Claim";
import { useParams } from "react-router-dom";
import { fetchLifeInsuranceCustomerInfoAsync } from "../../../../../../redux/lifeInsurancePolicy/lifeInsuranceBasicDetail/lifeInsuranceBasicDetailActions";
import { LifeInsurancePolicyAdminManager } from "./PolicyAdminManager/PolicyAdminManager";
import { usePremissions } from "../../../../../../security/PremissionsProvider/PremissionsProvider";

export const LifeInsuranceBasicDetails = () => {
  const { customerId, customerPolicyId } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
  };

  const [tab, setTab] = React.useState(0);
  const { customerInfo, customerPoliycId: savedCustomerPolicyId } = useSelector(
    (storeState: IStoreState) => storeState.lifeInsurance.basicDetails
  );
  const { premissionsList } = usePremissions();
  const showAdminManager = premissionsList.find((x) => {
    if (
      x.module_id === MODULE_IDS.LIFE_AND_FINCANCIAL_POLICY_ADMIN_MANAGER &&
      x.show_module
    ) {
      return true;
    }
    return false;
  });
  const dispatch = useDispatchWrapper();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchLifeInsuranceCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  if (!customerId) {
    return null;
  }

  return (
    <PageLoader
      loading={customerInfo.Loading === LoadState.InProgress}
      //  error={customerNotFoundError ? { message: customerNotFoundError } : null}
    >
      <StandadCard>
        <TabsList
          sx={{ mb: 1 }}
          varaint="fullWidth"
          selectedTab={tab}
          tabs={[
            { label: "Customer Info", icon: <AccountCircle /> },

            {
              label: "Policy Admin",
              icon: <StickyNote2 />,
              disabled: !savedCustomerPolicyId,
            },
            {
              label: "Policy Admin Manager",
              icon: <StickyNote2 />,
              disabled: !savedCustomerPolicyId,
              hidden: !showAdminManager,
            },
            {
              label: "Premium",
              icon: <Receipt />,
              disabled: !savedCustomerPolicyId,
            },
          ]}
          onChange={handleChange}
        />
        <TabPanel value={tab} index={0}>
          <LifeInsuranceCustomerInfo customerId={customerId} customerPolicyId={customerPolicyId} />
        </TabPanel>
        {savedCustomerPolicyId && (
          <>
            <TabPanel value={tab} index={1}>
              <LifeInsurancePolicyAdmin
                customerpolicyId={savedCustomerPolicyId}
              />
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <LifeInsurancePolicyAdminManager
                customerpolicyId={savedCustomerPolicyId}
              />
            </TabPanel>
            <TabPanel value={tab} index={3}>
              <LifeInsurancePremium customerpolicyId={savedCustomerPolicyId} />
            </TabPanel>
          </>
        )}
      </StandadCard>
    </PageLoader>
  );
};
