import { action } from "typesafe-actions";

import { IDate } from "../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { uploadFile } from "../../helpers";
import { ICustomerAllPolicyDetail, INotices } from "./notices.types";
import { makeApiCall } from "../../helpers/postRequest";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";


export const FETCH_NOTICES_LIST_PROGRESS = "FETCH_NOTICES_LIST_PROGRESS";
export const FETCH_NOTICES_LIST_SUCCESS = "FETCH_NOTICES_LIST_SUCCESS";
export const FETCH_NOTICES_LIST_FAILED = "FETCH_NOTICES_LIST_FAILED";

export const fetchNoticesListProgress = () =>
  action(FETCH_NOTICES_LIST_PROGRESS);
export const fetchNoticesListSuccess = (
  list: INotices[],
  totalRecords: number
) => action(FETCH_NOTICES_LIST_SUCCESS, { list, totalRecords });
export const fetchNoticesListFailed = () => action(FETCH_NOTICES_LIST_FAILED);

export const fetchNoticesListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchNoticesListProgress());
      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }

      let finalUrl = `/other/get-notice?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;

      if (searchValue.length > 0) {
        finalUrl = `/other/get-notice?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      const res = await api.get(finalUrl);
      const data: INotices[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchNoticesListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchNoticesListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertNoticesAysnc =
  (
    notices: INotices,
    initialData: INotices,
    isUpdate: boolean,
    file: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {};
      const path = await uploadFile(
        file,
        "NOTICE",
        notices.file_upload || "",
        asPayload
      );
      await makeApiCall(
        {
          url: "/other/upsert-notice",
          method: "POST",
          automation: {
            isUpdate: isUpdate,
            primaryFieldName: "notice_code",
          },
        },
        initialData,
        { ...notices, file_upload: path }
      );
      dispatch(
        showMessage({
          type: "success",
          message: "Notice saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchAllCustomerDetailsForNotices =
  (
    pageNumber: number,
    rowsInPerPage: number,
    searchvalue: string, onCallback: (data: ICustomerAllPolicyDetail[], totalRecords: number)=> void): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      let url = `/policy/get-all-customer-policy-details?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&column=customer_name&column=customer_code&column=policy_number&value=${searchvalue}&status=ACTIVE`;

      const res = await api.get(url);
      const data: ICustomerAllPolicyDetail[] = res.data.data;
      onCallback(data,res.data.totalRecords);
    } catch (err: any) {
      onCallback([],0);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally{
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_NOTICES_STATE = "CLEAR_NOTICES_STATE";

export const clearNoticesState = () => action(CLEAR_NOTICES_STATE);
