import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import {
  IAccountingGLAccounts,
  initialAccountingGLAccountsState,
} from "./glAccounts.types";

export const FETCH_ACCOUNTING_GL_ACCOUNTS_PROGRESS =
  "FETCH_ACCOUNTING_GL_ACCOUNTS_PROGRESS";
export const FETCH_ACCOUNTING_GL_ACCOUNTS_SUCCESS =
  "FETCH_ACCOUNTING_GL_ACCOUNTS_SUCCESS";
export const FETCH_ACCOUNTING_GL_ACCOUNTS_FAILURE =
  "FETCH_ACCOUNTING_GL_ACCOUNTS_FAILURE";

export const fetchAccountingGLAccountsProgress = () =>
  action(FETCH_ACCOUNTING_GL_ACCOUNTS_PROGRESS);
export const fetchAccountingGLAccountsSuccess = (data: IAccountingGLAccounts) =>
  action(FETCH_ACCOUNTING_GL_ACCOUNTS_SUCCESS, { data });
export const fetchAccountingGLAccountsFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_GL_ACCOUNTS_FAILURE, { errorMessage });

export const fetchAccountingGLAccountsAsync =
  (glAccountId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingGLAccountsProgress());

      const res = await api.get(
        `/accounting/get-account-company-gl-accounts?column=gl_account_id&value=${glAccountId}`
      );
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAccountingGLAccountsSuccess(data[0]));
      } else {
        dispatch(
          fetchAccountingGLAccountsSuccess(
            initialAccountingGLAccountsState["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchAccountingGLAccountsFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingGLAccountsAysnc =
  (
    data: IAccountingGLAccounts,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/accounting/edit-account-company-gl-accounts", data);
      let message = "GL Accounts saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
