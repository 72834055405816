/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { StandadCard } from "../../../../../components/Cards";

import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../constants/enums";
import { fetchCustomerInfoAsync } from "../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";
import { useDispatchWrapper } from "../../../../../hooks";
import { useOutletContext } from "react-router-dom";
import { useBreadcrumbContext } from "../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { CommAutomobileVehicleInformationList } from "./CommAutoVehicle/CommAutoVehicleInformation/CommAutoVehicleInformationList";
import { fetchCommAutomobileCustomerInfoAsync } from "../../../../../redux/commAutoPolicy/commAutoBasicDetails/commAutoBasicDetailsActions";

export const CommAutoVehicleRoot: React.FC = () => {
  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerPolicyId = data[1];
  const { addBreadcrumb } = useBreadcrumbContext();

  const { customerInfo } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.basicDetails
  );

  const dispatch = useDispatchWrapper();


  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchCommAutomobileCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  React.useEffect(() => {
    addBreadcrumb({
      title: "",
      items: [
        {
          to: "/dashboard",
          title: "dashboard",
        },
        {
          to: "/view-customers",
          title: "customers",
        },
        {
          to: "/customer-overview/" + customerId,
          title: "customer-overview",
        },
        {
          to:
            "/comm-automobile-policy/comm-automobile/" +
            customerId +
            "/" +
            customerPolicyId,
          title: "vehicle",
        },
      ],
    });
  }, []);

  return (
    <PageLoader
      loading={customerInfo.loading === LoadState.InProgress}
      error={customerInfo.error ? { message: customerInfo.error } : null}
    >
      <StandadCard>
        <CommAutomobileVehicleInformationList
          customerId={customerId}
          customerPolicyId={customerPolicyId}
        />
      </StandadCard>
    </PageLoader>
  );
};
