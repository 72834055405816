import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { defaultCommAutomobilePolicyAuthorizationState } from "./commAutoPolicyAuthorization.types";
import { CommAutomobilePolicyAuthorizationActions } from ".";
import { CLEAR_COMM_AUTOMOBILE_AUTHORIZATION, CLEAR_COMM_AUTOMOBILE_AUTHORIZATION_STATE, FETCH_COMM_AUTOMOBILE_AUTHORIZATION_FAILED, FETCH_COMM_AUTOMOBILE_AUTHORIZATION_INFORMATION_LIST_SUCCESS, FETCH_COMM_AUTOMOBILE_AUTHORIZATION_LIST_FAILED, FETCH_COMM_AUTOMOBILE_AUTHORIZATION_LIST_PROGRESS, FETCH_COMM_AUTOMOBILE_AUTHORIZATION_PROGRESS, FETCH_COMM_AUTOMOBILE_AUTHORIZATION_SUCCESS } from "./commAutoPolicyAuthorizationActions";

export const CommAutomobilePolicyAuthorizationReducer = (
  state: IStoreState["commAuto"]["authorization"] = defaultCommAutomobilePolicyAuthorizationState,
  action: CommAutomobilePolicyAuthorizationActions
) => {
  switch (action.type) {
    case FETCH_COMM_AUTOMOBILE_AUTHORIZATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.authLoading = LoadState.InProgress;
        draftState.auth = defaultCommAutomobilePolicyAuthorizationState["auth"];
        draftState.error = null;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_AUTHORIZATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.authLoading = LoadState.Loaded;
        draftState.auth = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_AUTHORIZATION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.authLoading = LoadState.Failed;
        draftState.auth = defaultCommAutomobilePolicyAuthorizationState["auth"];
        draftState.error = errorMessage;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_AUTHORIZATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_AUTHORIZATION_INFORMATION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_AUTHORIZATION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_AUTHORIZATION: {
      const newState = produce(state, (draftState) => {
        draftState.authLoading = LoadState.InProgress;
        draftState.auth = defaultCommAutomobilePolicyAuthorizationState["auth"];
        draftState.error = null;
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_AUTHORIZATION_STATE: {
      return defaultCommAutomobilePolicyAuthorizationState;
    }

    default: {
      return state;
    }
  }
};
