import { action } from "typesafe-actions";
import {
  IBusinessPolicyUnderWriting,
  defaultBusinessPolicyUnderWritingState,
} from "./businessPolicyUnderWriting.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { api } from "../../../api/api";

export const FETCH_BUSINESS_POLICY_UNDER_WRITING_PROGRESS =
  "FETCH_BUSINESS_POLICY_UNDER_WRITING_PROGRESS";
export const FETCH_BUSINESS_POLICY_UNDER_WRITING_SUCCESS =
  "FETCH_BUSINESS_POLICY_UNDER_WRITING_SUCCESS";
export const FETCH_BUSINESS_POLICY_UNDER_WRITING_FAILED =
  "FETCH_BUSINESS_POLICY_UNDER_WRITING_FAILED";

export const fetchBusinessUnderWritingProgress = () =>
  action(FETCH_BUSINESS_POLICY_UNDER_WRITING_PROGRESS);
export const fetchBusineUnderWritingSuccess = (
  data: IBusinessPolicyUnderWriting
) => action(FETCH_BUSINESS_POLICY_UNDER_WRITING_SUCCESS, { data });
export const fetchBusinessUnderWritingfailed = () =>
  action(FETCH_BUSINESS_POLICY_UNDER_WRITING_FAILED);

export const fetchBusinessUnderWritingAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessUnderWritingProgress());
      const res = await api.get(
        `/policy/get-policy-business-underwriting?customer_policy_id=${customerPolicyId}`
      );
      const data: IBusinessPolicyUnderWriting[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBusineUnderWritingSuccess(data[0]));
      } else {
        dispatch(
          fetchBusineUnderWritingSuccess(
            defaultBusinessPolicyUnderWritingState["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchBusinessUnderWritingfailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addBusinessUnderWritingAsync =
  (
    underwriting: IBusinessPolicyUnderWriting,
    onSuccess: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(`/policy/edit-policy-business-underwriting`, {
        ...underwriting,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Underwriting saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchBusineUnderWritingSuccess(underwriting));
      onSuccess();
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_BUSINESS_POLICY_UNDER_WRITING =
  "CLEAR_BUSINESS_POLICY_UNDER_WRITING";

export const clearBusinessPolicyUnderWritingState = () =>
  action(CLEAR_BUSINESS_POLICY_UNDER_WRITING);
