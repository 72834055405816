import React from "react";
import { StandadCard } from "../../../../components/Cards";
import { Box, Divider, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import { AccountCircle, AdminPanelSettings } from "@mui/icons-material";
import { a11yProps } from "../../../../components/Tabs/utils";
import { TabPanel } from "../../../../components/Tabs/TabPanel";
import { ViewAddInterestedParty } from "./ViewAddInterestedParty";
import { ViewDeleteInterestedParty } from "./ViewDeleteInterestedParty";
import { ITaskFileUploadAttachment } from "../../../../redux/tasks/task.types";
import { FileUpload } from "../../../../components/FileUpload/FileUpload";

export const ViewInterestedParty: React.FC<{
  taskCode: string;
  attachment: ITaskFileUploadAttachment[];
}> = (props) => {
  const { taskCode, attachment } = props;

  const [tab, setTab] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Tabs
          value={tab}
          variant="fullWidth"
          scrollButtons="auto"
          onChange={handleChange}
        >
          <Tab
            icon={<AccountCircle />}
            iconPosition="start"
            label="Add Interested Party"
            {...a11yProps(0)}
          />
          <Tab
            sx={{ minHeight: "50px" }}
            icon={<AdminPanelSettings />}
            iconPosition="start"
            label="Delete Interested Party"
            {...a11yProps(1)}
          />
        </Tabs>
        <Divider />
        <TabPanel value={tab} index={0}>
          <ViewAddInterestedParty taskCode={taskCode} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <ViewDeleteInterestedParty taskCode={taskCode} />
        </TabPanel>
      </Box>
      <Grid container>
        <Grid item xs={12} md={8}>
          <StandadCard sx={{ mt: 2 }}>
            <Typography variant="body1" fontSize={"1.2rem"} fontWeight={600}>
              Attachments
            </Typography>
            {attachment.length > 0 ? (
              attachment.map((item) => {
                return (
                  <Stack
                    key={item.key}
                    direction={"row"}
                    justifyContent={"center"}
                    gap={4}
                    alignItems={"center"}
                  >
                    <Typography variant="h4" fontWeight={"bold"} width={"30%"}>
                      {" "}
                      {item.fileName}{" "}
                    </Typography>
                    <FileUpload value={item.url} deleteDisabled />
                  </Stack>
                );
              })
            ) : (
              <Typography variant="body1">No attachments</Typography>
            )}
          </StandadCard>
        </Grid>
      </Grid>
    </>
  );
};
