import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { PolicyCancellationForm } from "../../Cancellation/PolicyCancellationForm";
import { useBusinessPremium } from "../../../../../../hooks/useCustomerPremium";
import { POLICIES_NAMES } from "../../../../../../constants/enums";
import { useBusinessCustomerBasicDetails } from "../../../../../../hooks";

export const BusinessCancellation: React.FC = () => {
  const { taskCode } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
    taskCode?: string;
  };

  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerPolicyId = data[1];

  const customerBasicDetail = useBusinessCustomerBasicDetails(customerPolicyId)
  
  const premium = useBusinessPremium(customerPolicyId);
  const agencyBill = premium.data.agency_bill_or_direct_bill;

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(
      `/business-policy/${customerId}/policy-forms-tab/${customerPolicyId}?tab=4`
    );
  };
  return (
    <>
      {taskCode && (
        <PolicyCancellationForm
          customerId={customerId}
          customerPolicyId={customerPolicyId}
          taskCode={taskCode}
          handleNavigate={handleNavigate}
          agencyBill={agencyBill}
          policy={POLICIES_NAMES.BUSINESS_POLICY}
          lineOfBusiness={customerBasicDetail.data.line_of_business}
          subType={customerBasicDetail.data.sub_type}
          policyNumber={customerBasicDetail.data.policy_number}
        />
      )}
    </>
  );
};
