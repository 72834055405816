import { StandadCard } from "../../../../components/Cards";
import React from "react";
import { TabPanel } from "../../../../components/Tabs/TabPanel";
import { TabsList } from "../../../../components/Tabs/TabsList";
import { ContactPage, TrackChanges } from "@mui/icons-material";
import { InsuranceReport } from "../InsuranceReportLIst";
import { NoPolicyInsuranceReportList } from "../NoPolicyInsuranceReportList";
import { useQueryParms } from "../../../../hooks";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";

export const InsuraceReportsTabs: React.FC = () => {
  const [tab, setTab] = React.useState(0);
  const { getParm } = useQueryParms();
  const tabValue = getParm("tab");
  const selectedTab = tabValue ? Number(tabValue) : 0;
  const navigate = useNavigate();

  const handleChange = (newValue: number) => {
    navigate(`/insurance-reports?tab=${newValue}`);
  };

  return (
    <>
       
      <TabPanel value={selectedTab} index={0}>
        <InsuranceReport onChange={(newTab) => handleChange(newTab)} />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <NoPolicyInsuranceReportList onChange={(newTab) => handleChange(newTab)} />
      </TabPanel>
    </>
  );
};
