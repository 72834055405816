import { IStoreState } from "../../initialStoreState";
import { BusinesPolicyCoverageActions } from ".";
import { defaultBusinessPolicyCoverageState } from "./businessPolicyCoverage.types";
import {
  CLEAR_BUSINES_POLICY_COVERAGE,
  CLEAR_BUSINES_POLICY_COVERAGE_STATE,
  FETCH_BUSINESS_POLICY_COVERAGE_FAILED,
  FETCH_BUSINESS_POLICY_COVERAGE_LIST_FAILED,
  FETCH_BUSINESS_POLICY_COVERAGE_LIST_SUCCESS,
  FETCH_BUSINESS_POLICY_COVERAGE_SUCCESS,
  FETCH_BUSINESS_POLICY_COVERAG_LIST_PROGRESS,
  FETCH_BUSINESS_POLICY_COVERAG_PROGRESS,
} from "./businesPolicyCoverageActions";
import produce from "immer";
import { LoadState } from "../../../constants/enums";

export const businessPolicyCoverageReducer = (
  state: IStoreState["business"]["coverage"] = defaultBusinessPolicyCoverageState,
  action: BusinesPolicyCoverageActions
) => {
  switch (action.type) {
    case FETCH_BUSINESS_POLICY_COVERAG_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_COVERAGE_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_COVERAGE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.data = [];
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_COVERAG_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.coverageLoading = LoadState.InProgress;
        draftState.coverage = defaultBusinessPolicyCoverageState["coverage"];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_COVERAGE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.coverageLoading = LoadState.Loaded;
        draftState.coverage = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_COVERAGE_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.coverageLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_BUSINES_POLICY_COVERAGE: {
      const newState = produce(state, (draftState) => {
        draftState.coverage = defaultBusinessPolicyCoverageState["coverage"];
      });
      return newState;
    }
    case CLEAR_BUSINES_POLICY_COVERAGE_STATE: {
      return defaultBusinessPolicyCoverageState;
    }
    default: {
      return state;
    }
  }
};
