import React from "react";
import ReactJson from "react-json-view";
import { IHistoryViewDialog } from "./HistoryViewDialog.types";
import { Grid, useTheme } from "@mui/material";
import { Dialog } from "../../Dialogs/Dialog";

export const HistoryViewDialog: React.FC<
  IHistoryViewDialog
> = (props) => {
  const { open, data, onClose } = props;
  const theme = useTheme();


  return (
    <Dialog
      open={open}
      title="View History"
      onClose={onClose}
      size="md"
      actions={[
        {
          type: "button",
          label: "Close",
          variant: "contained",
          onClick: onClose,
        },
      ]}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <ReactJson
            src={data}
            name={null}
           theme={theme.palette.mode === "dark" ? "apathy": undefined}// Choose a theme (e.g., monokai, apathy, etc.)
            // iconStyle="circle" // Choose an icon style (circle or square)
            displayObjectSize={false} // Hide the object size
            displayDataTypes={false} // Hide data types
            enableClipboard={false} // Disable clipboard copy
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};
