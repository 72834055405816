import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";

import {
  CLEAR_AUTOMOBILE_EDI_APPLICATION_STATE,
  FETCH_AUTOMOBILE_EDI_LIST_FAILED,
  FETCH_AUTOMOBILE_EDI_LIST_PROGRESS,
  FETCH_AUTOMOBILE_EDI_LIST_SUCCESS,
} from "./automobileCSIOEDIApplicationActions";
import { AutomobileCSIOEDIApplicationActions } from ".";
import { initialAutomobileCSIOEDIApplicationState } from "./automobileCSIOEdiApplication.types";

export const AutomobileCSIOEDIApplicationReducer = (
  state: IStoreState["automobile"]["edi"] = initialAutomobileCSIOEDIApplicationState,
  action: AutomobileCSIOEDIApplicationActions
) => {
  switch (action.type) {

    case FETCH_AUTOMOBILE_EDI_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_EDI_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_EDI_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.list = [];
      });
      return newState;
    }

    case CLEAR_AUTOMOBILE_EDI_APPLICATION_STATE: {
      return initialAutomobileCSIOEDIApplicationState;
    }

    default: {
      return state;
    }
  }
};
