import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface ILifeInsurancePolcyComplianceCheckListState {
    data: ILifeInsurancePolcyComplianceCheckList;
    loading: LoadState;
    error: string | null;
}

export interface ILifeInsurancePolcyComplianceCheckList {
    // life_insurance_policy_checklist: {
    //     key: string,
        customer_policy_id: number,
        advisor_disclosure: string | null,
        kyc_insurance_needs_financial_needs_analysis: string | null,
        consent: string | null,
        privacy_statement: string | null,
        lird: string | null,
        investor_profile: string | null,
        overall_product_suitability: string | null,
        notes: string | null,
        compliance_status: string | null,
        status: string | null,
    // }[],

}

export const defaultLifeInsurancePolicyComplianceCheckList: ILifeInsurancePolcyComplianceCheckListState = {
    data: {
        // life_insurance_policy_checklist: [
        //     {
                // key: getUniqueId(),
                customer_policy_id: 0,
                advisor_disclosure: null,
                kyc_insurance_needs_financial_needs_analysis: null,
                consent: null,
                privacy_statement: null,
                lird: null,
                investor_profile: null,
                overall_product_suitability: null,
                notes: null,
                compliance_status: null,
                status: "ACTIVE"
        //     }
        // ]
    }, 
    loading: LoadState.NotLoaded,
    error: null,
      
}
  