import React from "react";
import { useOutletContext } from "react-router-dom";
import { CSIODocsTableList } from "../../../../../csioEdocs/CSIODocsTable";

export const BusinessPolicyCSIOEDIList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;

  return (
    <>
      <CSIODocsTableList customerPolicyId={customerPolicyId} />
    </>
  );
};
