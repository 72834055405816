import { IStoreState } from "../../initialStoreState";
import {
  CLEAR_HABITATIONAL_COVERAGES,
  CLEAR_HABITATIONAL_COVERAGES_STATE,
  FETCH_HABITATIONAL_COVERAGES_FAILED,
  FETCH_HABITATIONAL_COVERAGES_LIST_FAILED,
  FETCH_HABITATIONAL_COVERAGES_LIST_PROGRESS,
  FETCH_HABITATIONAL_COVERAGES_LIST_SUCCESS,
  FETCH_HABITATIONAL_COVERAGES_PROGRESS,
  FETCH_HABITATIONAL_COVERAGES_SUCCESS,
} from "./habitationalCoveragesActions";
import produce from "immer";
import { LoadState } from "../../../constants/enums";
import { HabitationalCoveragesActions } from ".";
import { defaultHabitationalCoveragesState } from "./habitationalCoverages.types";

export const HabitationalCoveragesReducer = (
  state: IStoreState["habitational"]["coverages"] = defaultHabitationalCoveragesState,
  action: HabitationalCoveragesActions
) => {
  switch (action.type) {
    case FETCH_HABITATIONAL_COVERAGES_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_COVERAGES_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_COVERAGES_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.data = [];
      });
      return newState;
    }

    case FETCH_HABITATIONAL_COVERAGES_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.coveragesLoading = LoadState.InProgress;
        draftState.coverages = defaultHabitationalCoveragesState['coverages'];
        draftState.error = null;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_COVERAGES_SUCCESS: {
      const {data} = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.coveragesLoading = LoadState.Loaded;
        draftState.coverages = data
      });
      return newState;
    }

    case FETCH_HABITATIONAL_COVERAGES_FAILED: {
      const {errorMessage} = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.coveragesLoading = LoadState.Failed;
        draftState.coverages = defaultHabitationalCoveragesState['coverages'];
        draftState.error = errorMessage;
      });
      return newState;
    }
    
  
    case CLEAR_HABITATIONAL_COVERAGES: {
      const newState = produce(state, (draftState) => {
        draftState.coverages =
          defaultHabitationalCoveragesState["coverages"];
      });
      return newState;
    }
    case CLEAR_HABITATIONAL_COVERAGES_STATE: {
      return defaultHabitationalCoveragesState;
    }
    default: {
      return state;
    }
  }
};
