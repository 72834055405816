import { action } from "typesafe-actions";
import { IStoreState } from "../../initialStoreState";
import { initialAccountingCompanyOnboardingState } from "./CompanyOnboarding.types";
import { CompanyOnboardingActions } from ".";
import {
  CLEAR_ACCOUNTING_COMPANY_ONBOARDING,
  FETCH_ACCOUNTING_COMPANY_ONBOARDING_FAILED,
  FETCH_ACCOUNTING_COMPANY_ONBOARDING_PROGRESS,
  FETCH_ACCOUNTING_COMPANY_ONBOARDING_SUCCESS,
} from "./CompanyOnboardingAction";
import produce from "immer";
import { LoadState } from "../../../constants/enums";

export const AccountingCompanyOnboardingReducer = (
  state: IStoreState["accounting"]["companyOnboarding"] = initialAccountingCompanyOnboardingState,
  action: CompanyOnboardingActions
) => {
  switch (action.type) {
    case FETCH_ACCOUNTING_COMPANY_ONBOARDING_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_COMPANY_ONBOARDING_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_COMPANY_ONBOARDING_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_ACCOUNTING_COMPANY_ONBOARDING: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.NotLoaded;
        draftState.data = initialAccountingCompanyOnboardingState.data;
        draftState.error = null;
      });
      return newState;
    }

    default: {
      return state;
    }
  }
};
