import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface ICommAutomobileSupplementState {
  data: ICommAutomobileSupplement;
  loading: LoadState;
}

export interface ICommAutomobileSupplement {
  customer_policy_id: number;
  incremental_number: number | null;
  policy_number: null | string;
  insured: string | null;
  broker_or_agent: string | null;
  customer_name: string | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
  business_of_insured: string | null;
  insurance_company: string | null;
  broker_or_agent_client_id: string | null;
  ibc_industry_code: string | null;
  n_s_c: string | null;
  r_i_n: string | null;
  c_v_o_r: string | null;
  year_of_experience_in_this_type_of_operation: string | null;
  year_business_started: string | null;
  assigned_to: string | null;
  loc_one: string | null;
  garaging_location_address_one: string | null;
  loc_two: string | null;
  garaging_location_address_two: string | null;
  loc_three: string | null;
  garaging_location_address_three: string | null;
  loc_four: string | null;
  garaging_location_address_four: string | null;
  vehicle: {
    vehicle1: string | null;
    vehicle2: string | null;
    vehicle3: string | null;
    vehicle4: string | null;
    automobileUse: {
      primaryBusiness: ICommAutomobileuseColumn;
      garagingLocation: ICommAutomobileuseColumn;
      forAllOperations: ICommAutomobileuseColumn;
      vehicleUsedForPleasure: ICommAutomobileuseColumn;
      recreationalVehicle: ICommAutomobileuseColumn;
      artisanUse: ICommAutomobileuseColumn;
      haulTrailers: ICommAutomobileuseColumn;
      trailerTrain: ICommAutomobileuseColumn;
      empty1: ICommAutomobileuseColumn;
    };
    commoditiesTransported: {
      merchandiseCarried: ICommAutomobileuseColumnValuePrecentage;
      emptyRow1: ICommAutomobileuseColumnValuePrecentage;
      emptyRow2: ICommAutomobileuseColumnValuePrecentage;
      deliveryService: ICommAutomobileuseColumn;
    };
    haulingForOthers: {
      haulingDone: ICommAutomobileuseColumn;
    };
    radiusofOperation: {
      normalOperatingDistance: ICommAutomobileuseColumn;
      totalTrips: ICommAutomobileuseColumn;
      maximumOperatingDistance: ICommAutomobileuseColumn;
      maximumOperatingDistanceTotalTrips: ICommAutomobileuseColumn;
      tripsPerMonthBeyondTheNormalDistance: ICommAutomobileuseColumn;
      mostCommonDestinations: ICommAutomobileuseColumn;
    };
    usaExposure: {
      anyUSAExposure: ICommAutomobileuseColumn;
      mostCommonDestinations: ICommAutomobileuseMostCommonDestinations;
      numberofkilometers: ICommAutomobileuseColumn;
      numberofTripsPerMonth: ICommAutomobileuseColumn;
      consecutiveDays: ICommAutomobileuseColumn;
      annualUse: ICommAutomobileuseColumn;
    };
    machineryAndEquipment: {
      describeMachineryEquipment: ICommAutomobileuseColumn;
      exclude: ICommAutomobileuseColumn;
      ownedorLeased: ICommAutomobileuseColumn;
      value: ICommAutomobileuseColumn;
    };
    specialUse: {
      seasonalUse: ICommAutomobileuseColumn;
      use: ICommAutomobileuseColumn;
      percentageofAnnualUse: ICommAutomobileuseColumn;
    };
    filling: ICommAutoFillingRow[];
  };
  applicant_need_liability_for_damage: string | null;
  liability_been_assumed_under_contract_or_agreement: string | null;
  non_owned_vahicle_table: {
    key: string;
    type_of_non_owned_vehicle_or_trailer: string | null;
    average_no_of_vehicles_or_trailers_at_any_one_time: string | null;
    average_value: string | null;
    maximum_no_of_vehicle_or_trailers_annually: string | null;
    value_of_the_most_expensive_unit: string | null;
  }[];
  are_any_of_the_insured_vehicles_used_for_public_transportation: string | null;
  remarks: string | null;
  submission: string | null;
  date_of_print: string | null;
  status: string;
}

export interface ICommAutoFillingRow {
  key: string;
  autoNo: number;
  stateCity: string;
  usDot: string;
  docket: string;
  type: string;
  specify: string;
}

interface ICommAutomobileuseColumn {
  vehicle1: string | null;
  vehicle2: string | null;
  vehicle3: string | null;
  vehicle4: string | null;
}

interface ICommAutomobileuseMostCommonDestinations {
  vehicle1: {
    value1: string | null;
    value2: string | null;
    value3: string | null;
  };
  vehicle2: {
    value1: string | null;
    value2: string | null;
    value3: string | null;
  };
  vehicle3: {
    value1: string | null;
    value2: string | null;
    value3: string | null;
  };
  vehicle4: {
    value1: string | null;
    value2: string | null;
    value3: string | null;
  };
}

interface ICommAutomobileuseColumnValuePrecentage {
  vehicle1: {
    value: string | null;
    precentage: string | null;
  };
  vehicle2: {
    value: string | null;
    precentage: string | null;
  };
  vehicle3: {
    value: string | null;
    precentage: string | null;
  };
  vehicle4: {
    value: string | null;
    precentage: string | null;
  };
}

export const initialCommAutomobileSupplement: ICommAutomobileSupplementState = {
  data: {
    customer_policy_id: 0,
    policy_number: null,
    incremental_number: null,
    insured: null,
    broker_or_agent: null,
    customer_name: null,
    address: null,
    unit_or_suite: null,
    city: null,
    province_or_state: null,
    postal_code: null,
    country: null,
    business_of_insured: null,
    insurance_company: null,
    broker_or_agent_client_id: null,
    ibc_industry_code: null,
    n_s_c: null,
    r_i_n: null,
    c_v_o_r: null,
    year_of_experience_in_this_type_of_operation: null,
    year_business_started: null,
    assigned_to: null,
    loc_one: null,
    garaging_location_address_one: null,
    loc_two: null,
    garaging_location_address_two: null,
    loc_three: null,
    garaging_location_address_three: null,
    loc_four: null,
    garaging_location_address_four: null,
    vehicle: {
      vehicle1: null,
      vehicle2: null,
      vehicle3: null,
      vehicle4: null,
      automobileUse: {
        artisanUse: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
        forAllOperations: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
        garagingLocation: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
        haulTrailers: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
        primaryBusiness: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
        recreationalVehicle: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
        trailerTrain: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
        vehicleUsedForPleasure: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
        empty1: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
      },
      commoditiesTransported: {
        deliveryService: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
        emptyRow1: {
          vehicle1: {
            value: null,
            precentage: null,
          },
          vehicle2: {
            value: null,
            precentage: null,
          },
          vehicle3: {
            value: null,
            precentage: null,
          },
          vehicle4: {
            value: null,
            precentage: null,
          },
        },
        emptyRow2: {
          vehicle1: {
            value: null,
            precentage: null,
          },
          vehicle2: {
            value: null,
            precentage: null,
          },
          vehicle3: {
            value: null,
            precentage: null,
          },
          vehicle4: {
            value: null,
            precentage: null,
          },
        },
        merchandiseCarried: {
          vehicle1: {
            value: null,
            precentage: null,
          },
          vehicle2: {
            value: null,
            precentage: null,
          },
          vehicle3: {
            value: null,
            precentage: null,
          },
          vehicle4: {
            value: null,
            precentage: null,
          },
        },
      },
      haulingForOthers: {
        haulingDone: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
      },
      machineryAndEquipment: {
        describeMachineryEquipment: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
        exclude: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
        ownedorLeased: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
        value: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
      },
      radiusofOperation: {
        maximumOperatingDistance: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
        maximumOperatingDistanceTotalTrips: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
        mostCommonDestinations: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
        normalOperatingDistance: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
        totalTrips: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
        tripsPerMonthBeyondTheNormalDistance: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
      },
      specialUse: {
        percentageofAnnualUse: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
        seasonalUse: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
        use: { vehicle1: null, vehicle2: null, vehicle3: null, vehicle4: null },
      },
      usaExposure: {
        annualUse: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
        anyUSAExposure: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
        consecutiveDays: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
        mostCommonDestinations: {
          vehicle1: {
            value1: null,
            value2: null,
            value3: null
          },
          vehicle2: {
            value1: null,
            value2: null,
            value3: null
          },
          vehicle3: {
            value1: null,
            value2: null,
            value3: null
          },
          vehicle4: {
            value1: null,
            value2: null,
            value3: null
          },
        },
        numberofkilometers: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
        numberofTripsPerMonth: {
          vehicle1: null,
          vehicle2: null,
          vehicle3: null,
          vehicle4: null,
        },
      },
      filling: [
        {
          key: getUniqueId(),
          autoNo: 1,
          docket: "",
          specify: "",
          stateCity: "",
          type: "",
          usDot: "",
        },
        {
          key: getUniqueId(),
          autoNo: 2,
          docket: "",
          specify: "",
          stateCity: "",
          type: "",
          usDot: "",
        },
        {
          key: getUniqueId(),
          autoNo: 3,
          docket: "",
          specify: "",
          stateCity: "",
          type: "",
          usDot: "",
        },

        {
          key: getUniqueId(),
          autoNo: 4,
          docket: "",
          specify: "",
          stateCity: "",
          type: "",
          usDot: "",
        },
      ],
    },
    applicant_need_liability_for_damage: null,
    liability_been_assumed_under_contract_or_agreement: null,
    non_owned_vahicle_table: [
      {
        key: getUniqueId(),
        type_of_non_owned_vehicle_or_trailer: null,
        average_no_of_vehicles_or_trailers_at_any_one_time: null,
        average_value: null,
        maximum_no_of_vehicle_or_trailers_annually: null,
        value_of_the_most_expensive_unit: null,
      },
      {
        key: getUniqueId(),
        type_of_non_owned_vehicle_or_trailer: null,
        average_no_of_vehicles_or_trailers_at_any_one_time: null,
        average_value: null,
        maximum_no_of_vehicle_or_trailers_annually: null,
        value_of_the_most_expensive_unit: null,
      },
      {
        key: getUniqueId(),
        type_of_non_owned_vehicle_or_trailer: null,
        average_no_of_vehicles_or_trailers_at_any_one_time: null,
        average_value: null,
        maximum_no_of_vehicle_or_trailers_annually: null,
        value_of_the_most_expensive_unit: null,
      },
    ],
    are_any_of_the_insured_vehicles_used_for_public_transportation: null,
    remarks: null,
    submission: null,
    date_of_print: null,
    status: "ACTIVE",
  },
  loading: LoadState.NotLoaded,
};
