import produce from "immer";
import { LoadState } from "../../../constants/enums";
import { IStoreState } from "../../initialStoreState";
import { defaultLifeInsuranceCoverageNeed } from "./lifeInsuranceCoverageNeed.types";
import { LifeInsuranceCoverageNeedAction } from ".";
import {
  CLEAR_LIFE_INSURANCE_COVERAGE_NEED,
  CLEAR_LIFE_INSURANCE_COVERAGE_NEED_STATE,
  FETCH_LIFE_INSURANCE_COVERAGE_NEED_FAILED,
  FETCH_LIFE_INSURANCE_COVERAGE_NEED_PROGRESS,
  FETCH_LIFE_INSURANCE_COVERAGE_NEED_SUCCESS,
} from "./lifeInsuranceCoverageNeedActions";

export const lifeInsuranceCoverageNeedReducer = (
  state: IStoreState["lifeInsurance"]["coverageNeed"] = defaultLifeInsuranceCoverageNeed,
  action: LifeInsuranceCoverageNeedAction
) => {
  switch (action.type) {
    case FETCH_LIFE_INSURANCE_COVERAGE_NEED_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = defaultLifeInsuranceCoverageNeed["data"];
      });
      return newState;
    }
    case FETCH_LIFE_INSURANCE_COVERAGE_NEED_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_LIFE_INSURANCE_COVERAGE_NEED_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_LIFE_INSURANCE_COVERAGE_NEED: {
      const newState = produce(state, (draftState: any) => {
        draftState.data = defaultLifeInsuranceCoverageNeed;
        draftState.loading = LoadState.NotLoaded;
      });
      return newState;
    }
    case CLEAR_LIFE_INSURANCE_COVERAGE_NEED_STATE: {
      return defaultLifeInsuranceCoverageNeed;
    }
    default: {
      return state;
    }
  }
};
