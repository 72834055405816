import React from "react";
import { IStandardCellProps } from "./interfaces/IStandards";
import { Box, Stack, Theme, Typography } from "@mui/material";
import { CustomTypography } from "../formsComponents";

export const StandardCardCell: React.FC<IStandardCellProps> = (props) => {
  const { heading, data, isHide } = props;
  if (isHide) {
    return <></>;
  }

  return (
    <Stack spacing={1} >
      <Typography
        variant="body1"
        fontSize={'1.1rem'}
        fontWeight={500}
     
      >
        {heading}
      </Typography>
      <Typography
        variant="body2"
        sx={(theme: Theme) => ({
          mb: 1,
          fontSize: "1rem",
          wordBreak: "break-word",
          color:
            theme.palette.mode === "dark"
              ? data.color || "#fff"
              : data.color || theme.palette.grey[600],
        })}
      >
        {data.value && data.value !== "null" && data.value !== "undefined"
          ? data.value
          : "--"}
      </Typography>
    </Stack>
  );
};
