/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../redux/initialStoreState";
import { useDispatchWrapper } from "./useDispatch";
import { fetchCustomerInfoAsync } from "../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";


export const useBusinessCustomerBasicDetails = (customerPolicyId?: number) => {
  const customerInfo = useSelector(
    (storeState: IStoreState) => storeState.business.basicDetails.customerInfo
  );
  const dispatch = useDispatchWrapper();

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  return customerInfo;
};
