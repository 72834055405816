import { action } from "typesafe-actions";
import { ICompanyDetail, initialAccountingCompanyDetailState } from "./CompanyDetail.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";
import { api } from "../../../api/api";

export const FETCH_ACCOUNTING_COMPANY_DETAIL_PROGRESS =
  "FETCH_ACCOUNTING_COMPANY_DETAIL_PROGRESS";
export const FETCH_ACCOUNTING_COMPANY_DETAIL_SUCCESS =
  "FETCH_ACCOUNTING_COMPANY_DETAIL_SUCCESS";
export const FETCH_ACCOUNTING_COMPANY_DETAIL_FAILURE =
  "FETCH_ACCOUNTING_COMPANY_DETAIL_FAILURE";

export const fetchAccountingCompanyDetailProgress = () =>
  action(FETCH_ACCOUNTING_COMPANY_DETAIL_PROGRESS);
export const fetchAccountingCompanyDetailSuccess = (data: ICompanyDetail) =>
  action(FETCH_ACCOUNTING_COMPANY_DETAIL_SUCCESS, { data });
export const fetchAccountingCompanyDetailFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_COMPANY_DETAIL_FAILURE, { errorMessage });

export const fetchAccountingCompanyDetailAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingCompanyDetailProgress());

      const res = await api.get(`/accounting/get-account-company-detail`);
      const data: ICompanyDetail[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAccountingCompanyDetailSuccess(data[0]));
      } else {
        dispatch(
          fetchAccountingCompanyDetailSuccess(initialAccountingCompanyDetailState["data"])
        );
      }
    } catch (err: any) {
      dispatch(
        fetchAccountingCompanyDetailFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingCompanyDetailAysnc =
  (
    data: ICompanyDetail,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post(`/accounting/edit-account-company-detail`, {
        ...data,
      });

      let message = "Company Detail saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const CLEAR_ACCOUNTING_COMPANY_DETAIL =
  "CLEAR_ACCOUNTING_COMPANY_DETAIL";
export const clearAccountingCompanyDetail = () =>
  action(CLEAR_ACCOUNTING_COMPANY_DETAIL);
