import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { AccountingInvoiceInformationActions } from ".";
import { initialAccountingInvoiceInformationState } from "./InvoiceInformation.types";
import {
  CLEAR_ACCOUNTING_INVOICE_INFORMATION,
  FETCH_ACCOUNTING_INVOICE_INFORMATION_FAILURE,
  FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_FAILURE,
  FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_PROGRESS,
  FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_SUCCESS,
  FETCH_ACCOUNTING_INVOICE_INFORMATION_PROGRESS,
  FETCH_ACCOUNTING_INVOICE_INFORMATION_SUCCESS,
  FETCH_INVOICE_PAYMENT_HISTORY_FAILED,
  FETCH_INVOICE_PAYMENT_HISTORY_PROGRESS,
  FETCH_INVOICE_PAYMENT_HISTORY_SUCCESS,
} from "./InvoiceInformationActions";

export const AccountingInvoiceInformationReducer = (
  state: IStoreState["accounting"]["invoiceInformation"] = initialAccountingInvoiceInformationState,
  action: AccountingInvoiceInformationActions
) => {
  switch (action.type) {
    case FETCH_ACCOUNTING_INVOICE_INFORMATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_INVOICE_INFORMATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_INVOICE_INFORMATION_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case FETCH_INVOICE_PAYMENT_HISTORY_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.paymentHistoryLoading = LoadState.InProgress;
        draftState.paymentHistory =
          initialAccountingInvoiceInformationState["paymentHistory"];
      });
      return newState;
    }

    case FETCH_INVOICE_PAYMENT_HISTORY_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.paymentHistoryLoading = LoadState.Loaded;
        draftState.paymentHistory = data;
      });
      return newState;
    }

    case FETCH_INVOICE_PAYMENT_HISTORY_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.paymentHistoryLoading = LoadState.Failed;
        draftState.error = errorMessage;
        draftState.paymentHistory =
          initialAccountingInvoiceInformationState["paymentHistory"];
      });
      return newState;
    }

    case FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_ACCOUNTING_INVOICE_INFORMATION: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.NotLoaded;
        draftState.data = initialAccountingInvoiceInformationState.data;
        draftState.error = null;
      });
      return newState;
    }

    default:
      return state;
  }
};
