import { LoadState } from "../../constants/enums";

export interface ISecurityState {
  groups: {
    roleName: string | null;
    group: ISecurityNestedGroup;
    loading: LoadState;
    error: string | null;
  };
  roles: {
    list: IRole[];
    totalRecords: number;
    loading: LoadState;
  };
}

export interface IRole {
  role_id: number;
  role_name: string;
  role_json: string;
  status: string;
}

export interface ISecurityGroup extends IRecordPremission {
  module_id: number;
  module_name: string;
  submodule_name: string;
  role_name: string;
  role_id: number;
  show_module: number;
  view_access: number;
  edit_access: number;
  bulk_import: number;
  bulk_export: number;
  send_sms: number;
  send_mail: number;
  send_whatsapp: number;
  send_call: number;
}
export interface ISecurityNestedGroup {
  modules: {
    [key: string]: ISecurityGroupWithChildren;
  };
}

export interface ISecurityGroupWithChildren  {
  children: ISecurityGroup[];
}


export interface IRecordPremission {
  module_id: number;
  role_id: number;
  submodule_name: string;
  table_name: string;
  column_relation_options: IRecordColumnRelation[];
  filter_values: IRecordFilterValue;
  role_module_code?: string | null;
}

export interface IRecordColumnRelation {
  api: string;
  field: string;
  value: string;
  column_key: string;
  column_label: string;
}

export interface IRecordFilterValue {
  [key: string]: {
    [key: string]: any[];
  };
}


export const initialSecurityState: ISecurityState = {
  groups: {
    roleName: null,
    group: { modules: {} },
    loading: LoadState.NotLoaded,
    error: null,
  },
  roles: {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  }
};
