/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { StandadCard } from "../../../../../components/Cards";
import {
  AccountCircle,
  AdminPanelSettings,
  Receipt,
  StickyNote2,
} from "@mui/icons-material";
import { TabPanel } from "../../../../../components/Tabs/TabPanel";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../constants/enums";
import { fetchCustomerInfoAsync } from "../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";
import { useDispatchWrapper, useQueryParms } from "../../../../../hooks";
import { TabsList } from "../../../../../components/Tabs/TabsList";
import { PolicyUnderwriting } from "./PolicyUnderwriting/PolicyUnderwriting";
import { LabelCard } from "../../../../../components/LabelCard/LabelCard";
import { RiskLocationList } from "./RiskLocation/RiskLocationList";
import { InterestedPartyList } from "./InterestedParties/InterestedPartiesList";
import { AdditionalList } from "./AdditionalInterest/AdditionalInterestList";
import { CoverageList } from "./Coverage/CoverageList";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useBreadcrumbContext } from "../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { BusinessCSIOEDocsList } from "./BusinessCSIOEDocs/BusinessCsioEDocsList";
import { BusinessPolicyCSIOEDIList } from "./BusinessPolicyCSIOEDIList.tsx/BusinessPolicyCSIOEDIList";

export const CSIOEdiRoot: React.FC = () => {
  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerPolicyId = data[1];
  const { addBreadcrumb } = useBreadcrumbContext();
  const { getParm } = useQueryParms();
  const tabValue = getParm("tab");
  const selectedTab = tabValue ? Number(tabValue) : 0;
  console.log("selectedTab", selectedTab);
  const { customerInfo } = useSelector(
    (storeState: IStoreState) => storeState.business.basicDetails
  );
  const navigate = useNavigate();

  const dispatch = useDispatchWrapper();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(
      `/business-policy/${customerId}/csio-edocs-list/${customerPolicyId}?tab=${newValue}`
    );
  };

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  React.useEffect(() => {
    addBreadcrumb({
      title: "",
      items: [
        {
          to: "/policies/",
          title: "all-policies ",
        },
        {
          to: "/policies/Business%20Policy",
          title: "business",
        },
        {
          to: "/customer-overview/" + customerId,
          title: "customer",
        },
        {
          title: selectedTab === 0 ? "csio/edocs" : "edi",
        },
      ],
    });
  }, [selectedTab]);

  return (
    <PageLoader
      loading={customerInfo.loading === LoadState.InProgress}
      error={customerInfo.error ? { message: customerInfo.error } : null}
    >
      <StandadCard>
        <TabsList
          sx={{ mb: 1 }}
          varaint="fullWidth"
          selectedTab={selectedTab}
          tabs={[
            // { label: "Underwriting", icon: <AccountCircle /> },
            {
              label: "CSIO/eDocs",
              icon: <AdminPanelSettings />,
            },
            {
              label: "EDI",
              icon: <StickyNote2 />,
            },
          ]}
          onChange={handleChange}
        />
        <TabPanel value={selectedTab} index={0}>
          <BusinessCSIOEDocsList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <BusinessPolicyCSIOEDIList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
      </StandadCard>
    </PageLoader>
  );
};
