import React from "react";
import { StandadCard } from "../../../components/Cards";
import { PageContainer } from "../../../components/container/PageContainer";
import { RefreshPlugin, SearchPlugin } from "../../../components/Table/plugins";
import { IBreadcrumbProps } from "../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useNavigate } from "react-router-dom";
import { useDispatchWrapper, useRecordCounts } from "../../../hooks";
import { useDateFilter } from "../../../components/Table/hooks/useDateFilter";
import moment from "moment";
import { CustomDatePicker } from "../../../components/formsComponents/CustomDatePicker";
import { DateFilterDropdown } from "../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import {
  ControlledCustomSelect,
  CustomSelect,
} from "../../../components/formsComponents";
import { CustomLink } from "../../../components/CustomLink/CustomLink";
import {
  capitalizeWords,
  createTabsWithRecordcounts,
  formatDate,
  renderDropdownValue,
} from "../../../helpers";
import {
  IInsuranceReport,
  INoPolicyInsuranceReports,
} from "./insuranceReport.types";
import { IconicMultiColumnCard } from "../../../components/iconicMultiColumnCard/IconicMultiColumnCard";
import { StandardTableActions } from "../../../components/StandardTableActions/StandardTableActions";
import { IStoreState } from "../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import {
  IGroupedInsuranceReports,
  IInsuranceReports,
} from "../../../redux/insuranceReports/insuranceReports.types";
import { LoadState } from "../../../constants/enums";
import { fetchInsuranceReportsListAsync } from "../../../redux/insuranceReports/insuranceReportsActions";
import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  IconButton,
  MenuItem,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../../redux/common/commonActions";
import { LeadOppStatus } from "../../../components/LeadOppStatus/LeadOppStatus";
import { AddOutlined } from "@mui/icons-material";
import { RoleBasedCSVExportPlugin } from "../../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { InsuranceReportDialog } from "./InsuranceReportsDialog/InsuranceReportsDialog";
import { ITableCommandBarProps } from "../../../components/Table/components/TableComandBar/interfaces/ITableCommandBarProps";
import { TableCommandBar } from "../../../components/Table/components/TableComandBar/TableCommandBar";
import { TableSkeleton } from "../../../components/TableSkeleton";
import { TablePaginationActions } from "../../../components/Table/TablePaginationActions";
import { StatusRenderer } from "../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { InsuranceReportsWarningDialog } from "../../../components/InsuranceReportsWarningDialog/InsuranceReportsWarningDialog";
import { CustomChip } from "../../../components/CustomChip";
import { ITableTab } from "../../../components/Table/components/TableTabs/TableTabs.types";
import { TableTabs } from "../../../components/Table/components/TableTabs/TableTabs";
import { PageTitleBar } from "../../../components/PageTitleBar/PageTitleBar";

const INITIAL_STATE = {
  // customer_policy_id: 0,
  customer_name: "",
  customer_code: "",
  driver_name: "",
  license_number: "",
  business_reason: "Verify Driver Record",
  date_and_time: moment().format("YYYY-MM-DD HH:mm:ss"),
  days_left: "",
  transaction_type: "",
  report_requested: "",
  request_status: "Submitted",
  insurer_code: "",
  intact_branch: "",
  insurer_code_written: "",
  number_of_disclosed_convictions: "",
  policy_number: "",
  policy_status_cancelled: "",
  requested_by: "",
  assigned_to: "",
  branch: "",
  producer: "",
  csr: "",
  notes: "",
  file_type: "",
  file_type_two: "",
  file_upload: "",
  file_upload_two: "",
  date_wriitten: "",
  date_destroyed: "",
  date_voided: "",
  date_cancelled: "",
  status: "ACTIVE",
  incremental_number: null,
  years_at_this_address: "",
  current_address_type: "",
  current_address: "",
  current_address_unit_or_suite: "",
  current_address_city: "",
  current_address_state_or_province: "",
  current_address_postal_code: "",
  current_address_country: "",
  new_address_type: "",
  new_address: "",
  new_address_unit_or_suite: "",
  new_address_city: "",
  new_address_state_or_province: "",
  new_address_postal_code: "",
  new_address_country: "",
};

export const InsuranceReport: React.FC<{
  onChange: (newTab: number) => void;
}> = (props) => {
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      title: "insurance-reports",
    },
  ];

  const { list, loading, totalRecords } = useSelector(
    (storeState: IStoreState) => storeState.insuranceReports
  );

  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });
  const [selectedOpp, setSelectedOppr] = React.useState<{
    isAllSelected: boolean;
    leads: IInsuranceReports[];
  }>({
    isAllSelected: false,
    leads: [],
  });
  const [status, setStatus] = React.useState<
    IInsuranceReports["request_status"] | "-1"
  >("Submitted");

  const [openView, setOpenView] =
    React.useState<INoPolicyInsuranceReports | null>(null);

  const [searchType, setSearchType] = React.useState<string[]>(["driver_name"]);
  const [search, setSearch] = React.useState<string>("");
  const [openWarning, setOpenWarning] = React.useState<boolean>(false);
  const [tableTabs, setTableTabs] = React.useState<ITableTab[]>([]);

  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "lastWeek" });

  const dispatch = useDispatchWrapper();

  const fetchList = async () => {
    dispatch(
      fetchInsuranceReportsListAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        status,
        date,
        search,
        searchType
      )
    );
  };

  const handleComplete = () => {
    fetchList();
    setOpenView(null);
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagination, date, status, search]);

  const menuItems =
    selectedOpp.leads.length > 0 || selectedOpp.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const tableCommandBarProps: ITableCommandBarProps = {
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          ...menuItems,

          {
            key: "byDate",
            onRender: () => (
              <DateFilterDropdown
                value={type}
                options={dateDropdownList}
                onChange={handleDateSelectChange}
              />
            ),
          },
          {
            key: "fromDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.fromDate}
                  onChange={(newValue) => {
                    setDate({ ...date, fromDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "toDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.toDate}
                  onChange={(newValue) => {
                    setDate({ ...date, toDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },

          {
            key: "refresh",
            onRender: () => <RefreshPlugin onClick={fetchList} />,
          },
        ],
      },
      rightItems: {
        plugins: {
          // searchField: {
          //   searchKeys: ["opportunity_id", "customer_id", "lead_id", "status"],
          //   items: list,
          // },
        },
        customPlugins: [
          {
            key: "search",
            onRender: () => {
              return (
                <SearchPlugin
                  selectedDropdownValue={searchType}
                  dropdownOptions={[
                    { label: "Driver Name", value: "driver_name" },
                    { label: "License Number", value: "license_number" },
                    // { label: "Transaction Type", value: "transaction_type" },
                    // { label: "Report Requested", value: "report_requested" },
                    { label: "Report Status", value: "request_status" },
                    // { label: "Requested By", value: "requested_by" },
                    // { label: "Policy Number", value: "policy_number" },
                    { label: "Branch", value: "branch" },
                    // { label: "Date Written", value: "date_wriitten" },
                  ]}
                  onDropdownChange={(value) => setSearchType(value)}
                  onChange={(newValue) => {
                    setSearch(newValue);
                  }}
                />
              );
            },
          },
        ],
      },
    },
    columns: [],
    items: [],
  };

  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();

  React.useEffect(() => {
    const tabsData = createTabsWithRecordcounts(
      [
        { label: "Submitted", value: "Submitted", variant: "warning" },
        { label: "Voided", value: "Voided", variant: "grey" },
        { label: "Completed", value: "Completed", variant: "success" },
        { label: "Cancelled", value: "Cancelled", variant: "error" },
        { label: "Destroyed", value: "Destroyed", variant: "error" },
      ],
      recordsCounts
    );
    setTableTabs(tabsData);
  }, [recordsCounts]);

  React.useEffect(() => {
    dispatch(
      fetchRecordCountsAsync(
        "latest_task_driver_insurance_report",
        "request_status",
        date.fromDate,
        date.toDate
      )
    );
    return () => {
      dispatch(clearRecordCounts());
    };
  }, [date.fromDate, date.toDate]);

  const handlePageChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setTablePagination({ ...tablePagination, pageNumber: newPage + 1 });
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const rowsPerPage = parseInt(event.target.value, 10);

    setTablePagination({
      ...tablePagination,
      rowsInPerPage:
        rowsPerPage === -1 ? totalRecords || list.length : rowsPerPage,
    });
  };

  React.useEffect(() => {
    setTimeout(() => {
      setOpenWarning(true);
    }, 1000);
  }, []);

  return (
    <PageContainer
      title="View Insurance Report"
      description="this is innerpage"
    >
      {/* <Breadcrumb title="View Opportunites" items={BCrumb} /> */}

      <PageTitleBar
        heading="Insurance Reports"
        rightHeading={
          <Stack direction={"row"} spacing={2}>
       
            <Button
              variant="contained"
              size="small"
              startIcon={<AddOutlined />}
              onClick={() =>
                setOpenView(INITIAL_STATE as INoPolicyInsuranceReports)
              }
            >
              Order Insurance Reports
            </Button>
          </Stack>
        }
      />

      <Box marginBottom={2}>
      <Tabs value={0} variant="fullWidth"    onChange={(e, value) => props.onChange(value)}>
        <Tab
        sx={{minWidth: '400px'}}
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography fontWeight={600} sx={{ fontSize: "1.1rem" }}>
              Comm-Auto & Automobile Insurance Reports
              </Typography>
             
            </div>
          }
          value={0}
        ></Tab>
         <Tab
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography fontWeight={500} sx={{ fontSize: "1rem" }}>
              No Policy Insurance Reports
              </Typography>
             
            </div>
          }
          value={1}
        ></Tab>
      </Tabs>
      </Box>

      <TableCommandBar
        {...tableCommandBarProps}
        tableTabsProps={{
          selectedTab: status,
          tabs: tableTabs,
          onTabChange: (newSelectedTab) => {
            setStatus(newSelectedTab);
          },
        }}
      />
      <Card
        className="tableScroll"
        sx={{
          borderRadius: "20px",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 10px",
          border: 0,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          padding: 0,
          marginTop: 0,
          m: 0,
        }}
      >
        <CardContent sx={{ padding: "0px !important" }}>
          <Box
            sx={{
              overflowY: "auto",
              overflowX: "auto",
            }}
          >
            <Table
              aria-label="collapsible table"
              sx={{
                whiteSpace: {
                  xs: "nowrap",
                  sm: "unset",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <Typography variant="h6" fontWeight={"600"}>
                      ID
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" fontWeight={"600"}>
                      Driver Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" fontWeight={"600"}>
                      License Number
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" fontWeight={"600"}>
                      Policy Type
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" fontWeight={"600"}>
                      Business Reason
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography variant="h6" fontWeight={"600"}>
                      Insurer Code
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" fontWeight={"600"}>
                      Branch
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" fontWeight={"600"}>
                      Producer
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" fontWeight={"600"}>
                      CSR
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" fontWeight={"600"}>
                      Request Status
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" fontWeight={"600"}>
                      Status
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" fontWeight={"600"}>
                      Modified Date
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading === LoadState.InProgress ? (
                  <TableSkeleton numberOfCells={13} numberOfRows={5} />
                ) : (
                  list.map((data) => (
                    <Row key={data.customer_policy_id} data={data} />
                  ))
                )}
              </TableBody>
            </Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
                  sx={{ borderBottom: 0 }}
                  //colSpan={columns.length + 1}
                  count={totalRecords}
                  rowsPerPage={tablePagination.rowsInPerPage}
                  page={tablePagination.pageNumber - 1}
                  SelectProps={{
                    native: true,
                  }}
                  labelDisplayedRows={(data) => {
                    return (
                      <>
                        {data.from}-{data.to}
                      </>
                    );
                  }}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Box>
        </CardContent>
      </Card>

      {openView && (
        <InsuranceReportDialog
          open={true}
          onClose={() => setOpenView(null)}
          data={openView}
          onComplete={handleComplete}
        />
      )}

      {openWarning && (
        <InsuranceReportsWarningDialog
          open={true}
          onClose={() => setOpenWarning(false)}
        />
      )}
    </PageContainer>
  );
};

function Row(props: { data: IGroupedInsuranceReports }) {
  const { data } = props;
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const renderLink = (row: IInsuranceReports, value: string | number) => {
    let firstHalf: string = "";
    if (row.policy_type === "Commercial Auto") {
      firstHalf = "comm-automobile-policy";
    } else {
      firstHalf = "automobile-policy";
    }

    return (
      <CustomLink
        to={`/${firstHalf}/${row.customer_id}/driver-tab/${row.customer_policy_id}/activity-task/${row.incremental_number}`}
        label={value.toString()}
      />
    );
  };

  const handleOpen = (row: IInsuranceReports) => {
    let firstHalf: string = "";
    if (row.policy_type === "Commercial Auto") {
      firstHalf = "comm-automobile-policy";
    } else {
      firstHalf = "automobile-policy";
    }
    navigate(
      `/${firstHalf}/${row.customer_id}/driver-tab/${row.customer_policy_id}/activity-task/${row.incremental_number}`
    );
  };

  return (
    <>
      <TableRow
        sx={
          open
            ? {
                background: (theme) =>
                  `${
                    theme.palette.mode === "dark"
                      ? theme.palette.grey.A200
                      : theme.palette.error.light
                  }`,
              }
            : null
        }
      >
        <TableCell
          sx={{
            borderBottom: "0",
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          sx={{
            borderBottom: "0",
          }}
        >
          <Typography color="textSecondary" variant="h5" fontWeight="600">
            {renderLink(data, data.customer_policy_id)}
          </Typography>
        </TableCell>
        <TableCell
          sx={{
            borderBottom: "0",
          }}
        >
          <Typography variant="body1"> {data.driver_name}</Typography>
        </TableCell>
        <TableCell
          sx={{
            borderBottom: "0",
          }}
        >
          <Typography variant="body1"> {data.license_number}</Typography>
        </TableCell>
        <TableCell
          sx={{
            borderBottom: "0",
          }}
        >
          <Typography variant="body1" fontWeight={600}>
            {" "}
            {data.policy_type}
          </Typography>
        </TableCell>
        <TableCell
          sx={{
            borderBottom: "0",
          }}
        >
          <Typography variant="body1"> {data.business_reason}</Typography>
        </TableCell>
        <TableCell
          sx={{
            borderBottom: "0",
          }}
        >
          <Typography variant="body1"> {data.insurer_code}</Typography>
        </TableCell>
        <TableCell
          sx={{
            borderBottom: "0",
          }}
        >
          <Typography variant="body1"> {data.branch}</Typography>
        </TableCell>
        <TableCell
          sx={{
            borderBottom: "0",
          }}
        >
          <Typography variant="body1"> {data.producer_one_code}</Typography>
        </TableCell>
        <TableCell
          sx={{
            borderBottom: "0",
          }}
        >
          <Typography variant="body1"> {data.csr_code}</Typography>
        </TableCell>
        <TableCell
          sx={{
            borderBottom: "0",
          }}
        >
          <StatusRenderer status={data.request_status || ""} />
        </TableCell>
        <TableCell
          sx={{
            borderBottom: "0",
          }}
        >
          {data.status === "ACTIVE" ? (
            <CustomChip color="success" size="small" content="Compliant" />
          ) : (
            <CustomChip color="error" size="small" content="Non-Compliant" />
          )}
        </TableCell>
        <TableCell>
          <Typography variant="body1">
            {" "}
            {data.insert_ts ? formatDate(data.insert_ts) : "--"}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{ paddingBottom: 0, paddingTop: 0, pl: 0, pr: 0 }}
          colSpan={13}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{
              background: (theme) =>
                `${
                  theme.palette.mode === "dark"
                    ? theme.palette.grey.A200
                    : theme.palette.grey.A700
                }`,
            }}
          >
            <Box marginBottom={5} marginTop={3}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6" fontWeight={600}>
                        Actions
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={600}>
                        Id
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={600}>
                        Driver Name
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={600}>
                        License Number
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={600}>
                        Policy Type
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={600}>
                        Business Reason
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={600}>
                        Insurer Code
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={600}>
                        Branch
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={600}>
                        Producer
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={600}>
                        CSR
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={600}>
                        Request Status
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={600}>
                        Status
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={600}>
                        Modified Date
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.childs.map((item) => {
                    return (
                      <TableRow key={item.customer_policy_id}>
                        <TableCell>
                          <StandardTableActions
                            onEditClick={() => handleOpen(item)}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "0",
                          }}
                        >
                          {renderLink(item, item.customer_policy_id || "--")}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "0",
                          }}
                        >
                          <Typography variant="body1">
                            {item.driver_name}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "0",
                          }}
                        >
                          <Typography variant="body1">
                            {item.license_number}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "0",
                          }}
                        >
                          <Typography
                            variant="body1"
                            color="error"
                            fontWeight={600}
                          >
                            {item.policy_type}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "0",
                          }}
                        >
                          <Typography variant="body1">
                            {item.business_reason}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "0",
                          }}
                        >
                          <Typography variant="body1">
                            {item.insurer_code}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "0",
                          }}
                        >
                          <Typography variant="body1">{item.branch}</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "0",
                          }}
                        >
                          <Typography variant="body1">
                            {item.producer_one_code}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "0",
                          }}
                        >
                          <Typography variant="body1">
                            {item.csr_code}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "0",
                          }}
                        >
                          <StatusRenderer status={item.request_status || ""} />
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "0",
                          }}
                        >
                          {data.status === "ACTIVE" ? (
                            <CustomChip
                              color="success"
                              size="small"
                              content="Compliant"
                            />
                          ) : (
                            <CustomChip
                              color="error"
                              size="small"
                              content="Non-Compliant"
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {item.insert_ts ? formatDate(item.insert_ts) : "--"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
