import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import { INewRemarket, defaultNewRemarket } from "./NewRemarket.types";
import { uploadFile } from "../../helpers";

export const FETCH_TASK_NEW_REMARKET_PROGRESS =
  "FETCH_TASK_NEW_REMARKET_PROGRESS";
export const FETCH_TASK_NEW_REMARKET_SUCCESS =
  "FETCH_TASK_NEW_REMARKET_SUCCESS";
export const FETCH_TASK_NEW_REMARKET_FAILED =
  "FETCH_TASK_NEW_REMARKET_FAILED";

export const fetchTaskNewRemarketProgress = () =>
  action(FETCH_TASK_NEW_REMARKET_PROGRESS);
export const fetchTaskNewRemarketSuccess = (data: INewRemarket) =>
  action(FETCH_TASK_NEW_REMARKET_SUCCESS, { data });
export const fetchTaskNewRemarketFailed = () =>
  action(FETCH_TASK_NEW_REMARKET_FAILED);

export const fetchTaskNewRemarketAysnc =
  (taskCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTaskNewRemarketProgress());
      const res = await api.get(
        `/tasks/get-task-new-remarket?task_code=${taskCode}`
      );
      const data: INewRemarket[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchTaskNewRemarketSuccess(data[0]));
      } else {
        dispatch(
          fetchTaskNewRemarketSuccess(
            defaultNewRemarket.NewRemarket["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchTaskNewRemarketFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskNewRemarketAysnc =
  (
    data: INewRemarket,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/tasks/upsert-task-new-remarket", {
        ...data
      });
      dispatch(
        showMessage({
          type: "success",
          message: "New Remarket is saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }finally {
      dispatch(saveLoaderCompleted())
    }
  };

  
export const CLEAR_TASK_NEW_REMARKET_STATE =
"CLEAR_TASK_NEW_REMARKET_STATE";

export const clearTaskNewRemarketState = () =>
action(CLEAR_TASK_NEW_REMARKET_STATE);