import { Box, Theme, Typography } from "@mui/material";

export const PolicyTabCard: React.FC<{
  label: string;
  isSelected: boolean;
  disabled?: boolean;
  onClick: () => void;
}> = (props) => {
  const { label, onClick, disabled } = props;
  return (
    <Box
      onClick={disabled ? () => {} : onClick}
      sx={(theme: Theme) => ({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: props.isSelected ? "error.main": "primary.main",
        borderRadius: '15px',
        padding: "16px 28px",
        margin: "5px",
        width: "fit-content",
        opacity: disabled ? "0.5" : 1,
        cursor: disabled ? "default" : "pointer",
        
     
        "&:hover": {
          boxShadow: theme.shadows[15] + " !important",
        },
      })}
    >
      <Typography
        variant="h5"
        color={"white"}
        sx={{ ml: 1 }}
        textAlign={"center"}
        fontSize={"0.9rem"}
        fontWeight={800}
      >
        {label}
      </Typography>
    </Box>
  );
};