import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialInvoiceTemplateState } from "./Template.types";
import { InvoiceTemplateActions } from ".";
import {
  CLEAR_INVOICE_TEMPLATE,
  FETCH_TEMPLATE_FAILURE,
  FETCH_TEMPLATE_PROGRESS,
  FETCH_TEMPLATE_SUCCESS,
} from "./TemplateActions";

export const AccountingInvoiceTemplateReducer = (
  state: IStoreState["accounting"]["invoiceTemplate"] = initialInvoiceTemplateState,
  action: InvoiceTemplateActions
) => {
  switch (action.type) {
    case FETCH_TEMPLATE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_TEMPLATE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_TEMPLATE_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_INVOICE_TEMPLATE: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = initialInvoiceTemplateState.data;
        draftState.error = null;
      });
      return newState;
    }
    default:
      return state;
  }
};
