import produce from "immer";

import { LoadState } from "../../../constants/enums";
import { IStoreState } from "../../initialStoreState";
import { initialBusinessPolicyMarketing } from "./businessPolicyMarketing.types";
import { BusinesPolicyMarketingActions } from ".";
import {
  CLEAR_BUSINESS_POLICY_MARKETING_HISTORY,
  CLEAR_BUSINESS_POLICY_MARKETING_STATE,
  FETCH_BUSINESS_POLICY_BINDER_LIST_FAILED,
  FETCH_BUSINESS_POLICY_BINDER_LIST_PROGRESS,
  FETCH_BUSINESS_POLICY_BINDER_LIST_SUCCESS,
  FETCH_BUSINESS_POLICY_MARKETING_HISTORY_ATTACHMENTS_FAILED,
  FETCH_BUSINESS_POLICY_MARKETING_HISTORY_ATTACHMENTS_PROGRESS,
  FETCH_BUSINESS_POLICY_MARKETING_HISTORY_ATTACHMENTS_SUCCESS,
  FETCH_BUSINESS_POLICY_MARKETING_HISTORY_FAILED,
  FETCH_BUSINESS_POLICY_MARKETING_HISTORY_LIST_FAILED,
  FETCH_BUSINESS_POLICY_MARKETING_HISTORY_LIST_PROGRESS,
  FETCH_BUSINESS_POLICY_MARKETING_HISTORY_LIST_SUCCESS,
  FETCH_BUSINESS_POLICY_MARKETING_HISTORY_PROGRESS,
  FETCH_BUSINESS_POLICY_MARKETING_HISTORY_SUCCESS,
} from "./businessPolicyMarketingActions";

export const businessPolicyMarketingReducer = (
  state: IStoreState["business"]["marketing"] = initialBusinessPolicyMarketing,
  action: BusinesPolicyMarketingActions
) => {
  switch (action.type) {
    case FETCH_BUSINESS_POLICY_MARKETING_HISTORY_ATTACHMENTS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.attachmentLoading = LoadState.InProgress;
        draftState.attachmentsHistory = [];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_MARKETING_HISTORY_ATTACHMENTS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.attachmentLoading = LoadState.Loaded;
        draftState.attachmentsHistory = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_MARKETING_HISTORY_ATTACHMENTS_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.attachmentLoading = LoadState.Failed;
        draftState.attachmentsHistory = [];
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_MARKETING_HISTORY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.marketingHistory.loading = LoadState.InProgress;
        draftState.marketingHistory.list = [];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_MARKETING_HISTORY_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.marketingHistory.loading = LoadState.Loaded;
        draftState.marketingHistory.list = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_MARKETING_HISTORY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.marketingHistory.loading = LoadState.Failed;
        draftState.marketingHistory.list = [];
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_MARKETING_HISTORY_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.marketingHistory.marketingHistoryLoading = LoadState.InProgress;
        draftState.marketingHistory =
          initialBusinessPolicyMarketing["marketingHistory"];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_MARKETING_HISTORY_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.marketingHistory.marketingHistoryLoading = LoadState.Loaded;
        draftState.marketingHistory.marketingHistory = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_MARKETING_HISTORY_FAILED: {
      const {errorMessage} = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.marketingHistory.marketingHistoryLoading = LoadState.Failed;
        draftState.marketingHistory.error = errorMessage;
      });
      return newState;
    
    }

    case CLEAR_BUSINESS_POLICY_MARKETING_HISTORY: {
      const newState = produce(state, (draftState) => {
        draftState.marketingHistory.marketingHistory =initialBusinessPolicyMarketing['marketingHistory'].marketingHistory;
        draftState.marketingHistory.marketingHistoryLoading = LoadState.NotLoaded;
        draftState.marketingHistory.error = null;
      });
      return newState;
    }


    case FETCH_BUSINESS_POLICY_BINDER_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.binderHistory.loading = LoadState.InProgress;
        draftState.binderHistory.list = [];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_BINDER_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.binderHistory.loading = LoadState.Loaded;
        draftState.binderHistory.list = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_BINDER_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.binderHistory.loading = LoadState.Failed;
        draftState.binderHistory.list = [];
      });
      return newState;
    }
    case CLEAR_BUSINESS_POLICY_MARKETING_STATE: {
      return initialBusinessPolicyMarketing;
    }

    default: {
      return state;
    }
  }
};
