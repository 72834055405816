import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import {
  IAutomobileGarageApplicationData,
  defaultAutomobileGarageApplicationState,
} from "./automobileGarageApplication.types";

export const FETCH_AUTOMOBILE_GARAGE_APPLICATION_PROGRESS =
  "FETCH_AUTOMOBILE_GARAGE_APPLICATION_PROGRESS";
export const FETCH_AUTOMOBILE_GARAGE_APPLICATION_SUCCESSS =
  "FETCH_AUTOMOBILE_GARAGE_APPLICATION_SUCCESSS";
export const FETCH_AUTOMOBILE_GARAGE_APPLICATION_FAILED =
  "FETCH_AUTOMOBILE_GARAGE_APPLICATION_FAILED";

export const fetchAutomobileGarageApplicationProgress = () =>
  action(FETCH_AUTOMOBILE_GARAGE_APPLICATION_PROGRESS);
export const fetchAutomobileGarageApplicationSuccess = (
  data: IAutomobileGarageApplicationData
) => action(FETCH_AUTOMOBILE_GARAGE_APPLICATION_SUCCESSS, { data });
export const fetchAutomobileGarageApplicationFailed = () =>
  action(FETCH_AUTOMOBILE_GARAGE_APPLICATION_FAILED);

export const fetchAutomobileGarageApplicationAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileGarageApplicationProgress());
      const res = await api.get(
        `/policy/get-commauto-garage-application?customer_policy_id=${customerPolicyId}`
      );
      const data: IAutomobileGarageApplicationData[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileGarageApplicationSuccess(
            defaultAutomobileGarageApplicationState["data"]
          )
        );
      } else {
        dispatch(
          fetchAutomobileGarageApplicationSuccess(
            defaultAutomobileGarageApplicationState["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchAutomobileGarageApplicationFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAutomobileGarageApplication =
  (
    data: IAutomobileGarageApplicationData,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-commauto-garage-application", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Garage Application  saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchAutomobileGarageApplicationSuccess(data));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_AUTOMOBILE_GARAGE_APPLICATION_STATE =
  "CLEAR_AUTOMOBILE_GARAGE_APPLICATION_STATE";

export const clearAutomobileGarageApplicationState = () =>
  action(CLEAR_AUTOMOBILE_GARAGE_APPLICATION_STATE);

// /policy/edit-commauto-garage-application
// /policy/get-commauto-garage-application
