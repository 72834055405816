import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { api } from "../../../api/api";
import { IBusinessPolicyAuthorization } from "./businessPolicyAuthorization.types";
import { ERROR_MESSAGES } from "../../../constants/enums";

export const FETCH_BUSINESS_POLICY_AUTHORIZATION_LIST_PROGRESS =
  "FETCH_BUSINESS_POLICY_AUTHORIZATION_LIST_PROGRESS";
export const FETCH_BUSINESS_POLICY_AUTHORIZATION_LIST_SUCCESS =
  "FETCH_BUSINESS_POLICY_AUTHORIZATION_LIST_SUCCESS";
export const FETCH_BUSINESS_POLICY_AUTHORIZATION_LIST_FAILED =
  "FETCH_BUSINESS_POLICY_AUTHORIZATION_LIST_FAILED";

export const fetchBusinessPolicyAuthorizationListProgress = () =>
  action(FETCH_BUSINESS_POLICY_AUTHORIZATION_LIST_PROGRESS);
export const fetchBusinessPolicyAuthorizationListSuccess = (
  data: IBusinessPolicyAuthorization[]
) => action(FETCH_BUSINESS_POLICY_AUTHORIZATION_LIST_SUCCESS, { data });
export const fetchBusinessPolicyAuthorizationListFailed = () =>
  action(FETCH_BUSINESS_POLICY_AUTHORIZATION_LIST_FAILED);

export const fetchBusinessPolicyAuthorizationListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyAuthorizationListProgress());
      const res = await api.get(
        `/policy/get-policy-business-pre-authorization-form?customer_policy_id=${customerPolicyId}`
      );
      const data: IBusinessPolicyAuthorization[] = res.data.data;
      dispatch(fetchBusinessPolicyAuthorizationListSuccess(data));
    } catch (err: any) {
      dispatch(fetchBusinessPolicyAuthorizationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadCommPolicyAuthorizationListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-business-pre-authorization-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchBusinessPolicyAuthorizationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommPolicyAuthorizationListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-business-pre-authorization-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_pre_authorization_form_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchBusinessPolicyAuthorizationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addBusinesPolicyAuthorizationAsync =
  (
    data: IBusinessPolicyAuthorization,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      //dispatch(fetchBusinessPolicyRiskProgress());
      await api.post(`/policy/edit-policy-business-pre-authorization-form`, {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Authorization saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_BUSINESS_POLICY_AUTHORIZATION_PROGRESS =
  "FETCH_BUSINESS_POLICY_AUTHORIZATION_PROGRESS";
export const FETCH_BUSINESS_POLICY_AUTHORIZATION_SUCCESS =
  "FETCH_BUSINESS_POLICY_AUTHORIZATION_SUCCESS";
export const FETCH_BUSINESS_POLICY_AUTHORIZATION_FAILED =
  "FETCH_BUSINESS_POLICY_AUTHORIZATION_FAILED";

export const fetchBusinessPolicyAuthorizationProgress = () =>
  action(FETCH_BUSINESS_POLICY_AUTHORIZATION_PROGRESS);
export const fetchBusinessPolicyAuthorizationSuccess = (
  data: IBusinessPolicyAuthorization
) => action(FETCH_BUSINESS_POLICY_AUTHORIZATION_SUCCESS, { data });
export const fetchBusinessPolicyAuthorizationFailed = (errorMessage: string) =>
  action(FETCH_BUSINESS_POLICY_AUTHORIZATION_FAILED, { errorMessage });

export const fetchBusinessPolicyAuthorizationAsync =
  (
    customerPolicyId: number,
    incrementNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyAuthorizationProgress());
      const res = await api.get(
        `/policy/get-policy-business-pre-authorization-form?customer_policy_id=${customerPolicyId}&value=${incrementNumber}&column=incremental_number`
      );
      const data: IBusinessPolicyAuthorization[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBusinessPolicyAuthorizationSuccess(data[0]));
      } else {
        dispatch(
          fetchBusinessPolicyAuthorizationFailed(
            "Oops! We couldn't find any records associated with your number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchBusinessPolicyAuthorizationFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_BUSINES_POLICY_AUTHORIZATION_STATE =
  "CLEAR_BUSINES_POLICY_AUTHORIZATION_STATE";
export const CLEAR_BUSINES_POLICY_AUTHORIZATION =
  "CLEAR_BUSINES_POLICY_AUTHORIZATION";

export const clearBusinessAuthorizationState = () =>
  action(CLEAR_BUSINES_POLICY_AUTHORIZATION_STATE);

export const clearBusinessAuthorization = () =>
  action(CLEAR_BUSINES_POLICY_AUTHORIZATION);
