import { LoadState } from "../../constants/enums";
import { IPolicyCommissionState } from "./policyCommission.types";

export const initialPolicyCommissioneState: IPolicyCommissionState = {
  policyCommission: {
    data: {
      policy_commission_id: null,
      policy_commission_primary_id: null,
      insurer_code_id: 2,
      policy_code: "",
      description: null,
      insurer_code_with_name: null,
      producer_code: null,
      contract_number: null,
      commission_new: null,
      house_new: null,
      producer_new: null,
      commission_renewal: null,
      house_renewal: null,
      producer_renewal: null,
      commission_endorsement: null,
      house_endorsement: null,
      producer_endorsement: null,
      commission_bonus: null,
      house_bonus: null,
      producer_bonus: null,
      status: "ACTIVE",
      insert_ts: "",
    },
    loading: LoadState.NotLoaded,
    saveLoading: false,
    error: null,
  },
};
