import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { IAccountingBill, IAccountingBillHistory, initialAccountingBillState } from "./Bill.types";
import { IDate } from "../../../components/Table/hooks/useDateFilter";
import { ERROR_MESSAGES } from "../../../constants/enums";

export const FETCH_ACCOUNTING_BILL_PROGRESS = "FETCH_ACCOUNTING_BILL_PROGRESS";
export const FETCH_ACCOUNTING_BILL_SUCCESS = "FETCH_ACCOUNTING_BILL_SUCCESS";
export const FETCH_ACCOUNTING_BILL_FAILURE = "FETCH_ACCOUNTING_BILL_FAILURE";

export const fetchAccountingBillProgress = () =>
  action(FETCH_ACCOUNTING_BILL_PROGRESS);

export const fetchAccountingBillSuccess = (data: IAccountingBill) =>
  action(FETCH_ACCOUNTING_BILL_SUCCESS, { data });

export const fetchAccountingBillFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_BILL_FAILURE, { errorMessage });

export const fetchAccountingBillAsync =
  (billId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingBillProgress());

      const res = await api.get(
        `/accounting/get-account-create-bill?bill_id=${billId}`
      );
      const data: IAccountingBill[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAccountingBillSuccess(data[0]));
      }
    } catch (err: any) {
      dispatch(fetchAccountingBillFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingBillAysnc =
  (
    data: IAccountingBill,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/accounting/edit-account-create-bill", {
        ...data,
      });
      let message = "Bill saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_ACCOUNTING_BILL_LIST_PROGRESS =
  "FETCH_ACCOUNTING_BILL_LIST_PROGRESS";
export const FETCH_ACCOUNTING_BILL_LIST_SUCCESS =
  "FETCH_ACCOUNTING_BILL_LIST_SUCCESS";
export const FETCH_ACCOUNTING_BILL_LIST_FAILURE =
  "FETCH_ACCOUNTING_BILL_LIST_FAILURE";

export const fetchAccountingBillListProgress = () =>
  action(FETCH_ACCOUNTING_BILL_LIST_PROGRESS);

export const fetchAccountingBillListSuccess = (data: IAccountingBill[]) =>
  action(FETCH_ACCOUNTING_BILL_LIST_SUCCESS, { data });

export const fetchAccountingBillListFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_BILL_LIST_FAILURE, { errorMessage });

export const fetchAccountingBillListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[]
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingBillListProgress());
      let finalUrl = `/accounting/get-account-create-bill?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;

      if (searchType.length > 0 && searchValue) {
        finalUrl = `/accounting/get-account-create-bill?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}&column=${[searchType]}&value=${searchValue}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }

      const res = await api.get(finalUrl);
      const data: IAccountingBill[] = res.data.data;
      dispatch(fetchAccountingBillListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAccountingBillListFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };


  export const fetchAccountingBillByVendorAsync =
  (
    vendorCode: string,
    onCallback: (data: IAccountingBill[]) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const finalUrl = `/accounting/get-account-create-bill?code=${vendorCode}&status=ACTIVE`;
      const res = await api.get(finalUrl);
      let data: IAccountingBill[] = res.data.data;
       data = data.sort((x,y)=>{
        if((x.bill_id && y.bill_id )&&x.bill_id > y.bill_id){
          return 1;
        }
        return -1
       })
      onCallback(data);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };


  export const FETCH_BILL_PAYMENT_HISTORY_PROGRESS =
  "FETCH_BILL_PAYMENT_HISTORY_PROGRESS";
export const FETCH_BILL_PAYMENT_HISTORY_SUCCESS =
  "FETCH_BILL_PAYMENT_HISTORY_SUCCESS";
export const FETCH_BILL_PAYMENT_HISTORY_FAILED =
  "FETCH_BILL_PAYMENT_HISTORY_FAILED";

export const fetchBillPaymentHistoryProgress = () =>
  action(FETCH_BILL_PAYMENT_HISTORY_PROGRESS);
export const fetchBillPaymentHistorySuccess = (
  data: IAccountingBillHistory
) =>
  action(FETCH_BILL_PAYMENT_HISTORY_SUCCESS, {
    data,
  });
export const fetchBillPaymentHistoryFailed = (errorMessage: string) =>
  action(FETCH_BILL_PAYMENT_HISTORY_FAILED, { errorMessage });

export const fetchBillPaymentHistoryAsync =
  (billId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBillPaymentHistoryProgress());

      const res = await api.get(
        `/accounting/get-account-bill-and-payment-history?bill_id=${billId}`
      );
      const data: IAccountingBillHistory[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBillPaymentHistorySuccess(data[0]));
      } else {
        dispatch(
          fetchBillPaymentHistoryFailed(ERROR_MESSAGES.NO_RECORD_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchBillPaymentHistoryFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_ACCOUNTING_BILL = "CLEAR_ACCOUNTING_BILL";
export const clearAccountingBill = () => action(CLEAR_ACCOUNTING_BILL);
