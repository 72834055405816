import { LoadState } from "../../../constants/enums";

export interface IBusinessPolicyUnderWriting {
  customer_policy_id: number;
  customer_id: number;
  customer_name: string;
  policy_number: string | null;
  description: string | null;
  industry_code: string | null;
  business_description: string | null;
  known_to_broker_since: string | null;
  liquor_liability_exposure: string | null;
  in_business_since: string | null;
  number_of_years_continuously_insured: string | null;
  related_prior_experience: string | null;
  cancellation_or_renewal_refusal_by_insurer_in_last_five_years: string | null;
  sub_trades_percentage: string | null;
  percentage_internet_sales: string | null;
  are_you_the_incument_broker: string | null;
  insured_is: string | null;
  insurance_experience: string | null;
  renewal_offered: string | null;
  if_not_reason: string | null;
  steps_taken_to_prevent_further_losses: string | null;
  covered_by_wcb: string | null;
  gross_revenue: IGrossRevenue[];
  gross_revenue_sum: number;
  employees_and_payroll: IEmpolyeePayroll[];
  line_of_business: string | null;
  policy_expiry_date: string | null;
  current_insurer: string | null;
  expiring_premium: string | null;
  current_status: string | null;
  name: string | null;
  date_of_brith: string | null;
  street_address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
  years_at_this_address: string | null;
  status: "ACTIVE" | "PENDING";
}

export interface IGrossRevenue {
  key: string;
  business_activity: string | null;
  cdn_percentage: string | null;
  usa_percentage: string | null;
  foreign_percentage: string | null;
  gross_revenue: string | null;
}

export interface IEmpolyeePayroll {
  key: string;
  year: string | null;
  part_time: string | null;
  full_time: string | null;
  payroll_amount: string | null;
}

export interface IBusinessPolicyUnderWritingState {
  data: IBusinessPolicyUnderWriting;
  loading: LoadState;
}

export const defaultBusinessPolicyUnderWritingState: IBusinessPolicyUnderWritingState =
  {
    data: {
      customer_policy_id: 0,
      customer_id: 0,
      customer_name: "",
      description: null,
      policy_number: null,
      industry_code: null,
      business_description: null,
      known_to_broker_since: null,
      liquor_liability_exposure: null,
      in_business_since: null,
      number_of_years_continuously_insured: null,
      related_prior_experience: null,
      cancellation_or_renewal_refusal_by_insurer_in_last_five_years: null,
      sub_trades_percentage: null,
      percentage_internet_sales: null,
      are_you_the_incument_broker: null,
      insured_is: null,
      insurance_experience: null,
      renewal_offered: null,
      if_not_reason: null,
      steps_taken_to_prevent_further_losses: null,
      covered_by_wcb: null,
      gross_revenue: [],
      gross_revenue_sum: 0,
      employees_and_payroll: [],
      line_of_business: null,
      policy_expiry_date: null,
      current_insurer: null,
      expiring_premium: null,
      current_status: null,
      name: null,
      date_of_brith: null,
      street_address: null,
      city: null,
      country: null,
      postal_code: null,
      province_or_state: null,
      unit_or_suite: null,
      years_at_this_address: null,
      status: "ACTIVE",
    },
    loading: LoadState.NotLoaded,
  };
