import React from "react";
import { Dialog } from "../../../../../components/Dialogs/Dialog";
import {  Grid } from "@mui/material";
import { StandadCard } from "../../../../../components/Cards";
import { StandardCellList } from "../../../../../components/Standards";
import { formatDate } from "../../../../../helpers";
import { IViewUnderWriterDetailsProps } from "./ViewUnderWritersDetails.types";

export const ViewUnderWritersDetails: React.FC<IViewUnderWriterDetailsProps> = (props) => {
  const { open, underwriter , onClose } = props;

  return (
    <Dialog
      open={open}
      title="Under Writer Summary"
      onClose={onClose}
      size="xl"
      actions={[
        { type: "button", label: "Close", variant: "contained", onClick: onClose },
      ]}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
        
            <StandardCellList
            heading="About Under Writers"
              colSize={3}
              list={[
                {
                  heading: 'Insurer Code',
                  data: { type: "text", value: underwriter.insurer_code },
                },
               
                {
                  heading: 'Insurer Code ID',
                  data: { type: "text", value: underwriter.insurer_code_id },
                },

                {
                  heading: "Name",
                  data: { type: "text", value: underwriter.name },
                },

                {
                  heading: "Phone Number",
                  data: {
                    type: "text",
                    value: underwriter.phone_number,
                  },
                },

                {
                  heading: "Mobile Number",
                  data: {
                    type: "text",
                    value: underwriter.mobile_number,
                  },
                },

                {
                  heading: "Email ID",
                  data: {
                    type: "text",
                    value: underwriter.email,
                  },
                },

                {
                  heading: "Department Email ID",
                  data: {
                    type: "text",
                    value: underwriter.department_email,
                  },
                },

                {
                  heading: "Date",
                  data: {
                    type: "text",
                    value: formatDate(underwriter.insert_ts),
                  },
                },

                {
                  heading: "Status",
                  data: {
                    type: "text",
                    value: underwriter.status,
                  },
                },
            
              ]}
            />
        </Grid>
     
      </Grid>
    </Dialog>
  );
};
