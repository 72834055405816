import { LoadState } from "../../../constants/enums";

export interface IAccountingTaxInfoState {
  data: ITaxInfo;
  loading: LoadState;
  error: string | null;
}

export interface ITaxInfo {
  tax_id: string | null;
  default_tax_region: string | null;
  pst_or_gst_or_vat_number: string | null;
  bahamas_premium_tax: string | null;
  status: string | null;
}

export const initialAccountingTaxInfoState: IAccountingTaxInfoState = {
  data: {
    tax_id: null,
    default_tax_region: null,
    pst_or_gst_or_vat_number: null,
    bahamas_premium_tax: null,
    status: "Active",
  },
  loading: LoadState.NotLoaded,
  error: null,
};
