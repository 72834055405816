import React from "react";
import { Helmet } from "react-helmet";
import { useAppConfig } from "../../security/ApplicationProvider/ApplicationProvider";

export const AppHeader: React.FC = () => {
  const { favIcon, companyName } = useAppConfig();

  return (
    <div>
      <Helmet>
        <link rel="icon" href={favIcon} />
        <title>{companyName}</title>
      </Helmet>
    </div>
  );
};
