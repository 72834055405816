import moment, { Moment } from "moment";
import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface ICommAutomobileDriverState {
  driverInformation: {
    data: ICommAutomobileDriverInformation;
    loading: LoadState;
    driversList: ICommAutomobileDriverInformation[];
    driversListLoading: LoadState;
    error: string | null;
  };
  driverClaims: {
    data: ICommAutomobileDriverClaims;
    loading: LoadState;
    driverClaimsList: ICommAutomobileDriverClaims[];
    driverClaimsListLoading: LoadState;
    error: string | null;
  };
  driverConviction: {
    data: ICommAutomobileDriverConviction;
    loading: LoadState;
    driverConvictionList: ICommAutomobileDriverConviction[];
    driverConvisctionListLoading: LoadState;
    error: string | null;
  };
  driverActivity: {
    data: ICommAutomobileDriverActivity;
    loading: LoadState;
    driverActivityList: ICommAutomobileDriverActivity[];
    driverActivityListLoading: LoadState;
    error: string | null;
  };
}

export interface ICommDriverLicenseHistory {
  key: string;
  srNumber: number;
  name: string;
  dateOfFirstLicensed: string;
  yearLicensed: string;
}

export interface ICommAutomobileDriverInformation {
  customer_policy_id: number | null;
  driver_number: string | null;
  driver_name: string | null;
  date_of_birth: string | null;
  age: string | null;
  gender: string | null;
  marital_status: string | null;
  driver_phone_number: string | null;
  license_number: string | null;
  expiry_date_of_license: string | null;
  province: string | null;
  license_status: string | null;
  mvr_status: string | null;
  last_mvr_date: string | null;
  license_history: ICommDriverLicenseHistory[];
  driver_training_ceriticate: string | null;
  relationship_to_applicant: string | null;
  continuously_insured_since: string | null;
  hire_date: string | null;
  termination_date: string | null;
  insured_as_principal_operator_since: string | null;
  driver_occupation_code: string | null;
  number: string | null;
  class: string | null;
  date_first_licensed: string | null;
  years_licensed: string | null;
  heart_disorder: string | null;
  preffered_driver_status: string | null;
  insurance_cancelled_or_declined_or_refused: string | null;
  non_licensed_driver: string | null;
  has_the_driver_license_been_suspended_within_the_last_six_years:
    | string
    | null;
  number_of_years_credit_for_licensed_outside_of_canada: string | null;
  student_grades_code: string | null;
  does_good_student_credit_apply: string | null;
  retiree_discount: string | null;
  status: string;

  driver_training_certificate_date: string | null;
  driver_training_certificate_training_code: string | null;
  suspension_term_from: string | null;
  suspension_term_to: string | null;
  suspension_reason: string | null;
  serious_conviction_six_years_or_minor_conviction_three_years: string | null;
  losses_claims_by_applicant_past_five_years: string | null;
  driver_or_non_driver_drives_a_listed_vehicle: string | null;
  driver_or_non_driver_drives_a_listed_vehicle_y_n: string | null;
  driver_restricted_coverages: string | null;
  restriction_code_one: string | null;
  restriction_code_two: string | null;
  country_code: string | null;
  non_smoker: string | null;
  driver_subject_to_dizziness_or_fainting: string | null;
  recent_date_coverage_ceased: string | null;
  months_without_coverage: string | null;
  reason_coverage_ceased_code: string | null;
  reside_with_parents: string | null;
}

export interface ICommAutomobileDriverClaims {
  customer_policy_id: number;
  incremental_number: number | null;
  claim_number: string | null;
  producer: string | null;
  branch: string | null;
  driver_number: string | null;
  vehicle_number: string | null;
  date_of_loss: string | null;
  time_of_loss: string;
  value_of_loss: string | null;
  claim_type: string | null;
  amount_paid: string | null;
  insurer: string | null;
  claim_details: string | null;
  at_fault: string | null;
  forgiven: string | null;
  file_upload: string | null;
  ours: string | null;
  witness: string | null;
  witness_phone: string | null;
  witness_name: string | null;
  loss_reported_to_police: string | null;
  report_number: string | null;
  driver_name: string | null;
  license_number: string | null;
  who_was_this_driver: string | null;
  year: string | null;
  make: string | null;
  model: string | null;
  vin: string | null;
  plate_number: string | null;
  vehicle_drivable: string | null;
  where_we_can_see_your_vehicle: string | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  country: string | null;
  postal_code: string | null;
  any_third_part_involved_in_this_accident: string | null;
  third_party_driver_name: string | null;
  third_party_driver_license_number: string | null;
  third_party_vehicle_year: string | null;
  third_party_vehicle_make: string | null;
  third_party_vehicle_model: string | null;
  third_party_vehicle_vin: string | null;
  third_party_vehicle_plate_number: string | null;
  third_party_insurance_company: string | null;
  third_party_policy_number: string | null;
  Any_injuries: string | null;
  provide_details: string | null;
  contact_name: string | null;
  contact_phone: string | null;
  contact_phone_ext: string | null;
  contact_mobile: string | null;
  contact_email: string | null;
  preferred_contact_method: string | null;
  status: "ACTIVE";
}

export interface ICommAutomobileDriverConviction {
  customer_policy_id: number;
  incremental_number: number | null;
  customer_id: number | string | null;
  customer_name: string | null;
  policy_number: string | null;
  driver_number: string | null;
  date_of_conviction: string | null;
  conviction_code: string | null;
  no_of_kms_over: string | null;
  conviction_description: string | null;
  suspended: string | null;
  suspension_date: string | null;
  suspension_reason: string | null;
  reinstatement_date: string | null;
  suspended_no_of_months: string | null;
  status: string;
}

export interface ICommAutomobileDriverActivity {
  customer_policy_id: number | null;
  customer_name: string | null;
  customer_code: string | null;
  driver_name: string | null;
  license_number: string | null;
  business_reason: string | "Verify Driver Record";
  date_and_time: string | null;
  days_left: string | null;
  transaction_type: string | null;
  report_requested: string | null;
  request_status: string | null;
  insurer_code: string | null;
  intact_branch: string | null;
  number_of_disclosed_convictions: string | null;
  policy_number: string | null;
  insurer_code_written: string | null;
  policy_status_cancelled: string | null;
  requested_by: string | null;
  requested_by_code: string | null;
  assigned_to: string | null;
  branch: string | null;
  producer: string | null;
  producer_one_code: string | null;
  csr: string | null;
  csr_code: string | null;
  notes: string | null;
  file_type: string | null;
  file_type_two: string | null;
  file_upload: string | null;
  file_upload_two: string | null;
  date_wriitten: string | null;
  date_destroyed: string | null;
  date_voided: string | null;
  date_cancelled: string | null;
  status: "ACTIVE";
  incremental_number: number | null;
  years_at_this_address: string | null;
  current_address_type: string | null;
  current_address: string | null;
  current_address_unit_or_suite: string | null;
  current_address_city: string | null;
  current_address_state_or_province: string | null;
  current_address_postal_code: string | null;
  current_address_country: string | null;
  new_address_type: string | null;
  new_address: string | null;
  new_address_unit_or_suite: string | null;
  new_address_city: string | null;
  new_address_state_or_province: string | null;
  new_address_postal_code: string | null;
  new_address_country: string | null;
}

export const initialCommAutomoblieDriverInformation: ICommAutomobileDriverState =
  {
    driverInformation: {
      data: {
        customer_policy_id: 0,
        driver_number: null,
        driver_name: null,
        date_of_birth: null,
        age: null,
        gender: null,
        marital_status: null,
        driver_phone_number: null,
        license_number: null,
        expiry_date_of_license: null,
        province: "Ontario",
        license_status: "In-force",
        mvr_status: "Ordered",
        last_mvr_date: null,
        license_history: [
          {
            key: getUniqueId(),
            srNumber: 1,
            name: "G1",
            dateOfFirstLicensed: "",
            yearLicensed: "",
          },
          {
            key: getUniqueId(),
            srNumber: 2,
            name: "G2",
            dateOfFirstLicensed: "",
            yearLicensed: "",
          },
          {
            key: getUniqueId(),
            srNumber: 3,
            name: "G",
            dateOfFirstLicensed: "G",
            yearLicensed: "",
          },
          {
            key: getUniqueId(),
            srNumber: 4,
            name: "B",
            dateOfFirstLicensed: "",
            yearLicensed: "",
          },
          {
            key: getUniqueId(),
            srNumber: 5,
            name: "D",
            dateOfFirstLicensed: "",
            yearLicensed: "",
          },
          {
            key: getUniqueId(),
            srNumber: 6,
            name: "AZ",
            dateOfFirstLicensed: "",
            yearLicensed: "",
          },
        ],
        driver_training_ceriticate: null,
        relationship_to_applicant: "Insured",
        continuously_insured_since: null,
        hire_date: null,
        termination_date: null,
        insured_as_principal_operator_since: null,
        driver_occupation_code: null,
        number: null,
        class: null,
        date_first_licensed: null,
        years_licensed: null,
        heart_disorder: null,
        preffered_driver_status: null,
        insurance_cancelled_or_declined_or_refused: null,
        non_licensed_driver: null,
        has_the_driver_license_been_suspended_within_the_last_six_years: null,
        number_of_years_credit_for_licensed_outside_of_canada: null,
        student_grades_code: null,
        does_good_student_credit_apply: null,
        retiree_discount: null,
        status: "ACTIVE",
        driver_training_certificate_date: null,
        driver_training_certificate_training_code: null,
        suspension_term_from: null,
        suspension_term_to: null,
        suspension_reason: null,
        serious_conviction_six_years_or_minor_conviction_three_years: null,
        losses_claims_by_applicant_past_five_years: null,
        driver_or_non_driver_drives_a_listed_vehicle: null,
        driver_or_non_driver_drives_a_listed_vehicle_y_n: null,
        driver_restricted_coverages: null,
        restriction_code_one: null,
        restriction_code_two: null,
        country_code: null,
        non_smoker: null,
        driver_subject_to_dizziness_or_fainting: null,
        recent_date_coverage_ceased: null,
        months_without_coverage: null,
        reason_coverage_ceased_code: null,
        reside_with_parents: null,
      },
      loading: LoadState.NotLoaded,
      driversList: [],
      driversListLoading: LoadState.NotLoaded,
      error: null,
    },
    driverClaims: {
      data: {
        incremental_number: null,
        customer_policy_id: 0,
        claim_number: null,
        producer: null,
        branch: null,
        driver_number: null,
        vehicle_number: null,
        date_of_loss: null,
        time_of_loss: moment().format(),
        value_of_loss: null,
        claim_type: null,
        amount_paid: null,
        insurer: null,
        claim_details: null,
        at_fault: null,
        forgiven: null,
        file_upload: null,
        witness: null,
        witness_phone: null,
        witness_name: null,
        loss_reported_to_police: null,
        report_number: null,
        ours: null,
        driver_name: null,
        license_number: null,
        who_was_this_driver: null,
        year: null,
        make: null,
        model: null,
        vin: null,
        plate_number: null,
        vehicle_drivable: null,
        where_we_can_see_your_vehicle: null,
        address: null,
        unit_or_suite: null,
        city: null,
        province_or_state: null,
        country: null,
        postal_code: null,
        any_third_part_involved_in_this_accident: null,
        third_party_driver_name: null,
        third_party_driver_license_number: null,
        third_party_vehicle_year: null,
        third_party_vehicle_make: null,
        third_party_vehicle_model: null,
        third_party_vehicle_vin: null,
        third_party_vehicle_plate_number: null,
        third_party_insurance_company: null,
        third_party_policy_number: null,
        Any_injuries: null,
        provide_details: null,
        contact_name: null,
        contact_phone: null,
        contact_phone_ext: null,
        contact_mobile: null,
        contact_email: null,
        preferred_contact_method: null,
        status: "ACTIVE",
      },
      loading: LoadState.NotLoaded,
      driverClaimsList: [],
      driverClaimsListLoading: LoadState.NotLoaded,
      error: null,
    },
    driverConviction: {
      data: {
        customer_policy_id: 0,
        incremental_number: null,
        customer_id: null,
        customer_name: null,
        policy_number: null,
        driver_number: null,
        date_of_conviction: null,
        conviction_code: null,
        no_of_kms_over: null,
        conviction_description: null,
        suspended: null,
        suspension_date: null,
        suspension_reason: null,
        reinstatement_date: null,
        suspended_no_of_months: null,
        status: "ACTIVE",
      },
      loading: LoadState.NotLoaded,
      driverConvictionList: [],
      driverConvisctionListLoading: LoadState.NotLoaded,
      error: null,
    },
    driverActivity: {
      data: {
        customer_policy_id: 0,
        customer_name: null,
        customer_code: null,
        driver_name: null,
        license_number: null,
        business_reason: "Verify Driver Record",
        date_and_time: moment().format("YYYY-MM-DD HH:mm:ss"),
        days_left: null,
        transaction_type: null,
        report_requested: null,
        request_status: "",
        insurer_code: null,
        intact_branch: null,
        insurer_code_written: null,
        number_of_disclosed_convictions: null,
        policy_number: null,
        policy_status_cancelled: null,
        requested_by: null,
        requested_by_code: null,
        assigned_to: null,
        branch: null,
        producer: null,
        producer_one_code: null,
        csr: null,
        csr_code: null,
        notes: null,
        file_type: null,
        file_type_two: null,
        file_upload: null,
        file_upload_two: null,
        date_wriitten: moment().format("YYYY-MM-DD"),
        date_destroyed: null,
        date_voided: null,
        date_cancelled: null,
        status: "ACTIVE",
        incremental_number: null,
        years_at_this_address: null,
        current_address_type: null,
        current_address: null,
        current_address_unit_or_suite: null,
        current_address_city: null,
        current_address_state_or_province: null,
        current_address_postal_code: null,
        current_address_country: null,
        new_address_type: null,
        new_address: null,
        new_address_unit_or_suite: null,
        new_address_city: null,
        new_address_state_or_province: null,
        new_address_postal_code: null,
        new_address_country: null,
      },
      loading: LoadState.NotLoaded,
      driverActivityList: [],
      driverActivityListLoading: LoadState.NotLoaded,
      error: null,
    },
  };
