import { Avatar, Button, MenuItem, } from "@mui/material";
import React from "react";
import { StandadCard } from "../../../../../../components/Cards";
import { DataTable } from "../../../../../../components/Table/DataTable";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { useDispatchWrapper } from "../../../../../../hooks";
import {
  ILegal,
} from "../../../../../../redux/legals/legals.types";

import { AddOutlined } from "@mui/icons-material";
import { RoleBasedCSVExportPlugin } from "../../../../../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { DateFilterDropdown } from "../../../../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { useDateFilter } from "../../../../../../components/Table/hooks/useDateFilter";
import {
  RefreshPlugin,
  SearchPlugin,
} from "../../../../../../components/Table/plugins";
import { CustomSelect } from "../../../../../../components/formsComponents";
import { CustomDatePicker } from "../../../../../../components/formsComponents/CustomDatePicker";
import { LoadState, MODULE_IDS } from "../../../../../../constants/enums";
import { PageContainer } from "../../../../../../components/container/PageContainer";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import {
  clearCSIOState,
  fetchFromCSIOListAsync,
} from "../../../../../../redux/csio/csioActions";
import { CustomLink } from "../../../../../../components/CustomLink/CustomLink";
import { ICSIORaw } from "../../../../../../redux/csio/csio.types";
import { CSIOMapRightPanel } from "../../../../../csio/CSIOMapRightPanel";
import { fetchCSIOHabitationalListAsync } from "../../../../../../redux/habitationalPolicy/habitationalCsioApplication/habitationalCsioApplicationActions";
import { clearCommAutoCSIOApplicationState, fetchCSIOCommAutoListAsync } from "../../../../../../redux/commAutoPolicy/commAutoCsioApplication/commAutoCsioApplicationActions";

export const CommAutomobileCsioApplicationList = () => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerpolicyId = data1[1];

  console.log("cpI", data1)

  const { list, loading, totalRecords } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.csioapplication
  );
  console.log("totalRecords", totalRecords)
  const dispatch = useDispatchWrapper();
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 100,
  });
  const [status, setStatus] = React.useState<ILegal["status"] | "-1">("-1");
  const [searchType, setSearchType] = React.useState<string[]>([
    "message_guid",
  ]);
  const [search, setSearch] = React.useState<string>("");
  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "allTimes" });

  const [openCSIO, setOpenCSIO] = React.useState<ICSIORaw | null>(null);

  const navigate = useNavigate();

  const csioTableProps: IDataTableProps = {
    isPagination: true,
    totalRecords: totalRecords,
    isDataLoading: loading === LoadState.InProgress,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    selectionMode: "multiple",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={status}
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
              >
                <MenuItem value="-1">All Status</MenuItem>
                <MenuItem value="ACTIVE">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </CustomSelect>
            ),
          },
          {
            key: "byDate",
            onRender: () => (
              <DateFilterDropdown
                value={type}
                options={dateDropdownList}
                onChange={handleDateSelectChange}
              />
            ),
          },
          {
            key: "fromDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.fromDate}
                  onChange={(newValue) => {
                    setDate({ ...date, fromDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "toDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.toDate}
                  onChange={(newValue) => {
                    setDate({ ...date, toDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "csvExport",
            onRender: (columns, items) => (
              <RoleBasedCSVExportPlugin
                columns={columns}
                items={items}
                moduleId={MODULE_IDS.LEGAL}
                filePrefixName="Report"
              />
            ),
          },
          {
            key: "refresh",
            onRender: () => <RefreshPlugin onClick={fetchList} />,
          },
        ],
      },
      rightItems: {
        plugins: {},
        customPlugins: [
          {
            key: "search",
            onRender: () => {
              return (
                <SearchPlugin
                  selectedDropdownValue={searchType}
                  dropdownOptions={[
                    { label: "Message Guid", value: "message_guid" },
                  ]}
                  onDropdownChange={(value) => setSearchType(value)}
                  onChange={(newValue) => {
                    setSearch(newValue);
                  }}
                />
              );
            },
          },
        ],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#000",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        isFirstColumnSticky: true,
        onRowCellRender: (value, row) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(`/manage-csio/${row.message_guid}`);
              }}
            />
          );
        },
      },
      {
        key: "csio",
        headerName: "",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 20,
        enableSorting: false,

        onRowCellRender: (values, row) => {
          return (
            <Button
              variant="text"
              onClick={() =>
                navigate(
                  `/comm-automobile-policy/${customerId}/application-csio-tab/${customerpolicyId}/application/${row.message_guid}`
                )
              }
            >
              View XML & Map
            </Button>
          );
        },
      },
      {
        key: "message_guid",
        headerName: "Message ID",
        fieldName: "message_guid",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        enableSorting: true,
        onRowCellRender: (value, row) => {
          return (
            <CustomLink
              to={"/manage-csio/" + row.message_guid}
              label={row.message_guid}
            />
          );
        },
      },
      {
        key: "MessageType",
        headerName: "Message Type",
        fieldName: "message_type",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 15,
      },
      {
        key: "FromEmailAddress",
        headerName: "From Email Address",
        fieldName: "from_email_address",
        enableSorting: false,
        renderType: RenderType.TEXT,
        exportCellWidth: 25,
      },
      // {
      //   key: "MessageSize",
      //   headerName: "MessageSize",
      //   fieldName: "count",
      //   renderType: RenderType.CHIP_WARNING,
      //   exportCellWidth: 20,
      //   enableSorting: false,
      // },
      {
        key: "DateTime",
        headerName: "Date and Time",
        fieldName: "create_ts",
        renderType: RenderType.DATE_TIME,
        exportCellWidth: 20,
        enableSorting: false,
      },
    ],
    items: list,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
  };

  const fetchList = () => {
    dispatch(
      fetchCSIOCommAutoListAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        status,
        search,
        searchType,
        date
      )
    );
  };
  React.useEffect(() => {
    fetchList();
  }, [status, date, tablePagination, search]);

  React.useEffect(() => {
    return () => {
      dispatch(clearCommAutoCSIOApplicationState());
    };
  }, []);

  return (
    <>
      <PageContainer title="View Csio" description="this is innerpage">
        <StandadCard
          heading="CSIO"
        >
          <DataTable {...csioTableProps} />
        </StandadCard>
      </PageContainer>
      {openCSIO && (
        <CSIOMapRightPanel
          open={true}
          messageGUID={openCSIO.message_guid}
          onClose={() => setOpenCSIO(null)}
        />
      )}
    </>
  );
};
