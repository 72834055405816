import { LoadState } from "../../../constants/enums";

export interface IAccountingEftState {
  list: IEft[];
  listLoading: LoadState;
  data: IEft;
  loading: LoadState;
  error: string | null;
}

export interface IEft {
  eft: {
    payment_method: string | null;
    bank: string | null;
    amount: string | null;
    reference: string | null;
    created_by: string | null;
  };
  status: string;
}

export const initialAccountingEftState: IAccountingEftState = {
  list: [],
  listLoading: LoadState.NotLoaded,
  data: {
    eft: {
      payment_method: null,
      bank: null,
      amount: null,
      reference: null,
      created_by: null,
    },
    status: "Active",
  },
  loading: LoadState.NotLoaded,
  error: null,
};
