import { ThunkAction } from "redux-thunk";
import {
  IUtilityAssignPolicy,
  IUtilityBranchCode,
  IUtilityCSRCode,
  IUtilityCustomerCode,
  IUtilityProducerCode,
  IUtilityResponse,
} from "./utility.types";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { api } from "../../api/api";
import { action } from "typesafe-actions";
import { IDate } from "../../components/Table/hooks/useDateFilter";

export const FETCH_UTILITY_LIST_PROGRESS = "FETCH_UTILITY_LIST_PROGRESS";
export const FETCH_UTILITY_LIST_SUCCESS = "FETCH_UTILITY_LIST_SUCCESS";
export const FETCH_UTILITY_LIST_FAILED = "FETCH_UTILITY_LIST_FAILED";

export const fetchUtilityListProgress = () =>
  action(FETCH_UTILITY_LIST_PROGRESS);
export const fetchUtilityListSuccess = (
  data: IUtilityResponse[],
  totalRecords: number
) =>
  action(FETCH_UTILITY_LIST_SUCCESS, {
    data,
    totalRecords,
  });
export const fetchUtilityListFailed = () => action(FETCH_UTILITY_LIST_FAILED);

export const fetchUtilityListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    date: IDate,
    searchValue: string,
    searchType: string[],
    searchFor: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchUtilityListProgress());

      let url = `/general/get-bulk-update-utility?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&search_for=${searchFor}&from_date=${date.fromDate}&to_date=${date.toDate}`;
      if (searchType.length > 0 && searchValue) {
        url = `${url}&column=${[searchType]}&value=${searchValue}`;
      }
      const res = await api.get(url);
      const data: IUtilityResponse[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchUtilityListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchUtilityListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertUtilityBranchCodeAsync =
  (
    data: IUtilityBranchCode,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      await api.post(
        "/general/edit-utility-producer-all-customer-branch-change",
        {
          ...data,
        }
      );

      dispatch(
        showMessage({
          type: "success",
          message: "Utility run successfully",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const upsertUtilityCSRCodeAsync =
  (
    data: IUtilityCSRCode,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      await api.post("/general/edit-utility-all-csr-change", {
        ...data,
      });

      dispatch(
        showMessage({
          type: "success",
          message: "Utility run successfully",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const upsertUtilityCustomerCodeAsync =
  (
    data: IUtilityCustomerCode,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      await api.post("/utility/merge-customer", {
        ...data,
      });

      dispatch(
        showMessage({
          type: "success",
          message: "Customer merged successfully",
          displayAs: "dialog",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const upsertUtilityPolicyMergeSameLineBusinessAsync =
  (
    data: IUtilityCustomerCode,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      await api.post("/utility/same-line-of-business-policy-merge", {
        ...data,
      });

      dispatch(
        showMessage({
          type: "success",
          message: "Customer merged successfully",
          displayAs: "dialog",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

  export const upsertUtilityAssignPolicyAsync =
  (
    data: IUtilityAssignPolicy,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      await api.post("/utility/assign-policy-to-other-customer", {
        ...data,
      });

      dispatch(
        showMessage({
          type: "success",
          message: "Policy assigned successfully",
          displayAs: "dialog",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const upsertUtilityProducerCodeAsync =
  (
    data: IUtilityProducerCode,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      await api.post("/general/edit-utility-all-producer-change", {
        ...data,
      });

      dispatch(
        showMessage({
          type: "success",
          message: "Utility run successfully",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_UTILITY_STATE = "CLEAR_UTILITY_STATE";
export const clearUtilityState = () => action(CLEAR_UTILITY_STATE);
