/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { StandadCard } from "../../../../../../../components/Cards";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";

import produce from "immer";
import {
  addAutomobileScheduleAAsync,
  clearAutomobileScheduleState,
  fetchAutomobileScheduleAAsync,
} from "../../../../../../../redux/automobileSchedule/automobileScheduleActions";
import { IAutomobileScheduleCoverage } from "../../../../../../../redux/automobileSchedule/automobileSchedule.types";
import { CurrencyTextField } from "../../../../../../../components/CurrencyTextField/CurrencyTextField";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { getUniqueId, renderDropdownValue } from "../../../../../../../helpers";
import { fetchAutomobileVehicleListAsync } from "../../../../../../../redux/automobileVehicle/automobileVehicleActions";
import { ExtendableSelect } from "../../../../../../../components/formsComponents/ExtendableSelect";
import { AutoComplete } from "../../../../../../../components/AutoCompleteSearches/AutoComplete";
import { csioOtherCoverages } from "../../../../../../../constants/constants";

export const AutomobileScheduleA = () => {
  const { customerId, customerPolicyId, incrementalNumber, vehicleNumber } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
    incrementalNumber?: number;
    vehicleNumber?: number;
  };

  const {data} = useSelector((storeState: IStoreState)=> storeState.automobile.schedule.scheduleA);
  const vehiclesList = useSelector(
    (storeState: IStoreState) =>
      storeState.automobile.vehicle.VehicleInformation.vehiclesList
  );
  const dispatch = useDispatchWrapper();
  const [saveLoading, setSaveLoading] = React.useState(false);
  const navigate = useNavigate();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: data,
    validate: (values) => {},
    onSubmit: (values) => {
      if (customerPolicyId && customerId) {
        setSaveLoading(true);
        dispatch(
          addAutomobileScheduleAAsync(
            {
              ...values,
              customer_policy_id: customerPolicyId,
            },
            (isSuccess) => {
              if (isSuccess) {
                navigate(
                  `/automobile-policy/${customerId}/vehicle-coverage-schedule-tab/${customerPolicyId}?tab=0`
                );
                window.scrollTo(0, 0);
              }
              setSaveLoading(false);
            }
          )
        );
      }
    },
  });

  const handleTextChange = (index: number, key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValues = produce(values, draftValues=>{
      draftValues.coverage_details[index][key as "key"] = e.target.value;
    });
    setValues(newValues);
  }

  const handleSelectChange = (index: number, key: string) => (selectedOption: any) => {
    const newValues = produce(values, draftValues=>{
      draftValues.coverage_details[index][key as "key"] = selectedOption ? selectedOption : "";
    });
    setValues(newValues);
  }

  

  React.useEffect(() => {
    if (customerPolicyId && incrementalNumber && vehicleNumber) {
      dispatch(fetchAutomobileScheduleAAsync(customerPolicyId, incrementalNumber, vehicleNumber  ));
    }
  }, []);

  React.useEffect(() => {
    setValues({ ...data });
  }, [data]);



  React.useEffect(() => {
    return () => {
      dispatch(clearAutomobileScheduleState());
    };
  }, []);

  const finalList = React.useMemo(() => {
    return vehiclesList.map((item) => {
      return {
        label: `${item.incremental_number || "--"} (Year: ${
          item.year || "N/A"
        }, Make: ${item.make || "N/A"}, Model: ${item.model || "N/A"} 
        )`,
        value: item.incremental_number || "",
      };
    });
  }, [vehiclesList]);

  return (
    <PageLoader loading={false}>
      <StandadCard heading="Vehicle Coverage Summary">
        <form onSubmit={handleSubmit}>
          <Grid container sx={{ mt: 2 }}>
          <Grid item xs={6} md={6}>
          <CustomFormLabel fontWeight={600} variant="h4" textAlign={"center"}>
            Vehicle Number
          </CustomFormLabel>
          <ControlledCustomSelect
            variant="outlined"
            size="small"
            type="string"
            name="vehicle_number"
            value={values.vehicle_number}
            onChange={handleChange}
            displayEmpty
            fullWidth
            placeholder="Select one"
            options={finalList}
          ></ControlledCustomSelect>
        </Grid>
            <Grid item xs={12} md={12}>
              <TableContainer>
                <Table
                  aria-label="collapsible table"
                  sx={{
                    whiteSpace: {
                      xs: "nowrap",
                      sm: "nowrap",
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        <Typography fontWeight={600} variant="h4">
                          Section
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography fontWeight={600} variant="h4">
                          Automobile
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography fontWeight={600} variant="h4">
                          Occasional Premium
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.coverage_details.map((item, index) => {
                          return (
                            <TableRow
                              key={item.key}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell sx={{ minWidth: 600 }}>
                              {!item.isTypeOther ? (
                                  <Typography
                                    sx={{
                                      wordBreak: "break-word",
                                      whiteSpace: "normal",
                                    }}
                                  >
                                    {item.label}
                                  </Typography>
                                ) : (
                                  <AutoComplete
                                    value={item.label}
                                    placeholder="Select one"
                                    options={csioOtherCoverages}
                                    onChange={handleSelectChange(index,"label")}
                                  />
                                )}
                              </TableCell>
                              <TableCell>
                                <Stack direction={"row"} spacing={1}>
                                  <CustomTextField
                                    name="limit"
                                    value={item.limit}
                                    onChange={handleTextChange(index,"limit")}
                                    variant="outlined"
                                    size="small"
                                    placeholder="Limit"
                                  />
                                  <CustomTextField
                                    name="deductible"
                                    value={item.deductible}
                                    onChange={handleTextChange(index,"deductible")}
                                    variant="outlined"
                                    size="small"
                                    placeholder="Deductible"
                                  />
                                  <CustomTextField
                                    name="premimum"
                                    value={item.premimum}
                                    onChange={handleTextChange(index,"premimum")}
                                    variant="outlined"
                                    size="small"
                                    placeholder="Premium"
                                  />
                                </Stack>
                              </TableCell>
                              <TableCell>
                                <CustomTextField
                                  name="description"
                                  value={item.description}
                                  onChange={handleTextChange(index,"description")}
                                  variant="outlined"
                                  size="small"
                                  placeholder="Description"
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                      }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <Box sx={{ mt: 4 }}>
            <RoleBasedCustomButton
              moduleId={MODULE_IDS.AUTOMOBILE}
              sx={{ width: "15%" }}
              disabled={saveLoading}
              variant="contained"
              type="submit"
            >
              Save
            </RoleBasedCustomButton>
          </Box>
        </form>
      </StandadCard>
    </PageLoader>
  );
};
