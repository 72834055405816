import produce from "immer";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { initialTaskurgentTransactionsState } from "./taskUrgentTransactions.types";
import { TaskUrgentTransactionsActions } from ".";
import { CLEAR_TASK_URGENT_TRANSACTIONS, FETCH_TASK_POLICY_CANCELLATION_FAILED, FETCH_TASK_POLICY_CANCELLATION_PROGRESS, FETCH_TASK_POLICY_CANCELLATION_SUCCESS, FETCH_TASK_POLICY_LAO_FAILED, FETCH_TASK_POLICY_LAO_PROGRESS, FETCH_TASK_POLICY_LAO_SUCCESS, FETCH_TASK_POLICY_REINSTMENT_FAILED, FETCH_TASK_POLICY_REINSTMENT_PROGRESS, FETCH_TASK_POLICY_REINSTMENT_SUCCESS } from "./taskUrgentTransactionsActions";

export const taskUrgentTransactionsReducer = (
  state: IStoreState["tasks"]["urgentTransactions"] = initialTaskurgentTransactionsState,
  action: TaskUrgentTransactionsActions
) => {
  switch (action.type) {
    case FETCH_TASK_POLICY_CANCELLATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.policyCancellation.loading = LoadState.InProgress;
        draftState.policyCancellation = initialTaskurgentTransactionsState["policyCancellation"];
      });
      return newState;
    }
    case FETCH_TASK_POLICY_CANCELLATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.policyCancellation.loading = LoadState.Loaded;
        draftState.policyCancellation.data = data;
      });
      return newState;
    }
    case FETCH_TASK_POLICY_CANCELLATION_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.policyCancellation.loading = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_TASK_POLICY_REINSTMENT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.policyReinstment.loading = LoadState.InProgress;
        draftState.policyReinstment = initialTaskurgentTransactionsState["policyReinstment"];
      });
      return newState;
    }
    case FETCH_TASK_POLICY_REINSTMENT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.policyReinstment.loading = LoadState.Loaded;
        draftState.policyReinstment.data = data;
      });
      return newState;
    }
    case FETCH_TASK_POLICY_REINSTMENT_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.policyReinstment.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_TASK_POLICY_LAO_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loa.loading = LoadState.InProgress;
        draftState.loa.data = initialTaskurgentTransactionsState["loa"].data;
      });
      return newState;
    }
    case FETCH_TASK_POLICY_LAO_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loa.loading = LoadState.Loaded;
        draftState.loa.data = data;
      });
      return newState;
    }
    case FETCH_TASK_POLICY_LAO_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loa.loading = LoadState.Failed;
      });
      return newState;
    }


    case CLEAR_TASK_URGENT_TRANSACTIONS: {
      const newState = produce(state, (drafteState) =>{
        drafteState.policyCancellation.data = initialTaskurgentTransactionsState.policyCancellation.data;
        drafteState.policyCancellation.loading = initialTaskurgentTransactionsState.policyCancellation.loading;
      })
      return newState;
    }

    default:
      return state;
  }
};
