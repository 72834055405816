import { IStoreState } from "../../initialStoreState";
import {
  CLEAR_HABITATIONAL_RISK_LOCATION,
  CLEAR_HABITATIONAL_RISK_LOCATION_STATE,
  FETCH_HABITATIONAL_RISK_LOCATION_FAILED,
  FETCH_HABITATIONAL_RISK_LOCATION_LIST_FAILED,
  FETCH_HABITATIONAL_RISK_LOCATION_LIST_PROGRESS,
  FETCH_HABITATIONAL_RISK_LOCATION_LIST_SUCCESS,
  FETCH_HABITATIONAL_RISK_LOCATION_PROGRESS,
  FETCH_HABITATIONAL_RISK_LOCATION_SUCCESS,
} from "./habitationalRiskLocationActions";
import produce from "immer";
import { LoadState } from "../../../constants/enums";
import { defaultHabitationalRiskLocationState } from "./habitationalRiskLocation.types";
import { HabitationalRiskLocationActions } from ".";

export const HabitationalRiskLocationReducer = (
  state: IStoreState["habitational"]["riskLocation"] = defaultHabitationalRiskLocationState,
  action: HabitationalRiskLocationActions
) => {
  switch (action.type) {
    case FETCH_HABITATIONAL_RISK_LOCATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_RISK_LOCATION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_RISK_LOCATION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.data = [];
      });
      return newState;
    }

    case FETCH_HABITATIONAL_RISK_LOCATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.risklocationLoading = LoadState.InProgress;
        draftState.risklocation = defaultHabitationalRiskLocationState['risklocation'];
        draftState.error = null;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_RISK_LOCATION_SUCCESS: {
      const {data} = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.risklocationLoading = LoadState.Loaded;
        draftState.risklocation = data
      });
      return newState;
    }

    case FETCH_HABITATIONAL_RISK_LOCATION_FAILED: {
      const {errorMessage} = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.risklocationLoading = LoadState.Failed;
        draftState.risklocation = defaultHabitationalRiskLocationState['risklocation'];
        draftState.error = errorMessage;
      });
      return newState;
    }
    
  
    case CLEAR_HABITATIONAL_RISK_LOCATION: {
      const newState = produce(state, (draftState) => {
        draftState.risklocation =
          defaultHabitationalRiskLocationState["risklocation"];
      });
      return newState;
    }
    case CLEAR_HABITATIONAL_RISK_LOCATION_STATE: {
      return defaultHabitationalRiskLocationState;
    }
    default: {
      return state;
    }
  }
};
