import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialTaskInterestedPartyState } from "./interestedParty.types";
import { TaskInterestedPartyActions } from ".";
import { FETCH_ADD_INTERESTED_PARTY_FAILED, FETCH_ADD_INTERESTED_PARTY_PROGRESS, FETCH_ADD_INTERESTED_PARTY_SUCCESS, FETCH_CHANGE_INTERESTED_PARTY_FAILED, FETCH_CHANGE_INTERESTED_PARTY_PROGRESS, FETCH_CHANGE_INTERESTED_PARTY_SUCCESS, FETCH_DELETE_INTERESTED_PARTY_FAILED, FETCH_DELETE_INTERESTED_PARTY_PROGRESS, FETCH_DELETE_INTERESTED_PARTY_SUCCESS } from "./interestedPartyActions";

export const taskInterestedPartyReducer = (
    state: IStoreState["tasks"]["interestedParty"] = initialTaskInterestedPartyState,
    action: TaskInterestedPartyActions
  ) => {
    switch (action.type) {
        case FETCH_ADD_INTERESTED_PARTY_PROGRESS: {
            const newState = produce(state, draftState=>{
                draftState.addInterestedParty.loading = LoadState.InProgress;
                draftState.addInterestedParty = initialTaskInterestedPartyState['addInterestedParty'];
            })
            return newState;
        }
        case FETCH_ADD_INTERESTED_PARTY_SUCCESS: {
            const {data} = action.payload;
            const newState = produce(state, draftState=>{
                draftState.addInterestedParty.loading = LoadState.Loaded;
                draftState.addInterestedParty.data = data;
            })
            return newState;
        }
        case FETCH_ADD_INTERESTED_PARTY_FAILED: {
            const newState = produce(state, draftState=>{
                draftState.addInterestedParty.loading = LoadState.Failed;
            })
            return newState;
        }

        case FETCH_DELETE_INTERESTED_PARTY_PROGRESS: {
            const newState = produce(state, draftState=>{
                draftState.deleteInterestedParty.loading = LoadState.InProgress;
                draftState.deleteInterestedParty = initialTaskInterestedPartyState['deleteInterestedParty'];
            })
            return newState;
        }
        case FETCH_DELETE_INTERESTED_PARTY_SUCCESS: {
            const {data} = action.payload;
            const newState = produce(state, draftState=>{
                draftState.deleteInterestedParty.loading = LoadState.Loaded;
                draftState.deleteInterestedParty.data = data;
            })
            return newState;
        }
        case FETCH_DELETE_INTERESTED_PARTY_FAILED: {
            const newState = produce(state, draftState=>{
                draftState.deleteInterestedParty.loading = LoadState.Failed;
            })
            return newState;
        }

        case FETCH_CHANGE_INTERESTED_PARTY_PROGRESS: {
            const newState = produce(state, draftState=>{
                draftState.changeInterestedParty.loading = LoadState.InProgress;
                draftState.changeInterestedParty = initialTaskInterestedPartyState['changeInterestedParty'];
            })
            return newState;
        }
        case FETCH_CHANGE_INTERESTED_PARTY_SUCCESS: {
            const {data} = action.payload;
            const newState = produce(state, draftState=>{
                draftState.changeInterestedParty.loading = LoadState.Loaded;
                draftState.changeInterestedParty.data = data;
            })
            return newState;
        }
        case FETCH_CHANGE_INTERESTED_PARTY_FAILED: {
            const newState = produce(state, draftState=>{
                draftState.changeInterestedParty.loading = LoadState.Failed;
            })
            return newState;
        }
        
        default:
            return state;
    }
  }