import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import {
  CLEAR_ACCOUNTING_RECIEVABLE_TRUST,
  FETCH_ACCOUNTING_RECIEVABLE_TRUST_FAILURE,
  FETCH_ACCOUNTING_RECIEVABLE_TRUST_LIST_FAILURE,
  FETCH_ACCOUNTING_RECIEVABLE_TRUST_LIST_PROGRESS,
  FETCH_ACCOUNTING_RECIEVABLE_TRUST_LIST_SUCCESS,
  FETCH_ACCOUNTING_RECIEVABLE_TRUST_PROGRESS,
  FETCH_ACCOUNTING_RECIEVABLE_TRUST_SUCCESS,
} from "./RecievableTrustActions";
import { AccountingReceivableTrustActions } from ".";
import { initialAccountingRecievableTrustState } from "./RecievableTrust.types";

export const AccountingRecievableTrustReducer = (
  state: IStoreState["accounting"]["recievableTrust"] = initialAccountingRecievableTrustState,
  action: AccountingReceivableTrustActions
) => {
  switch (action.type) {
    case FETCH_ACCOUNTING_RECIEVABLE_TRUST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_RECIEVABLE_TRUST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_RECIEVABLE_TRUST_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_RECIEVABLE_TRUST_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_RECIEVABLE_TRUST_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Loaded;
        draftState.list = data;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_RECIEVABLE_TRUST_LIST_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Failed;
        draftState.listError = errorMessage;
      });
      return newState;
    }

    case CLEAR_ACCOUNTING_RECIEVABLE_TRUST: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.NotLoaded;
        draftState.data = initialAccountingRecievableTrustState.data;
        draftState.error = null;
      });
      return newState;
    }

    default:
      return state;
  }
};
