import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import {
  CLEAR_TRANSACTION_DESCRIPTION,
  FETCH_TRANSACTION_DESCRIPTION_FAILURE,
  FETCH_TRANSACTION_DESCRIPTION_LIST_FAILURE,
  FETCH_TRANSACTION_DESCRIPTION_LIST_PROGRESS,
  FETCH_TRANSACTION_DESCRIPTION_LIST_SUCCESS,
  FETCH_TRANSACTION_DESCRIPTION_PROGRESS,
  FETCH_TRANSACTION_DESCRIPTION_SUCCESS,
} from "./TransactionDescriptionAction";
import { AccountingTransactionDescriptionActions } from ".";
import { initialTransactionDescriptionState } from "./TransactionDescription.types";

export const AccountingTransactioDescriptionReducer = (
  state: IStoreState["accounting"]["transactionDescription"] = initialTransactionDescriptionState,
  action: AccountingTransactionDescriptionActions
) => {
  switch (action.type) {
    case FETCH_TRANSACTION_DESCRIPTION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_TRANSACTION_DESCRIPTION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_TRANSACTION_DESCRIPTION_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case FETCH_TRANSACTION_DESCRIPTION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }

    case FETCH_TRANSACTION_DESCRIPTION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
      });
      return newState;
    }

    case FETCH_TRANSACTION_DESCRIPTION_LIST_FAILURE: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_TRANSACTION_DESCRIPTION: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.NotLoaded;
        draftState.data = initialTransactionDescriptionState.data;
        draftState.error = null;
      });
      return newState;
    }
    default:
      return state;
  }
};
