import produce from "immer";
import { ReconciliationActions } from ".";
import { IStoreState } from "../initialStoreState";
import { initialReconciliationState } from "./reconciliation.types";
import { CLEAR_RECONCILIATION_STATE, FETCH_RECON_AGENCY_BILL_LIST_FAILED, FETCH_RECON_AGENCY_BILL_LIST_PROGRESS, FETCH_RECON_AGENCY_BILL_LIST_SUCCESS, FETCH_RECON_CUSTOMER_PAYABLE_LIST_FAILED, FETCH_RECON_CUSTOMER_PAYABLE_LIST_PROGRESS, FETCH_RECON_CUSTOMER_PAYABLE_LIST_SUCCESS, FETCH_RECON_DIRECT_BILL_LIST_FAILED, FETCH_RECON_DIRECT_BILL_LIST_PROGRESS, FETCH_RECON_DIRECT_BILL_LIST_SUCCESS } from "./reconciliationActions";
import { LoadState } from "../../constants/enums";



export const reconciliationReducer = (
    state: IStoreState["reconciliation"] = initialReconciliationState,
    action: ReconciliationActions
  ) => {
    switch (action.type) {
      case FETCH_RECON_AGENCY_BILL_LIST_PROGRESS: {
        const newState = produce(state, (draftState) => {
          draftState.agencyBill.loading = LoadState.InProgress;
          draftState.agencyBill.list = [];
          draftState.agencyBill.totalRecords = 0;
        });
        return newState;
      }
      case FETCH_RECON_AGENCY_BILL_LIST_SUCCESS: {
        const { data, totalRecords } = action.payload;
        const newState = produce(state, (draftState) => {
          draftState.agencyBill.loading = LoadState.Loaded;
          draftState.agencyBill.list = data;
          draftState.agencyBill.totalRecords = totalRecords;
        });
        return newState;
      }
      case FETCH_RECON_AGENCY_BILL_LIST_FAILED: {
        const newState = produce(state, (draftState) => {
          draftState.agencyBill.loading = LoadState.InProgress;
          draftState.agencyBill.list = [];
          draftState.agencyBill.totalRecords = 0;
        });
        return newState;
      }
      case FETCH_RECON_DIRECT_BILL_LIST_PROGRESS: {
        const newState = produce(state, (draftState) => {
          draftState.directBill.loading = LoadState.InProgress;
          draftState.directBill.list = [];
          draftState.directBill.totalRecords = 0;
        });
        return newState;
      }
      case FETCH_RECON_DIRECT_BILL_LIST_SUCCESS: {
        const { data, totalRecords } = action.payload;
        const newState = produce(state, (draftState) => {
          draftState.directBill.loading = LoadState.Loaded;
          draftState.directBill.list = data;
          draftState.directBill.totalRecords = totalRecords;
        });
        return newState;
      }
      case FETCH_RECON_DIRECT_BILL_LIST_FAILED: {
        const newState = produce(state, (draftState) => {
          draftState.directBill.loading = LoadState.InProgress;
          draftState.directBill.list = [];
          draftState.directBill.totalRecords = 0;
        });
        return newState;
      }
      case FETCH_RECON_CUSTOMER_PAYABLE_LIST_PROGRESS: {
        const newState = produce(state, (draftState) => {
          draftState.customerPayable.loading = LoadState.InProgress;
          draftState.customerPayable.list = [];
          draftState.customerPayable.totalRecords = 0;
        });
        return newState;
      }
      case FETCH_RECON_CUSTOMER_PAYABLE_LIST_SUCCESS: {
        const { data, totalRecords } = action.payload;
        const newState = produce(state, (draftState) => {
          draftState.customerPayable.loading = LoadState.Loaded;
          draftState.customerPayable.list = data;
          draftState.customerPayable.totalRecords = totalRecords;
        });
        return newState;
      }
      case FETCH_RECON_CUSTOMER_PAYABLE_LIST_FAILED: {
        const newState = produce(state, (draftState) => {
          draftState.customerPayable.loading = LoadState.InProgress;
          draftState.customerPayable.list = [];
          draftState.customerPayable.totalRecords = 0;
        });
        return newState;
      }
      case CLEAR_RECONCILIATION_STATE: {
        return initialReconciliationState;
      }
  
      default: {
        return state;
      }
    }
  };