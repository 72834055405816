import { action } from "typesafe-actions";
import { ITaskCalender } from "./tasksCalender.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";
import moment from "moment";

export const FETCH_TASKS_CALENDER_PROGRESS = "FETCH_TASKS_CALENDER_PROGRESS";
export const FETCH_TASKS_CALENDER_SUCCESS = "FETCH_TASKS_CALENDER_SUCCESS";
export const FETCH_TASKS_CALENDER_FAILED = "FETCH_TASKS_CALENDER_FAILED";

export const fetchTasksCalenderProgress = () =>
  action(FETCH_TASKS_CALENDER_PROGRESS);
export const fetchTasksCalenderSuccess = (data: ITaskCalender[]) =>
  action(FETCH_TASKS_CALENDER_SUCCESS, { data });
export const fetchTasksCalenderFailed = () =>
  action(FETCH_TASKS_CALENDER_FAILED);
export const fetchTaskCalenderAysnc =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTasksCalenderProgress());
      const res = await api.get("/tasks/get-calendar-data");
      let data: ITaskCalender[] = res.data.data;
      data = data.map((item) => {
        return {
          ...item,
          start: moment(item.start, "YYYY-M-DTHH:mm:ss").toDate(),
          end: moment(item.end, "YYYY-M-DTHH:mm:ss").toDate(),
        };
      });

      dispatch(fetchTasksCalenderSuccess(data));
    } catch (err: any) {
      dispatch(fetchTasksCalenderFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
