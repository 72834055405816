import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../redux/initialStoreState";
import { useDispatchWrapper } from "./useDispatch";
import { fetchHabitationalPremiumAsync } from "../redux/habitationalPolicy/habitationalBasicDetails/habitationalBasicDetailsActions";

export const useHabitationalPremium = (customerPolicyId: number) => {
  const dispatch = useDispatchWrapper();
  const premium = useSelector(
    (storeState: IStoreState) => storeState.habitational.basicDetails.premium
  );

  React.useEffect(() => {
    dispatch(fetchHabitationalPremiumAsync(customerPolicyId));
  }, [customerPolicyId]);

  return premium;
};