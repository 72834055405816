import { action } from "typesafe-actions";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import {
  IAddInterestedParty,
  IChangeInterestedParty,
  IDeleteInterestedParty,
  initialTaskInterestedPartyState,
} from "./interestedParty.types";
import { uploadFile } from "../../../helpers";

export const FETCH_ADD_INTERESTED_PARTY_PROGRESS =
  "FETCH_ADD_INTERESTED_PARTY_PROGRESS";
export const FETCH_ADD_INTERESTED_PARTY_SUCCESS =
  "FETCH_ADD_GARAGE_PLATE_SUCCESS";
export const FETCH_ADD_INTERESTED_PARTY_FAILED =
  "FETCH_ADD_INTERESTED_PARTY_FAILED";

export const fetchAddInterestedPartyProgress = () =>
  action(FETCH_ADD_INTERESTED_PARTY_PROGRESS);
export const fetchAddInterestedPartySuccess = (data: IAddInterestedParty) =>
  action(FETCH_ADD_INTERESTED_PARTY_SUCCESS, { data });
export const fetchAddInterestedPartyFailed = () =>
  action(FETCH_ADD_INTERESTED_PARTY_FAILED);

export const fetchAddInterestedPartyAysnc =
  (taskId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAddInterestedPartyProgress());
      const res = await api.get(
        `/tasks/get-tasks-add-interested-party?task_code=${taskId}`
      );
      const data: IAddInterestedParty[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAddInterestedPartySuccess(data[0]));
      } else {
        dispatch(
          fetchAddInterestedPartySuccess(
            initialTaskInterestedPartyState.addInterestedParty["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchAddInterestedPartyFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskAddInterestedPartyAysnc =
  (
    task: IAddInterestedParty,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/tasks/create-tasks-add-interested-party", {
        ...task,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Task is saved succesfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchAddInterestedPartySuccess({ ...task }));
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// Delete Interested Party

export const FETCH_DELETE_INTERESTED_PARTY_PROGRESS =
  "FETCH_DELETE_INTERESTED_PARTY_PROGRESS";
export const FETCH_DELETE_INTERESTED_PARTY_SUCCESS =
  "FETCH_DELETE_GARAGE_PLATE_SUCCESS";
export const FETCH_DELETE_INTERESTED_PARTY_FAILED =
  "FETCH_DELETE_INTERESTED_PARTY_FAILED";

export const fetchDeleteInterestedPartyProgress = () =>
  action(FETCH_DELETE_INTERESTED_PARTY_PROGRESS);
export const fetchDeleteInterestedPartySuccess = (
  data: IDeleteInterestedParty
) => action(FETCH_DELETE_INTERESTED_PARTY_SUCCESS, { data });
export const fetchDeleteInterestedPartyFailed = () =>
  action(FETCH_DELETE_INTERESTED_PARTY_FAILED);

export const fetchDeleteInterestedPartyAysnc =
  (taskId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchDeleteInterestedPartyProgress());
      const res = await api.get(
        `/tasks/get-tasks-delete-interested-party?task_code=${taskId}`
      );
      const data: IDeleteInterestedParty[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchDeleteInterestedPartySuccess(data[0]));
      } else {
        dispatch(
          fetchDeleteInterestedPartySuccess(
            initialTaskInterestedPartyState.deleteInterestedParty["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchDeleteInterestedPartyFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskDeleteInterestedPartyAysnc =
  (
    task: IDeleteInterestedParty,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/tasks/create-tasks-delete-interested-party", {
        ...task,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Task is saved succesfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchDeleteInterestedPartySuccess({ ...task }));
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// Change Interested Party

export const FETCH_CHANGE_INTERESTED_PARTY_PROGRESS =
  "FETCH_CHANGE_INTERESTED_PARTY_PROGRESS";
export const FETCH_CHANGE_INTERESTED_PARTY_SUCCESS =
  "FETCH_CHANGE_GARAGE_PLATE_SUCCESS";
export const FETCH_CHANGE_INTERESTED_PARTY_FAILED =
  "FETCH_CHANGE_INTERESTED_PARTY_FAILED";

export const fetchChangeInterestedPartyProgress = () =>
  action(FETCH_CHANGE_INTERESTED_PARTY_PROGRESS);
export const fetchChangeInterestedPartySuccess = (
  data: IChangeInterestedParty
) => action(FETCH_CHANGE_INTERESTED_PARTY_SUCCESS, { data });
export const fetchChangeInterestedPartyFailed = () =>
  action(FETCH_CHANGE_INTERESTED_PARTY_FAILED);

export const fetchChangeInterestedPartyAysnc =
  (taskId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchChangeInterestedPartyProgress());
      const res = await api.get(
        `/tasks/get-tasks-change-interested-party?task_code=${taskId}`
      );
      const data: IChangeInterestedParty[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchChangeInterestedPartySuccess(data[0]));
      } else {
        dispatch(
          fetchChangeInterestedPartySuccess(
            initialTaskInterestedPartyState.changeInterestedParty["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchChangeInterestedPartyFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskChangeInterestedPartyAysnc =
  (
    task: IChangeInterestedParty,
    onCallback: (isSuccess: boolean, taskId?: number) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post(
        "/tasks/create-tasks-change-interested-party",
        {
          ...task,
        }
      );
      dispatch(
        showMessage({
          type: "success",
          message: "Task is saved succesfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(
        fetchChangeInterestedPartySuccess({
          ...task,
          task_code: res.data.data.task_id,
        })
      );
      onCallback(true, res.data.data.task_id);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
