/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { StandadCard } from "../../../../../../components/Cards";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { LoadState, MODULE_IDS } from "../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../hooks";
import { useFormik } from "formik";
import { Box, Button, Grid, Stack, Typography, Checkbox, IconButton, Divider } from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../components/formsComponents";
import { IBreadcrumbProps } from "../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useNavigate, useParams } from "react-router-dom";
import { CustomDatePicker } from "../../../../../../components/formsComponents/CustomDatePicker";
import moment from "moment";
import { useBreadcrumbContext } from "../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { renderDropdownValue } from "../../../../../../helpers";
import { CurrencyTextField } from "../../../../../../components/CurrencyTextField/CurrencyTextField";
import { RoleBasedCustomButton } from "../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { LabelCard } from "../../../../../../components/LabelCard/LabelCard";
import { ILocationResponsePayload, LocationAutoComplete } from "../../../../../../components/LocationAutoComplete/LocationAutoComplete";
import { Add, Remove } from "@mui/icons-material";
import { addHabitationalDwellingDeclarationAsync, clearHabitationalDwellingDeclaration, fetchHabitationalDwellingDeclarationAsync } from "../../../../../../redux/habitationalPolicy/HabitationalPolicyForm/HabitationalPolicyFormActions";

export const HabitationalDwellingDeclaration: React.FC<{isDuplicate?: boolean}> = (props) => {
  const { customerId, customerPolicyId, serialNumber } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
    serialNumber?: number;
  };

  const { isDuplicate } = props;
  const { addBreadcrumb } = useBreadcrumbContext();

  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      to: "/view-customers",
      title: "customers",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer-overview",
    },
    {
      title: "Dwelling Declaration",
    },
  ];

  React.useEffect(() => {
    addBreadcrumb({ title: "", items: BCrumb });
    return () => {
      dispatch(clearHabitationalDwellingDeclaration());
    };
  }, []);

  const { dwelling, dwellingLoading, error } = useSelector(
    (storeState: IStoreState) => storeState.habitational.policyform.dwellingdeclaration
  );
  const customerInfo = useSelector(
    (storeState: IStoreState) =>
      storeState.habitational.basicDetails.customerInfo.data
  );
  const [saveLoading, setSaveLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: dwelling,
    validate: (values) => { },
    onSubmit: (values) => {
      if (customerPolicyId && customerId) {
        setSaveLoading(true);
        dispatch(
          addHabitationalDwellingDeclarationAsync(
            {
              ...values,
              customer_policy_id: customerPolicyId,
            },
            (isSuccess) => {
              if (isSuccess) {
                window.scrollTo(0, 0);
                navigate(
                  `/habitational-policy/${customerId}/policy-forms-tab/${customerPolicyId}?tab=3`
                );
              }
              setSaveLoading(false);
            },
            isDuplicate,
          )
        );
      }
    },
  });

  const handleAddress = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      address: data.address,
      city: data.city,
      country: data.country,
      province_or_state: data.state,
      postal_code: data.postalCode,
    });
  };

  const handleAdd = () => {
    setValues(prevState => ({
      ...prevState,
      name_relation_living_since: [
        ...prevState.name_relation_living_since,
        { name: '', relation: '', living_since: '' } 
      ]
    }));
  };
  
  const handleRemove = () => {
    setValues(prevState => ({
      ...prevState,
      name_relation_living_since: prevState.name_relation_living_since.slice(0, -1)
    }));
  };

  const handleChangeArray = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number, field: string) => {
    const { name_relation_living_since } = values;
    const updatedArray = [...name_relation_living_since]; 
    const value = e.target.value; 
    updatedArray[index] = { ...updatedArray[index], [field]: value }; 
    setFieldValue('name_relation_living_since', updatedArray); 
  };

  React.useEffect(() => {
    if (customerPolicyId && serialNumber) {
      dispatch(fetchHabitationalDwellingDeclarationAsync(customerPolicyId, serialNumber));
    }
  }, [customerPolicyId, serialNumber]);


  React.useEffect(() => {
    if (!serialNumber) {
      setValues({
        ...dwelling,
        customer_name: customerInfo.customer_name,
        policy_number: customerInfo.policy_number,
      });
    } else {
      setValues(dwelling);
    }
  }, [dwelling, customerInfo]);

  React.useEffect(() => {
    setFieldValue("customer_name", customerInfo.customer_name);
    setFieldValue("policy_number", customerInfo.policy_number);
    // setFieldValue("policy_status", customerInfo.policy_status);
  }, [customerInfo])

  React.useEffect(() => {
    return () => {
      dispatch(clearHabitationalDwellingDeclaration());
    };
  }, []);


  return (
    <PageLoader
      loading={dwellingLoading === LoadState.InProgress}
      error={error ? { message: error } : undefined}
    >
      <StandadCard sx={{ mt: 2 }} heading="Dwelling Declaration">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} px={2}>
          <Grid item xs={12} md={2}>
              <CustomFormLabel>Policy #</CustomFormLabel>
              <CustomTextField
                name="policy_number"
                variant="outlined"
                size="small"
                type="text"
                disabled
                fullWidth
                value={values.policy_number}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Customer Name</CustomFormLabel>
              <CustomTextField
                name="customer_name"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                disabled
                value={values.customer_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Address</CustomFormLabel>
              <LocationAutoComplete
                id="address"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.address}
                onLocationChange={handleAddress}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomFormLabel>Unit/Suite</CustomFormLabel>
              <CustomTextField
                name="unit_or_suite"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.unit_or_suite}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomFormLabel>City</CustomFormLabel>
              <CustomTextField
                name="city"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.city}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomFormLabel>State/Province</CustomFormLabel>
              <CustomTextField
                name="province_or_state"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.province_or_state}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomFormLabel>Postal Code</CustomFormLabel>
              <CustomTextField
                name="postal_code"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.postal_code}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomFormLabel>Country</CustomFormLabel>
              <CustomTextField
                name="country"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.country}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography
            px={1}
            variant="body1"
            fontSize={"1.2rem"}
            fontWeight={600}
          >
            Declaration Questions
          </Typography>
          <Grid container spacing={2} px={2}>
            <Grid item xs={12} lg={12}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Checkbox
                  name="this_policy_is_for_rental_dwelling"
                  size="medium"
                  color="primary"
                  checked={values.this_policy_is_for_rental_dwelling === "Y"}
                  onChange={(e, checked) => {
                    setFieldValue("this_policy_is_for_rental_dwelling", checked ? "Y" : "N");
                  }}
                />
                <CustomFormLabel>I/WE UNDERSTAND & ACKNOWLEDGE THAT THIS POLICY IS FOR RENTAL DWELLING COVERAGE, NOT FOR STUDENT HOUSING OR VACANT DWELLING COVERAGE </CustomFormLabel>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Checkbox
                  name="this_policy_is_for_student_housing"
                  size="medium"
                  color="primary"
                  checked={values.this_policy_is_for_student_housing === "Y"}
                  onChange={(e, checked) => {
                    setFieldValue("this_policy_is_for_student_housing", checked ? "Y" : "N");
                  }}
                />
                <CustomFormLabel>I/WE UNDERSTAND & ACKNOWLEDGE THAT THIS POLICY IS FOR STUDENT HOUSING </CustomFormLabel>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Checkbox
                  name="we_understand_that_if_any_of_the_above"
                  size="medium"
                  color="primary"
                  checked={values.we_understand_that_if_any_of_the_above === "Y"}
                  onChange={(e, checked) => {
                    setFieldValue("we_understand_that_if_any_of_the_above", checked ? "Y" : "N");
                  }}
                />
                <CustomFormLabel>WE UNDERSTAND THAT IF ANY OF THE ABOVE NEEDS TO CHANGED, WE WILL UPDATE MY BROKER IN WRITING BEFORE MAKING SUCH CHANGE. I ALSO UNDERSTAND FAILING TO DO SO, MAY RESULT IN DECLINATION OF COVERAGE AND/OR POLICY CANCELLATION. </CustomFormLabel>
              </Stack>
            </Grid>
          </Grid>
          <LabelCard heading="Residing People Details" />
          <Grid item xs={12} md={12}>
          <Box marginTop={2}>
            <Stack spacing={1} direction={"row"} justifyContent={"end"}>
              <Button
                color="error"
                variant="contained"
                onClick={handleRemove}
              >
                <Remove fontSize="small" />
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAdd}
              >
                <Add fontSize="small" />
              </Button>
            </Stack>
            {values.name_relation_living_since.map((item, index) => (
              <Grid container spacing={2} px={2} key={index}>
                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Name</CustomFormLabel>
                  <CustomTextField
                    name={`name_relation_living_since[${index}].name`}
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={item.name}
                    onChange={(e) => handleChangeArray(e, index, 'name')} // You need to implement handleChangeArray
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Relation</CustomFormLabel>
                  <CustomTextField
                    name={`name_relation_living_since[${index}].relation`}
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={item.relation}
                    onChange={(e) => handleChangeArray(e, index, 'relation')} // You need to implement handleChangeArray
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Living Since</CustomFormLabel>
                  <CustomTextField
                    name={`name_relation_living_since[${index}].living_since`}
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={item.living_since}
                    onChange={(e) => handleChangeArray(e, index, 'living_since')} // You need to implement handleChangeArray
                  />
                </Grid>
              </Grid>
            ))}
          </Box>
          
          </Grid>
          <Divider sx={{ mt: 4}} />
          <Grid container px={2}>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Status</CustomFormLabel>
              <ControlledCustomSelect
                name="status"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                displayEmpty
                placeholder="Select One"
                options={[
                  { label: "Active", value: "ACTIVE" },
                  { label: "Inactive", value: "INACTIVE" },
                ].map((data) => {
                  return { label: data.label, value: data.value };
                })}
                value={values.status}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: 4 }}>
            <RoleBasedCustomButton
              sx={{ width: "15%" }}
              disabled={saveLoading}
              variant="contained"
              moduleId={MODULE_IDS.COMMERCIAL_AUTO}
              type="submit"
            >
              Save
            </RoleBasedCustomButton>
          </Box>
        </form>
      </StandadCard>
      {/* </CustomerBaiscDetailsLayout> */}
    </PageLoader>
  );
};
