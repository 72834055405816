import produce from "immer";
import { IStoreState } from "../initialStoreState";

import { LoadState } from "../../constants/enums";
import { initialTemplateState } from "./templates.types";
import { TemplateActions } from ".";
import {
  CLEAR_TEMPLATE,
  CLEAR_TEMPLATE_STATE,
  FETCH_TEMPLATE_FAILED,
  FETCH_TEMPLATE_LIST_FAILED,
  FETCH_TEMPLATE_LIST_PROGRESS,
  FETCH_TEMPLATE_LIST_SUCCESS,
  FETCH_TEMPLATE_PROGRESS,
  FETCH_TEMPLATE_SUCCESS,
} from "./templateActions";

export const templateReducer = (
  state: IStoreState["templates"] = initialTemplateState,
  action: TemplateActions
) => {
  switch (action.type) {
    case FETCH_TEMPLATE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_TEMPLATE_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_TEMPLATE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_TEMPLATE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.templateLoading = LoadState.InProgress;
        draftState.template = initialTemplateState["template"];
      });
      return newState;
    }
    case FETCH_TEMPLATE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.templateLoading = LoadState.Loaded;
        draftState.template = data;
      });
      return newState;
    }
    case FETCH_TEMPLATE_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.templateLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_TEMPLATE: {
      const newState = produce(state, (draftState) => {
        draftState.templateLoading = LoadState.NotLoaded;
        draftState.template = initialTemplateState["template"];
      });
      return newState;
    }

    case CLEAR_TEMPLATE_STATE: {
      return initialTemplateState;
    }

    default: {
      return state;
    }
  }
};
