import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";
import { IRenewals } from "./renewals.types";


// <----------Renewals Actions-------->
export const CLEAR_TASK_RENEWALS = "CLEAR_TASK_RENEWALS";
export const clearRenewalsList = () =>
  action(CLEAR_TASK_RENEWALS);
export const FETCH_TASK_RENEWALS_LIST_PROGRESS =
  "FETCH_TASK_RENEWALS_LIST_PROGRESS";
export const FETCH_TASK_RENEWALS_LIST_SUCCESS =
  "FETCH_TASK_RENEWALS_LIST_SUCCESS";
export const FETCH_TASK_RENEWALS_LIST_FAILED =
  "FETCH_TASK_RENEWALS_LIST_FAILED";
  
export const fetchRenewalsListProgress = () =>
  action(FETCH_TASK_RENEWALS_LIST_PROGRESS);
export const fetchRenewalsListSuccess = (
  list: IRenewals[],
  totalRecords: number
) => action(FETCH_TASK_RENEWALS_LIST_SUCCESS, { list, totalRecords });
export const fetchRenewalsListFailed = () =>
  action(FETCH_TASK_RENEWALS_LIST_FAILED);

export const fetchRenewalsListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[],
    selectedDayValue: string,
    line_of_business: any,
    setTypeOfPolicy: any,
    setTableName: any,
    groupType: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchRenewalsListProgress());
      let finalUrl = `/policy/get-all-customer-policy-details?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;
      if (line_of_business === undefined) {
        setTypeOfPolicy("All Policies");
        setTableName("latest_all_customer_policy_details");
      } else if (line_of_business === "Business Policy") {
        finalUrl = `/policy/get-policy-business-customer-info?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;
        setTypeOfPolicy("Business Policies");
        setTableName("latest_policy_business_customer_info");
      } else if (line_of_business === "Automobile Policy") {
        finalUrl = `/policy/get-policy-auto-customer-info?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;

        setTypeOfPolicy("Automobile Policies");
        setTableName("latest_policy_automobile_customer_info");
      } else if (line_of_business === "Commercial Auto Policy") {
        finalUrl = `/policy/get-policy-commauto-customer-info?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;

        setTypeOfPolicy("Commercial Auto Policies");
        setTableName("latest_policy_commauto_customer_info");
      } else if (line_of_business === "Habitational Policy") {
        finalUrl = `/policy/get-habitational-customer-info?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;

        setTypeOfPolicy("Habitational Policies");
        setTableName("latest_policy_habitational_customer_info");
      } else if (line_of_business === "Life & Financial Policy") {
        finalUrl = `/policy/get-life-policy-basic-details-customer-info?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;

        setTypeOfPolicy("Life & Financial Policies");
        setTableName("latest_policy_life_basic_details_customer_info");
      }

      if (searchType.length > 0 && searchValue !== "-1") {
        finalUrl =
          finalUrl + `&column=${[searchType, "policy_status"]}&value=${searchValue}`;
      }
      if(selectedDayValue){
        finalUrl = finalUrl + "&date_column=create_ts" 
      }
      if (status !== "-1") {
        finalUrl = finalUrl + `&column=${"policy_status"}&value=${status}`;;
      }
      if(groupType !== "-1"){
        finalUrl += "&group_of=" + groupType;
      }
      const res = await api.get(finalUrl);

      const data: IRenewals[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchRenewalsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchRenewalsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
