import React from "react";
import { IOptionsRightPanelProps } from "./OptionsRightPanel.types";
import { useFormik } from "formik";
import { RightPanel } from "../RightPanel";
import { Button, Grid, SelectChangeEvent } from "@mui/material";
import { ControlledCustomSelect, CustomFormLabel } from "../formsComponents";
import { useSelector } from "react-redux";
import { IStoreState } from "../../redux/initialStoreState";
import { IG4SignEditior } from "../../redux/g4SignDocuments/g4SignDocuments.types";
import { DOCU_SIGN_FIELD_SIZE } from "./enums";

export const OptionsRightPanel: React.FC<IOptionsRightPanelProps> = (props) => {
  const { open, currentData, onSave, onDelete, onClose } = props;
  const recipientList = useSelector(
    (storeState: IStoreState) => storeState.g4Sign.documents.recipientList
  );

  const { values, handleChange, handleSubmit, setValues } = useFormik({
    initialValues: currentData,
    onSubmit: (values) => {
      onSave({ ...values, isNewPlaceholder: currentData.isNewPlaceholder });
    },
  });

  const handleRecipientChange = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value;
    const data = recipientList.find((x) => x.recipient_code === value) || {
      signer_name: "",
    };
    setValues({
      ...values,
      recipient_code: e.target.value as string,
      customer_text: data.signer_name as string,
    });
  };

  const handleSizeChange = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as string;

    setValues({
      ...values,
      size_category: value,
    });
  };

  return (
    <div
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <RightPanel
        open={open}
        heading="Placeholder Editing Panel"
        onClose={onClose}
        isWrappedWithForm={true}
        onFormSubmit={handleSubmit}
        actionButtons={() => {
          return (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  type="submit"
                  color="secondary"
                  fullWidth
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={props.onDelete}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          );
        }}
      >
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <CustomFormLabel>Recipient</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.recipient_code}
              options={recipientList.map((item) => {
                return { label: item.signer_name, value: item.recipient_code };
              })}
              onChange={handleRecipientChange}
              displayEmpty
              placeholder="Select recipient"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomFormLabel>Size</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.size_category}
              options={[
                { label: "Small", value: DOCU_SIGN_FIELD_SIZE.SMALL },
                { label: "Medium", value: DOCU_SIGN_FIELD_SIZE.MEDIUM },
                { label: "Large", value: DOCU_SIGN_FIELD_SIZE.LARGE },
              ]}
              onChange={handleSizeChange}
              displayEmpty
              placeholder="Select Size"
            />
          </Grid>
        </Grid>
      </RightPanel>
    </div>
  );
};
