/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { LoadState } from "../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../hooks";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { ICommAutomobileGaragePlates } from "../../../../../../redux/commAutoPolicy/commAutoGaragePlates/commAutoGaragePlates.types";
import { clearCommAutomobileGaragePlatesState, fetchCommAutomobileGaragePlatesListAsync } from "../../../../../../redux/commAutoPolicy/commAutoGaragePlates/commAutoGaragePlatesActions";
import { CommAutoGaragePlatesDialog } from "./CommAutoGaragePlateDialog";
import { useBreadcrumbContext } from "../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { IBreadcrumbProps } from "../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";

const INITIAL_GARAGE_PLATE: ICommAutomobileGaragePlates = {
  customer_policy_id: null,
  serial_number: null,
  type: null,
  plate_number: null,
  charge_details: null,
  assigned_to: null,
  attachment: null,
  status: "ACTIVE",
};

export const CommAutoGaragePlatesList = () => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerpolicyId = data1[1];
  const navigate = useNavigate();

  const { addBreadcrumb } = useBreadcrumbContext();

  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      to: "/view-customers",
      title: "customers",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer-overview",
    },
    {
      to: "/comm-automobile-policy/comm-automobile/" + customerId + "/" + customerpolicyId,
      title: "comm-auto",
    },
    {
      title: "garage-plate",
    },
  ];

  React.useEffect(() => {
    addBreadcrumb({ title: "", items: BCrumb });
    return () => {
      dispatch(clearCommAutomobileGaragePlatesState());
    };
  }, []);

  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.garagePlate
  );

  const dispatch = useDispatchWrapper();
  const [openGaragePlate, setOpenGaragePlate] =
    React.useState<ICommAutomobileGaragePlates | null>(null);

  const handleCloseGaragePlate = () => {
    setOpenGaragePlate(null);
  };

  const handleGaragePlateComplete = () => {
    dispatch(fetchCommAutomobileGaragePlatesListAsync(customerpolicyId));
    handleCloseGaragePlate();
  };

  const garagePlateTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() => {
                  setOpenGaragePlate({
                    ...INITIAL_GARAGE_PLATE,
                    customer_policy_id: customerpolicyId,
                  });
                }}
              >
                Add Garage Plate
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "serial_number",
        headerName: "#",
        fieldName: "serial_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: ICommAutomobileGaragePlates) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => setOpenGaragePlate(row)}
            >
              {row.serial_number}
            </Link>
          );
        },
      },
      {
        key: "plate_number",
        headerName: "Plate Number",
        fieldName: "plate_number",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
        onRowCellRender: (value, row: ICommAutomobileGaragePlates) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => setOpenGaragePlate(row)}
            >
              {row.plate_number}
            </Link>
          );
        },
      },
      {
        key: "type",
        headerName: "Type",
        fieldName: "type",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
        onRowCellRender: (value, row: ICommAutomobileGaragePlates) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => setOpenGaragePlate(row)}
            >
              {row.type}
            </Link>
          );
        },
      },
 

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobileGaragePlates) => {
          return (
            <StandardTableActions onEditClick={() => setOpenGaragePlate(row)} />
          );
        },
      },
    ],
    items: data,
  };

  React.useEffect(() => {
    dispatch(fetchCommAutomobileGaragePlatesListAsync(customerpolicyId));
  }, [customerpolicyId]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...garagePlateTableProps} />
      </Box>
      {openGaragePlate && (
        <CommAutoGaragePlatesDialog
          open={true}
          data={openGaragePlate}
          onClose={handleCloseGaragePlate}
          onComplete={handleGaragePlateComplete}
        />
      )}
    </>
  );
};
