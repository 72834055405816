/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, MenuItem, Stack } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { IBusinesPolicyBasicDetailsProps } from "../BusinessPolicyBasicDetails.types";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import {
  addPolicyAdminsync,
  addPremiumAsync,
  fetchPolicyAdminAsync,
  fetchPremiumAsync,
} from "../../../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import { InsurerCodeAutoSearch } from "../../../../../../../components/AutoCompleteSearches";
import { UsersAutoSearchByRole } from "../../../../../../../components/AutoCompleteSearches/UsersAutoSearchByRole";
import { ComissionCodeSelect } from "../../../../../../../components/AutoCompleteSearches/ComissionCodeSelect";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { fetchMyProfileAsync } from "../../../../../../../redux/UserProfileList/userProfileActions";
import { useTabsUncontrolledContext } from "../../../../../../../contexts/TabsContextProvider/TabsContextUncontrolledProvider";
import { BrokerCodeAutoSearch } from "../../../../../../../components/AutoCompleteSearches/BrokerCodeAutoSearch";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import { CurrencyTextField } from "../../../../../../../components/CurrencyTextField/CurrencyTextField";
import { renderDropdownValue } from "../../../../../../../helpers";
import { batch } from "react-redux";
import { api } from "../../../../../../../api/api";
import { IInsurerCode } from "../../../../../../../redux/insurer/insurer.types";
import { ISelectOption } from "../../../../../../../constants/types";
import { debounce } from "lodash";
import { showMessage } from "../../../../../../../redux/messages/messagesActions";

export const PolicyAdmin: React.FC<IBusinesPolicyBasicDetailsProps> = (
  props
) => {
  const { customerpolicyId } = props;
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.business.basicDetails.policyAdmin
  );
  const { data: premiumData, loading: premiumLoading } = useSelector(
    (storeState: IStoreState) => storeState.business.basicDetails.premium
  );

  const dispatch = useDispatchWrapper();
  const { customerProspect } = useSelector(
    (storeState: IStoreState) => storeState.customer
  );
  const [buttonClicked, setButtonClicked] = React.useState<string | null>(null);
  const { changeStep } = useTabsUncontrolledContext();

  const formikPremium = useFormik({
    initialValues: premiumData,
    validate: (values) => {
      const errors: any = {};
      if (values.tax_1 && Number(values.tax_1) > 100) {
        errors.tax_1 = "Tax 1 % cannot be greater than 100";
      }
      if (values.tax_2 && Number(values.tax_2) > 100) {
        errors.tax_2 = "Tax 2 % cannot be greater than 100";
      }
      return errors;
    },
    onSubmit: async (values) => {
      return new Promise((resolve,reject) => {
        dispatch(
          addPremiumAsync(
            {
              ...values,
              customer_policy_id: customerpolicyId,
            },
            (isSuccess,error) => {
              if(error){
                reject(error)
              }
              resolve(isSuccess);
            
            }
          )
        );
      });
    },
  });

  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    errors,
    submitForm,
    isValid,
  } = useFormik({
    initialValues: data,
    validate: (values) => {
      const errors: any = {};
      if (!values.producer_1) {
        errors.producer_1 = "Producer 1 is requried";
      }
      return errors;
    },
    onSubmit: async (values) => {
      return new Promise((resolve,reject) => {
        dispatch(
          addPolicyAdminsync(
            {
              ...values,
              customer_policy_id: customerpolicyId,
            },
            (isSuccess,error) => {
              if(error){
                reject(error)
              }
              resolve(isSuccess);
            }
          )
        );
      });
    },
  });

  const handleFinalSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
   
    // Submit Premium form and wait for success callback
   try{
    const adminSubmitSuccess = await submitForm();
    const premiumSubmitSuccess = await formikPremium
    .submitForm()


  // Check if both forms' API calls succeeded
  if (adminSubmitSuccess && premiumSubmitSuccess) {
    dispatch(
      showMessage({
        type: "success",
        message: "Policy admin saved successfully!",
        displayAs: "snackbar",
      })
    );
    if (buttonClicked === "saveAndNext") {
      changeStep(2); // Move to the next step if both forms succeed
    }
  }
   }
   catch(error: any){
    dispatch(
      showMessage({
        type: "error",
        message: error.response.data.message,
        displayAs: "snackbar",
      })
    );
   }
  };

  const handleInsurer = async (value: ISelectOption) => {
    setFieldValue("insurer", value.value);
    try {
      const res = await api.get(
        `/insurance/get-insurer-code?column=insurer_code&column=name&value=${value.value}`
      );
      const data: IInsurerCode[] = res.data.data;
      if (data) {
        formikPremium.setFieldValue(
          "agency_bill_or_direct_bill",
          data[0].default_billing
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  React.useEffect(() => {
    batch(() => {
      dispatch(fetchPolicyAdminAsync(customerpolicyId));
      dispatch(fetchPremiumAsync(customerpolicyId));
    });
  }, []);

  React.useEffect(() => {
    setValues({
      ...data,
      branch_code: data.branch_code || customerProspect.data.branch_code,
    });
  }, [data, customerProspect]);

  React.useEffect(() => {
    formikPremium.setValues(premiumData);
  }, [premiumData]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      <form onSubmit={handleFinalSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Insurer</CustomFormLabel>
            <InsurerCodeAutoSearch
              value={values.insurer}
              onSelect={handleInsurer}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Comission Code</CustomFormLabel>
            <ComissionCodeSelect
              insurerCode={values.insurer}
              value={values.comission_code}
              onChange={(value) => {
                setFieldValue("comission_code", value);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Branch Code</CustomFormLabel>
            <CustomTextField fullWidth value={values.branch_code} disabled />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Policy Fee</CustomFormLabel>
            <CustomTextField
              name="policy_fee"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={values.policy_fee}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              label="Producer 1"
              value={values.producer_1}
              errorMessage={errors.producer_1}
              onSelect={(data) => {
                setFieldValue("producer_1", data.user_fact_id);
                setFieldValue("producer_1_code", data.user_code);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              label="Producer 2"
              value={values.producer_2}
              onSelect={(data) => {
                setFieldValue("producer_2", data.user_fact_id);
                setFieldValue("producer_2_code", data.user_code);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              label="Producer 3"
              value={values.producer_3}
              onSelect={(data) => {
                setFieldValue("producer_3", data.user_fact_id);
                setFieldValue("producer_3_code", data.user_code);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              value={values.marketer}
              label="Marketer"
              onSelect={(value) => {
                setFieldValue("marketer", value.user_fact_id);
                setFieldValue("marketer_code", value.user_code);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              value={values.csr}
              label="CSR"
              onSelect={(value) => {
                setFieldValue("csr", value.user_fact_id);
                setFieldValue("csr_code", value.user_code);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Billing Account Number</CustomFormLabel>
            <CustomTextField
              name="billing_account_number"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.billing_account_number}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Broker Code</CustomFormLabel>
            <BrokerCodeAutoSearch
              value={values.broker_code}
              onSelect={(value) => {
                setFieldValue("broker_code", value.value);
              }}
            />
          </Grid>
        </Grid>

        <LabelCard heading="Premium" />
        <Grid container spacing={1}>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Policy Premium</CustomFormLabel>
            <CurrencyTextField
              fullWidth
              value={formikPremium.values.policy_premium}
              onChange={(value) => {
                formikPremium.setFieldValue("policy_premium", value);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Agency or Direct Bill</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="agency_bill_or_direct_bill"
              fullWidth
              value={formikPremium.values.agency_bill_or_direct_bill}
              onChange={formikPremium.handleChange}
              displayEmpty
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["A", "D"].map((type) => {
                return <MenuItem value={type}>{type}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Payment Plan</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={formikPremium.values.payment_plan}
              name="payment_plan"
              onChange={formikPremium.handleChange}
              placeholder="Select one"
              displayEmpty
              options={["Monthly", "Annual", "Quarterly", "Other"].map(
                (template) => {
                  return { label: template, value: template };
                }
              )}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Premium Finance Contract Number</CustomFormLabel>
            <CustomTextField
              name="premium_finance_contract_number"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={formikPremium.values.premium_finance_contract_number}
              onChange={formikPremium.handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Premium Financed?</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={formikPremium.values.premium_finance}
              name="premium_finance"
              onChange={formikPremium.handleChange}
              placeholder="Select one"
              displayEmpty
              options={[
                { label: "Yes", value: "YES" },
                { label: "No", value: "NO" },
              ]}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Financed By</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={formikPremium.values.financed_by}
              name="financed_by"
              onChange={formikPremium.handleChange}
              placeholder="Select one"
              displayEmpty
              options={["IFS", "CAFO", "First Insurance Funding", "SNAP"].map(
                (template) => {
                  return { label: template, value: template };
                }
              )}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Default Tax Region</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={formikPremium.values.default_tax_region}
              name="default_tax_region"
              onChange={formikPremium.handleChange}
              placeholder="Select One"
              displayEmpty
              options={[
                "AB",
                "BC",
                "MB",
                "NB",
                "NL",
                "NS",
                "NT",
                "NU",
                "ON",
                "PE",
                "QC",
                "SK",
                "YT",
              ].map((template) => {
                return { label: template, value: template };
              })}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Tax 1 %</CustomFormLabel>
            <CustomTextField
              name="tax_1"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={formikPremium.values.tax_1}
              error={formikPremium.errors.tax_1 ? true : false}
              helperText={formikPremium.errors.tax_1}
              onChange={formikPremium.handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Tax 2 %</CustomFormLabel>
            <CustomTextField
              name="tax_2"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={formikPremium.values.tax_2}
              error={formikPremium.errors.tax_2 ? true : false}
              helperText={formikPremium.errors.tax_2}
              onChange={formikPremium.handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Tax Exempt</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={formikPremium.values.tax_exempt}
              name="tax_exempt"
              onChange={formikPremium.handleChange}
              placeholder="Select one"
              displayEmpty
              options={["Y", "N"].map((template) => {
                return { label: template, value: template };
              })}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Tax Exempt Reason</CustomFormLabel>
            <CustomTextField
              name="tax_exempt_reason"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={formikPremium.values.tax_exempt_reason}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Stack direction={"row"} spacing={2} sx={{ mt: 4 }}>
          <RoleBasedCustomButton
            type="button"
            moduleId={MODULE_IDS.BUSINESS_POLICY}
           
            variant="contained"
            onClick={() => changeStep(0)}
          >
            Back
          </RoleBasedCustomButton>
          <RoleBasedCustomButton
            type="submit"
            moduleId={MODULE_IDS.BUSINESS_POLICY}
           
            variant="contained"
            onClick={() => setButtonClicked("save")}
          >
            Save
          </RoleBasedCustomButton>
          <RoleBasedCustomButton
            type="submit"
            moduleId={MODULE_IDS.BUSINESS_POLICY}
       
            variant="contained"
            onClick={() => setButtonClicked("saveAndNext")}
          >
            Save & Next
          </RoleBasedCustomButton>
        </Stack>
      </form>
    </PageLoader>
  );
};
