import moment from "moment";
import { LoadState } from "../../../constants/enums";

export interface ILifeInsurancePolicyState {
    data: ILifeInsurancePolicy;
    loading: LoadState;
    error: string | null;
}

export interface ILifeInsurancePolicy {
    customer_policy_id: number,
    life_insure: number,
    coverage_type: string | null,
    policy_number: string | null,
    advisor: string | null,
    csr: string | null,
    aga: string | null,
    compliance_status: string | null,
    insurer: string | null,
    policy_limit: string | null,
    effective_date: string,
    expiry_date: string,
    notes: string | null,
    assigned_to: string | null,
    status: "ACTIVE",
}

export const defaultLifeInsurancePolicy: ILifeInsurancePolicyState = {
    data: {
        customer_policy_id: 0,
        life_insure: 0,
        coverage_type: null,
        policy_number: null,
        advisor: null,
        csr: null,
        aga: null,
        compliance_status: null,
        insurer: null,
        policy_limit: null,
        effective_date: moment().format("YYYY-MM-DD"),
        expiry_date: moment().format("YYYY-MM-DD"),
        notes: null,
        assigned_to: null,
        status: "ACTIVE"
    },
    loading: LoadState.NotLoaded,
    error: null,
}
