import { action } from "typesafe-actions";
import {
  IInteracEmailTransfer,
  initialAccountingInteracEmailTransferState,
} from "./InteracEmailTransfer.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";

export const FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_PROGRESS =
  "FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_PROGRESS";
export const FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_SUCCESS =
  "FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_SUCCESS";
export const FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_FAILURE =
  "FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_FAILURE";

export const fetchAccountingInteracEmailTransferProgress = () =>
  action(FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_PROGRESS);
export const fetchAccountingInteracEmailTransferSuccess = (
  data: IInteracEmailTransfer
) => action(FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_SUCCESS, { data });
export const fetchAccountingInteracEmailTransferFailed = (
  errorMessage: string
) => action(FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_FAILURE, { errorMessage });

export const fetchAccountingInteracEmailTransferAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingInteracEmailTransferProgress());

      const res = await api.get(
        `/accounting/get-account-interac-email-transfer`
      );
      const data: IInteracEmailTransfer[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAccountingInteracEmailTransferSuccess(data[0]));
      } else {
        dispatch(
          fetchAccountingInteracEmailTransferSuccess(
            initialAccountingInteracEmailTransferState["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchAccountingInteracEmailTransferFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingInteracEmailTransferAysnc =
  (
    data: IInteracEmailTransfer,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/accounting/edit-account-interac-email-transfer", {
        ...data,
      });
      let message = "Interac Email Transfer saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_LIST_PROGRESS =
  "FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_LIST_PROGRESS";
export const FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_LIST_SUCCESS =
  "FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_LIST_SUCCESS";
export const FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_LIST_FAILURE =
  "FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_LIST_FAILURE";

export const fetchAccountingInteracEmailTransferListProgress = () =>
  action(FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_LIST_PROGRESS);
export const fetchAccountingInteracEmailTransferListSuccess = (
  data: IInteracEmailTransfer[]
) => action(FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_LIST_SUCCESS, { data });
export const fetchAccountingInteracEmailTransferListFailed = (
  errorMessage: string
) =>
  action(FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_LIST_FAILURE, {
    errorMessage,
  });

export const fetchAccountingInteracEmailTransferListAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingInteracEmailTransferListProgress());

      const res = await api.get(
        `/accounting/get-account-interac-email-transfer`
      );
      const data: IInteracEmailTransfer[] = res.data.data;
      dispatch(fetchAccountingInteracEmailTransferListSuccess(data));
    } catch (err: any) {
      dispatch(
        fetchAccountingInteracEmailTransferListFailed(
          "Something went to be wrong!"
        )
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_ACCOUNTING_INTERAC_EMAIL_TRANSFER =
  "CLEAR_ACCOUNTING_INTERAC_EMAIL_TRANSFER";
export const clearAccountingInteracEmailTransfer = () =>
  action(CLEAR_ACCOUNTING_INTERAC_EMAIL_TRANSFER);
