import produce from "immer";
import {
  ADD_INSURER_CONTACTS,
  ADD_INSURER_CONTACTS_ID,
  ADD_INSURER_CONTACTS_PROGRESS,
  CLEAR_INSURER_CONTACTS_STATE,
  FETCH_INSURER_CONTACTS_PROGRESS,
  INSURER_CONTACTS_ERROR,
} from "../constants";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialInsurerContactsState } from "./insurerContactsState";
import { IInsurerContactsActions } from ".";

export const insurerContactsReducer = (
  state: IStoreState["borkerage"]["insurerContacts"] = initialInsurerContactsState,
  action: IInsurerContactsActions
) => {
  switch (action.type) {
    case ADD_INSURER_CONTACTS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.insurerContacts.saveLoading = true;
      });
      return newState;
    }
    case ADD_INSURER_CONTACTS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.insurerContacts.loading = LoadState.Loaded;
        draftState.insurerContacts.saveLoading = false;
        draftState.insurerContacts.data = data;
      });
      return newState;
    }

    case FETCH_INSURER_CONTACTS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.insurerContacts.loading = LoadState.InProgress;
      });
      return newState;
    }

    case INSURER_CONTACTS_ERROR: {
      const newState = produce(state, (draftState) => {
        draftState.insurerContacts.error = action.payload.message;
        draftState.insurerContacts.saveLoading = false;
        draftState.insurerContacts.loading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_INSURER_CONTACTS_STATE: {
      return initialInsurerContactsState;
    }

    default: {
      return state;
    }
  }
};
