import React from "react";
import { IBreadcrumbProps } from "../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useTableV2State } from "../../components/TableV2/hooks/useTableV2State";
import { useDispatchWrapper, useRecordCounts } from "../../hooks";
import { IProject } from "../../redux/projects/projects.types";
import { useNavigate } from "react-router-dom";
import { CustomSelect } from "../../components/formsComponents";
import { createTabsWithRecordcounts, renderDropdownValue } from "../../helpers";
import { Button, MenuItem } from "@mui/material";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../components/TableV2/interfaces/IDataTableV2Props";
import { StandardTableActions } from "../../components/StandardTableActions/StandardTableActions";
import { StatusRenderer } from "../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { clearRecordCounts, fetchRecordCountsAsync } from "../../redux/common/commonActions";
import { PageContainer } from "../../components/container/PageContainer";
import { Breadcrumb } from "../../layout/fullLayout/breadcrumb/Breadcrumb";
import { PageTitleBar } from "../../components/PageTitleBar/PageTitleBar";
import { AddOutlined } from "@mui/icons-material";
import { DataTableV2 } from "../../components/TableV2/DataTableV2";

export const Projects: React.FC = () => {
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      title: "Projects",
    },
  ];

  //   const { list, listLoading, totalRecords } = useSelector(
  //     (state: IStoreState) => state.accounting.invoiceInformation
  //   );
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });

  // const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
  //   useDateFilter({ defaultType: "lastWeek" });
  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "allTimes",
      selectedTab: "ACTIVE",
    },
  });

  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();

  const [selectedProjects, setSelectedProjects] = React.useState<{
    isAllSelected: boolean;
    Invoice: IProject[];
  }>({
    isAllSelected: false,
    Invoice: [],
  });

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const fetchList = () => {
    // dispatch(
    //   fetchAccountingInvoiceInformationListAsync(
    //     tablePagination.pageNumber,
    //     tablePagination.rowsInPerPage,
    //     tabs.selectedTab,
    //     dateState.dates,
    //     searchState
    //   )
    // );
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagination, dateState.dates, tabs.selectedTab, searchState]);

  const menuItems =
    selectedProjects.Invoice.length > 0 || selectedProjects.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const tableV2: IDataTableV2Props = {
    isPagination: true,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    isDataLoading: false, //listLoading === LoadState.InProgress,
    totalRecords: 100, //totalRecords,
    selectionMode: "multiple",
    uniqueRowKeyName: "accn_invoice_information_id",
    tableCommandBarProps: {
      preDefinedPlugins: {
        dateFilter: {
          state: dateState,
          onChange: setDateState,
        },
        search: {
          state: searchState,
          onChange: setSearchState,
        },
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {
        customPlugins: menuItems,
      },
      rightItems: {},
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        isFirstColumnSticky: true,
        width: "120px",
        onRowCellRender: (value, row: IProject) => {
          return (
            <StandardTableActions
              //  onViewClick={() => {}}
              onEditClick={() => {
                navigate(
                  "/invoicing/create/" + (row as IProject).accident_forgiveness
                );
              }}
              // more={{ menuItems: menuItems }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",

        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IProject) => {
          return <StatusRenderer status={row.item_status || ""} />;
        },
      },

      {
        key: "lob",
        headerName: "LOB",
        fieldName: "lob",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },
      {
        key: "lob_code",
        headerName: "LOB Code",

        fieldName: "lob_code",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },

      {
        key: "broker_number",
        headerName: "Broker Number",
        fieldName: "broker_number",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "name",
        headerName: "Name",
        fieldName: "name",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "insurer_branch_code",
        headerName: "Insurer Branch Code",
        fieldName: "insurer_branch_code",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "operating_name",
        headerName: "Operating Name",
        fieldName: "operating_name",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "sub_broker",
        headerName: "Sub Broker",
        fieldName: "sub_broker",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "client_name",
        headerName: "Client Name",
        fieldName: "client_name",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "effective_date",
        headerName: "Effective Date",
        fieldName: "effective_date",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "expiry_date",
        headerName: "Expiry Date",
        fieldName: "expiry_date",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "transaction_type",
        headerName: "Transaction Type",
        fieldName: "transaction_type",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "year",
        headerName: "Year",
        fieldName: "year",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "make",
        headerName: "Make",
        fieldName: "make",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "mode",
        headerName: "Mode",
        fieldName: "mode",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "vin",
        headerName: "VIN",
        fieldName: "vin",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "unit",
        headerName: "Unit",
        fieldName: "unit",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "lpn",
        headerName: "LPN",
        fieldName: "lpn",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "acv",
        headerName: "ACV",
        fieldName: "acv",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "radius",
        headerName: "Radius",
        fieldName: "radius",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "producer",
        headerName: "Producer",
        fieldName: "producer",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "branch",
        headerName: "Branch",
        fieldName: "branch",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "retail_group",
        headerName: "Retail Group",
        fieldName: "retail_group",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "overland_water",
        headerName: "Overland Water",
        fieldName: "overland_water",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "overland_water_zone",
        headerName: "Overland Water Zone",
        fieldName: "overland_water_zone",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "sewer_back",
        headerName: "Sewer Back",
        fieldName: "sewer_back",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "will_receive_letter",
        headerName: "Will Receive Letter",
        fieldName: "will_receive_letter",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "liability_limit",
        headerName: "Liability Limit",
        fieldName: "liability_limit",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "accident_forgiveness",
        headerName: "Accident Forgiveness",
        fieldName: "accident_forgiveness",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "hnw",
        headerName: "HNW",
        fieldName: "hnw",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "province",
        headerName: "Province",
        fieldName: "province",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "mortage",
        headerName: "Mortgage",
        fieldName: "mortage",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "grc",
        headerName: "GRC",
        fieldName: "grc",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "earthquake",
        headerName: "Earthquake",
        fieldName: "earthquake",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "fsa",
        headerName: "FSA",
        fieldName: "fsa",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "high_risk",
        headerName: "High Risk",
        fieldName: "high_risk",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "tag",
        headerName: "Tag",
        fieldName: "tag",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "install_vpn",
        headerName: "Install VPN",
        fieldName: "install_vpn",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "surcharge",
        headerName: "Surcharge",
        fieldName: "surcharge",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "surchage_code",
        headerName: "Surcharge Code",
        fieldName: "surchage_code",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "loss_year",
        headerName: "Loss Year",
        fieldName: "loss_year",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "date_of_loss",
        headerName: "Date of Loss",
        fieldName: "date_of_loss",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "claim_number",
        headerName: "Claim Number",
        fieldName: "claim_number",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "case_type_of_loss",
        headerName: "Case Type of Loss",
        fieldName: "case_type_of_loss",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "gross_insured_claims",
        headerName: "Gross Insured Claims",
        fieldName: "gross_insured_claims",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "net_insured_claims",
        headerName: "Net Insured Claims",
        fieldName: "net_insured_claims",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "gross_current",
        headerName: "Gross Current",
        fieldName: "gross_current",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
    ],
    rows: [],
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
    onSelection: (isAllSelected: boolean, rows: IProject[]) => {
      setSelectedProjects({ isAllSelected: isAllSelected, Invoice: rows });
    },
    tableTabProps: {
      selectedTab: tabs.selectedTab,
      tabs: tabs.tabs,
      onTabChange: setSelectedTab,
    },
  };

  const handleProjects = () => {
    navigate("/projects/manage-project");
  };

  React.useEffect(() => {
    dispatch(
      fetchRecordCountsAsync(
        "latest_customer_all_detailed_record",
        "status",
        dateState.dates.fromDate,
        dateState.dates.toDate
      )
    );
    return () => {
      dispatch(clearRecordCounts());
    };
  }, [dateState.dates.fromDate, dateState.dates.toDate]);

  React.useEffect(() => {
    const tabsData = createTabsWithRecordcounts(
      [
        {
          label: "Active",
          value: "ACTIVE",
          variant: "warning",
        },

        { label: "Inactive", value: "INACTIVE", variant: "error" },
      ],
      recordsCounts
    );

    setTableTabs(tabsData);
  }, [recordsCounts]);

  return (
    <PageContainer title="View Projects" description="this is invoicing list">
      <Breadcrumb title="View Projects" items={BCrumb} />

      <PageTitleBar
        heading="Projects"
        rightHeading={
          <Button
            variant="contained"
            onClick={handleProjects}
            startIcon={<AddOutlined />}
          >
            Create
          </Button>
        }
      />
      {/* <DataTable {...InvoiceTableProps} /> */}

      <DataTableV2 {...tableV2} />
    </PageContainer>
  );
};
