import produce from "immer";
import { BusinesPolicyInterestedActions } from ".";
import { IStoreState } from "../../initialStoreState";
import { defaultBusinessPolicyInterestedState } from "./businessPolicyInterested.types";
import { CLEAR_BUSINES_POLICY_INTERESTED, CLEAR_BUSINES_POLICY_INTERESTED_STATE, FETCH_BUSINESS_POLICY_INTERESTED_FAILED, FETCH_BUSINESS_POLICY_INTERESTED_LIST_FAILED, FETCH_BUSINESS_POLICY_INTERESTED_LIST_PROGRESS, FETCH_BUSINESS_POLICY_INTERESTED_LIST_SUCCESS, FETCH_BUSINESS_POLICY_INTERESTED_PROGRESS, FETCH_BUSINESS_POLICY_INTERESTED_SUCCESS } from "./businessPolicyInterestedActions";
import { LoadState } from "../../../constants/enums";

export const businessPolicyInterestedReducer = (
  state: IStoreState["business"]["interested"] = defaultBusinessPolicyInterestedState,
  action: BusinesPolicyInterestedActions
) => {
    switch (action.type) {
        case FETCH_BUSINESS_POLICY_INTERESTED_LIST_PROGRESS: {
          const newState = produce(state, (draftState) => {
            draftState.loading = LoadState.InProgress;
            draftState.data = [];
          });
          return newState;
        }
        case FETCH_BUSINESS_POLICY_INTERESTED_LIST_SUCCESS: {
          const { data } = action.payload;
          const newState = produce(state, (draftState) => {
            draftState.loading = LoadState.Loaded;
            draftState.data = data;
          });
          return newState;
        }
        case FETCH_BUSINESS_POLICY_INTERESTED_LIST_FAILED: {
          const newState = produce(state, (draftState) => {
            draftState.loading = LoadState.Failed;
            draftState.data = [];
          });
          return newState;
        }

        case FETCH_BUSINESS_POLICY_INTERESTED_PROGRESS: {
          const newState = produce(state, (draftState) => {
            draftState.intrestLoading = LoadState.InProgress;
            draftState.interest = defaultBusinessPolicyInterestedState['interest'];
          });
          return newState;
        }
        case FETCH_BUSINESS_POLICY_INTERESTED_SUCCESS: {
          const { data } = action.payload;
          const newState = produce(state, (draftState) => {
            draftState.intrestLoading = LoadState.Loaded;
            draftState.interest = data;
          });
          return newState;
        }
        case FETCH_BUSINESS_POLICY_INTERESTED_FAILED: {
          const {errorMessage} = action.payload;
          const newState = produce(state, (draftState) => {
            draftState.intrestLoading = LoadState.Failed;
            draftState.error = errorMessage
          });
          return newState;
        }
       
        case CLEAR_BUSINES_POLICY_INTERESTED: {
          const newState = produce(state, (draftState) => {
            draftState.interest = defaultBusinessPolicyInterestedState["interest"];
          });
          return newState;
        }
        case CLEAR_BUSINES_POLICY_INTERESTED_STATE: {
          return defaultBusinessPolicyInterestedState;
        }
        default: {
          return state;
        }
      }
};
