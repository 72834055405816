import { IStoreState } from "../../initialStoreState";
import { BusinesPolicyRiskLocationActions } from ".";
import { defaultBusinessPolicyRiskLocationState } from "./businessPolicyRiskLocation.types";
import {
  CLEAR_BUSINES_POLICY_RISK_LOCATION,
  CLEAR_BUSINES_POLICY_RISK_LOCATION_STATE,
  FETCH_BUSINESS_POLICY_RISK_LOCATION_FAILED,
  FETCH_BUSINESS_POLICY_RISK_LOCATION_LIST_FAILED,
  FETCH_BUSINESS_POLICY_RISK_LOCATION_LIST_PROGRESS,
  FETCH_BUSINESS_POLICY_RISK_LOCATION_LIST_SUCCESS,
  FETCH_BUSINESS_POLICY_RISK_LOCATION_PROGRESS,
  FETCH_BUSINESS_POLICY_RISK_LOCATION_SUCCESS,
} from "./businessPolicyRiskLocationActions";
import produce from "immer";
import { LoadState } from "../../../constants/enums";

export const businessPolicyRiskLocationReducer = (
  state: IStoreState["business"]["riskLocation"] = defaultBusinessPolicyRiskLocationState,
  action: BusinesPolicyRiskLocationActions
) => {
  switch (action.type) {
    case FETCH_BUSINESS_POLICY_RISK_LOCATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_RISK_LOCATION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_RISK_LOCATION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.data = [];
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_RISK_LOCATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.risklocationLoading = LoadState.InProgress;
        draftState.risklocation = defaultBusinessPolicyRiskLocationState['risklocation'];
        draftState.error = null;
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_RISK_LOCATION_SUCCESS: {
      const {data} = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.risklocationLoading = LoadState.Loaded;
        draftState.risklocation = data
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_RISK_LOCATION_FAILED: {
      const {errorMessage} = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.risklocationLoading = LoadState.Failed;
        draftState.risklocation = defaultBusinessPolicyRiskLocationState['risklocation'];
        draftState.error = errorMessage;
      });
      return newState;
    }
    
  
    case CLEAR_BUSINES_POLICY_RISK_LOCATION: {
      const newState = produce(state, (draftState) => {
        draftState.risklocation =
          defaultBusinessPolicyRiskLocationState["risklocation"];
      });
      return newState;
    }
    case CLEAR_BUSINES_POLICY_RISK_LOCATION_STATE: {
      return defaultBusinessPolicyRiskLocationState;
    }
    default: {
      return state;
    }
  }
};
