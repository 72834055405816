/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { StandadCard } from "../../../../../../components/Cards";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { LoadState, MODULE_IDS } from "../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../hooks";
import { useFormik } from "formik";
import { Box, Button, Grid, Stack, Typography, Checkbox } from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../components/formsComponents";
import { IBreadcrumbProps } from "../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useNavigate, useParams } from "react-router-dom";
import { CustomDatePicker } from "../../../../../../components/formsComponents/CustomDatePicker";
import moment from "moment";
import { useBreadcrumbContext } from "../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { renderDropdownValue } from "../../../../../../helpers";
import { CurrencyTextField } from "../../../../../../components/CurrencyTextField/CurrencyTextField";
import { RoleBasedCustomButton } from "../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { addHabitationalConsentFormAsync, clearHabitationalConsentForm, fetchHabitationalConsentFormAsync } from "../../../../../../redux/habitationalPolicy/HabitationalPolicyForm/HabitationalPolicyFormActions";

export const HabitationalConsentForm: React.FC<{isDuplicate?: boolean}> = (props) => {
  const { customerId, customerPolicyId, serialNumber } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
    serialNumber?: number;
  };

  const { isDuplicate } = props;
  const { addBreadcrumb } = useBreadcrumbContext();

  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      to: "/view-customers",
      title: "customers",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer-overview",
    },
    {
      title: "consent-form",
    },
  ];

  React.useEffect(() => {
    addBreadcrumb({ title: "", items: BCrumb });
    return () => {
      dispatch(clearHabitationalConsentForm());
    };
  }, []);

  const { consent, consentLoading, error } = useSelector(
    (storeState: IStoreState) => storeState.habitational.policyform.consentform
  );
  const customerInfo = useSelector(
    (storeState: IStoreState) =>
      storeState.habitational.basicDetails.customerInfo.data
  );
  const [saveLoading, setSaveLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: consent,
    validate: (values) => { },
    onSubmit: (values) => {
      if (customerPolicyId && customerId) {
        setSaveLoading(true);
        dispatch(
          addHabitationalConsentFormAsync(
            {
              ...values,
              customer_policy_id: customerPolicyId,
            },
            (isSuccess) => {
              if (isSuccess) {
                window.scrollTo(0, 0);
                navigate(
                  `/habitational-policy/${customerId}/policy-forms-tab/${customerPolicyId}?tab=2 `
                );
              }
              setSaveLoading(false);
            },
            isDuplicate,
          )
        );
      }
    },
  });

  React.useEffect(() => {
    if (customerPolicyId && serialNumber) {
      dispatch(fetchHabitationalConsentFormAsync(customerPolicyId, serialNumber));
    }
  }, [customerPolicyId, serialNumber]);

  React.useEffect(() => {
    if (!serialNumber) {
      setValues({
        ...consent,
        customer_name: customerInfo.customer_name,
        // policy_number: customerInfo.policy_number,
      });
    } else {
      setValues(consent);
    }
  }, [consent, customerInfo]);

  React.useEffect(() => {
    setFieldValue("customer_name", customerInfo.customer_name);
    // setFieldValue("policy_number", customerInfo.policy_number);
    // setFieldValue("policy_status", customerInfo.policy_status);
  }, [customerInfo])

  React.useEffect(() => {
    return () => {
      dispatch(clearHabitationalConsentForm());
    };
  }, []);

  return (
    <PageLoader
      loading={consentLoading === LoadState.InProgress}
      error={error ? { message: error } : undefined}
    >
      <StandadCard sx={{ mt: 2 }} heading="Privacy Consent">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Customer Name</CustomFormLabel>
              <CustomTextField
                name="customer_name"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                disabled
                value={values.customer_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Email Address</CustomFormLabel>
              <CustomTextField
                name="email_address"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.email_address}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Text </CustomFormLabel>
              <CustomTextField
                name="text_field"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.text_field}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Status</CustomFormLabel>
              <ControlledCustomSelect
                name="status"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                displayEmpty
                placeholder="Select One"
                options={[
                  { label: "Active", value: "ACTIVE" },
                  { label: "Inactive", value: "INACTIVE" },
                ].map((data) => {
                  return { label: data.label, value: data.value };
                })}
                value={values.status}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: 4 }}>
            <RoleBasedCustomButton
              sx={{ width: "15%" }}
              disabled={saveLoading}
              variant="contained"
              moduleId={MODULE_IDS.COMMERCIAL_AUTO}
              type="submit"
            >
              Save
            </RoleBasedCustomButton>
          </Box>
        </form>
      </StandadCard>
      {/* </CustomerBaiscDetailsLayout> */}
    </PageLoader>
  );
};
