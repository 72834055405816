/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { useDispatchWrapper, useQueryParms } from "../../../../../hooks";
import { fetchCustomerInfoAsync } from "../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";
import { PageLoader } from "../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../constants/enums";
import { StandadCard } from "../../../../../components/Cards";
import { TabsList } from "../../../../../components/Tabs/TabsList";
import { AccountCircle, AdminPanelSettings } from "@mui/icons-material";
import { TabPanel } from "../../../../../components/Tabs/TabPanel";
import { useNavigate, useOutletContext } from "react-router-dom";
import { CSIOCertificateList } from "./CSIOCertificate/CSIOCertificateList";
import { PropertyInsuranceCertificateList } from "./PropertyInsuranceCertificate/PropertyInsuranceCertificateList";
import { MarketingList } from "./Marketing/Marketing";
import { BinderList } from "./Binder/BinderList";
import { IBreadcrumbProps } from "../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useBreadcrumbContext } from "../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { clearBusinessPolicyContractor } from "../../../../../redux/businessPolicy/businessPolicyContractor/businessPolicyContractorActions";

export const MarketingRoot = () => {
  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerPolicyId = data[1];
  const { getParm } = useQueryParms();
  const tabValue = getParm("tab");
  const selectedTab = tabValue ? Number(tabValue) : 0;
  const { addBreadcrumb } = useBreadcrumbContext();
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/policies/",
      title: "all-policies ",
    },
    {
      to: "/policies/Business%20Policy",
      title: "business",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer",
    },
    {
      title: selectedTab === 0 ? 'marketing' : 'binder',
    },
  ];
  const { customerInfo } = useSelector(
    (storeState: IStoreState) => storeState.business.basicDetails
  );
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(
      `/business-policy/${customerId}/marketing-tab/${customerPolicyId}?tab=${newValue}`
    );
  };
  React.useEffect(() => {
    addBreadcrumb({ title: "", items: BCrumb });
    return () => {
      dispatch(clearBusinessPolicyContractor());
    };
  }, [tabValue]);

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  return (
    <PageLoader
      loading={customerInfo.loading === LoadState.InProgress}
      error={customerInfo.error ? { message: customerInfo.error } : null}
    >
      <StandadCard>
        <TabsList
          sx={{ mb: 1 }}
          varaint="fullWidth"
          selectedTab={selectedTab}
          tabs={[
            {
              label: "Marketing",
              icon: <AccountCircle />,
            },
            {
              label: "Binder",
              icon: <AdminPanelSettings />,
            },
          ]}
          onChange={handleChange}
        />
        <TabPanel value={selectedTab} index={0}>
          <MarketingList
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <BinderList
          />
        </TabPanel>

      </StandadCard>
    </PageLoader>
  );
};
