import { useNavigate, useOutletContext } from "react-router-dom";
import { NotesList } from "../../Notes/NotesList";

export const BusinessNoteFormList = () => {
  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerPolicyId = data[1];

  const navigate = useNavigate();
  const handleAddEditClick = (incrementalNumber: number | null = null) => {
    if (incrementalNumber !== null) {
      navigate(
        `/business-policy/${customerId}/notes-tab/${customerPolicyId}/note/${incrementalNumber}`
      );
    } else {
      navigate(
        `/business-policy/${customerId}/notes-tab/${customerPolicyId}/note`
      );
    }
  };

  return (
    <NotesList
      customerId={customerId}
      customerPolicyId={customerPolicyId}
      onAddEditClick={handleAddEditClick}
    />
  );
};
