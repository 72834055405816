import React from "react";
import { ICSIOMapRightPanelProps } from "./CSIOMapRightPanel.types";
import { RightPanel } from "../../components/RightPanel";
import { useDispatchWrapper } from "../../hooks";
import { clearCSIORawData, fetchCSIOAsync } from "../../redux/csio/csioActions";
import { IStoreState } from "../../redux/initialStoreState";
import { useSelector } from "react-redux";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CustomTextField } from "../../components/formsComponents";
import { PageLoader } from "../../components/PageLoader/PageLoader";
import { LoadState } from "../../constants/enums";

export const CSIOMapRightPanel: React.FC<ICSIOMapRightPanelProps> = (props) => {
  const { open, messageGUID, onClose } = props;

  const { rawData, rawDataLoading, error } = useSelector(
    (storeState: IStoreState) => storeState.csio
  );

  const [state, setState] = React.useState<
    { key: string; value: string | null }[]
  >([]);

  const dispatch = useDispatchWrapper();

  React.useEffect(() => {
    function parseJSONToKeyValuePairs(jsonData: any) {
      const keyValuePairs: { key: string; value: string | null }[] = [];

      // Helper function to recursively traverse the JSON object
      function traverse(obj: any) {
        for (const key in obj) {
          if (key === "Limit") {
            console.log(key, obj);
          }
          if (
            typeof obj[key] === "object" &&
            obj[key] !== null &&
            !Array.isArray(obj[key])
          ) {
            keyValuePairs.push({ key: key, value: null });
            traverse(obj[key]);
          } else if (Array.isArray(obj[key])) {
            for (const arrayItem of obj[key]) {
              for (const objItemKey in arrayItem) {
                if (
                  typeof arrayItem[objItemKey] === "object" &&
                  arrayItem[key] !== null &&
                  !Array.isArray(arrayItem[key])
                ) {
                  keyValuePairs.push({ key: objItemKey, value: null });
                  traverse(obj[key]);
                } else {
                  keyValuePairs.push({
                    key: objItemKey,
                    value: arrayItem[objItemKey],
                  });
                }
              }
            }
          } else {
            keyValuePairs.push({ key: key, value: obj[key] });
          }
        }
      }

      traverse(jsonData);
      return keyValuePairs;
    }

    if (rawData && rawData.xml_json_message) {
      const finalParsedData = parseJSONToKeyValuePairs(
        JSON.parse(rawData.xml_json_message)
      );
      finalParsedData.splice(0, 1);
      setState(finalParsedData);
    }
  }, [rawData]);

  React.useEffect(() => {
    if (messageGUID) {
      dispatch(fetchCSIOAsync(messageGUID));
    }
  }, [messageGUID]);

  React.useEffect(() => {
    return () => {
      dispatch(clearCSIORawData());
    };
  }, []);

  return (
    <RightPanel
      heading="XML Map"
      //subHeading="Add notes or comments"
      open={open}
      onClose={onClose}
      width="50%"
    >
      <PageLoader loading={rawDataLoading === LoadState.InProgress}>
        <Table sx={(theme) => ({ backgroundColor: theme.palette.grey[100] })}>
          <TableHead>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableHead>
          <TableBody>
            {state.map((item) => {
          
              return (
                <TableRow key={item.key}>
                  <TableCell
                    sx={
                      !item.value
                        ? {
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                            color: "primary.main",
                          }
                        : { fontWeight: 600, fontSize: "1rem" }
                    }
                  >
                    {item.key}
                  </TableCell>
                  <TableCell
                    sx={{ width: "95%", backgroundColor: "#fff", padding: 2 }}
                  >
                    {item.value && item.value}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </PageLoader>{" "}
    </RightPanel>
  );
};

// const RAW_DATA = {
//   "acord:CommonSvcRs": {
//     $: {
//       "xmlns:acord": "http://www.ACORD.org/standards/PC_Surety/ACORD1/xml/",
//       "xmlns:csio": "http://www.CSIO.org/standards/PC_Surety/CSIO1/xml/",
//       "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
//     },
//     "acord:RqUID": "3b658ca3-2165-43af-8fd1-1c599adb7946",
//     "acord:SPName":
//       "www.economical.com ffhfhfhfhfhfgfhfhgfhffhfgfgfgdf dgddgdgdg",
//     "acord:MsgSeqNumber": "000001",
//     "acord:ActivityNoteRs": {
//       "acord:RqUID": "3b658ca3-2165-43af-8fd1-1c599adb7946",
//       "acord:ItemIdInfo": {
//         "acord:OtherIdentifier": [
//           {
//             "acord:OtherIdTypeCd": "csio:MachineAddress",
//             "acord:OtherId": "IBM905AAXELI",
//           },
//           {
//             "acord:OtherIdTypeCd": "csio:CsioNetId",
//             "acord:OtherId": "BE3528A@BROKER.EDI.CSIO.COM",
//           },
//         ],
//       },
//       "acord:TransactionResponseDt": {
//         _: "2024-05-27",
//         $: {
//           id: "ID0003032",
//         },
//       },
//       "acord:TransactionEffectiveDt": {
//         _: "2024-05-27",
//         $: {
//           id: "ID0003033",
//         },
//       },
//       "acord:MsgStatus": {
//         "acord:MsgStatusCd": "Success",
//       },
//       "acord:TransactionSeqNumber": "0001",
//       "acord:InsuredOrPrincipal": {
//         "acord:GeneralPartyInfo": {
//           "acord:NameInfo": {
//             "acord:CommlName": {
//               "acord:CommercialName": "Ashish Jain",
//             },
//           },
//         },
//       },
//       "acord:PartialPolicy": {
//         "acord:PolicyNumber": "502075586",
//         "acord:LOBCd": "csio:AUTO",
//         "acord:ContractTerm": {
//           "acord:EffectiveDt": "2024-05-16",
//           "acord:ExpirationDt": "2025-05-16",
//         },
//         "acord:ItemIdInfo": {
//           "acord:AgencyId": "6240",
//         },
//         "csio:CompanyCd": "ECON",
//       },
//       "acord:BusinessPurposeTypeCd": "csio:PCH",
//       "acord:FileAttachmentInfo": {
//         "acord:AttachmentTypeCd": "csio:DEC",
//         "acord:AttachmentDesc": "BROKER_DEC_PCH Policy # 502075586",
//         "acord:MIMEContentTypeCd": "application/pdf",
//         "acord:MIMEEncodingTypeCd": "BASE64",
//         "acord:AttachmentFilename": "502075586-DEC-20240527-66281324.pdf",
//         "acord:DocumentCopyTypeCd": "B",
//       },
//       "csio:RemarksInfo": {
//         "acord:RemarkText":
//           "Please find enclosed the broker copy of the policy Declaration Page, for your records.",
//       },
//     },
//   },
// };
