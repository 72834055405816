import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TabsList } from "../../components/Tabs/TabsList";
import { ContactPage, TrackChanges } from "@mui/icons-material";
import { TabPanel } from "../../components/Tabs/TabPanel";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { StandadCard } from "../../components/Cards";
import { ManageCSIO } from "./ManageCSIO";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { IStoreState } from "../../redux/initialStoreState";
import { clearCSIOState, fetchCSIOAsync } from "../../redux/csio/csioActions";
import { useDispatchWrapper } from "../../hooks";
import { CsioPotentialMatches } from "./CSIOPotentialMatches";
import { Divider, Tab, Tabs } from "@mui/material";
import { a11yProps } from "../../components/Tabs/utils";
import { AccountCircle, AdminPanelSettings } from "@mui/icons-material";
import { PageContainer } from "../../components/container/PageContainer";
import { IBreadcrumbProps } from "../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { Breadcrumb } from "../../layout/fullLayout/breadcrumb/Breadcrumb";

export const ManageCSIOTabs: React.FC = () => {
  const { id } = useParams();
  const [tab, setTab] = useState<number>(0);
  const handleChangetab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  const dispatch = useDispatchWrapper();
  const { rawData, rawDataLoading, error } = useSelector(
    (storeState: IStoreState) => storeState.csio
  );

  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      to: `/csio`,
      title: "csio",
    },
    {
      title: tab === 0 ? "Read Messages" : "Potential Matches",
    },
  ];

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: rawData,
    validate: (values) => {
      const errors: any = {};

      return errors;
    },
    onSubmit: (values) => {},
  });

  React.useEffect(() => {
    if (id) {
      dispatch(fetchCSIOAsync(id));
    }
  }, [id]);

  React.useEffect(() => {
    setValues(rawData);
  }, [rawData]);

  React.useEffect(() => {
    return () => {
      dispatch(clearCSIOState());
    };
  }, []);
  return (
    <PageContainer title="Read Messages" description="this is inner page">
      <Breadcrumb title="All application" items={BCrumb} />
      <StandadCard>
        <Tabs
          value={tab}
          variant="standard"
          scrollButtons="auto"
          onChange={handleChangetab}
        >
          <Tab
            icon={<AccountCircle />}
            iconPosition="start"
            label="Read Messages"
            {...a11yProps(0)}
          />
          <Tab
            sx={{ minHeight: "50px" }}
            icon={<AdminPanelSettings />}
            iconPosition="start"
            label="Potential Matches"
            {...a11yProps(1)}
          />
        </Tabs>
        <Divider />
        <TabPanel value={tab} index={0}>
          <ManageCSIO values={values} rawDataLoading={rawDataLoading} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <CsioPotentialMatches
            values={values}
            rawDataLoading={rawDataLoading}
          />
        </TabPanel>
      </StandadCard>
    </PageContainer>
  );
};
