import { Box, Button, Grid, Stack } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";

import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import {
  LoadState,
  MODULE_IDS,
  USER_ROLES,
} from "../../../../../../../constants/enums";
import { InsurerCodeAutoSearch } from "../../../../../../../components/AutoCompleteSearches";
import { IHabitationalPolicyBasicDetailsProps } from "../HabitationalBasicDetails.types";
import {
  addHabitationalPolicyAdminsync,
  addHabitationalPremiumAsync,
  fetchHabitationalPolicyAdminAsync,
  fetchHabitationalPremiumAsync,
} from "../../../../../../../redux/habitationalPolicy/habitationalBasicDetails/habitationalBasicDetailsActions";
import { ComissionCodeSelect } from "../../../../../../../components/AutoCompleteSearches/ComissionCodeSelect";
import { UsersAutoSearchByRole } from "../../../../../../../components/AutoCompleteSearches/UsersAutoSearchByRole";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { useTabsUncontrolledContext } from "../../../../../../../contexts/TabsContextProvider/TabsContextUncontrolledProvider";
import produce from "immer";
import { batch } from "react-redux";
import {
  agency_direct_bill,
  payment_plan,
} from "../../../../../../../constants/constants";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import { CurrencyTextField } from "../../../../../../../components/CurrencyTextField/CurrencyTextField";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import { IHabitationalPremiumPaymentSchedule } from "../../../../../../../redux/habitationalPolicy/habitationalApplication/habitationalApplicationNew.types";
import { CustomDatePicker } from "../../../../../../../components/formsComponents/CustomDatePicker";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import { showMessage } from "../../../../../../../redux/messages/messagesActions";

export const HabitationalPolicyAdmin: React.FC<
  IHabitationalPolicyBasicDetailsProps
> = (props) => {
  const { customerpolicyId } = props;
  const { data, loading } = useSelector(
    (storeState: IStoreState) =>
      storeState.habitational.basicDetails.policyAdmin
  );
  const { data: premiumData, loading: premiumLoading } = useSelector(
    (storeState: IStoreState) => storeState.habitational.basicDetails.premium
  );

  const dispatch = useDispatchWrapper();
  const { customerProspect } = useSelector(
    (storeState: IStoreState) => storeState.customer
  );
  const [buttonClicked, setButtonClicked] = React.useState<string | null>(null);
  const { changeStep } = useTabsUncontrolledContext();

  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    errors,
    submitForm,
  } = useFormik({
    initialValues: data,
    validate: (values) => {
      const errors: any = {};
      if (!values.producer_one) {
        errors.producer_one = "Producer 1 is requried";
      }
      return errors;
    },
    onSubmit: async (values) => {
      return new Promise((resolve, reject) => {
        dispatch(
          addHabitationalPolicyAdminsync(
            {
              ...values,
              customer_policy_id: customerpolicyId,
            },
            (isSuccess, error) => {
              if (error) {
                reject(error);
              }
              resolve(isSuccess);
            }
          )
        );
      });
    },
  });

  const formikPremium = useFormik({
    initialValues: premiumData,
    validate: (values) => {
      const errors: any = {};
      return errors;
    },
    onSubmit: async (values) => {
      return new Promise((resolve, reject) => {
        dispatch(
          addHabitationalPremiumAsync(
            {
              ...values,
              customer_policy_id: customerpolicyId,
            },
            (isSuccess, error) => {
              if (error) {
                reject(error);
              }
              resolve(isSuccess);
            }
          )
        );
      });
    },
  });

  const updatePaymentScheduleTextChange = (
    key: string,
    rowIndex: number,
    value: any
  ) => {
    const newValues = produce(
      formikPremium.values.payment_schedule,
      (draftValues) => {
        //@ts-ignore
        draftValues[rowIndex][key] = value;
      }
    );
    formikPremium.setFieldValue("payment_schedule", newValues);
  };

  const handlePaymentScheduleTextChange =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updatePaymentScheduleTextChange(key, rowIndex, e.target.value);
    };

    const handlePaymentScheduleDateChange =
    (key: string, rowIndex: number) =>
    (value: any) => {
      updatePaymentScheduleTextChange(key, rowIndex, value);
    };

  const handleFinalSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Submit Premium form and wait for success callback
    try {
      const adminSubmitSuccess = await submitForm();
      const premiumSubmitSuccess = await formikPremium.submitForm();

      // Check if both forms' API calls succeeded
      if (adminSubmitSuccess && premiumSubmitSuccess) {
        dispatch(
          showMessage({
            type: "success",
            message: "Policy admin saved successfully!",
            displayAs: "snackbar",
          })
        );
        if (buttonClicked === "saveAndNext") {
          changeStep(2); // Move to the next step if both forms succeed
        }
      }
    } catch (error: any) {
      dispatch(
        showMessage({
          type: "error",
          message: error.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

  React.useEffect(() => {
    batch(() => {
      dispatch(fetchHabitationalPolicyAdminAsync(customerpolicyId));
      dispatch(fetchHabitationalPremiumAsync(customerpolicyId));
    });
  }, []);

  React.useEffect(() => {
    setValues({
      ...data,
      branch_code: data.branch_code || customerProspect.data.branch_code,
    });
  }, [data]);

  React.useEffect(() => {
    formikPremium.setValues(premiumData);
  }, [premiumData]);

  const paymentScheduleTableProps: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 5,
    uniqueRowKeyName: "id",
    columns: [
      {
        key: "installment_number",
        headerName: "Installement Number",
        fieldName: "installment_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IHabitationalPremiumPaymentSchedule) => {
          return (
            <CustomTextField
              fullWidth
              value={row.installment_number}
              onChange={handlePaymentScheduleTextChange(
                "installment_number",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "installment_amount",
        headerName: "Installment Amount",
        fieldName: "installment_amount",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IHabitationalPremiumPaymentSchedule) => {
          return (
            <CustomTextField
              fullWidth
              value={row.installment_amount}
              onChange={handlePaymentScheduleTextChange(
                "installment_amount",
                value.column.rowIndex
              )}
            />
          );
        },
      },

      {
        key: "installment_date",
        headerName: "Installment Date",
        fieldName: "installment_date",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IHabitationalPremiumPaymentSchedule) => {
          return (
            <CustomDatePicker
              fullWidth
              value={row.installment_date}
              // onChange={updatePaymentScheduleTextChange(
              //   "installment_date",
              //   index,
              // )}
              onChange={handlePaymentScheduleDateChange(
                "installment_date",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: formikPremium.values.payment_schedule || [],
  };
  const normalizeValue = (backendValue: any) => {
    if (agency_direct_bill[1].value.includes(backendValue)) {
      return "D";
    }
    return backendValue;
  };

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      <form onSubmit={handleFinalSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Insurer</CustomFormLabel>
            <InsurerCodeAutoSearch
              value={values.insurer}
              onSelect={(value) => {
                setFieldValue("insurer", value.value);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Comission Code</CustomFormLabel>
            <ComissionCodeSelect
              insurerCode={values.insurer}
              value={values.comission_code}
              onChange={(value) => {
                setFieldValue("comission_code", value);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Branch Code</CustomFormLabel>
            <CustomTextField fullWidth value={values.branch_code} disabled />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Policy Fee</CustomFormLabel>
            <CustomTextField
              name="policy_fee"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={values.policy_fee}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              label="Producer 1"
              value={values.producer_one}
              onSelect={(data) => {
                setFieldValue("producer_one", data.user_fact_id);
                setFieldValue("producer_one_code", data.user_code);
              }}
              errorMessage={errors.producer_one}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              label="Producer 2"
              value={values.producer_two}
              onSelect={(data) => {
                setFieldValue("producer_two", data.user_fact_id);
                setFieldValue("producer_two_code", data.user_code);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              label="Producer 3"
              value={values.producer_three}
              onSelect={(data) => {
                setFieldValue("producer_three", data.user_fact_id);
                setFieldValue("producer_three_code", data.user_code);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              value={values.marketer}
              label="Marketer"
              onSelect={(data) => {
                setFieldValue("marketer", data.user_fact_id);
                setFieldValue("marketer_code", data.user_code);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              value={values.csr}
              label="CSR"
              onSelect={(data) => {
                setFieldValue("csr", data.user_fact_id);
                setFieldValue("csr_code", data.user_code);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Billing Account Number</CustomFormLabel>
            <CustomTextField
              name="billing_account_number"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.billing_account_number}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Broker Code</CustomFormLabel>
            {/* <BranchCodeAutoSearch
                value={values.broker_code}
                onSelect={(value) => {
                  setFieldValue("broker_code", value.value);
                }}
              /> */}
            <CustomTextField
              name="broker_code"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.broker_code}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <CustomFormLabel>Broker Sub Code</CustomFormLabel>
            {/* <BranchCodeAutoSearch
                value={values.broker_code}
                onSelect={(value) => {
                  setFieldValue("broker_code", value.value);
                }}
              /> */}
            <CustomTextField
              name="broker_sub_code"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.broker_sub_code}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <LabelCard heading="Premium" />
        <Grid container spacing={1}>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Policy Premium</CustomFormLabel>
            <CurrencyTextField
              fullWidth
              value={formikPremium.values.policy_premium}
              onChange={(value) => {
                formikPremium.setFieldValue("policy_premium", value);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Agency or Direct Bill</CustomFormLabel>

            <ControlledCustomSelect
              fullWidth
              value={normalizeValue(
                formikPremium.values.agency_bill_or_direct_bill
              )}
              name="agency_bill_or_direct_bill"
              onChange={formikPremium.handleChange}
              placeholder="Select One"
              displayEmpty
              options={agency_direct_bill.map((option: any) => ({
                label: option.label,
                value: option.label,
              }))}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Payment Plan</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={formikPremium.values.payment_plan}
              name="payment_plan"
              onChange={formikPremium.handleChange}
              placeholder="Select one"
              displayEmpty
              options={payment_plan.map((template) => {
                return { label: template, value: template };
              })}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Premium Finance Contract Number</CustomFormLabel>
            <CustomTextField
              name="premium_finance_contract_number"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={formikPremium.values.premium_finance_contract_number}
              onChange={formikPremium.handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Premium Financed?</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={formikPremium.values.premium_finance}
              name="premium_finance"
              onChange={formikPremium.handleChange}
              placeholder="Select one"
              displayEmpty
              options={[
                { label: "Yes", value: "YES" },
                { label: "No", value: "NO" },
              ]}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Financed By</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={formikPremium.values.financed_by}
              name="financed_by"
              onChange={formikPremium.handleChange}
              placeholder="Select one"
              displayEmpty
              options={["IFS", "CAFO", "First Insurance Funding", "SNAP"].map(
                (template) => {
                  return { label: template, value: template };
                }
              )}
            ></ControlledCustomSelect>
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Defaut Tax Region</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={formikPremium.values.default_tax_region}
              name="default_tax_region"
              onChange={formikPremium.handleChange}
              placeholder="Select One"
              displayEmpty
              options={[
                "AB",
                "BC",
                "MB",
                "NB",
                "NL",
                "NS",
                "NT",
                "NU",
                "ON",
                "PE",
                "QC",
                "SK",
                "YT",
              ].map((template) => {
                return { label: template, value: template };
              })}
            ></ControlledCustomSelect>
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Tax 1</CustomFormLabel>
            <CustomTextField
              name="tax_one"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={formikPremium.values.tax_one}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={formikPremium.handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Tax 2</CustomFormLabel>
            <CustomTextField
              name="tax_two"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={formikPremium.values.tax_two}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={formikPremium.handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel> Tax Exempt </CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={formikPremium.values.tax_exempt}
              name="tax_exempt"
              onChange={formikPremium.handleChange}
              placeholder="Select one"
              displayEmpty
              options={["Y", "N"].map((template) => {
                return { label: template, value: template };
              })}
            ></ControlledCustomSelect>
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Tax Exempt Reason</CustomFormLabel>
            <CustomTextField
              name="reason"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={formikPremium.values.reason}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={formikPremium.handleChange}
            />
          </Grid>

          <Grid xs={12} marginTop={2}>
            <DataTable {...paymentScheduleTableProps} />
          </Grid>
        </Grid>

        <Stack direction={"row"} spacing={2} sx={{ mt: 4 }}>
          <RoleBasedCustomButton
            type="button"
            moduleId={MODULE_IDS.HABITATIONAL}
            variant="contained"
            onClick={() => changeStep(1)}
          >
            Back
          </RoleBasedCustomButton>
          <RoleBasedCustomButton
            type="submit"
            moduleId={MODULE_IDS.HABITATIONAL}
            variant="contained"
            onClick={() => setButtonClicked("save")}
          >
            Save
          </RoleBasedCustomButton>
          <RoleBasedCustomButton
            type="submit"
            moduleId={MODULE_IDS.HABITATIONAL}
            variant="contained"
            onClick={() => setButtonClicked("saveAndNext")}
          >
            Save & Next
          </RoleBasedCustomButton>
        </Stack>
      </form>
    </PageLoader>
  );
};
