import { LoadState } from "../../constants/enums";
import { getUniqueId } from "../../helpers";

export interface IAutomobileCSIOEDIApplicationState {
  list: IAutomobileCSIOEDIApplication[];
  edi: IAutomobileCSIOEDIApplication;
  totalRecords: number;
  loading: LoadState;
  error: string | null;
}

export interface IAutoDescribedSectionOne {
  key: string;
  autoNo: string | null;
  modelYear: string | null;
  makeTradeName: string | null;
  model: string | null;
  bodyType: string | null;
  noCylinders: string | null;
  grossVehicleWeight: string | null;
}

export interface IAutoDescribedSectionTwo {
  key: string;
  autoNo: string | null;
  vinNumber: string | null;
  owned: string | null;
  leased: string | null;
  purchaseLeasedDate: string | null;
  purchaseLeased: string | null;
  purchasePrice: string | null;
  automobileUsed: string | null;
  automobileUsedOneWay: string | null;
  automobileUsedPrecentage: string | null;
}

export interface IAutoDescribedSectionThree {
  key: string;
  autoNo: string | null;
  annualDrivingDistance: number | null;
  automobileUsedForPooling: string | null;
  automobileUsedForPoolingIfYes: string | null;
  typefFuelUsed: string | null;
  typefFuelUsedIfYes: string | null;
  unrepairedDamage: string | null;
  modifiedCustomized: string | null;
}

export interface IAutoDescribedSectionFour {
  key: string;
  autoNo: string;
  lienholderName: string;
  postalAddress: string;
}

export interface IAutoDriverInformationOne {
  key: string;
  driverNo: string | null;
  nameofDriver: string | null;
  driverLicenseNumber: string | null;
  dateOfBirth: string;
  sex: string;
  maritalStatus: string;
}
export interface IAutoDriverInformationTwo {
  key: string;
  driverNo: string | null;
  driverTrainingCertificateAttached: string | null;
  dateFirstLicencedInCanadaorUSClass: string | null;
  dateFirstLicencedInCanadaorUSYear: string | null;
  otherClassofLicenseIfAnyClass: string | null;
  otherClassofLicenseIfAnyYear: string | null;
  percentageUseByEachDriverAuto1: string | null;
  percentageUseByEachDriverAuto2: string | null;
  percentageUseByEachDriverAuto3: string | null;
  areAnyOtherPersonsInTheHousehold: {
    questionCd: string | null;
    yesNoCd: string | null;
  };
  doAnyDriversQualifyForRetireeDiscount: {
    questionCd: string | null;
    yesNoCd: string | null;
  };
}

export interface IAutoPreviousClaims {
  key: string;
  driverNo: string | null;
  autoNo: string | null;
  date: string | null;
  coverageClaimPaidUnder: {
    bi: string | null;
    pd: string | null;
    Ab: string | null;
    dcpd: string | null;
    ua: string | null;
    col: string | null;
    comp: string | null;
  };
  amountPaidOrEstimate: string | null;
  details: string | null;
}

export interface IAutoHistory {
  key: string;
  driverNo: string | null;
  dateConvicted: string | null;
  details: string;
}

export interface IAutoRatingInformationOne {
  key: string;
  autoNo: string | null;
  class: string | null;
  drivingRecord: string | null;
  bi: string | null;
  pd: string | null;
  ab: string | null;
  dcpd: string | null;
  coll_ap: string | null;
  driverNoPrimary: string | null;
  driverNoSceondary: string | null;
  atFaultClaimSurcharges: string | null;
  atFaultClaimPrecentage: string | null;
  convictionsSurcharges: string | null;
  convictionsPrecentage: string | null;
}
export interface IAutoRatingInformationTwo {
  key: string;
  autoNo: string | null;
  listPriceView: string | null;
  vehicleCode: string | null;
  ratingGroupAb: string | null;
  ratingGroupDcpd: string | null;
  ratingGroupCollAp: string | null;
  ratingGroupcompSp: string | null;
  ratinratingGroupDcpd: string | null;
  location: string | null;
  territory: string | null;
  discountDescription: string | null;
  discountPrecentage: string | null;
}

export interface IAutomobilePremiumPaymentSchedule {
    id: string;
    installment_number: string;
    installment_amount: string;
    installment_date: string;
  }

export interface IAutoInsuranceApplied {
  key: string;
  code: string;
  label: string;
  description: string;
  isTypeOther: boolean;
  automobiles: {
    [key: string]: {
      limit: string | null;
      premimum: string | null;
      deductible: string | null;
      isOnlyPremimum: boolean;
      
    };
  };
}

export interface IAutomobileCSIOEDIApplication {
  customer_policy_id: number | null;
  incremental_number: number | null;
  policy_number_assigned: string | null;
  replacing_policy_no: string | null;
  new_policy: string | null;
  billing_method: string | null;
  language_preferred: string | null;
  policy_period_effective_date: string | null;
  policy_period_effective_time: string | null;
  policy_period_expiry_date: string | null;
  policy_period_expiry_time: string | null;
  insurance: {
    insurance_name: string | null;
    insurance_address: string | null;
    insurance_unit_or_suite: string | null;
    insurance_city: string | null;
    insurance_state: string | null;
    insurance_country: string | null;
    insurance_zip: string | null;
  };
  broker: {
    broker_agent: string | null;
    broker_agent_address: string | null;
    broker_agent_unit_or_suite: string | null;
    broker_agent_city: string | null;
    broker_agent_state: string | null;
    broker_agent_country: string | null;
    broker_agent_zip: string | null;
    broker_code: string | null;
    broker_sub_code: string | null;
  };
  application_details: {
    name: string | null;
    address: string | null;
    address_two: string | null;
    country: string | null;
    city: string | null;
    state: string | null;
    postalcode: string | null;
    phone: string | null;
    mobile: string | null;
  };
  lessor: {
    lessor_name: string | null;
    lessor_full_address: string | null;
    lessor_phone: string | null;
    lessor_email: string | null;
  };
  described_automobiles: {
    described_automobiles_part_one: IAutoDescribedSectionOne[];
    described_automobiles_part_two: IAutoDescribedSectionTwo[];
    described_automobiles_part_three: IAutoDescribedSectionThree[];
    described_automobiles_part_four: IAutoDescribedSectionFour[];
    owner_actual_owner_yesNoCd: string | null;
    total_automobiles_in_business: string | null;
    rented_or_leased_to_other: string | null;
    carry_passengers_for_compensation: string | null;
    haul_a_trailer: string | null;
    carry_explosives: string | null;
  };
  driver_information: {
    section_one: IAutoDriverInformationOne[];
    section_two: IAutoDriverInformationTwo[];
    insurance_company: string | null;
    prior_policy_number: string | null;
    expiry_date: string | null;
    has_any_driver_license: string | null;
    has_any_insurance_company: string | null;
    during_the_last_three_years: string | null;
    has_the_applicant_or_any_listed_driver: string | null;
  };
  previous_accidents_and_insurance_claims: IAutoPreviousClaims[];
  history_of_convictions: IAutoHistory[];
  rating_information: {
    section_one: IAutoRatingInformationOne[];
    section_two: IAutoRatingInformationTwo[];
  };
  payment_reporter_of_broker: {
    type_of_payment_plan: string | null;
    estimated_policy_premium: string | null;
    provincial_sales_tax: string | null;
    interest_amount: string | null;
    total_estimated_cost: string | null;
    amount_paid_with_application: string | null;
    amount_due: string | null;
    no_of_remaining_installments: string | null;
    amount_of_each_installment: string | null;
    installment_due_date: string | null;
    have_you_bound_the_risk: string | null;
    is_this_business_new_to_you: string | null;
    type_of_motor_vehicle_liability_insurance_card: string | null;
    how_long_you_have_known_the_applicant: string | null;
    how_long_you_have_known_the_principal_driver: string | null;
    has_an_inspection_been_done: string | null;
  };
  payment_schedule: IAutomobilePremiumPaymentSchedule[];
  incurance_coverage_applied_for: {
    columns: string[];
    rows: IAutoInsuranceApplied[];
  }
  remarks: string | null;
  status: string | null;
}


export const initialAutomobileCSIOEDIApplicationState: IAutomobileCSIOEDIApplicationState =
  {
    edi: {
      customer_policy_id: null,
      incremental_number: null,
      policy_number_assigned: null,
      replacing_policy_no: null,
      new_policy: null,
      billing_method: null,
      language_preferred: null,
      policy_period_effective_date: null,
      policy_period_effective_time: null,
      policy_period_expiry_date: null,
      policy_period_expiry_time: null,
      insurance: {
        insurance_name: null,
        insurance_address: null,
        insurance_unit_or_suite: null,
        insurance_city: null,
        insurance_state: null,
        insurance_country: null,
        insurance_zip: null,
      },
      broker: {
        broker_agent: null,
        broker_agent_address: null,
        broker_agent_unit_or_suite: null,
        broker_agent_city: null,
        broker_agent_state: null,
        broker_agent_country: null,
        broker_agent_zip: null,
        broker_code: null,
        broker_sub_code: null,
      },
      application_details: {
        name: null,
        address: null,
        address_two: null,
        country: null,
        city: null,
        state: null,
        postalcode: null,
        phone: null,
        mobile: null,
      },
      lessor: {
        lessor_name: null,
        lessor_full_address: null,
        lessor_phone: null,
        lessor_email: null,
      },
      described_automobiles: {
        described_automobiles_part_one: [
          {
            key: getUniqueId(),
            autoNo: null,
            modelYear: null,
            makeTradeName: null,
            model: null,
            bodyType: null,
            noCylinders: null,
            grossVehicleWeight: null,
          },
        ],
        described_automobiles_part_two: [
          {
            key: getUniqueId(),
            autoNo: null,
            vinNumber: null,
            owned: null,
            leased: null,
            purchaseLeasedDate: null,
            purchaseLeased: null,
            purchasePrice: null,
            automobileUsed: null,
            automobileUsedOneWay: null,
            automobileUsedPrecentage: null,
          },
        ],
        described_automobiles_part_three: [
          {
            key: getUniqueId(),
            autoNo: null,
            annualDrivingDistance: null,
            automobileUsedForPooling: null,
            automobileUsedForPoolingIfYes: null,
            typefFuelUsed: null,
            typefFuelUsedIfYes: null,
            unrepairedDamage: null,
            modifiedCustomized: null,
          },
        ],
        described_automobiles_part_four: [
          {
            key: getUniqueId(),
            autoNo: "",
            lienholderName: "",
            postalAddress: "",
          },
        ],
        owner_actual_owner_yesNoCd: null,
        total_automobiles_in_business: null,
        rented_or_leased_to_other: null,
        carry_passengers_for_compensation: null,
        haul_a_trailer: null,
        carry_explosives: null,
      },
      driver_information: {
        section_one: [
          {
            key: getUniqueId(),
            driverNo: null,
            nameofDriver: null,
            driverLicenseNumber: null,
            dateOfBirth: "",
            sex: "",
            maritalStatus: "",
          },
        ],
        section_two: [
          {
            key: getUniqueId(),
            driverNo: null,
            driverTrainingCertificateAttached: null,
            dateFirstLicencedInCanadaorUSClass: null,
            dateFirstLicencedInCanadaorUSYear: null,
            otherClassofLicenseIfAnyClass: null,
            otherClassofLicenseIfAnyYear: null,
            percentageUseByEachDriverAuto1: null,
            percentageUseByEachDriverAuto2: null,
            percentageUseByEachDriverAuto3: null,
            areAnyOtherPersonsInTheHousehold: {
              questionCd: null,
              yesNoCd: null,
            },
            doAnyDriversQualifyForRetireeDiscount: {
              questionCd: null,
              yesNoCd: null,
            },
          },
        ],
        insurance_company: null,
        prior_policy_number: null,
        expiry_date: null,
        has_any_driver_license: null,
        has_any_insurance_company: null,
        during_the_last_three_years: null,
        has_the_applicant_or_any_listed_driver: null,
      },
      previous_accidents_and_insurance_claims: [
        {
          key: getUniqueId(),
          driverNo: null,
          autoNo: null,
          date: null,
          coverageClaimPaidUnder: {
            bi: null,
            pd: null,
            Ab: null,
            dcpd: null,
            ua: null,
            col: null,
            comp: null,
          },
          amountPaidOrEstimate: null,
          details: null,
        }
      ],
      history_of_convictions: [
        {
          key: getUniqueId(),
          driverNo:null,
          dateConvicted:null,
          details: "",
        }
      ],
      rating_information: {
        section_one: [
          {
            key: getUniqueId(),
            autoNo: null,
            class: null,
            drivingRecord: null,
            bi: null,
            pd: null,
            ab: null,
            dcpd: null,
            coll_ap: null,
            driverNoPrimary: null,
            driverNoSceondary: null,
            atFaultClaimSurcharges: null,
            atFaultClaimPrecentage: null,
            convictionsSurcharges: null,
            convictionsPrecentage: null,
          },     
        ],
        section_two: [
          {
            key: getUniqueId(),
            autoNo: null,
            listPriceView: null,
            vehicleCode: null,
            ratingGroupAb: null,
            ratingGroupDcpd: null,
            ratingGroupCollAp: null,
            ratingGroupcompSp: null,
            ratinratingGroupDcpd: null,
            location: null,
            territory: null,
            discountDescription: null,
            discountPrecentage: null,
          }
        ],
      },
      payment_reporter_of_broker: {
        type_of_payment_plan: null,
        estimated_policy_premium: null,
        provincial_sales_tax: null,
        interest_amount: null,
        total_estimated_cost: null,
        amount_paid_with_application: null,
        amount_due: null,
        no_of_remaining_installments: null,
        amount_of_each_installment: null,
        installment_due_date: null,
        have_you_bound_the_risk: null,
        is_this_business_new_to_you: null,
        type_of_motor_vehicle_liability_insurance_card: null,
        how_long_you_have_known_the_applicant: null,
        how_long_you_have_known_the_principal_driver: null,
        has_an_inspection_been_done: null,
      },
      payment_schedule: [
        {
          id: getUniqueId(),
          installment_number: "",
          installment_amount: "",
          installment_date: "",
        },
      ],
      incurance_coverage_applied_for: {
        columns: [],
        rows: [
          {
            key: getUniqueId(),
            code: '',
            label: '',
            description: '',
            isTypeOther: false,
            automobiles: {
              "": {
                limit: null,
                premimum: null,
                deductible: null,
                isOnlyPremimum: false,
              },
            },
          },
        ]
      },
      remarks: null,
      status : null,
    },
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  };
