import { MoreHoriz } from "@mui/icons-material";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Popover,
  Tooltip,
} from "@mui/material";
import React from "react";
import { IContextMenuProps, IMenuOption } from "./interfaces/IContextMenuProps";

export const ContextMenu: React.FC<IContextMenuProps> = (props) => {
  const { menuOptions } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleMenuItemClick = (menuItem: IMenuOption) => () => {
    if (menuItem.onClick) {
      menuItem.onClick();
      setAnchorEl(null);
    }
  };

  return (
    <>
      <Tooltip title="More">
        <MoreHoriz color="primary" onClick={handleClick} />
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: { minWidth: "200px" },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Properties
            </ListSubheader>
          }
        >
          {menuOptions.map((menuItem) => {
            return (
              <ListItemButton
                disabled={menuItem.disabled}
                key={menuItem.label}
                onClick={handleMenuItemClick(menuItem)}
              >
                <ListItemIcon sx={{ mr: 1 }}>{menuItem.icon}</ListItemIcon>
                <ListItemText primary={menuItem.label} />
              </ListItemButton>
            );
          })}
        </List>
      </Popover>
    </>
  );
};
