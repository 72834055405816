import produce from "immer";
import { BusinessConsentFormActions } from ".";
import { IStoreState } from "../../initialStoreState";
import { initialBusinessConsentFormState } from "./businessConsentForm.types";
import { CLEAR_BUSINESS_CONSENT_FORM, FETCH_BUSINESS_CONSENT_FORM_FAILED, FETCH_BUSINESS_CONSENT_FORM_LIST_FAILED, FETCH_BUSINESS_CONSENT_FORM_LIST_PROGRESS, FETCH_BUSINESS_CONSENT_FORM_LIST_SUCCESS, FETCH_BUSINESS_CONSENT_FORM_PROGRESS, FETCH_BUSINESS_CONSENT_FORM_SUCCESS } from "./businessConsentFormActions";
import { LoadState } from "../../../constants/enums";

export const BusinessConsentFormReducer = (
  state: IStoreState["business"]["privacyConsent"] = initialBusinessConsentFormState,
  action: BusinessConsentFormActions
) => {
  switch (action.type) {
    case FETCH_BUSINESS_CONSENT_FORM_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.consentLoading = LoadState.InProgress;
        draftState.consent =
          initialBusinessConsentFormState["consent"];
      });
      return newState;
    }
     case FETCH_BUSINESS_CONSENT_FORM_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.consentLoading = LoadState.Loaded;
        draftState.consent = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_CONSENT_FORM_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.consentLoading = LoadState.Failed;
        draftState.consent =
        initialBusinessConsentFormState["consent"];
        draftState.error = errorMessage;
      });
      return newState;
    }
    case FETCH_BUSINESS_CONSENT_FORM_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }
    case FETCH_BUSINESS_CONSENT_FORM_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_CONSENT_FORM_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.list = [];
      });
      return newState;
    }

    case CLEAR_BUSINESS_CONSENT_FORM: {
      const newState = produce(state, (draftState) => {
        draftState.consentLoading = LoadState.NotLoaded;
        draftState.consent =
        initialBusinessConsentFormState["consent"];
        draftState.error = null;
      });
      return newState;
    }
    default: {
      return state;
    }
  }
}