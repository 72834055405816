import { useNavigate, useOutletContext} from "react-router-dom";
import { NotesList } from "../../Notes/NotesList";

export const CommAutoNoteFormList = () => {
    const data = useOutletContext() as any;
    const customerId = data[0];
    const customerPolicyId = data[1];

    const navigate = useNavigate();
    const handleAddEditClick = (incrementalNumber: number | null = null) => {
        if (incrementalNumber !== null) {
            navigate(
                `/comm-automobile-policy/${customerId}/notes-tab/${customerPolicyId}/note/${incrementalNumber}`
            );
        } else {
            navigate(
                `/comm-automobile-policy/${customerId}/notes-tab/${customerPolicyId}/note`
            );
        }
    }

    return (
        <NotesList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
            onAddEditClick={handleAddEditClick}
        />
    )
}