import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IAutomobileGaragePlates } from "./automobileGaragePlates.types";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";

export const addAutomobileGaragePlatesAsync =
  (
    data: IAutomobileGaragePlates,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/policy/edit-policy-commauto-garage-plates", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Garage plate saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const FETCH_AUTOMOBILE_GARAGE_PLATES_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_GARAGE_PLATES_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_GARAGE_PLATES_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_GARAGE_PLATES_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_GARAGE_PLATES_LIST_FAILED =
  "FETCH_AUTOMOBILE_GARAGE_PLATES_LIST_FAILED";

export const fetchAutomobileGaragePlatesListProgress = () =>
  action(FETCH_AUTOMOBILE_GARAGE_PLATES_LIST_PROGRESS);

export const fetchAutomobileGaragePlatesListSuccess = (
  data: IAutomobileGaragePlates[]
) =>
  action(FETCH_AUTOMOBILE_GARAGE_PLATES_LIST_SUCCESS, {
    data,
  });
export const fetchAutomobileGaragePlatesListFailed = () =>
  action(FETCH_AUTOMOBILE_GARAGE_PLATES_LIST_FAILED);

export const fetchAutomobileGaragePlatesListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileGaragePlatesListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-garage-plates?customer_policy_id=${customerPolicyId}`
      );
      const data: IAutomobileGaragePlates[] = res.data.data;
      dispatch(fetchAutomobileGaragePlatesListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileGaragePlatesListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_GARAGE_PLATES_STATE =
  "CLEAR_AUTOMOBILE_GARAGE_PLATES_STATE";
export const clearAutomobileGaragePlatesState = () =>
  action(CLEAR_AUTOMOBILE_GARAGE_PLATES_STATE);
