
import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialAutomobileCSIOApplicationState } from "./automobileCSIOApplication.types";
import { AutomobileCSIOApplicationActions } from ".";
import {
  CLEAR_AUTOMOBILE_CSIO_APPLICATION_STATE,
  FETCH_AUTOMOBILE_CSIO_APPLICATION_FAILED,
  FETCH_AUTOMOBILE_CSIO_APPLICATION_PROGRESS,
  FETCH_AUTOMOBILE_CSIO_APPLICATION_SUCCESSS,
  FETCH_AUTOMOBILE_CSIO_LIST_FAILED,
  FETCH_AUTOMOBILE_CSIO_LIST_PROGRESS,
  FETCH_AUTOMOBILE_CSIO_LIST_SUCCESS,
} from "./automobileCSIOApplicationActions";

export const AutomobileCSIOApplicationReducer = (
  state: IStoreState["automobile"]["csioapplication"] = initialAutomobileCSIOApplicationState,
  action: AutomobileCSIOApplicationActions
) => {
  switch (action.type) {
    case FETCH_AUTOMOBILE_CSIO_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_CSIO_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = list;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_CSIO_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    
    // case FETCH_AUTOMOBILE_CSIO_EDI_LIST_PROGRESS: {
    //   const newState = produce(state, (draftState) => {
    //     draftState.loading = LoadState.InProgress;
    //     draftState.list = [];
    //     draftState.totalRecords = 0;
    //   });
    //   return newState;
    // }
    // case FETCH_AUTOMOBILE_CSIO_EDI_LIST_SUCCESS: {
    //   const { list, totalRecords } = action.payload;
    //   const newState = produce(state, (draftState) => {
    //     draftState.loading = LoadState.Loaded;
    //     draftState.list = list;
    //     draftState.totalRecords = totalRecords;
    //   });
    //   return newState;
    // }
    // case FETCH_AUTOMOBILE_CSIO_EDI_LIST_FAILED: {
    //   const newState = produce(state, (draftState) => {
    //     draftState.loading = LoadState.Failed;
    //     draftState.list = [];
    //     draftState.totalRecords = 0;
    //   });
    //   return newState;
    // }

    case FETCH_AUTOMOBILE_CSIO_APPLICATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.error = null;
        draftState.csioData = initialAutomobileCSIOApplicationState["csioData"];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_CSIO_APPLICATION_SUCCESSS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.csioData = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_CSIO_APPLICATION_FAILED: {
      const { error } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = error || null;
        draftState.csioData = initialAutomobileCSIOApplicationState["csioData"];
      });
      return newState;
    }

    case CLEAR_AUTOMOBILE_CSIO_APPLICATION_STATE: {
      return initialAutomobileCSIOApplicationState;
    }

    default: {
      return state;
    }
  }
};
