import { LoadState } from "../../../constants/enums";

export interface IBusinessPolicyContractorState {
  data: IBusinessPolicyContractor[];
  loading: LoadState;
  contractor: IBusinessPolicyContractor;
  contractorLoading: LoadState;
  error: string | null;
}

export interface IBusinessPolicyContractor {
  customer_policy_id: number;
  customer_id: number;
  incremental_number: number | null;
  customer_name: string | null;
  policy_number: string | null;
  applicant_operating_under_the_present_name: string | null;
  previous_operating_name_if_any: string | null;
  years_of_experience_in_the_similar_operations: string | null;
  general_contractor: string | null;
  sub_contractor: string | null;
  independent_contractor: string | null;
  percentage_residential: string | null;
  percentage_commercial: string | null;
  percentage_industrial: string | null;
  describe_the_type_of_work_sublet: string | null;
  annual_cost_of_work_sublet: string | null;
  percentage_work_sublet: string | null;
  usa_operations: string | null;
  operations_other_than_usa_and_canada: string | null;
  applicant_enter_into_formal_contractual_agreements: string | null;
  sub_contractors_must_carry_cgl: string | null;
  minimum_limit_required: string | null;
  formal_contracts: string | null;
  certificate_of_insurance_secured: string | null;
  all_employees_covered_under_the_work_safety_insurance: string | null;
  employee_located_outside_canada: string | null;
  applicant_own_lease_or_operate_aircraft_and_or_watercraft: string | null;
  applicant_aware_of_any_incident: string | null;
  equipment_ever_rented: string | null;
  what_equipment: string | null;
  provide_details_in_applicants_operations: string | null;
  airports: string | null;
  nuclear_facilities: string | null;
  oil_fields_production: string | null;
  natural_gas_fields_production: string | null;
  provide_details_in_work_carried: string | null;
  absessos: string | null;
  blasting: string | null;
  bridges: string | null;
  caisson_work: string | null;
  chemicals: string | null;
  dams: string | null;
  demolition_wrecking: string | null;
  excavation: string | null;
  explosives: string | null;
  fire_restoration_work: string | null;
  gases: string | null;
  hot_tarring: string | null;
  land_clearing: string | null;
  liquified_petroleum: string | null;
  natural_gas: string | null;
  oil_fields: string | null;
  pesticides: string | null;
  pile_driving: string | null;
  radioactive_materials: string | null;
  raising_or_moving_structures: string | null;
  shoring: string | null;
  structural_steel: string | null;
  tunneling: string | null;
  underpinning: string | null;
  weilding: string | null;
  operations_performed_by_other_or_on_the_behalf_of_the_applicant:
    | string
    | null;
  provide_details_in_applicants_handle_use_perform_operations: string | null;
  status: string;
  policy_business_contractor_id:  number | null;
  contractor_submission_checkbox: string | null;
}

export const initialBusinessPolicyContractor: IBusinessPolicyContractor = {
  customer_policy_id: 0,
  customer_id: 0,
  incremental_number: null,
  customer_name: null,
  policy_number: null,
  applicant_operating_under_the_present_name: null,
  previous_operating_name_if_any: null,
  years_of_experience_in_the_similar_operations: null,
  general_contractor: null,
  sub_contractor: null,
  independent_contractor: null,
  percentage_residential: null,
  percentage_commercial: null,
  percentage_industrial: null,
  describe_the_type_of_work_sublet: null,
  annual_cost_of_work_sublet: null,
  percentage_work_sublet: null,
  usa_operations: null,
  operations_other_than_usa_and_canada: null,
  applicant_enter_into_formal_contractual_agreements: null,
  sub_contractors_must_carry_cgl: null,
  minimum_limit_required: null,
  formal_contracts: null,
  certificate_of_insurance_secured: null,
  all_employees_covered_under_the_work_safety_insurance: null,
  employee_located_outside_canada: null,
  applicant_own_lease_or_operate_aircraft_and_or_watercraft: null,
  applicant_aware_of_any_incident: null,
  equipment_ever_rented: null,
  what_equipment: null,
  provide_details_in_applicants_operations: null,
  airports: null,
  nuclear_facilities: null,
  oil_fields_production: null,
  natural_gas_fields_production: null,
  provide_details_in_work_carried: null,
  absessos: null,
  blasting: null,
  bridges: null,
  caisson_work: null,
  chemicals: null,
  dams: null,
  demolition_wrecking: null,
  excavation: null,
  explosives: null,
  fire_restoration_work: null,
  gases: null,
  hot_tarring: null,
  land_clearing: null,
  liquified_petroleum: null,
  natural_gas: null,
  oil_fields: null,
  pesticides: null,
  pile_driving: null,
  radioactive_materials: null,
  raising_or_moving_structures: null,
  shoring: null,
  structural_steel: null,
  tunneling: null,
  underpinning: null,
  weilding: null,
  operations_performed_by_other_or_on_the_behalf_of_the_applicant: null,
  provide_details_in_applicants_handle_use_perform_operations: null,
  status: "ACTIVE",
  policy_business_contractor_id: null,
  contractor_submission_checkbox: null,
};

export const initialBusinessPolicyContractorState: IBusinessPolicyContractorState =
  {
    data: [],
    loading: LoadState.NotLoaded,
    contractor: initialBusinessPolicyContractor,
    contractorLoading: LoadState.NotLoaded,
    error: null,
  };
