import { action } from "typesafe-actions";
import {
  ICompanyOnboarding,
  initialAccountingCompanyOnboardingState,
} from "./CompanyOnboarding.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";

export const FETCH_ACCOUNTING_COMPANY_ONBOARDING_PROGRESS =
  "FETCH_ACCOUNTING_COMPANY_ONBOARDING_PROGRESS";
export const FETCH_ACCOUNTING_COMPANY_ONBOARDING_SUCCESS =
  "FETCH_ACCOUNTING_COMPANY_ONBOARDING_SUCCESS";
export const FETCH_ACCOUNTING_COMPANY_ONBOARDING_FAILED =
  "FETCH_ACCOUNTING_COMPANY_ONBOARDING_FAILED";
export const CLEAR_ACCOUNTING_COMPANY_ONBOARDING =
  "CLEAR_ACCOUNTING_COMPANY_ONBOARDING";

export const fetchAccountingCompanyOnboardingProgress = () =>
  action(FETCH_ACCOUNTING_COMPANY_ONBOARDING_PROGRESS);
export const fetchAccountingCompanyOnboardingSuccess = (
  data: ICompanyOnboarding
) => action(FETCH_ACCOUNTING_COMPANY_ONBOARDING_SUCCESS, { data });
export const fetchAccountingCompanyOnboardingFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_COMPANY_ONBOARDING_FAILED, { errorMessage });
export const clearAccountingCompanyOnboarding = () =>
  action(CLEAR_ACCOUNTING_COMPANY_ONBOARDING);

export const fetchAccountingCompanyOnboardingAsync =
  (companyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingCompanyOnboardingProgress());

      const res = await api.get(
        `/accounting/get-account-company-onboarding?column=company_id&value=${companyId}`
      );
      const data: ICompanyOnboarding[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAccountingCompanyOnboardingSuccess(data[0]));
      } else {
        dispatch(
          fetchAccountingCompanyOnboardingSuccess(
            initialAccountingCompanyOnboardingState["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchAccountingCompanyOnboardingFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingCompanyOnboardingAysnc =
  (
    data: ICompanyOnboarding,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/accounting/edit-account-company-onboarding", {
        ...data,
      });
      let message = "Company Onboarding saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
