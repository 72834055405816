import { LoadState } from "../../constants/enums";

export interface IAutomobileGaragePlatesState {
    data: IAutomobileGaragePlates[];
    loading: LoadState;
  }

export interface IAutomobileGaragePlates {
    customer_policy_id: number | null;
    serial_number: number | null,
    type: string | null,
    plate_number: string | null,
    charge_details: string | null,
    assigned_to: string | null,
    attachment: string | null,
    status: "ACTIVE" | "PENDING";
  }

export const defaultAutomobileGaragePlatesState: IAutomobileGaragePlatesState =
  {
    data: [],
    loading: LoadState.NotLoaded,
  };

