import produce from "immer";
import { CSIOActions } from ".";
import { IStoreState } from "../initialStoreState";
import { initialCSIOState } from "./csio.types";
import {
  CLEAR_CSIO_RAW_DATA,
  CLEAR_CSIO_STATE,
  FETCH_CSIO_FAILED,
  FETCH_CSIO_LIST_FAILED,
  FETCH_CSIO_LIST_PROGRESS,
  FETCH_CSIO_LIST_SUCCESS,
  FETCH_CSIO_PROGRESS,
  FETCH_CSIO_SUCCESS,
} from "./csioActions";
import { LoadState } from "../../constants/enums";

export const csioReducer = (
  state: IStoreState["csio"] = initialCSIOState,
  action: CSIOActions
) => {
  switch (action.type) {
    case FETCH_CSIO_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_CSIO_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = list;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_CSIO_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_CSIO_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.rawDataLoading = LoadState.InProgress;
        draftState.rawData = initialCSIOState["rawData"];
      });
      return newState;
    }
    case FETCH_CSIO_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.rawDataLoading = LoadState.Loaded;
        draftState.rawData = data;
      });
      return newState;
    }
    case FETCH_CSIO_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.rawDataLoading = LoadState.Failed;
        draftState.rawData = initialCSIOState["rawData"];
        draftState.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_CSIO_STATE: {
      return initialCSIOState;
    }

    case CLEAR_CSIO_RAW_DATA: {
      const newState = produce(state, (draftState) => {
        draftState.rawDataLoading = LoadState.Failed;
        draftState.rawData = initialCSIOState["rawData"];
      });
      return newState;
    }

    default: {
      return state;
    }
  }
};
