import moment from "moment";
import { LoadState } from "../../constants/enums";

export interface ITaskPaymentMethodsState {
  paymentAuthorization: {
    data: ITaskPaymentAuth;
    loading: LoadState;
  };
}

export interface ITaskPaymentAuth {
  task_code: string;
  customer_code: string | null;
  customer_name: string;
  policy_number: number | null;
  line_of_business: string;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  pac_form: string;
  void_cheque: string;
  cc: string;
  cc_expiry: string;
  ccv: string;
  bank_name: string;
  bank_address: string;
  bank_unit_or_suite: string;
  bank_city: string;
  bank_province_or_state: string;
  bank_postal_code: string | null;
  bank_country: string;
  account_holder_name: string;
  account_holder_address: string;
  account_holder_unit_or_suite: string;
  account_holder_city: string;
  account_holder_province_or_city: string;
  account_holder_postal_code: string | null;
  account_holder_country: string;
  account_holder_transit_number: number | null;
  account_holder_institution_number: number | null;
  account_holder_account_number: number | null;
  effective_date_of_change: string;
  effective_time_of_change: string;
  details: string;
  file_upload: string | null;
}

export const initialPaymentMethodsState: ITaskPaymentMethodsState = {
  paymentAuthorization: {
    data: {
      task_code: "",
      customer_code: null,
      customer_name: "",
      policy_number: null,
      line_of_business: "",
      policy_type: "",
      branch: "",
      broker_code: null,
      insurer: "",
      producer: "",
      csr: "",
      pac_form: "",
      void_cheque: "",
      cc: "",
      cc_expiry: "",
      ccv: "",
      bank_name: "",
      bank_address: "",
      bank_unit_or_suite: "",
      bank_city: "",
      bank_province_or_state: "",
      bank_postal_code: null,
      bank_country: "",
      account_holder_name: "",
      account_holder_address: "",
      account_holder_unit_or_suite: "",
      account_holder_city: "",
      account_holder_province_or_city: "",
      account_holder_postal_code: null,
      account_holder_country: "",
      account_holder_transit_number: null,
      account_holder_institution_number: null,
      account_holder_account_number: null,
      effective_date_of_change: "",
      effective_time_of_change: moment()
      .startOf("day")
      .add(1, "minute")
      .toString(),
      details: "",
      file_upload: null,
    },
    loading: LoadState.NotLoaded,
  },
};
