import produce from "immer";
import { BusinesPolicyToolFloaterActions } from ".";
import { IStoreState } from "../../initialStoreState";
import { defaultBusinessPolicyToolFloaterState } from "./businesPolicyToolFloater.types";
import {
  CLEAR_BUSINES_POLICY_TOOL_FLOATER,
  CLEAR_BUSINES_POLICY_TOOL_FLOATER_STATE,
  FETCH_BUSINESS_POLICY_TOOL_FLOATER_FAILED,
  FETCH_BUSINESS_POLICY_TOOL_FLOATER_LIST_FAILED,
  FETCH_BUSINESS_POLICY_TOOL_FLOATER_LIST_PROGRESS,
  FETCH_BUSINESS_POLICY_TOOL_FLOATER_LIST_SUCCESS,
  FETCH_BUSINESS_POLICY_TOOL_FLOATER_PROGRESS,
  FETCH_BUSINESS_POLICY_TOOL_FLOATER_SUCCESS,
} from "./businessPolicyToolFloaterActions";
import { LoadState } from "../../../constants/enums";

export const businessPolicyToolFloaterReducer = (
  state: IStoreState["business"]["toolFloater"] = defaultBusinessPolicyToolFloaterState,
  action: BusinesPolicyToolFloaterActions
) => {
  switch (action.type) {
    case FETCH_BUSINESS_POLICY_TOOL_FLOATER_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_TOOL_FLOATER_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_TOOL_FLOATER_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_TOOL_FLOATER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.toolFloaterLoading = LoadState.InProgress;
        draftState.toolfloater =
          defaultBusinessPolicyToolFloaterState["toolfloater"];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_TOOL_FLOATER_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.toolFloaterLoading = LoadState.Loaded;
        draftState.toolfloater = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_TOOL_FLOATER_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.toolFloaterLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_BUSINES_POLICY_TOOL_FLOATER: {
      const newState = produce(state, (draftState) => {
        draftState.toolfloater =
          defaultBusinessPolicyToolFloaterState["toolfloater"];
      });
      return newState;
    }
    case CLEAR_BUSINES_POLICY_TOOL_FLOATER_STATE: {
      return defaultBusinessPolicyToolFloaterState;
    }
    default: {
      return state;
    }
  }
};
