/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../redux/initialStoreState";
import { useDispatchWrapper } from "./useDispatch";
import { LoadState } from "../constants/enums";
import { initialInsurerState } from "../redux/insurer/insurerState";
import { fetchInsurerCodeWithCallbackAsync } from "../redux/insurer/insurerActions";
import { initialBranchOfficeState } from "../redux/branchOffice/branchOfficeState";
import { fetchBranchOfficeWithCallback } from "../redux/branchOffice/branchOfficeActions";
import { fetchAutomobilePolicyAdminAsync } from "../redux/automobileBasicDetails/automobileBasicDetailsActions";

export const useAutomobilePolicyAdmin = (customerPolicyId?: number) => {
  const policyAdmin = useSelector(
    (storeState: IStoreState) => storeState.automobile.basicDetails.policyAdmin
  );
  const [insurer, setInsurer] = React.useState(
    initialInsurerState.insurerCode.data
  );
  const [branch, setBranch] = React.useState(
    initialBranchOfficeState.branchOffice.data
  );
  const dispatch = useDispatchWrapper();

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchAutomobilePolicyAdminAsync(customerPolicyId));
    }
  }, []);

  React.useEffect(() => {
    if (
      policyAdmin.data &&
      policyAdmin.loading === LoadState.Loaded &&
      policyAdmin.data.insurer
    ) {
      dispatch(
        fetchInsurerCodeWithCallbackAsync(policyAdmin.data.insurer, (data) => {
          if (data) {
            setInsurer(data);
          }
        })
      );
    }
  }, [policyAdmin]);

  React.useEffect(() => {
    if (
      policyAdmin.data &&
      policyAdmin.loading === LoadState.Loaded &&
      policyAdmin.data.branch_code
    ) {
      dispatch(
        fetchBranchOfficeWithCallback(policyAdmin.data.branch_code, (data) => {
          if (data) {
            setBranch(data);
          }
        })
      );
    }
  }, [policyAdmin]);

  return { policyAdmin, insurer, branch };
};
