import { action } from "typesafe-actions";
import { IBusinessPolicyHistory } from "./businessPolicyHistory.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { showMessage } from "../../messages/messagesActions";
import { api } from "../../../api/api";

export const FETCH_BUSINESS_POLICY_HISTORY_LIST_PROGRESS =
  "FETCH_BUSINESS_POLICY_HISTORY_LIST_PROGRESS";
export const FETCH_BUSINESS_POLICY_HISTORY_LIST_SUCCESS =
  "FETCH_BUSINESS_POLICY_HISTORY_LIST_SUCCESS";
export const FETCH_BUSINESS_POLICY_HISTORY_LIST_FAILED =
  "FETCH_BUSINESS_POLICY_HISTORY_LIST_FAILED";

export const fetchBusinessPolicyHistoryListProgress = () =>
  action(FETCH_BUSINESS_POLICY_HISTORY_LIST_PROGRESS);
export const fetchBusinessPolicyHistoryListSuccess = (
  data: IBusinessPolicyHistory[]
) => action(FETCH_BUSINESS_POLICY_HISTORY_LIST_SUCCESS, { data });
export const fetchBusinessPolicyHistoryListFailed = () =>
  action(FETCH_BUSINESS_POLICY_HISTORY_LIST_FAILED);

export const fetchBusinessPolicyHistoryListAsync =
  (
    customerPolicyId: number,
    tableName: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyHistoryListProgress());
      const res = await api.get(
        `/general/get-log-data?table_name=${tableName}&customer_policy_id=${customerPolicyId}`
      );
      const data: IBusinessPolicyHistory[] = res.data.data;
      dispatch(fetchBusinessPolicyHistoryListSuccess(data));
    } catch (err: any) {
      dispatch(fetchBusinessPolicyHistoryListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_BUSINESS_POLICY_HISTORY_STATE =
  "CLEAR_BUSINESS_POLICY_HISTORY_STATE";

export const clearBusinessPolicyHistoryState = () =>
  action(CLEAR_BUSINESS_POLICY_HISTORY_STATE);
