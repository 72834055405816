import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { api } from "../../../api/api";
import { IBusinessPolicyEquipment } from "./businessPolicyEquipment.types";
import { ERROR_MESSAGES } from "../../../constants/enums";

export const ADD_BUSINESS_POLICY_EQUIPMENT = "ADD_BUSINESS_POLICY_EQUIPMENT";
export const CLEAR_BUSINES_POLICY_EQUIPMENT_STATE =
  "CLEAR_BUSINES_POLICY_EQUIPMENT_STATE";
export const CLEAR_BUSINES_POLICY_EQUIPMENT = "CLEAR_BUSINES_POLICY_EQUIPMENT";
export const BUSINESS_POLICY_EQUIPMENT_NOT_FOUND =
  "BUSINESS_POLICY_EQUIPMENT_NOT_FOUND";

export const clearBusinessEquipmentRiskState = () =>
  action(CLEAR_BUSINES_POLICY_EQUIPMENT_STATE);

export const clearBusinessEquipment = () =>
  action(CLEAR_BUSINES_POLICY_EQUIPMENT);

export const FETCH_BUSINESS_POLICY_EQUIPMENT_LIST_PROGRESS =
  "FETCH_BUSINESS_POLICY_EQUIPMENT_LIST_PROGRESS";
export const FETCH_BUSINESS_POLICY_EQUIPMENT_LIST_SUCCESS =
  "FETCH_BUSINESS_POLICY_EQUIPMENT_LIST_SUCCESS";
export const FETCH_BUSINESS_POLICY_EQUIPMENT_LIST_FAILED =
  "FETCH_BUSINESS_POLICY_EQUIPMENT_LIST_FAILED";

export const fetchBusinessPolicyEquipmentListProgress = () =>
  action(FETCH_BUSINESS_POLICY_EQUIPMENT_LIST_PROGRESS);
export const fetchBusinessPolicyEquipmentListSuccess = (
  data: IBusinessPolicyEquipment[]
) => action(FETCH_BUSINESS_POLICY_EQUIPMENT_LIST_SUCCESS, { data });
export const fetchBusinessPolicyEquipmentListFailed = () =>
  action(FETCH_BUSINESS_POLICY_EQUIPMENT_LIST_FAILED);

export const fetchBusinessPolicyEquipmentListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyEquipmentListProgress());
      const res = await api.get(
        `/policy/get-policy-business-equipment?customer_policy_id=${customerPolicyId}`
      );
      const data: IBusinessPolicyEquipment[] = res.data.data;
      dispatch(fetchBusinessPolicyEquipmentListSuccess(data));
    } catch (err: any) {
      dispatch(fetchBusinessPolicyEquipmentListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addBusinesPolicyEquipmentnAsync =
  (
    data: IBusinessPolicyEquipment,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(`/policy/edit-policy-business-equipment`, {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Equipment saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_BUSINESS_POLICY_EQUIPMENT_PROGRESS =
  "FETCH_BUSINESS_POLICY_EQUIPMENT_PROGRESS";
export const FETCH_BUSINESS_POLICY_EQUIPMENT_SUCCESS =
  "FETCH_BUSINESS_POLICY_EQUIPMENT_SUCCESS";
export const FETCH_BUSINESS_POLICY_EQUIPMENT_FAILED =
  "FETCH_BUSINESS_POLICY_EQUIPMENT_FAILED";

export const fetchBusinessPolicyEquipmentProgress = () =>
  action(FETCH_BUSINESS_POLICY_EQUIPMENT_PROGRESS);
export const fetchBusinessPolicyEquipmentSuccess = (
  data: IBusinessPolicyEquipment
) => action(FETCH_BUSINESS_POLICY_EQUIPMENT_SUCCESS, { data });
export const fetchBusinessPolicyEquipmentFailed = (errorMessage: string) =>
  action(FETCH_BUSINESS_POLICY_EQUIPMENT_FAILED, { errorMessage });

export const fetchBusinessPolicyEquipmentAsync =
  (
    customerPolicyId: number,
    incrementNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyEquipmentProgress());
      const res = await api.get(
        `/policy/get-policy-business-equipment?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incrementNumber}`
      );
      const data: IBusinessPolicyEquipment[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBusinessPolicyEquipmentSuccess(data[0]));
      } else {
        dispatch(
          fetchBusinessPolicyEquipmentFailed(
            "Oops! We couldn't find any records associated with your equipment number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchBusinessPolicyEquipmentFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
