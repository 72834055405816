import produce from "immer";
import { BusinesPolicyUnderLyingActions } from ".";
import { IStoreState } from "../../initialStoreState";
import { defaultBusinessPolicyUnderLyingState } from "./businessPolicyUnderLyingInsurance.types";
import { CLEAR_BUSINES_POLICY_UNDERLYING, CLEAR_BUSINES_POLICY_UNDERLYING_STATE, FETCH_BUSINESS_POLICY_UNDERLYING_FAILED, FETCH_BUSINESS_POLICY_UNDERLYING_LIST_FAILED, FETCH_BUSINESS_POLICY_UNDERLYING_LIST_PROGRESS, FETCH_BUSINESS_POLICY_UNDERLYING_LIST_SUCCESS, FETCH_BUSINESS_POLICY_UNDERLYING_PROGRESS, FETCH_BUSINESS_POLICY_UNDERLYING_SUCCESS } from "./businessPolicyUnderLyingInsuranceActions";
import { LoadState } from "../../../constants/enums";

export const businessPolicUnderLyingReducer = (
  state: IStoreState["business"]["underLying"] = defaultBusinessPolicyUnderLyingState,
  action: BusinesPolicyUnderLyingActions
) => {
  switch (action.type) {
      case FETCH_BUSINESS_POLICY_UNDERLYING_LIST_PROGRESS: {
        const newState = produce(state, (draftState) => {
          draftState.loading = LoadState.InProgress;
          draftState.data = [];
        });
        return newState;
      }
      case FETCH_BUSINESS_POLICY_UNDERLYING_LIST_SUCCESS: {
        const { data } = action.payload;
        const newState = produce(state, (draftState) => {
          draftState.loading = LoadState.Loaded;
          draftState.data = data;
        });
        return newState;
      }
      case FETCH_BUSINESS_POLICY_UNDERLYING_LIST_FAILED: {
        const newState = produce(state, (draftState) => {
          draftState.loading = LoadState.Failed;
          draftState.data = [];
        });
        return newState;
      }

      case FETCH_BUSINESS_POLICY_UNDERLYING_PROGRESS: {
        const newState = produce(state, (draftState) => {
          draftState.underlyingLoading = LoadState.InProgress;
          draftState.underlying = defaultBusinessPolicyUnderLyingState['underlying'];
        });
        return newState;
      }
      case FETCH_BUSINESS_POLICY_UNDERLYING_SUCCESS: {
        const { data } = action.payload;
        const newState = produce(state, (draftState) => {
          draftState.underlyingLoading = LoadState.Loaded;
          draftState.underlying = data;
        });
        return newState;
      }
      case FETCH_BUSINESS_POLICY_UNDERLYING_FAILED: {
        const {errorMessage} = action.payload;
        const newState = produce(state, (draftState) => {
          draftState.underlyingLoading = LoadState.Failed;
          draftState.error = errorMessage;
        });
        return newState;
      }
   
     
      case CLEAR_BUSINES_POLICY_UNDERLYING: {
        const newState = produce(state, (draftState) => {
          draftState.underlying = defaultBusinessPolicyUnderLyingState["underlying"];
          draftState.error = null;
        });
        return newState;
      }
      case CLEAR_BUSINES_POLICY_UNDERLYING_STATE: {
        return defaultBusinessPolicyUnderLyingState;
      }
      default: {
        return state;
      }
    }
};
