import { action } from "typesafe-actions";

import { IDate } from "../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IEDocs } from "./eDocs.types";
export const FETCH_EDOCS_LIST_PROGRESS = "FETCH_EDOCS_LIST_PROGRESS";
export const FETCH_EDOCS_LIST_SUCCESS = "FETCH_EDOCS_LIST_SUCCESS";
export const FETCH_EDOCS_LIST_FAILED = "FETCH_EDOCS_LIST_FAILED";

export const fetchEDocsListProgress = () => action(FETCH_EDOCS_LIST_PROGRESS);
export const fetchEDocsListSuccess = (list: IEDocs[], totalRecords: number) =>
  action(FETCH_EDOCS_LIST_SUCCESS, { list, totalRecords });
export const fetchEDocsListFailed = () => action(FETCH_EDOCS_LIST_FAILED);

export const fetchEDocsListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[]
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchEDocsListProgress());
      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }

      let finalUrl = `/general/get-legal-plus?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;

      if (searchType.length > 0 && searchValue) {
        finalUrl = `/general/get-legal-plus?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&column=${[
          searchType,
        ]}&value=${searchValue}`;
      }
      const res = await api.get(finalUrl);
      const data: IEDocs[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchEDocsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchEDocsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_EDOCS_PROGRESS = "FETCH_EDOCS_PROGRESS";
export const FETCH_EDOCS_SUCCESS = "FETCH_EDOCS_SUCCESS";
export const FETCH_EDOCS_FAILED = "FETCH_EDOCS_FAILED";

export const fetchEDocsProgress = () => action(FETCH_EDOCS_PROGRESS);
export const fetchEDocsSuccess = (eDoc: IEDocs) =>
  action(FETCH_EDOCS_SUCCESS, { eDoc });
export const fetchEDocsFailed = (errorMessage: string) =>
  action(FETCH_EDOCS_FAILED, { errorMessage });

export const fetchEDocsAsync =
  (table_name?: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchEDocsProgress());

      const res = await api.get(`/general/get-legal-plus`);
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchEDocsSuccess(data[0]));
      } else {
        dispatch(
          fetchEDocsFailed(
            "Unfortunately, there are no records available at the moment."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchEDocsFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertEDocsAysnc =
  (
    eDoc: IEDocs,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/general/upsert-legal-plus", { ...eDoc });
      dispatch(
        showMessage({
          type: "success",
          message: "Legal is saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_EDOCS = "CLEAR_EDOCS";
export const clearEDocs = () => action(CLEAR_EDOCS);
