import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { AppRegistration } from "@mui/icons-material";
import { ContextMenu } from "../../../../../../components/ContextMenu/ContextMenu";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useDispatchWrapper } from "../../../../../../hooks";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { LoadState } from "../../../../../../constants/enums";
import { fetchHabitationalCoverageListAsync } from "../../../../../../redux/habitationalPolicy/habitationalCoverages/habitationalCoveragesActions";
import { IHabitationalCoverages } from "../../../../../../redux/habitationalPolicy/habitationalCoverages/habitationalCoverages.types";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";

export const HabitationalCoveragesList = () => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerPolicyId = data1[1];
  const navigate = useNavigate();
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.habitational.coverages
  );
  const dispatch = useDispatchWrapper();

  React.useEffect(() => {
    dispatch(fetchHabitationalCoverageListAsync(customerPolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerPolicyId]);

  const coverageTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    `/habitational-policy/${customerId}/habitational-coverages/${customerPolicyId}`
                  );
                }}
              >
                Add Coverages
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "view",
        headerName: "Actions",
        headingAlign: "center",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalCoverages) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/habitational-policy/${customerId}/habitational-coverages/${customerPolicyId}/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
      {
        key: "location_number",
        headerName: "Description",
        fieldName: "location_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IHabitationalCoverages) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/habitational-policy/${customerId}/habitational-coverages/${customerPolicyId}/${row.incremental_number}`
                )
              }
            >
              {row.incremental_number}
            </Link>
          );
        },
      },
      {
        key: "create_ts",
        headerName: "Created Date",
        fieldName: "create_ts",
        renderType: RenderType.DATE,
        exportCellWidth: 20,
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      
    ],
    items: data,
  };

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...coverageTableProps} />
      </Box>
    </>
  );
};
