import { LoadState } from "../../constants/enums";

export interface IReconciliationState {
  agencyBill: {
    list: IReconciliationAgencyBill[];
    totalRecords: number;
    loading: LoadState;
  };
  directBill: {
    list: IReconciliationDirectBill[];
    totalRecords: number;
    loading: LoadState;
  };
  customerPayable: {
    list: IReconciliationCustomerPayableBill[];
    totalRecords: number;
    loading: LoadState;
  }
}

export interface IReconciliationAgencyBill {
  accn_invoice_payment_transaction_items_id: number;
  invoice_id: number;
  invoice_number: string;
  accn_transaction_id: number;
  payment_number: string;
  entry_type: string;
  gl_accounts: string;
  invoice_payment_gl_account_name: string;
  date: string;
  batch_number: string;
  reciept_number: string;
  payment_for: string;
  name: string;
  code: string;
  policy_number: string;
  payment_type: string;
  payment_currency: string;
  payment_amount: string;
  service_fee: string | null;
  payment_method: string;
  bank: string;
  net_payable: string | null;
  total_due_dollar: string | null;
  amount_due: string | null;
  payment_paid: string | null;
  payment_id: string | null;
  total_paid: string | null;
  is_partial_payment: string | null;
  status: string | null;
  created_by_id: string | null;
  modified_by_id: 891;
  create_ts: string | null;
  insert_ts: string | null;
  actual_amount_due: string | null;
  invoice_due_date: string | null;
  transaction_type: string | null;
  gl_account_code: string | null;
  amount: string | null;
  payment_terms: string | null;
  policy_type: string | null;
  policy_transaction_type: string | null;
  policy_status: string | null;
  transaction_date: string | null;
  effective_date: string | null;
  default_tax_region: string | null;
  agency_or_direct_bill: string | null;
  premium_financed: string | null;
  financed_by: string | null;
  financed_fee: string | null;
  producer_one_id: string | null;
  producer_one_code: string | null;
  producer_two_id: string | null;
  producer_two_code: string | null;
  producer_three_id: string | null;
  producer_three_code: string | null;
  csr_code: string | null;
  branch_code: string | null;
  insurer_code: string | null;
  tax_one_percentage: string | null;
  tax_one_amount: string | null;
  tax_two_percentage: string | null;
  tax_two_amount: string | null;
  commission_percentage: string | null;
  commission_amount: string | null;
  house_percentage: string | null;
  house_amount: string | null;
  producer_one_percentage: string | null;
  producer_one_amount: string | null;
  producer_two_percentage: string | null;
  producer_two_amount: string | null;
  producer_three_percentage: string | null;
  producer_three_amount: string | null;
  branch_percentage: string | null;
  branch_amount: string | null;
  total_amount: string | null;
  invoice_total_due_dollar: string | null;
}

export interface IReconciliationDirectBill {
  accn_invoice_payment_transaction_items_id: number;
  invoice_id: number;
  invoice_number: string;
  accn_transaction_id: number;
  payment_number: string;
  entry_type: string;
  gl_accounts: string;
  invoice_payment_gl_account_name: string;
  date: string;
  batch_number: string;
  reciept_number: string;
  payment_for: string;
  name: string;
  code: string;
  policy_number: string;
  payment_type: string;
  payment_currency: string;
  payment_amount: string;
  service_fee: string | null;
  payment_method: string;
  bank: string;
  net_payable: string | null;
  total_due_dollar: string | null;
  amount_due: string | null;
  payment_paid: string | null;
  payment_id: string | null;
  total_paid: string | null;
  is_partial_payment: string | null;
  status: string | null;
  created_by_id: string | null;
  modified_by_id: 891;
  create_ts: string | null;
  insert_ts: string | null;
  actual_amount_due: string | null;
  invoice_due_date: string | null;
  transaction_type: string | null;
  gl_account_code: string | null;
  amount: string | null;
  payment_terms: string | null;
  policy_type: string | null;
  policy_transaction_type: string | null;
  policy_status: string | null;
  transaction_date: string | null;
  effective_date: string | null;
  default_tax_region: string | null;
  agency_or_direct_bill: string | null;
  premium_financed: string | null;
  financed_by: string | null;
  financed_fee: string | null;
  producer_one_id: string | null;
  producer_one_code: string | null;
  producer_two_id: string | null;
  producer_two_code: string | null;
  producer_three_id: string | null;
  producer_three_code: string | null;
  csr_code: string | null;
  branch_code: string | null;
  insurer_code: string | null;
  tax_one_percentage: string | null;
  tax_one_amount: string | null;
  tax_two_percentage: string | null;
  tax_two_amount: string | null;
  commission_percentage: string | null;
  commission_amount: string | null;
  house_percentage: string | null;
  house_amount: string | null;
  producer_one_percentage: string | null;
  producer_one_amount: string | null;
  producer_two_percentage: string | null;
  producer_two_amount: string | null;
  producer_three_percentage: string | null;
  producer_three_amount: string | null;
  branch_percentage: string | null;
  branch_amount: string | null;
  total_amount: string | null;
  invoice_total_due_dollar: string | null;
}

export interface IReconciliationCustomerPayableBill {
  accn_bill_transaction_items_id: number;
  customer_id: string | null;
  accn_transaction_id: number;
  detail: string | null;
  account: string | null;
  description: string | null;
  tax_percentage: string | null;
  tax_amount: string | null;
  amount: string | null;
  final_amount: string | null;
  vendor: string | null;
  code: string | null;
  vendor_type: string | null;
  gl_sales_account_name: string | null;
  category: string | null;
  bill_date: string | null;
  bill_id: string | null;
  bill_type: string | null;
  due_date: string | null;
  payment_terms: string | null;
  total_due_amount: string | null;
  status: string | null;
  created_by_id: number;
  modified_by_id: number;
  create_ts: string | null;
  insert_ts: string | null;
  first_name: string | null;
  last_name: string | null;
  personal_email: string | null;
  job_title: string | null;
  manager_id: string | null;
  user_type: string | null;
  assigned_phone_number: string | null;
  shared_email: string | null;
  mobile: string | null;
  home_phone: string | null;
  reports_to: string | null;
  phone_ext: string | null;
  linkedin_profile: string | null;
  hire_date: string | null;
  last_day_at_work: string | null;
  branch: string | null;
  department: string | null;
  fax: string | null;
  date_of_birth: string | null;
  sin: string | null;
  broker: string | null;
  mother_maiden_name: string | null;
  photo: string | null;
  signature: string | null;
  street_address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
  non_active_firm: string | null;
  non_active_firm_registered_with_ribo: string | null;
  non_active_firm_name: string | null;
  business_number: string | null;
  bank_name: string | null;
  bank: string | null;
  bank_number: string | null;
  transit: string | null;
  languages_known: string | null;
  documents: string | null;
  defaults_role: string | null;
  csr: string | null;
  csr_code: string | null;
  marketer: string | null;
  marketer_code: string | null;
  producer_one: string | null;
  producer_one_code: string | null;
  producer_two: string | null;
  producer_two_code: string | null;
  producer_three: string | null;
  producer_three_code: string | null;
  branch_code: string | null;
  branch_name: string | null;
  bonus_liability_code: string | null;
  bonus_expense_code: string | null;
  commission_liability_code: string | null;
  commission_expense_code: string | null;
  location_city: string | null;
}

export const initialReconciliationState: IReconciliationState = {
  agencyBill: {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
  directBill: {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
  customerPayable: {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  }
};
