import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { defaultAutomobileGarageApplicationState } from "./automobileGarageApplication.types";
import { CLEAR_AUTOMOBILE_GARAGE_APPLICATION_STATE, FETCH_AUTOMOBILE_GARAGE_APPLICATION_FAILED, FETCH_AUTOMOBILE_GARAGE_APPLICATION_PROGRESS, FETCH_AUTOMOBILE_GARAGE_APPLICATION_SUCCESSS } from "./automobileGarageApplicationActions";
import { AutomobileGarageApplicationActions } from ".";


export const AutomobileGarageApplicationReducer = (
  state: IStoreState["automobile"]["garageApplication"] = defaultAutomobileGarageApplicationState,
  action: AutomobileGarageApplicationActions
) => {
  switch (action.type) {
    case FETCH_AUTOMOBILE_GARAGE_APPLICATION_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.InProgress;
        draftState.data =
        defaultAutomobileGarageApplicationState;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_GARAGE_APPLICATION_SUCCESSS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_GARAGE_APPLICATION_FAILED: {
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.Failed;
        draftState.data =
        defaultAutomobileGarageApplicationState;
      });
      return newState;
    }

    case CLEAR_AUTOMOBILE_GARAGE_APPLICATION_STATE: {
      return defaultAutomobileGarageApplicationState;
    }

    default: {
      return state;
    }
  }
};
