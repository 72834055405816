import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import {
  ILifeInsuranceCoverageNeed,
  defaultLifeInsuranceCoverageNeed,
} from "./lifeInsuranceCoverageNeed.types";

export const FETCH_LIFE_INSURANCE_COVERAGE_NEED_PROGRESS =
  "FETCH_LIFE_INSURANCE_COVERAGE_NEED_PROGRESS";
export const FETCH_LIFE_INSURANCE_COVERAGE_NEED_SUCCESS =
  "FETCH_LIFE_INSURANCE_COVERAGE_NEED_SUCCESS";
export const FETCH_LIFE_INSURANCE_COVERAGE_NEED_FAILED =
  "FETCH_LIFE_INSURANCE_COVERAGE_NEED_FAILED";

export const fetchLifeInsuranceCoverageNeedProgress = () =>
  action(FETCH_LIFE_INSURANCE_COVERAGE_NEED_PROGRESS);
export const fetchLifeInsuranceCoverageNeedSuccess = (
  data: ILifeInsuranceCoverageNeed
) => action(FETCH_LIFE_INSURANCE_COVERAGE_NEED_SUCCESS, { data });
export const fetchLifeInsuranceCoverageNeedFailed = (errorMessage: string) =>
  action(FETCH_LIFE_INSURANCE_COVERAGE_NEED_FAILED, { errorMessage });

export const fetchLifeInsuranceCoverageNeedAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchLifeInsuranceCoverageNeedProgress());
      const res = await api.get(
        `/policy/get-life-policy-coverage-needs?customer_policy_id=${customerPolicyId}`
      );
      const data: ILifeInsuranceCoverageNeed[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchLifeInsuranceCoverageNeedSuccess(data[0]));
      } else {
        dispatch(
          fetchLifeInsuranceCoverageNeedSuccess(
            defaultLifeInsuranceCoverageNeed["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchLifeInsuranceCoverageNeedFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addLifeInsuranceCoverageNeedAsync =
  (
    coverage: ILifeInsuranceCoverageNeed,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(`/policy/edit-life-policy-coverage-needs`, {
        ...coverage,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Coverage Need saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_LIFE_INSURANCE_COVERAGE_NEED_STATE =
  "CLEAR_LIFE_INSURANCE_COVERAGE_NEED_STATE";
export const CLEAR_LIFE_INSURANCE_COVERAGE_NEED =
  "CLEAR_LIFE_INSURANCE_COVERAGE_NEED";

export const clearLifeInsuranceCoverageNeedState = () =>
  action(CLEAR_LIFE_INSURANCE_COVERAGE_NEED_STATE);

export const cleaLifeInsuranceCoverageNeed = () =>
  action(CLEAR_LIFE_INSURANCE_COVERAGE_NEED);

// /policy/edit-life-policy-coverage-needs
// /policy/get-life-policy-coverage-needs
