import { useSelector } from "react-redux";
import { IStoreState } from "../redux/initialStoreState";
import { useDispatchWrapper } from "./useDispatch";
import { fetchPremiumAsync } from "../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";
import React from "react";

export const useBusinessPremium = (customerPolicyId: number) => {
  const dispatch = useDispatchWrapper();
  const premium = useSelector(
    (storeState: IStoreState) => storeState.business.basicDetails.premium
  );

  React.useEffect(() => {
    dispatch(fetchPremiumAsync(customerPolicyId));
  }, [customerPolicyId]);

  return premium;
};
