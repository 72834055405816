import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialCommAutomobileCertificatesState } from "./commAutoCertificates.types";
import { CommAutomobileCertificatesActions } from ".";
import {
  CLEAR_COMM_AUTOMOBILE_CONSENT_FORM,
  CLEAR_COMM_AUTOMOBILE_DWELLING,
  CLEAR_COMM_AUTOMOBILE_DWELLING_DECLARATION,
  CLEAR_COMM_AUTOMOBILE_GARAGE_FORM,
  CLEAR_COMM_AUTOMOBILE_LIABILITY_INSURANCE,
  CLEAR_COMM_AUTOMOBILE_LIABILITY_SLIP,
  CLEAR_COMM_AUTOMOBILE_OCEP16,
  CLEAR_COMM_AUTOMOBILE_OCEP17,
  CLEAR_COMM_AUTOMOBILE_OCEP28A,
  CLEAR_COMM_AUTOMOBILE_OWNER_FORM,
  CLEAR_COMM_AUTOMOBILE_RETIREE_DISCOUNT,
  CLEAR_COMM_AUTOMOBILE_SHARING_FILLABLE,
  CLEAR_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM,
  FETCH_COMM_AUTOMOBILE_CERTIFICATES_STATE,
  FETCH_COMM_AUTOMOBILE_CONSENT_FORM_FAILED,
  FETCH_COMM_AUTOMOBILE_CONSENT_FORM_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_CONSENT_FORM_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_CONSENT_FORM_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_CONSENT_FORM_PROGRESS,
  FETCH_COMM_AUTOMOBILE_CONSENT_FORM_SUCCESS,
  FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_FAILED,
  FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_PROGRESS,
  FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_SUCCESS,
  FETCH_COMM_AUTOMOBILE_DWELLING_FAILED,
  FETCH_COMM_AUTOMOBILE_DWELLING_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_DWELLING_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_DWELLING_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_DWELLING_PROGRESS,
  FETCH_COMM_AUTOMOBILE_DWELLING_SUCCESS,
  FETCH_COMM_AUTOMOBILE_GARAGE_FORM_FAILED,
  FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_GARAGE_FORM_PROGRESS,
  FETCH_COMM_AUTOMOBILE_GARAGE_FORM_SUCCESS,
  FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_FAILED,
  FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS,
  FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS,
  FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_FAILED,
  FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_PROGRESS,
  FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_SUCCESS,
  FETCH_COMM_AUTOMOBILE_OCEP16_FAILED,
  FETCH_COMM_AUTOMOBILE_OCEP16_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_OCEP16_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_OCEP16_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_OCEP16_PROGRESS,
  FETCH_COMM_AUTOMOBILE_OCEP16_SUCCESS,
  FETCH_COMM_AUTOMOBILE_OCEP17_FAILED,
  FETCH_COMM_AUTOMOBILE_OCEP17_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_OCEP17_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_OCEP17_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_OCEP17_PROGRESS,
  FETCH_COMM_AUTOMOBILE_OCEP17_SUCCESS,
  FETCH_COMM_AUTOMOBILE_OCEP28A_FAILED,
  FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_OCEP28A_PROGRESS,
  FETCH_COMM_AUTOMOBILE_OCEP28A_SUCCESS,
  FETCH_COMM_AUTOMOBILE_OWNER_FORM_FAILED,
  FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_OWNER_FORM_PROGRESS,
  FETCH_COMM_AUTOMOBILE_OWNER_FORM_SUCCESS,
  FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_FAILED,
  FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_PROGRESS,
  FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_SUCCESS,
  FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_FAILED,
  FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_PROGRESS,
  FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_SUCCESS,
  FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_FAILED,
  FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_PROGRESS,
  FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_SUCCESS,
} from "./commAutoCertificatesActions";

export const CommAutomobileCertificatesReducer = (
  state: IStoreState["commAuto"]["certificates"] = initialCommAutomobileCertificatesState,
  action: CommAutomobileCertificatesActions
) => {
  switch (action.type) {
    case FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.liabilitySlips.liabilitySlipLoading = LoadState.InProgress;
        draftState.liabilitySlips.liabilitySlip =
          initialCommAutomobileCertificatesState["liabilitySlips"][
            "liabilitySlip"
          ];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.liabilitySlips.liabilitySlipLoading = LoadState.Loaded;
        draftState.liabilitySlips.liabilitySlip = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.liabilitySlips.liabilitySlipLoading = LoadState.Failed;
        draftState.liabilitySlips.liabilitySlip =
          initialCommAutomobileCertificatesState["liabilitySlips"][
            "liabilitySlip"
          ];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.liabilitySlips.loading = LoadState.InProgress;
        draftState.liabilitySlips.list = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.liabilitySlips.loading = LoadState.Loaded;
        draftState.liabilitySlips.list = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.liabilitySlips.loading = LoadState.Failed;
        draftState.liabilitySlips.list = [];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_LIABILITY_SLIP: {
      const newState = produce(state, (draftState) => {
        draftState.liabilitySlips.liabilitySlipLoading = LoadState.NotLoaded;
        draftState.liabilitySlips.liabilitySlip =
          initialCommAutomobileCertificatesState["liabilitySlips"][
            "liabilitySlip"
          ];
        draftState.liabilitySlips.error = null;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_OCEP16_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.opcf16.opcfLoading = LoadState.InProgress;
        draftState.opcf16.opcf =
          initialCommAutomobileCertificatesState["opcf16"]["opcf"];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP16_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf16.opcfLoading = LoadState.Loaded;
        draftState.opcf16.opcf = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP16_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf16.opcfLoading = LoadState.Failed;
        draftState.opcf16.opcf =
          initialCommAutomobileCertificatesState["opcf16"]["opcf"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP16_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.opcf16.loading = LoadState.InProgress;
        draftState.opcf16.list = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP16_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf16.loading = LoadState.Loaded;
        draftState.opcf16.list = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP16_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.opcf16.loading = LoadState.Failed;
        draftState.opcf16.list = [];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_OCEP16: {
      const newState = produce(state, (draftState) => {
        draftState.opcf16.opcfLoading = LoadState.NotLoaded;
        draftState.opcf16.opcf =
          initialCommAutomobileCertificatesState["opcf16"]["opcf"];
        draftState.liabilitySlips.error = null;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_CONSENT_FORM_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.consentform.consentLoading = LoadState.InProgress;
        draftState.consentform.consent =
          initialCommAutomobileCertificatesState["consentform"]["consent"];
      });
      return newState;
    }
     case FETCH_COMM_AUTOMOBILE_CONSENT_FORM_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.consentform.consentLoading = LoadState.Loaded;
        draftState.consentform.consent = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_CONSENT_FORM_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.consentform.consentLoading = LoadState.Failed;
        draftState.consentform.consent =
          initialCommAutomobileCertificatesState["consentform"]["consent"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_CONSENT_FORM_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.consentform.loading = LoadState.InProgress;
        draftState.consentform.list = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_CONSENT_FORM_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.consentform.loading = LoadState.Loaded;
        draftState.consentform.list = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_CONSENT_FORM_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.consentform.loading = LoadState.Failed;
        draftState.consentform.list = [];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_CONSENT_FORM: {
      const newState = produce(state, (draftState) => {
        draftState.consentform.consentLoading = LoadState.NotLoaded;
        draftState.consentform.consent =
          initialCommAutomobileCertificatesState["consentform"]["consent"];
        draftState.liabilitySlips.error = null;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.retireediscount.retireeLoading = LoadState.InProgress;
        draftState.retireediscount.retiree =
          initialCommAutomobileCertificatesState["retireediscount"]["retiree"];
      });
      return newState;
    }
     case FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.retireediscount.retireeLoading = LoadState.Loaded;
        draftState.retireediscount.retiree = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.retireediscount.retireeLoading = LoadState.Failed;
        draftState.retireediscount.retiree =
          initialCommAutomobileCertificatesState["retireediscount"]["retiree"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.retireediscount.loading = LoadState.InProgress;
        draftState.retireediscount.list = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.retireediscount.loading = LoadState.Loaded;
        draftState.retireediscount.list = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.retireediscount.loading = LoadState.Failed;
        draftState.retireediscount.list = [];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_RETIREE_DISCOUNT: {
      const newState = produce(state, (draftState) => {
        draftState.retireediscount.retireeLoading = LoadState.NotLoaded;
        draftState.retireediscount.retiree =
          initialCommAutomobileCertificatesState["retireediscount"]["retiree"];
        draftState.liabilitySlips.error = null;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.sharingfillable.sharingLoading = LoadState.InProgress;
        draftState.sharingfillable.sharing =
          initialCommAutomobileCertificatesState["sharingfillable"]["sharing"];
      });
      return newState;
    }
     case FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.sharingfillable.sharingLoading = LoadState.Loaded;
        draftState.sharingfillable.sharing = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.sharingfillable.sharingLoading = LoadState.Failed;
        draftState.sharingfillable.sharing =
          initialCommAutomobileCertificatesState["sharingfillable"]["sharing"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.sharingfillable.loading = LoadState.InProgress;
        draftState.sharingfillable.list = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.sharingfillable.loading = LoadState.Loaded;
        draftState.sharingfillable.list = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.sharingfillable.loading = LoadState.Failed;
        draftState.sharingfillable.list = [];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_SHARING_FILLABLE: {
      const newState = produce(state, (draftState) => {
        draftState.sharingfillable.sharingLoading = LoadState.NotLoaded;
        draftState.sharingfillable.sharing =
          initialCommAutomobileCertificatesState["sharingfillable"]["sharing"];
        draftState.liabilitySlips.error = null;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.supplementaryform.supplementaryLoading = LoadState.InProgress;
        draftState.supplementaryform.supplementary =
          initialCommAutomobileCertificatesState["supplementaryform"]["supplementary"];
      });
      return newState;
    }
     case FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.supplementaryform.supplementaryLoading = LoadState.Loaded;
        draftState.supplementaryform.supplementary = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.supplementaryform.supplementaryLoading = LoadState.Failed;
        draftState.supplementaryform.supplementary =
          initialCommAutomobileCertificatesState["supplementaryform"]["supplementary"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.supplementaryform.loading = LoadState.InProgress;
        draftState.supplementaryform.list = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.supplementaryform.loading = LoadState.Loaded;
        draftState.supplementaryform.list = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.supplementaryform.loading = LoadState.Failed;
        draftState.supplementaryform.list = [];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM: {
      const newState = produce(state, (draftState) => {
        draftState.supplementaryform.supplementaryLoading = LoadState.NotLoaded;
        draftState.supplementaryform.supplementary =
          initialCommAutomobileCertificatesState["supplementaryform"]["supplementary"];
        draftState.liabilitySlips.error = null;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_GARAGE_FORM_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.garageform.garageLoading = LoadState.InProgress;
        draftState.garageform.garage =
          initialCommAutomobileCertificatesState["garageform"]["garage"];
      });
      return newState;
    }
     case FETCH_COMM_AUTOMOBILE_GARAGE_FORM_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.garageform.garageLoading = LoadState.Loaded;
        draftState.garageform.garage = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_GARAGE_FORM_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.garageform.garageLoading = LoadState.Failed;
        draftState.garageform.garage =
          initialCommAutomobileCertificatesState["garageform"]["garage"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.garageform.loading = LoadState.InProgress;
        draftState.garageform.list = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.garageform.loading = LoadState.Loaded;
        draftState.garageform.list = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.garageform.loading = LoadState.Failed;
        draftState.garageform.list = [];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_GARAGE_FORM: {
      const newState = produce(state, (draftState) => {
        draftState.garageform.garageLoading = LoadState.NotLoaded;
        draftState.garageform.garage =
          initialCommAutomobileCertificatesState["garageform"]["garage"];
        draftState.liabilitySlips.error = null;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_OWNER_FORM_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.ownerform.ownerLoading = LoadState.InProgress;
        draftState.ownerform.owner =
          initialCommAutomobileCertificatesState["ownerform"]["owner"];
      });
      return newState;
    }
     case FETCH_COMM_AUTOMOBILE_OWNER_FORM_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.ownerform.ownerLoading = LoadState.Loaded;
        draftState.ownerform.owner = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OWNER_FORM_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.ownerform.ownerLoading = LoadState.Failed;
        draftState.ownerform.owner =
          initialCommAutomobileCertificatesState["ownerform"]["owner"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.ownerform.loading = LoadState.InProgress;
        draftState.ownerform.list = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.ownerform.loading = LoadState.Loaded;
        draftState.ownerform.list = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.ownerform.loading = LoadState.Failed;
        draftState.ownerform.list = [];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_OWNER_FORM: {
      const newState = produce(state, (draftState) => {
        draftState.ownerform.ownerLoading = LoadState.NotLoaded;
        draftState.ownerform.owner =
          initialCommAutomobileCertificatesState["ownerform"]["owner"];
        draftState.liabilitySlips.error = null;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.dwellingdeclaration.dwellingLoading = LoadState.InProgress;
        draftState.dwellingdeclaration.dwelling =
          initialCommAutomobileCertificatesState["dwellingdeclaration"]["dwelling"];
      });
      return newState;
    }
     case FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dwellingdeclaration.dwellingLoading = LoadState.Loaded;
        draftState.dwellingdeclaration.dwelling = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dwellingdeclaration.dwellingLoading = LoadState.Failed;
        draftState.dwellingdeclaration.dwelling =
          initialCommAutomobileCertificatesState["dwellingdeclaration"]["dwelling"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.dwellingdeclaration.loading = LoadState.InProgress;
        draftState.dwellingdeclaration.list = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dwellingdeclaration.loading = LoadState.Loaded;
        draftState.dwellingdeclaration.list = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.dwellingdeclaration.loading = LoadState.Failed;
        draftState.dwellingdeclaration.list = [];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_DWELLING_DECLARATION: {
      const newState = produce(state, (draftState) => {
        draftState.dwellingdeclaration.dwellingLoading = LoadState.NotLoaded;
        draftState.dwellingdeclaration.dwelling =
          initialCommAutomobileCertificatesState["dwellingdeclaration"]["dwelling"];
        draftState.liabilitySlips.error = null;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_DWELLING_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.dwelling.dwellingLoading = LoadState.InProgress;
        draftState.dwelling.dwelling =
          initialCommAutomobileCertificatesState["dwelling"]["dwelling"];
      });
      return newState;
    }
     case FETCH_COMM_AUTOMOBILE_DWELLING_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dwelling.dwellingLoading = LoadState.Loaded;
        draftState.dwelling.dwelling = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_DWELLING_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dwelling.dwellingLoading = LoadState.Failed;
        draftState.dwelling.dwelling =
          initialCommAutomobileCertificatesState["dwelling"]["dwelling"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_DWELLING_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.dwelling.loading = LoadState.InProgress;
        draftState.dwelling.list = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_DWELLING_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dwelling.loading = LoadState.Loaded;
        draftState.dwelling.list = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_DWELLING_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.dwelling.loading = LoadState.Failed;
        draftState.dwelling.list = [];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_DWELLING: {
      const newState = produce(state, (draftState) => {
        draftState.dwelling.dwellingLoading = LoadState.NotLoaded;
        draftState.dwelling.dwelling =
          initialCommAutomobileCertificatesState["dwelling"]["dwelling"];
        draftState.liabilitySlips.error = null;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_OCEP17_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.opcf17.opcfLoading = LoadState.InProgress;
        draftState.opcf17.opcf =
          initialCommAutomobileCertificatesState["opcf17"]["opcf"];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP17_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf17.opcfLoading = LoadState.Loaded;
        draftState.opcf17.opcf = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP17_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf17.opcfLoading = LoadState.Failed;
        draftState.opcf17.opcf =
          initialCommAutomobileCertificatesState["opcf17"]["opcf"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP17_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.opcf17.loading = LoadState.InProgress;
        draftState.opcf17.list = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP17_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf17.loading = LoadState.Loaded;
        draftState.opcf17.list = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP17_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.opcf17.loading = LoadState.Failed;
        draftState.opcf17.list = [];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_OCEP17: {
      const newState = produce(state, (draftState) => {
        draftState.opcf17.opcfLoading = LoadState.NotLoaded;
        draftState.opcf17.opcf =
          initialCommAutomobileCertificatesState["opcf17"]["opcf"];
        draftState.liabilitySlips.error = null;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_OCEP28A_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.opcf28A.opcfLoading = LoadState.InProgress;
        draftState.opcf28A.opcf =
          initialCommAutomobileCertificatesState["opcf28A"]["opcf"];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP28A_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf28A.opcfLoading = LoadState.Loaded;
        draftState.opcf28A.opcf = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP28A_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf28A.opcfLoading = LoadState.Failed;
        draftState.opcf28A.opcf =
          initialCommAutomobileCertificatesState["opcf28A"]["opcf"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.opcf28A.loading = LoadState.InProgress;
        draftState.opcf28A.list = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf28A.loading = LoadState.Loaded;
        draftState.opcf28A.list = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.opcf28A.loading = LoadState.Failed;
        draftState.opcf28A.list = [];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_OCEP28A: {
      const newState = produce(state, (draftState) => {
        draftState.opcf28A.opcfLoading = LoadState.NotLoaded;
        draftState.opcf28A.opcf =
          initialCommAutomobileCertificatesState["opcf28A"]["opcf"];
        draftState.liabilitySlips.error = null;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.liabilityInsurance.liabilityInsuranceLoading =
          LoadState.InProgress;
        draftState.liabilityInsurance.liabilityInsurance =
          initialCommAutomobileCertificatesState["liabilityInsurance"][
            "liabilityInsurance"
          ];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.liabilityInsurance.liabilityInsuranceLoading =
          LoadState.Loaded;
        draftState.liabilityInsurance.liabilityInsurance = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.liabilityInsurance.liabilityInsuranceLoading =
          LoadState.Failed;
        draftState.liabilityInsurance.liabilityInsurance =
          initialCommAutomobileCertificatesState["liabilityInsurance"][
            "liabilityInsurance"
          ];
        draftState.liabilityInsurance.error = errorMessage;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.liabilityInsurance.loading = LoadState.InProgress;
        draftState.liabilityInsurance.list = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.liabilityInsurance.loading = LoadState.Loaded;
        draftState.liabilityInsurance.list = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.liabilityInsurance.loading = LoadState.Failed;
        draftState.liabilityInsurance.list = [];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_LIABILITY_INSURANCE: {
      const newState = produce(state, (draftState) => {
        draftState.liabilityInsurance.liabilityInsuranceLoading =
          LoadState.NotLoaded;
        draftState.liabilityInsurance.liabilityInsurance =
          initialCommAutomobileCertificatesState["liabilityInsurance"][
            "liabilityInsurance"
          ];
        draftState.liabilityInsurance.error = null;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_CERTIFICATES_STATE: {
      return initialCommAutomobileCertificatesState;
    }

    default: {
      return state;
    }
  }
};
