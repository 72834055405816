import { LoadState } from "../../../constants/enums";


export interface IBusinessPolicyHistoryState {
    list: IBusinessPolicyHistory[];
    loading: LoadState;
}


export interface IBusinessPolicyHistory {
    insert_ts: string;
    [key: string]: any;
}


export const initialBusinessPolicyHistoryState: IBusinessPolicyHistoryState = {
    list: [],
    loading: LoadState.NotLoaded,
}