import { LoadState } from "../../constants/enums";

export interface IAppFormState {
  list: IAppForm[];
  totalRecords: number;
  loading: LoadState;
}

export interface IAppForm {
  apps_and_forms_code?: string;
  file_name: string | null;
  policy_type: string | null;
  line_of_business: string | null;
  insurer_id: string | null;
  file: string | null;
  status: string;
}

export const initialAppForm: IAppForm = {
  file_name: null,
  policy_type: null,
  line_of_business: null,
  insurer_id: null,
  file: null,
  status: "ACTIVE",
};

export const initialAppFormState: IAppFormState = {
  list: [],
  totalRecords: 0,
  loading: LoadState.NotLoaded,
};
