/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Button, Link, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { LoadState } from "../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../hooks";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { fetchAutomobileLiabilityInsuranceListAsync } from "../../../../../../redux/automobileCertificates/automobileCertificatesActions";
import { IAutomobileLiabilityInsurance } from "../../../../../../redux/automobileCertificates/automobileCertificates.types";
import { CustomSelect } from "../../../../../../components/formsComponents";
import { renderDropdownValue } from "../../../../../../helpers";
import { CustomChip } from "../../../../../../components/CustomChip";
import { Add, NotificationsActive, ThumbUpAlt } from "@mui/icons-material";

export const AutomobileCSIOCertificateList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { list, loading } = useSelector(
    (storeState: IStoreState) =>
      storeState.automobile.certificates.liabilityInsurance
  );

  const dispatch = useDispatchWrapper();

  const [selectedCsio, setselectedCsio] = React.useState<{
    isAllSelected: boolean;
    certificate: IAutomobileLiabilityInsurance[];
  }>({
    isAllSelected: false,
    certificate: [],
  });

  const menuItems =
    selectedCsio.certificate.length > 0 || selectedCsio.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const OPCF_16TableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    selectionMode: "multiple",
    uniqueRowKeyName: "incremental_number",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [...menuItems],
      },
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => {
                  navigate(
                    `/automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/liability-insurance`
                  );
                }}
              >
                Create
              </Button>
            ),
          },
        ],
      },
    },
    columns: [

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        isFirstColumnSticky: true,
        onRowCellRender: (value, row: IAutomobileLiabilityInsurance) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/liability-insurance/${row.incremental_number}`
                );
              }}
              onDuplicateClick={() => {
                navigate(
                  `/automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/liability-insurance-duplicate/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileLiabilityInsurance) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/liability-insurance/${row.incremental_number}`
                );
              }}
            >
              {row.incremental_number}
            </Link>
          );
        },
      },

      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IAutomobileLiabilityInsurance) => {
          if (row.status === "ACTIVE") {
            return (
              <CustomChip
                color="success"
                icon={<ThumbUpAlt />}
                content={"Active"}
                size="small"
              />
            );
          } else {
            return (
              <CustomChip
                color="error"
                icon={<NotificationsActive />}
                content={"Cancel"}
                size="small"
              />
            );
          }
        },
      },

      {
        key: "cgl_policy_number",
        headerName: "CGL Policy Number",
        fieldName: "cgl_policy_number",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
      },
      {
        key: "automobile_policy",
        headerName: "Automobile Policy",
        fieldName: "automobile_policy",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
      },
      {
        key: "vehicle_unit_number_or_vin",
        headerName: "Unit Number or VIN",
        fieldName: "vehicle_unit_number_or_vin",
        renderType: RenderType.CHIP_WARNING,
        exportCellWidth: 20,
      },
      {
        key: "certificate_holder_name",
        headerName: "Certificate Holder name",
        fieldName: "certificate_holder_name",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },

    
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        exportCellWidth: 25,
        renderType: RenderType.TEXT_DARK,
        onRowCellValueRender: (value, row:IAutomobileLiabilityInsurance) =>{
           return row.other_liability_section_one.policy_number
        },
      },

    ],
    items: list,
    onSelection: (
      isAllSelected: boolean,
      rows: IAutomobileLiabilityInsurance[]
    ) => {
      setselectedCsio({ isAllSelected: isAllSelected, certificate: rows });
    },
  };

  React.useEffect(() => {
    dispatch(fetchAutomobileLiabilityInsuranceListAsync(customerPolicyId));
  }, [customerPolicyId]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...OPCF_16TableProps} />
      </Box>
    </>
  );
};
