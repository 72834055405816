import moment from "moment";
import { LoadState } from "../../../constants/enums";

export interface IlifeInsurancePolicyExistingPropertyState {
    data: IlifeInsurancePolicyExistingProperty;
    loading: LoadState;
}

export interface IlifeInsurancePolicyExistingProperty {
    customer_policy_id: number,
    customer_name: string | null,
    covergae_type: string | null,
    insurer: string | null,
    effective_date: string,
    expiry_date: string,
    annual_premium: string | null,
    policy_status: string | null,
    notes: string | null,
    assigned_to: string | null,
    status: string | null,
}

export const defaultlifeInsurancePolicyExistingProperty: IlifeInsurancePolicyExistingPropertyState = {
    data: {
        customer_policy_id: 0,
        customer_name: null,
        covergae_type: null,
        insurer: null,
        effective_date: moment().format("YYYY-MM-DD"),
        expiry_date: moment().format("YYYY-MM-DD"),
        annual_premium: null,
        policy_status: null,
        notes: null,
        assigned_to: null,
        status: "ACTIVE"
    },
    loading: LoadState.NotLoaded,
}