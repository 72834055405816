import { LoadState } from "../../constants/enums";

export interface IAutomobileMarketingHistoryState {
  marketingHistoryList: IAutomobileMarketingHistory[];
  marketingHistoryListLoading: LoadState;
  marketingHistory: IAutomobileMarketingHistory;
  marketingHistoryLoading: LoadState;
  error: string | null;

}

export interface IAutomobileMarketingHistory {
  customer_policy_id: number;
  quote_number: number | null;
  serial_number: string | null;
  customer_name: string | null;
  quote_status: string | null;
  premium: string | null;
  submission_date: string | null;
  date_received: string | null;
  insurer: string | null;
  underwriter: string | null;
  attachment: any;
  notes: string | null;
  status: string;
}

export const initialAutomobileMarketingHistory: IAutomobileMarketingHistory = {
  customer_policy_id: 0,
  quote_number: null,
  serial_number: null,
  customer_name: null,
  quote_status: null,
  premium: null,
  submission_date: null,
  date_received: null,
  insurer: null,
  underwriter: null,
  attachment: [{}],
  notes: null,
  status: "ACTIVE",
};

export const initialAutomobileMarketingHistoryState: IAutomobileMarketingHistoryState =
  {
    marketingHistoryList: [],
    marketingHistoryListLoading: LoadState.NotLoaded,
    marketingHistory: initialAutomobileMarketingHistory,
    marketingHistoryLoading: LoadState.NotLoaded,
    error: null,
  };
