/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";

import produce from "immer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCurrentUserId } from "../../../../hooks/useAssignedTo";
import { useDispatchWrapper, usePoliciesByCustomer } from "../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";
import {
  ILocationResponsePayload,
  LocationAutoComplete,
} from "../../../../components/LocationAutoComplete/LocationAutoComplete";
import { datesDiffInDays } from "../../../../helpers/calcAge";
import {
  addNoPolicyDriverInsuranceReportAsync,
  fetchNoPolicyDriverInsuranceReportAsync,
} from "../../../../redux/insuranceReports/insuranceReportsActions";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../constants/enums";
import { StandadCard } from "../../../../components/Cards";
import { LabelCard } from "../../../../components/LabelCard/LabelCard";
import { UsersAutoSearchByRole } from "../../../../components/AutoCompleteSearches/UsersAutoSearchByRole";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../components/formsComponents";
import { BranchCodeAutoSearch } from "../../../../components/AutoCompleteSearches/BranchCodeAutoSearch";
import { FileUpload } from "../../../../components/FileUpload/FileUpload";
import { renderDropdownValue } from "../../../../helpers";
import { CustomDatePicker } from "../../../../components/formsComponents/CustomDatePicker";
import { InsurerCodeAutoSearch } from "../../../../components/AutoCompleteSearches";
import { CustomerAutoSearch } from "../../../../components/AutoCompleteSearches/CustomerAutoSearch";
import { ICustomerFullDetails } from "../../../../redux/customer/customer.types";
import { ErrorMessage } from "../../../../components/ErrorMessage/ErrorMessage";

export const NoPolicyInsuranceReports = () => {
  const { licenseNumber,incrementalNumber } = useParams() as {
    licenseNumber?: string;
    incrementalNumber?: string;
  };

  const { data, dataLoading, error } = useSelector(
    (storeState: IStoreState) => storeState.insuranceReports
  );

  const currentUserId = useCurrentUserId();

  const [file, setFile] = React.useState<any>({
    file_upload_one: null,
    file_upload_two: null,
  });
  const [isAttachment, setIsAttachment] = React.useState(false);
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const { policiesList, policiesLoading, fetchPoliciesByCustomerId } =
    usePoliciesByCustomer();

  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: data,
    validate: (values) => {
      const errors: any = {};
      if (
        values.date_wriitten &&
        !values.insurer_code_written
      ) {
        errors.insurer_code_written = "Request status is requried!";
      } else if (values.date_wriitten && !values.customer_id) {
        errors.customer_id = "Customer is requried!";
      } else if (
        values.date_wriitten &&
        !values.customer_policy_id
      ) {
        errors.customer_policy_id = "Policy number is requried!";
      }
      return errors;
    },
    onSubmit: async (values) => {
      if (licenseNumber) {
        setLoading(true);
        dispatch(
          addNoPolicyDriverInsuranceReportAsync(
            {
              ...values,
              license_number: licenseNumber,
            },
            file.file_upload_one,
            file.file_upload_two,
            (isSuccess) => {
              if (isSuccess) {
                window.scrollTo(0, 0);
                navigate(`/insurance-reports?tab=1`);
              }
              setLoading(false);
            }
          )
        );
      }
    },
  });

  const handleAddress = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      current_address: data.address,
      current_address_city: data.city,
      current_address_country: data.country,
      current_address_state_or_province: data.state,
      current_address_postal_code: data.postalCode,
    });
  };

  const handleNewAddress = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      new_address: data.address,
      new_address_city: data.city,
      new_address_country: data.country,
      new_address_state_or_province: data.state,
      new_address_postal_code: data.postalCode,
    });
  };

  const handleRequestStatusChange = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as string;
    const newState = produce(values, (draftState) => {
      if (!isAttachment && value === "Completed") {
        return;
      } else if (isAttachment && value === "Voided") {
        return;
      } else if (value === "Destroyed") {
        draftState.date_wriitten = null;
        draftState.date_voided = null;
        draftState.date_cancelled = null;
      }
      draftState.request_status = value;
    });
    setValues(newState);
  };

  const handlePolicyChange = (e: SelectChangeEvent<unknown>) => {
    const policyId = e.target.value as string;
    const policy = policiesList.find(
      (x) => x.customer_policy_id === Number(policyId)
    );
    if (policy) {
      setValues({
        ...values,
        customer_policy_id: policyId,
        policy_number: policy.policy_number,
      });
    }
  };

  const handleCustomer = (data: ICustomerFullDetails) => {
    if (data.customer_id) {
      setValues({
        ...values,
        customer_id: data.customer_id as number,
        customer_name: data.customer_name,
        customer_policy_id: null,
        policy_number: null,
      });
    }
  };

  React.useEffect(() => {
    if (values.customer_id) {
      fetchPoliciesByCustomerId(values.customer_id as number);
    }
  }, [values.customer_id]);

  React.useEffect(() => {
    if (
      values.request_status === "Voided" ||
      values.request_status === "Completed"
    ) {
      setFieldValue("status", "Compliant");
    } else if (values.request_status === "Submitted") {
      setFieldValue("status", null);
    }
  }, [values.request_status]);

  React.useEffect(() => {
    const newState = produce(data, (draftState) => {
      if (!licenseNumber) {
        //draftState.policy_number = customerInfo.policy_number;
      } else if (licenseNumber && data.request_status === null) {
        draftState.request_status = "Submitted";
        draftState.days_left = datesDiffInDays(data.date_and_time);
      } else {
        draftState.days_left = datesDiffInDays(data.date_and_time);
      }

      if (draftState.file_upload || draftState.file_upload_two) {
        setIsAttachment(true);
        draftState.request_status = draftState.request_status || "Completed";
        draftState.date_destroyed = null;
      } else {
        draftState.request_status = "Submitted";
        draftState.date_destroyed = null;
        draftState.date_wriitten = null;
        setIsAttachment(false);
      }
      if (draftState.request_status !== "Cancelled") {
        draftState.date_cancelled = null;
      }

      draftState.requested_by =
        draftState.requested_by || currentUserId.toString();
    });
    setValues(newState);
  }, [data, licenseNumber]);

  React.useEffect(() => {
    if (licenseNumber && incrementalNumber) {
      dispatch(fetchNoPolicyDriverInsuranceReportAsync(licenseNumber,incrementalNumber));
    }
  }, [licenseNumber]);

  //   React.useEffect(() => {
  //     return () => {
  //       dispatch(clearCommAutomobileActivityTask());
  //     };
  //   }, []);

  return (
    <PageLoader
      loading={dataLoading === LoadState.InProgress}
      error={error ? { message: error } : undefined}
    >
      <StandadCard sx={{ mt: 1 }}>
        <form onSubmit={handleSubmit}>
          <LabelCard heading="Insurance Reports" />
          <Grid container spacing={1}>
            <Grid item xs={12} lg={1}>
              <CustomFormLabel>#</CustomFormLabel>
              <CustomTextField
                name="incremental_number"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.incremental_number}
                disabled
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Driver Name</CustomFormLabel>
              <CustomTextField
                name="driver_name"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.driver_name}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Driver License Number</CustomFormLabel>
              <CustomTextField
                name="license_number"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.license_number}
                disabled
                // onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Business Reason</CustomFormLabel>
              <ControlledCustomSelect
                fullWidth
                value={values.business_reason}
                name="business_reason"
                onChange={handleChange}
                displayEmpty
                options={["Verify Driver Record", "Option 2"].map(
                  (template) => {
                    return { label: template, value: template };
                  }
                )}
              ></ControlledCustomSelect>
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Date and Time</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                disabled
                value={values.date_and_time || ""}
                onChange={(newValue, dateWithTime) => {
                  setFieldValue("date_and_time", dateWithTime);
                }}
              />
            </Grid>

            {licenseNumber && (
              <Grid item xs={12} lg={1.4}>
                <CustomFormLabel>Days Left</CustomFormLabel>
                <CustomTextField
                  name="days_left"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.days_left}
                  onChange={handleChange}
                  disabled
                />
              </Grid>
            )}

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Transaction Type</CustomFormLabel>
              <ControlledCustomSelect
                fullWidth
                value={values.transaction_type}
                name="transaction_type"
                onChange={handleChange}
                placeholder="Select one"
                displayEmpty
                options={[
                  "New",
                  "Remarket",
                  "Endorsement - Add Driver",
                  "Policy Change",
                  "Certificate of Insurance",
                  "Report Claim",
                ].map((template) => {
                  return { label: template, value: template };
                })}
              ></ControlledCustomSelect>
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Reports Requested</CustomFormLabel>
              <ControlledCustomSelect
                fullWidth
                value={values.report_requested}
                name="report_requested"
                onChange={handleChange}
                placeholder="Select one"
                displayEmpty
                options={["MVR", "A+", "MVR & A+", "Hits"].map((template) => {
                  return { label: template, value: template };
                })}
              ></ControlledCustomSelect>
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel># of Disclosed Convictions</CustomFormLabel>
              <CustomTextField
                variant="outlined"
                size="small"
                type="string"
                fullWidth
                name="number_of_disclosed_convictions"
                value={values.number_of_disclosed_convictions}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Insurer Code</CustomFormLabel>
              <InsurerCodeAutoSearch
                value={values.insurer_code}
                onSelect={(value) => {
                  setFieldValue("insurer_code", value.value);
                }}
              />
            </Grid>

            {values.insurer_code && ["HALI"].includes(values.insurer_code) && (
              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Intact Branch</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.intact_branch}
                  name="intact_branch"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={[
                    "Intact-Personal Insurance",
                    "Intact-Commercial Insurance",
                    "Intact-Group Insurance",
                    "N/A",
                  ].map((template) => {
                    return { label: template, value: template };
                  })}
                ></ControlledCustomSelect>
              </Grid>
            )}

            {values.report_requested === "Hits" && (
              <>
                <Grid item xs={12} lg={1.5}>
                  <CustomFormLabel>Years at This address?</CustomFormLabel>
                  <CustomTextField
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    name="years_at_this_address"
                    value={values.years_at_this_address}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} lg={1.5}>
                  <CustomFormLabel>Current Address Type</CustomFormLabel>
                  <CustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    name="current_address_type"
                    fullWidth
                    value={values.current_address_type}
                    onChange={handleChange}
                    displayEmpty
                    renderValue={renderDropdownValue(`Select one`)}
                  >
                    {["R", "B"].map((transactionType) => {
                      return (
                        <MenuItem value={transactionType}>
                          {transactionType}
                        </MenuItem>
                      );
                    })}
                  </CustomSelect>
                </Grid>

                {/* <Grid item xs={12} lg={3}></Grid> */}

                <Grid item xs={12} lg={4}>
                  <CustomFormLabel>Current Address</CustomFormLabel>
                  <LocationAutoComplete
                    id="current_address"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.current_address}
                    onLocationChange={handleAddress}
                  />
                </Grid>

                <Grid item xs={12} lg={1}>
                  <CustomFormLabel>Unit/Suite</CustomFormLabel>
                  <CustomTextField
                    id="current_address_unit_or_suite"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.current_address_unit_or_suite}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} lg={1.5}>
                  <CustomFormLabel>City</CustomFormLabel>
                  <CustomTextField
                    id="current_address_city"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.current_address_city}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={1.5}>
                  <CustomFormLabel>State/Province</CustomFormLabel>
                  <CustomTextField
                    id="current_address_state_or_province"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.current_address_state_or_province}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={1}>
                  <CustomFormLabel>Postal Code</CustomFormLabel>
                  <CustomTextField
                    id="current_address_postal_code"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.current_address_postal_code}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} lg={1.68}>
                  <CustomFormLabel>New Address Type</CustomFormLabel>
                  <CustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    name="new_address_type"
                    fullWidth
                    value={values.new_address_type}
                    onChange={handleChange}
                    displayEmpty
                    renderValue={renderDropdownValue(`Select one`)}
                  >
                    {["R", "B"].map((transactionType) => {
                      return (
                        <MenuItem value={transactionType}>
                          {transactionType}
                        </MenuItem>
                      );
                    })}
                  </CustomSelect>
                </Grid>

                <Grid item xs={12} lg={3.6}>
                  <CustomFormLabel>New Address</CustomFormLabel>
                  <LocationAutoComplete
                    id="new_address"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.new_address}
                    onLocationChange={handleNewAddress}
                  />
                </Grid>
                <Grid item xs={12} lg={1.68}>
                  <CustomFormLabel>Unit/Suite</CustomFormLabel>
                  <CustomTextField
                    id="new_address_unit_or_suite"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.new_address_unit_or_suite}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} lg={1.68}>
                  <CustomFormLabel>City</CustomFormLabel>
                  <CustomTextField
                    id="new_address_city"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.new_address_city}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={1.68}>
                  <CustomFormLabel>State/Province</CustomFormLabel>
                  <CustomTextField
                    id="new_address_state_or_province"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.new_address_state_or_province}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={1.68}>
                  <CustomFormLabel>Postal Code</CustomFormLabel>
                  <CustomTextField
                    id="new_address_postal_code"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.new_address_postal_code}
                    onChange={handleChange}
                  />
                </Grid>
              </>
            )}
          </Grid>

          {licenseNumber && (
            <>
              <LabelCard heading="Status & Updates" />
              <Grid container spacing={1}>
                <Grid item xs={12} lg={3}>
                  <CustomFormLabel>Date Written</CustomFormLabel>
                  <CustomDatePicker
                    fullWidth
                    value={values.date_wriitten || ""}
                    disabled={values.request_status !== "Completed"}
                    onChange={(newValue) => {
                      setFieldValue("date_wriitten", newValue);
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <CustomFormLabel>Date Cancelled</CustomFormLabel>
                  <CustomDatePicker
                    fullWidth
                    disabled={values.request_status !== "Cancelled"}
                    value={values.date_cancelled || ""}
                    onChange={(newValue) => {
                      setFieldValue("date_cancelled", newValue);
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <CustomFormLabel>Date Voided</CustomFormLabel>
                  <CustomDatePicker
                    fullWidth
                    disabled={
                      isAttachment ||
                      ["Completed", "Cancelled"].includes(
                        values.request_status as string
                      )
                    }
                    value={values.date_voided || ""}
                    onChange={(newValue) => {
                      setFieldValue("date_voided", newValue);
                    }}
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <CustomFormLabel>Date Destroyed</CustomFormLabel>
                  <CustomDatePicker
                    fullWidth
                    disabled={
                      !["Completed", "Destroyed"].includes(
                        values.request_status as string
                      )
                    }
                    value={values.date_destroyed || ""}
                    onChange={(newValue) => {
                      setFieldValue("date_destroyed", newValue);
                    }}
                  />
                </Grid>

                <Grid item xs={12} lg={2.4}>
                  <CustomFormLabel>Request Status</CustomFormLabel>
                  <ControlledCustomSelect
                    fullWidth
                    value={values.request_status}
                    name="request_status"
                    onChange={handleRequestStatusChange}
                    placeholder="Select one"
                    displayEmpty
                    options={[
                      "Submitted",
                      "Voided",
                      "Completed",
                      "Cancelled",
                      "Destroyed",
                    ].map((template) => {
                      return { label: template, value: template };
                    })}
                  ></ControlledCustomSelect>
                </Grid>

                <Grid item xs={12} lg={2.4}>
                  <CustomFormLabel>Policy Status Cancelled</CustomFormLabel>
                  <CustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    name="policy_status_cancelled"
                    value={values.policy_status_cancelled}
                    onChange={handleChange}
                    displayEmpty
                    fullWidth
                    renderValue={renderDropdownValue(`Select one`)}
                  >
                    {["Y", "N"].map((policyType) => {
                      return (
                        <MenuItem value={policyType}>{policyType}</MenuItem>
                      );
                    })}
                  </CustomSelect>
                </Grid>

                <Grid item xs={12} lg={2.4}>
                  <CustomFormLabel>Insurer Code</CustomFormLabel>
                  <InsurerCodeAutoSearch
                    value={values.insurer_code_written}
                    errorMessage={errors.insurer_code_written}
                    onSelect={(value) => {
                      setFieldValue("insurer_code_written", value.value);
                    }}
                  />
                  {}
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomerAutoSearch
                    label="Customer"
                    value={values.customer_id}
                    onSelect={handleCustomer}
                    error={errors.customer_id ? true : false}
                    helperText={errors.customer_id}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomFormLabel>Policy</CustomFormLabel>
                  <ControlledCustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    fullWidth
                    displayEmpty
                    name="customer_policy_id"
                    value={Number(values.customer_policy_id)}
                    onChange={handlePolicyChange}
                    placeholder="Select One"
                    error={errors.customer_policy_id ? true : false}
                    helperText={errors.customer_policy_id}
                    options={policiesList.map((item) => {
                      return {
                        label: item.policy_number,
                        value: item.customer_policy_id,
                      };
                    })}
                  />
                </Grid>
              </Grid>
            </>
          )}

          <LabelCard heading="Office Information" />
          <Grid container spacing={1}>
            <Grid item xs={12} lg={3}>
              <UsersAutoSearchByRole
                value={Number(values.requested_by)}
                label="Requested By"
                onSelect={(value) =>
                  setFieldValue("requested_by", value.user_fact_id)
                }
              />
            </Grid>

            {/* <Grid item xs={12} lg={3}>
              <CustomFormLabel>Requested By Code</CustomFormLabel>
              <CustomTextField
                name="requested_by_code"
                type="text"
                value={values.requested_by_code}
                disabled
                fullWidth
                onChange={handleChange}
              ></CustomTextField>
            </Grid> */}

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Branch</CustomFormLabel>
              <BranchCodeAutoSearch
                value={values.branch}
                disabled
                onSelect={(value) => setFieldValue("branch", value.label)}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <UsersAutoSearchByRole
                value={values.producer}
                label="Producer"
                disabled
                onSelect={(value) =>
                  setFieldValue("producer", value.user_fact_id)
                }
              />
            </Grid>

            {/* <Grid item xs={12} lg={3}>
              <CustomFormLabel>Producer Code</CustomFormLabel>
              <CustomTextField
                name="producer_code"
                type="text"
                value={values.producer_code}
                disabled
                fullWidth
                onChange={handleChange}
              ></CustomTextField>
            </Grid> */}

            <Grid item xs={12} lg={3}>
              <UsersAutoSearchByRole
                value={values.csr}
                disabled
                label="CSR"
                onSelect={(value) => setFieldValue("csr", value.user_fact_id)}
              />
            </Grid>

            <Grid item xs={12} lg={12}>
              <CustomFormLabel>Link</CustomFormLabel>
              <Link
                to={"https://iis.cgi.com/RapidWeb/main/welcome.aspx"}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                {" "}
                <Typography variant="h4">
                  https://iis.cgi.com/RapidWeb/main/welcome.aspx
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} lg={12}>
              <CustomFormLabel>Notes</CustomFormLabel>
              <CustomTextField
                variant="outlined"
                size="small"
                type="string"
                fullWidth
                name="notes"
                value={values.notes}
                onChange={handleChange}
              />
            </Grid>

            {licenseNumber && (
              <>
                <Grid item xs={12} lg={3}>
                  <CustomFormLabel>File Type 1</CustomFormLabel>
                  <ControlledCustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    name="file_type"
                    fullWidth
                    value={values.file_type}
                    onChange={handleChange}
                    displayEmpty
                    placeholder="Selec one"
                    options={[
                      { label: "MVR", value: "MVR" },
                      { label: "A+", value: "A+" },
                      { label: "Hits", value: "Hits" },
                      { label: "Other", value: "Other" },
                    ]}
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <CustomFormLabel>File Upload</CustomFormLabel>
                  <FileUpload
                    value={values.file_upload}
                    onChange={(file) => {
                      setIsAttachment(true);
                      setValues({
                        ...values,
                        request_status: "Completed",
                        date_destroyed: null,
                      });

                      setFile((prevfiles: any) => ({
                        ...prevfiles,
                        file_upload_one: file,
                      }));
                    }}
                    onDelete={() => {
                      setIsAttachment(false);
                      setValues({
                        ...values,
                        request_status: "Submitted",
                        date_destroyed: null,
                        date_wriitten: null,
                      });
                      setFile({
                        ...file,
                        file_upload_one: null,
                      });
                      setFieldValue("file_upload", null);
                    }}
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <CustomFormLabel>File Type 2</CustomFormLabel>
                  <CustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    name="file_type_two"
                    fullWidth
                    value={values.file_type_two}
                    onChange={handleChange}
                    displayEmpty
                    renderValue={renderDropdownValue(`Select one`)}
                  >
                    {["MVR", "A+", "Hits", "Other"].map((transactionType) => {
                      return (
                        <MenuItem value={transactionType}>
                          {transactionType}
                        </MenuItem>
                      );
                    })}
                  </CustomSelect>
                </Grid>

                <Grid item xs={12} lg={3}>
                  <CustomFormLabel>File Upload</CustomFormLabel>
                  <FileUpload
                    value={values.file_upload_two}
                    onChange={(file) => {
                      setIsAttachment(true);
                      setValues({
                        ...values,
                        request_status: "Completed",
                        date_destroyed: null,
                      });

                      setFile((prevfiles: any) => ({
                        ...prevfiles,
                        file_upload_two: file,
                      }));
                    }}
                    onDelete={() => {
                      setIsAttachment(false);
                      setValues({
                        ...values,
                        request_status: "Submitted",
                        date_destroyed: null,
                        date_wriitten: null,
                      });
                      setFile({
                        ...file,
                        file_upload_two: null,
                      });
                      setFieldValue("file_upload_two", null);
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>

          <Box sx={{ mt: 2 }}>
            <Button
              //moduleId={MODULE_IDS.COMMERCIAL_AUTO}

              type="submit"
              disabled={loading}
              variant="contained"
            >
              Save
            </Button>
          </Box>
        </form>
      </StandadCard>
    </PageLoader>
  );
};
