/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../redux/initialStoreState";
import { useDispatchWrapper } from "./useDispatch";
import { fetchActiveUserPolicyListAsync } from "../redux/customer/customerActions";

export const useCustomerActivePolicies = (customerId?: number) => {
  const data = useSelector(
    (storeState: IStoreState) => storeState.customer.policies
  );
  const dispatch = useDispatchWrapper();

  React.useEffect(() => {
    if (customerId) {
      dispatch(fetchActiveUserPolicyListAsync(customerId));
    }
  }, []);

  return data;
};
