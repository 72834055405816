import { useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../hooks";
import React from "react";
import { Grid, Typography } from "@mui/material";
import { StandardCellList } from "../../../../components/Standards";
import { formatDate, formatTime } from "../../../../helpers";
import {
  fetchTaskDeleteVehcileAysnc,
  fetchTaskVehcileChangeAysnc,
} from "../../../../redux/generalChange/generalChangeActions";
import {
  ITaskAddVehicle,
  ITaskDeleteVehicle,
} from "../../../../redux/generalChange/generalChange.types";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../constants/enums";
import { stripHtml } from "../../../../helpers/stripHtml";
import { QuillEditor } from "../../../../components/QuillEditor/QuillEditor";

export const ViewDeleteVehicle: React.FC<{
  taskCode: string;
}> = (props) => {
  const { taskCode } = props;

  const { deleteData, deleteLoading } = useSelector(
    (storeState: IStoreState) => storeState.tasks.generalChange.vehcile
  );

  const dispatch = useDispatchWrapper();
  const [vehicleDetails, setVehicleDetails] =
    React.useState<ITaskDeleteVehicle>();

  React.useEffect(() => {
    dispatch(fetchTaskDeleteVehcileAysnc(taskCode));
  }, [taskCode]);

  React.useEffect(() => {
    setVehicleDetails(deleteData);
  }, [deleteData]);

  return (
    <PageLoader loading={deleteLoading === LoadState.InProgress}>
      {vehicleDetails && (
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Vehicle Information"
              colSize={3}
              list={[
                {
                  heading: "Vehicle",
                  data: {
                    type: "text",
                    value: vehicleDetails.vehicle,
                  },
                },
                {
                  heading: "Effective Date",
                  data: {
                    type: "text",
                    value: formatDate(
                      vehicleDetails.effective_date_of_change || ""
                    ),
                  },
                },
                {
                  heading: "Effective Time",
                  data: {
                    type: "text",
                    value: formatTime(
                      vehicleDetails.effective_time_of_change || ""
                    ),
                  },
                },
              ]}
            >
              <Grid item xs={12} md={12}>
                <Typography fontWeight={500} variant="h4" mb={1}>
                  Details
                </Typography>
                <QuillEditor
                  value={vehicleDetails.details || ""}
                  onChange={() => {}}
                  readOnly={true}
                />
              </Grid>
            </StandardCellList>
          </Grid>
        </Grid>
      )}
    </PageLoader>
  );
};
