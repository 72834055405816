import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";
import {
  ITransactions,
  initialAccountingTransactionsState,
} from "./Transactions.types";

export const FETCH_ACCOUNTING_TRANSACTIONS_PROGRESS =
  "FETCH_ACCOUNTING_TRANSACTIONS_PROGRESS";
export const FETCH_ACCOUNTING_TRANSACTIONS_SUCCESS =
  "FETCH_ACCOUNTING_TRANSACTIONS_SUCCESS";
export const FETCH_ACCOUNTING_TRANSACTIONS_FAILURE =
  "FETCH_ACCOUNTING_TRANSACTIONS_FAILURE";

export const fetchAccountingTransactionsProgress = () =>
  action(FETCH_ACCOUNTING_TRANSACTIONS_PROGRESS);

export const fetchAccountingTransactionsSuccess = (data: ITransactions) =>
  action(FETCH_ACCOUNTING_TRANSACTIONS_SUCCESS, { data });

export const fetchAccountingTransactionsFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_TRANSACTIONS_FAILURE, { errorMessage });

export const fetchAccountingTransactionsAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingTransactionsProgress());

      const res = await api.get(`/accounting/get-account-transactions`);
      const data: ITransactions[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAccountingTransactionsSuccess(data[0]));
      } else {
        dispatch(
          fetchAccountingTransactionsSuccess(
            initialAccountingTransactionsState["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchAccountingTransactionsFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingTransactionsAysnc =
  (
    data: ITransactions,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/accounting/edit-account-transactions", {
        ...data,
      });
      let message = "Transactions saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const FETCH_ACCOUNTING_TRANSACTIONS_LIST_PROGRESS =
  "FETCH_ACCOUNTING_TRANSACTIONS_LIST_PROGRESS";
export const FETCH_ACCOUNTING_TRANSACTIONS_LIST_SUCCESS =
  "FETCH_ACCOUNTING_TRANSACTIONS_LIST_SUCCESS";
export const FETCH_ACCOUNTING_TRANSACTIONS_LIST_FAILURE =
  "FETCH_ACCOUNTING_TRANSACTIONS_LIST_FAILURE";

export const fetchAccountingTransactionsListProgress = () =>
  action(FETCH_ACCOUNTING_TRANSACTIONS_LIST_PROGRESS);

export const fetchAccountingTransactionsListSuccess = (data: ITransactions[]) =>
  action(FETCH_ACCOUNTING_TRANSACTIONS_LIST_SUCCESS, { data });

export const fetchAccountingTransactionsListFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_TRANSACTIONS_LIST_FAILURE, { errorMessage });

export const fetchAccountingTransactionsListAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingTransactionsListProgress());

      const res = await api.get(`/accounting/get-account-transactions`);
      const data: ITransactions[] = res.data.data;
      dispatch(fetchAccountingTransactionsListSuccess(data));
    } catch (err: any) {
      dispatch(
        fetchAccountingTransactionsListFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_ACCOUNTING_TRANSACTION = "CLEAR_ACCOUNTING_TRANSACTION";
export const clearAccountingTransactions = () =>
  action(CLEAR_ACCOUNTING_TRANSACTION);
