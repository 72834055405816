import moment, { Moment } from "moment";
import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface ITaskDriverState {
  addDriver: {
    data: IAddDriver;
    loading: LoadState;
  };
  deleteDriver: {
    data: IDeleteDriver;
    deleteloading: LoadState;
  };
}

export interface IAddDriver {
  task_code: string;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: number | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  driver_name: string | null;
  license_number: string | null;
  license_history: IAddDriverLicenseHistory[];
  effective_date_of_change: string | null;
  effective_time_of_change: string;
  details: string | null;
  file_upload: string | null;
  insurance_reports: string | null;
  quote_number: string | null;
}


export interface IAddDriverLicenseHistory {
  key: string;
  srNumber: number;
  name: string;
  dateOfFirstLicensed: string;
  yearLicensed: string;
}

export interface IDeleteDriver {
  task_code: string;
  // task_code: string;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: number | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  driver: number| null;
  license_number: string | null;
  license_history: string | null;
  effective_date_of_change: string | null;
  effective_time_of_change: string;
  details: string | null;
  file_upload: string | null;
  insurance_reports: string | null;
  quote_number: string | null;
}

export const initialTaskDriverState: ITaskDriverState = {
  addDriver: {
    data: {
      task_code: "",
      customer_code: null,
      customer_name: null,
      policy_number: null,
      line_of_business: null,
      policy_type: null,
      branch: null,
      broker_code: null,
      insurer: null,
      producer: null,
      csr: null,
      driver_name: null,
      license_number: null,
        license_history: [
        {
          key: getUniqueId(),
          srNumber: 1,
          name: "AZ",
          dateOfFirstLicensed:  "",
          yearLicensed: "",
        },
        {
          key: getUniqueId(),
          srNumber: 2,
          name: "B",
          dateOfFirstLicensed: "",
          yearLicensed: "",
        },
        {
          key: getUniqueId(),
          srNumber: 3,
          name: "D",
          dateOfFirstLicensed: "G",
          yearLicensed: "",
        },
        {
          key: getUniqueId(),
          srNumber: 4,
          name: "G",
          dateOfFirstLicensed: "",
          yearLicensed: "",
        },
        {
          key: getUniqueId(),
          srNumber: 5,
          name: "G1",
          dateOfFirstLicensed: "",
          yearLicensed: "",
        },
        {
          key: getUniqueId(),
          srNumber: 6,
          name: "G2",
          dateOfFirstLicensed: "",
          yearLicensed: "",
        },
       
      ],
      effective_date_of_change: null,
      effective_time_of_change: moment()
      .startOf("day")
      .add(1, "minute").format(),
      details: null,
      file_upload: null,
      insurance_reports: null,
      quote_number: null,
    },
    loading: LoadState.Loaded,
  },
  deleteDriver: {
    data: {
      task_code: "",
      customer_code: null,
      customer_name: null,
      policy_number: null,
      line_of_business: null,
      policy_type: null,
      branch: null,
      broker_code: null,
      insurer: null,
      producer: null,
      csr: null,
      driver: null,
      license_number: null,
      license_history: null,
      effective_date_of_change: null,
      effective_time_of_change: moment()
      .startOf("day")
      .add(1, "minute").format(),
      details: null,
      file_upload: null,
      insurance_reports: null,
      quote_number: null
    },
    deleteloading: LoadState.Loaded,
  },
};
