import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { initialAutomobileSupplement } from "./automobileSupplement.types";
import {
  FETCH_AUTOMOBILE_SUPPLEMENT_DETAILS_FAILED,
  FETCH_AUTOMOBILE_SUPPLEMENT_DETAILS_PROGRESS,
  FETCH_AUTOMOBILE_SUPPLEMENT_DETAILS_SUCCESS,
} from "./automobileSupplementActions";
import { LoadState } from "../../constants/enums";
import { AutomobileSupplementActions } from ".";

export const automobileSupplementReducer = (
  state: IStoreState["automobile"]["supplement"] = initialAutomobileSupplement,
  action: AutomobileSupplementActions
) => {
  switch (action.type) {
    case FETCH_AUTOMOBILE_SUPPLEMENT_DETAILS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.NotLoaded;
        draftState.data = initialAutomobileSupplement["data"];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_SUPPLEMENT_DETAILS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_SUPPLEMENT_DETAILS_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.data = initialAutomobileSupplement["data"];
      });
      return newState;
    }

    default: {
      return state;
    }
  }
};
