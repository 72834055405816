import React from "react";
import CallIcon from "@mui/icons-material/Call";
import { Button, Grid } from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../formsComponents";
import { useFormik } from "formik";
import { RightPanel } from "../../RightPanel";
import { useDispatchWrapper } from "../../../hooks";
import {
  sendMessageToUserPhone,
  sendVoiceCalling,
} from "../../../redux/common/commonActions";
import { ICallDialogProps } from "./CallDialog.types";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../redux/initialStoreState";
import { fetchTemplateListAllTempsAsync } from "../../../redux/templates/templateActions";
import { ICreateTemplate } from "../../../redux/templates/templates.types";
import { api } from "../../../api/api";
import { QuillEditor } from "../../QuillEditor/QuillEditor";

export const CallDialog: React.FC<ICallDialogProps> = (props) => {
  const { mobileNumber } = props;
  const [open, setOpen] = React.useState(false);

  const handleToggleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <CallIcon color="primary" fontSize="small" onClick={handleToggleOpen} />
      {open && (
        <CallRightPanel open={open} mobileNumber={mobileNumber} onClose={handleToggleOpen}/>
      )}
    </>
  );
};

export const CallRightPanel: React.FC<{
  open: boolean;
  mobileNumber: string;
  onClose: () => void;
}> = ({ open, mobileNumber, onClose }) => {
  const dispatch = useDispatchWrapper();
  const [loading, setLoading] = React.useState(false);
  const [selectedTemplate, setSelectedTemplate] = React.useState("");
  const templates = useSelector(
    (storeState: IStoreState) => storeState.templates.list
  );
  const { values,setFieldValue, handleChange, handleSubmit } = useFormik({
    initialValues: {
      mobileNumber: mobileNumber,
      template: "",
      message: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      dispatch(
        sendVoiceCalling(
          {
            mobileNumber: values.mobileNumber,
          },
          (isSucess) => {
            if (isSucess) {
              onClose();
            }
            setLoading(false);
          }
        )
      );
    },
  });

  React.useEffect(() => {
    dispatch(fetchTemplateListAllTempsAsync());
  }, []);

  React.useEffect(() => {
    const fetchTemplate = async (temp: ICreateTemplate) => {
      try {
        const res = await api.get(
          `/templates/get-templates?templates_id=${temp.templates_id}`
        );
        const list: ICreateTemplate[] = res.data.data;
        if (list.length > 0) {
          setFieldValue("message", list[0].body);
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (selectedTemplate) {
      const template = templates.find(
        (x) => x.template_name === selectedTemplate
      );
      if (template) {
        fetchTemplate(template);
      }
    }
  }, [selectedTemplate]);


  return (
    <RightPanel
      open={open}
      heading="Send Message as Voice Calling"
      subHeading="Connecting Through Voice Calling: Delivering Messages to Specified Mobile Numbers"
      onClose={onClose}
      isWrappedWithForm
      onFormSubmit={handleSubmit}
      actionButtons={() => {
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                fullWidth
              >
                Send
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button variant="contained" fullWidth onClick={onClose}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        );
      }}
    >
      <Grid container>
        <Grid item xs={12} md={12}>
          <CustomFormLabel>Mobile Number(+1)</CustomFormLabel>

          <CustomTextField disabled fullWidth value={values.mobileNumber} />
        </Grid>
        <Grid item xs={12} md={12}>
      <CustomFormLabel>Template</CustomFormLabel>
          <ControlledCustomSelect
            fullWidth
            value={selectedTemplate}
            name="sub_mobules"
            onChange={(e) => setSelectedTemplate(e.target.value as string)}
            placeholder="Choose Template"
            displayEmpty
            options={templates.map((item) => {
              return { label: item.template_name, value: item.template_name };
            })}
          ></ControlledCustomSelect>
      </Grid>
      <Grid item xs={12} md={12}>
        <CustomFormLabel>Message</CustomFormLabel>

        <QuillEditor
            value={values.message}
            onChange={(html) => setFieldValue("message", html)}
          />
      </Grid>
      </Grid>
    </RightPanel>
  );
};
