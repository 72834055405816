import moment, { Moment } from "moment";
import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";
import { get } from "lodash";

export interface ICommAutomobileCertificatesState {
  liabilitySlips: {
    list: ICommAutomobileLiabilitySlips[];
    loading: LoadState;
    liabilitySlip: ICommAutomobileLiabilitySlips;
    liabilitySlipLoading: LoadState;
    error: string | null;
  };
  liabilityInsurance: {
    list: ICommAutomobileLiabilityInsurance[];
    loading: LoadState;
    liabilityInsurance: ICommAutomobileLiabilityInsurance;
    liabilityInsuranceLoading: LoadState;
    error: string | null;
  };
  opcf16: {
    list: ICommAutomobileOCEP16[];
    loading: LoadState;
    opcf: ICommAutomobileOCEP16;
    opcfLoading: LoadState;
    error: string | null;
  };
  consentform: {
    list: ICommAutomobileConsentForm[];
    loading: LoadState;
    consent: ICommAutomobileConsentForm;
    consentLoading: LoadState;
    error: string | null;
  };
  supplementaryform: {
    list: ICommAutomobileSupplementaryForm[];
    loading: LoadState;
    supplementary: ICommAutomobileSupplementaryForm;
    supplementaryLoading: LoadState;
    error: string | null;
  };
  dwellingdeclaration: {
    list: ICommAutomobileDwellingDeclaration[];
    loading: LoadState;
    dwelling: ICommAutomobileDwellingDeclaration;
    dwellingLoading: LoadState;
    error: string | null;
  };
  dwelling: {
    list: ICommAutomobileDwelling[];
    loading: LoadState;
    dwelling: ICommAutomobileDwelling;
    dwellingLoading: LoadState;
    error: string | null;
  };
  retireediscount: {
    list: ICommAutomobileRetireeDiscount[];
    loading: LoadState;
    retiree: ICommAutomobileRetireeDiscount;
    retireeLoading: LoadState;
    error: string | null;
  };
  garageform: {
    list: ICommAutomobileGarageForm[];
    loading: LoadState;
    garage: ICommAutomobileGarageForm;
    garageLoading: LoadState;
    error: string | null;
  };
  ownerform: {
    list: ICommAutomobileOwnerForm[];
    loading: LoadState;
    owner: ICommAutomobileOwnerForm;
    ownerLoading: LoadState;
    error: string | null;
  };
  sharingfillable: {
    list: ICommAutomobileSharingFillable[];
    loading: LoadState;
    sharing: ICommAutomobileSharingFillable;
    sharingLoading: LoadState;
    error: string | null;
  };
  opcf17: {
    list: ICommAutomobileOCEP17[];
    loading: LoadState;
    opcf: ICommAutomobileOCEP17;
    opcfLoading: LoadState;
    error: string | null;
  };
  opcf28A: {
    list: ICommAutomobileOCEP28A[];
    loading: LoadState;
    opcf: ICommAutomobileOCEP28A;
    opcfLoading: LoadState;
    error: string | null;
  };
}

export interface ICommAutomobileLiabilitySlips {
  customer_policy_id: number;
  incremental_number: number | null;
  insured: string | null;
  address: string | null;
  policy_number: string | null;
  policy_status: string | null;
  insurer_name: string | null;
  broker_name: string | null;
  effective_date: string | null;
  expiry_date: string | null;
  effective_time_of_change: string | null;
  time_of_binding: string;
  list_of_vehicle_option_one: string | null;
  list_of_vehicle_option_two: string | null;
  list_of_vehicles: any[];
  status: string;
}

export interface ICommAutomobileLiabilityInsurance {
  customer_policy_id: number;
  incremental_number: number | null;
  cgl_policy_number: string | null;
  automobile_policy: string | null;
  umbrella_or_excess_liability_policy: string | null;
  other_policy: string | null;
  non_owned_automobiles_policy: string | null;
  hired_automobiles: string | null;
  vehicle_unit_number_or_vin: string | null;
  certificate_holder_name: string | null;
  certificate_holder_address: string | null;
  certificate_holder_email: string | null;
  certificate_holder_phone: string | null;
  description_of_operations: {
    key: string;
    label: string;
    value: string;
  }[];
  commercial_general_liability_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  commercial_general_liability_section_two: {
    claim_mode: string;
    claim_mode_deductible: string | number;
    claim_mode_amount: string | number;
    occurance: string;
    occurance_deductible: string | number;
    occurance_amount: string | number;
    products_completed_oprations: string;
    products_completed_oprations_deductible: string | number;
    products_completed_oprations_amount: string | number;
    empolyer_liability: string;
    empolyer_liability_peronal_injury: string;
    empolyer_liability_deductible: string | number;
    empolyer_liability_amount: string | number;
    waiver_of_subrogation: string;
    waiver_of_subrogation_peronal_injury: string;
    waiver_of_subrogation_deductible: string | number;
    waiver_of_subrogation_amount: string | number;
    cross_liability: string;
    cross_liability_deductible: string | number;
    cross_liability_amount: string | number;
    tenants_Legal_Liability: string;
    tenants_Legal_Liability_deductible: string | number;
    tenants_Legal_Liability_amount: string | number;
    pollution_Liability_Extension: string;
    pollution_Liability_Extension_deductible: string | number;
    pollution_Liability_Extension_amount: string | number;
  };
  commercial_general_liability_section_three: {
    key: string;
    type_of_insurance_check: string;
    type_of_insurance: string;
    coverage: string;
    deductible: string | number;
    amount: string | number;
  }[];
  non_owned_automobiles_section_one: {
    insurance_company: string | null;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  non_owned_automobiles_section_two: {
    non_owned: string;
    non_owned_deductible: string;
    non_ownder_amount: string;
  };
  hired_automobiles_section_one: {
    insurance_company: string | null;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  hired_automobiles_section_two: {
    hired_type_of_insurance: string;
    hired_amount: string;
    hired_deductible: string;
  };
  automobile_liability_section_one: {
    insurance_company: string | null;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  automobile_liability_section_two: {
    described_automobiles: string;
    described_automobiles_deductible: string;
    described_automobiles_amount: string;
    all_owned_automobiles: string;
    all_owned_automobiles_deductible: string;
    all_owned_automobiles_amount: string;
    leased_automobiles: string;
    leased_automobiles_deductible: string;
    leased_automobiles_amount: string;
    property_damage_deductible: string;
    property_damage_amount: string;
  };
  excess_liability_section_one: {
    insurance_company: string | null;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  excess_liability_section_two: {
    umbrella_form: string;
    umbrella_form_deductible: string;
    umbrella_form_amount: string;
    aggreate_deductible: string;
    aggreate_amount: string;
    extra: string;
    extra_type_of_insurance: string;
    extra_coverage: string;
    extra_deductible: string;
    extra_amount: string;
  };
  other_liability_section_one: {
    insurance_company: string | null;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  other_liability_section_two: {
    key: string;
    type_of_insurance_check: string;
    type_of_insurance: string;
    coverage: string;
    deductible: string | number;
    amount: string | number;
  }[];
  cancelation_number_of_days: string | null;
  brokerage_full_name: string | null;
  brokerage_mailing_address: string | null;
  brokerage_customer_code: string | null;
  additional_name_insured_yes_or_no: string | null;
  additional_name_insured_name: string | null;
  additional_name_insured_mailing_address: string | null;
  certificate_authorization_branch_name: string | null;
  certificate_authorization_assigned_to: string | null;
  certificate_authorization_phone_number: string | null;
  certificate_authorization_fax_number: string | null;
  certificate_authorization_email: string | null;
  certificate_authorization_date: string | null;
  status: string;
}

export interface ICommAutomobileOCEP16 {
  customer_policy_id: number;
  incremental_number: number | null;
  issued_to: string | null;
  policy_number: string | null;
  effective_date_of_change: string | null;
  policy_status: string | null;
  automobile_data: any;
  status: string;
  checkbox_this_change_applies: string;
  this_change_applies_only_to_automobile: string | null;
  indicated_on_your_certificate: string | null;
  checkbox_see_your_certificate_of_automobile: string;
  see_your_certificate_of_automobile: string | null;
}

export interface ICommAutomobileConsentForm {
  customer_policy_id: number;
  customer_id: number | null;
  incremental_number: number | null;
  customer_name: string | null;
  email_address: string | null;
  text_field: string | null;
  status: string;
  created_by_id: number | null;
  modified_by_id: number | null;
  insert_ts: string | null;
  create_ts: string | null;
}
export interface ICommAutomobileuseColumn {
  location_a: string | null;
  location_b: string | null;
  location_c: string | null;
  location_d: string | null;
}
export interface ICommAutomobileuseColumn4 {
  location_a: string | null;
  location_b: string | null;
  location_c: string | null;
  location_d: string | null;
  total: string | null;
}
export interface ICommAutomobileuseColumn3 {
  location_a_yes_or_no: string | null;
  location_b_yes_or_no: string | null;
  location_c_yes_or_no: string | null;
  location_d_yes_or_no: string | null;
  comments_description: string | null;
}
export interface ICommAutomobileuseColumn2 {
  location_a_ft: string | null;
  location_a_pt: string | null;
  location_b_ft: string | null;
  location_b_pt: string | null;
  location_c_ft: string | null;
  location_c_pt: string | null;
  location_d_ft: string | null;
  location_d_pt: string | null;
}

export interface ICommAutomobileSupplementaryForm {
  customer_policy_id: number;
  customer_id: number | null;
  incremental_number: number | null;
  customer_name: string | null;
  broker: string | null;
  effective_date: string | null;
  section_a: {
    in_business_since: ICommAutomobileuseColumn;
    sales_of_new_vehicles: ICommAutomobileuseColumn;
    sales_of_used_vehicles: ICommAutomobileuseColumn;
    leasing_of_new_or_used_vehicles: ICommAutomobileuseColumn;
    body_shop: ICommAutomobileuseColumn;
    body_shop_including_car_sales: ICommAutomobileuseColumn;
    repair_garage: ICommAutomobileuseColumn;
    repair_garage_including_car_sales: ICommAutomobileuseColumn;
    service_station: ICommAutomobileuseColumn;
    service_station_self_serve: ICommAutomobileuseColumn;
    parking_lot: ICommAutomobileuseColumn;
    parking_lot_self_park: ICommAutomobileuseColumn;
    storage_garage: ICommAutomobileuseColumn;
    storage_garage_self_store: ICommAutomobileuseColumn;
    valet_parking: ICommAutomobileuseColumn;
    valet_parking_fee_charged: ICommAutomobileuseColumn;
    car_wash: ICommAutomobileuseColumn;
    car_wash_automatic: ICommAutomobileuseColumn;
    other_detail_remarks: string | null;
  };

  private_passenger_checkbox: string | null;
  light_commercial_checkbox: string | null;
  heavy_commercial_checkbox: string | null;
  trailers_checkbox: string | null;
  commercial_trailers_checkbox: string | null;
  antique_or_classic_checkbox: string | null;
  exotic_racing_checkbox: string | null;
  motorcycles_checkbox: string | null;
  atv_checkbox: string | null;
  snowmobiles_checkbox: string | null;
  motorhomes_checkbox: string | null;
  other_checkbox: string | null;
  other_value: string | null;

  proprietors: ICommAutomobileuseColumn2;
  sales_staff: ICommAutomobileuseColumn2;
  mechanics: ICommAutomobileuseColumn2;
  lot_persons: ICommAutomobileuseColumn2;
  body_shop_workers: ICommAutomobileuseColumn2;
  full_service_station_attendants: ICommAutomobileuseColumn2;
  clerical: ICommAutomobileuseColumn2;
  parts_department_staff: ICommAutomobileuseColumn2;
  parking_lot_attendant: ICommAutomobileuseColumn2;
  valet_parking: ICommAutomobileuseColumn2;
  summary_of_employees_other_details: string | null;

  employee_information_including_owners: {
    key: string;
    name: string | null;
    drivers_licence_number: string | null;
    birth_date: string;
    position: string | null;
    years_lincence: string | null;
    licence_class: string | null;
    full_or_part_time: string | null;
    convictions_or_suspensions_for_the_past_six_years: string | null;
  }[];

  other_operators: {
    key: string;
    name: string | null;
    drivers_licence_number: string | null;
    birth_date: string;
    relationship_to_the_named_insured: string | null;
    years_lincence: string | null;
    licence_class: string | null;
    convictions_or_suspensions_for_the_past_six_years: string | null;
  }[];

  claims: {
    key: string;
    driver_name: string | null;
    date_of_loss: string;
    type_of_loss: string | null;
    amount_paid: string | null;
    descriptiom: string | null;
  }[];
  are_all_active_vehicles_owned_by_yes_or_no: string | null;
  are_all_active_vehicles_owned_by_no_reason: string | null;
  number_of_dealer_plates: ICommAutomobileuseColumn4;
  number_of_service_plates: ICommAutomobileuseColumn4;
  total_number_of_white_plates: ICommAutomobileuseColumn4;
  dealer_plate_numbers: string | null;
  service_plate_numbers: string | null;
  private_passenger: ICommAutomobileuseColumn4;
  commercial_tow_trucks: ICommAutomobileuseColumn4;
  commercial_tilt_and_load: ICommAutomobileuseColumn4;
  commercial_parts_truck: ICommAutomobileuseColumn4;
  commercial_service_trucks: ICommAutomobileuseColumn4;
  commercial_shuttle_vehicles: ICommAutomobileuseColumn4;
  commercial_other: ICommAutomobileuseColumn4;
  courtesy_cars: ICommAutomobileuseColumn4;
  summary_of_all_active_vehicles_other_details: string | null;
  details_of_all_vehicles_owned_by_the_applicant: {
    key: string;
    year: string | null;
    make_model_body_type: string | null;
    vin: string | null;
    gvw: string | null;
    list_price_new: string | null;
    use: string | null;
    driver_principal_and_occasional: string | null;
  }[];
  owned_automobiles_including_vehicles: {
    maximum_number_in_building_location_a: string | null;
    maximum_number_in_building_location_b: string | null;
    maximum_number_in_building_location_c: string | null;
    maximum_number_in_building_location_d: string | null;
    maximum_number_on_lot_location_a: string | null;
    maximum_number_on_lot_location_b: string | null;
    maximum_number_on_lot_location_c: string | null;
    maximum_number_on_lot_location_d: string | null;
    maximum_value_location_a: string | null;
    maximum_value_location_b: string | null;
    maximum_value_location_c: string | null;
    maximum_value_location_d: string | null;
    average_value_location_a: string | null;
    average_value_location_b: string | null;
    average_value_location_c: string | null;
    average_value_location_d: string | null;
  };
  customer_automobiles: {
    maximum_number_in_building_location_a: string | null;
    maximum_number_in_building_location_b: string | null;
    maximum_number_in_building_location_c: string | null;
    maximum_number_in_building_location_d: string | null;
    maximum_number_on_lot_location_a: string | null;
    maximum_number_on_lot_location_b: string | null;
    maximum_number_on_lot_location_c: string | null;
    maximum_number_on_lot_location_d: string | null;
    maximum_value_location_a: string | null;
    maximum_value_location_b: string | null;
    maximum_value_location_c: string | null;
    maximum_value_location_d: string | null;
    average_value_location_a: string | null;
    average_value_location_b: string | null;
    average_value_location_c: string | null;
    average_value_location_d: string | null;
  };
  during_business_hours: string | null;
  after_business_hours: string | null;
  are_driving_record_abstracts_obtained_yes_or_no: string | null;
  how_often: string | null;

  outside_floodlights: ICommAutomobileuseColumn3;
  burglary_alarm_system: ICommAutomobileuseColumn3;
  fenced_compound: ICommAutomobileuseColumn3;
  guard_dogs: ICommAutomobileuseColumn3;
  night_watchman: ICommAutomobileuseColumn3;
  other_describe_below: ICommAutomobileuseColumn3;

  security_measures_other_details: string | null;
  does_the_applicant_pick_up_or_deliver_yes_or_no: string | null;
  does_the_applicant_pick_up_or_deliver_value: string | null;
  number_of_spray_booths: string | null;
  inspected_and_approved_yes_or_no: string | null;
  spraying: string | null;
  welding: string | null;
  does_the_applicant_dispense_propane_yes_or_no: string | null;
  number_of_licensed_employees: string | null;
  give_details_of_any_contractual_liability: string | null;
  what_is_the_maximum_number_of_automobiles: string | null;
  how_many_tow_trucks_can_carry: string | null;
  does_the_applicant_have_written_rules_yes_or_no: string | null;
  does_the_applicant_have_written_rules_value: string | null;
  is_demonstrator_use_restricted_yes_or_no: string | null;
  including_spouse_yes_or_no: string | null;
  including_children_yes_or_no: string | null;
  others: string | null;
  is_vacation_use_of_owned_vehicles_yes_or_no: string | null;
  are_customers_permitted_to_test_drive_yes_or_no: string | null;
  is_customer_always_accompanied_yes_or_no: string | null;
  describe_procedures_for_other_precautions_taken: string | null;
  if_sales_include_recreational_vehicles_yes_or_no: string | null;
  is_applicant_currently_registered_yes_or_no: string | null;
  where_and_how_are_vehicles_held: string | null;
  lien_checks_yes_or_no: string | null;
  consignment_yes_or_no: string | null;
  if_yes_what_policies_and_procedures: string | null;
  indicate_number_and_average_retail_values_sold: {
    third_year_back_number_sold: string | null;
    third_year_back_average_retail_value: string | null;
    second_year_back_number_sold: string | null;
    second_year_back_average_retail_value: string | null;
    expiring_year_number_sold: string | null;
    expiring_year_average_retail_value: string | null;
    expected_for_coming_year_number_sold: string | null;
    expected_for_coming_year_average_retail_value: string | null;
  };
  have_you_bound_this_risk_yes_or_no: string | null;
  is_this_business_new_to_you_yes_or_no: string | null;
  how_long_have_you_known_the_applicant: string | null;
  report_of_broker_other_details: string | null;
  status: string;
  created_by_id: number | null;
  modified_by_id: number | null;
  insert_ts: string | null;
  create_ts: string | null;
}
export interface ICommAutomobileSharingFillable {
  customer_policy_id: number;
  customer_id: number | null;
  incremental_number: number | null;
  customer_name: string | null;
  policy_number: string | null;
  status: string;
  created_by_id: number | null;
  modified_by_id: number | null;
  insert_ts: string | null;
  create_ts: string | null;
}

export interface ICommAutomobileRetireeDiscount {
  customer_policy_id: number;
  customer_id: number | null;
  incremental_number: number | null;
  customer_name: string | null;
  policy_number: string | null;
  effective_date_of_discount: string | null;
  insurer: string | null;
  branch_code: string | null;
  retiree_name: string | null;
  status: string;
  created_by_id: number | null;
  modified_by_id: number | null;
  insert_ts: string | null;
  create_ts: string | null;
}
export interface ICommAutomobileOwnerForm {
  customer_policy_id: number;
  customer_id: number | null;
  incremental_number: number | null;
  policy_number: string | null;
  insurance_company: string | null;
  new_policy: string | null;
  renewal_policy: string | null;
  policy_language: string | null;
  customer_name: string | null;
  customer_address: string | null;
  customer_unit_or_suite: string | null;
  customer_city: string | null;
  customer_province_or_state: string | null;
  customer_postal_code: string | null;
  customer_country: string | null;
  contact_number: string | null;
  contact_business: string | null;
  contact_res: string | null;
  contact_mobile: string | null;
  agent_or_broker: string | null;
  policy_billing: string | null;
  email_address: string | null;
  broker_bill: string | null;
  company_bill: string | null;
  payment_plan: string | null;
  effective_time: string | null;
  effective_date: string | null;
  expiry_date: string | null;
  particulars_of_the_described_section_one: [
    {
      model_year: string;
      trade_name: string | null;
      model_or_cc: string | null;
      body_type: string | null;
      vin: string | null;
      year: string | null;
      month: string | null;
      new_or_used: string | null;
      purchase_price_including_accessories_equipment: string | null;
    }
  ];
  particulars_of_the_described_section_two: [
    {
      state_name: string | null;
      postal_address: string | null;
      postal_code: string | null;
      assignee: string | null;
      lienholder: string | null;
      lessor: string | null;
    }
  ];
  particulars_of_the_described_section_three: [
    {
      gvw: string | null;
      yes_or_no: string | null;
      list_price_new: string | null;
      vehicle_code: string | null;
      terr: string | null;
      loc: string | null;
      class: string | null;
      drtpl: string | null;
      dr_coll: string | null;
      dcpd: string | null;
      coll: string | null;
      comp: string | null;
    }
  ];
  limits_and_amounts_in_dollars: [
    {
      legal_liability_for_bodily_injury: string | null;
      amount_deductible: string | null;
      all_perils: string | null;
      collision_or_upset: string | null;
      comprehensive: string | null;
      specified_perils: string | null;
      vehicle_number: string | null;
      endorsement_number: string | null;
    }
  ];
  premium_in_dollars: [
    {
      pd: string | null;
      bi: string | null;
      yes_or_no: string | null;
      amount_deductible: string | null;
      payments_for_death_or_bodily_injury: string | null;
      all_perils: string | null;
      collision_or_upset: string | null;
      comprehensive: string | null;
      specified_perils: string | null;
      sef_premium: string | null;
      vehicle_premium: string | null;
    }
  ];
  minimum_retained_premium: string | null;
  total_estimated_policy_premium: string | null;
  list_all_drivers_part_one: [
    {
      name: string | null;
      drivers_licence_number: string | null;
      date_of_birth: string | null;
    }
  ];
  list_all_drivers_part_two: [
    {
      date_lic: string | null;
      lic_class: string | null;
      grid_step: string | null;
      vehicle_one: string | null;
      vehicle_two: string | null;
      vehicle_three: string | null;
      vehicle_four: string | null;
      driver_occupation: string | null;
      driver_training_yes_or_no: string | null;
    }
  ];
  give_particulars_of_all_convictions: [
    {
      driver_number: string | null;
      date: string | null;
      description: string | null;
    }
  ];
  give_particulars_of_all_accidents: [
    {
      vehicle_number: string | null;
      driver_number: string | null;
      date: string | null;
      type_of_claim: string | null;
      amount_paid_or_estimate: string | null;
      claim_amount_repaid_to_insurer: string | null;
      description: string | null;
    }
  ];
  has_any_driver_licence: string | null;
  section_eight_a_insurer: string | null;
  section_eight_a_reason: string | null;
  section_eight_a_policy_number: string | null;
  section_eight_b_insurer: string | null;
  section_eight_b_policy_number: string | null;
  section_eight_b_expiry_date: string | null;
  section_eight_c_yes_or_no: string | null;
  section_nine_a: {
    vehicle_number_one: string | null;
    vehicle_number_two: string | null;
    vehicle_number_three: string | null;
    vehicle_number_four: string | null;
  };
  section_nine_b: [
    {
      yes_or_no: string | null;
      distance_one_way: string | null;
    }
  ];
  section_nine_c: {
    state_the_usual_percentage_one: string | null;
    state_the_usual_percentage_two: string | null;
    state_the_usual_percentage_three: string | null;
    state_the_usual_percentage_four: string | null;
  };
  section_nine_d: [
    {
      yes_or_no: string | null;
      number_of_months: string | null;
    }
  ];
  section_nine_yes_or_no: string | null;
  section_ten: [
    {
      will_the_automobile_be_rented: string | null;
      will_the_automobile_be_used_for_the_transportation_of_goods:
        | string
        | null;
    }
  ];
  the_registered_owner: string | null;
  the_actual_owner: string | null;
  section_twelve_remarks: string | null;
  endorsements_vehicle_one: [
    {
      sef_number: string | null;
      description: string | null;
      limit_one: string | null;
      limit_two: string | null;
      deductible: string | null;
      percentage: string | null;
      premium: string | null;
    }
  ];
  endorsements_vehicle_two: [
    {
      sef_number: string | null;
      description: string | null;
      limit_one: string | null;
      limit_two: string | null;
      deductible: string | null;
      percentage: string | null;
      premium: string | null;
    }
  ];
  endorsements_vehicle_three: [
    {
      sef_number: string | null;
      description: string | null;
      limit_one: string | null;
      limit_two: string | null;
      deductible: string | null;
      percentage: string | null;
      premium: string | null;
    }
  ];
  endorsements_vehicle_four: [
    {
      sef_number: string | null;
      description: string | null;
      limit_one: string | null;
      limit_two: string | null;
      deductible: string | null;
      percentage: string | null;
      premium: string | null;
    }
  ];
  section_fourteen_part_one: [
    {
      name: string | null;
      date_completed: string | null;
      type: string | null;
      sex: string | null;
      marital_status: string | null;
      relationship_to_applicant: string | null;
    }
  ];
  section_fourteen_part_two: [
    {
      date_of_mvr: string | null;
      code: string | null;
      percntage: string | null;
      description: string | null;
    }
  ];
  section_fifteen: [
    {
      name: string | null;
      address: string | null;
      date_hired: string | null;
    }
  ];
  section_sixteen: [
    {
      full_name: string | null;
      birth_date: string | null;
      driver_licence_number: string | null;
      own_a_vehicle: string | null;
      non_licensed_resident: string | null;
    }
  ];
  section_seventeen: [
    {
      yes_or_no: string | null;
      number_of_passengers: string | null;
      number_of_times: string | null;
      per: string | null;
    }
  ];
  section_eighteen: {
    vehicle_number_one: string | null;
    vehicle_number_two: string | null;
    vehicle_number_three: string | null;
    vehicle_number_four: string | null;
  };
  section_nineteen: {
    vehicle_number_one: string | null;
    vehicle_number_two: string | null;
    vehicle_number_three: string | null;
    vehicle_number_four: string | null;
  };
  section_twenty: [
    {
      value: string | null;
      describe: string | null;
    }
  ];
  section_twenty_one: [
    {
      device_type: string | null;
      device_characteristics: string | null;
      product_code: string | null;
    }
  ];
  section_twenty_two_value_one: string | null;
  section_twenty_two_value_two: string | null;
  total_number_of_private_passenger_vehicles: string | null;
  section_twenty_four_remarks: string | null;
  have_you_bound_this_risk: string | null;
  is_this_business_new_to_your_office: string | null;
  motor_vehicle_liability: string | null;
  the_applicant: string | null;
  driver_n_a: string | null;
  the_principal_operator: string | null;
  driver_n_b: string | null;
  provide_applicant_email_address: string | null;
  does_your_client_have_otheer_insurance: string | null;
  policy_n_a: string | null;
  policy_n_b: string | null;
  if_yes_give_particulars_one: string | null;
  are_there_any_special_circumstances: string | null;
  if_yes_give_particulars_two: string | null;
  is_this_risk_eligible: string | null;
  if_yes_give_particulars_three: string | null;
  was_the_supplementary: string | null;
  if_yes_give_particulars_four: string | null;
  broker_or_agent_name: string | null;
  describe_any_trailer_not_already_listed: string | null;
  commercial_rated_vehicle: string | null;
  section_twenty_eight: [
    {
      does_vehicle_weight_exceed_forty_thousand_five_hundred_kg: string | null;
      is_operating_radius_greater_than_forty_km_from_place_vehicle:
        | string
        | null;
    }
  ];
  section_twenty_nine: [
    {
      percentage_of_pleasure_use: string | null;
      delete: string | null;
      wholesale: string | null;
      retail: string | null;
      hauling_done_for_others: string | null;
      specify: string | null;
    }
  ];
  section_thirty: [
    {
      if_yes_describe: string | null;
    }
  ];
  section_thirty_one: [
    {
      value: string | null;
    }
  ];
  section_thirty_two: [
    {
      if_yes_describe_and_name_owner: string | null;
    }
  ];
  section_thirty_three_remarks: string | null;
  status: string;
  created_by_id: number | null;
  modified_by_id: number | null;
  insert_ts: string | null;
  create_ts: string | null;
}
export interface ICommAutomobileGarageForm {
  customer_policy_id: number;
  customer_id: number | null;
  incremental_number: number | null;
  policy_number: string | null;
  insurance_company: string;
  new: string | null;
  replacing_policy_number: string | null;
  customer_name: string | null;
  customer_address: string | null;
  customer_unit_or_suite: string | null;
  customer_city: string | null;
  customer_province_or_state: string | null;
  customer_postal_code: string | null;
  customer_country: string | null;
  contact_business: string | null;
  contact_home: string | null;
  contact_fax: string | null;
  broker_client_id: string | null;
  company_client_id: string | null;
  broker_or_agent: string | null;
  branch: string | null;
  code: string | null;
  broker_or_agent_bill: string | null;
  company_bill: string | null;
  payment_plan: string | null;
  business_address: string | null;
  section_a: {
    key: string;
    srNo: string;
    location_of_other_premises: null;
    structure_type_one_lot: null;
    structure_type_two_lot: null;
  }[];
  effective_time: string | null;
  effective_date: string | null;
  expiry_date: string | null;
  the_automobiles_in_respect: string | null;
  estimated_total_payroll: string | null;
  full_time: string | null;
  part_time: string | null;
  policy_commauto_vcs_schedule_d_id: number;
  coverage_summary_incremental_number: string | null;
  leinholder_name: string | null;
  insurer: string | null;
  reason: string | null;
  injury_to_persons: string | null;
  collision: string | null;
  other: string | null;
  not_in_care_of_applicant: string | null;
  in_care_of_applicant: string | null;
  remarks: string | null;
  print_name: string | null;
  brokerage_or_agent_name: string | null;
  status: string;
  created_by_id: number | null;
  modified_by_id: number | null;
  insert_ts: string | null;
  create_ts: string | null;
}

export interface ICommAutomobileDwellingDeclaration {
  customer_policy_id: number;
  customer_id: number | null;
  incremental_number: number | null;
  customer_name: string | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
  policy_number: string | null;
  this_policy_is_for_rental_dwelling: string | null;
  this_policy_is_for_student_housing: string | null;
  we_understand_that_if_any_of_the_above: string | null;
  name_relation_living_since: {
    // key: string,
    name: string;
    relation: string;
    living_since: string;
  }[];
  status: string;
  created_by_id: number | null;
  modified_by_id: number | null;
  insert_ts: string | null;
}
export interface ICommAutomobileDwelling {
  customer_policy_id: number;
  customer_id: number | null;
  incremental_number: number | null;
  policy_number: string | null;
  applicant_name: string | null;
  is_the_applicant_a_company: string | null;
  name_of_principal: string | null;
  date_of_birth: string;
  any_additional_applicants: string | null;
  province_of_primary_residence: string | null;
  source_of_applicants_income: string | null;
  out_of_last_twelve_months: string | null;
  how_many_mortages_encumbrances_are_on_the_property: string | null;
  is_any_mortage_held_with_private_or_non_conve_lender: string | null;
  how_many_non_conv_or_private_lender_required_to_list_prop: string | null;
  if_three_or_more_what_is_the_reason_for_the_third_mortgage: string | null;
  how_much_equity_does_the_applicant_retain_the_home: string | null;
  is_the_applicant_behind_late_in_making_their_mortgage_payments: string;
  risk_information: {
    risk_location_address: string | null;
    unit_or_suite: string | null;
    city: string | null;
    province: string | null;
    postal_code: string | null;
    is_the_home_located_on_leased_land: string;
    if_yes_how_long_a_term_remains_in_the_land_lease_no_years: string;
    size_of_property_in_acres: string;
  };
  underwriting_building_information: {
    year_built: string;
    type_of_building: string;
    type_of_construction: string;
    type_of_foundation: string;
    no_of_stories: string;
    square_footage: string;
  };
  underwriting_renovation_or_demolition: {
    will_construction__renovation_activity_on_next_12_months: string;
    if_yes_please_check_all_that_apply: string;
    what_is_the_budget_for_renovations: string;
    will_there_be_any_structural_renov_adding_or_removing_walls: string;
    will_the_home_be_demolished_in_the_next_24_months: string;
    if_yes_please_describe: string;
  };
  underwriting_building_updates_electrical: {
    how_many_amps_is_the_electrical_system: string;
    electrical_details: string;
    type_of_wiring: string;
    if_there_is_any_aluminum_wiring: string;
    what_percentage_is_aluminum: string;
    has_it_been_inspected_and_approved_by_a_licensed_electrician: string;
    is_there_a_knob_tube_wiring_in_any_kitchen_or_laundry_area: string;
    electrical_year_of_most_recent_electrical_update: string;
  };
  underwriting_building_updates_roof: {
    roof_material: string;
    year_of_most_recent_roof_update: string;
  };
  underwriting_building_updates_plumbing: {
    plumbing_types: string;
    year_of_plumbing_update: string;
    year_of_hot_water_tank: string;
  };
  underwriting_building_updates_heating: {
    primary_heat_device_appliance_to_regularly_heat_the_home: string;
    heating_other_please_describe: string;
    what_is_the_primary_heating_fuel_source: string;
    home_solid_fuel_heat_device_other_than_traditional_fireplace: string;
    if_yes_woo_heat_the_primary_pellets_burned_annually: string;
    was_the_solid_fuel_heat_device_professionally_installed: string;
    is_there_was_a_passed_wett_inspection_on_file: string;
    if_yes_please_attach_a_copy_of_the_wett_inspection: string;
    have_modification_been_made_to_the_solid_fuel_heat_device: string;
    solid_fuel_heat_device__chimney_cleaned_every_year: string;
    ashes_disposed_metal_lidded_cont_placed_non_flammable_surface: string;
    at_least_24_inches_of_clear_space_combustible_materials: string;
  };
  underwriting_building_updates_other: {
    does_the_home_have_an_oil_tank: string;
    if_yes_year_of_oil_tank: string;
    location_of_oil_tank: string;
    are_there_operational_smoke_detectors: string;
    is_there_an_operational_sprinkler_system: string;
    is_there_a_swimming_pool: string;
  };
  underwriting_building_updates_wildfire_and_flood: {
    risk_located_evacuated_or_notice_of_evacuation_wildfire_or_flood: string;
    wildfire_and_food_if_yes_please_provide_details: string;
    is_this_risk_located_within_25km_of_a_current_wildfire: string;
    risk_located_in_an_area_that_is_currently_under_flood_warning: string;
    of_common_kitchens: string;
    distance_to_fire_hydrant: string;
    distance_to_firehall: string;
  };
  occupancy: {
    confirm_occupancy: string;
    minimum_rental_arrangements_for_this_property: string;
    do_allow_your_tenants_to_sublet_or_are_any_occupants_subtenants: string;
    if_rented_how_long_is_the_rental: string;
    are_there_any_commercial_occupancies_in_the_building: string;
    if_yes_are_there_any_restaurants_in_the_building: string;
    does_the_owners_child_live_in_the_dwelling: string;
    how_many_rented_self_contained_units_are_there: string;
    who_lives_in_the_home: string;
    if_students_of_students: string;
    is_this_a_faternity_or_sorority_house: string;
    does_the_owners_child_or_relative_live_in_the_home: string;
    who_is_responsible_for_the_care_and_maintenance_of_the_property: string;
    is_there_farming_business_or_commercial_operations_on_premises: string;
    if_yes_please_describe: string;
  };
  coverage_information: {
    residential_building_limit: string;
    annual_rental_income: string;
    premises_liability: string;
    deductible: string;
    loss_assessment: string;
    unit_owners_imporovements_and_betterments: string;
    condominium_deductible_assessment_coverage: string;
    is_sewer_backup_coverage_requested: string;
    is_earthquake_coverage_requested: string;
  };
  mortgages_or_loss_payees: IMortgagesLossPayee[];
  policy_term_info_proposed_effective_date: string | null;
  mailing_address: string | null;
  mailing_unit_or_suite: string | null;
  mailing_city: string | null;
  mailing_province: string | null;
  mailing_postal_code: string | null;
  brokerage: string | null;
  broker_id: string | null;
  broker_email: string | null;
  tel: string | null;
  fax: string | null;
  status: string;
}

export interface IMortgagesLossPayee {
  key: string;
  full_name: string | null;
  address: string;
  city: string | null;
  province: string | null;
  postal_code: string | null;
}

export interface ICommAutomobileOCEP17 {
  customer_policy_id: number;
  incremental_number: number | null;
  issued_to: string | null;
  policy_number: string | null;
  effective_date_of_change: string | null;
  policy_status: null;
  automobile_data: any;
  status: string;
  checkbox_this_change_applies_only_to_automobile: string;
  this_change_applies_only_to_automobile: string | null;
  indicated_on_your_certificate_of_automobile: string | null;
  checkbox_see_your_certificate_of_automobile: string;
  see_your_certificate_of_automobile: string | null;
}

export interface ICommAutomobileOCEP28A {
  customer_policy_id: number;
  incremental_number: number | null;
  issued_to: string | null;
  effective_date_of_change: string | null;
  policy_number: string | null;
  automobile_number: string | null;
  model: string | null;
  year: string | null;
  trade_name: string | null;
  serial_number_or_vin: string | null;
  name_of_excluded_driver: string | null;
  driver_license_number: string | null;
  status: string;
}

export const initialCommAutomobileCertificatesState: ICommAutomobileCertificatesState =
  {
    liabilitySlips: {
      list: [],
      loading: LoadState.NotLoaded,
      liabilitySlip: {
        customer_policy_id: 0,
        incremental_number: null,
        insured: null,
        address: null,
        policy_number: null,
        policy_status: null,
        insurer_name: null,
        broker_name: null,
        effective_date: moment().format("YYYY-MM-DD"),
        expiry_date: null,
        effective_time_of_change: moment()
          .startOf("day")
          .add(1, "minute")
          .toString(),
        time_of_binding: moment().format(),
        list_of_vehicle_option_one: null,
        list_of_vehicle_option_two: null,
        list_of_vehicles: [],
        status: "ACTIVE",
      },
      liabilitySlipLoading: LoadState.NotLoaded,
      error: null,
    },
    liabilityInsurance: {
      list: [],
      loading: LoadState.NotLoaded,
      liabilityInsurance: {
        incremental_number: null,
        customer_policy_id: 0,
        cgl_policy_number: null,
        automobile_policy: null,
        umbrella_or_excess_liability_policy: null,
        other_policy: null,
        non_owned_automobiles_policy: null,
        hired_automobiles: null,
        vehicle_unit_number_or_vin: null,
        certificate_holder_name: null,
        certificate_holder_address: null,
        certificate_holder_email: null,
        certificate_holder_phone: null,
        description_of_operations: [
          { key: getUniqueId(), label: "Line 1", value: "" },
          { key: getUniqueId(), label: "Line 2", value: "" },
          { key: getUniqueId(), label: "Line 3", value: "" },
          { key: getUniqueId(), label: "Line 4", value: "" },
        ],
        commercial_general_liability_section_one: {
          insurance_company: "",
          policy_number: "",
          effective_date: "",
          expiry_date: "",
        },
        commercial_general_liability_section_two: {
          claim_mode: "N",
          claim_mode_deductible: "",
          claim_mode_amount: "5,000,000",
          occurance: "N",
          occurance_deductible: "1,000",
          occurance_amount: "2,000,000",
          products_completed_oprations: "N",
          products_completed_oprations_deductible: "",
          products_completed_oprations_amount: "2,000,000",
          empolyer_liability: "N",
          empolyer_liability_peronal_injury: "N",
          empolyer_liability_deductible: "",
          empolyer_liability_amount: "",
          waiver_of_subrogation: "N",
          waiver_of_subrogation_peronal_injury: "N",
          waiver_of_subrogation_deductible: "",
          waiver_of_subrogation_amount: "2,000,000",
          cross_liability: "N",
          cross_liability_deductible: "",
          cross_liability_amount: "5,000",
          tenants_Legal_Liability: "N",
          tenants_Legal_Liability_deductible: "1,000",
          tenants_Legal_Liability_amount: "250,000",
          pollution_Liability_Extension: "N",
          pollution_Liability_Extension_deductible: "",
          pollution_Liability_Extension_amount: "",
        },
        commercial_general_liability_section_three: [
          {
            key: getUniqueId(),
            type_of_insurance_check: "",
            type_of_insurance: "",
            coverage: "",
            deductible: "",
            amount: "",
          },
          {
            key: getUniqueId(),
            type_of_insurance_check: "",
            type_of_insurance: "",
            coverage: "",
            deductible: "",
            amount: "",
          },
        ],
        non_owned_automobiles_section_one: {
          insurance_company: "",
          policy_number: "",
          effective_date: "",
          expiry_date: "",
        },
        non_owned_automobiles_section_two: {
          non_owned: "N",
          non_ownder_amount: "",
          non_owned_deductible: "",
        },
        hired_automobiles_section_one: {
          insurance_company: "",
          policy_number: "",
          effective_date: "",
          expiry_date: "",
        },
        hired_automobiles_section_two: {
          hired_type_of_insurance: "N",
          hired_amount: "",
          hired_deductible: "",
        },
        automobile_liability_section_one: {
          insurance_company: "",
          policy_number: "",
          effective_date: "",
          expiry_date: "",
        },
        automobile_liability_section_two: {
          described_automobiles: "N",
          described_automobiles_deductible: "",
          described_automobiles_amount: "",
          all_owned_automobiles: "N",
          all_owned_automobiles_deductible: "",
          all_owned_automobiles_amount: "",
          leased_automobiles: "N",
          leased_automobiles_deductible: "",
          leased_automobiles_amount: "",
          property_damage_deductible: "",
          property_damage_amount: "",
        },
        excess_liability_section_one: {
          insurance_company: "",
          policy_number: "",
          effective_date: "",
          expiry_date: "",
        },
        excess_liability_section_two: {
          umbrella_form: "N",
          umbrella_form_amount: "",
          aggreate_deductible: "",
          umbrella_form_deductible: "",
          extra: "N",
          extra_amount: "",
          extra_deductible: "",
          aggreate_amount: "",
          extra_coverage: "",
          extra_type_of_insurance: "",
        },
        other_liability_section_one: {
          insurance_company: "",
          policy_number: "",
          effective_date: "",
          expiry_date: "",
        },
        other_liability_section_two: [
          {
            key: getUniqueId(),
            type_of_insurance_check: "",
            type_of_insurance: "",
            coverage: "",
            deductible: "",
            amount: "",
          },
          {
            key: getUniqueId(),
            type_of_insurance_check: "",
            type_of_insurance: "",
            coverage: "",
            deductible: "",
            amount: "",
          },
        ],
        cancelation_number_of_days: null,
        brokerage_full_name: null,
        brokerage_mailing_address: null,
        brokerage_customer_code: null,
        additional_name_insured_yes_or_no: null,
        additional_name_insured_name: null,
        additional_name_insured_mailing_address: null,
        certificate_authorization_branch_name: null,
        certificate_authorization_assigned_to: null,
        certificate_authorization_phone_number: null,
        certificate_authorization_fax_number: null,
        certificate_authorization_email: null,
        certificate_authorization_date: moment().format("YYYY-MM-DD"),
        status: "ACTIVE",
      },
      liabilityInsuranceLoading: LoadState.NotLoaded,
      error: null,
    },
    opcf16: {
      list: [],
      loading: LoadState.NotLoaded,
      opcf: {
        customer_policy_id: 0,
        incremental_number: null,
        issued_to: null,
        policy_number: null,
        effective_date_of_change: moment().format("YYYY-MM-DD"),
        policy_status: null,
        automobile_data: [],
        status: "ACTIVE",
        checkbox_see_your_certificate_of_automobile: "N",
        checkbox_this_change_applies: "N",
        indicated_on_your_certificate: null,
        see_your_certificate_of_automobile: null,
        this_change_applies_only_to_automobile: null,
      },
      opcfLoading: LoadState.NotLoaded,
      error: null,
    },
    consentform: {
      list: [],
      loading: LoadState.NotLoaded,
      consent: {
        customer_policy_id: 0,
        incremental_number: null,
        customer_id: 0,
        customer_name: null,
        email_address: null,
        text_field: null,
        modified_by_id: null,
        insert_ts: null,
        create_ts: null,
        status: "ACTIVE",
        created_by_id: 0,
      },
      consentLoading: LoadState.NotLoaded,
      error: null,
    },
    sharingfillable: {
      list: [],
      loading: LoadState.NotLoaded,
      sharing: {
        customer_policy_id: 0,
        incremental_number: null,
        customer_id: 0,
        customer_name: null,
        policy_number: null,
        modified_by_id: null,
        insert_ts: null,
        create_ts: null,
        status: "ACTIVE",
        created_by_id: 0,
      },
      sharingLoading: LoadState.NotLoaded,
      error: null,
    },
    supplementaryform: {
      list: [],
      loading: LoadState.NotLoaded,
      supplementary: {
        customer_policy_id: 0,
        incremental_number: null,
        customer_id: 0,
        customer_name: null,
        broker: null,
        effective_date: null,
        section_a: {
          in_business_since: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          sales_of_new_vehicles: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          sales_of_used_vehicles: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          leasing_of_new_or_used_vehicles: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          body_shop: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          body_shop_including_car_sales: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          repair_garage: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          repair_garage_including_car_sales: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          service_station: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          service_station_self_serve: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          parking_lot: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          parking_lot_self_park: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          storage_garage: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          storage_garage_self_store: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          valet_parking: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          valet_parking_fee_charged: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          car_wash: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          car_wash_automatic: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          other_detail_remarks: null,
        },

        private_passenger_checkbox: null,
        light_commercial_checkbox: null,
        heavy_commercial_checkbox: null,
        trailers_checkbox: null,
        commercial_trailers_checkbox: null,
        antique_or_classic_checkbox: null,
        exotic_racing_checkbox: null,
        motorcycles_checkbox: null,
        atv_checkbox: null,
        snowmobiles_checkbox: null,
        motorhomes_checkbox: null,
        other_checkbox: null,
        other_value: null,
        proprietors: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        sales_staff: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        mechanics: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        lot_persons: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        body_shop_workers: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        full_service_station_attendants: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        clerical: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        parts_department_staff: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        parking_lot_attendant: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        valet_parking: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        summary_of_employees_other_details: null,
        employee_information_including_owners: [
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            position: null,
            years_lincence: null,
            licence_class: null,
            full_or_part_time: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            position: null,
            years_lincence: null,
            licence_class: null,
            full_or_part_time: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            position: null,
            years_lincence: null,
            licence_class: null,
            full_or_part_time: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            position: null,
            years_lincence: null,
            licence_class: null,
            full_or_part_time: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            position: null,
            years_lincence: null,
            licence_class: null,
            full_or_part_time: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
        ],
        other_operators: [
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            relationship_to_the_named_insured: null,
            years_lincence: null,
            licence_class: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            relationship_to_the_named_insured: null,
            years_lincence: null,
            licence_class: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            relationship_to_the_named_insured: null,
            years_lincence: null,
            licence_class: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            relationship_to_the_named_insured: null,
            years_lincence: null,
            licence_class: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            relationship_to_the_named_insured: null,
            years_lincence: null,
            licence_class: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            relationship_to_the_named_insured: null,
            years_lincence: null,
            licence_class: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
        ],
        claims: [
          {
            key: getUniqueId(),
            driver_name: null,
            date_of_loss: "",
            type_of_loss: null,
            amount_paid: null,
            descriptiom: null,
          },
          {
            key: getUniqueId(),
            driver_name: null,
            date_of_loss: "",
            type_of_loss: null,
            amount_paid: null,
            descriptiom: null,
          },
          {
            key: getUniqueId(),
            driver_name: null,
            date_of_loss: "",
            type_of_loss: null,
            amount_paid: null,
            descriptiom: null,
          },
          {
            key: getUniqueId(),
            driver_name: null,
            date_of_loss: "",
            type_of_loss: null,
            amount_paid: null,
            descriptiom: null,
          },
          {
            key: getUniqueId(),
            driver_name: null,
            date_of_loss: "",
            type_of_loss: null,
            amount_paid: null,
            descriptiom: null,
          },
        ],
        are_all_active_vehicles_owned_by_yes_or_no: null,
        are_all_active_vehicles_owned_by_no_reason: null,
        number_of_dealer_plates: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        number_of_service_plates: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        total_number_of_white_plates: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        dealer_plate_numbers: null,
        service_plate_numbers: null,
        private_passenger: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        commercial_tow_trucks: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        commercial_tilt_and_load: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        commercial_parts_truck: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        commercial_service_trucks: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        commercial_shuttle_vehicles: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        commercial_other: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        courtesy_cars: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        summary_of_all_active_vehicles_other_details: null,
        details_of_all_vehicles_owned_by_the_applicant: [
          {
            key: getUniqueId(),
            year: null,
            make_model_body_type: null,
            vin: null,
            gvw: null,
            list_price_new: null,
            use: null,
            driver_principal_and_occasional: null,
          },
          {
            key: getUniqueId(),
            year: null,
            make_model_body_type: null,
            vin: null,
            gvw: null,
            list_price_new: null,
            use: null,
            driver_principal_and_occasional: null,
          },
          {
            key: getUniqueId(),
            year: null,
            make_model_body_type: null,
            vin: null,
            gvw: null,
            list_price_new: null,
            use: null,
            driver_principal_and_occasional: null,
          },
          {
            key: getUniqueId(),
            year: null,
            make_model_body_type: null,
            vin: null,
            gvw: null,
            list_price_new: null,
            use: null,
            driver_principal_and_occasional: null,
          },
          {
            key: getUniqueId(),
            year: null,
            make_model_body_type: null,
            vin: null,
            gvw: null,
            list_price_new: null,
            use: null,
            driver_principal_and_occasional: null,
          },
          {
            key: getUniqueId(),
            year: null,
            make_model_body_type: null,
            vin: null,
            gvw: null,
            list_price_new: null,
            use: null,
            driver_principal_and_occasional: null,
          },
        ],
        owned_automobiles_including_vehicles: {
          maximum_number_in_building_location_a: null,
          maximum_number_in_building_location_b: null,
          maximum_number_in_building_location_c: null,
          maximum_number_in_building_location_d: null,
          maximum_number_on_lot_location_a: null,
          maximum_number_on_lot_location_b: null,
          maximum_number_on_lot_location_c: null,
          maximum_number_on_lot_location_d: null,
          maximum_value_location_a: null,
          maximum_value_location_b: null,
          maximum_value_location_c: null,
          maximum_value_location_d: null,
          average_value_location_a: null,
          average_value_location_b: null,
          average_value_location_c: null,
          average_value_location_d: null,
        },
        customer_automobiles: {
          maximum_number_in_building_location_a: null,
          maximum_number_in_building_location_b: null,
          maximum_number_in_building_location_c: null,
          maximum_number_in_building_location_d: null,
          maximum_number_on_lot_location_a: null,
          maximum_number_on_lot_location_b: null,
          maximum_number_on_lot_location_c: null,
          maximum_number_on_lot_location_d: null,
          maximum_value_location_a: null,
          maximum_value_location_b: null,
          maximum_value_location_c: null,
          maximum_value_location_d: null,
          average_value_location_a: null,
          average_value_location_b: null,
          average_value_location_c: null,
          average_value_location_d: null,
        },
        during_business_hours: null,
        after_business_hours: null,
        are_driving_record_abstracts_obtained_yes_or_no: null,
        how_often: null,
        outside_floodlights: {
          location_a_yes_or_no: null,
          location_b_yes_or_no: null,
          location_c_yes_or_no: null,
          location_d_yes_or_no: null,
          comments_description: null,
        },
        burglary_alarm_system: {
          location_a_yes_or_no: null,
          location_b_yes_or_no: null,
          location_c_yes_or_no: null,
          location_d_yes_or_no: null,
          comments_description: null,
        },
        fenced_compound: {
          location_a_yes_or_no: null,
          location_b_yes_or_no: null,
          location_c_yes_or_no: null,
          location_d_yes_or_no: null,
          comments_description: null,
        },
        guard_dogs: {
          location_a_yes_or_no: null,
          location_b_yes_or_no: null,
          location_c_yes_or_no: null,
          location_d_yes_or_no: null,
          comments_description: null,
        },
        night_watchman: {
          location_a_yes_or_no: null,
          location_b_yes_or_no: null,
          location_c_yes_or_no: null,
          location_d_yes_or_no: null,
          comments_description: null,
        },
        other_describe_below: {
          location_a_yes_or_no: null,
          location_b_yes_or_no: null,
          location_c_yes_or_no: null,
          location_d_yes_or_no: null,
          comments_description: null,
        },
        security_measures_other_details: null,
        does_the_applicant_pick_up_or_deliver_yes_or_no: null,
        does_the_applicant_pick_up_or_deliver_value: null,
        number_of_spray_booths: null,
        inspected_and_approved_yes_or_no: null,
        spraying: null,
        welding: null,
        does_the_applicant_dispense_propane_yes_or_no: null,
        number_of_licensed_employees: null,
        give_details_of_any_contractual_liability: null,
        what_is_the_maximum_number_of_automobiles: null,
        how_many_tow_trucks_can_carry: null,
        does_the_applicant_have_written_rules_yes_or_no: null,
        does_the_applicant_have_written_rules_value: null,
        is_demonstrator_use_restricted_yes_or_no: null,
        including_spouse_yes_or_no: null,
        including_children_yes_or_no: null,
        others: null,
        is_vacation_use_of_owned_vehicles_yes_or_no: null,
        are_customers_permitted_to_test_drive_yes_or_no: null,
        is_customer_always_accompanied_yes_or_no: null,
        describe_procedures_for_other_precautions_taken: null,
        if_sales_include_recreational_vehicles_yes_or_no: null,
        is_applicant_currently_registered_yes_or_no: null,
        where_and_how_are_vehicles_held: null,
        lien_checks_yes_or_no: null,
        consignment_yes_or_no: null,
        if_yes_what_policies_and_procedures: null,
        indicate_number_and_average_retail_values_sold: {
          third_year_back_number_sold: null,
          third_year_back_average_retail_value: null,
          second_year_back_number_sold: null,
          second_year_back_average_retail_value: null,
          expiring_year_number_sold: null,
          expiring_year_average_retail_value: null,
          expected_for_coming_year_number_sold: null,
          expected_for_coming_year_average_retail_value: null,
        },
        have_you_bound_this_risk_yes_or_no: null,
        is_this_business_new_to_you_yes_or_no: null,
        how_long_have_you_known_the_applicant: null,
        report_of_broker_other_details: null,
        modified_by_id: null,
        insert_ts: null,
        create_ts: null,
        status: "ACTIVE",
        created_by_id: 0,
      },
      supplementaryLoading: LoadState.NotLoaded,
      error: null,
    },
    garageform: {
      list: [],
      loading: LoadState.NotLoaded,
      garage: {
        customer_policy_id: 0,
        incremental_number: null,
        customer_id: 0,
        policy_number: "",
        insurance_company: "",
        new: null,
        replacing_policy_number: null,
        customer_name: null,
        customer_address: null,
        customer_unit_or_suite: null,
        customer_city: null,
        customer_province_or_state: null,
        customer_postal_code: null,
        customer_country: null,
        contact_business: null,
        contact_home: null,
        contact_fax: null,
        broker_client_id: null,
        company_client_id: null,
        broker_or_agent: null,
        branch: null,
        code: null,
        broker_or_agent_bill: null,
        company_bill: null,
        payment_plan: null,
        business_address: null,
        section_a: [
          {
            key: getUniqueId(),
            srNo: "A",
            location_of_other_premises: null,
            structure_type_one_lot: null,
            structure_type_two_lot: null,
          },
          {
            key: getUniqueId(),
            srNo: "B",
            location_of_other_premises: null,
            structure_type_one_lot: null,
            structure_type_two_lot: null,
          },
        ],
        effective_time: null,
        effective_date: null,
        expiry_date: null,
        the_automobiles_in_respect: null,
        estimated_total_payroll: null,
        full_time: null,
        part_time: null,
        policy_commauto_vcs_schedule_d_id: 0,
        coverage_summary_incremental_number: null,
        leinholder_name: null,
        insurer: null,
        reason: null,
        injury_to_persons: null,
        collision: null,
        other: null,
        not_in_care_of_applicant: null,
        in_care_of_applicant: null,
        remarks: null,
        print_name: null,
        brokerage_or_agent_name: null,
        modified_by_id: null,
        insert_ts: null,
        create_ts: null,
        status: "ACTIVE",
        created_by_id: 0,
      },
      garageLoading: LoadState.NotLoaded,
      error: null,
    },
    retireediscount: {
      list: [],
      loading: LoadState.NotLoaded,
      retiree: {
        customer_policy_id: 0,
        customer_id: 0,
        incremental_number: null,
        customer_name: null,
        policy_number: null,
        effective_date_of_discount: moment().format("YYYY-MM-DD"),
        insurer: null,
        branch_code: null,
        retiree_name: null,
        modified_by_id: null,
        insert_ts: null,
        create_ts: null,
        status: "ACTIVE",
        created_by_id: 0,
      },
      retireeLoading: LoadState.NotLoaded,
      error: null,
    },
    ownerform: {
      list: [],
      loading: LoadState.NotLoaded,
      owner: {
        customer_policy_id: 0,
        customer_id: 0,
        incremental_number: null,
        policy_number: "",
        insurance_company: null,
        new_policy: null,
        renewal_policy: null,
        policy_language: null,
        customer_name: null,
        customer_address: null,
        customer_unit_or_suite: null,
        customer_city: null,
        customer_province_or_state: null,
        customer_postal_code: null,
        customer_country: null,
        contact_number: null,
        contact_business: null,
        contact_res: null,
        contact_mobile: null,
        agent_or_broker: null,
        policy_billing: null,
        email_address: null,
        broker_bill: null,
        company_bill: null,
        payment_plan: null,
        effective_time: null,
        effective_date: null,
        expiry_date: null,
        particulars_of_the_described_section_one: [
          {
            model_year: "",
            trade_name: null,
            model_or_cc: null,
            body_type: null,
            vin: null,
            year: null,
            month: null,
            new_or_used: null,
            purchase_price_including_accessories_equipment: null,
          },
        ],
        particulars_of_the_described_section_two: [
          {
            state_name: null,
            postal_address: null,
            postal_code: null,
            assignee: null,
            lienholder: null,
            lessor: null,
          },
        ],
        particulars_of_the_described_section_three: [
          {
            gvw: null,
            yes_or_no: null,
            list_price_new: null,
            vehicle_code: null,
            terr: null,
            loc: null,
            class: null,
            drtpl: null,
            dr_coll: null,
            dcpd: null,
            coll: null,
            comp: null,
          },
        ],
        limits_and_amounts_in_dollars: [
          {
            legal_liability_for_bodily_injury: null,
            amount_deductible: null,
            all_perils: null,
            collision_or_upset: null,
            comprehensive: null,
            specified_perils: null,
            vehicle_number: null,
            endorsement_number: null,
          },
        ],
        premium_in_dollars: [
          {
            pd: null,
            bi: null,
            yes_or_no: null,
            amount_deductible: null,
            payments_for_death_or_bodily_injury: null,
            all_perils: null,
            collision_or_upset: null,
            comprehensive: null,
            specified_perils: null,
            sef_premium: null,
            vehicle_premium: null,
          },
        ],
        minimum_retained_premium: null,
        total_estimated_policy_premium: null,
        list_all_drivers_part_one: [
          {
            name: null,
            drivers_licence_number: null,
            date_of_birth: null,
          },
        ],
        list_all_drivers_part_two: [
          {
            date_lic: null,
            lic_class: null,
            grid_step: null,
            vehicle_one: null,
            vehicle_two: null,
            vehicle_three: null,
            vehicle_four: null,
            driver_occupation: null,
            driver_training_yes_or_no: null,
          },
        ],
        give_particulars_of_all_convictions: [
          {
            driver_number: null,
            date: null,
            description: null,
          },
        ],
        give_particulars_of_all_accidents: [
          {
            vehicle_number: null,
            driver_number: null,
            date: null,
            type_of_claim: null,
            amount_paid_or_estimate: null,
            claim_amount_repaid_to_insurer: null,
            description: null,
          },
        ],
        has_any_driver_licence: null,
        section_eight_a_insurer: null,
        section_eight_a_reason: null,
        section_eight_a_policy_number: null,
        section_eight_b_insurer: null,
        section_eight_b_policy_number: null,
        section_eight_b_expiry_date: null,
        section_eight_c_yes_or_no: null,
        section_nine_a: {
          vehicle_number_one: null,
          vehicle_number_two: null,
          vehicle_number_three: null,
          vehicle_number_four: null,
        },
        section_nine_b: [
          {
            yes_or_no: null,
            distance_one_way: null,
          },
        ],
        section_nine_c: {
          state_the_usual_percentage_one: null,
          state_the_usual_percentage_two: null,
          state_the_usual_percentage_three: null,
          state_the_usual_percentage_four: null,
        },
        section_nine_d: [
          {
            yes_or_no: null,
            number_of_months: null,
          },
        ],
        section_nine_yes_or_no: null,
        section_ten: [
          {
            will_the_automobile_be_rented: null,
            will_the_automobile_be_used_for_the_transportation_of_goods: null,
          },
        ],
        the_registered_owner: null,
        the_actual_owner: null,
        section_twelve_remarks: null,
        endorsements_vehicle_one: [
          {
            sef_number: null,
            description: null,
            limit_one: null,
            limit_two: null,
            deductible: null,
            percentage: null,
            premium: null,
          },
        ],
        endorsements_vehicle_two: [
          {
            sef_number: null,
            description: null,
            limit_one: null,
            limit_two: null,
            deductible: null,
            percentage: null,
            premium: null,
          },
        ],
        endorsements_vehicle_three: [
          {
            sef_number: null,
            description: null,
            limit_one: null,
            limit_two: null,
            deductible: null,
            percentage: null,
            premium: null,
          },
        ],
        endorsements_vehicle_four: [
          {
            sef_number: null,
            description: null,
            limit_one: null,
            limit_two: null,
            deductible: null,
            percentage: null,
            premium: null,
          },
        ],
        section_fourteen_part_one: [
          {
            name: null,
            date_completed: null,
            type: null,
            sex: null,
            marital_status: null,
            relationship_to_applicant: null,
          },
        ],
        section_fourteen_part_two: [
          {
            date_of_mvr: null,
            code: null,
            percntage: null,
            description: null,
          },
        ],
        section_fifteen: [
          {
            name: null,
            address: null,
            date_hired: null,
          },
        ],
        section_sixteen: [
          {
            full_name: null,
            birth_date: null,
            driver_licence_number: null,
            own_a_vehicle: null,
            non_licensed_resident: null,
          },
        ],
        section_seventeen: [
          {
            yes_or_no: null,
            number_of_passengers: null,
            number_of_times: null,
            per: null,
          },
        ],
        section_eighteen: {
          vehicle_number_one: null,
          vehicle_number_two: null,
          vehicle_number_three: null,
          vehicle_number_four: null,
        },
        section_nineteen: {
          vehicle_number_one: null,
          vehicle_number_two: null,
          vehicle_number_three: null,
          vehicle_number_four: null,
        },
        section_twenty: [
          {
            value: null,
            describe: null,
          },
        ],
        section_twenty_one: [
          {
            device_type: null,
            device_characteristics: null,
            product_code: null,
          },
        ],
        section_twenty_two_value_one: null,
        section_twenty_two_value_two: null,
        total_number_of_private_passenger_vehicles: null,
        section_twenty_four_remarks: null,
        have_you_bound_this_risk: null,
        is_this_business_new_to_your_office: null,
        motor_vehicle_liability: null,
        the_applicant: null,
        driver_n_a: null,
        the_principal_operator: null,
        driver_n_b: null,
        provide_applicant_email_address: null,
        does_your_client_have_otheer_insurance: null,
        policy_n_a: null,
        policy_n_b: null,
        if_yes_give_particulars_one: null,
        are_there_any_special_circumstances: null,
        if_yes_give_particulars_two: null,
        is_this_risk_eligible: null,
        if_yes_give_particulars_three: null,
        was_the_supplementary: null,
        if_yes_give_particulars_four: null,
        broker_or_agent_name: null,
        describe_any_trailer_not_already_listed: null,
        commercial_rated_vehicle: null,
        section_twenty_eight: [
          {
            does_vehicle_weight_exceed_forty_thousand_five_hundred_kg: null,
            is_operating_radius_greater_than_forty_km_from_place_vehicle: null,
          },
        ],
        section_twenty_nine: [
          {
            percentage_of_pleasure_use: null,
            delete: null,
            wholesale: null,
            retail: null,
            hauling_done_for_others: null,
            specify: null,
          },
        ],
        section_thirty: [
          {
            if_yes_describe: null,
          },
        ],
        section_thirty_one: [
          {
            value: null,
          },
        ],
        section_thirty_two: [
          {
            if_yes_describe_and_name_owner: null,
          },
        ],
        section_thirty_three_remarks: null,
        modified_by_id: null,
        insert_ts: null,
        create_ts: null,
        status: "ACTIVE",
        created_by_id: 0,
      },
      ownerLoading: LoadState.NotLoaded,
      error: null,
    },
    dwellingdeclaration: {
      list: [],
      loading: LoadState.NotLoaded,
      dwelling: {
        customer_policy_id: 0,
        incremental_number: null,
        customer_id: null,
        customer_name: null,
        address: null,
        unit_or_suite: null,
        city: null,
        province_or_state: null,
        postal_code: null,
        country: null,
        policy_number: null,
        this_policy_is_for_rental_dwelling: null,
        this_policy_is_for_student_housing: null,
        we_understand_that_if_any_of_the_above: null,
        name_relation_living_since: [
          {
            // key: getUniqueId(),
            name: "",
            relation: "",
            living_since: "",
          },
        ],
        status: "ACTIVE",
        created_by_id: 0,
        modified_by_id: 0,
        insert_ts: "",
      },
      dwellingLoading: LoadState.NotLoaded,
      error: null,
    },
    dwelling: {
      list: [],
      loading: LoadState.NotLoaded,
      dwelling: {
        customer_policy_id: 0,
        customer_id: null,
        incremental_number: null,
        policy_number: null,
        applicant_name: null,
        is_the_applicant_a_company: null,
        name_of_principal: null,
        date_of_birth: "",
        any_additional_applicants: null,
        province_of_primary_residence: "Ontario",
        source_of_applicants_income: null,
        out_of_last_twelve_months: null,
        how_many_mortages_encumbrances_are_on_the_property: null,
        is_any_mortage_held_with_private_or_non_conve_lender: null,
        how_many_non_conv_or_private_lender_required_to_list_prop: null,
        if_three_or_more_what_is_the_reason_for_the_third_mortgage: null,
        how_much_equity_does_the_applicant_retain_the_home: null,
        is_the_applicant_behind_late_in_making_their_mortgage_payments: "",
        risk_information: {
          risk_location_address: "",
          unit_or_suite: "",
          city: "",
          province: "",
          postal_code: "",
          is_the_home_located_on_leased_land: "",
          if_yes_how_long_a_term_remains_in_the_land_lease_no_years: "",
          size_of_property_in_acres: "",
        },
        underwriting_building_information: {
          year_built: "",
          type_of_building: "",
          type_of_construction: "",
          type_of_foundation: "",
          no_of_stories: "",
          square_footage: "",
        },
        underwriting_renovation_or_demolition: {
          will_construction__renovation_activity_on_next_12_months: "",
          if_yes_please_check_all_that_apply: "",
          what_is_the_budget_for_renovations: "",
          will_there_be_any_structural_renov_adding_or_removing_walls: "",
          will_the_home_be_demolished_in_the_next_24_months: "",
          if_yes_please_describe: "",
        },
        underwriting_building_updates_electrical: {
          how_many_amps_is_the_electrical_system: "",
          electrical_details: "",
          type_of_wiring: "",
          if_there_is_any_aluminum_wiring: "",
          what_percentage_is_aluminum: "",
          has_it_been_inspected_and_approved_by_a_licensed_electrician: "",
          is_there_a_knob_tube_wiring_in_any_kitchen_or_laundry_area: "",
          electrical_year_of_most_recent_electrical_update: "",
        },
        underwriting_building_updates_roof: {
          roof_material: "",
          year_of_most_recent_roof_update: "",
        },
        underwriting_building_updates_plumbing: {
          plumbing_types: "",
          year_of_plumbing_update: "",
          year_of_hot_water_tank: "",
        },
        underwriting_building_updates_heating: {
          primary_heat_device_appliance_to_regularly_heat_the_home: "",
          heating_other_please_describe: "",
          what_is_the_primary_heating_fuel_source: "",
          home_solid_fuel_heat_device_other_than_traditional_fireplace: "",
          if_yes_woo_heat_the_primary_pellets_burned_annually: "",
          was_the_solid_fuel_heat_device_professionally_installed: "",
          is_there_was_a_passed_wett_inspection_on_file: "",
          if_yes_please_attach_a_copy_of_the_wett_inspection: "",
          have_modification_been_made_to_the_solid_fuel_heat_device: "",
          solid_fuel_heat_device__chimney_cleaned_every_year: "",
          ashes_disposed_metal_lidded_cont_placed_non_flammable_surface: "",
          at_least_24_inches_of_clear_space_combustible_materials: "",
        },
        underwriting_building_updates_other: {
          does_the_home_have_an_oil_tank: "",
          if_yes_year_of_oil_tank: "",
          location_of_oil_tank: "",
          are_there_operational_smoke_detectors: "",
          is_there_an_operational_sprinkler_system: "",
          is_there_a_swimming_pool: "",
        },
        underwriting_building_updates_wildfire_and_flood: {
          risk_located_evacuated_or_notice_of_evacuation_wildfire_or_flood: "",
          wildfire_and_food_if_yes_please_provide_details: "",
          is_this_risk_located_within_25km_of_a_current_wildfire: "",
          risk_located_in_an_area_that_is_currently_under_flood_warning: "",
          of_common_kitchens: "",
          distance_to_fire_hydrant: "",
          distance_to_firehall: "",
        },
        occupancy: {
          confirm_occupancy: "",
          minimum_rental_arrangements_for_this_property: "",
          do_allow_your_tenants_to_sublet_or_are_any_occupants_subtenants: "",
          if_rented_how_long_is_the_rental: "",
          are_there_any_commercial_occupancies_in_the_building: "",
          if_yes_are_there_any_restaurants_in_the_building: "",
          does_the_owners_child_live_in_the_dwelling: "",
          how_many_rented_self_contained_units_are_there: "",
          who_lives_in_the_home: "",
          if_students_of_students: "",
          is_this_a_faternity_or_sorority_house: "",
          does_the_owners_child_or_relative_live_in_the_home: "",
          who_is_responsible_for_the_care_and_maintenance_of_the_property: "",
          is_there_farming_business_or_commercial_operations_on_premises: "",
          if_yes_please_describe: "",
        },
        coverage_information: {
          residential_building_limit: "",
          annual_rental_income: "",
          premises_liability: "",
          deductible: "",
          loss_assessment: "",
          unit_owners_imporovements_and_betterments: "",
          condominium_deductible_assessment_coverage: "",
          is_sewer_backup_coverage_requested: "",
          is_earthquake_coverage_requested: "",
        },
        mortgages_or_loss_payees: [],
        policy_term_info_proposed_effective_date: null,
        mailing_address: null,
        mailing_unit_or_suite: null,
        mailing_city: null,
        mailing_province: null,
        mailing_postal_code: null,
        brokerage: null,
        broker_id: null,
        broker_email: null,
        tel: null,
        fax: null,
        status: "ACTIVE",
      },
      dwellingLoading: LoadState.NotLoaded,
      error: null,
    },
    opcf17: {
      list: [],
      loading: LoadState.NotLoaded,
      opcf: {
        customer_policy_id: 0,
        incremental_number: null,
        issued_to: null,
        policy_number: null,
        effective_date_of_change: moment().format("YYYY-MM-DD"),
        policy_status: null,
        automobile_data: [{}],
        status: "ACTIVE",
        checkbox_see_your_certificate_of_automobile: "N",
        checkbox_this_change_applies_only_to_automobile: "N",
        indicated_on_your_certificate_of_automobile: null,
        see_your_certificate_of_automobile: null,
        this_change_applies_only_to_automobile: null,
      },
      opcfLoading: LoadState.NotLoaded,
      error: null,
    },
    opcf28A: {
      list: [],
      loading: LoadState.NotLoaded,
      opcf: {
        customer_policy_id: 0,
        incremental_number: null,
        issued_to: null,
        effective_date_of_change: moment().format("YYYY-MM-DD"),
        policy_number: null,
        automobile_number: null,
        model: null,
        year: null,
        trade_name: null,
        serial_number_or_vin: null,
        name_of_excluded_driver: null,
        driver_license_number: null,
        status: "ACTIVE",
      },
      opcfLoading: LoadState.NotLoaded,
      error: null,
    },
  };
