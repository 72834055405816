import React from "react";
import {
  Button,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Typography,
  TableContainer,
} from "@mui/material";
import {
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { CustomDatePicker } from "../../../../../../../components/formsComponents/CustomDatePicker";
import { RightPanel } from "../../../../../../../components/RightPanel";

import produce from "immer";
import { StandadCard } from "../../../../../../../components/Cards";
import { IAutomobileScheduleA } from "../../../../../../../redux/automobileSchedule/automobileSchedule.types";
import { useFormik } from "formik";

export const AutomobileScheduleADialog: React.FC<{
  open: boolean;
  coverageData: IAutomobileScheduleA;
  onClose: () => void;
}> = (props) => {
  const { open, coverageData, onClose } = props;

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: coverageData,
    validate: (values) => {},
    onSubmit: (values) => {},
  });

  return (
    <RightPanel
      open={open}
      heading="Risk Location"
      onClose={onClose}
      width="100%"
      isWrappedWithForm
      actionButtons={() => {
        return (
          <Stack spacing={2} direction={"row"} mt={2}>
            <Button
              variant="contained"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              Close
            </Button>
          </Stack>
        );
      }}
    >
      <StandadCard heading="Vehicle Coverage Summary">
        <form onSubmit={handleSubmit}>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} md={12}>
              <TableContainer>
                <Table
                  aria-label="collapsible table"
                  sx={{
                    whiteSpace: {
                      xs: "nowrap",
                      sm: "nowrap",
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ minWidth: 100 }} align="center">
                        <Typography fontWeight={600} variant="h4">
                          Section
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography fontWeight={600} variant="h4">
                          Automobile
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography fontWeight={600} variant="h4">
                          Occasional Premium
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.coverage_details
                      ? values.coverage_details?.map((item, index) => {
                          return (
                            <TableRow
                              key={item.key}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell sx={{ minWidth: 600 }}>
                                {!item.isTypeOther ? (
                                  <Typography
                                    sx={{
                                      wordBreak: "break-word",
                                      whiteSpace: "normal",
                                    }}
                                  >
                                    {item.label}
                                  </Typography>
                                ) : (
                                  <CustomTextField
                                    fullWidth
                                    value={item.label}
                                  />
                                )}
                              </TableCell>
                              <TableCell>
                                <Stack direction={"row"} spacing={1}>
                                  <CustomTextField
                                    name="limit"
                                    value={item.limit}
                                    onChange={handleChange}
                                    variant="outlined"
                                    size="small"
                                    placeholder="Limit"
                                  />
                                  <CustomTextField
                                    name="deductible"
                                    value={item.deductible}
                                    onChange={handleChange}
                                    variant="outlined"
                                    size="small"
                                    placeholder="Deductible"
                                  />
                                  <CustomTextField
                                    name="premimum"
                                    value={item.premimum}
                                    onChange={handleChange}
                                    variant="outlined"
                                    size="small"
                                    placeholder="Premium"
                                  />
                                </Stack>
                              </TableCell>
                              <TableCell>
                                <CustomTextField
                                  name="description"
                                  value={item.description}
                                  onChange={handleChange}
                                  variant="outlined"
                                  size="small"
                                  placeholder="Description"
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                      : []}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </form>
      </StandadCard>
    </RightPanel>
  );
};
