import React from "react";
import { Dialog } from "../../../../components/Dialogs/Dialog";
import { StandardCellList } from "../../../../components/Standards";
import { formatDate } from "../../../../helpers";
import { IViewLeadDetailsProps } from "./ViewLeadDetails.types";
import {
  Box,
  Divider,
  Grid,
  List,
  ListItemButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";
import { Phone } from "@mui/icons-material";
import { ILead } from "../../../../redux/leads/leads.types";
import { RightPanel } from "../../../../components/RightPanel";
import { StatusRenderer } from "../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";

export const ViewLeadDetails: React.FC<IViewLeadDetailsProps> = (props) => {
  const { open, onClose } = props;
  const { list } = useSelector((storeState: IStoreState) => storeState.leads);
  const [selectedLead, setSelectedLead] = React.useState(props.lead);

  const handleSelectLead = (lead: ILead) => () => {
    setSelectedLead(lead);
  };

  console.log("list", list);

  return (
    <RightPanel
      open={open}
      heading="Lead Summary"
      onClose={onClose}
      width="85%"
      // actions={[
      //   {
      //     type: "button",
      //     label: "Close",
      //     variant: "contained",
      //     onClick: onClose,
      //   },
      // ]}
    >
      <Grid container spacing={2} height={"100%"}>
        <Grid item xs={12} md={3}>
          <Paper
            variant="outlined"
            sx={{ height: "100%", maxHeight: "82vh", overflowY: "auto" }}
          >
            <List disablePadding>
              {list.map((lead, index) => {
                return (
                  <>
                    <ListItemButton
                      key={lead.id}
                      selected={lead.lead_id === selectedLead.lead_id}
                      onClick={handleSelectLead(lead)}
                    >
                      <Box
                        key={lead.lead_id}
                        minHeight={50}
                        width={"100%"}
                        padding={1}
                      >
                        <Stack>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                          >
                            <Typography
                              variant="h5"
                              fontSize={"1.1rem"}
                              fontWeight={600}
                            >
                              {lead.first_name + " " + lead.last_name}
                            </Typography>
                            <StatusRenderer status={lead.status} />
                          </Stack>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                          >
                            <Typography variant="body1">
                              {lead.email}
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                          >
                            <Typography
                              variant="body1"
                              maxWidth={"100px"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                              overflow={"hidden"}
                            >
                              {lead.insurance_type}
                            </Typography>
                            <Typography variant="body1" color="primary">
                              {lead.insurance_sub_type}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                          >
                            <Typography
                              variant="body1"
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Phone
                                sx={{ mr: 1 }}
                                color="primary"
                                fontSize="small"
                              />
                              {lead.phone}
                            </Typography>
                            <Typography
                              variant="body1"
                              color="error"
                              textAlign={"end"}
                            >
                              {formatDate(lead.insert_ts)}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Box>
                    </ListItemButton>
                    <Divider />
                  </>
                );
              })}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper variant="outlined" sx={{ p: 3 }}>
            <Typography variant="h2" textAlign={"center"} fontWeight={600}>
              {selectedLead.first_name +
                " " +
                selectedLead.last_name +
                ` (#${selectedLead.lead_id})`}
            </Typography>
            <Grid container spacing={2} mt={3}>
              <Grid item xs={12} md={6}>
                <Row
                  label="Quotes For"
                  value={selectedLead.insurance_type}
                ></Row>
              </Grid>
              <Grid item xs={12} md={6}>
                <Row
                  label="Insurance Sub Type"
                  value={selectedLead.insurance_sub_type}
                ></Row>
              </Grid>
              <Grid item xs={12} md={6}>
                <Row label="Phone Number(+1)" value={selectedLead.phone}></Row>
              </Grid>
              <Grid item xs={12} md={6}>
                <Row label="Email" value={selectedLead.email}></Row>
              </Grid>
              <Grid item xs={12} md={6}>
                <Row
                  label="Date"
                  value={formatDate(selectedLead.insert_ts)}
                ></Row>
              </Grid>
              <Grid item xs={12} md={6}>
                <Row
                  label="Time To Contact"
                  value={selectedLead.time_to_contact}
                ></Row>
              </Grid>
              <Grid item xs={12} md={6}>
                <Row label="Lead Source" value={selectedLead.lead_source}></Row>
              </Grid>
              <Grid item xs={12} md={6}>
                <Row
                  label="Business Name"
                  value={selectedLead.business_name}
                ></Row>
              </Grid>
              <Grid item xs={12} md={6}>
                <Row label="Producer" value={selectedLead.producer_name}></Row>
              </Grid>

              <Grid item xs={12} md={6}>
                <Row label="Group" value={selectedLead.group_name}></Row>
              </Grid>

              <Grid item xs={12} md={6}>
                <Row label="status" value={selectedLead.status}></Row>
              </Grid>
              <Grid item xs={12} md={6}>
                <Row
                  label="Assigned To"
                  value={selectedLead.assigned_to_name}
                ></Row>
              </Grid>
              <Grid item xs={12} md={12}>
                <Row label="Notes" value={selectedLead.notes}></Row>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {/* <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
      
            <StandardCellList
              heading="Leads"
              colSize={3}
              list={[
                
            

             


             
              
                {
                  heading: "Times To Contact",
                  data: {
                    type: "text",
                    value: lead.time_to_contact,
                  },
                },
                {
                  heading: "Notes",
                  data: { type: "text", value: lead.notes },
                },
            
              ]}
            />
      
        </Grid>
     
      </Grid> */}
    </RightPanel>
  );
};

const Row: React.FC<{ label: string; value: any }> = ({ label, value }) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Typography variant="body1" fontSize={"1.1rem"} fontWeight={600}>
        {label}
      </Typography>
      <Typography variant="body1" fontSize={"1.1rem"}>
        {value}
      </Typography>
    </Stack>
  );
};
