import produce from "immer";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { initialPaymentMethodsState } from "./taskPaymentMethods.types";
import { TaskPaymentMethodsActions } from ".";
import { CLEAR_TASK_PAYMENT_METHODS, FETCH_TASK_PAYMENT_AUTH_FAILED, FETCH_TASK_PAYMENT_AUTH_PROGRESS, FETCH_TASK_PAYMENT_AUTH_SUCCESS } from "./taskPaymentMethodsActions";
import { CLEAR_TASK_GENERAL_CHANGE_STATE } from "../generalChange/generalChangeActions";

export const tasksPaymentMethodsReducer = (
  state: IStoreState["tasks"]["paymentMethods"] = initialPaymentMethodsState,
  action: TaskPaymentMethodsActions
) => {
  switch (action.type) {

   
    case FETCH_TASK_PAYMENT_AUTH_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.paymentAuthorization.loading = LoadState.InProgress;
        draftState.paymentAuthorization.data = initialPaymentMethodsState.paymentAuthorization.data;
      });
      return newState;
    }
    case FETCH_TASK_PAYMENT_AUTH_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.paymentAuthorization.loading = LoadState.Loaded;
        draftState.paymentAuthorization.data = data;
      });
      return newState;
    }
    case FETCH_TASK_PAYMENT_AUTH_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.paymentAuthorization.loading = LoadState.Failed;
      });
      return newState;
    }
    case CLEAR_TASK_PAYMENT_METHODS: {
        return initialPaymentMethodsState;
    }
    default: {
      return state;
    }
  }
};