import React from "react";
import { useSelector } from "react-redux";

import { Button } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import { IBusinessTasksProps } from "./BusinessTasks.types";
import { ManageBusinessTask } from "./ManageBusinessTask";
import { useTableV2State } from "../../TableV2/hooks/useTableV2State";
import { clearBusinessTasks, fetchBusinessTasksListAsync } from "../../../redux/activity/activityActions";
import { DataTableV2RowRenderType, IDataTableV2Props } from "../../TableV2/interfaces/IDataTableV2Props";
import { LoadState } from "../../../constants/enums";
import { StandardTableActions } from "../../StandardTableActions/StandardTableActions";
import { IBusinessTask, INITIAL_BUSINESS_TASK } from "../../../redux/activity/activity.types";
import { PageTitleBar } from "../../PageTitleBar/PageTitleBar";
import { DataTableV2 } from "../../TableV2/DataTableV2";
import { StatusRenderer } from "../../ActiveInActiveStatus/ActiveInActiveStatus";
import { useDispatchWrapper } from "../../../hooks";
import { IStoreState } from "../../../redux/initialStoreState";

export const BusinessTasks: React.FC<IBusinessTasksProps> = (props) => {
  const { moduleName, subModuleName} = props;
 
  
    const { list, totalRecords, loading } = useSelector(
      (storeState: IStoreState) => storeState.acitivty.tasks
    );
  
  
    const [tablePagination, setTablePagination] = React.useState({
      pageNumber: 1,
      rowsInPerPage: 25,
    });
    const dispatch = useDispatchWrapper();
    const [open, setOpen]= React.useState<IBusinessTask | null>(null);

  
  
    const {
      state: { dateState, searchState, tabs, columnsConfig },
      setDateState,
      setSelectedTab,
      setSearchState,
      setColumnVisibility,
    } = useTableV2State({
      filtersInitialState: {
        defaultDateRange: "last28Days",
        selectedTab: "-1",
      },
    });
  
    const fetchList = () => {
      dispatch(
        fetchBusinessTasksListAsync(
          tablePagination.pageNumber,
          tablePagination.rowsInPerPage,
          tabs.selectedTab,
          dateState.dates,
          searchState,
          moduleName,
          subModuleName
        )
      );
    };
  
    React.useEffect(() => {
      fetchList();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      tablePagination,
      dateState.dates,
      tabs.selectedTab,
      searchState,
    ]);
  
   
  
    const tasksTableProps: IDataTableV2Props = {
      isPagination: true,
      totalRecords: totalRecords,
      rowsPerPageOptions: tablePagination.rowsInPerPage,
      isDataLoading: loading !== LoadState.Loaded,
      
      tableCommandBarProps: {
        preDefinedPlugins: {
          dateFilter: {
            state: dateState,
            onChange: setDateState,
          },
          search: {
            state: searchState,
            onChange: setSearchState,
          },
          columnVisibility: {
            columnVisibility: columnsConfig.columnVisibility,
            onChange: setColumnVisibility,
          },
          refresh: {
            onClick: fetchList,
          },
        },
        leftItems: {
          customPlugins: [
           
        
          
          ],
        },
        rightItems: {
          customPlugins: [],
        },
      },
   
      masterColumns: [
        {
          key: "view",
          headerName: "Actions",
          fieldName: "",
          renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
          width: '90px',
          isFirstColumnSticky: true,
          onRowCellRender: (value, row: IBusinessTask) => {
        
            return (
              <StandardTableActions
             
             
                onEditClick={() => {
                  setOpen(row);
                }}
              />
            );
          },
        },
        {
          key: "status",
          headerName: "Status",
          fieldName: "status",
          enableSorting: true,
          renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
          onRowCellRender: (value, row: IBusinessTask) => {
            return <StatusRenderer status={row.status} />;
          },
        },
      
  
        {
          key: "task_name",
          headerName: "Task Name",
          fieldName: "task_name",
          renderType: DataTableV2RowRenderType.TEXT_DARK,
  
          enableSorting: true,
  
        },
        {
          key: "task_type",
          headerName: "Task Type",
          fieldName: "task_type",
          renderType: DataTableV2RowRenderType.CHIP_WARNING,
  
          enableSorting: true,
  
        },
        {
          key: "assigned_to_name",
          headerName: "Assigned To",
          fieldName: "assigned_to_name",
          renderType: DataTableV2RowRenderType.TEXT,
          // exportCellWidth: 20,
          enableSorting: true,
        },
        {
          key: "created_by_name",
          headerName: "Created By",
          fieldName: "created_by_name",
          renderType: DataTableV2RowRenderType.TEXT,
          // exportCellWidth: 20,
          enableSorting: true,
        },
        {
          key: "task_priority",
          headerName: "Task Priority",
          fieldName: "task_priority",
          renderType: DataTableV2RowRenderType.TEXT_DARK,
          // exportCellWidth: 30,
          enableSorting: true,
        },
      
     
  
        {
          key: "insert_ts",
          headerName: "Date Created",
          fieldName: "create_ts",
          enableSorting: true,
          renderType: DataTableV2RowRenderType.DATE_TIME,
  
        },
  
      ],
      tableTabProps: {
        selectedTab: tabs.selectedTab,
        tabs: tabs.tabs,
        onTabChange: (newSelectedTab) => {
          setSelectedTab(newSelectedTab);
        },
      },
  
      rows: list,
  
      onPageChange: (newPageNumber: number) => {
        setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
      },
      onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
        setTablePagination({
          pageNumber: pageNumber,
          rowsInPerPage: rowsPerPage,
        });
      },
    
    };
  

  
    React.useEffect(() => {
      return () => {
        dispatch(clearBusinessTasks());
      };
    }, []);
  
    return (
      <>
      
        <PageTitleBar
          heading="Tasks"
         
        />
        <DataTableV2 {...tasksTableProps} />
        {open &&
           <ManageBusinessTask
              open={true}
              task={open}
              moduleInformation={{
                moduleId: open.module_reference_code_or_id,
                moduleName: open.module_name as string,
                subModuleName: open.sub_module_name as string,
                refColumnName: open.module_reference_column as string,
              }}
              onClose={()=> setOpen(null)}
 
           />

        }
       
      </>
    );
  };
  