import { LoadState } from "../../constants/enums";


export interface ILeadsState {
    list: ILead[];
    totalRecords: number;
    loading: LoadState;
    lead: ILead;
    leadLoading: LoadState;
    error: string | null;
}


export interface ILead {
    id: number | null;
    first_name: string;
    last_name: string;
    insurance_type: string | null;
    insurance_sub_type: string | null;
    email: string;
    phone: string;
    notes: string;
    comment: string | null;
    date: string | null;
    time_to_contact: string | null;
    insert_ts: string;
    specify: string;
    status: string;
    isconvert_status: string;
    business_name: string | null;
    group_name: string | null;
    lead_source: string | null;
    producer: string | null;
    producer_name: string | null;
    lead_id: string | null;
    assigned_to_id: number | null;
    assigned_to_name: string | null;
}


export const initialLead: ILead = {
    id: null,
    lead_id: null,
    insurance_type: null,
    insurance_sub_type: null,
    first_name: "",
    last_name: "",
    assigned_to_id: null,
    assigned_to_name: null,
    business_name: "",
    group_name: "",
    email: "",
    phone: "",
    notes: "",
    comment: "",
    date: null,
    time_to_contact: "",
    insert_ts: "",
    status: "ACTIVE",
    producer_name: null,
    isconvert_status: "Leads",
    lead_source: "",
    specify: "",
    producer: "",
}

export const initialLeadState: ILeadsState = {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    lead: initialLead,
    leadLoading: LoadState.NotLoaded,
    error: null,
}
