import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  ControlledCustomSelect,
  CustomCheckBoxWithLabel,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { renderDropdownValue } from "../../../../../../../helpers";
import {
  cancelType,
  cancellationReason,
  form_of_buisness,
  policyStatus,
  segment,
  transactionTypes,
  typeOfPolicy,
} from "../../../../../../../constants/constants";
import {
  ILocationResponsePayload,
  LocationAutoComplete,
} from "../../../../../../../components/LocationAutoComplete/LocationAutoComplete";
import {
  CustomDatePicker,
  CustomTimePicker,
} from "../../../../../../../components/formsComponents/CustomDatePicker";
import {
  useCustomerFullInfo,
  useDispatchWrapper,
  useFetchPolicies,
} from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import moment from "moment";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import {
  addLifeInsuranceCustomerInfoAsync,
  fetchLifeInsuranceCustomerInfoAsync,
} from "../../../../../../../redux/lifeInsurancePolicy/lifeInsuranceBasicDetail/lifeInsuranceBasicDetailActions";
import { ILifeInsuranceCustomerInfo } from "../../../../../../../redux/lifeInsurancePolicy/lifeInsuranceBasicDetail/lifeInsuranceBasicDetail.types";
import { MODULE_IDS, POLICIES } from "../../../../../../../constants/enums";
import { useNavigate } from "react-router-dom";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";

export const LifeInsuranceCustomerInfo: React.FC<{
  customerId: number;
  customerPolicyId?: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const { policies, getPolicySubtypes } = useFetchPolicies();
  const customerFullDetails = useCustomerFullInfo();
  const [saveLoading, setSaveLoading] = React.useState(false);
  const { customerInfo } = useSelector(
    (storeState: IStoreState) => storeState.lifeInsurance.basicDetails
  );

  const ComplianceCheckList = useSelector(
    (storeState: IStoreState) =>
      storeState.lifeInsurance.complianceCheckList.data
  );

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();
  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: customerInfo.data,
    validate: (values) => {
      const errors: any = {};
      if (Number(values.sub_type) === 0) {
        errors.sub_type = "*Line of Business is required";
      } else if (!values.line_of_business) {
        errors.line_of_business = "*Line of Business is required";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        addLifeInsuranceCustomerInfoAsync(
          { ...values, customer_id: customerId },
          (isSuccess, policyId) => {
            if (isSuccess && policyId) {
              navigate(
                `/life-insurance-policy/${props.customerId}/basic-details/${policyId}`
              );
            }
            setSaveLoading(false);
          }
        )
      );
    },
  });

  const handleAddress = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      city: data.city,
      country: data.country,
      state: data.state,
      postal_code: data.postalCode,
    });
  };

  const handleCheckBox =
    (key: keyof ILifeInsuranceCustomerInfo) =>
    (e: React.SyntheticEvent<Element>, checked: boolean) => {
      setFieldValue(key, checked);
    };

  const handleLineOfBusiness = (event: SelectChangeEvent<unknown>) => {
    setValues({
      ...values,
      line_of_business: event.target.value as string,
      sub_type: "",
    });
  };

  const { customerProspect } = useSelector(
    (storeState: IStoreState) => storeState.customer
  );

  React.useEffect(() => {
    if (!customerPolicyId && customerFullDetails) {
      setValues({
        ...customerInfo.data,
        customer_code: customerProspect.data.customer_code,
        customer_name: customerProspect.data.customer_name,
        state: customerProspect.data.province_or_state,
        city: customerProspect.data.city,
        postal_code: customerProspect.data.postal_code,
        country: customerProspect.data.country,
        unit_or_suite: customerProspect.data.unit_or_suite,
        language: customerProspect.data.language,
        line_of_business: POLICIES.LIFE_AND_FINCANCIAL_POLICY,
      });
    } else {
      setValues(customerInfo.data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerInfo.data, customerProspect.data, customerPolicyId]);

  React.useEffect(() => {
    setFieldValue("compliance_status", ComplianceCheckList.compliance_status);
  }, [ComplianceCheckList]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Life Insured</CustomFormLabel>
          <CustomTextField
            name="customer_name"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.customer_name}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={1.8}>
          <CustomFormLabel>Customer Code</CustomFormLabel>
          <CustomTextField
            name="customer_code"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            disabled
            value={values.customer_code}
          />
        </Grid>

        <Grid item xs={12} lg={1.8}>
          <CustomFormLabel>Language</CustomFormLabel>
          <ControlledCustomSelect
            fullWidth
            value={values.language}
            name="language"
            onChange={handleChange}
            placeholder="Select One"
            displayEmpty
            options={["E", "F"].map((template) => {
              return { label: template, value: template };
            })}
          ></ControlledCustomSelect>
        </Grid>

        <Grid item xs={12} lg={1.8}>
          <CustomFormLabel>Address Type</CustomFormLabel>
          <CustomSelect
            variant="outlined"
            size="small"
            type="string"
            name="address_type"
            fullWidth
            value={values.address_type}
            onChange={handleChange}
            displayEmpty
            renderValue={renderDropdownValue(`Select one`)}
          >
            {["R", "B"].map((transactionType) => {
              return (
                <MenuItem value={transactionType}>{transactionType}</MenuItem>
              );
            })}
          </CustomSelect>
        </Grid>

        <Grid item xs={12} lg={3.6}>
          <CustomFormLabel>Address</CustomFormLabel>
          <LocationAutoComplete
            name="address"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.address}
            onLocationChange={handleAddress}
          />
        </Grid>

        <Grid item xs={12} lg={1.8}>
          <CustomFormLabel>Unit/Suite</CustomFormLabel>
          <CustomTextField
            id="unit_or_suite"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.unit_or_suite}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={1.8}>
          <CustomFormLabel>City</CustomFormLabel>
          <CustomTextField
            id="city"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.city}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={1.8}>
          <CustomFormLabel>State/Province</CustomFormLabel>
          <CustomTextField
            id="province_or_state"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.state}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={1.2}>
          <CustomFormLabel>Postal Code</CustomFormLabel>
          <CustomTextField
            id="postal_code"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.postal_code}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={1.8}>
          <CustomFormLabel>Country</CustomFormLabel>
          <CustomTextField
            id="country"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.country}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <LabelCard heading="Policy Information" />

      <Grid container spacing={1}>
        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Policy Status</CustomFormLabel>
          <CustomSelect
            variant="outlined"
            size="small"
            type="string"
            fullWidth
            name="policy_status"
            value={values.policy_status}
            onChange={handleChange}
            displayEmpty
            renderValue={renderDropdownValue(`Select one`)}
          >
            {policyStatus.map((status) => {
              return <MenuItem value={status}>{status}</MenuItem>;
            })}
          </CustomSelect>
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Policy Number</CustomFormLabel>
          <CustomTextField
            name="policy_number"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.policy_number}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Effective Date</CustomFormLabel>
          <CustomDatePicker
            fullWidth
            value={values.effective_date || ""}
            onChange={(newValue) => {
              setFieldValue("effective_date", newValue);
            }}
          />
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Expiry Date</CustomFormLabel>
          <CustomDatePicker
            fullWidth
            value={values.expiry_date || ""}
            onChange={(newValue) => {
              setFieldValue("expiry_date", newValue);
            }}
          />
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Policy Limit</CustomFormLabel>
          <CustomTextField
            name="policy_limit"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.policy_limit}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Policy Type</CustomFormLabel>
          <CustomSelect
            variant="outlined"
            size="small"
            type="string"
            name="line_of_business"
            fullWidth
            value={values.line_of_business}
            onChange={handleLineOfBusiness}
            disabled
            displayEmpty
            renderValue={renderDropdownValue(`Select one`)}
            error={Boolean(errors.line_of_business)}
          >
            {policies.map((policy) => {
              return <MenuItem value={policy.type}>{policy.type}</MenuItem>;
            })}
          </CustomSelect>
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Line of Business</CustomFormLabel>
          <ControlledCustomSelect
            variant="outlined"
            size="small"
            type="string"
            name="sub_type"
            fullWidth
            value={Number(values.sub_type)}
            onChange={handleChange}
            displayEmpty
            placeholder="Select One"
            helperText={errors.sub_type}
            options={getPolicySubtypes(values.line_of_business || "").map(
              (item) => {
                return { label: item.name, value: item.policy_id };
              }
            )}
            error={Boolean(errors.sub_type)}
          ></ControlledCustomSelect>
        </Grid>

        {values.policy_status === "Cancelled" && (
          <>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Date Cancelled</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.date_cancelled || ""}
                onChange={(newValue) => {
                  setFieldValue("date_cancelled", newValue);
                }}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={12}>
          <LabelCard heading="Preferences" />
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} lg={4}>
            <FormControlLabel
              labelPlacement="end"
              onChange={handleCheckBox(
                "Send_Invoices_Renewal_Recievable_By_Email"
              )}
              control={
                <Checkbox
                  checked={
                    values.Send_Invoices_Renewal_Recievable_By_Email || false
                  }
                />
              }
              label="Send Invoices , Renewal , Recievable By Email"
              sx={{ m: 0, fontSize: 15 }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <FormControlLabel
              labelPlacement="end"
              onChange={handleCheckBox("Send_Statement_By_Email")}
              control={
                <Checkbox
                  name="Send_Statement_By_Email"
                  checked={values.Send_Statement_By_Email || false}
                />
              }
              label="Send Statement By Email"
              sx={{ m: 0, fontSize: 15 }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <FormControlLabel
              labelPlacement="end"
              onChange={handleCheckBox("Send_docs_By_Email")}
              control={
                <Checkbox
                  name="Send_docs_By_Email"
                  checked={values.Send_docs_By_Email || false}
                />
              }
              label="Send Docs By Email"
              sx={{ m: 0, fontSize: 15 }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{ mt: 2 }}>
        <RoleBasedCustomButton
          moduleId={MODULE_IDS.LIFE_AND_FINCANCIAL}
          type="submit"
          disabled={saveLoading}
          variant="contained"
        >
          Save
        </RoleBasedCustomButton>
      </Box>
    </form>
  );
};
