import produce from "immer";
import { BusinesPolicyAdditionalActions } from ".";
import { IStoreState } from "../../initialStoreState";
import { defaultBusinessPolicyAdditionalState } from "./businessPolicyAdditional.types";
import {
  CLEAR_BUSINES_POLICY_ADDITIONAL,
  CLEAR_BUSINES_POLICY_ADDITIONAL_STATE,
  FETCH_BUSINESS_POLICY_ADDITIONAL_FAILED,
  FETCH_BUSINESS_POLICY_ADDITIONAL_LIST_FAILED,
  FETCH_BUSINESS_POLICY_ADDITIONAL_LIST_PROGRESS,
  FETCH_BUSINESS_POLICY_ADDITIONAL_LIST_SUCCESS,
  FETCH_BUSINESS_POLICY_ADDITIONAL_PROGRESS,
  FETCH_BUSINESS_POLICY_ADDITIONAL_SUCCESS,
} from "./businessPolicyAdditionalActions";
import { LoadState } from "../../../constants/enums";

export const businessPolicyAdditionalReducer = (
  state: IStoreState["business"]["additional"] = defaultBusinessPolicyAdditionalState,
  action: BusinesPolicyAdditionalActions
) => {
  switch (action.type) {
    case FETCH_BUSINESS_POLICY_ADDITIONAL_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_ADDITIONAL_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_ADDITIONAL_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_ADDITIONAL_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.additionalLoading = LoadState.InProgress;
        draftState.additional =
          defaultBusinessPolicyAdditionalState["additional"];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_ADDITIONAL_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.additionalLoading = LoadState.Loaded;
        draftState.additional = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_ADDITIONAL_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.additional =
          defaultBusinessPolicyAdditionalState["additional"];
      });
      return newState;
    }

    case CLEAR_BUSINES_POLICY_ADDITIONAL: {
      const newState = produce(state, (draftState) => {
        draftState.additional =
          defaultBusinessPolicyAdditionalState["additional"];
      });
      return newState;
    }
    case CLEAR_BUSINES_POLICY_ADDITIONAL_STATE: {
      return defaultBusinessPolicyAdditionalState;
    }
    default: {
      return state;
    }
  }
};
