import { action } from "typesafe-actions";
import {
  ITaskPaymentAuth,
  initialPaymentMethodsState,
} from "./taskPaymentMethods.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { uploadFile } from "../../helpers";

export const FETCH_TASK_PAYMENT_AUTH_PROGRESS =
  "FETCH_TASK_PAYMENT_AUTH_PROGRESS";
export const FETCH_TASK_PAYMENT_AUTH_SUCCESS =
  "FETCH_TASK_PAYMENT_AUTH_SUCCESS";
export const FETCH_TASK_PAYMENT_AUTH_FAILED = "FETCH_TASK_PAYMENT_AUTH_FAILED";

export const fetchTaskPaymentAuthProgress = () =>
  action(FETCH_TASK_PAYMENT_AUTH_PROGRESS);
export const fetchTaskPaymentAuthSuccess = (data: ITaskPaymentAuth) =>
  action(FETCH_TASK_PAYMENT_AUTH_SUCCESS, { data });
export const fetchTaskPaymentAuthFailed = () =>
  action(FETCH_TASK_PAYMENT_AUTH_FAILED);

export const fetchTaskPaymentAuthAsync =
  (taskCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTaskPaymentAuthProgress());
      const res = await api.get(
        `/tasks/get-tasks-payment-authorization-change?task_code=${taskCode}`
      );
      const data: ITaskPaymentAuth[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchTaskPaymentAuthSuccess(data[0]));
      } else {
        dispatch(
          fetchTaskPaymentAuthSuccess(
            initialPaymentMethodsState.paymentAuthorization.data
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchTaskPaymentAuthFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskPaymentAuthAysnc =
  (
    data: ITaskPaymentAuth,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/tasks/create-tasks-payment-authorization-change", {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Payment authorization change is saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_TASK_PAYMENT_METHODS = "CLEAR_TASK_PAYMENT_METHODS";

export const clearTaskPaymentMethods = () => action(CLEAR_TASK_PAYMENT_METHODS);
