import React from "react";
import { TASK_SUBTYPE } from "../../../constants/enums";
import { ViewAddressChange } from "../ChangeAddress/ViewAddressChange/ViewAddressChange";
import { ViewCoverageChange } from "../CoverageChange/ViewCoverageChange/ViewCoverageChange";
import { ViewDriver } from "../Driver/ViewDriver/ViewDriver";
import { ViewGaragePlate } from "../GaragePlate/ViewGaragePlate/ViewGaragePlate";
import { ViewGeneralChange } from "../GeneralChange/ViewGeneralChange/ViewGeneralChange";
import { ViewInterestedParty } from "../InterestedParty/ViewInterestedParty/ViewInterestedParty";
import { ViewNewUpload } from "../NewUpload/ViewNewUpload/ViewNewUpload";
import { ViewPaymentTransaction } from "../PaymentAuthorization/ViewPaymentTransaction/ViewPaymentTransaction";
import { ViewPolicyForm } from "../PolicyForm/ViewPaymentAuthorization/ViewPolicyForm";
import { ViewRiskLocation } from "../RiskLocation/ViewRiskLocation/ViewRiskLocation";
import { ViewVehicle } from "../Vehicle/ViewVehicle/ViewVehicle";
import {
  ITaskFileUpload,
  ITaskFileUploadAttachment,
} from "../../../redux/tasks/task.types";
import { ViewLaoBor } from "../LOA_BOR/ViewLaoBor/ViewLaoBor";
import { ViewQuoteRequest } from "../QuoteRequest/ViewQuoteRequest/ViewQuoteRequest";
import { ViewCertificateofInsurance } from "../CertificateofInsurance/ViewCertificateofInsurance/ViewCertificateofInsurance";

interface IViewTaskDetailsLayoutProps {
  taskCode: string;
  subType: string;
  attachment: ITaskFileUploadAttachment[];
}

export const ViewTaskDetailsLayout: React.FC<IViewTaskDetailsLayoutProps> = (
  props
) => {
  const { taskCode, subType, attachment } = props;

  switch (subType) {
    case TASK_SUBTYPE.ADDRESS_CHANGE:
      return <ViewAddressChange taskCode={taskCode} attachment={attachment} />;

    case TASK_SUBTYPE.COVERAGE_CHANGE:
      return <ViewCoverageChange taskCode={taskCode} attachment={attachment} />;

    case TASK_SUBTYPE.GENERAL_CHANGE:
      return <ViewGeneralChange taskCode={taskCode} attachment={attachment} />;

    case TASK_SUBTYPE.QUOTE_REQUEST:
      return <ViewQuoteRequest taskCode={taskCode} attachment={attachment} />;
    case TASK_SUBTYPE.TASK_CERTIFICATE_OF_INSURANCE:
      return <ViewCertificateofInsurance taskCode={taskCode} attachment={attachment} />;

    case TASK_SUBTYPE.DRIVER_CHANGE:
      return <ViewDriver taskCode={taskCode} attachment={attachment} />;

    case TASK_SUBTYPE.GARAGE_PLATE_CHANGE:
      return <ViewGaragePlate taskCode={taskCode} attachment={attachment} />;

    case TASK_SUBTYPE.INTERESTED_PARTY_CHANGE:
      return (
        <ViewInterestedParty taskCode={taskCode} attachment={attachment} />
      );

    case TASK_SUBTYPE.RISK_LOCATION_CHANGE:
      return <ViewRiskLocation taskCode={taskCode} attachment={attachment} />;

    case TASK_SUBTYPE.VEHICLE_CHANGE:
      return <ViewVehicle taskCode={taskCode} attachment={attachment} />;

    case TASK_SUBTYPE.PAYMENT_AUTH_CHANGE:
      return (
        <ViewPaymentTransaction taskCode={taskCode} attachment={attachment} />
      );

    case TASK_SUBTYPE.CANCELLATION_REINSTALLMENT:
      return <ViewPolicyForm taskCode={taskCode} attachment={attachment} />;

    case TASK_SUBTYPE.LAO_BOR:
    case TASK_SUBTYPE.LETTER_OF_EXPERIENCE:
      return <ViewLaoBor taskCode={taskCode} attachment={attachment} />;

    case TASK_SUBTYPE.NEW_PERSONAL_AUTO:
    case TASK_SUBTYPE.NEW_HOME:
    case TASK_SUBTYPE.NEW_BUSINESS:
    case TASK_SUBTYPE.NEW_PERSONAL_AND_HOME:
    case TASK_SUBTYPE.NEW_COMMERCIAL_AUTO:
    case TASK_SUBTYPE.REMARKET_PERSONAL_AUTO:
    case TASK_SUBTYPE.REMARKET_HOME:
    case TASK_SUBTYPE.REMARKET_PERSONAL_AND_HOME:
    case TASK_SUBTYPE.REMARKET_BUSINESS:
      return <ViewNewUpload taskCode={taskCode} attachment={attachment} />;
    default:
      return <></>;
  }
};
