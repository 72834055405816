import { Box, Button, Grid, MenuItem, Typography } from "@mui/material";
import React from "react";
import { SignatureStatusWidget } from "../components/SignatureStatusWidget/SignatureStatusWidget";
import { StandadCard } from "../../../components/Cards";
import { FileUploadV2 } from "../../../components/FileUpload/FileUploadV2";
import { CustomSelect } from "../../../components/formsComponents";
import { DateFilterDropdown } from "../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { RefreshPlugin, SearchPlugin } from "../../../components/Table/plugins";
import {
  IDataTableProps,
  RenderType,
} from "../../../components/Table/interfaces/IDataTableProps";
import { StandardTableActions } from "../../../components/StandardTableActions/StandardTableActions";
import { useDateFilter } from "../../../components/Table/hooks/useDateFilter";
import { CustomDatePicker } from "../../../components/formsComponents/CustomDatePicker";
import { DataTable } from "../../../components/Table/DataTable";
import { useAuth } from "../../../contexts/AuthProvider/AuthProvider";
import { useRoles } from "../../../security/RolesProvider/RolesProvider";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../redux/initialStoreState";
import { useDispatchWrapper, useRecordCounts } from "../../../hooks";
import {
  clearG4DocumentsState,
  fetchG4SignDocListAsync,
  fetchG4SignDocumentAsync,
} from "../../../redux/g4SignDocuments/g4SignDocumentsAction";
import { StatusRenderer } from "../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { LoadState } from "../../../constants/enums";
import {
  IG4SignDocument,
  IG4SignDocumentRecipientGet,
} from "../../../redux/g4SignDocuments/g4SignDocuments.types";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../../redux/common/commonActions";
import { IconicMultiColumnCard } from "../../../components/iconicMultiColumnCard/IconicMultiColumnCard";
import {
  capitalizeWords,
  createTabsWithRecordcounts,
  getFileExtensionFromUrl,
} from "../../../helpers";
import { api } from "../../../api/api";
import {
  downLoadFile,
  previewFile,
} from "../../../components/FileUpload/utils";
import LoadingDialog from "../../../components/Dialogs/LoadingDialog";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../../redux/messages/messagesActions";
import { CustomLink } from "../../../components/CustomLink/CustomLink";
import { ViewCustomerDetails } from "../../customers/components/ViewCustomerDetails/ViewCustomerDetails";
import { ViewDocumentReceipt } from "./ViewDocumentReceipt/ViewDocumentReceipt";
import { PageContainer } from "../../../components/container/PageContainer";
import { g4SignStatusList } from "../../../constants/constants";
import { ITableTab } from "../../../components/Table/components/TableTabs/TableTabs.types";
import { PageTitleBar } from "../../../components/PageTitleBar/PageTitleBar";
import { useTableV2State } from "../../../components/TableV2/hooks/useTableV2State";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../components/TableV2/interfaces/IDataTableV2Props";
import { DataTableV2 } from "../../../components/TableV2/DataTableV2";

export const Documents: React.FC = () => {
  const { firstName, lastName } = useRoles();

  const { list, totalRecords, loading } = useSelector(
    (storeState: IStoreState) => storeState.g4Sign.documents
  );
  const dispatch = useDispatchWrapper();
  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });
  const [downloading, setDownloading] = React.useState(false);
  const [openView, setOpenView] = React.useState<IG4SignDocument | null>(null);
  const navigate = useNavigate();

  const redirectToUpsetDocument = (documentCode?: string) => {
    if (documentCode) {
      navigate(`/g4sign/documents/manage/${documentCode}`);
    } else {
      navigate(`/g4sign/documents/manage`);
    }
  };

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "lastWeek",
      selectedTab: "-1",
    },
  });

  const fetchList = () => {
    dispatch(
      fetchG4SignDocListAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        tabs.selectedTab,
        dateState.dates,
        searchState
      )
    );
  };

  React.useEffect(() => {
    fetchList();
  }, [tablePagination, dateState.dates, tabs.selectedTab, searchState]);

  const handleGenerateDocument = async (documentCode?: string) => {
    if (documentCode) {
      try {
        dispatch(saveLoaderProgress());
        await dispatch(fetchG4SignDocumentAsync(documentCode));
        dispatch(
          showMessage({
            type: "success",
            displayAs: "snackbar",
            message: "Document generated successfully!",
          })
        );
        fetchList();
      } catch (error) {
        console.error("Failed to generate document:", error);
      } finally {
        dispatch(saveLoaderCompleted());
      }
    }
  };

  const documentsTableProps: IDataTableV2Props = {
    isPagination: true,
    totalRecords: totalRecords,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    isDataLoading: loading === LoadState.InProgress,
    selectionMode: "none",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      preDefinedPlugins: {
        dateFilter: {
          state: dateState,
          onChange: setDateState,
        },
        search: {
          state: searchState,
          onChange: setSearchState,
        },
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {
        customPlugins: [],
      },
      rightItems: {
        customPlugins: [],
      },
    },

    initialSortConfig: {
      insert_ts: {
        direction: "desc",
        filedName: "insert_ts",
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        isFirstColumnSticky: true,
        onRowCellRender: (value, row: IG4SignDocument) => {
          return (
            <StandardTableActions
              onDownloadPreview={
                row.signed_document_url
                  ? async () => {
                      try {
                        dispatch(saveLoaderProgress());
                        await previewFile(row.signed_document_url);
                      } catch (err) {
                        console.log(err);
                      } finally {
                        dispatch(saveLoaderCompleted());
                      }
                    }
                  : undefined
              }
              onDownLoadClick={
                row.signed_document_url
                  ? async () => {
                      try {
                        dispatch(saveLoaderProgress());
                        await downLoadFile(row.signed_document_url);
                      } catch (err) {
                        console.log(err);
                      } finally {
                        dispatch(saveLoaderCompleted());
                      }
                    }
                  : undefined
              }
              onViewClick={() => setOpenView(row)}
              onEditClick={() => {
                navigate(`/g4sign/documents/manage/${row.document_code}`);
              }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "document_code",
        headerName: "Document Code",
        fieldName: "document_code",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CHIP_WARNING,
      },
      {
        key: "title",
        headerName: "Title",
        fieldName: "title",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return (
            <CustomLink
              to={`/g4sign/documents/manage/${row.document_code}`}
              label={row.title}
            />
          );
        },
      },
      {
        key: "customer_name",
        headerName: "Customer Name",
        fieldName: "customer_name",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          if (row.customer_name) {
            return (
              <CustomLink
                to={`/g4sign/documents/manage/${row.document_code}`}
                label={row.customer_name}
              />
            );
          }
          return "--";
        },
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },
      {
        key: "producer",
        headerName: "Producer",
        fieldName: "producer",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },
      {
        key: "branch",
        headerName: "Branch",
        fieldName: "branch",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },
      {
        key: "created_by_name",
        headerName: "Created By",
        fieldName: "created_by_name",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },
      {
        key: "insert_ts",
        headerName: "Date Created",
        fieldName: "insert_ts",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.DATE_TIME,
        // width: '25px',
      },
      {
        key: "label",
        headerName: "",
        fieldName: "label",
        isLastColumnSticky: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IG4SignDocument) => {
          if (row.status === "COMPLETED") {
            return (
              <Box display={"flex"} justifyContent={"start"}>
                <Button
                  variant="contained"
                  type="button"
                  onClick={() =>
                    handleGenerateDocument(row.document_code || "")
                  }
                >
                  Generate Document
                </Button>
              </Box>
            );
          }
          return null;
        },
      },
    ],

    rows: list || [],

    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
    tableTabProps: {
      selectedTab: tabs.selectedTab,
      tabs: tabs.tabs,
      onTabChange: (newSelectedTab) => {
        setSelectedTab(newSelectedTab);
      },
    },
  };

  React.useEffect(() => {
    const tabsData = createTabsWithRecordcounts(
      g4SignStatusList,
      recordsCounts
    );
    setTableTabs(tabsData);
  }, [recordsCounts]);

  React.useEffect(() => {
    dispatch(
      fetchRecordCountsAsync(
        "latest_sign_document",
        "status",
        dateState.dates.fromDate,
        dateState.dates.toDate
      )
    );
    return () => {
      dispatch(clearRecordCounts());
    };
  }, [dateState.dates.fromDate, dateState.dates.toDate]);

  React.useEffect(() => {
    return () => {
      dispatch(clearG4DocumentsState());
    };
  }, []);

  return (
    <>
      <PageContainer title="View Document" description="this is innerpage">
        <Typography variant="h1">Hi {` ${firstName}`}!</Typography>

        {/* <StandadCard>
        <Grid container spacing={2.5}>
          <Grid item xs={12}>

   
          </Grid>
          <Grid item xs={12} md={3}>
            <SignatureStatusWidget count={5} status="Pending" />
          </Grid>
          <Grid item xs={12} md={3}>
            <SignatureStatusWidget count={5} status="Completed" />
          </Grid>
          <Grid item xs={12} md={3}>
            <SignatureStatusWidget count={20} status="Draft" />
          </Grid>
          <Grid item xs={12} md={3}>
            <SignatureStatusWidget
              count={11}
              status="Pending"
              hideRightBorder
            />
          </Grid>
        </Grid>
      </StandadCard> */}
        {/* <Box mt={5}>
        <FileUploadV2
          value={null}
          onChange={(data) => setFile(file)}
          onDelete={() => setFile(null)}
          actionButton={{
            text: "Get Signed",
            onClick: ()=> setOpen(true)
          }}
        />
      </Box> */}

        <Box mt={5}>
          <PageTitleBar
            heading=""
            rightHeading={
              <Button
                sx={{ mb: 2 }}
                variant="contained"
                onClick={() => redirectToUpsetDocument()}
              >
                Create Document
              </Button>
            }
          />
          <DataTableV2 {...documentsTableProps} />

          {openView && (
            <ViewDocumentReceipt
              open={true}
              document={openView}
              // customer={openView}
              onClose={() => setOpenView(null)}
            />
          )}
        </Box>
      </PageContainer>
    </>
  );
};
