import { LoadState } from "../../../constants/enums";

export interface IAccountingInformationState {
  list: IAccountingInformation[];
  listLoading: LoadState;
  data: IAccountingInformation;
  loading: LoadState;
  error: string | null;
}

export interface IAccountingInformation {
  commision_percentage: string | null;
  total_commision_amount: string | null;
  net_payable_new: string | null;
  transaction_description: string | null;
  commision_split_table: string | null;
  premium_financed: string | null;
  financed_by: string | null;
  status: string | null;
}

export const initialAccountingInformationState: IAccountingInformationState = {
  list: [],
  listLoading: LoadState.NotLoaded,
  data: {
    commision_percentage: null,
    total_commision_amount: null,
    net_payable_new: null,
    transaction_description: null,
    commision_split_table: null,
    premium_financed: null,
    financed_by: null,
    status: "Active",
  },
  loading: LoadState.NotLoaded,
  error: null,
};
