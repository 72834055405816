import { LoadState } from "../../constants/enums";

export interface IIntrestedPartiesState {
  intrestedParties: {
    data: IInterestedParty;
    loading: LoadState;
    saveLoading: boolean;
    error: string | null;
    list: IInterestedParty[];
    listLoading: LoadState;
  };
  // additionalIntrest: {
  //   data: IAdditionalInterest;
  //   loading: LoadState;
  //   saveLoading: boolean;
  //   error: string | null;
  // };
}

export interface IInterestedParty {
  interested_party_id: number | null;
  interested_party_code: string;
  interested_party_name: string | null;
  interest: string | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  state_or_province: string | null;
  postal_code: string | null;
  country: string | null;
  status: string;
}

// export interface IAdditionalInterest {
//   incremental_number: number | null;
//   name: string | null;
//   interest: string | null;
//   address: string | null;
//   unit_or_suite: string | null;
//   city: string | null;
//   state_or_province: string | null;
//   postal_code: string | null;
//   country: string | null;
//   status: string;
// }

export const initialIntrestedPartiesState: IIntrestedPartiesState = {
  intrestedParties: {
    list: [],
    listLoading: LoadState.NotLoaded,
    data: {
      interested_party_id: null,
      interested_party_code: "",
      interested_party_name: null,
      interest: null,
      address: null,
      unit_or_suite: null,
      city: null,
      state_or_province: null,
      postal_code: null,
      country: null,
      status: "Active",
    },
    loading: LoadState.NotLoaded,
    saveLoading: false,
    error: null,
  },

  // additionalIntrest: {
  //   data: {
  //     incremental_number: null,
  //     name: null,
  //     interest: null,
  //     address: null,
  //     unit_or_suite: null,
  //     city: null,
  //     state_or_province: null,
  //     postal_code: null,
  //     country: null,
  //     status: "Active",
  //   },
  //   loading: LoadState.NotLoaded,
  //   saveLoading: false,
  //   error: null,
  // },
};
