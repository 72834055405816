import produce from "immer";
import { BusinesPolicyHistoryActions } from ".";
import { IStoreState } from "../../initialStoreState";
import { initialBusinessPolicyHistoryState } from "./businessPolicyHistory.types";
import {
  CLEAR_BUSINESS_POLICY_HISTORY_STATE,
  FETCH_BUSINESS_POLICY_HISTORY_LIST_FAILED,
  FETCH_BUSINESS_POLICY_HISTORY_LIST_PROGRESS,
  FETCH_BUSINESS_POLICY_HISTORY_LIST_SUCCESS,
} from "./businessPolicyHistoryActions";
import { LoadState } from "../../../constants/enums";

export const businessPolicyHistoryReducer = (
  state: IStoreState["business"]["history"] = initialBusinessPolicyHistoryState,
  action: BusinesPolicyHistoryActions
) => {
  switch (action.type) {
    case FETCH_BUSINESS_POLICY_HISTORY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_HISTORY_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_HISTORY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.list = [];
      });
      return newState;
    }
    case CLEAR_BUSINESS_POLICY_HISTORY_STATE: {
      return initialBusinessPolicyHistoryState;
    }

    default: {
      return state;
    }
  }
};
