import { LoadState } from "../../../constants/enums";

export interface ITaxState {
  list: ITax[];
  loading : LoadState;
  data: ITax;
  error: string | null;
}

export interface ITax {
  tax_id: number;
  tax_code: string;
  province: string | null;
  line_of_business: string | null;
  tax_one: string | number | null;
  tax_two: string | number | null;
  status: string;
}

export const initialTax: ITax = {
  tax_id: 0,
  tax_code: "",
  province: null,
  line_of_business: null,
  tax_one: null,
  tax_two: null,
  status: "ACTIVE",
}

export const initialTaxState: ITaxState = {
  list: [],
  loading: LoadState.NotLoaded,
  data: initialTax,
  error: null,
}