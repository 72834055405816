import { Box, Divider, useTheme } from "@mui/material";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { SignableField } from "./widgets/signableField/SignableField";
import Draggable, { DraggableEvent, DraggableData } from "react-draggable";
import { IPDFViewerProps } from "./PDFViewer.types";
import { IG4SignEditior } from "../../redux/g4SignDocuments/g4SignDocuments.types";
import { WidgetsRenderer } from "./widgetsRenderer/WidgetsRenderer";
// Set up workerSrc
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
};

export default function PDFViewer(props: IPDFViewerProps) {
  const { fields, addInitialPlacholder } = props;

  const theme = useTheme();
  const [numPages, setNumPages] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pageHeight, setPageHeight] = useState(0);
  const [pdfContent, setPdfContent] = useState<any>(null);
  const [signItem, setSignItem] = React.useState<boolean>(false);
  const draggableRef = React.useRef<HTMLDivElement | null>(null);

  function onDocumentLoadSuccess({ numPages: nextNumPages }: any) {
    console.log("Document loaded successfully");
    setNumPages(nextNumPages);
  }

  // React.useEffect(() => {
  //   const fetchPdf = async () => {
  //     try {
  //       const response = await axios.get(props.pdfUrl, {
  //         responseType: "blob", // Important
  //       });
  //       const pdfBlob = new Blob([response.data], { type: "application/pdf" });
  //       setPdfContent(URL.createObjectURL(pdfBlob));
  //     } catch (error) {
  //       console.error("Error fetching PDF:", error);
  //     }
  //   };

  //   fetchPdf();
  // }, [props.pdfUrl]);

  return (
    <Box
      hidden={false}
      mt={2}
      onMouseUp={props.onMouseUp}
      style={{ height: (numPages as any) * pageHeight + 1000 }}
      display={"flex"}
      justifyContent={"center"}
      sx={{
        backgroundColor: theme.palette.grey[100],
      }}
      pt={8}
      pb={8}
      maxHeight={"80vh"}
      overflow={"auto"}
    >
      <Document
        file={props.pdfUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(error) => {
          console.error("Error rendering PDF:", error);
          setLoading(false);
        }}
        onSourceError={(error) => {
          console.error("Error rendering PDF:", error);
          setLoading(false);
        }}
        options={options}
        renderMode="canvas"
      >
        {Array.from({ length: numPages as any }, (_, index) => (
          <Fragment key={index}>
            <Box
              id={`rel-page-${index + 1}`}
              onMouseDown={(e) => {
                if (e.button === 0) props.onMouseDown(e, index + 1);
              }}
              onMouseUp={(e) => {
                // if (e.button === 0) props.onMouseUp(e, index);
              }}
              key={index}
              style={{
                position: "relative",
                ...props.style,
              }}
            >
              <Page
                scale={2}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                onLoadSuccess={(e) => {
                  if (e.height) {
                    setPageHeight(e.height);
                  }
                  setLoading(false);
                }}
                onRenderError={() => setLoading(false)}
              ></Page>
              {fields
                .filter(
                  (placeholder: IG4SignEditior) =>
                    placeholder.page_no === index + 1
                )
                .map((item) => {
                  return (
                    <WidgetsRenderer
                      type={item.field_type}
                      placeholder={item}
                      onPlaceholderUpdated={props.onPlaceholderUpdated}
                      onPlaceholderDelete={props.onPlaceholderDelete}
                    />
                  );
                })}
              <Divider />

              {/* {props?.fields
                    .filter((field: any) => field.page === index)
                    .map((field: any) =>
                      props.readonly ? (
                        <SignableField
                          onClick={props.onClick}
                          key={field.id}
                          field={field}
                          //  className="absolute"
                          onDelete={onDeleteHandler}
                        ></SignableField>
                      ) : (
                        <></>
                        // <EditableField
                        //   hidden={
                        //     field.Signature ||
                        //     field.inserted ||
                        //     field.type === FieldType.FREE_SIGNATURE
                        //   }
                        //   key={field.id}
                        //   field={field}
                        //   className="absolute"
                        //   onPositionChanged={onPositionChangedHandler}
                        //   onDelete={onDeleteHandler}></EditableField>
                      )
                    )} */}
            </Box>
          </Fragment>
        ))}
      </Document>
    </Box>
  );
}
