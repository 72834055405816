import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import {
  ICommAutomobileCSVDetails,
  initialCommAutomoblieCSV,
} from "./commAutoCSV.types";

export const CLEAR_COMM_AUTOMOBILE_CSV_STATE = "CLEAR_AUTOMOBILE_CSV_STATE";

export const clearCommAutomobileCSVState = () =>
  action(CLEAR_COMM_AUTOMOBILE_CSV_STATE);

export const FETCH_COMM_AUTOMOBILE_CSV_DETAILS_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_CSV_DETAILS_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_CSV_DETAILS_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_CSV_DETAILS_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_CSV_DETAILS_FAILED =
  "FETCH_COMM_AUTOMOBILE_CSV_DETAILS_FAILED";

export const fetchCommAutomobileCSVDetailsProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_CSV_DETAILS_PROGRESS);
export const fetchCommAutomobileCSVDetailsSuccess = (
  data: ICommAutomobileCSVDetails
) => action(FETCH_COMM_AUTOMOBILE_CSV_DETAILS_SUCCESS, { data });
export const fetchCommAutomobileCSVDetailsFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_CSV_DETAILS_FAILED);
export const fetchCommAutomobileCSVDetailsAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileCSVDetailsProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-cvs-details?customer_policy_id=${customerPolicyId}`
      );
      const data: ICommAutomobileCSVDetails[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileCSVDetailsSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileCSVDetailsSuccess({
            ...initialCommAutomoblieCSV["details"].data,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      dispatch(fetchCommAutomobileCSVDetailsFailed());
    }
  };

export const addCommAutomobileCSVDetailsAsync =
  (
    data: ICommAutomobileCSVDetails,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-commauto-cvs-details", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "CSV details saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchCommAutomobileCSVDetailsSuccess(data));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_COMM_AUTOMOBILE_CSV_DETAILS_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_CSV_DETAILS_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_CSV_DETAILS_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_CSV_DETAILS_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_CSV_DETAILS_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_CSV_DETAILS_LIST_FAILED";

export const fetchCommAutomobileCSVDetailListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_CSV_DETAILS_LIST_PROGRESS);

export const fetchAutomobileCSVDetailListSuccess = (
  data: ICommAutomobileCSVDetails[]
) =>
  action(FETCH_COMM_AUTOMOBILE_CSV_DETAILS_LIST_SUCCESS, {
    data,
  });
export const fetchCommAutomobileCSVDetailListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_CSV_DETAILS_LIST_FAILED);

export const fetchCommAutomobileCSVDetailListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileCSVDetailListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-all-cvs?customer_policy_id=${customerPolicyId}`
      );
      const data: ICommAutomobileCSVDetails[] = res.data.data;
      dispatch(fetchAutomobileCSVDetailListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileCSVDetailListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
