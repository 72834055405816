import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import {
  CLEAR_CHARTS_OF_ACCOUNTS,
  FETCH_CHARTS_OF_ACCOUNTS_FAILURE,
  FETCH_CHARTS_OF_ACCOUNTS_PROGRESS,
  FETCH_CHARTS_OF_ACCOUNTS_SUCCESS,
  FETCH_CHARTS_OF_ACCOUNT_LIST_FAILURE,
  FETCH_CHARTS_OF_ACCOUNT_LIST_PROGRESS,
  FETCH_CHARTS_OF_ACCOUNT_LIST_SUCCESS,
} from "./ChartsOfAccountsActions";
import { ChartsOfAccountsActions } from ".";
import { initialChartsOfAccountsState } from "./ChartsOfAccounts.types";

export const ChartsOfAccountsReducer = (
  state: IStoreState["accounting"]["chartsOfAccounts"] = initialChartsOfAccountsState,
  action: ChartsOfAccountsActions
) => {
  switch (action.type) {
    case FETCH_CHARTS_OF_ACCOUNTS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CHARTS_OF_ACCOUNTS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_CHARTS_OF_ACCOUNTS_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case FETCH_CHARTS_OF_ACCOUNT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CHARTS_OF_ACCOUNT_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Loaded;
        draftState.list = data;
      });
      return newState;
    }
    case FETCH_CHARTS_OF_ACCOUNT_LIST_FAILURE: {
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Failed;
      });
      return newState;
    }
    case CLEAR_CHARTS_OF_ACCOUNTS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = initialChartsOfAccountsState.loading;
        draftState.data = initialChartsOfAccountsState.data;
        draftState.error = initialChartsOfAccountsState.error;
      });
      return newState;
    }

    default:
      return state;
  }
};
