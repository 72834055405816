import { LoadState } from "../../constants/enums";
import { getUniqueId } from "../../helpers";

export interface ITemplateState {
  list: ICreateTemplate[];
  loading: LoadState;
  totalRecords: number;
  template: ICreateTemplate;
  templateLoading: LoadState;
  error: string | null;
}

export interface ICreateTemplate {
  templates_id: number | null;
  template_code: string;
  template_name: string;
  template_category: string;
  call_type: string | null;
  table_name_or_dynamic_view_code: string;
  template_subject: string;
  column: string;
  // column_value_mapping: ICreateTemplateColumn[];
  track_changes: string
  field: string | null;
  body: string | null;
  status: string;
}

// export interface ICreateTemplateColumn {

//   reference_view: string | null;
//   common_column: string | null;
//   reference_column: string | null;
// }

export const initialTemplateState: ITemplateState = {
  list: [],
  totalRecords: 0,
  loading: LoadState.NotLoaded,
  template: {
    templates_id: null,
    template_code: "",
   
    template_category: "",
    template_name: "",
    table_name_or_dynamic_view_code: "",
    call_type: null,
    column: "",
    template_subject: "",
    // column_value_mapping: [
    //   {
    //     reference_view: null,
    //     common_column: null,
    //     reference_column: null,
    //   },
    // ],
    track_changes: "@@track_changes",
    field: null,
    body: null,
    status: "ACTIVE",
  },
  templateLoading: LoadState.NotLoaded,
  error: null,
};
