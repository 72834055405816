import { LoadState } from "../../../constants/enums";

export interface ICommAutomobileGaragePlatesState {
    data: ICommAutomobileGaragePlates[];
    loading: LoadState;
  }

export interface ICommAutomobileGaragePlates {
    customer_policy_id: number | null;
    serial_number: number | null,
    type: string | null,
    plate_number: string | null,
    charge_details: string | null,
    assigned_to: string | null,
    attachment: string | null,
    status: "ACTIVE" | "PENDING";
  }

export const defaultCommAutomobileGaragePlatesState: ICommAutomobileGaragePlatesState =
  {
    data: [],
    loading: LoadState.NotLoaded,
  };

