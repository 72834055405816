import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { LoadState } from "../../../../../../../constants/enums";
import { IAutomobileDriverInformation } from "../../../../../../../redux/automobileDriver/automobileDriver.types";
import { fetchAutomobileDriverListAsync } from "../../../../../../../redux/automobileDriver/automobileDriverActions";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";

export const AutomobileDriverInformationList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { driversList, loading } = useSelector(
    (storeState: IStoreState) => storeState.automobile.driver.driverInformation
  );

  const dispatch = useDispatchWrapper();

  React.useEffect(() => {
    dispatch(fetchAutomobileDriverListAsync(customerPolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerPolicyId]);

  const automobileDriverTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    `/automobile-policy/${customerId}/driver-tab/${customerPolicyId}/driver-information`
                  );
                }}
              >
                Add Driver
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "driver_number",
        headerName: "#",
        fieldName: "driver_number",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
      },
      {
        key: "driver_name",
        headerName: "Driver Name",
        fieldName: "driver_name",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
        onRowCellRender: (value, row) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/automobile-policy/${customerId}/driver-tab/${customerPolicyId}/driver-information/${row.driver_number}`
                );
              }}
            >
              {row.driver_name}
            </Link>
          );
        },
      },

      {
        key: "date_of_birth",
        headerName: "Date of Birth",
        fieldName: "date_of_birth",
        exportCellWidth: 25,
        renderType: RenderType.DATE_DARK_COLOR,
      },
      {
        key: "age",
        headerName: "Age",
        fieldName: "age",
        exportCellWidth: 25,
        renderType: RenderType.CHIP_WARNING,
      },


      {
        key: "driver_phone_number",
        headerName: "Phone Number",
        fieldName: "driver_phone_number",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "license_number",
        headerName: "License Number",
        fieldName: "license_number",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },

      {
        key: "marital_status",
        headerName: "Marital Status",
        fieldName: "marital_status",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "hire_date",
        headerName: "Hire Date",
        fieldName: "hire_date",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },


      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: 'center',
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IAutomobileDriverInformation) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/automobile-policy/${customerId}/driver-tab/${customerPolicyId}/driver-information/${row.driver_number}`
                );
              }}
            />
          );
        },
      },
    ],
    items: driversList,
  };

  return (
    <Box sx={{ mt: 2 }}>
      <DataTable {...automobileDriverTableProps} />
    </Box>
  );
};
