/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../redux/initialStoreState";
import { useDispatchWrapper } from "./useDispatch";
import { fetchAutomobileCustomerInfoAsync } from "../redux/automobileBasicDetails/automobileBasicDetailsActions";

export const useAutomobileCustomerBasicDetails = (
  customerPolicyId?: number
) => {
  const customerInfo = useSelector(
    (storeState: IStoreState) => storeState.automobile.basicDetails.customerInfo
  );
  const dispatch = useDispatchWrapper();

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchAutomobileCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  return customerInfo;
};
