import React, { useRef } from "react";
import {
  Avatar,
  BottomNavigation,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Comment,
  HistoryOutlined,
  InsertCommentOutlined,
  Send,
} from "@mui/icons-material";
import { RightPanel } from "../RightPanel";
import { CustomTextField } from "../formsComponents";
import { IComment } from "../../redux/comments/commentsTypes";
import { useDispatchWrapper } from "../../hooks";
import {
  fetchCommentsAysnc,
  upsertCommentAysnc,
} from "../../redux/comments/commentsActions";
import { PageLoader } from "../PageLoader/PageLoader";
import { useFormik } from "formik";
import { formatDateTimeWithLocale, formatDateWithTime } from "../../helpers";
import { IHistorySidebarProps } from "./HistorySideBar.types";
import { fetchHistoryByTableColumnAsync } from "../../redux/common/commonActions";
import { useSelector } from "react-redux";
import { IStoreState } from "../../redux/initialStoreState";
import { LoadState } from "../../constants/enums";
import ReactJson from "react-json-view";
import {
  IDataTableProps,
  RenderType,
} from "../Table/interfaces/IDataTableProps";
import { StandardTableActions } from "../StandardTableActions/StandardTableActions";
import { IGeneralHistory } from "../../redux/common/common.types";
import { DataTable } from "../Table/DataTable";
import { HistoryViewDialog } from "./HistoryViewDialog/HistoryViewDialog";

export const HistorySideBar: React.FC<IHistorySidebarProps> = (props) => {
  const { module_code } = props;
  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <Tooltip title="View History" onClick={handleToggle}>
        <HistoryOutlined color="primary" />
      </Tooltip>

      {open && (
        <RightPanel
          heading="History"
          subHeading="View history"
          open={true}
          onClose={handleToggle}
          width="60%"
          actionButtons={() => {
            return (
              <Button variant="contained" onClick={handleToggle}>
                Close
              </Button>
            );
          }}
        >
          {" "}
          <RenderSideBar
            module_code={module_code}
            onToogle={handleToggle}
          />
        </RightPanel>
      )}
    </>
  );
};

const RenderSideBar: React.FC<{
  module_code: string;
  onToogle: () => void;
}> = (props) => {
  const { module_code } = props;
  const theme = useTheme();
  const { list, loading } = useSelector(
    (storeState: IStoreState) => storeState.common.generalHistory
  );
  const dispatch = useDispatchWrapper();
  const [openView, setOpenView] = React.useState<IGeneralHistory | null>(null);

  const fetchHistory = async () => {
    dispatch(fetchHistoryByTableColumnAsync(module_code));
  };

  React.useEffect(() => {
    fetchHistory();
  }, []);

  return (
    <>
      <PageLoader loading={loading === LoadState.InProgress}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Stack spacing={4} position={"relative"}>
              {list.map(({ create_ts, message, created_by_name }, index) => {
                return (
                  <Paper sx={{ p: 3 }}>
                    <Typography
                      variant="body1"
                      display={"block"}
                      fontWeight={500}
                      fontSize={"1rem"}
                    >
                      {formatDateTimeWithLocale(create_ts)}
                    </Typography>
                    <Typography
                      marginTop={1}
                      fontWeight={600}
                      fontSize={"1.2rem"}
                    >
                      {created_by_name}
                    </Typography>
                    <Typography
                      marginTop={1}
                      variant="body1"
                      fontSize={"1.1rem"}
                    >
                      {message}
                    </Typography>
                  </Paper>
                );
              })}
              <Box
                position={"absolute"}
                height={"90%"}
                left={"10%"}
                zIndex={-1}
                sx={(theme) => ({
                  border: `1.5px solid ` + theme.palette.grey[300],
                })}
              ></Box>
            </Stack>
          </Grid>
        </Grid>
      </PageLoader>
      {openView && (
        <HistoryViewDialog
          open={true}
          data={openView}
          onClose={() => setOpenView(null)}
        />
      )}
    </>
  );
};
