import { action } from "typesafe-actions";
import { ICreateTemplate } from "./templates.types";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { ERROR_MESSAGES } from "../../constants/enums";
import { ISelectOption } from "../../constants/types";
import { formatText } from "../../helpers";

export const FETCH_TEMPLATE_LIST_PROGRESS = "FETCH_TEMPLATE_LIST_PROGRESS";
export const FETCH_TEMPLATE_LIST_SUCCESS = "FETCH_TEMPLATE_LIST_SUCCESS";
export const FETCH_TEMPLATE_LIST_FAILED = "FETCH_TEMPLATE_LIST_FAILED";

export const fetchTemplateListProgress = () =>
  action(FETCH_TEMPLATE_LIST_PROGRESS);
export const fetchTemplateListSuccess = (
  data: ICreateTemplate[],
  totalRecords: number
) => action(FETCH_TEMPLATE_LIST_SUCCESS, { data, totalRecords });
export const fetchTemplateListFailed = () => action(FETCH_TEMPLATE_LIST_FAILED);

export const fetchTemplateListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    allTemplates?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTemplateListProgress());
      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }
      const res = await api.get(
        `/templates/get-templates?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&${url}`
      );
      const data: ICreateTemplate[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchTemplateListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchTemplateListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const fetchTemplateListAllTempsAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTemplateListProgress());

      const res = await api.get(`/templates/get-templates`);
      const data: ICreateTemplate[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchTemplateListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchTemplateListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const fetchTemplateByIdAsync =
  (
    templateId: number,
    onCallback: (templates: ICreateTemplate[]) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/templates/get-templates?templates_id=${templateId}`
      );
      const list: ICreateTemplate[] = res.data.data;
      onCallback(list);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_TEMPLATE_PROGRESS = "FETCH_TEMPLATE_PROGRESS";
export const FETCH_TEMPLATE_SUCCESS = "FETCH_TEMPLATE_SUCCESS";
export const FETCH_TEMPLATE_FAILED = "FETCH_TEMPLATE_FAILED";

export const fetchTemplateProgress = () => action(FETCH_TEMPLATE_PROGRESS);
export const fetchTemplateSuccess = (data: ICreateTemplate) =>
  action(FETCH_TEMPLATE_SUCCESS, { data });
export const fetchTemplateFailed = (errorMessage: string) =>
  action(FETCH_TEMPLATE_FAILED, { errorMessage });

export const fetchTemplateAsync =
  (templateCode: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTemplateProgress());
      const res = await api.get(
        `/templates/get-templates?template_code=${templateCode}`
      );
      const data: ICreateTemplate[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchTemplateSuccess(data[0]));
      } else {
        dispatch(
          fetchTemplateFailed(
            "Oops! We couldn't find any records associated with your template name at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchTemplateFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTemplateysnc =
  (
    template: ICreateTemplate,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/templates/edit-templates", template);

      dispatch(
        showMessage({
          type: "success",
          message: "Template is saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchTemplateModuleSubModuleAsync =
  (
    onCallback: (modules: ISelectOption[]) => void,
    tableName?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      let url = "/general/get-table-or-column-name?table_type=VIEW";
      if (tableName) {
        url = url + "&table_name=" + tableName;
      }
      const res = await api.get(url);
      const data: string[] = res.data.data;
      const finalData = data.map((item)=>({label: formatText(item), value: item}))
      onCallback(finalData);
    } catch (err: any) {
      onCallback([]);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

  export const fetchTemplateSQLViewAndColumnsAsync =
  (
    onCallback: (modules: ISelectOption[]) => void,
    tableName?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      let url = "/templates/get-sql-view-or-columns?";
      if (tableName) {
        url = url + "templates_dynamic_views_code=" + tableName;
      }
      const res = await api.get(url);
      if(!tableName){
        const data: {
          "view_for": string;
          "templates_dynamic_views_code":string;
        }[] = res.data.data;
        const finalData = data.map((item)=>({label: formatText(item.view_for), value: item.templates_dynamic_views_code}))
        onCallback(finalData);
       
      }
      else {
        const data: string[] = res.data.data;
        const finalData = data.map((item)=>({label: item, value: item}))
        onCallback(finalData);
      }
     
    } catch (err: any) {
      onCallback([]);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_TEMPLATE = "CLEAR_TEMPLATE";
export const CLEAR_TEMPLATE_STATE = "CLEAR_TEMPLATE_STATE";

export const clearTemplate = () => action(CLEAR_TEMPLATE);
export const clearTemplateState = () => action(CLEAR_TEMPLATE_STATE);
