/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { StandadCard } from "../../../../../../components/Cards";
import { Divider, Tab, Tabs } from "@mui/material";
import {
  AccountCircle,
  AdminPanelSettings,
  StickyNote2,
} from "@mui/icons-material";
import { a11yProps } from "../../../../../../components/Tabs/utils";
import { TabPanel } from "../../../../../../components/Tabs/TabPanel";
import { IBreadcrumbProps } from "../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useBreadcrumbContext } from "../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { useDispatchWrapper } from "../../../../../../hooks";
import { fetchCommAutomobileVehicleAsync } from "../../../../../../redux/commAutoPolicy/commAutoVehicle/commAutoVehicleActions";
import { clearAutomobileVehicleState, fetchAutomobileVehicleAsync } from "../../../../../../redux/automobileVehicle/automobileVehicleActions";
import { AutomobileVehicleInformation } from "./AutomobileVehicleInformation/AutomobileVehicleInformation";
import { AutomobileAdditionalInformation } from "./AutomobileAdditional/AutomobileAdditional";
import { CommAutomobileVehicleInterestedList } from "../../CommAutomobilePolicy/CommAutoVehicle/CommAutoVehicleInterested/CommAutoVehicleInterestedList";
import { AutomobileVehicleInterestedList } from "./AutomobileInterested/AutomobileInterestedList";

export const AutomobileVehicle: React.FC = () => {
  const { customerId, customerPolicyId, vehicleNumber } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
    vehicleNumber?: number;
  };
  const [tab, setTab] = React.useState(0);

  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      to: "/view-customers",
      title: "customers",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer-overview",
    },
    {
      to:
        "/automobile-policy/automobile/" + customerId + "/" + customerPolicyId,
      title: "automoblie",
    },
    {
      title: "vehicle",
    },
  ];

  const savedVehicleNumber = useSelector(
    (storeState: IStoreState) => storeState.automobile.vehicle.vehicleNumber
  );
    
  const dispatch = useDispatchWrapper();

  const { addBreadcrumb } = useBreadcrumbContext();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const changeStep = (value: number) => {
    setTab(value);
  };

  React.useEffect(() => {
    addBreadcrumb({ title: "", items: BCrumb });
  }, []);

  React.useEffect(() => {
    if (customerPolicyId && vehicleNumber) {
      dispatch(fetchAutomobileVehicleAsync(customerPolicyId, vehicleNumber));
    }
    return(()=>{
      dispatch(clearAutomobileVehicleState())
    })
  }, [customerPolicyId]);

  return (
    <>
      <StandadCard sx={{ mt: 2 }}>
        <Tabs
          value={tab}
          variant="fullWidth"
          scrollButtons="auto"
          onChange={handleChange}
        >
          <Tab
            icon={<AccountCircle />}
            iconPosition="start"
            label="Vehicle Information"
            {...a11yProps(0)}
          />
          <Tab
            sx={{ minHeight: "50px" }}
            icon={<AdminPanelSettings />}
            iconPosition="start"
            label="Additional Information"
            disabled={!savedVehicleNumber}
            {...a11yProps(1)}
          />
          <Tab
            sx={{ minHeight: "50px" }}
            icon={<StickyNote2 />}
            iconPosition="start"
            label="Interested Parties"
            disabled={!savedVehicleNumber}
            {...a11yProps(2)}
          />
        </Tabs>
        <Divider />
        <TabPanel value={tab} index={0}>
          <AutomobileVehicleInformation
            customerPolicyId={customerPolicyId}
            customerId={customerId}
            vehicleNumber={vehicleNumber}
            changeStep={changeStep}
          />
        </TabPanel>

        {customerPolicyId && customerId && savedVehicleNumber && (
          <>
            <TabPanel value={tab} index={1}>
              <AutomobileAdditionalInformation
                customerId={customerId}
                customerPolicyId={customerPolicyId}
                vehcileNumber={savedVehicleNumber}
                changeStep={changeStep}
              />
            </TabPanel>

            <TabPanel value={tab} index={2}>
              <AutomobileVehicleInterestedList
                customerId={customerId}
                customerPolicyId={customerPolicyId}
                vehicleNumber={savedVehicleNumber}
              />
            </TabPanel>
          </>
        )}
      </StandadCard>
    </>
  );
};
