import moment from "moment";
import { LoadState } from "../../constants/enums";

export interface INewRemarketState {
  NewRemarket: {
    data: INewRemarket;
    loading: LoadState;
  };
}

export interface INewRemarket {
  task_code: string;
  transaction: string | null;
  sub_type: string;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: number | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  type: string | null;
  effective_date_of_change: string | null;
  effective_time_of_change: string | null;
  details: string | null;
  file_upload: string | null;
  auto_application: string | null;
  auto_application_status: string | null;
  home_application_status: string | null;
  home_application: string | null;
  cancellation: string | null;
  cancellation_status: string | null;
  insurance_reports: string | null;
  quote_number: string | null;
  cc: string | null;
  expiry: string | null;
  cvv: string | null;
}

export const defaultNewRemarket: INewRemarketState = {
  NewRemarket: {
    data: {
      task_code: "",
      transaction: null,
      sub_type: "",
      customer_code: null,
      customer_name: null,
      policy_number: null,
      line_of_business: null,
      policy_type: null,
      branch: null,
      broker_code: null,
      insurer: null,
      producer: null,
      csr: null,
      type: null,
      effective_date_of_change: null,
      effective_time_of_change: moment()
        .startOf("day")
        .add(1, "minute")
        .toString(),
      details: null,
      file_upload: null,
      auto_application: null,
      auto_application_status: null,
      cancellation: null,
      home_application: null,
      home_application_status: null,
      cancellation_status: "",
      insurance_reports: null,
      quote_number: null,
      cc: null,
      cvv: null,
      expiry: null,
    },
    loading: LoadState.NotLoaded,
  },
};
