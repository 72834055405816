import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { IHabitationalPaymentAuthorization } from "./habitationalPaymentAuthorization.types";

export const FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_LIST_PROGRESS =
  "FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_LIST_PROGRESS";
export const FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_LIST_SUCCESS =
  "FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_LIST_SUCCESS";
export const FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_LIST_FAILED =
  "FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_LIST_FAILED";

export const fetchHabitationalPaymentAuthorizationListProgress = () =>
  action(FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_LIST_PROGRESS);
export const fetchHabitationalPaymentAuthorizationListSuccess = (
  data: IHabitationalPaymentAuthorization[]
) => action(FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_LIST_SUCCESS, { data });
export const fetchHabitationalPaymentAuthorizationListFailed = () =>
  action(FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_LIST_FAILED);

export const fetchHabitationalPaymentAuthorizationListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalPaymentAuthorizationListProgress());
      const res = await api.get(
        `/policy/get-habitational-payment-authorization?customer_policy_id=${customerPolicyId}`
      );
      const data: IHabitationalPaymentAuthorization[] = res.data.data;
      dispatch(fetchHabitationalPaymentAuthorizationListSuccess(data));
    } catch (err: any) {
      dispatch(fetchHabitationalPaymentAuthorizationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_PROGRESS =
  "FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_PROGRESS";
export const FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_SUCCESS =
  "FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_SUCCESS";
export const FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_FAILED =
  "FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_FAILED";

export const fetchHabitationalPaymentAuthorizationProgress = () =>
  action(FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_PROGRESS);
export const fetchHabitationalPaymentAuthorizationSuccess = (
  data: IHabitationalPaymentAuthorization
) => action(FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_SUCCESS, { data });
export const fetchHabitationalPaymentAuthorizationFailed = (
  errorMessage: string
) => action(FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_FAILED, { errorMessage });

export const fetchHabitationalPaymentAuthorizationAsync =
  (
    customerPolicyId: number,
    payAuth: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalPaymentAuthorizationProgress());
      const res = await api.get(
        `/policy/get-habitational-payment-authorization?customer_policy_id=${customerPolicyId}&column=serial_number&value=${payAuth}`
      );
      const data: IHabitationalPaymentAuthorization[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchHabitationalPaymentAuthorizationSuccess(data[0]));
      } else {
        dispatch(
          fetchHabitationalPaymentAuthorizationFailed(
            "Oops! We couldn't find any records associated with your restrutant number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchHabitationalPaymentAuthorizationFailed(
          "Something went to be wrong!"
        )
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addHabitationalPaymentAuthorizationAsync =
  (
    payAuth: IHabitationalPaymentAuthorization,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        `/policy/edit-habitational-payment-authorization`,
        {
          ...payAuth,
        }
      );
      dispatch(
        showMessage({
          type: "success",
          message: "Payment Authorization saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_HABITATIONAL_PAYMENT_AUTHORIZATION =
  "CLEAR_HABITATIONAL_PAYMENT_AUTHORIZATION";
export const CLEAR_HABITATIONAL_PAYMENT_AUTHORIZATION_STATE =
  "CLEAR_HABITATIONAL_PAYMENT_AUTHORIZATION_STATE";

export const clearHabitationalPaymentAuthorizationState = () =>
  action(CLEAR_HABITATIONAL_PAYMENT_AUTHORIZATION_STATE);

export const clearHabitationalPaymentAuthorization = () =>
  action(CLEAR_HABITATIONAL_PAYMENT_AUTHORIZATION);

// /policy/get-habitational-payment-authorization
// /policy/edit-habitational-payment-authorization
