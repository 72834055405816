import produce from "immer";
import { BusinesPolicyAuthorizationActions } from ".";
import { IStoreState } from "../../initialStoreState";
import { defaultBusinessPolicyAuthorizationState } from "./businessPolicyAuthorization.types";
import {
  CLEAR_BUSINES_POLICY_AUTHORIZATION,
  CLEAR_BUSINES_POLICY_AUTHORIZATION_STATE,
  FETCH_BUSINESS_POLICY_AUTHORIZATION_FAILED,
  FETCH_BUSINESS_POLICY_AUTHORIZATION_LIST_FAILED,
  FETCH_BUSINESS_POLICY_AUTHORIZATION_LIST_PROGRESS,
  FETCH_BUSINESS_POLICY_AUTHORIZATION_LIST_SUCCESS,
  FETCH_BUSINESS_POLICY_AUTHORIZATION_PROGRESS,
  FETCH_BUSINESS_POLICY_AUTHORIZATION_SUCCESS,
} from "./businessPolicyAuthorizationActions";
import { LoadState } from "../../../constants/enums";

export const businessPolicyAuthorizationReducer = (
  state: IStoreState["business"]["authorization"] = defaultBusinessPolicyAuthorizationState,
  action: BusinesPolicyAuthorizationActions
) => {
  switch (action.type) {
    case FETCH_BUSINESS_POLICY_AUTHORIZATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_AUTHORIZATION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_AUTHORIZATION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_AUTHORIZATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.authLoading = LoadState.InProgress;
        draftState.auth = defaultBusinessPolicyAuthorizationState["auth"];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_AUTHORIZATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.authLoading = LoadState.Loaded;
        draftState.auth = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_AUTHORIZATION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.authLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_BUSINES_POLICY_AUTHORIZATION: {
      const newState = produce(state, (draftState) => {
        draftState.auth = defaultBusinessPolicyAuthorizationState["auth"];
        draftState.error = null;
      });
      return newState;
    }
    case CLEAR_BUSINES_POLICY_AUTHORIZATION_STATE: {
      return defaultBusinessPolicyAuthorizationState;
    }
    default: {
      return state;
    }
  }
};
