/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useDispatchWrapper } from "../../../../../../hooks";
import { useFormik } from "formik";
import {
  Box,
  CircularProgress,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import {
  ControlledCustomSelect,
  CustomCheckBoxWithLabel,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../components/formsComponents";

import { Dialog } from "../../../../../../components/Dialogs/Dialog";
import { UnderWritersListBase } from "../../../../../insuranceCompany/UnderWritersList";
import { IBusinessPolicyMarketingBinderPdf } from "../../../../../../redux/businessPolicy/businessPolicyMarketing/businessPolicyMarketing.types";
import {
  businessPolicyGenerateMarketCoveragePdfAsync,
  businessPolicyPreviewMarketingAsync,
} from "../../../../../../redux/businessPolicy/businessPolicyMarketing/businessPolicyMarketingActions";
import { showMessage } from "../../../../../../redux/messages/messagesActions";
import { api } from "../../../../../../api/api";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { LoadState } from "../../../../../../constants/enums";
import { IInsurerUnderWriter } from "../../../../../../redux/insurer/insurer.types";
import { ICommAutomobileMarketingActivityDialogProps } from "./CommAutoMarketing.types";
import { QuillEditor } from "../../../../../../components/QuillEditor/QuillEditor";
import { ICreateTemplate } from "../../../../../../redux/templates/templates.types";
import {
  commAutoPolicyBinderSendCoverageMailAndHistAsync,
  commAutoPolicyGenerateMarketCoveragePdfAsync,
  commAutoPolicyPreviewMarketingAsync,
  commAutoPolicySendCoverageMailAndHistAsync,
} from "../../../../../../redux/commAutoPolicy/commAutoMarketingHistory/commAutoMarketingHistoryActions";
import { ICommAutoPolicyMarketingBinderPdf } from "../../../../../../redux/commAutoPolicy/commAutoMarketingHistory/commAutoMarketingHistory.types";
import { DownLoadFile } from "../../../../../../helpers";
import { ConfirmDialog } from "../../../../../../components/Dialogs/ConfirmDialog";

const INITIAL_MARKETING_PREVIEW: ICommAutoPolicyMarketingBinderPdf = {
  customer_policy_id: 0,
  isMarketSubmissionPreview: false,
  pdfOrPreviewFor: "IRCA | GARAGE | FLEET",
  isBinderPreview: false,
  isBinderPdf: false,
  generalInformation: true,
  driver: true,
  priorPolicy: true,
  claims: true,
  convictions: true,
  vehicle: true,
  coverage: true,
  garagePlat: true,
};

const steps = ["Content Selection", "Send Proposal to Insurer"];

export const CommAutomobileMarketingDialog: React.FC<
  ICommAutomobileMarketingActivityDialogProps
> = (props) => {
  const { open, customerPolicyId, customerId, onClose } = props;

  const [activeStep, setActiveStep] = React.useState(0);
  const [loadingPreview, setOpenLoadingPreview] = React.useState(false);

  const dispatch = useDispatchWrapper();
  const [loading, setLoading] = React.useState(false);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const { marketingHistory, marketingHistoryLoading, marketingHistoryList } =
    useSelector((storeState: IStoreState) => storeState.commAuto.marketing);
  const { data } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.basicDetails.customerInfo
  );
  const [selectedUnderWriters, setSelectedUnderWriters] = React.useState<{
    isAllSelected: boolean;
    underwriter: IInsurerUnderWriter[];
  }>({
    isAllSelected: false,
    underwriter: [],
  });

  const [filesUrl, setFilesUrl] = React.useState<string[]>([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState("");
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const templates = useSelector(
    (storeState: IStoreState) => storeState.templates.list
  );

  React.useEffect(() => {
    const fetchTemplate = async (temp: ICreateTemplate) => {
      try {
        // id=${id}
        const res = await api.get(`/templates/get-templates`);
        const list: ICreateTemplate[] = res.data.data;
        if (list.length > 0) {
          setFieldValue("email_body", list[0].body);
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (selectedTemplate) {
      const template = templates.find(
        (x) => x.template_name === selectedTemplate
      );
      if (template) {
        fetchTemplate(template);
      }
    }
  }, [selectedTemplate]);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      req_for_quotation: true,
      preview: INITIAL_MARKETING_PREVIEW,

      email_body: "",
      subject: `${data.customer_name} (${data.customer_code}) - New Submission`,
      bcc_email: "",
      templateName: "",
    },
    validate: (values) => {
      if (activeStep === 1) {
        const errors: any = {};
        if (!values.subject) {
          errors.subject = "*Subject is required.";
        } else if (values.email_body === "") {
          errors.email_body = "*Body is required.";
        }
        return errors;
      }
    },
    onSubmit: (values) => {
      if (activeStep === 0) {
        setOpenConfirm(true);
      } else {
        dispatch(
          commAutoPolicySendCoverageMailAndHistAsync(
            { ...values.preview, customer_policy_id: customerPolicyId },
            filesUrl,
            values.bcc_email,
            values.subject,
            values.email_body,
            selectedUnderWriters.underwriter,
            (isSuccess) => {
              if (isSuccess) {
                props.onComplete();
              }
              setSaveLoading(false);
            }
          )
        );
      }
    },
  });

  const handleNext = (step: number) => () => {
    if (activeStep === 1) {
      setActiveStep(step);
    }
  };

  const handlePreview = async () => {
    setOpenLoadingPreview(true);
    dispatch(
      commAutoPolicyPreviewMarketingAsync(
        {
          ...values.preview,
          customer_policy_id: customerPolicyId,
          isMarketSubmissionPreview: true,
        },
        (isSuccess, htmlJson) => {
          if (isSuccess && htmlJson) {
            for (const key in htmlJson.data) {
              const newTab = window.open("", "_blank");
              if (newTab) {
                newTab.document.write(htmlJson.data[key]);
                newTab.document.close();
              }
            }
          }
          setOpenLoadingPreview(false);
        }
      )
    );
  };
  const handleSelectionUnderWriters = (
    isAllSelected: boolean,
    rows: IInsurerUnderWriter[]
  ) => {
    setSelectedUnderWriters({
      isAllSelected: isAllSelected,
      underwriter: rows,
    });
  };

  const handleConfirm = () => {
    setOpenConfirm(false);
    dispatch(
      commAutoPolicyGenerateMarketCoveragePdfAsync(
        {
          ...values.preview,
          customer_policy_id: customerPolicyId,
          customer_id: customerId,
          // isMarketSubmissionPreview: true,
        },
        (isSuccess: boolean, data: any) => {
          if (isSuccess) {
            setActiveStep(1);
            setFilesUrl([...data?.data]);
          }
          setSaveLoading(false);
        }
      )
    );
  };

  const handleConfirmClose = () => {
    setOpenConfirm(false);
  };

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get(
          `/policy/get-is-record-exist-in-every-commauto-module?customer_policy_id=${customerPolicyId}`
        );
        setValues({
          ...values,
          preview: {
            customer_policy_id: customerPolicyId,
            ...response.data.data,
          },
        });
      } catch (err: any) {
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Dialog
      open={open}
      title="Marketing Submission"
      onClose={onClose}
      size="lg"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      actions={[
        {
          type: "button",
          label: loadingPreview ? "Wait..." : "Preview",
          variant: "contained",
          disabled: loadingPreview,
          onClick: handlePreview,
        },
        {
          type: "submit",
          label:
            activeStep === 0 ? (
              "Save & Next"
            ) : saveLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Submit"
            ),
          disabled: !values.req_for_quotation || saveLoading,
          variant: "contained",
        },
      ]}
    >
      <PageLoader loading={loading}>
        <>
          <Stepper activeStep={activeStep} variant="outlined">
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};

              return (
                <Step key={label} {...stepProps} onClick={handleNext(index)}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <Box sx={{ mt: 2 }}>
            {activeStep === 0 && (
              <>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Request for Quotation"
                      name="req_for_quotation"
                      checked={values.req_for_quotation}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="General Information"
                      name="preview.generalInformation"
                      checked={values.preview.generalInformation}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Driver"
                      name="preview.driver"
                      checked={values.preview.driver}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Prior Policies"
                      name="preview.priorPolicy"
                      checked={values.preview.priorPolicy}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Claims"
                      name="preview.claims"
                      checked={values.preview.claims}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Convictions"
                      name="preview.convictions"
                      checked={values.preview.convictions}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Vehicle"
                      name="preview.vehicle"
                      checked={values.preview.vehicle}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Garage Plate"
                      name="preview.garagePlat"
                      checked={values.preview.garagePlat}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Coverage"
                      name="preview.coverage"
                      checked={values.preview.coverage}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {activeStep === 1 && (
              <>
                <Typography variant="h4" fontWeight={"bold"}>
                  Enter the Subject and Message Body Below
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <CustomFormLabel>BCC Emails</CustomFormLabel>
                    <CustomTextField
                      name="bcc_email"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      value={values.bcc_email}
                      error={errors.bcc_email ? true : false}
                      helperText={errors.bcc_email}
                      // disabled={isUpdateLead}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <CustomFormLabel>Customer Name (Code)</CustomFormLabel>
                    <CustomTextField
                      name="subject"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      value={values.subject}
                      error={errors.subject ? true : false}
                      helperText={errors.subject}
                      // disabled={isUpdateLead}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <CustomFormLabel>Template</CustomFormLabel>
                    <ControlledCustomSelect
                      fullWidth
                      value={"jbjsj"}
                      name="templateName"
                      onChange={(e) =>
                        setSelectedTemplate(e.target.value as string)
                      }
                      placeholder="Choose Template"
                      displayEmpty
                      options={templates.map((item) => {
                        return {
                          label: item.template_name,
                          value: item.template_name,
                        };
                      })}
                    ></ControlledCustomSelect>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <CustomFormLabel>Email Body</CustomFormLabel>
                    {/* <CustomTextField
                      name="email_body"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      multiline
                      rows={4}
                      value={values.email_body}
                      error={errors.email_body ? true : false}
                      helperText={errors.email_body}
                      onChange={handleChange}
                    /> */}
                    <QuillEditor
                      value={values.email_body}
                      onChange={(html) => setFieldValue("email_body", html)}
                    />
                  </Grid>
                </Grid>
                <Typography variant="h4" fontWeight={"bold"} sx={{ mt: 2 }}>
                  Download All Files
                </Typography>
                {/* {marketingHistoryLoading === LoadState.InProgress && (
                  <Typography sx={{ color: "primary.main" }}>
                    Please wait....
                  </Typography>
                )} */}
                {filesUrl.map((attachment) => {
                  // const data = attachment.split("/");
                  return (
                    <Typography
                      fontSize={"1rem"}
                      sx={{
                        color: "primary.main",
                        cursor: "pointer",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                      onClick={() => {
                        DownLoadFile(attachment);
                      }}
                    >
                      {attachment}
                    </Typography>
                  );
                })}

                <Typography
                  variant="h4"
                  fontWeight={"bold"}
                  sx={{ mt: 2, mb: 2 }}
                >
                  Select Insurers/Underwritters
                </Typography>
                <UnderWritersListBase
                  hideColumnsName={["Actions", "Date"]}
                  hideTableFilters
                  onSelectionUnderwritter={handleSelectionUnderWriters}
                />
              </>
            )}
          </Box>
        </>
      </PageLoader>
      {openConfirm && (
        <ConfirmDialog
          open={openConfirm}
          content={
            <Typography variant="h4">
              Are you sure you want to save the market submission and proceed?
            </Typography>
          }
          onClose={handleConfirmClose}
          onConfrim={handleConfirm}
        />
      )}
    </Dialog>
  );
};
