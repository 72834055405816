import React from "react";
import { useOutletContext } from "react-router-dom";
import { CSIODocsTableList } from "../../../../../csioEdocs/CSIODocsTable";

export const AutomobileCSIOEDIList: React.FC = () => {
    const data1 = useOutletContext() as any;
    const customerpolicyId = data1[1];

    return(
        <>
          <CSIODocsTableList customerPolicyId={customerpolicyId}/>
        </>
    )
}
