export interface IProject {
  assigned_to: string | null;
  item_status: string | null;
  lob: string | null;
  lob_code: string | null;
  broker_number: string | null;
  name: string | null;
  insurer_branch_code: string | null;
  operating_name: string | null;
  sub_broker: string | null;
  client_name: string | null;
  policy_number: string | null;
  effective_date: string | null;
  expiry_date: string | null;
  transaction_type: string | null;
  year: string | null;
  make: string | null;
  mode: string | null;
  vin: string | null;
  unit: string | null;
  lpn: string | null;
  acv: string | null;
  radius: string | null;
  producer: string | null;
  branch: string | null;
  retail_group: string | null;
  overland_water: string | null;
  overland_water_zone: string | null;
  sewer_back: string | null;
  will_receive_letter: string | null;
  liability_limit: string | null;
  accident_forgiveness: string | null;
  hnw: string | null;
  province: string | null;
  mortage: string | null;
  grc: string | null;
  earthquake: string | null;
  fsa: string | null;
  high_risk: string | null;
  tag: string | null;
  install_vpn: string | null;
  surcharge: string | null;
  surchage_code: string | null;
  loss_year: string | null;
  date_of_loss: string | null;
  claim_number: string | null;
  case_type_of_loss: string | null;
  gross_insured_claims: string | null;
  net_insured_claims: string | null;
  gross_current: string | null;
  gross_opening: string | null;
  gross_paid_expenses: string | null;
  gross_received: string | null;
  claim_status: string | null;
  fault: string | null;
  notes: string | null;
  action_taken: string | null;
}

export const initialProject: IProject = {
  assigned_to: "",
  item_status: "",
  lob: "",
  lob_code: "",
  broker_number: "",
  name: "",
  insurer_branch_code: "",
  operating_name: "",
  sub_broker: "",
  client_name: "",
  policy_number: "",
  effective_date: "",
  expiry_date: "",
  transaction_type: "",
  year: "",
  make: "",
  mode: "",
  vin: "",
  unit: "",
  lpn: "",
  acv: "",
  radius: "",
  producer: "",
  branch: "",
  retail_group: "",
  overland_water: "",
  overland_water_zone: "",
  sewer_back: "",
  will_receive_letter: "",
  liability_limit: "",
  accident_forgiveness: "",
  hnw: "",
  province: "",
  mortage: "",
  grc: "",
  earthquake: "",
  fsa: "",
  high_risk: "",
  tag: "",
  install_vpn: "",
  surcharge: "",
  surchage_code: "",
  loss_year: "",
  date_of_loss: "",
  claim_number: "",
  case_type_of_loss: "",
  gross_insured_claims: "",
  net_insured_claims: "",
  gross_current: "",
  gross_opening: "",
  gross_paid_expenses: "",
  gross_received: "",
  claim_status: "",
  fault: "",
  notes: "",
  action_taken: "",
};
