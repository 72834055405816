import produce from "immer";
import { GeneralChangeActions } from ".";
import { IStoreState } from "../initialStoreState";
import { initialGeneralState } from "./generalChange.types";
import { LoadState } from "../../constants/enums";
import {
  CLEAR_TASK_GENERAL_CHANGE_STATE,
  FETCH_TASK_ADDRESS_CHANGE_FAILED,
  FETCH_TASK_ADDRESS_CHANGE_PROGRESS,
  FETCH_TASK_ADDRESS_CHANGE_SUCCESS,
  FETCH_TASK_ADD_RISK_LOCATION_FAILED,
  FETCH_TASK_ADD_RISK_LOCATION_PROGRESS,
  FETCH_TASK_ADD_RISK_LOCATION_SUCCESS,
  FETCH_TASK_BUSINESS_CHANGE_FAILED,
  FETCH_TASK_BUSINESS_CHANGE_PROGRESS,
  FETCH_TASK_BUSINESS_CHANGE_SUCCESS,
  FETCH_TASK_CHANGE_RISK_LOCATION_FAILED,
  FETCH_TASK_CHANGE_RISK_LOCATION_PROGRESS,
  FETCH_TASK_CHANGE_RISK_LOCATION_SUCCESS,
  FETCH_TASK_COVERAGE_CHANGE_FAILED,
  FETCH_TASK_COVERAGE_CHANGE_PROGRESS,
  FETCH_TASK_COVERAGE_CHANGE_SUCCESS,
  FETCH_TASK_DELETE_RISK_LOCATION_FAILED,
  FETCH_TASK_DELETE_RISK_LOCATION_PROGRESS,
  FETCH_TASK_DELETE_RISK_LOCATION_SUCCESS,
  FETCH_TASK_DELETE_VEHICLE_FAILED,
  FETCH_TASK_DELETE_VEHICLE_PROGRESS,
  FETCH_TASK_DELETE_VEHICLE_SUCCESS,
  FETCH_TASK_GENERAL_CHANGE_FAILED,
  FETCH_TASK_GENERAL_CHANGE_PROGRESS,
  FETCH_TASK_GENERAL_CHANGE_SUCCESS,
  FETCH_TASK_POLICY_SUBMISSION_FAILED,
  FETCH_TASK_POLICY_SUBMISSION_PROGRESS,
  FETCH_TASK_POLICY_SUBMISSION_SUCCESS,
  FETCH_TASK_VEHICLE_CHANGE_FAILED,
  FETCH_TASK_VEHICLE_CHANGE_PROGRESS,
  FETCH_TASK_VEHICLE_CHANGE_SUCCESS,
} from "./generalChangeActions";

export const generalChangeReducer = (
  state: IStoreState["tasks"]["generalChange"] = initialGeneralState,
  action: GeneralChangeActions
) => {
  switch (action.type) {
    case FETCH_TASK_GENERAL_CHANGE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.generalChange.loading = LoadState.InProgress;
        draftState.generalChange = initialGeneralState["generalChange"];
      });
      return newState;
    }
    case FETCH_TASK_GENERAL_CHANGE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.generalChange.loading = LoadState.Loaded;
        draftState.generalChange.data = data;
      });
      return newState;
    }
    case FETCH_TASK_GENERAL_CHANGE_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.generalChange.loading = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_TASK_ADDRESS_CHANGE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.addressChange.loading = LoadState.InProgress;
        draftState.addressChange = initialGeneralState["addressChange"];
      });
      return newState;
    }
    case FETCH_TASK_ADDRESS_CHANGE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.addressChange.loading = LoadState.Loaded;
        draftState.addressChange.data = data;
      });
      return newState;
    }
    case FETCH_TASK_ADDRESS_CHANGE_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.generalChange.loading = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_TASK_BUSINESS_CHANGE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.businessChange.loading = LoadState.InProgress;
        draftState.businessChange = initialGeneralState["businessChange"];
      });
      return newState;
    }
    case FETCH_TASK_BUSINESS_CHANGE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.businessChange.loading = LoadState.Loaded;
        draftState.businessChange.data = data;
      });
      return newState;
    }
    case FETCH_TASK_BUSINESS_CHANGE_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.businessChange.loading = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_TASK_COVERAGE_CHANGE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.coverageChange.loading = LoadState.InProgress;
        draftState.coverageChange = initialGeneralState["coverageChange"];
      });
      return newState;
    }
    case FETCH_TASK_COVERAGE_CHANGE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.coverageChange.loading = LoadState.Loaded;
        draftState.coverageChange.data = data;
      });
      return newState;
    }
    case FETCH_TASK_COVERAGE_CHANGE_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.coverageChange.loading = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_TASK_POLICY_SUBMISSION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.policySubmission.loading = LoadState.InProgress;
        draftState.policySubmission = initialGeneralState["policySubmission"];
      });
      return newState;
    }
    case FETCH_TASK_POLICY_SUBMISSION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.policySubmission.loading = LoadState.Loaded;
        draftState.policySubmission.data = data;
      });
      return newState;
    }
    case FETCH_TASK_POLICY_SUBMISSION_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.policySubmission.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_TASK_VEHICLE_CHANGE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.vehcile.loading = LoadState.InProgress;
        draftState.vehcile.data = initialGeneralState["vehcile"]["data"];
      });
      return newState;
    }
    case FETCH_TASK_VEHICLE_CHANGE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.vehcile.loading = LoadState.Loaded;
        draftState.vehcile.data = data;
      });
      return newState;
    }
    case FETCH_TASK_VEHICLE_CHANGE_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.vehcile.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_TASK_DELETE_VEHICLE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.vehcile.deleteLoading = LoadState.InProgress;
        draftState.vehcile.deleteData =
          initialGeneralState["vehcile"]["deleteData"];
      });
      return newState;
    }
    case FETCH_TASK_DELETE_VEHICLE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.vehcile.deleteLoading = LoadState.Loaded;
        draftState.vehcile.deleteData = data;
      });
      return newState;
    }
    case FETCH_TASK_DELETE_VEHICLE_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.vehcile.deleteLoading = LoadState.Failed;
      });
      return newState;
    }

    /*----------*/
    case FETCH_TASK_ADD_RISK_LOCATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.riskLoaction.loading = LoadState.InProgress;
        draftState.riskLoaction.data =
          initialGeneralState["riskLoaction"]["data"];
      });
      return newState;
    }
    case FETCH_TASK_ADD_RISK_LOCATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.riskLoaction.loading = LoadState.Loaded;
        draftState.riskLoaction.data = data;
      });
      return newState;
    }
    case FETCH_TASK_ADD_RISK_LOCATION_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.riskLoaction.loading = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_TASK_DELETE_RISK_LOCATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.riskLoaction.deleteLoading = LoadState.InProgress;
        draftState.riskLoaction.deleteData =
          initialGeneralState["riskLoaction"]["deleteData"];
      });
      return newState;
    }
    case FETCH_TASK_DELETE_RISK_LOCATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.riskLoaction.deleteLoading = LoadState.Loaded;
        draftState.riskLoaction.deleteData = data;
      });
      return newState;
    }
    case FETCH_TASK_DELETE_RISK_LOCATION_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.riskLoaction.deleteLoading = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_TASK_CHANGE_RISK_LOCATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.riskLoaction.changeLoading = LoadState.InProgress;
        draftState.riskLoaction.changeData =
          initialGeneralState["riskLoaction"]["changeData"];
      });
      return newState;
    }
    case FETCH_TASK_CHANGE_RISK_LOCATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.riskLoaction.changeLoading = LoadState.Loaded;
        draftState.riskLoaction.changeData = data;
      });
      return newState;
    }
    case FETCH_TASK_CHANGE_RISK_LOCATION_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.riskLoaction.changeLoading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_TASK_GENERAL_CHANGE_STATE: {
      return initialGeneralState;
    }

    default:
      return state;
  }
};
