/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { api } from "../../api/api";
import { CustomFormLabel, CustomTextField } from "../formsComponents";
import Autocomplete from "@mui/material/Autocomplete";
import { IUsersAutoSearchByRole } from "./AutoCompleteSearches.types";
import { IUserCreation } from "../../redux/UserProfileList/userProfile.types";
import { CircularProgress } from "@mui/material";
import { debounce } from "lodash";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";

const INITIAL_STATE: IUserCreation = {
  first_name: "",
  last_name: "",
  username: "",
  user_code: "",
  email: "",
  config_json: null,
  user_password: "",
  role_id: 0,
  status: "ACTIVE",
  user_fact_id: null,
};

export const UsersAutoSearchByRole: React.FC<IUsersAutoSearchByRole> = (
  props
) => {
  const { value, label, selectionBy = "user_fact_id", disabled, errorMessage, placeholder } = props;
  const [usersList, setUsersList] = React.useState<IUserCreation[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");

  const fetchUsers = debounce( async (val: string) => {
    try {
      setLoading(true);
      const res = await api.get(
        `/user/get-users?status=ACTIVE&column=username&column=first_name&column=user_code&column=user_fact_id&value=${val}&pageNo=1&itemPerPage=10`
      );
      const data: IUserCreation[] = res.data.data;
      const finalData: IUserCreation[] = [];
      for (const user of data) {
        finalData.push(user);
      }
      setUsersList(finalData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, 500);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    fetchUsers(value);
    setSearchText(value);
  };

  const getOptionLabel = (option: IUserCreation) => {
    return `${option.user_code} ${
      option.first_name || option.last_name
        ? `(${option.first_name || ""} ${option.last_name || ""})`
        : ""
    } `;
  };

  const getValue = () => {
    if (selectionBy === "user_fact_id") {
      return (
        usersList.find((option) => option.user_fact_id === Number(value)) ||
        null
      );
    }
    return usersList.find((option) => option.user_code === value) || null;
  };

  React.useEffect(() => {
    if(value){
      setSearchText(value);
      fetchUsers(value);
    } else {
      setSearchText("");
    }
  }, [value]);

  return (
    <>
      {label && <CustomFormLabel>{label}</CustomFormLabel>}
      <Autocomplete
        options={usersList}
        disabled={disabled}
        sx={{
          ".MuiOutlinedInput-root": {
            paddingTop: "2px",
            paddingBottom: "2px",
            fontSize: "0.8rem",
            color: "rgb(38, 38, 38)",
          },
        }}
        value={getValue()}
        defaultValue={value}
        getOptionLabel={getOptionLabel}
        onChange={(e, newValue) => {
          if (newValue) {
            if (props.onSelect) {
              props.onSelect(newValue);
              return;
            }
          }
          if (props.onSelect) {
            props.onSelect(INITIAL_STATE);
          }
        }}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            fullWidth
            value={searchText}
            onChange={handleChange}
            placeholder={placeholder}
            error={errorMessage ? true : false}
            helperText={errorMessage}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="primary" size={15} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
 
    </>
  );
};
