import { action } from "typesafe-actions";
import { ICSIO, ICSIORaw } from "./csio.types";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { api } from "../../api/api";
import { ERROR_MESSAGES } from "../../constants/enums";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";
export const FETCH_CSIO_LIST_PROGRESS = "FETCH_CSIO_LIST_PROGRESS";
export const FETCH_CSIO_LIST_SUCCESS = "FETCH_CSIO_LIST_SUCCESS";
export const FETCH_CSIO_LIST_FAILED = "FETCH_CSIO_LIST_FAILED";

export const fetchCSIOListProgress = () => action(FETCH_CSIO_LIST_PROGRESS);
export const fetchCSIOListSuccess = (list: ICSIORaw[], totalRecords: number) =>
  action(FETCH_CSIO_LIST_SUCCESS, {
    list,
    totalRecords,
  });
export const fetchCSIOListFailed = () => action(FETCH_CSIO_LIST_FAILED);

export const fetchCSIOListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter,
    map: string,
    messageType?: string,
    customerPolicyId?: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    console.log(map)
    try {
      dispatch(fetchCSIOListProgress());

      let finalUrl = `/csio/get-csio-retrieve-messages?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;

      if (searchValue.length > 0) {
        finalUrl = `/csio/get-csio-retrieve-messages?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}&advanceFilter=${JSON.stringify(searchValue)}`;
      }

      if (map && map !== "-1") {
        finalUrl += `&is_map=${map}`;
      }
      if (messageType && messageType !== "-1") {
        finalUrl += `&column=message_type&value=${messageType}`;
      }

      if(customerPolicyId){
        finalUrl += `&customer_policy_id=${customerPolicyId}`;
      }

      const res = await api.get(finalUrl);
      const data: ICSIORaw[] = res.data.data;
      console.log("record", res.data.totalRecords);
      const totalRecords = res.data.totalRecords;

      dispatch(fetchCSIOListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCSIOListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const fetchFromCSIOListAsync =
  (
    fromDate: string,
    toDate: string,
    onCallback?: (code: string) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      const finalUrl = `/csio/csio-final-api`;

      const res = await api.post(finalUrl, {
        "from_date": fromDate,
        "to_date": toDate,
        "execution_on": "date_time",
        "sequence_number": [
          1,
          2,
          3,
          4,
          5,
          6
        ],
        "only_for_unmapped": 0,
        "skip_interruption": 1
      });
      const code = res.data.data.csio_log_code;
      if (onCallback) {
        onCallback(code);
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const addInitializeMappingAsync =
  (
    only_for_unmapped: string,
    fromUtcDate: string,
    toUtcDate: string,
    onCallback: (isSuccess: boolean, code?: string) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post("/csio/csio-final-api", {
        from_date: fromUtcDate,
        only_for_unmapped:only_for_unmapped,
        to_date: toUtcDate,
        "execution_on": "date_time",
        "message_guid": [],
        "sequence_number": [
          4,
          6,
          7
        ],
        "skip_interruption": 1,
        "is_manual_trigger": 1
   
      });
      onCallback(true,res.data.data);
      dispatch(
        showMessage({
          type: "success",
          message: "Mapping done successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_CSIO_PROGRESS = "FETCH_CSIO_PROGRESS";
export const FETCH_CSIO_SUCCESS = "FETCH_CSIO_SUCCESS";
export const FETCH_CSIO_FAILED = "FETCH_CSIO_FAILED";

export const fetchCSIOProgress = () => action(FETCH_CSIO_PROGRESS);
export const fetchCSIOSuccess = (data: ICSIORaw) =>
  action(FETCH_CSIO_SUCCESS, {
    data,
  });
export const fetchCSIOFailed = (errorMessage: string) =>
  action(FETCH_CSIO_FAILED, { errorMessage });

export const fetchCSIOAsync =
  (messageGUid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCSIOProgress());
      const res = await api.get(
        `/csio/get-csio-retrieve-messages?message_guid=${messageGUid}`
      );
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchCSIOSuccess({ ...data[0] }));
      } else {
        dispatch(fetchCSIOFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
      }
    } catch (err: any) {
      dispatch(fetchCSIOFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_CSIO_STATE = "CLEAR_CSIO_STATE";
export const clearCSIOState = () => action(CLEAR_CSIO_STATE);

export const CLEAR_CSIO_RAW_DATA = "CLEAR_CSIO_RAW_DATA";
export const clearCSIORawData = () => action(CLEAR_CSIO_RAW_DATA);
