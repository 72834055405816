import { LoadState } from "../../../constants/enums";

export interface IBusinessPolicyPremiumFinanceState {
  data: IBusinessPolicyPremiumFinance[];
  loading: LoadState;
  premiumFA: IBusinessPolicyPremiumFinance;
  premiumFALoading: LoadState;
  error: string | string | null;
}

export interface IBusinessPolicyPremiumFinance {
  customer_policy_id: number;
  incremental_number: number | null;
  customer_id: number | null;
  contact_name: string | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
  phone: string;
  email: string ;
  producer: string | null;
  transaction: string | null;
  insurer: string | null;
  policy_number: string | null;
  line_of_business: string | null;
  premium: number;
  mga_fee: number;
  broker_fee: number;
  tax: number;
  total: number;
  financed_by: string | null;
  down_payment: string | null;
  dp_collection: string | null;
  minimum_retained: string | null;
  continuous: string | null;
  contract_number: string | null;
  expiry_date: string | null;
  pfa_attachment: string | null;
  status: string;
}

export interface IPremiumFinanceRow {
  customer_policy_id: string;
  customer_id: string | null;
  contact_name: string | null;
  email: string | null;
}

export const defaultBusinessPolicyPremiumFinanceState: IBusinessPolicyPremiumFinanceState =
  {
    data: [],
    loading: LoadState.NotLoaded,
    premiumFA: {
      customer_policy_id: 0,
      incremental_number: null,
      customer_id: null,
      contact_name: null,
      address: null,
      unit_or_suite: null,
      city: null,
      province_or_state: null,
      postal_code: null,
      country: null,
      phone: "",
      email: "",
      producer: null,
      transaction: null,
      insurer: null,
      policy_number: null,
      line_of_business: null,
      premium: 0,
      mga_fee: 0,
      broker_fee: 0,
      tax: 0,
      total: 0,
      financed_by: null,
      down_payment: null,
      dp_collection: null,
      minimum_retained: null,
      continuous: null,
      contract_number: null,
      expiry_date: null,
      pfa_attachment: null,
      status: "ACTIVE",
    },
    premiumFALoading: LoadState.NotLoaded,
    error: null,
  };
