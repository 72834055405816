import { Box, InputAdornment, Typography, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IComment, ICommentField } from './CommentField.types'
import { CustomTextField } from '../../../../components/formsComponents'
import { useAuth } from '../../../../contexts/AuthProvider/AuthProvider'

export const CommentField: React.FC<ICommentField> = ({ comments, onChange }) => {
  const [commentsState, setCommentsState] = useState<IComment[]>([])
  const [newComment, setNewComment] = useState<IComment>({ comment: "", timestamp: "" })
  const {user} = useAuth();

  useEffect(() => {
    setCommentsState(comments)
  }, [comments])

  const onAddNewComment = () => {
    onChange([...commentsState, newComment])
    setNewComment({ comment: "", timestamp: "" })
  }

  return (
    <Box display="flex" flexDirection="column" sx={{ border: '1px solid #ccc', borderRadius: 1, padding: 2, gap: 1 }} >
      {commentsState.map((value, idx) => {
        return (
          <Box key={idx} sx={{ border: '1px solid #ccc', borderRadius: 1, padding: 1, gap: 1 }}>
            <Typography variant='body1'>{value.comment}</Typography>
            <Typography variant='subtitle1' textAlign="right">{`${user.name} `}</Typography>
            <Typography variant='subtitle1' textAlign="right">{`${new Date(value.timestamp).toLocaleString()}`}</Typography>
          </Box>
        )
      })}
      <Box  display="flex" gap={2}>
        <CustomTextField
          id="comment"
          variant="outlined"
          placeholder='add new comment here'
          size="small"
          type="text"
          fullWidth
          value={newComment.comment}
          onChange={(e) => setNewComment({ comment: e.target.value , timestamp: new Date().toISOString() })}
        />
        <Button variant="contained" size='small' onClick={onAddNewComment} >Add</Button>
      </Box>

    </Box>
  )
}
