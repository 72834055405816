/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import { Dialog } from "../../../../../components/Dialogs/Dialog";
import {
  CustomFormLabel,
  CustomTextField,
} from "../../../../../components/formsComponents";
import {
  clearTaskCancel,
  upsertTaskAysnc,
} from "../../../../../redux/tasks/tasksActions";
import { useFormik } from "formik";
import { useDispatchWrapper, useTaskCustomerInfo } from "../../../../../hooks";
import React from "react";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { initialTask } from "../../../../../redux/tasks/task.types";
import { UsersAutoSearchByRole } from "../../../../../components/AutoCompleteSearches/UsersAutoSearchByRole";
import { useCurrentUserId } from "../../../../../hooks/useAssignedTo";

export const CancellationDialog: React.FC<{
  customerPolicyId: number;
  open: boolean;
  onClose: () => void;
  changeNextStep: (taskCode: string) => void;
}> = (props) => {
  const { customerPolicyId, open, onClose, changeNextStep } = props;
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.customer.personalInformation
  );

  const dispatch = useDispatchWrapper();
  const currentUserId = useCurrentUserId();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialTask,
    validate: (values) => {
      const errors: any = {};
      return errors;
    },
    onSubmit: (values) => {
      dispatch(
        upsertTaskAysnc(
          {
            ...values,
            customer_policy_id: customerPolicyId,
            first_name: data.first_name,
            last_name: data.last_name,
            assigned_to_id: currentUserId
          },
          initialTask,
          initialTask.task_code ? true : false,
          (isSuccess, taskCode) => {
            if (isSuccess && taskCode) {
              changeNextStep(taskCode.task_code);
            }
          }
        )
      );
    },
  });

  const customerNameCode = `${data.first_name} ${data.last_name}`;

  React.useEffect(() => {
    setValues({
      ...values,
      task_type: "Urgent Transaction",
      task_sub_type: "Cancellation/Reinstatement",
      task_related_to: "Customers",
    });
  }, []);

  React.useEffect(() => {
    return () => {
      dispatch(clearTaskCancel());
    };
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Cancellation"
      size="md"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      actions={[
        {
          type: "button",
          variant: "contained",
          label: "Next",
          onClick: handleSubmit,
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <CustomFormLabel>Name</CustomFormLabel>
          <CustomTextField
            variant="outlined"
            size="small"
            type="string"
            name="customerNameCode"
            fullWidth
            value={customerNameCode}
            disabled
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <CustomFormLabel>Transaction</CustomFormLabel>
          <CustomTextField
            variant="outlined"
            disabled
            size="small"
            type="string"
            fullWidth
            value={"Urgent Transaction"}
          ></CustomTextField>
        </Grid>

        <Grid item xs={12} md={4}>
          <CustomFormLabel>Sub-Type</CustomFormLabel>
          <CustomTextField
            variant="outlined"
            size="small"
            type="string"
            fullWidth
            disabled
            value={"Cancellation/Reinstatement"}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};
