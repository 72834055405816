/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../redux/initialStoreState";
import { useDispatchWrapper } from "./useDispatch";
import { fetchPolicies } from "../redux/common/commonActions";

export const useFetchPolicies = () => {
  const policies = useSelector(
    (storeState: IStoreState) => storeState.common.policies.list
  );
  const dispatch = useDispatchWrapper();

  const getPolicySubtypes = (policy: string) => {
    const policyData = policies.find((x) => x.type === policy);
    if (policyData) {
      return policyData.subTypes || [];
    }
    return [];
  };

  React.useEffect(() => {
    dispatch(fetchPolicies());
  }, []);

  return {
    policies,
    getPolicySubtypes,
  };
};
