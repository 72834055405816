import produce from "immer";
import { IStoreState } from "../initialStoreState";
import {  initialAutomobileMarketingHistoryState } from "./automobileMarketingHistory.types";
import { AutomobileMarketingHistoryActions } from ".";
import { CLEAR_AUTOMOBILE_POLICY_MARKETING_HISTORY, CLEAR_AUTOMOBILE_POLICY_MARKETING_STATE, FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_FAILED, FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_FAILED, FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_PROGRESS, FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_SUCCESS, FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_PROGRESS, FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_SUCCESS } from "./automobileMarketingHistoryActions";
import { LoadState } from "../../constants/enums";



export const automobilePolicyMarketingReducer = (
  state: IStoreState["automobile"]["marketing"] = initialAutomobileMarketingHistoryState,
  action: AutomobileMarketingHistoryActions
) => {
  switch (action.type) {
   
    
   
    case FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.marketingHistoryListLoading = LoadState.InProgress;
        draftState.marketingHistoryList = [];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.marketingHistoryListLoading = LoadState.Loaded;
        draftState.marketingHistoryList = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.marketingHistoryListLoading = LoadState.Failed;
        draftState.marketingHistoryList = [];
      });
      return newState;
    }

    case FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.marketingHistoryLoading = LoadState.InProgress;
        draftState.marketingHistory =
          initialAutomobileMarketingHistoryState["marketingHistory"];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.marketingHistoryLoading = LoadState.Loaded;
        draftState.marketingHistory = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_FAILED: {
      const {errorMessage} = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.marketingHistoryLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    
    }

    case CLEAR_AUTOMOBILE_POLICY_MARKETING_HISTORY: {
      const newState = produce(state, (draftState) => {
        draftState.marketingHistory =initialAutomobileMarketingHistoryState['marketingHistory'];
        draftState.marketingHistoryLoading = LoadState.NotLoaded;
        draftState.error = null;
      });
      return newState;
    }
    case CLEAR_AUTOMOBILE_POLICY_MARKETING_STATE: {
      return initialAutomobileMarketingHistoryState;
    }

    default: {
      return state;
    }
  }
};
