/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Button, Link, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { LoadState } from "../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../hooks";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { CustomSelect } from "../../../../../../components/formsComponents";
import { renderDropdownValue } from "../../../../../../helpers";
import { Add, NotificationsActive, ThumbUpAlt } from "@mui/icons-material";
import { CustomChip } from "../../../../../../components/CustomChip";
import { LeadOppStatus } from "../../../../../../components/LeadOppStatus/LeadOppStatus";
import { IHabitationalDwellingDeclaration } from "../../../../../../redux/habitationalPolicy/HabitationalPolicyForm/HabitationalPolicyForm.types";
import { downloadHabitationalDwellingDeclarationPDFAsync, fetchHabitationalDwellingDeclarationListAsync, previewHabitationalDwellingDeclarationPDFAsync } from "../../../../../../redux/habitationalPolicy/HabitationalPolicyForm/HabitationalPolicyFormActions";

export const HabitationalDwellingDeclarationList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { list, loading } = useSelector(
    (storeState: IStoreState) => storeState.habitational.policyform.dwellingdeclaration
  );
  const [downloading, setDownloading] = React.useState(false);
  const dispatch = useDispatchWrapper();

  const [selectedConsent, setselectedConsent] = React.useState<{
    isAllSelected: boolean;
    certificate: IHabitationalDwellingDeclaration[];
  }>({
    isAllSelected: false,
    certificate: [],
  });

  const menuItems =
    selectedConsent.certificate.length > 0 || selectedConsent.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const DwellingDeclarationTableProps: IDataTableProps = {
    isPagination: true,
    isDataLoading: loading === LoadState.InProgress,
    rowsPerPageOptions: 50,
    selectionMode: "multiple",
    uniqueRowKeyName: "incemental_number",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [...menuItems],
      },
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => {
                  navigate(
                    `/habitational-policy/${customerId}/policy-forms-tab/${customerPolicyId}/dwelling-declaration`
                  );
                }}
              >
                Create
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
    {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalDwellingDeclaration) => {
          return (
            <StandardTableActions
              downloadLoading={downloading}
              onDownloadPreview={() => {
                dispatch(
                  previewHabitationalDwellingDeclarationPDFAsync(
                    customerPolicyId,
                    row.incremental_number as number,
                    () => {}
                  )
                );
              }}
              onDownLoadClick={() => {
                if (row.incremental_number) {
                  setDownloading(true);
                  dispatch(
                    downloadHabitationalDwellingDeclarationPDFAsync(
                      customerPolicyId,
                      row.incremental_number,
                      () => {
                        setDownloading(false);
                      }
                    )
                  );
                }
              }}
              onEditClick={() => {
                navigate(
                  `/habitational-policy/${customerId}/policy-forms-tab/${customerPolicyId}/dwelling-declaration/${row.incremental_number}`
                );
              }}
              // onDuplicateClick={() => {
              //   navigate(
              //     `/habitational-policy/${customerId}/policy-forms-tab/${customerPolicyId}/dwelling-declaration-duplicate/${row.incremental_number}`
              //   );
              // }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalDwellingDeclaration) => {
          return <LeadOppStatus status={row.status} />;
        },
      },
      {
        key: "customer_name",
        headerName: "Customer Name",
        fieldName: "customer_name",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },
      {
        key: "address",
        headerName: "Address",
        fieldName: "address",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },
      {
        key: "unit_or_suite",
        headerName: "Unit Suite",
        fieldName: "unit_or_suite",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },
      {
        key: "city",
        headerName: "City",
        fieldName: "city",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },
      {
        key: "province_or_state",
        headerName: "Provice State",
        fieldName: "province_or_state",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },
      {
        key: "postal_code",
        headerName: "Postal Code",
        fieldName: "postal_code",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },
      {
        key: "country",
        headerName: "Country",
        fieldName: "country",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },
    ],
    items: list,
    onSelection: (isAllSelected: boolean, rows: IHabitationalDwellingDeclaration[]) => {
        setselectedConsent({ isAllSelected: isAllSelected, certificate: rows });
    },
  };

  React.useEffect(() => {
    dispatch(fetchHabitationalDwellingDeclarationListAsync(customerPolicyId));
  }, [customerPolicyId]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...DwellingDeclarationTableProps} />
      </Box>
    </>
  );
};
