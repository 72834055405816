import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { LoadState } from "../../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";
import { ICommAutomobileApplication } from "../../../../../../../redux/commAutoPolicy/commAutoApplication/commAutoApplication.types";
import { fetchCommAutomobileScheduleBListAsync, fetchCommAutomobileScheduleCListAsync } from "../../../../../../../redux/commAutoPolicy/commAutoSchedule/commAutoScheduleActions";
import {  ICommAutomobileScheduleB, ICommAutomobileScheduleC } from "../../../../../../../redux/commAutoPolicy/commAutoSchedule/commAutoSchedule.types";

export const CommAutomobileScheduleCList = () => {

  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerpolicyId = data1[1];

  const navigate = useNavigate();
  const { listData: data, listLoading: loading } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.schedule.scheduleC
  );

  const dispatch = useDispatchWrapper();

  const CommautomobileApplicationTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    `/comm-automobile-policy/${customerId}/vehicle-coverage-schedule-tab/${customerpolicyId}/scheduleC`
                  );
                }}
              >
                Add
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "customer_policy_id",
        headerName: "ID",
        fieldName: "customer_policy_id",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
        onRowCellRender: (value, row: ICommAutomobileScheduleC) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/vehicle-coverage-schedule-tab/${customerpolicyId}/scheduleC/${row.incremental_number}`
                );
              }}
            >
              {row.customer_policy_id}
            </Link>
          );
        },
      },
      {
        key: "created_by_id",
        headerName: "Created by ID",
        fieldName: "created_by_id",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: 'center',
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobileApplication) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/vehicle-coverage-schedule-tab/${customerpolicyId}/scheduleC/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
    ],
    items: data, 
  };

  
  React.useEffect(() => {
    dispatch(fetchCommAutomobileScheduleCListAsync(customerpolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerpolicyId]);

  return (
    <Box sx={{ mt: 2 }}>
      <DataTable {...CommautomobileApplicationTableProps} />
    </Box>
  );
};
