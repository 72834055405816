import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { defaultRenewalsState } from "./renewals.types";
import { CLEAR_TASK_RENEWALS, FETCH_TASK_RENEWALS_LIST_FAILED, FETCH_TASK_RENEWALS_LIST_PROGRESS, FETCH_TASK_RENEWALS_LIST_SUCCESS } from "./renewalsActions";
import { RenewalsActions } from ".";



export const renewalsReducer = (
  state: IStoreState["renewals"] = defaultRenewalsState,
  action: RenewalsActions
) => {
  switch (action.type) {
    
    case FETCH_TASK_RENEWALS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_TASK_RENEWALS_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = list;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_TASK_RENEWALS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case CLEAR_TASK_RENEWALS: {
      const newState = produce(state, (draftState)=>{
        draftState.loading = LoadState.NotLoaded;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState
    }

    default: {
      return state;
    }
  }
};
