import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialBusinessPolicyContractorState } from "./businessPolicyContractor.type";
import { CLEAR_BUSINESS_POLICY_CONTRACTOR, CLEAR_BUSINESS_POLICY_CONTRACTOR_STATE, FETCH_BUSINESS_POLICY_CONTRACTOR_FAILED, FETCH_BUSINESS_POLICY_CONTRACTOR_LIST_FAILED, FETCH_BUSINESS_POLICY_CONTRACTOR_LIST_PROGRESS, FETCH_BUSINESS_POLICY_CONTRACTOR_LIST_SUCCESS, FETCH_BUSINESS_POLICY_CONTRACTOR_PROGRESS, FETCH_BUSINESS_POLICY_CONTRACTOR_SUCCESS } from "./businessPolicyContractorActions";
import { BusinessPolicyContractActions } from ".";

export const businessPolicyContractorReducer = (
  state: IStoreState["business"]["contractor"] = initialBusinessPolicyContractorState,
  action: BusinessPolicyContractActions
) => {
  switch (action.type) {
    case FETCH_BUSINESS_POLICY_CONTRACTOR_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_CONTRACTOR_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_CONTRACTOR_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.data = [];
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_CONTRACTOR_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.contractorLoading = LoadState.InProgress;
        draftState.contractor = initialBusinessPolicyContractorState["contractor"];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_CONTRACTOR_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.contractorLoading = LoadState.Loaded;
        draftState.contractor = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_CONTRACTOR_FAILED: {
      const {errorMessage} = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.contractorLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
  
    case CLEAR_BUSINESS_POLICY_CONTRACTOR: {
      const newState = produce(state, (draftState) => {
        draftState.contractor = initialBusinessPolicyContractorState["contractor"];
        draftState.contractorLoading = LoadState.NotLoaded;
      });
      return newState;
    }
    case CLEAR_BUSINESS_POLICY_CONTRACTOR_STATE: {
      return initialBusinessPolicyContractorState;
    }
    default: {
      return state;
    }
  }
};
