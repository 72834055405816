import { useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../hooks";
import React from "react";
import { Grid, Typography } from "@mui/material";
import { StandardCellList } from "../../../../components/Standards";
import { formatTime } from "../../../../helpers";
import { ITaskDeleteRiskLocation } from "../../../../redux/generalChange/generalChange.types";
import { fetchTaskDeleteRiskAysnc } from "../../../../redux/generalChange/generalChangeActions";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../constants/enums";
import { stripHtml } from "../../../../helpers/stripHtml";
import { QuillEditor } from "../../../../components/QuillEditor/QuillEditor";

export const ViewDeleteRiskLocation: React.FC<{
  taskCode: string;
}> = (props) => {
  const { taskCode } = props;

  const { deleteData, deleteLoading } = useSelector(
    (storeState: IStoreState) => storeState.tasks.generalChange.riskLoaction
  );

  const dispatch = useDispatchWrapper();
  const [riskLocationDetails, setRiskLocationDetails] =
    React.useState<ITaskDeleteRiskLocation>();

  React.useEffect(() => {
    dispatch(fetchTaskDeleteRiskAysnc(taskCode));
  }, [taskCode]);

  React.useEffect(() => {
    setRiskLocationDetails(deleteData);
  }, [deleteData]);

  return (
    <PageLoader loading={deleteLoading === LoadState.InProgress}>
      {riskLocationDetails && (
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              colSize={3}
              list={[
                {
                  heading: "Risk Location",
                  data: {
                    type: "text",
                    value: riskLocationDetails.risk_location,
                  },
                },
                {
                  heading: "Effective Date",
                  data: {
                    type: "text",
                    value: riskLocationDetails.effective_date_of_change,
                  },
                },
                {
                  heading: "Effective Time",
                  data: {
                    type: "text",
                    value: formatTime(
                      riskLocationDetails.effective_time_of_change || ""
                    ),
                  },
                },
              ]}
            >
              <Grid item xs={12} md={12}>
                <Typography fontWeight={500} variant="h4" mb={1}>
                  Details
                </Typography>
                <QuillEditor
                  value={riskLocationDetails.change_details || ""}
                  onChange={() => {}}
                  readOnly={true}
                />
              </Grid>
            </StandardCellList>
          </Grid>
        </Grid>
      )}
    </PageLoader>
  );
};
