import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { TaskGaragePlateActions } from ".";
import { initialTaskGaragePlateState } from "./garagePlate.types";
import { FETCH_ADD_GARAGE_PLATE_FAILED, FETCH_ADD_GARAGE_PLATE_PROGRESS, FETCH_ADD_GARAGE_PLATE_SUCCESS, FETCH_DELETE_GARAGE_PLATE_FAILED, FETCH_DELETE_GARAGE_PLATE_PROGRESS, FETCH_DELETE_GARAGE_PLATE_SUCCESS } from "./garagePlateActions";

export const taskGaragePlateReducer = (
    state: IStoreState["tasks"]["garagePlate"] = initialTaskGaragePlateState,
    action: TaskGaragePlateActions
  ) => {
    switch (action.type) {
        case FETCH_ADD_GARAGE_PLATE_PROGRESS: {
            const newState = produce(state, draftState=>{
                draftState.addGaragePlate.loading = LoadState.InProgress;
                draftState.addGaragePlate = initialTaskGaragePlateState['addGaragePlate'];
            })
            return newState;
        }
        case FETCH_ADD_GARAGE_PLATE_SUCCESS: {
            const {data} = action.payload;
            const newState = produce(state, draftState=>{
                draftState.addGaragePlate.loading = LoadState.Loaded;
                draftState.addGaragePlate.data = data;
            })
            return newState;
        }
        case FETCH_ADD_GARAGE_PLATE_FAILED: {
            const newState = produce(state, draftState=>{
                draftState.addGaragePlate.loading = LoadState.Failed;
            })
            return newState;
        }

        case FETCH_DELETE_GARAGE_PLATE_PROGRESS: {
            const newState = produce(state, draftState=>{
                draftState.deleteGaragePlate.loading = LoadState.InProgress;
                draftState.deleteGaragePlate = initialTaskGaragePlateState['deleteGaragePlate'];
            })
            return newState;
        }
        case FETCH_DELETE_GARAGE_PLATE_SUCCESS: {
            const {data} = action.payload;
            const newState = produce(state, draftState=>{
                draftState.deleteGaragePlate.loading = LoadState.Loaded;
                draftState.deleteGaragePlate.data = data;
            })
            return newState;
        }
        case FETCH_DELETE_GARAGE_PLATE_FAILED: {
            const newState = produce(state, draftState=>{
                draftState.deleteGaragePlate.loading = LoadState.Failed;
            })
            return newState;
        }
        
        default:
            return state;
    }
  }