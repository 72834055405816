import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { defaultHabitationalPaymentAuthorizationState } from "./habitationalPaymentAuthorization.types";
import { HabitationalPaymentAuthorizationActions } from ".";
import { CLEAR_HABITATIONAL_PAYMENT_AUTHORIZATION, CLEAR_HABITATIONAL_PAYMENT_AUTHORIZATION_STATE, FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_FAILED, FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_LIST_FAILED, FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_LIST_PROGRESS, FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_LIST_SUCCESS, FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_PROGRESS, FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_SUCCESS } from "./habitationalPaymentAuthorizationActions";

export const habitationalPaymentAuthorizationReducer = (
  state: IStoreState["habitational"]["paymentAuthorization"] = defaultHabitationalPaymentAuthorizationState,
  action: HabitationalPaymentAuthorizationActions
) => {
  switch (action.type) {
    case FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.data = [];
      });
      return newState;
    }

    case FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.payAuthLoading = LoadState.InProgress;
        draftState.payAuth = defaultHabitationalPaymentAuthorizationState["payAuth"];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.payAuthLoading = LoadState.Loaded;
        draftState.payAuth = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_FAILED: {
      const {errorMessage} = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.payAuthLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
  
    case CLEAR_HABITATIONAL_PAYMENT_AUTHORIZATION: {
      const newState = produce(state, (draftState) => {
        draftState.payAuth = defaultHabitationalPaymentAuthorizationState["payAuth"];
        draftState.payAuthLoading = LoadState.NotLoaded;
      });
      return newState;
    }
    case CLEAR_HABITATIONAL_PAYMENT_AUTHORIZATION_STATE: {
      return defaultHabitationalPaymentAuthorizationState;
    }
    default: {
      return state;
    }
  }
};
