import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { defaultHabitationalInterestedState } from "./habitationalInterested.types";
import { CLEAR_HABITATIONAL_INTERESTED, CLEAR_HABITATIONAL_INTERESTED_STATE, FETCH_HABITATIONAL_INTERESTED_FAILED, FETCH_HABITATIONAL_INTERESTED_LIST_FAILED, FETCH_HABITATIONAL_INTERESTED_LIST_PROGRESS, FETCH_HABITATIONAL_INTERESTED_LIST_SUCCESS, FETCH_HABITATIONAL_INTERESTED_PROGRESS, FETCH_HABITATIONAL_INTERESTED_SUCCESS } from "./habitationalInterestedActions";
import { LoadState } from "../../../constants/enums";
import { HabitationalInterestedActions } from ".";

export const HabitationalInterestedReducer = (
  state: IStoreState["habitational"]["interested"] = defaultHabitationalInterestedState,
  action: HabitationalInterestedActions
) => {
    switch (action.type) {
        case FETCH_HABITATIONAL_INTERESTED_LIST_PROGRESS: {
          const newState = produce(state, (draftState) => {
            draftState.loading = LoadState.InProgress;
            draftState.data = [];
          });
          return newState;
        }
        case FETCH_HABITATIONAL_INTERESTED_LIST_SUCCESS: {
          const { data } = action.payload;
          const newState = produce(state, (draftState) => {
            draftState.loading = LoadState.Loaded;
            draftState.data = data;
          });
          return newState;
        }
        case FETCH_HABITATIONAL_INTERESTED_LIST_FAILED: {
          const newState = produce(state, (draftState) => {
            draftState.loading = LoadState.Failed;
            draftState.data = [];
          });
          return newState;
        }

        case FETCH_HABITATIONAL_INTERESTED_PROGRESS: {
          const newState = produce(state, (draftState) => {
            draftState.intrestLoading = LoadState.InProgress;
            draftState.interest = defaultHabitationalInterestedState['interest'];
          });
          return newState;
        }
        case FETCH_HABITATIONAL_INTERESTED_SUCCESS: {
          const { data } = action.payload;
          const newState = produce(state, (draftState) => {
            draftState.intrestLoading = LoadState.Loaded;
            draftState.interest = data;
          });
          return newState;
        }
        case FETCH_HABITATIONAL_INTERESTED_FAILED: {
          const {errorMessage} = action.payload;
          const newState = produce(state, (draftState) => {
            draftState.intrestLoading = LoadState.Failed;
            draftState.error = errorMessage
          });
          return newState;
        }
       
        case CLEAR_HABITATIONAL_INTERESTED: {
          const newState = produce(state, (draftState) => {
            draftState.interest = defaultHabitationalInterestedState["interest"];
          });
          return newState;
        }
        case CLEAR_HABITATIONAL_INTERESTED_STATE: {
          return defaultHabitationalInterestedState;
        }
        default: {
          return state;
        }
      }
};
