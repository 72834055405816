import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import {
  IAutomobileDriverActivity,
  IAutomobileDriverClaims,
  IAutomobileDriverConviction,
  IAutomobileDriverInformation,
} from "./automobileDriver.types";
import { ERROR_MESSAGES } from "../../constants/enums";
import { uploadFile } from "../../helpers";

export const FETCH_AUTOMOBILE_DRIVER_INFORMATION_PROGRESS =
  "FETCH_AUTOMOBILE_DRIVER_INFORMATION_PROGRESS";
export const FETCH_AUTOMOBILE_DRIVER_INFORMATION_SUCCESS =
  "FETCH_AUTOMOBILE_DRIVER_INFORMATION_SUCCESS";
export const FETCH_AUTOMOBILE_DRIVER_INFORMATION_FAILED =
  "FETCH_AUTOMOBILE_DRIVER_INFORMATION_FAILED";
export const CLEAR_AUTOMOBILE_DRIVER_INFO = "CLEAR_AUTOMOBILE_DRIVER_INFO";

export const fetchAutomobileDriverProgress = () =>
  action(FETCH_AUTOMOBILE_DRIVER_INFORMATION_PROGRESS);
export const fetchAutomobileDriverSuccess = (
  data: IAutomobileDriverInformation
) =>
  action(FETCH_AUTOMOBILE_DRIVER_INFORMATION_SUCCESS, {
    data,
  });
export const fetchAutomobileDriverFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_DRIVER_INFORMATION_FAILED, { errorMessage });
export const clearAutomobileDriverInfo = () =>
  action(CLEAR_AUTOMOBILE_DRIVER_INFO);

export const addAutomobileDriverAsync =
  (
    data: IAutomobileDriverInformation,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/policy/edit-policy-auto-driver", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Driver information saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const fetchAutomobileDriverAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileDriverProgress());
      const res = await api.get(
        `/policy/get-policy-auto-driver?customer_policy_id=${customerPolicyId}&column=driver_number&column=driver_name&value=${incremental_number}`
      );
      const data: IAutomobileDriverInformation[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileDriverSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(fetchAutomobileDriverFailed(ERROR_MESSAGES.POLICY_NOT_FOUND));
      }
    } catch (err: any) {
      dispatch(fetchAutomobileDriverFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_AUTOMOBILE_DRIVER_INFORMATION_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_DRIVER_INFORMATION_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_DRIVER_INFORMATION_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_DRIVER_INFORMATION_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_DRIVER_LIST_INFORMATION_FAILED =
  "FETCH_AUTOMOBILE_DRIVER_LIST_INFORMATION_FAILED";

export const fetchAutomobileDriversListProgress = () =>
  action(FETCH_AUTOMOBILE_DRIVER_INFORMATION_LIST_PROGRESS);

export const fetchAutomobileDriverListSuccess = (
  data: IAutomobileDriverInformation[]
) =>
  action(FETCH_AUTOMOBILE_DRIVER_INFORMATION_LIST_SUCCESS, {
    data,
  });
export const fetchAutomobileDriversListFailed = () =>
  action(FETCH_AUTOMOBILE_DRIVER_LIST_INFORMATION_FAILED);

export const fetchAutomobileDriverListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileDriversListProgress());
      const res = await api.get(
        `/policy/get-policy-auto-driver?customer_policy_id=${customerPolicyId}`
      );
      const data: IAutomobileDriverInformation[] = res.data.data;
      dispatch(fetchAutomobileDriverListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileDriversListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_DRIVER_STATE = "CLEAR_AUTOMOBILE_DRIVER_STATE";
export const clearAutomobileDriverState = () =>
  action(CLEAR_AUTOMOBILE_DRIVER_STATE);

export const FETCH_AUTOMOBILE_DRIVER_CLAIM_PROGRESS =
  "FETCH_AUTOMOBILE_DRIVER_CLAIM_PROGRESS";
export const FETCH_AUTOMOBILE_DRIVER_CLAIM_SUCCESS =
  "FETCH_AUTOMOBILE_DRIVER_CLAIM_SUCCESS";
export const FETCH_AUTOMOBILE_DRIVER_CLAIM_FAILED =
  "FETCH_AUTOMOBILE_DRIVER_CLAIM_FAILED";

export const fetchAutomobileDriverClaimProgress = () =>
  action(FETCH_AUTOMOBILE_DRIVER_CLAIM_PROGRESS);
export const fetchAutomobileDriverClaimSuccess = (
  data: IAutomobileDriverClaims
) =>
  action(FETCH_AUTOMOBILE_DRIVER_CLAIM_SUCCESS, {
    data,
  });
export const fetchAutomobileDriverClaimFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_DRIVER_CLAIM_FAILED, { errorMessage });

export const addAutomobileDriverClaimAsync =
  (
    data: IAutomobileDriverClaims,
    customerID: number,
    file: any | null,
    attachment: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      const asPayload = {
        customer_id: customerID,
        policy_type: "AUTOMOBILE",
        customer_policy_id: data.customer_policy_id,
        page_name: "Auto Driver Claims",
      };

      const filePath = await uploadFile(
        file,
        "POLICY",
        data.file_upload || "",
        asPayload
      );

      await api.post("/policy/edit-policy-auto-driver-claims", {
        ...data,
        file_upload: filePath,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Driver claim saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const fetchAutomobileDriverClaimAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileDriverClaimProgress());
      const res = await api.get(
        `/policy/get-policy-auto-driver-claims?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileDriverClaims[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileDriverClaimSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchAutomobileDriverClaimFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchAutomobileDriverClaimFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const CLEAR_AUTOMOBILE_DRIVER_CLAIM_INFO =
  "CLEAR_AUTOMOBILE_DRIVER_CLAIM_INFO";
export const clearAutomobileClaimInfo = () =>
  action(CLEAR_AUTOMOBILE_DRIVER_CLAIM_INFO);

export const FETCH_AUTOMOBILE_DRIVER_CLAIM_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_DRIVER_CLAIM_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_DRIVER_CLAIM_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_DRIVER_CLAIM_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_DRIVER_CLAIM_LIST_FAILED =
  "FETCH_AUTOMOBILE_DRIVER_CLAIM_LIST_FAILED";

export const fetchAutomobileDriversListClaimProgress = () =>
  action(FETCH_AUTOMOBILE_DRIVER_CLAIM_LIST_PROGRESS);
export const fetchAutomobileDriverClaimsListSuccess = (
  data: IAutomobileDriverClaims[]
) =>
  action(FETCH_AUTOMOBILE_DRIVER_CLAIM_LIST_SUCCESS, {
    data,
  });
export const fetchAutomobileDriversListClaimFailed = () =>
  action(FETCH_AUTOMOBILE_DRIVER_CLAIM_LIST_FAILED);
export const fetchAutomobileDriverClaimsListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileDriversListClaimProgress());
      const res = await api.get(
        `/policy/get-policy-auto-driver-claims?customer_policy_id=${customerPolicyId}`
      );
      const data: IAutomobileDriverClaims[] = res.data.data;
      dispatch(fetchAutomobileDriverClaimsListSuccess(data));
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_PROGRESS =
  "FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_PROGRESS";
export const FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_SUCCESS =
  "FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_SUCCESS";
export const FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_FAILED =
  "FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_FAILED";

export const fetchAutomobileDriverConvitionsProgress = () =>
  action(FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_PROGRESS);
export const fetchAutomobileDriverConvitionsSuccess = (
  data: IAutomobileDriverConviction
) =>
  action(FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_SUCCESS, {
    data,
  });
export const fetchAutomobileDriverConvitionsFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_FAILED, { errorMessage });

export const CLEAR_AUTOMOBILE_DRIVER_CONVICTIONS_INFO =
  "CLEAR_AUTOMOBILE_DRIVER_CONVICTIONS_INFO";
export const clearAutomobileConvictions = () =>
  action(CLEAR_AUTOMOBILE_DRIVER_CONVICTIONS_INFO);

export const addAutomobileDriverConvitionsAsync =
  (
    data: IAutomobileDriverConviction,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/policy/edit-policy-auto-driver-convictions", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Driver convitions saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const fetchAutomobileDriverConvitionsAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileDriverClaimProgress());
      const res = await api.get(
        `/policy/get-policy-auto-driver-convictions?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileDriverConviction[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileDriverConvitionsSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchAutomobileDriverConvitionsFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(
        fetchAutomobileDriverConvitionsFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_DRIVER_LIST_CONVICTIONS_FAILED =
  "FETCH_AUTOMOBILE_DRIVER_LIST_CONVICTIONS_FAILED";

export const fetchAutomobileDriversConvitionsListProgress = () =>
  action(FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_LIST_PROGRESS);

export const fetchAutomobileDriverConvitionsListSuccess = (
  data: IAutomobileDriverConviction[]
) =>
  action(FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_LIST_SUCCESS, {
    data,
  });
export const fetchAutomobileDriversConvitionsListFailed = () =>
  action(FETCH_AUTOMOBILE_DRIVER_LIST_CONVICTIONS_FAILED);

export const fetchAutomobileDriverConvitionsListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileDriversConvitionsListProgress());
      const res = await api.get(
        `/policy/get-policy-auto-driver-convictions?customer_policy_id=${customerPolicyId}`
      );
      const data: IAutomobileDriverConviction[] = res.data.data;
      dispatch(fetchAutomobileDriverConvitionsListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileDriversConvitionsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_AUTOMOBILE_DRIVER_TASKS_PROGRESS =
  "FETCH_AUTOMOBILE_DRIVER_TASKS_PROGRESS";
export const FETCH_AUTOMOBILE_DRIVER_TASKS_SUCCESS =
  "FETCH_AUTOMOBILE_DRIVER_TASKS_SUCCESS";
export const FETCH_AUTOMOBILE_DRIVER_TASKS_FAILED =
  "FETCH_AUTOMOBILE_DRIVER_TASKS_FAILED";

export const fetchAutomobileDriverTasksProgress = () =>
  action(FETCH_AUTOMOBILE_DRIVER_TASKS_PROGRESS);
export const fetchAutomobileDriverTasksSuccess = (
  data: IAutomobileDriverActivity
) =>
  action(FETCH_AUTOMOBILE_DRIVER_TASKS_SUCCESS, {
    data,
  });
export const fetchAutomobileDriverTasksFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_DRIVER_TASKS_FAILED, { errorMessage });

export const addAutomobileDriverTasksAsync =
  (
    data: IAutomobileDriverActivity,
    customerId: number | string,
    file: any | null,
    file_two: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      const asPayload = {
        customer_id: customerId,
        policy_type: "AUTOMOBILE",
        customer_policy_id: data.customer_policy_id,
        page_name: "Auto Driver Insurnace Task",
      };

      const path = await uploadFile(
        file,
        "POLICY",
        data.file_upload || "",
        asPayload
      );
      const path_two = await uploadFile(
        file_two,
        "POLICY",
        data.file_upload_two || "",
        asPayload
      );

      await api.post("/policy/edit-policy-auto-insurance-reports", {
        ...data,
        producer_one_code: data.producer_one_code,
        csr_code: data.csr_code,
        requested_by_code: data.requested_by_code,
        file_upload: path,
        file_upload_two: path_two
      });
      
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Driver activity/tasks saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const fetchAutomobileDriverTasksAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileDriverTasksProgress());
      const res = await api.get(
        `/policy/get-policy-auto-insurance-reports?customer_policy_id=${customerPolicyId}&column=incremental_number&column=driver_number&column=driver_name&value=${incremental_number}`
      );
      const data: IAutomobileDriverActivity[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileDriverTasksSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchAutomobileDriverTasksFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchAutomobileDriverTasksFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const CLEAR_AUTOMOBILE_DRIVER_TASKS = "CLEAR_AUTOMOBILE_DRIVER_TASKS";
export const clearAutomobileActivityTask = () =>
  action(CLEAR_AUTOMOBILE_DRIVER_TASKS);

export const FETCH_AUTOMOBILE_DRIVER_TASKS_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_DRIVER_TASKS_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_DRIVER_TASKS_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_DRIVER_TASKS_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_DRIVER_TASKS_LIST_CONVICTIONS_FAILED =
  "FETCH_AUTOMOBILE_DRIVER_TASKS_LIST_CONVICTIONS_FAILED";

export const fetchAutomobileDriversTasksListProgress = () =>
  action(FETCH_AUTOMOBILE_DRIVER_TASKS_LIST_PROGRESS);

export const fetchAutomobileDriverTasksListSuccess = (
  data: IAutomobileDriverActivity[]
) =>
  action(FETCH_AUTOMOBILE_DRIVER_TASKS_LIST_SUCCESS, {
    data,
  });
export const fetchAutomobileDriversTasksListFailed = () =>
  action(FETCH_AUTOMOBILE_DRIVER_TASKS_LIST_CONVICTIONS_FAILED);

export const fetchAutomobileDriverTasksListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileDriversTasksListProgress());
      const res = await api.get(
        `/policy/get-policy-auto-insurance-reports?customer_policy_id=${customerPolicyId}`
      );
      const data: IAutomobileDriverActivity[] = res.data.data;
      dispatch(fetchAutomobileDriverTasksListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileDriversTasksListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
