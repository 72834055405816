import { LoadState } from "../../constants/enums";

export interface IBinderState {
  list: IBinders[];
  totalRecords: number;
  loading: LoadState;
}

export interface IBinders {
    customer_policy_id: number,
    policy_binder_number: string | null,
    type: string,
    status: string,
    isBinder: string,
    created_by_id: number,
    insert_ts: string | null
    policy_status: string,
}

export const defaultBindersState: IBinderState = {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
}