import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { ERROR_MESSAGES } from "../../../constants/enums";
import { ICommAutomobilePolicyAuthorization } from "./commAutoPolicyAuthorization.types";

export const FETCH_COMM_AUTOMOBILE_AUTHORIZATION_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_AUTHORIZATION_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_AUTHORIZATION_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_AUTHORIZATION_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_AUTHORIZATION_FAILED =
  "FETCH_COMM_AUTOMOBILE_AUTHORIZATION_FAILED";

export const fetchCommAutomobileAuthorizationProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_AUTHORIZATION_PROGRESS);
export const fetchCommAutomobileAuthorizationSuccess = (
  data: ICommAutomobilePolicyAuthorization
) =>
  action(FETCH_COMM_AUTOMOBILE_AUTHORIZATION_SUCCESS, {
    data,
  });
export const fetchCommAutomobileAuthorizationFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_AUTHORIZATION_FAILED, { errorMessage });

export const addCommAutomobileAuthorizationAsync =
  (
    data: ICommAutomobilePolicyAuthorization,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-commauto-paf", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Authorization saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileAuthorizationAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileAuthorizationProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-paf?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobilePolicyAuthorization[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileAuthorizationSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileAuthorizationFailed(
            ERROR_MESSAGES.POLICY_NOT_FOUND
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileAuthorizationFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_COMM_AUTOMOBILE_AUTHORIZATION_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_AUTHORIZATION_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_AUTHORIZATION_INFORMATION_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_AUTHORIZATION_INFORMATION_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_AUTHORIZATION_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_AUTHORIZATION_LIST_FAILED";

export const fetchCommAutomobileAuthorizationListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_AUTHORIZATION_LIST_PROGRESS);

export const fetchCommAutomobileAuthorizationListSuccess = (
  data: ICommAutomobilePolicyAuthorization[]
) =>
  action(FETCH_COMM_AUTOMOBILE_AUTHORIZATION_INFORMATION_LIST_SUCCESS, {
    data,
  });
export const fetchCommAutomobileAuthorizationListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_AUTHORIZATION_LIST_FAILED);

export const fetchCommAutomobileAuthorizationListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileAuthorizationListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-paf?customer_policy_id=${customerPolicyId}`
      );
      const data: ICommAutomobilePolicyAuthorization[] = res.data.data;
      dispatch(fetchCommAutomobileAuthorizationListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileAuthorizationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadCommAutomobileAuthorizationPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-paf-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileAuthorizationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileAuthorizationPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-paf-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_auto_paf_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileAuthorizationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_AUTHORIZATION =
  "CLEAR_COMM_AUTOMOBILE_AUTHORIZATION";

export const clearCommAutomobileAuthorization = () =>
  action(CLEAR_COMM_AUTOMOBILE_AUTHORIZATION);

export const CLEAR_COMM_AUTOMOBILE_AUTHORIZATION_STATE =
  "CLEAR_COMM_AUTOMOBILE_AUTHORIZATION_STATE";

export const clearAutomobileAuthorizationState = () =>
  action(CLEAR_COMM_AUTOMOBILE_AUTHORIZATION_STATE);
