import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { api } from "../../../api/api";
import { IBusinessPolicyFineArt } from "./businessPolicyFineArt.types";
import { ERROR_MESSAGES } from "../../../constants/enums";

export const FETCH_BUSINESS_POLICY_FINE_ART_LIST_PROGRESS =
  "FETCH_BUSINESS_POLICY_FINE_ART_LIST_PROGRESS";
export const FETCH_BUSINESS_POLICY_FINE_ART_LIST_SUCCESS =
  "FETCH_BUSINESS_POLICY_FINE_ART_LIST_SUCCESS";
export const FETCH_BUSINESS_POLICY_FINE_ART_LIST_FAILED =
  "FETCH_BUSINESS_POLICY_FINE_ART_LIST_FAILED";

export const fetchBusinessPolicyFineArtListProgress = () =>
  action(FETCH_BUSINESS_POLICY_FINE_ART_LIST_PROGRESS);
export const fetchBusinessPolicyFineArtListSuccess = (
  data: IBusinessPolicyFineArt[]
) => action(FETCH_BUSINESS_POLICY_FINE_ART_LIST_SUCCESS, { data });
export const fetchBusinessPolicyFineArtListFailed = () =>
  action(FETCH_BUSINESS_POLICY_FINE_ART_LIST_FAILED);

export const fetchBusinessPolicyFineArtListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyFineArtListProgress());
      const res = await api.get(
        `/policy/get-policy-business-fine-art?customer_policy_id=${customerPolicyId}`
      );
      const data: IBusinessPolicyFineArt[] = res.data.data;
      dispatch(fetchBusinessPolicyFineArtListSuccess(data));
    } catch (err: any) {
      dispatch(fetchBusinessPolicyFineArtListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addBusinesPolicyFineArtAsync =
  (
    data: IBusinessPolicyFineArt,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      //dispatch(fetchBusinessPolicyRiskProgress());
      await api.post(`/policy/edit-policy-business-fine-art`, {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Fine art saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_BUSINESS_POLICY_FINE_ART_PROGRESS =
  "FETCH_BUSINESS_POLICY_FINE_ART_PROGRESS";
export const FETCH_BUSINESS_POLICY_FINE_ART_SUCCESS =
  "FETCH_BUSINESS_POLICY_FINE_ART_SUCCESS";
export const FETCH_BUSINESS_POLICY_FINE_ART_FAILED =
  "FETCH_BUSINESS_POLICY_FINE_ART_FAILED";

export const fetchBusinessPolicyFineArtProgress = () =>
  action(FETCH_BUSINESS_POLICY_FINE_ART_PROGRESS);
export const fetchBusinessPolicyFineArtSuccess = (
  data: IBusinessPolicyFineArt
) => action(FETCH_BUSINESS_POLICY_FINE_ART_SUCCESS, { data });
export const fetchBusinessPolicyFineArtFailed = (errorMessage: string) =>
  action(FETCH_BUSINESS_POLICY_FINE_ART_FAILED, { errorMessage });

export const fetchBusinessPolicyFineArtAsync =
  (
    customerPolicyId: number,
    incrementNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyFineArtProgress());
      const res = await api.get(
        `/policy/get-policy-business-fine-art?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incrementNumber}`
      );
      const data: IBusinessPolicyFineArt[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBusinessPolicyFineArtSuccess(data[0]));
      } else {
        dispatch(
          fetchBusinessPolicyFineArtFailed(
            "Oops! We couldn't find any records associated with your fine art number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchBusinessPolicyFineArtFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_BUSINES_POLICY_FINE_ART_STATE =
  "CLEAR_BUSINES_POLICY_FINE_ART_STATE";
export const CLEAR_BUSINES_POLICY_FINE_ART = "CLEAR_BUSINES_POLICY_FINE_ART";

export const clearBusinessFineArtState = () =>
  action(CLEAR_BUSINES_POLICY_FINE_ART_STATE);

export const clearBusinessToolFloater = () =>
  action(CLEAR_BUSINES_POLICY_FINE_ART);
