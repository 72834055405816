import { useSelector } from "react-redux";
import { IStoreState } from "../redux/initialStoreState";


export const useCustomerFullInfo = () => {
  const customerFullDetails = useSelector(
    (storeState: IStoreState) => storeState.customer.personalInformation.data
  );
  return customerFullDetails;
};

export const useCustomerPropspect = () => {
  return useSelector(
    (storeState: IStoreState) => storeState.customer.customerProspect
  );
};
