import produce from "immer";
import { LoadState } from "../../../constants/enums";
import { IStoreState } from "../../initialStoreState";
import { initialHabitationalBinderCsio } from "./habitationalBinderCsio.types";
import { HabitationalBinderCsioActions } from ".";
import { CLEAR_HABITATIONAL_CSIO_BINDER, CLEAR_HABITATIONAL_CSIO_BINDER_STATE, FETCH_HABITATIONAL_CSIO_BINDER_FAILED, FETCH_HABITATIONAL_CSIO_BINDER_LIST_FAILED, FETCH_HABITATIONAL_CSIO_BINDER_LIST_PROGRESS, FETCH_HABITATIONAL_CSIO_BINDER_LIST_SUCCESS, FETCH_HABITATIONAL_CSIO_BINDER_PROGRESS, FETCH_HABITATIONAL_CSIO_BINDER_SUCCESS } from "./habitationalBinderCsioActions";

export const habitationalBinderCsioReducer = (
  state: IStoreState["habitational"]["binderCsio"] = initialHabitationalBinderCsio,
  action: HabitationalBinderCsioActions
) => {
  switch (action.type) {
    case FETCH_HABITATIONAL_CSIO_BINDER_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_CSIO_BINDER_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_CSIO_BINDER_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.data = [];
      });
      return newState;
    }

    case FETCH_HABITATIONAL_CSIO_BINDER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.CsioLoading = LoadState.InProgress;
        draftState.Csio = initialHabitationalBinderCsio["Csio"];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_CSIO_BINDER_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.CsioLoading = LoadState.Loaded;
        draftState.Csio = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_CSIO_BINDER_FAILED: {
      const {errorMessage} = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.CsioLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
  
    case CLEAR_HABITATIONAL_CSIO_BINDER: {
      const newState = produce(state, (draftState) => {
        draftState.Csio = initialHabitationalBinderCsio["Csio"];
        draftState.CsioLoading = LoadState.NotLoaded;
      });
      return newState;
    }
    case CLEAR_HABITATIONAL_CSIO_BINDER_STATE: {
      return initialHabitationalBinderCsio;
    }
    default: {
      return state;
    }
  }
};