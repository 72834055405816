import React, { createContext, useContext } from "react";
import { IBreadcrumbProps } from "../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { Breadcrumb } from "../../layout/fullLayout/breadcrumb/Breadcrumb";

export interface IBreadcrumbContext {
  addBreadcrumb: (data: IBreadcrumbProps) => void;
  removeBreadcrumb: () => void;
}

const BreadcrumbContext = createContext<IBreadcrumbContext>({
  addBreadcrumb: () => {},
  removeBreadcrumb: () => {},
});

export const useBreadcrumbContext = () => useContext(BreadcrumbContext);

export const BreadcrumbProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const [state, setState] = React.useState<IBreadcrumbProps | null>(null);

  const handleAddBreadcrub = (data: IBreadcrumbProps) => {
    setState(data);
  };

  const handleRemove = () => {
    setState(null);
  };

  React.useEffect(() => {
    return () => {
      setState(null);
    };
  }, []);

  return (
    <BreadcrumbContext.Provider
      value={{
        addBreadcrumb: handleAddBreadcrub,
        removeBreadcrumb: handleRemove,
      }}
    >
      {state && <Breadcrumb {...state} />}
      {props.children}
    </BreadcrumbContext.Provider>
  );
};
