import React from "react";
import { useFormik } from "formik";
import { Dialog } from "../../../../components/Dialogs/Dialog";
import {
  useBusinessTrackerCustomerPolicies,
  useDispatchWrapper,
} from "../../../../hooks";
import { Grid, MenuItem, SelectChangeEvent } from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../components/formsComponents";
import { CustomDatePicker } from "../../../../components/formsComponents/CustomDatePicker";
import { useNavigate } from "react-router-dom";
import { IOldBusinessTrackerDialogProps } from "./OldBusinessTrackerDialog.types";
import { upsertOldBusinessTrackerAysnc } from "../../../../redux/tasks/tasksActions";
import { renderDropdownValue } from "../../../../helpers";
import { UsersAutoSearchByRole } from "../../../../components/AutoCompleteSearches/UsersAutoSearchByRole";
import { BranchCodeAutoSearch } from "../../../../components/AutoCompleteSearches/BranchCodeAutoSearch";
import { CustomerAutoSearch } from "../../../../components/AutoCompleteSearches/CustomerAutoSearch";
import { InsurerCodeAutoSearch } from "../../../../components/AutoCompleteSearches";
import {
  TabSelectOptions,
  related_to,
  task_priority,
} from "../../../../constants/constants";
import moment from "moment";

const INITIAL_STATE = {
  task_code: "",
  customer_policy_id: null,
  task_name: null,
  task_created_date: null,
  task_due_date: null,
  task_completed_date: null,
  task_type: null,
  task_sub_type: null,
  task_status: null,
  task_specify: null,
  task_details: null,
  task_priority: null,
  task_related_to: null,
  task_related_to_id: null,
  assigned_to_id: null,
  notes: null,
  customer_code: null,
  customer_name: null,
  policy_number: null,
  line_of_business: null,
  branch: null,
  broker_code: null,
  insurer: null,
  producer: null,
  csr: null,
  effective_date_of_change: null,
  status: "ACTIVE",
};

export const OldBusinessTrackerDialog: React.FC<
  IOldBusinessTrackerDialogProps
> = (props) => {
  const { open, onClose, data, refreshList } = props;
  const dispatch = useDispatchWrapper();

  const [loading, setLoading] = React.useState<boolean>(false);

  const { values, handleChange, handleSubmit, setValues, setFieldValue } =
    useFormik({
      initialValues: data,
      validate: (values) => {
        const errors: any = {};
        return errors;
      },
      onSubmit: async (values) => {
        setLoading(true);
        dispatch(
          upsertOldBusinessTrackerAysnc(
            {
              ...values,
              customer_policy_id: values.customer_policy_id || null,
            },
            (isSuccess) => {
              if (isSuccess) {
                refreshList();
              }
              setLoading(false);
            }
          )
        );
      },
    });

  const {
    policies,
    fetchPolicies,
    getPolicyFullDetails,
    clearPolicies,
    getCustomerDetails,
  } = useBusinessTrackerCustomerPolicies();

  const handlePolicyChange = (event: SelectChangeEvent<unknown>) => {
    const policy = getPolicyFullDetails(event.target.value as number);
    if (policy) {
      setValues({
        ...values,
        policy_number: policy.policy_number,
        customer_policy_id: policy.customer_policy_id.toString(),
        line_of_business: policy.line_of_business,
        producer: policy.producer_one,
        csr: policy.csr,
        branch: policy.branch_code,
        broker_code: policy.broker_code,
        insurer: policy.insurer,
      });
    }
  };

  return (
    <Dialog
      open={open}
      title="Edit Business Tracker"
      onClose={onClose}
      size={"lg"}
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      actions={[
        {
          type: "submit",
          label: "Save",
          variant: "contained",
          disabled: loading,
        },
      ]}
    >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* <Grid item xs={12} md={4}>
            <CustomFormLabel>Task Code</CustomFormLabel>
            <CustomTextField
              name="task_code"
              variant="outlined"
              type="text"
              fullWidth
              value={values.task_code}
              onChange={handleChange}
              // disabled
            />
          </Grid> */}

          <Grid item xs={12} md={4}>
            <CustomFormLabel>Task Name</CustomFormLabel>
            <CustomTextField
              name="task_name"
              variant="outlined"
              type="text"
              fullWidth
              value={values.task_name}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomFormLabel>Task Created Date</CustomFormLabel>
            <CustomDatePicker
              fullWidth
              // disabled
              value={values.task_created_date || ""}
              onChange={(newValue) => {
                setFieldValue("task_created_date", newValue);
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomFormLabel>Task Due Date</CustomFormLabel>
            <CustomDatePicker
              fullWidth
              value={values.task_due_date || ""}
              onChange={(newValue) => {
                setFieldValue("task_due_date", newValue);
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomFormLabel>Task Completed Date</CustomFormLabel>
            <CustomDatePicker
              fullWidth
              disabled
              value={values.task_completed_date || ""}
              onChange={(newValue) => {
                setFieldValue("task_completed_date", newValue);
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomFormLabel>Task Type</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="task_type"
              fullWidth
              value={values.task_type}
              onChange={handleChange}
              displayEmpty
              renderValue={renderDropdownValue(`Select One`)}
            >
              {Object.keys(TabSelectOptions).map((tab) => {
                return <MenuItem value={tab}>{tab}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomFormLabel>Task Sub-Type</CustomFormLabel>
            <ControlledCustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="task_sub_type"
              fullWidth
              value={values.task_sub_type}
              onChange={handleChange}
              displayEmpty
              placeholder="Select one"
              options={(
                TabSelectOptions[values.task_type as "Remarket (Upload)"] || []
              ).map((STab: string) => {
                return { label: STab, value: STab };
              })}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomFormLabel>Task Status</CustomFormLabel>
            <CustomTextField
              name="task_status"
              variant="outlined"
              type="text"
              fullWidth
              value={values.task_status}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomFormLabel>Task Specify</CustomFormLabel>
            <CustomTextField
              name="task_specify"
              variant="outlined"
              type="text"
              fullWidth
              value={values.task_specify}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomFormLabel>Task Details</CustomFormLabel>
            <CustomTextField
              name="task_details"
              variant="outlined"
              type="text"
              fullWidth
              value={values.task_details}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomFormLabel>Task Priority</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="task_priority"
              fullWidth
              value={values.task_priority}
              onChange={handleChange}
              displayEmpty
              renderValue={renderDropdownValue(`Select One`)}
            >
              {task_priority.map((tab) => {
                return <MenuItem value={tab}>{tab}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomFormLabel>Task Related To</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="task_related_to"
              fullWidth
              value={values.task_related_to}
              onChange={handleChange}
              displayEmpty
              renderValue={renderDropdownValue(`Select One`)}
            >
              {related_to.map((tab) => {
                return <MenuItem value={tab}>{tab}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomFormLabel>Task Related to Id</CustomFormLabel>
            <CustomTextField
              name="task_related_to_id"
              variant="outlined"
              type="text"
              fullWidth
              value={values.task_related_to_id}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <UsersAutoSearchByRole
              label="Created By"
              value={values.assigned_to_id}
              onSelect={(user) => {
                setFieldValue("assigned_to_id", user.user_fact_id);
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomFormLabel>Notes</CustomFormLabel>
            <CustomTextField
              name="notes"
              variant="outlined"
              type="text"
              fullWidth
              value={values.notes}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomerAutoSearch
              label="Customer Name"
              value={values.customer_name}
              onSelect={(newValue) => {
                setFieldValue("customer_name", newValue.customer_id);
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomFormLabel>Customer Code</CustomFormLabel>
            <CustomTextField
              name="customer_code"
              variant="outlined"
              type="text"
              fullWidth
              value={values.customer_code}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomFormLabel>Task Name</CustomFormLabel>
            <CustomTextField
              name="task_name"
              variant="outlined"
              type="text"
              fullWidth
              value={values.task_name}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomFormLabel>Policy Number</CustomFormLabel>
            <ControlledCustomSelect
              variant="outlined"
              size="small"
              type="string"
              fullWidth
              displayEmpty
              name="customer_policy_id"
              value={Number(values.customer_policy_id)}
              onChange={handlePolicyChange}
              placeholder="Select One"
              options={policies.map((item) => {
                return {
                  label: item.policy_number,
                  value: item.customer_policy_id,
                };
              })}
            />{" "}
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomFormLabel>Line of Business</CustomFormLabel>
            <CustomTextField
              name="line_of_business"
              variant="outlined"
              type="text"
              fullWidth
              value={values.line_of_business}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomFormLabel>Branch</CustomFormLabel>
            <BranchCodeAutoSearch
              value={values.branch}
              onSelect={(value) => {
                setFieldValue("branch", value.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomFormLabel>Broker Code</CustomFormLabel>
            <CustomTextField
              name="broker_code"
              variant="outlined"
              type="text"
              fullWidth
              value={values.broker_code}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomFormLabel>Insurer</CustomFormLabel>
            <InsurerCodeAutoSearch
              value={values.insurer}
              onSelect={(value) => {
                setFieldValue("insurer", value.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <UsersAutoSearchByRole
              label="Producer"
              value={values.producer}
              selectionBy="user_fact_id"
              onSelect={(user) => {
                setFieldValue("producer", user.user_fact_id);
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <UsersAutoSearchByRole
              label="CSR"
              value={values.csr}
              selectionBy="user_fact_id"
              onSelect={(user) => {
                setFieldValue("csr", user.user_fact_id);
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomFormLabel>Effective Date of Change</CustomFormLabel>
            <CustomDatePicker
              fullWidth
              value={values.effective_date_of_change || ""}
              onChange={(newValue) => {
                setFieldValue("effective_date_of_change", newValue);
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomFormLabel>Status</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="status"
              fullWidth
              value={values.status}
              onChange={handleChange}
              displayEmpty
              renderValue={renderDropdownValue(`Select One`)}
            >
              {["Active", "Inactive"].map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};
