import { Grid } from "@mui/material";
import { StandadCard } from "../../../../components/Cards";
import { Dialog } from "../../../../components/Dialogs/Dialog";
import { StandardCellList } from "../../../../components/Standards";
import { formatDate, formatDateWithTime } from "../../../../helpers";
import { IViewOldBusinessTrackerState } from "./ViewOldBusinessTracker.types";

interface IViewOldBusinessTrackerProps {
  open: boolean;
  onClose: () => void;
  data: IViewOldBusinessTrackerState;
}

export const ViewOldBusinessTracker: React.FC<IViewOldBusinessTrackerProps> = (
  props
) => {
  const { open, data, onClose } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      size="lg"
      title="View Old Business Tracker"
    >
      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <StandardCellList
            // heading="About Tasks"
            colSize={3}
            list={[
              {
                heading: "Name",
                data: {
                  type: "text",
                  value: data.task_name,
                },
              },
              {
                heading: "Type",
                data: {
                  type: "text",
                  value: data.task_type,
                },
              },
              {
                heading: "Sub Type",
                data: {
                  type: "text",
                  value: data.task_sub_type,
                },
              },
              {
                heading: "Specify",
                data: {
                  type: "text",
                  value: data.task_specify,
                },
              },
              {
                heading: "Completed Date",
                data: {
                  type: "text",
                  value: formatDate(data.task_completed_date || ""),
                },
              },
              {
                heading: "Created date",
                data: {
                  type: "text",
                  value: formatDate(data.task_created_date || ""),
                },
              },
              {
                heading: "Due Date",
                data: {
                  type: "text",
                  value: formatDate(data.task_due_date || ""),
                },
              },

              {
                heading: "Priority",
                data: { type: "text", value: data.task_priority },
              },
              {
                heading: "Related To",
                data: {
                  type: "text",
                  value: data.task_related_to,
                },
              },
              {
                heading: "Customer Policy Id",
                data: {
                  type: "text",
                  value: data.customer_policy_id,
                },
              },
              {
                heading: "Customer Name",
                data: {
                  type: "text",
                  value: data.customer_name,
                },
              },
              {
                heading: "Customer Code",
                data: {
                  type: "text",
                  value: data.customer_code,
                },
              },
              {
                heading: "CSR",
                data: {
                  type: "text",
                  value: data.csr,
                },
              },
              {
                heading: "Producer",
                data: {
                  type: "text",
                  value: data.producer,
                },
              },
              {
                heading: "Branch",
                data: {
                  type: "text",
                  value: data.branch,
                },
              },
              {
                heading: "Insurer",
                data: {
                  type: "text",
                  value: data.insurer,
                },
              },
              {
                heading: "Broker Code",
                data: {
                  type: "text",
                  value: data.broker_code,
                },
              },
              {
                heading: "Line of Business",
                data: {
                  type: "text",
                  value: data.line_of_business,
                },
              },
              {
                heading: "Policy Number",
                data: {
                  type: "text",
                  value: data.policy_number,
                },
              },
              {
                heading: "Date Modified",
                data: {
                  type: "text",
                  value: formatDateWithTime(data.insert_ts || ""),
                },
              },
              {
                heading: "Status",
                data: {
                  type: "text",
                  value: data.status,
                },
              },
              {
                heading: "Task Status",
                data: {
                  type: "text",
                  value: data.task_status,
                },
              },
            ]}
          />
        </Grid>

        <Grid item xs={12} lg={12}>
          <StandardCellList
            heading="Details"
            colSize={12}
            list={[
              {
                heading: "",
                data: {
                  type: "text",
                  value: data.task_details,
                },
              },
            ]}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};
