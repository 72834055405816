/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Button, Link, MenuItem } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { LoadState } from "../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../hooks";
import { fetchAutomobileLiabilitySlipsListAsync } from "../../../../../../redux/automobileCertificates/automobileCertificatesActions";
import { IAutomobileLiabilitySlips } from "../../../../../../redux/automobileCertificates/automobileCertificates.types";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { ICommAutomobileLiabilitySlips } from "../../../../../../redux/commAutoPolicy/commAutoCertificates/commAutoCertificates.types";
import { downloadCommAutomobileLiabilitySlipsPDFAsync, fetchCommAutomobileLiabilitySlipsListAsync, previewCommAutomobileLiabilitySlipsPDFAsync } from "../../../../../../redux/commAutoPolicy/commAutoCertificates/commAutoCertificatesActions";
import { CustomSelect } from "../../../../../../components/formsComponents";
import { renderDropdownValue } from "../../../../../../helpers";
import { Add, NotificationsActive, ThumbUpAlt } from "@mui/icons-material";
import { CustomChip } from "../../../../../../components/CustomChip";

export const CommAutoLiabilitySlipsList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { list, loading } = useSelector(
    (storeState: IStoreState) =>
      storeState.commAuto.certificates.liabilitySlips
  );
  const [downloading, setDownloading] = React.useState(false);
  const dispatch = useDispatchWrapper();

  const [selectedSlips, setselectedSlips] = React.useState<{
    isAllSelected: boolean;
    certificate: ICommAutomobileLiabilitySlips[];
  }>({
    isAllSelected: false,
    certificate: [],
  });

  const menuItems =
    selectedSlips.certificate.length > 0 || selectedSlips.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const liabilitySlipTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    selectionMode: "multiple",
    uniqueRowKeyName: "incremental_number",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          ...menuItems,
        ]
      },
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => {
                  navigate(
                    `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/liability-slips`
                  );
                }}
              >
                Create
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
        onRowCellRender: (value, row: ICommAutomobileLiabilitySlips) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/liability-slips/${row.incremental_number}`
                );
              }}
            >
              {row.incremental_number}
            </Link>
          );
        },
      },

      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobileLiabilitySlips) => {
          if (row.status === "ACTIVE") {
            return (
              <CustomChip
                color="success"
                icon={<ThumbUpAlt />}
                content={"Active"}
                size="small"
              />
            );
          } else {
            return (
              <CustomChip
                color="error"
                icon={<NotificationsActive />}
                content={"Cancel"}
                size="small"
              />
            );
          }
        },
      },

      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        exportCellWidth: 25,
        renderType: RenderType.CHIP_WARNING,
      },
      {
        key: "insured",
        headerName: "Insured",
        fieldName: "insured",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
      },
      {
        key: "insurer_name",
        headerName: "Insurer Name",
        fieldName: "insurer_name",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "broker_name",
        headerName: "Broker Name",
        fieldName: "broker_name",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },

      {
        key: "effective_date",
        headerName: "Effective Date",
        fieldName: "effective_date",
        exportCellWidth: 25,
        renderType: RenderType.DATE,
      },
      {
        key: "expiry_date",
        headerName: "Expiry Date",
        fieldName: "expiry_date",
        exportCellWidth: 25,
        renderType: RenderType.DATE,
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobileLiabilitySlips) => {
          return (
            <StandardTableActions
            downloadLoading={downloading}
              onDownloadPreview={() => {
                dispatch(
                  previewCommAutomobileLiabilitySlipsPDFAsync(
                    customerPolicyId,
                    row.incremental_number as number,
                    () => {}
                  )
                );
              }}
              onDownLoadClick={() => {
                if (row.incremental_number) {
                  setDownloading(true);
                  dispatch(
                    downloadCommAutomobileLiabilitySlipsPDFAsync(
                      customerPolicyId,
                      row.incremental_number,
                      () => {
                        setDownloading(false);
                      }
                    )
                  );
                }
              }}
              onEditClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/liability-slips/${row.incremental_number}`
                );
              }}
              onDuplicateClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/liability-slips-duplicate/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
    ],
    items: list,
    onSelection: (isAllSelected: boolean, rows: ICommAutomobileLiabilitySlips[]) => {
      setselectedSlips({ isAllSelected: isAllSelected, certificate: rows });
    },
  };

  React.useEffect(() => {
    dispatch(fetchCommAutomobileLiabilitySlipsListAsync(customerPolicyId));
  }, [customerPolicyId]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...liabilitySlipTableProps} />
      </Box>
    </>
  );
};
