import { action } from "typesafe-actions";
import {
  IHabitationalAdditionalInsured,
  IHabitationalAttachment,
  IHabitationalCustomerInfo,
  IHabitationalPolicyAdmin,
  IHabitationalPolicyAdminManager,
  IHabitationalPremium,
  IHabitationalPriorPolicy,
  initialHabitationalDetails,
} from "./habitationalBasicDetails.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { ERROR_MESSAGES, LoadState } from "../../../constants/enums";
import moment from "moment";
import { uploadFile } from "../../../helpers";
import { makeApiCall } from "../../../helpers/postRequest";

export const FETCH_HABITATIONAL_CUSTOMER_INFO_PROGRESS =
  "FETCH_HABITATIONAL_CUSTOMER_INFO_PROGRESS";
export const FETCH_HABITATIONAL_CUSTOMER_INFO_SUCCESS =
  "FETCH_HABITATIONAL_CUSTOMER_INFO_SUCCESS";
export const FETCH_HABITATIONAL_CUSTOMER_FAILED =
  "FETCH_HABITATIONAL_CUSTOMER_FAILED";

export const FETCH_HABITATIONAL_PRIOR_POLICY_PROGRESS =
  "FETCH_HABITATIONAL_PRIOR_POLICY_PROGRESS";
export const FETCH_HABITATIONAL_PRIOR_POLICY_SUCCESS =
  "FETCH_HABITATIONAL_PRIOR_POLICY_SUCCESS";

export const FETCH_HABITATIONAL_POLICY_ADMIN_PROGRESS =
  "FETCH_HABITATIONAL_POLICY_ADMIN_PROGRESS";
export const FETCH_HABITATIONAL_POLICY_ADMIN_SUCCESS =
  "FETCH_HABITATIONAL_POLICY_ADMIN_SUCCESS";

export const FETCH_HABITATIONAL_POLICY_ADMIN_MANAGER_PROGRESS =
  "FETCH_HABITATIONAL_POLICY_ADMIN_MANAGER_PROGRESS";
export const FETCH_HABITATIONAL_POLICY_ADMIN_MANAGER_SUCCESS =
  "FETCH_HABITATIONAL_POLICY_ADMIN_MANAGER_SUCCESS";
export const FETCH_HABITATIONAL_POLICY_ADMIN_MANAGER_FAILED =
  "FETCH_HABITATIONAL_POLICY_ADMIN_MANAGER_FAILED";

export const FETCH_HABITATIONAL_CLAIM_PROGRESS =
  "FETCH_HABITATIONAL_CLAIM_PROGRESS";
export const FETCH_HABITATIONAL_CLAIM_SUCCESS =
  "FETCH_HABITATIONAL_CLAIM_SUCCESS";

export const FETCH_HABITATIONAL_PREMIUM_PROGRESS =
  "FETCH_HABITATIONAL_PREMIUM_PROGRESS";
export const FETCH_HABITATIONAL_PREMIUM_SUCCESS =
  "FETCH_HABITATIONAL_PREMIUM_SUCCESS";

export const ADD_HABITATIONAL_POLICY_ID = "ADD_HABITATIONAL_POLICY_ID";
export const POLICY_HABITATIONAL_BASIC_DETAILS_NOT_FOUND =
  "POLICY_HABITATIONAL_BASIC_DETAILS_NOT_FOUND";
export const CLEAR_HABITATIONAL_BASIC_DETAILS_STATE =
  "CLEAR_HABITATIONAL_BASIC_DETAILS_STATE";

export const addHabitationalPolicyId = () => action(ADD_HABITATIONAL_POLICY_ID);
export const basicHabitationalPolicyDteailsNotFound = () =>
  action(POLICY_HABITATIONAL_BASIC_DETAILS_NOT_FOUND);
export const clearHabitationalBasicPolicyState = () =>
  action(CLEAR_HABITATIONAL_BASIC_DETAILS_STATE);
export const fetchHabitationalCustomerInfoProgress = () =>
  action(FETCH_HABITATIONAL_CUSTOMER_INFO_PROGRESS);

export const addHabitationalCustomerInfo = (
  customerInfo: IHabitationalCustomerInfo,
  customerPolicyId: number,
  isNewPolicy: boolean
) =>
  action(FETCH_HABITATIONAL_CUSTOMER_INFO_SUCCESS, {
    customerInfo,
    customerPolicyId,
    isNewPolicy,
  });
export const addHabitationalCustomerInfoAsync =
  (
    data: IHabitationalCustomerInfo,
    initialData: IHabitationalCustomerInfo,
    isUpdate: boolean,
    callBack: (isSuccess: boolean, policyId?: number) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const response = await makeApiCall({
        url:     "/policy/create-habitational-customer-info",
        method: "POST",
        automation: {
          isUpdate: isUpdate,
          primaryFieldName: "customer_policy_id",
        }
      }, initialData, {
        ...data,
        policy_id: data.sub_type,
        effective_time: data.effective_time,
      })
    
      const customerPolicyId = response.data.data.customer_policy_id;
      dispatch(
        addHabitationalCustomerInfo(response.data.data, customerPolicyId, true)
      );
      dispatch(
        showMessage({
          type: "success",
          message: "Customer information saved successfully!",
          displayAs: "snackbar",
        })
      );
      callBack(true, customerPolicyId);
    } catch (err: any) {
      callBack(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchHabitationalCustomerInfoAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().habitational.basicDetails.customerInfo.loading ===
      LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchHabitationalCustomerInfoProgress());
        const res = await api.get(
          `/policy/get-habitational-customer-info?customer_policy_id=${customerPolicyId}`
        );
        const data: IHabitationalCustomerInfo[] = res.data.data;
        if (data.length > 0) {
          dispatch(
            addHabitationalCustomerInfo(
              {
                ...data[0],
              },
              customerPolicyId,
              false
            )
          );
        } else {
          dispatch(basicHabitationalPolicyDteailsNotFound());
        }
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

// Additional insured
export const FETCH_HABITATIONAL_ADDITIONAL_INSURED_LIST_PROGRESS =
  "FETCH_HABITATIONAL_ADDITIONAL_INSURED_LIST_PROGRESS";
export const FETCH_HABITATIONAL_ADDITIONAL_INSURED_LIST_SUCCESS =
  "FETCH_HABITATIONAL_ADDITIONAL_INSURED_LIST_SUCCESS";
export const FETCH_HABITATIONAL_ADDITIONAL_INSURED_LIST_FAILED =
  "FETCH_HABITATIONAL_ADDITIONAL_INSURED_LIST_FAILED";

export const fetchHabitationalAdditionalInsuredListProgress = () =>
  action(FETCH_HABITATIONAL_ADDITIONAL_INSURED_LIST_PROGRESS);

export const fetchHabitationalAdditionalInsuredListSuccess = (data: IHabitationalAdditionalInsured[]) =>
  action(FETCH_HABITATIONAL_ADDITIONAL_INSURED_LIST_SUCCESS, { data });

export const fetchHabitationalAdditionalInsuredListFailed = () =>
  action(FETCH_HABITATIONAL_ADDITIONAL_INSURED_LIST_FAILED);

export const fetchHabitationalAdditionalInsuredListAsync =
  (customerpolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalAdditionalInsuredListProgress());
      const res = await api.get(
        `/policy/get-habitational-additional-insured?customer_policy_id=${customerpolicyId}`
      );
      const data: IHabitationalAdditionalInsured[] = res.data.data;
      dispatch(fetchHabitationalAdditionalInsuredListSuccess(data));
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
  export const upsertHabitationalAdditionalInsuredAsync =
  (
    data: IHabitationalAdditionalInsured,
    onCallback: (isSucces?: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-habitational-additional-insured", {
        ...data,
        status: "ACTIVE",
      });

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Insured saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );

      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchHabitationalPriorPolicyProgress = () =>
  action(FETCH_HABITATIONAL_PRIOR_POLICY_PROGRESS);
export const addHabitationalPriorPolicy = (
  priorPolicy: IHabitationalPriorPolicy
) => action(FETCH_HABITATIONAL_PRIOR_POLICY_SUCCESS, { priorPolicy });
export const addHabitationalPriorPolicyInfoAsync =
  (
    data: IHabitationalPriorPolicy,
    callBack: (isSuccess?: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/create-habitational-prior-policies", {
        ...data,
        status: "ACTIVE",
      });
      dispatch(addHabitationalPriorPolicy(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Prior policy saved successfully!",
          displayAs: "snackbar",
        })
      );
      callBack(true);
    } catch (err: any) {
      callBack(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
export const fetchHabitationalPriorPolicyAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().habitational.basicDetails.priorPolicy.loading ===
      LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchHabitationalPriorPolicyProgress());
        const res = await api.get(
          `/policy/get-habitational-prior-policies?customer_policy_id=${customerPolicyId}`
        );
        const data: IHabitationalPriorPolicy[] = res.data.data;
        if (data.length > 0) {
          dispatch(addHabitationalPriorPolicy(data[0]));
        } else {
          dispatch(
            addHabitationalPriorPolicy(
              initialHabitationalDetails["priorPolicy"]["data"]
            )
          );
        }
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const fetchHabitationalPremiumProgress = () =>
  action(FETCH_HABITATIONAL_PREMIUM_PROGRESS);
export const addHabitationalPremium = (premium: IHabitationalPremium) =>
  action(FETCH_HABITATIONAL_PREMIUM_SUCCESS, { premium });

export const addHabitationalPremiumAsync =
  (
    data: IHabitationalPremium,
    onCallback: (isSuccess: boolean,error?: any) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/create-habitational-premium", {
        ...data,
      });
      dispatch(addHabitationalPremium(data));
     
      onCallback(true);
    } catch (err: any) {
      onCallback(false, err)
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
  
export const fetchHabitationalPremiumAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().habitational.basicDetails.premium.loading === LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchHabitationalPremiumProgress());
        const res = await api.get(
          `/policy/get-habitational-premium?customer_policy_id=${customerPolicyId}`
        );
        const data: IHabitationalPremium[] = res.data.data;
        if (data.length > 0) {
          dispatch(addHabitationalPremium(data[0]));
        } else {
          dispatch(
            addHabitationalPremium(
              initialHabitationalDetails["premium"]["data"]
            )
          );
        }
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

//policy admin
export const fetchHabitationalPolicyAdminProgress = () =>
  action(FETCH_HABITATIONAL_POLICY_ADMIN_PROGRESS);
export const addHabitationalPolicyAdmin = (data: IHabitationalPolicyAdmin) =>
  action(FETCH_HABITATIONAL_POLICY_ADMIN_SUCCESS, { data });
export const addHabitationalPolicyAdminsync =
  (
    data: IHabitationalPolicyAdmin,
    callBack: (isSuccess: boolean,error?: any) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/create-habitational-policy-admin", {
        ...data,
      });
      dispatch(addHabitationalPolicyAdmin(data));
      
      callBack(true);
    } catch (err: any) {
      callBack(false,err);
     
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
export const fetchHabitationalPolicyAdminAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().habitational.basicDetails.policyAdmin.loading ===
      LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchHabitationalPolicyAdminProgress());
        const res = await api.get(
          `/policy/get-habitational-policy-admin?customer_policy_id=${customerPolicyId}`
        );
        const data: IHabitationalPolicyAdmin[] = res.data.data;
        if (data.length > 0) {
          dispatch(addHabitationalPolicyAdmin(data[0]));
        } else {
          dispatch(
            addHabitationalPolicyAdmin(
              initialHabitationalDetails["policyAdmin"]["data"]
            )
          );
        }
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
//policy admin manager
export const fetchHabitationalPolicyAdminManagerProgress = () =>
  action(FETCH_HABITATIONAL_POLICY_ADMIN_MANAGER_PROGRESS);
export const fetchHabitationalPolicyAdminManagerSuccess = (
  data: IHabitationalPolicyAdminManager
) => action(FETCH_HABITATIONAL_POLICY_ADMIN_MANAGER_SUCCESS, { data });
export const fetchHabitationalPolicyAdminManagerFailed = (
  errorMessage: string
) => action(FETCH_HABITATIONAL_POLICY_ADMIN_MANAGER_FAILED, { errorMessage });

export const addHabitationalPolicyAdminManagerAsync =
  (
    data: IHabitationalPolicyAdminManager,
    callBack: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/create-habitational-policy-admin-manager", data);
      dispatch(
        showMessage({
          type: "success",
          message: "Policy admin manager saved successfully!",
          displayAs: "snackbar",
        })
      );
      callBack(true);
    } catch (err: any) {
      callBack(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
export const fetchHabitationalPolicyAdminManagerAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().habitational.basicDetails.policyAdminManager.loading ===
      LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchHabitationalPolicyAdminManagerProgress());
        const res = await api.get(
          `/policy/get-habitational-policy-admin-manager?customer_policy_id=${customerPolicyId}`
        );
        const data: IHabitationalPolicyAdminManager[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchHabitationalPolicyAdminManagerSuccess(data[0]));
        } else {
          dispatch(
            fetchHabitationalPolicyAdminManagerFailed(
              "Oops! We couldn't find any records associated with your fine art number at the moment. Please ensure that the provided information is accurate and try again."
            )
          );
        }
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_HABITATIONAL_ATTACHMENT_PROGRESS =
  "FETCH_HABITATIONAL_ATTACHMENT_PROGRESS";
export const FETCH_HABITATIONAL_ATTACHMENT_SUCCESS =
  "FETCH_HABITATIONAL_ATTACHMENT_SUCCESS";
export const FETCH_HABITATIONAL_ATTACHMENT_FAILED =
  "FETCH_HABITATIONAL_ATTACHMENT_FAILED";

export const fetchHabitationalAttachmentProgress = () =>
  action(FETCH_HABITATIONAL_ATTACHMENT_PROGRESS);
export const fetchHabitationalAttachmentSuccess = (
  data: IHabitationalAttachment
) => action(FETCH_HABITATIONAL_ATTACHMENT_SUCCESS, { data });
export const fetchHabitationalAttachmentFailed = (errorMessage: string) =>
  action(FETCH_HABITATIONAL_ATTACHMENT_FAILED, { errorMessage });

export const addHabitationalAttachmentAsync =
  (
    data: IHabitationalAttachment,
    customerID: number,
    file: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        customer_id: customerID,
        policy_type: "HABITATIONAL",
        customer_policy_id: data.customer_policy_id,
        page_name: "Habitational Attachment",
      };

      const path = await uploadFile(
        file,
        "POLICY",
        data.file_upload || "",
        asPayload
      );
      await api.post("/policy/edit-policy-habitational-attachments", {
        ...data,
        file_upload: path,
      });
      // const data = res.data.data;

      dispatch(fetchHabitationalAttachmentSuccess(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Attachment saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchHabitationalAttachmentAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().habitational.basicDetails.attachment.loading ===
      LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchHabitationalAttachmentProgress());
        const res = await api.get(
          `/policy/get-policy-habitational-attachments?customer_policy_id=${customerPolicyId}`
        );
        const data: IHabitationalAttachment[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchHabitationalAttachmentSuccess(data[0]));
        } else {
          dispatch(
            fetchHabitationalAttachmentFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
          );
        }
      }
    } catch (err: any) {
      dispatch(fetchHabitationalAttachmentFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_HABITATIONAL_ATTACHMENT_LIST_PROGRESS =
  "FETCH_HABITATIONAL_ATTACHMENT_LIST_PROGRESS";
export const FETCH_HABITATIONAL_ATTACHMENT_LIST_SUCCESS =
  "FETCH_HABITATIONAL_ATTACHMENT_LIST_SUCCESS";
export const FETCH_HABITATIONAL_ATTACHMENT_LIST_FAILED =
  "FETCH_HABITATIONAL_ATTACHMENT_LIST_FAILED";

export const fetchHabitationalAttachmentListProgress = () =>
  action(FETCH_HABITATIONAL_ATTACHMENT_LIST_PROGRESS);
export const fetchHabitationalAttachmentListSuccess = (
  data: IHabitationalAttachment[]
) => action(FETCH_HABITATIONAL_ATTACHMENT_LIST_SUCCESS, { data });
export const fetchHabitationalAttachmentListFailed = () =>
  action(FETCH_HABITATIONAL_ATTACHMENT_LIST_FAILED);

export const fetchHabitationalAttachmentListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalAttachmentListProgress());
      const res = await api.get(
        `/policy/get-policy-habitational-attachments?customer_policy_id=${customerPolicyId}`
      );
      const data: IHabitationalAttachment[] = res.data.data;
      dispatch(fetchHabitationalAttachmentListSuccess(data));
    } catch (err: any) {
      dispatch(fetchHabitationalAttachmentListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addHabitationalAttachmentListAsync =
  (
    data: IHabitationalAttachment,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(`/policy/edit-policy-habitational-attachments`, data);
      dispatch(
        showMessage({
          type: "success",
          message: "Attachment saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLAER_HABITATIONAL_POLICY_ATTACHMENT_STATE =
  "CLAER_HABITATIONAL_POLICY_ATTACHMENT_STATE";
export const clearHabitationalPolicyAttachment = () =>
  action(CLAER_HABITATIONAL_POLICY_ATTACHMENT_STATE);
