import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useDispatchWrapper } from "../../../../../../hooks";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { LoadState } from "../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { IHabitationalNonCsioBinder } from "../../../../../../redux/habitationalPolicy/habitationalNonBinderCsio/habitationalNonCsioBinder.types";
import { downloadHabitationalNonCsioBinderPDFAsync, fetchHabitationalNonCsioBinderListAsync, previewHabitationalNonCsioBinderPDFAsync } from "../../../../../../redux/habitationalPolicy/habitationalNonBinderCsio/habitationalNonCsioBinderActions";
import { Add } from "@mui/icons-material";

export const HabitationalNonCsioBinderList = () => {

  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerpolicyId = data1[1];
  const navigate = useNavigate();

  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.habitational.nonCsioBinder
  );
  const [downloading, setDownloading] = React.useState(false);
  const dispatch = useDispatchWrapper();



  const habitationalNonCsioBinderTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => {
                  navigate(
                    `/habitational-policy/${customerId}/policy-forms-tab/${customerpolicyId}/non-binder`
                  );
                }}
              >
                Create
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IHabitationalNonCsioBinder) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/habitational-policy/${customerId}/policy-forms-tab/${customerpolicyId}/non-binder/${row.incremental_number}`
                )
              }
            >
              {row.incremental_number}
            </Link>
          );
        },
      },

      {
        key: "applicant_full_name",
        headerName: "Applicant Name",
        fieldName: "applicant_full_name",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 30,
      },
     
      {
        key: "insurance_company",
        headerName: "Insurance Company",
        fieldName: "insurance_company",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },

      {
        key: "brokerage_full_name",
        headerName: "Broker Name",
        fieldName: "brokerage_full_name",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 20,
      },
      {
        key: "brokerage_contract_number",
        headerName: "Contract Name",
        fieldName: "brokerage_contract_number",
        renderType: RenderType.DATE,
        exportCellWidth: 20,
      },
      {
        key: "policy_effective_date",
        headerName: "Effective Date",
        fieldName: "policy_effective_date",
        renderType: RenderType.DATE,
        exportCellWidth: 20,
      },
      {
        key: "policy_expiry_date",
        headerName: "Expiry Date",
        fieldName: "policy_expiry_date",
        renderType: RenderType.DATE,
        exportCellWidth: 20,
      },
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalNonCsioBinder) => {
       

          return    (
            <StandardTableActions
            downloadLoading={downloading}
            onDownloadPreview={() => {
              dispatch(
                previewHabitationalNonCsioBinderPDFAsync(
                  customerpolicyId,
                  row.incremental_number as number,
                  () => {}
                )
              );
            }}
            onDownLoadClick={() => {
              if (row.incremental_number) {
                setDownloading(true);
                dispatch(
                  downloadHabitationalNonCsioBinderPDFAsync(
                    customerpolicyId,
                    row.incremental_number,
                    () => {
                      setDownloading(false);
                    }
                  )
                );
              }
            }}
              onEditClick={() => {
                navigate(
                  `/habitational-policy/${customerId}/policy-forms-tab/${customerpolicyId}/non-binder/${row.incremental_number}`
                );
              }}
            //  more={{ menuItems: menuItems }}
            />
          );
        },
      },
    ],
    items: data,
  };

  React.useEffect(() => {
    dispatch(fetchHabitationalNonCsioBinderListAsync(customerpolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerpolicyId]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...habitationalNonCsioBinderTableProps} />
      </Box>
    </>
  );
};
