import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import { LoadState } from "../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { fetchCommAutomobileCSVDetailListAsync, fetchCommAutomobileCSVDetailsAsync } from "../../../../../../redux/commAutoPolicy/commAutoCSV/commAutoCSVActions";
import { ICommAutomobileCSVDetails } from "../../../../../../redux/commAutoPolicy/commAutoCSV/commAutoCSV.types";
import { ICommAutomobileSupplement } from "../../../../../../redux/commAutoPolicy/commAutoSupplement/commAutoSupplement.types";

export const CommAutomobileCSVList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { detailsList: data, ListLoading: loading } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.csv.details
  );

  const dispatch = useDispatchWrapper();

  React.useEffect(() => {
    dispatch(fetchCommAutomobileCSVDetailListAsync(customerPolicyId));
  }, [customerPolicyId])

  const automobileDriverTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    `/comm-automobile-policy/${customerId}/comm-vehicle-supplement-tab/${customerPolicyId}/csv-detail-information`
                  );
                }}
              >
                Add CVS
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
        {
            key: "incremental_number",
            headerName: "#",
            fieldName: "incremental_number",
            renderType: RenderType.CUSTOM_RENDER,
            exportCellWidth: 30,
            onRowCellRender: (value, row: ICommAutomobileSupplement) => {
              return (
                <Link
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(
                      `/comm-automobile-policy/${customerId}/comm-vehicle-supplement-tab/${customerPolicyId}/csv-detail-information/${row.incremental_number}`
                    );
                  }}
                >
                  {row.incremental_number}
                </Link>
              );
            },
          },
      {
        key: "business_of_insured",
        headerName: "Insured",
        fieldName: "business_of_insured",
        renderType: RenderType.CHIP_SUCCESS,
        exportCellWidth: 20,
      },
      {
        key: "insurance_company",
        headerName: "Insurance Company",
        fieldName: "insurance_company",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "ibc_industry_code",
        headerName: "IBC Industry Code",
        fieldName: "ibc_industry_code",
        exportCellWidth: 25,
        renderType: RenderType.CHIP_WARNING,
      },
      {
        key: "year_business_started",
        headerName: "Year Business Started",
        fieldName: "year_business_started",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: 'center',
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobileSupplement) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/comm-vehicle-supplement-tab/${customerPolicyId}/csv-detail-information/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
    ],
    items: data,
  };

  return (
    <Box sx={{ mt: 2 }}>
      <DataTable {...automobileDriverTableProps} />
    </Box>
  );
};
