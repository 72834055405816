import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { ERROR_MESSAGES } from "../../../constants/enums";
import {
  ICommAutomobileAdditionalInformation,
  ICommAutomobileInterestedParty,
  ICommAutomobileVehicleInformation,
} from "./commAutoVehicle.types";
import { initialCommAutomoblieVehicleInformation } from "./commAutoVehicle.types";

export const FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_FAILED =
  "FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_FAILED";

export const fetchCommAutomobileVehicleProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_PROGRESS);

export const fetchCommAutomobileVehicleSuccess = (
  data: ICommAutomobileVehicleInformation
) =>
  action(FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_SUCCESS, {
    data,
  });
export const fetchCommAutomobileVehicleFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_FAILED, { errorMessage });

export const addCommAutomobileVehicleAsync =
  (
    data: ICommAutomobileVehicleInformation,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post(
        "/policy/edit-policy-commauto-vehicle-information",
        {
          ...data,
        }
      );
      dispatch(fetchCommAutomobileVehicleSuccess(res.data.data[0]));
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Vehicle information saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileVehicleAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileVehicleProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-vehicle-information?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileVehicleInformation[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileVehicleSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileVehicleFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchCommAutomobileVehicleFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_VEHICLE_INFO =
  "CLEAR_COMM_AUTOMOBILE_VEHICLE_INFO";
export const clearCommAutoMobileVehicleInfo = () =>
  action(CLEAR_COMM_AUTOMOBILE_VEHICLE_INFO);

export const FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_LIST_FAILED";

export const fetchCommAutomobileVehcileListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_LIST_PROGRESS);

export const fetchCommAutomobileVehiclesListSuccess = (
  data: ICommAutomobileVehicleInformation[]
) =>
  action(FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_LIST_SUCCESS, {
    data,
  });
export const fetchCommAutomobileVehcileListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_LIST_FAILED);

export const fetchCommAutomobileVehicleListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileVehcileListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-vehicle-information?customer_policy_id=${customerPolicyId}`
      );
      const data: ICommAutomobileVehicleInformation[] = res.data.data;
      dispatch(fetchCommAutomobileVehiclesListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileVehcileListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const fetchCommAutomobileVehicleListByCustomerIdAsync =
  (customerId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileVehcileListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-vehicle-information?customer_id=${customerId}`
      );
      const data: ICommAutomobileVehicleInformation[] = res.data.data;
      dispatch(fetchCommAutomobileVehiclesListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileVehcileListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const fetchCommAutomobileVehicleListByPolicyNumberAsync =
  (policyNumber: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileVehcileListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-vehicle-information?column=policy_number&value=${policyNumber}`
      );
      const data: ICommAutomobileVehicleInformation[] = res.data.data;
      dispatch(fetchCommAutomobileVehiclesListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileVehcileListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_ADDITIONAL_INFO =
  "CLEAR_COMM_AUTOMOBILE_ADDITIONAL_INFO";

export const clearCommAutomobileAdditionalInfo = () =>
  action(CLEAR_COMM_AUTOMOBILE_ADDITIONAL_INFO);

export const FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_FAILED =
  "FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_FAILED";

export const fetchCommAutomobilAdditionalInfoProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_PROGRESS);
export const addCommAutomobilAdditionalInfo = (
  data: ICommAutomobileAdditionalInformation
) =>
  action(FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_SUCCESS, {
    data,
  });
export const fetchCommAutomobilAdditionalInfoFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_FAILED, { errorMessage });

export const addCommAutomobilAdditionalInfoAsync =
  (
    data: ICommAutomobileAdditionalInformation,
    vehcileNumber: number,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post(
        "/policy/edit-policy-commauto-additional-information",
        {
          ...data,
          vehicle_id: vehcileNumber,
        }
      );
      onCallback(true);
      dispatch(addCommAutomobilAdditionalInfo(res.data.data[0]));
      dispatch(
        showMessage({
          type: "success",
          message: "Additional information saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobilAdditionalInfoVehicleAsync =
  (
    customerPolicyId: number,
    vehcile_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobilAdditionalInfoProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-additional-information?customer_policy_id=${customerPolicyId}&vehicle_id=${vehcile_number}`
      );
      const data: ICommAutomobileAdditionalInformation[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          addCommAutomobilAdditionalInfo({
            ...data[0],
          })
        );
      } else {
        dispatch(
          addCommAutomobilAdditionalInfo(
            initialCommAutomoblieVehicleInformation["additionalInformation"][
              "data"
            ]
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobilAdditionalInfoFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_FAILED";

export const fetchCommAutomobileAdditionalInfoListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_PROGRESS);
export const fetchCommAutomobileAdditionalListSuccess = (
  data: ICommAutomobileAdditionalInformation[]
) =>
  action(FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_SUCCESS, {
    data,
  });
export const fetchCommAutomobileAdditionalInfoListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_FAILED);

export const fetchCommAutomobileAdditioalListAsync =
  (vehicle_number: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileAdditionalInfoListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-additional-information?value=${vehicle_number}`
      );
      const data: ICommAutomobileAdditionalInformation[] = res.data.data;
      dispatch(fetchCommAutomobileAdditionalListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileAdditionalInfoListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_SUCCESS =
  "ADD_COMM_AUTOMOBILE_INTERESTED_PARTY";
export const FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_FAILED =
  "FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_FAILED";

export const fetchCommAutomobilIntrestedPartiesInfoProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_PROGRESS);
export const fetchCommAutomobilIntrestedPartiesInfoSuccess = (
  data: ICommAutomobileInterestedParty
) =>
  action(FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_SUCCESS, {
    data,
  });
export const fetchCommAutomobilIntrestedPartiesInfoFailed = (
  errorMessage: string
) => action(FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_FAILED, { errorMessage });

export const addCommAutomobilIntrestedPartiesAsync =
  (
    data: ICommAutomobileInterestedParty,
    vehicleNumber: number,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-commauto-interested-party", {
        ...data,
        vehicle_id: vehicleNumber,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Intrested party saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileIntrestedPartieAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobilIntrestedPartiesInfoProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-interested-party?customer_policy_id=${customerPolicyId}&vehicle_id=${incremental_number}`
      );
      const data: ICommAutomobileInterestedParty[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobilIntrestedPartiesInfoSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobilIntrestedPartiesInfoSuccess(
            initialCommAutomoblieVehicleInformation["interestedParty"]["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobilIntrestedPartiesInfoFailed(
          ERROR_MESSAGES.SERVER_ERROR
        )
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_INTERESTED_PARTY =
  "CLEAR_COMM_AUTOMOBILE_INTERESTED_PARTY";

export const clearCommAutomobileIntrestedParty = () =>
  action(CLEAR_COMM_AUTOMOBILE_INTERESTED_PARTY);

export const FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_LIST_FAILED";

export const fetchCommAutomobileIntrestedPartieListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_LIST_PROGRESS);

export const fetchCommAutomobileIntrestedPartieListSuccess = (
  data: ICommAutomobileInterestedParty[]
) =>
  action(FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_LIST_SUCCESS, {
    data,
  });
export const fetchCommAutomobileIntrestedPartieListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_LIST_FAILED);

export const fetchCommAutomobileIntrestedPartieListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileIntrestedPartieListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-interested-party?customer_policy_id=${customerPolicyId}`
      );
      const data: ICommAutomobileInterestedParty[] = res.data.data;
      dispatch(fetchCommAutomobileIntrestedPartieListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileIntrestedPartieListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_VEHICLE_STATE =
  "CLEAR_COMM_AUTOMOBILE_VEHICLE_STATE";

export const clearCommAutomobileVehicleState = () =>
  action(CLEAR_COMM_AUTOMOBILE_VEHICLE_STATE);
