import React from "react";
import { ITaskFileUploadTableProps } from "./TaskFileUploadTable.types";
import {
  IDataTableProps,
  RenderType,
} from "../../../components/Table/interfaces/IDataTableProps";
import { Button, Stack } from "@mui/material";
import { Add, Remove, Upload } from "@mui/icons-material";
import { CustomTextField } from "../../../components/formsComponents";
import { FileUpload } from "../../../components/FileUpload/FileUpload";
import { DataTable } from "../../../components/Table/DataTable";
import produce from "immer";
import { getUniqueId } from "../../../helpers";
import {
  ITaskFileUpload,
  ITaskFileUploadAttachment,
} from "../../../redux/tasks/task.types";
import { Dialog } from "../../../components/Dialogs/Dialog";
import { useDispatchWrapper } from "../../../hooks";
import { fetchTaskMultiFileUploadAysnc, upsertTaskMutliFileUploadAysnc } from "../../../redux/tasks/tasksActions";
import { RoleBasedCustomButton } from "../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { MODULE_IDS } from "../../../constants/enums";

export const TaskFileUploadButton: React.FC<{ taskCode: string }> = (props) => {
  const { taskCode } = props;
  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <Button variant="contained" startIcon={<Upload />} type="button" onClick={handleToggle}>
        Attachments
      </Button>

      {open && (
        <TaskFileUploadTable
          open={open}
          taskCode={taskCode}
          onClose={handleToggle}
        />
      )}
    </>
  );
};

export const TaskFileUploadTable: React.FC<ITaskFileUploadTableProps> = (
  props
) => {
  const { open, taskCode, onClose } = props;

  const [loading, setLoading] = React.useState(false);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [state, setState] = React.useState<ITaskFileUpload>({
    task_code: taskCode,
    attachments: [],
  });
  const dispatch = useDispatchWrapper();

  const handleSubmit = async () => {
    setSaveLoading(true);
    dispatch(upsertTaskMutliFileUploadAysnc(state,(isSuccess)=>{
        if(isSuccess){
          onClose();
        }
        setSaveLoading(false);
    }))
    
  };

  const handleAdd = () => {
    const newFiles = produce(state.attachments, (draftState) => {
      draftState.push({
        key: getUniqueId(),
        fileName: null,
        url: null,
        file: null,
      });
    });
    setState({ ...state, attachments: newFiles });
  };

  const handleRemove = () => {
    const newFiles = produce(state.attachments, (draftState) => {
      draftState.pop();
    });
    setState({ ...state, attachments: newFiles });
  };

  const handleFileName =
    (rowIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newFiles = produce(state.attachments, (draftState) => {
        draftState[rowIndex].fileName = e.target.value;
      });
      setState({ ...state, attachments: newFiles });
    };

  const handleFileUpload = (rowIndex: number) => (file: File | null) => {
    const newFiles = produce(state.attachments, (draftState) => {
      draftState[rowIndex].file = file;
      draftState[rowIndex].url = null;
    });
    setState({ ...state, attachments: newFiles });
  };

  const handleRemoveFile =
  (rowIndex: number) => {
    const newFiles = produce(state.attachments, (draftState) => {
      draftState[rowIndex].file = null;
      draftState[rowIndex].url = null;
    });
    setState({ ...state, attachments: newFiles });
  };

  const fileTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading,
    rowsPerPageOptions: 1000,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "add",
            onRender: () => (
              <>
                <Stack spacing={1} direction={"row"} justifyContent={"end"}>
                  <RoleBasedCustomButton
                    color="error"
                    variant="contained"
                    onClick={handleRemove}
                    moduleId={MODULE_IDS.ALL_TASK}
                  >
                    <Remove fontSize="small" />
                  </RoleBasedCustomButton>
                  <RoleBasedCustomButton
                    variant="contained"
                    color="primary"
                    onClick={handleAdd}
                    moduleId={MODULE_IDS.ALL_TASK}
                  >
                    <Add fontSize="small" />
                  </RoleBasedCustomButton>
                </Stack>
              </>
            ),
          },
        ],
      },
    },

    uniqueRowKeyName: "key",
    columns: [
      {
        key: "name",
        headerName: "File name",
        fieldName: "name",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: ITaskFileUploadAttachment) => {
          return (
            <CustomTextField
              fullWidth
              value={row.fileName}
              onChange={handleFileName(value.column.rowIndex)}
            />
          );
        },
      },

      {
        key: "file",
        headerName: "File",
        fieldName: "file",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ITaskFileUploadAttachment) => {
          return (
            <FileUpload
              value={row.url}
              onDelete={()=>handleRemoveFile(value.column.rowIndex)}
              onChange={handleFileUpload(value.column.rowIndex)}
            />
          );
        },
      },
    ],
    items: state.attachments,
  };

  React.useEffect(() => {
    setLoading(true);
    dispatch(fetchTaskMultiFileUploadAysnc(taskCode, (isSuccess, data)=>{
      if(isSuccess){
        if(data && data.length > 0){
          setState({...state, attachments: data[0].attachments})
        }
      }
      setLoading(false);
    }));
  }, []);

  return (
    <>
      <Dialog
        open={open}
        title="Attachments"
        onClose={onClose}
        size="md"
        actions={[
          { type: "button", label: "Close", variant: "text", onClick: onClose },
          {
            type: "button",
            label: "Save",
            variant: "contained",
            disabled: saveLoading,
            onClick: handleSubmit,
          },
        ]}
      >
        <DataTable {...fileTableProps} />
      </Dialog>
    </>
  );
};
