import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import {
  IBusinessPropertyInsurance,
  initialBusinessPropertyInsurance,
} from "./businessPropertyInsurance.types";

export const FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_PROGRESS =
  "FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_PROGRESS";
export const FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_SUCCESS =
  "FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_SUCCESS";
export const FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_FAILED =
  "FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_FAILED";

export const fetchBusinessPropertyInsuranceProgress = () =>
  action(FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_PROGRESS);
export const fetchBusinePropertyInsuranceSuccess = (
  data: IBusinessPropertyInsurance
) => action(FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_SUCCESS, { data });
export const fetchBusinessPropertyInsurancefailed = () =>
  action(FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_FAILED);

export const fetchBusinessPropertyInsuranceAsync =
  (
    customerPolicyId: number,
    serialNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPropertyInsuranceProgress());
      const res = await api.get(
        `/policy/get-policy-add-property-insurance-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${serialNumber}`
      );
      const data: IBusinessPropertyInsurance[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBusinePropertyInsuranceSuccess(data[0]));
      } else {
        dispatch(
          fetchBusinePropertyInsuranceSuccess(initialBusinessPropertyInsurance)
        );
      }
    } catch (err: any) {
      dispatch(fetchBusinessPropertyInsurancefailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addBusinessPropertyInsuranceAsync =
  (
    data: IBusinessPropertyInsurance,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post(
          `/policy/edit-policy-add-property-insurance-certificate`,
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          `/policy/edit-policy-add-property-insurance-certificate`,
          { ...data }
        );
      }
      dispatch(
        showMessage({
          type: "success",
          message: "Property insurance certificate saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchBusinePropertyInsuranceSuccess(data));
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_LIST_PROGRESS =
  "FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_LIST_PROGRESS";
export const FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_LIST_SUCCESS =
  "FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_LIST_SUCCESS";
export const FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_LIST_FAILED =
  "FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_LIST_FAILED";

export const fetchBusinessPropertyInsuranceListProgress = () =>
  action(FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_LIST_PROGRESS);
export const fetchBusinessPropertyInsuranceListSuccess = (
  data: IBusinessPropertyInsurance[]
) => action(FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_LIST_SUCCESS, { data });
export const fetchBusinessPropertyInsuranceListFailed = () =>
  action(FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_LIST_FAILED);

export const fetchBusinessPropertyInsuranceListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPropertyInsuranceListProgress());
      const res = await api.get(
        `/policy/get-policy-add-property-insurance-certificate?customer_policy_id=${customerPolicyId}`
      );
      const data: IBusinessPropertyInsurance[] = res.data.data;
      dispatch(fetchBusinessPropertyInsuranceListSuccess(data));
    } catch (err: any) {
      dispatch(fetchBusinessPropertyInsuranceListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const downloadBusinessPropertyInsuranceListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-property-insurance-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchBusinessPropertyInsuranceListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewBusinessPropertyInsuranceListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-property-insurance-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_property_insurance_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchBusinessPropertyInsuranceListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addBusinessPropertyInsuranceListAsync =
  (
    PropertyInsurance: IBusinessPropertyInsurance,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(`/policy/edit-policy-add-property-insurance-certificate`, {
        ...PropertyInsurance,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Property Insurance saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_BUSINESS_POLICY_PROPERTY_INSURANCE_STATE =
  "CLEAR_BUSINESS_POLICY_PROPERTY_INSURANCE_STATE";

export const clearBusinessPolicyPropertyInsuranceState = () =>
  action(CLEAR_BUSINESS_POLICY_PROPERTY_INSURANCE_STATE);
