import { POLICIES } from "../constants/enums";


export const getPolicyUrl = (
  lineOfBusiness: string,
  customerId: number,
  policyId: number,
) => {
  switch (lineOfBusiness) {
    case POLICIES.BUSINESS_POLICY: {
      return `/business-policy/${customerId}/basic-details/${policyId}`;
    }
    case POLICIES.AUTOMOBILE_POLICY: {
      return `/automobile-policy/${customerId}/basic-details/${policyId}`;
    }
    case POLICIES.HABITATIONAL_POLICY: {
      return `/habitational-policy/${customerId}/basic-details/${policyId}`;
    }
    case POLICIES.COMMERCIAL_AUTO_POLICY: {
      return `/comm-automobile-policy/${customerId}/basic-details/${policyId}`;
    }
    case POLICIES.LIFE_AND_FINCANCIAL_POLICY: {
      return `/life-insurance-policy/${customerId}/basic-details/${policyId}`;
    }
    default: {
      return '#';
    }
  }
};
