/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../redux/initialStoreState";
import { useDispatchWrapper } from "./useDispatch";
import {
  clearCommAutoMobileVehicleInfo,
  fetchCommAutomobileVehicleListAsync,
  fetchCommAutomobileVehicleListByPolicyNumberAsync,
} from "../redux/commAutoPolicy/commAutoVehicle/commAutoVehicleActions";

export const useCommAutovehcilesList = (
  byCustomerPolicyId: boolean,
  policyNumber?: string | number | null
) => {
  const vehiclesList = useSelector(
    (storeState: IStoreState) =>
      storeState.commAuto.vehicle.VehicleInformation.vehiclesList
  );
  const dispatch = useDispatchWrapper();

  React.useEffect(() => {
    if (byCustomerPolicyId) {
      if (policyNumber) {
        dispatch(fetchCommAutomobileVehicleListAsync(policyNumber as number));
      }
    } else {
      dispatch(
        fetchCommAutomobileVehicleListByPolicyNumberAsync(
          policyNumber as string
        )
      );
    }
    return () => {
      dispatch(clearCommAutoMobileVehicleInfo());
    };
  }, [policyNumber]);

  const getVehicleDetailOnVinNumber = (vinNumber: string | null) => {
    if (vinNumber) {
      return vehiclesList.find((item) => item.vin === vinNumber);
    }
  };

  const finalList = React.useMemo(() => {
    return vehiclesList.map((item) => {
      return {
        label: `${item.incremental_number || "--"} ( Make: ${
          item.make || "N/A"
        }, Model: ${item.model || "N/A"},
        Year: ${item.year || "N/A"}, VIN: ${item.vin})`,
        value: item.vin || "",
      };
    });
  }, [vehiclesList]);

  return  { finalList, getVehicleDetailOnVinNumber };
};
