import { Box, Button, Grid, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import {
  useDispatchWrapper,
} from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";

import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import {
  LoadState,
  MODULE_IDS,
  SaveState,
} from "../../../../../../../constants/enums";
import { InsurerCodeAutoSearch } from "../../../../../../../components/AutoCompleteSearches";
import { ComissionCodeAutoSearch } from "../../../../../../../components/AutoCompleteSearches/ComissionCodeAutoSearch";
import { BranchCodeAutoSearch } from "../../../../../../../components/AutoCompleteSearches/BranchCodeAutoSearch";
import {
  addAutomobilePolicyAdminsync,
  fetchAutomobilePolicyAdminAsync,
} from "../../../../../../../redux/automobileBasicDetails/automobileBasicDetailsActions";
import { renderDropdownValue } from "../../../../../../../helpers";
import {
  policies_coverage_type,
  policyStatus,
} from "../../../../../../../constants/constants";
import { CustomDatePicker } from "../../../../../../../components/formsComponents/CustomDatePicker";
import moment from "moment";
import { ILifeInsuranceAnalysisProps } from "../LifeInsuranceAnalysis.types";
import {
  addlifeInsurancePolicyExistingPropertyAsync,
  fetchlifeInsurancePolicyExistingPropertyAsync,
} from "../../../../../../../redux/lifeInsurancePolicy/lifeInsurancePolicyExistingProperty/lifeInsurancePolicyExistingPropertyActions";
import { CommentField } from "../../../../../../leads/components/CommentField/CommentField";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";

export const ExistingPropertyCasualty: React.FC<ILifeInsuranceAnalysisProps> = (
  props
) => {
  const { customerpolicyId } = props;
  const [saveLoading, setSaveLoading] = React.useState(false);

  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.lifeInsurance.existingProperty
  );
  const customerInfo = useSelector(
    (storeState: IStoreState) =>
      storeState.lifeInsurance.basicDetails.customerInfo.data
  );

  const dispatch = useDispatchWrapper();
  const { values, handleChange, handleSubmit, setValues, setFieldValue } =
    useFormik({
      initialValues: data,
      validate: (values) => {
        const errors: any = {};
        return errors;
      },
      onSubmit: async (values) => {
        setSaveLoading(true);
        dispatch(
          addlifeInsurancePolicyExistingPropertyAsync(
            {
              ...values,
              customer_policy_id: customerpolicyId,
            },
            (isSuccess) => {
              if (isSuccess) {
                window.scrollTo(0, 0);
                // navigate(
                //   `/business-policy/liability-extension/${customerId}/${customerPolicyId}`
                // );
              }
              setSaveLoading(false);
            }
          )
        );
      },
    });

  React.useEffect(() => {
    dispatch(fetchlifeInsurancePolicyExistingPropertyAsync(customerpolicyId));
  }, []);

  React.useEffect(() => {
    setValues(data);
    setFieldValue("customer_name", customerInfo.customer_name);
  }, [data, customerInfo]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Customer Name</CustomFormLabel>
            <CustomTextField
              name="customer_name"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              disabled
              value={values.customer_name}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Coverage Type</CustomFormLabel>
            <ControlledCustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="covergae_type"
              fullWidth
              value={values.covergae_type}
              onChange={handleChange}
              displayEmpty
              placeholder="Select One"
              options={policies_coverage_type.map((policy) => ({
                label: policy,
                value: policy,
              }))}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Insurer</CustomFormLabel>
            <CustomTextField
              name="insurer"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.insurer}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Effective Date</CustomFormLabel>
            <CustomDatePicker
              fullWidth
              value={values.effective_date}
              onChange={(newValue) => {
                setFieldValue("effective_date", newValue);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Expiry Date</CustomFormLabel>
            <CustomDatePicker
              fullWidth
              value={values.expiry_date}
              onChange={(newValue) => {
                setFieldValue("expiry_date", newValue);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Annual Premium</CustomFormLabel>
            <CustomTextField
              name="annual_premium"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.annual_premium}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Policy Status</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              fullWidth
              name="policy_status"
              value={values.policy_status}
              onChange={handleChange}
              displayEmpty
              renderValue={renderDropdownValue(`Select one`)}
            >
              {policyStatus.map((status) => {
                return <MenuItem value={status}>{status}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={12}>
            <CustomFormLabel>Notes</CustomFormLabel>
            <CommentField
              comments={
                values.notes && values.notes.length > 0
                  ? JSON.parse(values.notes)
                  : []
              }
              onChange={(value) =>
                setFieldValue("notes", JSON.stringify(value))
              }
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 2 }}>
          <RoleBasedCustomButton
            moduleId={MODULE_IDS.LIFE_AND_FINCANCIAL}
            type="submit"
            disabled={saveLoading}
            variant="contained"
          >
            Save
          </RoleBasedCustomButton>
        </Box>
      </form>
    </PageLoader>
  );
};
