import { LoadState } from "../../../constants/enums";

export interface IBusinessPolicyEquipmentState {
  data: IBusinessPolicyEquipment[];
  loading: LoadState;
  equipment: IBusinessPolicyEquipment;
  equipmentLoading: LoadState;
  error: string | null;
}

export interface IBusinessPolicyEquipment {
  customer_policy_id: number;
  incremental_number: number | null;
  effective_date: string | null;
  customer_id: number | null;
  year: string | null;
  description: string | null;
  serial_number: string;
  limit_amount: string | null;
  purchase_date: string | null;
  interested_party_code: string | null;
  interested_party: string | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
  interest: string | null;
  note: string | null;
  equipment_submission_checkbox: string | null;
  status: "ACTIVE" | "PENDING";
}

export interface IEquipmentRow {
  customer_policy_id: number | null;
  effective_date: string | null;
  customer_id: string | null;
  policy_number: string | null;
}

export const defaultBusinessPolicyEquipmentState: IBusinessPolicyEquipmentState =
  {
    data: [],
    loading: LoadState.NotLoaded,
    equipment: {
      customer_policy_id: 0,
      incremental_number: null,
      effective_date: null,
      customer_id: null,
      year: null,
      description: null,
      serial_number: "",
      limit_amount: null,
      purchase_date: null,
      interested_party_code: null,
      interested_party: null,
      address: null,
      city: null,
      country: null,
      postal_code: null,
      province_or_state: null,
      unit_or_suite: null,
      interest: null,
      note: null,
      equipment_submission_checkbox: null,
      status: "ACTIVE",
    },
    equipmentLoading: LoadState.NotLoaded,
    error: null,
  };
