import { LoadState } from "../../../constants/enums";

export interface IAccountingTaxInformationState {
  list: IAccountingTaxInformation[];
  listLoading: LoadState;
  data: IAccountingTaxInformation;
  loading: LoadState;
  error: string;
}

export interface IAccountingTaxInformation {
  tax_id: string | null;
  default_tax_region: string | null;
  pst_gst_vat_number: string | null;
  bahamas_premium_tax: string | null;
  status: string;
}

export const initialAccountingTaxInformationState: IAccountingTaxInformationState =
  {
    list: [],
    listLoading: LoadState.NotLoaded,
    data: {
      tax_id: null,
      default_tax_region: null,
      pst_gst_vat_number: null,
      bahamas_premium_tax: null,
      status: "",
    },
    loading: LoadState.NotLoaded,
    error: "",
  };
