import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { BillTemplateActions } from ".";
import { initialBillTemplateState } from "./BillTemplate.types";
import { CLEAR_INVOICE_BILL_TEMPLATE, FETCH_BILL_TEMPLATE_FAILURE, FETCH_BILL_TEMPLATE_PROGRESS, FETCH_BILL_TEMPLATE_SUCCESS } from "./BillTemplateActions";

export const AccountingBillTemplateReducer = (
  state: IStoreState["accounting"]["billTemplate"] = initialBillTemplateState,
  action: BillTemplateActions
) => {
  switch (action.type) {
    case FETCH_BILL_TEMPLATE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_BILL_TEMPLATE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_BILL_TEMPLATE_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_INVOICE_BILL_TEMPLATE: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.NotLoaded;
        draftState.data = initialBillTemplateState.data;
        draftState.error = null;
      });
      return newState;
    }
    default:
      return state;
  }
};
