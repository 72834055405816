import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialCommAutomobileMarketingHistoryState } from "./commAutoMarketingHistory.types";
import { CommAutomobileMarketingHistoryActions } from ".";
import { CLEAR_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY, CLEAR_COMM_AUTOMOBILE_POLICY_MARKETING_STATE, CLEAR_COMM_AUTO_POLICY_MARKETING_STATE, FETCH_COMMAUTO_POLICY_BINDER_LIST_FAILED, FETCH_COMMAUTO_POLICY_BINDER_LIST_PROGRESS, FETCH_COMMAUTO_POLICY_BINDER_LIST_SUCCESS, FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_FAILED, FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_FAILED, FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_PROGRESS, FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_SUCCESS, FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_PROGRESS, FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_SUCCESS } from "./commAutoMarketingHistoryActions";

export const CommAutomobilePolicyMarketingReducer = (
  state: IStoreState["commAuto"]["marketing"] = initialCommAutomobileMarketingHistoryState,
  action: CommAutomobileMarketingHistoryActions
) => {
  switch (action.type) {
    case FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.marketingHistoryListLoading = LoadState.InProgress;
        draftState.marketingHistoryList = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.marketingHistoryListLoading = LoadState.Loaded;
        draftState.marketingHistoryList = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.marketingHistoryListLoading = LoadState.Failed;
        draftState.marketingHistoryList = [];
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.marketingHistoryLoading = LoadState.InProgress;
        draftState.marketingHistory =
        initialCommAutomobileMarketingHistoryState["marketingHistory"];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.marketingHistoryLoading = LoadState.Loaded;
        draftState.marketingHistory = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_FAILED: {
      const {errorMessage} = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.marketingHistoryLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    
    }

    case CLEAR_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY: {
      const newState = produce(state, (draftState) => {
        draftState.marketingHistory = initialCommAutomobileMarketingHistoryState['marketingHistory'];
        draftState.marketingHistoryLoading = LoadState.NotLoaded;
        draftState.error = null;
      });
      return newState;
    }
    case CLEAR_COMM_AUTOMOBILE_POLICY_MARKETING_STATE: {
      return initialCommAutomobileMarketingHistoryState;
    }

    case FETCH_COMMAUTO_POLICY_BINDER_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.binderHistory.loading = LoadState.InProgress;
        draftState.binderHistory.list = [];
      });
      return newState;
    }
    case FETCH_COMMAUTO_POLICY_BINDER_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.binderHistory.loading = LoadState.Loaded;
        draftState.binderHistory.list = data;
      });
      return newState;
    }
    case FETCH_COMMAUTO_POLICY_BINDER_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.binderHistory.loading = LoadState.Failed;
        draftState.binderHistory.list = [];
      });
      return newState;
    }

    case CLEAR_COMM_AUTO_POLICY_MARKETING_STATE: {
      return initialCommAutomobileMarketingHistoryState;
    }

    default: {
      return state;
    }
  }
};
