import produce from "immer";
import { CompanyDetailActions } from ".";
import { IStoreState } from "../../initialStoreState";
import { initialAccountingCompanyDetailState } from "./CompanyDetail.types";
import {
  CLEAR_ACCOUNTING_COMPANY_DETAIL,
  FETCH_ACCOUNTING_COMPANY_DETAIL_FAILURE,
  FETCH_ACCOUNTING_COMPANY_DETAIL_PROGRESS,
  FETCH_ACCOUNTING_COMPANY_DETAIL_SUCCESS,
} from "./CompanyDetailAction";
import { LoadState } from "../../../constants/enums";

export const AccountingCompanyDetailReducer = (
  state: IStoreState["accounting"]["companyDetail"] = initialAccountingCompanyDetailState,
  action: CompanyDetailActions
) => {
  switch (action.type) {
    case FETCH_ACCOUNTING_COMPANY_DETAIL_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_COMPANY_DETAIL_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_COMPANY_DETAIL_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_ACCOUNTING_COMPANY_DETAIL: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.NotLoaded;
        draftState.data = initialAccountingCompanyDetailState.data;
        draftState.error = null;
      });
      return newState;
    }

    default:
      return state;
  }
};
