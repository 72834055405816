import { LoadState } from "../../constants/enums";

export interface ITaskCalenderState {
  list: ITaskCalender[];
  loading: LoadState;
}

export interface ITaskCalender {
  id: number;
  title: string;
  start: any;
  end: any;
}

export const initialTaskCalenderState: ITaskCalenderState = {
  list: [],
  loading: LoadState.NotLoaded,
};
