import React, { useState } from "react";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { StandadCard } from "../../../../../../../components/Cards";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useFormik } from "formik";
// import { CustomerBaiscDetailsLayout } from "../";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
// import { ICoverageRow } from "../../../../../../redux/businessPolicy/businessPolicyCoverage/businessPolicyCoverage.types";
import produce from "immer";
import { Breadcrumb } from "../../../../../../../layout/fullLayout/breadcrumb/Breadcrumb";
import { IBreadcrumbProps } from "../../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useNavigate, useParams } from "react-router-dom";
import { CustomDatePicker } from "../../../../../../../components/formsComponents/CustomDatePicker";
import moment from "moment";
import { renderDropdownValue } from "../../../../../../../helpers";
import { CustomerBaiscDetailsLayout } from "../../../../../../customers/components/CustomerBasicDetailsLayout/CustomerBasicDetailsLayout";
import {
  TabSelectOptions,
  intertestedPartyCode,
  yearsList,
} from "../../../../../../../constants/constants";
import {
  addBusinesPolicyFineArtAsync,
  fetchBusinessPolicyFineArtAsync,
} from "../../../../../../../redux/businessPolicy/businessPolicyFineArt/businessPolicyFineArtActions";
import { CommentField } from "../../../../../../leads/components/CommentField/CommentField";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
// import {
//   intertestedPartyCode,
//   yearsList,
// } from "../../../constants/constants/";

const initialTaskState = {
  name: "",
  priority: "",
  relatedTo: "",
  relatedToId: "",
  type: "",
  subType: "",
  labels: "",
  url: "",
  details: "",
  inviteEmail: "",
  sendEmailBug: "No",
  createdDate: "",
  dueDate: "",
  completedDate: "",
  assignedTo: "",
};

export const HabitationalCreateTask: React.FC<{
  //   changeToNextStep: (subType: string) => void;
}> = (props) => {
  //   const { changeToNextStep } = props;
  const { customerId, customerPolicyId, taskNumber } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
    taskNumber?: number;
  };

  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      to: "/view-customers",
      title: "customers",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer-overview",
    },
    {
      title: "create-task",
    },
  ];

  const { loading, fineArt, error } = useSelector(
    (storeState: IStoreState) => storeState.business.fineArt
  );
  const [saveLoading, setSaveLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialTaskState,
    validate: (values) => {},
    onSubmit: (values) => {
      //   changeToNextStep(values.subType);
      // if (customerPolicyId && customerId) {
      //   setSaveLoading(true);
      //   dispatch(
      //     addBusinesPolicyFineArtAsync(
      //       {
      //         ...values,
      //         customer_id: customerId,
      //         customer_policy_id: customerPolicyId,
      //       },
      //       (isSuccess) => {
      //         if (isSuccess) {
      //           window.scrollTo(0, 0);
      //           navigate(
      //             `/business-policy/Business/${customerId}/${customerPolicyId}`
      //           );
      //         }
      //         setSaveLoading(false);
      //       }
      //     )
      //   );
      // }
    },
  });

  // React.useEffect(() => {
  //   setValues({
  //     ...fineArt,
  //     incremental_number: fineArtNumber || null,
  //   });
  // }, [fineArt]);

  //   React.useEffect(() => {
  //     return () => {
  //       dispatch(clearBusinessFineArtState());
  //     };
  //   }, []);

  const [tab, setTab] = useState("");
  const handleSelect = (e: any) => {
    setTab(e.target.value);
  };

  return (
    <PageLoader
      loading={loading === LoadState.InProgress}
      error={error ? { message: error } : undefined}
    >
      <StandadCard heading="Create Task">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <CustomFormLabel> Name</CustomFormLabel>
              <CustomTextField
                name="name"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.name}
                // error={errors.name ? true : false}
                // helperText={errors.name}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <CustomFormLabel> Priority</CustomFormLabel>
              <CustomTextField
                name="priority"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.priority}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel> Related To </CustomFormLabel>
              <CustomTextField
                name="relatedTo"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.relatedTo}
                // error={errors.name ? true : false}
                // helperText={errors.name}
                // disabled={isUpdateLead}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel> Related To ID</CustomFormLabel>
              <CustomTextField
                name="relatedToId"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.relatedToId}
                // error={errors.name ? true : false}
                // helperText={errors.name}
                // disabled={isUpdateLead}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel> Type</CustomFormLabel>
              <CustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="type"
                fullWidth
                value={values.type}
                onChange={handleChange}
                displayEmpty
                renderValue={renderDropdownValue(`Select One`)}
              >
                {Object.keys(TabSelectOptions).map((tab) => {
                  return <MenuItem value={tab}>{tab}</MenuItem>;
                })}
              </CustomSelect>
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Sub Type</CustomFormLabel>
              <CustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="subType"
                fullWidth
                value={values.subType}
                onChange={handleChange}
                displayEmpty
                renderValue={renderDropdownValue(`Select One`)}
              >
                {(
                  TabSelectOptions[values.type as "Remarket (Upload)"] || []
                ).map((STab) => {
                  return <MenuItem value={STab}>{STab}</MenuItem>;
                })}
              </CustomSelect>
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Labels</CustomFormLabel>
              <CustomTextField
                name="labels"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.labels}
                // error={errors.name ? true : false}
                // helperText={errors.name}
                // disabled={isUpdateLead}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>URL</CustomFormLabel>
              <CustomTextField
                name="url"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.url}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <CustomFormLabel>Details</CustomFormLabel>
              <CustomTextField
                name="details"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.details}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <CustomFormLabel>Invite Email</CustomFormLabel>
              <CustomTextField
                name="inviteEmail"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.inviteEmail}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <CustomFormLabel>Send Email Flag</CustomFormLabel>
              <Checkbox
                name="send_eDocs_via_email"
                checked={values.sendEmailBug === "Yes" ? true : false}
                onChange={(e, checked) =>
                  setFieldValue("sendEmailBug", checked ? "Yes" : "No")
                }
              />
            </Grid>
          </Grid>

          <Divider sx={{ mt: 4, mb: 4 }} />

          <Grid container spacing={2}>
            <Grid item xs={12} md={2.4}>
              <CustomFormLabel>Created Date</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.createdDate || ""}
                onChange={(newValue) => {
                  setFieldValue("createdDate", newValue);
                }}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <CustomFormLabel>Due Date</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.dueDate || ""}
                onChange={(newValue) => {
                  setFieldValue("dueDate", newValue);
                }}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <CustomFormLabel> Completed Date</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.completedDate || ""}
                onChange={(newValue) => {
                  setFieldValue("completedDate", newValue);
                }}
              />
            </Grid>

            <Grid item xs={12} md={2.4}>
              <CustomFormLabel>Assigned To</CustomFormLabel>
              <CustomTextField
                name="assignedTo"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.assignedTo}
                // error={errors.name ? true : false}
                // helperText={errors.name}
                // disabled={isUpdateLead}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={2.4}>
              <CustomFormLabel>Status</CustomFormLabel>
              <CustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="subType"
                fullWidth
                value={values.subType}
                onChange={handleChange}
                displayEmpty
                renderValue={renderDropdownValue(`Select One`)}
              >
                {["ACTIVE", "PENDING"].map((STab) => {
                  return <MenuItem value={STab}>{STab}</MenuItem>;
                })}
              </CustomSelect>
            </Grid>
          </Grid>

          <Box sx={{ mt: 4 }}>
            <RoleBasedCustomButton
              sx={{ width: "15%" }}
              moduleId={MODULE_IDS.HABITATIONAL}
              disabled={saveLoading}
              variant="contained"
              type="submit"
            >
              Save
            </RoleBasedCustomButton>
          </Box>
        </form>
      </StandadCard>
    </PageLoader>
  );
};
