/* eslint-disable react-hooks/exhaustive-deps */
import { SelectProps } from "@mui/material";
import React from "react";
import { ControlledCustomSelect } from "../formsComponents";
import { ISelectOption } from "../../constants/types";
import { api } from "../../api/api";
import { IPolicyCommission } from "../../redux/policyCommission/policyCommission.types";

interface IComissionCodeSelect {
  value: any;
  insurerCode: string | null;
  onChange: (newValue: string)=> void;
}

export const ComissionCodeSelect: React.FC<IComissionCodeSelect> = (props) => {
  const { value, insurerCode, onChange } = props;
  const [list, setList] = React.useState<ISelectOption[]>([]);

  const fetchList = async () => {
    try {
      const res = await api.get(
        `insurance/get-policy-commission?status=ACTIVE&column=insurer_code_with_name&value=${insurerCode}`
      );
      const data: IPolicyCommission[] = res.data.data;
      const finalData: ISelectOption[] = [];
      for (const comission of data) {
        if (comission.policy_code) {
          finalData.push({
            label: `${comission.policy_code} (${comission.policy_code})`,
            value: comission.policy_code,
          });
        }

        setList(finalData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  React.useEffect(() => {
    if (!insurerCode) {
      setList([]);
      onChange("")
    } else {
      fetchList();
    }
  }, [insurerCode]);

  return (
    <>
      <ControlledCustomSelect
        variant="outlined"
        size="small"
        type="string"
        fullWidth
        displayEmpty
        value={value}
        onChange={(e)=> onChange(e.target.value as string)}
        placeholder="Select One"
        options={list}
      />
    </>
  );
};
