import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import { IAutomobilePolicyAuthorization } from "./automobilePolicyAuthorization.types";
import { ERROR_MESSAGES } from "../../constants/enums";

export const FETCH_AUTOMOBILE_AUTHORIZATION_PROGRESS =
  "FETCH_AUTOMOBILE_AUTHORIZATION_PROGRESS";
export const FETCH_AUTOMOBILE_AUTHORIZATION_SUCCESS =
  "FETCH_AUTOMOBILE_AUTHORIZATION_SUCCESS";
export const FETCH_AUTOMOBILE_AUTHORIZATION_FAILED =
  "FETCH_AUTOMOBILE_AUTHORIZATION_FAILED";

export const fetchAutomobileAuthorizationProgress = () =>
  action(FETCH_AUTOMOBILE_AUTHORIZATION_PROGRESS);
export const fetchAutomobileAuthorizationSuccess = (
  data: IAutomobilePolicyAuthorization
) =>
  action(FETCH_AUTOMOBILE_AUTHORIZATION_SUCCESS, {
    data,
  });
export const fetchAutomobileAuthorizationFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_AUTHORIZATION_FAILED, { errorMessage });

export const addAutomobileAuthorizationAsync =
  (
    data: IAutomobilePolicyAuthorization,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/policy/edit-policy-auto-paf", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Authorization saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const fetchAutomobileAuthorizationAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileAuthorizationProgress());
      const res = await api.get(
        `/policy/get-policy-auto-paf?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobilePolicyAuthorization[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileAuthorizationSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchAutomobileAuthorizationFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchAutomobileAuthorizationFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_AUTOMOBILE_AUTHORIZATION_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_AUTHORIZATION_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_AUTHORIZATION_INFORMATION_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_AUTHORIZATION_INFORMATION_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_AUTHORIZATION_LIST_FAILED =
  "FETCH_AUTOMOBILE_AUTHORIZATION_LIST_FAILED";

export const fetchAutomobileAuthorizationListProgress = () =>
  action(FETCH_AUTOMOBILE_AUTHORIZATION_LIST_PROGRESS);

export const fetchAutomobileAuthorizationListSuccess = (
  data: IAutomobilePolicyAuthorization[]
) =>
  action(FETCH_AUTOMOBILE_AUTHORIZATION_INFORMATION_LIST_SUCCESS, {
    data,
  });
export const fetchAutomobileAuthorizationListFailed = () =>
  action(FETCH_AUTOMOBILE_AUTHORIZATION_LIST_FAILED);

export const fetchAutomobileAuthorizationListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileAuthorizationListProgress());
      const res = await api.get(
        `/policy/get-policy-auto-paf?customer_policy_id=${customerPolicyId}`
      );
      const data: IAutomobilePolicyAuthorization[] = res.data.data;
      dispatch(fetchAutomobileAuthorizationListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileAuthorizationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

  export const downloadAutomobileAuthorizationListPDFAsync =
  (customerPolicyId: number,incremental_number: number, onCallback: ()=> void): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-paf-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,{
          responseType: 'arraybuffer',
        }
      );

      const pdfBuffer = res.data;
      const pdfBlob = new Blob([pdfBuffer], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = 'downloaded.pdf';
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    
    } catch (err: any) {
      dispatch(fetchAutomobileAuthorizationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

  export const previewAutomobileAuthorizationListPDFAsync =
  (customerPolicyId: number,incremental_number: number, onCallback: ()=> void): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-paf-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_auto_paf_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    
    } catch (err: any) {
      dispatch(fetchAutomobileAuthorizationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_AUTHORIZATION = "CLEAR_AUTOMOBILE_AUTHORIZATION";

export const clearAutomobileAuthorization = () =>
  action(CLEAR_AUTOMOBILE_AUTHORIZATION);

export const CLEAR_AUTOMOBILE_AUTHORIZATION_STATE =
  "CLEAR_AUTOMOBILE_AUTHORIZATION_STATE";

export const clearAutomobileAuthorizationState = () =>
  action(CLEAR_AUTOMOBILE_AUTHORIZATION_STATE);
