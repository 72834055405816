import { useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../hooks";
import React from "react";
import { IAddDriver } from "../../../../redux/TaskReducer/Driver/driver.types";
import { fetchAddDriverAysnc } from "../../../../redux/TaskReducer/Driver/driverActions";
import { Grid, Stack, Typography } from "@mui/material";
import { StandardCellList } from "../../../../components/Standards";
import { formatTime } from "../../../../helpers";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../constants/enums";
import { ITaskFileUploadAttachment } from "../../../../redux/tasks/task.types";
import { StandadCard } from "../../../../components/Cards";
import { FileUpload } from "../../../../components/FileUpload/FileUpload";
import {
  IDataTableProps,
  RenderType,
} from "../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../components/Table/DataTable";
import { stripHtml } from "../../../../helpers/stripHtml";
import { QuillEditor } from "../../../../components/QuillEditor/QuillEditor";

export const ViewAddDriver: React.FC<{
  taskCode: string;
  attachment: ITaskFileUploadAttachment[];
}> = (props) => {
  const { taskCode, attachment } = props;

  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.tasks.driver.addDriver
  );

  const dispatch = useDispatchWrapper();
  const [driverDetails, setDriverDetails] = React.useState<IAddDriver>();

  React.useEffect(() => {
    dispatch(fetchAddDriverAysnc(taskCode));
  }, [taskCode]);

  React.useEffect(() => {
    setDriverDetails(data);
  }, [data]);

  const licenseTableProps: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 1000,

    uniqueRowKeyName: "key",
    columns: [
      {
        key: "srNumber",
        headerName: "No.",
        fieldName: "srNumber",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
      },
      {
        key: "name",
        headerName: "Class Name",
        fieldName: "name",
        renderType: RenderType.TEXT,
      },
      {
        key: "dateOfFirstLicensed",
        headerName: "Date First Licensed",
        fieldName: "dateOfFirstLicensed",
        renderType: RenderType.TEXT,
      },
      {
        key: "yearLicensed",
        headerName: "Years Licensed",
        fieldName: "yearLicensed",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
      },
    ],
    items: driverDetails?.license_history || [],
  };

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      {driverDetails && (
        <>
          <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item xs={12} lg={12} >
              <StandardCellList
                heading="Driver Information"
                colSize={3}
                list={[
                  {
                    heading: "Driver Name",
                    data: {
                      type: "text",
                      value: driverDetails.driver_name,
                    },
                  },
                  {
                    heading: "License Number",
                    data: {
                      type: "text",
                      value: driverDetails.license_number,
                    },
                  },
                  {
                    heading: "Effective Date",
                    data: {
                      type: "text",
                      value: driverDetails.effective_date_of_change,
                    },
                  },
                  {
                    heading: "Effective Time",
                    data: {
                      type: "text",
                      value: formatTime(
                        driverDetails.effective_time_of_change.toString()
                      ),
                    },
                  },
                ]}
              >
                <Grid item xs={12} md={12} mb={2}>
                  <Typography fontWeight={500} variant="h4" mb={1}>
                    Details
                  </Typography>
                  <QuillEditor
                    value={driverDetails.details || ""}
                    onChange={() => {}}
                    readOnly={true}
                  />
                </Grid>
                <DataTable {...licenseTableProps} />
              </StandardCellList>
            </Grid>
          </Grid>
        </>
      )}
    </PageLoader>
  );
};
