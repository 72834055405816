import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialAccountingBankAccountState } from "./BankAccount.types";
import { AccountingBankAccountActions } from ".";
import {
  CLEAR_ACCOUNTING_BANK_ACCOUNT,
  FETCH_ACCOUNTING_BANK_ACCOUNT_FAILURE,
  FETCH_ACCOUNTING_BANK_ACCOUNT_LIST_FAILURE,
  FETCH_ACCOUNTING_BANK_ACCOUNT_LIST_PROGRESS,
  FETCH_ACCOUNTING_BANK_ACCOUNT_LIST_SUCCESS,
  FETCH_ACCOUNTING_BANK_ACCOUNT_PROGRESS,
  FETCH_ACCOUNTING_BANK_ACCOUNT_SUCCESS,
} from "./BankAccountActions";

export const AccountingBankAccountReducer = (
  state: IStoreState["accounting"]["bankAccount"] = initialAccountingBankAccountState,
  action: AccountingBankAccountActions
) => {
  switch (action.type) {
    case FETCH_ACCOUNTING_BANK_ACCOUNT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_BANK_ACCOUNT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_BANK_ACCOUNT_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_BANK_ACCOUNT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_BANK_ACCOUNT_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Loaded;
        draftState.list = data;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_BANK_ACCOUNT_LIST_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_ACCOUNTING_BANK_ACCOUNT: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.NotLoaded;
        draftState.data = initialAccountingBankAccountState.data;
        draftState.error = null;
      });
      return newState;
    }

    default:
      return state;
  }
};
