import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialHabitationalDetails } from "./habitationalBasicDetails.types";
import { HabitationalBasicAction } from ".";
import {
  CLAER_HABITATIONAL_POLICY_ATTACHMENT_STATE,
  CLEAR_HABITATIONAL_BASIC_DETAILS_STATE,
  FETCH_HABITATIONAL_ATTACHMENT_FAILED,
  FETCH_HABITATIONAL_ATTACHMENT_LIST_FAILED,
  FETCH_HABITATIONAL_ATTACHMENT_LIST_PROGRESS,
  FETCH_HABITATIONAL_ATTACHMENT_LIST_SUCCESS,
  FETCH_HABITATIONAL_ATTACHMENT_PROGRESS,
  FETCH_HABITATIONAL_ATTACHMENT_SUCCESS,
  FETCH_HABITATIONAL_CUSTOMER_INFO_PROGRESS,
  FETCH_HABITATIONAL_CUSTOMER_INFO_SUCCESS,
  FETCH_HABITATIONAL_POLICY_ADMIN_PROGRESS,
  FETCH_HABITATIONAL_POLICY_ADMIN_SUCCESS,
  FETCH_HABITATIONAL_POLICY_ADMIN_MANAGER_PROGRESS,
  FETCH_HABITATIONAL_POLICY_ADMIN_MANAGER_SUCCESS,
  FETCH_HABITATIONAL_PREMIUM_PROGRESS,
  FETCH_HABITATIONAL_PREMIUM_SUCCESS,
  FETCH_HABITATIONAL_PRIOR_POLICY_PROGRESS,
  FETCH_HABITATIONAL_PRIOR_POLICY_SUCCESS,
  POLICY_HABITATIONAL_BASIC_DETAILS_NOT_FOUND,
  FETCH_HABITATIONAL_POLICY_ADMIN_MANAGER_FAILED,
  FETCH_HABITATIONAL_ADDITIONAL_INSURED_LIST_PROGRESS,
  FETCH_HABITATIONAL_ADDITIONAL_INSURED_LIST_SUCCESS,
  FETCH_HABITATIONAL_ADDITIONAL_INSURED_LIST_FAILED,
} from "./habitationalBasicDetailsActions";

export const habitationalBasicDetailsReducer = (
  state: IStoreState["habitational"]["basicDetails"] = initialHabitationalDetails,
  action: HabitationalBasicAction
) => {
  switch (action.type) {
    case FETCH_HABITATIONAL_CUSTOMER_INFO_SUCCESS: {
      const { customerInfo, customerPolicyId, isNewPolicy } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.customerInfo.loading = LoadState.Loaded;
        draftState.customerInfo.data = customerInfo;
        draftState.customerPoliycId = customerPolicyId;
        draftState.isNewPolicy = isNewPolicy;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_CUSTOMER_INFO_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.customerInfo.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_ADDITIONAL_INSURED_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.additional_insured.list = [];
        draftState.additional_insured.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_ADDITIONAL_INSURED_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.additional_insured.list = data;
        draftState.additional_insured.loading = LoadState.Loaded;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_ADDITIONAL_INSURED_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.additional_insured.list = [];
        draftState.additional_insured.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_POLICY_ADMIN_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.policyAdmin.loading = LoadState.Loaded;
        draftState.policyAdmin.data = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_POLICY_ADMIN_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.policyAdmin.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_POLICY_ADMIN_MANAGER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.policyAdminManager.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_POLICY_ADMIN_MANAGER_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.policyAdminManager.loading = LoadState.Loaded;
        draftState.policyAdminManager.data = data;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_POLICY_ADMIN_MANAGER_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.policyAdminManager.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_PREMIUM_SUCCESS: {
      const { premium } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.premium.loading = LoadState.Loaded;
        draftState.premium.data = premium;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_PREMIUM_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.premium.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_PRIOR_POLICY_SUCCESS: {
      const { priorPolicy } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.priorPolicy.loading = LoadState.Loaded;
        draftState.priorPolicy.data = priorPolicy;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_PRIOR_POLICY_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.priorPolicy.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_ATTACHMENT_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.attachment.loading = LoadState.InProgress;
        draftState.attachment.data =
          initialHabitationalDetails["attachment"]["data"];
        draftState.attachment.error = null;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_ATTACHMENT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.attachment.loading = LoadState.Loaded;
        draftState.attachment.data = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_ATTACHMENT_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.attachment.loading = LoadState.Failed;
        draftState.attachment.error = errorMessage;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_ATTACHMENT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.attachment.listLoading = LoadState.InProgress;
        draftState.attachment.list = [];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_ATTACHMENT_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.attachment.listLoading = LoadState.Loaded;
        draftState.attachment.list = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_ATTACHMENT_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.attachment.listLoading = LoadState.Failed;
        draftState.attachment.list = [];
      });
      return newState;
    }

    case CLAER_HABITATIONAL_POLICY_ATTACHMENT_STATE: {
      const newState = produce(state, (draftState) => {
        draftState.attachment = initialHabitationalDetails["attachment"];
      });
      return newState;
    }

    case CLEAR_HABITATIONAL_BASIC_DETAILS_STATE: {
      return initialHabitationalDetails;
    }

    case POLICY_HABITATIONAL_BASIC_DETAILS_NOT_FOUND: {
      const newState = produce(state, (draftState) => {
        draftState.customerNotFoundError =
          "Oops! We couldn't find any records associated with your polidy id at the moment. Please ensure that the provided information is accurate and try again.";
      });
      return newState;
    }

    default: {
      return state;
    }
  }
};
