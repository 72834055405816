import { LoadState } from "../../../constants/enums";

export interface ISystemGLAccountState {
  data: ISystemGLAccount;
  loading: LoadState;
  error: string | null;
}

export interface ISystemGLAccount {
  accn_system_gl_account_unique_id: number | null;
  incremental_number: number;
  agency_bill_receivables: string | null;
  direct_bill_receivables: string | null;
  net_income: string | null;
  retained_earnings: string | null;
  cash_disbursements_asset_account: string | null;
  agency_bill_trust_account: string | null;
  general_cash_receipts_account: string | null;
  agency_bill_tax_payable_account: string | null;
  agency_bill_tax_two_payable_account: string | null;
  vat_on_direct_bill_commissions_payable_account: string | null;
  vat_on_agency_bill_earned_commissions_expense_account: string | null;
  agency_bill_tax_trust_account: string | null;
  direct_bill_bonus_commission_account: string | null;
  direct_bill_charge_back_commission_account: string | null;
  direct_bill_charge_back_bonus_account: string | null;
  finance_and_service_charge_income_account: string | null;
  automatic_writeoff_or_waiver_account: string | null;
  status: string;
}

export const initialSystemGLAccountState: ISystemGLAccountState = {
  data: {
    accn_system_gl_account_unique_id: null,
    incremental_number: 0,
    agency_bill_receivables: null,
    direct_bill_receivables: null,
    net_income: null,
    retained_earnings: null,
    cash_disbursements_asset_account: null,
    agency_bill_trust_account: null,
    general_cash_receipts_account: null,
    agency_bill_tax_payable_account: null,
    agency_bill_tax_two_payable_account: null,
    vat_on_direct_bill_commissions_payable_account: null,
    vat_on_agency_bill_earned_commissions_expense_account: null,
    agency_bill_tax_trust_account: null,
    direct_bill_bonus_commission_account: null,
    direct_bill_charge_back_commission_account: null,
    direct_bill_charge_back_bonus_account: null,
    finance_and_service_charge_income_account: null,
    automatic_writeoff_or_waiver_account: null,
    status: "ACTIVE",
  },
  loading: LoadState.NotLoaded,
  error: null,
};
