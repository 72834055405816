import React from "react";
import { Dialog } from "../Dialogs/Dialog";
import { InsuranceReportsWarningDialogProps } from "./InsuranceReportsWarningDialog.types";
import { Box, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { WarningOutlined } from "@mui/icons-material";

export const InsuranceReportsWarningDialog: React.FC<
  InsuranceReportsWarningDialogProps
> = (props) => {
  const { open, onClose } = props;

  const navigate = useNavigate();
  const handleDisagree = () => {
    navigate("/dashboard");
  };

  return (
    <Dialog
      title="Warning"
      size="md"
      open={open}
      onClose={() => {}}
      hideCloseIcon
      hideHeader
      actions={[
        {
          type: "button",
          variant: "contained",
          label: "I Disagree",
          onClick: handleDisagree,
        },
        {
          type: "button",
          variant: "contained",
          label: "I Agree",
          onClick: onClose,
        },
      ]}
    >
      <Stack direction={"row"} spacing={3} alignItems={'center'}
       
      >
        <WarningOutlined color="warning" sx={{fontSize: "4rem"}}/>
       <Box>
       <Typography variant="h2" fontWeight={"bold"} >
          Authorized Requester Program  Balancing
        </Typography>
        <Typography variant="h2" fontWeight={"bold"}>
        Privacy and Access!
        </Typography>
       </Box>
      </Stack>
     
      {/* <Box
        sx={{
          // border: "0.5px solid black",
          borderRadius: "20px",
          width: "100%",
          backgroundColor: "warning.main",
          marginX: "auto",
          marginY: 1,
          padding: 4,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography variant="h2" fontWeight={"bold"}>
          Authorized Requester Program Balancing-Privacy and Access
        </Typography>
      </Box> */}
      <Typography variant="h4" fontWeight={'bold'} marginTop={1}>
        I Understand that :{" "}
      </Typography>
      <Box>
        <ul>
          <li>
            {" "}
            <Typography variant="body1" sx={{fontSize: '1.15rem'}}>
              I understand that I can order MTO data for underwriting purpose
              only.
            </Typography>
          </li>
          <li>
            {" "}
            <Typography variant="body1" sx={{fontSize: '1.15rem'}}>
              I must keep client profile up to date.
            </Typography>
          </li>
          <li>
            {" "}
            <Typography variant="body1" sx={{fontSize: '1.15rem'}}>
              I cannot disclose information to other parties unless required to
              do so by applicable law.
            </Typography>
          </li>
          <li>
            {" "}
            <Typography variant="body1" sx={{fontSize: '1.15rem'}}>
              I cannot share user IDs and/or passwords with others. If I do so
              this is a breach of contract.
            </Typography>
          </li>
          <li>
            {" "}
            <Typography variant="body1" sx={{fontSize: '1.15rem'}}>
              I cannot leave hard copies of ministry information products
              containing personal information where they can be seen by others.
            </Typography>
          </li>
          <li>
            {" "}
            <Typography variant="body1" sx={{fontSize: '1.15rem'}}>
              I cannot match MTO information with other data and/or cannot
              create a database.
            </Typography>
          </li>
          <li>
            {" "}
            <Typography variant="body1" sx={{fontSize: '1.15rem'}}>
              I need to maintain records for each information request made that
              specifies the date of the request, the information requested and
              the business reason for the request.
            </Typography>
          </li>
          <li>
            {" "}
            <Typography variant="body1" sx={{fontSize: '1.15rem'}}>
              I must update records within 30 days of request in regards to when
              the policy was written, MVR destroyed, request voided and or
              policy is cancelled.
            </Typography>
          </li>
          <li>
            {" "}
            <Typography variant="body1" sx={{fontSize: '1.15rem'}}>
              The Ministry will conduct an audit of your business to ensure
              compliance with the terms of your agreement.
            </Typography>
          </li>
        </ul>
      </Box>
    </Dialog>
  );
};
