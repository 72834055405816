import produce from 'immer';
import { LegalsActions } from '.';
import { IStoreState } from '../initialStoreState';
import { initialLegal, initialLegalState } from './legals.types';
import {
    FETCH_LEGALS_LIST_PROGRESS,
    FETCH_LEGALS_LIST_SUCCESS,
    FETCH_LEGALS_LIST_FAILED,
    FETCH_LEGAL_PROGRESS,
    FETCH_LEGAL_SUCCESS,
    FETCH_LEGAL_FAILED,
    CLEAR_LEGAL_STATE,
    CLEAR_LEGAL,
  } from './legalsActions'; 
import { LoadState } from '../../constants/enums';
  
  const legalsReducer = (state: IStoreState["legals"] = initialLegalState,
  action: LegalsActions) => {
    switch (action.type) {
        case FETCH_LEGALS_LIST_PROGRESS: {
          const newState = produce(state, (draftState) => {
            draftState.loading = LoadState.InProgress;
            draftState.list = [];
            draftState.totalRecords = 0;
          });
          return newState;
        }
        case FETCH_LEGALS_LIST_SUCCESS: {
          const { list, totalRecords } = action.payload;
          const newState = produce(state, (draftState) => {
            draftState.loading = LoadState.Loaded;
            draftState.list = list;
            draftState.totalRecords = totalRecords;
          });
          return newState;
        }
        case FETCH_LEGALS_LIST_FAILED: {
          const newState = produce(state, (draftState) => {
            draftState.loading = LoadState.InProgress;
            draftState.list = [];
            draftState.totalRecords = 0;
          });
          return newState;
        }
        case FETCH_LEGAL_PROGRESS: {
          const newState = produce(state, (draftState) => {
            draftState.legalLoading = LoadState.InProgress;
            draftState.legal = initialLegal;
          });
          return newState;
        }
        case FETCH_LEGAL_SUCCESS: {
          const { legal } = action.payload;
          const newState = produce(state, (draftState) => {
            draftState.legalLoading= LoadState.Loaded;
            draftState.legal = legal;
          });
          return newState;
        }
        case FETCH_LEGAL_FAILED: {
          const { errorMessage } = action.payload;
          const newState = produce(state, (draftState) => {
            draftState.legalLoading= LoadState.Failed;
            draftState.error = errorMessage;
          });
          return newState;
        }

        case CLEAR_LEGAL: {
          const newState = produce(state, (draftState) => {
            draftState.legalLoading = LoadState.NotLoaded;
            draftState.legal = initialLegal;
          });
          return newState;
        }
        // case CLEAR_LEGAL_STATE: {
        //   return initialLegal;
        // }

        default: {
          return state;
        }
      }
  };
  
  export default legalsReducer;
  