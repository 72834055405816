import moment, { Moment } from "moment";
import { LoadState, POLICIES } from "../../../constants/enums";

export interface ILifeInsuranceBasicDetailsState {
  customerInfo: {
    data: ILifeInsuranceCustomerInfo;
    Loading: LoadState;
    error: string | null;
  };
  policyAdmin: {
    data: ILifeInsurancePolicyAdmin;
    Loading: LoadState;
    error: string | null;
  };
  policyAdminManager: {
    data: ILifeInsurancePolicyAdminManager;
    Loading: LoadState;
    error: string | null;
  };
  premium: {
    data: ILifeInsurancePremium;
    Loading: LoadState;
    error: string | null;
  };
  claim: {
    data: ILifeInsuranceClaims[];
    Loading: LoadState;
    error: string | null;
  };
  attachment: {
    list: ILifeInsuranceAttachment[];
    listLoading: LoadState;
    data: ILifeInsuranceAttachment;
    loading: LoadState;
    error: string | null;
  };
  customerPoliycId?: number | null;
  isNewPolicy: boolean;
}

export interface ILifeInsuranceCustomerInfo {
  customer_policy_id: number | null;
  customer_id: number;
  policy_id: string | null;
  customer_name: string | null;
  customer_code: string | null;
  line_of_business: string | null;
  sub_type: string | null;
  language: string | null;
  address_type: string | null;
  address: string | null;
  unit_or_suite: string | null;
  state: string | null;
  city: string | null;
  postal_code: string | null;
  country: string | null;
  policy_status: string | null;
  policy_number: string | null;
  date_cancelled: string | null;
  policy_limit: string | null;
  effective_date: string | null;
  expiry_date: string | null;
  Send_Invoices_Renewal_Recievable_By_Email: boolean | null;
  Send_Statement_By_Email: boolean | null;
  Send_docs_By_Email: boolean | null;
  status: string;
}

export interface ILifeInsurancePolicyAdmin {
  customer_policy_id: number;
  insurer: string | null;
  aga: string | null;
  comission_code: string | null;
  branch_code: string | null;
  advisor_one: string | null;
  advisor_one_code: string | null;
  advisor_two: string | null;
  advisor_two_code: string | null;
  advisor_three: string | null;
  advisor_three_code: string | null;
  marketer: string | null;
  marketer_code: string | null;
  csr: string | null;
  csr_code: string | null;
  status: string | null;
}

export interface ILifeInsurancePolicyAdminManager {
  customer_policy_id: number;
  insurer: string | null;
  aga: string | null;
  comission_code: string | null;
  branch_code: string | null;
  advisor_one: string | null;
  advisor_one_code: string | null;
  advisor_one_percentage: string | null;
  advisor_two: string | null;
  advisor_two_code: string | null;
  advisor_two_percentage: string | null;
  advisor_three: string | null;
  advisor_three_code: string | null;
  advisor_three_percentage: string | null;
  marketer: string | null;
  marketer_code: string | null;
  csr: string | null;
  csr_code: string | null;
  status: string;
}

export interface ILifeInsurancePremium {
  customer_policy_id: number;
  policy_premium: string | null;
  payment_plan: string | null;
  commission_code_percentage: string | null;
  bonus_percent: string | null;
  bonus_amount: string | null;
  fyc: string | null;
  status: string | null;
}

export interface ILifeInsuranceClaims {
  customer_policy_id: number;
  serial_number: number;
  claim_number: string | null;
  date_of_loss: string | null;
  // amount_paid_dollar: string | null;
  // ours: string | null;
  type: string | null;
  producer: string | null;
  branch: string | null;
  amount_paid: string | null;
  loss_details: string | null;
  status: string | null;
}

export interface ILifeInsuranceAttachment {
  customer_policy_id: number | null;
  serial_number: number | null;
  document_name: string | null;
  file_upload: string | null;
  description: string | null;
  status: string;
}

export const initialLifeInsuranceBasicDetails: ILifeInsuranceBasicDetailsState =
  {
    customerInfo: {
      data: {
        customer_policy_id: null,
        customer_id: 0,
        customer_name: null,
        customer_code: null,
        line_of_business: POLICIES.LIFE_AND_FINCANCIAL_POLICY,
        sub_type: null,
        policy_id: null,
        language: "E",
        address_type: null,
        address: null,
        unit_or_suite: null,
        state: null,
        city: null,
        postal_code: null,
        country: null,
        policy_limit: null,
        policy_status: "Active",
        policy_number: null,
        effective_date: "",
        expiry_date: "",
        date_cancelled: moment().format("YYYY-MM-DD"),
        Send_Invoices_Renewal_Recievable_By_Email: null,
        Send_Statement_By_Email: null,
        Send_docs_By_Email: null,
        status: "ACTIVE",
      },
      Loading: LoadState.NotLoaded,
      error: null,
    },
    policyAdmin: {
      data: {
        customer_policy_id: 0,
        insurer: null,
        aga: null,
        comission_code: null,
        branch_code: null,
        advisor_one: null,
        advisor_one_code: null,
        advisor_two: null,
        advisor_two_code: null,
        advisor_three: null,
        advisor_three_code: null,
        marketer: null,
        marketer_code: null,
        csr: null,
        csr_code: null,
        status: "ACTIVE",
      },
      Loading: LoadState.NotLoaded,
      error: null,
    },
    policyAdminManager: {
      data: {
        customer_policy_id: 0,
        insurer: null,
        aga: null,
        comission_code: null,
        branch_code: null,
        advisor_one: null,
        advisor_one_code: null,
        advisor_one_percentage: null,
        advisor_two: null,
        advisor_two_code: null,
        advisor_two_percentage: null,
        advisor_three: null,
        advisor_three_code: null,
        advisor_three_percentage: null,
        marketer: null,
        marketer_code: null,
        csr: null,
        csr_code: null,
        status: "ACTIVE",
      },
      Loading: LoadState.NotLoaded,
      error: null,
    },
    premium: {
      data: {
        customer_policy_id: 0,
        policy_premium: null,
        commission_code_percentage: null,
        bonus_percent: null,
        bonus_amount: null,
        fyc: null,
        payment_plan: null,
        status: "ACTIVE",
      },
      Loading: LoadState.NotLoaded,
      error: null,
    },
    claim: {
      data: [],
      Loading: LoadState.NotLoaded,
      error: null,
    },
    attachment: {
      list: [],
      listLoading: LoadState.NotLoaded,
      data: {
        customer_policy_id: null,
        serial_number: null,
        document_name: null,
        file_upload: null,
        description: null,
        status: "ACTIVE",
      },
      loading: LoadState.NotLoaded,
      error: null,
    },
    customerPoliycId: null,
    isNewPolicy: true,
  };
