import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { automobilePolicyAuthorizationActions } from ".";
import { defaultAutomobilePolicyAuthorizationState } from "./automobilePolicyAuthorization.types";
import {
  CLEAR_AUTOMOBILE_AUTHORIZATION,
  CLEAR_AUTOMOBILE_AUTHORIZATION_STATE,
  FETCH_AUTOMOBILE_AUTHORIZATION_FAILED,
  FETCH_AUTOMOBILE_AUTHORIZATION_INFORMATION_LIST_SUCCESS,
  FETCH_AUTOMOBILE_AUTHORIZATION_LIST_FAILED,
  FETCH_AUTOMOBILE_AUTHORIZATION_LIST_PROGRESS,
  FETCH_AUTOMOBILE_AUTHORIZATION_PROGRESS,
  FETCH_AUTOMOBILE_AUTHORIZATION_SUCCESS,
} from "./automobilePolicyAuthorizationActions";

export const AutomobilePolicyAuthorizationReducer = (
  state: IStoreState["automobile"]["authorization"] = defaultAutomobilePolicyAuthorizationState,
  action: automobilePolicyAuthorizationActions
) => {
  switch (action.type) {
    case FETCH_AUTOMOBILE_AUTHORIZATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.authLoading = LoadState.InProgress;
        draftState.auth = defaultAutomobilePolicyAuthorizationState["auth"];
        draftState.error = null;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_AUTHORIZATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.authLoading = LoadState.Loaded;
        draftState.auth = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_AUTHORIZATION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.authLoading = LoadState.Failed;
        draftState.auth = defaultAutomobilePolicyAuthorizationState["auth"];
        draftState.error = errorMessage;
      });
      return newState;
    }

    case FETCH_AUTOMOBILE_AUTHORIZATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_AUTHORIZATION_INFORMATION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_AUTHORIZATION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }

    case CLEAR_AUTOMOBILE_AUTHORIZATION: {
      const newState = produce(state, (draftState) => {
        draftState.authLoading = LoadState.InProgress;
        draftState.auth = defaultAutomobilePolicyAuthorizationState["auth"];
        draftState.error = null;
      });
      return newState;
    }

    case CLEAR_AUTOMOBILE_AUTHORIZATION_STATE: {
      return defaultAutomobilePolicyAuthorizationState;
    }

    default: {
      return state;
    }
  }
};
