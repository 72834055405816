import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useDispatchWrapper } from "../../../../../../hooks";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { LoadState } from "../../../../../../constants/enums";
import { fetchBusinessPolicyPremiumFinanceListAsync } from "../../../../../../redux/businessPolicy/businessPolicyPremiumFinance/businessPolicyPremiumFinanceActions";
import { IBusinessPolicyPremiumFinance } from "../../../../../../redux/businessPolicy/businessPolicyPremiumFinance/businessPolicyPremiumFinance.types";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { useBreadcrumbContext } from "../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { IBreadcrumbProps } from "../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { clearBusinessPolicyRestrutant } from "../../../../../../redux/businessPolicy/businessPolicyRestrutant/businessPolicyRestrutantActions";
import { AddOutlined } from "@mui/icons-material";

export const PremiumFinanceList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const { addBreadcrumb } = useBreadcrumbContext();
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/policies/",
      title: "all-policies ",
    },
    {
      to: "/policies/Business%20Policy",
      title: "business",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer",
    },
    {
      title: 'premium-finance'
    }
  ];
  const navigate = useNavigate();
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.business.premiumFinance
  );
  const dispatch = useDispatchWrapper();
  React.useEffect(() => {
    addBreadcrumb({ title: "", items: BCrumb });
    return () => {
      dispatch(clearBusinessPolicyRestrutant());
    };
  }, []);
  React.useEffect(() => {
    dispatch(fetchBusinessPolicyPremiumFinanceListAsync(customerPolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerPolicyId]);

  const premiumfinanceProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                startIcon={<AddOutlined />}
                onClick={() => {
                  navigate(
                    `/business-policy/${customerId}/others-tab/${customerPolicyId}/premium-finance`
                  );
                }}
              >
                Create
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IBusinessPolicyPremiumFinance) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/business-policy/${customerId}/others-tab/${customerPolicyId}/premium-finance/${row.incremental_number}`
                )
              }
            >
              {row.incremental_number}
            </Link>
          );
        },
      },

      {
        key: "contact_name",
        headerName: "Contact Name",
        fieldName: "contact_name",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
      },
      {
        key: "contract_number",
        headerName: "Contract Number",
        fieldName: "contract_number",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 20,
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },
      {
        key: "line_of_business",
        headerName: "Line of Business",
        fieldName: "line_of_business",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },
      {
        key: "expiry_date",
        headerName: "Expiry Date",
        fieldName: "expiry_date",
        renderType: RenderType.DATE,
        exportCellWidth: 30,
      },
      {
        key: "created_date",
        headerName: "Created Date",
        fieldName: "insert_ts",
        renderType: RenderType.DATE,
        exportCellWidth: 30,
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBusinessPolicyPremiumFinance) => {
          return (
            <StandardTableActions
              //onViewClick={() => setOpenView(row)}
              onEditClick={() => {
                navigate(
                  `/business-policy/${customerId}/others-tab/${customerPolicyId}/premium-finance/${row.incremental_number}`
                );
              }}
              //  more={{ menuItems: menuItems }}
            />
          );
        },
      },
    ],
    items: data,
  };

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...premiumfinanceProps} />
      </Box>
    </>
  );
};
