import produce from "immer";
import { TaskActions } from ".";
import { IStoreState } from "../initialStoreState";
import { initialTask, initialTaskState } from "./task.types";
import {
  CLEAR_TASK,
  FETCH_TASKS_LIST_FAILED,
  FETCH_TASKS_LIST_PROGRESS,
  FETCH_TASKS_LIST_SUCCESS,
  FETCH_TASK_FAILED,
  FETCH_TASK_PROGRESS,
  FETCH_TASK_SUCCESS,
} from "./tasksActions";
import { LoadState } from "../../constants/enums";

export const tasksReducer = (
  state: IStoreState["tasks"]["allTasks"] = initialTaskState,
  action: TaskActions
) => {
  switch (action.type) {
    case FETCH_TASKS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_TASKS_LIST_SUCCESS: {
      const { tasksList, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = tasksList;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_TASKS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_TASK_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.taskLoading = LoadState.InProgress;
        draftState.task = initialTask;
      });
      return newState;
    }
    case FETCH_TASK_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.taskLoading = LoadState.Loaded;
        draftState.task = data;
      });
      return newState;
    }
    case FETCH_TASK_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.taskLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_TASK: {
      const newState = produce(state, (draftState) => {
        draftState.taskLoading = LoadState.NotLoaded;
        draftState.task = initialTask;
      });
      return newState;
    }

    default: {
      return state;
    }
  }
};