import moment, { Moment } from "moment";
import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";
import { ICSIORaw } from "../../csio/csio.types";
import { IHabitationalApplication} from "../habitationalApplication/habitationalApplicationNew.types";

export interface IHabitationalCSIOApplicationState {
  list: ICSIORaw[];
  csioData: IHabitationalApplication;
  totalRecords: number;
  loading: LoadState;
  error: string | null;
}

export const initialHabitationalCSIOApplicationState: IHabitationalCSIOApplicationState =
  {
    csioData: {
      customer_policy_id: 0,
      incremental_number: null,
      insured_company: null,
      billing_method: null,
      billing_account_number: null,
      company_type: null,
      binder_number: null,
      policy_number: 0,
      applicant_details: {
        applicant_name: null,
        applicant_address: null,
        applicant_address_two: null,
        applicant_country: null,
        applicant_city: null,
        applicant_province_or_state: null,
        applicant_postalcode: null,
        applicant_contact_name: null,
        applicant_contact_type: null,
        applicant_home: null,
        applicant_cell: null,
        applicant_business: null,
        applicant_fax: null,
        applicant_email: null,
        applicant_website: null,
      },
      applicant_preferred_language: null,
      broker_details: {
        broker_name: null,
        broker_address: null,
        broker_unit_or_suite: null,
        broker_city: null,
        broker_province_or_state: null,
        broker_country: null,
        broker_postal_or_zip_code: null,
        broker_contact_name: null,
        broker_home: null,
        broker_cell: null,
        broker_business: null,
        broker_fax: null,
        broker_email: null,
        broker_code: null,
        broker_sub_code: null,
        broker_client_id: null,
        broker_company_client_id: null,
        broker_group_name: null,
        broker_group_id: null,
      },
      policy_period_effective_date: moment().format("YYYY-MM-DD"),
      policy_period_effective_time: moment().format(),
      policy_period_expiry_date: moment().format("YYYY-MM-DD"),
      policy_period_expiry_time: moment().format(),
      applicant_data: [
        {
          key: getUniqueId(),
          applicant_name: null,
          occupation: null,
          years_continuously_employed: null,
          date_of_birth: moment().format("YYYY-MM-DD"),
        },
      ],
      loss_history: [
        {
          key: getUniqueId(),
          date_of_loss: "",
          loc_no: null,
          cause_of_loss: null,
          status: null,
          amount_paid: null,
          insurance_company_1: null,
          policy_number: null,
        },
      ],
      policy_history: [
        {
          key: getUniqueId(),
          insurance_company_2: null,
          policy_number: null,
          effective_date: "",
          end_date: "",
          reason_for_ending: null,
          if_terminated_by_insurer_reason: null,
        },
      ],
      cross_reference_information: [
        {
          key: getUniqueId(),
          line_of_business: null,
          policy_number: null,
        },
      ],
      risk_location_data: [
        {
          key: getUniqueId(),
          loc_no: null,
          risk_location_address: {
            address: null,
            unit_suite: null,
            city: null,
            province_or_state: null,
            postal_code: null,
            country: null,
          },
          risk_location_construction: {
            year_built: null,
            no_of_storeys: null,
            no_of_families: null,
            no_of_units: null,
            total_living_area: null,
            total_living_area_unit: null,
            access_type: null,
            smokers: null,
            replacement_cost_emulator_product: null,
            data_evaluation_completed: moment().format("YYYY-MM-DD"),
            date_of_birth_of_eldest_occupant: moment().format("YYYY-MM-DD"),
            relationship_to_applicant: null,
            occupancy_type: null,
            structure_type: null,
            foundation_type: null,
            finished_basement_percentage: null,
            exterior_wall_framing_type: null,
            exterior_wall_finish_type: null,
          },
          interior_wall_construction_type: [
            {
              key: getUniqueId(),
              type: null,
              percentage: null,
            },
          ],
          interior_wall_height: [
            {
              key: getUniqueId(),
              height: null,
              percentage: null,
            },
          ],
          interior_floor_finish_type: [
            {
              key: getUniqueId(),
              type: null,
              percentage: null,
            },
          ],
          ceiling_construction_type: [
            {
              key: getUniqueId(),
              type: null,
              percentage: null,
            },
          ],
          risk_location_upgrades: [
            {
              key: getUniqueId(),
              upgrade_name: "Roof",
              full_yy: null,
              partial_yy: null,
            },
            {
              key: getUniqueId(),
              upgrade_name: "Electrical",
              full_yy: null,
              partial_yy: null,
            },
            {
              key: getUniqueId(),
              upgrade_name: "Heating",
              full_yy: null,
              partial_yy: null,
            },
            {
              key: getUniqueId(),
              upgrade_name: "Plumbing",
              full_yy: null,
              partial_yy: null,
            },
          ],
          risk_location_services: {
            risk_location_roof_covering_type: null,
            risk_location_electrical_wiring_type: null,
            risk_location_electrical_panel_type: null,
            risk_location_service: null,
          },
          primary_heating_type: {
            apparatus: null,
            fuel: null,
            location: null,
            professionally_installed: null,
            approved_by_ulc_csa_or_wh: null,
          },
          auxiliary_heating_type: {
            apparatus: null,
            fuel: null,
            location: null,
            professionally_installed: null,
            approved_by_ulc_csa_or_wh: null,
            no_of_face_cords_per_year: null,
            solid_fuel_heating_questionaire_attached: null,
            radiant_heating_area: null,
            make: null,
            year: null,
            oil_tank_year: null,
            oil_tank_year_type: null,
            fuel_oil_tank_questionaire_attached: null,
          },
          plumbing_type: {
            copper_percentage: null,
            galvanized_percentage: null,
            abs_percentage: null,
            pvc_percentage: null,
            pex_percentage: null,
            poly_b_percentage: null,
            lead_percentage: null,
            other: null,
          },
          water_heater_type: {
            apparatus: null,
            water_heater_type: null,
            fuel: null,
            professionally_installed: null,
            approved_by_ulc_csa_or_wh: null,
          },
          primary_water_mitigation_type: {
            sump_pump_type: null,
            auxiliary_power: null,
            backup_value: null,
          },
          auxiliary_water_mitigation_type: {
            sump_pump_type: null,
            auxiliary_power: null,
            backup_value: null,
            main_water_value_shut_off_type: null,
            no_of_main_water_value_shut_off_sensor: null,
            sewer_backeup_questionaire_attached: null,
          },
          fire_protection: {
            distance_to_hydrant: null,
            hydrant_type: null,
            distance_to_responding_fire_hall: null,
            fire_hall_name: null,
          },
          security_system: {
            fire: null,
            burglary: null,
            smoke_detectors: null,
            smoke_detector_type: null,
            no_of_detectors: null,
            if_any_of_the_above_are_monitored_monitored_by: null,
            home_sprinklered: null,
            alarm_certificate: null,
            premises_type_security_system: null,
          },
          bathrooms: {
            no_of_full: null,
            no_of_half: null,
          },

          kitchens: {
            no_of_kitchens: null,
            kitchen_1_quality: null,
            kitchen_2_quality: null,
          },

          garage_or_carport: {
            no_of_cars: null,
            garage_type: null,
          },

          swimming_pool: {
            year: null,
            pool_type: null,
            pool_fenced: null,
          },
          detached_outbuildings_structures: [
            {
              key: getUniqueId(),
              number: null,
              year: null,
              structure_type: null,
              exterior_wall_framing_type: null,
              heating_apparatus_type: null,
              fuel_type: null,
              total_area: null,
              value: null,
            },
          ],
          risk_location_questions: {
            do_you_own_rent_more_than_one_location: null,
            number_of_weeks_location_rented_to_others: null,
            number_of_rooms_rented_to_others: null,
            renewable_energy_installation_on_premises: null,
            number_of_full_residence_employees: null,
            number_of_dogs_in_the_household: null,
            breed_of_dogs: null,
            total_property_area: null,
          },
          coverages_form: [
            {
              key: getUniqueId(),
              code: "",
              coverage_discription: null,
              deductible: null,
              requested_or_declined: null,
              amount_of_insurance: null,
              deductible_type: null,
              type1: null,
              type2: null,
              type3: null,
              type4: null,
              type5: null,
              premium: null,
            },
          ],
          liability_extension_and_exclusion_form: [
            {
              key: getUniqueId(),
              code: "",
              liability_coverage_discription: null,
              deductible: null,
              requested_or_declined: null,
              amount_of_insurance: null,
              deductible_type: null,
              type1: null,
              type2: null,
              type3: null,
              type4: null,
              type5: null,
              estimated_premium: null,
            },
          ],
          discount_and_surcharges_form: [
            {
              key: getUniqueId(),
              discount_or_surcharge_discription: null,
              percentage: null,
              applied_to_premium: null,
              est_discount_or_surchage: null,
            },
          ],
        },
      ],
      mortgage_or_loss_payee: [
        {
          key: getUniqueId(),
          loc_no: null,
          name: null,
          nature_of_interest: null,
          address: null,
          city: null,
          province_or_state: null,
          postal_or_zip_code: null,
        },
      ],

      liability_exposures: {
        daycare_operation_number_of_children: null,
        do_you_own_a_trampoline: {
          questionCd: null,
          yesNoCd: null,
        },
        do_you_have_a_garden_tractor: {
          questionCd: null,
          yesNoCd: null,
        },
        do_you_have_a_golf_cart: {
          questionCd: null,
          yesNoCd: null,
        },
        no_of_saddle_draft_animals: null,
        do_you_own_any_unlicensed_recreational_vehicles: {
          questionCd: null,
          yesNoCd: null,
        },
        do_you_own_any_watercrafts: {
          questionCd: null,
          yesNoCd: null,
        },
        is_there_a_co_occupant_that_requires_coverage: null,
        co_occupant_name: null,
        is_there_any_kind_of_business_operation: {
          questionCd: null,
          yesNoCd: null,
        },
        if_yes_describe_business: null,
        number_of_cannabis_plants_grown_on_premises: null,
        other_exposures: null,
      },

      address_form: [
        {
          key: getUniqueId(),
          serial_number: 1,
          address_type: null,
          address: null,
          unit_of_suit: null,
          state: null,
          city: null,
          postal_code: null,
          country: null,
          date_moved_in: "",
          date_moved_out: "",
        },
      ],
      attachments: [
        {
          key: getUniqueId(),
          attachments_serial_number: 1,
          attachments_date_completed: "",
          attachments_description: null,
          attachments_documents_link: null,
        },
        {
          key: getUniqueId(),
          attachments_serial_number: 2,
          attachments_date_completed: "",
          attachments_description: null,
          attachments_documents_link: null,
        },
        {
          key: getUniqueId(),
          attachments_serial_number: 3,
          attachments_date_completed: "",
          attachments_description: null,
          attachments_documents_link: null,
        },
        {
          key: getUniqueId(),
          attachments_serial_number: 4,
          attachments_date_completed: "",
          attachments_description: null,
          attachments_documents_link: null,
        },
        {
          key: getUniqueId(),
          attachments_serial_number: 5,
          attachments_date_completed: "",
          attachments_description: null,
          attachments_documents_link: null,
        },
        {
          key: getUniqueId(),
          attachments_serial_number: 6,
          attachments_date_completed: "",
          attachments_description: null,
          attachments_documents_link: null,
        },
      ],

      premium_info_type_of_payment_plan: null,
      premium_info_estimated_policy_premium: null,
      premium_info_provincial_sales_tax: null,
      premium_info_additional_charges: null,
      premium_info_total_estimated_cost: null,
      premium_info_amount_paid_with_application: null,
      premium_info_amount_still_due: null,
      premium_info_no_of_remaining_installments: null,
      premium_info_amount_of_each_installment: null,
      premium_info_installment_due_date: moment().format("YYYY-MM-DD"),
      payment_schedule: [
        {
          id: getUniqueId(),
          installment_number: "",
          installment_amount: "",
          installment_date: "",
        },
      ],
      premium_info_Remarks: null,
      status: "ACTIVE",
    },
    list: [],
    totalRecords: 0,
    loading: LoadState.Loaded,
    error: null,
  };
