import React, { useState } from "react";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
// import { StandadCard } from "../../../../../../../components/Cards";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { LoadState } from "../../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useFormik } from "formik";
// import { CustomerBaiscDetailsLayout } from "../";
import { Box, Button, Divider, Grid, MenuItem, Typography } from "@mui/material";
import {
  ControlledCustomSelect,
  CustomCheckBoxWithLabel,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { Breadcrumb } from "../../../../../../../layout/fullLayout/breadcrumb/Breadcrumb";
import { IBreadcrumbProps } from "../../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useNavigate, useParams } from "react-router-dom";
import { FileUpload } from "../../../../../../../components/FileUpload/FileUpload";
import {
  addHabitationalAttachmentAsync,
  clearHabitationalBasicPolicyState,
  fetchHabitationalAttachmentAsync,
} from "../../../../../../../redux/habitationalPolicy/habitationalBasicDetails/habitationalBasicDetailsActions";
import { StandadCard } from "../../../../../../../components/Cards";
import { IHabitationalAttachment } from "../../../../../../../redux/habitationalPolicy/habitationalBasicDetails/habitationalBasicDetails.types";
import { Dialog } from "../../../../../../../components/Dialogs/Dialog";
import { renderDropdownValue } from "../../../../../../../helpers";

interface IDialogAttachmentProps {
  open: boolean;
  onClose: () => void;
  onComplete: () => void;
  attachment: IHabitationalAttachment;
}

export const HabitationalAttachment: React.FC<IDialogAttachmentProps> = (
  props
) => {
  const { open, attachment, onClose, onComplete } = props;

  const { customerId, customerPolicyId } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
  };
  
  const { data: customerID } = useSelector(
    (storeState: IStoreState) => storeState.habitational.basicDetails.customerInfo
  );


  const [saveLoading, setSaveLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();
  const [file, setFile] = React.useState<any>(null);
  const navigate = useNavigate();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: attachment,
    validate: (values) => {},
    onSubmit: (values) => {
      //   changeToNextStep(values.subType);
      if (customerPolicyId && customerId) {
        setSaveLoading(true);
        dispatch(
          addHabitationalAttachmentAsync(
            {
              ...values,
              customer_policy_id: customerPolicyId,
            },
            customerID.customer_id,
            file,
            (isSuccess) => {
              if (isSuccess) {
                onClose();
                onComplete();
              }
              setSaveLoading(false);
            }
          )
        );
      }
    },
  });

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(
        fetchHabitationalAttachmentAsync(customerPolicyId)
      );
    }
  }, [customerPolicyId]);

  return (
    <Dialog
      open={open}
      title="Create/Edit Attachment"
      onClose={onClose}
      size="sm"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      actions={[
        { type: "button", label: "Close", variant: "text", onClick: onClose },
        {
          type: "submit",
          label: "Save",
          variant: "contained",
          disabled: saveLoading,
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <CustomFormLabel>Serial Number</CustomFormLabel>
          <CustomTextField
            name="serial_number"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.serial_number}
            disabled
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <CustomFormLabel>Document Name</CustomFormLabel>
          <CustomTextField
            name="document_name"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.document_name}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <CustomFormLabel>Description</CustomFormLabel>
          <CustomTextField
            name="description"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={values.description}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <CustomFormLabel>File Upload</CustomFormLabel>
          <FileUpload
            value={values.file_upload}
            onChange={(file) => setFile(file)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <CustomFormLabel>Status</CustomFormLabel>
          <CustomSelect
            variant="outlined"
            size="small"
            type="string"
            fullWidth
            name="status"
            value={values.status}
            onChange={handleChange}
            displayEmpty
            renderValue={renderDropdownValue(`Select one`)}
          >
            {["Active", "Inactive"].map((status) => {
              return <MenuItem value={status}>{status}</MenuItem>;
            })}
          </CustomSelect>
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomCheckBoxWithLabel
            label="Send Attachments"
            name="send_attachment"
            checked={values.send_attachment === 1}
            onChange={(e, checked) => {
              setFieldValue("send_attachment", checked ? 1 : 0);
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};
