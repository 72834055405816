import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../redux/initialStoreState";
import { useDispatchWrapper } from "./useDispatch";
import { fetchCommAutomobilePremiumAsync } from "../redux/commAutoPolicy/commAutoBasicDetails/commAutoBasicDetailsActions";

export const useCommAutoPremium = (customerPolicyId: number) => {
  const dispatch = useDispatchWrapper();
  const premium = useSelector(
    (storeState: IStoreState) => storeState.commAuto.basicDetails.premium
  );

  React.useEffect(() => {
    dispatch(fetchCommAutomobilePremiumAsync(customerPolicyId));
  }, [customerPolicyId]);

  return premium;
};