import produce from "immer";
import { TaskDriverActions } from ".";
import { IStoreState } from "../../initialStoreState";
import { initialTaskDriverState } from "./driver.types";
import { FETCH_ADD_DRIVER_FAILED, FETCH_ADD_DRIVER_PROGRESS, FETCH_ADD_DRIVER_SUCCESS, FETCH_DELETE_DRIVER_FAILED, FETCH_DELETE_DRIVER_PROGRESS, FETCH_DELETE_DRIVER_SUCCESS } from "./driverActions";
import { LoadState } from "../../../constants/enums";

export const taskDriverReducer = (
    state: IStoreState["tasks"]["driver"] = initialTaskDriverState,
    action: TaskDriverActions
  ) => {
    switch (action.type) {
        case FETCH_ADD_DRIVER_PROGRESS: {
            const newState = produce(state, draftState=>{
                draftState.addDriver.loading = LoadState.InProgress;
                draftState.addDriver = initialTaskDriverState['addDriver'];
            })
            return newState;
        }
        case FETCH_ADD_DRIVER_SUCCESS: {
            const { data } = action.payload;
            const newState = produce(state, draftState=>{
                draftState.addDriver.loading = LoadState.Loaded;
                draftState.addDriver.data = data;
            })
            return newState;
        }
        case FETCH_ADD_DRIVER_FAILED: {
            const newState = produce(state, draftState=>{
                draftState.addDriver.loading = LoadState.Failed;
            })
            return newState;
        }
        
        case FETCH_DELETE_DRIVER_PROGRESS: {
            const newState = produce(state, draftState=>{
                draftState.deleteDriver.deleteloading = LoadState.InProgress;
                draftState.deleteDriver = initialTaskDriverState['deleteDriver'];
            })
            return newState;
        }
        case FETCH_DELETE_DRIVER_SUCCESS: {
            const {data} = action.payload;
            const newState = produce(state, draftState=>{
                draftState.deleteDriver.deleteloading = LoadState.Loaded;
                draftState.deleteDriver.data = data;
            })
            return newState;
        }
        case FETCH_DELETE_DRIVER_FAILED: {
            const newState = produce(state, draftState=>{
                draftState.deleteDriver.deleteloading = LoadState.Failed;
            })
            return newState;
        }
        default:
            return state;
    }
  }