import React from "react";
import { IWhatsAppDialogProps } from "./WhatsAppDialog.types";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Button, Grid, } from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../formsComponents";
import { useFormik } from "formik";
import { RightPanel } from "../../RightPanel";
import { useDispatchWrapper } from "../../../hooks";
import {sendMessageToWhatsapp,
} from "../../../redux/common/commonActions";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../redux/initialStoreState";
import { ICreateTemplate } from "../../../redux/templates/templates.types";
import { api } from "../../../api/api";
import { fetchTemplateListAllTempsAsync } from "../../../redux/templates/templateActions";
import { QuillEditor } from "../../QuillEditor/QuillEditor";

export const WhatsAppDialog: React.FC<IWhatsAppDialogProps> = (props) => {
  const { mobileNumber } = props;
  const [open, setOpen] = React.useState(false);


  const handleToggleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <WhatsAppIcon
        color="success"
        fontSize="small"
        onClick={handleToggleOpen}
      />
      {open &&
        <WhatsAppRightPanel open={open} mobileNumber={mobileNumber} onClose={handleToggleOpen}/>

      }
 
      {/* {open && (
        <Dialog
          open={open}
          title="WhatsApp"
          onClose={handleToggleOpen}
          size="xs"
          contentWrappedWithForm={{ onSubmit: handleSubmit }}
          actions={[
            {
              type: "button",
              label: "Close",
              variant: "text",
              onClick: handleToggleOpen,
            },
            {
              type: "submit",
              label: "Send",
              variant: "contained",
            },
          ]}
        >
          <Grid container>
            <Grid item xs={12} md={12}>
              <CustomFormLabel>Mobile Number</CustomFormLabel>
              <Typography variant="body2">{mobileNumber}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <CustomFormLabel>Select Template</CustomFormLabel>
              <ControlledCustomSelect
                fullWidth
                value={values.privacy_act_consent}
                name="privacy_act_consent"
                onChange={handleChange}
                placeholder="Choose privacy act consent"
                displayEmpty
                options={[
                  "Yes",
                  "Yes Written",
                  "Yes Verbal",
                  "Consent Declined",
                ].map((consent) => {
                  return { label: consent, value: consent };
                })}
              ></ControlledCustomSelect>
            </Grid>
          </Grid>
        </Dialog>
      )} */}
    </>
  );
};


export const WhatsAppRightPanel: React.FC<{open: boolean; mobileNumber: string; onClose: ()=>void}> = ({
  open,
  onClose,
  mobileNumber
}) => {

  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();
  const [selectedTemplate, setSelectedTemplate] = React.useState("");
  const templates = useSelector(
    (storeState: IStoreState) => storeState.templates.list
  );
  const { values,setFieldValue, handleChange, handleSubmit } = useFormik({
    initialValues: {
      mobileNumber: mobileNumber,
      template: "",
      message: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      dispatch(
        sendMessageToWhatsapp(
          {
            mobileNumber: values.mobileNumber,
            message: values.message,
          },
          (isSucess) => {
            if (isSucess) {
              onClose();
            }
            setLoading(false);
          }
        )
      );
    },
  });

  React.useEffect(() => {
    dispatch(fetchTemplateListAllTempsAsync());
  }, []);

  React.useEffect(() => {
    const fetchTemplate = async (temp: ICreateTemplate) => {
      try {
        const res = await api.get(
          `/templates/get-templates?templates_id=${temp.templates_id}`
        );
        const list: ICreateTemplate[] = res.data.data;
        if (list.length > 0) {
          setFieldValue("message", list[0].body);
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (selectedTemplate) {
      const template = templates.find(
        (x) => x.template_name === selectedTemplate
      );
      if (template) {
        fetchTemplate(template);
      }
    }
  }, [selectedTemplate]);


  return(
    <>
       <RightPanel
          open={open}
          heading="Send Message on Whatsapp"
          subHeading="Connecting Through Whatsapp: Delivering Messages to Specified Mobile Numbers"
          onClose={onClose}
          isWrappedWithForm
          onFormSubmit={handleSubmit}
          actionButtons={() => {
            return (
              <Grid container spacing={3}>
               
                <Grid item xs={12} md={4}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                    fullWidth
                  >
                    Send
                  </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </Grid>
     
              </Grid>
            );
          }}
        >
          <Grid container>
            <Grid item xs={12} md={12}>
              <CustomFormLabel>Mobile Number(+1)</CustomFormLabel>

              <CustomTextField disabled fullWidth value={values.mobileNumber} />
            </Grid>
            <Grid item xs={12} md={12}>
      <CustomFormLabel>Template</CustomFormLabel>
          <ControlledCustomSelect
            fullWidth
            value={selectedTemplate}
            name="sub_mobules"
            onChange={(e) => setSelectedTemplate(e.target.value as string)}
            placeholder="Choose Template"
            displayEmpty
            options={templates.map((item) => {
              return { label: item.template_name, value: item.template_name };
            })}
          ></ControlledCustomSelect>
      </Grid>
      <Grid item xs={12} md={12}>
        <CustomFormLabel>Message</CustomFormLabel>

        <QuillEditor
            value={values.message}
            onChange={(html) => setFieldValue("message", html)}
          />
      </Grid>
          </Grid>
        </RightPanel>
    </>
  )
}
