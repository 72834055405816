import { LoadState } from "../../../constants/enums";

export interface IAccountingAccountsPayableChequeState {
  list: IAccountsPayableCheque[];
  listLoading: LoadState;
  data: IAccountsPayableCheque;
  loading: LoadState;
  error: string | null;
}

export interface IAccountsPayableCheque {
  accounts_payable_id: string | null;
  cheque_number: string | null;
  cheque_date: string | null;
  cheque_amount: string | null;
  cheque_voided: string | null;
  cheque_voided_date: string | null;
  cheque_printed: string | null;
  cheque_printed_date: string | null;
  cheque_cleared: string | null;
  cheque_cleared_date: string | null;
  clear_pay_y_n: string | null;
  clear_pay_code: string | null;
  clear_pay_branch_code: string | null;
  status: string;
}

export const initialAccountingAccountsPayableChequeState: IAccountingAccountsPayableChequeState =
  {
    list: [],
    listLoading: LoadState.NotLoaded,
    data: {
      accounts_payable_id: null,
      cheque_number: null,
      cheque_date: null,
      cheque_amount: null,
      cheque_voided: null,
      cheque_voided_date: null,
      cheque_printed: null,
      cheque_printed_date: null,
      cheque_cleared: null,
      cheque_cleared_date: null,
      clear_pay_y_n: null,
      clear_pay_code: null,
      clear_pay_branch_code: null,
      status: "ACTIVE",
    },
    loading: LoadState.NotLoaded,
    error: null,
  };
