import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialAccountingTransactionsState } from "./Transactions.types";
import { AccountingTransactionActions } from ".";
import {
  CLEAR_ACCOUNTING_TRANSACTION,
  FETCH_ACCOUNTING_TRANSACTIONS_FAILURE,
  FETCH_ACCOUNTING_TRANSACTIONS_LIST_FAILURE,
  FETCH_ACCOUNTING_TRANSACTIONS_LIST_PROGRESS,
  FETCH_ACCOUNTING_TRANSACTIONS_LIST_SUCCESS,
  FETCH_ACCOUNTING_TRANSACTIONS_PROGRESS,
  FETCH_ACCOUNTING_TRANSACTIONS_SUCCESS,
} from "./TransactionsActions";

export const AccountingTransactionsReducer = (
  state: IStoreState["accounting"]["transactions"] = initialAccountingTransactionsState,
  action: AccountingTransactionActions
) => {
  switch (action.type) {
    case FETCH_ACCOUNTING_TRANSACTIONS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_TRANSACTIONS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_TRANSACTIONS_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_TRANSACTIONS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_TRANSACTIONS_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Loaded;
        draftState.list = data;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_TRANSACTIONS_LIST_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_ACCOUNTING_TRANSACTION: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.NotLoaded;
        draftState.data = initialAccountingTransactionsState.data;
        draftState.error = null;
      });
      return newState;
    }

    default:
      return state;
  }
};
