import { LoadState } from "../../../constants/enums";

export interface ICompanyOnboardingState {
  data: ICompanyOnboarding;
  loading: LoadState;
  error: string | null;
}

export interface ICompanyOnboarding {
  company_id: number | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
  phone_telephone: string | null;
  fax: string | null;
  default_language: string | null;
  email: string | null;
  accounts_email: string | null;
  cl_email: string | null;
  pl_email: string | null;
  status: string;
}

export const initialAccountingCompanyOnboardingState: ICompanyOnboardingState =
  {
    data: {
      company_id: null,
      address: null,
      unit_or_suite: null,
      city: null,
      province_or_state: null,
      postal_code: null,
      country: null,
      phone_telephone: null,
      fax: null,
      default_language: null,
      email: null,
      accounts_email: null,
      cl_email: null,
      pl_email: null,
      status: "Active",
    },
    loading: LoadState.NotLoaded,
    error: null,
  };
