import { action } from "typesafe-actions";
import { ICSIOCron } from "./csioCron.types";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";

export const FETCH_CSIO_CRON_LIST_PROGRESS =
  "FETCH_CSIO_CRON_LIST_PROGRESS";
export const FETCH_CSIO_CRON_LIST_SUCCESS =
  "FETCH_CSIO_CRON_LIST_SUCCESS";
export const FETCH_CSIO_CRON_LIST_FAILURE =
  "FETCH_CSIO_CRON_LIST_FAILURE";

export const fetchCsioCronListProgress = () =>
  action(FETCH_CSIO_CRON_LIST_PROGRESS);

export const fetchCsioCronListSuccess = (data: ICSIOCron[]) =>
  action(FETCH_CSIO_CRON_LIST_SUCCESS, { data });

export const fetchCsioCronListFailed = (errorMessage: string) =>
  action(FETCH_CSIO_CRON_LIST_FAILURE, { errorMessage });

export const fetchCsioCronListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCsioCronListProgress());
      let finalUrl = `/csio/get-csio-cron?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;


      const res = await api.get(finalUrl);
      const data: ICSIOCron[] = res.data.data;
      dispatch(fetchCsioCronListSuccess(data));
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

  export const upsertCSIOCron =
  (
    data: ICSIOCron,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
     await api.post("/csio/upsert-csio-cron", {
        ...data,
      });

      dispatch(
        showMessage({
          type: "success",
          message: "Workflow node saved successfully",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

  export const CLEAR_CSIO_CRON_STATE = "CLEAR_CSIO_CRON_STATE";
  export const clearCsioCronState = () => action(CLEAR_CSIO_CRON_STATE);