import produce from "immer";
import { G4SignDocumentsActions } from ".";
import { IStoreState } from "../initialStoreState";
import { initialG4SignDocumentState } from "./g4SignDocuments.types";
import {
  ADD_G4SIGN_PLACEHOLDER,
  CLEAR_G4_SIGN_DOC_STATE,
  CLEAR_G4_SIGN_RECIPIENT,
  FETCH_G4SIGN_PLACEHOLDERS_FAILED,
  FETCH_G4SIGN_PLACEHOLDERS_PROGRESS,
  FETCH_G4SIGN_PLACEHOLDERS_SUCCESS,
  FETCH_G4SIGN_SIGNED_DOCUMENT_FAILED,
  FETCH_G4SIGN_SIGNED_DOCUMENT_PROGRESS,
  FETCH_G4SIGN_SIGNED_DOCUMENT_SUCCESS,
  FETCH_G4_SIGN_DOC_FAILED,
  FETCH_G4_SIGN_DOC_LIST_FAILED,
  FETCH_G4_SIGN_DOC_LIST_PROGRESS,
  FETCH_G4_SIGN_DOC_LIST_SUCCESS,
  FETCH_G4_SIGN_DOC_PROGRESS,
  FETCH_G4_SIGN_DOC_SUCCESS,
  FETCH_G4_SIGN_RECIPIENT_LIST_FAILED,
  FETCH_G4_SIGN_RECIPIENT_LIST_PROGRESS,
  FETCH_G4_SIGN_RECIPIENT_LIST_SUCCESS,
  ROLLBACK_G4SIGN_PLACEHOLDER,
} from "./g4SignDocumentsAction";
import { LoadState } from "../../constants/enums";
import { DOCU_SIGN_FIELD_TYPES } from "../../components/PdfViewer/enums";

export const g4SignDocumentsReducer = (
  state: IStoreState["g4Sign"]["documents"] = initialG4SignDocumentState,
  action: G4SignDocumentsActions
) => {
  switch (action.type) {
    case FETCH_G4_SIGN_DOC_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }
    case FETCH_G4_SIGN_DOC_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_G4_SIGN_DOC_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.list = [];
      });
      return newState;
    }
    case FETCH_G4SIGN_SIGNED_DOCUMENT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }
    case FETCH_G4SIGN_SIGNED_DOCUMENT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
      });
      return newState;
    }
    case FETCH_G4SIGN_SIGNED_DOCUMENT_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.list = [];
      });
      return newState;
    }
    case FETCH_G4_SIGN_DOC_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.documentLoading = LoadState.InProgress;
        draftState.document = initialG4SignDocumentState["document"];
      });
      return newState;
    }
    case FETCH_G4_SIGN_DOC_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.documentLoading = LoadState.Loaded;
        draftState.document = data;
      });
      return newState;
    }
    case FETCH_G4_SIGN_DOC_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.documentLoading = LoadState.Failed;
        draftState.document = initialG4SignDocumentState["document"];
        draftState.errorMessage = errorMessage;
      });
      return newState;
    }
    case FETCH_G4_SIGN_RECIPIENT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.recipientLoading = LoadState.InProgress;
        draftState.recipientList = [];
      });
      return newState;
    }
    case FETCH_G4_SIGN_RECIPIENT_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.recipientLoading = LoadState.Loaded;
        draftState.recipientList = data;
      });
      return newState;
    }
    case FETCH_G4_SIGN_RECIPIENT_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.recipientLoading = LoadState.Failed;
        draftState.recipientList = [];
      });
      return newState;
    }

    case FETCH_G4SIGN_PLACEHOLDERS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.editior.loading = LoadState.InProgress;
        draftState.editior.placeholders = [];
      });
      return newState;
    }
    case FETCH_G4SIGN_PLACEHOLDERS_SUCCESS: {
      const { list } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.editior.loading = LoadState.Loaded;
        draftState.editior.placeholders = list;
      });
      return newState;
    }
    case FETCH_G4SIGN_PLACEHOLDERS_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.editior.loading = LoadState.Failed;
        draftState.editior.placeholders = [];
      });
      return newState;
    }

    case ADD_G4SIGN_PLACEHOLDER: {
      const { data, isUpdate } = action.payload;
      const newState = produce(state, (draftState) => {
        if (isUpdate) {
          const index = state.editior.placeholders.findIndex(
            (x) => x.field_code === data.field_code
          );
          if (index > -1) {
            draftState.editior.placeholders[index] = { ...data };
            const type = data.field_type;
            if([DOCU_SIGN_FIELD_TYPES.SIGNATURE, DOCU_SIGN_FIELD_TYPES.FREE_SIGNATURE].includes(type)){
              for(const field of draftState.editior.placeholders){
                if(type === field.field_type){
                  field.size_category = data.size_category;
                }
              }
            }
          }
        } else {
      
          const previousField = state.editior.placeholders.find(x=>x.field_type=== data.field_type);
          if(previousField){
            data.size_category= previousField.size_category;
          }
          draftState.editior.placeholders.push(data);

        }
      });
    
      return newState;
    }
    case ROLLBACK_G4SIGN_PLACEHOLDER: {
      const { code } = action.payload;
      const newState = produce(state, (draftState) => {
        const index = state.editior.placeholders.findIndex(
          (x) => x.field_code === code
        );
        if (index > -1) {
          draftState.editior.placeholders.splice(index, 1);
        }
      });
      return newState;
    }

    case CLEAR_G4_SIGN_RECIPIENT: {
      const newState = produce(state, (draftState) => {
        draftState.recipientList = [];
        draftState.recipientLoading = LoadState.NotLoaded;
      });
      return newState;
    }

    case CLEAR_G4_SIGN_DOC_STATE: {
      return initialG4SignDocumentState;
    }

    default: {
      return state;
    }
  }
};
