import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { ERROR_MESSAGES } from "../../../constants/enums";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import {
  ICommAutoBinder,
  ICommAutoPolicyMarketingBinderPdf,
  ICommAutomobileMarketingHistory,
} from "./commAutoMarketingHistory.types";
import { IFileUpload } from "../../../components/FileUpload/FileUpload.type";
import { getUniqueId } from "../../../helpers";
import { uploadMultipleFile } from "../../../components/FileUpload/utils";
import { IMail } from "../../common/common.types";
import { IInsurerUnderWriter } from "../../insurer/insurer.types";

export const commAutoPolicyPreviewMarketingAsync =
  (
    data: ICommAutoPolicyMarketingBinderPdf,
    onCallback: (isSuccess: boolean, html?: any) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.post(`/policy/market-binder-preview-or-pdf`, data);

      onCallback(true, res.data);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    }
  };

export const commAutoPolicyGenerateMarketCoveragePdfAsync =
  (
    data: ICommAutoPolicyMarketingBinderPdf,
    onCallback: (isSuccess: boolean, data?: any) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post(`/policy/market-binder-preview-or-pdf`, data);

      onCallback(true, res?.data);
      //dispatch(fetchBusinessMarketingAttachmentsAsync(data));
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    }
    finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const commAutoPolicyBinderSendCoverageMailAndHistAsync =
  (
    data: ICommAutoPolicyMarketingBinderPdf,
    keys: Array<string>,
    bccEmail: string,
    subject: string,
    text: string,
    customerMail: string,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const bufferJson = await api.post("/general/download-files", {
        type: "json",
        keys,
      });
      const mailPayload: IMail = {
        from_email: null,
        from_name: null,
        emails: [customerMail],
        bcc: bccEmail ? [bccEmail] : [],
        cc: [],
        subject: subject,
        body: text,
        templateName: null,
        objectVariables: {},
        attachments: bufferJson?.data?.map((ele: any, index: number) => ({
          content: ele,
          filename: keys[index].split("/").at(-1),
        })),
      };

      const historyPayload: ICommAutomobileMarketingHistory[] = [];

      // mailPayload.attachments = attachmentResponse.data;
      // const a = Buffer.from(attachmentResponse.data);
      // console.log(a);

      await api.post("/general/send-email", mailPayload);

      for (const payload of historyPayload) {
        // await api.post(`/policy/edit-policy-commauto-add-marketing-history`, payload);
      }
      dispatch(
        showMessage({
          type: "success",
          message: "Email and Marketing history saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    }
  };

export const commAutoPolicySendCoverageMailAndHistAsync =
  (
    data: ICommAutoPolicyMarketingBinderPdf,
    keys: Array<string>,
    bccEmail: string,
    subject: string,
    text: string,
    underWriters: IInsurerUnderWriter[],
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const bufferJson = await api.post("/general/download-files", {
        type: "json",
        keys,
      });
      const mailPayload: IMail = {
        from_email: null,
        from_name: null,
        emails: [],
        bcc: bccEmail ? [bccEmail] : [],
        cc: [],
        subject: subject,
        body: text,
        templateName: null,
        objectVariables: {},
        attachments: bufferJson?.data?.map((ele: any, index: number) => ({
          content: ele,
          filename: keys[index].split("/").at(-1),
        })),
      };

      const historyPayload: ICommAutomobileMarketingHistory[] = [];

      // mailPayload.attachments = attachmentResponse.data;
      // const a = Buffer.from(attachmentResponse.data);
      // console.log(a);

      for (const item of underWriters) {
        mailPayload.emails.push(item.email);
        historyPayload.push({
          customer_policy_id: data.customer_policy_id,
          quote_number: null,
          serial_number: null,
          premium: null,
          date_received: null,
          attachment: [],
          notes: null,
          status: "ACTIVE",
          underwriter: item.name,
          insurer: item.insurer_code,
          submission_date: item.insert_ts,
          effective_date: null,
          quote_status: "Submitted",
          customer_name: "",
        });
      }
      await api.post("/general/send-email", mailPayload);

      for (const payload of historyPayload) {
        await api.post(
          `/policy/edit-policy-commauto-add-marketing-history`,
          payload
        );
      }
      dispatch(
        showMessage({
          type: "success",
          message: "Email and Marketing history saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    }
  };

export const FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_FAILED";

export const fetchCommAutomobilePolicyMarketingHistListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_PROGRESS);
export const fetchCommAutomobilePolicyMarketingHistListSuccess = (
  data: ICommAutomobileMarketingHistory[]
) =>
  action(FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_SUCCESS, { data });
export const fetchCommAutomobilePolicyMarketingHistListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_FAILED);

export const fetchCommAutomobilePolicyMarketingHistListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobilePolicyMarketingHistListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-add-marketing-history?customer_policy_id=${customerPolicyId}`
      );
      const data: ICommAutomobileMarketingHistory[] = res.data.data;
      dispatch(fetchCommAutomobilePolicyMarketingHistListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobilePolicyMarketingHistListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addCommAutomobilePolicyMarketingHistAsync =
  (
    data: ICommAutomobileMarketingHistory,
    customerId: number,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        customer_id: customerId,
        policy_type: "COMMAUTO",
        customer_policy_id: data.customer_policy_id,
        page_name: "Marketing",
      };
      const { paths } = await uploadMultipleFile(
        data.attachment,
        "POLICY",
        asPayload
      );
      await api.post(`/policy/edit-policy-commauto-add-marketing-history`, {
        ...data,
        attachment: paths,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Marketing History saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_FAILED =
  "FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_FAILED";

export const fetchCommAutomobilePolicyMarketingHistProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_PROGRESS);
export const fetchCommAutomobilePolicyMarketingHistSuccess = (
  data: ICommAutomobileMarketingHistory
) => action(FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_SUCCESS, { data });
export const fetchCommAutomobilePolicyMarketingHistFailed = (
  errorMessage: string
) =>
  action(FETCH_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY_FAILED, {
    errorMessage,
  });

export const fetchCommAutomobilePolicyMarketingHistAsync =
  (
    customerPolicyId: number,
    incrementNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobilePolicyMarketingHistProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-add-marketing-history?customer_policy_id=${customerPolicyId}&column=serial_number&value=${incrementNumber}`
      );
      const data: ICommAutomobileMarketingHistory[] = res.data.data;
      if (data.length > 0) {
        const attachments: IFileUpload[] = [];
        for (const item of data[0].attachment as any) {
          attachments.push({
            file: null,
            key: getUniqueId(),
            path: item,
          });
        }
        dispatch(
          fetchCommAutomobilePolicyMarketingHistSuccess({
            ...data[0],
            attachment: attachments,
          })
        );
      } else {
        dispatch(
          fetchCommAutomobilePolicyMarketingHistFailed(
            ERROR_MESSAGES.POLICY_NOT_FOUND
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobilePolicyMarketingHistFailed(
          ERROR_MESSAGES.SERVER_ERROR
        )
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_POLICY_MARKETING_STATE =
  "CLEAR_COMM_AUTOMOBILE_POLICY_MARKETING_STATE";
export const CLEAR_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY =
  "CLEAR_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY";

export const clearCommAutonmobilePolicyMarketingState = () =>
  action(CLEAR_COMM_AUTOMOBILE_POLICY_MARKETING_STATE);
export const clearCommAutomobilePolicyMarketingHistory = () =>
  action(CLEAR_COMM_AUTOMOBILE_POLICY_MARKETING_HISTORY);

export const FETCH_COMMAUTO_POLICY_BINDER_LIST_PROGRESS =
  "FETCH_COMMAUTO_POLICY_BINDER_LIST_PROGRESS";
export const FETCH_COMMAUTO_POLICY_BINDER_LIST_SUCCESS =
  "FETCH_COMMAUTO_POLICY_BINDER_LIST_SUCCESS";
export const FETCH_COMMAUTO_POLICY_BINDER_LIST_FAILED =
  "FETCH_COMMAUTO_POLICY_BINDER_LIST_FAILED";

export const fetchCommAutoPolicyBindertListProgress = () =>
  action(FETCH_COMMAUTO_POLICY_BINDER_LIST_PROGRESS);
export const fetchCommAutoPolicyBindertListSuccess = (
  data: ICommAutoBinder[]
) => action(FETCH_COMMAUTO_POLICY_BINDER_LIST_SUCCESS, { data });
export const fetchCommAutoPolicyBindertListFailed = () =>
  action(FETCH_COMMAUTO_POLICY_BINDER_LIST_FAILED);

export const fetchCommAutoPolicyBinderistListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutoPolicyBindertListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-binder-details?customer_policy_id=${customerPolicyId}`
      );
      const data: ICommAutoBinder[] = res.data.data;
      dispatch(fetchCommAutoPolicyBindertListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutoPolicyBindertListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTO_POLICY_MARKETING_STATE =
  "CLEAR_COMM_AUTO_POLICY_MARKETING_STATE";
export const CLEAR_COMM_AUTO_POLICY_MARKETING_HISTORY =
  "CLEAR_COMM_AUTO_POLICY_MARKETING_HISTORY";

export const clearCommAutoPolicyMarketingState = () =>
  action(CLEAR_COMM_AUTO_POLICY_MARKETING_STATE);
export const clearCommAutoPolicyMarketingHistory = () =>
  action(CLEAR_COMM_AUTO_POLICY_MARKETING_HISTORY);
