import produce from "immer";
import { LoadState } from "../../../constants/enums";
import { IStoreState } from "../../initialStoreState";
import { defaultLifeInsurancePolicyFamilyTree } from "./lifeInsurancePolicyFamilyTree.types";
import { LifeInsurancePolicyFamilyTreeActions } from ".";
import {
  CLEAR_LIFE_INSURANCE_FAMILY_TREE,
  CLEAR_LIFE_INSURANCE_FAMILY_TREE_STATE,
  FETCH_LIFE_INSURANCE_FAMILY_TREE_FAILED,
  FETCH_LIFE_INSURANCE_FAMILY_TREE_LIST_FAILED,
  FETCH_LIFE_INSURANCE_FAMILY_TREE_LIST_PROGRESS,
  FETCH_LIFE_INSURANCE_FAMILY_TREE_LIST_SUCCESS,
  FETCH_LIFE_INSURANCE_FAMILY_TREE_PROGRESS,
  FETCH_LIFE_INSURANCE_FAMILY_TREE_SUCCESS,
} from "./lifeInsurancePolicyFamilyTreeActions";

export const lifeInsurancePolicyFamilyTreeReducer = (
  state: IStoreState["lifeInsurance"]["familyTree"] = defaultLifeInsurancePolicyFamilyTree,
  action: LifeInsurancePolicyFamilyTreeActions
) => {
  switch (action.type) {
    case FETCH_LIFE_INSURANCE_FAMILY_TREE_LIST_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_LIFE_INSURANCE_FAMILY_TREE_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_LIFE_INSURANCE_FAMILY_TREE_LIST_FAILED: {
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.Failed;
        draftState.data = [];
      });
      return newState;
    }

    case FETCH_LIFE_INSURANCE_FAMILY_TREE_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.familyLoading = LoadState.InProgress;
        draftState.familyData =
          defaultLifeInsurancePolicyFamilyTree["familyData"];
      });
      return newState;
    }
    case FETCH_LIFE_INSURANCE_FAMILY_TREE_SUCCESS: {
      const { familyData } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.familyLoading = LoadState.Loaded;
        draftState.familyData = familyData;
      });
      return newState;
    }
    case FETCH_LIFE_INSURANCE_FAMILY_TREE_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.familyLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_LIFE_INSURANCE_FAMILY_TREE: {
      const newState = produce(state, (draftState: any) => {
        draftState.familyData =
          defaultLifeInsurancePolicyFamilyTree["familyData"];
        draftState.familyLoading = LoadState.NotLoaded;
      });
      return newState;
    }
    case CLEAR_LIFE_INSURANCE_FAMILY_TREE_STATE: {
      return defaultLifeInsurancePolicyFamilyTree;
    }
    default: {
      return state;
    }
  }
};
