import produce from "immer";
import { AccountingAccountsPayableChequeActions } from ".";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialAccountingAccountsPayableState } from "../AccountsPayable/AccountsPayable.types";
import {
  CLEAR_ACCOUNTING_ACCOUNTS_PAYABLE_CHEQUE,
  FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_CHEQUE_FAILURE,
  FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_CHEQUE_LIST_FAILURE,
  FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_CHEQUE_LIST_PROGRESS,
  FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_CHEQUE_LIST_SUCCESS,
  FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_CHEQUE_PROGRESS,
  FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_CHEQUE_SUCCESS,
} from "./AccountsPayableChequeActions";
import { initialAccountingAccountsPayableChequeState } from "./AccountsPayableCheque.types";

export const AccountingAccountsPayableChequeReducer = (
  state: IStoreState["accounting"]["accountsPayableCheque"] = initialAccountingAccountsPayableChequeState,
  action: AccountingAccountsPayableChequeActions
) => {
  switch (action.type) {
    case FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_CHEQUE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_CHEQUE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_CHEQUE_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_CHEQUE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_CHEQUE_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Loaded;
        draftState.list = data;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_CHEQUE_LIST_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_ACCOUNTING_ACCOUNTS_PAYABLE_CHEQUE: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.NotLoaded;
        draftState.data = initialAccountingAccountsPayableChequeState.data;
        draftState.error = null;
      });
      return newState;
    }

    default:
      return state;
  }
};
