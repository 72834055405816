import React from "react";

import { useFormik } from "formik";
import { IExpressionValidateRightPanelProps } from "./ExpressionvalidateRightPanel.types";
import { useDispatchWrapper } from "../../hooks";
import { RightPanel } from "../RightPanel";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { ErrorOutline, PublishedWithChanges, Save } from "@mui/icons-material";
import { CustomFormLabel, CustomTextField } from "../formsComponents";
import { useSelector } from "react-redux";
import { IStoreState } from "../../redux/initialStoreState";
import {
  upsertExpressionFunctionsAsync,
  validateExpression,
} from "../../redux/expressionTransformation/expressionTransformationActions";
import produce from "immer";
import { IExpressionValidatePayload } from "../../redux/expressionTransformation/expressionTransformation.types";
import { CustomAlert } from "../formsComponents/CustomAlert";

export const ExpressionValidateRightPanel: React.FC<
  IExpressionValidateRightPanelProps
> = (props) => {
  const { open, data, onComplete, onClose } = props;
  const { data: variablesList } = useSelector(
    (storeState: IStoreState) => storeState.expressionTransformation.variables
  );
  const tableName = useSelector(
    (storeState: IStoreState) =>
      storeState.expressionTransformation.selectedTableName
  );
  const [message, setMessage] = React.useState<{
    type: string;
    content: string;
  } | null>(null);
  const dispatch = useDispatchWrapper();

  const [values, setValues] = React.useState<IExpressionValidatePayload>({
    eval_string: "",
    libraries: [],
    external_variables: {},
  });

  const handleSaveAndValidate = () => {
    setMessage(null);
    dispatch(
      upsertExpressionFunctionsAsync(
        { ...data, table_name: tableName },
        (error?: string) => {
          if (!error) {
            onComplete();
          }
          // } else {
          //    setMessage(error);
          // }
        }
      )
    );
    // dispatch(
    //   validateExpression({...values, eval_string: values.eval_string.replace(/:[a-zA-Z0-9_]+/g, '')}, (isSuccess, message) => {
    //     if (isSuccess) {
    //       dispatch(
    //         upsertExpressionFunctionsAsync(
    //           { ...data, table_name: tableName },
    //           (error?: string) => {
    //             if (!error) {
    //               onComplete();
    //             }
    //             // } else {
    //             //    setMessage(error);
    //             // }
    //           }
    //         )
    //       );
    //     }
    //     else{
    //       setMessage({ type: "error", content: message });
    //     }
    //   })
    // );
  };

  const handleValidate = () => {
    dispatch(
      validateExpression({...values, eval_string: values.eval_string.replace(/:[a-zA-Z0-9_]+/g, '')}, (isSuccess, message) => {
        if (isSuccess) {
          setMessage({ type: "info", content: message });
        } else {
          setMessage({ type: "error", content: message });
        }
      })
    );
  };

  const handleVarChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValues = produce(values, (draftValues) => {
        draftValues.external_variables[key] = e.target.value;
      });
      setValues(newValues);
    };

  React.useEffect(() => {
    const words = data.eval_string.split(/[^a-zA-Z0-9_]+/);

    // Filter only those that exist in the variables array
    const result = words.filter((word) =>
      variablesList.some((variable) => variable.csio_edi_groupcode_definition_code === word)
    );
    const newValues = produce(values, (draftvalues) => {
      draftvalues.eval_string = data.eval_string;
      draftvalues.libraries = data.libraries as string[];
      draftvalues.external_variables = {};
      for (const variable of result) {
        draftvalues.external_variables[variable] = "";
      }
    });

    setValues(newValues);
  }, []);

  return (
    <RightPanel
      open={open}
      heading="Validate"
      onClose={onClose}
      drawerProps={{ zIndex: 1500 }}
      actionButtons={() => {
        return (
          <Stack spacing={2} direction={"row"}>
            <Button
              variant="contained"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              type="button"
              // disabled={loading}
              onClick={handleValidate}
              startIcon={<PublishedWithChanges />}
            >
              Validate
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={handleSaveAndValidate}
              // disabled={loading}
              startIcon={<Save />}
            >
              Validate & Save
            </Button>
          </Stack>
        );
      }}
    >
      <Typography variant="h4">Fill the parameters</Typography>
      {message && (
        <CustomAlert
         variant="filled"
          severity={message.type as any}
          sx={{color: '#fff'}}
       
          onClose={() => setMessage(null)}
        >
          {message.content}
        </CustomAlert>
      )}
      {Object.keys(values.external_variables).length === 0 &&
        <Box
        minHeight={500}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box display={"flex"} justifyContent={"center"} mb={1}>
          <ErrorOutline />
        </Box>
        <Box
          display={"flex"}
          justifyContent={"center"}
          width={"80%"}
        >
          <Typography
            variant="body1"
            textAlign={"center"}
            fontWeight={600}
          >
           There is no parameter found for input from your code. Please click 'Save' to proceed further.
          </Typography>
        </Box>
      </Box>
      }
      <Grid container spacing={1}>
        {Object.keys(values.external_variables).map((variableKey, index) => {
          const value = values.external_variables[variableKey];
          return (
            <Grid key={index} item xs={12}>
              <CustomFormLabel>{variableKey}</CustomFormLabel>
              <CustomTextField
                fullWidth
                value={value}
                variant="outlined"
                onChange={handleVarChange(variableKey)}
              />
            </Grid>
          );
        })}
      </Grid>
    </RightPanel>
  );
};
