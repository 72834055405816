import moment from "moment";
import { getUniqueId } from "../../../helpers";
import {
  AUTOMOBILE_CSIO_BILLING_METHOD,
  AUTOMOBILE_CSIO_LOSS,
  AUTOMOBILE_CSIO_PAYMENT_COVRAGE,
  CSIO_ALARM_DESC_TYPE,
  CSIO_ALARM_TYPE,
  CSIO_CERTIFYING_ENTITY,
  CSIO_CONSTRUCTION_QUALITY,
  CSIO_CONSTRUCTION_TYPE,
  CSIO_DRIVER_RELATIONSHIP,
  CSIO_ELECTRICAL_PANEL,
  CSIO_FIRE_PROTECTION_TYPE,
  CSIO_FOUNDATION_TYPE,
  CSIO_FUEL_TYPE,
  CSIO_GARAGE_TYPE,
  CSIO_HEATING_TYPE,
  CSIO_INSTALLATION_TYPE,
  CSIO_INSURED_PRINCIPAL_ROLE,
  CSIO_LINE_OF_BUSINESS,
  CSIO_LINE_OF_BUSINESS_SUBCODE,
  CSIO_NATURE_OF_INTEREST,
  CSIO_NO_OF_STOREYS,
  CSIO_OCCUPANCT_TYPE,
  CSIO_PLUMBING_TYPE,
  CSIO_POLICY_NAME,
  CSIO_POLICY_TERMINATED,
  CSIO_POOL_CONSTRUCTION_TYPE,
  CSIO_PREMISES_SECURITY_TYPE,
  CSIO_RESIDENCE_TYPE,
  CSIO_ROOF_MATERIAL,
  CSIO_SMOKE_DETECTION_TYPE,
  CSIO_STRUCTURE_TYPE,
  CSIO_WIRING_TYPE,
  HABITATIONAL_CSIO_COVERAGE,
  HABITATIONAL_CSIO_DISCOUNT_COVERAGE,
  HABITATIONAL_CSIO_PACKAGE_OTHER,
} from "../../../constants/constants";
import { IHabitationalApplicantData,
  IHabitationalAddressForm,
  IHabitationalCrossReferrence,
  IHabitationalCoverage,
  IHabitationalPremiumPaymentSchedule,
  IHabitationalDetachedOutbuildingStructure,
  IHabitationalDiscountSurcharge,
  IHabitationalLiabilityCoverage,
  IHabitationalLossHistory,
  IHabitationalMortgageLossPayee,
  IHabitationalPolicyHistory,
  IHabitationalRiskLocationData,
  IHabitationalApplication,
 } from "../habitationalApplication/habitationalApplicationNew.types";

export const transformCSIOToHabitationalApp = (
  parsedJSON: any
): IHabitationalApplication => {
  const homePolicyAddRs = parsedJSON.ACORD.InsuranceSvcRs.HomePolicyAddRs || parsedJSON.ACORD.InsuranceSvcRs.HomePolicyModRs;

  const {
    PersPolicy,
    Producer: { ProducerInfo },
    InsuredOrPrincipal
  } = homePolicyAddRs;

  let PersPolicyQuestions = Array.isArray(
    homePolicyAddRs?.PersPolicy?.QuestionAnswer
  )
    ? homePolicyAddRs?.PersPolicy?.QuestionAnswer
    : [homePolicyAddRs?.PersPolicy?.QuestionAnswer];

  let insuredOrPrincipal =  Array.isArray(InsuredOrPrincipal)
  ? InsuredOrPrincipal
  : [InsuredOrPrincipal];

  const applicantDetails = {
    applicant_name: "",
    applicant_address: "",
    applicant_address_two: "",
    applicant_country: "",
    applicant_city: "",
    applicant_province_or_state: "",
    applicant_postalcode: "",
    applicant_contact_name: "",
    applicant_contact_type: "",
    applicant_home: "",
    applicant_cell: "",
    applicant_business: "",
    applicant_fax: "",
    applicant_email: "",
    applicant_website: "",
  };
  const names: any[] = [];
  insuredOrPrincipal.forEach((detail: any) => {
    const nameInfo = detail?.GeneralPartyInfo?.NameInfo;
    const commercialName = nameInfo?.CommlName;
    const personName = nameInfo?.PersonName;
    if (personName) {
      const fullName = `${personName?.GivenName} ${personName?.Surname}`.trim();
      names.push(fullName);
    }
    if (commercialName) {
      names.push(commercialName?.CommercialName);
    }

    const addr = detail?.GeneralPartyInfo?.Addr;
    if (addr) {
      applicantDetails.applicant_address = addr?.Addr1;
      applicantDetails.applicant_address_two = addr?.Addr2;
      applicantDetails.applicant_city = addr?.City;
      applicantDetails.applicant_province_or_state = addr?.StateProvCd;
      applicantDetails.applicant_postalcode = addr?.PostalCode;
      applicantDetails.applicant_country = addr?.CountryCd;
    }
    const phoneInfo = Array.isArray(
      detail?.GeneralPartyInfo?.Communications?.PhoneInfo
    )
      ? detail?.GeneralPartyInfo?.Communications?.PhoneInfo
      : [detail?.GeneralPartyInfo?.Communications?.PhoneInfo];
    phoneInfo.forEach((phone: any) => {
      if (phone?.CommunicationUseCd === "Alternate") {
        applicantDetails.applicant_cell = phone?.PhoneNumber;
      }
      if (phone?.CommunicationUseCd === "Home") {
        applicantDetails.applicant_home = phone?.PhoneNumber;
      }
      if (phone?.CommunicationUseCd === "Fax") {
        applicantDetails.applicant_fax = phone?.PhoneNumber;
      }
      if (phone?.CommunicationUseCd === "Business") {
        applicantDetails.applicant_cell = phone?.PhoneNumber;
        applicantDetails.applicant_business = phone?.PhoneNumber;
      }
    });
    if (detail) {
      applicantDetails.applicant_contact_type = 
        CSIO_INSURED_PRINCIPAL_ROLE[detail.InsuredOrPrincipalInfo?.InsuredOrPrincipalRoleCd] || "";
    }
    applicantDetails.applicant_email =
      detail?.GeneralPartyInfo?.Communications?.EmailInfo?.EmailAddr || "";
    applicantDetails.applicant_website =
      detail?.GeneralPartyInfo?.Communications?.WebsiteInfo?.WebsitUrl || "";
  });

  applicantDetails.applicant_name = names.join(" & ");
  applicantDetails.applicant_contact_name = names.join(" & ");

  // Applicant Data

  const describedApplicantData: IHabitationalApplicantData[] = [];
  for (const applicant of insuredOrPrincipal) {
    const Name = applicant?.GeneralPartyInfo?.NameInfo?.PersonName?.GivenName;
    const SurName = applicant?.GeneralPartyInfo?.NameInfo?.PersonName?.Surname;
    const ApplicantName = `${Name} ${SurName}`;
    const applicantName =
      applicant?.GeneralPartyInfo?.NameInfo?.CommlName?.CommercialName ||
      ApplicantName;
    describedApplicantData.push({
      key: getUniqueId(),
      applicant_name: applicantName || "",
      occupation:
        applicant?.InsuredOrPrincipalInfo?.PersonInfo?.OccupationClassCd || "",
      years_continuously_employed:
        applicant?.InsuredOrPrincipalInfo?.PersonInfo?.LengthTimeEmployed
          ?.NumUnits || "",
      date_of_birth:
        applicant?.InsuredOrPrincipalInfo?.PersonInfo?.BirthDt || "",
    });
  }

  // Loss of History
  const persPolicyLossList = Array.isArray(PersPolicy?.Loss)
    ? PersPolicy.Loss
    : [PersPolicy?.Loss];
  const describedLossHistory: IHabitationalLossHistory[] = [];

  for (const previousLoss of persPolicyLossList) {
    describedLossHistory.push({
      key: getUniqueId(),
      date_of_loss: previousLoss?.LossDt || "",
      loc_no: previousLoss?.ItemIdInfo || "",
      cause_of_loss: AUTOMOBILE_CSIO_LOSS[previousLoss?.LossCauseCd] || "",
      status: previousLoss?.ClaimStatusCd || "",
      amount_paid: previousLoss?.LossPayment?.TotalPaidAmt?.Amt || "",
      insurance_company_1: previousLoss?.["csio:CompanyCd"] || "",
      policy_number: previousLoss?.PolicyNumber || "",
    });
  }

  // Policy History
  const describedPolicyHistory: IHabitationalPolicyHistory[] = [];

  const OtherOrPriorPolicyList = Array.isArray(PersPolicy?.OtherOrPriorPolicy)
    ? PersPolicy.OtherOrPriorPolicy
    : [PersPolicy?.OtherOrPriorPolicy];
  for (const OtherPolicy of OtherOrPriorPolicyList) {
    describedPolicyHistory.push({
      key: getUniqueId(),
      insurance_company_2: OtherPolicy?.["csio:CompanyCd"],
      policy_number: OtherPolicy?.PolicyNumber,
      effective_date: OtherPolicy?.ContractTerm?.EffectiveDt,
      end_date: OtherPolicy?.ContractTerm?.ExpirationDt,
      reason_for_ending:
        CSIO_POLICY_TERMINATED[OtherPolicy?.PolicyTerminatedCd],
      if_terminated_by_insurer_reason:
        OtherPolicy?.["csio:CancelledDeclinedCd"],
    });
  }

  // Cross Reference
  const describedCrossReference: IHabitationalCrossReferrence[] = [];
  for (const crossRef of OtherOrPriorPolicyList) {
    switch (crossRef?.PolicyCd) {
      case "Prior":
        describedCrossReference.push({
          key: getUniqueId(),
          line_of_business:
            CSIO_LINE_OF_BUSINESS[crossRef?.LOBCd] ||
            CSIO_LINE_OF_BUSINESS_SUBCODE[crossRef?.LOBCd],
          policy_number: crossRef?.PolicyNumber,
        });
        break;
      case "Other":
        describedCrossReference.push({
          key: getUniqueId(),
          line_of_business:
            CSIO_LINE_OF_BUSINESS[crossRef?.LOBCd] ||
            CSIO_LINE_OF_BUSINESS_SUBCODE[crossRef?.LOBCd],
          policy_number: crossRef?.PolicyNumber,
        });
        break;
      default:
        describedCrossReference.push({
          key: getUniqueId(),
          line_of_business: null,
          policy_number: null,
        });
        break;
    }
  }
  // Risk Location

  const riskLocationData: IHabitationalRiskLocationData[] = [];
  let DwellList = Array.isArray(homePolicyAddRs?.HomeLineBusiness?.Dwell)
    ? homePolicyAddRs?.HomeLineBusiness?.Dwell
    : [homePolicyAddRs?.HomeLineBusiness?.Dwell];

  let Location = Array.isArray(homePolicyAddRs?.Location)
    ? homePolicyAddRs?.Location
    : [homePolicyAddRs?.Location];

  for (let i = 0; i < DwellList.length; i++) {
    const riskLocation = DwellList[i];
    const location = Location[i];
    const serviceNumunits =
      riskLocation?.Construction?.ElectricalStrength?.NumUnits || "";
    const serviceunitmeasured =
      riskLocation?.Construction?.ElectricalStrength?.UnitMeasurementCd || "";
    const riskLocationServices = `${serviceNumunits} ${serviceunitmeasured}`;

    let heatingUnitInfo = Array.isArray(riskLocation?.HeatingUnitInfo)
      ? riskLocation?.HeatingUnitInfo
      : [riskLocation?.HeatingUnitInfo];

    let primary_heating_type = {
      apparatus: "",
      fuel: "",
      location: "",
      professionally_installed: "",
      approved_by_ulc_csa_or_wh: "",
    };

    let auxiliary_heating_type = {
      apparatus: "",
      fuel: "",
      location: "",
      professionally_installed: "",
      approved_by_ulc_csa_or_wh: "",
      no_of_face_cords_per_year: "",
      solid_fuel_heating_questionaire_attached: "",
      radiant_heating_area: "",
      make: "",
      year: "",
      oil_tank_year: "",

      oil_tank_year_type: "",
      fuel_oil_tank_questionaire_attached: "",
    };

    let water_heater_type = {
      apparatus: "",
      water_heater_type: "",
      fuel: "",
      professionally_installed: "",
      approved_by_ulc_csa_or_wh: "",
    };

    for (let unit of heatingUnitInfo) {
      if (unit?.UseCd === "csio:PRI") {
        primary_heating_type = {
          apparatus: CSIO_HEATING_TYPE[unit?.HeatingUnitCd],
          fuel: CSIO_FUEL_TYPE[unit?.FuelTypeCd],
          location: unit?.["csio:HeatingUnitLocationCd"],
          professionally_installed:
            CSIO_INSTALLATION_TYPE[unit?.InstallationCd],
          approved_by_ulc_csa_or_wh:
            CSIO_CERTIFYING_ENTITY[unit?.CertifyingEntityCd],
        };
      }

      if (unit?.UseCd === "csio:AUX") {
        auxiliary_heating_type = {
          apparatus: CSIO_HEATING_TYPE[unit?.HeatingUnitCd],
          fuel: CSIO_FUEL_TYPE[unit?.FuelTypeCd],
          location: unit?.["csio:HeatingUnitLocationCd"],
          professionally_installed:
            CSIO_INSTALLATION_TYPE[unit?.InstallationCd],
          approved_by_ulc_csa_or_wh:
            CSIO_CERTIFYING_ENTITY[unit?.CertifyingEntityCd],
          no_of_face_cords_per_year: unit?.NumFaceCordsUsed,
          solid_fuel_heating_questionaire_attached: "",
          radiant_heating_area: `${unit?.Area?.NumUnits || ""} ${
            unit?.Area?.UnitMeasurementCd || ""
          }`,
          make: "",
          year: "",
          oil_tank_year: "",
          oil_tank_year_type: "",
          fuel_oil_tank_questionaire_attached: "",
        };
      }

      if (unit?.HeatingUnitCd === "csio:M") {
        water_heater_type = {
          apparatus: unit?.["csio:WaterHeaterTypeCd"],
          water_heater_type: unit?.ItemInfo?.ItenDefinition?.ModelYear,
          fuel: CSIO_FUEL_TYPE[unit?.FuelTypeCd],
          professionally_installed:
            CSIO_INSTALLATION_TYPE[unit?.InstallationCd],
          approved_by_ulc_csa_or_wh: unit?.CertifiedInd,
        };
      }
    }

    let plumbing_type = {
      copper_percentage: "",
      galvanized_percentage: "",
      abs_percentage: "",
      pvc_percentage: "",
      pex_percentage: "",
      poly_b_percentage: "",
      lead_percentage: "",
      other: "",
    };

    let plumbingInfo = Array.isArray(riskLocation?.["csio:PlumbingInfo"])
      ? riskLocation?.["csio:PlumbingInfo"]
      : [riskLocation?.["csio:PlumbingInfo"]];

    for (const plumbingType of plumbingInfo) {
      const plumbingTypeCd =
        CSIO_PLUMBING_TYPE[plumbingType?.["csio:PlumbingTypeCd"]] || "";
      switch (plumbingTypeCd) {
        case "csio:1":
          plumbing_type.copper_percentage =
            plumbingType?.["csio:PlumbingTypePct"];
          break;
        case "csio:5":
          plumbing_type.galvanized_percentage =
            plumbingType?.["csio:PlumbingTypePct"];
          break;
        case "csio:11":
          plumbing_type.abs_percentage = plumbingType?.["csio:PlumbingTypePct"];
          break;
        case "csio:10":
          plumbing_type.pvc_percentage = plumbingType?.["csio:PlumbingTypePct"];
          break;
        case "csio:12":
          plumbing_type.pex_percentage = plumbingType?.["csio:PlumbingTypePct"];
          break;
        case "csio:13":
          plumbing_type.poly_b_percentage =
            plumbingType?.["csio:PlumbingTypePct"];
          break;
        case "csio:7":
          plumbing_type.lead_percentage =
            plumbingType?.["csio:PlumbingTypePct"];
          break;
        case "csio:3":
          plumbing_type.other = plumbingType?.["csio:PlumbingTypePct"];
          break;
        default:
          break;
      }
    }

    const sewerBackUpInfo = Array.isArray(
      riskLocation?.["csio:SewerBackupPreventionInfo"]
    )
      ? riskLocation?.["csio:SewerBackupPreventionInfo"]
      : [riskLocation?.["csio:SewerBackupPreventionInfo"]];

    let primary_water_mitigation_type = {
      sump_pump_type: "",
      auxiliary_power: "",
      backup_value: "",
    };
    let auxiliary_water_mitigation_type = {
      sump_pump_type: "",
      auxiliary_power: "",
      backup_value: "",
      main_water_value_shut_off_type: "",
      no_of_main_water_value_shut_off_sensor: "",
      sewer_backeup_questionaire_attached: "",
    };

    for (const sewerBackup of sewerBackUpInfo) {
      if (sewerBackup?.UseCd === "csio:PRI") {
        primary_water_mitigation_type = {
          sump_pump_type: sewerBackup?.["csio:SumpPumpTypeCd"],
          auxiliary_power: sewerBackup?.["csio:SumpPumpAuxiliaryPowerTypeCd"],
          backup_value: sewerBackup?.["csio:BackflowValveTypeCd"],
        };
      }

      if (sewerBackup?.UseCd === "csio:AUX") {
        auxiliary_water_mitigation_type = {
          sump_pump_type: sewerBackup?.["csio:SumpPumpTypeCd"],
          auxiliary_power: sewerBackup?.["csio:SumpPumpAuxiliaryPowerTypeCd"],
          backup_value: sewerBackup?.["csio:BackflowValveTypeCd"],
          main_water_value_shut_off_type:
            riskLocation?.["csio:PlumbingInfo"]?.["csio:MainShutOffInfo"]?.[
              "csio:MainValveShutOffTypeCd"
            ],
          no_of_main_water_value_shut_off_sensor:
            riskLocation?.["csio:PlumbingInfo"]?.["csio:MainShutOffInfo"]?.[
              "csio:NumSensors"
            ],
          sewer_backeup_questionaire_attached: "",
        };
      }
    }

    const alarmSecurity = Array.isArray(riskLocation?.AlarmAndSecurity)
      ? riskLocation?.AlarmAndSecurity
      : [riskLocation?.AlarmAndSecurity];

    let security_system = {
      fire: "",
      burglary: "",
      smoke_detectors: "",
      smoke_detector_type: "",
      no_of_detectors: "",
      if_any_of_the_above_are_monitored_monitored_by: "",
      home_sprinklered: "",
      alarm_certificate: "",
      premises_type_security_system: "",
    };

    alarmSecurity?.forEach((security: any) => {
      const Security = CSIO_ALARM_TYPE[security?.AlarmTypeCd] || "";
      switch (Security) {
        case "csio:1":
          security_system.fire = CSIO_ALARM_DESC_TYPE[security?.AlarmDescCd];
          break;
        case "csio:2":
          security_system.burglary =
            CSIO_ALARM_DESC_TYPE[security?.AlarmDescCd];
          break;
        case "csio:10":
          security_system.smoke_detectors =
            CSIO_ALARM_DESC_TYPE[security?.AlarmDescCd];
          break;
        default:
          break;
      }
      security_system.smoke_detector_type =
        CSIO_SMOKE_DETECTION_TYPE[security?.["csio:SmokeDetectionTypeCd"]];
      security_system.premises_type_security_system =
        CSIO_PREMISES_SECURITY_TYPE[
          riskLocation?.BldgProtection?.["csio:ProtectionDeviceOtherCd"]
        ];
      security_system.home_sprinklered =
        riskLocation?.BldgProtection?.["csio:SprinkleredInd"];
    });

    let bathrooms = {
      no_of_full: "",
      no_of_half: "",
    };
    const bathroomInfo =
      riskLocation?.DwellInspectionValuation?.BathroomInfo?.BathroomTypeCd ||
      "";
    if (bathroomInfo === "Full") {
      bathrooms.no_of_full =
        riskLocation?.DwellInspectionValuation?.BathroomInfo?.NumBathrooms ||
        "";
    }
    if (bathroomInfo?.BathroomTypeCd === "Half") {
      bathrooms.no_of_half =
        riskLocation?.DwellInspectionValuation?.BathroomInfo?.NumBathrooms ||
        "";
    }
    const DetachedStructuresInfo = Array.isArray(
      riskLocation?.DwellInspectionValuation?.DetachedStructuresInfo
    )
      ? riskLocation?.DwellInspectionValuation?.DetachedStructuresInfo
      : [riskLocation?.DwellInspectionValuation?.DetachedStructuresInfo];
    const detachedOutbuilding: IHabitationalDetachedOutbuildingStructure[] = [];

    for (let j = 0; j < DetachedStructuresInfo.length; j++) {
      const structure = DetachedStructuresInfo[j];
      detachedOutbuilding.push({
        key: getUniqueId(),
        number: j + 1,
        year: structure?.Construction?.YearBuilt,
        structure_type: CSIO_RESIDENCE_TYPE[structure?.ResidenceTypeCd],
        exterior_wall_framing_type:
          CSIO_CONSTRUCTION_TYPE[structure?.Construction?.ConstructionCd],
        heating_apparatus_type:
          CSIO_HEATING_TYPE[structure?.HeatingUnitInfo?.HeatingUnitCd],
        fuel_type: CSIO_FUEL_TYPE[structure?.HeatingUnitInfo?.FuelTypeCd],
        total_area: `${structure?.SurfaceArea?.NumUnits || ""} ${
          structure?.SurfaceArea?.UnitMeasurementCd || ""
        }`,
        value: structure?.ItemValueAmt?.Amt,
      });
    }

    const questionAnswers = Array.isArray(riskLocation?.QuestionAnswer)
      ? riskLocation?.QuestionAnswer
      : riskLocation?.QuestionAnswer
      ? [riskLocation?.QuestionAnswer]
      : [];

    let riskLocationQuestionAnswer: {
      QuestionCd: string;
      YesNoCd: string;
    }[] = [];

    riskLocationQuestionAnswer =
      riskLocationQuestionAnswer.concat(questionAnswers);

    const QuestionsRiskLocation = {
      do_you_own_rent_more_than_one_location:
        getDescibedRiskLocationQuestionAns(
          riskLocationQuestionAnswer,
          "csio:114"
        ).yesNoCd,
      number_of_weeks_location_rented_to_others: `${
        riskLocation?.DwellOccupancy?.LengthTimeRentedToOthers?.NumUnits || ""
      } ${
        riskLocation?.DwellOccupancy?.LengthTimeRentedToOthers
          ?.UnitMeasurementCd || ""
      }`,
      number_of_rooms_rented_to_others:
        riskLocation?.DwellOccupancy?.["csio:NumRoomsRented"],
      renewable_energy_installation_on_premises:
        riskLocation?.["csio:RenewableEnergy"]?.["csio:RenewableEnergyInd"],
      number_of_full_residence_employees:
        riskLocation?.ResidenceEmployees?.NumEmployees,
      number_of_dogs_in_the_household:
        riskLocation?.AnimalExposureInfo?.NumUnits,
      breed_of_dogs: riskLocation?.AnimalExposureInfo?.BreedCd,
      total_property_area: `${riskLocation?.LandArea?.NumUnits || ""} ${
        riskLocation?.LandArea?.UnitMeasurementCd || ""
      }`,
    };

    // Coverage Section
    const describedCsioCoverage: IHabitationalCoverage[] = [];
    const describedLiabilityCoverage: IHabitationalLiabilityCoverage[] = [];
    const describedDiscountSurcharge: IHabitationalDiscountSurcharge[] = [];

    for (const coverageKey in HABITATIONAL_CSIO_COVERAGE) {
      const data = {
        key: getUniqueId(),
        code: coverageKey,
        coverage_discription: HABITATIONAL_CSIO_COVERAGE[coverageKey],
        deductible: null,
        requested_or_declined: null,
        amount_of_insurance: null,
        deductible_type: null,
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        premium: null,
        isTypeOther: false,
      };
      describedCsioCoverage.push(data);
    }
    const coverageList = riskLocation?.Coverage || [];
    if (coverageList.length > 0) {
      for (const coverage of coverageList) {
        const index = describedCsioCoverage.findIndex(
          (x) => x.code === coverage.CoverageCd
        );

        if (index > -1) {
          describedCsioCoverage[index] = {
            ...describedCsioCoverage[index],
            deductible: coverage?.Deductible?.FormatCurrencyAmt?.Amt || null,
            requested_or_declined: null,
            amount_of_insurance:
              coverage?.Limit?.FormatCurrencyAmt?.Amt || null,
            deductible_type: coverage?.Deductible?.DeductibleTypeCd || null,
            type1: coverage?.Limit?.ValuationCd,
            type2: coverage?.Limit?.LimitAppliesToCd,
            type3: coverage?.Option?.OptionCd,
            type4: coverage?.Option?.OptionCd,
            type5: coverage?.Option?.OptionCd,
            premium: coverage?.CurrentTermAmt?.Amt || null,
          };
        } else if (coverage.CoverageDesc) {
          describedLiabilityCoverage.push({
            key: getUniqueId(),
            code: coverage.CoverageCd,
            liability_coverage_discription:
              HABITATIONAL_CSIO_PACKAGE_OTHER[coverage.CoverageCd] ||
              HABITATIONAL_CSIO_DISCOUNT_COVERAGE[coverage.CoverageCd],
            deductible: coverage?.Deductible?.FormatCurrencyAmt?.Amt || null,
            requested_or_declined: null,
            amount_of_insurance:
              coverage?.Limit?.FormatCurrencyAmt?.Amt || null,
            deductible_type: coverage?.Deductible?.DeductibleTypeCd || null,
            type1: coverage?.Limit?.ValuationCd,
            type2: coverage?.Limit?.LimitAppliesToCd,
            type3: coverage?.Option?.OptionCd,
            type4: coverage?.Option?.OptionCd,
            type5: coverage?.Option?.OptionCd,
            estimated_premium: coverage?.CurrentTermAmt?.Amt || null,
          });
        } else if (!coverage.CoverageDesc) {
          describedDiscountSurcharge.push({
            key: getUniqueId(),
            discount_or_surcharge_discription:
              HABITATIONAL_CSIO_DISCOUNT_COVERAGE[
                coverage?.CreditOrSurcharge?.CreditSurchargeCd
              ],
            percentage:
              coverage?.CreditOrSurcharge?.NumericValue?.FormatPct || null,
            applied_to_premium: null,
            est_discount_or_surchage:
              coverage?.CreditOrSurcharge?.NumericValue?.FormatCurrencyAmt ||
              null,
          });
        }
      }
    }

    riskLocationData.push({
      key: getUniqueId(),
      loc_no: i + 1,
      risk_location_address: {
        address: location?.Addr?.Addr1,
        unit_suite: null,
        city: location?.Addr?.City,
        province_or_state: location?.Addr?.StateProvCd,
        postal_code: location?.Addr?.PostalCode,
        country: location?.Addr?.CountryCd,
      },
      risk_location_construction: {
        year_built: riskLocation?.Construction?.YearBuilt,
        no_of_storeys:
          CSIO_NO_OF_STOREYS[
            riskLocation?.DwellInspectionValuation?.NumStoriesInDwellingCd
          ],
        no_of_families: riskLocation?.DwellInspectionValuation?.NumFamilies,
        no_of_units: riskLocation?.Construction?.NumUnits,
        total_living_area:
          riskLocation?.DwellInspectionValuation?.TotalLivingArea?.NumUnits,
        total_living_area_unit:
          riskLocation?.DwellInspectionValuation?.TotalLivingArea
            ?.UnitMeasurementCd,
        access_type: riskLocation?.["csio:AccessCd"],
        smokers: riskLocation?.DwellRating?.HomeRatingCreditCd,
        replacement_cost_emulator_product:
          riskLocation?.DwellInspectionValuation?.[
            "csio:ValuationProviderProductCd"
          ],
        data_evaluation_completed: riskLocation?.InspectionInfo?.InspectionDt,
        date_of_birth_of_eldest_occupant:
          riskLocation?.DwellOccupancy?.["csio:EldestBirthDt"],
        relationship_to_applicant:
          CSIO_DRIVER_RELATIONSHIP[
            riskLocation?.DwellOccupancy?.RelationShipToInsuredCd
          ],
        occupancy_type:
          CSIO_OCCUPANCT_TYPE[riskLocation?.DwellOccupancy?.OccupancyTypeCd],
        structure_type:
          CSIO_STRUCTURE_TYPE[riskLocation?.DwellOccupancy?.ResidenceTypeCd],
        foundation_type:CSIO_FOUNDATION_TYPE[riskLocation?.DwellInspectionValuation?.SubstructureInfo
          ?.FoundationCd],
        finished_basement_percentage:
          riskLocation?.DwellInspectionValuation?.SubstructureInfo
            ?.FinishedBasementInfo?.FinishedBasementPct,
        exterior_wall_framing_type:
          riskLocation?.DwellInspectionValuation?.ExteriorWallMaterialInfo
            ?.ExteriorWallMaterialCd,
        exterior_wall_finish_type:
          riskLocation?.DwellInspectionValuation?.ExteriorWallMaterialInfo
            ?.ExteriorWallMaterialCd,
      },
      interior_wall_construction_type: [
        {
          key: getUniqueId(),
          type: null,
          percentage: null,
        },
      ],
      interior_wall_height: [
        {
          key: getUniqueId(),
          height: null,
          percentage: null,
        },
      ],
      interior_floor_finish_type: [
        {
          key: getUniqueId(),
          type: null,
          percentage: null,
        },
      ],
      ceiling_construction_type: [
        {
          key: getUniqueId(),
          type: null,
          percentage: null,
        },
      ],
      risk_location_upgrades: [
        {
          key: getUniqueId(),
          upgrade_name: "Roof",
          full_yy: null,
          partial_yy: null,
        },
        {
          key: getUniqueId(),
          upgrade_name: "Electrical",
          full_yy: null,
          partial_yy: null,
        },
        {
          key: getUniqueId(),
          upgrade_name: "Heating",
          full_yy: null,
          partial_yy: null,
        },
        {
          key: getUniqueId(),
          upgrade_name: "Plumbing",
          full_yy: null,
          partial_yy: null,
        },
      ],
      risk_location_services: {
        risk_location_roof_covering_type:
          CSIO_ROOF_MATERIAL[
            riskLocation?.Construction?.RoofingMaterial?.RoofMaterialCd
          ],
        risk_location_electrical_wiring_type:
          CSIO_WIRING_TYPE[riskLocation?.WiringTypeCd],
        risk_location_electrical_panel_type:
          CSIO_ELECTRICAL_PANEL[riskLocation?.Construction?.ElectricalPanelCd],
        risk_location_service: riskLocationServices,
      },
      primary_heating_type: primary_heating_type,
      auxiliary_heating_type: auxiliary_heating_type,
      plumbing_type: plumbing_type,
      water_heater_type: water_heater_type,
      primary_water_mitigation_type: primary_water_mitigation_type,
      auxiliary_water_mitigation_type: auxiliary_water_mitigation_type,
      fire_protection: {
        distance_to_hydrant: `${
          riskLocation?.BldgProtection?.DistanceToHydrant?.NumUnits || ""
        } ${
          riskLocation?.BldgProtection?.DistanceToHydrant?.UnitMeasurementCd ||
          ""
        }`,
        hydrant_type:
          CSIO_FIRE_PROTECTION_TYPE[
            riskLocation?.DwellStateSupplement?.HydrantTypeCd
          ],
        distance_to_responding_fire_hall: `${
          riskLocation?.BldgProtection?.DistanceToFireStation?.NumUnits || ""
        } ${
          riskLocation?.BldgProtection?.DistanceToFireStation
            ?.UnitMeasurementCd || ""
        }`,
        fire_hall_name: location?.FireStation,
      },
      security_system: security_system,
      bathrooms: bathrooms,

      kitchens: {
        no_of_kitchens:
          riskLocation?.DwellInspectionValuation?.KitchenInfo?.NumKitchens,
        kitchen_1_quality:
          CSIO_CONSTRUCTION_QUALITY[
            riskLocation?.DwellInspectionValuation?.KitchenInfo
              ?.ConstructionQualityCd
          ],
        kitchen_2_quality:
          CSIO_CONSTRUCTION_QUALITY[
            riskLocation?.DwellInspectionValuation?.KitchenInfo
              ?.ConstructionQualityCd
          ],
      },

      garage_or_carport: {
        no_of_cars: riskLocation?.DwellInspectionValuation?.GarageInfo?.NumVehs,
        garage_type:
          CSIO_GARAGE_TYPE[
            riskLocation?.DwellInspectionValuation?.GarageInfo?.GarageTypeCd
          ],
      },

      swimming_pool: {
        year: riskLocation?.SwimmingPool?.YearBuilt,
        pool_type: `${riskLocation?.SwimmingPool?.IndoorCd || ""} ${
          CSIO_POOL_CONSTRUCTION_TYPE[
            riskLocation?.SwimmingPool?.PoolConstructionCd
          ] || ""
        }`,
        pool_fenced: riskLocation?.SwimmingPool?.ApprovedFenceInd,
      },
      detached_outbuildings_structures: detachedOutbuilding,
      risk_location_questions: QuestionsRiskLocation,
      coverages_form: describedCsioCoverage,
      liability_extension_and_exclusion_form: describedLiabilityCoverage,
      discount_and_surcharges_form: describedDiscountSurcharge,
    });
  }
  console.log("riskLocation", riskLocationData);

  // Mortgage/Loss Payee
  const mortgageLossPayee: IHabitationalMortgageLossPayee[] = [];

  for (let i = 0; i < Location.length; i++) {
    const mortgageLocation = Location[i];

    mortgageLossPayee.push({
      key: getUniqueId(),
      loc_no: i + 1,
      name: mortgageLocation?.AdditionalInterest?.GeneralPartyInfo?.NameInfo
        ?.CommlName?.CommercialName,
      nature_of_interest:
        CSIO_NATURE_OF_INTEREST[
          mortgageLocation?.AdditionalInterest?.AdditionalInterestInfo
            ?.NatureInterestCd
        ],
      address:
        mortgageLocation?.AdditionalInterest?.GeneralPartyInfo?.Addr?.Addr1,
      city: mortgageLocation?.AdditionalInterest?.GeneralPartyInfo?.Addr?.City,
      province_or_state:
        mortgageLocation?.AdditionalInterest?.GeneralPartyInfo?.Addr
          ?.StateProvCd,
      postal_or_zip_code:
        mortgageLocation?.AdditionalInterest?.GeneralPartyInfo?.Addr
          ?.PostalCode,
    });
  }

  const PersAppInfo = Array.isArray(PersPolicy?.PersApplicationInfo)
    ? PersPolicy?.PersApplicationInfo
    : [PersPolicy?.PersApplicationInfo];

  const describedAddressForm: IHabitationalAddressForm[] = [];
  for (let i = 0; i < PersAppInfo.length; i++) {
    const persAppInfo = PersAppInfo[i];
    describedAddressForm.push({
      key: getUniqueId(),
      serial_number: i + 1,
      address_type: persAppInfo?.CurrentResidenceDt,
      address: persAppInfo?.Addr?.Addr1,
      unit_of_suit: null,
      state: persAppInfo?.Addr?.StateProvCd,
      city: persAppInfo?.Addr?.City,
      postal_code: persAppInfo?.Addr?.PostalCode,
      country: persAppInfo?.Addr?.CountryCd,
      date_moved_in: persAppInfo?.Addr?.["csio:LengthTimeAddr"]?.EffectiveDt,
      date_moved_out: persAppInfo?.Addr?.["csio:LengthTimeAddr"]?.ExpirationDt,
    });
  }

  const describedPaymentschedule: IHabitationalPremiumPaymentSchedule[] = [];
  const installmentInfo = Array.isArray(
    PersPolicy?.PaymentOption?.InstallmentInfo
  )
    ? PersPolicy?.PaymentOption?.InstallmentInfo
    : [PersPolicy?.PaymentOption?.InstallmentInfo];
  for (const installment of installmentInfo) {
    describedPaymentschedule.push({
      id: getUniqueId(),
      installment_number: installment?.InstallmentNumber,
      installment_amount: installment.InstallmentAmt?.Amt,
      installment_date: installment.InstallmentDueDt,
    });
  }

  return {
    customer_policy_id: 0,
    incremental_number: null,
    insured_company: PersPolicy["csio:CompanyCd"],
    billing_method:
      AUTOMOBILE_CSIO_BILLING_METHOD[PersPolicy.BillingMethodCd] ||
      PersPolicy.BillingMethodCd,
    billing_account_number: PersPolicy?.BillingAccountNumber,
    company_type:
      CSIO_POLICY_NAME[PersPolicy.PolicyStatusCd] || PersPolicy.PolicyStatusCd,
    binder_number: PersPolicy?.Binder?.AgencyBinderNumber,
    policy_number: PersPolicy.PolicyNumber,
    // applicant_details: describedApplicantDetails,
    applicant_details: applicantDetails,
    applicant_preferred_language: PersPolicy?.LanguageCd || "",
    broker_details: {
      broker_name: null,
      broker_address: null,
      broker_unit_or_suite: null,
      broker_city: null,
      broker_province_or_state: null,
      broker_country: null,
      broker_postal_or_zip_code: null,
      broker_contact_name: null,
      broker_home: null,
      broker_cell: null,
      broker_business: null,
      broker_fax: null,
      broker_email: null,
      broker_code: ProducerInfo?.ContractNumber,
      broker_sub_code: ProducerInfo?.ProducerSubCode,
      broker_client_id: null,
      broker_company_client_id: null,
      broker_group_name: null,
      broker_group_id: null,
    },
    policy_period_effective_date: PersPolicy?.ContractTerm?.EffectiveDt,
    policy_period_effective_time: PersPolicy?.ContractTerm?.StartTime || null,
    policy_period_expiry_date: PersPolicy?.ContractTerm?.ExpirationDt,
    policy_period_expiry_time: moment().hours(0).minutes(1).format(),
    applicant_data: describedApplicantData,
    loss_history: describedLossHistory,
    policy_history: describedPolicyHistory,
    cross_reference_information: describedCrossReference,
    risk_location_data: riskLocationData,

    mortgage_or_loss_payee: mortgageLossPayee,
    liability_exposures: {
      daycare_operation_number_of_children: null,
      do_you_own_a_trampoline: getDescibedRiskLocationQuestionAns(
        PersPolicyQuestions,
        "csio:137"
      ) || {
        questionCd: null,
        yesNoCd: null,
      },
      do_you_have_a_garden_tractor: getDescibedRiskLocationQuestionAns(
        PersPolicyQuestions,
        "csio:138"
      ) || {
        questionCd: null,
        yesNoCd: null,
      },
      do_you_have_a_golf_cart: getDescibedRiskLocationQuestionAns(
        PersPolicyQuestions,
        "csio:139"
      ) || {
        questionCd: null,
        yesNoCd: null,
      },
      no_of_saddle_draft_animals: null,
      do_you_own_any_unlicensed_recreational_vehicles:
        getDescibedRiskLocationQuestionAns(PersPolicyQuestions, "csio:140") || {
          questionCd: null,
          yesNoCd: null,
        },
      do_you_own_any_watercrafts: getDescibedRiskLocationQuestionAns(
        PersPolicyQuestions,
        "csio:58"
      ) || {
        questionCd: null,
        yesNoCd: null,
      },
      is_there_a_co_occupant_that_requires_coverage: null,
      co_occupant_name: null,
      is_there_any_kind_of_business_operation:
        getDescibedRiskLocationQuestionAns(PersPolicyQuestions, "csio:56") || {
          questionCd: null,
          yesNoCd: null,
        },
      if_yes_describe_business: null,
      number_of_cannabis_plants_grown_on_premises: null,
      other_exposures: null,
    },

    address_form: describedAddressForm,
    attachments: [
      {
        key: getUniqueId(),
        attachments_serial_number: 1,
        attachments_date_completed: "",
        attachments_description: null,
        attachments_documents_link: null,
      },
      {
        key: getUniqueId(),
        attachments_serial_number: 2,
        attachments_date_completed: "",
        attachments_description: null,
        attachments_documents_link: null,
      },
      {
        key: getUniqueId(),
        attachments_serial_number: 3,
        attachments_date_completed: "",
        attachments_description: null,
        attachments_documents_link: null,
      },
      {
        key: getUniqueId(),
        attachments_serial_number: 4,
        attachments_date_completed: "",
        attachments_description: null,
        attachments_documents_link: null,
      },
      {
        key: getUniqueId(),
        attachments_serial_number: 5,
        attachments_date_completed: "",
        attachments_description: null,
        attachments_documents_link: null,
      },
      {
        key: getUniqueId(),
        attachments_serial_number: 6,
        attachments_date_completed: "",
        attachments_description: null,
        attachments_documents_link: null,
      },
    ],

    premium_info_type_of_payment_plan:
      AUTOMOBILE_CSIO_PAYMENT_COVRAGE[PersPolicy?.PaymentOption?.PaymentPlanCd],
    premium_info_estimated_policy_premium: PersPolicy?.CurrentTermAmt?.Amt,
    premium_info_provincial_sales_tax:
      PersPolicy?.PaymentOption?.InstallmentInfo[0]?.TaxAmt?.Amt || null,
    premium_info_additional_charges:
      PersPolicy?.PaymentOption?.InstallmentFeeAmt?.Amt,
    premium_info_total_estimated_cost:
      PersPolicy?.["csio:TotalEstimatedAmt"]?.Amt, // check this if error occur in total estimated Cost
    premium_info_amount_paid_with_application:
      PersPolicy?.PaymentOption?.CollectedByAgentAmt?.Amt,
    premium_info_amount_still_due: PersPolicy?.PaymentOption?.TotalDueAmt,
    premium_info_no_of_remaining_installments:
      PersPolicy?.PaymentOption?.NumPayments,
    premium_info_amount_of_each_installment:
      PersPolicy?.PaymentOption?.InstallmentInfo[0]?.InstallmentAmt?.Amt,
    premium_info_installment_due_date:
      PersPolicy?.PaymentOption?.InstallmentInfo[0]?.InstallmentDueDt,
      payment_schedule: describedPaymentschedule,
    premium_info_Remarks: homePolicyAddRs?.["csio:RemarksInfo"]?.RemarkText,
    status: "ACTIVE",
  };
};

const getDescibedRiskLocationQuestionAns = (
  questions: {
    QuestionCd: string;
    YesNoCd: string;
  }[],
  questionCd: string
) => {
  const question = questions.find((qa: any) => qa?.QuestionCd === questionCd);
  return {
    questionCd: question?.QuestionCd || null,
    yesNoCd: question?.YesNoCd || null,
  };
};
