import { useFormik } from "formik";
import React from "react";
import { initialProject } from "../../redux/projects/projects.types";
import { PageLoader } from "../../components/PageLoader/PageLoader";
import { PageContainer } from "../../components/container/PageContainer";
import { Breadcrumb } from "../../layout/fullLayout/breadcrumb/Breadcrumb";
import { useParams } from "react-router-dom";
import { IBreadcrumbProps } from "../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { StandadCard } from "../../components/Cards";
import { Button, Grid, Stack } from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../components/formsComponents";
import { RoleBasedCustomButton } from "../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { UsersAutoSearchByRole } from "../../components/AutoCompleteSearches/UsersAutoSearchByRole";

export const ManageProjects: React.FC = () => {
  const { projectId } = useParams() as { projectId?: number };
  const isUpdateProject = projectId ? true : false;

  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      to: "/projects",
      title: "projects",
    },
    {
      title: isUpdateProject ? "Edit Project" : "Create Project",
    },
  ];

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: initialProject,
    validate: (values) => {},
    onSubmit: (values) => {},
  });

  return (
    <>
      <PageLoader
        loading={false}
        error={null}
        //loading={leadLoading === LoadState.InProgress}
        // error={error ? { message: error } : null}
      >
        <PageContainer
          title={isUpdateProject ? "Edit Project" : "Create New Project"}
          description="this is inner page"
        >
          <Breadcrumb title="Lead" items={BCrumb} />
          <StandadCard
            sx={{ ml: 2, mr: 2 }}
            heading={`${isUpdateProject ? "Update" : "Create"} Project`}
          >
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <UsersAutoSearchByRole
                    label="Assigned To"
                    value={values.assigned_to}
                    onSelect={(newValue) => {
                      setValues({
                        ...values,
                        assigned_to: newValue.user_fact_id as any,
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <CustomFormLabel>Item Status</CustomFormLabel>
                  <ControlledCustomSelect
                    name="item_status"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    displayEmpty
                    placeholder="Select One"
                    options={[
                      { label: "Active", value: "ACTIVE" },
                      { label: "Inactive", value: "INACTIVE" },
                    ]}
                    value={values.item_status}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <CustomFormLabel>LOB</CustomFormLabel>
                  <CustomTextField
                    id="lob"
                    name="lob"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.lob}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <CustomFormLabel>LOB Code</CustomFormLabel>
                  <CustomTextField
                    id="lob_code"
                    name="lob_code"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.lob_code}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Broker Number</CustomFormLabel>
                  <CustomTextField
                    id="broker_number"
                    name="broker_number"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.broker_number}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Name</CustomFormLabel>
                  <CustomTextField
                    id="name"
                    name="name"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.name}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Insurer Branch Code</CustomFormLabel>
                  <CustomTextField
                    id="insurer_branch_code"
                    name="insurer_branch_code"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.insurer_branch_code}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Operating Name</CustomFormLabel>
                  <CustomTextField
                    id="operating_name"
                    name="operating_name"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.operating_name}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Sub Broker</CustomFormLabel>
                  <CustomTextField
                    id="sub_broker"
                    name="sub_broker"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.sub_broker}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Client Name</CustomFormLabel>
                  <CustomTextField
                    id="client_name"
                    name="client_name"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.client_name}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Policy Number</CustomFormLabel>
                  <CustomTextField
                    id="policy_number"
                    name="policy_number"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.policy_number}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Effective Date</CustomFormLabel>
                  <CustomTextField
                    id="effective_date"
                    name="effective_date"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.effective_date}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Expiry Date</CustomFormLabel>
                  <CustomTextField
                    id="expiry_date"
                    name="expiry_date"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.expiry_date}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Transaction Type</CustomFormLabel>
                  <CustomTextField
                    id="transaction_type"
                    name="transaction_type"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.transaction_type}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Year</CustomFormLabel>
                  <CustomTextField
                    id="year"
                    name="year"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.year}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Make</CustomFormLabel>
                  <CustomTextField
                    id="make"
                    name="make"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.make}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Mode</CustomFormLabel>
                  <CustomTextField
                    id="mode"
                    name="mode"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.mode}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>VIN</CustomFormLabel>
                  <CustomTextField
                    id="vin"
                    name="vin"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.vin}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Unit</CustomFormLabel>
                  <CustomTextField
                    id="unit"
                    name="unit"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.unit}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>LPN</CustomFormLabel>
                  <CustomTextField
                    id="lpn"
                    name="lpn"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.lpn}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>ACV</CustomFormLabel>
                  <CustomTextField
                    id="acv"
                    name="acv"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.acv}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Radius</CustomFormLabel>
                  <CustomTextField
                    id="radius"
                    name="radius"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.radius}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Producer</CustomFormLabel>
                  <CustomTextField
                    id="producer"
                    name="producer"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.producer}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Branch</CustomFormLabel>
                  <CustomTextField
                    id="branch"
                    name="branch"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.branch}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Retail Group</CustomFormLabel>
                  <CustomTextField
                    id="retail_group"
                    name="retail_group"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.retail_group}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Overland Water</CustomFormLabel>
                  <CustomTextField
                    id="overland_water"
                    name="overland_water"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.overland_water}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Overland Water Zone</CustomFormLabel>
                  <CustomTextField
                    id="overland_water_zone"
                    name="overland_water_zone"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.overland_water_zone}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Sewer Back</CustomFormLabel>
                  <CustomTextField
                    id="sewer_back"
                    name="sewer_back"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.sewer_back}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Will Receive Letter</CustomFormLabel>
                  <CustomTextField
                    id="will_receive_letter"
                    name="will_receive_letter"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.will_receive_letter}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Liability Limit</CustomFormLabel>
                  <CustomTextField
                    id="liability_limit"
                    name="liability_limit"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.liability_limit}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Accident Forgiveness</CustomFormLabel>
                  <CustomTextField
                    id="accident_forgiveness"
                    name="accident_forgiveness"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.accident_forgiveness}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>HNW</CustomFormLabel>
                  <CustomTextField
                    id="hnw"
                    name="hnw"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.hnw}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Province</CustomFormLabel>
                  <CustomTextField
                    id="province"
                    name="province"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.province}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Mortgage</CustomFormLabel>
                  <CustomTextField
                    id="mortage"
                    name="mortage"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.mortage}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>GRC</CustomFormLabel>
                  <CustomTextField
                    id="grc"
                    name="grc"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.grc}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Earthquake</CustomFormLabel>
                  <CustomTextField
                    id="earthquake"
                    name="earthquake"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.earthquake}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>FSA</CustomFormLabel>
                  <CustomTextField
                    id="fsa"
                    name="fsa"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.fsa}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>High Risk</CustomFormLabel>
                  <CustomTextField
                    id="high_risk"
                    name="high_risk"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.high_risk}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Tag</CustomFormLabel>
                  <CustomTextField
                    id="tag"
                    name="tag"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.tag}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Install VPN</CustomFormLabel>
                  <CustomTextField
                    id="install_vpn"
                    name="install_vpn"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.install_vpn}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Surcharge</CustomFormLabel>
                  <CustomTextField
                    id="surcharge"
                    name="surcharge"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.surcharge}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Surcharge Code</CustomFormLabel>
                  <CustomTextField
                    id="surchage_code"
                    name="surchage_code"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.surchage_code}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Loss Year</CustomFormLabel>
                  <CustomTextField
                    id="loss_year"
                    name="loss_year"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.loss_year}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Date of Loss</CustomFormLabel>
                  <CustomTextField
                    id="date_of_loss"
                    name="date_of_loss"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.date_of_loss}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Claim Number</CustomFormLabel>
                  <CustomTextField
                    id="claim_number"
                    name="claim_number"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.claim_number}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Case Type of Loss</CustomFormLabel>
                  <CustomTextField
                    id="case_type_of_loss"
                    name="case_type_of_loss"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.case_type_of_loss}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Gross Insured Claims</CustomFormLabel>
                  <CustomTextField
                    id="gross_insured_claims"
                    name="gross_insured_claims"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.gross_insured_claims}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Net Insured Claims</CustomFormLabel>
                  <CustomTextField
                    id="net_insured_claims"
                    name="net_insured_claims"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.net_insured_claims}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Gross Current</CustomFormLabel>
                  <CustomTextField
                    id="gross_current"
                    name="gross_current"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.gross_current}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Gross Opening</CustomFormLabel>
                  <CustomTextField
                    id="gross_opening"
                    name="gross_opening"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.gross_opening}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Gross Paid Expenses</CustomFormLabel>
                  <CustomTextField
                    id="gross_paid_expenses"
                    name="gross_paid_expenses"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.gross_paid_expenses}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Gross Received</CustomFormLabel>
                  <CustomTextField
                    id="gross_received"
                    name="gross_received"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.gross_received}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Claim Status</CustomFormLabel>

                  <ControlledCustomSelect
                    name="claim_status"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    displayEmpty
                    placeholder="Select One"
                    options={[
                      { label: "Active", value: "ACTIVE" },
                      { label: "Inactive", value: "INACTIVE" },
                    ]}
                    value={values.claim_status}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Fault</CustomFormLabel>
                  <CustomTextField
                    id="fault"
                    name="fault"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.fault}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Action Taken</CustomFormLabel>
                  <CustomTextField
                    id="action_taken"
                    name="action_taken"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.action_taken}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomFormLabel>Notes</CustomFormLabel>
                  <CustomTextField
                    id="notes"
                    name="notes"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    multiline
                    rows={4}
                    value={values.notes}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Stack direction={"row"} spacing={2} marginTop={3}>
                <Button variant="contained" type="submit">
                  Save
                </Button>
                {/* <RoleBasedCustomButton
                type="submit"
               // disabled={loading}
                variant="contained"
                //moduleId={MODULE_IDS.LEADS}
              >
                Save
              </RoleBasedCustomButton> */}
              </Stack>
            </form>
          </StandadCard>
        </PageContainer>
      </PageLoader>
    </>
  );
};
