import produce from "immer";
import { BusinesPolicyFineArtActions } from ".";
import { IStoreState } from "../../initialStoreState";
import { defaultBusinessPolicyFineArtState } from "./businessPolicyFineArt.types";
import {  CLEAR_BUSINES_POLICY_FINE_ART, CLEAR_BUSINES_POLICY_FINE_ART_STATE, FETCH_BUSINESS_POLICY_FINE_ART_FAILED, FETCH_BUSINESS_POLICY_FINE_ART_LIST_FAILED, FETCH_BUSINESS_POLICY_FINE_ART_LIST_PROGRESS, FETCH_BUSINESS_POLICY_FINE_ART_LIST_SUCCESS, FETCH_BUSINESS_POLICY_FINE_ART_PROGRESS, FETCH_BUSINESS_POLICY_FINE_ART_SUCCESS } from "./businessPolicyFineArtActions";
import { LoadState } from "../../../constants/enums";

export const businessPolicyFineArtReducer = (
  state: IStoreState["business"]["fineArt"] = defaultBusinessPolicyFineArtState,
  action: BusinesPolicyFineArtActions
) => {
  switch (action.type) {
    case FETCH_BUSINESS_POLICY_FINE_ART_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_FINE_ART_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_FINE_ART_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.data = [];
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_FINE_ART_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.fineArtLoading = LoadState.InProgress;
        draftState.fineArt = defaultBusinessPolicyFineArtState["fineArt"];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_FINE_ART_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.fineArtLoading = LoadState.Loaded;
        draftState.fineArt = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_FINE_ART_FAILED: {
      const {  errorMessage} = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.fineArtLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
  
    case CLEAR_BUSINES_POLICY_FINE_ART: {
      const newState = produce(state, (draftState) => {
        draftState.fineArt = defaultBusinessPolicyFineArtState["fineArt"];
      });
      return newState;
    }
    case CLEAR_BUSINES_POLICY_FINE_ART_STATE: {
      return defaultBusinessPolicyFineArtState;
    }
    default: {
      return state;
    }
  }
};
