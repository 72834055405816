import { action } from "typesafe-actions";
import {
  IInvoiceTemplate,
  initialInvoiceTemplateState,
} from "./Template.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";

export const FETCH_TEMPLATE_FAILURE = "FETCH_TEMPLATE_FAILURE";
export const FETCH_TEMPLATE_PROGRESS = "FETCH_TEMPLATE_PROGRESS";
export const FETCH_TEMPLATE_SUCCESS = "FETCH_TEMPLATE_SUCCESS";

export const fetchTemplateProgress = () => action(FETCH_TEMPLATE_PROGRESS);
export const fetchTemplateSuccess = (data: IInvoiceTemplate) =>
  action(FETCH_TEMPLATE_SUCCESS, { data });
export const fetchTemplateFailure = (errorMessage: string) =>
  action(FETCH_TEMPLATE_FAILURE, { errorMessage });

export const fetchAccountingInvoiceTemplateAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTemplateProgress());

      const res = await api.get(
        `/accounting/get-account-invoice-template`
      );
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchTemplateSuccess(data[0]));
      } else {
        dispatch(fetchTemplateSuccess(initialInvoiceTemplateState["data"]));
      }
    } catch (err: any) {
      dispatch(fetchTemplateFailure("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingInvoiceTemplateAysnc =
  (
    data: IInvoiceTemplate,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/accounting/edit-account-invoice-template", {
        ...data,
      });
      let message = "Template saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_INVOICE_TEMPLATE = "CLEAR_INVOICE_TEMPLATE";
export const clearInvoiceTemplate = () => action(CLEAR_INVOICE_TEMPLATE);
