import React from "react";
import PDFEditor from "../../../../components/PdfViewer/PdfEditor";
import { Box } from "@mui/material";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export const DocumentPreview: React.FC<{documentCode: string}> = (props) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <Box position={"relative"}>
        <PDFEditor 
         documentCode={props.documentCode}
        />
      </Box>
    </DndProvider>
  );
};
