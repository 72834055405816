import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";

import {
  CLEAR_HABITATIONAL_EDI_APPLICATION_STATE,
  FETCH_HABITATIONAL_EDI_LIST_FAILED,
  FETCH_HABITATIONAL_EDI_LIST_PROGRESS,
  FETCH_HABITATIONAL_EDI_LIST_SUCCESS,
} from "./habitatinalCSIOEDIApplicationActions";
import { initialHabitationalCSIOEDIApplicationState } from "./habitationalCSIOEDIApplication.types";
import { HabitationalCSIOEDIApplicationActions } from ".";

export const HabitationalCSIOEDIApplicationReducer = (
  state: IStoreState["habitational"]["edi"] = initialHabitationalCSIOEDIApplicationState,
  action: HabitationalCSIOEDIApplicationActions
) => {
  switch (action.type) {

    case FETCH_HABITATIONAL_EDI_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_EDI_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_EDI_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.list = [];
      });
      return newState;
    }

    case CLEAR_HABITATIONAL_EDI_APPLICATION_STATE: {
      return initialHabitationalCSIOEDIApplicationState;
    }

    default: {
      return state;
    }
  }
};
