import { Divider, Grid, Stack, Typography } from "@mui/material";
import { formatDate, formatDateWithTime } from "../../../../helpers";
import { StandardCellList } from "../../../../components/Standards";
import React from "react";
import { fetchTaskAddressChangeAysnc } from "../../../../redux/generalChange/generalChangeActions";
import { useDispatchWrapper } from "../../../../hooks";
import { ITaskAddressChange } from "../../../../redux/generalChange/generalChange.types";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../constants/enums";
import { StandadCard } from "../../../../components/Cards";
import { ITaskFileUploadAttachment } from "../../../../redux/tasks/task.types";
import { FileUpload } from "../../../../components/FileUpload/FileUpload";
import { stripHtml } from "../../../../helpers/stripHtml";
import { QuillEditor } from "../../../../components/QuillEditor/QuillEditor";

export const ViewAddressChange: React.FC<{
  taskCode: string;
  attachment: ITaskFileUploadAttachment[];
}> = (props) => {
  const { taskCode, attachment } = props;
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.tasks.generalChange.addressChange
  );
  const dispatch = useDispatchWrapper();
  const [taskDetails, setTaskDetails] = React.useState<ITaskAddressChange>();

  React.useEffect(() => {
    dispatch(fetchTaskAddressChangeAysnc(taskCode));
  }, [taskCode]);

  React.useEffect(() => {
    if (data) {
      setTaskDetails(data);
    }
  }, [data]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      {taskDetails && (
        <Grid container spacing={1} sx={{mt: 2}}>
          <Grid item xs={12} lg={12}>
            
          </Grid>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Address Change"
              colSize={3}
              list={[
                {
                  heading: "Type",
                  data: {
                    type: "text",
                    value: taskDetails.type,
                  },
                },
                {
                  heading: "Effective Date",
                  data: {
                    type: "text",
                    value: taskDetails.effective_date_of_change,
                  },
                },
                {
                  heading: "Effective Time",
                  data: {
                    type: "text",
                    value: formatDateWithTime(taskDetails.effective_time_of_change),
                  },
                },
                {
                  heading: "Address Type",
                
                  data: {
                    type: "text",
                    value: taskDetails.type_of_address,
                  },
                },
                {
                  heading: "Current Address",
                  colSize: 6,
                  data: {
                    type: "text",
                    value:
                      taskDetails.current_address +
                      " " +
                      (taskDetails.current_unit_or_suite ?? "") +
                      " " +
                      taskDetails.current_city +
                      " " +
                      taskDetails.current_province_or_state +
                      " " +
                      taskDetails.current_country +
                      " " +
                      taskDetails.current_postal_code,
                  },
                },
                {
                  heading: "New Address",
                  colSize: 6,
                  data: {
                    type: "text",
                    value:
                      taskDetails.new_address +
                      " " +
                      (taskDetails.new_unit_or_suite ?? "") +
                      " " +
                      taskDetails.new_city +
                      " " +
                      taskDetails.new_province_or_state +
                      " " +
                      taskDetails.new_country +
                      " " +
                      taskDetails.new_postal_code,
                  },
                },
              ]}
            >
              <Grid item xs={12} md={12}>
                <Typography fontWeight={500} variant="h4" mb={1}>
                  Details
                </Typography>
                <QuillEditor
                  value={taskDetails.reason_of_change_or_details || ""}
                  onChange={() => {}}
                  readOnly={true}
                />
              </Grid>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="body1"
                    fontSize={"1.2rem"}
                    fontWeight={600}
                  >
                    Attachments
                  </Typography>
                  {attachment.length > 0 ? (
                    attachment.map((item) => {
                      return (
                        <Stack
                          key={item.key}
                          direction={"row"}
                          justifyContent={"center"}
                          gap={2}
                          alignItems={"center"}
                        >
                          <Typography
                            variant="h4"
                            textTransform={"capitalize"}
                            width={"30%"}
                          >
                            {" "}
                            {item.fileName}{" "}
                          </Typography>
                          <FileUpload value={item.url} deleteDisabled  />
                        </Stack>
                      );
                    })
                  ) : (
                    <Typography variant="body1">No attachments</Typography>
                  )}
                </Grid>
              </Grid>
                
            </StandardCellList>
          </Grid>
         
        </Grid>
      )}
    </PageLoader>
  );
};
