import { CSIO_BUSINESS_TYPE, CSIO_INSURANCE_COMPANY_TYPE, CSIO_LINE_OF_BUSINESS } from "../../constants/constants";
import { ICSIOEDocs } from "./csioEdocs.types";

export const transformCSIOToOurEDOCS = (parsedJSON: any, attachment:string): ICSIOEDocs => {
  // for CommonSvcRs
  const activityNoteRs = parsedJSON?.CommonSvcRs?.ActivityNoteRs || parsedJSON?.ACORD?.CommonSvcRs?.ActivityNoteRs ;
  console.log("attach", attachment)
  // for acord:CommonSvcRs
  const acordCommonSvcRs = parsedJSON?.["acord:CommonSvcRs"];
  const acordActivityNoteRs = acordCommonSvcRs?.["acord:ActivityNoteRs"];
  const partialPolicy = acordActivityNoteRs?.["acord:PartialPolicy"];
  const contractTerm = partialPolicy?.["acord:ContractTerm"];
  const generalPartyInfo =
    acordActivityNoteRs?.["acord:InsuredOrPrincipal"]?.[
      "acord:GeneralPartyInfo"
    ];

    const businessPurposeTypeCd = activityNoteRs?.BusinessPurposeTypeCd ||
    acordActivityNoteRs?.["acord:BusinessPurposeTypeCd"];

  // Get the business purpose details from CSIO_BUSINESS_TYPE
  const businessPurposeDetails = businessPurposeTypeCd ? CSIO_BUSINESS_TYPE[businessPurposeTypeCd] : null;

  // Extract the type and value
  const type = businessPurposeDetails ? businessPurposeDetails.type : null;
  const businessPurposeType = businessPurposeDetails ? businessPurposeDetails.value : null;

  return {
    csio_edoc_id: null,
    customer_policy_id: null,
    customer_id: null,
    message_guid: null,
    customer_name:
      activityNoteRs?.InsuredOrPrincipal?.GeneralPartyInfo?.NameInfo?.CommlName
        ?.CommercialName ||
      generalPartyInfo?.["acord:NameInfo"]?.["acord:CommlName"]?.[
        "acord:CommercialName"
      ] ||
      null,
    policy_number:
      activityNoteRs?.PartialPolicy?.PolicyNumber ||
      partialPolicy?.["acord:PolicyNumber"] ||
      null,
    type: type,
    business_purpose_type:businessPurposeType,
    insurance_company:
      CSIO_INSURANCE_COMPANY_TYPE[activityNoteRs?.PartialPolicy?.["csio:CompanyCd"]] ||
      CSIO_INSURANCE_COMPANY_TYPE[partialPolicy?.["csio:CompanyCd"]] ||
      null,
    contract_term_effective_date:
      activityNoteRs?.PartialPolicy?.ContractTerm?.EffectiveDt ||
      contractTerm?.["acord:EffectiveDt"] ||
      null,
    contract_term_expiry_date:
      activityNoteRs?.PartialPolicy?.ContractTerm?.ExpirationDt ||
      contractTerm?.["acord:ExpirationDt"] ||
      null,
    line_of_business:
      CSIO_LINE_OF_BUSINESS[activityNoteRs?.PartialPolicy?.LOBCd] ||
      CSIO_LINE_OF_BUSINESS[partialPolicy?.["acord:LOBCd"]] ||
      null,
    attachment: attachment || "",
    remarks:
      activityNoteRs?.["csio:RemarksInfo"]?.RemarkText ||
      acordActivityNoteRs?.["csio:RemarksInfo"]?.["acord:RemarkText"] ||
      null,
    status: "ACTIVE",
  };
};