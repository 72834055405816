import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import {
  CLEAR_ALL_CUSTOMERS_STATE,
  FETCH_ALL_CUSTOMERS_LIST_FAILED,
  FETCH_ALL_CUSTOMERS_LIST_PROGRESS,
  FETCH_ALL_CUSTOMERS_LIST_SUCCESS,
  
} from "./allCustomersActions";
import { AllCustomersDetailsActions } from ".";
import { initialCustomerFullDetailsState } from "./allCustomers.types";

export const AllCustomersDetailsReducer = (
  state: IStoreState["allcustomers"] = initialCustomerFullDetailsState,
  action: AllCustomersDetailsActions
) => {
  switch (action.type) {
    case FETCH_ALL_CUSTOMERS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        // draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_ALL_CUSTOMERS_LIST_SUCCESS: {
      const { list } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = list;
        // draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_ALL_CUSTOMERS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        // draftState.totalRecords = 0;
      });
      return newState;
    }
    case CLEAR_ALL_CUSTOMERS_STATE: {
      return initialCustomerFullDetailsState;
    }
    default: {
      return state;
    }
  }
};
