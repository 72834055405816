import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialBusinessPolicyRestrutantState } from "./businessPolicyRestrutant.types";
import {BusinessPolicyRestrutantActions} from "./index";
import { CLEAR_BUSINESS_POLICY_RESTRUTANT, CLEAR_BUSINESS_POLICY_RESTRUTANT_STATE, FETCH_BUSINESS_POLICY_RESTRUTANT_FAILED, FETCH_BUSINESS_POLICY_RESTRUTANT_LIST_FAILED, FETCH_BUSINESS_POLICY_RESTRUTANT_LIST_PROGRESS, FETCH_BUSINESS_POLICY_RESTRUTANT_LIST_SUCCESS, FETCH_BUSINESS_POLICY_RESTRUTANT_PROGRESS, FETCH_BUSINESS_POLICY_RESTRUTANT_SUCCESS } from "./businessPolicyRestrutantActions";

export const businessPolicyRestrutantReducer = (
  state: IStoreState["business"]["restrutant"] = initialBusinessPolicyRestrutantState,
  action: BusinessPolicyRestrutantActions
) => {
  switch (action.type) {
    case FETCH_BUSINESS_POLICY_RESTRUTANT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_RESTRUTANT_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_RESTRUTANT_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.data = [];
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_RESTRUTANT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.restrutantLoading = LoadState.InProgress;
        draftState.restrutant = initialBusinessPolicyRestrutantState["restrutant"];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_RESTRUTANT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.restrutantLoading = LoadState.Loaded;
        draftState.restrutant = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_RESTRUTANT_FAILED: {
      const {errorMessage} = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.restrutantLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
  
    case CLEAR_BUSINESS_POLICY_RESTRUTANT: {
      const newState = produce(state, (draftState) => {
        draftState.restrutant = initialBusinessPolicyRestrutantState["restrutant"];
        draftState.restrutantLoading = LoadState.NotLoaded;
      });
      return newState;
    }
    case CLEAR_BUSINESS_POLICY_RESTRUTANT_STATE: {
      return initialBusinessPolicyRestrutantState;
    }
    default: {
      return state;
    }
  }
};
