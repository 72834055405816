/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useFormik } from "formik";
import {
  Box,
  Button,
  MenuItem,
  Typography,
  TableCell,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Stack,
  Checkbox,
  SelectChangeEvent,
} from "@mui/material";

import {
  ControlledCustomSelect,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import produce from "immer";
import { renderDropdownValue } from "../../../../../../../helpers";
import {
  addCommAutomobileScheduleCAsync,
  fetchCommAutomobileScheduleCAsync,
} from "../../../../../../../redux/commAutoPolicy/commAutoSchedule/commAutoScheduleActions";
import { ICommScheduleCCoverage } from "../../../../../../../redux/commAutoPolicy/commAutoSchedule/commAutoSchedule.types";
import { useNavigate, useParams } from "react-router-dom";
import { StandadCard } from "../../../../../../../components/Cards";
import { CurrencyTextField } from "../../../../../../../components/CurrencyTextField/CurrencyTextField";
import { vehicle_schedule_dropdown } from "../../../../../../../constants/constants";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";

export const CommAutomobileScheduleC = () => {
  const { customerId, customerPolicyId, driverNumber } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
    driverNumber?: number;
  };

  const { loading, data } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.schedule.scheduleC
  );
  const dispatch = useDispatchWrapper();
  const [saveLoading, setSaveLoading] = React.useState(false);
  const navigate = useNavigate();

  const { values, handleChange, handleSubmit, setFieldValue, setValues } =
    useFormik({
      initialValues: data,
      validate: (values) => {},
      onSubmit: (values) => {
        if (customerPolicyId) {
          setSaveLoading(true);
          dispatch(
            addCommAutomobileScheduleCAsync(
              {
                ...values,
                customer_policy_id: customerPolicyId,
                // incremental_number: driverNumber
              },
              (isSuccess) => {
                if (isSuccess) {
                  window.scrollTo(0, 0);
                }
                navigate(
                  `/comm-automobile-policy/${customerId}/vehicle-coverage-schedule-tab/${customerPolicyId}?tab=2`
                );
                setSaveLoading(false);
              }
            )
          );
        }
      },
    });

  const handleThirdPartyLiability =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newvalues = produce(
        values.section_one_third_party_liability,
        (draftState) => {
          draftState[rowIndex][key as "label"] = e.target.value;
        }
      );
      setFieldValue("section_one_third_party_liability", newvalues);
    };
  const handleThirdPartyLiabilityCurrencyChange =
    (key: string, rowIndex: number) => (value: string) => {
      const newvalues = produce(
        values.section_one_third_party_liability,
        (draftState) => {
          draftState[rowIndex][key as "label"] = value;
        }
      );
      setFieldValue("section_one_third_party_liability", newvalues);
    };

  const handleAccidentBenefits =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newvalues = produce(
        values.section_two_accident_benefits,
        (draftState) => {
          draftState[rowIndex][key as "label"] = e.target.value;
        }
      );
      setFieldValue("section_two_accident_benefits", newvalues);
    };
  const handleAccidentBenefitsCurrencyChange =
    (key: string, rowIndex: number) => (value: string) => {
      const newvalues = produce(
        values.section_two_accident_benefits,
        (draftState) => {
          draftState[rowIndex][key as "label"] = value;
        }
      );
      setFieldValue("section_two_accident_benefits", newvalues);
    };

  const handleOptionalIncreasedBenefits =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newvalues = produce(
        values.optional_increased_accident_benefits,
        (draftState) => {
          draftState[rowIndex][key as "label"] = e.target.value;
        }
      );
      setFieldValue("optional_increased_accident_benefits", newvalues);
    };
  const handleOptionalIncreasedBenefitsCurrencyChange =
    (key: string, rowIndex: number) => (value: string) => {
      const newvalues = produce(
        values.optional_increased_accident_benefits,
        (draftState) => {
          draftState[rowIndex][key as "label"] = value;
        }
      );
      setFieldValue("optional_increased_accident_benefits", newvalues);
    };

  const handleSection3 =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newvalues = produce(values.section_three, (draftState) => {
        draftState[rowIndex][key as "label"] = e.target.value;
      });
      setFieldValue("section_three", newvalues);
    };
  const handleSection3CurrencyChange =
    (key: string, rowIndex: number) => (value: string) => {
      const newvalues = produce(values.section_three, (draftState) => {
        draftState[rowIndex][key as "label"] = value;
      });
      setFieldValue("section_three", newvalues);
    };

  const handleSection4 =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newvalues = produce(values.section_four, (draftState) => {
        draftState[rowIndex][key as "label"] = e.target.value;
      });
      setFieldValue("section_four", newvalues);
    };
  const handleSection4CurrencyChange =
    (key: string, rowIndex: number) => (value: string) => {
      const newvalues = produce(values.section_four, (draftState) => {
        draftState[rowIndex][key as "label"] = value;
      });
      setFieldValue("section_four", newvalues);
    };

  const handleDamageOwnedAutomobiles =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newvalues = produce(
        values.section_five_lossof_or_damage_to_owned_automobiles,
        (draftState) => {
          draftState[rowIndex][key as "label"] = e.target.value;
        }
      );
      setFieldValue(
        "section_five_lossof_or_damage_to_owned_automobiles",
        newvalues
      );
    };

  const handleDamageOwnedAutomobilesCurrencyChange =
    (key: string, rowIndex: number) => (value: string) => {
      const newvalues = produce(
        values.section_five_lossof_or_damage_to_owned_automobiles,
        (draftState) => {
          draftState[rowIndex][key as "label"] = value;
        }
      );
      setFieldValue(
        "section_five_lossof_or_damage_to_owned_automobiles",
        newvalues
      );
    };

  const handleLiabilityforDamage =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newvalues = produce(
        values.section_six_liability_for_damage_to_a_customer_automobile,
        (draftState) => {
          draftState[rowIndex][key as "label"] = e.target.value;
        }
      );
      setFieldValue(
        "section_six_liability_for_damage_to_a_customer_automobile",
        newvalues
      );
    };
  const handleLiabilityforDamageCurrencyChange =
    (key: string, rowIndex: number) => (value: string) => {
      const newvalues = produce(
        values.section_six_liability_for_damage_to_a_customer_automobile,
        (draftState) => {
          draftState[rowIndex][key as "label"] = value;
        }
      );
      setFieldValue(
        "section_six_liability_for_damage_to_a_customer_automobile",
        newvalues
      );
    };

  const handleCollisionOrupsetChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newvalues = produce(values.collision_or_upset, (draftState) => {
        draftState[key as keyof typeof draftState] = e.target.value;
      });
      setFieldValue("section_six_a", newvalues);
    };

  const handleThirdPartyLiabilityDropDownChange =
    (key: string, index: number) => (e: SelectChangeEvent<any>) => {
      const newValues = produce(
        values.section_one_third_party_liability,
        (draftState) => {
          draftState.forEach((row) => {
            row[key as "label"] = e.target.value;
          });
        }
      );
      setFieldValue("section_one_third_party_liability", newValues);
    };

  const handleIncreasedAccidentBenefits =
    (key: string, index: number) => (e: SelectChangeEvent<any>) => {
      const newValues = produce(
        values.optional_increased_accident_benefits,
        (draftState) => {
          draftState[index][key as "label"] = e.target.value;
        }
      );
      setFieldValue("optional_increased_accident_benefits", newValues);
    };

  const thirdPartyLiability: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 1000,

    uniqueRowKeyName: "label",
    columns: [
      {
        key: "label",
        headerName: "",
        fieldName: "label",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
      },
      {
        key: "deductible",
        headerName: "Limit",
        fieldName: "deductible",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: ICommScheduleCCoverage) => {
          return (
            <ControlledCustomSelect
              fullWidth
              displayEmpty
              value={row.limit}
              options={[
                { label: "1,000,000", value: "1,000,000" },
                { label: "2,000,000", value: "2,000,000" },
                { label: "3,000,000", value: "3,000,000" },
                { label: "4,000,000", value: "4,000,000" },
                { label: "5,000,000", value: "5,000,000" },
                { label: "10,000,000", value: "10,000,000" },
              ]}
              placeholder="Select one"
              onChange={handleThirdPartyLiabilityDropDownChange(
                "limit",
                value.column.rowIndex
              )}
            />
          );
        },
      },

      {
        key: "premium",
        headerName: "Premium",
        fieldName: "premium",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.premium}
              onChange={handleThirdPartyLiabilityCurrencyChange(
                "premium",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.section_one_third_party_liability,
  };

  const accidentBenefits: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 1000,

    uniqueRowKeyName: "schedule-table",
    columns: [
      {
        key: "label",
        headerName: "",
        fieldName: "label",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
      },

      // {
      //   key: "deductible",
      //   headerName: "Deductible",
      //   fieldName: "deductible",
      //   renderType: RenderType.CUSTOM_RENDER,
      //   exportCellWidth: 15,

      //   onRowCellRender: (value, row: ICommScheduleCCoverage) => {
      //     return (
      //       <CurrencyTextField
      //         fullWidth
      //         value={row.deductible}
      //         onChange={handleAccidentBenefitsCurrencyChange(
      //           "deductible",
      //           value.column.rowIndex
      //         )}
      //       />
      //     );
      //   },
      // },
      // {
      //   key: "limit",
      //   headerName: "Limit",
      //   fieldName: "limit",
      //   renderType: RenderType.CUSTOM_RENDER,
      //   exportCellWidth: 15,

      //   onRowCellRender: (
      //     value: { column: { rowIndex: number } },
      //     row: ICommScheduleCCoverage
      //   ) => {
      //     return (
      //       <CurrencyTextField
      //         fullWidth
      //         value={row.limit}
      //         onChange={handleAccidentBenefitsCurrencyChange("limit", value.column.rowIndex)}
      //       />
      //     );
      //   },
      // },
      {
        key: "premium",
        headerName: "Premium",
        fieldName: "premium",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.premium}
              onChange={handleAccidentBenefitsCurrencyChange(
                "premium",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.section_two_accident_benefits,
  };

  const optionalIncreasedAccidentBenefits: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 1000,

    uniqueRowKeyName: "schedule-table",
    columns: [
      {
        key: "label",
        headerName: "",
        fieldName: "label",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
        onRowCellRender: (value, row: ICommScheduleCCoverage) => {
          const splitText = row.label?.split("(");
          const part1: string = splitText[0]?.trim();
          const part2: string = "(" + splitText[1]?.trim();
          return row.label?.length <= 76 ? (
            <Typography>{row.label}</Typography>
          ) : (
            <>
              <Typography>{part1}</Typography>
              <Typography>{part2}</Typography>
            </>
          );
        },
      },

      {
        key: "deductible",
        headerName: "Accepted",
        fieldName: "deductible",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: ICommScheduleCCoverage) => {
          return (
            <ControlledCustomSelect
              fullWidth
              value={row.deductible}
              options={[
                { label: "Y", value: "Y" },
                { label: "N", value: "N" },
              ]}
              placeholder="Select one"
              onChange={handleIncreasedAccidentBenefits(
                "deductible",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      // {
      //   key: "limit",
      //   headerName: "Limit",
      //   fieldName: "limit",
      //   renderType: RenderType.CUSTOM_RENDER,
      //   exportCellWidth: 15,

      //   onRowCellRender: (
      //     value: { column: { rowIndex: number } },
      //     row: ICommScheduleCCoverage
      //   ) => {
      //     return (
      //       <CurrencyTextField
      //         fullWidth
      //         value={row.limit}
      //         onChange={handleOptionalIncreasedBenefitsCurrencyChange(
      //           "limit",
      //           value.column.rowIndex
      //         )}
      //       />
      //     );
      //   },
      // },
      {
        key: "premium",
        headerName: "Premium",
        fieldName: "premium",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.premium}
              onChange={handleOptionalIncreasedBenefitsCurrencyChange(
                "premium",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.optional_increased_accident_benefits,
  };

  const section3: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 1000,

    uniqueRowKeyName: "schedule-table",
    columns: [
      {
        key: "label",
        headerName: "",
        fieldName: "label",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
      },

      {
        key: "deductible",
        headerName: "Deductible/Limit",
        fieldName: "deductible",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: ICommScheduleCCoverage) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.deductible}
              onChange={handleSection3CurrencyChange(
                "deductible",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      // {
      //   key: "limit",
      //   headerName: "Limit",
      //   fieldName: "limit",
      //   renderType: RenderType.CUSTOM_RENDER,
      //   exportCellWidth: 15,

      //   onRowCellRender: (
      //     value: { column: { rowIndex: number } },
      //     row: ICommScheduleCCoverage
      //   ) => {
      //     return (
      //       <CurrencyTextField
      //         fullWidth
      //         value={row.limit}
      //         onChange={handleSection3CurrencyChange("limit", value.column.rowIndex)}
      //       />
      //     );
      //   },
      // },
      {
        key: "premium",
        headerName: "Premium",
        fieldName: "premium",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.premium}
              onChange={handleSection3CurrencyChange(
                "premium",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.section_three,
  };

  const section4: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 1000,

    uniqueRowKeyName: "schedule-table",
    columns: [
      {
        key: "label",
        headerName: "",
        fieldName: "label",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
      },

      {
        key: "deductible",
        headerName: "Deductible/Limit",
        fieldName: "deductible",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: ICommScheduleCCoverage) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.deductible}
              onChange={handleSection4CurrencyChange(
                "deductible",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      // {
      //   key: "limit",
      //   headerName: "Limit",
      //   fieldName: "limit",
      //   renderType: RenderType.CUSTOM_RENDER,
      //   exportCellWidth: 15,

      //   onRowCellRender: (
      //     value: { column: { rowIndex: number } },
      //     row: ICommScheduleCCoverage
      //   ) => {
      //     return (
      //       <CurrencyTextField
      //         fullWidth
      //         value={row.limit}
      //         onChange={handleSection4CurrencyChange("limit", value.column.rowIndex)}
      //       />
      //     );
      //   },
      // },
      {
        key: "premium",
        headerName: "Premium",
        fieldName: "premium",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.premium}
              onChange={handleSection4CurrencyChange(
                "premium",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.section_four,
  };

  const damageOwnedAutomobiles: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 1000,

    uniqueRowKeyName: "schedule-table",
    columns: [
      {
        key: "label",
        headerName: "",
        fieldName: "label",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return row.label.includes("5.1.") === false ? (
            <CustomTextField
              fullWidth
              value={row.label}
              onChange={handleDamageOwnedAutomobiles(
                "label",
                value.column.rowIndex
              )}
            />
          ) : (
            <Typography>{row.label}</Typography>
          );
        },
      },

      {
        key: "locationPerItem1",
        headerName: "Location Per Item 1",
        fieldName: "locationPerItem1",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
      },

      {
        key: "subsectionInsured",
        headerName: "Subsections Insured",
        fieldName: "subsectionInsured",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return (
            <CustomTextField
              fullWidth
              value={row.subsectionInsured}
              onChange={handleDamageOwnedAutomobiles(
                "subsectionInsured",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "deductibleOfEachOccurrence",
        headerName: "Deductible for Each Occurrence",
        fieldName: "deductibleOfEachOccurrence",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.deductibleOfEachOccurrence}
              onChange={handleDamageOwnedAutomobilesCurrencyChange(
                "deductibleOfEachOccurrence",
                value.column.rowIndex
              )}
            />
          );
        },
      },

      {
        key: "limitofLiability",
        headerName: "Limit of Liability",
        fieldName: "limitofLiability",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.limitofLiability}
              onChange={handleDamageOwnedAutomobilesCurrencyChange(
                "limitofLiability",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "advacnePremimum",
        headerName: "Premium",
        fieldName: "advacnePremimum",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.advacnePremimum}
              onChange={handleDamageOwnedAutomobilesCurrencyChange(
                "advacnePremimum",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.section_five_lossof_or_damage_to_owned_automobiles,
  };

  const liabilityforDamage: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 1000,

    uniqueRowKeyName: "schedule-table",
    columns: [
      {
        key: "label",
        headerName: "",
        fieldName: "label",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return row.label === "" ? (
            <CustomTextField
              fullWidth
              value={row.label}
              onChange={handleLiabilityforDamage(
                "label",
                value.column.rowIndex
              )}
            />
          ) : (
            <Typography>{row.label}</Typography>
          );
        },
      },

      {
        key: "locationPerItem1",
        headerName: "Location Per Item 1",
        fieldName: "locationPerItem1",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
      },

      {
        key: "maximumNumberOfCustomers",
        headerName: "Maximum Number of Customer's Automobiles",
        fieldName: "maximumNumberOfCustomers",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return (
            <CustomTextField
              fullWidth
              value={row.maximumNumberOfCustomers}
              onChange={handleLiabilityforDamage(
                "maximumNumberOfCustomers",
                value.column.rowIndex
              )}
            />
          );
        },
      },

      {
        key: "limitofLiability",
        headerName: "Limit of Liability Any One Occurence",
        fieldName: "limitofLiability",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.limitofLiability}
              onChange={handleLiabilityforDamageCurrencyChange(
                "limitofLiability",
                value.column.rowIndex
              )}
            />
          );
        },
      },

      {
        key: "deductibleOfEachOccurrence",
        headerName: "Deductible of Each Occurrence",
        fieldName: "deductibleOfEachOccurrence",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.deductibleOfEachOccurrence}
              onChange={handleLiabilityforDamageCurrencyChange(
                "deductibleOfEachOccurrence",
                value.column.rowIndex
              )}
            />
          );
        },
      },

      {
        key: "advacnePremimum",
        headerName: "Premium",
        fieldName: "advacnePremimum",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.advacnePremimum}
              onChange={handleLiabilityforDamageCurrencyChange(
                "advacnePremimum",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.section_six_liability_for_damage_to_a_customer_automobile,
  };

  const handleSectionSixFirst = (key: string, index: number) => (e: any) => {
    const newValues = produce(values.section_six_b, (draftState) => {
      draftState[index][key as "label"] = e.target.value;
    });
    setFieldValue("section_six_b", newValues);
  };

  const handleDropDownSectionSixFirst =
    (key: string, index: number) => (e: SelectChangeEvent<any>) => {
      const newValues = produce(values.section_six_b, (draftState) => {
        draftState[index][key as "label"] = e.target.value;
      });
      setFieldValue("section_six_b", newValues);
    };

  React.useEffect(() => {
    const Section_One = values.section_one_third_party_liability.reduce(
      (acc, item) => {
        let inputString = item.premium;
        let outputString = inputString?.replaceAll(",", "");
        return acc + Number(outputString);
      },
      0
    );

    const Section_two = values.section_two_accident_benefits.reduce(
      (acc, item) => {
        let inputString = item.premium;
        let outputString = inputString?.replaceAll(",", "");
        return acc + Number(outputString);
      },
      0
    );

    const Section_three = values.section_three.reduce((acc, item) => {
      let inputString = item.premium;
      let outputString = inputString?.replaceAll(",", "");
      return acc + Number(outputString);
    }, 0);

    const Section_four = values.section_four.reduce((acc, item) => {
      let inputString = item.premium;
      let outputString = inputString?.replaceAll(",", "");
      return acc + Number(outputString);
    }, 0);

    const Section_five =
      values.section_five_lossof_or_damage_to_owned_automobiles.reduce(
        (acc, item) => {
          let inputString = item.advacnePremimum;
          let outputString = inputString?.replaceAll(",", "");
          return acc + Number(outputString);
        },
        0
      );

    const Section_six =
      values.section_six_liability_for_damage_to_a_customer_automobile.reduce(
        (acc, item) => {
          let inputString = item.advacnePremimum;
          let outputString = inputString?.replaceAll(",", "");
          return acc + Number(outputString);
        },
        0
      );

    const optional = values.optional_increased_accident_benefits.reduce(
      (acc, item) => {
        let inputString = item.premium;
        let outputString = inputString?.replaceAll(",", "");
        return acc + Number(outputString);
      },
      0
    );

    let total =
      Section_One +
      Section_two +
      Section_three +
      Section_four +
      Section_five +
      Section_six +
      optional;

    if (values.section_six_b[1].advacnePremimum) {
      let inputString = values.section_six_b[1].advacnePremimum;
      let outputString = inputString?.replaceAll(",", "");
      total = total + Number(outputString);
    }
    if (values.section_six_b[0].advacnePremimum) {
      let inputString = values.section_six_b[0].advacnePremimum;
      let outputString = inputString?.replaceAll(",", "");
      total = total + Number(outputString);
    }
    if (values.section_six_b[2].advacnePremimum) {
      let inputString = values.section_six_b[2].advacnePremimum;
      let outputString = inputString?.replaceAll(",", "");
      total = total + Number(outputString);
    }

    setFieldValue("total_premium", total);
  }, [
    values.section_one_third_party_liability,
    values.section_two_accident_benefits,
    values.section_three,
    values.section_four,
    values.section_five_lossof_or_damage_to_owned_automobiles,
    values.optional_increased_accident_benefits,
    values.section_six_b[1].advacnePremimum,
    values.section_six_b[2].advacnePremimum,
    values.section_six_b[0].advacnePremimum,
    values.section_six_liability_for_damage_to_a_customer_automobile,
  ]);

  React.useEffect(() => {
    if (customerPolicyId && driverNumber) {
      dispatch(
        fetchCommAutomobileScheduleCAsync(customerPolicyId, driverNumber)
      );
    }
  }, [customerPolicyId, driverNumber]);

  React.useEffect(() => {
    setValues(data);
  }, [data]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      <StandadCard heading="Garage Coverage(Ontario)">
        <form onSubmit={handleSubmit}>
          <LabelCard heading="Section 1. Third Party Liability" />
          {/* <DataTable {...thirdPartyLiability} /> */}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  <Typography fontWeight={600} variant="h4">
                    Limit
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography fontWeight={600} variant="h4">
                    Premium
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.section_one_third_party_liability.map((item, index) => {
                return (
                  <TableRow
                    key={item.key}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell>{item.label}</TableCell>
                    <TableCell>
                      <ControlledCustomSelect
                        fullWidth
                        displayEmpty
                        value={item.deductible}
                        options={[
                          { label: "1,000,000", value: "1,000,000" },
                          { label: "2,000,000", value: "2,000,000" },
                          { label: "3,000,000", value: "3,000,000" },
                          { label: "4,000,000", value: "4,000,000" },
                          { label: "5,000,000", value: "5,000,000" },
                          { label: "10,000,000", value: "10,000,000" },
                        ]}
                        placeholder="Select one"
                        onChange={handleThirdPartyLiabilityDropDownChange(
                          "deductible",
                          index
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <CurrencyTextField
                        fullWidth
                        value={item.premium}
                        onChange={handleThirdPartyLiabilityCurrencyChange(
                          "premium",
                          index
                        )}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <LabelCard heading="Section 2. Accident Benefits" />
          <DataTable {...accidentBenefits} />

          <LabelCard heading="Optional Increased Accident Benefits" />
          <DataTable {...optionalIncreasedAccidentBenefits} />

          <LabelCard heading="Section 3" />
          <DataTable {...section3} />

          <LabelCard heading="Section 4" />
          <DataTable {...section4} />

          <LabelCard heading="Section 5. Loss of or Damage to Owned Automobiles" />
          <Table sx={{ minWidth: 650, my: 2 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center"> </TableCell>
                <TableCell align="center">
                  {" "}
                  <Typography fontWeight={600} variant="h4">
                    Deductible
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  {" "}
                  <Typography fontWeight={600} variant="h4">
                    Premium
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  Deductible Applicable to each separate automobile
                </TableCell>
                <TableCell>
                  {" "}
                  <CustomTextField
                    id="each_separate_automobile_deductible"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.each_separate_automobile_deductible}
                    onChange={handleChange}
                  />
                </TableCell>
                <TableCell>
                  {" "}
                  <CustomTextField
                    id="each_separate_automobile_premium"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.each_separate_automobile_premium}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "center",
              ml: 2,
            }}
          >
            <Typography variant="body2">
              The Premium under sub-section 5.1.2, 5.1.3, and 5.1.4 shall be
              computed on a
            </Typography>
            <Stack
              direction="row"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                value={values.MCO}
                checked={values.MCO === "M"}
                onChange={(e, checked) => {
                  setFieldValue("MCO", checked ? "M" : null);
                }}
              />
              <Typography>Monthly Average Basis</Typography>
            </Stack>
            <Stack
              direction="row"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                value={values.MCO}
                checked={values.MCO === "C"}
                onChange={(e, checked) => {
                  setFieldValue("MCO", checked ? "C" : null);
                }}
              />
              <Typography>Co-Insurance Basis</Typography>
            </Stack>
            <Stack
              direction="row"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                value={values.MCO}
                checked={values.MCO === "O"}
                onChange={(e, checked) => {
                  setFieldValue("MCO", checked ? "O" : null);
                }}
              />
              <Typography>Other</Typography>
            </Stack>
          </Stack>
          <DataTable {...damageOwnedAutomobiles} />

          <LabelCard heading="Section 6. Liability for damage to a customer’s automobile while in the care, custody, or control of the applicant" />
          <Table sx={{ minWidth: 650, my: 2 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left"> </TableCell>
                <TableCell align="center">
                  {" "}
                  <Typography fontWeight={600} variant="h5">
                    Limit applicable to any one customer's automobile
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  {" "}
                  <Typography fontWeight={600} variant="h5">
                    Deductable applicable to each separate occurence
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  {" "}
                  <Typography fontWeight={600} variant="h4">
                    Premium
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>6.1 Collision or Upset</TableCell>
                <TableCell>
                  {" "}
                  <CustomTextField
                    id="collision_or_upset.limitofLiability"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.collision_or_upset.limitofLiability}
                    onChange={handleChange}
                  />
                </TableCell>
                <TableCell>
                  {" "}
                  <CustomTextField
                    id="collision_or_upset.deductibleOfEachOccurrence"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.collision_or_upset.deductibleOfEachOccurrence}
                    onChange={handleChange}
                  />
                </TableCell>
                <TableCell>
                  {" "}
                  <CustomTextField
                    id="collision_or_upset.advacnePremimum"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.collision_or_upset.advacnePremimum}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <DataTable {...liabilityforDamage} />

          <Table sx={{ minWidth: 650, my: 2 }} aria-label="simple table">
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{values.section_six_b[0].label}</TableCell>
                <TableCell></TableCell>
                <TableCell> </TableCell>
                <TableCell>
                  {" "}
                  <CustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    value={values.section_six_b[0].deductibleOfEachOccurrence}
                    onChange={handleDropDownSectionSixFirst(
                      "deductibleOfEachOccurrence",
                      0
                    )}
                    displayEmpty
                    fullWidth
                    renderValue={renderDropdownValue(`Select One`)}
                  >
                    {["Yes", "No"].map((policyType) => {
                      return (
                        <MenuItem value={policyType}>{policyType}</MenuItem>
                      );
                    })}
                  </CustomSelect>
                </TableCell>
                <TableCell>
                  {" "}
                  <CustomTextField
                    id="section_six_b[0].limitofLiability"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.section_six_b[0].limitofLiability}
                    onChange={handleSectionSixFirst("limitofLiability", 0)}
                  />
                </TableCell>
                <TableCell>
                  {" "}
                  <CustomTextField
                    id="section_six_b[0].advacnePremimum"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.section_six_b[0].advacnePremimum}
                    onChange={handleSectionSixFirst("advacnePremimum", 0)}
                  />
                </TableCell>
              </TableRow>

              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <CustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    value={values.section_six_b[1].label}
                    onChange={handleDropDownSectionSixFirst("label", 1)}
                    displayEmpty
                    fullWidth
                    renderValue={renderDropdownValue(`Select One`)}
                  >
                    {vehicle_schedule_dropdown.map((policyType) => {
                      return (
                        <MenuItem value={policyType}>{policyType}</MenuItem>
                      );
                    })}
                  </CustomSelect>
                </TableCell>
                <TableCell></TableCell>
                <TableCell> </TableCell>
                <TableCell>
                  {" "}
                  <CustomSelect
                    name="section_six_b[1].deductibleOfEachOccurrence"
                    variant="outlined"
                    size="small"
                    type="string"
                    value={values.section_six_b[1].deductibleOfEachOccurrence}
                    onChange={handleDropDownSectionSixFirst(
                      "deductibleOfEachOccurrence",
                      1
                    )}
                    displayEmpty
                    fullWidth
                    renderValue={renderDropdownValue(`Select One`)}
                  >
                    {["Yes", "No"].map((policyType) => {
                      return (
                        <MenuItem value={policyType}>{policyType}</MenuItem>
                      );
                    })}
                  </CustomSelect>
                </TableCell>
                <TableCell>
                  {" "}
                  <CustomTextField
                    id="section_six_b[1].limitofLiability"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.section_six_b[1].limitofLiability}
                    onChange={handleSectionSixFirst("limitofLiability", 1)}
                  />
                </TableCell>
                <TableCell>
                  {" "}
                  <CustomTextField
                    id="section_six_b[1].advacnePremimum"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.section_six_b[1].advacnePremimum}
                    onChange={handleSectionSixFirst("advacnePremimum", 1)}
                  />
                </TableCell>
              </TableRow>

              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <CustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    value={values.section_six_b[2].label}
                    onChange={handleDropDownSectionSixFirst("label", 2)}
                    displayEmpty
                    fullWidth
                    renderValue={renderDropdownValue(`Select One`)}
                  >
                    {vehicle_schedule_dropdown.map((policyType) => {
                      return (
                        <MenuItem value={policyType}>{policyType}</MenuItem>
                      );
                    })}
                  </CustomSelect>
                </TableCell>
                <TableCell></TableCell>
                <TableCell> </TableCell>
                <TableCell>
                  {" "}
                  <CustomSelect
                    name="section_six_b[2].deductibleOfEachOccurrence"
                    variant="outlined"
                    size="small"
                    type="string"
                    value={values.section_six_b[2].deductibleOfEachOccurrence}
                    onChange={handleDropDownSectionSixFirst(
                      "deductibleOfEachOccurrence",
                      2
                    )}
                    displayEmpty
                    fullWidth
                    renderValue={renderDropdownValue(`Select One`)}
                  >
                    {["Yes", "No"].map((policyType) => {
                      return (
                        <MenuItem value={policyType}>{policyType}</MenuItem>
                      );
                    })}
                  </CustomSelect>
                </TableCell>
                <TableCell>
                  {" "}
                  <CustomTextField
                    id="section_six_b[2].limitofLiability"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.section_six_b[2].limitofLiability}
                    onChange={handleSectionSixFirst("limitofLiability", 2)}
                  />
                </TableCell>
                <TableCell>
                  {" "}
                  <CustomTextField
                    id="section_six_b[2].advacnePremimum"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.section_six_b[2].advacnePremimum}
                    onChange={handleSectionSixFirst("advacnePremimum", 2)}
                  />
                </TableCell>
              </TableRow>

              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>Minimum Retained Premium</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <CustomTextField
                    id="minimum_retained_premium"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.minimum_retained_premium}
                    onChange={handleChange}
                  />
                </TableCell>
                <TableCell>
                  {" "}
                  <Typography fontWeight={600} variant="h4">
                    Total Premium
                  </Typography>
                </TableCell>
                <TableCell> </TableCell>
                <TableCell>
                  {" "}
                  <CustomTextField
                    name="total_premium"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.total_premium}
                    // onChange={handleChange}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          {/* <Table sx={{ minWidth: 650, my: 2 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                </TableCell>
                <TableCell align="center">
                  <Typography fontWeight={600} variant="h4">
                    Declined
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography fontWeight={600} variant="h4">
                    Deductible
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography fontWeight={600} variant="h4">
                    Premium
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody> */}
          {/* {values.endorsements?.map((item, index) => {
                return (
                  <TableRow
                    key={item.key}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      sx={{
                        width: "30%",
                      }}
                    >
                      <ControlledCustomSelect
                        fullWidth
                        value={item.label}
                        options={[
                          {
                            label: "20 Loss of Use",
                            value: "20 Loss of Use",
                          },
                          {
                            label: "21b Blanket Basis Fleet (Pro Rata)",
                            value: "21b Blanket Basis Fleet (Pro Rata)",
                          },
                          {
                            label:
                              "27 Legal Liability for Damage to Non-Owned Automobiles",
                            value:
                              "27 Legal Liability for Damage to Non-Owned Automobiles",
                          },
                          {
                            label:
                              "27b Business Operations - Liability for Damage to Non Owned Autos",
                            value:
                              "27b Business Operations - Liability for Damage to Non Owned Autos",
                          },
                          {
                            label: "21b Blanket Basis Fleet (50/50 basis)",
                            value: "21b Blanket Basis Fleet (50/50 basis)",
                          },
                          {
                            label: "43 Removing Depreciation Deduction",
                            value: "43 Removing Depreciation Deduction",
                          },
                          {
                            label: "44 Family Protection",
                            value: "44 Family Protection",
                          },
                          {
                            label: "39 Accident Rating Waiver",
                            value: "39 Accident Rating Waiver",
                          },
                          {
                            label: "5 Permission to Rent or Lease",
                            value: "5 Permission to Rent or Lease",
                          },
                          {
                            label: "23a Lienholder Protection",
                            value: "23a Lienholder Protection",
                          },
                          {
                            label: "40 Fire and Theft Deductible",
                            value: "40 Fire and Theft Deductible",
                          },
                          {
                            label:
                              "43a Removing Depreciation Deduction for Specified Lessee(s)",
                            value:
                              "43a Removing Depreciation Deduction for Specified Lessee(s)",
                          },
                          {
                            label: "5a Permission to Rent or Lease",
                            value: "5a Permission to Rent or Lease",
                          },
                          {
                            label: "44R Family Protection (Ontario)",
                            value: "44R Family Protection (Ontario)",
                          },
                          {
                            label:
                              "16 Agreement for suspension of Coverage OEF 71-EXCLUDING OWNED AUTO",
                            value:
                              "16 Agreement for suspension of Coverage OEF 71-EXCLUDING OWNED AUTO",
                          },
                          {
                            label: "OEF 77",
                            value: "OEF 77",
                          },
                        ]}
                        placeholder="Select one"
                        onChange={handleEndorsementsForms("label", index)}
                      />
                    </TableCell>
                    <TableCell>
                      <ControlledCustomSelect
                        fullWidth
                        value={item.declined}
                        options={[
                          { label: "Y", value: "Y" },
                          { label: "N", value: "N" },
                        ]}
                        placeholder="Select one"
                        onChange={handleEndorsementsForms("declined", index)}
                      />
                    </TableCell>
                    <TableCell>
                      <CustomTextField
                        fullWidth
                        value={item.deductibleOrLimit}
                        onChange={handleEndorsementsForms(
                          "deductibleOrLimit",
                          index
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <CustomTextField
                        fullWidth
                        value={item.premium}
                        onChange={handleEndorsementsForms("premium", index)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })} */}
          {/* </TableBody>
          </Table> */}

          <Box sx={{ mt: 4 }}>
            <RoleBasedCustomButton
              sx={{ width: "15%" }}
              disabled={saveLoading}
              variant="contained"
              moduleId={MODULE_IDS.COMMERCIAL_AUTO}
              type="submit"
            >
              Save
            </RoleBasedCustomButton>
          </Box>
        </form>
      </StandadCard>
    </PageLoader>
  );
};
