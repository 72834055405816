/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";

import { useNavigate, useParams } from "react-router-dom";
import { IStoreState } from "../../redux/initialStoreState";
import { useDispatchWrapper, usePoliciesByCustomer } from "../../hooks";

import { PageLoader } from "../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../constants/enums";
import {
  CustomFormLabel,
  CustomTextField,
} from "../../components/formsComponents";
import { StandadCard } from "../../components/Cards";
import { CustomDatePicker } from "../../components/formsComponents/CustomDatePicker";
import { RoleBasedCustomButton } from "../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { Breadcrumb } from "../../layout/fullLayout/breadcrumb/Breadcrumb";
import { IBreadcrumbProps } from "../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import {
  clearCsioEdocsState,
  fetchManageCsioEdocsAsync,
  upsertCsioEdocsAsync,
} from "../../redux/csioEdocs/csioEdocsActions";
import { FileUpload } from "../../components/FileUpload/FileUpload";

export const CSIOEdocs: React.FC = () => {
  const { messageGUID } = useParams() as {
    messageGUID?: string;
  };

  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      to: "/csio-edocs-list",
      title: "company equipments",
    },
    {
      title: messageGUID ? "CSIO EDOCS" : "Create CSIO EDOCS",
    },
  ];

  const { data, dataLoading, error } = useSelector(
    (storeState: IStoreState) => storeState.csioEdocs
  );

  const [file, setFile] = React.useState<File | null>(null);

  const [saveLoading, setSaveLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: data,
    validate: (values) => {},
    onSubmit: (values) => {
      setSaveLoading(true);
      dispatch(
        upsertCsioEdocsAsync(
          values,

          data,
          messageGUID ? true : false,
          file,
          (isSuccess) => {
            if (isSuccess) {
              navigate("/csio-edocs-list");
            }
            setSaveLoading(false);
          }
        )
      );
    },
  });

  // const handleCustomer = (data: ICustomerFullDetails) => {
  //   if (data.customer_id) {
  //     setValues({
  //       ...values,
  //       customer_id: data.customer_id as number,
  //       customer_name: data.customer_name,
  //       customer_policy_id: null,
  //       policy_number: null,
  //     });
  //   }
  // };

  // const handlePolicyChange = (e: SelectChangeEvent<unknown>) => {
  //   const policyId = e.target.value as string;
  //   const policy = policiesList.find(
  //     (x) => x.customer_policy_id === Number(policyId)
  //   );
  //   if (policy) {
  //     setValues({
  //       ...values,
  //       customer_policy_id: policyId,
  //       policy_number: policy.policy_number,
  //     });
  //   }
  // };

  // React.useEffect(() => {
  //   if (values.customer_id) {
  //     fetchPoliciesByCustomerId(values.customer_id as number);
  //   }
  // }, [values.customer_id]);

  // React.useEffect(() => {
  //   if (csioEdocsId) {
  //     dispatch(fetchCsioEdocsAsync(csioEdocsId));
  //   }
  // }, [csioEdocsId]);

  React.useEffect(() => {
    if (messageGUID) {
      dispatch(fetchManageCsioEdocsAsync(messageGUID));
    }
  }, [messageGUID]);

  React.useEffect(() => {
    setValues({
      ...data,
    });
  }, [data]);

  React.useEffect(() => {
    return () => {
      dispatch(clearCsioEdocsState());
    };
  }, []);

  return (
    <PageLoader
      loading={dataLoading === LoadState.InProgress}
      error={error ? { message: error } : null}
    >
      <Breadcrumb title="" items={BCrumb} />
      <StandadCard sx={{ mt: 2 }} heading={`CSIO EDOCS`}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Customer Name</CustomFormLabel>
              <CustomTextField
                name="customer_name"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.customer_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Policy</CustomFormLabel>
              <CustomTextField
                name="policy_number"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.policy_number}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Type</CustomFormLabel>
              <CustomTextField
                name="type"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.type}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Business Purpose Type</CustomFormLabel>
              <CustomTextField
                name="business_purpose_type"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.business_purpose_type}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Insuracne Company</CustomFormLabel>
              <CustomTextField
                name="insurance_company"
                value={values.insurance_company}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Contract Term Effective Date</CustomFormLabel>
              <CustomDatePicker
                value={values.contract_term_effective_date || ""}
                onChange={(value) =>
                  setFieldValue("contract_term_effective_date", value)
                }
                fullWidth
                errorMessage={errors.contract_term_effective_date}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <CustomFormLabel>Contract Term Expiry Date</CustomFormLabel>
              <CustomDatePicker
                value={values.contract_term_expiry_date || ""}
                onChange={(value) =>
                  setFieldValue("contract_term_expiry_date", value)
                }
                fullWidth
                errorMessage={errors.contract_term_expiry_date}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <CustomFormLabel>Line of Business</CustomFormLabel>
              <CustomTextField
                name="line_of_business"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.line_of_business}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <Typography variant="h5" fontWeight={800}>
                Attachments EDOCS
              </Typography>
              <FileUpload
                value={values.attachment} disabled
                onChange={(file) => setFile(file)}
                deleteDisabled
              />
            </Grid>

            <Grid item xs={12} lg={6}></Grid>

            <Grid item xs={12} md={6}>
              <CustomFormLabel>Remarks</CustomFormLabel>
              <CustomTextField
                multiline
                rows={3}
                name="remarks"
                value={values.remarks}
                onChange={handleChange}
                fullWidth
                error={errors.remarks ? true : false}
                // helperText={errors.remarks}
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: 4 }}>
            <RoleBasedCustomButton
              sx={{ width: "15%" }}
              disabled={saveLoading}
              variant="contained"
              moduleId={MODULE_IDS.EQUIPMENT}
              type="submit"
            >
              Save
            </RoleBasedCustomButton>
          </Box>
        </form>
      </StandadCard>
    </PageLoader>
  );
};
