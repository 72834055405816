import moment from "moment";
import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface IBusinessPolicyLiabilityState {
  businessPolicyLiabilityList: IBusinessPolicyLiability[];
  businessPolicyLiabilityListLoading: LoadState;
  businessPolicyLiability: IBusinessPolicyLiability;
  businessPolicyLiabilityLoading: LoadState;
}

export interface IBusinessPolicyLiability {
  incremental_number: number | null;
  customer_policy_id: number;
  cgl_policy_number: string | null;
  automobile_policy: string | null;
  umbrella_or_excess_liability_policy: string | null;
  other_policy: string | null;
  non_owned_automobiles_policy: string | null;
  hired_automobiles: string | null;
  vehicle_unit_number_or_vin: string | null;
  certificate_holder_name: string | null;
  certificate_holder_address: string | null;
  certificate_holder_email: string | null;
  certificate_holder_phone: string | null;
  description_of_operations: {
    key: string;
    label: string;
    value: string;
  }[];
  commercial_general_liability_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  commercial_general_liability_section_two: {
    claim_mode: string;
    claim_mode_deductible: string | number;
    claim_mode_amount: string | number;
    occurance: string;
    occurance_deductible: string | number;
    occurance_amount: string | number;
    products_completed_oprations: string;
    products_completed_oprations_deductible: string | number;
    products_completed_oprations_amount: string | number;
    empolyer_liability: string;
    empolyer_liability_peronal_injury: string;
    empolyer_liability_deductible: string | number;
    empolyer_liability_amount: string | number;
    waiver_of_subrogation: string;
    waiver_of_subrogation_peronal_injury: string;
    waiver_of_subrogation_deductible: string | number;
    waiver_of_subrogation_amount: string | number;
    cross_liability: string;
    cross_liability_deductible: string | number;
    cross_liability_amount: string | number;
    tenants_Legal_Liability: string;
    tenants_Legal_Liability_deductible: string | number;
    tenants_Legal_Liability_amount: string | number;
    pollution_Liability_Extension: string;
    pollution_Liability_Extension_deductible: string | number;
    pollution_Liability_Extension_amount: string | number;
  };
  commercial_general_liability_section_three: {
    key: string;
    type_of_insurance_check: string;
    type_of_insurance: string;
    coverage: string;
    deductible: string | number;
    amount: string | number;
  }[];
  non_owned_automobiles_section_one: {
    insurance_company: string | null;
    policy_number: string | null;
    effective_date: string;
    expiry_date: string;
  };
  non_owned_automobiles_section_two: {
    non_owned: string;
    non_owned_deductible: string;
    non_ownder_amount: string;
  };
  hired_automobiles_section_one: {
    insurance_company: string | null;
    policy_number: string | null;
    effective_date: string;
    expiry_date: string;
  };
  hired_automobiles_section_two: {
    hired_type_of_insurance: string;
    hired_deductible: string;
    hired_amount: string;
  };
  automobile_liability_section_one: {
    insurance_company: string | null;
    policy_number: string | null;
    effective_date: string;
    expiry_date: string;
  };
  automobile_liability_section_two: {
    described_automobiles: string;
    described_automobiles_deductible: string;
    described_automobiles_amount: string;
    all_owned_automobiles: string;
    all_owned_automobiles_deductible: string;
    all_owned_automobiles_amount: string;
    leased_automobiles: string;
    leased_automobiles_deductible: string;
    leased_automobiles_amount: string;
    property_damage_deductible: string;
    property_damage_amount: string;
  };
  excess_liability_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  excess_liability_section_two: {
    umbrella_form: string;
    umbrella_form_deductible: string;
    umbrella_form_amount: string;
    aggreate_deductible: string;
    aggreate_amount: string;
    extra: string;
    extra_type_of_insurance: string;
    extra_coverage: string;
    extra_deductible: string;
    extra_amount: string;
  };
  other_liability_section_one: {
    insurance_company: string | null;
    policy_number: string | null;
    effective_date: string;
    expiry_date: string;
  };
  other_liability_section_two: {
    key: string;
    type_of_insurance_check: string;
    type_of_insurance: string;
    coverage: string;
    deductible: string | number;
    amount: string | number;

  }[];
  cancelation_number_of_days: string | null;
  brokerage_full_name: string | null;
  brokerage_mailing_address: string | null;
  brokerage_customer_code: string | null;
  additional_name_insured_yes_or_no: string | null;
  additional_name_insured_name: string | null;
  additional_name_insured_mailing_address: string | null;
  certificate_authorization_branch_name: string | null;
  certificate_authorization_assigned_to: number | null;
  certificate_authorization_phone_number: string | null;
  certificate_authorization_fax_number: string | null;
  certificate_authorization_email: string | null;
  certificate_authorization_date: string | null;
  status: string;
}

export const initialBusinessPolicyLiability: IBusinessPolicyLiability = {
  incremental_number: null,
  customer_policy_id: 0,
  cgl_policy_number: null,
  automobile_policy: null,
  umbrella_or_excess_liability_policy: null,
  other_policy: null,
  non_owned_automobiles_policy: null,
  hired_automobiles: null,
  vehicle_unit_number_or_vin: null,
  certificate_holder_name: null,
  certificate_holder_address: null,
  certificate_holder_email: null,
  certificate_holder_phone: null,
  description_of_operations: [
    { key: getUniqueId(), label: "Line 1", value: "" },
    { key: getUniqueId(), label: "Line 2", value: "" },
    { key: getUniqueId(), label: "Line 3", value: "" },
    { key: getUniqueId(), label: "Line 4", value: "" },
  ],
  commercial_general_liability_section_one: {
    insurance_company: "",
    policy_number: "",
    effective_date: "",
    expiry_date: "",
  },
  commercial_general_liability_section_two: {
    claim_mode: "N",
    claim_mode_deductible: "",
    claim_mode_amount: "5,000,000",
    occurance: "N",
    occurance_deductible: "1,000",
    occurance_amount: "2,000,000",
    products_completed_oprations: "N",
    products_completed_oprations_deductible: "",
    products_completed_oprations_amount: "2,000,000",
    empolyer_liability: "N",
    empolyer_liability_peronal_injury: "N",
    empolyer_liability_deductible: "",
    empolyer_liability_amount: "",
    waiver_of_subrogation: "N",
    waiver_of_subrogation_peronal_injury: "N",
    waiver_of_subrogation_deductible: "",
    waiver_of_subrogation_amount: "2,000,000",
    cross_liability: "N",
    cross_liability_deductible: "",
    cross_liability_amount: "5,000",
    tenants_Legal_Liability: "N",
    tenants_Legal_Liability_deductible: "1,000",
    tenants_Legal_Liability_amount: "250,000",
    pollution_Liability_Extension: "N",
    pollution_Liability_Extension_deductible: "",
    pollution_Liability_Extension_amount: "",
  },
  commercial_general_liability_section_three: [
    {
      key: getUniqueId(),
      type_of_insurance_check: "",
      type_of_insurance: "",
      coverage: "",
      deductible: "",
      amount: "",
    },
    {
      key: getUniqueId(),
      type_of_insurance_check: "",
      type_of_insurance: "",
      coverage: "",
      deductible: "",
      amount: "",
    },

  ],
  non_owned_automobiles_section_one: {
    insurance_company: "",
    policy_number: "",
    effective_date: "",
    expiry_date: "",
  },
  non_owned_automobiles_section_two: {
    non_owned: "N",
    non_ownder_amount: "",
    non_owned_deductible: "",
  },
  hired_automobiles_section_one: {
    insurance_company: "",
    policy_number: "",
    effective_date: "",
    expiry_date: "",
  },
  hired_automobiles_section_two: {
    hired_type_of_insurance: "N",
    hired_amount: "",
    hired_deductible: "",
  },
  automobile_liability_section_one: {
    insurance_company: "",
    policy_number: "",
    effective_date: "",
    expiry_date: "",
  },
  automobile_liability_section_two: {
    described_automobiles: "N",
    described_automobiles_deductible: "",
    described_automobiles_amount: "1,000,000",
    all_owned_automobiles: "N",
    all_owned_automobiles_deductible: "",
    all_owned_automobiles_amount: "",
    leased_automobiles: "N",
    leased_automobiles_deductible: "",
    leased_automobiles_amount: "",
    property_damage_deductible: "",
    property_damage_amount: "",
  },
  excess_liability_section_one:{
    insurance_company: "",
    policy_number: "",
    effective_date: "",
    expiry_date: "",
  },
  excess_liability_section_two: {
    umbrella_form: 'N',
    umbrella_form_amount: '',
    aggreate_deductible: "",
    umbrella_form_deductible: '',
    extra: 'N',
    extra_coverage: '',
    extra_type_of_insurance: '',
    extra_amount: '',
    extra_deductible: '',
    aggreate_amount: '',
  },
  other_liability_section_one: {
    insurance_company: "",
    policy_number: "",
    effective_date: "",
    expiry_date: "",
  },
  other_liability_section_two: [
    {
      key: getUniqueId(),
      type_of_insurance_check: "",
      type_of_insurance: "",
      coverage: "",
      deductible: "",
      amount: "",
    },
    {
      key: getUniqueId(),
      type_of_insurance_check: "",
      type_of_insurance: "",
      coverage: "",
      deductible: "",
      amount: "",
    },
  ],
  cancelation_number_of_days: "0",
  brokerage_full_name: null,
  brokerage_mailing_address: null,
  brokerage_customer_code: null,
  additional_name_insured_yes_or_no: null,
  additional_name_insured_name: null,
  additional_name_insured_mailing_address: null,
  certificate_authorization_branch_name: null,
  certificate_authorization_assigned_to: null,
  certificate_authorization_phone_number: null,
  certificate_authorization_fax_number: null,
  certificate_authorization_email: null,
  certificate_authorization_date: moment().format("YYYY-MM-DD"),
  status: "ACTIVE",
};

export const initialBusinessPolicyLiabilityState: IBusinessPolicyLiabilityState =
  { 
    businessPolicyLiabilityList: [],
    businessPolicyLiabilityListLoading: LoadState.NotLoaded,
    businessPolicyLiability: initialBusinessPolicyLiability,
    businessPolicyLiabilityLoading: LoadState.NotLoaded,
  };
