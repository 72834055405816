import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import {
  ICommAutomobileSupplement,
  initialCommAutomobileSupplement,
} from "./commAutoSupplement.types";

export const FETCH_COMM_AUTOMOBILE_SUPPLEMENT_DETAILS_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_SUPPLEMENT_DETAILS_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_SUPPLEMENT_DETAILS_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_SUPPLEMENT_DETAILS_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_SUPPLEMENT_DETAILS_FAILED =
  "FETCH_COMM_AUTOMOBILE_SUPPLEMENT_DETAILS_FAILED";
export const CLEAR_COMM_AUTOMOBILE_SUPPLEMENT_DETAILS =
  "CLEAR_COMM_AUTOMOBILE_SUPPLEMENT_DETAILS";

export const fetchCommAutomobileSupplementDetailsProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_SUPPLEMENT_DETAILS_PROGRESS);
export const fetchCommAutomobileSupplementDetailsSuccess = (
  data: ICommAutomobileSupplement
) =>
  action(FETCH_COMM_AUTOMOBILE_SUPPLEMENT_DETAILS_SUCCESS, {
    data,
  });
export const fetchCommAutomobileSupplementDetailsFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_SUPPLEMENT_DETAILS_FAILED);
export const clearCommAutomobileSupplementDetails = () =>
  action(CLEAR_COMM_AUTOMOBILE_SUPPLEMENT_DETAILS);

export const addCommAutomobileSupplementDetailsAsync =
  (
    data: ICommAutomobileSupplement,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-commauto-all-cvs", {
        ...data,
      });

      dispatch(fetchCommAutomobileSupplementDetailsSuccess(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Automobile supplement saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileSupplementDetailsAsync =
  (
    customerPolicyId: number,
    serialNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileSupplementDetailsProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-all-cvs?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${serialNumber}`
      );
      const data: ICommAutomobileSupplement[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchCommAutomobileSupplementDetailsSuccess(data[0]));
      } else {
        dispatch(
          fetchCommAutomobileSupplementDetailsSuccess(
            initialCommAutomobileSupplement["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchCommAutomobileSupplementDetailsFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
