import produce from "immer";
import { BusinePolicyUnderWritingActions } from ".";
import { LoadState } from "../../../constants/enums";
import { IStoreState } from "../../initialStoreState";

import { defaultBusinessPolicyUnderWritingState } from "./businessPolicyUnderWriting.types";
import { CLEAR_BUSINESS_POLICY_UNDER_WRITING, FETCH_BUSINESS_POLICY_UNDER_WRITING_FAILED, FETCH_BUSINESS_POLICY_UNDER_WRITING_PROGRESS, FETCH_BUSINESS_POLICY_UNDER_WRITING_SUCCESS } from "./businePolicyUnderWritingActions";

export const businessPolicyUnderWritingReducer = (
  state: IStoreState["business"]["underwriting"] = defaultBusinessPolicyUnderWritingState,
  action: BusinePolicyUnderWritingActions
) => {
  switch (action.type) {
    case FETCH_BUSINESS_POLICY_UNDER_WRITING_PROGRESS: {
      return {
        ...state,
        data: defaultBusinessPolicyUnderWritingState["data"],
        loading: LoadState.InProgress,
      };
    }
    case FETCH_BUSINESS_POLICY_UNDER_WRITING_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.data = data;
        draftState.loading = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_UNDER_WRITING_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
      });
      return newState;
    }
    case CLEAR_BUSINESS_POLICY_UNDER_WRITING: {
      return defaultBusinessPolicyUnderWritingState
    }
    default: {
      return state;
    }
  }
};
