import React from "react";
import { Avatar, Box, Button, Link, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../../components/Table/DataTable";

import { ICustomerContact } from "../../../../../../../redux/customer/customer.types";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { fetchCustomerContactsListAsync } from "../../../../../../../redux/customer/customerActions";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";
import { RoleBasedMessages } from "../../../../../../../security/RoleBasedComponents/RoleBaseMessages/RoleBaseMessages";
import { CustomSelect } from "../../../../../../../components/formsComponents";
import { renderDropdownValue } from "../../../../../../../helpers";
import { fetchAutomobileAdditionalInsuredListAsync } from "../../../../../../../redux/automobileBasicDetails/automobileBasicDetailsActions";
import { AdditionalInsuredForm } from "./AdditionalInsuredDialog/AdditionalInsuredDialog";
import { IAutomobileAdditionalInsured } from "../../../../../../../redux/automobileBasicDetails/automobileBasicDetails.types";



export const AutomobileAdditioanlInsured: React.FC<{ customerpolicyId: number }> = (props) => {
  const { customerpolicyId } = props;
  const navigate = useNavigate();
  const dispatch = useDispatchWrapper();

  const { list, loading } = useSelector(
    (storeState: IStoreState) => storeState.automobile.basicDetails.additional_insured
  );

  const [openContact, setOpenContact] = React.useState<IAutomobileAdditionalInsured | null>(
    null
  );

  const [openViewContacts, setOpenViewontacts] =
    React.useState<ICustomerContact | null>(null);

  const handleComplete = () => {
    dispatch(fetchAutomobileAdditionalInsuredListAsync(customerpolicyId));
    setOpenContact(null);
  };

  const [selectedCustomers, setSelectedCustomers] = React.useState<{
    isAllSelected: boolean;
    customers: ICustomerContact[];
  }>({
    isAllSelected: false,
    customers: [],
  });

  const menuItems =
    selectedCustomers.customers.length > 0 || selectedCustomers.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const contactsTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    selectionMode: "multiple",
    uniqueRowKeyName: "type",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [...menuItems],
      },
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: (value, row: IAutomobileAdditionalInsured) => (
              <Button
                variant="contained"
                onClick={() =>
                  setOpenContact({
                    ...row,
                    customer_policy_id: customerpolicyId,
                  })
                }
              >
                Add New Insured
              </Button>
            ),
          },
        ],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "type",
        headerName: "Type",
        fieldName: "type",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        enableSorting: true,
        onRowCellRender: (value, row: IAutomobileAdditionalInsured) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setOpenContact(row);
              }}
            >
              {row.type}
            </Link>
          );
        },
      },
      {
        key: "customer_name",
        headerName: "Customer Name",
        fieldName: "customer_name",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
        enableSorting: true,
      },
      {
        key: "mobile_number",
        headerName: "Mobile",
        fieldName: "mobile_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
        enableSorting: true,
      },

      {
        key: "phone_number",
        headerName: "Phone",
        fieldName: "phone_number",
        exportCellWidth: 25,
        enableSorting: true,
        renderType: RenderType.TEXT,
        onRowCellRender: (value, row: IAutomobileAdditionalInsured) => {
          if (row.phone_number) {
            return (
              <RoleBasedMessages
                phone={row.phone_number}
                moduleId={MODULE_IDS.CUSTOMER}
              />
            );
          }

          return "--";
        },
      },
      {
        key: "gender",
        headerName: "Gender",
        fieldName: "gender",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "birth_date",
        headerName: "DOB",
        fieldName: "birth_date",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "marital_status",
        headerName: "Marital Status",
        fieldName: "marital_status",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        headingAlign: "center",
        onRowCellRender: (value, row) => {
          return (
            <StandardTableActions
              // onViewClick={() => setOpenViewontacts(row)}
              onEditClick={() => {
                setOpenContact(row);
              }}
            />
          );
        },
      },
    ],
    items: list,
    onSelection: (isAllSelected: boolean, rows: ICustomerContact[]) => {
      setSelectedCustomers({ isAllSelected: isAllSelected, customers: rows });
    },
  };

  React.useEffect(() => {
    dispatch(fetchAutomobileAdditionalInsuredListAsync(customerpolicyId));
  }, []);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...contactsTableProps} />
      </Box>
      {openContact && (
        <AdditionalInsuredForm
          open={true}
          insured={openContact}
          onClose={() => setOpenContact(null)}
          onComplete={handleComplete}
        />
      )}
    </>
  );
};
