/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Typography,
  Divider,
  Stack,
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { CustomDatePicker } from "../../../../../../../components/formsComponents/CustomDatePicker";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import moment from "moment";
import { InsurerCodeAutoSearch } from "../../../../../../../components/AutoCompleteSearches";
import {
  current_status,
  payment_method,
} from "../../../../../../../constants/constants";
import { renderDropdownValue } from "../../../../../../../helpers";
import { ICommAutomobilePolicyBasicDetailsProps } from "../CommAutoBasicDetailsprops.types";
import {
  addCommAutomobilePriorPolicyInfoAsync,
  fetchCommAutomobilePriorPolicyAsync,
} from "../../../../../../../redux/commAutoPolicy/commAutoBasicDetails/commAutoBasicDetailsActions";
import { CurrencyTextField } from "../../../../../../../components/CurrencyTextField/CurrencyTextField";
import { StandadCard } from "../../../../../../../components/Cards";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { ExtendableSelect } from "../../../../../../../components/formsComponents/ExtendableSelect";
import { useTabsUncontrolledContext } from "../../../../../../../contexts/TabsContextProvider/TabsContextUncontrolledProvider";

export const CommAutomobilePriorPolicy: React.FC<
  ICommAutomobilePolicyBasicDetailsProps
> = (props) => {
  const { customerpolicyId } = props;
  const { data, loading } = useSelector(
    (storeState: IStoreState) =>
      storeState.commAuto.basicDetails.priorPolicy
  );
  const dispatch = useDispatchWrapper();
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [buttonClicked, setButtonClicked] = React.useState<string | null>(null);
  const { changeStep } = useTabsUncontrolledContext();
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: data,
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        addCommAutomobilePriorPolicyInfoAsync(
          {
            ...values,
            customer_policy_id: customerpolicyId,
          },
          (isSuccess) => {
            if(buttonClicked === "saveAndNext" && isSuccess){
              changeStep(3);
            }
            setSaveLoading(false);
          }
        )
      );
    },
  });

  React.useEffect(() => {
    dispatch(fetchCommAutomobilePriorPolicyAsync(customerpolicyId));
  }, []);

  React.useEffect(() => {
    setValues(data);
  }, [data]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      {/* <Typography
        variant="h2"
        fontWeight={600}
        sx={{
          mt: 4,
        }}
      >
        Prior Policy
      </Typography>
      <Divider sx={{ mt: 2, mb: 2 }} /> */}
      {/* <StandadCard> */}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Line of Business</CustomFormLabel>
              <CustomTextField
                name="line_of_business"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.line_of_business}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Policy Expiry Date</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.policy_expiry_date || ""}
                onChange={(newValue) => {
                  setFieldValue("policy_expiry_date", newValue);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Current Insurer</CustomFormLabel>
              <CustomTextField
                name="current_insurer"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.current_insurer}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Expiring Premium</CustomFormLabel>
              <CurrencyTextField
                fullWidth
                value={values.expiring_premium}
                onChange={(value) => {
                  setFieldValue("expiring_premium", value);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Current Status</CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={values.current_status}
                options= {(current_status || []).map((type) => {
                  return { label: type, value: type}
                })}
                onChange={(newValue)=>{
                  setFieldValue("current_status", newValue)
                }}
              />
            </Grid>
          </Grid>

          <Divider sx={{ mt: 4, mb: 2.5 }} />

          <Grid container spacing={1}>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Non Payment Code</CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={values.non_payment_code}
                options= {(payment_method || []).map((type) => {
                  return { label: type, value: type}
                })}
                onChange={(newValue)=>{
                  setFieldValue("non_payment_code", newValue)
                }}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Non Payment Date</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.non_payment_date || ""}
                onChange={(newValue) => {
                  setFieldValue("non_payment_date", newValue);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Insurer</CustomFormLabel>
              <CustomTextField
                name="insurer"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.insurer}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Amount Owing</CustomFormLabel>
              <CurrencyTextField
                fullWidth
                value={values.amount_owing}
                onChange={(value) => {
                  setFieldValue("amount_owing", value);
                }}
              />
            </Grid>
          </Grid>

          <Stack direction={"row"} spacing={2} sx={{ mt: 4 }}>
          <RoleBasedCustomButton
            type="button"
            moduleId={MODULE_IDS.COMMERCIAL_AUTO}
            disabled={saveLoading}
            variant="contained"
            onClick={() => changeStep(1)}
          >
            Back
          </RoleBasedCustomButton>
          <RoleBasedCustomButton
            type="submit"
            moduleId={MODULE_IDS.COMMERCIAL_AUTO}
            disabled={saveLoading}
            variant="contained"
            onClick={() => setButtonClicked("save")}
          >
            Save
          </RoleBasedCustomButton>
          <RoleBasedCustomButton
            type="submit"
            moduleId={MODULE_IDS.COMMERCIAL_AUTO}
            disabled={saveLoading}
            variant="contained"
            onClick={() => setButtonClicked("saveAndNext")}
          >
            Save & Next
          </RoleBasedCustomButton>
        </Stack>
        </form>
      {/* </StandadCard> */}
    </PageLoader>
  );
};
