import { api } from "../api/api";
import { compareTwoObejcts } from "./compareTwoObjects";

export const findPrimaryFieldValue = (obj: any, primaryFieldName: string) => {
  // Base case: if obj is null or undefined, return null
  if (!obj || typeof obj !== "object") {
    return null;
  }

  // Check if the primary field exists in the current level of obj
  if (obj.hasOwnProperty(primaryFieldName)) {
    return obj[primaryFieldName];
  }

  // Recursive case: search through all properties of obj
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const result: any = findPrimaryFieldValue(obj[key], primaryFieldName);
      if (result !== null) {
        return result;
      }
    }
  }

  // If primary field is not found, return null
  return null;
};

export const makeApiCall = async (
  config: {
    url: string;
    method: "GET" | "POST";
    automation?: {
      primaryFieldName: string;
      isUpdate: boolean;
    };
  },
  initialData?: any,
  payload?: any
) => {
  try {
    if (config.method === "POST") {
      const response = await api.post(config.url, payload);
      if (config.automation) {
        const primaryFieldValue = findPrimaryFieldValue(
          response.data,
          config.automation.primaryFieldName
        );
        if (primaryFieldValue) {
          await initiateWorkFlow(
            initialData,
            payload,
            config.url,
            config.automation.isUpdate,
            config.automation.primaryFieldName,
            primaryFieldValue
          );
        }
      }
      return response;
    }

    const response = await api.get(config.url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const initiateWorkFlow = async (
  initialData: any,
  currentData: any,
  apiUrl: string,
  isUpdate: boolean,
  primaryFieldName: string,
  primaryFieldValue: any
) => {
  try {
    const payload: {
      endpoint_path: string;
      upsert_type: "CREATE" | "UPDATE";
      identifier: {
        column_name: string;
        column_value: any;
      };
      changed_column: {
        [key: string]: {
          old_value: any;
          new_value: any;
        };
      };
      no_changed_column: {
        [key: string]: any;
      };
    } = {
      endpoint_path: "/api/v1" + apiUrl,
      upsert_type: isUpdate ? "UPDATE" : "CREATE",
      identifier: {
        column_name: primaryFieldName,
        column_value: primaryFieldValue || null,
      },
      changed_column: {},
      no_changed_column: {},
    };

    if (isUpdate) {
      const finalData = compareTwoObejcts(initialData, currentData);
      payload.changed_column = finalData.changed;
      payload.no_changed_column = finalData.unchanged;
    } else {
      payload.no_changed_column = currentData;
    }

    await api.post("/workflow/workflow-initiate", payload);
  } catch (err: any) {
    throw err;
  }
};
