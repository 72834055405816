import produce from "immer";
import { LoadState } from "../../../constants/enums";
import { IStoreState } from "../../initialStoreState";

import { initialBusinessPolicyLiabilityState } from "./businessPolicyLiability.types";
import { BusinessPolicyLiabilityActions } from ".";
import {
  CLEAR_BUSINESS_POLICY_LIABILITY_STATE,
  FETCH_BUSINESS_POLICY_LIABILITY_FAILED,
  FETCH_BUSINESS_POLICY_LIABILITY_LIST_FAILED,
  FETCH_BUSINESS_POLICY_LIABILITY_LIST_PROGRESS,
  FETCH_BUSINESS_POLICY_LIABILITY_LIST_SUCCESS,
  FETCH_BUSINESS_POLICY_LIABILITY_PROGRESS,
  FETCH_BUSINESS_POLICY_LIABILITY_SUCCESS,
} from "./businessPolicyLiabilityActions";

export const businessPolicyLiabilityReducer = (
  state: IStoreState["business"]["lability"] = initialBusinessPolicyLiabilityState,
  action: BusinessPolicyLiabilityActions
) => {
  switch (action.type) {

    case FETCH_BUSINESS_POLICY_LIABILITY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.businessPolicyLiabilityListLoading = LoadState.InProgress;
        draftState.businessPolicyLiabilityList = [];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_LIABILITY_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.businessPolicyLiabilityListLoading = LoadState.Loaded;
        draftState.businessPolicyLiabilityList = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_LIABILITY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.businessPolicyLiabilityListLoading = LoadState.Failed;
        draftState.businessPolicyLiabilityList = [];
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_LIABILITY_PROGRESS: {
      return {
        ...state,
        businessPolicyLiability:
          initialBusinessPolicyLiabilityState["businessPolicyLiability"],
        businessPolicyLiabilityLoading: LoadState.InProgress,
      };
    }
    case FETCH_BUSINESS_POLICY_LIABILITY_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.businessPolicyLiability = data;
        draftState.businessPolicyLiabilityLoading = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_LIABILITY_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.businessPolicyLiabilityLoading = LoadState.Failed;
      });
      return newState;
    }
    case CLEAR_BUSINESS_POLICY_LIABILITY_STATE: {
      return initialBusinessPolicyLiabilityState;
    }
    default: {
      return state;
    }
  }
};
