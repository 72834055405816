/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line react/jsx-pascal-case

import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { useDispatchWrapper, useQueryParms } from "../../../../../hooks";
import { fetchCustomerInfoAsync } from "../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";
import { PageLoader } from "../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../constants/enums";
import { StandadCard } from "../../../../../components/Cards";
import { TabsList } from "../../../../../components/Tabs/TabsList";
import {
  AccountCircle,
  AdminPanelSettings,
  Receipt,
  StickyNote2,
} from "@mui/icons-material";
import { TabPanel } from "../../../../../components/Tabs/TabPanel";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useBreadcrumbContext } from "../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { fetchAutomobileVehicleListAsync } from "../../../../../redux/automobileVehicle/automobileVehicleActions";
import { fetchBindersListAsync } from "../../../../../redux/Binder/binderActions";
import { BinderList } from "../BusinessPolicy/Binder/BinderList";
import { HabitationalBinderNonCSIO } from "./HabitationalBinderNonCSIO/HabitationalBinderNonCSIO";
import { HabitationalNonCsioBinderList } from "./HabitationalBinderNonCSIO/HabitationalNonBinderCsioList";
import { HabitationalBinderCsioList } from "./HabitationalBinderCSIO/HabitationalBinderCsioList";
import { HabitationalPaymentAuthorizationList } from "./HabitationalPaymentAuthorization/HabitationalPaymentAuthList";
import { HabitationalCancellationList } from "./HabitationalCancellation/HabitationalCancellationList";
import { fetchHabitationalCustomerInfoAsync } from "../../../../../redux/habitationalPolicy/habitationalBasicDetails/habitationalBasicDetailsActions";
import { HabitationalConsentFormList } from "./HabitationalConsentForm/HabitationalConsentFormList";
import { HabitationalDwellingDeclarationList } from "./HabitationalDwellingDeclaration/HabitationalDwellingDeclarationList";
import { HabitationalDwellingList } from "./HabitationalDwelling/HabitationalDwellingList";

export const HabitationalPolicyFormsRoot = () => {
  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerPolicyId = data[1];
  const { addBreadcrumb } = useBreadcrumbContext();
  const { getParm } = useQueryParms();
  const tabValue = getParm("tab");
  const selectedTab = tabValue ? Number(tabValue) : 0;

  const { customerInfo } = useSelector(
    (storeState: IStoreState) => storeState.habitational.basicDetails
  );

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(
      `/habitational-policy/${customerId}/policy-forms-tab/${customerPolicyId}?tab=${newValue}`
    );
  };

  React.useEffect(() => {
    if (customerPolicyId) {
      //   dispatch(fetchBindersListAsync(customerPolicyId));
      dispatch(fetchHabitationalCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  React.useEffect(() => {
    addBreadcrumb({
      title: "",
      items: [
        {
          to: "/dashboard",
          title: "dashboard",
        },
        {
          to: "/view-customers",
          title: "customers",
        },
        {
          to: "/customer-overview/" + customerId,
          title: "customer-overview",
        },
      ],
    });
  }, []);

  return (
    <PageLoader
      loading={customerInfo.loading === LoadState.InProgress}
      error={customerInfo.error ? { message: customerInfo.error } : null}
    >
      <StandadCard>
        <TabsList
          sx={{ mb: 1 }}
          varaint="scrollable"
          selectedTab={selectedTab}
          tabs={[
            {
              label: "Payment Authorization",
              icon: <AdminPanelSettings />,
            },
            {
              label: "Cancellation",
              icon: <AccountCircle />,
            },
            {
              label: "Privacy Consent",
              icon: <Receipt />,
            },
            {
              label: "Dwelling Declaration",
              icon: <Receipt />,
            },
            {
              label: "Rental Dwelling",
              icon: <Receipt />,
            },
            {
              label: "Binder",
              icon: <AdminPanelSettings />,
            },
            {
              label: "Binder Non-CSIO",
              icon: <AccountCircle />,
            },
          ]}
          onChange={handleChange}
        />
        <TabPanel value={selectedTab} index={0}>
          <HabitationalPaymentAuthorizationList />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <HabitationalCancellationList />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <HabitationalConsentFormList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={3}>
          <HabitationalDwellingDeclarationList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={4}>
          <HabitationalDwellingList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={5}>
          <HabitationalBinderCsioList
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={6}>
          <HabitationalNonCsioBinderList
          />
        </TabPanel>
      </StandadCard>
    </PageLoader>
  );
};
