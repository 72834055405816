import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { api } from "../../../api/api";
import { ERROR_MESSAGES } from "../../../constants/enums";
import { IHabitationalAdditional } from "./habitationalAdditional.types";

export const FETCH_HABITATIONAL_ADDITIONAL_LIST_PROGRESS =
  "FETCH_HABITATIONAL_ADDITIONAL_LIST_PROGRESS";
export const FETCH_HABITATIONAL_ADDITIONAL_LIST_SUCCESS =
  "FETCH_HABITATIONAL_ADDITIONAL_LIST_SUCCESS";
export const FETCH_HABITATIONAL_ADDITIONAL_LIST_FAILED =
  "FETCH_HABITATIONAL_ADDITIONAL_LIST_FAILED";
export const fetchHabitationalAdditionalListProgress = () =>
  action(FETCH_HABITATIONAL_ADDITIONAL_LIST_PROGRESS);

export const fetchHabitationalAdditionalListSuccess = (
  data: IHabitationalAdditional[]
) => action(FETCH_HABITATIONAL_ADDITIONAL_LIST_SUCCESS, { data });
export const fetchHabitationalAdditionalListFailed = () =>
  action(FETCH_HABITATIONAL_ADDITIONAL_LIST_FAILED);

export const fetchHabitationalAdditionalListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalAdditionalListProgress());
      const res = await api.get(
        `/policy/get-habitational-interested-party?customer_policy_id=${customerPolicyId}`
      );
      const data: IHabitationalAdditional[] = res.data.data;
      dispatch(fetchHabitationalAdditionalListSuccess(data));
    } catch (err: any) {
      dispatch(fetchHabitationalAdditionalListProgress());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addHabitationalAdditionalAsync =
  (
    additional: IHabitationalAdditional,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        `/policy/create-habitational-interested-party`,
        {
          ...additional,
        }
      );
      dispatch(
        showMessage({
          type: "success",
          message: "Additional Interest saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_HABITATIONAL_ADDITIONAL_PROGRESS =
  "FETCH_HABITATIONAL_ADDITIONAL_PROGRESS";
export const FETCH_HABITATIONAL_ADDITIONAL_SUCCESS =
  "FETCH_HABITATIONAL_ADDITIONAL_SUCCESS";
export const FETCH_HABITATIONAL_ADDITIONAL_FAILED =
  "FETCH_HABITATIONAL_ADDITIONAL_FAILED";

export const fetchHabitationalAdditionalProgress = () =>
  action(FETCH_HABITATIONAL_ADDITIONAL_PROGRESS);
export const fetchHabitationalAdditionalSuccess = (
  data: IHabitationalAdditional
) => action(FETCH_HABITATIONAL_ADDITIONAL_SUCCESS, { data });
export const fetchHabitationalAdditionalFailed = (errorMessage: string) =>
  action(FETCH_HABITATIONAL_ADDITIONAL_FAILED, { errorMessage });

export const fetchHabitationalAdditionalAsync =
  (
    customerPolicyId: number,
    additionalNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalAdditionalProgress());
      const res = await api.get(
        `/policy/get-habitational-interested-party?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${additionalNumber}`
      );
      const data: IHabitationalAdditional[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchHabitationalAdditionalSuccess(data[0]));
      } else {
        dispatch(
          fetchHabitationalAdditionalFailed(
            "Oops! We couldn't find any records associated with your additional intrest number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchHabitationalAdditionalFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_HABITATIONAL_ADDITIONAL_STATE =
  "CLEAR_HABITATIONAL_ADDITIONAL_STATE";
export const CLEAR_HABITATIONAL_ADDITIONAL =
  "CLEAR_HABITATIONAL_ADDITIONAL";

export const clearHabitationalAdditionalState = () =>
  action(CLEAR_HABITATIONAL_ADDITIONAL_STATE);

export const clearHabitationalAdditional = () =>
  action(CLEAR_HABITATIONAL_ADDITIONAL);
