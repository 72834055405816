import { LoadState } from "../../constants/enums";

export interface IAllBinderState {
    list: IAllBinders[];
    totalRecords: number;
    loading: LoadState;
  }

  export interface IAllBinders {
    customer_policy_id: number;
    policy_id: number;
    customer_id: number;
    status: string;
    insert_ts: string;
    customer_name: string;
    customer_code: string;
    form_of_business: string | null;
    language: string;
    line_of_business: string;
    sub_type: string | null;
    effective_date: string | null;
    expiry_date: string;
    transaction_type: string;
    address: string | null;
    policy_status: string;
    date_cancelled: string | null;
    cancellation_reason: string | null;
    cancellation_type: string | null;
    program: string;
    type_of_policy: string;
    quote_number: string;
    policy_number: string;
    effective_time: string;
    send_invoices_renewal_and_receivacble_letter_via_emails: string | null;
    send_statements_via_email: string | null;
    send_eDocs_via_email: string | null;
    prior_line_of_business: string;
    policy_expiry_date: string;
    current_insurer: string | null;
    expiring_premium: string | null;
    current_status: string | null;
    non_payment_code: string | null;
    non_payment_date: string;
    insurer: string | null;
    amount_owing: string | null;
    policy_admin_insurer: string | null;
    broker_code: string | null;
    comission_code: string | null;
    policy_fee: string | null;
    house_percentage: string | null;
    branch_code: string | null;
    producer_one: string | null;
    producer_two: string | null;
    producer_two_code: string | null;
    producer_three: string | null;
    producer_three_code: string | null;
    marketer_code: string | null;
    producer_1: string | null;
    producer_1_percentage: string | null;
    producer_2: string | null;
    producer_2_percentage: string | null;
    producer_3: string | null;
    producer_3_percentage: string | null;
    marketer: string | null;
    csr: string | null;
    billing_account_number: string | null;
    claim_number: string;
    date_of_loss: string;
    location: string;
    amount_paid_dollar: string;
    loss_details: string | null;
    ours: string | null;
    policy_premium: string;
    agency_bill_or_direct_bill: string | null;
    payment_plan: string | null;
    premium_finance_contract_number: string | null;
    financed_by: string | null;
    default_tax_region: string | null;
    tax_2: string | null;
    tax_1: string;
    tax_exempt: string | null;
    reason: string | null;
    policy_type: string | null;
    policy_sub_type: string | null;
    producer_one_code: string | null;
    create_ts: string | null;
    csr_code: string | null;
    advisor_one: string | null;
  }


  export const defaultAllBindersState: IAllBinderState = {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
}