import { IFileUpload } from "../../../components/FileUpload/FileUpload.type";
import { LoadState } from "../../../constants/enums";


export interface ICommAutoPolicyMarketingBinderPdf {
  customer_policy_id: number;
  isMarketSubmissionPreview: boolean;
  pdfOrPreviewFor: string;
  isBinderPreview: boolean;
  isBinderPdf: boolean;
  generalInformation: boolean;
  driver: boolean;
  priorPolicy: boolean;
  claims: boolean;
  convictions: boolean;
  vehicle: boolean;
  coverage: boolean;
  garagePlat: boolean;
  customer_id?: number;
}
export interface ICommAutomobileMarketingHistoryState {
  marketingHistoryList: ICommAutomobileMarketingHistory[];
  marketingHistoryListLoading: LoadState;
  marketingHistory: ICommAutomobileMarketingHistory;
  marketingHistoryLoading: LoadState;
  error: string | null;
  binderHistory: {
    list: ICommAutoBinder[];
    loading: LoadState;
  };

}

export interface ICommAutoBinder {
  attachment: string;
  created_by_id: string;
  customer_policy_id: number;
  status: string;
}

export interface ICommAutomobileMarketingHistory {
  customer_policy_id: number;
  quote_number: number | null;
  serial_number: string | null;
  customer_name: string | null;
  quote_status: string | null;
  premium: string | null;
  submission_date: string | null;
  effective_date: string | null;
  date_received: string | null;
  insurer: string | null;
  underwriter: string | null;
  attachment: IFileUpload[];
  notes: string | null;
  status: string;
}

export const initialCommAutomobileMarketingHistory: ICommAutomobileMarketingHistory = {
  customer_policy_id: 0,
  quote_number: null,
  serial_number: null,
  customer_name: null,
  quote_status: null,
  premium: null,
  submission_date: null,
  effective_date: null,
  date_received: null,
  insurer: null,
  underwriter: null,
  attachment: [],
  notes: null,
  status: "ACTIVE",
};

export const initialCommAutomobileMarketingHistoryState: ICommAutomobileMarketingHistoryState =
  {
    marketingHistoryList: [],
    marketingHistoryListLoading: LoadState.NotLoaded,
    marketingHistory: initialCommAutomobileMarketingHistory,
    marketingHistoryLoading: LoadState.NotLoaded,
    error: null,
    binderHistory: {
      list: [],
      loading: LoadState.NotLoaded
    }
  };
