import produce from "immer";

import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialAdditionalInterestState } from "./additionalInterest.types";
import { ADDITIONAL_INTEREST_ERROR, ADD_ADDITIONAL_INTEREST, ADD_ADDITIONAL_INTEREST_PROGRESS, CLEAR_ADDITIONAL_INTEREST_STATE, FETCH_ADDITIONAL_INTEREST_PROGRESS } from "../constants";
import { AdditionalInterestActions } from ".";
import { FETCH_ADDITIONAL_INTEREST_LIST_FAILED, FETCH_ADDITIONAL_INTEREST_LIST_PROGRESS, FETCH_ADDITIONAL_INTEREST_LIST_SUCCESS } from "./additionalInterestActions";

export const AdditionalInterestReducer = (
  state: IStoreState["borkerage"]["additionalInterest"] = initialAdditionalInterestState,
  action: AdditionalInterestActions
) => {
  switch (action.type) {
      case ADD_ADDITIONAL_INTEREST_PROGRESS: {
        const newState = produce(state, (draftState) => {
          draftState.additionalIntrest.saveLoading = true;
        });
        return newState;
      }
      case ADD_ADDITIONAL_INTEREST: {
        const { data } = action.payload;
        const newState = produce(state, (draftState) => {
          draftState.additionalIntrest.loading = LoadState.Loaded;
          draftState.additionalIntrest.saveLoading = false;
          draftState.additionalIntrest.data = data;
        });
        return newState;
      }
  
      case FETCH_ADDITIONAL_INTEREST_PROGRESS: {
        const newState = produce(state, (draftState) => {
          draftState.additionalIntrest.loading = LoadState.InProgress;
        });
        return newState;
      }
  
      case ADDITIONAL_INTEREST_ERROR: {
        const newState = produce(state, (draftState) => {
          draftState.additionalIntrest.error = action.payload.message;
          draftState.additionalIntrest.saveLoading = false;
          draftState.additionalIntrest.loading = LoadState.Failed;
        });
        return newState;
      }
  
      case CLEAR_ADDITIONAL_INTEREST_STATE: {
        return initialAdditionalInterestState;
      }
  
      case FETCH_ADDITIONAL_INTEREST_LIST_PROGRESS: {
        const newState = produce(state, (draftState) => {
          draftState.additionalIntrest.listLoading = LoadState.InProgress;
          draftState.additionalIntrest.list = [];
          // draftState.totalRecords = 0;
        });
        return newState;
      }
      case FETCH_ADDITIONAL_INTEREST_LIST_SUCCESS: {
        const { data } = action.payload;
        const newState = produce(state, (draftState) => {
          draftState.additionalIntrest.listLoading = LoadState.Loaded;
          draftState.additionalIntrest.list = data;
          // draftState.totalRecords = totalRecords;
        });
        return newState;
      }
      case FETCH_ADDITIONAL_INTEREST_LIST_FAILED: {
        const newState = produce(state, (draftState) => {
          draftState.additionalIntrest.listLoading = LoadState.InProgress;
          draftState.additionalIntrest.list = [];
          // draftState.totalRecords = 0;
        });
        return newState;
      }
    default: {
      return state;
    }
  }
};
