import produce from "immer";
import { IStoreState } from "../initialStoreState";

import { LoadState } from "../../constants/enums";
import { initialCSIOEDocsState } from "./csioEdocs.types";
import { CsioEdocsAction } from ".";
import {
  CLEAR_CSIO_EDOCS_STATE,
  FETCH_CSIO_EDOCS_FAILED,
  FETCH_CSIO_EDOCS_LIST_FAILED,
  FETCH_CSIO_EDOCS_LIST_PROGRESS,
  FETCH_CSIO_EDOCS_LIST_SUCCESS,
  FETCH_CSIO_EDOCS_PROGRESS,
  FETCH_CSIO_EDOCS_SUCCESS,
  FETCH_MANAGE_CSIO_EDOCS_FAILED,
  FETCH_MANAGE_CSIO_EDOCS_PROGRESS,
  FETCH_MANAGE_CSIO_EDOCS_SUCCESSS,
} from "./csioEdocsActions";

export const csioEdocsReducer = (
  state: IStoreState["csioEdocs"] = initialCSIOEDocsState,
  action: CsioEdocsAction
) => {
  switch (action.type) {
    case FETCH_CSIO_EDOCS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }
    case FETCH_CSIO_EDOCS_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_CSIO_EDOCS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.list = [];
      });
      return newState;
    }

    case FETCH_CSIO_EDOCS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.dataLoading = LoadState.InProgress;
        draftState.data = initialCSIOEDocsState["data"];
        draftState.error = null;
      });
      return newState;
    }
    case FETCH_CSIO_EDOCS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dataLoading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_CSIO_EDOCS_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dataLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case FETCH_MANAGE_CSIO_EDOCS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.error = null;
        draftState.data = initialCSIOEDocsState["data"];
      });
      return newState;
    }
    case FETCH_MANAGE_CSIO_EDOCS_SUCCESSS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_MANAGE_CSIO_EDOCS_FAILED: {
      const { error } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = error || null;
        draftState.data = initialCSIOEDocsState["data"];
      });
      return newState;
    }

    case CLEAR_CSIO_EDOCS_STATE: {
      return initialCSIOEDocsState;
    }

    default: {
      return state;
    }
  }
};
