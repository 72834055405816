import produce from "immer";
import { BusinesPolicyEquipmentActions } from ".";
import { IStoreState } from "../../initialStoreState";
import { defaultBusinessPolicyEquipmentState } from "./businessPolicyEquipment.types";
import {
  ADD_BUSINESS_POLICY_EQUIPMENT,
  BUSINESS_POLICY_EQUIPMENT_NOT_FOUND,
  CLEAR_BUSINES_POLICY_EQUIPMENT,
  CLEAR_BUSINES_POLICY_EQUIPMENT_STATE,
  FETCH_BUSINESS_POLICY_EQUIPMENT_FAILED,
  FETCH_BUSINESS_POLICY_EQUIPMENT_LIST_FAILED,
  FETCH_BUSINESS_POLICY_EQUIPMENT_LIST_PROGRESS,
  FETCH_BUSINESS_POLICY_EQUIPMENT_LIST_SUCCESS,
  FETCH_BUSINESS_POLICY_EQUIPMENT_PROGRESS,
  FETCH_BUSINESS_POLICY_EQUIPMENT_SUCCESS,
} from "./businessPolicyEquipmentActions";
import { LoadState } from "../../../constants/enums";

export const businessPolicyEquipmentReducer = (
  state: IStoreState["business"]["equipment"] = defaultBusinessPolicyEquipmentState,
  action: BusinesPolicyEquipmentActions
) => {
  switch (action.type) {
    case FETCH_BUSINESS_POLICY_EQUIPMENT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_EQUIPMENT_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_EQUIPMENT_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.data = [];
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_EQUIPMENT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.equipmentLoading = LoadState.InProgress;
        draftState.equipment = defaultBusinessPolicyEquipmentState["equipment"];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_EQUIPMENT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.equipmentLoading = LoadState.Loaded;
        draftState.equipment = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_EQUIPMENT_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.equipmentLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_BUSINES_POLICY_EQUIPMENT: {
      const newState = produce(state, (draftState) => {
        draftState.equipment = defaultBusinessPolicyEquipmentState["equipment"];
      });
      return newState;
    }
    case CLEAR_BUSINES_POLICY_EQUIPMENT_STATE: {
      return defaultBusinessPolicyEquipmentState;
    }
    default: {
      return state;
    }
  }
};
