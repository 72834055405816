import produce from "immer";
import { TaxInfoActions } from ".";
import { IStoreState } from "../../initialStoreState";
import { initialAccountingTaxInfoState } from "./TaxInfo.types";
import {
  FETCH_ACCOUNTING_TAX_INFO_FAILED,
  FETCH_ACCOUNTING_TAX_INFO_PROGRESS,
  FETCH_ACCOUNTING_TAX_INFO_SUCCESS,
} from "./TaxInfoActions";
import { LoadState } from "../../../constants/enums";

export const AccountingTaxInfoReducer = (
  state: IStoreState["accounting"]["taxInfo"] = initialAccountingTaxInfoState,
  action: TaxInfoActions
) => {
  switch (action.type) {
    case FETCH_ACCOUNTING_TAX_INFO_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_ACCOUNTING_TAX_INFO_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_ACCOUNTING_TAX_INFO_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    default:
      return state;
  }
};
