import { useNavigate, useOutletContext } from "react-router-dom";
import React from "react";
import { PolicyCancellationList } from "../../Cancellation/PolicyCancellationList";

export const HabitationalCancellationList: React.FC = () => {
  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerPolicyId = data[1];

  const navigate = useNavigate();

  const onSaveEditClick = (taskCode: string) => {
    navigate(
      `/habitational-policy/${customerId}/policy-forms-tab/${customerPolicyId}/cancellation/${taskCode}`
    );
  };

  return (
    <>
      <PolicyCancellationList
        onSaveEditClick={onSaveEditClick}
        customerId={customerId}
        customerPolicyId={customerPolicyId}
      />
    </>
  );
};
