import moment from "moment";
import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface ILifeInsurancePolicyFamilyTreeState {
  data: ILifeInsurancePolicyFamilyTree[];
  loading: LoadState;
  familyData: ILifeInsurancePolicyFamilyTree;
  familyLoading: LoadState;
}

export interface ILifeInsurancePolicyFamilyTree {
  customer_policy_id: number | null;
  serial_number: number | null;
  name: string | null;
  email: string | null;
  date_of_birth: string;
  marital_status: string | null;
  profession: string | null;
  annual_income: string | null;
  existing_coverage_list: {
    key: string;
    SrNo: number;
    coverage_type: string | null;
    policy_status: string | null;
    insurer: string | null;
    effective_date: string;
    expiry_date: string;
    policy_limit: string | null;
  }[];
  rider_list: {
    key: string;
    SrNo: number;
    rider: string | null;
    limit: string | null;
  }[];
  relationship_to_owner: string | null;
  notes: string | null;
  assigned_to: string | null;
  status: string | null;
}

export const defaultLifeInsurancePolicyFamilyTree: ILifeInsurancePolicyFamilyTreeState =
  {
    data: [],
    loading: LoadState.NotLoaded,
    familyData: {
      customer_policy_id: null,
      serial_number: null,
      name: null,
      email: null,
      date_of_birth: moment().format("YYYY-MM-DD"),
      marital_status: null,
      profession: null,
      annual_income: null,
      existing_coverage_list: [
        {
          key: getUniqueId(),
          SrNo: 1,
          coverage_type: null,
          policy_status: null,
          insurer: null,
          effective_date: moment().format("YYYY-MM-DD"),
          expiry_date: moment().format("YYYY-MM-DD"),
          policy_limit: null,
        },
        {
          key: getUniqueId(),
          SrNo: 2,
          coverage_type: null,
          policy_status: null,
          insurer: null,
          effective_date: moment().format("YYYY-MM-DD"),
          expiry_date: moment().format("YYYY-MM-DD"),
          policy_limit: null,
        },
        {
          key: getUniqueId(),
          SrNo: 3,
          coverage_type: null,
          policy_status: null,
          insurer: null,
          effective_date: moment().format("YYYY-MM-DD"),
          expiry_date: moment().format("YYYY-MM-DD"),
          policy_limit: null,
        },
        {
          key: getUniqueId(),
          SrNo: 4,
          coverage_type: null,
          policy_status: null,
          insurer: null,
          effective_date: moment().format("YYYY-MM-DD"),
          expiry_date: moment().format("YYYY-MM-DD"),
          policy_limit: null,
        },
        {
          key: getUniqueId(),
          SrNo: 5,
          coverage_type: null,
          policy_status: null,
          insurer: null,
          effective_date: moment().format("YYYY-MM-DD"),
          expiry_date: moment().format("YYYY-MM-DD"),
          policy_limit: null,
        },
      ],
      rider_list: [
        {
          key: getUniqueId(),
          SrNo: 1,
          rider: null,
          limit: null,
        },
        {
          key: getUniqueId(),
          SrNo: 2,
          rider: null,
          limit: null,
        },
        {
          key: getUniqueId(),
          SrNo: 3,
          rider: null,
          limit: null,
        },
        {
          key: getUniqueId(),
          SrNo: 4,
          rider: null,
          limit: null,
        },
        {
          key: getUniqueId(),
          SrNo: 5,
          rider: null,
          limit: null,
        },
      ],
      relationship_to_owner: null,
      notes: null,
      assigned_to: null,
      status: "ACTIVE",
    },
    familyLoading: LoadState.NotLoaded,
  };
