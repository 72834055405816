import moment, { Moment } from "moment";
import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";
import { get } from "lodash";

export interface IBusinessConsentFormState {
  list: IBusinessConsentForm[];
  loading: LoadState;
  consent: IBusinessConsentForm;
  consentLoading: LoadState;
  error: string | null;
}

export interface IBusinessConsentForm {
  customer_policy_id: number;
  customer_id: number | null;
  incremental_number: number | null;
  customer_name: string | null;
  email_address: string | null;
  text_field: string | null;
  status: string;
  created_by_id: number | null;
  modified_by_id: number | null;
  insert_ts: string | null;
  create_ts: string | null;
}

export const initialBusinessConsentFormState: IBusinessConsentFormState = {
  list: [],
  loading: LoadState.NotLoaded,
  consent: {
    customer_policy_id: 0,
    incremental_number: null,
    customer_id: 0,
    customer_name: null,
    email_address: null,
    text_field: null,
    modified_by_id: null,
    insert_ts: null,
    create_ts: null,
    status: "ACTIVE",
    created_by_id: 0,
  },
  consentLoading: LoadState.NotLoaded,
  error: null,
};
