import {  BusinessOutlined, EditOutlined, FormatColorTextOutlined } from "@mui/icons-material";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import Draggable, { DraggableEvent, DraggableData } from "react-draggable";
import { IG4SignEditior } from "../../../../redux/g4SignDocuments/g4SignDocuments.types";
import { DOCU_SIGN_FIELD_TYPES } from "../../enums";
import { OptionsRightPanel } from "../../OptionsRightPanel";
import { ICompanyFieldProps } from "./CompanyField.types";

export const CompanyField: React.FC<ICompanyFieldProps> = (props) => {
  const {
    placeholder = {
      customer_text: "",
      document_code: "",
      field_code: "",
      field_type: DOCU_SIGN_FIELD_TYPES.COMPANY,
      page_no: 0,
      position_x: 0,
      position_y: 0,
      recipient_code: null,
    },
    dragDisabled,
    onPlaceholderUpdated,
  } = props;
  const theme = useTheme();
  const [open, setOpen] = React.useState<IG4SignEditior | null>(null);

  const handleDragStop = (e: DraggableEvent, data: DraggableData) => {
    if (onPlaceholderUpdated) {
      const updatedPlaceholder = { ...placeholder } as IG4SignEditior;
      updatedPlaceholder.position_x = data.x;
      updatedPlaceholder.position_y = data.y;
      onPlaceholderUpdated(updatedPlaceholder);
    }
  };

  const handleSave = (updatedData: IG4SignEditior) => {
    if (onPlaceholderUpdated) {
      onPlaceholderUpdated(updatedData);
    }
    setOpen(null);
  };

  const handleOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    setOpen(placeholder as IG4SignEditior)
  }

  const handleDelete = () => {
   if(props.onPlaceholderDelete){
    props.onPlaceholderDelete(placeholder as IG4SignEditior);
   }
   setOpen(null);
  }

  return (
    <>
      <Draggable
        bounds="parent"
        position={{
          x: placeholder.position_x,
          y: placeholder.position_y,
        }}
        defaultPosition={{ x: 0, y: 0 }}
        disabled={dragDisabled}
        // cancel="div"
        onMouseDown={(e: any) => {
          e.stopPropagation();
        }}
        onStop={handleDragStop}
      >
        <Box
          position={dragDisabled ? "relative" : "absolute"}
          top={0}
          zIndex={2}
          sx={{
            "&:hover": {
              "& .icon": {
                display: "flex",
              },
            },
          }}
        >
          <Box
            position={"relative"}
            sx={{ minWidth: "100px", zIndex: 1500000000 }}
          border={`1px dashed `+theme.palette.grey[500]}
          p={1}
          >
            <Stack
              direction={"row"}
              spacing={2}
              alignItems={"center"}
              sx={{ cursor: "pointer" }}
            >
              <Box
                sx={{
                  backgroundColor: theme.palette.warning.main,

                  padding: "2px 8px",
                }}
              >
                <BusinessOutlined fontSize="large" />
              </Box>
              <Typography variant="h5" fontWeight={500}>
                Company
                {placeholder.customer_text
                  ? ` (${placeholder.customer_text})`
                  : ""}
              </Typography>
            </Stack>
            {!dragDisabled &&
            <Box
            className="icon"
            position={"absolute"}
            top={-2}
            right={-5}
            padding={0.5}
            sx={{
              backgroundColor: "#fff",
              boxShadow: theme.shadows[1],
              display: "none",
              cursor: "pointer",
            }}
            borderRadius={2}
            display={"flex"}
            alignItems={"center"}
            onClick={handleOpen}
          >
            <EditOutlined fontSize="small" />
          </Box>
            }
          </Box>
        </Box>
      </Draggable>
      {open && (
        <OptionsRightPanel
          open={true}
          currentData={open}
          onClose={() => setOpen(null)}
          onSave={handleSave}
          onDelete={handleDelete}
        />
      )}
    </>
  );
};
