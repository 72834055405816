import { LoadState } from "../../../constants/enums";

export interface IBusinessPolicySubscriptionState {
  data: IBusinessPolicySubscription[];
  loading: LoadState;
  subscription: IBusinessPolicySubscription;
  subscriptionLoading: LoadState;
  error: string | string | null;
}

export interface IBusinessPolicySubscription {
  incremental_number: number | null;
  customer_policy_id: number | null;
  customer_id: number | null;
  effective_date: string | null;
  expiry_date: string | null;
  insurer_or_subscriber: string | null;
  schedule_or_line_of_coverage: string | null;
  subscription_percentage: string | null;
  premium_amount: string | null;
  note: string | null;
  status: "ACTIVE" | "PENDING";
}

export interface ISubscriptionRow {
  customer_policy_id: string;
  subscription_percentage: string | null;
  premium_amount: string | null;
  expiry_date: string | null;
}

export const defaultBusinessPolicySubscriptionState: IBusinessPolicySubscriptionState =
  {
    data: [],
    loading: LoadState.NotLoaded,
    subscription: {
      incremental_number: null,
      customer_policy_id: null,
      customer_id: null,
      effective_date: null,
      expiry_date: null,
      insurer_or_subscriber: null,
      schedule_or_line_of_coverage: null,
      subscription_percentage: null,
      premium_amount: null,
      note: null,
      status: "ACTIVE",
    },
    subscriptionLoading: LoadState.NotLoaded,
    error: null,
  };
