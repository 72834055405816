/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { Box, Button, Grid, SelectChangeEvent } from "@mui/material";
import { useFormik } from "formik";

import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { IStoreState } from "../../../redux/initialStoreState";
import {
  useBusinessTrackerCustomerPolicies,
  useDispatchWrapper,
} from "../../../hooks";
import {
  clearNote,
  fetchNoteAsync,
  upsertNoteAysnc,
} from "../../../redux/notes/notesActions";
import { PageLoader } from "../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../constants/enums";
import { StandadCard } from "../../../components/Cards";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../components/formsComponents";
import { UsersAutoSearchByRole } from "../../../components/AutoCompleteSearches/UsersAutoSearchByRole";
import {
  CustomDatePicker,
  CustomTimePicker,
} from "../../../components/formsComponents/CustomDatePicker";
import { FileUpload } from "../../../components/FileUpload/FileUpload";
import { RoleBasedCustomButton } from "../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { CustomerAutoSearch } from "../../../components/AutoCompleteSearches/CustomerAutoSearch";
import { ICustomerFullDetails } from "../../../redux/customer/customer.types";
import { useCurrentUserId } from "../../../hooks/useAssignedTo";
import { formatDate } from "../../../helpers";
import {
  STANDARD_APP_DATE_FORMAT,
  STANDARD_APP_DATE_TIME_FORMAT,
} from "../../../constants/constants";

export const ManageTaskNote: React.FC = () => {
  const { noteId, customerPolicyId } = useParams() as {
    noteId?: string;
    customerPolicyId?: number;
  };

  const { note, noteLoading, error } = useSelector(
    (storeState: IStoreState) => storeState.policyNotes
  );

  // const [file, setFile] = React.useState<File | null>(null);
  const { policies, clearPolicies, fetchPolicies } =
    useBusinessTrackerCustomerPolicies();

  const [saveLoading, setSaveLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();
  const currentUser = useCurrentUserId();

  const { values, handleChange, handleSubmit, setValues, setFieldValue } =
    useFormik({
      initialValues: note,
      validate: (values) => {},
      onSubmit: (values) => {
        setSaveLoading(true);
        dispatch(
          upsertNoteAysnc(
            {
              ...values,
            },
            note,
            noteId ? true : false,
            (isSuccess) => {
              if (isSuccess) {
                navigate("/notes-list");
              }
              setSaveLoading(false);
            }
          )
        );
      },
    });

  const handleCustomer = (customer: ICustomerFullDetails) => {
    clearPolicies();

    setValues({
      ...values,
      customer: customer.customer_id as string,
      policy_number: null,
    });
  };

  const handleStatusChange = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as string;
    if (value === "Completed") {
      setValues({
        ...values,
        status: value,
        date_completed: moment().format(STANDARD_APP_DATE_FORMAT),
        time_completed: moment().format(STANDARD_APP_DATE_TIME_FORMAT),
      });
    } else {
      setValues({
        ...values,
        status: value,
      });
    }
  };

  React.useEffect(() => {
    (async () => {
      if (values.customer) {
        fetchPolicies(values.customer as any);
      }
    })();
  }, [values.customer]);

  React.useEffect(() => {
    if (noteId && customerPolicyId) {
      dispatch(fetchNoteAsync(customerPolicyId, noteId as any));
    }
  }, [noteId, customerPolicyId]);

  React.useEffect(() => {
    setValues({
      ...note,
      assigned_to: note.assigned_to || (currentUser as any),
    });
  }, [note]);

  React.useEffect(() => {
    return () => {
      dispatch(clearNote());
    };
  }, []);

  return (
    <PageLoader
      loading={noteLoading === LoadState.InProgress}
      error={error ? { message: error } : null}
    >
      <StandadCard sx={{ mt: 2 }} heading="Abeyances/Notes">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={1.4}>
              <CustomFormLabel>#</CustomFormLabel>
              <CustomTextField
                name="notes_title"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.incremental_number}
                disabled
                //onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>Title</CustomFormLabel>
              <CustomTextField
                name="notes_title"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.notes_title}
                // disabled
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2}>
              <CustomerAutoSearch
                label="Customer"
                value={values.customer}
                onSelect={handleCustomer}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>Policy #</CustomFormLabel>
              <ControlledCustomSelect
                fullWidth
                name="customer_policy_id"
                value={Number(values.policy_number)}
                options={policies.map((policy) => {
                  return {
                    label: policy.policy_number,
                    value: policy.customer_policy_id,
                  };
                })}
                displayEmpty
                placeholder="Select one"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>Status</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="status"
                fullWidth
                displayEmpty
                value={values.status}
                onChange={handleStatusChange}
                placeholder="Select One"
                options={[
                  { label: "Open", value: "ACTIVE" },
                  { label: "Closed", value: "INACTIVE" },
                ]}
              ></ControlledCustomSelect>
            </Grid>

            <Grid item xs={12} lg={2.6}>
              {/* <CustomFormLabel>Assigned To</CustomFormLabel> */}
              <UsersAutoSearchByRole
                label="Assigned To"
                //roleId={USER_ROLES.CSR}
                value={values.assigned_to}
                onSelect={(newValue) => {
                  setFieldValue("assigned_to", newValue.user_fact_id);
                }}
              />
            </Grid>

            <Grid item xs={12} md={2.4}>
              <CustomFormLabel>Date Created</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                disabled
                value={values.date_created || ""}
                onChange={(newValue) => {
                  setFieldValue("date_created", newValue);
                }}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <CustomFormLabel>Due Date</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.due_date || ""}
                onChange={(newValue) => {
                  setFieldValue("due_date", newValue);
                }}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <CustomFormLabel>Due Time</CustomFormLabel>
              <CustomTimePicker
                fullWidth
                value={values.due_time as any}
                onChange={(newValue) => {
                  setFieldValue("due_time", newValue.toString());
                }}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <CustomFormLabel>Date Completed</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.date_completed || ""}
                disabled
                onChange={(newValue) => {
                  setFieldValue("date_completed", newValue);
                }}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <CustomFormLabel>Time Completed</CustomFormLabel>
              <CustomTimePicker
                fullWidth
                disabled
                value={values.time_completed}
                onChange={(newValue) => {
                  setFieldValue("time_completed", newValue);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <CustomFormLabel>Description</CustomFormLabel>
              <CustomTextField
                name="description"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                multiline
                rows={4}
                value={values.description}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>File</CustomFormLabel>
              <FileUpload
                multiple
                value={values.file_upload || []}
                onMultiChange={(data) => setFieldValue("file_upload", data)}
                onDelete={() => {
                  setFieldValue("file_upload", null);
                }}
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: 4 }}>
            <RoleBasedCustomButton
              sx={{ width: "15%" }}
              disabled={saveLoading}
              variant="contained"
              moduleId={MODULE_IDS.NOTES}
              type="submit"
            >
              Save
            </RoleBasedCustomButton>
          </Box>
        </form>
      </StandadCard>
    </PageLoader>
  );
};
