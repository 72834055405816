/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Button, Link, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { LoadState } from "../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../hooks";
import { IAutomobileOCEP17 } from "../../../../../../redux/automobileCertificates/automobileCertificates.types";
import { downloadAutomobileOcep17ListPDFAsync, fetchAutomobileOcep17ListAsync, previewAutomobileOcep17ListPDFAsync } from "../../../../../../redux/automobileCertificates/automobileCertificatesActions";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { CustomSelect } from "../../../../../../components/formsComponents";
import { renderDropdownValue } from "../../../../../../helpers";
import { CustomChip } from "../../../../../../components/CustomChip";
import { Add, NotificationsActive, ThumbUpAlt } from "@mui/icons-material";

export const OPCF17List: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { list, loading } = useSelector(
    (storeState: IStoreState) => storeState.automobile.certificates.opcf17
  );

  const [selectedOpcf, setselectedOpcf] = React.useState<{
    isAllSelected: boolean;
    certificate: IAutomobileOCEP17[];
  }>({
    isAllSelected: false,
    certificate: [],
  });

  const menuItems =
    selectedOpcf.certificate.length > 0 || selectedOpcf.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const dispatch = useDispatchWrapper();
  const [downloading, setDownloading] = React.useState(false);
  const OPCF_17TableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    selectionMode: "multiple",
    uniqueRowKeyName: "incremental_number",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          ...menuItems,
        ],
      },
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => {
                  navigate(
                    `/automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/opcf-17`
                  );
                }}
              >
                Create
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IAutomobileOCEP17) => {
          return (
            <StandardTableActions
            downloadLoading={downloading}
              onDownloadPreview={() => {
                dispatch(
                  previewAutomobileOcep17ListPDFAsync(
                    customerPolicyId,
                    row.incremental_number as number,
                    () => {}
                  )
                );
              }}
              onDownLoadClick={() => {
                if (row.incremental_number) {
                  setDownloading(true);
                  dispatch(
                    downloadAutomobileOcep17ListPDFAsync(
                      customerPolicyId,
                      row.incremental_number,
                      () => {
                        setDownloading(false);
                      }
                    )
                  );
                }
              }}
              onEditClick={() => {
                navigate(
                  `/automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/opcf-17/${row.incremental_number}`
                );
              }}
              onDuplicateClick={() => {
                navigate(
                  `/automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/opcf-17-duplicate/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileOCEP17) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/opcf-17/${row.incremental_number}`
                );
              }}
            >
              {row.incremental_number}
            </Link>
          );
        },
      },

      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IAutomobileOCEP17) => {
          if (row.status === "ACTIVE") {
            return (
              <CustomChip
                color="success"
                icon={<ThumbUpAlt />}
                content={"Active"}
                size="small"
              />
            );
          } else {
            return (
              <CustomChip
                color="error"
                icon={<NotificationsActive />}
                content={"Cancel"}
                size="small"
              />
            );
          }
        },
      },

      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        renderType: RenderType.CHIP_WARNING,
        exportCellWidth: 30,
      },
      {
        key: "issued_to",
        headerName: "Issued to",
        fieldName: "issued_to",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
      },

      {
        key: "effective_date_of_change",
        headerName: "Effective date of Change",
        fieldName: "effective_date_of_change",
        exportCellWidth: 25,
        renderType: RenderType.DATE,
      },
      {
        key: "policy_status",
        headerName: "Policy Status",
        fieldName: "policy_status",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },

     
    ],
    items: list,
    onSelection: (isAllSelected: boolean, rows: IAutomobileOCEP17[]) => {
      setselectedOpcf({ isAllSelected: isAllSelected, certificate: rows });
    },
  };

  React.useEffect(() => {
    dispatch(fetchAutomobileOcep17ListAsync(customerPolicyId));
  }, [customerPolicyId]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...OPCF_17TableProps} />
      </Box>
    </>
  );
};
