import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialHabitationalCSIOApplicationState } from "./habitationalCsioApplication.types";
import { HabitationalCSIOApplicationActions } from ".";
import { CLEAR_HABITATIONAL_CSIO_APPLICATION_STATE, FETCH_HABITATIONAL_CSIO_APPLICATION_FAILED, FETCH_HABITATIONAL_CSIO_APPLICATION_PROGRESS, FETCH_HABITATIONAL_CSIO_APPLICATION_SUCCESSS, FETCH_HABITATIONAL_CSIO_LIST_FAILED, FETCH_HABITATIONAL_CSIO_LIST_PROGRESS, FETCH_HABITATIONAL_CSIO_LIST_SUCCESS } from "./habitationalCsioApplicationActions";



export const HabitationalCSIOApplicationReducer = (
  state: IStoreState["habitational"]["csioapplication"] = initialHabitationalCSIOApplicationState,
  action: HabitationalCSIOApplicationActions
) => {
  switch (action.type) {
    case FETCH_HABITATIONAL_CSIO_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_CSIO_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = list;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_CSIO_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_CSIO_APPLICATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.error = null;
        draftState.csioData = initialHabitationalCSIOApplicationState["csioData"];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_CSIO_APPLICATION_SUCCESSS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.csioData = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_CSIO_APPLICATION_FAILED: {
      const { error } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = error || null;
        draftState.csioData = initialHabitationalCSIOApplicationState["csioData"];
      });
      return newState;
    }

    case CLEAR_HABITATIONAL_CSIO_APPLICATION_STATE: {
      return initialHabitationalCSIOApplicationState;
    }

    default: {
      return state;
    }
  }
};
