import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { AccountingBillPaymentPayableActions } from ".";
import { initialBillPaymentPayableState } from "./BillPaymentPayable.types";
import {
  CLEAR_ACCOUNTING_BILL_PAYMENT_PAYABLE,
  FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_FAILURE,
  FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_LIST_FAILURE,
  FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_LIST_PROGRESS,
  FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_LIST_SUCCESS,
  FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_PROGRESS,
  FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_SUCCESS,
} from "./BillPaymentPayableActions";

export const AccountingBillPaymentPayableReducer = (
  state: IStoreState["accounting"]["billPaymentPayable"] = initialBillPaymentPayableState,
  action: AccountingBillPaymentPayableActions
) => {
  switch (action.type) {
    case FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Loaded;
        draftState.list = data;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_LIST_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_ACCOUNTING_BILL_PAYMENT_PAYABLE: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.NotLoaded;
        draftState.data = initialBillPaymentPayableState.data;
        draftState.error = null;
      });
      return newState;
    }

    default:
      return state;
  }
};
