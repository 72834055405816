import { LoadState } from "../../constants/enums";

export interface ICSIOEDocsState {
  list: ICSIOEDocs[];
  loading: LoadState;
  totalRecords: number;
  data: ICSIOEDocs;
  dataLoading: LoadState;
  error: string | null;
}

export interface ICSIOEDocs {
  csio_edoc_id: string | null;
  customer_id: number | null;
  customer_name: string | null;
  customer_policy_id: string | null;
  message_guid: string | null;
  policy_number: string | null;
  type: string | null;
  business_purpose_type: string | null;
  insurance_company: string | null;
  contract_term_effective_date: string | null;
  contract_term_expiry_date: string | null;
  line_of_business: string | null;
  attachment: string;
  remarks: string | null;
  status: string;
  customer_email?: string;
}

export const initialCSIOEDocsState: ICSIOEDocsState = {
  list: [],
  loading: LoadState.NotLoaded,
  totalRecords: 0,
  data: {
    csio_edoc_id: null,
    customer_policy_id: null,
    customer_id: null,
    policy_number: null,
    message_guid: null,
    type: null,
    business_purpose_type: null,
    insurance_company: null,
    contract_term_effective_date: null,
    contract_term_expiry_date: null,
    line_of_business: null,
    customer_name: null,
    attachment: "",
    remarks: null,
    status: "ACTIVE",
    customer_email: "",
  },
  dataLoading: LoadState.NotLoaded,
  error: null,
};
