import { action } from "typesafe-actions";
import { IEquipment } from "./equipment.types";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import { makeApiCall } from "../../helpers/postRequest";
import { endPoints } from "../../constants/endPoints";
import { uploadFile } from "../../components/FileUpload/utils";

export const FETCH_EQUIPMENT_LIST_PROGRESS = "FETCH_EQUIPMENT_LIST_PROGRESS";
export const FETCH_EQUIPMENT_LIST_SUCCESS = "FETCH_EQUIPMENT_LIST_SUCCESS";
export const FETCH_EQUIPMENT_LIST_FAILED = "FETCH_EQUIPMENT_LIST_FAILED";

export const fetchEquipmentListProgress = () =>
  action(FETCH_EQUIPMENT_LIST_PROGRESS);
export const fetchEquipmentListSuccess = (
  data: IEquipment[],
  totalRecords: number
) =>
  action(FETCH_EQUIPMENT_LIST_SUCCESS, {
    data,
    totalRecords,
  });
export const fetchEquipmentListFailed = () =>
  action(FETCH_EQUIPMENT_LIST_FAILED);


  export const fetchEquipmentListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[],
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchEquipmentListProgress());
      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }
      let finalUrl = `/insurance/get-assigned-equipments?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;
      if (searchType.length > 0 && searchValue) {
        finalUrl = `/insurance/get-assigned-equipments?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&column=${[
          searchType,
        ]}&value=${searchValue}`;
      }
      const res = await makeApiCall({
        method: "GET",
        url: finalUrl
      });
      const data: IEquipment[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchEquipmentListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchEquipmentListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };



export const FETCH_EQUIPMENT_PROGRESS = "FETCH_EQUIPMENT_PROGRESS";
export const FETCH_EQUIPMENT_SUCCESS = "FETCH_EQUIPMENT_SUCCESS";
export const FETCH_EQUIPMENT_FAILED = "FETCH_EQUIPMENT_FAILED";

export const fetchEquipmentProgress = () => action(FETCH_EQUIPMENT_PROGRESS);
export const fetchEquipmentSuccess = (data: IEquipment) =>
  action(FETCH_EQUIPMENT_SUCCESS, {
    data,
  });
export const fetchEquipmentFailed = (errorMessage: string) =>
  action(FETCH_EQUIPMENT_FAILED, { errorMessage });


  export const fetchEquipmentAsync =
  (equipmentCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchEquipmentProgress());

      const res = await makeApiCall(
       {
        method: "GET",
        url:  `/insurance/get-assigned-equipments?brokerage_equipment_code=${equipmentCode}`
       }
      );
      const data = res.data.data;
      if (data.length > 0) {    
        dispatch(fetchEquipmentSuccess(data[0]));
      } else {
        dispatch(
            fetchEquipmentFailed(
            "Unfortunately, there are no records available at the moment."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchEquipmentFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

  export const upsertEquipmentAsync =
  (
    data: IEquipment,
    initialData: IEquipment,
    isUpdate: boolean,
    file: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        brokerage_equipment_code: data.brokerage_equipment_code,
      };
      const path = await uploadFile(
        file,
        "BROKERAGE_EQUIPMENT",
        data.attachments || "",
        asPayload
      );
      await makeApiCall(
        {
          url: "/insurance/upsert-assigned-equipments",
          method: "POST",
          automation: {
            isUpdate: isUpdate,
            primaryFieldName: "brokerage_equipment_code",
          },
        },initialData,  {
          ...data,
          attachments: path,
        } ,
       
      );
     
      dispatch(
        showMessage({
          type: "success",
          message: "Equipment saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

  

export const CLEAR_EQUIPMENT_STATE = "CLEAR_EQUIPMENT_STATE";
export const clearEquipmentState = () => action(CLEAR_EQUIPMENT_STATE);
