import Avatar from "@mui/material/Avatar";
import React from "react";
import { api } from "../../../api/api";
import { StandadCard } from "../../../components/Cards";
import { PageContainer } from "../../../components/container/PageContainer";
import { DataTable } from "../../../components/Table/DataTable";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../components/Table/interfaces/IDataTableProps";
import {
  CSVExportPlugin,
  RefreshPlugin,
  SearchPlugin,
} from "../../../components/Table/plugins";
import { IBreadcrumbProps } from "../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useNavigate } from "react-router-dom";
import { useDispatchWrapper, useRecordCounts } from "../../../hooks";
import { useDateFilter } from "../../../components/Table/hooks/useDateFilter";
import moment from "moment";
import { CustomDatePicker } from "../../../components/formsComponents/CustomDatePicker";
import { DateFilterDropdown } from "../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import {
  ControlledCustomSelect,
  CustomSelect,
} from "../../../components/formsComponents";
import { CustomLink } from "../../../components/CustomLink/CustomLink";
import {
  capitalizeWords,
  createTabsWithRecordcounts,
  renderDropdownValue,
} from "../../../helpers";
import {
  IInsuranceReport,
  INoPolicyInsuranceReports,
} from "./insuranceReport.types";
import { IconicMultiColumnCard } from "../../../components/iconicMultiColumnCard/IconicMultiColumnCard";
import { StandardTableActions } from "../../../components/StandardTableActions/StandardTableActions";
import { IStoreState } from "../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { IInsuranceReports } from "../../../redux/insuranceReports/insuranceReports.types";
import { LoadState, MODULE_IDS } from "../../../constants/enums";
import {
  fetchInsuranceReportsListAsync,
  fetchNoPolicyInsuranceReportsListAsync,
} from "../../../redux/insuranceReports/insuranceReportsActions";
import {
  Box,
  Button,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../../redux/common/commonActions";
import { LeadOppStatus } from "../../../components/LeadOppStatus/LeadOppStatus";
import { AddOutlined } from "@mui/icons-material";
import { RoleBasedCSVExportPlugin } from "../../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { InsuranceReportDialog } from "./InsuranceReportsDialog/InsuranceReportsDialog";
import { CustomChip } from "../../../components/CustomChip";
import { PageTitleBar } from "../../../components/PageTitleBar/PageTitleBar";
import { ITableTab } from "../../../components/Table/components/TableTabs/TableTabs.types";
import { useTableV2State } from "../../../components/TableV2/hooks/useTableV2State";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../components/TableV2/interfaces/IDataTableV2Props";
import { DataTableV2 } from "../../../components/TableV2/DataTableV2";
import { NoPolicyInsuranceReportTable } from "./NoPolicyInsuranceReportTable";
// import PolicyTypeCard


export const NoPolicyInsuranceReportList: React.FC<{
  onChange?: (newTab: number) => void;
  leadId?: string;
}> = (props) => {
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      title: "insurance-reports",
    },
  ];

  return (
    <PageContainer
      title="View Insurance Report"
      description="this is innerpage"
    >
      <PageTitleBar
        heading="No Policy Insurance Reports"
       
      />
      {props.onChange && (
        <Box marginBottom={2}>
          <Tabs
            value={1}
            variant="fullWidth"
            onChange={(e, value) => props.onChange!(value)}
          >
            <Tab
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography fontWeight={500} sx={{ fontSize: "1.1rem" }}>
                    Comm-Auto & Automobile Insurance Reports
                  </Typography>
                </div>
              }
              value={0}
            />
            <Tab
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography fontWeight={600} sx={{ fontSize: "1rem" }}>
                    No Policy Insurance Reports
                  </Typography>
                </div>
              }
              value={1}
            />
          </Tabs>
        </Box>
      )}
    <NoPolicyInsuranceReportTable/>
    </PageContainer>
  );
};
