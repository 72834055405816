import React from "react";
import { ICreateCodeRightPanelProps } from "./CreateCodeRightPanel.types";
import { useFormik } from "formik";
import { RightPanel } from "../RightPanel";
import { Button, Grid, Stack } from "@mui/material";
import { PublishedWithChanges, Save } from "@mui/icons-material";
import {
  ControlledCustomSelect,
  CustomCheckBoxWithLabel,
  CustomFormLabel,
  CustomSwitch,
  CustomTextField,
} from "../formsComponents";
import { useDispatchWrapper } from "../../hooks";
import { upsertExpressionCodeAsync } from "../../redux/expressionTransformation/expressionTransformationActions";
import { IVariableItem } from "../../redux/expressionTransformation/expressionTransformation.types";
import { CustomAlert } from "../formsComponents/CustomAlert";

export const CreateCodeRightPanel: React.FC<ICreateCodeRightPanelProps> = (
  props
) => {
  const { open,data, onComplete, onClose } = props;
  const [showError, setShowError] = React.useState<any>(null)
  const dispatch = useDispatchWrapper();

  const { values, errors, handleChange, handleSubmit,setFieldValue } =
    useFormik<IVariableItem>({
      initialValues: data,
      validate: (values) => {
        const errors: any = {};
        if (!values.group_code) {
          errors.group_code = "Group code is requried!";
        } else if (values.start_index === null) {
          errors.start_index = "Start index is requried!";
        } else if (values.element_length === null) {
          errors.element_length = "Element length is requried!";
        }
        return errors;
      },
      onSubmit: (values) => {
        setShowError(null);
         dispatch(upsertExpressionCodeAsync(values, (isSuccess,error)=>{
          if(error){
           setShowError(error.response.data.message);
           return;
          }
          onComplete();
         }))
      },
    });

  return (
    <>
      <RightPanel
        open={open}
        heading="Create Code"
        onClose={onClose}
        drawerProps={{ zIndex: 1500 }}
        isWrappedWithForm={true}
        onFormSubmit={handleSubmit}
        actionButtons={() => {
          return (
            <Stack spacing={2} direction={"row"}>
              <Button
                variant="contained"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
              >
                Close
              </Button>

              <Button
                variant="contained"
                type="submit"
                // disabled={loading}
                startIcon={<Save />}
              >
                Save
              </Button>
            </Stack>
          );
        }}
      >
          {showError && (
          <CustomAlert severity="error" onClose={() => setShowError(null)}>
            {showError}
          </CustomAlert>
        )}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CustomFormLabel>Group Code</CustomFormLabel>
            <CustomTextField
              fullWidth
              name="group_code"
              value={values.group_code}
              onChange={handleChange}
              error={errors.group_code ? true : false}
              helperText={errors.group_code}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomFormLabel>Start Index</CustomFormLabel>
            <CustomTextField
              fullWidth
              name="start_index"
              type="number"
              value={values.start_index}
              onChange={handleChange}
              error={errors.start_index ? true : false}
              helperText={errors.start_index}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomFormLabel>Element length</CustomFormLabel>
            <CustomTextField
              fullWidth
              name="element_length"
              type="number"
              value={values.element_length}
              onChange={handleChange}
              error={errors.element_length ? true : false}
              helperText={errors.element_length}
            />
          </Grid>
          <Grid item xs={6}>
            <Stack direction={'row'} spacing={1} justifyContent={'space-between'} >
            <CustomFormLabel>Has Mutiple Values</CustomFormLabel>
            <CustomSwitch checked={values.has_multiple_values===1} onChange={(e,checked)=> setFieldValue("has_multiple_values", checked ? 1 :0)}/>
            
            </Stack>
            {/* <ControlledCustomSelect
              fullWidth
              name="has_multiple_values"
              value={values.has_multiple_values}
              options={[
                { label: "Y", value: "true" },
                { label: "N", value: "false" },
              ]}
              onChange={handleChange}
            /> */}
          </Grid>
          <Grid item xs={12}>
            <CustomFormLabel>Status</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              name="status"
              value={values.status}
              MenuProps={{
                style: {zIndex: 1800}
            }}
              options={[
                { label: "Active", value: "ACTIVE" },
                { label: "Inactive", value: "INACTIVE" },
              ]}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </RightPanel>
    </>
  );
};
