import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../redux/initialStoreState";
import { useDispatchWrapper } from "./useDispatch";
import { fetchAutomobilePremiumAsync } from "../redux/automobileBasicDetails/automobileBasicDetailsActions";

export const useAutomobilePremium = (customerPolicyId: number) => {
  const dispatch = useDispatchWrapper();
  const premium = useSelector(
    (storeState: IStoreState) => storeState.automobile.basicDetails.premium
  );

  React.useEffect(() => {
    dispatch(fetchAutomobilePremiumAsync(customerPolicyId));
  }, [customerPolicyId]);

  return premium;
};