import { action } from "typesafe-actions";
import { IMessage } from "./messages.types";

export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';

export const showMessage= (message: IMessage) => action(SHOW_MESSAGE, {message});
export const hideMessage = () => action(HIDE_MESSAGE);

export const SAVE_LOADER_PROGRESS = "SAVE_LOADER_PROGRESS";
export const SAVE_LOADER_DONE = "SAVE_LOADER_DONE";

export const saveLoaderProgress = () => action(SAVE_LOADER_PROGRESS);
export const saveLoaderCompleted = () => action(SAVE_LOADER_DONE);
