/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Button, Link, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { LoadState } from "../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../hooks";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { ICommAutomobileLiabilityInsurance } from "../../../../../../redux/commAutoPolicy/commAutoCertificates/commAutoCertificates.types";
import { fetchCommAutomobileLiabilityInsuranceListAsync } from "../../../../../../redux/commAutoPolicy/commAutoCertificates/commAutoCertificatesActions";
import { CustomSelect } from "../../../../../../components/formsComponents";
import { renderDropdownValue } from "../../../../../../helpers";
import { Add, NotificationsActive, ThumbUpAlt } from "@mui/icons-material";
import { CustomChip } from "../../../../../../components/CustomChip";

export const CommAutomobileCSIOCertificateList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { list, loading } = useSelector(
    (storeState: IStoreState) =>
      storeState.commAuto.certificates.liabilityInsurance
  );
  const [downloading, setDownloading] = React.useState(false);
  const dispatch = useDispatchWrapper();

  const [selectedCsio, setselectedCsio] = React.useState<{
    isAllSelected: boolean;
    certificate: ICommAutomobileLiabilityInsurance[];
  }>({
    isAllSelected: false,
    certificate: [],
  });

  const menuItems =
    selectedCsio.certificate.length > 0 || selectedCsio.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const csioCertificateTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    selectionMode: "multiple",
    uniqueRowKeyName: "incremental_number",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [...menuItems],
      },
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => {
                  navigate(
                    `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/liability-insurance`
                  );
                }}
              >
                Create
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: ICommAutomobileLiabilityInsurance) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/liability-insurance/${row.incremental_number}`
                );
              }}
            >
              {row.incremental_number}
            </Link>
          );
        },
      },

      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobileLiabilityInsurance) => {
          if (row.status === "ACTIVE") {
            return (
              <CustomChip
                color="success"
                icon={<ThumbUpAlt />}
                content={"Active"}
                size="small"
              />
            );
          } else {
            return (
              <CustomChip
                color="error"
                icon={<NotificationsActive />}
                content={"Cancel"}
                size="small"
              />
            );
          }
        },
      },

      {
        key: "cgl_policy_number",
        headerName: "CGL Policy Number",
        fieldName: "cgl_policy_number",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
      },
      {
        key: "automobile_policy",
        headerName: "Automobile Policy",
        fieldName: "automobile_policy",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
      },
      {
        key: "vehicle_unit_number_or_vin",
        headerName: "Unit Number or VIN",
        fieldName: "vehicle_unit_number_or_vin",
        renderType: RenderType.CHIP_WARNING,
        exportCellWidth: 20,
      },
      {
        key: "certificate_holder_name",
        headerName: "Certificate Holder name",
        fieldName: "certificate_holder_name",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },

      {
        key: "certificate_holder_email",
        headerName: "Certificate Holder Email",
        fieldName: "certificate_holder_email",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "certificate_holder_phone",
        headerName: "Certificate Holder Phone",
        fieldName: "certificate_holder_phone",
        exportCellWidth: 25,
        renderType: RenderType.TEXT_DARK,
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobileLiabilityInsurance) => {
          return (
            <StandardTableActions
              // downloadLoading={downloading}
              //   onDownloadPreview={() => {
              //     dispatch(
              //       previewCommAutomobileLiabilityInsurancePDFAsync(
              //         customerPolicyId,
              //         row.incremental_number as number,
              //         () => {}
              //       )
              //     );
              //   }}
              //   onDownLoadClick={() => {
              //     if (row.incremental_number) {
              //       setDownloading(true);
              //       dispatch(
              //         downloadCommAutomobileLiabilityInsurancePDFAsync(
              //           customerPolicyId,
              //           row.incremental_number,
              //           () => {
              //             setDownloading(false);
              //           }
              //         )
              //       );
              //     }
              //   }}
              onEditClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/liability-insurance/${row.incremental_number}`
                );
              }}
              onDuplicateClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/liability-insurance/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
    ],
    items: list,
    onSelection: (
      isAllSelected: boolean,
      rows: ICommAutomobileLiabilityInsurance[]
    ) => {
      setselectedCsio({ isAllSelected: isAllSelected, certificate: rows });
    },
  };

  React.useEffect(() => {
    dispatch(fetchCommAutomobileLiabilityInsuranceListAsync(customerPolicyId));
  }, [customerPolicyId]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...csioCertificateTableProps} />
      </Box>
    </>
  );
};
