import { action } from "typesafe-actions";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import {
  IAddLessor,
  IChangeLessor,
  IDeleteLessor,
  initialTaskLessorState,
} from "./lessor.types";
import { uploadFile } from "../../../helpers";

export const FETCH_ADD_LESSOR_PROGRESS = "FETCH_ADD_LESSOR_PROGRESS";
export const FETCH_ADD_LESSOR_SUCCESS = "FETCH_ADD_LESSOR_SUCCESS";
export const FETCH_ADD_LESSOR_FAILED = "FETCH_ADD_LESSOR_FAILED";

export const fetchAddLessorProgress = () => action(FETCH_ADD_LESSOR_PROGRESS);
export const fetchAddLessorSuccess = (data: IAddLessor) =>
  action(FETCH_ADD_LESSOR_SUCCESS, { data });
export const fetchAddLessorFailed = () => action(FETCH_ADD_LESSOR_FAILED);

export const fetchAddLessorAysnc =
  (taskId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAddLessorProgress());
      const res = await api.get(
        `/tasks/get-tasks-add-lessor?task_code=${taskId}`
      );
      const data: IAddLessor[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAddLessorSuccess(data[0]));
      } else {
        dispatch(
          fetchAddLessorSuccess(initialTaskLessorState.addLessor["data"])
        );
      }
    } catch (err: any) {
      dispatch(fetchAddLessorFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskAddLessorAysnc =
  (
    task: IAddLessor,
    onCallback: (isSuccess: boolean, taskId?: number) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post("/tasks/create-tasks-add-lessor", {
        ...task,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Task is saved succesfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(
        fetchAddLessorSuccess({ ...task, task_code: res.data.data.task_id })
      );
      onCallback(true, res.data.data.task_id);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_DELETE_LESSOR_PROGRESS = "FETCH_DELETE_LESSOR_PROGRESS";
export const FETCH_DELETE_LESSOR_SUCCESS = "FETCH_DELETE_LESSOR_SUCCESS";
export const FETCH_DELETE_LESSOR_FAILED = "FETCH_DELETE_LESSOR_FAILED";

export const fetchDeleteLessorProgress = () =>
  action(FETCH_DELETE_LESSOR_PROGRESS);
export const fetchDeleteLessorSuccess = (data: IDeleteLessor) =>
  action(FETCH_DELETE_LESSOR_SUCCESS, { data });
export const fetchDeleteLessorFailed = () => action(FETCH_DELETE_LESSOR_FAILED);

export const fetchDeleteLessorAysnc =
  (taskId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchDeleteLessorProgress());
      const res = await api.get(
        `/tasks/get-tasks-delete-lessor?task_code=${taskId}`
      );
      const data: IDeleteLessor[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchDeleteLessorSuccess(data[0]));
      } else {
        dispatch(
          fetchDeleteLessorSuccess(initialTaskLessorState.deleteLessor["data"])
        );
      }
    } catch (err: any) {
      dispatch(fetchDeleteLessorFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskDeleteLessorAysnc =
  (
    task: IDeleteLessor,
    onCallback: (isSuccess: boolean, taskId?: number) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post("/tasks/create-tasks-delete-lessor", {
        ...task,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Task is deleted succesfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(
        fetchDeleteLessorSuccess({ ...task, task_code: res.data.data.task_id })
      );
      onCallback(true, res.data.data.task_id);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_CHANGE_LESSOR_PROGRESS = "FETCH_CHANGE_LESSOR_PROGRESS";
export const FETCH_CHANGE_LESSOR_SUCCESS = "FETCH_CHANGE_LESSOR_SUCCESS";
export const FETCH_CHANGE_LESSOR_FAILED = "FETCH_CHANGE_LESSOR_FAILED";

export const fetchChangeLessorProgress = () =>
  action(FETCH_CHANGE_LESSOR_PROGRESS);
export const fetchChangeLessorSuccess = (data: IChangeLessor) =>
  action(FETCH_CHANGE_LESSOR_SUCCESS, { data });
export const fetchChangeLessorFailed = () => action(FETCH_CHANGE_LESSOR_FAILED);

export const fetchChangeLessorAysnc =
  (taskId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchChangeLessorProgress());
      const res = await api.get(
        `/tasks/get-tasks-change-lessor?task_code=${taskId}`
      );
      const data: IChangeLessor[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchChangeLessorSuccess(data[0]));
      } else {
        dispatch(
          fetchChangeLessorSuccess(initialTaskLessorState.changeLessor["data"])
        );
      }
    } catch (err: any) {
      dispatch(fetchChangeLessorFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskChangeLessorAysnc =
  (
    task: IChangeLessor,
    onCallback: (isSuccess: boolean, taskId?: number) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post("/tasks/create-tasks-change-lessor", {
        ...task,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Task is changed succesfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(
        fetchChangeLessorSuccess({ ...task, task_code: res.data.data.task_id })
      );
      onCallback(true, res.data.data.task_id);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
