import { action } from "typesafe-actions";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IPolicy } from "./Policies.types";

export const FETCH_ALL_POLICIES_LIST_PROGRESS =
  "FETCH_ALL_POLICIES_LIST_PROGRESS";
export const FETCH_ALL_POLICIES_LIST_SUCCESS =
  "FETCH_ALL_POLICIES_LIST_SUCCESS";
export const FETCH_ALL_POLICIES_LIST_FAILED = "FETCH_ALL_POLICIES_LIST_FAILED";

export const fetchAllPoliciesListProgress = () =>
  action(FETCH_ALL_POLICIES_LIST_PROGRESS);
export const fetchAllPoliciesListSuccess = (list: IPolicy[]) =>
  action(FETCH_ALL_POLICIES_LIST_SUCCESS, { list });
export const fetchAllPoliciesListFailed = () =>
  action(FETCH_ALL_POLICIES_LIST_FAILED);

export const fetchAllPoliciesListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    limit: number,
    status: string,
    date: IDataTableV2DateState,
    searchValue: IDatatableV2AdvancedSearchFilter,
    line_of_business: string,
    setTypeOfPolicy: (policyType: string) => void,
    setTableName: (tableName: string) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      dispatch(fetchAllPoliciesListProgress());
      let finalUrl = `/policy/get-all-customer-policy-details?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}`;
      if (line_of_business === undefined) {
        setTypeOfPolicy("All Policies");
        setTableName("latest_all_customer_policy_details");
      } else if (line_of_business === "Business Policy") {
        finalUrl = `/policy/get-policy-business-customer-info?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}`;
        setTypeOfPolicy("Business Policies");
        setTableName("latest_policy_business_customer_info");
      } else if (line_of_business === "Automobile Policy") {
        finalUrl = `/policy/get-policy-auto-customer-info?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}`;

        setTypeOfPolicy("Automobile Policies");
        setTableName("latest_policy_automobile_customer_info");
      } else if (line_of_business === "Commercial Auto Policy") {
        finalUrl = `/policy/get-policy-commauto-customer-info?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}`;

        setTypeOfPolicy("Commercial Auto Policies");
        setTableName("latest_policy_commauto_customer_info");
      } else if (line_of_business === "Habitational Policy") {
        finalUrl = `/policy/get-habitational-customer-info?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}`;

        setTypeOfPolicy("Habitational Policies");
        setTableName("latest_policy_habitational_customer_info");
      } else if (line_of_business === "Life & Financial Policy") {
        finalUrl = `/policy/get-life-policy-basic-details-customer-info?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}`;

        setTypeOfPolicy("Life & Financial Policies");
        setTableName("latest_policy_life_basic_details_customer_info");
      }

      if(date.rangeType !== "allTimes"){
        finalUrl = finalUrl+ `&to_date=${date.dates.toDate}&from_date=${date.dates.fromDate}`
      }

      if (searchValue.length > 0) {
        finalUrl = finalUrl + `&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      if (status !== "-1") {
        finalUrl = finalUrl + `&column=policy_status&value=${status}`;
      }

      const res = await api.get(finalUrl);
      const data: IPolicy[] = res.data.data;

      dispatch(fetchAllPoliciesListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAllPoliciesListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_ALL_POLICIES_STATE = "CLEAR_ALL_POLICIES_STATE";

export const clearAllPoliciesState = () => action(CLEAR_ALL_POLICIES_STATE);
