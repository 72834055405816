import { LoadState } from "../../constants/enums";

export interface IExpressionTransformationState {
  selectedTableName: string;
  mappings: {
    data: IExpresssionTranform[];
    loading: LoadState;
  };
  funsVars: {
    data: IExpressionTransfromFunsVars;
    loading: LoadState;
    error: string | null;
  };
  variables: {
    data: IVariableItem[];
    loading: LoadState;
    totalRecords: number;
    error: string | null;
  };
  externalFunctions: {
    data: IExpressionTransfromFunsVars;
    loading: LoadState;
    error: string | null;
  };
}

export interface IVariableItem {
  csio_edi_groupcode_definition_id?: number | null;
  csio_edi_groupcode_definition_code: string | null;
  group_code: string;
  start_index: number;
  element_length: number;
  has_multiple_values: number;
  status: string;
  created_by_id?: number | null;
  created_by_name?: string | null;
  modified_by_id?: number | null;
  modified_by_name?: string | null;
  create_ts?: string;
  insert_ts?: string;
}

export interface IExpressionTransfromFunsVars {
  [key: string]: IExpressTransformObject;
}

export interface IExpressTransformObject {
  [key: string]: {
    params: IExpressTransformFunParams[];
    return: any;
  };
}

export interface IExpressTransformFunParams {
  name: string;
  type: any;
  required: boolean;
}

export interface IExpresssionTranform {
  csio_formula_calculator_id?: string;
  csio_formula_calculator_code: string | null;
  table_name: string | null;
  column_name: string | null;
  eval_string: string;
  libraries: string[];
  status: string;
  modified_by_id?: string | null;
  modified_by_name?: string | null;
  create_ts?: string | null;
  insert_ts?: string | null;
}

export interface IExpressionValidatePayload {
  eval_string: string;
  libraries: string[];
  external_variables: {
    [key: string]: string;
  };
}


export const initialExprressionTransformPayload: IExpresssionTranform = {
  csio_formula_calculator_code: null,
  table_name: null,
  column_name: null,
  eval_string: "",
  libraries: [],
  status: "ACTIVE",
};

export const initialExpressionTransformation: IExpressionTransformationState = {
  selectedTableName: "",
  mappings: {
    data: [],
    loading: LoadState.NotLoaded,
  },
  funsVars: {
    data: {},
    loading: LoadState.NotLoaded,
    error: null,
  },
  variables: {
    data: [],
    loading: LoadState.NotLoaded,
    totalRecords: 0,
    error: null,
  },
  externalFunctions: {
    data: {},
    loading: LoadState.NotLoaded,
    error: null,
  },
};
