import { LoadState } from "../../../constants/enums";

export interface IHabitationalInterestedState {
  data: IHabitationalInterested[];
  loading: LoadState;
  interest: IHabitationalInterested;
  intrestLoading: LoadState;
  error: string | null;
}

export interface IHabitationalInterested {
  customer_policy_id: number | null;
  customer_id: number | null;
  incremental_number: number | null;
  location_number: number | null;
  interested_party_name?: string;
  interested_party_code: string | null;
  mortgage_or_loss_payee_code: string | null;
  mortgage_or_loss_payee_name: string | null;
  mortgage_or_loss_payee_nature_of_interest: string | null;
  mortgage_or_loss_payee_address: string | null;
  mortgage_or_loss_payee_unit_or_suite: string | null;
  mortgage_or_loss_payee_city: string | null;
  mortgage_or_loss_payee_province_or_state: string | null;
  mortgage_or_loss_payee_country: string | null;
  mortgage_or_loss_payee_postal_or_zip_code: string | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
}

export interface IInterestedRow {
  customer_policy_id: string;
  policy_number: string | null;
  customer_id: string | null;
  name: string | null;
}

export const defaultHabitationalInterestedState: IHabitationalInterestedState =
  {
    data: [],
    loading: LoadState.NotLoaded,
    interest: {
      customer_policy_id: null,
      customer_id: null,
      incremental_number: null,
      location_number: null,
      interested_party_name: "",
      interested_party_code: null,
      mortgage_or_loss_payee_code: null,
      mortgage_or_loss_payee_name: null,
      mortgage_or_loss_payee_nature_of_interest: null,
      mortgage_or_loss_payee_address: null,
      mortgage_or_loss_payee_unit_or_suite: null,
      mortgage_or_loss_payee_city: null,
      mortgage_or_loss_payee_province_or_state: null,
      mortgage_or_loss_payee_country: null,
      mortgage_or_loss_payee_postal_or_zip_code: null,
      address: null,
      city: null,
      country: null,
      postal_code: null,
      province_or_state: null,
      unit_or_suite: null,
    },
    intrestLoading: LoadState.NotLoaded,
    error: null,
  };
