
import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";

import { AutomobileCSIOApplicationPolicyReqActions } from ".";
import {
  CLEAR_AUTOMOBILE_CSIO_POLICY_REQ_APPLICATION_STATE,
  FETCH_AUTOMOBILE_CSIO_POLICY_REQ_APPLICATION_FAILED,
  FETCH_AUTOMOBILE_CSIO_POLICY_REQ_APPLICATION_PROGRESS,
  FETCH_AUTOMOBILE_CSIO_POLICY_REQ_APPLICATION_SUCCESSS,
  FETCH_AUTOMOBILE_CSIO_POLICY_REQ_LIST_FAILED,
  FETCH_AUTOMOBILE_CSIO_POLICY_REQ_LIST_PROGRESS,
  FETCH_AUTOMOBILE_CSIO_POLICY_REQ_LIST_SUCCESS,
} from "./automobileCSIOPolicyChangeReqActions";
import { initialAutomobileCSIOPolicyChangeReqState } from "./automobileCSIOPolicyChangeReq.types";

export const AutomobileCSIOPolicyChangeReqReducer = (
  state: IStoreState["automobile"]["csioPolicyChangeReq"] = initialAutomobileCSIOPolicyChangeReqState,
  action: AutomobileCSIOApplicationPolicyReqActions
) => {
  switch (action.type) {
    case FETCH_AUTOMOBILE_CSIO_POLICY_REQ_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_CSIO_POLICY_REQ_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = list;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_CSIO_POLICY_REQ_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }

    case FETCH_AUTOMOBILE_CSIO_POLICY_REQ_APPLICATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.error = null;
        draftState.policyChangeData = initialAutomobileCSIOPolicyChangeReqState["policyChangeData"];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_CSIO_POLICY_REQ_APPLICATION_SUCCESSS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.policyChangeData = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_CSIO_POLICY_REQ_APPLICATION_FAILED: {
      const { error } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.Failed;
        draftState.error = error || null;
        draftState.policyChangeData = initialAutomobileCSIOPolicyChangeReqState["policyChangeData"];
      });
      return newState;
    }

    case CLEAR_AUTOMOBILE_CSIO_POLICY_REQ_APPLICATION_STATE: {
      return initialAutomobileCSIOPolicyChangeReqState;
    }

    default: {
      return state;
    }
  }
};
