import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useDispatchWrapper } from "../../../../../../hooks";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { LoadState } from "../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { useDateFilter } from "../../../../../../components/Table/hooks/useDateFilter";
import { IHabitationalCSIOEDIApplication } from "../../../../../../redux/habitationalPolicy/habitationalCSIOEDIApplication/habitationalCSIOEDIApplication.types";
import { fetchHabitationalEDIListAsync } from "../../../../../../redux/habitationalPolicy/habitationalCSIOEDIApplication/habitatinalCSIOEDIApplicationActions";

export const HabitationalCSIOEDIApplicationList = () => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerpolicyId = data1[1];
  const navigate = useNavigate();
  const { list, loading } = useSelector(
    (storeState: IStoreState) => storeState.habitational.edi
  );

  const [searchType, setSearchType] = React.useState<string[]>([
    "message_guid",
  ]);
  const [status, setStatus] = React.useState<
    IHabitationalCSIOEDIApplication["status"] | "-1"
  >("-1");
  const [search, setSearch] = React.useState<string>("");
  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "allTimes" });

  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });
  const dispatch = useDispatchWrapper();

  const habitationalEDIApplicationTableProps: IDataTableProps = {
    isPagination: true,
    isDataLoading: loading === LoadState.InProgress,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    selectionMode: "multiple",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [],
      },
    },
    columns: [
      {
        key: "view",
        headerName: "Actions",
        headingAlign: "center",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalCSIOEDIApplication) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/habitational-policy/${customerId}/habitational-application-csio-tab/${customerpolicyId}/edi/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
      {
        key: "company_type",
        headerName: "Policy Status",
        fieldName: "company_type",
        exportCellWidth: 25,
        renderType: RenderType.CHIP_WARNING,
      },
      {
        key: "message_guid",
        headerName: "Message Guid",
        fieldName: "message_guid",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
      },
      {
        key: "applicant_name",
        headerName: "Applicant Name",
        fieldName: "applicant_name",
        renderType: RenderType.CHIP_WARNING,
        exportCellWidth: 20,
        onRowCellRender: (value, row: IHabitationalCSIOEDIApplication) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/habitational-policy/${customerId}/habitational-application-csio-tab/${customerpolicyId}/edi/${row.incremental_number}`
                )
              }
            >
              {row.applicant_details.applicant_name}
            </Link>
          );
        },
      },
      {
        key: "policy_number",
        headerName: "Policy No.",
        fieldName: "policy_number",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 20,
      },
      {
        key: "insured_company",
        headerName: "Insurance Company",
        fieldName: "insured_company",
        exportCellWidth: 25,
        renderType: RenderType.TEXT_DARK,
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        exportCellWidth: 25,
        renderType: RenderType.CHIP_SUCCESS,
      },
    ],
    items: list,
  };

  const fetchList = () => {
    dispatch(
      fetchHabitationalEDIListAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        status,
        date,
        customerpolicyId
      )
    );
  };

  React.useEffect(() => {
    fetchList();
  }, [status, date, tablePagination, customerpolicyId]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...habitationalEDIApplicationTableProps} />
      </Box>
    </>
  );
};
