
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";

import { ERROR_MESSAGES } from "../../../constants/enums";
import { IDate } from "../../../components/Table/hooks/useDateFilter";
import { ICommAutomobileCSIOState } from "./commAutoCsioApplication.types";
import { transformCSIOToCommAuto } from "./transformCSIOToCommAuto";


export const FETCH_COMMAUTO_CSIO_LIST_PROGRESS = "FETCH_COMMAUTO_CSIO_LIST_PROGRESS";
export const FETCH_COMMAUTO_CSIO_LIST_SUCCESS = "FETCH_COMMAUTO_CSIO_LIST_SUCCESS";
export const FETCH_COMMAUTO_CSIO_LIST_FAILED = "FETCH_COMMAUTO_CSIO_LIST_FAILED";

export const fetchCSIOCommAutoListProgress = () => action(FETCH_COMMAUTO_CSIO_LIST_PROGRESS);
export const fetchCSIOCommAutoListSuccess = (list: ICommAutomobileCSIOState[], totalRecords: number) =>
  action(FETCH_COMMAUTO_CSIO_LIST_SUCCESS, {
    list,
    totalRecords,
  });
export const fetchCSIOCommAutoListFailed = () => action(FETCH_COMMAUTO_CSIO_LIST_FAILED);

export const fetchCSIOCommAutoListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    searchValue: string,
    searchType: string[],
    date: IDate
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCSIOCommAutoListProgress());
      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }
      let finalUrl = `/csio/get-csio-retrieve-messages?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;

      if (searchType.length > 0 && searchValue) {
        finalUrl = `/csio/get-csio-retrieve-messages?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&column=${[
          searchType,
        ]}&value=${searchValue}`;
      }

      const res = await api.get(finalUrl);
      const data: ICommAutomobileCSIOState[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchCSIOCommAutoListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCSIOCommAutoListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };


export const FETCH_COMMAUTO_CSIO_APPLICATION_PROGRESS =
  "FETCH_COMMAUTO_CSIO_APPLICATION_PROGRESS";
export const FETCH_COMMAUTO_CSIO_APPLICATION_SUCCESSS =
  "FETCH_COMMAUTO_CSIO_APPLICATION_SUCCESSS";
export const FETCH_COMMAUTO_CSIO_APPLICATION_FAILED =
  "FETCH_COMMAUTO_CSIO_APPLICATION_FAILED";

export const fetchCommAutoCSIOApplicationProgress = () =>
  action(FETCH_COMMAUTO_CSIO_APPLICATION_PROGRESS);
export const fetchCommAutoCSIOApplicationSuccess = (
  data: ICommAutomobileCSIOState
) => action(FETCH_COMMAUTO_CSIO_APPLICATION_SUCCESSS, { data });
export const fetchCommAutoCSIOApplicationFailed = (error?: string) =>
  action(FETCH_COMMAUTO_CSIO_APPLICATION_FAILED, {error});

export const fetchCommAutoCSIOApplicationAsync =
  (
    messageGUid: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
  //   try {
  //     dispatch(fetchCommAutoCSIOApplicationProgress());
  //     const res = await api.get(
  //       `/csio/get-csio-retrieve-messages?message_guid=${messageGUid}`
  //     );
  //     const data = res.data.data;
  //     if (data.length > 0) {
  //       const { xml_json_message } = data[0];
  //       const parsedData = JSON.parse(xml_json_message);
  //       const result = transformCSIOToAutomobile(parsedData);
  //       dispatch(fetchCommAutoCSIOApplicationSuccess(result));
  //     } else {
  //       dispatch(fetchCommAutoCSIOApplicationFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
  //     }
  //   } catch (err: any) {
  //     dispatch(fetchCommAutoCSIOApplicationFailed());
  //     dispatch(
  //       showMessage({
  //         type: "error",
  //         message: err.response.data.message,
  //         displayAs: "snackbar",
  //       })
  //     );
  //   }
  // };
  try {
    dispatch(fetchCommAutoCSIOApplicationProgress());
    const res = await api.get(
      `/csio/get-csio-retrieve-messages?message_guid=${messageGUid}`
    );
    console.log("API response:", res);
    
    if (res && res.data && res.data.data) {
      const data = res.data.data;
      console.log("AutomobileData:", data);
      
      if (data.length > 0) {
        const { xml_json_message } = data[0];
        console.log("xml_json_message:", xml_json_message);
        
        try {
          const parsedData = JSON.parse(xml_json_message);
          console.log("parsedData:", parsedData);
          const result = transformCSIOToCommAuto(parsedData);
          console.log("result:", result);
          dispatch(fetchCommAutoCSIOApplicationSuccess(result));
        } catch (parseError) {
          console.error("JSON parse error:", parseError);
          dispatch(fetchCommAutoCSIOApplicationFailed("Failed to parse JSON message"));
        }
      } else {
        console.log("No record found.");
        dispatch(fetchCommAutoCSIOApplicationFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
      }
    } else {
      console.log("No record found in response data.");
      dispatch(fetchCommAutoCSIOApplicationFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
    }
  } catch (err: any) {
    console.error("Fetch error:", err);
    dispatch(fetchCommAutoCSIOApplicationFailed());
    dispatch(
      showMessage({
        type: "error",
        message: err.response?.data?.message || "An error occurred",
        displayAs: "snackbar",
      })
    );
  }
};

export const addCommAutoCSIOApplication =
  (
    data: ICommAutomobileCSIOState,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/policy/edit-auto-automobile-application", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Application saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchCommAutoCSIOApplicationSuccess(data));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };



export const CLEAR_COMMAUTO_CSIO_APPLICATION_STATE =
  "CLEAR_COMMAUTO_CSIO_APPLICATION_STATE";

export const clearCommAutoCSIOApplicationState = () =>
  action(CLEAR_COMMAUTO_CSIO_APPLICATION_STATE);
