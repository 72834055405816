import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import { LoadState, MODULE_IDS } from "../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { DateFilterDropdown } from "../../../../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { useDateFilter } from "../../../../../../components/Table/hooks/useDateFilter";
import { CustomDatePicker } from "../../../../../../components/formsComponents/CustomDatePicker";
import { RoleBasedCSVExportPlugin } from "../../../../../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { RefreshPlugin } from "../../../../../../components/Table/plugins";
import { fetchCSIOPolicyChangeListAsync } from "../../../../../../redux/automobileCSIOApplication/automobileCSIOApplicationActions";
import { ILegal } from "../../../../../../redux/legals/legals.types";
import { IAutomobileCSIOEDIApplication } from "../../../../../../redux/automobileCSIOEDIApplication/automobileCSIOEdiApplication.types";
import { fetchAutomobileEDIListAsync } from "../../../../../../redux/automobileCSIOEDIApplication/automobileCSIOEDIApplicationActions";

export const AutomobileCSIOEDIApplicationList = () => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerpolicyId = data1[1];

  const navigate = useNavigate();
  const { list, loading } = useSelector(
    (storeState: IStoreState) => storeState.automobile.edi
  );
  const [searchType, setSearchType] = React.useState<string[]>([
    "message_guid",
  ]);
  const [status, setStatus] = React.useState<IAutomobileCSIOEDIApplication["status"] | "-1">("-1");
  const [search, setSearch] = React.useState<string>("");
  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
  useDateFilter({ defaultType: "allTimes" });

  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });
  const dispatch = useDispatchWrapper();

  const automobileApplicationTableProps: IDataTableProps = {
    isPagination: true,
    // totalRecords: totalRecords,
    isDataLoading: loading === LoadState.InProgress,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    selectionMode: "multiple",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          // {
          //   key: "0",
          //   onRender: () => (
          //     <CustomSelect
          //       sx={{ minWidth: "120px" }}
          //       value={map}
          //       onChange={(e) => setMap(e.target.value as string)}
          //     >
          //       <MenuItem value={"-1"}>Both</MenuItem>;
          //       <MenuItem value={"1"}>Matched</MenuItem>
          //       <MenuItem value={"0"}>Unmatched</MenuItem>
          //     </CustomSelect>
          //   ),
          // },
          {
            key: "byDate",
            onRender: () => (
              <DateFilterDropdown
                value={type}
                options={dateDropdownList}
                onChange={handleDateSelectChange}
              />
            ),
          },
          {
            key: "fromDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.fromDate}
                  onChange={(newValue) => {
                    setDate({ ...date, fromDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "toDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.toDate}
                  onChange={(newValue) => {
                    setDate({ ...date, toDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "csvExport",
            onRender: (columns, items) => (
              <RoleBasedCSVExportPlugin
                columns={columns}
                items={items}
                moduleId={MODULE_IDS.AUTOMOBILE}
                filePrefixName="Report"
              />
            ),
          },
          {
            key: "refresh",
            onRender: () => <RefreshPlugin onClick={fetchList} />,
          },
        ],
      },
      rightItems: {
        customPlugins: [],
      },
    },
    columns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IAutomobileCSIOEDIApplication) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/automobile-policy/${customerId}/automobile-csio-application-tab/${customerpolicyId}/edi/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
      {
        key: "new_policy",
        headerName: "Policy Status",
        fieldName: "new_policy",
        exportCellWidth: 25,
        renderType: RenderType.CHIP_WARNING,
      },
      {
        key: "message_guid",
        headerName: "Message Guid",
        fieldName: "message_guid",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
      },
      {
        key: "application_details.name",
        headerName: "Customer Name",
        fieldName: "application_details.name",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 20,
        onRowCellRender: (value, row: IAutomobileCSIOEDIApplication) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/automobile-policy/${customerId}/automobile-csio-application-tab/${customerpolicyId}/edi/${row.incremental_number}`
                );
              }}
            >
              {row.application_details.name}
            </Link>
          );
        },
      },
      {
        key: "policy_number_assigned",
        headerName: "Policy #",
        fieldName: "policy_number_assigned",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
      },
      {
        key: "insurance.insurance_name",
        headerName: "Insurance Company",
        fieldName: "insurance.insurance_name",
        exportCellWidth: 25,
        renderType: RenderType.TEXT_DARK,
        onRowCellValueRender: (value, row: IAutomobileCSIOEDIApplication) => {
          return row.insurance.insurance_name || "";
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        exportCellWidth: 25,
        renderType: RenderType.CHIP_SUCCESS,
      },
    ],
    items: list,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
  };


  const fetchList = () => {
    dispatch(
      fetchAutomobileEDIListAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        status,
        date,
        customerpolicyId
      )
    );
  };


  React.useEffect(() => {
    fetchList();
  }, [status, date, tablePagination, customerpolicyId]);

  return (
    <Box sx={{ mt: 2 }}>
      <DataTable {...automobileApplicationTableProps} />
    </Box>
  );
};
