import moment from "moment";
import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface IBusinessGarageCertificateState {
  businessGarageCertificateList: IBusinessGarageCertificate[];
  businessGarageCertificateListLoading: LoadState;
  businessGarageCertificate: IBusinessGarageCertificate;
  businessGarageCertificateLoading: LoadState;
}

interface ICommercialDeductRow {
  deductible: number | null;
  amount: number | null;
}

interface IDynamicRow {
  key: string;
  coverage: string;
  deductible: number | null;
  amount: number | null;
}

interface ICommercialSectionDropdown {
  key: string;
  label: string;
  deductible: number | null;
  amount: number | null;
}

interface ICommercialPropertySectionFive {
  deductible: number | null;
  amount: number | null;
}

export interface IBusinessGarageCertificate {
  customer_policy_id: number;
  cgl: string | null;
  incremental_number: number | null;
  garage_liability: string | null;
  other_liability: string | null;
  vehicle_unit_number_or_vin: string | null;
  certificate_holder_section_one: string | null;
  certificate_holder_name: string | null;
  certificate_holder_address: string | null;
  certificate_holder_email: string | null;
  certificate_holder_phone: string | null;
  description_of_operations: {
    key: string;
    label: string;
    value: string;
  }[];
  commercial_general_liability_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  commercial_general_liability_section_two: {
    generalAggregate: ICommercialDeductRow;
    eachOccurrence: ICommercialDeductRow;
    productsAndCompletedOperationsAggregate: ICommercialDeductRow;
    employerLiability: ICommercialDeductRow;
    dropDownPersonalAndAdvertInjury: ICommercialDeductRow & { value: string };
    medicalPayments: ICommercialDeductRow;
    tenantsLegalLiability: ICommercialDeductRow;
    nonOwnedAutomobiles: ICommercialDeductRow;
    hiredAutomobiles: ICommercialDeductRow;
  };
  commercial_general_liability_section_three: ICommercialSectionDropdown[];

  commercial_general_liability_section_four: {
    description: {label: string} & ICommercialPropertySectionFive,
    building: ICommercialPropertySectionFive;
    equipment: ICommercialPropertySectionFive;
    stock: ICommercialPropertySectionFive;
    earthquake: ICommercialPropertySectionFive;
    sewerBackup: ICommercialPropertySectionFive;
    flood: ICommercialPropertySectionFive;
  };
  commercial_general_liability_section_five: ICommercialSectionDropdown[];
  commercial_general_liability_section_six: ICommercialSectionDropdown[];
  commercial_general_liability_section_seven: ICommercialSectionDropdown[];
  commercial_general_liability_section_eight: ICommercialSectionDropdown[];
  commercial_general_liability_section_nine: ICommercialSectionDropdown[];


  garage_liability_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };

  garage_liability_section_two: ICommercialSectionDropdown[];
  garage_liability_section_three: {
    lossOrDamage: string;
    deductible: string | null;
    amount: string | null;
  };
  garage_liability_section_four: {
    label1: string;
    label1Amount: string | null;
    label1Deductible: string | null;

    label2: string;
    label2Amount: string | null;
    label2Deductible: string | null;
  },
  // garage_liability_section_two: {
  //   thirdPartyLiability: {
  //     deductible: string | null;
  //     amount: string | null;
  //   };
  //   accidentBenefits: {
  //     deductible: string | null;
  //     amount: string | null;
  //   };
  //   uninsuredAutomobileCoverage: {
  //     deductible: string | null;
  //     amount: string | null;
  //   };
  //   dcpd: {
  //     deductible: string | null;
  //     amount: string | null;
  //   };
  // };

  excess_liability_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  excess_liability_section_two: {
    umbrella_form_deductible: string;
    umbrella_form_amount: string;
    aggregate_form_deductible: string;
    aggregate_form_amount: string;
  };
  // other_liability_section_one: {
  //   insurance_company: string;
  //   policy_number: string;
  //   effective_date: string;
  //   expiry_date: string;
  // };

  // other_liability_section_two: ICommercialSectionDropdown[];
  cancelation_number_of_days: string | null;
  brokerage_full_name: string | null;
  brokerage_mailing_address: string | null;
  brokerage_customer_code: string | null;
  additional_name_insured_yes_or_no: string | null;
  additional_name_insured_name: string | null;
  additional_name_insured_mailing_address: string | null;
  certificate_authorization_branch_name: string | null;
  certificate_authorization_assigned_to: number | null;
  certificate_authorization_phone_number: string | null;
  certificate_authorization_fax_number: string | null;
  certificate_authorization_email: string | null;
  certificate_authorization_date: string | null;
  status: string;

  other_liability_section_one: {
    insurance_company: string | null;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  other_liability_section_two: IDynamicRow[];
}

export const initialBusinessGarageCertificate: IBusinessGarageCertificate = {
  customer_policy_id: 0,
  other_liability: null,
  cgl: "",
  garage_liability: "",
  incremental_number: null,
  vehicle_unit_number_or_vin: null,
  certificate_holder_name: null,
  certificate_holder_address: null,
  certificate_holder_email: null,
  certificate_holder_phone: null,
  description_of_operations: [
    { key: getUniqueId(), label: "Line 1", value: "" },
    { key: getUniqueId(), label: "Line 2", value: "" },
    { key: getUniqueId(), label: "Line 3", value: "" },
    { key: getUniqueId(), label: "Line 4", value: "" },
  ],
  commercial_general_liability_section_one: {
    insurance_company: "",
    policy_number: "",
    effective_date: "",
    expiry_date: "",
  },
  commercial_general_liability_section_two: {
    eachOccurrence: { deductible: null, amount: null },
    dropDownPersonalAndAdvertInjury: {
      deductible: null,
      amount: null,
      value: "",
    },
    employerLiability: { deductible: null, amount: null },
    generalAggregate: { deductible: null, amount: null },
    hiredAutomobiles: { deductible: null, amount: null },
    medicalPayments: { deductible: null, amount: null },
    nonOwnedAutomobiles: { deductible: null, amount: null },
    productsAndCompletedOperationsAggregate: { deductible: null, amount: null },
    tenantsLegalLiability: { deductible: null, amount: null },
  },
  commercial_general_liability_section_three: [
    {
      key: getUniqueId(),
      label: "",
      amount: null,
      deductible: null,
    },
  ],
  commercial_general_liability_section_five: [
    {
      key: getUniqueId(),
      label: "",
      amount: null,
      deductible: null,
    },
  ],
  commercial_general_liability_section_four: {
    description: {label: "", amount: null, deductible: null},
    building: { amount: null, deductible: null },
    earthquake: { amount: null, deductible: null },
    equipment: { amount: null, deductible: null },
    flood: { amount: null, deductible: null },
    sewerBackup: { amount: null, deductible: null },
    stock: { amount: null, deductible: null },
  },
  commercial_general_liability_section_six: [
    { key: getUniqueId(), label: "", amount: null, deductible: null },
  ],
  commercial_general_liability_section_seven: [
    { key: getUniqueId(), label: "", amount: null, deductible: null },
  ],
  commercial_general_liability_section_eight: [
    { key: getUniqueId(), label: "", amount: null, deductible: null },
  ],
  commercial_general_liability_section_nine: [
    { key: getUniqueId(), label: "", amount: null, deductible: null },
  ],

  other_liability_section_one: {
    insurance_company: "",
    policy_number: "",
    effective_date: "",
    expiry_date: "",
  },
  other_liability_section_two: [
    { key: getUniqueId(), coverage: "", amount: null, deductible: null },
  ],
  garage_liability_section_one: {
    effective_date: "",
    expiry_date: "",
    policy_number: "",
    insurance_company: "",
  },
  garage_liability_section_two: [
    {
      key: getUniqueId(),
      label: "Third Party Liability",
      amount: null,
      deductible: null,
    },
    {
      key: getUniqueId(),
      label: "Accident Benefits",
      amount: null,
      deductible: null,
    },
    {
      key: getUniqueId(),
      label: "Uninsured Automobile Coverage",
      amount: null,
      deductible: null,
    },
    { key: getUniqueId(), label: "DCPD", amount: null, deductible: null },
  ],
  garage_liability_section_three: {
    amount: null,
    deductible: null,
    lossOrDamage: ''
  },
garage_liability_section_four: {
  label1: "",
  label1Amount: null,
  label1Deductible: null,
  label2: "",
  label2Amount: null,
  label2Deductible: null,
},


  excess_liability_section_one: {
    insurance_company: "",
    policy_number: "",
    effective_date: "",
    expiry_date: "",
  },
  excess_liability_section_two: {
    umbrella_form_amount: "",
    umbrella_form_deductible: "",

    aggregate_form_amount: "",
    aggregate_form_deductible: "",
  },

  // other_liability_section_one: {
  //   insurance_company: "",
  //   policy_number: "",
  //   effective_date: "",
  //   expiry_date: "",
  // },
  // other_liability_section_two: [
  //   { key: getUniqueId(), label: "", deductible: null, amount: null },
  // ],

  cancelation_number_of_days: null,
  brokerage_full_name: null,
  brokerage_mailing_address: null,
  brokerage_customer_code: null,
  additional_name_insured_yes_or_no: null,
  additional_name_insured_name: null,
  additional_name_insured_mailing_address: null,
  certificate_authorization_branch_name: null,
  certificate_authorization_assigned_to: null,
  certificate_authorization_phone_number: null,
  certificate_authorization_fax_number: null,
  certificate_authorization_email: null,
  certificate_authorization_date: moment().format("YYYY-MM-DD"),
  status: "ACTIVE",
  certificate_holder_section_one: "",
};

export const initialBusinessGarageCertificateState: IBusinessGarageCertificateState =
  {
    businessGarageCertificateList: [],
    businessGarageCertificateListLoading: LoadState.NotLoaded,
    businessGarageCertificate: initialBusinessGarageCertificate,
    businessGarageCertificateLoading: LoadState.NotLoaded,
  };
