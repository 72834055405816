import { action } from "typesafe-actions";
import {
  ITaskBaord,
  ITaskBoardItem,
  initialTaskBoardState,
} from "./taskBoard.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";

export const FETCH_TASKBOARD_PROGRESS = "FETCH_TASKBOARD_PROGRESS";
export const FETCH_TASKBOARD_SUCCESS = "FETCH_TASKBOARD_SUCCESS";
export const FETCH_TASKBOARD_FAILED = "FETCH_TASKBOARD_FAILED";

export const fetchTaskboardProgress = () => action(FETCH_TASKBOARD_PROGRESS);
export const fetchTaskboardSuccess = (data: ITaskBaord) =>
  action(FETCH_TASKBOARD_SUCCESS, { data });
export const fetchTaskboardFailed = () => action(FETCH_TASKBOARD_FAILED);

export const fetchTaskbaordAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTaskboardProgress());
      const res = await api.get(`tasks/get-tasks-user-taskboard`);
      const data: ITaskBaord[] = res.data.data;

      if (data.length > 0) {
        dispatch(fetchTaskboardSuccess(data[0]));
      } else {
        dispatch(fetchTaskboardSuccess(initialTaskBoardState["data"]));
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskBoardAsync =
  (
    taskBoard: ITaskBaord,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try { dispatch(saveLoaderProgress())
      dispatch(fetchTaskboardSuccess(taskBoard));
      await api.post("/tasks/create-tasks-user-taskboard", {
        ...taskBoard
      });

      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const editTaskbaordAync =
  (
    task: ITaskBoardItem,
    key: "todo" | "progress" | "hold" | "completed",
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const taskBoard = getState().tasks.taskBoard.data;
    try {
      const tasks = [...taskBoard[key as "todo"]];
      const index = tasks.findIndex((x) => x.id === task.id);
      if (index > -1) {
        tasks[index] = task;
        const finalTaskBaord = { ...taskBoard, [key]: tasks };
        dispatch(upsertTaskBoardAsync(finalTaskBaord, onCallback));
      } else {
        onCallback(false);
      }
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
