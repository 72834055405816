/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Button, Link, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { LoadState } from "../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../hooks";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { CustomSelect } from "../../../../../../components/formsComponents";
import { renderDropdownValue } from "../../../../../../helpers";
import { Add, NotificationsActive, ThumbUpAlt } from "@mui/icons-material";
import { CustomChip } from "../../../../../../components/CustomChip";
import { LeadOppStatus } from "../../../../../../components/LeadOppStatus/LeadOppStatus";
import { IHabitationalConsentForm } from "../../../../../../redux/habitationalPolicy/HabitationalPolicyForm/HabitationalPolicyForm.types";
import { downloadHabitationalConsentFormPDFAsync, fetchHabitationalConsentFormListAsync, previewHabitationalConsentFormPDFAsync } from "../../../../../../redux/habitationalPolicy/HabitationalPolicyForm/HabitationalPolicyFormActions";

export const HabitationalConsentFormList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { list, loading } = useSelector(
    (storeState: IStoreState) => storeState.habitational.policyform.consentform
  );
  const [downloading, setDownloading] = React.useState(false);
  const dispatch = useDispatchWrapper();

  const [selectedConsent, setselectedConsent] = React.useState<{
    isAllSelected: boolean;
    certificate: IHabitationalConsentForm[];
  }>({
    isAllSelected: false,
    certificate: [],
  });

  const menuItems =
    selectedConsent.certificate.length > 0 || selectedConsent.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const ConsentFormTableProps: IDataTableProps = {
    isPagination: true,
    isDataLoading: loading === LoadState.InProgress,
    selectionMode: "multiple",
    uniqueRowKeyName: "incemental_number",
    rowsPerPageOptions: 50,
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [...menuItems],
      },
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => {
                  navigate(
                    `/habitational-policy/${customerId}/policy-forms-tab/${customerPolicyId}/consent-form`
                  );
                }}
              >
                Create
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
    {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalConsentForm) => {
          return (
            <StandardTableActions
              downloadLoading={downloading}
              onDownloadPreview={() => {
                dispatch(
                  previewHabitationalConsentFormPDFAsync(
                    customerPolicyId,
                    row.incremental_number as number,
                    () => {}
                  )
                );
              }}
              onDownLoadClick={() => {
                if (row.incremental_number) {
                  setDownloading(true);
                  dispatch(
                    downloadHabitationalConsentFormPDFAsync(
                      customerPolicyId,
                      row.incremental_number,
                      () => {
                        setDownloading(false);
                      }
                    )
                  );
                }
              }}
              onEditClick={() => {
                navigate(
                  `/habitational-policy/${customerId}/policy-forms-tab/${customerPolicyId}/consent-form/${row.incremental_number}`
                );
              }}
              // onDuplicateClick={() => {
              //   navigate(
              //     `/habitational-policy/${customerId}/policy-forms-tab/${customerPolicyId}/consent-form-duplicate/${row.incremental_number}`
              //   );
              // }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalConsentForm) => {
          return <LeadOppStatus status={row.status} />;
        },
      },
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IHabitationalConsentForm) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/habitational-policy/${customerId}/policy-forms-tab/${customerPolicyId}/consent-form/${row.incremental_number}`
                );
              }}
            >
              {row.incremental_number}
            </Link>
          );
        },
      },
      {
        key: "customer_name",
        headerName: "Customer Name",
        fieldName: "customer_name",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },
      {
        key: "email_address",
        headerName: "Email",
        fieldName: "email_address",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },
      {
        key: "create_ts",
        headerName: "Date Created",
        fieldName: "create_ts",
        renderType: RenderType.DATE_TIME_DARK_COLOR,
        exportCellWidth: 25,
        enableSorting: false,
      },
      
    ],
    items: list,
    onSelection: (isAllSelected: boolean, rows: IHabitationalConsentForm[]) => {
        setselectedConsent({ isAllSelected: isAllSelected, certificate: rows });
    },
  };

  React.useEffect(() => {
    dispatch(fetchHabitationalConsentFormListAsync(customerPolicyId));
  }, [customerPolicyId]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...ConsentFormTableProps} />
      </Box>
    </>
  );
};
