import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialAccountingInformationState } from "./AccountingInformation.types";
import { AccountingInformationActions } from ".";
import {
  CLEAR_ACCOUNTING_INFORMATION,
  FETCH_ACCOUNTING_INFORMATION_FAILURE,
  FETCH_ACCOUNTING_INFORMATION_PROGRESS,
  FETCH_ACCOUNTING_INFORMATION_SUCCESS,
} from "./AccountingInformationActions";

export const AccountingInformationReducer = (
  state: IStoreState["accounting"]["information"] = initialAccountingInformationState,
  action: AccountingInformationActions
) => {
  switch (action.type) {
    case FETCH_ACCOUNTING_INFORMATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_INFORMATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_INFORMATION_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_ACCOUNTING_INFORMATION: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.NotLoaded;
        draftState.data = initialAccountingInformationState.data;
        draftState.error = null;
      });
      return newState;
    }

    default:
      return state;
  }
};
