import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { IBillTemplate, initialBillTemplateState } from "./BillTemplate.types";

export const FETCH_BILL_TEMPLATE_FAILURE = "FETCH_BILL_TEMPLATE_FAILURE";
export const FETCH_BILL_TEMPLATE_PROGRESS = "FETCH_BILL_TEMPLATE_PROGRESS";
export const FETCH_BILL_TEMPLATE_SUCCESS = "FETCH_BILL_TEMPLATE_SUCCESS";

export const fetchBillTemplateProgress = () =>
  action(FETCH_BILL_TEMPLATE_PROGRESS);
export const fetchBillTemplateSuccess = (data: IBillTemplate) =>
  action(FETCH_BILL_TEMPLATE_SUCCESS, { data });
export const fetchBillTemplateFailure = (errorMessage: string) =>
  action(FETCH_BILL_TEMPLATE_FAILURE, { errorMessage });

export const fetchAccountingBillTemplateAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBillTemplateProgress());

      const res = await api.get(`/accounting/get-account-bill-template`);
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBillTemplateSuccess(data[0]));
      } else {
        dispatch(fetchBillTemplateSuccess(initialBillTemplateState["data"]));
      }
    } catch (err: any) {
      dispatch(fetchBillTemplateFailure("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingBillTemplateAysnc =
  (
    data: IBillTemplate,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/accounting/edit-account-bill-template", {
        ...data,
      });
      let message = "Bill Template saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_INVOICE_BILL_TEMPLATE = "CLEAR_INVOICE_BILL_TEMPLATE";
export const clearBillTemplate = () => action(CLEAR_INVOICE_BILL_TEMPLATE);
