import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { ICommAutomobileGaragePlates } from "./commAutoGaragePlates.types";
import { uploadFile } from "../../../helpers";

export const addCommAutomobileGaragePlatesAsync =
  (
    data: ICommAutomobileGaragePlates,
    customerID: number,
    file: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        customer_id: customerID,
        policy_type: "COMM_AUTOMOBILE",
        customer_policy_id: data.customer_policy_id,
        page_name: "Comm-Auto Garage Plate",
      };

      const path = await uploadFile(
        file,
        "POLICY",
        data.attachment || "",
        asPayload
      );
      await api.post("/policy/edit-policy-commauto-garage-plates", {
        ...data,
        attachment: path,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Garage plate saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_COMM_AUTOMOBILE_GARAGE_PLATES_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_GARAGE_PLATES_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_GARAGE_PLATES_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_GARAGE_PLATES_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_GARAGE_PLATES_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_GARAGE_PLATES_LIST_FAILED";

export const fetchCommAutomobileGaragePlatesListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_GARAGE_PLATES_LIST_PROGRESS);

export const fetchCommAutomobileGaragePlatesListSuccess = (
  data: ICommAutomobileGaragePlates[]
) =>
  action(FETCH_COMM_AUTOMOBILE_GARAGE_PLATES_LIST_SUCCESS, {
    data,
  });
export const fetchCommAutomobileGaragePlatesListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_GARAGE_PLATES_LIST_FAILED);

export const fetchCommAutomobileGaragePlatesListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileGaragePlatesListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-garage-plates?customer_policy_id=${customerPolicyId}`
      );
      const data: ICommAutomobileGaragePlates[] = res.data.data;
      dispatch(fetchCommAutomobileGaragePlatesListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileGaragePlatesListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_GARAGE_PLATES_STATE =
  "CLEAR_COMM_AUTOMOBILE_GARAGE_PLATES_STATE";
export const clearCommAutomobileGaragePlatesState = () =>
  action(CLEAR_COMM_AUTOMOBILE_GARAGE_PLATES_STATE);
