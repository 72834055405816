/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { LoadState } from "../../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";
import { ICommAutomobileVehicleInformation } from "../../../../../../../redux/commAutoPolicy/commAutoVehicle/commAutoVehicle.types";
import { fetchCommAutomobileVehicleListAsync } from "../../../../../../../redux/commAutoPolicy/commAutoVehicle/commAutoVehicleActions";

export const CommAutomobileVehicleInformationList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { vehiclesList, vehiclesListLoading } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.vehicle.VehicleInformation
  );


  const dispatch = useDispatchWrapper();



  const automobileVehicleTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: vehiclesListLoading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    `/comm-automobile-policy/${customerId}/vehicle-tab/${customerPolicyId}/vehicle-information`
                  );
                }}
              >
                Add Vehicle
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "vehcile_number",
        headerName: "Vehicle Number",
        fieldName: "vehcile_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: ICommAutomobileVehicleInformation) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/vehicle-tab/${customerPolicyId}/vehicle-information/${row.incremental_number}`
                );
              }}
            >
              {row.incremental_number}
            </Link>
          );
        },
      },
      {
        key: "customer_name",
        headerName: " Customer Name",
        fieldName: "customer_name",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 20,
      },
      {
        key: "unit_number",
        headerName: "Unit Name",
        fieldName: "unit_number",
        exportCellWidth: 25,
        renderType: RenderType.CHIP_WARNING,
      },
      {
        key: "year",
        headerName: "Year",
        fieldName: "year",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "make",
        headerName: "Make",
        fieldName: "make",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },

      {
        key: "model",
        headerName: "Model",
        fieldName: "model",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "vin",
        headerName: "VIN",
        fieldName: "vin",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "owned_or_leased",
        headerName: "Owned/Leased",
        fieldName: "owned_or_leased",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "purchase_date",
        headerName: "Purchase Date",
        fieldName: "purchase_date",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "puchase_condition",
        headerName: "Purchase Condition",
        fieldName: "puchase_condition",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobileVehicleInformation) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/vehicle-tab/${customerPolicyId}/vehicle-information/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
    ],
    items: vehiclesList,
  };


  React.useEffect(() => {
    dispatch(fetchCommAutomobileVehicleListAsync(customerPolicyId));
  }, [customerPolicyId]);

  return (
    <Box sx={{ mt: 2 }}>
      <DataTable {...automobileVehicleTableProps} />
    </Box>
  );
};
