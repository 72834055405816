import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialAutomobileAlbertaApplicationState } from "./automobileAlbertaApplication.types";
import { CLEAR_AUTOMOBILE_ALBERTA_APPLICATION_STATE, FETCH_AUTOMOBILE_ALBERTA_APPLICATION_FAILED, FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_FAILED, FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_PROGRESS, FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_SUCCESS, FETCH_AUTOMOBILE_ALBERTA_APPLICATION_PROGRESS, FETCH_AUTOMOBILE_ALBERTA_APPLICATION_SUCCESSS } from "./automobileAlbertaApplicationActions";
import { AutomobileAlbertaApplicationActions } from ".";




export const AutomobileAlbertaApplicationReducer = (
  state: IStoreState["automobile"]["albertaApplication"] = initialAutomobileAlbertaApplicationState,
  action: AutomobileAlbertaApplicationActions
) => {
  switch (action.type) {
    case FETCH_AUTOMOBILE_ALBERTA_APPLICATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.application =
          initialAutomobileAlbertaApplicationState["application"];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_ALBERTA_APPLICATION_SUCCESSS: {
      const {data} =action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.application =data
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_ALBERTA_APPLICATION_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.application =
          initialAutomobileAlbertaApplicationState["application"];
      });
      return newState;
    }

    case FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Failed;
        draftState.data = [];
      });
      return newState;
    }

    case CLEAR_AUTOMOBILE_ALBERTA_APPLICATION_STATE: {
      return initialAutomobileAlbertaApplicationState;
    }

    default: {
      return state;
    }
  }
};