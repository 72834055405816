import { LoadState } from "../../constants/enums";

export interface ITaskBoardState {
  data: ITaskBaord;
  loading: LoadState;
}

export interface ITaskBoardItem {
  id:  string;
  dueDate: string;
  timeinhrs: any;
  title: string | null;
  content: string | null;
  createdDate: string | null;
  type: string,
  customer: string | null,
  policy: string | null,
  assigned_to: string | null,
}

export interface ITaskBaord {
  assigned_to_id: number | null;
  todo: ITaskBoardItem[];
  progress: ITaskBoardItem[];
  hold: ITaskBoardItem[];
  completed: ITaskBoardItem[];
  priority: string | null;
  status: string;
}

export const initialTaskBoardState: ITaskBoardState = {
  data: {
    assigned_to_id: 0,
    todo: [],
    progress: [],
    hold: [],
    completed: [],
    priority: null,
    status: "ACTIVE",
  },
  loading: LoadState.NotLoaded,
};
