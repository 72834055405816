import produce from "immer";
import { LoadState } from "../../../constants/enums";
import { IStoreState } from "../../initialStoreState";
import { initialBusinessPropertyInsurance, initialBusinessPropertyInsuranceState } from "./businessPropertyInsurance.types";
import { BusinessPolicyPropertyInsuranceActions } from ".";
import { CLEAR_BUSINESS_POLICY_PROPERTY_INSURANCE_STATE, FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_FAILED, FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_LIST_FAILED, FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_LIST_PROGRESS, FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_LIST_SUCCESS, FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_PROGRESS, FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_SUCCESS } from "./businessPropertyInsuranceActions";



export const businessPropertyInsuranceReducer = (
  state: IStoreState["business"]["insurance"] = initialBusinessPropertyInsuranceState,
  action: BusinessPolicyPropertyInsuranceActions,
) => {
  switch (action.type) {
    case FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.businessPropertyInsuranceListLoading = LoadState.InProgress;
        draftState.businessPropertyInsuranceList = [];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.businessPropertyInsuranceListLoading = LoadState.Loaded;
        draftState.businessPropertyInsuranceList = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.businessPropertyInsuranceListLoading = LoadState.Failed;
        draftState.businessPropertyInsuranceList = [];
      });
      return newState;
    }


    case FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_PROGRESS: {
        const newState = produce(state, (draftState) => {
          draftState.businessPropertyInsurance = initialBusinessPropertyInsuranceState["businessPropertyInsurance"];
          draftState.businessPropertyInsuranceLoading = LoadState.InProgress;
        });
        return newState;
      }
    
    case FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.businessPropertyInsurance = data;
        draftState.businessPropertyInsuranceLoading = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.businessPropertyInsuranceLoading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_BUSINESS_POLICY_PROPERTY_INSURANCE_STATE: {
      return initialBusinessPropertyInsuranceState;
    }
    default: {
      return state;
    }
  }
};
