import { LoadState } from "../../../constants/enums";

export interface IHabitationalAdditionalState {
  data: IHabitationalAdditional[];
  loading: LoadState;
  additional: IHabitationalAdditional;
  additionalLoading: LoadState;
  error: string | string | null;
}

export interface IHabitationalAdditional {
  customer_policy_id: number | null;
  customer_id: number | null;
  incremental_number: number | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
  name: string | null;
  risk_location: string | null;
  remarks: string | null;
  interest: string | null;
  status: "ACTIVE" | "PENDING";
}

export interface IAdditionalRow {
  customer_policy_id: string;
  policy_number: string | null;
  customer_id: string | null;
  interested_party: string | null;
}

export const defaultHabitationalAdditionalState: IHabitationalAdditionalState =
  {
    data: [],
    loading: LoadState.NotLoaded,
    additional: {
      customer_policy_id: null,
      customer_id: null,
      incremental_number: null,
      status: "ACTIVE",
      address: null,
      city: null,
      country: null,
      postal_code: null,
      province_or_state: null,
      unit_or_suite: null,
      name: null,
      risk_location: null,
      interest: null,
      remarks: null,
    },
    additionalLoading: LoadState.NotLoaded,
    error: null,
  };
