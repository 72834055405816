import { LoadState, SaveState } from "../../constants/enums";

export interface IAutomobileCSVState {
  details: {
    data: IAutomobileCSVDetails;
    loading: LoadState;
  };
  csvUse: {
    loading: LoadState;
    csvtable: IAutomobileCSVUse;
  };
  csv3: {
    data: IAutomobileCSV3[];
    loading: LoadState;
    saveLoading: SaveState;
    csvtable: IAutomobileCSV3;
    notFoundError: string | string | null;
  };
  customerNotFoundError: string | null;
  customerPoliycId?: number | null;
  isNewPolicy: boolean;
  loading: LoadState;
}
export interface IAutomobileCSV3 {
  customer_policy_id: number;
  csv3array: ICSV3Array[];
  status: string | null;
}

export interface IAutomobileCSVDetails {
  customer_policy_id: number | null;
  policy_number: string | null;
  insured: string | null;
  broker_or_agent: string | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
  business_of_insured: string | null;
  insurance_company: string | null;
  broker_or_agent_client_id: string | null;
  ibc_industry_code: string | null;
  n_s_c: string | null;
  r_i_n: string | null;
  c_v_o_r: string | null;
  year_of_experience_in_this_type_of_operation: string | null;
  year_business_started: string | null;
  assigned_to: string | null;
  loc_one: string | null;
  garaging_location_address_one: string | null;
  loc_two: string | null;
  garaging_location_address_two: string | null;
  loc_three: string | null;
  garaging_location_address_three: string | null;
  loc_four: string | null;
  garaging_location_address_four: string | null;
  applicant_need_liability_for_damage: string | null;
  liability_been_assumed_under_contract_or_agreement: string | null;
  type_of_non_owned_vehicle_or_trailer: string | null;
  average_no_of_vehicles_or_trailers_at_any_one_time: string | null;
  average_value: string | null;
  maximum_no_of_vehicle_or_trailers_annually: string | null;
  value_of_the_most_expensive_unit: string | null;
  are_any_of_the_insured_vehicles_used_for_public_transportation: string | null;
  remarks: string | null;
  submission: string | null;
  date_of_print: string | null;
  status: string;
}

export interface IAutomobileCSVUse {
  customer_policy_id: number;
  list: ICSV3Array[];
  status: string | null;
}

export interface ICSV3Array {
  type: string;
  Vehicle1: string | null;
  Vehicle2: string | null;
  Vehicle3: string | null;
  Vehicle4: string | null;
}

export const initialAutomoblieCSV: IAutomobileCSVState = {
  details: {
    data: {
      customer_policy_id: 0,
      policy_number: null,
      insured: null,
      broker_or_agent: null,
      address: null,
      unit_or_suite: null,
      city: null,
      province_or_state: null,
      postal_code: null,
      country: null,
      business_of_insured: null,
      insurance_company: null,
      broker_or_agent_client_id: null,
      ibc_industry_code: null,
      n_s_c: null,
      r_i_n: null,
      c_v_o_r: null,
      year_of_experience_in_this_type_of_operation: null,
      year_business_started: null,
      assigned_to: null,
      loc_one: null,
      garaging_location_address_one: null,
      loc_two: null,
      garaging_location_address_two: null,
      loc_three: null,
      garaging_location_address_three: null,
      loc_four: null,
      garaging_location_address_four: null,
      applicant_need_liability_for_damage: null,
      liability_been_assumed_under_contract_or_agreement: null,
      type_of_non_owned_vehicle_or_trailer: null,
      average_no_of_vehicles_or_trailers_at_any_one_time: null,
      average_value: null,
      maximum_no_of_vehicle_or_trailers_annually: null,
      value_of_the_most_expensive_unit: null,
      are_any_of_the_insured_vehicles_used_for_public_transportation: null,
      remarks: null,
      submission: null,
      date_of_print: null,
      status: "ACTIVE",
    },
    loading: LoadState.NotLoaded,
  },
  csvUse: {
    csvtable: {
      customer_policy_id: 0,
      list: [
        // csv3array: [
        {
          type: "Primary Bussness Use",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "Garage Location #",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "Years Of Exp",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "Pleasure Use (Y/N)",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "Pleasure Use %",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "If Recreational Vehicle User For Business, Identify Frenquency",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "If Artisan Use, Average Number of Customer Locations Visited in A Work day",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "Is Vehicle Used To Haul Trailers (Y/N)",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "Does Vehicle From Part Of A Trailer Train (Y/N)",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "Merchandise Carried",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "And Percentage Use Remarks, If More Space Required",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "If Delivery Service - Wholesale or Retail",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "Hauling Done For Others? If so, Provide Frequency",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "Normal Operating Distance - One Way (Kms)",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "% Of Total Trips",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "Maximum Operating Distance - One Way (Kms)",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "% Of Total Trips",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "No Of Trips Per Month Beyond The Normal Distance Form The Place Usually Kept",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "Most Common Destinations - List Cities and Provinces. Use Remarks Section If More Space Is Required",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "Any USA Exposure (Y/N)",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "Most Common Destinations - List Cities and States",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "No OF Kms Form The Border",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "No OF Trips Per Month",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "No Of Consecutive Days",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "Annual Use %",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "No OF Kms Form The Border",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "Describe Machinery Or Equipment Mounted On Or Attached To Vehicle",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "Year",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "No OF Kms Form The Border",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "Exclude",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "Owned or Leased",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "Value",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "No OF Kms Form The Border",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "Special or Seasonal Use",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "Percentage Of Annual Use",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "AUTO NO",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "LIST CITY, PROVINCE FOR STATE",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "U.S. DOT #",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "DOCKET #",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "TYPING OF FILING",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
        {
          type: "SPECIFY EXACT NAME ON THE FILING REQUIRED",
          Vehicle1: " ",
          Vehicle2: " ",
          Vehicle3: " ",
          Vehicle4: " ",
        },
      ],
      status: "ACTIVE",
    },
    loading: LoadState.NotLoaded,
  },
  csv3: {
    csvtable: { csv3array: [], customer_policy_id: 0, status: "ACTIVE" },
    data: [],
    loading: LoadState.NotLoaded,
    notFoundError: null,
    saveLoading: SaveState.NotStarted,
  },
  customerNotFoundError: null,
  customerPoliycId: null,
  isNewPolicy: true,
  loading: LoadState.NotLoaded,
};
