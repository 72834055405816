import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { TaskRiskLocationActions } from ".";
import { initialTaskRiskLocationState } from "./taskriskLocation.types";
import { FETCH_ADD_RISK_LOCATION_FAILED, FETCH_ADD_RISK_LOCATION_PROGRESS, FETCH_ADD_RISK_LOCATION_SUCCESS } from "./taskRiskLocationActions";

export const taskRiskLocationReducer = (
    state: IStoreState["tasks"]["riskLocation"] = initialTaskRiskLocationState,
    action: TaskRiskLocationActions
  ) => {
    switch (action.type) {
        case FETCH_ADD_RISK_LOCATION_PROGRESS: {
            const newState = produce(state, draftState=>{
                draftState.addRiskLocation.loading = LoadState.InProgress;
                draftState.addRiskLocation = initialTaskRiskLocationState['addRiskLocation'];
            })
            return newState;
        }
        case FETCH_ADD_RISK_LOCATION_SUCCESS: {
            const {data} = action.payload;
            const newState = produce(state, draftState=>{
                draftState.addRiskLocation.loading = LoadState.Loaded;
                draftState.addRiskLocation.data = data;
            })
            return newState;
        }
        case FETCH_ADD_RISK_LOCATION_FAILED: {
            const newState = produce(state, draftState=>{
                draftState.addRiskLocation.loading = LoadState.Failed;
            })
            return newState;
        }
        
        default:
            return state;
    }
  }