import React from "react";
import { Dialog } from "../../../../components/Dialogs/Dialog";
import { Button, Grid } from "@mui/material";
import { StandardCellList } from "../../../../components/Standards";
import { formatDate } from "../../../../helpers";
import { useNavigate, useParams } from "react-router-dom";
import { StandadCard } from "../../../../components/Cards";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../hooks";
import {
  fetchTaskAysnc,
  fetchTaskMultiFileUploadAysnc,
} from "../../../../redux/tasks/tasksActions";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../constants/enums";
import { ITaskFileUploadAttachment } from "../../../../redux/tasks/task.types";
import EditIcon from "@mui/icons-material/Edit";
import { IOldInsuranceReportsList } from "../OldInsuranceReportsList";

interface IViewOldInsuranceReportProps {
  open: boolean;
  onClose: () => void;
  data: IOldInsuranceReportsList;
}

export const ViewOldInsuranceReport: React.FC<IViewOldInsuranceReportProps> = (
  props
) => {
  const { open, data, onClose } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      size="lg"
      title="View Old Insurance Report"
    >
      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <StandardCellList
            // heading="About Driver"
            colSize={3}
            list={[
              {
                heading: "Driver Number",
                data: {
                  type: "text",
                  value: data.driver_number,
                },
              },
              {
                heading: "Driver Name",
                data: {
                  type: "text",
                  value: data.driver_name,
                },
              },
              {
                heading: "License Number",
                data: {
                  type: "text",
                  value: data.license_number,
                },
              },
              {
                heading: "Customer Code",
                data: {
                  type: "text",
                  value: data.customer_code,
                },
              },
              {
                heading: "Policy Status Cancelled",
                data: {
                  type: "text",
                  value: data.policy_status_cancelled,
                },
              },
              {
                heading: "Transaction",
                data: {
                  type: "text",
                  value: data.transaction_type,
                },
              },
              {
                heading: "Days Left",
                data: { type: "text", value: data.days_left },
              },

              {
                heading: "Report Requested",
                data: { type: "text", value: data.report_requested },
              },
              {
                heading: "Year at this Address",
                data: { type: "text", value: data.years_at_this_address },
              },
              {
                heading: "Current Address Type",
                data: { type: "text", value: data.current_address_type },
              },
              {
                heading: "Current Address",
                data: {
                  type: "text",
                  value:
                    data.current_address +
                    ", " +
                    data.current_address_city +
                    ", " +
                    data.current_address_unit_or_suite +
                    ", " +
                    data.current_address_state_or_province +
                    ", " +
                    data.current_address_country +
                    ", " +
                    data.current_address_postal_code,
                },
              },
              {
                heading: "New Address Type",
                data: { type: "text", value: data.new_address_type },
              },
              {
                heading: "New Address",
                data: {
                  type: "text",
                  value:
                    data.new_address +
                    ", " +
                    data.new_address_city +
                    ", " +
                    data.new_address_unit_or_suite +
                    ", " +
                    data.new_address_state_or_province +
                    ", " +
                    data.new_address_country +
                    ", " +
                    data.new_address_postal_code,
                },
              },
              {
                heading: "Request Status",
                data: {
                  type: "text",
                  value: data.request_status,
                },
              },
              {
                heading: "Insurer Code",
                data: {
                  type: "text",
                  value: data.insurer_code,
                },
              },
              {
                heading: "Insurer Code Written",
                data: {
                  type: "text",
                  value: data.insurer_code_written,
                },
              },
              {
                heading: "Branch",
                data: {
                  type: "text",
                  value: data.branch,
                },
              },
              {
                heading: "Policy Number",
                data: {
                  type: "text",
                  value: data.policy_number,
                },
              },
              {
                heading: "Requested By",
                data: {
                  type: "text",
                  value: data.requested_by,
                },
              },
              {
                heading: "Requested By Code",
                data: {
                  type: "text",
                  value: data.requested_by_code,
                },
              },
              {
                heading: "Producer",
                data: {
                  type: "text",
                  value: data.producer,
                },
              },
              {
                heading: "Producer One Code",
                data: {
                  type: "text",
                  value: data.producer_one_code,
                },
              },
              {
                heading: "CSR",
                data: {
                  type: "text",
                  value: data.csr,
                },
              },
              {
                heading: "CSR Code",
                data: {
                  type: "text",
                  value: data.csr_code,
                },
              },
              {
                heading: "Intact Branch",
                data: {
                  type: "text",
                  value: data.intact_branch,
                },
              },
              {
                heading: "Assigned To",
                data: {
                  type: "text",
                  value: data.assigned_to,
                },
              },
              {
                heading: "No. of disclosed Convictions",
                data: {
                  type: "text",
                  value: data.number_of_disclosed_convictions,
                },
              },
              {
                heading: "Date & Time",
                data: {
                  type: "text",
                  value: data.date_and_time,
                },
              },
              {
                heading: "Date Cancelled",
                data: {
                  type: "text",
                  value: formatDate(data.date_cancelled || ""),
                },
              },
              {
                heading: "Date Written",
                data: {
                  type: "text",
                  value: formatDate(data.date_written || ""),
                },
              },
              {
                heading: "Date Voided",
                data: {
                  type: "text",
                  value: formatDate(data.date_voided || ""),
                },
              },
              {
                heading: "Date Destroyed",
                data: {
                  type: "text",
                  value: formatDate(data.date_destroyed || ""),
                },
              },
              {
                heading: "Date Modified",
                data: {
                  type: "text",
                  value: formatDate(data.create_ts || ""),
                },
              },
              {
                heading: "Date Created",
                data: {
                  type: "text",
                  value: formatDate(data.insert_ts || ""),
                },
              },
              {
                heading: "Note",
                data: {
                  type: "text",
                  value: data.notes,
                },
              },
              {
                heading: "Status",
                data: {
                  type: "text",
                  value: data.status,
                },
              },
            ]}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};
