import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { initialSystemGLAccountState } from "./SystemGLAccount.types";
import {
  CLEAR_ACCOUNTING_SYSTEM_GL_ACCOUNT,
  FETCH_ACCOUNTING_SYSTEM_GL_ACCOUNT_FAILURE,
  FETCH_ACCOUNTING_SYSTEM_GL_ACCOUNT_PROGRESS,
  FETCH_ACCOUNTING_SYSTEM_GL_ACCOUNT_SUCCESS,
} from "./SystemGLAccountActions";
import { LoadState } from "../../../constants/enums";
import { SystemGLAccountActions } from ".";

export const AccountingSystemGLAccountReducer = (
  state: IStoreState["accounting"]["systemGLAccount"] = initialSystemGLAccountState,
  action: SystemGLAccountActions
) => {
  switch (action.type) {
    case FETCH_ACCOUNTING_SYSTEM_GL_ACCOUNT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_SYSTEM_GL_ACCOUNT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_SYSTEM_GL_ACCOUNT_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_ACCOUNTING_SYSTEM_GL_ACCOUNT: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.NotLoaded;
        draftState.data = initialSystemGLAccountState.data;
        draftState.error = null;
      });
      return newState;
    }

    default:
      return state;
  }
};
