import { LoadState } from "../../constants/enums";

export interface IAutomobilePolicyAuthorizationState {
  data: IAutomobilePolicyAuthorization[];
  loading: LoadState;
  auth: IAutomobilePolicyAuthorization;
  authLoading: LoadState;
  error: string | null;
}

export interface IAutomobilePolicyAuthorization {
  incremental_number: number | null;
  customer_policy_id: number;
  policy_type: string | null,
  applicant_name: string | null,
  applicant_address: string | null,
  applicant_unit_or_suite: string | null,
  applicant_city: string | null,
  applicant_province_or_state: string | null,
  applicant_postal_code: string | null,
  applicant_contact_number: string | null,
  applicant_preferred_document_language: string | null,
  applicant_email_address: string | null,
  applicant_website_address: string | null,
  brokerage_branch_name: string | null,
  brokerage_full_address: string | null,
  brokerage_postal_code: string | null,
  brokerage_broker_code: string | null,
  brokerage_contact_name: string | null,
  brokerage_phone_number: string | null,
  brokerage_fax_number: string | null,
  brokerage_contract_number: string | null,
  brokerage_sub_contract_number: string | null,
  brokerage_group_or_program_name: string | null,
  brokerage_group_id: string | null,
  brokerage_broker_client_id: string | null,
  brokerage_company_client_id: string | null,
  customer_id: number | null;
  insurance_company_name: string | null;
  insurance_company_address: string | null;
  insurance_company_unit_or_suite: string | null;
  insurance_company_city: string | null;
  insurance_company_province_or_state: string | null;
  insurance_company_postal_code: string | null;
  insurance_company_country: string | null;
  policy_number: string | null;
  request: string | null;
  broker_code: string | null;
  total_estimated_policy_premium: string | null;
  installment_fee: number | null;
  tax_percentage: number | null;
  tax: string | null;
  percentage_finance: number | null;
  finance_charges: string | null;
  total_estimated_cost: string | null;
  mode_of_payment: string | null;
  plan_type: string | null;
  financial_institution_or_bank_name: string | null;
  address_bank: string | null;
  bank_unit_or_suite: string | null;
  bank_city: string | null;
  bank_province_or_state: string | null;
  bank_postal_code: string | null;
  bank_country: string | null;
  account_holder_name: string | null;
  address_account_holder: string | null;
  account_holder_unit_or_suite: string | null;
  account_holder_city: string | null;
  account_holder_province_or_state: string | null;
  account_holder_postal_code: string | null;
  account_holder_country: string | null;
  transit_number: string | null;
  institution_number: string | null;
  account_number: string | null;
  type_of_payment: string | null;
  down_payment_amount: string | null;
  insurance_company_additional_charges: string | null;
  broker_additional_charges: string | null;
  full_payment_amount: string | null;
  installment_amount: string | null;
  next_payment_date: string | null;
  consent_and_disclosure: [
    {
      sentence: string;
      checked: boolean;
    }
  ];
  status: string;
}

export interface IAuthorizationRow {
  customer_policy_id: string;
  insurance_company_name: string | null;
  broker_code: string | null;
  policy_number: string | null;
}

export const defaultAutomobilePolicyAuthorizationState: IAutomobilePolicyAuthorizationState =
  {
    data: [],
    loading: LoadState.NotLoaded,
    auth: {
      customer_policy_id: 0,
      incremental_number: null,
      customer_id: null,
      policy_type: null,
      applicant_name: null,
      applicant_address: null,
      applicant_unit_or_suite: null,
      applicant_city: null,
      applicant_province_or_state: null,
      applicant_postal_code: null,
      applicant_contact_number: null,
      applicant_preferred_document_language: null,
      applicant_email_address: null,
      applicant_website_address: null,
      brokerage_branch_name: null,
      brokerage_full_address: null,
      brokerage_postal_code: null,
      brokerage_broker_code: null,
      brokerage_contact_name: null,
      brokerage_phone_number: null,
      brokerage_fax_number: null,
      brokerage_contract_number: null,
      brokerage_sub_contract_number: null,
      brokerage_group_or_program_name: null,
      brokerage_group_id: null,
      brokerage_broker_client_id: null,
      brokerage_company_client_id: null,
      insurance_company_name: null,
      insurance_company_address: null,
      insurance_company_unit_or_suite: null,
      insurance_company_city: null,
      insurance_company_province_or_state: null,
      insurance_company_postal_code: null,
      insurance_company_country: null,
      policy_number: null,
      request: null,
      broker_code: null,
      total_estimated_policy_premium: null,
      installment_fee: null,
      tax_percentage: null,
      tax: null,
      percentage_finance: null,
      finance_charges: null,
      total_estimated_cost: null,
      mode_of_payment: null,
      plan_type: null,
      financial_institution_or_bank_name: null,
      address_bank: null,
      bank_unit_or_suite: null,
      bank_city: null,
      bank_province_or_state: null,
      bank_postal_code: null,
      bank_country: null,
      account_holder_name: null,
      address_account_holder: null,
      account_holder_unit_or_suite: null,
      account_holder_city: null,
      account_holder_province_or_state: null,
      account_holder_postal_code: null,
      account_holder_country: null,
      transit_number: null,
      institution_number: null,
      account_number: null,
      type_of_payment: null,
      down_payment_amount: null,
      insurance_company_additional_charges: null,
      broker_additional_charges: null,
      full_payment_amount: null,
      installment_amount: null,
      next_payment_date: null,
      consent_and_disclosure: [
        {
          sentence:
            "I/We hereby authorize the named financial institution above to debit my/our account for all payments payable to",
          checked: false,
        },
      ],
      status: "ACTIVE",
    },
    authLoading: LoadState.NotLoaded,
    error: null,
  };
