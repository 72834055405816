import moment from "moment";
import { LoadState } from "../../constants/enums";

export interface IGeneralChangeState {
  generalChange: {
    data: IGeneralChange;
    loading: LoadState;
  };
  addressChange: {
    data: ITaskAddressChange;
    loading: LoadState;
  };
  businessChange: {
    data: ITaskBusinessChange;
    loading: LoadState;
  };
  coverageChange: {
    data: ITaskCoverageChange;
    loading: LoadState;
  };
  policySubmission: {
    data: ITaskPolicySubmission;
    loading: LoadState;
  };
  taskDriver: {
    data: ITaskDriver;
    loading: LoadState;
  };
  garagePlate: {
    data: ITaskGaragePlate;
    loading: LoadState;
  };
  intrestedParty: {
    data: ITaskIntrestedParty;
    loading: LoadState;
  };
  lessor: {
    data: ITaskAddLessor;
    loading: LoadState;
    deleteData: ITaskDeleteLessor;
    deleteLoading: LoadState;
  };
  riskLoaction: {
    data: ITaskAddRiskLocation;
    loading: LoadState;
    deleteData: ITaskDeleteRiskLocation;
    deleteLoading: LoadState;
    changeData: ITaskChangeRiskLocation;
    changeLoading: LoadState;
  };
  vehcile: {
    data: ITaskAddVehicle;
    loading: LoadState;
    deleteData: ITaskDeleteVehicle;
    deleteLoading: LoadState;
  };

  paymentAuthorization: {
    data: ITaskPaymentAuthorization;
    loading: LoadState;
  };
}

export interface IGeneralChange {
  task_code: string;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: number | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  type: string | null;
  effective_date_of_change: string | null;
  effective_time_of_change: string | null;
  details: string | null;
}

export interface ITaskAddressChange {
  task_code: string;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: number | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  address_change: string | null;
  type_of_address: string | null;
  effective_date_of_change: string | null;
  effective_time_of_change: string | null;
  current_address: string | null;
  current_unit_or_suite: string | null;
  current_city: string | null;
  current_province_or_state: string | null;
  current_postal_code: string | null;
  current_country: string | null;
  new_address: string | null;
  new_unit_or_suite: string | null;
  new_city: string | null;
  new_province_or_state: string | null;
  new_postal_code: string | null;
  new_country: string | null;
  reason_of_change_or_details: string | null;
  type: string | null;
}

export interface ITaskBusinessChange {
  task_code: string;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: string | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  type: string | null;
  effective_date_of_change: string | null;
  effective_time_of_change: string | null;
  details: string | null;
  file_upload: string | null;
}

export interface ITaskCoverageChange {
  task_code: string;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: number | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  vehicle: string | null;
  liability: string | null;
  collision: string | null;
  comprehensive: string | null;
  all_perills: string | null;
  specified_perills: string | null;
  endorsement_with_limits: string | null;
  effective_date_of_change: string | null;
  effective_time_of_change: string | null;
  details: string | null;
  file_upload: null;
}

export interface ITaskPolicySubmission {
  task_code: string;
  customer_name: string | null;
  policy_number: string | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  type: string | null;
  effective_date_of_change: string | null;
  effective_time_of_change: string | null;
  details: string | null;
  file_upload: string | null;
}

export interface ITaskDriver {
  task_code: string;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: string | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  driver_name: string | null;
  license_number: string | null;
  license_history: string | null;
  driver_assignment_primary: string | null;
  driver_assignment_secondary: string | null;
  effective_date_of_change: string | null;
  effective_time_of_change: string | null;
  details: string | null;
  file_upload: string | null;
}

export interface ITaskGaragePlate {
  task_code: string;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: string | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  plate_number: string | null;
  plate_type: string | null;
  effective_date_of_change: string | null;
  effective_time_of_change: string | null;
  details: string | null;
  file_upload: string | null;
}

export interface ITaskIntrestedParty {
  task_code: string;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: string | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  interested_party_code: string | null;
  type: string | null;
  name: string | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
  effective_date_of_change: string | null;
  effective_time_of_change: string | null;
  details: string | null;
  file_upload: string | null;
}

export interface ITaskAddLessor {
  task_code: string;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: string | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  interested_party_code: string | null;
  type: string | null;
  name: string | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
  effective_date_of_change: string | null;
  effective_time_of_change: string | null;
  details: string | null;
  file_upload: string | null;
}

export interface ITaskDeleteLessor {
  task_code: string;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: string | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  interested_party_code: string | null;
  effective_date_of_change: string | null;
  effective_time_of_change: string | null;
  details: string | null;
  file_upload: string | null;
}

export interface ITaskAddRiskLocation {
  task_code: string;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: number | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  insurer_code: string | null;
  effective_date_of_change: string | null;
  effective_time_of_change: string | null;
  status: string | null;
  change_details: string | null;
  file_upload: string | null;
}

export interface ITaskDeleteRiskLocation {
  task_code: string;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: number | null;
  line_of_business: string | null;
  risk_location: number | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  interested_party_code: string | null;
  effective_date_of_change: string | null;
  effective_time_of_change: string | null;
  status: string | null;
  change_details: string | null;
  file_upload: string | null;
}

export interface ITaskAddVehicle {
  task_code: string;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: number | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  vehcile: number | null;
  year: string;
  make: string | null;
  model: string | null;
  vin: string;
  date_of_purchase: string | null;
  purchase_condition: string | null;
  kms_at_purchase: string | null;
  list_price_new: string | null;
  horse_power: string | null;
  use_of_vehicle: string | null;
  annual_kms: string | null;
  one_way_commute: string | null;
  business_kms: string | null;
  details: string | null;
  file_upload: string | null;
}

export interface ITaskDeleteVehicle {
  task_code: string;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: number | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  vehicle: number | null;
  effective_date_of_change: string | null;
  effective_time_of_change: string | null;
  details: string | null;
  file_upload: string | null;
}

export interface ITaskChangeRiskLocation {
  task_code: string;
  risk_location: number;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: number | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  insurer_code: string | null;
  effective_date_of_change: string | null;
  effective_time_of_change: string | null;
  status: string | null;
  change_details: string | null;
  file_upload: string | null;
}

export interface ITaskPaymentAuthorization {
  task_code: string;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: string | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  pac_form: string | null;
  void_cheque: string | null;
  cc: string | null;
  cc_expiry: string | null;
  ccv: string | null;
  bank_name: string | null;
  bank_address: string | null;
  bank_unit_or_suite: string | null;
  bank_city: string | null;
  bank_province_or_state: string | null;
  bank_postal_code: string | null;
  bank_country: string | null;
  account_holder_name: string | null;
  account_holder_address: string | null;
  account_holder_unit_or_suite: string | null;
  account_holder_city: string | null;
  account_holder_province_or_city: string | null;
  account_holder_postal_code: string | null;
  account_holder_country: string | null;
  account_holder_transit_number: string | null;
  account_holder_institution_number: string | null;
  account_holder_account_number: string | null;
  effective_date_of_change: string | null;
  effective_time_of_change: string | null;
  details: string | null;
}

export const initialGeneralState: IGeneralChangeState = {
  generalChange: {
    data: {
      task_code: "",
      customer_code: null,
      customer_name: null,
      policy_number: null,
      line_of_business: null,
      policy_type: null,
      branch: null,
      broker_code: null,
      insurer: null,
      producer: null,
      csr: null,
      type: null,
      effective_date_of_change: null,
      effective_time_of_change: moment()
      .startOf("day")
      .add(1, "minute")
      .toString(),
      details: null,
    },
    loading: LoadState.NotLoaded,
  },
  addressChange: {
    data: {
      task_code: "",
      customer_code: null,
      customer_name: null,
      policy_number: null,
      line_of_business: null,
      policy_type: null,
      branch: null,
      broker_code: null,
      insurer: null,
      producer: null,
      csr: null,
      address_change: null,
      type_of_address: null,
      effective_date_of_change: null,
      effective_time_of_change: moment()
      .startOf("day")
      .add(1, "minute")
      .toString(),
      current_address: null,
      new_address: null,
      reason_of_change_or_details: null,
      current_city: "",
      current_country: "",
      current_postal_code: "",
      current_province_or_state: "",
      current_unit_or_suite: "",
      new_city: "",
      new_country:"",
      new_postal_code: "",
      new_province_or_state: "",
      new_unit_or_suite: "",
      type: "",
    },
    loading: LoadState.NotLoaded,
  },
  businessChange: {
    data: {
      task_code: "",
      customer_code: null,
      customer_name: null,
      policy_number: null,
      line_of_business: null,
      policy_type: null,
      branch: null,
      broker_code: null,
      insurer: null,
      producer: null,
      csr: null,
      type: null,
      effective_date_of_change: null,
      effective_time_of_change: moment()
      .startOf("day")
      .add(1, "minute")
      .toString(),
      details: null,
      file_upload: null,
    },
    loading: LoadState.NotLoaded,
  },
  coverageChange: {
    data: {
      task_code: "",
      customer_code: null,
      customer_name: null,
      policy_number: null,
      line_of_business: null,
      policy_type: null,
      branch: null,
      broker_code: null,
      insurer: null,
      producer: null,
      csr: null,
      vehicle: null,
      liability: null,
      collision: null,
      comprehensive: null,
      all_perills: null,
      specified_perills: null,
      endorsement_with_limits: null,
      effective_date_of_change: null,
      effective_time_of_change: moment()
      .startOf("day")
      .add(1, "minute")
      .toString(),
      details: null,
      file_upload: null,
    },
    loading: LoadState.NotLoaded,
  },
  policySubmission: {
    data: {
      task_code: "",
      customer_name: null,
      policy_number: null,
      line_of_business: null,
      policy_type: null,
      branch: null,
      broker_code: null,
      insurer: null,
      producer: null,
      csr: null,
      type: null,
      effective_date_of_change: null,
      effective_time_of_change: moment()
      .startOf("day")
      .add(1, "minute")
      .toString(),
      details: null,
      file_upload: null,
    },
    loading: LoadState.NotLoaded,
  },
  garagePlate: {
    data: {
      task_code: "",
      customer_code: null,
      customer_name: null,
      policy_number: null,
      line_of_business: null,
      policy_type: null,
      branch: null,
      broker_code: null,
      insurer: null,
      producer: null,
      csr: null,
      plate_number: null,
      plate_type: null,
      effective_date_of_change: null,
      effective_time_of_change: moment()
      .startOf("day")
      .add(1, "minute")
      .toString(),
      details: null,
      file_upload: null,
    },
    loading: LoadState.NotLoaded,
  },
  intrestedParty: {
    data: {
      task_code: "",
      customer_code: null,
      customer_name: null,
      policy_number: null,
      line_of_business: null,
      policy_type: null,
      branch: null,
      broker_code: null,
      insurer: null,
      producer: null,
      csr: null,
      interested_party_code: null,
      type: null,
      name: null,
      address: null,
      unit_or_suite: null,
      city: null,
      province_or_state: null,
      postal_code: null,
      country: null,
      effective_date_of_change: null,
      effective_time_of_change: moment()
      .startOf("day")
      .add(1, "minute")
      .toString(),
      details: null,
      file_upload: null,
    },
    loading: LoadState.NotLoaded,
  },
  lessor: {
    data: {
      task_code: "",
      customer_code: null,
      customer_name: null,
      policy_number: null,
      line_of_business: null,
      policy_type: null,
      branch: null,
      broker_code: null,
      insurer: null,
      producer: null,
      csr: null,
      interested_party_code: null,
      type: null,
      name: null,
      address: null,
      unit_or_suite: null,
      city: null,
      province_or_state: null,
      postal_code: null,
      country: null,
      effective_date_of_change: null,
      effective_time_of_change: moment()
      .startOf("day")
      .add(1, "minute")
      .toString(),
      details: null,
      file_upload: null,
    },
    loading: LoadState.NotLoaded,
    deleteData: {
      task_code: "",
      customer_code: null,
      customer_name: null,
      policy_number: null,
      line_of_business: null,
      policy_type: null,
      branch: null,
      broker_code: null,
      insurer: null,
      producer: null,
      csr: null,
      interested_party_code: null,
      effective_date_of_change: null,
      effective_time_of_change: moment()
      .startOf("day")
      .add(1, "minute")
      .toString(),
      details: null,
      file_upload: null,
    },
    deleteLoading: LoadState.NotLoaded,
  },
  paymentAuthorization: {
    data: {
      task_code: "string",
      customer_code: null,
      customer_name: null,
      policy_number: null,
      line_of_business: null,
      policy_type: null,
      branch: null,
      broker_code: null,
      insurer: null,
      producer: null,
      csr: null,
      pac_form: null,
      void_cheque: null,
      cc: null,
      cc_expiry: null,
      ccv: null,
      bank_name: null,
      bank_address: null,
      bank_unit_or_suite: null,
      bank_city: null,
      bank_province_or_state: null,
      bank_postal_code: null,
      bank_country: null,
      account_holder_name: null,
      account_holder_address: null,
      account_holder_unit_or_suite: null,
      account_holder_city: null,
      account_holder_province_or_city: null,
      account_holder_postal_code: null,
      account_holder_country: null,
      account_holder_transit_number: null,
      account_holder_institution_number: null,
      account_holder_account_number: null,
      effective_date_of_change: null,
      effective_time_of_change: moment()
      .startOf("day")
      .add(1, "minute")
      .toString(),
      details: null,
    },
    loading: LoadState.NotLoaded,
  },
  riskLoaction: {
    data: {
      task_code: "",
      customer_code: null,
      customer_name: null,
      policy_number: null,
      line_of_business: null,
      policy_type: null,
      branch: null,
      broker_code: null,
      insurer: null,
      producer: null,
      csr: null,
      insurer_code: null,
      effective_date_of_change: null,
      effective_time_of_change: moment()
      .startOf("day")
      .add(1, "minute")
      .toString(),
      status: null,
      change_details: null,
      file_upload: null,
    },
    loading: LoadState.NotLoaded,
    deleteData: {
      task_code: "",
      customer_code: null,
      customer_name: null,
      policy_number: null,
      risk_location: null,
      line_of_business: null,
      policy_type: null,
      branch: null,
      broker_code: null,
      insurer: null,
      producer: null,
      csr: null,
      interested_party_code: null,
      effective_date_of_change: null,
      effective_time_of_change: moment()
      .startOf("day")
      .add(1, "minute")
      .toString(),
      status: null,
      change_details: null,
      file_upload: null,
    },

    deleteLoading: LoadState.NotLoaded,
    changeData: {
      task_code: "",
      risk_location: 0,
      customer_code: null,
      customer_name: null,
      policy_number: null,
      line_of_business: null,
      policy_type: null,
      branch: null,
      broker_code: null,
      insurer: null,
      producer: null,
      csr: null,
      insurer_code: null,
      effective_date_of_change: null,
      effective_time_of_change: moment()
      .startOf("day")
      .add(1, "minute")
      .toString(),
      status: null,
      change_details: null,
      file_upload: null,
    },
    changeLoading: LoadState.NotLoaded,
  },
  taskDriver: {
    data: {
      task_code: "",
      customer_code: null,
      customer_name: null,
      policy_number: null,
      line_of_business: null,
      policy_type: null,
      branch: null,
      broker_code: null,
      insurer: null,
      producer: null,
      csr: null,
      driver_name: null,
      license_number: null,
      license_history: null,
      driver_assignment_primary: null,
      driver_assignment_secondary: null,
      effective_date_of_change: null,
      effective_time_of_change: moment()
      .startOf("day")
      .add(1, "minute")
      .toString(),
      details: null,
      file_upload: null,
    },
    loading: LoadState.NotLoaded,
  },
  vehcile: {
    data: {
      task_code: "",
      customer_code: null,
      customer_name: null,
      policy_number: null,
      line_of_business: null,
      policy_type: null,
      branch: null,
      broker_code: null,
      vehcile: null,
      insurer: null,
      producer: null,
      csr: null,
      year: "",
      make: null,
      model: null,
      vin: "",
      date_of_purchase: null,
      purchase_condition: null,
      kms_at_purchase: null,
      list_price_new: null,
      horse_power: null,
      use_of_vehicle: null,
      annual_kms: null,
      one_way_commute: null,
      business_kms: null,
      details: null,
      file_upload: null,
    },
    loading: LoadState.NotLoaded,
    deleteData: {
      task_code: "",
      customer_code: null,
      customer_name: null,
      policy_number: null,
      line_of_business: null,
      policy_type: null,
      branch: null,
      broker_code: null,
      insurer: null,
      producer: null,
      csr: null,
      vehicle: null,
      effective_date_of_change: null,
      effective_time_of_change: moment()
      .startOf("day")
      .add(1, "minute")
      .toString(),
      details: null,
      file_upload: null,
    },
    deleteLoading: LoadState.NotLoaded,
  },
};
