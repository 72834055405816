import Avatar from "@mui/material/Avatar";
import React from "react";
import { api } from "../../../api/api";
import { StandadCard } from "../../../components/Cards";
import { PageContainer } from "../../../components/container/PageContainer";
import { DataTable } from "../../../components/Table/DataTable";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../components/Table/interfaces/IDataTableProps";
import {
  CSVExportPlugin,
  RefreshPlugin,
  SearchPlugin,
} from "../../../components/Table/plugins";
import { IBreadcrumbProps } from "../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useNavigate } from "react-router-dom";
import { useDispatchWrapper, useRecordCounts } from "../../../hooks";
import { useDateFilter } from "../../../components/Table/hooks/useDateFilter";
import { CustomDatePicker } from "../../../components/formsComponents/CustomDatePicker";
import { DateFilterDropdown } from "../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { CustomSelect } from "../../../components/formsComponents";
import { capitalizeWords, createTabsWithRecordcounts, renderDropdownValue } from "../../../helpers";
import { INoPolicyInsuranceReports } from "./insuranceReport.types";
import { IconicMultiColumnCard } from "../../../components/iconicMultiColumnCard/IconicMultiColumnCard";
import { StandardTableActions } from "../../../components/StandardTableActions/StandardTableActions";
import { IInsuranceReports } from "../../../redux/insuranceReports/insuranceReports.types";
import { LoadState, MODULE_IDS } from "../../../constants/enums";
import { MenuItem } from "@mui/material";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../../redux/common/commonActions";
import { RoleBasedCSVExportPlugin } from "../../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { InsuranceReportDialog } from "./InsuranceReportsDialog/InsuranceReportsDialog";
import { TASK_STATUS, task_status } from "../../../constants/constants";
import { ViewOldInsuranceReport } from "./ViewOldInsuranceReport/ViewOldInsuranceReport";
import { ITableTab } from "../../../components/Table/components/TableTabs/TableTabs.types";
import { PageTitleBar } from "../../../components/PageTitleBar/PageTitleBar";

export interface IOldInsuranceReportsList {
  customer_policy_id: string | null;
  incremental_number: string | null;
  customer_code: string | null;
  customer_name: string | null;
  driver_number: string | null;
  driver_name: string | null;
  license_number: string | null;
  business_reason: string | null;
  date_and_time: string | null;
  days_left: string | null;
  transaction_type: string | null;
  report_requested: string | null;
  years_at_this_address: string | null;
  current_address_type: string | null;
  current_address: string | null;
  current_address_unit_or_suite: string | null;
  current_address_city: string | null;
  current_address_state_or_province: string | null;
  current_address_postal_code: string | null;
  current_address_country: string | null;
  new_address_type: string | null;
  new_address: string | null;
  new_address_unit_or_suite: string | null;
  new_address_city: string | null;
  new_address_state_or_province: string | null;
  new_address_postal_code: string | null;
  new_address_country: string | null;
  request_status: string | null;
  insurer_code: string | null;
  intact_branch: string | null;
  number_of_disclosed_convictions: string | null;
  policy_number: string | null;
  policy_status_cancelled: string | null;
  requested_by: string | null;
  requested_by_code: string | null;
  assigned_to: string | null;
  branch: string | null;
  producer: string | null;
  csr: string | null;
  notes: string | null;
  file_upload: string | null;
  file_upload_two: string | null;
  file_type: string | null;
  file_type_two: string | null;
  date_written: string | null;
  date_destroyed: string | null;
  date_voided: string | null;
  date_cancelled: string | null;
  producer_one_code: string | null;
  csr_code: string | null;
  insurer_code_written: string | null;
  status: string;
  create_ts: string;
  insert_ts: string;
}

export const OldInsuranceReportsList: React.FC = (props) => {
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      title: "insurance-reports",
    },
  ];

  const [state, setState] = React.useState({
    totalRecord: 0,
    loading: LoadState.NotLoaded,
    list: [],
  });

  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });
  const [selectedOpp, setSelectedOppr] = React.useState<{
    isAllSelected: boolean;
    leads: IInsuranceReports[];
  }>({
    isAllSelected: false,
    leads: [],
  });
  const [status, setStatus] = React.useState<
    IInsuranceReports["status"] | "-1"
  >("-1");
  const [tableTabs, setTableTabs] = React.useState<ITableTab[]>([]);

  const [openView, setOpenView] =
    React.useState<INoPolicyInsuranceReports | null>(null);
  const [viewOldInsuranceReport, setViewOldInsuranceReport] =
    React.useState<IOldInsuranceReportsList | null>(null);

  const [searchType, setSearchType] = React.useState<string[]>(["driver_name"]);
  const [search, setSearch] = React.useState<string>("");
  const [openWarning, setOpenWarning] = React.useState<boolean>(false);

  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "lastWeek" });

  const dispatch = useDispatchWrapper();

  const fetchList = async () => {
    setState({ ...state, loading: LoadState.InProgress });
    try {
      let finalUrl = `/other/get-old-insurance-reports?pageNo=${tablePagination.pageNumber}&itemPerPage=${tablePagination.rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;

      if (searchType.length > 0 && search) {
        finalUrl = finalUrl + `&column=${[searchType]}&value=${search}`;
      }
      if (status !== "-1") {
        finalUrl = finalUrl + `&status=${status}`;
      }

      const res = await api.get(finalUrl);
      const data: IOldInsuranceReportsList[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      setState({
        ...state,
        list: data as any,
        totalRecord: totalRecords,
        loading: LoadState.Loaded,
      });
    } catch (err: any) {
      console.log("err");
      setState({ ...state, loading: LoadState.Failed });
    }
  };

  const handleComplete = () => {
    fetchList();
    setOpenView(null);
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagination, date, status, search, searchType]);

  const [dropdownList, setDropdownList] = React.useState<any>([]);

  const menuItems =
    selectedOpp.leads.length > 0 || selectedOpp.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const opportunitesTableProps: IDataTableProps = {
    isPagination: true,
    totalRecords: state.totalRecord,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    isDataLoading: state.loading === LoadState.InProgress,
    selectionMode: "multiple",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          ...menuItems,
      
          {
            key: "byDate",
            onRender: () => (
              <DateFilterDropdown
                value={type}
                options={dateDropdownList}
                onChange={handleDateSelectChange}
              />
            ),
          },
          {
            key: "fromDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.fromDate}
                  onChange={(newValue) => {
                    setDate({ ...date, fromDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "toDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.toDate}
                  onChange={(newValue) => {
                    setDate({ ...date, toDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "csvExport",
            onRender: (columns, items) => (
              <RoleBasedCSVExportPlugin
                columns={columns}
                items={items}
                moduleId={MODULE_IDS.ALL_TASK}
                filePrefixName="Report"
              />
            ),
          },
          {
            key: "refresh",
            onRender: () => <RefreshPlugin onClick={fetchList} />,
          },
        ],
      },
      rightItems: {
        plugins: {
          // searchField: {
          //   searchKeys: ["opportunity_id", "customer_id", "lead_id", "status"],
          //   items: list,
          // },
        },
        customPlugins: [
          {
            key: "search",
            onRender: () => {
              return (
                <SearchPlugin
                  selectedDropdownValue={searchType}
                  dropdownOptions={[
                    { label: "Driver Name", value: "driver_name" },
                    { label: "Policy Number", value: "policy_number" },
                    { label: "Task Name", value: "task_name" },
                    { label: "Task Code", value: "task_code" },
                    { label: "Task Type", value: "task_type" },
                    { label: "Task Sub-Type", value: "task_sub_type" },
                    { label: "Task Priority", value: "task_priority" },
                    { label: "Assigned To", value: "first_name" },
                  ]}
                  onDropdownChange={(value) => setSearchType(value)}
                  onChange={(newValue) => {
                    setSearch(newValue);
                  }}
                />
              );
            },
          },
        ],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "task_status",
        headerName: "Status",
        fieldName: "status",
        exportCellWidth: 25,
        renderType: RenderType.CHIP_SUCCESS,
      },
      {
        key: "customer_code",
        headerName: "Customer Code",
        fieldName: "customer_code",
        exportCellWidth: 25,
        renderType: RenderType.TEXT_DARK,
        // onRowCellRender: (value, row: ITask) => {
        //   return <LeadOppStatus status={row.task_status} />;
        // },
      },
      {
        key: "driver_name",
        headerName: "Driver Name",
        fieldName: "driver_name",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        exportCellWidth: 25,
        renderType: RenderType.TEXT_DARK,
      },
      {
        key: "notes",
        headerName: "Notes",
        fieldName: "notes",
        exportCellWidth: 25,
        renderType: RenderType.TEXT_DARK,
      },
      {
        key: "producer_one_code",
        headerName: "Producer Code",
        fieldName: "producer_one_code",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },

      {
        key: "insurer_code",
        headerName: "Insurer Code",
        fieldName: "insurer_code",
        renderType: RenderType.TEXT,
      },
      {
        key: "report_requested",
        headerName: "Report Requested",
        fieldName: "report_requested",
        renderType: RenderType.TEXT,
      },
      {
        key: "create_ts",
        headerName: "Due Date",
        fieldName: "create_ts",
        renderType: RenderType.DATE,
      },
      {
        key: "request_status",
        headerName: "Request Status",
        fieldName: "request_status",
        renderType: RenderType.TEXT,
      },
      {
        key: "transaction_type",
        headerName: "Transaction Type",
        fieldName: "transaction_type",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "insert_ts",
        headerName: "Date Modified",
        fieldName: "insert_ts",
        renderType: RenderType.DATE_TIME_DARK_COLOR,
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IOldInsuranceReportsList) => {
          return (
            <StandardTableActions
              onViewClick={() => {
                setViewOldInsuranceReport(row);
              }}
            />
          );
        },
      },
    ],
    items: state.list,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
    tableTabProps: {
      selectedTab: status,
      tabs: tableTabs,
   onTabChange:(newSelectedTab) =>{
          setStatus(newSelectedTab);
        },
  
    },
  
  };
  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();


  React.useEffect(()=>{
    const tabsData = createTabsWithRecordcounts(TASK_STATUS,recordsCounts);
    setTableTabs(tabsData);
  },[recordsCounts])


  React.useEffect(() => {
    dispatch(
      fetchRecordCountsAsync(
        "latest_task_driver_insurance_report",
        "status",
        date.fromDate,
        date.toDate
      )
    );
    return () => {
      dispatch(clearRecordCounts());
    };
  }, [date.fromDate, date.toDate]);

  const handlePageChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setTablePagination({ ...tablePagination, pageNumber: newPage + 1 });
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const rowsPerPage = parseInt(event.target.value, 10);

    setTablePagination({
      ...tablePagination,
      rowsInPerPage:
        rowsPerPage === -1
          ? state.totalRecord || state.list.length
          : rowsPerPage,
    });
  };

  React.useEffect(() => {
    setTimeout(() => {
      setOpenWarning(true);
    }, 1000);
  }, []);

  return (
    <PageContainer
      title="View Insurance Report"
      description="this is innerpage"
    >
      {/* <Breadcrumb title="View Opportunites" items={BCrumb} /> */}

     
      <PageTitleBar heading="Old Insurance Reports"/>
        <DataTable {...opportunitesTableProps} />
   

      {openView && (
        <InsuranceReportDialog
          open={true}
          onClose={() => setOpenView(null)}
          data={openView}
          onComplete={handleComplete}
        />
      )}
      {viewOldInsuranceReport && (
        <ViewOldInsuranceReport
          open={true}
          onClose={() => setViewOldInsuranceReport(null)}
          data={viewOldInsuranceReport}
        />
      )}

    </PageContainer>
  );
};
