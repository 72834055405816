import moment from "moment";
import { LoadState } from "../../../constants/enums";
import { IFileUpload } from "../../../components/FileUpload/FileUpload.type";

export interface IBusinessPolicyMarketingBinderPdf {
  isBinderPdf: boolean;
  isBinderPreview: boolean;
  isMarketSubmissionPreview: boolean;
  customer_policy_id: number;
  customer_id?: number;
  generalInformation: boolean;
  priorPolicy: boolean;
  underwriting: boolean;
  claims: boolean;
  riskLocation: boolean;
  coverageSummary: boolean;
  equipment: boolean;
  toolFloater: boolean;
  fineArt: boolean;
  restaurantSupplement: boolean;
  contractorSupplement: boolean;
  attachment: boolean;
}

export interface IBusinessPolicyMarketingState {
  attachmentsHistory: IBusinessPolicyMatketingAttachment[];
  attachmentLoading: LoadState;
  marketingHistory: {
    list: IBusinessPolicyMarketingHistory[];
    loading: LoadState;
    marketingHistory: IBusinessPolicyMarketingHistory;
    marketingHistoryLoading: LoadState;
    error: string | null;
  };
  binderHistory: {
    list: IBusinessBinder[];
    loading: LoadState;
  };
}

export interface IBusinessBinder {
  attachment: string;
  created_by_id: string;
  customer_policy_id: number;
  status: string;
}

export interface IBusinessPolicyMarketingHistory {
  customer_policy_id: number;
  quote_number: number | null;
  serial_number: number | null;
  customer_name: string | null;
  quote_status: string | null;
  premium: string | null;
  submission_date: string | null;
  effective_date: string | null;
  date_received: string | null;
  insurer: string | null;
  underwriter: string | null;
  attachment: IFileUpload[];
  notes: string | null;
  status: string;
}

export interface IBusinessPolicyMatketingAttachment {
  policy_business_add_marketing_history_id: number;
  customer_policy_id: number;
  serial_number: number;
  customer_name: string | null;
  quote_number: string | null;
  quote_status: string | null;
  premium: string | null;
  submission_date: string | null;
  date_received: string | null;
  insurer: string | null;
  underwriter: string | null;
  attachment: string;
  notes: string | null;
  status: string;
  created_by_id: number;
  insert_ts: string;
}

export const initialBusinessPolicyMarketingHistory: IBusinessPolicyMarketingHistory =
  {
    customer_policy_id: 0,
    quote_number: 0,
    serial_number: null,
    customer_name: null,
    quote_status: null,
    premium: null,
    submission_date: moment().format("YYYY-MM-DD"),
    effective_date: null,
    date_received: null,
    insurer: null,
    underwriter: null,
    attachment: [],
    notes: null,
    status: "ACTIVE",
  };

export const initialBusinessPolicyMarketing: IBusinessPolicyMarketingState = {
  attachmentsHistory: [],
  attachmentLoading: LoadState.NotLoaded,
  marketingHistory: {
    list: [],
    loading: LoadState.NotLoaded,
    marketingHistory: initialBusinessPolicyMarketingHistory,
    marketingHistoryLoading: LoadState.NotLoaded,
    error: null,
  },
  binderHistory: {
    list: [],
    loading: LoadState.NotLoaded
  }
};
