import { Refresh } from "@mui/icons-material";
import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { IDataTableV2RefreshPlugin } from "./DataTableV2Refresh.types";

export const DatatableV2Refresh: React.FC<IDataTableV2RefreshPlugin> = (
  props
) => {
  const { onClick } = props;

  return (
   <Box width={'fit-content'}>
     <Tooltip title="Refresh Data">
      <Button variant="text" onClick={onClick}>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Refresh color="primary" sx={{ fontSize: 25 }} />
          <Typography variant="body1" fontSize={'1.2rem'} fontWeight={600} color="primary.main">
            Refresh
          </Typography>{" "}
        </Stack>
      </Button>
    </Tooltip>
   </Box>
  );
};
