import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { DriverAction } from ".";
import { initialAutomoblieDriverInformation } from "./automobileDriver.types";
import {
  CLEAR_AUTOMOBILE_DRIVER_STATE,
  FETCH_AUTOMOBILE_DRIVER_CLAIM_FAILED,
  FETCH_AUTOMOBILE_DRIVER_CLAIM_LIST_FAILED,
  FETCH_AUTOMOBILE_DRIVER_CLAIM_LIST_PROGRESS,
  FETCH_AUTOMOBILE_DRIVER_CLAIM_LIST_SUCCESS,
  FETCH_AUTOMOBILE_DRIVER_CLAIM_PROGRESS,
  FETCH_AUTOMOBILE_DRIVER_CLAIM_SUCCESS,
  FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_FAILED,
  FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_LIST_PROGRESS,
  FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_LIST_SUCCESS,
  FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_PROGRESS,
  FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_SUCCESS,
  FETCH_AUTOMOBILE_DRIVER_INFORMATION_FAILED,
  FETCH_AUTOMOBILE_DRIVER_INFORMATION_LIST_PROGRESS,
  FETCH_AUTOMOBILE_DRIVER_INFORMATION_LIST_SUCCESS,
  FETCH_AUTOMOBILE_DRIVER_INFORMATION_PROGRESS,
  FETCH_AUTOMOBILE_DRIVER_INFORMATION_SUCCESS,
  FETCH_AUTOMOBILE_DRIVER_LIST_CONVICTIONS_FAILED,
  FETCH_AUTOMOBILE_DRIVER_LIST_INFORMATION_FAILED,
  FETCH_AUTOMOBILE_DRIVER_TASKS_FAILED,
  FETCH_AUTOMOBILE_DRIVER_TASKS_LIST_CONVICTIONS_FAILED,
  FETCH_AUTOMOBILE_DRIVER_TASKS_LIST_PROGRESS,
  FETCH_AUTOMOBILE_DRIVER_TASKS_LIST_SUCCESS,
  FETCH_AUTOMOBILE_DRIVER_TASKS_PROGRESS,
  FETCH_AUTOMOBILE_DRIVER_TASKS_SUCCESS,
  CLEAR_AUTOMOBILE_DRIVER_TASKS,
  CLEAR_AUTOMOBILE_DRIVER_CLAIM_INFO,
  CLEAR_AUTOMOBILE_DRIVER_INFO,
  CLEAR_AUTOMOBILE_DRIVER_CONVICTIONS_INFO,
} from "./automobileDriverActions";

export const AutomobileDriverReducer = (
  state: IStoreState["automobile"]["driver"] = initialAutomoblieDriverInformation,
  action: DriverAction
) => {
  switch (action.type) {
    case FETCH_AUTOMOBILE_DRIVER_INFORMATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.driverInformation.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_DRIVER_INFORMATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.driverInformation.loading = LoadState.Loaded;
        draftState.driverInformation.data = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_DRIVER_INFORMATION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.driverInformation.loading = LoadState.Failed;
        draftState.driverInformation.error = errorMessage;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_DRIVER_INFORMATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.driverInformation.driversListLoading = LoadState.InProgress;
        draftState.driverInformation.driversList = [];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_DRIVER_INFORMATION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.driverInformation.driversListLoading = LoadState.Loaded;
        draftState.driverInformation.driversList = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_DRIVER_LIST_INFORMATION_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.driverInformation.driversListLoading = LoadState.Failed;
        draftState.driverInformation.driversList = [];
      });
      return newState;
    }

    case FETCH_AUTOMOBILE_DRIVER_CLAIM_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.driverClaims.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_DRIVER_CLAIM_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.driverClaims.loading = LoadState.Loaded;
        draftState.driverClaims.data = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_DRIVER_CLAIM_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.driverClaims.loading = LoadState.Failed;
        draftState.driverClaims.error = errorMessage;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_DRIVER_CLAIM_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.driverClaims.driverClaimsListLoading = LoadState.InProgress;
        draftState.driverClaims.driverClaimsList = [];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_DRIVER_CLAIM_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.driverClaims.driverClaimsListLoading = LoadState.Loaded;
        draftState.driverClaims.driverClaimsList = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_DRIVER_CLAIM_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.driverClaims.driverClaimsListLoading = LoadState.Failed;
        draftState.driverClaims.driverClaimsList = [];
      });
      return newState;
    }

    case FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.driverConviction.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.driverConviction.loading = LoadState.Loaded;
        draftState.driverConviction.data = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.driverConviction.loading = LoadState.Failed;
        draftState.driverConviction.error = errorMessage;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.driverConviction.driverConvisctionListLoading =
          LoadState.InProgress;
        draftState.driverConviction.driverConvictionList = [];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_DRIVER_CONVICTIONS_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.driverConviction.driverConvisctionListLoading =
          LoadState.Loaded;
        draftState.driverConviction.driverConvictionList = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_DRIVER_LIST_CONVICTIONS_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.driverConviction.driverConvisctionListLoading =
          LoadState.Failed;
        draftState.driverConviction.driverConvictionList = [];
      });
      return newState;
    }

    /**-------------- */
    case FETCH_AUTOMOBILE_DRIVER_TASKS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.driverActivity.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_DRIVER_TASKS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.driverActivity.loading = LoadState.Loaded;
        draftState.driverActivity.data = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_DRIVER_TASKS_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.driverActivity.loading = LoadState.Failed;
        draftState.driverActivity.error = errorMessage;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_DRIVER_TASKS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.driverActivity.driverActivityListLoading =
          LoadState.InProgress;
        draftState.driverActivity.driverActivityList = [];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_DRIVER_TASKS_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.driverActivity.driverActivityListLoading = LoadState.Loaded;
        draftState.driverActivity.driverActivityList = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_DRIVER_TASKS_LIST_CONVICTIONS_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.driverActivity.driverActivityListLoading = LoadState.Failed;
        draftState.driverActivity.driverActivityList = [];
      });
      return newState;
    }

    case CLEAR_AUTOMOBILE_DRIVER_TASKS: {
      const newState = produce(state, (draftState) => {
        draftState.driverActivity.loading = LoadState.NotLoaded;
        draftState.driverActivity.data =
          initialAutomoblieDriverInformation["driverActivity"]["data"];
      });
      return newState;
    }

    case CLEAR_AUTOMOBILE_DRIVER_INFO: {
      const newState = produce(state, (draftState) => {
        draftState.driverInformation.loading = LoadState.NotLoaded;
        draftState.driverInformation.data =
          initialAutomoblieDriverInformation["driverInformation"]["data"];
      })
      return newState;
    }

    case CLEAR_AUTOMOBILE_DRIVER_CLAIM_INFO: {
      const newState = produce(state, (draftState) => {
        draftState.driverClaims.loading = LoadState.NotLoaded;
        draftState.driverClaims.data =
          initialAutomoblieDriverInformation["driverClaims"]["data"];
      })
      return newState;
    }

    case CLEAR_AUTOMOBILE_DRIVER_CONVICTIONS_INFO: {
      const newState = produce(state, (draftState) => {
        draftState.driverConviction.loading = LoadState.NotLoaded;
        draftState.driverConviction.data =
          initialAutomoblieDriverInformation["driverConviction"]["data"];
      })
      return newState;
    }

    default: {
      return state;
    }
  }
};
