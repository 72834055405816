import { action } from "typesafe-actions";
import { IEmailStatistics, IEmailStatisticsGetApiResponse } from "./emailStatistics.types";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import { api, sendGrigApi } from "../../api/api";

export const FETCH_EMAIL_STATISTICS_LIST_PROGRESS =
  "FETCH_EMAIL_STATISTICS_LIST_PROGRESS";
export const FETCH_EMAIL_STATISTICS_LIST_SUCCESS =
  "FETCH_EMAIL_STATISTICS_LIST_SUCCESS";
export const FETCH_EMAIL_STATISTICS_LIST_FAILED =
  "FETCH_EMAIL_STATISTICS_LIST_FAILED";

export const fetchEmailStatisticsListProgress = () =>
  action(FETCH_EMAIL_STATISTICS_LIST_PROGRESS);
export const fetchEmailStatisticsListSuccess = (data: IEmailStatistics[]) =>
  action(FETCH_EMAIL_STATISTICS_LIST_SUCCESS, { data });
export const fetchEmailStatisticsListFailed = () =>
  action(FETCH_EMAIL_STATISTICS_LIST_FAILED);

export const fetchEmailStatisticsListAsync =
  (
    aggregated_by: string,
    date: IDate,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      dispatch(fetchEmailStatisticsListProgress());

      const res = await api.get(`/general/get-email-statistics?start_date=${date.fromDate}&end_date=${date.toDate}&aggregated_by=${aggregated_by}`);
      const data: IEmailStatisticsGetApiResponse = res.data.data[0];

      let finalData: IEmailStatistics[] = [];
      for(const item of data.body){
        finalData.push({...item.stats[0].metrics, date: item.date})
      }

      dispatch(fetchEmailStatisticsListSuccess(finalData));
    } catch (err: any) {
      dispatch(fetchEmailStatisticsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted());
    }
  };


  export const CLEAR_EMAIL_STATISTICS =
  "CLEAR_EMAIL_STATISTICS";

  export const clearEmailStatistics = () => action(CLEAR_EMAIL_STATISTICS);