import moment from "moment";
import { LoadState } from "../../constants/enums";

export interface ITaskUrgentTransactionsState {
  loa: {
    data: ITaskLooBor;
    loading: LoadState;
  };
  policyCancellation: {
    data: ITaskPolicyCancellation;
    loading: LoadState;
  };
  policyReinstment: {
    data: ITaskPolicyReinstment;
    loading: LoadState;
  };
}

export interface ITaskLooBor {
  task_code: string;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: number | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  lao_or_bor: string | null;
  letter_of_experience: string | null;
  signed_change_form: string | null;
  effective_date_of_change: string | null;
  effective_time_of_change: string | null;
  details: string | null;
  file_upload: string | null;
}

export interface ITaskPolicyCancellation {
  task_code: string;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: number | null;
  bill_type: string | null; 
  pc_type: string | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  date_of_cancellation: string | null;
  time_of_cancellation: string | null;
  requested_by_insured: string | null;
  rewritten: string | null;
  other: string | null;
  effective_date: string | null;
  expiration_date: string | null;
  insurance_company: string | null;
  flat: string | null;
  short_rate: string | null;
  prorata: string | null;
  premium_paid_to_broker: string | null;
  remarks: string | null;
  insured: string | null;
  mortgagee: string | null;
  lienholder: string | null;
  finance_company: string | null;
  loss_payee: string | null;
  lessor: string | null;
  releases_are_not_attached: string | null;
  file_upload: string | null;
}

export interface ITaskPolicyReinstment {
  task_code: string;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: number | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  reinstatement_date: string | null;
  reinstatement_time: string | null;
  am: string | null;
  pm: string | null;
  full: string | null;
  certified_cheque: string | null;
  partial: string | null;
  balance_owing: string | null;
  reason_for_reinstatement: string | null;
  file_upload: string | null;
}

export const initialTaskurgentTransactionsState: ITaskUrgentTransactionsState =
  {
    loa: {
      data: {
        task_code: "",
        customer_code: null,
        customer_name: null,
        policy_number: null,
        line_of_business: null,
        policy_type: null,
        branch: null,
        broker_code: null,
        insurer: null,
        producer: null,
        csr: null,
        lao_or_bor: null,
        letter_of_experience: null,
        signed_change_form: null,
        effective_date_of_change: null,
        effective_time_of_change: moment()
        .startOf("day")
        .add(1, "minute")
        .toString(),
        details: null,
        file_upload: null,
      },
      loading: LoadState.NotLoaded,
    },
    policyCancellation: {
      data: {
        task_code: "string",
        customer_code: null,
        customer_name: null,
        policy_number: null,
        bill_type: null,
        pc_type: null,
        line_of_business: null,
        policy_type: null,
        branch: null,
        broker_code: null,
        insurer: null,
        producer: null,
        csr: null,
        date_of_cancellation: null,
        time_of_cancellation: null,
        requested_by_insured: null,
        rewritten: null,
        other: null,
        effective_date: null,
        expiration_date: null,
        insurance_company: null,
        flat: null,
        short_rate: null,
        prorata: null,
        premium_paid_to_broker: null,
        remarks: null,
        insured: null,
        mortgagee: null,
        lienholder: null,
        finance_company: null,
        loss_payee: null,
        lessor: null,
        releases_are_not_attached: null,
        file_upload: null,
      },
      loading: LoadState.NotLoaded,
    },
    policyReinstment: {
      data: {
        task_code: "string",
        customer_code: null,
        customer_name: null,
        policy_number: null,
        line_of_business: null,
        policy_type: null,
        branch: null,
        broker_code: null,
        insurer: null,
        producer: null,
        csr: null,
        reinstatement_date: null,
        reinstatement_time: null,
        am: null,
        pm: null,
        full: null,
        certified_cheque: null,
        partial: null,
        balance_owing: null,
        reason_for_reinstatement: null,
        file_upload: null,
      },
      loading: LoadState.NotLoaded,
    },
  };
