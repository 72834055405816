import { action } from "typesafe-actions";
import { IBusinessPolicyRiskLocation } from "./businessPolicyRiskLocation.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { api } from "../../../api/api";

export const FETCH_BUSINESS_POLICY_RISK_LOCATION_LIST_PROGRESS =
  "FETCH_BUSINESS_POLICY_RISK_LOCATION_LIST_PROGRESS";
export const FETCH_BUSINESS_POLICY_RISK_LOCATION_LIST_SUCCESS =
  "FETCH_BUSINESS_POLICY_RISK_LOCATION_LIST_SUCCESS";
export const FETCH_BUSINESS_POLICY_RISK_LOCATION_LIST_FAILED =
  "FETCH_BUSINESS_POLICY_RISK_LOCATION_LIST_FAILED";

export const fetchBusinessPolicyRiskListProgress = () =>
  action(FETCH_BUSINESS_POLICY_RISK_LOCATION_LIST_PROGRESS);
export const fetchBusinessPolicyRiskListSuccess = (
  data: IBusinessPolicyRiskLocation[]
) => action(FETCH_BUSINESS_POLICY_RISK_LOCATION_LIST_SUCCESS, { data });
export const fetchBusinessPolicyRiskListFailed = () =>
  action(FETCH_BUSINESS_POLICY_RISK_LOCATION_LIST_FAILED);

export const fetchBusinessPolicyRiskListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyRiskListProgress());
      const res = await api.get(
        `/policy/get-policy-business-risk-location?customer_policy_id=${customerPolicyId}`
      );
      const data: IBusinessPolicyRiskLocation[] = res.data.data;
      dispatch(fetchBusinessPolicyRiskListSuccess(data));
    } catch (err: any) {
      dispatch(fetchBusinessPolicyRiskListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addBusinesPolicyRiskLocationAsync =
  (
    riskLocation: IBusinessPolicyRiskLocation,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        `/policy/edit-policy-business-risk-location`,
        riskLocation
      );
      dispatch(
        showMessage({
          type: "success",
          message: "Risk location saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_BUSINESS_POLICY_RISK_LOCATION_PROGRESS =
  "FETCH_BUSINESS_POLICY_RISK_LOCATION_PROGRESS";
export const FETCH_BUSINESS_POLICY_RISK_LOCATION_SUCCESS =
  "FETCH_BUSINESS_POLICY_RISK_LOCATION_SUCCESS";
export const FETCH_BUSINESS_POLICY_RISK_LOCATION_FAILED =
  "FETCH_BUSINESS_POLICY_RISK_LOCATION_FAILED";

export const fetchBusinessPolicyRiskProgress = () =>
  action(FETCH_BUSINESS_POLICY_RISK_LOCATION_PROGRESS);
export const fetchBusinessPolicyRiskSuccess = (
  data: IBusinessPolicyRiskLocation
) => action(FETCH_BUSINESS_POLICY_RISK_LOCATION_SUCCESS, { data });
export const fetchBusinessPolicyRiskFailed = (errorMessage: string) =>
  action(FETCH_BUSINESS_POLICY_RISK_LOCATION_FAILED, { errorMessage });

export const fetchBusinessPolicyRiskAsync =
  (
    customerPolicyId: number,
    riskLocationNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyRiskProgress());
      const res = await api.get(
        `/policy/get-policy-business-risk-location?customer_policy_id=${customerPolicyId}&column=location_number&value=${riskLocationNumber}`
      );
      const data: IBusinessPolicyRiskLocation[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBusinessPolicyRiskSuccess(data[0]));
      } else {
        dispatch(
          fetchBusinessPolicyRiskFailed(
            "Oops! We couldn't find any records associated with your risk location number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchBusinessPolicyRiskFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_BUSINES_POLICY_RISK_LOCATION_STATE =
  "CLEAR_BUSINES_POLICY_RISK_LOCATION_STATE";
export const CLEAR_BUSINES_POLICY_RISK_LOCATION =
  "CLEAR_BUSINES_POLICY_RISK_LOCATION";

export const clearBusinessPolicyRiskState = () =>
  action(CLEAR_BUSINES_POLICY_RISK_LOCATION_STATE);

export const clearBusinessPolicyCRisk = () =>
  action(CLEAR_BUSINES_POLICY_RISK_LOCATION);
