import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import {
  CLEAR_TASK_NEW_REMARKET_STATE,
  FETCH_TASK_NEW_REMARKET_FAILED,
  FETCH_TASK_NEW_REMARKET_PROGRESS,
  FETCH_TASK_NEW_REMARKET_SUCCESS,
} from "./NewRemarketActions";
import { defaultNewRemarket } from "./NewRemarket.types";
import { NewRemarketActions } from ".";

export const NewRemarketReducer = (
  state: IStoreState["NewRemarketChange"] = defaultNewRemarket,
  action: NewRemarketActions
) => {
  switch (action.type) {
    case FETCH_TASK_NEW_REMARKET_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.NewRemarket.loading = LoadState.InProgress;
        draftState.NewRemarket = defaultNewRemarket["NewRemarket"];
      });
      return newState;
    }
    case FETCH_TASK_NEW_REMARKET_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.NewRemarket.loading = LoadState.Loaded;
        draftState.NewRemarket.data = data;
      });
      return newState;
    }
    case FETCH_TASK_NEW_REMARKET_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.NewRemarket.loading = LoadState.Failed;
      });
      return newState;
    }
    case CLEAR_TASK_NEW_REMARKET_STATE: {
      return defaultNewRemarket;
    }

    default:
      return state;
  }
};
