/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../redux/initialStoreState";
import { useDispatchWrapper } from "./useDispatch";
import { fetchHabitationalCustomerInfoAsync } from "../redux/habitationalPolicy/habitationalBasicDetails/habitationalBasicDetailsActions";


export const useHabitationalCustomerBasicDetails = (customerPolicyId?: number) => {
  const customerInfo = useSelector(
    (storeState: IStoreState) => storeState.habitational.basicDetails.customerInfo
  );
  const dispatch = useDispatchWrapper();

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchHabitationalCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  return customerInfo;
};
