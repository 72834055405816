import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux/store";
import { AuthProvider } from "./contexts/AuthProvider/AuthProvider";
import { LinearProgress } from "@mui/material";
import { MessageBox } from "./components/MessageBox/MessageBox";
import { RolesProvider } from "./security/RolesProvider/RolesProvider";
import moment from "moment-timezone";
import { AppConfigProvider } from "./security/ApplicationProvider/ApplicationProvider";

moment.tz.setDefault("Canada/Eastern");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <AppConfigProvider>
        <AuthProvider>
          <RolesProvider>
            <Suspense fallback={<LinearProgress />}>
              <App />
            </Suspense>
          </RolesProvider>
          <MessageBox />
        </AuthProvider>
      </AppConfigProvider>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
