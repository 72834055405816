import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { LeadsActions } from ".";
import { initialLead, initialLeadState } from "./leads.types";
import {
  CLEAR_LEAD,
  CLEAR_LEAD_STATE,
  FETCH_LEADS_LIST_FAILED,
  FETCH_LEADS_LIST_PROGRESS,
  FETCH_LEADS_LIST_SUCCESS,
  FETCH_LEAD_FAILED,
  FETCH_LEAD_PROGRESS,
  FETCH_LEAD_SUCCESS,
} from "./leadsActions";

export const leadsReducer = (
  state: IStoreState["leads"] = initialLeadState,
  action: LeadsActions
) => {
  switch (action.type) {
    case FETCH_LEADS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_LEADS_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = list;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_LEADS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_LEAD_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.leadLoading = LoadState.InProgress;
        draftState.lead = initialLead;
      });
      return newState;
    }
    case FETCH_LEAD_SUCCESS: {
      const { lead } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.leadLoading = LoadState.Loaded;
        draftState.lead = lead;
      });
      return newState;
    }
    case FETCH_LEAD_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.leadLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_LEAD: {
      const newState = produce(state, (draftState) => {
        draftState.leadLoading = LoadState.NotLoaded;
        draftState.lead = initialLead;
      });
      return newState;
    }
    case CLEAR_LEAD_STATE: {
      return initialLeadState;
    }
    default: {
      return state;
    }
  }
};
