import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";
import {
  IAccountsPayable,
  initialAccountingAccountsPayableState,
} from "./AccountsPayable.types";

export const FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_PROGRESS =
  "FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_PROGRESS";
export const FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_SUCCESS =
  "FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_SUCCESS";
export const FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_FAILURE =
  "FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_FAILURE";

export const fetchAccountingAccountsPayableProgress = () =>
  action(FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_PROGRESS);

export const fetchAccountingAccountsPayableSuccess = (data: IAccountsPayable) =>
  action(FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_SUCCESS, { data });

export const fetchAccountingAccountsPayableFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_FAILURE, { errorMessage });

export const fetchAccountingAccountsPayableAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingAccountsPayableProgress());

      const res = await api.get(`/accounting/get-account-accounts-payable`);
      const data: IAccountsPayable[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAccountingAccountsPayableSuccess(data[0]));
      } else {
        dispatch(
          fetchAccountingAccountsPayableSuccess(
            initialAccountingAccountsPayableState["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchAccountingAccountsPayableFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingAccountsPayableAysnc =
  (
    data: IAccountsPayable,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/accounting/edit-account-accounts-payable", {
        ...data,
      });
      let message = "Accounts Payable saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_LIST_PROGRESS =
  "FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_LIST_PROGRESS";
export const FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_LIST_SUCCESS =
  "FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_LIST_SUCCESS";
export const FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_LIST_FAILURE =
  "FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_LIST_FAILURE";

export const fetchAccountingAccountsPayableListProgress = () =>
  action(FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_LIST_PROGRESS);

export const fetchAccountingAccountsPayableListSuccess = (
  data: IAccountsPayable[]
) => action(FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_LIST_SUCCESS, { data });

export const fetchAccountingAccountsPayableListFailed = (
  errorMessage: string
) => action(FETCH_ACCOUNTING_ACCOUNTS_PAYABLE_LIST_FAILURE, { errorMessage });

export const fetchAccountingAccountsPayableListAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingAccountsPayableListProgress());

      const res = await api.get(`/accounting/get-account-accounts-payable`);
      const data: IAccountsPayable[] = res.data.data;
      dispatch(fetchAccountingAccountsPayableListSuccess(data));
    } catch (err: any) {
      dispatch(
        fetchAccountingAccountsPayableListFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_ACCOUNTING_ACCOUNTS_PAYABLE =
  "CLEAR_ACCOUNTING_ACCOUNTS_PAYABLE";
export const clearAccountingAccountsPayable = () =>
  action(CLEAR_ACCOUNTING_ACCOUNTS_PAYABLE);
