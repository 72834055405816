import React, { useRef } from "react";
import { ICommentsSideBarProps } from "./CommentsSideBar.types";
import {
  Avatar,
  BottomNavigation,
  Box,
  Button,
  Card,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Comment, InsertCommentOutlined, Send } from "@mui/icons-material";
import { RightPanel } from "../RightPanel";
import { CustomTextField } from "../formsComponents";
import { IComment } from "../../redux/comments/commentsTypes";
import { useDispatchWrapper } from "../../hooks";
import {
  fetchCommentsAysnc,
  upsertCommentAysnc,
} from "../../redux/comments/commentsActions";
import { PageLoader } from "../PageLoader/PageLoader";
import { useFormik } from "formik";
import { formatDateTimeWithLocale, formatDateWithTime } from "../../helpers";

export const CommentsSideBar: React.FC<ICommentsSideBarProps> = (props) => {
  const { typeId } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <Tooltip title="Comments" onClick={handleToggle}>
        <InsertCommentOutlined color="primary" />
      </Tooltip>

      {open && (
        <RightPanel
          heading="Comments"
          //subHeading="Add notes or comments"
          open={true}
          onClose={handleToggle}
          width="50%"
          paperSx={{
            backgroundColor: theme.palette.mode === "dark" ? "#282C34": "#F7F8FB !important"
          }}
        >
          {" "}
          <RenderSideBar typeId={typeId} onToogle={handleToggle} />
        </RightPanel>
      )}
    </>
  );
};

const RenderSideBar: React.FC<{
  typeId: string | number;
  onToogle: () => void;
}> = (props) => {
  const listRef = useRef<HTMLUListElement | null>(null);
  const [comments, setComments] = React.useState<IComment[]>([]);
  const dispatch = useDispatchWrapper();
  const [loading, setLoading] = React.useState(false);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const { values, handleChange, handleSubmit,resetForm } = useFormik({
    initialValues: {
      comment: "",
    },
    onSubmit: (values) => {
        setSaveLoading(true);
      dispatch(
        upsertCommentAysnc(
          {
            comment: values.comment,
            comment_type: "",
            incremental_number: null,
            status: "ACTIVE",
            type_id: props.typeId,
            first_name: "",
            insert_ts: "",
            last_name: "",
          },
          (isSuccess) => {
            if (isSuccess) {
                resetForm()
              fetchComments();
            }
            setSaveLoading(false);
          }
        )
      );
    },
  });

  const fetchComments = async () => {
    setLoading(true);
    dispatch(
      fetchCommentsAysnc(props.typeId, (isSuccess, data) => {
        if (isSuccess && data) {
          setComments(data);
        }
        setLoading(false);
      })
    );
  };

  React.useEffect(() => {
    fetchComments();
  }, []);


  return (
    <>
      <>
        <PageLoader loading={loading}>
         <Stack spacing={4} position={'relative'}>
         
         {comments.map(
              (
                { comment, first_name,last_name, incremental_number, insert_ts, type_id },
                index
              ) =>{
                return <Paper sx={{p: 3,}}>
                  <Typography variant="body1" display={'block'} fontWeight={500}  fontSize={'1rem'}>{formatDateTimeWithLocale(insert_ts)}</Typography>
                  <Typography marginTop={1} fontWeight={600} fontSize={'1.2rem'}>{first_name+' '+last_name}</Typography>
                  <Typography marginTop={1} variant="body1" fontSize={'1.1rem'}>{comment}</Typography>
                </Paper>
              })}
               <Box position={'absolute'} height={'90%'} left={'10%'} zIndex={-1} sx={(theme)=>({
                border: `1.5px solid `+theme.palette.grey[300]
               })}></Box>
         </Stack>
          {/* <List sx={{ mb: 2}}>
            {comments.map(
              (
                { comment, first_name,last_name, incremental_number, insert_ts, type_id },
                index
              ) => (
                <ListItem
                  button
                  key={incremental_number}
                  sx={{
                    pt: 2,
                    pb: 2,
                    border: "1px solid #eeeeee",
                    borderRadius: "15px",
                    mb: 2
                  }}
                >
                  <ListItemAvatar>
                    <Avatar />
                  </ListItemAvatar>
                  <ListItemText>
                    <Typography fontWeight={600}>{first_name+' '+last_name}</Typography>
                    <Typography>{comment}</Typography>
                    <Typography variant="caption" display={'block'} textAlign={'end'}>{formatDateWithTime(insert_ts)}</Typography>
                  </ListItemText>
                </ListItem>
              )
            )}
          </List> */}
        </PageLoader>
      </>
      <Paper
        sx={(theme: Theme) => ({
          backgroundColor: theme.palette.grey[200],
          position: "absolute",
          bottom: 0,
          left: 0,
          height: "8vh",
          right: 0,
          display: "flex",
          alignItems: "center",
          pl: 5,
          pr: 5,
        })}
        elevation={3}
      >
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={10}>
              <CustomTextField
                fullWidth
                id="comment"
                value={values.comment}
                sx={{ background: "#fff" }}
                onChange={handleChange}
                placeholder="Type comment...."
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                variant="contained"
                fullWidth
                startIcon={<Send />}
                type="submit"
                disabled={saveLoading}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};
