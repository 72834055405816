import { Fragment, useEffect, useState } from "react";
//@ts-ignore
import { FieldType } from "@prisma/client";
import PDFViewer from "./PdfViewer";
import { IOptionsBarSelectedItem, OptionsBar } from "./OptionsBar";
import {
  DOCU_SIGN_FIELD_LABELS,
  DOCU_SIGN_FIELD_SIZE,
  DOCU_SIGN_FIELD_TYPES,
} from "./enums";
import { Button, Grid, Stack } from "@mui/material";
import { useDispatchWrapper } from "../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../redux/initialStoreState";
import { IG4SignEditior } from "../../redux/g4SignDocuments/g4SignDocuments.types";
import { IPdfEditorProps } from "./PdfEditor.types";
import {
  addG4SignPlaceholder,
  fetchG4SignPlaceholdersAsync,
  rollBackG4SignPlaceholder,
} from "../../redux/g4SignDocuments/g4SignDocumentsAction";
import { Send } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { G4EmailDialog } from "../../views/g4sign/g4EmailDialog/G4EmailDialog";
import { getUniqueId } from "../../helpers";
import { CustomAlert } from "../formsComponents/CustomAlert";

export default function PDFEditor(props: IPdfEditorProps) {
  const { documentCode } = props;
  const [option, setOptions] = useState<IOptionsBarSelectedItem>({
    recipient: "",
    recipient_name: "",
    type: DOCU_SIGN_FIELD_TYPES.NONE,
  });
  const { placeholders, loading } = useSelector(
    (storeState: IStoreState) => storeState.g4Sign.documents.editior
  );
  const { preview_attachment = "" } = useSelector(
    (storeState: IStoreState) => storeState.g4Sign.documents.document
  );
  const recipientList = useSelector(
    (storeState: IStoreState) => storeState.g4Sign.documents.recipientList
  );
  const [openEmail, setOpenEmail] = useState(false);
  const dispatch = useDispatchWrapper();
  const [showError, setShowError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleAddField = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    pageNumber: number
  ) => {
    if (option.type === DOCU_SIGN_FIELD_TYPES.NONE) {
      return;
    }
    //@ts-ignore
    const rect = e.target.getBoundingClientRect();
    const fieldSize = { width: 192, height: 64 };
    let newFieldX = e.clientX - rect.left - fieldSize.width / 2; //x position within the element.
    let newFieldY = e.clientY - rect.top - fieldSize.height / 2; //y position within the element.
    if (newFieldX < 0) newFieldX = 0;
    if (newFieldY < 0) newFieldY = 0;

    if (newFieldX + fieldSize.width > rect.width)
      newFieldX = rect.width - fieldSize.width;
    if (newFieldY + fieldSize.height > rect.height)
      newFieldY = rect.height - fieldSize.height;
    let tempUUID = getUniqueId();

    const field: IG4SignEditior = {
      customer_text: option.recipient_name,
      document_code: documentCode,
      field_code: tempUUID,
      field_type: option.type,
      page_no: pageNumber,
      position_x: newFieldX,
      position_y: newFieldY,
      recipient_code: option.recipient,
      isNewPlaceholder: true,
      size_category: DOCU_SIGN_FIELD_SIZE.SMALL,
    };

    dispatch(addG4SignPlaceholder(field, false));
  };

  const handleAddNewInitialPlaceholder = (
    pageNumber: number,
    x: number,
    y: number
  ) => {
    const tempUUID = getUniqueId();
    const field: IG4SignEditior = {
      customer_text: option.recipient_name,
      document_code: documentCode,
      field_code: tempUUID,
      field_type: DOCU_SIGN_FIELD_TYPES.FREE_SIGNATURE,
      page_no: pageNumber + 1,
      position_x: x,
      position_y: y,
      recipient_code: option.recipient,
      isNewPlaceholder: true,
      size_category: DOCU_SIGN_FIELD_SIZE.SMALL,
    };

    dispatch(addG4SignPlaceholder(field, false));
  };

  const handlePlaceholderUpdated = (updatedPlaceholder: IG4SignEditior) => {
    dispatch(addG4SignPlaceholder(updatedPlaceholder, true));
  };

  const handleDeletePlaceholder = (placeholder: IG4SignEditior) => {
    dispatch(rollBackG4SignPlaceholder(placeholder.field_code as string));
  };

  const handleToggleSendEmail = () => {
    let isAllSignAdded = true;
    for (const signer of recipientList) {
      const isExist = placeholders.find(
        (x) => x.recipient_code === signer.recipient_code
      );
      if (!isExist) {
        isAllSignAdded = false;
      }
    }
    if (isAllSignAdded) {
      setOpenEmail(!openEmail);
    } else {
      setShowError("Attention: You have not added all required signers. Please review and confirm before sending.")
    }
  };

  useEffect(() => {
    if (recipientList.length > 0) {
      const recipient = recipientList[0];
      setOptions({
        recipient: recipient.recipient_code,
        recipient_name: recipient.signer_name,
        type: DOCU_SIGN_FIELD_TYPES.NONE,
      });
    }
  }, []);

  useEffect(() => {
    dispatch(fetchG4SignPlaceholdersAsync(documentCode));
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction={"row"} justifyContent={"end"}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Send />}
              onClick={handleToggleSendEmail}
            >
              Send For Signatures
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
        {showError && (
          <CustomAlert severity="error" onClose={() => setShowError(null)}>
            {showError}
          </CustomAlert>
        )}
        </Grid>
        <Grid item xs={2} position={"relative"}>
          <OptionsBar selected={option} onSelect={(data) => setOptions(data)} />
        </Grid>
       

        <Grid item xs={10}>
          <PDFViewer
            style={{
              cursor:
                option.type !== DOCU_SIGN_FIELD_TYPES.NONE
                  ? `url("https://place-hold.it/110x64/37f095/ffffff&text=${
                      DOCU_SIGN_FIELD_LABELS[option.type]
                    }") 55 32, auto`
                  : "",
            }}
            readonly={false}
            fields={placeholders}
            pdfUrl={preview_attachment}
            //pdfUrl="https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf"
            onMouseDown={handleAddField}
            onMouseUp={() => {}}
            onPlaceholderUpdated={handlePlaceholderUpdated}
            onPlaceholderDelete={handleDeletePlaceholder}
            addInitialPlacholder={handleAddNewInitialPlaceholder}
          ></PDFViewer>
        </Grid>
      </Grid>
      {openEmail && (
        <G4EmailDialog
          open={openEmail}
          documentCode={documentCode}
          onClose={handleToggleSendEmail}
        />
      )}
    </>
  );
}
