import React from "react";

interface ITabsContext {
  currentTab: number;
  changeStep: (newStep: number) => void;
}

const TabsContext = React.createContext<ITabsContext>({
  currentTab: 0,
  changeStep: () => {},
});

export const useTabsUncontrolledContext = () => React.useContext(TabsContext);

export const TabsUncontrolledProvider: React.FC<{
  step: number;
  changeStep: (newStep: number) => void;
  children: React.ReactNode;
}> = (props) => {
  const { step } = props;



  return (
    <TabsContext.Provider value={{ currentTab: step, changeStep: props.changeStep }}>
      {props.children}
    </TabsContext.Provider>
  );
};
