import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { getUserAuth, uploadFile } from "../../helpers";
import { action } from "typesafe-actions";
import {
  ADD_BRANCH_OFFICE,
  ADD_BRANCH_OFFICE_PROGRESS,
  CLEAR_BRANCH_OFFICE_STATE,
  FETCH_BRANCH_OFFICE_PROGRESS,
  FETCH_BRANCH_OFFICE_SUCCESS,
} from "../constants";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IBranchOffice } from "./branchOffice.types";
import { endPoints } from "../../constants/endPoints";
import { makeApiCall } from "../../helpers/postRequest";

export const BRANCH_OFFICE_ERROR = "BRANCH_OFFICE_ERROR";
export const ADD_BRANCH_OFFICE_ERROR = "ADD_BRANCH_OFFICE_ERROR";

export const addBranchOfficeProgress = () => action(ADD_BRANCH_OFFICE_PROGRESS);

export const addBranchOffice = (data: IBranchOffice) =>
  action(ADD_BRANCH_OFFICE, { data: data });

export const addBranchOfficeAsync =
  (
    data: IBranchOffice,
    initialData: IBranchOffice,
    isUpdate: boolean,
    file: any | null,
    onSuccess: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const {insert_ts,...rest} = data;
      dispatch(saveLoaderProgress());
      const asPayload: { [key: string]: string | number } = {
        branch_office_id: data.branch_office_primary_id ?? 0,
      };
      dispatch(addBranchOfficeProgress());
      const path = await uploadFile(
        file,
        "BRANCH_OFFICE",
        data.logo || "",
        asPayload
      );
      await makeApiCall({
        url: endPoints.branchOffice.createBranchOffice,
        method: "POST",
        automation: {
          isUpdate: isUpdate,
          primaryFieldName: "branch_office_primary_id"
        }
      },initialData,{
        ...rest,
        logo: path,
      })
   
      dispatch(addBranchOffice(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Branch Office saved successfully!",
          displayAs: "snackbar",
        })
      );
      onSuccess();
    } catch (err: any) {
      dispatch(branchOfficeError(null));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchBranchOfficeProgress = () =>
  action(FETCH_BRANCH_OFFICE_PROGRESS);

export const fetchBranchOfficeSuccess = (data: IBranchOffice) =>
  action(FETCH_BRANCH_OFFICE_SUCCESS, { data: data });

export const fetchBranchOfficeAsync =
  (branchOfficeId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const token = getUserAuth();
    try {
      if (token) {
        dispatch(fetchBranchOfficeProgress());
        const res = await api.get(
          `insurance/get-branch-office?branch_office_primary_id=${branchOfficeId}`,
          {
            headers: {
              "auth-Token": token,
            },
          }
        );
        const data: IBranchOffice[] = res.data.data;
        if (data.length > 0) {
          dispatch(addBranchOffice(data[0]));
        } else {
          dispatch(
            branchOfficeError(
              "Oops! We couldn't find any records associated with your branch office at the moment. Please ensure that the provided information is accurate and try again."
            )
          );
        }
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const fetchBranchOfficeWithCallback =
  (
    branchOfficeId: string,
    onCallBack: (data: IBranchOffice) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `insurance/get-branch-office?column=branch_code&value=${branchOfficeId}`
      );
      const data: IBranchOffice[] = res.data.data;
      if (data.length > 0) {
        onCallBack(data[0]);
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const branchOfficeError = (message: string | null) =>
  action(BRANCH_OFFICE_ERROR, {
    message: message,
  });

export const clearBranchOfficeState = () => action(CLEAR_BRANCH_OFFICE_STATE);
