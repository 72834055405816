import { LoadState } from "../../../constants/enums";

export interface IInvoiceTemplateState {
  loading: LoadState;
  data: IInvoiceTemplate;
  error: string | null;
}

export interface IInvoiceTemplate {
  customer_id: string | null;
  customer_policy_id: string | null;
  incremental_number: string | null;
  invoice_notes: string | null;
  invoice_footer: string | null;
  status: string;
}

export const initialInvoiceTemplateState: IInvoiceTemplateState = {
  data: {
    customer_id: null,
    customer_policy_id: null,
    incremental_number: null,
    invoice_notes: null,
    invoice_footer: null,
    status: "ACTIVE",
  },
  loading: LoadState.NotLoaded,
  error: null,
};
