import { action } from "typesafe-actions";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import {
  IAddRiskLocation,
  initialTaskRiskLocationState,
} from "./taskriskLocation.types";

export const FETCH_ADD_RISK_LOCATION_PROGRESS =
  "FETCH_ADD_RISK_LOCATION_PROGRESS";
export const FETCH_ADD_RISK_LOCATION_SUCCESS =
  "FETCH_ADD_RISK_LOCATION_SUCCESS";
export const FETCH_ADD_RISK_LOCATION_FAILED = "FETCH_ADD_RISK_LOCATIONR_FAILED";

export const fetchAddRiskLocationProgress = () =>
  action(FETCH_ADD_RISK_LOCATION_PROGRESS);
export const fetchAddRiskLocationSuccess = (data: IAddRiskLocation) =>
  action(FETCH_ADD_RISK_LOCATION_SUCCESS, { data });
export const fetchAddRiskLocationFailed = () =>
  action(FETCH_ADD_RISK_LOCATION_FAILED);

export const fetchAddRiskLocationAysnc =
  (taskId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAddRiskLocationProgress());
      const res = await api.get(
        `tasks/get-tasks-delete-risk-location?task_id=${taskId}`
      );
      const data: IAddRiskLocation[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAddRiskLocationSuccess(data[0]));
      } else {
        dispatch(
          fetchAddRiskLocationSuccess(
            initialTaskRiskLocationState.addRiskLocation["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchAddRiskLocationFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskAddRiskLocationAysnc =
  (
    task: IAddRiskLocation,
    onCallback: (isSuccess: boolean, taskId?: number) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post("/tasks/create-tasks-add-risk-location", task);
      dispatch(
        showMessage({
          type: "success",
          message: "Task is saved succesfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(
        fetchAddRiskLocationSuccess({ ...task, task_id: res.data.data.task_id })
      );
      onCallback(true, res.data.data.task_id);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
