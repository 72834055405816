import { LoadState } from "../../constants/enums";
import { getUniqueId } from "../../helpers";
import { IAutomobileApplication } from "../automobileApplication/automobileApplication.types";
import { ICSIORaw } from "../csio/csio.types";


export interface IAutomobileCSIOApplicationState {
  list: ICSIORaw[];
  csioData: IAutomobileApplication;
  totalRecords: number;
  loading: LoadState;
  error: string | null;
}

export const initialAutomobileCSIOApplicationState: IAutomobileCSIOApplicationState =
  {
    csioData: {
      customer_policy_id: null,
      incremental_number: null,
      policy_number_assigned: null,
      replacing_policy_no: null,
      new_policy: null,
      billing_method: null,
      language_preferred: null,
      policy_period_effective_date: null,
      policy_period_effective_time: null,
      policy_period_expiry_date: null,
      policy_period_expiry_time: null,
      insurance: {
        insurance_name: null,
        insurance_address: null,
        insurance_unit_or_suite: null,
        insurance_city: null,
        insurance_state: null,
        insurance_country: null,
        insurance_zip: null,
      },
      broker: {
        broker_agent: null,
        broker_agent_address: null,
        broker_agent_unit_or_suite: null,
        broker_agent_city: null,
        broker_agent_state: null,
        broker_agent_country: null,
        broker_agent_zip: null,
        broker_code: null,
        broker_sub_code: null,
      },
      application_details: {
        name: null,
        address: null,
        address_two: null,
        country: null,
        city: null,
        state: null,
        postalcode: null,
        phone: null,
        mobile: null,
      },
      lessor: {
        lessor_name: null,
        lessor_full_address: null,
        lessor_phone: null,
        lessor_email: null,
      },
      described_automobiles: {
        described_automobiles_part_one: [
          {
            key: getUniqueId(),
            autoNo: null,
            modelYear: null,
            makeTradeName: null,
            model: null,
            bodyType: null,
            noCylinders: null,
            grossVehicleWeight: null,
          },
        ],
        described_automobiles_part_two: [
          {
            key: getUniqueId(),
            autoNo: null,
            vinNumber: null,
            owned: null,
            leased: null,
            purchaseLeasedDate: null,
            purchaseLeased: null,
            purchasePrice: null,
            automobileUsed: null,
            automobileUsedOneWay: null,
            automobileUsedPrecentage: null,
          },
        ],
        described_automobiles_part_three: [
          {
            key: getUniqueId(),
            autoNo: null,
            annualDrivingDistance: null,
            automobileUsedForPooling: null,
            automobileUsedForPoolingIfYes: null,
            typefFuelUsed: null,
            typefFuelUsedIfYes: null,
            unrepairedDamage: null,
            modifiedCustomized: null,
          },
        ],
        described_automobiles_part_four: [
          {
            key: getUniqueId(),
            autoNo: "",
            lienholderName: "",
            postalAddress: "",
          },
        ],
        owner_actual_owner_yesNoCd: null,
        total_automobiles_in_business: null,
        rented_or_leased_to_other: null,
        carry_passengers_for_compensation: null,
        haul_a_trailer: null,
        carry_explosives: null,
      },
      driver_information: {
        section_one: [
          {
            key: getUniqueId(),
            driverNo: null,
            nameofDriver: null,
            driverLicenseNumber: null,
            dateOfBirth: "",
            sex: "",
            maritalStatus: "",
          },
        ],
        section_two: [
          {
            key: getUniqueId(),
            driverNo: null,
            driverTrainingCertificateAttached: null,
            dateFirstLicencedInCanadaorUSClass: null,
            dateFirstLicencedInCanadaorUSYear: null,
            otherClassofLicenseIfAnyClass: null,
            otherClassofLicenseIfAnyYear: null,
            percentageUseByEachDriverAuto1: null,
            percentageUseByEachDriverAuto2: null,
            percentageUseByEachDriverAuto3: null,
            areAnyOtherPersonsInTheHousehold: {
              questionCd: null,
              yesNoCd: null,
            },
            doAnyDriversQualifyForRetireeDiscount: {
              questionCd: null,
              yesNoCd: null,
            },
          },
        ],
        insurance_company: null,
        prior_policy_number: null,
        expiry_date: null,
        has_any_driver_license: null,
        has_any_insurance_company: null,
        during_the_last_three_years: null,
        has_the_applicant_or_any_listed_driver: null,
      },
      previous_accidents_and_insurance_claims: [
        {
          key: getUniqueId(),
          driverNo: null,
          autoNo: null,
          date: null,
          coverageClaimPaidUnder: {
            bi: null,
            pd: null,
            Ab: null,
            dcpd: null,
            ua: null,
            col: null,
            comp: null,
          },
          amountPaidOrEstimate: null,
          details: null,
        }
      ],
      history_of_convictions: [
        {
          key: getUniqueId(),
          driverNo:null,
          dateConvicted:null,
          details: "",
        }
      ],
      rating_information: {
        section_one: [
          {
            key: getUniqueId(),
            autoNo: null,
            class: null,
            drivingRecord: null,
            bi: null,
            pd: null,
            ab: null,
            dcpd: null,
            coll_ap: null,
            driverNoPrimary: null,
            driverNoSceondary: null,
            atFaultClaimSurcharges: null,
            atFaultClaimPrecentage: null,
            convictionsSurcharges: null,
            convictionsPrecentage: null,
          },     
        ],
        section_two: [
          {
            key: getUniqueId(),
            autoNo: null,
            listPriceView: null,
            vehicleCode: null,
            ratingGroupAb: null,
            ratingGroupDcpd: null,
            ratingGroupCollAp: null,
            ratingGroupcompSp: null,
            ratinratingGroupDcpd: null,
            location: null,
            territory: null,
            discountDescription: null,
            discountPrecentage: null,
          }
        ],
      },
      payment_reporter_of_broker: {
        type_of_payment_plan: null,
        estimated_policy_premium: null,
        provincial_sales_tax: null,
        interest_amount: null,
        total_estimated_cost: null,
        amount_paid_with_application: null,
        amount_due: null,
        no_of_remaining_installments: null,
        amount_of_each_installment: null,
        installment_due_date: null,
        have_you_bound_the_risk: null,
        is_this_business_new_to_you: null,
        type_of_motor_vehicle_liability_insurance_card: null,
        how_long_you_have_known_the_applicant: null,
        how_long_you_have_known_the_principal_driver: null,
        has_an_inspection_been_done: null,
      },
      payment_schedule: [
        {
          id: getUniqueId(),
          installment_number: "",
          installment_amount: "",
          installment_date: "",
        },
      ],
      incurance_coverage_applied_for: {
        columns: [],
        rows: [
          {
            key: getUniqueId(),
            code: '',
            label: '',
            description: '',
            isTypeOther: false,
            automobiles: {
              "": {
                limit: null,
                premimum: null,
                deductible: null,
                isOnlyPremimum: false,
              },
            },
          },
        ]
      },
      remarks: null,
      status : null,
    },
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  };
