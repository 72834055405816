import produce from "immer";
import { AutomationActions } from ".";
import { IStoreState } from "../initialStoreState";
import { initialAutomationState } from "./automation.types";
import {
  ADD_ACTION_NODE_IN_GRAPH,
  ADD_CONDITION_NODE_IN_GRAPH,
  ADD_FINISH_ACTION_NODE_IN_GRAPH,
  ADD_WORKFLOW_NODE_IN_GRAPH,
  CLEAR_AUTOMATION_STATE,
  FETCH_GRAPH_DATA_FAILED,
  FETCH_GRAPH_DATA_PROGRESS,
  FETCH_GRAPH_DATA_SUCCESS,
  FETCH_WORKFLOWS_LIST_FAILED,
  FETCH_WORKFLOWS_LIST_PROGRESS,
  FETCH_WORKFLOWS_LIST_SUCCESS,
} from "./automationActions";
import { LoadState } from "../../constants/enums";
import { updateNodesWithPlaceholderAndEdges } from "./nodesEdgesUpdate";

export const automationReducer = (
  state: IStoreState["automation"] = initialAutomationState,
  action: AutomationActions
) => {
  switch (action.type) {
    case FETCH_GRAPH_DATA_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.graphLoadState = LoadState.InProgress;
        draftState.graphData = {
          nodes: [],
          edges: [],
          workflow_basic_code: null,
        };
      });
      return newState;
    }
    case FETCH_GRAPH_DATA_SUCCESS: {
      const { graphData } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.graphLoadState = LoadState.Loaded;
        draftState.graphData = graphData;
      });
      return newState;
    }
    case FETCH_GRAPH_DATA_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.graphLoadState = LoadState.Failed;
        draftState.graphData = {
          nodes: [],
          edges: [],
          workflow_basic_code: null,
        };
      });
      return newState;
    }

    case ADD_WORKFLOW_NODE_IN_GRAPH: {
      const { node } = action.payload;
      const newState = updateNodesWithPlaceholderAndEdges(state, node, false);
      return newState;
    }

    case ADD_CONDITION_NODE_IN_GRAPH:
    case ADD_ACTION_NODE_IN_GRAPH:
    case ADD_FINISH_ACTION_NODE_IN_GRAPH: {
      const { node, isDelete } = action.payload;
      const newState = updateNodesWithPlaceholderAndEdges(
        state,
        node,
        isDelete
      );
      return newState;
    }

    case FETCH_WORKFLOWS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }
    case FETCH_WORKFLOWS_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_WORKFLOWS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.list = [];
      });
      return newState;
    }

    case CLEAR_AUTOMATION_STATE: {
      return initialAutomationState;
    }

    default: {
      return state;
    }
  }
};
