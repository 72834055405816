import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";
import {
  ILifeInsurancePolicyFamilyTree,
  defaultLifeInsurancePolicyFamilyTree,
} from "./lifeInsurancePolicyFamilyTree.types";

export const FETCH_LIFE_INSURANCE_FAMILY_TREE_LIST_PROGRESS =
  "FETCH_LIFE_INSURANCE_FAMILY_TREE_LIST_PROGRESS";
export const FETCH_LIFE_INSURANCE_FAMILY_TREE_LIST_SUCCESS =
  "FETCH_LIFE_INSURANCE_FAMILY_TREE_LIST_SUCCESS";
export const FETCH_LIFE_INSURANCE_FAMILY_TREE_LIST_FAILED =
  "FETCH_LIFE_INSURANCE_FAMILY_TREE_LIST_FAILED";

export const fetchLifeInsuranceFamilyTreeListProgress = () =>
  action(FETCH_LIFE_INSURANCE_FAMILY_TREE_LIST_PROGRESS);
export const fetchLifeInsuranceFamilyTreeListSuccess = (
  data: ILifeInsurancePolicyFamilyTree[]
) => action(FETCH_LIFE_INSURANCE_FAMILY_TREE_LIST_SUCCESS, { data });
export const fetchLifeInsuranceFamilyTreeListFailed = () =>
  action(FETCH_LIFE_INSURANCE_FAMILY_TREE_LIST_FAILED);

export const fetchLifeInsuranceFamilyTreeListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchLifeInsuranceFamilyTreeListProgress());
      const res = await api.get(
        `/policy/get-life-policy-additional-information-family-tree?customer_policy_id=${customerPolicyId}`
      );
      const data: ILifeInsurancePolicyFamilyTree[] = res.data.data;
      dispatch(fetchLifeInsuranceFamilyTreeListSuccess(data));
    } catch (err: any) {
      dispatch(fetchLifeInsuranceFamilyTreeListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_LIFE_INSURANCE_FAMILY_TREE_PROGRESS =
  "FETCH_LIFE_INSURANCE_FAMILY_TREE_PROGRESS";
export const FETCH_LIFE_INSURANCE_FAMILY_TREE_SUCCESS =
  "FETCH_LIFE_INSURANCE_FAMILY_TREE_SUCCESS";
export const FETCH_LIFE_INSURANCE_FAMILY_TREE_FAILED =
  "FETCH_LIFE_INSURANCE_FAMILY_TREE_FAILED";

export const fetchLifeInsuranceFamilyTreeProgress = () =>
  action(FETCH_LIFE_INSURANCE_FAMILY_TREE_PROGRESS);
export const fetchLifeInsuranceFamilyTreeSuccess = (
  familyData: ILifeInsurancePolicyFamilyTree
) => action(FETCH_LIFE_INSURANCE_FAMILY_TREE_SUCCESS, { familyData });
export const fetchLifeInsuranceFamilyTreeFailed = (errorMessage: string) =>
  action(FETCH_LIFE_INSURANCE_FAMILY_TREE_FAILED, { errorMessage });

export const fetchLifeInsuranceFamilyTreeAsync =
  (
    customerPolicyId: number,
    serialNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchLifeInsuranceFamilyTreeProgress());
      const res = await api.get(
        `/policy/get-life-policy-additional-information-family-tree?customer_policy_id=${customerPolicyId}&serial_number=${serialNumber}`
      );
      const data: ILifeInsurancePolicyFamilyTree[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchLifeInsuranceFamilyTreeSuccess(data[0]));
      } else {
        dispatch(
          dispatch(
            fetchLifeInsuranceFamilyTreeSuccess(
              defaultLifeInsurancePolicyFamilyTree.familyData
            )
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchLifeInsuranceFamilyTreeFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addLifeInsuranceFamilyTreeAsync =
  (
    familyData: ILifeInsurancePolicyFamilyTree,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post(
        `/policy/edit-life-policy-additional-information-family-tree`,
        { ...familyData }
      );
      dispatch(fetchLifeInsuranceFamilyTreeSuccess(familyData));
      dispatch(
        showMessage({
          type: "success",
          message: "Family Information saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const CLEAR_LIFE_INSURANCE_FAMILY_TREE =
  "CLEAR_HABITATIONAL_CSIO_BINDER";
export const CLEAR_LIFE_INSURANCE_FAMILY_TREE_STATE =
  "CLEAR_HABITATIONAL_CSIO_BINDER_STATE";

export const clearLifeInsuranceFamilyTreeState = () =>
  action(CLEAR_LIFE_INSURANCE_FAMILY_TREE_STATE);

export const clearLifeInsuranceFamilyTree = () =>
  action(CLEAR_LIFE_INSURANCE_FAMILY_TREE);

// /edit-life-policy-additional-information-family-tree
// /policy/edit-habitational-binder
