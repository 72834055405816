import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";
import {
  IRecievableTrust,
  initialAccountingRecievableTrustState,
} from "./RecievableTrust.types";

export const FETCH_ACCOUNTING_RECIEVABLE_TRUST_PROGRESS =
  "FETCH_ACCOUNTING_RECIEVABLE_TRUST_PROGRESS";
export const FETCH_ACCOUNTING_RECIEVABLE_TRUST_SUCCESS =
  "FETCH_ACCOUNTING_RECIEVABLE_TRUST_SUCCESS";
export const FETCH_ACCOUNTING_RECIEVABLE_TRUST_FAILURE =
  "FETCH_ACCOUNTING_RECIEVABLE_TRUST_FAILURE";

export const fetchAccountingRecievableTrustProgress = () =>
  action(FETCH_ACCOUNTING_RECIEVABLE_TRUST_PROGRESS);

export const fetchAccountingRecievableTrustSuccess = (data: IRecievableTrust) =>
  action(FETCH_ACCOUNTING_RECIEVABLE_TRUST_SUCCESS, { data });

export const fetchAccountingRecievableTrustFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_RECIEVABLE_TRUST_FAILURE, { errorMessage });

export const fetchAccountingRecievableTrustAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingRecievableTrustProgress());
      const res = await api.get(`/accounting/get-account-receivable-trust`);
      const data: IRecievableTrust[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAccountingRecievableTrustSuccess(data[0]));
      } else {
        dispatch(
          fetchAccountingRecievableTrustSuccess(
            initialAccountingRecievableTrustState["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchAccountingRecievableTrustFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingRecievableTrustAysnc =
  (
    data: IRecievableTrust,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/accounting/edit-account-receivable-trust", {
        ...data,
      });
      let message = "RecievableTrust saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const FETCH_ACCOUNTING_RECIEVABLE_TRUST_LIST_PROGRESS =
  "FETCH_ACCOUNTING_RECIEVABLE_TRUST_LIST_PROGRESS";
export const FETCH_ACCOUNTING_RECIEVABLE_TRUST_LIST_SUCCESS =
  "FETCH_ACCOUNTING_RECIEVABLE_TRUST_LIST_SUCCESS";
export const FETCH_ACCOUNTING_RECIEVABLE_TRUST_LIST_FAILURE =
  "FETCH_ACCOUNTING_RECIEVABLE_TRUST_LIST_FAILURE";

export const fetchAccountingRecievableTrustListProgress = () =>
  action(FETCH_ACCOUNTING_RECIEVABLE_TRUST_LIST_PROGRESS);

export const fetchAccountingRecievableTrustListSuccess = (
  data: IRecievableTrust[]
) => action(FETCH_ACCOUNTING_RECIEVABLE_TRUST_LIST_SUCCESS, { data });

export const fetchAccountingRecievableTrustListFailed = (
  errorMessage: string
) => action(FETCH_ACCOUNTING_RECIEVABLE_TRUST_LIST_FAILURE, { errorMessage });

export const fetchAccountingRecievableTrustListAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingRecievableTrustListProgress());
      const res = await api.get(`/accounting/get-account-receivable-trust`);
      const data: IRecievableTrust[] = res.data.data;
      dispatch(fetchAccountingRecievableTrustListSuccess(data));
    } catch (err: any) {
      dispatch(
        fetchAccountingRecievableTrustFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_ACCOUNTING_RECIEVABLE_TRUST =
  "CLEAR_ACCOUNTING_RECIEVABLE_TRUST";
export const clearAccountingRecievableTrust = () =>
  action(CLEAR_ACCOUNTING_RECIEVABLE_TRUST);
