import produce from "immer";
import { BusinesPolicySubscriptionActions } from ".";
import { IStoreState } from "../../initialStoreState";
import { defaultBusinessPolicySubscriptionState } from "./businessPolicySubscription.types";
import {
  CLEAR_BUSINES_POLICY_SUBSCRIPTION,
  CLEAR_BUSINES_POLICY_SUBSCRIPTION_STATE,
  FETCH_BUSINESS_POLICY_SUBSCRIPTION_FAILED,
  FETCH_BUSINESS_POLICY_SUBSCRIPTION_LIST_FAILED,
  FETCH_BUSINESS_POLICY_SUBSCRIPTION_LIST_PROGRESS,
  FETCH_BUSINESS_POLICY_SUBSCRIPTION_LIST_SUCCESS,
  FETCH_BUSINESS_POLICY_SUBSCRIPTION_PROGRESS,
  FETCH_BUSINESS_POLICY_SUBSCRIPTION_SUCCESS,
} from "./businessPolicySubscriptionActions";
import { LoadState } from "../../../constants/enums";

export const businessPolicySubscriptionReducer = (
  state: IStoreState["business"]["subscription"] = defaultBusinessPolicySubscriptionState,
  action: BusinesPolicySubscriptionActions
) => {
  switch (action.type) {
    case FETCH_BUSINESS_POLICY_SUBSCRIPTION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_SUBSCRIPTION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_SUBSCRIPTION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.data = [];
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_SUBSCRIPTION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.subscriptionLoading = LoadState.InProgress;
        draftState.subscription =
          defaultBusinessPolicySubscriptionState["subscription"];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_SUBSCRIPTION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.subscriptionLoading = LoadState.Loaded;
        draftState.subscription = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_SUBSCRIPTION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.subscriptionLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_BUSINES_POLICY_SUBSCRIPTION: {
      const newState = produce(state, (draftState) => {
        draftState.subscription =
          defaultBusinessPolicySubscriptionState["subscription"];
      });
      return newState;
    }
    case CLEAR_BUSINES_POLICY_SUBSCRIPTION_STATE: {
      return defaultBusinessPolicySubscriptionState;
    }
    default: {
      return state;
    }
  }
};
