import React from "react";
import { Dialog } from "../../../../components/Dialogs/Dialog";
import { Button, Grid } from "@mui/material";
import { StandadCard } from "../../../../components/Cards";
import { StandardCellList } from "../../../../components/Standards";
import { IViewDocumentReceiptProps } from "./ViewDocumentReceipt.types";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";
import {
  clearG4DocumentsState,
  clearG4SignRecipient,
  fetchG4SignRecipientListAsync,
  resendEmailToRecipient,
} from "../../../../redux/g4SignDocuments/g4SignDocumentsAction";
import { useDispatchWrapper } from "../../../../hooks";
import moment from "moment";
import {
  IDataTableProps,
  RenderType,
} from "../../../../components/Table/interfaces/IDataTableProps";
import { RefreshPlugin } from "../../../../components/Table/plugins";
import { LoadState } from "../../../../constants/enums";
import { StatusRenderer } from "../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { DataTable } from "../../../../components/Table/DataTable";
import { useRoles } from "../../../../security/RolesProvider/RolesProvider";

export const ViewDocumentReceipt: React.FC<IViewDocumentReceiptProps> = (
  props
) => {
  const { open, document, onClose } = props;
  const dispatch = useDispatchWrapper();
  const { firstName, lastName } = useRoles();

  const { recipientList, recipientLoading } = useSelector(
    (storeState: IStoreState) => storeState.g4Sign.documents
  );

  const fetchList = () => {
    if (document.document_code) {
      dispatch(fetchG4SignRecipientListAsync(document.document_code));
    }
  };

  const tableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: recipientLoading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [],
      },
      rightItems: {
        customPlugins: [],
      },
    },
    columns: [
      {
        key: "document_code",
        headerName: "Document Code",
        fieldName: "document_code",
        enableSorting: true,
        renderType: RenderType.CHIP_WARNING,
      },
      {
        key: "signer_name",
        headerName: "Signer Name",
        fieldName: "signer_name",
        enableSorting: true,
        renderType: RenderType.TEXT,
      },
      {
        key: "email",
        headerName: "Email",
        fieldName: "email",
        enableSorting: true,
        renderType: RenderType.TEXT_DARK,
      },
      {
        key: "send_status",
        headerName: "Sent Status",
        fieldName: "send_status",
        enableSorting: true,
        renderType: RenderType.TEXT_DARK,
      },
      {
        key: "read_status",
        headerName: "Read Status",
        fieldName: "read_status",
        enableSorting: true,
        renderType: RenderType.TEXT_DARK,
      },
      {
        key: "signed_status",
        headerName: "Signed Status",
        fieldName: "signed_status",
        enableSorting: true,
        renderType: RenderType.TEXT_DARK,
      },
      {
        key: "signed_at",
        headerName: "Signed At",
        fieldName: "signed_at",
        enableSorting: true,
        renderType: RenderType.DATE_TIME,
        exportCellWidth: 25,
      },
      // {
      //   key: "expired",
      //   headerName: "Email",
      //   fieldName: "email",
      //   enableSorting: true,
      //   renderType: RenderType.TEXT_DARK,
      // },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "insert_ts",
        headerName: "Date Created",
        fieldName: "insert_ts",
        enableSorting: true,
        renderType: RenderType.DATE_TIME,
        exportCellWidth: 25,
      },
      {
        key: "resent",
        headerName: "Send Email",
        fieldName: "",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 25,
        isLastColumnSticky: true,
        onRowCellRender: (value, row: any) => {
          return (
            <Button
              variant="contained"
              disabled={document.status !== "PENDING"}
              onClick={() => {
                dispatch(
                  resendEmailToRecipient(
                    row.recipient_code,
                    `Signature Requested by ${firstName} ${
                      lastName ? lastName : ""
                    }`
                  )
                );
              }}
            >
              Send Email
            </Button>
          );
        },
      },
    ],
    items: recipientList,
  };
  React.useEffect(() => {
    fetchList();
  }, []);

  React.useEffect(() => {
    return () => {
      dispatch(clearG4SignRecipient());
    };
  }, []);

  return (
    <Dialog
      open={open}
      title="Signer"
      onClose={onClose}
      size="xl"
      actions={[
        {
          type: "button",
          label: "Close",
          variant: "contained",
          onClick: onClose,
        },
      ]}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <DataTable {...tableProps} />
        </Grid>
      </Grid>
    </Dialog>
  );
};
