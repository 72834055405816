import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import {
  ILifeInsurancePolicy,
  defaultLifeInsurancePolicy,
} from "./lifeInsurancePolicies.types";

export const FETCH_LIFE_INSURANCE_POLICIES_PROGRESS =
  "FETCH_LIFE_INSURANCE_POLICIES_PROGRESS";
export const FETCH_LIFE_INSURANCE_POLICIES_SUCCESS =
  "FETCH_LIFE_INSURANCE_POLICIES_SUCCESS";
export const FETCH_LIFE_INSURANCE_POLICIES_FAILED =
  "FETCH_LIFE_INSURANCE_POLICIES_FAILED";

export const fetchLifeInsurancePoliciesProgress = () =>
  action(FETCH_LIFE_INSURANCE_POLICIES_PROGRESS);
export const fetchLifeInsurancePoliciesSuccess = (data: ILifeInsurancePolicy) =>
  action(FETCH_LIFE_INSURANCE_POLICIES_SUCCESS, { data });
export const fetchLifeInsurancePoliciesFailed = (errorMessage: string) =>
  action(FETCH_LIFE_INSURANCE_POLICIES_FAILED, { errorMessage });

export const fetchLifeInsurancePoliciesAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchLifeInsurancePoliciesProgress());
      const res = await api.get(
        `/policy/get-life-policy-policies?customer_policy_id=${customerPolicyId}`
      );
      const data: ILifeInsurancePolicy[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchLifeInsurancePoliciesSuccess(data[0]));
      } else {
        dispatch(
          fetchLifeInsurancePoliciesSuccess(defaultLifeInsurancePolicy.data)
        );
      }
    } catch (err: any) {
      dispatch(fetchLifeInsurancePoliciesFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addLifeInsurancePoliciesAsync =
  (
    policy: ILifeInsurancePolicy,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(`/policy/edit-life-policy-policies`, { ...policy });
      dispatch(fetchLifeInsurancePoliciesSuccess(policy));
      dispatch(
        showMessage({
          type: "success",
          message: "Policy saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const CLEAR_LIFE_INSURANCE_POLICIES_STATE =
  "CLEAR_LIFE_INSURANCE_POLICIES_STATE";
export const CLEAR_LIFE_INSURANCE_POLICIES = "CLEAR_LIFE_INSURANCE_POLICIES";

export const clearLifeInsurancePoliciesState = () =>
  action(CLEAR_LIFE_INSURANCE_POLICIES_STATE);

export const cleaLifeInsurancePolicies = () =>
  action(CLEAR_LIFE_INSURANCE_POLICIES);
