/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { StandadCard } from "../../../../../components/Cards";
import {
  AccountCircle,
  AdminPanelSettings,
} from "@mui/icons-material";
import { TabPanel } from "../../../../../components/Tabs/TabPanel";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../constants/enums";
import { fetchCustomerInfoAsync } from "../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";
import { useDispatchWrapper, useQueryParms } from "../../../../../hooks";
import { TabsList } from "../../../../../components/Tabs/TabsList";
import { RestrurantQuestionareList } from "./ResturantQuestionare/ResturantQuestionareList";
import { BusinessPolicyContractorList } from "./Contractor/ContractorList";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useBreadcrumbContext } from "../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { IBreadcrumbProps } from "../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { clearBusinessPolicyContractor } from "../../../../../redux/businessPolicy/businessPolicyContractor/businessPolicyContractorActions";

export const QuestionnareRoot = () => {
  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerPolicyId = data[1];
  const {getParm} = useQueryParms();
  const tabValue = getParm('tab');
  const selectedTab = tabValue ? Number(tabValue) : 0;
  
  const { addBreadcrumb } = useBreadcrumbContext();
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/policies/",
      title: "all-policies ",
    },
    {
      to: "/policies/Business%20Policy",
      title: "business",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer",
    },
    {
      title: selectedTab === 0 ? 'contractor' : 'restaurant',
    },
  ];
  const { customerInfo } = useSelector(
    (storeState: IStoreState) => storeState.business.basicDetails
  );
  const navigate = useNavigate();

  const dispatch = useDispatchWrapper();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(
      `/business-policy/${customerId}/questionnare-tab/${customerPolicyId}?tab=${newValue}`
    );
  };
  React.useEffect(() => {
    addBreadcrumb({ title: "", items: BCrumb });
    return () => {
      dispatch(clearBusinessPolicyContractor());
    };
  }, [tabValue]);
  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  return (
    <PageLoader
      loading={customerInfo.loading === LoadState.InProgress}
      error={customerInfo.error ? { message: customerInfo.error } : null}
    >
      <StandadCard>
        <TabsList
          sx={{ mb: 1 }}
          varaint="fullWidth"
          selectedTab={selectedTab}
          tabs={[
            { label: "Contractor", icon: <AccountCircle /> },
            {
              label: "Restaurant",
              icon: <AdminPanelSettings />,
            },
          ]}
          onChange={handleChange}
        />
              <TabPanel value={selectedTab} index={0}>
          <BusinessPolicyContractorList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <RestrurantQuestionareList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
  
      </StandadCard>
    </PageLoader>
  );
};
