import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import {
  IBusinessGarageCertificate,
  initialBusinessGarageCertificate,
} from "./businessGarageCertificate.types";

export const FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_PROGRESS =
  "FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_PROGRESS";
export const FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_SUCCESS =
  "FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_SUCCESS";
export const FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_FAILED =
  "FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_FAILED";

export const fetchBusinessGarageCertiicateProgress = () =>
  action(FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_PROGRESS);
export const fetchBusineGarageCertiicateSuccess = (
  data: IBusinessGarageCertificate
) => action(FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_SUCCESS, { data });
export const fetchBusinessGarageCertiicatefailed = () =>
  action(FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_FAILED);

export const fetchBusinessGarageCertiicateAsync =
  (
    customerPolicyId: number,
    garageNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessGarageCertiicateProgress());
      const res = await api.get(
        `/policy/get-policy-add-garage-insurance-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${garageNumber}`
      );
      const data: IBusinessGarageCertificate[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBusineGarageCertiicateSuccess(data[0]));
      } else {
        dispatch(
          fetchBusineGarageCertiicateSuccess(initialBusinessGarageCertificate)
        );
      }
    } catch (err: any) {
      dispatch(fetchBusinessGarageCertiicatefailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addBusinessGarageCertiicateAsync =
  (
    data: IBusinessGarageCertificate,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post(`/policy/edit-policy-add-garage-insurance-certificate`, {
          ...data,
          incremental_number: null,
        });
      } else {
        await api.post(`/policy/edit-policy-add-garage-insurance-certificate`, {
          ...data,
        });
      }
      dispatch(
        showMessage({
          type: "success",
          message: "Property insurance certificate saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchBusineGarageCertiicateSuccess(data));
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_LIST_PROGRESS =
  "FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_LIST_PROGRESS";
export const FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_LIST_SUCCESS =
  "FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_LIST_SUCCESS";
export const FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_LIST_FAILED =
  "FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_LIST_FAILED";

export const fetchBusinessGarageCertificateListProgress = () =>
  action(FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_LIST_PROGRESS);
export const fetchBusinessGarageCertificateListSuccess = (
  data: IBusinessGarageCertificate[]
) => action(FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_LIST_SUCCESS, { data });
export const fetchBusinessGarageCertificateListFailed = () =>
  action(FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_LIST_FAILED);

export const fetchBusinessGarageCertificateListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessGarageCertificateListProgress());
      const res = await api.get(
        `/policy/get-policy-add-garage-insurance-certificate?customer_policy_id=${customerPolicyId}`
      );
      const data: IBusinessGarageCertificate[] = res.data.data;
      dispatch(fetchBusinessGarageCertificateListSuccess(data));
    } catch (err: any) {
      dispatch(fetchBusinessGarageCertificateListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const downloadBusinessGarageCertificateListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-garage-insurance-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchBusinessGarageCertificateListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewBusinessGarageCertificateListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-garage-insurance-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_garage_insurance_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchBusinessGarageCertificateListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addBusinessGarageCertificateListAsync =
  (
    GarageCertificate: IBusinessGarageCertificate,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        `/policy/edit-policy-add-garage-insurance-certificate`,
        GarageCertificate
      );
      dispatch(
        showMessage({
          type: "success",
          message: "Garage Certificate Insurance saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_BUSINESS_POLICY_GARAGE_CERTIFICATE_STATE =
  "CLEAR_BUSINESS_POLICY_GARAGE_CERTIFICATE_STATE";

export const clearBusinessPolicyGarageCertiicateState = () =>
  action(CLEAR_BUSINESS_POLICY_GARAGE_CERTIFICATE_STATE);
