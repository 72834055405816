import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialBasicDetails } from "./businessPolicyBasicDetails.types";
import { PolicyBasicAction } from ".";
import {
  ADD_CLAIM,
  ADD_CUSTOMER_INFO,
  ADD_POLICY_ADMIN,
  ADD_PREMIUM,
  ADD_PRIOR_POLICY,
  CLAER_BUSINESS_POLICY_ATTACHMENT_STATE,
  CLEAR_BASIC_DETAILS_STATE,
  FETCH_BUSINESS_ATTACHMENT_FAILED,
  FETCH_BUSINESS_ATTACHMENT_LIST_FAILED,
  FETCH_BUSINESS_ATTACHMENT_LIST_PROGRESS,
  FETCH_BUSINESS_ATTACHMENT_LIST_SUCCESS,
  FETCH_BUSINESS_ATTACHMENT_PROGRESS,
  FETCH_BUSINESS_ATTACHMENT_SUCCESS,
  FETCH_BUSINESS_POLICY_ADMIN_MANAGER_FAILED,
  FETCH_BUSINESS_POLICY_ADMIN_MANAGER_PROGRESS,
  FETCH_BUSINESS_POLICY_ADMIN_MANAGER_SUCCESS,
  FETCH_CLAIM_FAILED,
  FETCH_CLAIM_PROGRESS,
  FETCH_CUSTOMER_INFO_FAILED,
  FETCH_CUSTOMER_INFO_PROGRESS,
  FETCH_POLICY_ADMIN_FAILED,
  FETCH_POLICY_ADMIN_PROGRESS,
  FETCH_PREMIUM_FAILED,
  FETCH_PREMIUM_PROGRESS,
  FETCH_PRIOR_POLICY_FAILED,
  FETCH_PRIOR_POLICY_PROGRESS,
} from "./businessPolicyBasicDetailsActions";

export const businesspolicBasicDetailsReducer = (
  state: IStoreState["business"]["basicDetails"] = initialBasicDetails,
  action: PolicyBasicAction
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_INFO_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.customerInfo.loading = LoadState.InProgress;
      });
      return newState;
    }
    case ADD_CUSTOMER_INFO: {
      const { customerInfo, customerPolicyId } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.customerInfo.loading = LoadState.Loaded;
        draftState.customerInfo.data = customerInfo;
        draftState.customerPoliycId = customerPolicyId;
      });
      return newState;
    }
    case FETCH_CUSTOMER_INFO_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.customerInfo.loading = LoadState.Failed;
        draftState.customerInfo.error = errorMessage;
      });
      return newState;
    }

    case FETCH_POLICY_ADMIN_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.policyAdmin.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_POLICY_ADMIN_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.policyAdmin.loading = LoadState.Failed;
      });
      return newState;
    }
    case ADD_POLICY_ADMIN: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.policyAdmin.loading = LoadState.Loaded;
        draftState.policyAdmin.data = data;
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_ADMIN_MANAGER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.policyAdminManager.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_ADMIN_MANAGER_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.policyAdminManager.loading = LoadState.Loaded;
        draftState.policyAdminManager.data = data;
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_ADMIN_MANAGER_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.policyAdminManager.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_CLAIM_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.claim.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CLAIM_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.claim.loading = LoadState.Failed;
      });
      return newState;
    }
    case ADD_CLAIM: {
      const { claim } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.claim.loading = LoadState.Loaded;
        draftState.claim.data = claim;
      });
      return newState;
    }

    case FETCH_PREMIUM_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.premium.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PREMIUM_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.premium.loading = LoadState.Failed;
      });
      return newState;
    }
    case ADD_PREMIUM: {
      const { premium } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.premium.loading = LoadState.Loaded;
        draftState.premium.data = premium;
      });
      return newState;
    }

    case FETCH_PRIOR_POLICY_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.priorPolicy.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PRIOR_POLICY_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.priorPolicy.loading = LoadState.Failed;
      });
      return newState;
    }
    case ADD_PRIOR_POLICY: {
      const { priorPolicy } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.priorPolicy.loading = LoadState.Loaded;
        draftState.priorPolicy.data = priorPolicy;
      });
      return newState;
    }


    case FETCH_BUSINESS_ATTACHMENT_PROGRESS: {
      const newState = produce(state, (draftState : any) => {
        draftState.attachment.loading = LoadState.InProgress;
        draftState.attachment.data =
        initialBasicDetails["attachment"]["data"];
        draftState.attachment.error = null;
      });
      return newState;
    }
    case FETCH_BUSINESS_ATTACHMENT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState : any) => {
        draftState.attachment.loading = LoadState.Loaded;
        draftState.attachment.data = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_ATTACHMENT_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState : any) => {
        draftState.attachment.loading = LoadState.Failed;
        draftState.attachment.error = errorMessage;
      });
      return newState;
    }

    case FETCH_BUSINESS_ATTACHMENT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.attachment.listLoading = LoadState.InProgress;
        draftState.attachment.list = [];
      });
      return newState;
    }
    case FETCH_BUSINESS_ATTACHMENT_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.attachment.listLoading = LoadState.Loaded;
        draftState.attachment.list = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_ATTACHMENT_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.attachment.listLoading = LoadState.Failed;
        draftState.attachment.list = [];
      });
      return newState;
    }

    case CLAER_BUSINESS_POLICY_ATTACHMENT_STATE: {
      const newState = produce(state, (draftState) => {
        draftState.attachment= initialBasicDetails['attachment']
      });
      return newState;
    }


    case CLEAR_BASIC_DETAILS_STATE: {
      return initialBasicDetails;
    }

    default: {
      return state;
    }
  }
};
