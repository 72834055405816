import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialTransactionTypeState } from "./TransactionType.types";
import { AccountingTransactionTypeActions } from ".";
import {
  CLEAR_TRANSACTION_TYPE,
  FETCH_TRANSACTION_TYPE_FAILURE,
  FETCH_TRANSACTION_TYPE_LIST_FAILURE,
  FETCH_TRANSACTION_TYPE_LIST_PROGRESS,
  FETCH_TRANSACTION_TYPE_LIST_SUCCESS,
  FETCH_TRANSACTION_TYPE_PROGRESS,
  FETCH_TRANSACTION_TYPE_SUCCESS,
} from "./TransactionTypeActions";

export const AccountingTransactioTypeReducer = (
  state: IStoreState["accounting"]["transactionType"] = initialTransactionTypeState,
  action: AccountingTransactionTypeActions
) => {
  switch (action.type) {
    case FETCH_TRANSACTION_TYPE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_TRANSACTION_TYPE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_TRANSACTION_TYPE_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case FETCH_TRANSACTION_TYPE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }

    case FETCH_TRANSACTION_TYPE_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Loaded;
        draftState.list = data;
      });
      return newState;
    }

    case FETCH_TRANSACTION_TYPE_LIST_FAILURE: {
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_TRANSACTION_TYPE: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.NotLoaded;
        draftState.data = initialTransactionTypeState.data;
        draftState.error = null;
      });
      return newState;
    }
    default:
      return state;
  }
};
