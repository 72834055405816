import React from "react";
import { CustomChip } from "../CustomChip";
import { NotificationsActive } from "@mui/icons-material";
import { capitalizeWords } from "../../helpers";

interface IActiveInActiveStatusProps {
  status: string;
  size?: "small" | "medium";
}

export const StatusRenderer: React.FC<IActiveInActiveStatusProps> = (props) => {
  const status = (props.status || "").toLowerCase();
  const size = props.size || "small";
  if (status === "active") {
    return <CustomChip color="success" content={"Active"} size={size} />;
  } else if (status === "open") {
    return <CustomChip color="success" content={"Open"} size={size} />;
  } else if (status === "closed") {
    return <CustomChip color="error" content={"Closed"} size={size} />;
  } else if (status === "re-opened") {
    return <CustomChip color="info" content={"Re-Opened"} size={size} />;
  } else if (status === "inactive") {
    return <CustomChip color="error" content={"Inactive"} size={size} />;
  } else if (status === "new submission") {
    return (
      <CustomChip color="success" content={"New Submission"} size={size} />
    );
  } else if (status === "quote received") {
    return (
      <CustomChip
        color="warning"
        content={"Quote Received"}
        sx={{ color: "#000" }}
        size={size}
      />
    );
  } else if (status === "remote work") {
    return <CustomChip color="secondary" content={"Remote Work"} size={size} />;
  } else if (status === "on-leave") {
    return (
      <CustomChip color="info" content={"On-Leave"} size="small" />
    );
  }


  else if (status === "completed") {
    return (
      <CustomChip color="success" content={"Completed"} size="small" />
    );
  }
  else if (status === "pending") {
    return (
      <CustomChip color="error" content={"Pending"} size="small" />
    );
  }

  else if (status === "opened") {
    return (
      <CustomChip color="success" content={"Opened"} size="small" />
    );
  }
  else if (status === "closed") {
    return (
      <CustomChip color="error" content={"Closed"} size="small" />
    );
  }
  else if(status === "pending_for_signature"){
    return (
      <CustomChip color="warning" content={"Pending for signature"} sx={{color: '#000'}} size="small" />
    );
  }
  else if(status === "overdue"){
    return (
      <CustomChip color="error" content={"Overdue"}  size="small" />
    );
  }


  else if (status === "completed") {
    return (
      <CustomChip color="success" content={"Completed"} size="small" />
    );
  }
  else if (status === "pending") {
    return (
      <CustomChip color="warning" content={"Pending"} size="small" />
    );
  }

  else if (status === "opened") {
    return (
      <CustomChip color="success" content={"Opened"} size="small" />
    );
  }
  else if (status === "closed") {
    return (
      <CustomChip color="error" content={"Closed"} size="small" />
    );
  }
  else if(status === "pending_for_signature"){
    return (
      <CustomChip color="warning" content={"Pending for signature"} sx={{color: '#000'}} size="small" />
    );
  }
  else if(status === "overdue"){
    return (
      <CustomChip color="error" content={"Overdue"}  size="small" />
    );
  }

  else if (status === "completed") {
    return (
      <CustomChip color="success" content={"Completed"} size="small" />
    );
  }
  else if (status === "pending") {
    return (
      <CustomChip color="warning" content={"Pending"} size="small" />
    );
  }

  else if (status === "opened") {
    return (
      <CustomChip color="success" content={"Opened"} size="small" />
    );
  }
  else if (status === "closed") {
    return (
      <CustomChip color="error" content={"Closed"} size="small" />
    );
  }
  else if(status === "pending_for_signature"){
    return (
      <CustomChip color="warning" content={"Pending for signature"} sx={{color: '#000'}} size="small" />
    );
  }
  else if(status === "overdue"){
    return (
      <CustomChip color="error" content={"Overdue"}  size="small" />
    );
  }



  else if (status === "completed") {
    return (
      <CustomChip color="success" content={"Completed"} size="small" />
    );
  }
  else if (status === "pending") {
    return (
      <CustomChip color="warning" content={"Pending"} size="small" />
    );
  }

  else if (status === "opened") {
    return (
      <CustomChip color="success" content={"Opened"} size="small" />
    );
  }
  else if (status === "closed") {
    return (
      <CustomChip color="error" content={"Closed"} size="small" />
    );
  }
  else if(status === "pending_for_signature"){
    return (
      <CustomChip color="warning" content={"Pending for signature"} sx={{color: '#000'}} size="small" />
    );
  }
  else if(status === "overdue"){
    return (
      <CustomChip color="error" content={"Overdue"}  size="small" />
    );
  }

  else if (status === "completed") {
    return (
      <CustomChip color="success" content={"Completed"} size="small" />
    );
  }
  else if (status === "pending") {
    return (
      <CustomChip color="warning" content={"Pending"} size="small" />
    );
  }

  else if (status === "opened") {
    return (
      <CustomChip color="success" content={"Opened"} size="small" />
    );
  }
  else if (status === "closed") {
    return (
      <CustomChip color="error" content={"Closed"} size="small" />
    );
  }
  


  else if (status === "completed") {
    return (
      <CustomChip color="success" content={"Completed"} size={size} />
    );
  }
  else if (status === "pending") {
    return (
      <CustomChip color="warning" content={"Pending"} size={size} />
    );
  }

  else if (status === "opened") {
    return (
      <CustomChip color="success" content={"Opened"} size={size} />
    );
  }
  else if (status === "closed") {
    return (
      <CustomChip color="error" content={"Closed"} size={size} />
    );
  }
  else if(status === "pending_for_signature"){
    return (
      <CustomChip color="warning" content={"Pending for signature"} sx={{color: '#000'}} size={size} />
    );
  }
  else if(status === "overdue"){
    return (
      <CustomChip color="error" content={"Overdue"}  size={size} />
    );
  }
  else if(status === "spare"){
    return (
      <CustomChip color="success" content={"Spare"}  size={size} />
    );
  }
  else if(status === "assigned"){
    return (
      <CustomChip color="primary" content={"Assigned"}  size={size} />
    );
  }
  else if(status === "discarded"){
    return (
      <CustomChip color="error" content={"Discarded"}  size={size} />
    );
  }
  else if(status === "draft"){
    return (
      <CustomChip color="warning" sx={{color: '#000'}} content={"Draft"}  size={size} />
    );
  }
  else if(status === "paid"){
    return (
      <CustomChip color="success" content={"Paid"}  size={size} />
    );
  }
  else if(status === "unpaid"){
    return (
      <CustomChip color="error" content={"Unpaid"}  size={size} />
    );
  }
  else if(status === "partially paid"){
    return (
      <CustomChip color="primary" content={"Partially Paid"}  size={size} />
    );
  }
  else {
    return <CustomChip color="info" content={capitalizeWords(status) || "N/A"} size={size} />;
  }
};
