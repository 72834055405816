import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialCommAutomoblieCSV } from "./commAutoCSV.types";
import { CommAutoCSVAction } from ".";
import {
  CLEAR_COMM_AUTOMOBILE_CSV_STATE,
  FETCH_COMM_AUTOMOBILE_CSV_DETAILS_FAILED,
  FETCH_COMM_AUTOMOBILE_CSV_DETAILS_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_CSV_DETAILS_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_CSV_DETAILS_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_CSV_DETAILS_PROGRESS,
  FETCH_COMM_AUTOMOBILE_CSV_DETAILS_SUCCESS,
} from "./commAutoCSVActions";

export const CommAutomobileCSVReducer = (
  state: IStoreState["commAuto"]["csv"] = initialCommAutomoblieCSV,
  action: CommAutoCSVAction
) => {
  switch (action.type) {
    case FETCH_COMM_AUTOMOBILE_CSV_DETAILS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.details.loading = LoadState.InProgress;
        draftState.details.data = initialCommAutomoblieCSV.details.data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_CSV_DETAILS_SUCCESS: {
      const newState = produce(state, (draftState) => {
        draftState.details.loading = LoadState.Loaded;
        draftState.details.data = action.payload.data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_CSV_DETAILS_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.details.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_CSV_DETAILS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.details.ListLoading = LoadState.InProgress;
        draftState.details.detailsList = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_CSV_DETAILS_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.details.ListLoading = LoadState.Loaded;
        draftState.details.detailsList = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_CSV_DETAILS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.details.ListLoading = LoadState.Failed;
        draftState.details.detailsList = [];
      });
      return newState;
    }
    case CLEAR_COMM_AUTOMOBILE_CSV_STATE: {
      const newState = produce(state, (draftState) => {
        draftState.details.loading = LoadState.NotLoaded;
        draftState.details.data = initialCommAutomoblieCSV["details"]["data"];
        draftState.csvUse.loading = LoadState.NotLoaded;
        draftState.csvUse.csvtable =
          initialCommAutomoblieCSV["csvUse"]["csvtable"];
        draftState.csv3.loading = LoadState.NotLoaded;
        draftState.csv3.csvtable = initialCommAutomoblieCSV["csv3"]["csvtable"];
      });
      return newState;
    }
    default: {
      return state;
    }
  }
};
