import produce from "immer";
import { EmailStatisticsActions } from ".";
import { IStoreState } from "../initialStoreState";
import { initialEmailStatisticsState } from "./emailStatistics.types";
import { CLEAR_EMAIL_STATISTICS, FETCH_EMAIL_STATISTICS_LIST_FAILED, FETCH_EMAIL_STATISTICS_LIST_PROGRESS, FETCH_EMAIL_STATISTICS_LIST_SUCCESS } from "./emailStatisticsActions";
import { LoadState } from "../../constants/enums";



export const emailStatisticsReducer = (
    state: IStoreState["emailStatistics"] = initialEmailStatisticsState,
    action: EmailStatisticsActions
  ) => {
    switch (action.type) {
      case FETCH_EMAIL_STATISTICS_LIST_PROGRESS: {
        const newState = produce(state, (draftState) => {
          draftState.loading = LoadState.InProgress;
          draftState.list = [];
        });
        return newState;
      }
      case FETCH_EMAIL_STATISTICS_LIST_SUCCESS: {
        const { data } = action.payload;
        const newState = produce(state, (draftState) => {
          draftState.loading = LoadState.Loaded;
          draftState.list = data;
        });
        return newState;
      }
      case FETCH_EMAIL_STATISTICS_LIST_FAILED: {
        const newState = produce(state, (draftState) => {
          draftState.loading = LoadState.Failed;
          draftState.list = [];
        });
        return newState;
      }
  
      case CLEAR_EMAIL_STATISTICS: {
        return initialEmailStatisticsState;
      }
  
      default: {
        return state;
      }
    }
  };