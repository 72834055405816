import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { PolicyCancellationForm } from "../../Cancellation/PolicyCancellationForm";
import { useCommAutoPremium } from "../../../../../../hooks/useCommAutoPremium";
import { POLICIES_NAMES } from "../../../../../../constants/enums";
import { useCommAutoCustomerBasicDetails } from "../../../../../../hooks";

export const CommAutoCancellation: React.FC = () => {
  const { taskCode } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
    taskCode?: string;
  };

  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerPolicyId = data[1];

  const customerBasicDetail = useCommAutoCustomerBasicDetails(customerPolicyId);
  const premium = useCommAutoPremium(customerPolicyId);
  const agencyBill = premium.data.agency_bill_or_direct_bill;
  const subType = customerBasicDetail.data.sub_type;
  console.log("subType", subType);
  

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(
      `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}?tab=6`
    );
  };
  return (
    <>
      {taskCode && (
        <PolicyCancellationForm
          customerId={customerId}
          customerPolicyId={customerPolicyId}
          taskCode={taskCode}
          handleNavigate={handleNavigate}
          agencyBill={agencyBill}
          policy={POLICIES_NAMES.COMMERCIAL_AUTO_POLICY}
          lineOfBusiness={customerBasicDetail.data.line_of_business}
          subType={subType}
          policyNumber={customerBasicDetail.data.policy_number}
        />
      )}
    </>
  );
};
