/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line react/jsx-pascal-case

import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { useDispatchWrapper, useQueryParms } from "../../../../../hooks";
import { fetchCustomerInfoAsync } from "../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";
import { PageLoader } from "../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../constants/enums";
import { StandadCard } from "../../../../../components/Cards";
import { TabsList } from "../../../../../components/Tabs/TabsList";
import { AccountCircle, AdminPanelSettings } from "@mui/icons-material";
import { TabPanel } from "../../../../../components/Tabs/TabPanel";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useBreadcrumbContext } from "../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { HabitationalNonCsioBinderList } from "./HabitationalBinderNonCSIO/HabitationalNonBinderCsioList";
import { HabitationalBinderCsioList } from "./HabitationalBinderCSIO/HabitationalBinderCsioList";
import { fetchHabitationalCustomerInfoAsync } from "../../../../../redux/habitationalPolicy/habitationalBasicDetails/habitationalBasicDetailsActions";


export const HabitationalBinderRoot= () => {

  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerPolicyId = data[1];
  const { addBreadcrumb } = useBreadcrumbContext();
  const { getParm } = useQueryParms();
  const tabValue = getParm("tab");
  const selectedTab = tabValue ? Number(tabValue) : 0;

  const { customerInfo } = useSelector(
    (storeState: IStoreState) => storeState.habitational.basicDetails
  );

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(
      `/habitational-policy/${customerId}/binder-csio-tab/${customerPolicyId}?tab=${newValue}`
    );
  };

  React.useEffect(() => {
    if (customerPolicyId) {
    //   dispatch(fetchBindersListAsync(customerPolicyId));
      dispatch(fetchHabitationalCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  React.useEffect(() => {
    addBreadcrumb({
      title: "",
      items: [
        {
          to: "/dashboard",
          title: "dashboard",
        },
        {
          to: "/view-customers",
          title: "customers",
        },
        {
          to: "/customer-overview/" + customerId,
          title: "customer-overview",
        },
        {
          to:
            "/habitational-policy/habitational/" +
            customerId +
            "/" +
            customerPolicyId,
          title: "binder",
        },
      ],
    });
  }, []);


  return (
    <PageLoader
      loading={customerInfo.loading === LoadState.InProgress}
      error={customerInfo.error ? { message: customerInfo.error } : null}
    >
      <StandadCard>
        <TabsList
          sx={{ mb: 1 }}
          varaint="fullWidth"
          selectedTab={selectedTab}
          tabs={[
            {
                label: "Binder",
                icon: <AdminPanelSettings />,
            },
            {
              label: "Binder Non-CSIO",
              icon: <AccountCircle />,
            },
            
          ]}
          onChange={handleChange}
        />
        <TabPanel value={selectedTab} index={0}>
          <HabitationalBinderCsioList
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <HabitationalNonCsioBinderList
          />
        </TabPanel>
      </StandadCard>
    </PageLoader>
  );
};
