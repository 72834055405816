import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { TaxInformationActions } from ".";
import { initialAccountingTaxInformationState } from "./taxInformation.types";
import {
  FETCH_ACCOUNTING_TAX_INFORMATION_FAILURE,
  FETCH_ACCOUNTING_TAX_INFORMATION_PROGRESS,
  FETCH_ACCOUNTING_TAX_INFORMATION_SUCCESS,
} from "./taxInformationAction";

export const AccountingTaxInformationReducer = (
  state: IStoreState["accounting"]["accountingTaxInformation"] = initialAccountingTaxInformationState,
  action: TaxInformationActions
) => {
  switch (action.type) {
    case FETCH_ACCOUNTING_TAX_INFORMATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_ACCOUNTING_TAX_INFORMATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_ACCOUNTING_TAX_INFORMATION_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.error = errorMessage;
      });
      return newState;
    }
    default:
      return state;
  }
};
