import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import {
  IAccountingCheque,
  initialAccountingChequeState,
} from "./Cheque.types";
import { IDate } from "../../../components/Table/hooks/useDateFilter";

export const FETCH_ACCOUNTING_CHEQUE_LIST_PROGRESS =
  "FETCH_ACCOUNTING_CHEQUE_LIST_PROGRESS";
export const FETCH_ACCOUNTING_CHEQUE_LIST_SUCCESS =
  "FETCH_ACCOUNTING_CHEQUE_LIST_SUCCESS";
export const FETCH_ACCOUNTING_CHEQUE_LIST_FAILURE =
  "FETCH_ACCOUNTING_CHEQUE_LIST_FAILURE";

export const fetchAccountingChequeListProgress = () =>
  action(FETCH_ACCOUNTING_CHEQUE_LIST_PROGRESS);

export const fetchAccountingChequeListSuccess = (data: IAccountingCheque[]) =>
  action(FETCH_ACCOUNTING_CHEQUE_LIST_SUCCESS, { data });

export const fetchAccountingChequeListFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_CHEQUE_LIST_FAILURE, { errorMessage });

export const fetchAccountingChequeListAsync =
  (
    searchType: string[],
    search: string,
    status: string,
    date: IDate,
    pageNumber: number,
    rowsInPerPage: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingChequeListProgress());
      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }
      let finalUrl = `/accounting/get-account-cheque?from_date=${date.fromDate}&to_date=${date.toDate}&pageNumber=${pageNumber}&rowsInPerPage=${rowsInPerPage}${url}`;
      if (searchType.length > 0 && search !== "") {
        finalUrl = finalUrl + `&column=${searchType}&value=${search}`;
      }
      const res = await api.get(finalUrl);
      const data: IAccountingCheque[] = res.data.data;
      dispatch(fetchAccountingChequeListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAccountingChequeListFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_ACCOUNTING_CHEQUE_PROGRESS =
  "FETCH_ACCOUNTING_CHEQUE_PROGRESS";
export const FETCH_ACCOUNTING_CHEQUE_SUCCESS =
  "FETCH_ACCOUNTING_CHEQUE_SUCCESS";
export const FETCH_ACCOUNTING_CHEQUE_FAILURE =
  "FETCH_ACCOUNTING_CHEQUE_FAILURE";

export const fetchAccountingChequeProgress = () =>
  action(FETCH_ACCOUNTING_CHEQUE_PROGRESS);

export const fetchAccountingChequeSuccess = (data: IAccountingCheque) =>
  action(FETCH_ACCOUNTING_CHEQUE_SUCCESS, { data });

export const fetchAccountingChequeFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_CHEQUE_FAILURE, { errorMessage });

export const fetchAccountingChequeAsync =
  (paymentId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingChequeProgress());

      const res = await api.get(
        `/accounting/get-account-cheque?payment_id=${paymentId}`
      );
      const data: IAccountingCheque[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAccountingChequeSuccess(data[0]));
      } else {
        dispatch(
          fetchAccountingChequeSuccess(initialAccountingChequeState["data"])
        );
      }
    } catch (err: any) {
      dispatch(fetchAccountingChequeFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingChequeAysnc =
  (
    data: IAccountingCheque,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/accounting/edit-account-cheque", {
        ...data,
      });
      let message = "Cheque saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };



export const CLEAR_ACCOUNTING_CHEQUE = "CLEAR_ACCOUNTING_CHEQUE";
export const clearAccountingCheque = () => action(CLEAR_ACCOUNTING_CHEQUE);
