import React from "react";
import { useDispatchWrapper } from "../../hooks";

import { useSelector } from "react-redux";
import { IStoreState } from "../../redux/initialStoreState";
import { ControlledCustomSelect, CustomTextField } from "../formsComponents";
import {
  Autocomplete as MUIAutoComplete,
  CircularProgress,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { LoadState } from "../../constants/enums";
import { formatText } from "../../helpers";

export const AutoComplete: React.FC<{
  value: any;
  placeholder?: string;
  helperText?: string;
  disabled?: boolean;
  options: { label: string; value: string }[];
  fullWidth?: boolean;
  error?: boolean;
  onChange?: (value: any) => void;
}> = (props) => {
  const { value, placeholder, helperText, options, onChange, disabled=false, fullWidth, error = false, } = props;

  


  return (
    <>
      <MUIAutoComplete
        sx={{
          width: fullWidth ? "100%" : "auto",
          ".MuiOutlinedInput-root": {
            paddingTop: "2px",
            paddingBottom: "2px",
            fontSize: "0.8rem",
            color: "rgb(38, 38, 38)",
          },
        }}
        key={options.length}
        value={value ? options.find((x) => x.value.toLowerCase()=== value.toLowerCase()): null}
        options={[...options]}
     
        // groupBy={(option) => option.}
        autoHighlight
        getOptionLabel={(option) => option.label}
        onChange={(e, newValue) => {
          if (onChange) {
            if (newValue) {
              onChange(newValue.value);
            } else {
              onChange(null);
            }
          }
        }}
        disabled={disabled}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            fullWidth
            placeholder={placeholder}
            disabled={disabled}
            helperText={helperText}
            error={error}
            InputProps={{
              ...params.InputProps,
              // endAdornment: (
              //   <React.Fragment>
              //     {loading === LoadState.InProgress && <CircularProgress color="inherit" size={20} />}
              //     {params.InputProps.endAdornment}
              //   </React.Fragment>
              // ),
            }}
          />
        )}
      />
    </>
  );
};
