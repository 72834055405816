import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { IAutomobileMarketingHistory } from "./automobileMarketingHistory.types";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { ERROR_MESSAGES } from "../../constants/enums";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";

export const FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_FAILED =
  "FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_FAILED";

  export const fetchAutomobilePolicyMarketingHistListProgress = () =>
  action(FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_PROGRESS);
export const fetchAutomobilePolicyMarketingHistListSuccess = (
  data: IAutomobileMarketingHistory[]
) => action(FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_SUCCESS, { data });
export const fetchAutomobilePolicyMarketingHistListFailed = () =>
  action(FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_LIST_FAILED);

export const fetchAutomobilePolicyMarketingHistListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobilePolicyMarketingHistListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-add-marketing-history?customer_policy_id=${customerPolicyId}`
      );
      const data: IAutomobileMarketingHistory[] = res.data.data;
      dispatch(fetchAutomobilePolicyMarketingHistListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobilePolicyMarketingHistListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };


export const addAutomobilePolicyMarketingHistAsync =
  (
    data: IAutomobileMarketingHistory,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post(
        `/policy/edit-policy-commauto-add-marketing-history`,
        data
      );
      dispatch(
        showMessage({
          type: "success",
          message: "Marketing History saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_PROGRESS =
  "FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_PROGRESS";
export const FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_SUCCESS =
  "FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_SUCCESS";
export const FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_FAILED =
  "FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_FAILED";

export const fetchAutomobilePolicyMarketingHistProgress = () =>
  action(FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_PROGRESS);
export const fetchAutomobilePolicyMarketingHistSuccess = (
  data: IAutomobileMarketingHistory
) => action(FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_SUCCESS, { data });
export const fetchAutomobilePolicyMarketingHistFailed = (
  errorMessage: string
) => action(FETCH_AUTOMOBILE_POLICY_MARKETING_HISTORY_FAILED, { errorMessage });

export const fetchAutomobilePolicyMarketingHistAsync =
  (
    customerPolicyId: number,
    incrementNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobilePolicyMarketingHistProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-add-marketing-history?customer_policy_id=${customerPolicyId}&value=${incrementNumber}`
      );
      const data: IAutomobileMarketingHistory[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAutomobilePolicyMarketingHistSuccess(data[0]));
      } else {
        dispatch(
          fetchAutomobilePolicyMarketingHistFailed(
            ERROR_MESSAGES.POLICY_NOT_FOUND
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchAutomobilePolicyMarketingHistFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_POLICY_MARKETING_STATE =
  "CLEAR_AUTOMOBILE_POLICY_MARKETING_STATE";
export const CLEAR_AUTOMOBILE_POLICY_MARKETING_HISTORY =
  "CLEAR_AUTOMOBILE_POLICY_MARKETING_HISTORY";

export const clearAutonmobilePolicyMarketingState = () =>
  action(CLEAR_AUTOMOBILE_POLICY_MARKETING_STATE);
export const clearAutomobilePolicyMarketingHistory = () =>
  action(CLEAR_AUTOMOBILE_POLICY_MARKETING_HISTORY);
