import { action } from "typesafe-actions";
import {
  IGeneralChange,
  ITaskAddRiskLocation,
  ITaskAddVehicle,
  ITaskAddressChange,
  ITaskBusinessChange,
  ITaskChangeRiskLocation,
  ITaskCoverageChange,
  ITaskDeleteRiskLocation,
  ITaskDeleteVehicle,
  ITaskPolicySubmission,
  initialGeneralState,
} from "./generalChange.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { uploadFile, uploadTaskFile } from "../../helpers";

export const FETCH_TASK_GENERAL_CHANGE_PROGRESS =
  "FETCH_TASK_GENERAL_CHANGE_PROGRESS";
export const FETCH_TASK_GENERAL_CHANGE_SUCCESS =
  "FETCH_TASK_GENERAL_CHANGE_SUCCESS";
export const FETCH_TASK_GENERAL_CHANGE_FAILED =
  "FETCH_TASK_GENERAL_CHANGE_FAILED";

export const fetchTaskGeneralChangeProgress = () =>
  action(FETCH_TASK_GENERAL_CHANGE_PROGRESS);
export const fetchTaskGeneralChangeSuccess = (data: IGeneralChange) =>
  action(FETCH_TASK_GENERAL_CHANGE_SUCCESS, { data });
export const fetchTaskGeneralChangeFailed = () =>
  action(FETCH_TASK_GENERAL_CHANGE_FAILED);

export const fetchTaskGeneralChangeAysnc =
  (taskCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTaskGeneralChangeProgress());
      const res = await api.get(
        `/tasks/get-tasks-general-change?task_code=${taskCode}`
      );
      const data: IGeneralChange[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchTaskGeneralChangeSuccess(data[0]));
      } else {
        dispatch(
          fetchTaskGeneralChangeSuccess(
            initialGeneralState.generalChange["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchTaskGeneralChangeFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskGeneralChangeAysnc =
  (
    data: IGeneralChange,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/tasks/create-tasks-general-change", {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "General change is saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_TASK_ADDRESS_CHANGE_PROGRESS =
  "FETCH_TASK_ADDRESS_CHANGE_PROGRESS";
export const FETCH_TASK_ADDRESS_CHANGE_SUCCESS =
  "FETCH_TASK_ADDRESS_CHANGE_SUCCESS";
export const FETCH_TASK_ADDRESS_CHANGE_FAILED =
  "FETCH_TASK_ADDRESS_CHANGE_FAILED";

export const fetchTaskAddressChangeProgress = () =>
  action(FETCH_TASK_ADDRESS_CHANGE_PROGRESS);
export const fetchTaskAddressChangeSuccess = (data: ITaskAddressChange) =>
  action(FETCH_TASK_ADDRESS_CHANGE_SUCCESS, { data });
export const fetchTaskAddressChangeFailed = () =>
  action(FETCH_TASK_ADDRESS_CHANGE_FAILED);

export const fetchTaskAddressChangeAysnc =
  (taskCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTaskAddressChangeProgress());
      const res = await api.get(
        `/tasks/get-tasks-change-address?task_code=${taskCode}`
      );
      const data: ITaskAddressChange[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchTaskAddressChangeSuccess(data[0]));
      } else {
        dispatch(
          fetchTaskAddressChangeSuccess(
            initialGeneralState.addressChange["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchTaskAddressChangeFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskAddressChangeAysnc =
  (
    data: ITaskAddressChange,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/tasks/create-tasks-change-address", {
        ...data,
      });

      dispatch(
        showMessage({
          type: "success",
          message: "Task change address is saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_TASK_BUSINESS_CHANGE_PROGRESS =
  "FETCH_TASK_BUSINESS_CHANGE_PROGRESS";
export const FETCH_TASK_BUSINESS_CHANGE_SUCCESS =
  "FETCH_TASK_BUSINESS_CHANGE_SUCCESS";
export const FETCH_TASK_BUSINESS_CHANGE_FAILED =
  "FETCH_TASK_BUSINESS_CHANGE_FAILED";

export const fetchTaskBusinessChangeProgress = () =>
  action(FETCH_TASK_BUSINESS_CHANGE_PROGRESS);
export const fetchTaskBusinessChangeSuccess = (data: ITaskBusinessChange) =>
  action(FETCH_TASK_BUSINESS_CHANGE_SUCCESS, { data });
export const fetchTaskBusinessChangeFailed = () =>
  action(FETCH_TASK_BUSINESS_CHANGE_FAILED);

export const fetchTaskBusinessChangeAysnc =
  (taskCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTaskBusinessChangeProgress());
      const res = await api.get(
        `tasks/get-tasks-business-submission?task_code=${taskCode}`
      );
      const data: ITaskBusinessChange[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchTaskBusinessChangeSuccess(data[0]));
      } else {
        dispatch(
          fetchTaskBusinessChangeSuccess(
            initialGeneralState.businessChange["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchTaskBusinessChangeFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskBusinessChangeAysnc =
  (
    data: IGeneralChange,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/tasks/create-tasks-business-submission", data);
      dispatch(
        showMessage({
          type: "success",
          message: "Business submission is saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_TASK_COVERAGE_CHANGE_PROGRESS =
  "FETCH_TASK_COVERAGE_CHANGE_PROGRESS";
export const FETCH_TASK_COVERAGE_CHANGE_SUCCESS =
  "FETCH_TASK_COVERAGE_CHANGE_SUCCESS";
export const FETCH_TASK_COVERAGE_CHANGE_FAILED =
  "FETCH_TASK_COVERAGE_CHANGE_FAILED";

export const fetchTaskCoverageChangeProgress = () =>
  action(FETCH_TASK_COVERAGE_CHANGE_PROGRESS);
export const fetchTaskCoverageChangeSuccess = (data: ITaskCoverageChange) =>
  action(FETCH_TASK_COVERAGE_CHANGE_SUCCESS, { data });
export const fetchTaskCoverageChangeFailed = () =>
  action(FETCH_TASK_COVERAGE_CHANGE_FAILED);

export const fetchTaskCoverageChangeAysnc =
  (taskCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTaskCoverageChangeProgress());
      const res = await api.get(
        `tasks/get-tasks-coverage-change?task_code=${taskCode}`
      );
      const data: ITaskCoverageChange[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchTaskCoverageChangeSuccess(data[0]));
      } else {
        dispatch(
          fetchTaskCoverageChangeSuccess(
            initialGeneralState.coverageChange["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchTaskCoverageChangeFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskCoverageChangeAysnc =
  (
    data: ITaskCoverageChange,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/tasks/create-tasks-coverage-change", {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "General change is saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_TASK_POLICY_SUBMISSION_PROGRESS =
  "FETCH_TASK_POLICY_SUBMISSION_PROGRESS";
export const FETCH_TASK_POLICY_SUBMISSION_SUCCESS =
  "FETCH_TASK_POLICY_SUBMISSION_SUCCESS";
export const FETCH_TASK_POLICY_SUBMISSION_FAILED =
  "FETCH_TASK_POLICY_SUBMISSION_FAILED";

export const fetchTaskPolicySubmissionProgress = () =>
  action(FETCH_TASK_POLICY_SUBMISSION_PROGRESS);
export const fetchTaskPolicySubmissionSuccess = (data: ITaskPolicySubmission) =>
  action(FETCH_TASK_POLICY_SUBMISSION_SUCCESS, { data });
export const fetchTaskPolicySubmissionFailed = () =>
  action(FETCH_TASK_POLICY_SUBMISSION_FAILED);

export const fetchTaskPolicySubmissionAysnc =
  (taskCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTaskPolicySubmissionProgress());
      const res = await api.get(
        `tasks/get-tasks-policy-submission?task_code=${taskCode}`
      );
      const data: ITaskPolicySubmission[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchTaskPolicySubmissionSuccess(data[0]));
      } else {
        dispatch(
          fetchTaskPolicySubmissionSuccess(
            initialGeneralState.policySubmission["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchTaskPolicySubmissionFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskPolicySubmissioneAysnc =
  (
    data: IGeneralChange,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/tasks/create-tasks-general-change", data);
      dispatch(
        showMessage({
          type: "success",
          message: "General change is saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

/**--------------------------------------- */
export const FETCH_TASK_VEHICLE_CHANGE_PROGRESS =
  "FETCH_TASK_VEHICLE_CHANGE_PROGRESS";
export const FETCH_TASK_VEHICLE_CHANGE_SUCCESS =
  "FETCH_TASK_VEHICLE_CHANGE_SUCCESS";
export const FETCH_TASK_VEHICLE_CHANGE_FAILED =
  "FETCH_TASK_VEHICLE_CHANGE_FAILED";

export const fetchTaskVehcileChangeProgress = () =>
  action(FETCH_TASK_VEHICLE_CHANGE_PROGRESS);
export const fetchTaskVehcileChangeSuccess = (data: ITaskAddVehicle) =>
  action(FETCH_TASK_VEHICLE_CHANGE_SUCCESS, { data });
export const fetchTaskVehcileChangeFailed = () =>
  action(FETCH_TASK_VEHICLE_CHANGE_FAILED);

export const fetchTaskVehcileChangeAysnc =
  (taskCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTaskVehcileChangeProgress());
      const res = await api.get(
        `/tasks/get-tasks-add-vehicle?task_code=${taskCode}`
      );
      const data: ITaskAddVehicle[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchTaskVehcileChangeSuccess(data[0]));
      } else {
        dispatch(
          fetchTaskVehcileChangeSuccess(initialGeneralState.vehcile["data"])
        );
      }
    } catch (err: any) {
      dispatch(fetchTaskVehcileChangeFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskVehcileChangeAysnc =
  (
    data: ITaskAddVehicle,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/tasks/create-tasks-add-vehicle", {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Add vehicle succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_TASK_DELETE_VEHICLE_PROGRESS =
  "FETCH_TASK_DELETE_VEHICLE_PROGRESS";
export const FETCH_TASK_DELETE_VEHICLE_SUCCESS =
  "FETCH_TASK_DELETE_VEHICLE_SUCCESS";
export const FETCH_TASK_DELETE_VEHICLE_FAILED =
  "FETCH_TASK_DELETE_VEHICLE_FAILED";

export const fetchTaskDeleteVehcileProgress = () =>
  action(FETCH_TASK_DELETE_VEHICLE_PROGRESS);
export const fetchTaskDeleteVehcileSuccess = (data: ITaskDeleteVehicle) =>
  action(FETCH_TASK_DELETE_VEHICLE_SUCCESS, { data });
export const fetchTaskDeleteVehcileFailed = () =>
  action(FETCH_TASK_DELETE_VEHICLE_FAILED);

export const fetchTaskDeleteVehcileAysnc =
  (taskCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTaskDeleteVehcileProgress());
      const res = await api.get(
        `/tasks/get-tasks-delete-vehicle?task_code=${taskCode}`
      );
      const data: ITaskDeleteVehicle[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchTaskDeleteVehcileSuccess(data[0]));
      } else {
        dispatch(
          fetchTaskDeleteVehcileSuccess(
            initialGeneralState.vehcile["deleteData"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchTaskDeleteVehcileFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskDeleteVehcileAysnc =
  (
    data: ITaskDeleteVehicle,

    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/tasks/create-tasks-delete-vehicle", {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Add vehicle succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_TASK_ADD_RISK_LOCATION_PROGRESS =
  "FETCH_TASK_ADD_RISK_LOCATION_PROGRESS";
export const FETCH_TASK_ADD_RISK_LOCATION_SUCCESS =
  "FETCH_TASK_ADD_RISK_LOCATION_SUCCESS";
export const FETCH_TASK_ADD_RISK_LOCATION_FAILED =
  "FETCH_TASK_ADD_RISK_LOCATION_FAILED";

export const fetchTaskAddRiskProgress = () =>
  action(FETCH_TASK_ADD_RISK_LOCATION_PROGRESS);
export const fetchTaskAddRiskSuccess = (data: ITaskAddRiskLocation) =>
  action(FETCH_TASK_ADD_RISK_LOCATION_SUCCESS, { data });
export const fetchTaskAddRiskFailed = () =>
  action(FETCH_TASK_ADD_RISK_LOCATION_FAILED);

export const fetchTaskAddRiskAysnc =
  (taskCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTaskAddRiskProgress());
      const res = await api.get(
        `/tasks/get-tasks-add-risk-location?task_code=${taskCode}`
      );
      const data: ITaskAddRiskLocation[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchTaskAddRiskSuccess(data[0]));
      } else {
        dispatch(
          fetchTaskAddRiskSuccess(initialGeneralState.riskLoaction["data"])
        );
      }
    } catch (err: any) {
      dispatch(fetchTaskAddRiskFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskAddRiskLocationAysnc =
  (
    data: ITaskAddRiskLocation,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/tasks/create-tasks-add-risk-location", {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Add Risk location saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_TASK_DELETE_RISK_LOCATION_PROGRESS =
  "FETCH_TASK_DELETE_RISK_LOCATION_PROGRESS";
export const FETCH_TASK_DELETE_RISK_LOCATION_SUCCESS =
  "FETCH_TASK_DELETE_RISK_LOCATION_SUCCESS";
export const FETCH_TASK_DELETE_RISK_LOCATION_FAILED =
  "FETCH_TASK_DELETE_RISK_LOCATION_FAILED";

export const fetchTaskDeleteRiskProgress = () =>
  action(FETCH_TASK_DELETE_RISK_LOCATION_PROGRESS);
export const fetchTaskDeleteRiskSuccess = (data: ITaskDeleteRiskLocation) =>
  action(FETCH_TASK_DELETE_RISK_LOCATION_SUCCESS, { data });
export const fetchTaskDeleteRiskFailed = () =>
  action(FETCH_TASK_DELETE_RISK_LOCATION_FAILED);

export const fetchTaskDeleteRiskAysnc =
  (taskCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTaskDeleteRiskProgress());
      const res = await api.get(
        `/tasks/get-tasks-delete-risk-location?task_code=${taskCode}`
      );
      const data: ITaskDeleteRiskLocation[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchTaskDeleteRiskSuccess(data[0]));
      } else {
        dispatch(
          fetchTaskDeleteRiskSuccess(
            initialGeneralState.riskLoaction["deleteData"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchTaskDeleteRiskFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskDeleteRiskLocationAysnc =
  (
    data: ITaskDeleteRiskLocation,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/tasks/create-tasks-delete-risk-location", {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Delete Risk location saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_TASK_CHANGE_RISK_LOCATION_PROGRESS =
  "FETCH_TASK_CHANGE_RISK_LOCATION_PROGRESS";
export const FETCH_TASK_CHANGE_RISK_LOCATION_SUCCESS =
  "FETCH_TASK_CHANGE_RISK_LOCATION_SUCCESS";
export const FETCH_TASK_CHANGE_RISK_LOCATION_FAILED =
  "FETCH_TASK_CHANGE_RISK_LOCATION_FAILED";

export const fetchTaskChangeRiskProgress = () =>
  action(FETCH_TASK_CHANGE_RISK_LOCATION_PROGRESS);
export const fetchTaskChangeRiskSuccess = (data: ITaskChangeRiskLocation) =>
  action(FETCH_TASK_CHANGE_RISK_LOCATION_SUCCESS, { data });
export const fetchTaskChangeRiskFailed = () =>
  action(FETCH_TASK_CHANGE_RISK_LOCATION_FAILED);

export const fetchTaskChangeRiskAysnc =
  (taskCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTaskChangeRiskProgress());
      const res = await api.get(
        `/tasks/get-tasks-change-risk-location?task_code=${taskCode}`
      );
      const data: ITaskChangeRiskLocation[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchTaskChangeRiskSuccess(data[0]));
      } else {
        dispatch(
          fetchTaskChangeRiskSuccess(
            initialGeneralState.riskLoaction["changeData"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchTaskChangeRiskFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskChangeRiskLocationAysnc =
  (
    data: ITaskChangeRiskLocation,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/tasks/create-tasks-change-risk-location", {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Change Risk location saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_TASK_GENERAL_CHANGE_STATE =
  "CLEAR_TASK_GENERAL_CHANGE_STATE";

export const clearTaskGeneralChangeState = () =>
  action(CLEAR_TASK_GENERAL_CHANGE_STATE);
