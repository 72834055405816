import moment, { Moment } from "moment";
import { LoadState } from "../../constants/enums";
import { getUniqueId } from "../../helpers";

export interface IAutomobileGarageApplicationState {
  data: IAutomobileGarageApplicationData;
  error: string | null;
  loading: LoadState;
}

export interface IAutomobileGarageApplicationData {
    customer_policy_id: number;
    incremental_number: string | null;
    policy_type: string | null;
    replacing_policy_no: string | null;
    policy_number_assigned: string | null;
    language_preferred: string | null;
    insurance_company: string | null;
    broke_or_agent: string | null;
    applicant_full_name: string | null;
    applicant_full_address: string | null;
    applicant_email: string | null;
    applicant_phone: string | null;
    applicant_full_business_address_a: string | null;
    applicant_full_business_address_a_type: string | null;
    applicant_full_business_address_b: string | null;
    applicant_full_business_address_b_type: string | null;
    applicant_full_business_address_c: string | null;
    applicant_full_business_address_c_type: string | null;
    applicant_full_business_address_d: string | null;
    applicant_full_business_address_d_type: string | null;
    policy_period_effective_date: string;
    policy_period_effective_time: string;
    policy_period_expiry_date: string;
    policy_period_expiry_time: string;
    applicant_business_info: string | null;
    estimated_total_payroll_for_policy_period: string | null;
    no_of_employees_full_time: string | null;
    no_of_employees_part_time: string | null;
    policy_cancelled_in_last_3_years: string | null;
    policy_cancelled_no_of_insurer: string | null;
    policy_cancelled_policy_no: string | null;
    list_of_all_accidents_losses_or_claims:
      {
        key: string;
        Losses_date: string;
        bi: string | null;
        pd: string | null;
        ab: string | null;
        dc_pd: string | null;
        ua: string | null;
        coll: string | null;
        comp_sp: string | null;
      }[];
    insurer: string | null;
    policy_no: string | null;
    expiry_date: string;
    remarks: string | null;
    item_no: string | null;
    signature_of_broker: string | null
  }
  

export const defaultAutomobileGarageApplicationState: IAutomobileGarageApplicationState =
  {
    data: {
      customer_policy_id: 0,
      incremental_number: null,
      policy_type: null,
      replacing_policy_no: null,
      policy_number_assigned: null,
      language_preferred: null,
      insurance_company: null,
      broke_or_agent: null,
      applicant_full_name: null,
      applicant_full_address: null,
      applicant_email: null,
      applicant_phone: null,
      applicant_full_business_address_a: null,
      applicant_full_business_address_a_type: null,
      applicant_full_business_address_b: null,
      applicant_full_business_address_b_type: null,
      applicant_full_business_address_c: null,
      applicant_full_business_address_c_type: null,
      applicant_full_business_address_d: null,
      applicant_full_business_address_d_type: null,
      policy_period_effective_date: moment().format("YYYY-MM-DD"),
      policy_period_effective_time: moment().format(),
      policy_period_expiry_date: moment().format("YYYY-MM-DD"),
       policy_period_expiry_time: moment().format(),
      applicant_business_info: null,
      estimated_total_payroll_for_policy_period: null,
      no_of_employees_full_time: null,
      no_of_employees_part_time: null,
      policy_cancelled_in_last_3_years: null,
      policy_cancelled_no_of_insurer: null,
      policy_cancelled_policy_no: null,
      list_of_all_accidents_losses_or_claims: [
        {
            key: getUniqueId(),
            Losses_date: moment().format("YYYY-MM-DD"),
            bi: null,
            pd: null,
            ab: null,
            dc_pd: null,
            ua: null,
            coll: null,
            comp_sp: null,
        },
        {
            key: getUniqueId(),
            Losses_date: moment().format("YYYY-MM-DD"),
            bi: null,
            pd: null,
            ab: null,
            dc_pd: null,
            ua: null,
            coll: null,
            comp_sp: null,
        }
    ],
      insurer: null,
      policy_no: null,
      expiry_date: moment().format("YYYY-MM-DD"),
      remarks: null,
      item_no: null,
      signature_of_broker: null,
    },
    error: null,
    loading: LoadState.NotLoaded,
  };
