import { action } from "typesafe-actions";

import { IDate } from "../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { ILegal } from "./legals.types";
import { getUniqueId, uploadFile } from "../../helpers";
import { makeApiCall } from "../../helpers/postRequest";
import { endPoints } from "../../constants/endPoints";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";
import { uploadMultipleFile } from "../../components/FileUpload/utils";
import { IFileUpload } from "../../components/FileUpload/FileUpload.type";

export const FETCH_LEGALS_LIST_PROGRESS = "FETCH_LEGALS_LIST_PROGRESS";
export const FETCH_LEGALS_LIST_SUCCESS = "FETCH_LEGALS_LIST_SUCCESS";
export const FETCH_LEGALS_LIST_FAILED = "FETCH_LEGALS_LIST_FAILED";

export const fetchLegalsListProgress = () => action(FETCH_LEGALS_LIST_PROGRESS);
export const fetchLegalsListSuccess = (list: ILegal[], totalRecords: number) =>
  action(FETCH_LEGALS_LIST_SUCCESS, { list, totalRecords });
export const fetchLegalsListFailed = () => action(FETCH_LEGALS_LIST_FAILED);

export const fetchLegalsListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchLegalsListProgress());
      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }

      let finalUrl = `/other/get-legal-plus?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;

      if (searchValue.length > 0) {
        finalUrl = `/other/get-legal-plus?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      const res = await api.get(finalUrl);
      const data: ILegal[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      console.log("from action file", data, totalRecords);
      dispatch(fetchLegalsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchLegalsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_LEGAL_PROGRESS = "FETCH_LEGAL_PROGRESS";
export const FETCH_LEGAL_SUCCESS = "FETCH_LEGAL_SUCCESS";
export const FETCH_LEGAL_FAILED = "FETCH_LEGAL_FAILED";

export const fetchLegalProgress = () => action(FETCH_LEGAL_PROGRESS);
export const fetchLegalSuccess = (legal: ILegal) =>
  action(FETCH_LEGAL_SUCCESS, { legal });
export const fetchLegalFailed = (errorMessage: string) =>
  action(FETCH_LEGAL_FAILED, { errorMessage });

export const fetchLegalAsync =
  (legalCode?: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchLegalProgress());

      const res = await api.get(
        `/other/get-legal-plus?legal_plus_code=${legalCode}`
      );
      const data = res.data.data;
      if (data.length > 0) {
        const attachments: IFileUpload[] = [];
        for (const item of (data[0].attachment || []) as any) {
          attachments.push({
            file: null,
            key: getUniqueId(),
            path: item,
          });
        }
        dispatch(fetchLegalSuccess({ ...data[0], attachment: attachments }));
      } else {
        dispatch(
          fetchLegalFailed(
            "Unfortunately, there are no records available at the moment."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchLegalFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertLegalAysnc =
  (
    legal: ILegal,
    initialData: ILegal,
    isUpdate: boolean,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        customer_name: legal.customer_name,
        policy_number: legal.policy_number,
      };
      const { paths } = await uploadMultipleFile(
        legal.attachment || [],
        "LEGAL",
        asPayload
      );
      await makeApiCall(
        {
          url: endPoints.legalPlus.upsertLegal,
          method: "POST",
          automation: {
            isUpdate: isUpdate,
            primaryFieldName: "legal_plus_code",
          },
        },
        initialData,
        { ...legal, attachment: paths }
      );

      dispatch(
        showMessage({
          type: "success",
          message: "Legal is saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_LEGAL_STATE = "CLEAR_LEGAL_STATE";
export const CLEAR_LEGAL = "CLEAR_LEGAL";

export const clearLegalsState = () => action(CLEAR_LEGAL_STATE);
export const clearLegal = () => action(CLEAR_LEGAL);
