import { action } from "typesafe-actions";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { api } from "../../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";
import { IAddGaragePlate, IDeleteGaragePlate, initialTaskGaragePlateState } from "./garagePlate.types";
import { uploadFile } from "../../../helpers";

export const FETCH_ADD_GARAGE_PLATE_PROGRESS = "FETCH_ADD_GARAGE_PLATE_PROGRESS";
export const FETCH_ADD_GARAGE_PLATE_SUCCESS = "FETCH_ADD_GARAGE_PLATE_SUCCESS";
export const FETCH_ADD_GARAGE_PLATE_FAILED = "FETCH_ADD_GARAGE_PLATE_FAILED";

export const fetchAddGaragePlateProgress = () => action(FETCH_ADD_GARAGE_PLATE_PROGRESS);
export const fetchAddGaragePlateSuccess = (data: IAddGaragePlate) =>
  action(FETCH_ADD_GARAGE_PLATE_SUCCESS, { data });
export const fetchAddGaragePlateFailed = () => action(FETCH_ADD_GARAGE_PLATE_FAILED);


export const fetchAddGaragePlateAysnc =
  (taskId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAddGaragePlateProgress());
      const res = await api.get(
        `tasks/get-tasks-add-garage-plate?task_code=${taskId}`
      );
      const data: IAddGaragePlate[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAddGaragePlateSuccess(data[0]));
      } else {
        dispatch(
          fetchAddGaragePlateSuccess(initialTaskGaragePlateState.addGaragePlate["data"])
        );
      }
    } catch (err: any) {
      dispatch(fetchAddGaragePlateFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

  export const upsertTaskAddGaragePlateAysnc =
  (
    task: IAddGaragePlate,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try { dispatch(saveLoaderProgress())
    await api.post("/tasks/create-tasks-add-garage-plate", {
        ...task,
      });
      dispatch(
        showMessage({
          type: "success",
          message: 'Task is saved succesfully!',
          displayAs: "snackbar",
        })
      );
      dispatch(fetchAddGaragePlateSuccess({...task}));
      onCallback(true)
    } catch (err: any) {
      onCallback(false)
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }finally {
      dispatch(saveLoaderCompleted())
    }
};



// Delete Garage Plate

export const FETCH_DELETE_GARAGE_PLATE_PROGRESS = "FETCH_DELETE_GARAGE_PLATE_PROGRESS";
export const FETCH_DELETE_GARAGE_PLATE_SUCCESS = "FETCH_DELETE_GARAGE_PLATE_SUCCESS";
export const FETCH_DELETE_GARAGE_PLATE_FAILED = "FETCH_DELETE_GARAGE_PLATE_FAILED";

export const fetchDeleteGaragePlateProgress = () => action(FETCH_DELETE_GARAGE_PLATE_PROGRESS);
export const fetchDeleteGaragePlateSuccess = (data: IDeleteGaragePlate) =>
  action(FETCH_DELETE_GARAGE_PLATE_SUCCESS, { data });
export const fetchDeleteGaragePlateFailed = () => action(FETCH_DELETE_GARAGE_PLATE_FAILED);


export const fetchDeleteGaragePlateAysnc =
  (taskId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchDeleteGaragePlateProgress());
      const res = await api.get(
        `tasks/get-tasks-delete-garage-plate?task_code=${taskId}`
      );
      const data: IDeleteGaragePlate[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchDeleteGaragePlateSuccess(data[0]));
      } else {
        dispatch(
          fetchDeleteGaragePlateSuccess(initialTaskGaragePlateState.deleteGaragePlate["data"])
        );
      }
    } catch (err: any) {
      dispatch(fetchDeleteGaragePlateFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

  export const upsertTaskDeleteGaragePlateAysnc =
  (
    task: IDeleteGaragePlate,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try { dispatch(saveLoaderProgress())
      await api.post("/tasks/create-tasks-delete-garage-plate", {
        ...task,
      });
      dispatch(
        showMessage({
          type: "success",
          message: 'Task is saved succesfully!',
          displayAs: "snackbar",
        })
      );
      dispatch(fetchDeleteGaragePlateSuccess({...task}));
      onCallback(true)
    } catch (err: any) {
      onCallback(false)
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }finally {
      dispatch(saveLoaderCompleted())
    }
};