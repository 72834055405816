import { action } from "typesafe-actions";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { IAddVehicle, initialTaskVehicleState } from "./taskVehicle.types";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";

export const FETCH_ADD_VEHICLE_PROGRESS = "FETCH_ADD_VEHICLE_PROGRESS";
export const FETCH_ADD_VEHICLE_SUCCESS = "FETCH_ADD_VEHICLE_SUCCESS";
export const FETCH_ADD_VEHICLE_FAILED = "FETCH_ADD_VEHICLE_FAILED";

export const fetchAddVehicleProgress = () => action(FETCH_ADD_VEHICLE_PROGRESS);
export const fetchAddVehicleSuccess = (data: IAddVehicle) =>
  action(FETCH_ADD_VEHICLE_SUCCESS, { data });
export const fetchAddVehicleFailed = () => action(FETCH_ADD_VEHICLE_FAILED);

export const fetchAddVehicleAysnc =
  (taskId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAddVehicleProgress());
      const res = await api.get(`tasks/get-tasks-add-driver?task_id=${taskId}`);
      const data: IAddVehicle[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAddVehicleSuccess(data[0]));
      } else {
        dispatch(
          fetchAddVehicleSuccess(initialTaskVehicleState.addVehicle["data"])
        );
      }
    } catch (err: any) {
      dispatch(fetchAddVehicleFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskVehicleAysnc =
  (
    task: IAddVehicle,
    onCallback: (isSuccess: boolean, taskId?: number) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post("/tasks/upsert-task", task);
      dispatch(
        showMessage({
          type: "success",
          message: "Task is saved succesfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(
        fetchAddVehicleSuccess({ ...task, task_id: res.data.data.task_id })
      );
      onCallback(true, res.data.data.task_id);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
