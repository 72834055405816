import { LoadState } from "../../constants/enums";

export interface IAllCustomersDetailsState {
  list: IAllCustomersDetails[];
  loading: LoadState;
  data: IAllCustomersDetails;
  totalRecords: number;
  error: string | null;
}

export interface IAllCustomersDetails {
  activity: string;
  anti_spam_consent: string;
  assigned_to: string;
  branch: string;
  customer_code: string;
  customer_id: number | string;
  customer_name: string;
  email: string;
  facebook: string;
  first_name: string;
  form_of_business: string;
  goes_by: string;
  insert_ts: string;
  language: string;
  last_name: string;
  linkedin: string;
  mailing_address: string;
  marketer: string;
  mobile: string;
  phone: string;
  phone_ext: string;
  position: string;
  privacy_act_consent: string;
  privacy_consent: string;
  producer: string;
  salutation: string;
  tax_zone: string;
  telematics_consent_code: string;
  website: string;

}


export const initialCustomerFullDetailsState: IAllCustomersDetailsState = {
  list: [],
  totalRecords: 0,
  loading: LoadState.NotLoaded,
  error: null,
  data: {
    activity: "",
    anti_spam_consent: "",
    assigned_to: "",
    branch: "",
    customer_code: "",
    customer_id: "",
    customer_name: "",
    email: "",
    facebook: "",
    first_name: "",
    form_of_business: "",
    goes_by: "",
    insert_ts: "",
    language: "",
    last_name: "",
    linkedin: "",
    mailing_address: "",
    marketer: "",
    mobile: "",
    phone: "",
    phone_ext: "",
    position: "",
    privacy_act_consent: "",
    privacy_consent: "",
    producer: "",
    salutation: "",
    tax_zone: "",
    telematics_consent_code: "",
    website: "",
  },
};
