import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { IHabitationalBinderCsio } from "./habitationalBinderCsio.types";
import { uploadFile } from "../../../helpers";

export const FETCH_HABITATIONAL_CSIO_BINDER_LIST_PROGRESS =
  "FETCH_HABITATIONAL_CSIO_BINDER_LIST_PROGRESS";
export const FETCH_HABITATIONAL_CSIO_BINDER_LIST_SUCCESS =
  "FETCH_HABITATIONAL_CSIO_BINDER_LIST_SUCCESS";
export const FETCH_HABITATIONAL_CSIO_BINDER_LIST_FAILED =
  "FETCH_HABITATIONAL_CSIO_BINDER_LIST_FAILED";

export const fetchHabitationalCsioBinderListProgress = () =>
  action(FETCH_HABITATIONAL_CSIO_BINDER_LIST_PROGRESS);
export const fetchHabitationalCsioBinderListSuccess = (
  data: IHabitationalBinderCsio[]
) => action(FETCH_HABITATIONAL_CSIO_BINDER_LIST_SUCCESS, { data });
export const fetchHabitationalCsioBinderListFailed = () =>
  action(FETCH_HABITATIONAL_CSIO_BINDER_LIST_FAILED);

export const fetchHabitationalCsioBinderListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalCsioBinderListProgress());
      const res = await api.get(
        `/policy/get-habitational-binder?customer_policy_id=${customerPolicyId}`
      );
      const data: IHabitationalBinderCsio[] = res.data.data;
      dispatch(fetchHabitationalCsioBinderListSuccess(data));
    } catch (err: any) {
      dispatch(fetchHabitationalCsioBinderListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_HABITATIONAL_CSIO_BINDER_PROGRESS =
  "FETCH_HABITATIONAL_CSIO_BINDER_PROGRESS";
export const FETCH_HABITATIONAL_CSIO_BINDER_SUCCESS =
  "FETCH_HABITATIONAL_CSIO_BINDER_SUCCESS";
export const FETCH_HABITATIONAL_CSIO_BINDER_FAILED =
  "FETCH_HABITATIONAL_CSIO_BINDER_FAILED";

export const fetchHabitationalCsioBinderProgress = () =>
  action(FETCH_HABITATIONAL_CSIO_BINDER_PROGRESS);
export const fetchHabitationalCsioBinderSuccess = (
  data: IHabitationalBinderCsio
) => action(FETCH_HABITATIONAL_CSIO_BINDER_SUCCESS, { data });
export const fetchHabitationalCsioBinderFailed = (errorMessage: string) =>
  action(FETCH_HABITATIONAL_CSIO_BINDER_FAILED, { errorMessage });

export const fetchHabitationalCsioBinderAsync =
  (
    customerPolicyId: number,
    Csio: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalCsioBinderProgress());
      const res = await api.get(
        `/policy/get-habitational-binder?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${Csio}`
      );
      const data: IHabitationalBinderCsio[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchHabitationalCsioBinderSuccess(data[0]));
      } else {
        dispatch(
          fetchHabitationalCsioBinderFailed(
            "Oops! We couldn't find any records associated with your restrutant number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchHabitationalCsioBinderFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addHabitationalCsioBinderAsync =
  (
    Csio: IHabitationalBinderCsio,
    customerID: number,
    signature: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        customer_id: customerID,
        policy_type: "HABITATIONAL",
        customer_policy_id: Csio.customer_policy_id,
        page_name: "Habitational Binder",
      };

      const path = await uploadFile(
        signature,
        "POLICY",
        Csio.signature_of_broker || "",
        asPayload
      );
      await api.post(`/policy/edit-habitational-binder`, {
        ...Csio,
        signature_of_broker: path,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Non-Csio Binder saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_HABITATIONAL_CSIO_BINDER = "CLEAR_HABITATIONAL_CSIO_BINDER";
export const CLEAR_HABITATIONAL_CSIO_BINDER_STATE =
  "CLEAR_HABITATIONAL_CSIO_BINDER_STATE";

export const clearHabitationalCsioBinderState = () =>
  action(CLEAR_HABITATIONAL_CSIO_BINDER_STATE);

export const clearHabitationalCsioBinder = () =>
  action(CLEAR_HABITATIONAL_CSIO_BINDER);

// `/policy/get-habitational-binder
// /policy/edit-habitational-binder
