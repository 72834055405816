/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { useDispatchWrapper, useQueryParms } from "../../../../../hooks";
import { fetchCustomerInfoAsync } from "../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";
import { PageLoader } from "../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../constants/enums";
import { StandadCard } from "../../../../../components/Cards";
import { TabsList } from "../../../../../components/Tabs/TabsList";
import { AccountCircle, AdminPanelSettings } from "@mui/icons-material";
import { TabPanel } from "../../../../../components/Tabs/TabPanel";
import { PremiumFinanceList } from "./PremiumFinance/PremiumFinanceList";
import { useNavigate, useOutletContext } from "react-router-dom";

export const OthersRoot= () => {
  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerPolicyId = data[1];
  const {getParm} = useQueryParms();
  const tabValue = getParm('tab');
  const selectedTab = tabValue ? Number(tabValue) : 0;
  const { customerInfo } = useSelector(
    (storeState: IStoreState) => storeState.business.basicDetails
  );

  const navigate = useNavigate();

  const dispatch = useDispatchWrapper();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(
      `/business-policy/${customerId}/others-tab/${customerPolicyId}?tab=${newValue}`
    );
  };

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  return (
    <PageLoader
      loading={customerInfo.loading === LoadState.InProgress}
      error={customerInfo.error ? { message: customerInfo.error } : null}
    >
      <StandadCard>
        {/* <TabsList
          sx={{ mb: 1 }}
          varaint="fullWidth"
          selectedTab={selectedTab}
          tabs={[
            {
              label: "Premium FA",
              icon: <AdminPanelSettings />,
            },
          ]}
          onChange={handleChange}
        /> */}
        <TabPanel value={selectedTab} index={0}>
          <PremiumFinanceList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
      </StandadCard>
    </PageLoader>
  );
};
