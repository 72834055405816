import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import { IAutomobileAlbertaApplication, initialAutomobileAlbertaApplicationState } from "./automobileAlbertaApplication.types";


export const FETCH_AUTOMOBILE_ALBERTA_APPLICATION_PROGRESS =
  "FETCH_AUTOMOBILE_ALBERTA_APPLICATION_PROGRESS";
export const FETCH_AUTOMOBILE_ALBERTA_APPLICATION_SUCCESSS =
  "FETCH_AUTOMOBILE_ALBERTA_APPLICATION_SUCCESSS";
export const FETCH_AUTOMOBILE_ALBERTA_APPLICATION_FAILED =
  "FETCH_AUTOMOBILE_ALBERTA_APPLICATION_FAILED";

export const fetchAutomobileAlbertaApplicationProgress = () =>
  action(FETCH_AUTOMOBILE_ALBERTA_APPLICATION_PROGRESS);
export const fetchAutomobileAlbertaApplicationSuccess = (
  data: IAutomobileAlbertaApplication
) => action(FETCH_AUTOMOBILE_ALBERTA_APPLICATION_SUCCESSS, { data });
export const fetchAutomobileAlbertaApplicationFailed = () =>
  action(FETCH_AUTOMOBILE_ALBERTA_APPLICATION_FAILED);

export const fetchAutomobileAlbertaApplicationAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileAlbertaApplicationProgress());
      const res = await api.get(
        `/policy/get-auto-automobile-application?customer_policy_id=${customerPolicyId}&value=${incremental_number}`
      );
      const data: IAutomobileAlbertaApplication[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAutomobileAlbertaApplicationSuccess(data[0]));
      } else {
        dispatch(
          fetchAutomobileAlbertaApplicationSuccess(
            initialAutomobileAlbertaApplicationState["application"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchAutomobileAlbertaApplicationFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAutomobileAlbertaApplication =
  (
    data: IAutomobileAlbertaApplication,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/policy/edit-auto-automobile-application", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Application saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchAutomobileAlbertaApplicationSuccess(data));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_FAILED =
  "FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_FAILED";

export const fetchAutomobileAlbertaApplicationListProgress = () =>
  action(FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_PROGRESS);

export const fetchAutomobileAlbertaApplicationsListSuccess = (
  data: IAutomobileAlbertaApplication[]
) =>
  action(FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_SUCCESS, {
    data,
  });
export const fetchAutomobileAlbertaApplicationListFailed = () =>
  action(FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_FAILED);

export const fetchAutomobileAlbertaApplicationListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileAlbertaApplicationListProgress());
      const res = await api.get(
        `/policy/get-auto-automobile-application?customer_policy_id=${customerPolicyId}`
      );
      const data: IAutomobileAlbertaApplication[] = res.data.data;
      dispatch(fetchAutomobileAlbertaApplicationsListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileAlbertaApplicationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_ALBERTA_APPLICATION_STATE =
  "CLEAR_AUTOMOBILE_ALBERTA_APPLICATION_STATE";

export const clearAutomobileAlbertaApplicationState = () =>
  action(CLEAR_AUTOMOBILE_ALBERTA_APPLICATION_STATE);