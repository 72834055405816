export const getFullAddres = (
  address: string | null,
  unit: string | null,
  city: string | null,
  province_or_state: string | null,
  country: string | null,
  postalCode: string | null,
) => {
    let final =""
    if(address){
        final =address
    }
    if(unit){
        final +=", "+unit;
    }
    if(city){
        final +=", "+city;
    }
    if(province_or_state){
        final +=", "+province_or_state;
    }
    if(country){
        final +=", "+country;
    }
    if(postalCode){
        final += ", "+postalCode;
    }
    return final;
};
