import { LoadState } from "../../../constants/enums";

export interface ITransactionDescriptionState {
  list: ITransactionDescription[];
  listLoading: LoadState;
  data: ITransactionDescription;
  loading: LoadState;
  error: string | null;
}

export interface ITransactionDescription {
  transaction_description_id: string | null;
  customer_id: string | null;
  customer_policy_id: string | null;
  invoice_id: string | null;
  transaction_description: string;
  detail: string | null;
  status: string;
}

export const initialTransactionDescriptionState: ITransactionDescriptionState =
  {
    list: [],
    listLoading: LoadState.NotLoaded,
    data: {
      transaction_description_id: null,
      customer_id: null,
      customer_policy_id: null,
      invoice_id: null,
      transaction_description: "",
      detail: null,
      status: "ACTIVE",
    },
    loading: LoadState.NotLoaded,
    error: null,
  };
