import produce from "immer";
import { AccountingTaxActions } from ".";
import { IStoreState } from "../../initialStoreState";
import { CLEAR_ACCOUNTING_TAX, CLEAR_ACCOUNTING_TAX_STATE, FETCH_ACCOUNTING_TAX_FAILURE, FETCH_ACCOUNTING_TAX_LIST_FAILURE, FETCH_ACCOUNTING_TAX_LIST_PROGRESS, FETCH_ACCOUNTING_TAX_LIST_SUCCESS, FETCH_ACCOUNTING_TAX_PROGRESS, FETCH_ACCOUNTING_TAX_SUCCESS } from "./TaxActions";
import { LoadState } from "../../../constants/enums";
import { initialTaxState } from "./Tax.types";

export const AccountingTaxReducer = (
  state: IStoreState["accounting"]["tax"] = initialTaxState,
  action: AccountingTaxActions
) => {
  switch(action.type){
    case FETCH_ACCOUNTING_TAX_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_TAX_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_TAX_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    
    case FETCH_ACCOUNTING_TAX_LIST_FAILURE: {
      const {errorMessage} = action.payload;
      const newState = produce(state, (draftState)=>{
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage
      })
      return newState
    }

    case FETCH_ACCOUNTING_TAX_LIST_PROGRESS: {
      const newState = produce(state, (draftState)=>{
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      })
      return newState
    }

    case FETCH_ACCOUNTING_TAX_LIST_SUCCESS: {
      const {data} = action.payload;
      const newState = produce(state, (draftState)=>{
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
      })
      return newState
    }

    case CLEAR_ACCOUNTING_TAX: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.NotLoaded;
        draftState.data = initialTaxState["data"];
      });
      return newState;
    }

    case CLEAR_ACCOUNTING_TAX_STATE: {
      return initialTaxState;
    }

    default: {
      return state;
    }
  }
}