/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { StandadCard } from "../../../../../../components/Cards";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../components/formsComponents";
import { getUniqueId, renderDropdownValue } from "../../../../../../helpers";
import { lineofBusiness } from "../../../../../../constants/constants";
import { LabelCard } from "../../../../../../components/LabelCard/LabelCard";
import { CustomDatePicker } from "../../../../../../components/formsComponents/CustomDatePicker";
import moment from "moment";
import { InsurerCodeAutoSearch } from "../../../../../../components/AutoCompleteSearches";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import {
  IEmpolyeePayroll,
  IGrossRevenue,
} from "../../../../../../redux/businessPolicy/businessPolicyUnderWriting/businessPolicyUnderWriting.types";
import { Add, Remove } from "@mui/icons-material";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useFormik } from "formik";
import { LoadState, MODULE_IDS } from "../../../../../../constants/enums";
import produce from "immer";
import { useDispatchWrapper } from "../../../../../../hooks";
import {
  addBusinessUnderWritingAsync,
  fetchBusinessUnderWritingAsync,
} from "../../../../../../redux/businessPolicy/businessPolicyUnderWriting/businePolicyUnderWritingActions";
import {
  ILocationResponsePayload,
  LocationAutoComplete,
} from "../../../../../../components/LocationAutoComplete/LocationAutoComplete";
import { CurrencyTextField } from "../../../../../../components/CurrencyTextField/CurrencyTextField";
import { RoleBasedCustomButton } from "../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { useOutletContext } from "react-router-dom";
import { PageContainer } from "../../../../../../components/container/PageContainer";
import { PageTitleBar } from "../../../../../../components/PageTitleBar/PageTitleBar";

export const PolicyUnderwriting: React.FC = () => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerPolicyId = data1[1];

  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.business.underwriting
  );
  const dispatch = useDispatchWrapper();
  const [saveLoading, setSaveLoading] = React.useState(false);
  const { customer_name, policy_number } = useSelector(
    (storeState: IStoreState) =>
      storeState.business.basicDetails.customerInfo.data
  );

  const { values, handleChange, handleSubmit, setFieldValue, setValues } =
    useFormik({
      initialValues: data,
      validate: (values) => {},
      onSubmit: (values) => {
        setSaveLoading(true);
        dispatch(
          addBusinessUnderWritingAsync(
            {
              ...values,
              customer_id: customerId,
              customer_policy_id: customerPolicyId,
            },
            () => {
              setSaveLoading(false);
            }
          )
        );
      },
    });

  React.useEffect(() => {
    setValues({
      ...data,
      customer_name: customer_name || "",
      policy_number: policy_number,
    });
    return;
  }, [data]);

  React.useEffect(() => {
    dispatch(fetchBusinessUnderWritingAsync(customerPolicyId));
  }, [customerPolicyId]);

  const handleGrossRevNewRow = () => {
    const row = {
      key: getUniqueId(),
      business_activity: null,
      revenue_amount: null,
      cdn_percentage: null,
      usa_percentage: null,
      foreign_percentage: null,
      gross_revenue: null,
    };
    setValues({ ...values, gross_revenue: [...values.gross_revenue, row] });
  };

  const handleRemoveRevRow = (index: number) => () => {
    const finalRows = [...values.gross_revenue];
    finalRows.splice(index, 1);
    setValues({ ...values, gross_revenue: finalRows });
  };

  const handleGrossRevChange =
    (key: keyof IGrossRevenue, rowIndex: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (values.gross_revenue.length > rowIndex) {
        const finalRows = produce(values.gross_revenue, (draftRows) => {
          draftRows[rowIndex][key] = event.target.value;
        });
        setValues({ ...values, gross_revenue: finalRows });
      }
    };

  const handleEmpolyeePayRolRow = () => {
    const row = {
      key: getUniqueId(),
      year: null,
      part_time: null,
      full_time: null,
      payroll_amount: null,
    };
    setValues({
      ...values,
      employees_and_payroll: [...values.employees_and_payroll, row],
    });
  };

  const handleRemoveEmpolyeePayrollRow = (index: number) => () => {
    const finalRows = [...values.employees_and_payroll];
    finalRows.splice(index, 1);
    console.log(finalRows);
    setValues({ ...values, employees_and_payroll: finalRows });
  };

  const handleEmpolyeePayrRollChange =
    (key: keyof IEmpolyeePayroll, rowIndex: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (values.employees_and_payroll.length > rowIndex) {
        const finalRows = produce(values.employees_and_payroll, (draftRows) => {
          draftRows[rowIndex][key] = event.target.value;
        });
        setValues({ ...values, employees_and_payroll: finalRows });
      }
    };
  const handleEmpolyeePayrRollCurrencyChange =
    (key: keyof IEmpolyeePayroll, rowIndex: number) => (value: string) => {
      if (values.employees_and_payroll.length > rowIndex) {
        const finalRows = produce(values.employees_and_payroll, (draftRows) => {
          draftRows[rowIndex][key] = value;
        });
        setValues({ ...values, employees_and_payroll: finalRows });
      }
    };
  const handleAddress = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      street_address: data.address,
      city: data.city,
      country: data.country,
      province_or_state: data.state,
      postal_code: data.postalCode,
    });
  };

  const grossRevenueTableProps: IDataTableProps = {
    isPagination: false,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "add",
            onRender: () => (
              <Button
                size="small"
                variant="contained"
                onClick={handleGrossRevNewRow}
              >
                <Add fontSize="small" />
              </Button>
            ),
          },
        ],
      },
    },
    uniqueRowKeyName: "key",

    columns: [
      {
        key: "business_activity",
        headerName: "Business Activity",
        fieldName: "business_activity",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: IGrossRevenue) => {
          return (
            <CustomTextField
              fullWidth
              value={row.business_activity}
              onChange={handleGrossRevChange(
                "business_activity",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      // {
      //   key: "revenue_amount",
      //   headerName: "Revenue Amount",
      //   fieldName: "revenue_amount",
      //   renderType: RenderType.CUSTOM_RENDER,
      //   exportCellWidth: 15,

      //   onRowCellRender: (value, row: IGrossRevenue) => {
      //     return (
      //       <CustomTextField
      //         fullWidth
      //         value={row.revenue_amount}
      //         onChange={handleGrossRevChange(
      //           "revenue_amount",
      //           value.column.rowIndex
      //         )}
      //       />
      //     );
      //   },
      // },
      {
        key: "cdn_percentage",
        headerName: "CDN %",
        fieldName: "cdn_percentage",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: IGrossRevenue) => {
          return (
            <CustomTextField
              fullWidth
              type="number"
              value={row.cdn_percentage}
              onChange={handleGrossRevChange(
                "cdn_percentage",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "usa_percentage",
        headerName: "USA %",
        fieldName: "usa_percentage",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IGrossRevenue) => {
          return (
            <CustomTextField
              fullWidth
              type="number"
              value={row.usa_percentage}
              onChange={handleGrossRevChange(
                "usa_percentage",
                value.column.rowIndex
              )}
            />
          );
        },
      },

      {
        key: "foreign_percentage",
        headerName: "Foreign %",
        fieldName: "foreign_percentage",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: IGrossRevenue) => {
          return (
            <CustomTextField
              fullWidth
              type="number"
              value={row.foreign_percentage}
              onChange={handleGrossRevChange(
                "foreign_percentage",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "gross_revenue",
        headerName: "Revenue",
        fieldName: "gross_revenue",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IGrossRevenue) => {
          return (
            <CustomTextField
              fullWidth
              type="number"
              value={row.gross_revenue}
              onChange={handleGrossRevChange(
                "gross_revenue",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "delete",
        headerName: "",
        fieldName: "add",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: IGrossRevenue) => {
          return (
            <Box display={"flex"}>
              <IconButton
                color="error"
                onClick={handleRemoveRevRow(value.column.rowIndex)}
              >
                <Remove fontSize="small" />
              </IconButton>
              <IconButton color="primary" onClick={handleGrossRevNewRow}>
                <Add fontSize="small" />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    items: values.gross_revenue,
  };

  const empolyeePayrollTableProps: IDataTableProps = {
    isPagination: false,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "add",
            onRender: () => (
              <Button
                size="small"
                variant="contained"
                onClick={handleEmpolyeePayRolRow}
              >
                <Add fontSize="small" />
              </Button>
            ),
          },
        ],
      },
    },
    uniqueRowKeyName: "key",

    columns: [
      {
        key: "year",
        headerName: "Year",
        fieldName: "year",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: IEmpolyeePayroll) => {
          return (
            <CustomTextField
              fullWidth
              value={row.year}
              onChange={handleEmpolyeePayrRollChange(
                "year",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "part_time",
        headerName: "Part Time",
        fieldName: "part_time",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: IEmpolyeePayroll) => {
          return (
            <CustomTextField
              fullWidth
              value={row.part_time}
              onChange={handleEmpolyeePayrRollChange(
                "part_time",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "full_time",
        headerName: "Full Time",
        fieldName: "full_time",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: IEmpolyeePayroll) => {
          return (
            <CustomTextField
              fullWidth
              value={row.full_time}
              onChange={handleEmpolyeePayrRollChange(
                "full_time",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "payroll_amount",
        headerName: "Payroll($)",
        fieldName: "payroll_amount",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: IEmpolyeePayroll) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.payroll_amount}
              onChange={handleEmpolyeePayrRollCurrencyChange(
                "payroll_amount",
                value.column.rowIndex
              )}
            />
          );
        },
      },

      {
        key: "delete",
        headerName: "",
        fieldName: "add",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: IGrossRevenue) => {
          return (
            <Box display={"flex"}>
              <IconButton
                color="error"
                onClick={handleRemoveEmpolyeePayrollRow(value.column.rowIndex)}
              >
                <Remove fontSize="small" />
              </IconButton>
              <IconButton color="primary" onClick={handleEmpolyeePayRolRow}>
                <Add fontSize="small" />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    items: values.employees_and_payroll,
  };

  React.useEffect(() => {
    const total = values.gross_revenue.reduce((accumulator, currentValue) => {
      return accumulator + (Number(currentValue.gross_revenue) || 0);
    }, 0);
    setFieldValue("gross_revenue_sum", total);
  }, [values.gross_revenue]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      <PageContainer title="Underwriting" description="this is inner page">
      <PageTitleBar
        heading="Underwriting"
      />
        <StandadCard>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Customer</CustomFormLabel>
                <CustomTextField
                  name="customer_name"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  disabled
                  value={values.customer_name}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Policy Number</CustomFormLabel>
                <CustomTextField
                  name="policy_number"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.policy_number}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Known to Broker Since</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  views={["year"]}
                  inputFormat="YYYY"
                  value={values.known_to_broker_since || ""}
                  onChange={(newValue, x, momentObject) => {
                    const newDate = moment(momentObject).format("YYYY");
                    setFieldValue(
                      "known_to_broker_since",
                      momentObject ? newDate : null
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <CustomFormLabel>In Business Since</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  views={["year"]}
                  inputFormat="YYYY"
                  value={values.in_business_since || ""}
                  onChange={(newValue, x, momentObject) => {
                    const newDate = moment(newValue).format("YYYY");
                    setFieldValue(
                      "in_business_since",
                      momentObject ? newDate : null
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>
                  Number of Years Continuously Insured
                </CustomFormLabel>
                <CustomTextField
                  name="number_of_years_continuously_insured"
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  value={values.number_of_years_continuously_insured}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={2.25}>
                <CustomFormLabel>Related Prior Experience</CustomFormLabel>
                <CustomTextField
                  name="related_prior_experience"
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  value={values.related_prior_experience}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={2.25}>
                <CustomFormLabel>Liqour Liability Exposure</CustomFormLabel>
                <CustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="liquor_liability_exposure"
                  fullWidth
                  value={values.liquor_liability_exposure}
                  onChange={handleChange}
                  displayEmpty
                  renderValue={renderDropdownValue(`Select one`)}
                >
                  {["Y", "N"].map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </CustomSelect>
              </Grid>

              <Grid item xs={12} md={2.25}>
                <CustomFormLabel>Sub-Trades %?</CustomFormLabel>
                <CustomTextField
                  id="sub_trades_percentage"
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  value={values.sub_trades_percentage}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={2.25}>
                <CustomFormLabel>% Internet Sales</CustomFormLabel>
                <CustomTextField
                  id="percentage_internet_sales"
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  value={values.percentage_internet_sales}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>Covered by WCB?</CustomFormLabel>
                <CustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="covered_by_wcb"
                  fullWidth
                  value={values.covered_by_wcb}
                  onChange={handleChange}
                  displayEmpty
                  renderValue={renderDropdownValue(`Select one`)}
                >
                  {["Y", "N"].map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </CustomSelect>
              </Grid>

              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>Are you the Incumbent broker?</CustomFormLabel>
                <CustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="are_you_the_incument_broker"
                  fullWidth
                  value={values.are_you_the_incument_broker}
                  onChange={handleChange}
                  displayEmpty
                  renderValue={renderDropdownValue(`Select one`)}
                >
                  {["Y", "N"].map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </CustomSelect>
              </Grid>

              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>Insured Is</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="insured_is"
                  fullWidth
                  value={values.insured_is}
                  onChange={handleChange}
                  renderValue={renderDropdownValue(`Select one`)}
                  options={[
                    { value: "Building Owner", label: "Building Owner" },
                    { value: "Condo", label: "Condo Unit Owner" },
                    { value: "Tenant", label: "Tenant" },
                  ]}
                />
              </Grid>

              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>Insurance Experience</CustomFormLabel>
                <CustomTextField
                  name="insurance_experience"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.insurance_experience}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>
                  Cancellation by Insurer in the last 5 years:
                </CustomFormLabel>
                <CustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="cancellation_or_renewal_refusal_by_insurer_in_last_five_years"
                  fullWidth
                  value={
                    values.cancellation_or_renewal_refusal_by_insurer_in_last_five_years
                  }
                  onChange={handleChange}
                  displayEmpty
                  renderValue={renderDropdownValue(`Select one`)}
                >
                  {["Y", "N"].map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </CustomSelect>
              </Grid>

              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>Renewal Offered</CustomFormLabel>
                <CustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="renewal_offered"
                  fullWidth
                  value={values.renewal_offered}
                  onChange={handleChange}
                  displayEmpty
                  renderValue={renderDropdownValue(`Select one`)}
                >
                  {["Y", "N"].map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </CustomSelect>
              </Grid>

              <Grid item xs={12} md={2.8}>
                <CustomFormLabel>Industry Code</CustomFormLabel>
                <CustomTextField
                  name="industry_code"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.industry_code}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={6.8}>
                <CustomFormLabel>Industry Description</CustomFormLabel>
                <CustomTextField
                  name="description"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.description}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <CustomFormLabel>Business Description</CustomFormLabel>
                <CustomTextField
                  name="business_description"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  multiline
                  rows={3}
                  value={values.business_description}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CustomFormLabel>
                  Steps taken to prevent further losses
                </CustomFormLabel>
                <CustomTextField
                  name="steps_taken_to_prevent_further_losses"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  multiline
                  rows={3}
                  value={values.steps_taken_to_prevent_further_losses}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <LabelCard heading="GROSS REVENUE" />
            <Grid container>
              <Grid item xs={12} md={12}>
                <DataTable {...grossRevenueTableProps} />
              </Grid>
              <Grid item xs={12} md={12} marginTop={1}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Grid container spacing={2}>
                  <Grid item xs={9}></Grid>
                  <Grid item xs={2}>
                    <Typography variant="h4" fontWeight={600}>
                      Gross Revenue
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="h4" fontWeight={600}>
                      $ {values.gross_revenue_sum}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 2, mb: 2 }} />
              </Grid>
            </Grid>
            <LabelCard heading="EMPOLYEE PAYROLL" />
            <Grid container>
              <Grid item xs={12} md={12}>
                <DataTable {...empolyeePayrollTableProps} />
              </Grid>
            </Grid>
            <LabelCard heading="PREVIOUS POLICY" />
            <Grid container spacing={3}>
              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>Line of Business</CustomFormLabel>

                <CustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="line_of_business"
                  fullWidth
                  value={values.line_of_business}
                  onChange={handleChange}
                  displayEmpty
                  renderValue={renderDropdownValue(`Select one`)}
                >
                  {(lineofBusiness["Business"].list || []).map((policy) => {
                    return <MenuItem value={policy}>{policy}</MenuItem>;
                  })}
                </CustomSelect>
              </Grid>
              <Grid item xs={12} lg={2}>
                <CustomFormLabel>Policy Expiry Date</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  value={values.policy_expiry_date || ""}
                  onChange={(newValue) => {
                    setFieldValue("policy_expiry_date", newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={2.8}>
                <CustomFormLabel>Current Insurer</CustomFormLabel>
                <InsurerCodeAutoSearch
                  value={values.current_insurer}
                  onSelect={(value) => {
                    setFieldValue("current_insurer", value.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CustomFormLabel>Expiring Premium</CustomFormLabel>
                <CurrencyTextField
                  fullWidth
                  value={values.expiring_premium}
                  onChange={(value) => {
                    setFieldValue("expiring_premium", value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2.8}>
                <CustomFormLabel>Current Status</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="current_status"
                  fullWidth
                  value={values.current_status}
                  onChange={handleChange}
                  displayEmpty
                  placeholder={"Select one"}
                  options={[
                    { label: "Cancelled For NSF", value: "Cancelled_nsf" },
                    {
                      label:
                        "Cancelled for Non Compliance of  Inspection Recommendations",
                      value: "Cancellednoncompliance",
                    },
                    { label: "No Prior Coverage", value: "NoPrior" },
                    {
                      label: "Renewal Declined by Current Insurer",
                      value: "NonRenewal",
                    },
                    {
                      label: "Renewal Offered by Current Insurer",
                      value: "RenewalOffered",
                    },
                    { label: "Up for Renewal", value: "UpRenewal" },
                    { label: "In-Force", value: "InForce" },
                  ]}
                ></ControlledCustomSelect>
              </Grid>
            </Grid>
            <LabelCard heading="CREDIT CONSENT" />
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <CustomFormLabel>Name</CustomFormLabel>
                <CustomTextField
                  name="name"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.name}
                  onChange={handleChange}
                  placeholder="Last, First Name"
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <CustomFormLabel>Date of Birth</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  value={values.date_of_brith || ""}
                  onChange={(newValue) => {
                    setFieldValue("date_of_brith", newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomFormLabel>Years at this Address?</CustomFormLabel>
                <CustomTextField
                  name="years_at_this_address"
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  value={values.years_at_this_address}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={3.5}>
                <CustomFormLabel>Street Address</CustomFormLabel>
                <LocationAutoComplete
                  id="street_address"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.street_address}
                  onLocationChange={handleAddress}
                />
              </Grid>
              <Grid item xs={12} lg={1.5}>
                <CustomFormLabel>Unit/Suite</CustomFormLabel>
                <CustomTextField
                  id="unit_or_suite"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.unit_or_suite}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={2}>
                <CustomFormLabel>City</CustomFormLabel>
                <CustomTextField
                  id="city"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.city}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <CustomFormLabel>State/Province</CustomFormLabel>
                <CustomTextField
                  id="province_or_state"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.province_or_state}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={1.5}>
                <CustomFormLabel>Postal Code</CustomFormLabel>
                <CustomTextField
                  id="postal_code"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.postal_code}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={1.5}>
                <CustomFormLabel>Country</CustomFormLabel>
                <CustomTextField
                  id="country"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.country}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 3 }}>
              <RoleBasedCustomButton
                sx={{ width: "15%" }}
                disabled={saveLoading}
                variant="contained"
                moduleId={MODULE_IDS.BUSINESS_POLICY}
                type="submit"
              >
                Save
              </RoleBasedCustomButton>
            </Box>
          </form>
        </StandadCard>
      </PageContainer>
    </PageLoader>
  );
};
