/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line react/jsx-pascal-case

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { useDispatchWrapper, useQueryParms } from "../../../../../hooks";
import { PageLoader } from "../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../constants/enums";
import { StandadCard } from "../../../../../components/Cards";
import { TabsList } from "../../../../../components/Tabs/TabsList";
import {
  AccountCircle,
  AdminPanelSettings,
  Receipt,
  StickyNote2,
} from "@mui/icons-material";
import { TabPanel } from "../../../../../components/Tabs/TabPanel";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useBreadcrumbContext } from "../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { fetchCommAutomobileCustomerInfoAsync } from "../../../../../redux/commAutoPolicy/commAutoBasicDetails/commAutoBasicDetailsActions";
import { Divider, Tab, Tabs } from "@mui/material";
import { a11yProps } from "../../../../../components/Tabs/utils";
import { HabitationalCSIOApplicationList } from "./HabitationalCsioApplicationForm/HabitationalCsioApplicationList";
import { HabitationalCSIOPolicyEndorsementList } from "./HabitationalCsioApplicationForm/HabitationalCsioPolicyChangeEndorsement/HabitationalCsioPolicyChangeEndorsement";
import { HabitationalCSIOEDIApplicationList } from "./HabitationalCSIOEDIApplication/HabitationalCSIOEDIApplicationList";

export const HabitationalCSIOApplicationTab = () => {
  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerPolicyId = data[1];
  const { addBreadcrumb } = useBreadcrumbContext();
  const { getParm } = useQueryParms();
  const tabValue = getParm("tab");
  const selectedTab = tabValue ? Number(tabValue) : 0;
  const { customerInfo } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.basicDetails
  );

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const handleChangetab = (event: React.SyntheticEvent, newValue: number) => {
    navigate(
      `/habitational-policy/${customerId}/habitational-application-csio-tab/${customerPolicyId}?tab=${newValue}`
    );
  };

  React.useEffect(() => {
    if (customerPolicyId) {
      //   dispatch(fetchBindersListAsync(customerPolicyId));
      dispatch(fetchCommAutomobileCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  return (
    <PageLoader loading={customerInfo.loading === LoadState.InProgress}>
      <StandadCard sx={{ marginBottom: 2 }}>
        <Tabs
          value={selectedTab}
          variant="fullWidth"
          scrollButtons="auto"
          onChange={handleChangetab}
        >
          <Tab
            icon={<AccountCircle />}
            iconPosition="start"
            label="CSIO Application"
            {...a11yProps(0)}
          />
          <Tab
            sx={{ minHeight: "50px" }}
            icon={<AdminPanelSettings />}
            iconPosition="start"
            label="Policy Change/Endorsement"
            {...a11yProps(1)}
          />
          <Tab
            sx={{ minHeight: "50px" }}
            icon={<AdminPanelSettings />}
            iconPosition="start"
            label="EDI"
            {...a11yProps(2)}
          />
        </Tabs>
      </StandadCard>
      {/* <Divider /> */}
      <TabPanel value={selectedTab} index={0}>
        <HabitationalCSIOApplicationList />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <HabitationalCSIOPolicyEndorsementList />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <HabitationalCSIOEDIApplicationList
         />
      </TabPanel>
    </PageLoader>
  );
};
