import { api } from "../api/api";
import { ITaskFileUpload } from "../redux/tasks/task.types";

export const uploadFile = async (
  file: File | null,
  module_name: string,
  defaultReturn: string,
  asPayload: {
    [key: string]: string | number | null;
  }
) => {
  if (file) {
    const formdata = new FormData();
    formdata.append("files", file);
    formdata.append("module_name", module_name);
    formdata.append("as_payload", JSON.stringify(asPayload));
    const res = await api.post("/general/upload", formdata);
    const data: string[] = res.data.data;
    if (data.length > 0) {
      return data[0];
    }
  }
  return defaultReturn;
};

export const uploadTaskFile = async (
   files: ITaskFileUpload['attachments'],
  module_name: string,
  asPayload: {
    [key: string]: string | number | null;
  }
) => {
  let filesCount =0;
  const formdata = new FormData();
  for (let file of files) {
    if (file.file) {
      filesCount++;
      formdata.append("files", file.file);
    }
  }
  formdata.append("module_name", module_name);
  formdata.append("as_payload", JSON.stringify(asPayload));
  if(filesCount === 0){
    return files;
  }
  const res = await api.post("/general/upload", formdata);
  const data: string[] = res.data.data;
  const finalList = [];
  let i = 0;
  if (data.length > 0) {
    for (let item of files) {
      const url = data[i];
      const obj = {...item};
      if (item.file) {
        obj.file = null;
        obj.url = url;
        i++;
      }
      finalList.push(obj);
    }
    return finalList;
  }

  return files;
};
