import { Box, Card, Typography } from "@mui/material";

export const LabelCard: React.FC<{
  heading: string;
  icon?: React.ReactNode;
  sx?: any;
}> = (props) => {
  const { heading, icon } = props;

  return (
    <>
      <Box
        sx={{
          mt: 2,
          mb: 1,
          width: "100%",
          backgroundColor: "primary.main",
          borderRadius: "15px",
          padding: "16px 12px",
          ...props.sx,
        }}
      >
        <Box display={"flex"} alignItems={'center'}>
          {icon && <Box marginRight={1} sx={{ svg: { color: "#fff", } }}> {icon}</Box> }
          <Typography
            variant="h5"
            color={"white"}
            textAlign={"start"}
            fontSize={"0.95rem"}
          >
            {heading}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export const TableSellSplit: React.FC<{ heading: string; sx?: any }> = (
  props
) => {
  const { heading } = props;

  return (
    <>
      <Card
        sx={{ padding: "16px", backgroundColor: "primary.main", ...props.sx }}
      >
        <Typography
          variant="h5"
          color={"white"}
          textAlign={"start"}
          fontSize={"0.9rem"}
        >
          {heading}
        </Typography>
      </Card>
    </>
  );
};
