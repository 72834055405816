/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { StandadCard } from "../../../../../../components/Cards";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { LoadState, MODULE_IDS } from "../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../hooks";
import { useFormik } from "formik";
import { Box, Button, Grid } from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../components/formsComponents";
import { IBreadcrumbProps } from "../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useNavigate, useParams } from "react-router-dom";
import { CustomDatePicker } from "../../../../../../components/formsComponents/CustomDatePicker";
import moment from "moment";
import {
  addCommAutomobileOcep28Async,
  clearCommAutomobileOcep28A,
  fetchCommAutomobileOcep28AAsync,
} from "../../../../../../redux/commAutoPolicy/commAutoCertificates/commAutoCertificatesActions";
import { useBreadcrumbContext } from "../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { renderDropdownValue } from "../../../../../../helpers";
import { RoleBasedCustomButton } from "../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { fetchCommAutomobileVehicleListAsync } from "../../../../../../redux/commAutoPolicy/commAutoVehicle/commAutoVehicleActions";

export const CommAutoOPCF_28A: React.FC<{ isDuplicate?: boolean }> = (props) => {
  const { customerId, customerPolicyId, serialNumber } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
    serialNumber?: number;
  };
  const { isDuplicate } = props;

  const { addBreadcrumb } = useBreadcrumbContext();

  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      to: "/view-customers",
      title: "customers",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer-overview",
    },
    {
      to:
        "/comm-automobile-policy/comm-automobile/" +
        customerId +
        "/" +
        customerPolicyId,
      title: "comm-auto",
    },
    {
      title: "opcf-28A",
    },
  ];

  React.useEffect(() => {
    addBreadcrumb({ title: "", items: BCrumb });
    return () => {
      dispatch(clearCommAutomobileOcep28A());
    };
  }, []);

  const { opcf, opcfLoading, error } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.certificates.opcf28A
  );
  const customerInfo = useSelector(
    (storeState: IStoreState) =>
      storeState.commAuto.basicDetails.customerInfo.data
  );

  const vehiclesList = useSelector(
    (storeState: IStoreState) =>
      storeState.commAuto.vehicle.VehicleInformation.vehiclesList
  );

  const [saveLoading, setSaveLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const { values, handleChange, handleSubmit, setFieldValue, setValues } =
    useFormik({
      initialValues: opcf,
      validate: (values) => {},
      onSubmit: (values) => {
        if (customerPolicyId && customerId) {
          setSaveLoading(true);
          dispatch(
            addCommAutomobileOcep28Async(
              {
                ...values,
                customer_policy_id: customerPolicyId,
              },
              (isSuccess) => {
                if (isSuccess) {
                  window.scrollTo(0, 0);
                  navigate(
                    `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}?tab=4`
                  );
                }
                setSaveLoading(false);
              },
              isDuplicate,
            )
          );
        }
      },
    });

  React.useEffect(() => {
    if (customerPolicyId && serialNumber) {
      dispatch(fetchCommAutomobileOcep28AAsync(customerPolicyId, serialNumber));
    }
  }, [customerPolicyId, serialNumber]);

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchCommAutomobileVehicleListAsync(customerPolicyId));
    }
  }, [customerPolicyId, serialNumber]);

  React.useEffect(() => {
    if (!serialNumber) {
      setValues({
        ...opcf,
        issued_to: customerInfo.customer_name,
        policy_number: customerInfo.policy_number,
      });
    } else {
      setValues(opcf);
    }
  }, [opcf, serialNumber]);

  React.useEffect(() => {
    setFieldValue("issued_to", customerInfo.customer_name);
    setFieldValue("policy_number", customerInfo.policy_number);
  }, [customerInfo]);

  React.useEffect(() => {
    if (values.automobile_number) {
      const vehicle = vehiclesList.find(
            (item) => item.incremental_number === values.automobile_number
          );
      if (vehicle) {
        setFieldValue("automobile_number", vehicle.incremental_number);
        setFieldValue("year", vehicle.year);
        setFieldValue("trade_name", vehicle.make);
        setFieldValue("model", vehicle.model);
        setFieldValue("serial_number_or_vin", vehicle.vin);
      }
    }
  }, [values.automobile_number, serialNumber]);

  const finalList = React.useMemo(() => {
    return vehiclesList.map((item) => {
      const commIncremental = item.incremental_number || "";
      const commYear = item.year || "null";
      const commMake = item.make || "null";
      const commModel = item.model || "null";
      const commVin = item.vin || "null";
      return {
        label: `${item.incremental_number || "--"} (
        Year: ${commYear} 
        Make: ${commMake} 
        Model: ${commModel}
        VIN: ${commVin} 
        )`,
        value: commIncremental,
      };
    });
  }, [vehiclesList]);

  React.useEffect(() => {
    return () => {
      dispatch(clearCommAutomobileOcep28A());
    };
  }, []);

  return (
    <PageLoader
      loading={opcfLoading === LoadState.InProgress}
      error={error ? { message: error } : undefined}
    >
      <StandadCard sx={{ mt: 2 }} heading="OPCF 28A Excluded Driver">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={1}>
              <CustomFormLabel>#</CustomFormLabel>
              <CustomTextField
                name="incremental_number"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.incremental_number}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Issued to</CustomFormLabel>
              <CustomTextField
                name="issued_to"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                disabled
                value={values.issued_to}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <CustomFormLabel>Effective Date of Change</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.effective_date_of_change || ""}
                onChange={(newValue) => {
                  setFieldValue("effective_date_of_change", newValue);
                }}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <CustomFormLabel>Policy Number</CustomFormLabel>
              <CustomTextField
                name="policy_number"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                disabled
                value={values.policy_number}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <CustomFormLabel>Automobile #</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="automobile_number"
                fullWidth
                value={Number(values.automobile_number)}
                onChange={handleChange}
                displayEmpty
                renderValue={renderDropdownValue(`Select one`)}
                placeholder="Select one"
                options={finalList}
              ></ControlledCustomSelect>
            </Grid>

            <Grid item xs={12} md={2}>
              <CustomFormLabel>Model</CustomFormLabel>
              <CustomTextField
                name="model"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                disabled
                value={values.model}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <CustomFormLabel>Year</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.year || ""}
                inputFormat="YYYY"
                views={["year"]}
                disabled
                onChange={(newValue) => {
                  setFieldValue("year", newValue);
                }}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <CustomFormLabel>Trade Name (Make)</CustomFormLabel>
              <CustomTextField
                name="trade_name"
                variant="outlined"
                size="small"
                type="text"
                disabled
                fullWidth
                value={values.trade_name}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <CustomFormLabel>Serial # /VIN</CustomFormLabel>
              <CustomTextField
                name="serial_number_or_vin"
                variant="outlined"
                size="small"
                type="text"
                disabled
                fullWidth
                value={values.serial_number_or_vin}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <CustomFormLabel>Name of Excluded Driver</CustomFormLabel>
              <CustomTextField
                name="name_of_excluded_driver"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.name_of_excluded_driver}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>Driver's Licence #</CustomFormLabel>
              <CustomTextField
                name="driver_license_number"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.driver_license_number}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: 4 }}>
            <RoleBasedCustomButton
              sx={{ width: "15%" }}
              moduleId={MODULE_IDS.COMMERCIAL_AUTO}
              disabled={saveLoading}
              variant="contained"
              type="submit"
            >
              Save
            </RoleBasedCustomButton>
          </Box>
        </form>
      </StandadCard>
      {/* </CustomerBaiscDetailsLayout> */}
    </PageLoader>
  );
};
