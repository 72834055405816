import { action } from "typesafe-actions";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { IAddDriver, IDeleteDriver, initialTaskDriverState } from "./driver.types";
import { api } from "../../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";
import { uploadFile } from "../../../helpers";

export const FETCH_ADD_DRIVER_PROGRESS = "FETCH_ADD_DRIVER_PROGRESS";
export const FETCH_ADD_DRIVER_SUCCESS = "FETCH_ADD_DRIVER_SUCCESS";
export const FETCH_ADD_DRIVER_FAILED = "FETCH_ADD_DRIVER_FAILED";

export const fetchAddDriverProgress = () => action(FETCH_ADD_DRIVER_PROGRESS);
export const fetchAddDriverSuccess = (data: IAddDriver) =>
  action(FETCH_ADD_DRIVER_SUCCESS, { data });
export const fetchAddDriverFailed = () => action(FETCH_ADD_DRIVER_FAILED);

export const fetchAddDriverAysnc =
  (taskId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchAddDriverProgress());
        const res = await api.get(
          `tasks/get-tasks-add-driver?task_code=${taskId}`
        );
        const data: IAddDriver[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchAddDriverSuccess(data[0]));
        } else {
          dispatch(
            fetchAddDriverSuccess(initialTaskDriverState.addDriver["data"])
          );
        }
      } catch (err: any) {
        dispatch(fetchAddDriverFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      }
    };

export const upsertTaskAddDriverAysnc =
  (
    task: IAddDriver,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
     
      dispatch(saveLoaderProgress())
    
      
      const res = await api.post("/tasks/create-tasks-add-driver", {
        ...task,
      });
      dispatch(
        showMessage({
          type: "success",
          message: 'Driver is saved succesfully!',
          displayAs: "snackbar",
        })
      );
      dispatch(fetchAddDriverSuccess({...task}));
      onCallback(true)
    } catch (err: any) {
      onCallback(false)
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }finally {
      dispatch(saveLoaderCompleted())
    }
};

// Delete Driver

export const FETCH_DELETE_DRIVER_PROGRESS = "FETCH_DELETE_DRIVER_PROGRESS";
export const FETCH_DELETE_DRIVER_SUCCESS = "FETCH_DELETE_DRIVER_SUCCESS";
export const FETCH_DELETE_DRIVER_FAILED = "FETCH_DELETE_DRIVER_FAILED";

export const fetchDeleteDriverProgress = () => action(FETCH_DELETE_DRIVER_PROGRESS);
export const fetchDeleteDriverSuccess = (data: IDeleteDriver) =>
  action(FETCH_DELETE_DRIVER_SUCCESS, { data });
export const fetchDeleteDriverFailed = () => action(FETCH_DELETE_DRIVER_FAILED);


export const fetchDeleteDriverAysnc =
  (taskCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchDeleteDriverProgress());
        const res = await api.get(
          `tasks/get-tasks-delete-driver?task_code=${taskCode}`
        );
        const data: IDeleteDriver[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchDeleteDriverSuccess(data[0]));
        } else {
          dispatch(
            fetchDeleteDriverSuccess(initialTaskDriverState.deleteDriver["data"])
          );
        }
      } catch (err: any) {
        dispatch(fetchDeleteDriverFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      }
    };

export const upsertTaskDeleteDriverAysnc =
  (
    task: IDeleteDriver,
    onCallback: (isSuccess: boolean, taskId?: number) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
 
      dispatch(saveLoaderProgress())
      await api.post("/tasks/create-tasks-delete-driver", {
        ...task,
      });
      dispatch(
        showMessage({
          type: "success",
          message: 'Task is saved succesfully!',
          displayAs: "snackbar",
        })
      );
      // dispatch(fetchAddDriverSuccess({...task,task_code: res.data.data.task_id}));
      onCallback(true)
    } catch (err: any) {
      onCallback(false)
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }finally {
      dispatch(saveLoaderCompleted())
    }
};
