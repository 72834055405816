import produce from "immer";
import { LoadState } from "../../../constants/enums";
import { IStoreState } from "../../initialStoreState";
import { CLEAR_LIFE_INSURANCE_COMPLIANCE_CHECKLIST, 
  CLEAR_LIFE_INSURANCE_COMPLIANCE_CHECKLIST_STATE, 
  FETCH_LIFE_INSURANCE_COMPLIANCE_CHECKLIST_FAILED, 
  FETCH_LIFE_INSURANCE_COMPLIANCE_CHECKLIST_PROGRESS, 
  FETCH_LIFE_INSURANCE_COMPLIANCE_CHECKLIST_SUCCESS } 
  from "./lifeInsurancePolicyComplianceCheckListActions";
import { LifeInsurancePolicyComplianceCheckListAction } from ".";
import { defaultLifeInsurancePolicyComplianceCheckList } from "./lifeInsurancePolicyComplianceCheckList.types";

export const lifeInsuranceComplianceCheckListReducer = (
  state: IStoreState["lifeInsurance"]["complianceCheckList"] = defaultLifeInsurancePolicyComplianceCheckList,
  action: LifeInsurancePolicyComplianceCheckListAction
) => {
  switch (action.type) {
    case FETCH_LIFE_INSURANCE_COMPLIANCE_CHECKLIST_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = defaultLifeInsurancePolicyComplianceCheckList;
      });
      return newState;
    }
    case FETCH_LIFE_INSURANCE_COMPLIANCE_CHECKLIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_LIFE_INSURANCE_COMPLIANCE_CHECKLIST_FAILED: {
      const {errorMessage} = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
  
    case CLEAR_LIFE_INSURANCE_COMPLIANCE_CHECKLIST: {
      const newState = produce(state, (draftState: any) => {
        draftState.data = defaultLifeInsurancePolicyComplianceCheckList;
        draftState.loading = LoadState.NotLoaded;
      });
      return newState;
    }
    case CLEAR_LIFE_INSURANCE_COMPLIANCE_CHECKLIST_STATE: {
      return defaultLifeInsurancePolicyComplianceCheckList;
    }
    default: {
      return state;
    }
  }
};