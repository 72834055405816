import { action } from "typesafe-actions";
import { IBusinessPolicySubscription } from "./businessPolicySubscription.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { api } from "../../../api/api";
import { ERROR_MESSAGES } from "../../../constants/enums";

export const FETCH_BUSINESS_POLICY_SUBSCRIPTION_LIST_PROGRESS =
  "FETCH_BUSINESS_POLICY_SUBSCRIPTION_LIST_PROGRESS";
export const FETCH_BUSINESS_POLICY_SUBSCRIPTION_LIST_SUCCESS =
  "FETCH_BUSINESS_POLICY_SUBSCRIPTION_LIST_SUCCESS";
export const FETCH_BUSINESS_POLICY_SUBSCRIPTION_LIST_FAILED =
  "FETCH_BUSINESS_POLICY_SUBSCRIPTION_LIST_FAILED";

export const fetchBusinessPolicySubscriptionListProgress = () =>
  action(FETCH_BUSINESS_POLICY_SUBSCRIPTION_LIST_PROGRESS);
export const fetchBusinessPolicySubscriptionListSuccess = (
  data: IBusinessPolicySubscription[]
) => action(FETCH_BUSINESS_POLICY_SUBSCRIPTION_LIST_SUCCESS, { data });
export const fetchBusinessPolicySubscriptionListFailed = () =>
  action(FETCH_BUSINESS_POLICY_SUBSCRIPTION_LIST_FAILED);

export const fetchBusinessPolicySubscriptionListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicySubscriptionListProgress());
      const res = await api.get(
        `/policy/get-policy-business-subscription?customer_policy_id=${customerPolicyId}`
      );
      const data: IBusinessPolicySubscription[] = res.data.data;
      dispatch(fetchBusinessPolicySubscriptionListSuccess(data));
    } catch (err: any) {
      dispatch(fetchBusinessPolicySubscriptionListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addBusinesPolicySubscriptionAsync =
  (
    subscription: IBusinessPolicySubscription,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(`/policy/edit-policy-business-subscription`, {
        ...subscription,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Intrested Parties saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_BUSINESS_POLICY_SUBSCRIPTION_PROGRESS =
  "FETCH_BUSINESS_POLICY_SUBSCRIPTION_PROGRESS";
export const FETCH_BUSINESS_POLICY_SUBSCRIPTION_SUCCESS =
  "FETCH_BUSINESS_POLICY_SUBSCRIPTION_SUCCESS";
export const FETCH_BUSINESS_POLICY_SUBSCRIPTION_FAILED =
  "FETCH_BUSINESS_POLICY_SUBSCRIPTION_FAILED";

export const fetchBusinessPolicySubscriptionProgress = () =>
  action(FETCH_BUSINESS_POLICY_SUBSCRIPTION_PROGRESS);
export const fetchBusinessPolicySubscriptionSuccess = (
  data: IBusinessPolicySubscription
) => action(FETCH_BUSINESS_POLICY_SUBSCRIPTION_SUCCESS, { data });
export const fetchBusinessPolicySubscriptionFailed = (errorMessage: string) =>
  action(FETCH_BUSINESS_POLICY_SUBSCRIPTION_FAILED, { errorMessage });

export const fetchBusinessPolicySubscriptionAsync =
  (
    customerPolicyId: number,
    subscriptionNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicySubscriptionProgress());
      const res = await api.get(
        `/policy/get-policy-business-subscription?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${subscriptionNumber}`
      );
      const data: IBusinessPolicySubscription[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBusinessPolicySubscriptionSuccess(data[0]));
      } else {
        dispatch(
          fetchBusinessPolicySubscriptionFailed(
            "Oops! We couldn't find any records associated with your intrested parties number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchBusinessPolicySubscriptionFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_BUSINES_POLICY_SUBSCRIPTION_STATE =
  "CLEAR_BUSINES_POLICY_SUBSCRIPTION_STATE";
export const CLEAR_BUSINES_POLICY_SUBSCRIPTION =
  "CLEAR_BUSINES_POLICY_SUBSCRIPTION";

export const clearBusinessPolicySubscriptionState = () =>
  action(CLEAR_BUSINES_POLICY_SUBSCRIPTION_STATE);

export const clearBusinessPolicySubscription = () =>
  action(CLEAR_BUSINES_POLICY_SUBSCRIPTION);
