import { LoadState } from "../../constants/enums";
import { IInsurerState } from "./insurer.types";

export const initialInsurerState: IInsurerState = {
  insurerCode: {
    data: {
      insurer_code: null,
      insurer_code_id: 0,
      dim_insurer_code_id: null,
      name: "",
      email: "",
      company_email: "",
      phone_number: "",
      address: "",
      city: "",
      province_or_state: "",
      country: "",
      postal_code: "",
      unit_or_suite: "",
      agency_code: "",
      sub_code: "",
      logo_file: "",
      csio_code: "",
      agency_bill_trust_payable_g_or_l_code: "",
      direct_bill_receivable_g_or_l_code: "",
      agency_bill_revenue_g_or_l_code: "",
      direct_bill_revenue_g_or_l_code: "",
      default_billing: "",
      insert_ts: "",
      status: "ACTIVE",
    },
    loading: LoadState.NotLoaded,
    saveLoading: false,
    error: null,
  },
  
};
