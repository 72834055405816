import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";
import { api } from "../../../api/api";
import { initialSystemGLAccountState, ISystemGLAccount } from "./SystemGLAccount.types";

export const FETCH_ACCOUNTING_SYSTEM_GL_ACCOUNT_PROGRESS =
  "FETCH_ACCOUNTING_SYSTEM_GL_ACCOUNT_PROGRESS";
export const FETCH_ACCOUNTING_SYSTEM_GL_ACCOUNT_SUCCESS =
  "FETCH_ACCOUNTING_SYSTEM_GL_ACCOUNT_SUCCESS";
export const FETCH_ACCOUNTING_SYSTEM_GL_ACCOUNT_FAILURE =
  "FETCH_ACCOUNTING_SYSTEM_GL_ACCOUNT_FAILURE";

export const fetchAccountingSystemGLAccountProgress = () =>
  action(FETCH_ACCOUNTING_SYSTEM_GL_ACCOUNT_PROGRESS);
export const fetchAccountingSystemGLAccountSuccess = (data: ISystemGLAccount) =>
  action(FETCH_ACCOUNTING_SYSTEM_GL_ACCOUNT_SUCCESS, { data });
export const fetchAccountingSystemGLAccountFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_SYSTEM_GL_ACCOUNT_FAILURE, { errorMessage });

export const fetchAccountingSystemGLAccountAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingSystemGLAccountProgress());

      const res = await api.get(`/accounting/get-account-system-gl-account`);
      const data: ISystemGLAccount[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAccountingSystemGLAccountSuccess(data[0]));
      } else {
        dispatch(
          fetchAccountingSystemGLAccountSuccess(initialSystemGLAccountState["data"])
        );
      }
    } catch (err: any) {
      dispatch(
        fetchAccountingSystemGLAccountFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingSystemGLAccountAysnc =
  (
    data: ISystemGLAccount,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post(`/accounting/edit-account-system-gl-account`, {
        ...data,
      });

      let message = "System GL Account saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const CLEAR_ACCOUNTING_SYSTEM_GL_ACCOUNT =
  "CLEAR_ACCOUNTING_SYSTEM_GL_ACCOUNT";
export const clearAccountingSystemGLAccount = () =>
  action(CLEAR_ACCOUNTING_SYSTEM_GL_ACCOUNT);
