import React from "react";
import { Box, Button, Link, MenuItem } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import { LoadState } from "../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { fetchCommAutomobileApplicationListAsync } from "../../../../../../redux/commAutoPolicy/commAutoApplication/commAutoApplicationActions";
import { ICommAutomobileApplication } from "../../../../../../redux/commAutoPolicy/commAutoApplication/commAutoApplication.types";
import { fetchCommAutomobileGarageApplicationListAsync } from "../../../../../../redux/commAutoPolicy/commAutoGarageApplication/commAutoGarageApplicationActions";
import { ICommAutomobileGarageApplicationData } from "../../../../../../redux/commAutoPolicy/commAutoGarageApplication/commAutoGarageApplication.types";
import { NotificationsActive, ThumbUpAlt } from "@mui/icons-material";
import { CustomChip } from "../../../../../../components/CustomChip";
import { CustomSelect } from "../../../../../../components/formsComponents";
import { renderDropdownValue } from "../../../../../../helpers";

export const CommAutoInsuranceGarageApplicationList = () => {

  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerpolicyId = data1[1];

  const navigate = useNavigate();
  const { listData: data, listLoading: loading } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.garageApplication
  );

  const dispatch = useDispatchWrapper();

  const [selectedInsurance, setselectedInsurance] = React.useState<{
    isAllSelected: boolean;
    certificate: ICommAutomobileGarageApplicationData[];
  }>({
    isAllSelected: false,
    certificate: [],
  });

  const menuItems =
  selectedInsurance.certificate.length > 0 || selectedInsurance.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  React.useEffect(() => {
    dispatch(fetchCommAutomobileGarageApplicationListAsync(customerpolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerpolicyId]);

  const automobileApplicationTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    selectionMode: "multiple",
    uniqueRowKeyName: "incremental_number",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          ...menuItems,
        ]
      },
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    `/comm-automobile-policy/${customerId}/application-tab/${customerpolicyId}/garage`
                  );
                }}
              >
                Add Garage Application
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
        onRowCellRender: (value, row: ICommAutomobileGarageApplicationData) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/application-tab/${customerpolicyId}/garage/${row.incremental_number}`
                );
              }}
            >
              {row.incremental_number}
            </Link>
          );
        },
      },

      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobileGarageApplicationData) => {
          if (row.signature_of_broker === "ACTIVE") {
            return (
              <CustomChip
                color="success"
                icon={<ThumbUpAlt />}
                content={"Active"}
                size="small"
              />
            );
          } else {
            return (
              <CustomChip
                color="error"
                icon={<NotificationsActive />}
                content={"Cancel"}
                size="small"
              />
            );
          }
        },
      },

      {
        key: "expiry_date",
        headerName: "Expiry Date",
        fieldName: "expiry_date",
        exportCellWidth: 25,
        renderType: RenderType.DATE_DARK_COLOR,
      },
      {
        key: "insurer",
        headerName: "Insurer",
        fieldName: "insurer",
        exportCellWidth: 25,
        renderType: RenderType.CHIP_WARNING,
      },


      {
        key: "policy_type",
        headerName: "Policy Type",
        fieldName: "policy_type",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "policy_no",
        headerName: "Policy Number",
        fieldName: "policy_no",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },

      {
        key: "insurance_company",
        headerName: "Insurance Company",
        fieldName: "insurance_company",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "policy_number_assigned",
        headerName: "Policy Number Assigned",
        fieldName: "policy_number_assigned",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },


      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: 'center',
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobileGarageApplicationData) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/application-tab/${customerpolicyId}/garage/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
    ],
    items: data,
    onSelection: (isAllSelected: boolean, rows: ICommAutomobileGarageApplicationData[]) => {
      setselectedInsurance({ isAllSelected: isAllSelected, certificate: rows });
    },
  };

  return (
    <Box sx={{ mt: 2 }}>
      <DataTable {...automobileApplicationTableProps} />
    </Box>
  );
};
