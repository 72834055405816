import { LoadState } from "../../constants/enums";


export interface ICSIOCronState {
  list: ICSIOCron[];
   loading: LoadState;
}


export interface ICSIOCron {
    csio_cron_code: string | null;
    cron_name: string | null; 
    cron_schedule_at: {
        second: number | null;
        minute: number | null;
        hour:  number | null;
        date:  number | null;
        month:  number | null;
        week:  number | null;
        repeatEvery: boolean;
      };
    status: string; 
    created_by_id?: string | null; 
    created_by_name?: string | null; 
    modified_by_id?: string | null; 
    modified_by_name?: string | null; 
    create_ts? : string | null; 
    insert_ts? : string | null; 
}

 export const initialCSIOCron: ICSIOCron = {
     csio_cron_code: null,
     cron_name: null,
     cron_schedule_at: {
        date: null,
        hour: null,
        minute: null,
        month: null,
        repeatEvery: false,
        second: null,
        week: null
     },
     status: "ACTIVE"

 } 

 export const initialCSIOCronState: ICSIOCronState = {
    list: [],
    loading: LoadState.NotLoaded,
 }