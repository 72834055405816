import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { IHabitationalNonCsioBinder } from "./habitationalNonCsioBinder.types";
import { uploadFile } from "../../../helpers";

export const FETCH_HABITATIONAL_NON_CSIO_BINDER_LIST_PROGRESS =
  "FETCH_HABITATIONAL_NON_CSIO_BINDER_LIST_PROGRESS";
export const FETCH_HABITATIONAL_NON_CSIO_BINDER_LIST_SUCCESS =
  "FETCH_HABITATIONAL_NON_CSIO_BINDER_LIST_SUCCESS";
export const FETCH_HABITATIONAL_NON_CSIO_BINDER_LIST_FAILED =
  "FETCH_HABITATIONAL_NON_CSIO_BINDER_LIST_FAILED";

export const fetchHabitationalNonCsioBinderListProgress = () =>
  action(FETCH_HABITATIONAL_NON_CSIO_BINDER_LIST_PROGRESS);
export const fetchHabitationalNonCsioBinderListSuccess = (
  data: IHabitationalNonCsioBinder[]
) => action(FETCH_HABITATIONAL_NON_CSIO_BINDER_LIST_SUCCESS, { data });
export const fetchHabitationalNonCsioBinderListFailed = () =>
  action(FETCH_HABITATIONAL_NON_CSIO_BINDER_LIST_FAILED);

export const fetchHabitationalNonCsioBinderListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalNonCsioBinderListProgress());
      const res = await api.get(
        `/policy/get-habitational-binder-non-csio?customer_policy_id=${customerPolicyId}`
      );
      const data: IHabitationalNonCsioBinder[] = res.data.data;
      dispatch(fetchHabitationalNonCsioBinderListSuccess(data));
    } catch (err: any) {
      dispatch(fetchHabitationalNonCsioBinderListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadHabitationalNonCsioBinderPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-habitational-binder-non-csio-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchHabitationalNonCsioBinderListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewHabitationalNonCsioBinderPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-habitational-binder-non-csio-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_habitational_binder_non_csio_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchHabitationalNonCsioBinderListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_HABITATIONAL_NON_CSIO_BINDER_PROGRESS =
  "FETCH_HABITATIONAL_NON_CSIO_BINDER_PROGRESS";
export const FETCH_HABITATIONAL_NON_CSIO_BINDER_SUCCESS =
  "FETCH_HABITATIONAL_NON_CSIO_BINDER_SUCCESS";
export const FETCH_HABITATIONAL_NON_CSIO_BINDER_FAILED =
  "FETCH_HABITATIONAL_NON_CSIO_BINDER_FAILED";

export const fetchHabitationalNonCsioBinderProgress = () =>
  action(FETCH_HABITATIONAL_NON_CSIO_BINDER_PROGRESS);
export const fetchHabitationalNonCsioBinderSuccess = (
  data: IHabitationalNonCsioBinder
) => action(FETCH_HABITATIONAL_NON_CSIO_BINDER_SUCCESS, { data });
export const fetchHabitationalNonCsioBinderFailed = (errorMessage: string) =>
  action(FETCH_HABITATIONAL_NON_CSIO_BINDER_FAILED, { errorMessage });

export const fetchHabitationalNonCsioBinderAsync =
  (
    customerPolicyId: number,
    nonCsio: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalNonCsioBinderProgress());
      const res = await api.get(
        `/policy/get-habitational-binder-non-csio?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${nonCsio}`
      );
      const data: IHabitationalNonCsioBinder[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchHabitationalNonCsioBinderSuccess(data[0]));
      } else {
        dispatch(
          fetchHabitationalNonCsioBinderFailed(
            "Oops! We couldn't find any records associated with your restrutant number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchHabitationalNonCsioBinderFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addHabitationalNonCsioBinderAsync =
  (
    nonCsio: IHabitationalNonCsioBinder,
    customerID: number,
    signature: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        customer_id: customerID,
        policy_type: "HABITATIONAL",
        customer_policy_id: nonCsio.customer_policy_id,
        page_name: "Habitational Non-Binder",
      };

      const path = await uploadFile(
        signature,
        "POLICY",
        nonCsio.signature_of_broker || "",
        asPayload
      );
      await api.post(`/policy/edit-habitational-binder-non-csio`, {
        ...nonCsio,
        signature_of_broker: path,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Non-Csio Binder saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_HABITATIONAL_NON_CSIO_BINDER =
  "CLEAR_HABITATIONAL_NON_CSIO_BINDER";
export const CLEAR_HABITATIONAL_NON_CSIO_BINDER_STATE =
  "CLEAR_HABITATIONAL_NON_CSIO_BINDER_STATE";

export const clearHabitationalNonCsioBinderState = () =>
  action(CLEAR_HABITATIONAL_NON_CSIO_BINDER_STATE);

export const clearHabitationalNonCsioBinder = () =>
  action(CLEAR_HABITATIONAL_NON_CSIO_BINDER);
