import produce from "immer";
import { LoadState } from "../../../constants/enums";
import { IStoreState } from "../../initialStoreState";
import { defaultlifeInsurancePolicyExistingProperty } from "./lifeInsurancePolicyExistingProperty.types";
import { LifeInsurancePolicyExistingPropertyAction } from ".";
import { CLEAR_LIFE_INSURANCE_EXISTING_PROPERTY, CLEAR_LIFE_INSURANCE_EXISTING_PROPERTY_STATE, FETCH_LIFE_INSURANCE_EXISTING_PROPERTY_FAILED, FETCH_LIFE_INSURANCE_EXISTING_PROPERTY_PROGRESS, FETCH_LIFE_INSURANCE_EXISTING_PROPERTY_SUCCESS } from "./lifeInsurancePolicyExistingPropertyActions";

export const lifeInsurancePolicyExistingPropertyReducer = (
  state: IStoreState["lifeInsurance"]["existingProperty"] = defaultlifeInsurancePolicyExistingProperty,
  action: LifeInsurancePolicyExistingPropertyAction
) => {
  switch (action.type) {
    case FETCH_LIFE_INSURANCE_EXISTING_PROPERTY_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = defaultlifeInsurancePolicyExistingProperty;
      });
      return newState;
    }
    case FETCH_LIFE_INSURANCE_EXISTING_PROPERTY_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_LIFE_INSURANCE_EXISTING_PROPERTY_FAILED: {
      const {errorMessage} = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
  
    case CLEAR_LIFE_INSURANCE_EXISTING_PROPERTY: {
      const newState = produce(state, (draftState: any) => {
        draftState.data = defaultlifeInsurancePolicyExistingProperty;
        draftState.loading = LoadState.NotLoaded;
      });
      return newState;
    }
    case CLEAR_LIFE_INSURANCE_EXISTING_PROPERTY_STATE: {
      return defaultlifeInsurancePolicyExistingProperty;
    }
    default: {
      return state;
    }
  }
};