import { useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../hooks";
import React from "react";
import { Grid, Typography } from "@mui/material";
import { StandardCellList } from "../../../../components/Standards";
import { formatTime } from "../../../../helpers";
import { fetchDeleteInterestedPartyAysnc } from "../../../../redux/TaskReducer/InterestedParty/interestedPartyActions";
import { IDeleteInterestedParty } from "../../../../redux/TaskReducer/InterestedParty/interestedParty.types";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../constants/enums";
import { stripHtml } from "../../../../helpers/stripHtml";
import { QuillEditor } from "../../../../components/QuillEditor/QuillEditor";

export const ViewDeleteInterestedParty: React.FC<{
  taskCode: string;
}> = (props) => {
  const { taskCode } = props;

  const { data, loading } = useSelector(
    (storeState: IStoreState) =>
      storeState.tasks.interestedParty.deleteInterestedParty
  );

  const dispatch = useDispatchWrapper();
  const [interestedPartyDetails, setInterestedPartyDetails] =
    React.useState<IDeleteInterestedParty>();

  React.useEffect(() => {
    dispatch(fetchDeleteInterestedPartyAysnc(taskCode));
  }, [taskCode]);

  React.useEffect(() => {
    setInterestedPartyDetails(data);
  }, [data]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      {interestedPartyDetails && (
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Garage Plate Information"
              colSize={3}
              list={[
                {
                  heading: "Party Code",
                  data: {
                    type: "text",
                    value: interestedPartyDetails.interested_party_code,
                  },
                },
                {
                  heading: "Effective Date",
                  data: {
                    type: "text",
                    value: interestedPartyDetails.effective_date_of_change,
                  },
                },
                {
                  heading: "Effective Time",
                  data: {
                    type: "text",
                    value: formatTime(
                      interestedPartyDetails.effective_time_of_change.toString()
                    ),
                  },
                },
              ]}
            >
              <Grid item xs={12} md={12}>
                <Typography fontWeight={500} variant="h4" mb={1}>
                  Details
                </Typography>
                <QuillEditor
                  value={interestedPartyDetails.details || ""}
                  onChange={() => {}}
                  readOnly={true}
                />
              </Grid>
            </StandardCellList>
          </Grid>
        </Grid>
      )}
    </PageLoader>
  );
};
