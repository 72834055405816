/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Button, Link, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { LoadState } from "../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../hooks";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { ICommAutomobileOCEP28A } from "../../../../../../redux/commAutoPolicy/commAutoCertificates/commAutoCertificates.types";
import {
  downloadCommAutomobileOcep28AListPDFAsync,
  fetchCommAutomobileOcep28AListAsync,
  previewCommAutomobileOcep28AListPDFAsync,
} from "../../../../../../redux/commAutoPolicy/commAutoCertificates/commAutoCertificatesActions";
import { renderDropdownValue } from "../../../../../../helpers";
import { CustomSelect } from "../../../../../../components/formsComponents";
import { Add, NotificationsActive, ThumbUpAlt } from "@mui/icons-material";
import { CustomChip } from "../../../../../../components/CustomChip";

export const CommAutoOPCF28AList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { list, loading } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.certificates.opcf28A
  );
  const [downloading, setDownloading] = React.useState(false);
  const dispatch = useDispatchWrapper();

  const [selectedOpcf, setselectedOpcf] = React.useState<{
    isAllSelected: boolean;
    certificate: ICommAutomobileOCEP28A[];
  }>({
    isAllSelected: false,
    certificate: [],
  });

  const menuItems =
    selectedOpcf.certificate.length > 0 || selectedOpcf.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const OPCF_28ATableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    selectionMode: "multiple",
    uniqueRowKeyName: "incremental_number",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [...menuItems],
      },
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => {
                  navigate(
                    `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/opcf-28A`
                  );
                }}
              >
                Create
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: ICommAutomobileOCEP28A) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/opcf-28A/${row.incremental_number}`
                );
              }}
            >
              {row.incremental_number}
            </Link>
          );
        },
      },

      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobileOCEP28A) => {
          if (row.status === "ACTIVE") {
            return (
              <CustomChip
                color="success"
                icon={<ThumbUpAlt />}
                content={"Active"}
                size="small"
              />
            );
          } else {
            return (
              <CustomChip
                color="error"
                icon={<NotificationsActive />}
                content={"Cancel"}
                size="small"
              />
            );
          }
        },
      },

      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        renderType: RenderType.CHIP_WARNING,
        exportCellWidth: 30,
      },
      {
        key: "issued_to",
        headerName: "Issued to",
        fieldName: "issued_to",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
      },
      {
        key: "effective_date_of_change",
        headerName: "Effective date of Change",
        fieldName: "effective_date_of_change",
        exportCellWidth: 25,
        renderType: RenderType.DATE,
      },

      {
        key: "incremental_number",
        headerName: "Automobile Number",
        fieldName: "incremental_number",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },
      {
        key: "model",
        headerName: "Model",
        fieldName: "model",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "year",
        headerName: "Year",
        fieldName: "year",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "trade_name",
        headerName: "Trade Name",
        fieldName: "trade_name",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "serial_number_or_vin",
        headerName: "Serial # /VIN",
        fieldName: "serial_number_or_vin",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "driver_license_number",
        headerName: "Driver's Licence #",
        fieldName: "driver_license_number",
        exportCellWidth: 25,
        renderType: RenderType.TEXT_DARK,
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobileOCEP28A) => {
          return (
            <StandardTableActions
              downloadLoading={downloading}
              onDownloadPreview={() => {
                dispatch(
                  previewCommAutomobileOcep28AListPDFAsync(
                    customerPolicyId,
                    row.incremental_number as number,
                    () => {}
                  )
                );
              }}
              onDownLoadClick={() => {
                if (row.incremental_number) {
                  setDownloading(true);
                  dispatch(
                    downloadCommAutomobileOcep28AListPDFAsync(
                      customerPolicyId,
                      row.incremental_number,
                      () => {
                        setDownloading(false);
                      }
                    )
                  );
                }
              }}
              onEditClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/opcf-28A/${row.incremental_number}`
                );
              }}
              onDuplicateClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/opcf-28A-duplicate/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
    ],
    items: list,
    onSelection: (isAllSelected: boolean, rows: ICommAutomobileOCEP28A[]) => {
      setselectedOpcf({ isAllSelected: isAllSelected, certificate: rows });
    },
  };

  React.useEffect(() => {
    dispatch(fetchCommAutomobileOcep28AListAsync(customerPolicyId));
  }, [customerPolicyId]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...OPCF_28ATableProps} />
      </Box>
    </>
  );
};
