import moment from "moment";
import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface IHabitationalCoveragesState {
  data: IHabitationalCoverages[];
  loading: LoadState;
  coverages: IHabitationalCoverages;
  coveragesLoading: LoadState;
  error: string | null;
}

export interface IHabitationalCoverageForm {
  key: string;
  coverage_discription: string | null;
  requested_or_declined: string | null;
  amount_of_insurance: string | null;
  deductible: string | null;
  deductible_type: string | null;
  type1: string | null;
  type2: string | null;
  type3: string | null;
  type4: string | null;
  type5: string | null;
  premium: string | null;
}
export interface IHabitatioanalLiabilityForm {
  key: string;
  liability_coverage_discription: string | null;
  requested_or_declined: string | null;
  amount_of_insurance: string | null;
  deductible: string | null;
  deductible_type: string | null;
  type1: string | null;
  type2: string | null;
  type3: string | null;
  type4: string | null;
  type5: string | null;
  estimated_premium: string | null;
}
export interface IHabitationalDiscountSurcharge {
  key: string;
  discount_or_surcharge_discription: string | null;
  percentage: string | null;
  applied_to_premium: string | null;
  est_discount_or_surchage: string | null;
}

export interface IHabitationalCoverages {
  customer_policy_id: number;
  incremental_number: null;
  coverages_form: IHabitationalCoverageForm[];
  liability_extension_and_exclusion_form: IHabitatioanalLiabilityForm[];
  discount_and_surcharges_form: IHabitationalDiscountSurcharge[];
}

export const defaultHabitationalCoveragesState: IHabitationalCoveragesState = {
  data: [],
  loading: LoadState.NotLoaded,
  coverages: {
    customer_policy_id: 0,
    incremental_number: null,
    coverages_form: [
      {
        key: getUniqueId(),
        coverage_discription: "Dwelling Buildings",
        deductible: null,
        requested_or_declined: null,
        amount_of_insurance: null,
        deductible_type: null,
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        premium: null,
      },
      {
        key: getUniqueId(),
        coverage_discription: "Detached Private Structures",
        deductible: null,
        requested_or_declined: null,
        amount_of_insurance: null,
        deductible_type: null,
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        premium: null,
      },
      {
        key: getUniqueId(),
        coverage_discription: "Personal Property",
        deductible: null,
        requested_or_declined: null,
        amount_of_insurance: null,
        deductible_type: null,
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        premium: null,
      },
      {
        key: getUniqueId(),
        coverage_discription: "Additional Living Expenses",
        deductible: null,
        requested_or_declined: null,
        amount_of_insurance: null,
        deductible_type: null,
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        premium: null,
      },
      {
        key: getUniqueId(),
        coverage_discription: "Legal Liability",
        deductible: null,
        requested_or_declined: null,
        amount_of_insurance: null,
        deductible_type: null,
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        premium: null,
      },
      {
        key: getUniqueId(),
        coverage_discription: "Voluntary Medical Payments",
        deductible: null,
        requested_or_declined: null,
        amount_of_insurance: null,
        deductible_type: null,
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        premium: null,
      },
      {
        key: getUniqueId(),
        coverage_discription: "Voluntary Payment Damage",
        deductible: null,
        requested_or_declined: null,
        amount_of_insurance: null,
        deductible_type: null,
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        premium: null,
      },
      {
        key: getUniqueId(),
        coverage_discription: "Sewer Backup",
        deductible: null,
        requested_or_declined: null,
        amount_of_insurance: null,
        deductible_type: null,
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        premium: null,
      },
      {
        key: getUniqueId(),
        coverage_discription: "Single Limit",
        deductible: null,
        requested_or_declined: null,
        amount_of_insurance: null,
        deductible_type: null,
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        premium: null,
      },
      {
        key: getUniqueId(),
        coverage_discription: "Guaranteed Replacement Cost-Building",
        deductible: null,
        requested_or_declined: null,
        amount_of_insurance: null,
        deductible_type: null,
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        premium: null,
      },
      {
        key: getUniqueId(),
        coverage_discription: "Replacement Cost - Contents/Extended",
        deductible: null,
        requested_or_declined: null,
        amount_of_insurance: null,
        deductible_type: null,
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        premium: null,
      },
      {
        key: getUniqueId(),
        coverage_discription: "Unit Owners Building Improvements and Betterments",
        deductible: null,
        requested_or_declined: null,
        amount_of_insurance: null,
        deductible_type: null,
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        premium: null,
      },
      {
        key: getUniqueId(),
        coverage_discription: "Loss Assessments",
        deductible: null,
        requested_or_declined: null,
        amount_of_insurance: null,
        deductible_type: null,
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        premium: null,
      },
      {
        key: getUniqueId(),
        coverage_discription: "Condominium Contingent Legal Liability",
        deductible: null,
        requested_or_declined: null,
        amount_of_insurance: null,
        deductible_type: null,
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        premium: null,
      },
      {
        key: getUniqueId(),
        coverage_discription: "Unit Owners Additional Protection",
        deductible: null,
        requested_or_declined: null,
        amount_of_insurance: null,
        deductible_type: null,
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        premium: null,
      },
      {
        key: getUniqueId(),
        coverage_discription: "Identity Theft",
        deductible: null,
        requested_or_declined: null,
        amount_of_insurance: null,
        deductible_type: null,
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        premium: null,
      },
      {
        key: getUniqueId(),
        coverage_discription: "Earthquake",
        deductible: null,
        requested_or_declined: null,
        amount_of_insurance: null,
        deductible_type: null,
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        premium: null,
      },
      {
        key: getUniqueId(),
        coverage_discription: "Post-Earthquake Damage",
        deductible: null,
        requested_or_declined: null,
        amount_of_insurance: null,
        deductible_type: null,
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        premium: null,
      },
    ],
    liability_extension_and_exclusion_form: [
      {
        key: getUniqueId(),
        liability_coverage_discription: null,
        deductible: null,
        requested_or_declined: null,
        amount_of_insurance: null,
        deductible_type: null,
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        estimated_premium: null,
      },
      {
        key: getUniqueId(),
        liability_coverage_discription: null,
        deductible: null,
        requested_or_declined: null,
        amount_of_insurance: null,
        deductible_type: null,
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        estimated_premium: null,
      },
      {
        key: getUniqueId(),
        liability_coverage_discription: null,
        deductible: null,
        requested_or_declined: null,
        amount_of_insurance: null,
        deductible_type: null,
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        estimated_premium: null,
      },
      {
        key: getUniqueId(),
        liability_coverage_discription: null,
        deductible: null,
        requested_or_declined: null,
        amount_of_insurance: null,
        deductible_type: null,
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        estimated_premium: null,
      },
    ],
    discount_and_surcharges_form: [
      {
        key: getUniqueId(),
        discount_or_surcharge_discription: null,
        percentage: null,
        applied_to_premium: null,
        est_discount_or_surchage: null,
      },
      {
        key: getUniqueId(),
        discount_or_surcharge_discription: null,
        percentage: null,
        applied_to_premium: null,
        est_discount_or_surchage: null,
      },
      {
        key: getUniqueId(),
        discount_or_surcharge_discription: null,
        percentage: null,
        applied_to_premium: null,
        est_discount_or_surchage: null,
      },
      {
        key: getUniqueId(),
        discount_or_surcharge_discription: null,
        percentage: null,
        applied_to_premium: null,
        est_discount_or_surchage: null,
      },
      {
        key: getUniqueId(),
        discount_or_surcharge_discription: null,
        percentage: null,
        applied_to_premium: null,
        est_discount_or_surchage: null,
      },
      {
        key: getUniqueId(),
        discount_or_surcharge_discription: null,
        percentage: null,
        applied_to_premium: null,
        est_discount_or_surchage: null,
      },
      {
        key: getUniqueId(),
        discount_or_surcharge_discription: null,
        percentage: null,
        applied_to_premium: null,
        est_discount_or_surchage: null,
      },
      {
        key: getUniqueId(),
        discount_or_surcharge_discription: null,
        percentage: null,
        applied_to_premium: null,
        est_discount_or_surchage: null,
      },
      {
        key: getUniqueId(),
        discount_or_surcharge_discription: null,
        percentage: null,
        applied_to_premium: null,
        est_discount_or_surchage: null,
      },
      {
        key: getUniqueId(),
        discount_or_surcharge_discription: null,
        percentage: null,
        applied_to_premium: null,
        est_discount_or_surchage: null,
      },
    ],
  },
  coveragesLoading: LoadState.Loaded,
  error: null,
};
