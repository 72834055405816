import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface IAccountingBillState {
  list: IAccountingBill[];
  listLoading: LoadState;
  data: IAccountingBill;
  loading: LoadState;
  error: string | null;
  bill_history: IAccountingBillHistory;
  billHistoryLoading: LoadState;
}

export interface IAccountingBillDetail {
  key: string;
  detail: string;
  account: string;
  description: string;
  tax_percentage: string;
  tax_amount: string;
  amount: string;
  final_amount: string;
}

export interface IAccountingBill {
  customer_id: string | null;
  customer_policy_id: string | null;
  bill_id: string | null;
  bill_type: string | null;
  vendor_type: string | null;
  vendor: string | null;
  code: string | null;
  category: string | null;
  bill_date: string | null;
  due_date: string | null;
  bill_number: string | null;
  payment_terms: string | null;
  bill_details: IAccountingBillDetail[];
  bill_information: string | null;
  bill_notes: string | null;
  bill_footer_information: string | null;
  sub_total_amount: string;
  discount_percentage: string;
  discount_amount: string;
  adjustment_percentage: string;
  adjustment_amount: string;
  total_tax_amount: string;
  amount_due: string;
  status: string;
  amount_paid: string;
  total_due_amount: string;
  accounting_information_id: string | null;
  gl_sales_account_name: string | null;
}

export interface IAccountingBillHistory {
  accn_create_bill_id: string | null;
  customer_id: string | null;
  customer_policy_id: string | null;
  bill_id: string | null;
  bill_number: string | null;
  vendor: string | null;
  code: string | null;
  vendor_type: string | null;
  category: string | null;
  bill_date: string | null;
  due_date: string | null;
  payment_terms: string | null;
  gl_expense_account: string | null;
  gl_sales_account_name: string | null;
  bill_type: string | null;
  bill_notes: string | null;
  bill_footer_information: string | null;
  sub_total_amount: string | null;
  discount_percentage: string | null;
  discount_amount: string | null;
  adjustment_percentage: string | null;
  adjustment_amount: string | null;
  total_tax_amount: string | null;
  amount_due: string | null;
  amount_paid: string | null;
  total_due_amount: string | null;
  accounting_information_id: string | null;
  status: string | null;
  created_by_id: string | null;
  create_ts: string | null;
  insert_ts: string | null;
  payment_paid: string | null;
  remaining_amount_due: string | null;
  bill_details: IAccountingBillDetail[];
  bill_payment_details: IAccountingBillPayment[];
}

export interface IAccountingBillPayment {
  accn_payment_payable_details_id: string | null;
  payment_id: string | null;
  accn_transaction_id: string | null;
  create_payment_code: string | null;
  create_payment_id: string | null;
  vendor: string | null;
  invoice_payment_gl_account_name: string | null;
  payment_number: string | null;
  payment_made: string | null;
  bank_charges: string | null;
  payment_method: string | null;
  paid_through_account: string | null;
  date: string | null;
  payment_paid: string | null;
  amount_due: string | null;
  bill_amount: string | null;
  bill_number: string | null;
  total_amount: string | null;
  is_partial_payment: string | null;
  status: string | null;
  created_by_id: string | null;
  modified_by_id: string | null;
  create_ts: string | null;
  insert_ts: "2024-10-22T10:30:15.000Z";
  customer_id: string | null;
  detail: string | null;
  account: string | null;
  description: string | null;
  tax_percentage: string | null;
  tax_amount: string | null;
  amount: string | null;
  final_amount: string | null;
  vendor_type: string | null;
  category: string | null;
  bill_date: string | null;
  bill_id: string | null;
  bill_type: string | null;
  due_date: string | null;
  payment_terms: string | null;
  total_due_amount: string | null;
}

export const initialAccountingBillState: IAccountingBillState = {
  list: [],
  listLoading: LoadState.NotLoaded,
  data: {
    customer_id: null,
    customer_policy_id: null,
    bill_id: null,
    bill_type: null,
    vendor_type: null,
    vendor: null,
    code: null,
    bill_number: null,
    category: null,
    bill_date: null,
    due_date: null,
    payment_terms: null,
    bill_details: [
      {
        key: getUniqueId(),
        detail: "",
        account: "",
        description: "",
        tax_percentage: "",
        tax_amount: "",
        amount: "",
        final_amount: "",
      },
    ],
    bill_information: null,
    bill_notes: null,
    bill_footer_information: null,
    sub_total_amount: "",
    discount_percentage: "",
    discount_amount: "",
    adjustment_percentage: "",
    adjustment_amount: "",
    amount_due: "",
    total_tax_amount: "",
    status: "ACTIVE",
    amount_paid: "",
    total_due_amount: "",
    accounting_information_id: null,
    gl_sales_account_name: null,
  },
  loading: LoadState.NotLoaded,
  error: null,
  bill_history: {
    accn_create_bill_id: null,
    customer_id: null,
    customer_policy_id: null,
    bill_id: null,
    bill_number: null,
    vendor: null,
    code: null,
    vendor_type: null,
    category: null,
    bill_date: null,
    due_date: null,
    payment_terms: null,
    gl_expense_account: null,
    gl_sales_account_name: null,
    bill_type: null,
    bill_notes: null,
    bill_footer_information: null,
    sub_total_amount: null,
    discount_percentage: null,
    discount_amount: null,
    adjustment_percentage: null,
    adjustment_amount: null,
    total_tax_amount: null,
    amount_due: null,
    amount_paid: null,
    total_due_amount: null,
    accounting_information_id: null,
    status: null,
    created_by_id: null,
    create_ts: null,
    insert_ts: null,
    payment_paid: null,
    remaining_amount_due: null,
    bill_details: [],
    bill_payment_details: [],
  },
  billHistoryLoading: LoadState.NotLoaded,
};
