import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { CSVAction } from ".";
import { initialAutomoblieCSV } from "./automobileCSV.types";
import { FETCH_AUTOMOBILE_CSV_DETAILS_FAILED, FETCH_AUTOMOBILE_CSV_DETAILS_PROGRESS, FETCH_AUTOMOBILE_CSV_DETAILS_SUCCESS } from "./automobileCSVActions";
import { LoadState } from "../../constants/enums";

export const AutomobileCSVReducer = (
  state: IStoreState["automobile"]["csv"] = initialAutomoblieCSV,
  action: CSVAction
) => {
  switch (action.type) {
    case FETCH_AUTOMOBILE_CSV_DETAILS_PROGRESS: {
      const newState = produce(state, draftState => {
        draftState.details.loading = LoadState.InProgress;
        draftState.details.data = initialAutomoblieCSV.details.data;
      })
      return newState;
    }
    case FETCH_AUTOMOBILE_CSV_DETAILS_SUCCESS: {
      const newState = produce(state, draftState => {
        draftState.details.loading = LoadState.Loaded;
        draftState.details.data = action.payload.data;
      })
      return newState;
    }
    case FETCH_AUTOMOBILE_CSV_DETAILS_FAILED: {
      const newState = produce(state, draftState => {
        draftState.details.loading = LoadState.Failed;
      })
      return newState;
    }
    default: {
      return state;
    }
  }
};
