import produce from "immer";
import { UtilityActions } from ".";
import { IStoreState } from "../initialStoreState";
import { initialUtilityState } from "./utility.types";
import {
  CLEAR_UTILITY_STATE,
  FETCH_UTILITY_LIST_FAILED,
  FETCH_UTILITY_LIST_PROGRESS,
  FETCH_UTILITY_LIST_SUCCESS,
} from "./utilityActions";
import { LoadState } from "../../constants/enums";

export const utilityReducer = (
  state: IStoreState["utility"] = initialUtilityState,
  action: UtilityActions
) => {
  switch (action.type) {
    case FETCH_UTILITY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }
    case FETCH_UTILITY_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_UTILITY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.list = [];
      });
      return newState;
    }
    case CLEAR_UTILITY_STATE: {
      return initialUtilityState;
    }
    default:{ 
      return initialUtilityState;
    }
  }
};
