import produce from "immer";
import { IAutomationState, IGraphNode } from "./automation.types";
import { getUniqueId } from "../../helpers";

export const updateNodesWithPlaceholderAndEdges = (state: IAutomationState, node: IGraphNode, isDelete: boolean ) =>{
    const newState = produce(state, draftState=>{
        const index = draftState.graphData.nodes.findIndex(x=>x.id === node.id);
        if(isDelete){
         
            if(index > -1){
                draftState.graphData.nodes.splice(index, 1);
            }
        }
        else if(index > -1){
            draftState.graphData.nodes[index] = {
                ...draftState.graphData.nodes[index],
                data: node.data,
            };
      
        }
        else {
            const placeholderNodeId = getUniqueId();
          
            draftState.graphData.nodes.push(node);
            const placeholderNodeIndex = draftState.graphData.nodes.findIndex(x=>x.type === "placeholder");
            if(placeholderNodeIndex > -1){
                draftState.graphData.nodes.splice(placeholderNodeIndex, 1);
            }
            draftState.graphData.nodes.push({
                id: placeholderNodeId,
                position: { x: 0, y:0 },
                type: 'placeholder',
                data: { label: '+',payload: {} as any },
            });

            // const childPlaceholderEdge = {
            //     id: `${node.id}=>${placeholderNodeId}`,
            //     source: node.id,
            //     target: placeholderNodeId,
            //     type: 'placeholder',
            //   };
            //   draftState.graphData.edges.push(childPlaceholderEdge as any)
        }
        draftState.graphData.edges=[];

        for (let i = 0; i < draftState.graphData.nodes.length; i++) {
            if (i !== 0) {
              const targetId = draftState.graphData.nodes[i].id;
              const sourceId = draftState.graphData.nodes[i - 1].id;
              draftState.graphData.edges.push({
                id: sourceId + "=>" + targetId,
                source: sourceId,
                target: targetId,
                type: draftState.graphData.nodes[i].type,
              });
            }
          
        }
    });
    return newState;
}