import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { ERROR_MESSAGES } from "../../../constants/enums";
import { IBusinessConsentForm } from "./businessConsentForm.types";


export const FETCH_BUSINESS_CONSENT_FORM_PROGRESS =
 "FETCH_BUSINESS_CONSENT_FORM_PROGRESS";
export const FETCH_BUSINESS_CONSENT_FORM_SUCCESS =
  "FETCH_AUTOMOBILE_CONSENT_FORM_SUCCESS";
export const FETCH_BUSINESS_CONSENT_FORM_FAILED =
  "FETCH_BUSINESS_CONSENT_FORM_FAILED";

export const fetchBusinessConsentFormProgress = () =>
  action(FETCH_BUSINESS_CONSENT_FORM_PROGRESS);
export const fetchBusinessConsentFormSuccess = (data: IBusinessConsentForm) =>
  action(FETCH_BUSINESS_CONSENT_FORM_SUCCESS, { data });
export const fetchBusinessConsentFormFailed = (errorMessage: string) =>
  action(FETCH_BUSINESS_CONSENT_FORM_FAILED, { errorMessage });

export const addBusinessConsentFormAsync =
  (
    data: IBusinessConsentForm,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        "/policy/edit-policy-form-privacy-consent-form",
        {
          ...data,
        }
      );
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

  export const fetchBusinessConsentFormAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessConsentFormProgress());
      const res = await api.get(
        `/policy/get-policy-form-privacy-consent-form?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IBusinessConsentForm[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchBusinessConsentFormSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchBusinessConsentFormFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchBusinessConsentFormFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_BUSINESS_CONSENT_FORM = "CLEAR_BUSINESS_CONSENT_FORM";
export const clearBusinessConsentForm = () =>
  action(CLEAR_BUSINESS_CONSENT_FORM);

export const FETCH_BUSINESS_CONSENT_FORM_LIST_PROGRESS =
"FETCH_BUSINESS_CONSENT_FORM_LIST_PROGRESS";
export const FETCH_BUSINESS_CONSENT_FORM_LIST_SUCCESS =
"FETCH_BUSINESS_CONSENT_FORM_LIST_SUCCESS";
export const FETCH_BUSINESS_CONSENT_FORM_LIST_FAILED =
  "FETCH_BUSINESS_CONSENT_FORM_LIST_FAILED";


export const fetchBusinessConsentFormListProgress = () =>
  action(FETCH_BUSINESS_CONSENT_FORM_LIST_PROGRESS);
export const fetchBusinessConsentFormListSuccess = (
  data: IBusinessConsentForm[]
) => action(FETCH_BUSINESS_CONSENT_FORM_LIST_SUCCESS, { data });
export const fetchBusinessConsentFormListFailed = () =>
  action(FETCH_BUSINESS_CONSENT_FORM_LIST_FAILED);

export const fetchBusinessConsentFormListAsync = (customerPolicyId: number):ThunkAction<void, IStoreState, {}, AnyAction> => 
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessConsentFormListProgress());
      const res = await api.get(`/policy/get-policy-form-privacy-consent-form?customer_policy_id=${customerPolicyId}`);

      const data :IBusinessConsentForm[] =res.data.data;
      dispatch(fetchBusinessConsentFormListSuccess(data))
    } catch (err: any) {
      dispatch(fetchBusinessConsentFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  }

  export const downloadBusinessConsentFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-privacy-consent-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchBusinessConsentFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewBusinessConsentFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-privacy-consent-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_privacy_consent_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchBusinessConsentFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };