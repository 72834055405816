/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Box, Button, Link } from "@mui/material";
import React from "react";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useNavigate, useOutletContext } from "react-router-dom";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import { LoadState } from "../../../../../../constants/enums";
import { fetchCommAutomobilePolicyMarketingHistListAsync } from "../../../../../../redux/commAutoPolicy/commAutoMarketingHistory/commAutoMarketingHistoryActions";
import { ICommAutomobileMarketingHistory } from "../../../../../../redux/commAutoPolicy/commAutoMarketingHistory/commAutoMarketingHistory.types";
import { CommAutomobileMarketingDialog } from "./CommAutoMarketingDialog";
import { AddOutlined } from "@mui/icons-material";

export const CommAutomobileMarketingList = () => {
  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerpolicyId = data[1];
  const navigate = useNavigate();

  const [openSubmitMarketing, setOpenSubmitMarketing] = React.useState(false);
  const { marketingHistoryList, marketingHistoryListLoading } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.marketing
  );
  const dispatch = useDispatchWrapper();

  const handleToggleSubmitMarketing = () => {
    setOpenSubmitMarketing(!openSubmitMarketing);
  };

  const handleComplete = () => {
    dispatch(fetchCommAutomobilePolicyMarketingHistListAsync(customerpolicyId));
    setOpenSubmitMarketing(false);
  };

  const marketingActivityTableProps: IDataTableProps = {
    isPagination: true,
    isDataLoading: marketingHistoryListLoading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Box>
                <Button
                  sx={{ mx: 1 }}
                  variant="contained"
                  onClick={handleToggleSubmitMarketing}
                >
                  Submit for Marketing
                </Button>
                <Button
                  sx={{ mx: 1 }}
                  variant="contained"
                  startIcon={<AddOutlined />}
                  onClick={() => {
                    navigate(
                      `/comm-automobile-policy/${customerId}/marketing-tab/${customerpolicyId}/marketing-history`
                    );
                  }}
                  // }
                >
                  Create
                </Button>
              </Box>
            ),
          },
        ],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "serial_number",
        headerName: "Serial Number",
        fieldName: "serial_number",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
        enableSorting: true,
        onRowCellRender: (value, row: ICommAutomobileMarketingHistory) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/marketing-tab/${customerpolicyId}/marketing-history/${row.serial_number}`
                );
              }}
            >
              {row.serial_number}
            </Link>
          );
        },
      },
      {
        key: "customer_name",
        headerName: "Cutomer Name",
        fieldName: "customer_name",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 15,
        enableSorting: true,
      },
      {
        key: "insurer",
        headerName: "Insurer",
        fieldName: "insurer",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "underwriter",
        headerName: "Underwriter",
        fieldName: "underwriter",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "quote_number",
        headerName: "Quote Number",
        fieldName: "quote_number",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "quote_status",
        headerName: "Quote Status",
        fieldName: "quote_status",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
        enableSorting: true,
      },

      {
        key: "premium",
        headerName: "Premium",
        fieldName: "premium",
        exportCellWidth: 25,
        enableSorting: true,
        renderType: RenderType.TEXT,
      },
      {
        key: "submission_date",
        headerName: "Submission Date",
        fieldName: "submission_date",
        exportCellWidth: 25,
        enableSorting: true,
        renderType: RenderType.DATE,
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/marketing-tab/${customerpolicyId}/marketing-history/${row.serial_number}`
                );
              }}
            />
          );
        },
      },
    ],
    items: marketingHistoryList,
  };

  React.useEffect(() => {
    dispatch(fetchCommAutomobilePolicyMarketingHistListAsync(customerpolicyId));
  }, []);

  return (
    <>
      <PageLoader loading={false}>
        <Box sx={{ mt: 2 }}>
          <DataTable {...marketingActivityTableProps} />
        </Box>
      </PageLoader>
      {openSubmitMarketing && (
        <CommAutomobileMarketingDialog
          open={true}
          customerPolicyId={customerpolicyId}
          customerId={customerId}
          onClose={handleToggleSubmitMarketing}
          onComplete={handleComplete}
        />
      )}
    </>
  );
};
