import produce from "immer";
import { IStoreState } from "../initialStoreState";

import { LoadState } from "../../constants/enums";
import { defaultBindersState } from "./binder.types";
import { BinderActions } from ".";
import { FETCH_BINDERS_LIST_FAILED, FETCH_BINDERS_LIST_PROGRESS, FETCH_BINDERS_LIST_SUCCESS } from "./binderActions";


export const bindersReducer = (
  state: IStoreState["binders"] = defaultBindersState,
  action: BinderActions
) => {
  switch (action.type) {
    case FETCH_BINDERS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_BINDERS_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = list;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_BINDERS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }

    default: {
      return state;
    }
  }
};
