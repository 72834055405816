import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { LoadState } from "../../../../../../../constants/enums";
import { fetchAutomobileScheduleAListAsync } from "../../../../../../../redux/automobileSchedule/automobileScheduleActions";
import { IAutomobileScheduleA } from "../../../../../../../redux/automobileSchedule/automobileSchedule.types";
import { Add } from "@mui/icons-material";
import { AutomobileScheduleADialog } from "./AutomobileScheduleADialog";
import { PageTitleBar } from "../../../../../../../components/PageTitleBar/PageTitleBar";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";

export const AutomobileScheduleAList = () => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerpolicyId = data1[1];

  const navigate = useNavigate();
  const { listData: data, listLoading: loading } = useSelector(
    (storeState: IStoreState) => storeState.automobile.schedule.scheduleA
  );
  const [open, setOpen] = React.useState<boolean>(false);

  const [coverageData, SetCoverageData] = React.useState();

  const dispatch = useDispatchWrapper();
  const handleCoverageData = (data: any) => {
    SetCoverageData(data);
    setOpen((prev) => !prev);
  };

  React.useEffect(() => {
    dispatch(fetchAutomobileScheduleAListAsync(customerpolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerpolicyId]);

  const automobileApplicationTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          // {
          //   key: "status",
          //   onRender: () => (
          //     <Button
          //       variant="contained"
          //       startIcon={<Add/>}
          //       onClick={() => {
          //         navigate(
          //           `/automobile-policy/${customerId}/vehicle-coverage-schedule-tab/${customerpolicyId}/schedule`
          //         );
          //       }}
          //     >
          //       Create
          //     </Button>
          //   ),
          // },
        ],
      },
    },
    columns: [
      {
        key: "customer_policy_id",
        headerName: "Customer Policy Id",
        fieldName: "customer_policy_id",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
        onRowCellRender: (value, row: IAutomobileScheduleA) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/automobile-policy/${customerId}/vehicle-coverage-schedule-tab/${customerpolicyId}/manage-coverage/${row.vehicle_number}/${row.incremental_number}`
                );
              }}
            >
              {row.customer_policy_id}
            </Link>
          );
        },
      },
      {
        key: "vehicle_number",
        headerName: "Vehicle Number",
        fieldName: "vehicle_number",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "incremental_number",
        headerName: "Coverage Number",
        fieldName: "incremental_number",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      //   {
      //     key: "broker_agent",
      //     headerName: "Broker Agent",
      //     fieldName: "broker_agent",
      //     exportCellWidth: 25,
      //     renderType: RenderType.CHIP_WARNING,
      //   },

      //   {
      //     key: "interest",
      //     headerName: "Interest",
      //     fieldName: "interest",
      //     exportCellWidth: 25,
      //     renderType: RenderType.TEXT,
      //   },
      //   {
      //     key: "type_of_payment_plan",
      //     headerName: "Type of Payment Plan",
      //     fieldName: "type_of_payment_plan",
      //     exportCellWidth: 25,
      //     renderType: RenderType.TEXT,
      //   },

      //   {
      //     key: "amount_due",
      //     headerName: "Amount Due",
      //     fieldName: "amount_due",
      //     exportCellWidth: 25,
      //     renderType: RenderType.TEXT,
      //   },
      //   {
      //     key: "status",
      //     headerName: "Status",
      //     fieldName: "status",
      //     exportCellWidth: 25,
      //     renderType: RenderType.TEXT,
      //   },
      {
        key: "view",
        headerName: "Actions",
        headingAlign: "center",
        fieldName: "",
        // headingAlign: 'center',
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IAutomobileScheduleA) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/automobile-policy/${customerId}/vehicle-coverage-schedule-tab/${customerpolicyId}/manage-coverage/${row.vehicle_number}/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
    ],
    items: data || [],
  };

  return (
    <Box sx={{ mt: 4 }}>
      <PageTitleBar
        heading="Coverage Summary"
        rightHeading={
          <Button
            variant="contained"
            onClick={() => {
              navigate(
                `/automobile-policy/${customerId}/vehicle-coverage-schedule-tab/${customerpolicyId}/manage-coverage`
              );
            }}
          >
            Create
          </Button>
        }
      />
      <DataTable {...automobileApplicationTableProps} />
      {open && coverageData && (
        <AutomobileScheduleADialog
          open={open}
          coverageData={coverageData}
          onClose={() => setOpen(false)}
        />
      )}
    </Box>
  );
};
