import produce from "immer";
import { EftActions } from ".";
import { IStoreState } from "../../initialStoreState";
import { initialAccountingEftState } from "./Eft.types";
import {
  CLEAR_ACCOUNTING_EFT,
  FETCH_ACCOUNTING_EFT_FAILURE,
  FETCH_ACCOUNTING_EFT_LIST_FAILURE,
  FETCH_ACCOUNTING_EFT_LIST_PROGRESS,
  FETCH_ACCOUNTING_EFT_LIST_SUCCESS,
  FETCH_ACCOUNTING_EFT_PROGRESS,
  FETCH_ACCOUNTING_EFT_SUCCESS,
} from "./EftActions";
import { LoadState } from "../../../constants/enums";

export const AccountingEftReducer = (
  state: IStoreState["accounting"]["eft"] = initialAccountingEftState,
  action: EftActions
) => {
  switch (action.type) {
    case FETCH_ACCOUNTING_EFT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_EFT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_EFT_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_EFT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_EFT_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Loaded;
        draftState.list = data;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_EFT_LIST_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_ACCOUNTING_EFT: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.NotLoaded;
        draftState.data = initialAccountingEftState.data;
        draftState.error = null;
      });
      return newState;
    }

    default:
      return state;
  }
};
