import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";
import {
  IAccountingInformation,
  initialAccountingInformationState,
} from "./AccountingInformation.types";

export const FETCH_ACCOUNTING_INFORMATION_PROGRESS =
  "FETCH_ACCOUNTING_INFORMATION_PROGRESS";
export const FETCH_ACCOUNTING_INFORMATION_SUCCESS =
  "FETCH_ACCOUNTING_INFORMATION_SUCCESS";
export const FETCH_ACCOUNTING_INFORMATION_FAILURE =
  "FETCH_ACCOUNTING_INFORMATION_FAILURE";

export const fetchAccountingInformationProgress = () =>
  action(FETCH_ACCOUNTING_INFORMATION_PROGRESS);

export const fetchAccountingInformationSuccess = (
  data: IAccountingInformation
) => action(FETCH_ACCOUNTING_INFORMATION_SUCCESS, { data });

export const fetchAccountingInformationFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_INFORMATION_FAILURE, { errorMessage });

export const fetchAccountingInformationAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {

      dispatch(fetchAccountingInformationProgress());
      const res = await api.get(
        `/accounting/get-account-accounting-information`
      );
      const data: IAccountingInformation[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAccountingInformationSuccess(data[0]));
      } else {
        dispatch(
          fetchAccountingInformationSuccess(
            initialAccountingInformationState["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchAccountingInformationFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } 
  };

export const addAccountingInformationAysnc =
  (
    data: IAccountingInformation,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/accounting/edit-account-accounting-information", {
        ...data,
      });
      let message = "Accounting Information saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const FETCH_ACCOUNTING_INFORMATION_LIST_PROGRESS =
  "FETCH_ACCOUNTING_INFORMATION_LIST_PROGRESS";
export const FETCH_ACCOUNTING_INFORMATION_LIST_SUCCESS =
  "FETCH_ACCOUNTING_INFORMATION_LIST_SUCCESS";
export const FETCH_ACCOUNTING_INFORMATION_LIST_FAILURE =
  "FETCH_ACCOUNTING_INFORMATION_LIST_FAILURE";

export const fetchAccountingInformationListProgress = () =>
  action(FETCH_ACCOUNTING_INFORMATION_LIST_PROGRESS);

export const fetchAccountingInformationListSuccess = (
  data: IAccountingInformation[]
) => action(FETCH_ACCOUNTING_INFORMATION_LIST_SUCCESS, { data });

export const fetchAccountingInformationListFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_INFORMATION_LIST_FAILURE, { errorMessage });

export const fetchAccountingInformationListAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingInformationListProgress());

      const res = await api.get(
        `/accounting/get-account-accounting-information`
      );
      const data: IAccountingInformation[] = res.data.data;
      dispatch(fetchAccountingInformationListSuccess(data));
    } catch (err: any) {
      dispatch(
        fetchAccountingInformationListFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_ACCOUNTING_INFORMATION = "CLEAR_ACCOUNTING_INFORMATION";
export const clearAccountingInformation = () =>
  action(CLEAR_ACCOUNTING_INFORMATION);
