import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import { LoadState } from "../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { IAutomobileApplication } from "../../../../../../redux/automobileApplication/automobileApplication.types";
import { fetchAutomobileAlbertaApplicationListAsync } from "../../../../../../redux/automobileAlbertaApplication/automobileAlbertaApplicationActions";

export const AutomobileAlbertaApplicationList = () => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerpolicyId = data1[1];

  const navigate = useNavigate();
  const { data, listLoading: loading } = useSelector(
    (storeState: IStoreState) => storeState.automobile.albertaApplication
  );

  const dispatch = useDispatchWrapper();

  React.useEffect(() => {
    dispatch(fetchAutomobileAlbertaApplicationListAsync(customerpolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerpolicyId]);

  const automobileAlbertaApplicationTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    `/automobile-policy/${customerId}/automobile-alberta-application-tab/${customerpolicyId}/application`
                  );
                }}
              >
                Add Application
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
        onRowCellRender: (value, row) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/automobile-policy/${customerId}/automobile-alberta-application-tab/${customerpolicyId}/application/${row.incremental_number}`
                );
              }}
            >
              {row.incremental_number}
            </Link>
          );
        },
      },
      {
        key: "insurance_company",
        headerName: "Insurance Company",
        fieldName: "insurance_company",
        exportCellWidth: 25,
        renderType: RenderType.DATE_DARK_COLOR,
      },
      {
        key: "broker_agent",
        headerName: "Broker Agent",
        fieldName: "broker_agent",
        exportCellWidth: 25,
        renderType: RenderType.CHIP_WARNING,
      },

      {
        key: "interest",
        headerName: "Interest",
        fieldName: "interest",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "type_of_payment_plan",
        headerName: "Type of Payment Plan",
        fieldName: "type_of_payment_plan",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },

      {
        key: "amount_due",
        headerName: "Amount Due",
        fieldName: "amount_due",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IAutomobileApplication) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/automobile-policy/${customerId}/automobile-alberta-application-tab/${customerpolicyId}/application/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
    ],
    items: data,
  };

  return (
    <Box sx={{ mt: 2 }}>
      <DataTable {...automobileAlbertaApplicationTableProps} />
    </Box>
  );
};