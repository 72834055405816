import React from "react";
import { useDispatchWrapper } from "../../../../../../hooks";
import { useFormik } from "formik";
import { Grid, MenuItem } from "@mui/material";
import {
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../components/formsComponents";
import { FileUpload } from "../../../../../../components/FileUpload/FileUpload";
import { addAutomobileGaragePlatesAsync } from "../../../../../../redux/automobileGaragePlates/automobileGaragePlatesActions";
import { isAlphaNumeric, renderDropdownValue } from "../../../../../../helpers";
import { Dialog } from "../../../../../../components/Dialogs/Dialog";
import { UsersAutoSearchByRole } from "../../../../../../components/AutoCompleteSearches/UsersAutoSearchByRole";
import { ICommAutoGaragePlatesDialogProps } from "./CommAutoGaragePlate.types";
import { addCommAutomobileGaragePlatesAsync } from "../../../../../../redux/commAutoPolicy/commAutoGaragePlates/commAutoGaragePlatesActions";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useCurrentUserId } from "../../../../../../hooks/useAssignedTo";

export const CommAutoGaragePlatesDialog: React.FC<
  ICommAutoGaragePlatesDialogProps
> = (props) => {
  const { data, open } = props;

  const [saveLoading, setSaveLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();

  const currentUserId = useCurrentUserId();

  const customerInfo = useSelector(
    (storeState: IStoreState) =>
      storeState.commAuto.basicDetails.customerInfo.data
  );

  const [file, setFile] = React.useState<any>(null);

  const { values, handleChange, handleSubmit, setFieldValue, errors } =
    useFormik({
      initialValues: data,
      validate: (values) => {
        const errors: any = {};
        if (values.plate_number && !isAlphaNumeric(values.plate_number)) {
          errors.plate_number = "Must alpha numeric.";
        }
        return errors;
      },
      onSubmit: (values) => {
        setSaveLoading(true);
        dispatch(
          addCommAutomobileGaragePlatesAsync(
            {
              ...values,
            },
            customerInfo.customer_id,
            file,
            (isSuccess) => {
              if (isSuccess) {
                window.scrollTo(0, 0);
                props.onComplete();
              }
              setSaveLoading(false);
            }
          )
        );
      },
    });

    React.useEffect(() => {
      setFieldValue("assigned_to", currentUserId);
    }, [currentUserId])

  return (
    <Dialog
      open={open}
      title="Create/Edit Garage Plate"
      onClose={props.onClose}
      size="md"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      actions={[
        {
          type: "button",
          label: "Close",
          variant: "text",
          onClick: props.onClose,
        },
        {
          type: "submit",
          label: "Save",
          variant: "contained",
          disabled: saveLoading,
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <CustomFormLabel>#</CustomFormLabel>
          <CustomTextField
            name="serial_number"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.serial_number}
            disabled
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <CustomFormLabel>Plate #</CustomFormLabel>
          <CustomTextField
            name="plate_number"
            variant="outlined"
            size="small"
            type="string"
            fullWidth
            value={values.plate_number}
            error={errors.plate_number ? true : false}
            helperText={errors.plate_number}
            onChange={(e) => {
              setFieldValue("plate_number", e.target.value.toUpperCase());
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <CustomFormLabel>Type</CustomFormLabel>
          <CustomSelect
            variant="outlined"
            size="small"
            type="string"
            name="type"
            value={values.type}
            onChange={handleChange}
            displayEmpty
            fullWidth
            renderValue={renderDropdownValue(`Select one`)}
          >
            {["Service Plate", "Dealer Plate", "White Plate"].map((policyType) => {
              return <MenuItem value={policyType}>{policyType}</MenuItem>;
            })}
          </CustomSelect>
        </Grid>

        <Grid item xs={12} lg={3}>
          <UsersAutoSearchByRole
            label="Created By"
            //roleId={USER_ROLES.CSR}
            value={values.assigned_to}
            onSelect={(newValue) => {
              setFieldValue("assigned_to", newValue.user_fact_id);
            }}
          />
        </Grid>

        <Grid item xs={12} md={9}>
          <CustomFormLabel>Attachment</CustomFormLabel>
          <FileUpload
            value={values.attachment}
            onChange={(file) => setFile(file)}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};
