import moment from "moment";
import { LoadState } from "../../constants/enums";
import { getUniqueId } from "../../helpers";

export interface IAutomobileCertificatesState {
  liabilitySlips: {
    list: IAutomobileLiabilitySlips[];
    loading: LoadState;
    liabilitySlip: IAutomobileLiabilitySlips;
    liabilitySlipLoading: LoadState;
    error: string | null;
  };
  liabilityInsurance: {
    list: IAutomobileLiabilityInsurance[];
    loading: LoadState;
    liabilityInsurance: IAutomobileLiabilityInsurance;
    liabilityInsuranceLoading: LoadState;
    error: string | null;
  };
  opcf16: {
    list: IAutomobileOCEP16[];
    loading: LoadState;
    opcf: IAutomobileOCEP16;
    opcfLoading: LoadState;
    error: string | null;
  };
  opcf17: {
    list: IAutomobileOCEP17[];
    loading: LoadState;
    opcf: IAutomobileOCEP17;
    opcfLoading: LoadState;
    error: string | null;
  };
  opcf28A: {
    list: IAutomobileOCEP28A[];
    loading: LoadState;
    opcf: IAutomobileOCEP28A;
    opcfLoading: LoadState;
    error: string | null;
  };
  consentform: {
    list: IAutomobileConsentForm[];
    loading: LoadState;
    consent: IAutomobileConsentForm;
    consentLoading: LoadState;
    error: string | null;
  };
  supplementaryform: {
    list: IAutomobileSupplementaryForm[];
    loading: LoadState;
    supplementary: IAutomobileSupplementaryForm;
    supplementaryLoading: LoadState;
    error: string | null;
  };
  retireediscount: {
    list: IAutomobileRetireeDiscount[];
    loading: LoadState;
    retiree: IAutomobileRetireeDiscount;
    retireeLoading: LoadState;
    error: string | null;
  };
  garageform: {
    list: IAutomobileGarageForm[];
    loading: LoadState;
    garage: IAutomobileGarageForm;
    garageLoading: LoadState;
    error: string | null;
  };
  sharingfillable: {
    list: IAutomobileSharingFillable[];
    loading: LoadState;
    sharing: IAutomobileSharingFillable;
    sharingLoading: LoadState;
    error: string | null;
  };
}

export interface IAutomobileSharingFillable {
  customer_policy_id: number;
  customer_id: number | null;
  incremental_number: number | null;
  customer_name: string | null;
  policy_number: string | null;
  status: string;
  created_by_id: number | null;
  modified_by_id: number | null;
  insert_ts: string | null;
  create_ts: string | null;
}


export interface IAutomobileConsentForm {
  customer_policy_id: number;
  customer_id: number | null;
  incremental_number: number | null;
  customer_name: string | null;
  email_address: string | null;
  text_field: string | null;
  status: string;
  created_by_id: number | null;
  modified_by_id: number | null;
  insert_ts: string | null;
  create_ts: string | null;
}


export interface IAutomobileRetireeDiscount {
  customer_policy_id: number;
  customer_id: number | null;
  incremental_number: number | null;
  customer_name: string | null;
  policy_number: string | null;
  effective_date_of_discount: string | null;
  insurer: string | null;
  branch_code: string | null;
  retiree_name: string | null;
  status: string;
  created_by_id: number | null;
  modified_by_id: number | null;
  insert_ts: string | null;
  create_ts: string | null;
}


export interface IAutomobileGarageForm {
  customer_policy_id: number;
  customer_id: number | null;
  incremental_number: number | null;
  policy_number: string | null;
  insurance_company: string;
  new: string | null;
  replacing_policy_number: string | null;
  customer_name: string | null;
  customer_address: string | null;
  customer_unit_or_suite: string | null;
  customer_city: string | null;
  customer_province_or_state: string | null;
  customer_postal_code: string | null;
  customer_country: string | null;
  contact_business: string | null;
  contact_home: string | null;
  contact_fax: string | null;
  broker_client_id: string | null;
  company_client_id: string | null;
  broker_or_agent: string | null;
  branch: string | null;
  code: string | null;
  broker_or_agent_bill: string | null;
  company_bill: string | null;
  payment_plan: string | null;
  business_address: string | null;
  section_a: {
    key: string;
    srNo: string;
    location_of_other_premises: null;
    structure_type_one_lot: null;
    structure_type_two_lot: null;
  }[];
  effective_time: string | null;
  effective_date: string | null;
  expiry_date: string | null;
  the_automobiles_in_respect: string | null;
  estimated_total_payroll: string | null;
  full_time: string | null;
  part_time: string | null;
  policy_auto_vcs_schedule_d_id: number;
  coverage_summary_incremental_number: string | null;
  leinholder_name: string | null;
  insurer: string | null;
  reason: string | null;
  injury_to_persons: string | null;
  collision: string | null;
  other: string | null;
  not_in_care_of_applicant: string | null;
  in_care_of_applicant: string | null;
  remarks: string | null;
  print_name: string | null;
  brokerage_or_agent_name: string | null;
  status: string;
  created_by_id: number | null;
  modified_by_id: number | null;
  insert_ts: string | null;
  create_ts: string | null;
}

export interface IAutomobileuseColumn {
  location_a: string | null;
  location_b: string | null;
  location_c: string | null;
  location_d: string | null;
}
export interface IAutomobileuseColumn4 {
  location_a: string | null;
  location_b: string | null;
  location_c: string | null;
  location_d: string | null;
  total: string | null;
}
export interface IAutomobileuseColumn3 {
  location_a_yes_or_no: string | null;
  location_b_yes_or_no: string | null;
  location_c_yes_or_no: string | null;
  location_d_yes_or_no: string | null;
  comments_description: string | null;
}
export interface IAutomobileuseColumn2 {
  location_a_ft: string | null;
  location_a_pt: string | null;
  location_b_ft: string | null;
  location_b_pt: string | null;
  location_c_ft: string | null;
  location_c_pt: string | null;
  location_d_ft: string | null;
  location_d_pt: string | null;
}

export interface IAutomobileSupplementaryForm {
  customer_policy_id: number;
  customer_id: number | null;
  incremental_number: number | null;
  customer_name: string | null;
  broker: string | null;
  effective_date: string | null;
  section_a: {
    in_business_since: IAutomobileuseColumn;
    sales_of_new_vehicles: IAutomobileuseColumn;
    sales_of_used_vehicles: IAutomobileuseColumn;
    leasing_of_new_or_used_vehicles: IAutomobileuseColumn;
    body_shop: IAutomobileuseColumn;
    body_shop_including_car_sales: IAutomobileuseColumn;
    repair_garage: IAutomobileuseColumn;
    repair_garage_including_car_sales: IAutomobileuseColumn;
    service_station: IAutomobileuseColumn;
    service_station_self_serve: IAutomobileuseColumn;
    parking_lot: IAutomobileuseColumn;
    parking_lot_self_park: IAutomobileuseColumn;
    storage_garage: IAutomobileuseColumn;
    storage_garage_self_store: IAutomobileuseColumn;
    valet_parking: IAutomobileuseColumn;
    valet_parking_fee_charged: IAutomobileuseColumn;
    car_wash: IAutomobileuseColumn;
    car_wash_automatic: IAutomobileuseColumn;
    other_detail_remarks: string | null;
  };

  private_passenger_checkbox: string | null;
  light_commercial_checkbox: string | null;
  heavy_commercial_checkbox: string | null;
  trailers_checkbox: string | null;
  commercial_trailers_checkbox: string | null;
  antique_or_classic_checkbox: string | null;
  exotic_racing_checkbox: string | null;
  motorcycles_checkbox: string | null;
  atv_checkbox: string | null;
  snowmobiles_checkbox: string | null;
  motorhomes_checkbox: string | null;
  other_checkbox: string | null;
  other_value: string | null;

  proprietors: IAutomobileuseColumn2;
  sales_staff: IAutomobileuseColumn2;
  mechanics: IAutomobileuseColumn2;
  lot_persons: IAutomobileuseColumn2;
  body_shop_workers: IAutomobileuseColumn2;
  full_service_station_attendants: IAutomobileuseColumn2;
  clerical: IAutomobileuseColumn2;
  parts_department_staff: IAutomobileuseColumn2;
  parking_lot_attendant: IAutomobileuseColumn2;
  valet_parking: IAutomobileuseColumn2;
  summary_of_employees_other_details: string | null;

  employee_information_including_owners: {
    key: string;
    name: string | null;
    drivers_licence_number: string | null;
    birth_date: string;
    position: string | null;
    years_lincence: string | null;
    licence_class: string | null;
    full_or_part_time: string | null;
    convictions_or_suspensions_for_the_past_six_years: string | null;
  }[];

  other_operators: {
    key: string;
    name: string | null;
    drivers_licence_number: string | null;
    birth_date: string;
    relationship_to_the_named_insured: string | null;
    years_lincence: string | null;
    licence_class: string | null;
    convictions_or_suspensions_for_the_past_six_years: string | null;
  }[];

  claims: {
    key: string;
    driver_name: string | null;
    date_of_loss: string;
    type_of_loss: string | null;
    amount_paid: string | null;
    descriptiom: string | null;
  }[];
  are_all_active_vehicles_owned_by_yes_or_no: string | null;
  are_all_active_vehicles_owned_by_no_reason: string | null;
  number_of_dealer_plates: IAutomobileuseColumn4;
  number_of_service_plates: IAutomobileuseColumn4;
  total_number_of_white_plates: IAutomobileuseColumn4;
  dealer_plate_numbers: string | null;
  service_plate_numbers: string | null;
  private_passenger: IAutomobileuseColumn4;
  commercial_tow_trucks: IAutomobileuseColumn4;
  commercial_tilt_and_load: IAutomobileuseColumn4;
  commercial_parts_truck: IAutomobileuseColumn4;
  commercial_service_trucks: IAutomobileuseColumn4;
  commercial_shuttle_vehicles: IAutomobileuseColumn4;
  commercial_other: IAutomobileuseColumn4;
  courtesy_cars: IAutomobileuseColumn4;
  summary_of_all_active_vehicles_other_details: string | null;
  details_of_all_vehicles_owned_by_the_applicant: {
    key: string;
    year: string | null;
    make_model_body_type: string | null;
    vin: string | null;
    gvw: string | null;
    list_price_new: string | null;
    use: string | null;
    driver_principal_and_occasional: string | null;
  }[];
  owned_automobiles_including_vehicles: {
    maximum_number_in_building_location_a: string | null;
    maximum_number_in_building_location_b: string | null;
    maximum_number_in_building_location_c: string | null;
    maximum_number_in_building_location_d: string | null;
    maximum_number_on_lot_location_a: string | null;
    maximum_number_on_lot_location_b: string | null;
    maximum_number_on_lot_location_c: string | null;
    maximum_number_on_lot_location_d: string | null;
    maximum_value_location_a: string | null;
    maximum_value_location_b: string | null;
    maximum_value_location_c: string | null;
    maximum_value_location_d: string | null;
    average_value_location_a: string | null;
    average_value_location_b: string | null;
    average_value_location_c: string | null;
    average_value_location_d: string | null;
  };
  customer_automobiles: {
    maximum_number_in_building_location_a: string | null;
    maximum_number_in_building_location_b: string | null;
    maximum_number_in_building_location_c: string | null;
    maximum_number_in_building_location_d: string | null;
    maximum_number_on_lot_location_a: string | null;
    maximum_number_on_lot_location_b: string | null;
    maximum_number_on_lot_location_c: string | null;
    maximum_number_on_lot_location_d: string | null;
    maximum_value_location_a: string | null;
    maximum_value_location_b: string | null;
    maximum_value_location_c: string | null;
    maximum_value_location_d: string | null;
    average_value_location_a: string | null;
    average_value_location_b: string | null;
    average_value_location_c: string | null;
    average_value_location_d: string | null;
  };
  during_business_hours: string | null;
  after_business_hours: string | null;
  are_driving_record_abstracts_obtained_yes_or_no: string | null;
  how_often: string | null;

  outside_floodlights: IAutomobileuseColumn3;
  burglary_alarm_system: IAutomobileuseColumn3;
  fenced_compound: IAutomobileuseColumn3;
  guard_dogs: IAutomobileuseColumn3;
  night_watchman: IAutomobileuseColumn3;
  other_describe_below: IAutomobileuseColumn3;

  security_measures_other_details: string | null;
  does_the_applicant_pick_up_or_deliver_yes_or_no: string | null;
  does_the_applicant_pick_up_or_deliver_value: string | null;
  number_of_spray_booths: string | null;
  inspected_and_approved_yes_or_no: string | null;
  spraying: string | null;
  welding: string | null;
  does_the_applicant_dispense_propane_yes_or_no: string | null;
  number_of_licensed_employees: string | null;
  give_details_of_any_contractual_liability: string | null;
  what_is_the_maximum_number_of_automobiles: string | null;
  how_many_tow_trucks_can_carry: string | null;
  does_the_applicant_have_written_rules_yes_or_no: string | null;
  does_the_applicant_have_written_rules_value: string | null;
  is_demonstrator_use_restricted_yes_or_no: string | null;
  including_spouse_yes_or_no: string | null;
  including_children_yes_or_no: string | null;
  others: string | null;
  is_vacation_use_of_owned_vehicles_yes_or_no: string | null;
  are_customers_permitted_to_test_drive_yes_or_no: string | null;
  is_customer_always_accompanied_yes_or_no: string | null;
  describe_procedures_for_other_precautions_taken: string | null;
  if_sales_include_recreational_vehicles_yes_or_no: string | null;
  is_applicant_currently_registered_yes_or_no: string | null;
  where_and_how_are_vehicles_held: string | null;
  lien_checks_yes_or_no: string | null;
  consignment_yes_or_no: string | null;
  if_yes_what_policies_and_procedures: string | null;
  indicate_number_and_average_retail_values_sold: {
    third_year_back_number_sold: string | null;
    third_year_back_average_retail_value: string | null;
    second_year_back_number_sold: string | null;
    second_year_back_average_retail_value: string | null;
    expiring_year_number_sold: string | null;
    expiring_year_average_retail_value: string | null;
    expected_for_coming_year_number_sold: string | null;
    expected_for_coming_year_average_retail_value: string | null;
  };
  have_you_bound_this_risk_yes_or_no: string | null;
  is_this_business_new_to_you_yes_or_no: string | null;
  how_long_have_you_known_the_applicant: string | null;
  report_of_broker_other_details: string | null;
  status: string;
  created_by_id: number | null;
  modified_by_id: number | null;
  insert_ts: string | null;
  create_ts: string | null;
}

export interface IAutomobileLiabilitySlips {
  customer_policy_id: number;
  incremental_number: number | null;
  insured: string | null;
  address: string | null;
  policy_number: string | null;
  policy_status: string | null;
  insurer_name: string | null;
  broker_name: string | null;
  effective_date: string | null;
  expiry_date: string | null;
  effective_time_of_change: string | null;
  time_of_binding: string | null;
  list_of_vehicle_option_one: string | null;
  list_of_vehicle_option_two: string | null;
  list_of_vehicles: any[];
  status: string;
}

export interface IAutomobileLiabilityInsurance {
  customer_policy_id: number;
  incremental_number: number | null;
  cgl_policy_number: string | null;
  automobile_policy: string | null;
  umbrella_or_excess_liability_policy: string | null;
  other_policy: string | null;
  non_owned_automobiles_policy: string | null;
  hired_automobiles: string | null;
  vehicle_unit_number_or_vin: string | null;
  certificate_holder_name: string | null;
  certificate_holder_address: string | null;
  certificate_holder_email: string | null;
  certificate_holder_phone: string | null;
  description_of_operations: {
    key: string;
    label: string;
    value: string;
  }[];
  commercial_general_liability_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  commercial_general_liability_section_two: {
    claim_mode: string;
    claim_mode_deductible: string | number;
    claim_mode_amount: string | number;
    occurance: string;
    occurance_deductible: string | number;
    occurance_amount: string | number;
    products_completed_oprations: string;
    products_completed_oprations_deductible: string | number;
    products_completed_oprations_amount: string | number;
    empolyer_liability: string;
    empolyer_liability_peronal_injury: string;
    empolyer_liability_deductible: string | number;
    empolyer_liability_amount: string | number;
    waiver_of_subrogation: string;
    waiver_of_subrogation_peronal_injury: string;
    waiver_of_subrogation_deductible: string | number;
    waiver_of_subrogation_amount: string | number;
    cross_liability: string;
    cross_liability_deductible: string | number;
    cross_liability_amount: string | number;
    tenants_Legal_Liability: string;
    tenants_Legal_Liability_deductible: string | number;
    tenants_Legal_Liability_amount: string | number;
    pollution_Liability_Extension: string;
    pollution_Liability_Extension_deductible: string | number;
    pollution_Liability_Extension_amount: string | number;
  };
  commercial_general_liability_section_three: {
    key: string;
    type_of_insurance_check: string;
    type_of_insurance: string;
    coverage: string;
    deductible: string | number;
    amount: string | number;
  }[];
  non_owned_automobiles_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  non_owned_automobiles_section_two: {
    non_owned: string;
    non_owned_deductible: string;
    non_ownder_amount: string;
  };
  hired_automobiles_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  hired_automobiles_section_two: {
    hired_type_of_insurance: string;
    hired_amount: string;
    hired_deductible: string
  };
  automobile_liability_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  automobile_liability_section_two: {
    described_automobiles: string;
    described_automobiles_deductible: string;
    described_automobiles_amount: string;
    all_owned_automobiles: string;
    all_owned_automobiles_deductible: string;
    all_owned_automobiles_amount: string;
    leased_automobiles: string;
    leased_automobiles_deductible: string;
    leased_automobiles_amount: string;
    property_damage_deductible: string;
    property_damage_amount: string;
  };
  excess_liability_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  excess_liability_section_two: {
    umbrella_form: string;
    umbrella_form_deductible: string;
    umbrella_form_amount: string;
    aggreate_deductible: string;
    aggreate_amount: string;
    extra: string;
    extra_type_of_insurance: string;
    extra_coverage: string;
    extra_deductible: string;
    extra_amount: string;
  };
  other_liability_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  other_liability_section_two: {
    key: string;
    type_of_insurance_check: string;
    type_of_insurance: string;
    coverage: string;
    deductible: string | number;
    amount: string | number;
  }[];
  cancelation_number_of_days: string | null;
  brokerage_full_name: string | null;
  brokerage_mailing_address: string | null;
  brokerage_customer_code: string | null;
  additional_name_insured_yes_or_no: string | null;
  additional_name_insured_name: string | null;
  additional_name_insured_mailing_address: string | null;
  certificate_authorization_branch_name: string | null;
  certificate_authorization_assigned_to: string | null;
  certificate_authorization_phone_number: string | null;
  certificate_authorization_fax_number: string | null;
  certificate_authorization_email: string | null;
  certificate_authorization_date: string | null;
  status: string;
}

export interface IAutomobileOCEP16 {
  customer_policy_id: number;
  incremental_number: number | null;
  issued_to: string | null;
  policy_number: string | null;
  effective_date_of_change: string | null;
  policy_status: string | null;
  checkbox_this_change_applies: string | null,
  this_change_applies_only_to_automobile: string | null,
  indicated_on_your_certificate: string | null,
  checkbox_see_your_certificate_of_automobile: string | null,
  see_your_certificate_of_automobile: string | null,
  automobile_data: any;
  status: string;
}

export interface IAutomobileOCEP17 {
  customer_policy_id: number;
  incremental_number: number | null;
  issued_to: string | null;
  policy_number: string | null;
  effective_date_of_change: string | null;
  policy_status: null;
  automobile_data: any;
  status: string;
  checkbox_this_change_applies_only_to_automobile: string;
  this_change_applies_only_to_automobile: string | null;
  indicated_on_your_certificate_of_automobile: string | null;
  checkbox_see_your_certificate_of_automobile: string;
  see_your_certificate_of_automobile: string | null;
}

export interface IAutomobileOCEP28A {
  customer_policy_id: number;
  incremental_number: number | null;
  issued_to: string | null;
  effective_date_of_change: string | null;
  policy_number: string | null;
  automobile_number: string | null;
  model: string | null;
  year: string | null;
  trade_name: string | null;
  serial_number_or_vin: string | null;
  name_of_excluded_driver: string | null;
  driver_license_number: string | null;
  status: string;
}

export const initialAutomobileCertificatesState: IAutomobileCertificatesState =
  {
    liabilitySlips: {
      list: [],
      loading: LoadState.NotLoaded,
      liabilitySlip: {
        customer_policy_id: 0,
        incremental_number: null,
        insured: null,
        address: null,
        policy_number: null,
        policy_status: null,
        insurer_name: null,
        broker_name: null,
        effective_date: null,
        expiry_date: null,
        effective_time_of_change: moment()
          .startOf("day")
          .add(1, "minute")
          .toString(),
        time_of_binding: moment().startOf("day").add(1, "minute").format().toString(),
        list_of_vehicle_option_one: null,
        list_of_vehicle_option_two: null,
        list_of_vehicles: [],
        status: "ACTIVE",
      },
      liabilitySlipLoading: LoadState.NotLoaded,
      error: null,
    },
    liabilityInsurance: {
      list: [],
      loading: LoadState.NotLoaded,
      liabilityInsurance: {
        incremental_number: null,
        customer_policy_id: 0,
        cgl_policy_number: null,
        automobile_policy: null,
        umbrella_or_excess_liability_policy: null,
        other_policy: null,
        non_owned_automobiles_policy: null,
        hired_automobiles: null,
        vehicle_unit_number_or_vin: null,
        certificate_holder_name: null,
        certificate_holder_address: null,
        certificate_holder_email: null,
        certificate_holder_phone: null,
        description_of_operations: [
          { key: getUniqueId(), label: "Line 1", value: "" },
          { key: getUniqueId(), label: "Line 2", value: "" },
          { key: getUniqueId(), label: "Line 3", value: "" },
          { key: getUniqueId(), label: "Line 4", value: "" },
        ],
        commercial_general_liability_section_one: {
          insurance_company: "",
          policy_number: "",
          effective_date: "",
          expiry_date: "",
        },
        commercial_general_liability_section_two: {
          claim_mode: "N",
          claim_mode_deductible: "",
          claim_mode_amount: "",
          occurance: "N",
          occurance_deductible: "",
          occurance_amount: "",
          products_completed_oprations: "N",
          products_completed_oprations_deductible: "",
          products_completed_oprations_amount: "",
          empolyer_liability: "N",
          empolyer_liability_peronal_injury: "N",
          empolyer_liability_deductible: "",
          empolyer_liability_amount: "",
          waiver_of_subrogation: "N",
          waiver_of_subrogation_peronal_injury: "N",
          waiver_of_subrogation_deductible: "",
          waiver_of_subrogation_amount: "",
          cross_liability: "N",
          cross_liability_deductible: "",
          cross_liability_amount: "",
          tenants_Legal_Liability: "N",
          tenants_Legal_Liability_deductible: "",
          tenants_Legal_Liability_amount: "",
          pollution_Liability_Extension: "N",
          pollution_Liability_Extension_deductible: "",
          pollution_Liability_Extension_amount: "",
        },
        commercial_general_liability_section_three: [
          {
            key: getUniqueId(),
            type_of_insurance_check: "",
            type_of_insurance: "",
            coverage: "",
            deductible: "",
            amount: "",
          },
          {
            key: getUniqueId(),
            type_of_insurance_check: "",
            type_of_insurance: "",
            coverage: "",
            deductible: "",
            amount: "",
          },
     
        ],
        non_owned_automobiles_section_one: {
          insurance_company: "",
          policy_number: "",
          effective_date: "",
          expiry_date: "",
        },
        non_owned_automobiles_section_two: {
          non_owned: "N",
          non_ownder_amount: "",
          non_owned_deductible: "",
        },
        hired_automobiles_section_one: {
          insurance_company: "",
          policy_number: "",
          effective_date: "",
          expiry_date: "",
        },
        hired_automobiles_section_two: {
          hired_type_of_insurance: "N",
          hired_amount: "",
          hired_deductible: "",
        },
        automobile_liability_section_one: {
          insurance_company: "",
          policy_number: "",
          effective_date: "",
          expiry_date: "",
        },
        automobile_liability_section_two: {
          described_automobiles: "N",
          described_automobiles_deductible: "",
          described_automobiles_amount: "",
          all_owned_automobiles: "N",
          all_owned_automobiles_deductible: "",
          all_owned_automobiles_amount: "",
          leased_automobiles: "N",
          leased_automobiles_deductible: "",
          leased_automobiles_amount: "",
          property_damage_deductible: "",
          property_damage_amount: "",
        },
        excess_liability_section_one: {
          insurance_company: "",
          policy_number: "",
          effective_date: "",
          expiry_date: "",
        },
        excess_liability_section_two: {
          umbrella_form: "N",
          umbrella_form_amount: "",
          aggreate_deductible: "",
          umbrella_form_deductible: "",
          extra: "N",
          extra_coverage: "",
          extra_type_of_insurance: "",
          extra_amount: "",
          extra_deductible: "",
          aggreate_amount: "",
        },
        other_liability_section_one: {
          insurance_company: "",
          policy_number: "",
          effective_date: "",
          expiry_date: "",
        },
        other_liability_section_two: [
          {
            key: getUniqueId(),
            type_of_insurance_check: "",
            type_of_insurance: "",
            coverage: "",
            deductible: "",
            amount: "",
          },
          {
            key: getUniqueId(),
            type_of_insurance_check: "",
            type_of_insurance: "",
            coverage: "",
            deductible: "",
            amount: "",
          },
        ],
        cancelation_number_of_days: null,
        brokerage_full_name: null,
        brokerage_mailing_address: null,
        brokerage_customer_code: null,
        additional_name_insured_yes_or_no: null,
        additional_name_insured_name: null,
        additional_name_insured_mailing_address: null,
        certificate_authorization_branch_name: null,
        certificate_authorization_assigned_to: null,
        certificate_authorization_phone_number: null,
        certificate_authorization_fax_number: null,
        certificate_authorization_email: null,
        certificate_authorization_date: moment().format("YYYY-MM-DD"),
        status: "ACTIVE",
      },
      liabilityInsuranceLoading: LoadState.NotLoaded,
      error: null,
    },
    opcf16: {
      list: [],
      loading: LoadState.NotLoaded,
      opcf: {
        customer_policy_id: 0,
        incremental_number: null,
        issued_to: null,
        policy_number: null,
        effective_date_of_change: null,
        policy_status: null,
        automobile_data: [],
        checkbox_this_change_applies: null,
        this_change_applies_only_to_automobile: null,
        indicated_on_your_certificate: null,
        checkbox_see_your_certificate_of_automobile: null,
        see_your_certificate_of_automobile: null,
        status: "ACTIVE",
      },
      opcfLoading: LoadState.NotLoaded,
      error: null,
    },
    opcf17: {
      list: [],
      loading: LoadState.NotLoaded,
      opcf: {
        customer_policy_id: 0,
        incremental_number: null,
        issued_to: null,
        policy_number: null,
        effective_date_of_change: null,
        policy_status: null,
        automobile_data: [{}],
        checkbox_this_change_applies_only_to_automobile: "N",
        this_change_applies_only_to_automobile: null,
        indicated_on_your_certificate_of_automobile: null,
        checkbox_see_your_certificate_of_automobile: "N",
        see_your_certificate_of_automobile: null,
        status: "ACTIVE",
      },
      opcfLoading: LoadState.NotLoaded,
      error: null,
    },
    opcf28A: {
      list: [],
      loading: LoadState.NotLoaded,
      opcf: {
        customer_policy_id: 0,
        incremental_number: null,
        issued_to: null,
        effective_date_of_change: moment().toString(),
        policy_number: null,
        automobile_number: null,
        model: null,
        year: null,
        trade_name: null,
        serial_number_or_vin: null,
        name_of_excluded_driver: null,
        driver_license_number: null,
        status: "ACTIVE",
      },
      opcfLoading: LoadState.NotLoaded,
      error: null,
    },
    consentform: {
      list: [],
      loading: LoadState.NotLoaded,
      consent: {
        customer_policy_id: 0,
        incremental_number: null,
        customer_id: 0,
        customer_name: null,
        email_address: null,
        text_field: null,
        modified_by_id: null,
        insert_ts: null,
        create_ts: null,
        status: "ACTIVE",
        created_by_id: 0,
      },
      consentLoading: LoadState.NotLoaded,
      error: null,
    },
    sharingfillable: {
      list: [],
      loading: LoadState.NotLoaded,
      sharing: {
        customer_policy_id: 0,
        incremental_number: null,
        customer_id: 0,
        customer_name: null,
        policy_number: null,
        modified_by_id: null,
        insert_ts: null,
        create_ts: null,
        status: "ACTIVE",
        created_by_id: 0,
      },
      sharingLoading: LoadState.NotLoaded,
      error: null,
    },
    supplementaryform: {
      list: [],
      loading: LoadState.NotLoaded,
      supplementary: {
        customer_policy_id: 0,
        incremental_number: null,
        customer_id: 0,
        customer_name: null,
        broker: null,
        effective_date: null,
        section_a: {
          in_business_since: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          sales_of_new_vehicles: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          sales_of_used_vehicles: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          leasing_of_new_or_used_vehicles: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          body_shop: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          body_shop_including_car_sales: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          repair_garage: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          repair_garage_including_car_sales: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          service_station: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          service_station_self_serve: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          parking_lot: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          parking_lot_self_park: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          storage_garage: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          storage_garage_self_store: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          valet_parking: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          valet_parking_fee_charged: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          car_wash: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          car_wash_automatic: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          other_detail_remarks: null,
        },

        private_passenger_checkbox: null,
        light_commercial_checkbox: null,
        heavy_commercial_checkbox: null,
        trailers_checkbox: null,
        commercial_trailers_checkbox: null,
        antique_or_classic_checkbox: null,
        exotic_racing_checkbox: null,
        motorcycles_checkbox: null,
        atv_checkbox: null,
        snowmobiles_checkbox: null,
        motorhomes_checkbox: null,
        other_checkbox: null,
        other_value: null,
        proprietors: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        sales_staff: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        mechanics: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        lot_persons: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        body_shop_workers: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        full_service_station_attendants: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        clerical: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        parts_department_staff: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        parking_lot_attendant: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        valet_parking: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        summary_of_employees_other_details: null,
        employee_information_including_owners: [
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            position: null,
            years_lincence: null,
            licence_class: null,
            full_or_part_time: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            position: null,
            years_lincence: null,
            licence_class: null,
            full_or_part_time: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            position: null,
            years_lincence: null,
            licence_class: null,
            full_or_part_time: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            position: null,
            years_lincence: null,
            licence_class: null,
            full_or_part_time: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            position: null,
            years_lincence: null,
            licence_class: null,
            full_or_part_time: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
        ],
        other_operators: [
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            relationship_to_the_named_insured: null,
            years_lincence: null,
            licence_class: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            relationship_to_the_named_insured: null,
            years_lincence: null,
            licence_class: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            relationship_to_the_named_insured: null,
            years_lincence: null,
            licence_class: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            relationship_to_the_named_insured: null,
            years_lincence: null,
            licence_class: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            relationship_to_the_named_insured: null,
            years_lincence: null,
            licence_class: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            relationship_to_the_named_insured: null,
            years_lincence: null,
            licence_class: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
        ],
        claims: [
          {
            key: getUniqueId(),
            driver_name: null,
            date_of_loss: "",
            type_of_loss: null,
            amount_paid: null,
            descriptiom: null,
          },
          {
            key: getUniqueId(),
            driver_name: null,
            date_of_loss: "",
            type_of_loss: null,
            amount_paid: null,
            descriptiom: null,
          },
          {
            key: getUniqueId(),
            driver_name: null,
            date_of_loss: "",
            type_of_loss: null,
            amount_paid: null,
            descriptiom: null,
          },
          {
            key: getUniqueId(),
            driver_name: null,
            date_of_loss: "",
            type_of_loss: null,
            amount_paid: null,
            descriptiom: null,
          },
          {
            key: getUniqueId(),
            driver_name: null,
            date_of_loss: "",
            type_of_loss: null,
            amount_paid: null,
            descriptiom: null,
          },
        ],
        are_all_active_vehicles_owned_by_yes_or_no: null,
        are_all_active_vehicles_owned_by_no_reason: null,
        number_of_dealer_plates: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        number_of_service_plates: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        total_number_of_white_plates: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        dealer_plate_numbers: null,
        service_plate_numbers: null,
        private_passenger: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        commercial_tow_trucks: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        commercial_tilt_and_load: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        commercial_parts_truck: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        commercial_service_trucks: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        commercial_shuttle_vehicles: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        commercial_other: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        courtesy_cars: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        summary_of_all_active_vehicles_other_details: null,
        details_of_all_vehicles_owned_by_the_applicant: [
          {
            key: getUniqueId(),
            year: null,
            make_model_body_type: null,
            vin: null,
            gvw: null,
            list_price_new: null,
            use: null,
            driver_principal_and_occasional: null,
          },
          {
            key: getUniqueId(),
            year: null,
            make_model_body_type: null,
            vin: null,
            gvw: null,
            list_price_new: null,
            use: null,
            driver_principal_and_occasional: null,
          },
          {
            key: getUniqueId(),
            year: null,
            make_model_body_type: null,
            vin: null,
            gvw: null,
            list_price_new: null,
            use: null,
            driver_principal_and_occasional: null,
          },
          {
            key: getUniqueId(),
            year: null,
            make_model_body_type: null,
            vin: null,
            gvw: null,
            list_price_new: null,
            use: null,
            driver_principal_and_occasional: null,
          },
          {
            key: getUniqueId(),
            year: null,
            make_model_body_type: null,
            vin: null,
            gvw: null,
            list_price_new: null,
            use: null,
            driver_principal_and_occasional: null,
          },
          {
            key: getUniqueId(),
            year: null,
            make_model_body_type: null,
            vin: null,
            gvw: null,
            list_price_new: null,
            use: null,
            driver_principal_and_occasional: null,
          },
        ],
        owned_automobiles_including_vehicles: {
          maximum_number_in_building_location_a: null,
          maximum_number_in_building_location_b: null,
          maximum_number_in_building_location_c: null,
          maximum_number_in_building_location_d: null,
          maximum_number_on_lot_location_a: null,
          maximum_number_on_lot_location_b: null,
          maximum_number_on_lot_location_c: null,
          maximum_number_on_lot_location_d: null,
          maximum_value_location_a: null,
          maximum_value_location_b: null,
          maximum_value_location_c: null,
          maximum_value_location_d: null,
          average_value_location_a: null,
          average_value_location_b: null,
          average_value_location_c: null,
          average_value_location_d: null,
        },
        customer_automobiles: {
          maximum_number_in_building_location_a: null,
          maximum_number_in_building_location_b: null,
          maximum_number_in_building_location_c: null,
          maximum_number_in_building_location_d: null,
          maximum_number_on_lot_location_a: null,
          maximum_number_on_lot_location_b: null,
          maximum_number_on_lot_location_c: null,
          maximum_number_on_lot_location_d: null,
          maximum_value_location_a: null,
          maximum_value_location_b: null,
          maximum_value_location_c: null,
          maximum_value_location_d: null,
          average_value_location_a: null,
          average_value_location_b: null,
          average_value_location_c: null,
          average_value_location_d: null,
        },
        during_business_hours: null,
        after_business_hours: null,
        are_driving_record_abstracts_obtained_yes_or_no: null,
        how_often: null,
        outside_floodlights: {
          location_a_yes_or_no: null,
          location_b_yes_or_no: null,
          location_c_yes_or_no: null,
          location_d_yes_or_no: null,
          comments_description: null,
        },
        burglary_alarm_system: {
          location_a_yes_or_no: null,
          location_b_yes_or_no: null,
          location_c_yes_or_no: null,
          location_d_yes_or_no: null,
          comments_description: null,
        },
        fenced_compound: {
          location_a_yes_or_no: null,
          location_b_yes_or_no: null,
          location_c_yes_or_no: null,
          location_d_yes_or_no: null,
          comments_description: null,
        },
        guard_dogs: {
          location_a_yes_or_no: null,
          location_b_yes_or_no: null,
          location_c_yes_or_no: null,
          location_d_yes_or_no: null,
          comments_description: null,
        },
        night_watchman: {
          location_a_yes_or_no: null,
          location_b_yes_or_no: null,
          location_c_yes_or_no: null,
          location_d_yes_or_no: null,
          comments_description: null,
        },
        other_describe_below: {
          location_a_yes_or_no: null,
          location_b_yes_or_no: null,
          location_c_yes_or_no: null,
          location_d_yes_or_no: null,
          comments_description: null,
        },
        security_measures_other_details: null,
        does_the_applicant_pick_up_or_deliver_yes_or_no: null,
        does_the_applicant_pick_up_or_deliver_value: null,
        number_of_spray_booths: null,
        inspected_and_approved_yes_or_no: null,
        spraying: null,
        welding: null,
        does_the_applicant_dispense_propane_yes_or_no: null,
        number_of_licensed_employees: null,
        give_details_of_any_contractual_liability: null,
        what_is_the_maximum_number_of_automobiles: null,
        how_many_tow_trucks_can_carry: null,
        does_the_applicant_have_written_rules_yes_or_no: null,
        does_the_applicant_have_written_rules_value: null,
        is_demonstrator_use_restricted_yes_or_no: null,
        including_spouse_yes_or_no: null,
        including_children_yes_or_no: null,
        others: null,
        is_vacation_use_of_owned_vehicles_yes_or_no: null,
        are_customers_permitted_to_test_drive_yes_or_no: null,
        is_customer_always_accompanied_yes_or_no: null,
        describe_procedures_for_other_precautions_taken: null,
        if_sales_include_recreational_vehicles_yes_or_no: null,
        is_applicant_currently_registered_yes_or_no: null,
        where_and_how_are_vehicles_held: null,
        lien_checks_yes_or_no: null,
        consignment_yes_or_no: null,
        if_yes_what_policies_and_procedures: null,
        indicate_number_and_average_retail_values_sold: {
          third_year_back_number_sold: null,
          third_year_back_average_retail_value: null,
          second_year_back_number_sold: null,
          second_year_back_average_retail_value: null,
          expiring_year_number_sold: null,
          expiring_year_average_retail_value: null,
          expected_for_coming_year_number_sold: null,
          expected_for_coming_year_average_retail_value: null,
        },
        have_you_bound_this_risk_yes_or_no: null,
        is_this_business_new_to_you_yes_or_no: null,
        how_long_have_you_known_the_applicant: null,
        report_of_broker_other_details: null,
        modified_by_id: null,
        insert_ts: null,
        create_ts: null,
        status: "ACTIVE",
        created_by_id: 0,
      },
      supplementaryLoading: LoadState.NotLoaded,
      error: null,
    },
    garageform: {
      list: [],
      loading: LoadState.NotLoaded,
      garage: {
        customer_policy_id: 0,
        incremental_number: null,
        customer_id: 0,
        policy_number: "",
        insurance_company: "",
        new: null,
        replacing_policy_number: null,
        customer_name: null,
        customer_address: null,
        customer_unit_or_suite: null,
        customer_city: null,
        customer_province_or_state: null,
        customer_postal_code: null,
        customer_country: null,
        contact_business: null,
        contact_home: null,
        contact_fax: null,
        broker_client_id: null,
        company_client_id: null,
        broker_or_agent: null,
        branch: null,
        code: null,
        broker_or_agent_bill: null,
        company_bill: null,
        payment_plan: null,
        business_address: null,
        section_a: [
          {
            key: getUniqueId(),
            srNo: "A",
            location_of_other_premises: null,
            structure_type_one_lot: null,
            structure_type_two_lot: null,
          },
          {
            key: getUniqueId(),
            srNo: "B",
            location_of_other_premises: null,
            structure_type_one_lot: null,
            structure_type_two_lot: null,
          },
        ],
        effective_time: null,
        effective_date: null,
        expiry_date: null,
        the_automobiles_in_respect: null,
        estimated_total_payroll: null,
        full_time: null,
        part_time: null,
        policy_auto_vcs_schedule_d_id: 0,
        coverage_summary_incremental_number: null,
        leinholder_name: null,
        insurer: null,
        reason: null,
        injury_to_persons: null,
        collision: null,
        other: null,
        not_in_care_of_applicant: null,
        in_care_of_applicant: null,
        remarks: null,
        print_name: null,
        brokerage_or_agent_name: null,
        modified_by_id: null,
        insert_ts: null,
        create_ts: null,
        status: "ACTIVE",
        created_by_id: 0,
      },
      garageLoading: LoadState.NotLoaded,
      error: null,
    },
    retireediscount: {
      list: [],
      loading: LoadState.NotLoaded,
      retiree: {
        customer_policy_id: 0,
        customer_id: 0,
        incremental_number: null,
        customer_name: null,
        policy_number: null,
        effective_date_of_discount: moment().format("YYYY-MM-DD"),
        insurer: null,
        branch_code: null,
        retiree_name: null,
        modified_by_id: null,
        insert_ts: null,
        create_ts: null,
        status: "ACTIVE",
        created_by_id: 0,
      },
      retireeLoading: LoadState.NotLoaded,
      error: null,
    },
  };
