import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";
import { IGroupedMarketing, IMarketings } from "./marketing.types";

export const FETCH_MARKETINGS_LIST_PROGRESS = "FETCH_MARKETINGS_LIST_PROGRESS";
export const FETCH_MARKETINGS_LIST_SUCCESS = "FETCH_MARKETINGS_LIST_SUCCESS";
export const FETCH_MARKETINGS_LIST_FAILED = "FETCH_MARKETINGS_LIST_FAILED";

export const fetchMarketingsListProgress = () =>
  action(FETCH_MARKETINGS_LIST_PROGRESS);
export const fetchMarketingsListSuccess = (
  list: IGroupedMarketing[],
  totalRecords: number
) => action(FETCH_MARKETINGS_LIST_SUCCESS, { list, totalRecords });
export const fetchMarketingsListFailed = () =>
  action(FETCH_MARKETINGS_LIST_FAILED);

const execGroupData = (data: IMarketings[]): IGroupedMarketing[] => {
  const groupedData = Object.values(
    data.reduce((acc, obj) => {
      //@ts-ignore
      if (!acc[obj.customer_policy_id]) {
        //@ts-ignore
        acc[obj.customer_policy_id] = {
          id: obj.customer_policy_id,
          ...obj,
          childs: [obj],
        };
      } else {
        //@ts-ignore
        acc[obj.customer_policy_id].childs.push(obj);
      }
      return acc;
    }, {})
  );
  return groupedData as IGroupedMarketing[];
};

export const fetchMarketingsListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[]
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchMarketingsListProgress());
      let url = "";
      if (status !== "-1") {
        url = "&quote_status=" + status;
      }
      let finalUrl = `/tasks/get-tasks-all-marketing-history?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;
      if (searchType.length > 0 && searchValue) {
        finalUrl = `/tasks/get-tasks-all-marketing-history?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&column=${[
          searchType,
        ]}&value=${searchValue}`;
      }
      const res = await api.get(finalUrl);

      const data: IMarketings[] = res.data.data;
      const finalData = execGroupData(data);

      const totalRecords = res.data.totalRecords;

      dispatch(fetchMarketingsListSuccess(finalData, totalRecords));
    } catch (err: any) {
      dispatch(fetchMarketingsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
