/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { api } from "../api/api";
import { IPolicy } from "../redux/allPolicies/Policies.types";
import { ICustomerFullDetails } from "../redux/customer/customer.types";
import { useDispatchWrapper } from "./useDispatch";
import { showMessage } from "../redux/messages/messagesActions";

export const useTaskCustomerInfo = (customerId?: number | null) => {
  const [state, setState] = React.useState<{
    customerCode: string;
    customerName: string;
    policies: IPolicy[];
  }>({
    customerCode: "",
    customerName: "",
    policies: [],
  });

  const dispatch = useDispatchWrapper();

  const fetchCustomerAndPolicyDetails = async () => {
    try {
      const res = await api.get(
        `/customer/get-customer-all-detailed-record?customer_id=${customerId}`
      );
      const data: ICustomerFullDetails[] = res.data.data;
      const res1 = await api.get(
        `/policy/get-all-customer-policy-details?customer_id=${customerId}`
      );

      if (data.length > 0) {
        const customerInfo = data[0];
        setState({
          customerCode: customerInfo.customer_code,
          customerName: customerInfo.customer_name,
          policies: res1.data.data || [],
        });
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

  React.useEffect(() => {
    if (customerId) {
      fetchCustomerAndPolicyDetails();
    }
  }, [customerId]);

  const getCustomerDetailsByPolicyNumber = (
    policyNumber: number | string,
    isByPolicyNumber?: boolean
  ) => {
    let policy = state.policies.find((x) => {
      if (!isByPolicyNumber && x.customer_policy_id === policyNumber) {
        return x.customer_policy_id === policyNumber;
      } else {
        return x.policy_number === policyNumber;
      }
    });
    if (policy) {
      return policy;
    }
    return null;
  };

  return { customerDetails: state, getCustomerDetailsByPolicyNumber };
};
