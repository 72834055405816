import { action } from "typesafe-actions";
import { IDate } from "../../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { showMessage } from "../../messages/messagesActions";
import { IHabitationalCSIOEDIApplication, initialHabitationalCSIOEDIApplicationState } from "./habitationalCSIOEDIApplication.types";

export const FETCH_HABITATIONAL_EDI_LIST_PROGRESS =
  "FETCH_HABITATIONAL_EDI_LIST_PROGRESS";
export const FETCH_HABITATIONAL_EDI_LIST_SUCCESS =
  "FETCH_HABITATIONAL_EDI_LIST_SUCCESS";
export const FETCH_HABITATIONAL_EDI_LIST_FAILED =
  "FETCH_HABITATIONAL_EDI_LIST_FAILED";

export const fetchHabitationalEDIListProgress = () =>
  action(FETCH_HABITATIONAL_EDI_LIST_PROGRESS);
export const fetchHabitationalEDIListSuccess = (
  data: IHabitationalCSIOEDIApplication[],
  totalRecords: number
) =>
  action(FETCH_HABITATIONAL_EDI_LIST_SUCCESS, {
    data,
    totalRecords,
  });
export const fetchHabitationalEDIListFailed = () =>
  action(FETCH_HABITATIONAL_EDI_LIST_FAILED);

export const fetchHabitationalEDIListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string | null,
    date: IDate,
    customerPolicyId: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalEDIListProgress());
      let finalUrl = `/csio/get-csio-application-edi?table=Habitational&pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}&customer_policy_id=${customerPolicyId}`;

      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }

      const res = await api.get(finalUrl);
      const data: IHabitationalCSIOEDIApplication[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchHabitationalEDIListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchHabitationalEDIListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_HABITATIONAL_APPLICATION_EDI_PROGRESS =
  "FETCH_HABITATIONAL_APPLICATION_EDI_PROGRESS";
export const FETCH_HABITATIONAL_APPLICATION_EDI_SUCCESS =
  "FETCH_HABITATIONAL_APPLICATION_EDI_SUCCESS";
export const FETCH_HABITATIONAL_APPLICATION_EDI_FAILED =
  "FETCH_HABITATIONAL_APPLICATION_EDI_FAILED";

export const fetchHabitationalApplicationEDIProgress = () =>
  action(FETCH_HABITATIONAL_APPLICATION_EDI_PROGRESS);

export const fetchHabitationalApplicationEDISuccess = (
  data: IHabitationalCSIOEDIApplication
) =>
  action(FETCH_HABITATIONAL_APPLICATION_EDI_SUCCESS, {
    data,
  });
export const fetchHabitationalApplicationEDIFailed = () =>
  action(FETCH_HABITATIONAL_APPLICATION_EDI_FAILED);

export const fetchHabitationalApplicationEDIAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalApplicationEDIProgress());
      const res = await api.get(
        `/csio/get-csio-application-edi?table=Habitational&customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`
      );
      const data: IHabitationalCSIOEDIApplication[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchHabitationalApplicationEDISuccess(data[0]));
      } else {
        dispatch(
          fetchHabitationalApplicationEDISuccess(
            initialHabitationalCSIOEDIApplicationState["edi"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchHabitationalApplicationEDIFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };


  export const CLEAR_HABITATIONAL_EDI_APPLICATION_STATE =
  "CLEAR_HABITATIONAL_EDI_APPLICATION_STATE";

export const clearHabitationalApplicationEDIState = () =>
  action(CLEAR_HABITATIONAL_EDI_APPLICATION_STATE);
