import { LoadState } from "../../../constants/enums";

export interface ICompanyDetailState {
  data: ICompanyDetail;
  loading: LoadState;
  error: string | null;
}

export interface ICompanyDetail {
  company_id: number | null;
  company_name: string | null;
  marketcap: string | null;
  hq_location: string | null;
  country: string | null;
  continent: string | null;
  linkedin_page: string | null;
  website: string | null;
  employees_count: string | null;
  status: string;
}

export const initialAccountingCompanyDetailState: ICompanyDetailState = {
  data: {
    company_id: null,
    company_name: null,
    marketcap: null,
    hq_location: null,
    country: null,
    continent: null,
    linkedin_page: null,
    website: null,
    employees_count: null,
    status: "Active",
  },
  loading: LoadState.NotLoaded,
  error: null,
};
