import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { AddOutlined, AppRegistration } from "@mui/icons-material";
import { ContextMenu } from "../../../../../../components/ContextMenu/ContextMenu";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { IBusinessPolicyEquipment } from "../../../../../../redux/businessPolicy/businessPolicyEquipment/businessPolicyEquipment.types";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { LoadState } from "../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../hooks";
import { fetchBusinessPolicyEquipmentListAsync } from "../../../../../../redux/businessPolicy/businessPolicyEquipment/businessPolicyEquipmentActions";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { CustomLink } from "../../../../../../components/CustomLink/CustomLink";

export const EquipmentList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.business.equipment
  );

  const dispatch = useDispatchWrapper();

  React.useEffect(() => {
    dispatch(fetchBusinessPolicyEquipmentListAsync(customerPolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerPolicyId]);


  const equimentTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                startIcon={<AddOutlined />}
                onClick={() => {
                  navigate(
                    `/business-policy/${customerId}/schedule-tab/${customerPolicyId}/equipment`
                  );
                }}
              >
                Create
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "equipment_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IBusinessPolicyEquipment) => {
          return (
          <Link sx={{ cursor: "pointer" }} onClick={() => {
            navigate(
              `/business-policy/${customerId}/schedule-tab/${customerPolicyId}/equipment/${row.incremental_number}`
            );
          }}>
              {row.incremental_number} 
            </Link>
          );
        },
      },
      {
        key: "effective_date",
        headerName: "Effective Date",
        fieldName: "effective_date",
        renderType: RenderType.DATE_DARK_COLOR,
        exportCellWidth: 20,
      },
     
     
      {
        key: "serial_number",
        headerName: "Serial Number",
        fieldName: "serial_number",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
        onRowCellRender: (value, row: IBusinessPolicyEquipment) => {
          return (
            <CustomLink
              to={`/business-policy/${customerId}/schedule-tab/${customerPolicyId}/equipment/${row.incremental_number}`}
              label={row.serial_number}
            />
          );
        },
      },
      {
        key: "limit_amount",
        headerName: "Limit Amount",
        fieldName: "limit_amount",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
     
      {
        key: "purchase_date",
        headerName: "Purchase Date",
        fieldName: "purchase_date",
        renderType: RenderType.DATE,
        exportCellWidth: 20,
      },
      {
        key: "equipment_submission_checkbox",
        headerName: "Submission",
        fieldName: "equipment_submission_checkbox",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: 'center',
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBusinessPolicyEquipment) => {
         

          return    (
            <StandardTableActions
              //onViewClick={() => setOpenView(row)}
              onEditClick={() => {
                navigate(
                  `/business-policy/${customerId}/schedule-tab/${customerPolicyId}/equipment/${row.incremental_number}`
                );
              }}
            //  more={{ menuItems: menuItems }}
            />
          );
        },
        },
      
    ],
    items: data,
  };

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...equimentTableProps} />
      </Box>
    </>
  );
};

