import { LoadState } from "../../constants/enums";
import { ISubInsurerState } from "./subInsurer.types";

export const initialSubInsurerState: ISubInsurerState = {
    subInsurer: {
        data: {
            insurer_sub_primary_id: null,
            insurer_sub_id: null,
            insurer_code_id: null,
            insurer_code: "",
            agency_code: null,
            branch_code: null,
            branch_name: null,
            address: null,
            remarks: null,
            status: "ACTIVE",
            insert_ts: "",
            country: "",
            city: "",
            postal_code: "",
            province_or_state: "",
            unit_or_suite: "",
        },
        loading: LoadState.NotLoaded,
        saveLoading: false,
        error: null,
    },

};
