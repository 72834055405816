import { action } from "typesafe-actions";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import {
  IG4SignDocument,
  IG4SignDocumentRecipient,
  IG4SignDocumentRecipientGet,
  IG4SignEditior,
  IG4SignState,
} from "./g4SignDocuments.types";
import { api } from "../../api/api";
import { ERROR_MESSAGES } from "../../constants/enums";
import { mergeFiles, uploadFile } from "../../components/FileUpload/utils";
import { getUniqueId } from "../../helpers";
import { sendEmail } from "../common/commonActions";
import { IMail } from "../common/common.types";
import { isEqual } from "lodash";
import { IFileUpload } from "../../components/FileUpload/FileUpload.type";
import { makeApiCall } from "../../helpers/postRequest";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";

export const FETCH_G4_SIGN_DOC_LIST_PROGRESS =
  "FETCH_G4_SIGN_DOC_LIST_PROGRESS";
export const FETCH_G4_SIGN_DOC_LIST_SUCCESS = "FETCH_G4_SIGN_DOC_LIST_SUCCESS";
export const FETCH_G4_SIGN_DOC_LIST_FAILED = "FETCH_G4_SIGN_DOC_LIST_FAILED";

export const fetchG4SignDocListProgress = () =>
  action(FETCH_G4_SIGN_DOC_LIST_PROGRESS);
export const fetchG4SignDocListSuccess = (
  data: IG4SignDocument[],
  totalRecords: number
) => action(FETCH_G4_SIGN_DOC_LIST_SUCCESS, { data, totalRecords });
export const fetchG4SignDocListFailed = () =>
  action(FETCH_G4_SIGN_DOC_LIST_FAILED);

export const fetchG4SignDocListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchG4SignDocListProgress());
      let finalUrl = `/docusign/get-documents?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;

      if (searchValue.length > 0) {
        finalUrl = `/docusign/get-documents?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }
      const res = await api.get(finalUrl);
      const data: IG4SignDocument[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchG4SignDocListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchG4SignDocListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_G4SIGN_SIGNED_DOCUMENT_PROGRESS =
  "FETCH_G4SIGN_SIGNED_DOCUMENT_PROGRESS";
export const FETCH_G4SIGN_SIGNED_DOCUMENT_SUCCESS =
  "FETCH_G4SIGN_SIGNED_DOCUMENT_SUCCESS";
export const FETCH_G4SIGN_SIGNED_DOCUMENT_FAILED =
  "FETCH_G4SIGN_SIGNED_DOCUMENT_FAILED";

export const fetchG4SignDocumentProgress = () =>
  action(FETCH_G4SIGN_SIGNED_DOCUMENT_PROGRESS);
export const fetchG4SignDocumentSuccess = (data: IG4SignDocument[]) =>
  action(FETCH_G4SIGN_SIGNED_DOCUMENT_SUCCESS, { data });
export const fetchG4SignDocumentFailed = () =>
  action(FETCH_G4SIGN_SIGNED_DOCUMENT_FAILED);

export const fetchG4SignDocumentAsync =
  (documentCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchG4SignDocumentProgress());
      const res = await api.get(
        `/docusign/generate-signed-document?document_code=${documentCode}`
      );
      const data = res.data.data;
      dispatch(fetchG4SignDocumentSuccess(data));
    } catch (err: any) {
      dispatch(fetchG4SignDocumentFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_G4_SIGN_DOC_PROGRESS = "FETCH_G4_SIGN_DOC_PROGRESS";
export const FETCH_G4_SIGN_DOC_SUCCESS = "FETCH_G4_SIGN_DOC_SUCCESS";
export const FETCH_G4_SIGN_DOC_FAILED = "FETCH_G4_SIGN_DOC_FAILED";

export const fetchG4SignDocProgress = () => action(FETCH_G4_SIGN_DOC_PROGRESS);
export const fetchG4SignDocSuccess = (data: IG4SignDocument) =>
  action(FETCH_G4_SIGN_DOC_SUCCESS, { data });
export const fetchG4SignDocFailed = (errorMessage: string) =>
  action(FETCH_G4_SIGN_DOC_FAILED, { errorMessage });

export const fetchG4SignDocAsync =
  (documentCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchG4SignDocProgress());

      const res = await api.get(
        `/docusign/get-documents?document_code=${documentCode}`
      );
      const data = res.data.data;
      if (data.length > 0) {
        const attachments: IFileUpload[] = [];
        if (data[0].attachment && !Array.isArray(data[0].attachment)) {
          attachments.push({
            file: null,
            key: getUniqueId(),
            path: data[0].attachment,
          });
        } else {
          for (const item of (data[0].attachment || []) as any) {
            attachments.push({
              file: null,
              key: getUniqueId(),
              path: item,
            });
          }
        }
        dispatch(
          fetchG4SignDocSuccess({ ...data[0], attachment: attachments })
        );
      } else {
        dispatch(fetchG4SignDocFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
      }
    } catch (err: any) {
      dispatch(fetchG4SignDocFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertG4SignDoc =
  (
    data: IG4SignDocument,
    initialData: IG4SignDocument,
    isUpdate: boolean,

    onCallback: (isSuccess: boolean, code?: string) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const previousState = getState().g4Sign.documents.document;
    const diff = isEqual(previousState, data);
    try {
      dispatch(saveLoaderProgress());
      if (!diff) {
        const asPayload = {};
        const options = {
          decryption: true,
        };
        const { file } = await mergeFiles(data.attachment);
        //@ts-ignore
        const path = await uploadFile(file, "SIGN", "", asPayload, options);

        const res = await makeApiCall(
          {
            url: "/docusign/insert-document",
            method: "POST",
            automation: {
              isUpdate: isUpdate,
              primaryFieldName: "document_code",
            },
          },
          initialData,
          {
            ...data,
            attachment: path,
          }
        );

        const finalDocument: IG4SignDocument = res.data.data;
        dispatch(fetchG4SignDocSuccess(finalDocument));

        dispatch(
          showMessage({
            type: "success",
            message: "Document created successfully",
            displayAs: "snackbar",
          })
        );
        onCallback(true, finalDocument.document_code as string);
      } else {
        onCallback(true, data.document_code as string);
      }
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_G4_SIGN_RECIPIENT_LIST_PROGRESS =
  "FETCH_G4_SIGN_RECIPIENT_LIST_PROGRESS";
export const FETCH_G4_SIGN_RECIPIENT_LIST_SUCCESS =
  "FETCH_G4_SIGN_RECIPIENT_LIST_SUCCESS";
export const FETCH_G4_SIGN_RECIPIENT_LIST_FAILED =
  "FETCH_G4_SIGN_RECIPIENT_LIST_FAILED";

export const fetchG4SignRecipientListProgress = () =>
  action(FETCH_G4_SIGN_RECIPIENT_LIST_PROGRESS);
export const fetchG4SignDocRecipientListSuccess = (
  data: IG4SignDocumentRecipientGet[]
) => action(FETCH_G4_SIGN_RECIPIENT_LIST_SUCCESS, { data });
export const fetchG4SignDocRecipientListFailed = () =>
  action(FETCH_G4_SIGN_RECIPIENT_LIST_FAILED);

export const fetchG4SignRecipientListAsync =
  (document_code: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchG4SignRecipientListProgress());
      const finalUrl = `/docusign/get-recipient?document_code=${document_code}&status=ACTIVE`;
      const res = await api.get(finalUrl);
      const data: IG4SignDocumentRecipientGet[] = res.data.data;

      dispatch(fetchG4SignDocRecipientListSuccess(data));
    } catch (err: any) {
      dispatch(fetchG4SignDocListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertG4Recipient =
  (
    data: IG4SignDocumentRecipient,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/docusign/insert-recipient", {
        ...data,
      });

      dispatch(
        showMessage({
          type: "success",
          message: "Recipient saved successfully",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const resendEmailToRecipient =
  (
    recipient_code: string,
    emailSubject: string,
    
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/docusign/resend-sign-email-to-recipient", {
        recipient_code: recipient_code,
        email_subject: emailSubject,
      });

      dispatch(
        showMessage({
          type: "success",
          message: "Email send successfully",
          displayAs: "dialog",
        })
      );
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_G4SIGN_PLACEHOLDERS_PROGRESS =
  "FETCH_G4SIGN_PLACEHOLDERS_PROGRESS";
export const FETCH_G4SIGN_PLACEHOLDERS_SUCCESS =
  "FETCH_G4SIGN_PLACEHOLDERS_SUCCESS";
export const FETCH_G4SIGN_PLACEHOLDERS_FAILED =
  "FETCH_G4SIGN_PLACEHOLDERS_FAILED";

export const ADD_G4SIGN_PLACEHOLDER = "ADD_G4SIGN_PLACEHOLDER";
export const ROLLBACK_G4SIGN_PLACEHOLDER = "ROLLBACK_G4SIGN_PLACEHOLDER";

export const fetchG4SignPlaceholdersProgress = () =>
  action(FETCH_G4SIGN_PLACEHOLDERS_PROGRESS);
export const fetchG4SignPlaceholdersSuccess = (list: IG4SignEditior[]) =>
  action(FETCH_G4SIGN_PLACEHOLDERS_SUCCESS, { list });
export const fetchG4SignPlaceholdersFailed = () =>
  action(FETCH_G4SIGN_PLACEHOLDERS_FAILED);

export const addG4SignPlaceholder = (data: IG4SignEditior, isUpdate: boolean) =>
  action(ADD_G4SIGN_PLACEHOLDER, {
    data,
    isUpdate,
  });

export const rollBackG4SignPlaceholder = (code: string) =>
  action(ROLLBACK_G4SIGN_PLACEHOLDER, { code });

export const fetchG4SignPlaceholdersAsync =
  (document_code: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchG4SignPlaceholdersProgress());
      const finalUrl = `/docusign/get-placeholder?document_code=${document_code}`;
      const res = await api.get(finalUrl);
      const data: IG4SignEditior[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchG4SignPlaceholdersSuccess(data));
      }
    } catch (err: any) {
      dispatch(fetchG4SignPlaceholdersFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const sendMailToRecipients =
  (
    otherData: {
      subject: string;
      // body: string;
      // templateName: string | null;
      // objectVariables: any;
    },
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const {
      recipientList,
      document,
      editior: { placeholders },
    } = getState().g4Sign.documents;
    try {
      dispatch(saveLoaderProgress());

      const payload: {
        document_code: string;
        email_subject: string;
        recipient_details: {
          recipient_email: string;
          auth_key: string;
        }[];
        status: string;
      } = {
        document_code: document.document_code as string,
        email_subject: otherData.subject,
        recipient_details: [],
        status: "ACTIVE",
      };

      for (const recipient of recipientList) {
        // const link =
        //   `${process.env["REACT_APP_SIGN_URL"]}/` + recipient.auth_key;
        // const data = {
        //   emails: [recipient.email],
        //   bcc: [],
        //   cc: [],
        //   subject: otherData.subject,
        //   body:
        //     otherData.body +
        //     `<p>Review Document</p><a href=${link} targte="_blank">Click here to sign document</a><br/><br/>`,
        //   templateName: null,
        //   objectVariables: null,
        // };
        // await api.post(`/general/send-email`, {
        //   ...data,
        // });
        payload.recipient_details.push({
          auth_key: recipient.auth_key,
          recipient_email: recipient.email,
        });
      }
      await api.post(`/docusign/send-email-for-signature`, payload);

      for (let placeholder of placeholders) {
        const finalPlaceholder = { ...placeholder };

        if (finalPlaceholder.isNewPlaceholder) {
          finalPlaceholder.field_code = null;
        }
        await api.post("/docusign/insert-placeholder", finalPlaceholder);
      }
      // await api.get(
      //   "/docusign/mark-as-send-document?document_code=" +
      //     document.document_code
      // );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_G4_SIGN_RECIPIENT = "CLEAR_G4_SIGN_RECIPIENT";
export const clearG4SignRecipient = () => action(CLEAR_G4_SIGN_RECIPIENT);

export const CLEAR_G4_SIGN_DOC_STATE = "CLEAR_G4_SIGN_DOC_STATE";
export const clearG4DocumentsState = () => action(CLEAR_G4_SIGN_DOC_STATE);
