import moment from "moment";
import { LoadState } from "../../constants/enums";
import { getUniqueId } from "../../helpers";
import { bool } from "yup";
import { getuid } from "process";

export interface IAutomobileAlbertaApplicationState {
  data: IAutomobileAlbertaApplication[];
  listLoading: LoadState;
  application: IAutomobileAlbertaApplication;
  loading: LoadState;
}

export interface IAutoDescribedSectionOne {
  key: string;
  vehNo: number;
  modelYear: number | null;
  makeTradeName: string | null;
  model: string | null;
  bodyType: string | null;
  vin_serial_No: string | null;
  purchase_price_including_accessories_equipment: string | null;
}

export interface IAutoDescribedSectionTwo {
  key: string;
  VehNo: number;
  postal_code: string | null;
  postal_address: string | null;
  name: string | null;
  type: string | null;
}

export interface IAutoDescribedSectionThree {
  key: string;
  vehNo: number;
  gvw_kg: string | null;
  winter_tires: string | null;
  list_price_new: string | null;
  vehicle_code: string | null;
  terr: string | null;
  loc: string | null;
  class: string | null;
  drtpl: string | null;
  dr_coll_ap: string | null;
  dcpd: string | null;
  coll: string | null;
  comp: string | null;
}

export interface IAutoDescribedSectionThreeA {
  key: string;
  occasional_driver_vehicle_no: string | null;
  list_price_new: string | null;
  vehicle_code: string | null;
  terr: string | null;
  loc: string | null;
  class: string | null;
  drtpl: string | null;
  dr_coll_ap: string | null;
  dcpd: string | null;
  coll: string | null;
  comp: string | null;
}

export interface IAutoDescribedSectionFour {
  key: string;
  vehNo: number;
  third_party_liability: string | null;
  direct_comp_for_property_damage: string | null;
  accident_benefits: string | null;
  loss_of_Damage_ap: string | null;
  loss_of_Damage_coll: string | null;
  loss_of_Damage_comp: string | null;
  loss_of_Damage_sp: string | null;
  veh_no: string | null;
  endorement_no: string | null;
}
export interface IAutoDescribedSectionFourA {
  key: string;
  vehNo: number;
  third_party_liability_pd: string | null;
  third_party_liability_bi: string | null;
  third_party_liability_grid: string | null;
  benefits_of_accident: string | null;
  loss_of_Damage_ap: string | null;
  loss_of_Damage_coll: string | null;
  loss_of_Damage_comp: string | null;
  loss_of_Damage_sp: string | null;
  sef_premium: string | null;
  vehicle_premium: string | null;
}

export interface IAutomobileAlbertaApplication {
  customer_policy_id: number | null;
  incremental_number: number | null;
  new_policy: boolean;
  renewal_policy: boolean;
  replacing_policy_no: string | null;
  company_bill: boolean;
  broker_or_agent_bill: boolean;
  other_specify: string | null;
  language_preferred: string | null;
  policy_no_assigned: string | null;
  insurance_company: string | null;
  broke_or_agent: string | null;
  insurance_name: string | null;
  insurance_address: string | null;
  insurance_unit_or_suite: string | null;
  insurance_city: string | null;
  insurance_state: string | null;
  insurance_country: string | null;
  insurance_zip: string | null;
  broker_agent: string | null;
  broker_agent_address: string | null;
  broker_agent_unit_or_suite: string | null;
  broker_agent_city: string | null;
  broker_agent_state: string | null;
  broker_agent_country: string | null;
  broker_agent_zip: string | null;
  broker_code: string | null;
  certificate_holder_name: string | null;
  certificate_holder_full_address: string | null;
  certificate_holder_email: string | null;
  certificate_holder_phone: string | null;
  lessor_name: string | null;
  lessor_full_address: string | null;
  lessor_email: string | null;
  lessor_phone: string | null;
  policy_period_effective_date: string | null;
  policy_period_effective_time: string;
  policy_period_expiry_date: string | null;
  policy_period_expiry_time: string;
  described_automobiles_part_one: IAutoDescribedSectionOne[];
  described_automobiles_part_two: IAutoDescribedSectionTwo[];
  described_automobiles_part_three: IAutoDescribedSectionThree[];
  described_automobiles_part_threeA: IAutoDescribedSectionThreeA[];
  described_automobiles_part_four: IAutoDescribedSectionFour[];
  described_automobiles_part_fourA: IAutoDescribedSectionFourA[];
  minimum_retained_premium: string | null;
  total_estimated_policy_premium: string | null;
  driver_information_part_one: {
    key: string;
    driverNumber: number;
    nameofDriver: string | null;
    driverLicenseNumber: string | null;
    dateOfBirth: string;
  }[];
  driver_information_part_two: {
    key: string;
    driverNumber: number;
    date_lic: string;
    lic_class: string | null;
    gridStep: string | null;
    driver_veh1: string | null;
    driver_veh2: string | null;
    driver_veh3: string | null;
    driver_veh4: string | null;
    driver_occupation: string | null;
    driver_training_attach_dtc_cert: string | null;
  }[];
  all_convictions: {
    key: string;
    driverNumber: string;
    date: string;
    description: string | null;
  }[];
  all_accidents_or_claims: {
    key: string;
    vehNo: string;
    driverNumber: string;
    date: string;
    typeOfClaim: string | null;
    amount_paid_or_estimate: string | null;
    claim_amount_repaid_to_insurer: string | null;
    description: string | null;
  }[];
  has_any_drivers: string | null;
  has_any_insurer: {
    insurer: string;
    reason: string;
    policy_no: string;
  };
  details_of_applicants_most_recent_automobile_insurance: {
    insurer: string;
    policy_no: string;
    expiry_date: string;
  };
  does_applicant_owe_any_money: string | null;
  all_accidents_or_claims_tabel_two: {
    key: string;
    VehNo: number;
    state_the_usual_distance_driven_annually: string | null;
    the_vehicle_used_to_commute: string | null;
    distance_one_way: string | null;
    state_the_usual_percentage_of_annual_kilometer: string | null;
    is_the_vehicle_used_outside_of_canada: string | null;
    no_of_months: string | null;
  }[];
  are_there_any_modification: string | null;
  all_accidents_or_claims_tabel_three: {
    key: string;
    vehNo: number;
    will_the_automobile_be_rented_leased_etc: string | null;
    will_the_automobile_be_used_for_the_transportation_of_goods: string | null;
  }[];
  unless_otherwise_stated: {
    the_registered_owner: string | null;
    the_actual_owner: string | null;
  };
  remarks: string | null;
  endorsements_vehicle1: {
    key: string;
    sef_n: string | null;
    description: string | null;
    limit1: string | null;
    limit2: string | null;
    deductible: string | null;
    percentage: string | null;
    premium: string | null;
  }[];
  endorsements_vehicle2: {
    key: string;
    sef_n: string | null;
    description: string | null;
    limit1: string | null;
    limit2: string | null;
    deductible: string | null;
    percentage: string | null;
    premium: string | null;
  }[];
  endorsements_vehicle3: {
    key: string;
    sef_n: string | null;
    description: string | null;
    limit1: string | null;
    limit2: string | null;
    deductible: string | null;
    percentage: string | null;
    premium: string | null;
  }[];
  endorsements_vehicle4: {
    key: string;
    sef_n: string | null;
    description: string | null;
    limit1: string | null;
    limit2: string | null;
    deductible: string | null;
    percentage: string | null;
    premium: string | null;
  }[];
  additional_information_for_drivers_part_one: {
    key: string;
    driverNo: number;
    driverName: string | null;
    driver_training_date_completed: string;
    type: string | null;
    sex: string | null;
    maritalStatus: string | null;
    relationship_to_applicant: string | null;
  }[];
  additional_information_for_drivers_part_two: {
    key: string;
    driverNo: number;
    conviction_date_of_mvr: string;
    conviction_code: string | null;
    conviction_percentage: string | null;
    conviction_description: string | null;
    atFault_code: string | null;
    atFault_percentage: string | null;
    atFault_description: string | null;
    discountApplied_code: string | null;
    discountApplied_percentage: string | null;
    discountApplied_description: string | null;
  }[];
  name_and_address_of_employer: {
    key: string;
    driverNO: number;
    name: string | null;
    address: string | null;
    dateHired: string;
  }[];
  list_all_residents_of_household: {
    key: string;
    driverNo: number;
    fullName: string | null;
    birthDate: string;
    driver_licence_no: string | null;
    own_a_vehicle: string | null;
    non_licensed_resident: string | null;
  }[];
  isVehicle_used_for_car_pools: {
    key: string;
    vehNo: number;
    type: string | null;
    no_of_passengers: string | null;
    frequency_of_times: string | null;
    frequency_per: string | null;
    fuel_if_not_powered_by_gas_diesel_engine: string | null;
  }[];
  isVehicle_used_for_car_pools2: {
    key: string;
    is_there_any_unprepaired_damage_including_damage_to_glass: string | null;
    describe_and_give_value_for_any_special_equipment_value: string | null;
    describe_and_give_value_for_any_special_equipment_describe: string | null;
  }[];
  provide_details_of_vehicle_antiTheft_device: {
    key: string;
    vehNo: number;
    deviceType: string | null;
    deviceCharacterstics: string | null;
    prouductCode: string | null;
  }[];
  if_applicant_has_changed_address_within_the_last_three_year: string | null;
  total_number_of_private_passenger_vehicle_in_household: string | null;
  additonal_information_remarks: string | null;


  have_you_bound_this_risk: string | null;
  is_this_business_new_to_your_office: string | null;
  motor_vehicle_liability_insurance_card_issued: string | null;
  how_long_have_your_known: {
    the_applicant: string | null;
    driverN_a: string | null;
    the_principal_operators: string | null;
    driverN_b: string | null;
  };
  provide_applicants_email_address_if_applicabel: string | null;
  does_your_client_have_other_insurance_with_this_company: {
    type: string | null;
    policyN1: string | null;
    policyN2: string | null;
    if_yes_give_particular: string | null;
  };
  are_ther_any_special_cicumstances_concerning_this_application_which_the_company_should_know: {
    type: string | null;
    if_yes_give_particular: string | null;
  };
  is_this_risk_eligible_for_the_residual_market: {
    type: string | null;
    if_yes_give_particular: string | null;
  }
  was_the_supplementary_market_availability_plan_accessed_to_place_risk: {
    type: string | null;
    if_yes_provide_map_reference_number: string | null;
  }
  broker_agent_name: string | null;
  date: string;
  describe_any_trailer_not_already_listed: string | null;
  commercial_rated_vehicles: string | null;
  commercial_vehicle_section: {
    key: string;
    vehNo: number;
    does_vehicle_weight_exceed_4500_kg: string | null;
    is_operating_radius_greater_than_40km_from_place_vehicle_usually_kept: string | null;
  }[];
  commercial_vehicle_use: {
    key: string ;
    vehNo: number ;
    percentage_of_pleasure_use: string | null;
    delivery: string | null;
    wholesale: string | null;
    retail: string | null;
    other : string ;
    hauling_done_for_others: string | null;
    specify: string | null;
  }[];
  is_there_any_merchandise_or_material_carried: {
    key: string ;
    vehNo: number;
    type: string | null;
    if_yes_describe: string | null;
    if_volatile_toxic_corrosive_radio_active_or_explosive_materials_carried_state_quantities: string | null;
  }[];
  is_there_any_machinery_or_equipment_mounted_on_or_attached_to_vehicle: {
    key: string ;
    vehNo: number;
    type: string | null;
    if_yes_describe_and_name_owner_if_not_owned_by_applicant: string | null;
  }[];
  total_remarks: string | null;
}

export const initialAutomobileAlbertaApplicationState: IAutomobileAlbertaApplicationState =
  {
    application: {
      customer_policy_id: 0,
      incremental_number: null,
      new_policy: false,
      renewal_policy: false,
      replacing_policy_no: null,
      company_bill: false,
      broker_or_agent_bill: false,
      other_specify: null,
      language_preferred: null,
      policy_no_assigned: null,
      insurance_company: null,
      broke_or_agent: null,
      insurance_name: null,
      insurance_address: null,
      insurance_unit_or_suite: null,
      insurance_city: null,
      insurance_state: null,
      insurance_country: null,
      insurance_zip: null,
      broker_agent: null,
      broker_agent_address: null,
      broker_agent_unit_or_suite: null,
      broker_agent_city: null,
      broker_agent_state: null,
      broker_agent_country: null,
      broker_agent_zip: null,
      broker_code: null,
      certificate_holder_name: null,
      certificate_holder_full_address: null,
      certificate_holder_email: null,
      certificate_holder_phone: null,
      lessor_name: null,
      lessor_full_address: null,
      lessor_email: null,
      lessor_phone: null,
      policy_period_effective_date: null,
      policy_period_effective_time: moment().format(),
      policy_period_expiry_date: null,
      policy_period_expiry_time: moment()
        .startOf("day")
        .add(1, "minutes")
        .format(),
      described_automobiles_part_one: [
        {
          key: getUniqueId(),
          vehNo: 1,
          bodyType: null,
          makeTradeName: null,
          model: null,
          modelYear: null,
          purchase_price_including_accessories_equipment: null,
          vin_serial_No: null,
        },
        {
          key: getUniqueId(),
          vehNo: 2,
          bodyType: null,
          makeTradeName: null,
          model: null,
          modelYear: null,
          purchase_price_including_accessories_equipment: null,
          vin_serial_No: null,
        },
        {
          key: getUniqueId(),
          vehNo: 3,
          bodyType: null,
          makeTradeName: null,
          model: null,
          modelYear: null,
          purchase_price_including_accessories_equipment: null,
          vin_serial_No: null,
        },
        {
          key: getUniqueId(),
          vehNo: 4,
          bodyType: null,
          makeTradeName: null,
          model: null,
          modelYear: null,
          purchase_price_including_accessories_equipment: null,
          vin_serial_No: null,
        },
      ],
      described_automobiles_part_two: [
        {
          key: getUniqueId(),
          VehNo: 1,
          postal_code: null,
          postal_address: null,
          name: null,
          type: null,
        },
        {
          key: getUniqueId(),
          VehNo: 2,
          postal_code: null,
          postal_address: null,
          name: null,
          type: null,
        },
        {
          key: getUniqueId(),
          VehNo: 3,
          postal_code: null,
          postal_address: null,
          name: null,
          type: null,
        },
        {
          key: getUniqueId(),
          VehNo: 4,
          postal_code: null,
          postal_address: null,
          name: null,
          type: null,
        },
      ],
      described_automobiles_part_three: [
        {
          key: getUniqueId(),
          vehNo: 1,
          gvw_kg: null,
          winter_tires: null,
          list_price_new: null,
          vehicle_code: null,
          terr: null,
          loc: null,
          class: null,
          drtpl: null,
          dr_coll_ap: null,
          dcpd: null,
          coll: null,
          comp: null,
        },
        {
          key: getUniqueId(),
          vehNo: 2,
          gvw_kg: null,
          winter_tires: null,
          list_price_new: null,
          vehicle_code: null,
          terr: null,
          loc: null,
          class: null,
          drtpl: null,
          dr_coll_ap: null,
          dcpd: null,
          coll: null,
          comp: null,
        },
        {
          key: getUniqueId(),
          vehNo: 3,
          gvw_kg: null,
          winter_tires: null,
          list_price_new: null,
          vehicle_code: null,
          terr: null,
          loc: null,
          class: null,
          drtpl: null,
          dr_coll_ap: null,
          dcpd: null,
          coll: null,
          comp: null,
        },
        {
          key: getUniqueId(),
          vehNo: 4,
          gvw_kg: null,
          winter_tires: null,
          list_price_new: null,
          vehicle_code: null,
          terr: null,
          loc: null,
          class: null,
          drtpl: null,
          dr_coll_ap: null,
          dcpd: null,
          coll: null,
          comp: null,
        },
      ],
      described_automobiles_part_threeA: [
        {
          key: getUniqueId(),
          occasional_driver_vehicle_no: null,
          list_price_new: null,
          vehicle_code: null,
          terr: null,
          loc: null,
          class: null,
          drtpl: null,
          dr_coll_ap: null,
          dcpd: null,
          coll: null,
          comp: null,
        },
        {
          key: getUniqueId(),
          occasional_driver_vehicle_no: null,
          list_price_new: null,
          vehicle_code: null,
          terr: null,
          loc: null,
          class: null,
          drtpl: null,
          dr_coll_ap: null,
          dcpd: null,
          coll: null,
          comp: null,
        },
      ],
      described_automobiles_part_four: [
        {
          key: getUniqueId(),
          vehNo: 1,
          third_party_liability: null,
          direct_comp_for_property_damage: null,
          accident_benefits: "As stated in Section B of the Policy",
          loss_of_Damage_ap: null,
          loss_of_Damage_coll: null,
          loss_of_Damage_comp: null,
          loss_of_Damage_sp: null,
          veh_no: null,
          endorement_no: null,
        },
        {
          key: getUniqueId(),
          vehNo: 2,
          third_party_liability: null,
          direct_comp_for_property_damage: null,
          accident_benefits: "As stated in Section B of the Policy",
          loss_of_Damage_ap: null,
          loss_of_Damage_coll: null,
          loss_of_Damage_comp: null,
          loss_of_Damage_sp: null,
          veh_no: null,
          endorement_no: null,
        },
        {
          key: getUniqueId(),
          vehNo: 3,
          third_party_liability: null,
          direct_comp_for_property_damage: null,
          accident_benefits: "As stated in Section B of the Policy",
          loss_of_Damage_ap: null,
          loss_of_Damage_coll: null,
          loss_of_Damage_comp: null,
          loss_of_Damage_sp: null,
          veh_no: null,
          endorement_no: null,
        },
        {
          key: getUniqueId(),
          vehNo: 4,
          third_party_liability: null,
          direct_comp_for_property_damage: null,
          accident_benefits: "As stated in Section B of the Policy",
          loss_of_Damage_ap: null,
          loss_of_Damage_coll: null,
          loss_of_Damage_comp: null,
          loss_of_Damage_sp: null,
          veh_no: null,
          endorement_no: null,
        },
      ],
      described_automobiles_part_fourA: [
        {
          key: getUniqueId(),
          vehNo: 1,
          third_party_liability_pd: null,
          third_party_liability_bi: null,
          third_party_liability_grid: null,
          benefits_of_accident: null,
          loss_of_Damage_ap: null,
          loss_of_Damage_coll: null,
          loss_of_Damage_comp: null,
          loss_of_Damage_sp: null,
          sef_premium: null,
          vehicle_premium: null,
        },
        {
          key: getUniqueId(),
          vehNo: 2,
          third_party_liability_pd: null,
          third_party_liability_bi: null,
          third_party_liability_grid: null,
          benefits_of_accident: null,
          loss_of_Damage_ap: null,
          loss_of_Damage_coll: null,
          loss_of_Damage_comp: null,
          loss_of_Damage_sp: null,
          sef_premium: null,
          vehicle_premium: null,
        },
        {
          key: getUniqueId(),
          vehNo: 3,
          third_party_liability_pd: null,
          third_party_liability_bi: null,
          third_party_liability_grid: null,
          benefits_of_accident: null,
          loss_of_Damage_ap: null,
          loss_of_Damage_coll: null,
          loss_of_Damage_comp: null,
          loss_of_Damage_sp: null,
          sef_premium: null,
          vehicle_premium: null,
        },
        {
          key: getUniqueId(),
          vehNo: 4,
          third_party_liability_pd: null,
          third_party_liability_bi: null,
          third_party_liability_grid: null,
          benefits_of_accident: null,
          loss_of_Damage_ap: null,
          loss_of_Damage_coll: null,
          loss_of_Damage_comp: null,
          loss_of_Damage_sp: null,
          sef_premium: null,
          vehicle_premium: null,
        },
      ],
      minimum_retained_premium: null,
      total_estimated_policy_premium: null,
      driver_information_part_one: [
        {
          key: getUniqueId(),
          driverNumber: 1,
          nameofDriver: null,
          driverLicenseNumber: null,
          dateOfBirth: "",
        },
        {
          key: getUniqueId(),
          driverNumber: 2,
          nameofDriver: null,
          driverLicenseNumber: null,
          dateOfBirth: "",
        },
        {
          key: getUniqueId(),
          driverNumber: 3,
          nameofDriver: null,
          driverLicenseNumber: null,
          dateOfBirth: "",
        },
        {
          key: getUniqueId(),
          driverNumber: 4,
          nameofDriver: null,
          driverLicenseNumber: null,
          dateOfBirth: "",
        },
      ],
      driver_information_part_two: [
        {
          key: getUniqueId(),
          driverNumber: 1,
          date_lic: "",
          lic_class: null,
          gridStep: null,
          driver_veh1: null,
          driver_veh2: null,
          driver_veh3: null,
          driver_veh4: null,
          driver_occupation: null,
          driver_training_attach_dtc_cert: null,
        },
        {
          key: getUniqueId(),
          driverNumber: 2,
          date_lic: "",
          lic_class: null,
          gridStep: null,
          driver_veh1: null,
          driver_veh2: null,
          driver_veh3: null,
          driver_veh4: null,
          driver_occupation: null,
          driver_training_attach_dtc_cert: null,
        },
        {
          key: getUniqueId(),
          driverNumber: 3,
          date_lic: "",
          lic_class: null,
          gridStep: null,
          driver_veh1: null,
          driver_veh2: null,
          driver_veh3: null,
          driver_veh4: null,
          driver_occupation: null,
          driver_training_attach_dtc_cert: null,
        },
        {
          key: getUniqueId(),
          driverNumber: 4,
          date_lic: "",
          lic_class: null,
          gridStep: null,
          driver_veh1: null,
          driver_veh2: null,
          driver_veh3: null,
          driver_veh4: null,
          driver_occupation: null,
          driver_training_attach_dtc_cert: null,
        },
      ],
      all_convictions: [
        {
          key: getUniqueId(),
          driverNumber: "",
          date: "",
          description: null,
        },
        {
          key: getUniqueId(),
          driverNumber: "",
          date: "",
          description: null,
        },
        {
          key: getUniqueId(),
          driverNumber: "",
          date: "",
          description: null,
        },
        {
          key: getUniqueId(),
          driverNumber: "",
          date: "",
          description: null,
        },
      ],
      all_accidents_or_claims: [
        {
          key: getUniqueId(),
          vehNo: "",
          driverNumber: "",
          date: "",
          typeOfClaim: null,
          amount_paid_or_estimate: null,
          claim_amount_repaid_to_insurer: null,
          description: null,
        },
        {
          key: getUniqueId(),
          vehNo: "",
          driverNumber: "",
          date: "",
          typeOfClaim: null,
          amount_paid_or_estimate: null,
          claim_amount_repaid_to_insurer: null,
          description: null,
        },
        {
          key: getUniqueId(),
          vehNo: "",
          driverNumber: "",
          date: "",
          typeOfClaim: null,
          amount_paid_or_estimate: null,
          claim_amount_repaid_to_insurer: null,
          description: null,
        },
        {
          key: getUniqueId(),
          vehNo: "",
          driverNumber: "",
          date: "",
          typeOfClaim: null,
          amount_paid_or_estimate: null,
          claim_amount_repaid_to_insurer: null,
          description: null,
        },
      ],
      has_any_drivers: null,
      has_any_insurer: {
        insurer: "",
        reason: "",
        policy_no: "",
      },
      details_of_applicants_most_recent_automobile_insurance: {
        insurer: "",
        policy_no: "",
        expiry_date: moment().format("YYYY-MM-DD"),
      },
      does_applicant_owe_any_money: null,
      all_accidents_or_claims_tabel_two: [
        {
          key: getUniqueId(),
          VehNo: 1,
          state_the_usual_distance_driven_annually: null,
          the_vehicle_used_to_commute: null,
          distance_one_way: null,
          state_the_usual_percentage_of_annual_kilometer: null,
          is_the_vehicle_used_outside_of_canada: null,
          no_of_months: null,
        },
        {
          key: getUniqueId(),
          VehNo: 2,
          state_the_usual_distance_driven_annually: null,
          the_vehicle_used_to_commute: null,
          distance_one_way: null,
          state_the_usual_percentage_of_annual_kilometer: null,
          is_the_vehicle_used_outside_of_canada: null,
          no_of_months: null,
        },
        {
          key: getUniqueId(),
          VehNo: 3,
          state_the_usual_distance_driven_annually: null,
          the_vehicle_used_to_commute: null,
          distance_one_way: null,
          state_the_usual_percentage_of_annual_kilometer: null,
          is_the_vehicle_used_outside_of_canada: null,
          no_of_months: null,
        },
        {
          key: getUniqueId(),
          VehNo: 4,
          state_the_usual_distance_driven_annually: null,
          the_vehicle_used_to_commute: null,
          distance_one_way: null,
          state_the_usual_percentage_of_annual_kilometer: null,
          is_the_vehicle_used_outside_of_canada: null,
          no_of_months: null,
        },
      ],
      are_there_any_modification: null,
      all_accidents_or_claims_tabel_three: [
        {
          key: getUniqueId(),
          vehNo: 1,
          will_the_automobile_be_rented_leased_etc: null,
          will_the_automobile_be_used_for_the_transportation_of_goods: null,
        },
        {
          key: getUniqueId(),
          vehNo: 2,
          will_the_automobile_be_rented_leased_etc: null,
          will_the_automobile_be_used_for_the_transportation_of_goods: null,
        },
        {
          key: getUniqueId(),
          vehNo: 3,
          will_the_automobile_be_rented_leased_etc: null,
          will_the_automobile_be_used_for_the_transportation_of_goods: null,
        },
        {
          key: getUniqueId(),
          vehNo: 4,
          will_the_automobile_be_rented_leased_etc: null,
          will_the_automobile_be_used_for_the_transportation_of_goods: null,
        },
      ],
      unless_otherwise_stated: {
        the_actual_owner: null,
        the_registered_owner: null,
      },
      remarks: null,
      endorsements_vehicle1: [
        {
          key: getUniqueId(),
          sef_n: null,
          description: null,
          limit1: null,
          limit2: null,
          deductible: null,
          percentage: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          sef_n: null,
          description: null,
          limit1: null,
          limit2: null,
          deductible: null,
          percentage: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          sef_n: null,
          description: null,
          limit1: null,
          limit2: null,
          deductible: null,
          percentage: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          sef_n: null,
          description: null,
          limit1: null,
          limit2: null,
          deductible: null,
          percentage: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          sef_n: null,
          description: null,
          limit1: null,
          limit2: null,
          deductible: null,
          percentage: null,
          premium: null,
        },
      ],
      endorsements_vehicle2: [
        {
          key: getUniqueId(),
          sef_n: null,
          description: null,
          limit1: null,
          limit2: null,
          deductible: null,
          percentage: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          sef_n: null,
          description: null,
          limit1: null,
          limit2: null,
          deductible: null,
          percentage: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          sef_n: null,
          description: null,
          limit1: null,
          limit2: null,
          deductible: null,
          percentage: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          sef_n: null,
          description: null,
          limit1: null,
          limit2: null,
          deductible: null,
          percentage: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          sef_n: null,
          description: null,
          limit1: null,
          limit2: null,
          deductible: null,
          percentage: null,
          premium: null,
        },
      ],
      endorsements_vehicle3: [
        {
          key: getUniqueId(),
          sef_n: null,
          description: null,
          limit1: null,
          limit2: null,
          deductible: null,
          percentage: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          sef_n: null,
          description: null,
          limit1: null,
          limit2: null,
          deductible: null,
          percentage: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          sef_n: null,
          description: null,
          limit1: null,
          limit2: null,
          deductible: null,
          percentage: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          sef_n: null,
          description: null,
          limit1: null,
          limit2: null,
          deductible: null,
          percentage: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          sef_n: null,
          description: null,
          limit1: null,
          limit2: null,
          deductible: null,
          percentage: null,
          premium: null,
        },
      ],
      endorsements_vehicle4: [
        {
          key: getUniqueId(),
          sef_n: null,
          description: null,
          limit1: null,
          limit2: null,
          deductible: null,
          percentage: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          sef_n: null,
          description: null,
          limit1: null,
          limit2: null,
          deductible: null,
          percentage: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          sef_n: null,
          description: null,
          limit1: null,
          limit2: null,
          deductible: null,
          percentage: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          sef_n: null,
          description: null,
          limit1: null,
          limit2: null,
          deductible: null,
          percentage: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          sef_n: null,
          description: null,
          limit1: null,
          limit2: null,
          deductible: null,
          percentage: null,
          premium: null,
        },
      ],
      additional_information_for_drivers_part_one: [
        {
          key: getUniqueId(),
          driverNo: 1,
          driverName: null,
          driver_training_date_completed: "",
          type: null,
          sex: null,
          maritalStatus: null,
          relationship_to_applicant: null,
        },
        {
          key: getUniqueId(),
          driverNo: 2,
          driverName: null,
          driver_training_date_completed: "",
          type: null,
          sex: null,
          maritalStatus: null,
          relationship_to_applicant: null,
        },
        {
          key: getUniqueId(),
          driverNo: 3,
          driverName: null,
          driver_training_date_completed: "",
          type: null,
          sex: null,
          maritalStatus: null,
          relationship_to_applicant: null,
        },
        {
          key: getUniqueId(),
          driverNo: 4,
          driverName: null,
          driver_training_date_completed: "",
          type: null,
          sex: null,
          maritalStatus: null,
          relationship_to_applicant: null,
        },
      ],
      additional_information_for_drivers_part_two: [
        {
          key: getUniqueId(),
          driverNo: 1,
          conviction_date_of_mvr: "",
          conviction_code: null,
          conviction_percentage: null,
          conviction_description: null,
          atFault_code: null,
          atFault_percentage: null,
          atFault_description: null,
          discountApplied_code: null,
          discountApplied_percentage: null,
          discountApplied_description: null,
        },
        {
          key: getUniqueId(),
          driverNo: 2,
          conviction_date_of_mvr: "",
          conviction_code: null,
          conviction_percentage: null,
          conviction_description: null,
          atFault_code: null,
          atFault_percentage: null,
          atFault_description: null,
          discountApplied_code: null,
          discountApplied_percentage: null,
          discountApplied_description: null,
        },
        {
          key: getUniqueId(),
          driverNo: 3,
          conviction_date_of_mvr: "",
          conviction_code: null,
          conviction_percentage: null,
          conviction_description: null,
          atFault_code: null,
          atFault_percentage: null,
          atFault_description: null,
          discountApplied_code: null,
          discountApplied_percentage: null,
          discountApplied_description: null,
        },
        {
          key: getUniqueId(),
          driverNo: 4,
          conviction_date_of_mvr: "",
          conviction_code: null,
          conviction_percentage: null,
          conviction_description: null,
          atFault_code: null,
          atFault_percentage: null,
          atFault_description: null,
          discountApplied_code: null,
          discountApplied_percentage: null,
          discountApplied_description: null,
        },
      ],
      name_and_address_of_employer: [
        {
          key: getUniqueId(),
          driverNO: 1,
          name: null,
          address: null,
          dateHired: "",
        },
        {
          key: getUniqueId(),
          driverNO: 2,
          name: null,
          address: null,
          dateHired: "",
        },
        {
          key: getUniqueId(),
          driverNO: 3,
          name: null,
          address: null,
          dateHired: "",
        },
        {
          key: getUniqueId(),
          driverNO: 4,
          name: null,
          address: null,
          dateHired: "",
        },
      ],
      list_all_residents_of_household: [
        {
          key: getUniqueId(),
          driverNo: 1,
          fullName: null,
          birthDate: "",
          driver_licence_no: null,
          own_a_vehicle: null,
          non_licensed_resident: null,
        },
        {
          key: getUniqueId(),
          driverNo: 2,
          fullName: null,
          birthDate: "",
          driver_licence_no: null,
          own_a_vehicle: null,
          non_licensed_resident: null,
        },
        {
          key: getUniqueId(),
          driverNo: 3,
          fullName: null,
          birthDate: "",
          driver_licence_no: null,
          own_a_vehicle: null,
          non_licensed_resident: null,
        },
        {
          key: getUniqueId(),
          driverNo: 4,
          fullName: null,
          birthDate: "",
          driver_licence_no: null,
          own_a_vehicle: null,
          non_licensed_resident: null,
        },
      ],
      isVehicle_used_for_car_pools: [
        {
          key: getUniqueId(),
          vehNo: 1,
          type: null,
          no_of_passengers: null,
          frequency_of_times: null,
          frequency_per: null,
          fuel_if_not_powered_by_gas_diesel_engine: null,
        },
        {
          key: getUniqueId(),
          vehNo: 2,
          type: null,
          no_of_passengers: null,
          frequency_of_times: null,
          frequency_per: null,
          fuel_if_not_powered_by_gas_diesel_engine: null,
        },
        {
          key: getUniqueId(),
          vehNo: 3,
          type: null,
          no_of_passengers: null,
          frequency_of_times: null,
          frequency_per: null,
          fuel_if_not_powered_by_gas_diesel_engine: null,
        },
        {
          key: getUniqueId(),
          vehNo: 4,
          type: null,
          no_of_passengers: null,
          frequency_of_times: null,
          frequency_per: null,
          fuel_if_not_powered_by_gas_diesel_engine: null,
        },
      ],
      isVehicle_used_for_car_pools2: [
        {
          key: getUniqueId(),
          is_there_any_unprepaired_damage_including_damage_to_glass: null,
          describe_and_give_value_for_any_special_equipment_value: null,
          describe_and_give_value_for_any_special_equipment_describe: null,
        },
        {
          key: getUniqueId(),
          is_there_any_unprepaired_damage_including_damage_to_glass: null,
          describe_and_give_value_for_any_special_equipment_value: null,
          describe_and_give_value_for_any_special_equipment_describe: null,
        },
        {
          key: getUniqueId(),
          is_there_any_unprepaired_damage_including_damage_to_glass: null,
          describe_and_give_value_for_any_special_equipment_value: null,
          describe_and_give_value_for_any_special_equipment_describe: null,
        },
        {
          key: getUniqueId(),
          is_there_any_unprepaired_damage_including_damage_to_glass: null,
          describe_and_give_value_for_any_special_equipment_value: null,
          describe_and_give_value_for_any_special_equipment_describe: null,
        },
      ],
      provide_details_of_vehicle_antiTheft_device: [
        {
          key: getUniqueId(),
          vehNo: 1,
          deviceType: null,
          deviceCharacterstics: null,
          prouductCode: null,
        },
        {
          key: getUniqueId(),
          vehNo: 2,
          deviceType: null,
          deviceCharacterstics: null,
          prouductCode: null,
        },
        {
          key: getUniqueId(),
          vehNo: 3,
          deviceType: null,
          deviceCharacterstics: null,
          prouductCode: null,
        },
        {
          key: getUniqueId(),
          vehNo: 4,
          deviceType: null,
          deviceCharacterstics: null,
          prouductCode: null,
        },
      ],
      if_applicant_has_changed_address_within_the_last_three_year: null,
      total_number_of_private_passenger_vehicle_in_household: null,
      additonal_information_remarks: null,
      have_you_bound_this_risk: null,
      is_this_business_new_to_your_office: null,
      motor_vehicle_liability_insurance_card_issued: null,
      how_long_have_your_known: {
        the_applicant: null,
        driverN_a: null,
        driverN_b: null,
        the_principal_operators: null,
      },
      provide_applicants_email_address_if_applicabel: null,
      does_your_client_have_other_insurance_with_this_company: {
        type: null,
        policyN1: null,
        policyN2: null,
        if_yes_give_particular: null,
      },
      are_ther_any_special_cicumstances_concerning_this_application_which_the_company_should_know: {
        type: null,
        if_yes_give_particular: null,
      },
      is_this_risk_eligible_for_the_residual_market: {
        type: null,
        if_yes_give_particular: null,
      },
      was_the_supplementary_market_availability_plan_accessed_to_place_risk: {
        type: null,
        if_yes_provide_map_reference_number: null,
      },
    broker_agent_name: null,
    date: moment().format("YYYY-MM-DD"),
    describe_any_trailer_not_already_listed: null,
    commercial_rated_vehicles: null,
    commercial_vehicle_section: [
      {
        key: getUniqueId(),
        vehNo: 1,
        does_vehicle_weight_exceed_4500_kg: null,
        is_operating_radius_greater_than_40km_from_place_vehicle_usually_kept: null,
      },
      {
        key: getUniqueId(),
        vehNo: 2,
        does_vehicle_weight_exceed_4500_kg: null,
        is_operating_radius_greater_than_40km_from_place_vehicle_usually_kept: null,
      },
      {
        key: getUniqueId(),
        vehNo: 3,
        does_vehicle_weight_exceed_4500_kg: null,
        is_operating_radius_greater_than_40km_from_place_vehicle_usually_kept: null,
      },
      {
        key: getUniqueId(),
        vehNo: 4,
        does_vehicle_weight_exceed_4500_kg: null,
        is_operating_radius_greater_than_40km_from_place_vehicle_usually_kept: null,
      },
    ],
    commercial_vehicle_use: [
      {
        key: getUniqueId(),
        vehNo: 1,
        percentage_of_pleasure_use: null,
        delivery: null,
        wholesale: null,
        retail: null,
        other: "Add in Remarks section below",
        hauling_done_for_others: null,
        specify: null,
      },
      {
        key: getUniqueId(),
        vehNo: 2,
        percentage_of_pleasure_use: null,
        delivery: null,
        wholesale: null,
        retail: null,
        other: "Add in Remarks section below",
        hauling_done_for_others: null,
        specify: null,
      },
      {
        key: getUniqueId(),
        vehNo: 3,
        percentage_of_pleasure_use: null,
        delivery: null,
        wholesale: null,
        retail: null,
        other: "Add in Remarks section below",
        hauling_done_for_others: null,
        specify: null,
      },
      {
        key: getUniqueId(),
        vehNo: 4,
        percentage_of_pleasure_use: null,
        delivery: null,
        wholesale: null,
        retail: null,
        other: "Add in Remarks section below",
        hauling_done_for_others: null,
        specify: null,
      },
    ],
    is_there_any_merchandise_or_material_carried: [
      {
        key: getUniqueId(),
        vehNo: 1,
        type: null,
        if_yes_describe: null,
        if_volatile_toxic_corrosive_radio_active_or_explosive_materials_carried_state_quantities: null,
      },
      {
        key: getUniqueId(),
        vehNo: 2,
        type: null,
        if_yes_describe: null,
        if_volatile_toxic_corrosive_radio_active_or_explosive_materials_carried_state_quantities: null,
      },
      {
        key: getUniqueId(),
        vehNo: 3,
        type: null,
        if_yes_describe: null,
        if_volatile_toxic_corrosive_radio_active_or_explosive_materials_carried_state_quantities: null,
      },
      {
        key: getUniqueId(),
        vehNo: 4,
        type: null,
        if_yes_describe: null,
        if_volatile_toxic_corrosive_radio_active_or_explosive_materials_carried_state_quantities: null,
      },
    ],
    is_there_any_machinery_or_equipment_mounted_on_or_attached_to_vehicle: [
      {
        key: getUniqueId(),
        vehNo: 1,
        type: null,
        if_yes_describe_and_name_owner_if_not_owned_by_applicant: null,
      },
      {
        key: getUniqueId(),
        vehNo: 2,
        type: null,
        if_yes_describe_and_name_owner_if_not_owned_by_applicant: null,
      },
      {
        key: getUniqueId(),
        vehNo: 3,
        type: null,
        if_yes_describe_and_name_owner_if_not_owned_by_applicant: null,
      },
      {
        key: getUniqueId(),
        vehNo: 4,
        type: null,
        if_yes_describe_and_name_owner_if_not_owned_by_applicant: null,
      },
    ],
    total_remarks: null,

    },
    loading: LoadState.NotLoaded,
    data: [],
    listLoading: LoadState.NotLoaded,
  };
