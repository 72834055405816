import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { AnyAction } from "redux";
import { IStoreState } from "../../initialStoreState";
import { api } from "../../../api/api";
import { ERROR_MESSAGES } from "../../../constants/enums";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";
import { transformCSIOToHabitationalApp } from "./transformCSIOToHabitationalApp";
import { IDate } from "../../../components/Table/hooks/useDateFilter";
import { ICSIORaw } from "../../csio/csio.types";
import { IHabitationalApplication } from "../habitationalApplication/habitationalApplicationNew.types";


export const FETCH_HABITATIONAL_CSIO_LIST_PROGRESS = "FETCH_HABITATIONAL_CSIO_LIST_PROGRESS";
export const FETCH_HABITATIONAL_CSIO_LIST_SUCCESS = "FETCH_HABITATIONAL_CSIO_LIST_SUCCESS";
export const FETCH_HABITATIONAL_CSIO_LIST_FAILED = "FETCH_HABITATIONAL_CSIO_LIST_FAILED";

export const fetchCSIOHabitationalListProgress = () => action(FETCH_HABITATIONAL_CSIO_LIST_PROGRESS);
export const fetchCSIOHabitationalListSuccess = (list: ICSIORaw[], totalRecords: number) =>
  action(FETCH_HABITATIONAL_CSIO_LIST_SUCCESS, {
    list,
    totalRecords,
  });
export const fetchCSIOHabitationalListFailed = () => action(FETCH_HABITATIONAL_CSIO_LIST_FAILED);

export const fetchCSIOHabitationalListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    searchValue: string,
    searchType: string[],
    date: IDate,
    map: string,
    customerpolicyId: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCSIOHabitationalListProgress());
      
      let finalUrl = `/csio/get-csio-retrieve-messages?column=xml_message&value=HomePolicyAddRs&pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}&customer_policy_id=${customerpolicyId}`;

      if (searchType.length > 0 && searchValue) {
        finalUrl = `/csio/get-csio-retrieve-messages?column=xml_message&value=HomePolicyAddRs&pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&column=${[
          searchType,
        ]}&value=${searchValue}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }
      if(customerpolicyId){
        finalUrl += ""
      }
      if (map && map !== "-1") {
        finalUrl += `&is_map=${map}&column=xml_message&value=HomePolicyAddRs`
      }

      const res = await api.get(finalUrl);
      const data: ICSIORaw[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchCSIOHabitationalListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCSIOHabitationalListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };


  export const fetchCSIOHabitationalPolicyChangeListProgress = () => action(FETCH_HABITATIONAL_CSIO_LIST_PROGRESS);
  export const fetchCSIOHabitationalPolicyChangeListSuccess = (list: ICSIORaw[], totalRecords: number) =>
    action(FETCH_HABITATIONAL_CSIO_LIST_SUCCESS, {
      list,
      totalRecords,
    });
  export const fetchCSIOHabitationalPolicyChangeListFailed = () => action(FETCH_HABITATIONAL_CSIO_LIST_FAILED);
  
  export const fetchCSIOHabitationalPolicyChangeListAsync =
    (
      pageNumber: number,
      rowsInPerPage: number,
      status: string,
      searchValue: string,
      searchType: string[],
      date: IDate,
      map: string,
      customerpolicyId: string
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchCSIOHabitationalPolicyChangeListProgress());
        let finalUrl = `/csio/get-csio-retrieve-messages?column=xml_message&value=HomePolicyModRs&pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;
  
        if (searchType.length > 0 && searchValue) {
          finalUrl = `/csio/get-csio-retrieve-messages?column=xml_message&value=HomePolicyModRs&pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&column=${[
            searchType,
          ]}&value=${searchValue}`;
        }
        if (status !== "-1") {
          finalUrl += "&status=" + status;
        }
        if (map && map !== "-1") {
          finalUrl += `&is_map=${map}`
        }
        if( customerpolicyId){
          finalUrl += `&customer_policy_id=${customerpolicyId}`
        }
  
        const res = await api.get(finalUrl);
        const data: ICSIORaw[] = res.data.data;
        const totalRecords = res.data.totalRecords;
  
        dispatch(fetchCSIOHabitationalPolicyChangeListSuccess(data, totalRecords));
      } catch (err: any) {
        dispatch(fetchCSIOHabitationalPolicyChangeListFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      }
    };


export const FETCH_HABITATIONAL_CSIO_APPLICATION_PROGRESS =
  "FETCH_HABITATIONAL_CSIO_APPLICATION_PROGRESS";
export const FETCH_HABITATIONAL_CSIO_APPLICATION_SUCCESSS =
  "FETCH_HABITATIONAL_CSIO_APPLICATION_SUCCESSS";
export const FETCH_HABITATIONAL_CSIO_APPLICATION_FAILED =
  "FETCH_HABITATIONAL_CSIO_APPLICATION_FAILED";

export const fetchHabitationalCSIOApplicationProgress = () =>
  action(FETCH_HABITATIONAL_CSIO_APPLICATION_PROGRESS);
export const fetchHabitationalCSIOApplicationSuccess = (
  data: IHabitationalApplication
) => action(FETCH_HABITATIONAL_CSIO_APPLICATION_SUCCESSS, { data });
export const fetchHabitationalCSIOApplicationFailed = (error?: string) =>
  action(FETCH_HABITATIONAL_CSIO_APPLICATION_FAILED, {error});

export const fetchHabitationalCSIOApplicationAsync =
  (
    messageGUid: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalCSIOApplicationProgress());
      const res = await api.get(
        `/csio/get-csio-retrieve-messages?message_guid=${messageGUid}`
      );
      console.log("API response:", res);
      
      if (res && res.data && res.data.data) {
        const data = res.data.data;
        console.log("habitationalData:", data);
        
        if (data.length > 0) {
          const { xml_json_message } = data[0];
          console.log("xml_json_message:", xml_json_message);
          
          try {
            const parsedData = JSON.parse(xml_json_message);
            console.log("parsedData:", parsedData);
            const result = transformCSIOToHabitationalApp(parsedData);
            console.log("result:", result);
            dispatch(fetchHabitationalCSIOApplicationSuccess(result));
          } catch (parseError) {
            console.error("JSON parse error:", parseError);
            dispatch(fetchHabitationalCSIOApplicationFailed("Failed to parse JSON message"));
          }
        } else {
          console.log("No record found.");
          dispatch(fetchHabitationalCSIOApplicationFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
        }
      } else {
        console.log("No record found in response data.");
        dispatch(fetchHabitationalCSIOApplicationFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
      }
    } catch (err: any) {
      console.error("Fetch error:", err);
      dispatch(fetchHabitationalCSIOApplicationFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response?.data?.message || "An error occurred",
          displayAs: "snackbar",
        })
      );
    }
  };

export const addHabitationalCSIOApplication =
  (
    data: IHabitationalApplication,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/policy/create-policy-habitational-application", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Application saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchHabitationalCSIOApplicationSuccess(data));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };



export const CLEAR_HABITATIONAL_CSIO_APPLICATION_STATE =
  "CLEAR_HABITATIONAL_CSIO_APPLICATION_STATE";

export const clearHabitationalCSIOApplicationState = () =>
  action(CLEAR_HABITATIONAL_CSIO_APPLICATION_STATE);
