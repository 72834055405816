import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import { LoadState } from "../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { fetchAutomobileApplicationListAsync } from "../../../../../../redux/automobileApplication/automobileApplicationActions";
import { IAutomobileApplication } from "../../../../../../redux/automobileApplication/automobileApplication.types";

export const AutomobileApplicationList = () => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerpolicyId = data1[1];

  const navigate = useNavigate();
  const { data, listLoading: loading } = useSelector(
    (storeState: IStoreState) => storeState.automobile.appication
  );

  console.log("data", data);

  const dispatch = useDispatchWrapper();

  React.useEffect(() => {
    dispatch(fetchAutomobileApplicationListAsync(customerpolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerpolicyId]);

  const automobileApplicationTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    `/automobile-policy/${customerId}/automobile-application-tab/${customerpolicyId}/application`
                  );
                }}
              >
                Add Application
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IAutomobileApplication) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/automobile-policy/${customerId}/automobile-application-tab/${customerpolicyId}/application/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
      },
      {
        key: "insurance.insurance_name",
        headerName: "Insurance Company",
        fieldName: "insurance.insurance_name",
        exportCellWidth: 25,
        renderType: RenderType.TEXT_DARK,
        onRowCellValueRender: (value, row: IAutomobileApplication) => {
          return row.insurance.insurance_name || "";
        },
      },
      {
        key: "name",
        headerName: "Applicant Name",
        fieldName: "name",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 20,
        onRowCellRender: (value, row: IAutomobileApplication) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/automobile-policy/${customerId}/automobile-application-tab/${customerpolicyId}/application/${row.incremental_number}`
                );
              }}
            >
              {row.application_details?.name}
            </Link>
          );
        },
      },
      {
        key: "broker.broker_code",
        headerName: "Broker Agent Code",
        fieldName: "broker.broker_code",
        exportCellWidth: 25,
        renderType: RenderType.CHIP_WARNING,

        onRowCellValueRender: (value, row: IAutomobileApplication) => {
          return row.broker?.broker_code || "";
        },
      },

      {
        key: "payment_reporter_of_broker.interest_amount",
        headerName: "Interest",
        fieldName: "payment_reporter_of_broker.interest_amount",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "payment_reporter_of_broker.type_of_payment_plan",
        headerName: "Type of Payment Plan",
        fieldName: "payment_reporter_of_broker.type_of_payment_plan",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },

      {
        key: "payment_reporter_of_broker.amount_due",
        headerName: "Amount Due",
        fieldName: "payment_reporter_of_broker.amount_due",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      
    ],
    items: data,
  };

  return (
    <Box sx={{ mt: 2 }}>
      <DataTable {...automobileApplicationTableProps} />
    </Box>
  );
};
