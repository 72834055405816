/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useFormik } from "formik";
import { Box, Grid } from "@mui/material";
import { useCommAutoCustomerBasicDetails, useDispatchWrapper, useFetchPolicies } from "../../../../../../hooks";

import { showMessage } from "../../../../../../redux/messages/messagesActions";
import { Dialog } from "../../../../../../components/Dialogs/Dialog";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { CustomCheckBoxWithLabel } from "../../../../../../components/formsComponents";
import { api } from "../../../../../../api/api";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { ICommAutoPolicyMarketingBinderPdf } from "../../../../../../redux/commAutoPolicy/commAutoMarketingHistory/commAutoMarketingHistory.types";
import { commAutoPolicyPreviewMarketingAsync } from "../../../../../../redux/commAutoPolicy/commAutoMarketingHistory/commAutoMarketingHistoryActions";

const INITIAL_MARKETING_PREVIEW: ICommAutoPolicyMarketingBinderPdf = {
  isBinderPdf: false,
  pdfOrPreviewFor: "",
  isMarketSubmissionPreview: false,
  isBinderPreview: false,
  customer_policy_id: 0,
  generalInformation: true,
  priorPolicy: true,
  claims: true,
  coverage: true,
  driver: true,
  convictions: true,
  vehicle: true,
  garagePlat: true,
};

export const BinderPreview: React.FC<{
  open: boolean;
  customerPolicyId: number;
  onClose: () => void;
  onComplete: () => void;
}> = (props) => {
  const { open, customerPolicyId, onClose } = props;

  const [loadingPreview, setOpenLoadingPreview] = React.useState(false);

  const dispatch = useDispatchWrapper();
  const [loading, setLoading] = React.useState(false);
  const { policies, getPolicySubtypes } = useFetchPolicies();
  const customerBasicDetail = useCommAutoCustomerBasicDetails(customerPolicyId);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      req_for_quotation: true,
      preview: INITIAL_MARKETING_PREVIEW,

      email_body: "",
      subject: "",
      bcc_email: "",
    },
    validate: (values) => {},
    onSubmit: (values) => {},
  });

  let subType = getPolicySubtypes(
    customerBasicDetail.data.line_of_business || ""
  ).find((item) => {
    return Number(customerBasicDetail.data.sub_type) === item.policy_id
      ? item.name
      : "";
  });

  if (subType?.name === "IRCA/Scheduled") {
    subType = { ...subType, name: "IRCA" };
  } else if (subType?.name === "Blanket Fleet") {
    // subType.name = "Fleet";
    subType = { ...subType, name: "Fleet" };
  }

  const handlePreview = async () => {
    setOpenLoadingPreview(true);
    if (subType) {
      dispatch(
        commAutoPolicyPreviewMarketingAsync(
          {
            ...values.preview,
            customer_policy_id: customerPolicyId,
            isBinderPreview: true,
            pdfOrPreviewFor: subType.name,
            isMarketSubmissionPreview: false,
          },
          (isSuccess, htmlJson) => {
            if (isSuccess && htmlJson) {
              for (const key in htmlJson.data) {
                const newTab = window.open("", "_blank");
                if (newTab) {
                  newTab.document.write(htmlJson.data[key]);
                  newTab.document.close();
                }
              }
            }
            setOpenLoadingPreview(false);
          }
        )
      );
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get(
          `/policy/get-is-record-exist-in-every-commauto-module?customer_policy_id=${customerPolicyId}`
        );
        setValues({
          ...values,
          preview: {
            customer_policy_id: customerPolicyId,
            ...response.data.data,
          },
        });
      } catch (err: any) {
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Dialog
      open={open}
      title="Binder Submission Preview"
      onClose={onClose}
      size="lg"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      actions={[
        {
          type: "button",
          label: loadingPreview ? "Wait..." : "Preview",
          variant: "contained",
          disabled: loadingPreview,
          onClick: handlePreview,
        },
      ]}
    >
      <PageLoader loading={loading}>
        <>
          <Box sx={{ mt: 2 }}>
            <>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <CustomCheckBoxWithLabel
                    label="Request for Quotation"
                    name="req_for_quotation"
                    checked={values.req_for_quotation}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomCheckBoxWithLabel
                    label="General Information"
                    name="preview.generalInformation"
                    checked={values.preview.generalInformation}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomCheckBoxWithLabel
                    label="Driver"
                    name="preview.driver"
                    checked={values.preview.driver}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomCheckBoxWithLabel
                    label="Prior Policies"
                    name="preview.priorPolicy"
                    checked={values.preview.priorPolicy}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <CustomCheckBoxWithLabel
                    label="Claims"
                    name="preview.claims"
                    checked={values.preview.claims}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <CustomCheckBoxWithLabel
                    label="Convictions"
                    name="preview.convictions"
                    checked={values.preview.convictions}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomCheckBoxWithLabel
                    label="Vehicle"
                    name="preview.vehicle"
                    checked={values.preview.vehicle}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomCheckBoxWithLabel
                    label="Garage Plate"
                    name="preview.garagePlat"
                    checked={values.preview.garagePlat}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomCheckBoxWithLabel
                    label="Coverage"
                    name="preview.coverage"
                    checked={values.preview.coverage}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </>
          </Box>
        </>
      </PageLoader>
    </Dialog>
  );
};
