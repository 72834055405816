/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Box,
  Divider,
  Grid,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  styled,
  TableCell,
  tableCellClasses,
  TableBody,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IStoreState } from "../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../hooks";
import { clearAccountingInformation } from "../../../redux/Accounting/AccountingInformation/AccountingInformationActions";
import { PageLoader } from "../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../constants/enums";
import { formatDate } from "../../../helpers";
import { CustomLink } from "../../../components/CustomLink/CustomLink";
import {
  clearAccountingBill,
  fetchBillPaymentHistoryAsync,
} from "../../../redux/Accounting/Bill/BillActions";
import { Edit } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderRight: "1px solid " + theme.palette.grey[300] + " !important",
  },
}));

const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const BillPaymentHistoryView: React.FC = () => {
  const { billId } = useParams() as { billId?: number };
  const { bill_history, billHistoryLoading, error } = useSelector(
    (store: IStoreState) => store.accounting.bill
  );
  const navigate = useNavigate();

  const dispatch = useDispatchWrapper();

  React.useEffect(() => {
    if (billId) {
      dispatch(fetchBillPaymentHistoryAsync(billId));
    }
  }, [billId]);

  React.useEffect(() => {
    return () => {
      dispatch(clearAccountingBill());
    };
  }, []);

  return (
    <PageLoader
      loading={billHistoryLoading === LoadState.InProgress}
      error={
        error
          ? {
              message: error,
            }
          : null
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={1.5}></Grid>
        <Grid item xs={12} md={9}>
          <Paper variant="outlined" sx={{ p: 5, mb: 5 }}>
          <Box mb={2}>
              <Button variant="contained" size="small" startIcon={<Edit/>}
                onClick={()=>{
                  navigate("/bill/create/" + bill_history.bill_id)
                }}
              >Edit Bill</Button>
            </Box>
            <Typography
              variant="h1"
              color={"primary.main"}
              fontWeight={600}
              mb={3}
            >
              Bill
            </Typography>
            <Grid container mb={5}>
              <Grid item xs={12}>
                <Typography variant="h4" fontWeight={600} mb={1}>
                  Payment Details
                </Typography>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Date</StyledTableCell>
                        <StyledTableCell>Payment Number</StyledTableCell>
                    
                        <StyledTableCell>Payment Paid</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bill_history.bill_payment_details.length > 0 ? (
                        bill_history.bill_payment_details.map((item, index) => {
                          return (
                            <StyledTableRow key={index}>
                              <StyledTableCell>
                                {formatDate(item.date)}
                              </StyledTableCell>
                              <StyledTableCell>
                                <CustomLink
                                  label={item.create_payment_code || ""}
                                  to={"/bill/payment/create/" + item.create_payment_code}
                                  target="_blank"
                                />
                              </StyledTableCell>
                             
                              <StyledTableCell>
                                {item.payment_paid}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })
                      ) : (
                        <StyledTableRow>
                          <StyledTableCell colSpan={5}>
                            <Typography
                              variant="body1"
                              fontSize={"1rem"}
                              fontWeight={500}
                              textAlign={"center"}
                            >
                              No payments have been made for this bill.
                            </Typography>
                          </StyledTableCell>
                        </StyledTableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid container mb={5}>
              <Grid item xs={12} md={5}>
                <Paper variant="outlined">
                  <Row
                    label="Bill Number"
                    value={bill_history.bill_number}
                    sx={{
                      backgroundColor: "primary.main",
                      color: "#fff",
                    }}
                  />
                  <Divider />
                  <Row label="Bill Date" value={bill_history.bill_date} />
                  <Divider />
                  <Row label="Vendor Type" value={bill_history.vendor_type} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={2}></Grid>
              <Grid item xs={12} md={5}>
                <Paper variant="outlined">
                  <Row
                    label="Bill Status"
                    value={bill_history.status}
                    sx={{
                      backgroundColor: "primary.main",
                      color: "#fff",
                    }}
                  />
                  <Divider />
                  <Row label="Bill Due Date" value={bill_history.due_date} />
                </Paper>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={5}>
                <Typography variant="h4" fontWeight={600} mb={1}>
                  Basic Details
                </Typography>
                <Paper variant="outlined">
                  <Row
                    label="Name (Code)"
                    value={`${bill_history.vendor} (${bill_history.code})`}
                  />

                  <Divider />
                  <Row label="Bill Type" value={bill_history.bill_type} />
                  <Divider />
                  <Row
                    label="Payment Terms"
                    value={bill_history.payment_terms}
                  />
                  <Divider />
                  <Row
                    label="G/L Expense Account"
                    value={bill_history.gl_sales_account_name}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={5}>
                <Typography variant="h4" fontWeight={600} mb={1}>
                  Other Details
                </Typography>
                <Paper variant="outlined">
                  <Row
                    label="Bill Notes"
                    value={bill_history.bill_notes}
                  />
                 
                 
                </Paper>
              </Grid>
            </Grid>

            <Grid container mt={5}>
              <Grid item xs={12}>
                <Typography variant="h4" fontWeight={600} mb={1}>
                  Transactions
                </Typography>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Detail</StyledTableCell>
                        <StyledTableCell>Account</StyledTableCell>
                        <StyledTableCell>Description</StyledTableCell>
                        <StyledTableCell>Tax</StyledTableCell>

                        <StyledTableCell>Total.</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bill_history.bill_details.map((item, index) => {
                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell>
                            
                                {item.detail}
                             </StyledTableCell>
                            <StyledTableCell>{item.amount}</StyledTableCell>
                            <StyledTableCell>{item.description}</StyledTableCell>
                            <StyledTableCell>{item.tax_amount+ ` (${item.tax_percentage}%)`}</StyledTableCell>

                            <StyledTableCell>
                            {item.final_amount}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>



            <Grid container mt={2}>
              <Grid item xs={12} md={7}></Grid>
              <Grid item xs={12} md={5}>
                <Row label="Sub Total" value={bill_history.sub_total_amount} />

                <Divider />
                <Row label="Tax Total" value={bill_history.total_tax_amount} />

               

                <Divider />
                <Row
                  label="Discount Total"
                  value={bill_history.discount_amount}
                />

                <Divider />
                <Row label="Adjustment" value={bill_history.adjustment_amount} />
                <Divider />
               
               
                <Row label="Total Due" value={bill_history.total_due_amount} />
                <Divider />
                <Row label="Amount Paid" value={bill_history.amount_paid} />
               
                <Divider />
                <Row
                  label="Amount Due"
                  value={bill_history.amount_due}
                  sx={{
                    backgroundColor: "primary.main",
                    color: "#fff",
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={1.5}></Grid>
      </Grid>
    </PageLoader>
  );
};

export const Row: React.FC<{ label: string; value: any; sx?: any }> = ({
  label,
  value,
  sx,
}) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
     
      p={3}
      pt={2}
      pb={2}
      sx={sx}
    >
      <Typography variant="body1" fontSize={"1rem"} fontWeight={600} minWidth={'55%'}>
        {label}
      </Typography>
      <Typography variant="body1" textAlign={'end'} sx={{ fontSize: "0.95rem" }} minWidth={'45%'}>
        {value !== null ? value : "N/A"}
      </Typography>
    </Stack>
  );
};
