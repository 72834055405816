import { LoadState } from "../../constants/enums";

export interface IPolicyState {
  list: IPolicy[];
  loading: LoadState;
  data: IPolicy;
  error: string | null;
}
export interface IPolicy {
  customer_policy_id: 0;
  policy_id: 0;
  customer_id: 0;
  status: string;
  insert_ts: string;
  customer_name: string;
  customer_code: string;
  form_of_business: string | null;
  language: string;
  line_of_business: string;
  commission_code: string | null;
  sub_type: string | null;
  effective_date: string | null;
  expiry_date: string;
  transaction_type: string;
  address: string | null;
  policy_status: string;
  date_cancelled: string | null;
  cancellation_reason: string | null;
  cancellation_type: string | null;
  program: string;
  type_of_policy: string;
  quote_number: string;
  policy_number: string;
  effective_time: string;
  send_invoices_renewal_and_receivacble_letter_via_emails: string | null;
  send_statements_via_email: string | null;
  send_eDocs_via_email: string | null;
  prior_line_of_business: string;
  policy_expiry_date: string;
  current_insurer: string | null;
  expiring_premium: string | null;
  current_status: string | null;
  non_payment_code: string | null;
  non_payment_date: string;
  insurer: string | null;
  amount_owing: string | null;
  policy_admin_insurer: string | null;
  broker_code: string | null;
  comission_code: string | null;
  policy_fee: string | null;
  house_percentage: string | null;
  branch_code: string | null;
  producer_one: string | null;
  producer_two: string | null;
  producer_two_code: string | null;
  producer_three: string | null;
  producer_three_code: string | null;
  marketer_code: string | null;
  producer_1: string | null;
  producer_1_percentage: string | null;
  producer_2: string | null;
  producer_2_percentage: string | null;
  producer_3: string | null;
  producer_3_percentage: string | null;
  marketer: string | null;
  csr: string | null;
  billing_account_number: string | null;
  claim_number: string;
  date_of_loss: string;
  location: string;
  amount_paid_dollar: string;
  loss_details: string | null;
  ours: string | null;
  policy_premium: string;
  agency_bill_or_direct_bill: string | null;
  payment_plan: string | null;
  premium_finance_contract_number: string | null;
  financed_by: string | null;
  default_tax_region: string | null;
  tax_2: string | null;
  tax_1: string;
  tax_exempt: string | null;
  reason: string | null;
  policy_type: string | null;
  policy_sub_type: string | null;
  producer_one_code: string | null;
  create_ts: string | null;
  csr_code: string | null;
  advisor_one: string | null;
}

export const initialPolicyState: IPolicyState = {
  list: [],
  loading: LoadState.NotLoaded,
  error: null,
  data: {
    customer_policy_id: 0,
    policy_id: 0,
    customer_id: 0,
    status: "",
    insert_ts: "",
    customer_name: "",
    customer_code: "",
    form_of_business: "",
    language: "",
    line_of_business: "",
    commission_code: "",
    sub_type: "",
    effective_date: "",
    expiry_date: "",
    transaction_type: "",
    address: "",
    policy_status: "",
    date_cancelled: "",
    cancellation_reason: "",
    cancellation_type: "",
    program: "",
    type_of_policy: "",
    quote_number: "",
    policy_number: "",
    effective_time: "",
    send_invoices_renewal_and_receivacble_letter_via_emails: "",
    send_statements_via_email: "",
    send_eDocs_via_email: "",
    prior_line_of_business: "",
    policy_expiry_date: "",
    current_insurer: "",
    expiring_premium: "",
    current_status: "",
    non_payment_code: "",
    non_payment_date: "",
    insurer: "",
    amount_owing: "",
    policy_admin_insurer: "",
    broker_code: "",
    comission_code: "",
    policy_fee: "",
    house_percentage: "",
    branch_code: "",
    producer_one: "",
    producer_two: "",
    producer_two_code: "",
    producer_three: "",
    producer_three_code: "",
    marketer_code: "",
    producer_1: "",
    producer_1_percentage: "",
    producer_2: "",
    producer_2_percentage: "",
    producer_3: "",
    producer_3_percentage: "",
    marketer: "",
    csr: "",
    billing_account_number: "",
    claim_number: "",
    date_of_loss: "",
    location: "",
    amount_paid_dollar: "",
    loss_details: "",
    ours: "",
    policy_premium: "",
    agency_bill_or_direct_bill: "",
    payment_plan: "",
    premium_finance_contract_number: "",
    financed_by: "",
    default_tax_region: "",
    tax_2: "",
    tax_1: "",
    tax_exempt: "",
    reason: "",
    policy_type: "",
    policy_sub_type: "",
    producer_one_code: "",
    create_ts: "",
    csr_code: "",
    advisor_one: "",
  },
};
