/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { StandadCard } from "../../../../../components/Cards";
import {
  AccountCircle,
  AdminPanelSettings,
  Receipt,
  StickyNote2,
} from "@mui/icons-material";
import { TabPanel } from "../../../../../components/Tabs/TabPanel";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../constants/enums";
import { useDispatchWrapper, useQueryParms } from "../../../../../hooks";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useBreadcrumbContext } from "../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { LabelCard } from "../../../../../components/LabelCard/LabelCard";
import { fetchHabitationalCustomerInfoAsync } from "../../../../../redux/habitationalPolicy/habitationalBasicDetails/habitationalBasicDetailsActions";
import { HabitationalRiskLocationList } from "./HabitationalRiskLocation/HabitationalRiskLocationList";
import { HabitationalAdditionalList } from "./AdditionalInterest/AdditionalInterestList";
import { InterestedPartyList } from "./InterestedParties/InterestedPartiesList";

export const RiskLocationRoot: React.FC = () => {
  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerPolicyId = data[1];
  const { addBreadcrumb } = useBreadcrumbContext();
  const { customerInfo } = useSelector(
    (storeState: IStoreState) => storeState.habitational.basicDetails
  );
  const navigate = useNavigate();

  const dispatch = useDispatchWrapper();

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchHabitationalCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  React.useEffect(() => {
    addBreadcrumb({
      title: "",
      items: [
        {
          to: "/dashboard",
          title: "dashboard",
        },
        {
          to: "/view-customers",
          title: "customers",
        },
        {
          to: "/customer-overview/" + customerId,
          title: "customer-overview",
        },
        // {
        //   title: selectedTab === 0 ? 'underwriting' : selectedTab === 1 ? 'risk-location' : 'coverage',
        // },
      ],
    });
  }, []);

  return (
    <PageLoader
      loading={customerInfo.loading === LoadState.InProgress}
      error={customerInfo.error ? { message: customerInfo.error } : null}
    >
      <StandadCard>
        <LabelCard heading="Risk Locations" />
        <HabitationalRiskLocationList
          customerId={customerId}
          customerPolicyId={customerPolicyId}
        />

        <LabelCard heading="Intrested Parties" />
        <InterestedPartyList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
      </StandadCard>
    </PageLoader>
  );
};
