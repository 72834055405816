import moment, { Moment } from "moment";

export const datesDiffInYearMonth = (date1: Moment, date2: Moment) => {
  const year = date2.diff(date1, "years", true);
  return Math.floor(year);
};

export const datesDiffInDays = (value: string | null): string => {
  if(value){
    const date1 = moment(value);
    const today = new Date();
    const todayDate = moment(today);
    const days = todayDate.diff(date1, "days", true);
    const diffDate = 30 - Math.floor(days);
    return diffDate.toString();
  }
  else return "";
};
