import produce from "immer";
import {
  ADD_CSIO_COMPANY,
  ADD_CSIO_COMPANY_ID,
  ADD_CSIO_COMPANY_PROGESS,
  CLEAR_CSIO_COMPANY_STATE,
  CSIO_COMPANY_ERROR,
  FETCH_CSIO_COMPANY_PROGRESS,
} from "../constants";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { CSIOCronActions } from ".";
import { initialCSIOCronState } from "./csioCron.types";
import { CLEAR_CSIO_CRON_STATE, FETCH_CSIO_CRON_LIST_FAILURE, FETCH_CSIO_CRON_LIST_PROGRESS, FETCH_CSIO_CRON_LIST_SUCCESS } from "./csioCronActions";

export const csioCronReducer = (
  state: IStoreState["csioCron"] = initialCSIOCronState,
  action: CSIOCronActions
) => {
  switch (action.type) {
    case FETCH_CSIO_CRON_LIST_PROGRESS: {
        const newState = produce(state, (draftState) => {
          draftState.loading = LoadState.InProgress;
        });
        return newState;
      }
  
      case FETCH_CSIO_CRON_LIST_SUCCESS: {
        const { data } = action.payload;
        const newState = produce(state, (draftState) => {
          draftState.loading = LoadState.Loaded;
          draftState.list = data;
        });
        return newState;
      }
  
      case FETCH_CSIO_CRON_LIST_FAILURE: {
        const newState = produce(state, (draftState) => {
          draftState.loading = LoadState.Failed;
        });
        return newState;
      }

      case CLEAR_CSIO_CRON_STATE: {
        return initialCSIOCronState;
      }

    default: {
      return state;
    }
  }
};
