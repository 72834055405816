/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useDispatchWrapper } from "../../../hooks";
import { useFormik } from "formik";

import { sendEmail } from "../../../redux/common/commonActions";
import { Button, Grid, SelectChangeEvent, Typography } from "@mui/material";
import { IMail } from "../../../redux/common/common.types";
import { api } from "../../../api/api";
import {
  fetchTemplateListAllTempsAsync,
  fetchTemplateListAsync,
} from "../../../redux/templates/templateActions";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../redux/initialStoreState";
import { ICreateTemplate } from "../../../redux/templates/templates.types";
import { useAuth } from "../../../contexts/AuthProvider/AuthProvider";
import { IG4EmailDialogProps } from "./G4EmailDialog.types";
import { sendMailToRecipients } from "../../../redux/g4SignDocuments/g4SignDocumentsAction";
import { RightPanel } from "../../../components/RightPanel";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../components/formsComponents";
import { QuillEditor } from "../../../components/QuillEditor/QuillEditor";
import { useNavigate } from "react-router-dom";
import { useRoles } from "../../../security/RolesProvider/RolesProvider";

const INITIAL_MAIL_PAYLOAD = {
  subject: "",
  body: "",
  templateName: null,
  objectVariables: {},
};

export const G4EmailDialog: React.FC<IG4EmailDialogProps> = (props) => {
  const { open, onClose, documentCode } = props;
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();
  const [selectedTemplate, setSelectedTemplate] = React.useState<number | null>(null);
  const templates = useSelector(
    (storeState: IStoreState) => storeState.templates.list
  );
  const { firstName, lastName } = useRoles();
  const navigate = useNavigate();
  const { values, handleChange, handleSubmit, setFieldValue, setValues } =
    useFormik({
      initialValues: {
        ...INITIAL_MAIL_PAYLOAD,
        subject: `Signature Requested by ${firstName} ${lastName ? lastName:''}`
      },
      onSubmit: async (values) => {
        setLoading(true);
        dispatch(
          sendMailToRecipients( values, (isSucess) => {
            if (isSucess) {
              navigate("/g4sign/documents");
              onClose();
            }
            setLoading(false);
          })
        );
      },
    });

  const handleTemplateChange = (e: SelectChangeEvent<unknown>) => {
    const templateId = e.target.value as number;
    const template = templates.find((x) => x.templates_id === templateId);
    setSelectedTemplate(templateId)
    if (template) {
      setValues({
        ...values,
        body: template.body || "",
      });
    }
  };

  React.useEffect(() => {
    dispatch(fetchTemplateListAllTempsAsync());
  }, []);

  // React.useEffect(() => {
  //   const fetchTemplate = async (temp: ICreateTemplate) => {
  //     try {
  //       const res = await api.get(
  //         `/templates/get-templates?templates_id=${temp.templates_id}`
  //       );
  //       const list: ICreateTemplate[] = res.data.data;
  //       if (list.length > 0) {
  //         setFieldValue("body", list[0].body);
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   if (selectedTemplate) {
  //     const template = templates.find(
  //       (x) => x.template_name === selectedTemplate
  //     );
  //     if (template) {
  //       fetchTemplate(template);
  //     }
  //   }
  // }, [selectedTemplate]);

  return (

<RightPanel
      open={open}
      heading="Send the attachment link in the email"
      onClose={onClose}
      isWrappedWithForm
      onFormSubmit={handleSubmit}
      actionButtons={() => {
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                fullWidth
              >
                Send
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button variant="contained" fullWidth onClick={onClose}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        );
      }}
    >

      <Grid container>
        {/* <Grid item xs={12} md={12}>
          <CustomFormLabel>To</CustomFormLabel>

          <CustomTextField
            fullWidth
            name="emails[0]"
            value={values.emails[0]}
            onChange={handleChange}
          />
        </Grid> */}
        {/* <Grid item xs={12} md={12}>
          <CustomFormLabel>Reply</CustomFormLabel>
          <CustomTextField
            name="reply_to.email"
            fullWidth
            value={values.reply_to ? values.reply_to.email : ''}
            onChange={handleEmailReplyTo}
          />
        </Grid> */}
        <Grid item xs={12} md={12}>
          <CustomFormLabel>Subject</CustomFormLabel>
          <CustomTextField
            name="subject"
            fullWidth
            value={values.subject}
            onChange={handleChange}
          />
        </Grid>
        {/* <Grid item xs={12} md={12}>
          <CustomFormLabel>Template</CustomFormLabel>
          <ControlledCustomSelect
            fullWidth
            value={selectedTemplate}
            name="sub_mobules"
            onChange={handleTemplateChange}
            placeholder="Choose Template"
            displayEmpty
            options={templates.map((item) => {
              return {
                label: item.template_name,
                value: item.templates_id as number,
              };
            })}
          ></ControlledCustomSelect>
        </Grid>

        <Grid item xs={12} md={12}>
          <CustomFormLabel>Message</CustomFormLabel>

          <QuillEditor
            value={values.body}
            onChange={(html) => setFieldValue("body", html)}
          />
          <Typography marginTop={2} sx={{color: "error.main"}}>Note: The link will automatically be attached to the email. There is no need to add it in the editor.</Typography>
        </Grid> */}
      </Grid>

    </RightPanel>

  );
};
