import { LoadState } from "../../../constants/enums";

export interface IBusinessPolicyUnderLyingState {
  data: IBusinessPolicyUnderLying[];
  loading: LoadState;
  underlying: IBusinessPolicyUnderLying;
  underlyingLoading: LoadState;
  error: string | null;
}

export interface IBusinessPolicyUnderLying {
  customer_policy_id: number;
  incremental_number: number | null;
  insurer: number | null;
  policy_number: number | null;
  effective_date: string | null;
  expiry_date: string | null;
  type_of_coverage: string | null;
  limit_amount: string | null;
  premium_amount: string | null;
  notes: string | null;
  status: string;
}

export interface IUnderLyingRow {
  customer_policy_id: string;
  insurer: string | null;
  policy_number: string | null;
  limit_amount: string | null;
}

export const defaultBusinessPolicyUnderLyingState: IBusinessPolicyUnderLyingState =
  {
    data: [],
    loading: LoadState.NotLoaded,
    underlying: {
      customer_policy_id: 0,
      incremental_number: null,
      insurer: null,
      policy_number: null,
      effective_date: null,
      expiry_date: null,
      type_of_coverage: null,
      limit_amount: null,
      premium_amount: null,
      notes: null,
      status: "ACTIVE",
    },
    underlyingLoading: LoadState.NotLoaded,
    error: null,
  };
