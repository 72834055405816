import { action } from "typesafe-actions";
import { IHabitationalConsentForm, IHabitationalDwelling, IHabitationalDwellingDeclaration } from "./HabitationalPolicyForm.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";
import { api } from "../../../api/api";
import { ERROR_MESSAGES } from "../../../constants/enums";


export const FETCH_HABITATIONAL_CONSENT_FORM_PROGRESS =
 "FETCH_HABITATIONAL_CONSENT_FORM_PROGRESS";
export const FETCH_HABITATIONAL_CONSENT_FORM_SUCCESS =
  "FETCH_AUTOMOBILE_CONSENT_FORM_SUCCESS";
export const FETCH_HABITATIONAL_CONSENT_FORM_FAILED =
  "FETCH_HABITATIONAL_CONSENT_FORM_FAILED";

export const fetchHabitationalConsentFormProgress = () =>
  action(FETCH_HABITATIONAL_CONSENT_FORM_PROGRESS);
export const fetchHabitationalConsentFormSuccess = (data: IHabitationalConsentForm) =>
  action(FETCH_HABITATIONAL_CONSENT_FORM_SUCCESS, { data });
export const fetchHabitationalConsentFormFailed = (errorMessage: string) =>
  action(FETCH_HABITATIONAL_CONSENT_FORM_FAILED, { errorMessage });

export const addHabitationalConsentFormAsync =
  (
    data: IHabitationalConsentForm,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        "/policy/edit-policy-form-privacy-consent-form",
        {
          ...data,
        }
      );
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

  export const fetchHabitationalConsentFormAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalConsentFormProgress());
      const res = await api.get(
        `/policy/get-policy-form-privacy-consent-form?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IHabitationalConsentForm[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchHabitationalConsentFormSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchHabitationalConsentFormFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchHabitationalConsentFormFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_HABITATIONAL_CONSENT_FORM = "CLEAR_HABITATIONAL_CONSENT_FORM";
export const clearHabitationalConsentForm = () =>
  action(CLEAR_HABITATIONAL_CONSENT_FORM);

export const FETCH_HABITATIONAL_CONSENT_FORM_LIST_PROGRESS =
"FETCH_HABITATIONAL_CONSENT_FORM_LIST_PROGRESS";
export const FETCH_HABITATIONAL_CONSENT_FORM_LIST_SUCCESS =
"FETCH_HABITATIONAL_CONSENT_FORM_LIST_SUCCESS";
export const FETCH_HABITATIONAL_CONSENT_FORM_LIST_FAILED =
  "FETCH_HABITATIONAL_CONSENT_FORM_LIST_FAILED";


export const fetchHabitationalConsentFormListProgress = () =>
  action(FETCH_HABITATIONAL_CONSENT_FORM_LIST_PROGRESS);
export const fetchHabitationalConsentFormListSuccess = (
  data: IHabitationalConsentForm[]
) => action(FETCH_HABITATIONAL_CONSENT_FORM_LIST_SUCCESS, { data });
export const fetchHabitationalConsentFormListFailed = () =>
  action(FETCH_HABITATIONAL_CONSENT_FORM_LIST_FAILED);

export const fetchHabitationalConsentFormListAsync = (customerPolicyId: number):ThunkAction<void, IStoreState, {}, AnyAction> => 
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalConsentFormListProgress());
      const res = await api.get(`/policy/get-policy-form-privacy-consent-form?customer_policy_id=${customerPolicyId}`);

      const data :IHabitationalConsentForm[] =res.data.data;
      dispatch(fetchHabitationalConsentFormListSuccess(data))
    } catch (err: any) {
      dispatch(fetchHabitationalConsentFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  }

  export const downloadHabitationalConsentFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-privacy-consent-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchHabitationalConsentFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewHabitationalConsentFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-privacy-consent-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_privacy_consent_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchHabitationalConsentFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_HABITATIONAL_DWELLING_DECLARATION_PROGRESS =
  "FETCH_HABITATIONAL_DWELLING_DECLARATION_PROGRESS";
export const FETCH_HABITATIONAL_DWELLING_DECLARATION_SUCCESS =
   "FETCH_AUTOMOBILE_DWELLING_DECLARATION_SUCCESS";
export const FETCH_HABITATIONAL_DWELLING_DECLARATION_FAILED =
   "FETCH_HABITATIONAL_DWELLING_DECLARATION_FAILED";
 
export const fetchHabitationalDwellingDeclarationProgress = () =>
   action(FETCH_HABITATIONAL_DWELLING_DECLARATION_PROGRESS);
export const fetchHabitationalDwellingDeclarationSuccess = (data: IHabitationalDwellingDeclaration) =>
   action(FETCH_HABITATIONAL_DWELLING_DECLARATION_SUCCESS, { data });
export const fetchHabitationalDwellingDeclarationFailed = (errorMessage: string) =>
   action(FETCH_HABITATIONAL_DWELLING_DECLARATION_FAILED, { errorMessage });
 
export const addHabitationalDwellingDeclarationAsync =
   (
     data: IHabitationalDwellingDeclaration,
     onCallback: (isSuccess: boolean) => void,
     isDuplicate?: boolean
   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
   async (dispatch, getState) => {
     try {
       dispatch(saveLoaderProgress());
        await api.post(
           "/policy/edit-policy-form-rental-dwelling-declaration",
           {
             ...data,
           }
        );
       onCallback(true);
       dispatch(
         showMessage({
           type: "success",
           message: "Conset Form saved successfully!",
           displayAs: "snackbar",
         })
       );
     } catch (err: any) {
       onCallback(false);
       dispatch(
         showMessage({
           type: "error",
           message: err.response.data.message,
           displayAs: "snackbar",
         })
       );
     } finally {
       dispatch(saveLoaderCompleted());
     }
   };

   export const fetchHabitationalDwellingDeclarationAsync =
   (
     customerPolicyId: number,
     incremental_number: number
   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
   async (dispatch, getState) => {
     try {
       dispatch(fetchHabitationalDwellingDeclarationProgress());
       const res = await api.get(
         `/policy/get-policy-form-rental-dwelling-declaration?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
       );
       const data: IHabitationalDwellingDeclaration[] = res.data.data;
       if (data.length > 0) {
         dispatch(
           fetchHabitationalDwellingDeclarationSuccess({
             ...data[0],
           })
         );
       } else {
         dispatch(
           fetchHabitationalDwellingDeclarationFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
         );
       }
     } catch (err: any) {
       dispatch(fetchHabitationalDwellingDeclarationFailed(ERROR_MESSAGES.SERVER_ERROR));
       dispatch(
         showMessage({
           type: "error",
           message: err.response.data.message,
           displayAs: "snackbar",
         })
       );
     }
   };
 
export const CLEAR_HABITATIONAL_DWELLING_DECLARATION = "CLEAR_HABITATIONAL_DWELLING_DECLARATION";
export const clearHabitationalDwellingDeclaration = () =>
  action(CLEAR_HABITATIONAL_DWELLING_DECLARATION);

export const FETCH_HABITATIONAL_DWELLING_DECLARATION_LIST_PROGRESS =
"FETCH_HABITATIONAL_DWELLING_DECLARATION_LIST_PROGRESS";
export const FETCH_HABITATIONAL_DWELLING_DECLARATION_LIST_SUCCESS =
"FETCH_HABITATIONAL_DWELLING_DECLARATION_LIST_SUCCESS";
export const FETCH_HABITATIONAL_DWELLING_DECLARATION_LIST_FAILED =
  "FETCH_HABITATIONAL_DWELLING_DECLARATION_LIST_FAILED";


export const fetchHabitationalDwellingDeclarationListProgress = () =>
  action(FETCH_HABITATIONAL_DWELLING_DECLARATION_LIST_PROGRESS);
export const fetchHabitationalDwellingDeclarationListSuccess = (
  data: IHabitationalDwellingDeclaration[]
) => action(FETCH_HABITATIONAL_DWELLING_DECLARATION_LIST_SUCCESS, { data });
export const fetchHabitationalDwellingDeclarationListFailed = () =>
  action(FETCH_HABITATIONAL_DWELLING_DECLARATION_LIST_FAILED);

export const fetchHabitationalDwellingDeclarationListAsync = (customerPolicyId: number):ThunkAction<void, IStoreState, {}, AnyAction> => 
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalDwellingDeclarationListProgress());
      const res = await api.get(`/policy/get-policy-form-rental-dwelling-declaration?customer_policy_id=${customerPolicyId}`);

      const data :IHabitationalDwellingDeclaration[] =res.data.data;
      dispatch(fetchHabitationalDwellingDeclarationListSuccess(data))
    } catch (err: any) {
      dispatch(fetchHabitationalDwellingDeclarationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  }

  export const downloadHabitationalDwellingDeclarationPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-rental-dwelling-declaration-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchHabitationalDwellingDeclarationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewHabitationalDwellingDeclarationPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-rental-dwelling-declaration-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_rental_dwelling_declaration_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchHabitationalDwellingDeclarationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const FETCH_HABITATIONAL_DWELLING_PROGRESS =
  "FETCH_HABITATIONAL_DWELLING_PROGRESS";
export const FETCH_HABITATIONAL_DWELLING_SUCCESS =
   "FETCH_AUTOMOBILE_DWELLING_SUCCESS";
export const FETCH_HABITATIONAL_DWELLING_FAILED =
   "FETCH_HABITATIONAL_DWELLING_FAILED";
 
export const fetchHabitationalDwellingProgress = () =>
   action(FETCH_HABITATIONAL_DWELLING_PROGRESS);
export const fetchHabitationalDwellingSuccess = (data: IHabitationalDwelling) =>
   action(FETCH_HABITATIONAL_DWELLING_SUCCESS, { data });
export const fetchHabitationalDwellingFailed = (errorMessage: string) =>
   action(FETCH_HABITATIONAL_DWELLING_FAILED, { errorMessage });
 
export const addHabitationalDwellingAsync =
   (
     data: IHabitationalDwelling,
     onCallback: (isSuccess: boolean) => void,
     isDuplicate?: boolean
   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
   async (dispatch, getState) => {
     try {
       dispatch(saveLoaderProgress());
        await api.post(
           "/policy/edit-policy-form-rental-dwelling",
           {
             ...data,
           }
        );
       onCallback(true);
       dispatch(
         showMessage({
           type: "success",
           message: "Conset Form saved successfully!",
           displayAs: "snackbar",
         })
       );
     } catch (err: any) {
       onCallback(false);
       dispatch(
         showMessage({
           type: "error",
           message: err.response.data.message,
           displayAs: "snackbar",
         })
       );
     } finally {
       dispatch(saveLoaderCompleted());
     }
   };

   export const fetchHabitationalDwellingAsync =
   (
     customerPolicyId: number,
     incremental_number: number
   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
   async (dispatch, getState) => {
     try {
       dispatch(fetchHabitationalDwellingProgress());
       const res = await api.get(
         `/policy/get-policy-form-rental-dwelling?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
       );
       const data: IHabitationalDwelling[] = res.data.data;
       if (data.length > 0) {
         dispatch(
           fetchHabitationalDwellingSuccess({
             ...data[0],
           })
         );
       } else {
         dispatch(
           fetchHabitationalDwellingFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
         );
       }
     } catch (err: any) {
       dispatch(fetchHabitationalDwellingFailed(ERROR_MESSAGES.SERVER_ERROR));
       dispatch(
         showMessage({
           type: "error",
           message: err.response.data.message,
           displayAs: "snackbar",
         })
       );
     }
   };
 
export const CLEAR_HABITATIONAL_DWELLING = "CLEAR_HABITATIONAL_DWELLING";
export const clearHabitationalDwelling = () =>
  action(CLEAR_HABITATIONAL_DWELLING);

export const FETCH_HABITATIONAL_DWELLING_LIST_PROGRESS =
"FETCH_HABITATIONAL_DWELLING_LIST_PROGRESS";
export const FETCH_HABITATIONAL_DWELLING_LIST_SUCCESS =
"FETCH_HABITATIONAL_DWELLING_LIST_SUCCESS";
export const FETCH_HABITATIONAL_DWELLING_LIST_FAILED =
  "FETCH_HABITATIONAL_DWELLING_LIST_FAILED";


export const fetchHabitationalDwellingListProgress = () =>
  action(FETCH_HABITATIONAL_DWELLING_LIST_PROGRESS);
export const fetchHabitationalDwellingListSuccess = (
  data: IHabitationalDwelling[]
) => action(FETCH_HABITATIONAL_DWELLING_LIST_SUCCESS, { data });
export const fetchHabitationalDwellingListFailed = () =>
  action(FETCH_HABITATIONAL_DWELLING_LIST_FAILED);

export const fetchHabitationalDwellingListAsync = (customerPolicyId: number):ThunkAction<void, IStoreState, {}, AnyAction> => 
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalDwellingListProgress());
      const res = await api.get(`/policy/get-policy-form-rental-dwelling?customer_policy_id=${customerPolicyId}`);

      const data :IHabitationalDwelling[] =res.data.data;
      dispatch(fetchHabitationalDwellingListSuccess(data))
    } catch (err: any) {
      dispatch(fetchHabitationalDwellingListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  }

  export const downloadHabitationalDwellingPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-rental-dwelling-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchHabitationalDwellingListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewHabitationalDwellingPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-rental-dwelling-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_rental_dwelling_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchHabitationalDwellingListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };