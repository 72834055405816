import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import {
  CLEAR_AUTOMOBILE_VEHICLE_STATE,
  FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_FAILED,
  FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_PROGRESS,
  FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_SUCCESS,
  FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_FAILED,
  FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_PROGRESS,
  FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_SUCCESS,
  FETCH_AUTOMOBILE_VEHICLE_INFORMATION_FAILED,
  FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_FAILED,
  FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_PROGRESS,
  FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_SUCCESS,
  FETCH_AUTOMOBILE_VEHICLE_INFORMATION_PROGRESS,
  FETCH_AUTOMOBILE_VEHICLE_INFORMATION_SUCCESS,
} from "./automobileVehicleActions";
import { VehicleAction } from ".";
import { initialAutomoblieVehicleInformation } from "./automobileVehicle.types";

export const AutomobileVehicleReducer = (
  state: IStoreState["automobile"]["vehicle"] = initialAutomoblieVehicleInformation,
  action: VehicleAction
) => {
  switch (action.type) {
    case FETCH_AUTOMOBILE_VEHICLE_INFORMATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.VehicleInformation.loading = LoadState.InProgress;
        draftState.VehicleInformation.data =
          initialAutomoblieVehicleInformation["VehicleInformation"]["data"];
        draftState.VehicleInformation.error = null;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_VEHICLE_INFORMATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.VehicleInformation.loading = LoadState.Loaded;
        draftState.VehicleInformation.data = data;
        draftState.vehicleNumber = data.incremental_number;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_VEHICLE_INFORMATION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.VehicleInformation.loading = LoadState.Failed;
        draftState.VehicleInformation.data =
          initialAutomoblieVehicleInformation["VehicleInformation"]["data"];
        draftState.VehicleInformation.error = errorMessage;
      });
      return newState;
    }

    case FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.VehicleInformation.vehiclesListLoading = LoadState.InProgress;
        draftState.VehicleInformation.vehiclesList = [];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.VehicleInformation.vehiclesListLoading = LoadState.Loaded;
        draftState.VehicleInformation.vehiclesList = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.VehicleInformation.vehiclesListLoading = LoadState.Failed;
        draftState.VehicleInformation.vehiclesList = [];
      });
      return newState;
    }

    case FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.additionalInformation.loading = LoadState.InProgress;
        draftState.additionalInformation.data =
          initialAutomoblieVehicleInformation["additionalInformation"]["data"];
        draftState.additionalInformation.error = null;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.additionalInformation.loading = LoadState.Loaded;
        draftState.additionalInformation.data = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.additionalInformation.loading = LoadState.InProgress;
        draftState.additionalInformation.data =
          initialAutomoblieVehicleInformation["additionalInformation"]["data"];
        draftState.additionalInformation.error = errorMessage;
      });
      return newState;
    }

   
    /**--- */
 
    
    case FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.interestedParty.interestedPartyListLoading =
          LoadState.InProgress;
        draftState.interestedParty.interestedPartyList = [];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.interestedParty.interestedPartyListLoading =
          LoadState.Loaded;
        draftState.interestedParty.interestedPartyList = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.interestedParty.interestedPartyListLoading =
          LoadState.Failed;
        draftState.interestedParty.interestedPartyList = [];
      });
      return newState;
    }

    case CLEAR_AUTOMOBILE_VEHICLE_STATE: {
      return initialAutomoblieVehicleInformation;
    }

    default: {
      return state;
    }
  }
};
