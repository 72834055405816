import { LoadState } from "../../constants/enums";

export interface IEmailStatisticsState {
  list: IEmailStatistics[];
  loading: LoadState;
}

export interface IEmailStatistics {
    date: string;
  blocks: number;
  bounce_drops: number;
  bounces: number;
  clicks: number;
  deferred: number;
  delivered: number;
  invalid_emails: number;
  opens: number;
  processed: number;
  requests: number;
  spam_report_drops: number;
  spam_reports: number;
  unique_clicks: number;
  unique_opens: number;
  unsubscribe_drops: number;
  unsubscribes: number;
}

export const initialEmailStatisticsState: IEmailStatisticsState = {
  list: [],
  loading: LoadState.NotLoaded,
};


export interface IEmailStatisticsGetApiResponse {
    body: {
        date: string;
        stats: [
         { metrics: IEmailStatistics}
        ]
    }[]
}
