import React from "react";
import { IAddNewUserProps } from "./AddNewUser.types";
import { useDispatchWrapper } from "../../../hooks";
import { useFormik } from "formik";
import { Dialog } from "../../../components/Dialogs/Dialog";
import { Grid } from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../components/formsComponents";
import { createNewUserAsync } from "../../../redux/UserProfileList/userProfileActions";
import { IUserCreation } from "../../../redux/UserProfileList/userProfile.types";
import { useNavigate } from "react-router-dom";
import { fetchRolesListAsync } from "../../../redux/security/securityActions";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../redux/initialStoreState";

const INITIAL_STATE: IUserCreation = {
  first_name: "",
  last_name: "",
  username: "",
  user_code: "",
  email: "",
  config_json: null,
  user_password: "",
  role_id: 0,
  status: "ACTIVE",
  user_fact_id: null,
};

export const AddNewUserDialog: React.FC<IAddNewUserProps> = (props) => {
  const { open, onClose } = props;

  const [saveLoading, setSaveLoading] = React.useState(false);
  const list = useSelector((storeState: IStoreState) => storeState.security.roles.list)

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();
  const { values, errors, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: { ...INITIAL_STATE, confirmPassword: "" },
      validate: (values) => {
        const errors: any = {};
        if (!values.first_name) {
          errors.first_name = "First name is required";
        } else if (!values.last_name) {
          errors.last_name = "Last name is required";
        } else if (!values.username) {
          errors.username = "Username is required";
        } else if (!values.user_code) {
          errors.user_code = "User code is required";
        } 
        else if(values.user_code.length >6){
          errors.user_code = "Max 6 characters allowed"
        }
        else if (!values.email) {
          errors.email = "Email is required";
        } else if (!values.user_password) {
          errors.user_password = "Password is required";
        } else if (values.user_password.length < 6) {
          errors.user_password = "Password must be at least 6 characters long";
        } else if (!values.confirmPassword) {
          errors.confirmPassword = "Confirm password is required";
        } else if (values.user_password !== values.confirmPassword) {
          errors.confirmPassword = "Passwords are not matching";
        } else if (values.role_id === 0) {
          errors.role_id = "Role is requried.";
        }
        return errors;
      },
      onSubmit: async (values) => {
        setSaveLoading(true);
        dispatch(
          createNewUserAsync(
            {
              ...values,
            },
            { ...INITIAL_STATE},
            values.user_fact_id ? true : false, 
            (isSuccess, userFactId) => {
              if (isSuccess && userFactId) {
                navigate("/users/manage/"+userFactId);
                onClose();
              }
              setSaveLoading(false);
            }
          )
        );
      },
    });

  const handleUserCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputText = e.target.value;
    const alphaNumericText = inputText.replace(/[^A-Za-z0-9]/g, "");
    setFieldValue("user_code", alphaNumericText.toUpperCase());
  };

  React.useEffect(() => {
    dispatch(fetchRolesListAsync());
  }, [])

  return (
    <Dialog
      open={open}
      title="Create New User"
      onClose={onClose}
      size="md"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      actions={[
        { type: "button", label: "Close", variant: "text", onClick: onClose },
        {
          type: "submit",
          label: "Save",
          variant: "contained",
          disabled: saveLoading,
        },
      ]}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} lg={6}>
          <CustomFormLabel>First Name </CustomFormLabel>
          <CustomTextField
            name="first_name"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.first_name}
            error={errors.first_name ? true : false}
            helperText={errors.first_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CustomFormLabel>Last Name </CustomFormLabel>
          <CustomTextField
            name="last_name"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.last_name}
            error={errors.last_name ? true : false}
            helperText={errors.last_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomFormLabel>UserName </CustomFormLabel>
          <CustomTextField
            name="username"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.username}
            error={errors.username ? true : false}
            helperText={errors.username}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomFormLabel>User Code</CustomFormLabel>
          <CustomTextField
            name="user_code"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.user_code}
            error={errors.user_code ? true : false}
            helperText={errors.user_code}
            onChange={handleUserCode}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomFormLabel>Email</CustomFormLabel>
          <CustomTextField
            name="email"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.email}
            error={errors.email ? true : false}
            helperText={errors.email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomFormLabel>Password </CustomFormLabel>
          <CustomTextField
            name="user_password"
            variant="outlined"
            size="small"
            type="password"
            fullWidth
            value={values.user_password}
            error={errors.user_password ? true : false}
            helperText={errors.user_password}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomFormLabel>Confirm Password</CustomFormLabel>
          <CustomTextField
            name="confirmPassword"
            variant="outlined"
            size="small"
            type="password"
            fullWidth
            value={values.confirmPassword}
            error={errors.confirmPassword ? true : false}
            helperText={errors.confirmPassword}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomFormLabel>Role</CustomFormLabel>
          <ControlledCustomSelect
            fullWidth
            value={values.role_id}
            name="role_id"
            onChange={handleChange}
            placeholder="Select one"
            displayEmpty
            options={list.map((role) => {
              return { label: role.role_name, value:role.role_id };
            })}
            error={errors.role_id ? true : false}
          ></ControlledCustomSelect>
        </Grid>
      </Grid>
    </Dialog>
  );
};
