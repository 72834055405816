import produce from "immer";
import { IStoreState } from "../initialStoreState";

import { LoadState } from "../../constants/enums";


import { defaultAllBindersState } from "./allbinders.types";
import { AllBinderActions } from ".";
import { CLEAR_ALL_BINDERS, FETCH_ALL_BINDERS_LIST_FAILED, FETCH_ALL_BINDERS_LIST_PROGRESS, FETCH_ALL_BINDERS_LIST_SUCCESS } from "./allbinderActions";


export const allBindersReducer = (
  state: IStoreState["allbinders"] = defaultAllBindersState,
  action: AllBinderActions
) => {
  switch (action.type) {
    
    case FETCH_ALL_BINDERS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_ALL_BINDERS_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = list;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_ALL_BINDERS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case CLEAR_ALL_BINDERS: {
      const newState = produce(state, (draftState)=>{
        draftState.loading = LoadState.NotLoaded;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState
    }

    default: {
      return state;
    }
  }
};
