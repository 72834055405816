import produce from "immer";
import { LoadState } from "../../../constants/enums";
import { IStoreState } from "../../initialStoreState";
import { initialBusinessGarageCertificateState } from "./businessGarageCertificate.types";
import { BusinessGarageCertificateActions } from ".";
import { CLEAR_BUSINESS_POLICY_GARAGE_CERTIFICATE_STATE, FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_FAILED, FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_LIST_FAILED, FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_LIST_PROGRESS, FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_LIST_SUCCESS, FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_PROGRESS, FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_SUCCESS } from "./businessGarageCertificateActions";



export const businessGarageCertificateReducer = (
  state: IStoreState["business"]["certificate"] = initialBusinessGarageCertificateState,
  action: BusinessGarageCertificateActions,
) => {
  switch (action.type) {

    case FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.businessGarageCertificateListLoading = LoadState.InProgress;
        draftState.businessGarageCertificateList = [];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.businessGarageCertificateListLoading = LoadState.Loaded;
        draftState.businessGarageCertificateList = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.businessGarageCertificateListLoading = LoadState.Failed;
        draftState.businessGarageCertificateList = [];
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_PROGRESS: {
        const newState = produce(state, (draftState) => {
          draftState.businessGarageCertificate = initialBusinessGarageCertificateState["businessGarageCertificate"];
          draftState.businessGarageCertificateLoading = LoadState.InProgress;
        });
        return newState;
    }
    case FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.businessGarageCertificate = data;
        draftState.businessGarageCertificateLoading = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.businessGarageCertificateLoading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_BUSINESS_POLICY_GARAGE_CERTIFICATE_STATE: {
      return initialBusinessGarageCertificateState;
    }
    default: {
      return state;
    }
  }
};
