import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { LoadState } from "../../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";
import { fetchCommAutomobileDriverClaimsListAsync } from "../../../../../../../redux/commAutoPolicy/commAutoDriver/commAutoDriverActions";
import { ICommAutomobileDriverClaims } from "../../../../../../../redux/commAutoPolicy/commAutoDriver/commAutoDriver.types";

export const CommAutomobileDriverClaimsList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { driverClaimsList, loading } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.driver.driverClaims
  );

  const dispatch = useDispatchWrapper();

  React.useEffect(() => {
    dispatch(fetchCommAutomobileDriverClaimsListAsync(customerPolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerPolicyId]);

  const automobileDriverTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    `/comm-automobile-policy/${customerId}/driver-tab/${customerPolicyId}/claims`
                  );
                }}
              >
                Add Claim
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "claim_number",
        headerName: "#",
        fieldName: "claim_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 20,
        onRowCellRender: (value, row: ICommAutomobileDriverClaims) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/driver-tab/${customerPolicyId}/claims/${row.incremental_number}`
                );
              }}
            >
              {row.incremental_number}
            </Link>
          );
        },
      },

      {
        key: "driver_number",
        headerName: "Driver Number",
        fieldName: "driver_number",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
        onRowCellRender: (value, row: ICommAutomobileDriverClaims) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/driver-tab/${customerPolicyId}/claims/${row.incremental_number}`
                );
              }}
            >
              {row.driver_number}
            </Link>
          );
        },
      },

      {
        key: "vehicle_number",
        headerName: "Vehicle Number",
        fieldName: "vehicle_number",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
        onRowCellRender: (value, row: ICommAutomobileDriverClaims) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/driver-tab/${customerPolicyId}/claims/${row.incremental_number}`
                );
              }}
            >
              {row.vehicle_number}
            </Link>
          );
        },
      },

      {
        key: "claim_type",
        headerName: "Type",
        fieldName: "claim_type",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "amount_paid",
        headerName: "Amount Paid",
        fieldName: "amount_paid",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "insurer",
        headerName: "Insurer",
        fieldName: "insurer",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "at_fault",
        headerName: "At Fault",
        fieldName: "at_fault",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "forgiven",
        headerName: "Forgiven",
        fieldName: "forgiven",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "date_of_loss",
        headerName: "Date of Loss",
        fieldName: "date_of_loss",
        exportCellWidth: 25,
        renderType: RenderType.DATE_DARK_COLOR,
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: 'center',
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobileDriverClaims) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/driver-tab/${customerPolicyId}/claims/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
    ],
    items: driverClaimsList,
  };

  return (
    <Box sx={{ mt: 2 }}>
      <DataTable {...automobileDriverTableProps} />
    </Box>
  );
};
