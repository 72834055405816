import produce from "immer";
import { LoadState } from "../../../constants/enums";
import { IStoreState } from "../../initialStoreState";
import { defaultLifeInsurancePolicy } from "./lifeInsurancePolicies.types";
import { LifeInsurancePoliciesActions } from ".";
import { CLEAR_LIFE_INSURANCE_POLICIES, CLEAR_LIFE_INSURANCE_POLICIES_STATE, FETCH_LIFE_INSURANCE_POLICIES_FAILED, FETCH_LIFE_INSURANCE_POLICIES_PROGRESS, FETCH_LIFE_INSURANCE_POLICIES_SUCCESS } from "./lifeInsurancePoliciesActions";

export const lifeInsurancePoliciesReducer = (
  state: IStoreState["lifeInsurance"]["policies"] = defaultLifeInsurancePolicy,
  action: LifeInsurancePoliciesActions
) => {
  switch (action.type) {
    case FETCH_LIFE_INSURANCE_POLICIES_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = defaultLifeInsurancePolicy.data;
      });
      return newState;
    }
    case FETCH_LIFE_INSURANCE_POLICIES_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_LIFE_INSURANCE_POLICIES_FAILED: {
      const {errorMessage} = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
  
    case CLEAR_LIFE_INSURANCE_POLICIES: {
      const newState = produce(state, (draftState: any) => {
        draftState.data = defaultLifeInsurancePolicy;
        draftState.loading = LoadState.NotLoaded;
      });
      return newState;
    }
    case CLEAR_LIFE_INSURANCE_POLICIES_STATE: {
      return defaultLifeInsurancePolicy;
    }
    default: {
      return state;
    }
  }
};