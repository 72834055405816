import { useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../hooks";
import React from "react";
import { Box, Checkbox, Grid, Stack, Typography } from "@mui/material";
import { StandardCellList } from "../../../../components/Standards";
import { formatDate, formatTime } from "../../../../helpers";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../constants/enums";
import { fetchTaskPolicyCancellationAysnc } from "../../../../redux/taskUrgentTransactions/taskUrgentTransactionsActions";
import { ITaskPolicyCancellation } from "../../../../redux/taskUrgentTransactions/taskUrgentTransactions.types";

export const ViewCancellation: React.FC<{
  taskCode: string;
}> = (props) => {
  const { taskCode } = props;
  const { data, loading } = useSelector(
    (storeState: IStoreState) =>
      storeState.tasks.urgentTransactions.policyCancellation
  );

  const dispatch = useDispatchWrapper();
  const [cancellationDetails, setCancellationDetails] =
    React.useState<ITaskPolicyCancellation>();

  React.useEffect(() => {
    dispatch(fetchTaskPolicyCancellationAysnc(taskCode));
  }, [taskCode]);

  React.useEffect(() => {
    setCancellationDetails(data);
  }, [data]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      {cancellationDetails && (
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Cancellation Request"
              colSize={3}
              list={[
                {
                  heading: "Date of Cancellation",
                  data: {
                    type: "text",
                    value: formatDate(
                      cancellationDetails.date_of_cancellation || ""
                    ),
                  },
                },
                {
                  heading: "Time of Cancellation",
                  data: {
                    type: "text",
                    value: formatTime(
                      cancellationDetails.time_of_cancellation || ""
                    ),
                  },
                },
                {
                  heading: "Reason for Cancellation",
                  data: {
                    type: "text",
                    value: `${
                      cancellationDetails.requested_by_insured
                        ? "Requested By Insurer"
                        : "--"
                    }`,
                  },
                },
                {
                  heading: "Effective Date",
                  data: {
                    type: "text",
                    value: formatDate(cancellationDetails.effective_date || ""),
                  },
                },
                {
                  heading: "Expiration Date",
                  data: {
                    type: "text",
                    value: formatDate(
                      cancellationDetails.expiration_date || ""
                    ),
                  },
                },
                {
                  heading: "Insurance Company",
                  data: {
                    type: "text",
                    value: cancellationDetails.insurance_company,
                  },
                },
                {
                  heading: "Methods of Cancellation",
                  colSize: 4,
                  data: {
                    type: "text",
                    value: (
                      <Stack
                        direction="row"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Checkbox
                            checked={cancellationDetails.flat === "Y"}
                          />
                          <Typography>FLAT</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Checkbox
                            checked={cancellationDetails.short_rate === "Y"}
                          />
                          <Typography>SHORT RATE</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Checkbox
                            checked={cancellationDetails.prorata === "Y"}
                          />
                          <Typography>PRO-RATA</Typography>
                        </Box>
                      </Stack>
                    ),
                  },
                },
                {
                  heading: "Premium Paid to Broker",
                  data: {
                    type: "text",
                    value: cancellationDetails.premium_paid_to_broker,
                  },
                },
                {
                  heading: "Remarks",
                  colSize: 12,
                  data: {
                    type: "text",
                    value: cancellationDetails.remarks,
                  },
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <StandardCellList
              heading="Release(s) Attached"
              colSize={3}
              list={[
                {
                  heading: "Insured",
                  colSize: 2,
                  data: {
                    type: "text",
                    value: cancellationDetails.insured,
                  },
                },
                {
                  heading: "Mprtgagee",
                  colSize: 2,
                  data: {
                    type: "text",
                    value: cancellationDetails.mortgagee,
                  },
                },
                {
                  heading: "Lienholder",
                  colSize: 2,
                  data: {
                    type: "text",
                    value: cancellationDetails.lienholder,
                  },
                },
                {
                  heading: "Finance Company",
                  colSize: 2,
                  data: {
                    type: "text",
                    value: cancellationDetails.finance_company,
                  },
                },
                {
                  heading: "Loss Payee",
                  colSize: 2,
                  data: {
                    type: "text",
                    value: cancellationDetails.loss_payee,
                  },
                },
                {
                  heading: "Lessor",
                  colSize: 2,
                  data: {
                    type: "text",
                    value: cancellationDetails.lessor,
                  },
                },
                {
                  heading: "WHERE RELEASES ARE NOT ATTACHED, PLEASE ISSUE CANCELLATION NOTICES TO ALL INTERESTS ON THE CANCELLED POLICY",
                  colSize: 12,
                  data: {
                    type: "text",
                    value: cancellationDetails.other,
                  },
                },
              ]}
            />
          </Grid>
        </Grid>
      )}
    </PageLoader>
  );
};
