import produce from "immer";

import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialSupportTicketsState } from "./supportTickets.types";
import { SupportTicketsAction } from ".";
import { CLEAR_SUPPORT_TICKETS, FETCH_SUPPORT_TICKETS_FAILED, FETCH_SUPPORT_TICKETS_LIST_FAILED, FETCH_SUPPORT_TICKETS_LIST_PROGRESS, FETCH_SUPPORT_TICKETS_LIST_SUCCESS, FETCH_SUPPORT_TICKETS_PROGRESS, FETCH_SUPPORT_TICKETS_SUCCESS } from "./supportTicketsAction";

export const supportTicketsReducer = (
  state: IStoreState["supportTickets"] = initialSupportTicketsState,
  action: SupportTicketsAction
) => {
  switch (action.type) {
      case FETCH_SUPPORT_TICKETS_LIST_PROGRESS: {
        const newState = produce(state, (draftState) => {
          draftState.listLoading = LoadState.InProgress;
          draftState.list = [];
          draftState.totalRecords = 0;
        });
        return newState;
      }
      case FETCH_SUPPORT_TICKETS_LIST_SUCCESS: {
        const { data, totalRecords } = action.payload;
        const newState = produce(state, (draftState) => {
          draftState.listLoading = LoadState.Loaded;
          draftState.list = data;
          draftState.totalRecords = totalRecords;
        });
        return newState;
      }
      case FETCH_SUPPORT_TICKETS_LIST_FAILED: {
        const newState = produce(state, (draftState) => {
          draftState.listLoading = LoadState.Failed;
          draftState.list = [];
          draftState.totalRecords = 0;
        });
        return newState;
      }

      case FETCH_SUPPORT_TICKETS_PROGRESS: {
        const newState = produce(state, (draftState) => {
          draftState.loading = LoadState.InProgress;
          draftState.data = initialSupportTicketsState['data'];
          draftState.error = null;
        });
        return newState;
      }
      case FETCH_SUPPORT_TICKETS_SUCCESS: {
        const { data } = action.payload;
        const newState = produce(state, (draftState) => {
          draftState.loading = LoadState.Loaded;
          draftState.data = data;
          draftState.error = null;
        });
        return newState;
      }
      case FETCH_SUPPORT_TICKETS_FAILED: {
        const newState = produce(state, (draftState) => {
          draftState.loading = LoadState.Failed;
          draftState.data = initialSupportTicketsState['data'];
          draftState.error = null;
        });
        return newState;
      }

      case CLEAR_SUPPORT_TICKETS: {
        return initialSupportTicketsState;
      }
  
    default: {
      return state;
    }
  }
};
