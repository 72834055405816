import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import {
  CLEAR_HABITATIONAL_ADDITIONAL,
  CLEAR_HABITATIONAL_ADDITIONAL_STATE,
  FETCH_HABITATIONAL_ADDITIONAL_FAILED,
  FETCH_HABITATIONAL_ADDITIONAL_LIST_FAILED,
  FETCH_HABITATIONAL_ADDITIONAL_LIST_PROGRESS,
  FETCH_HABITATIONAL_ADDITIONAL_LIST_SUCCESS,
  FETCH_HABITATIONAL_ADDITIONAL_PROGRESS,
  FETCH_HABITATIONAL_ADDITIONAL_SUCCESS,
} from "./habitationalAdditionalActions";
import { LoadState } from "../../../constants/enums";
import { HabitationalAdditionalActions } from ".";
import { defaultHabitationalAdditionalState } from "./habitationalAdditional.types";

export const HabitationalInterestedPartyReducer = (
  state: IStoreState["habitational"]["additional"] = defaultHabitationalAdditionalState,
  action: HabitationalAdditionalActions
) => {
  switch (action.type) {
    case FETCH_HABITATIONAL_ADDITIONAL_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_ADDITIONAL_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_ADDITIONAL_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_ADDITIONAL_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.additionalLoading = LoadState.InProgress;
        draftState.additional =
          defaultHabitationalAdditionalState["additional"];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_ADDITIONAL_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.additionalLoading = LoadState.Loaded;
        draftState.additional = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_ADDITIONAL_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.additional =
          defaultHabitationalAdditionalState["additional"];
      });
      return newState;
    }

    case CLEAR_HABITATIONAL_ADDITIONAL: {
      const newState = produce(state, (draftState) => {
        draftState.additional =
          defaultHabitationalAdditionalState["additional"];
      });
      return newState;
    }
    case CLEAR_HABITATIONAL_ADDITIONAL_STATE: {
      return defaultHabitationalAdditionalState;
    }
    default: {
      return state;
    }
  }
};
