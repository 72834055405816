import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialCommAutomoblieSchedule } from "./commAutoSchedule.types";
import { CommAutomobileScheduleActions } from ".";
import { CLEAR_COMM_AUTOMOBILE_SCHEDULE, FETCH_COMM_AUTOMOBILE_SCHEDULE_A_FAILED, FETCH_COMM_AUTOMOBILE_SCHEDULE_A_LIST_FAILED, FETCH_COMM_AUTOMOBILE_SCHEDULE_A_LIST_PROGRESS, FETCH_COMM_AUTOMOBILE_SCHEDULE_A_LIST_SUCCESS, FETCH_COMM_AUTOMOBILE_SCHEDULE_A_PROGRESS, FETCH_COMM_AUTOMOBILE_SCHEDULE_A_SUCCESS, FETCH_COMM_AUTOMOBILE_SCHEDULE_B_FAILED, FETCH_COMM_AUTOMOBILE_SCHEDULE_B_LIST_FAILED, FETCH_COMM_AUTOMOBILE_SCHEDULE_B_LIST_PROGRESS, FETCH_COMM_AUTOMOBILE_SCHEDULE_B_LIST_SUCCESS, FETCH_COMM_AUTOMOBILE_SCHEDULE_B_PROGRESS, FETCH_COMM_AUTOMOBILE_SCHEDULE_B_SUCCESS, FETCH_COMM_AUTOMOBILE_SCHEDULE_C_FAILED, FETCH_COMM_AUTOMOBILE_SCHEDULE_C_LIST_FAILED, FETCH_COMM_AUTOMOBILE_SCHEDULE_C_LIST_PROGRESS, FETCH_COMM_AUTOMOBILE_SCHEDULE_C_LIST_SUCCESS, FETCH_COMM_AUTOMOBILE_SCHEDULE_C_PROGRESS, FETCH_COMM_AUTOMOBILE_SCHEDULE_C_SUCCESS, FETCH_COMM_AUTOMOBILE_SCHEDULE_D_FAILED, FETCH_COMM_AUTOMOBILE_SCHEDULE_D_LIST_FAILED, FETCH_COMM_AUTOMOBILE_SCHEDULE_D_LIST_PROGRESS, FETCH_COMM_AUTOMOBILE_SCHEDULE_D_LIST_SUCCESS, FETCH_COMM_AUTOMOBILE_SCHEDULE_D_PROGRESS, FETCH_COMM_AUTOMOBILE_SCHEDULE_D_SUCCESS } from "./commAutoScheduleActions";

export const CommAutomobileScheduleReducer = (
  state: IStoreState["commAuto"]["schedule"] = initialCommAutomoblieSchedule,
  action: CommAutomobileScheduleActions
) => {
  switch (action.type) {
    case FETCH_COMM_AUTOMOBILE_SCHEDULE_A_PROGRESS: {
      const newState = produce(state, draftState => {
        draftState.scheduleA.loading = LoadState.InProgress;
        draftState.scheduleA.data = initialCommAutomoblieSchedule['scheduleA']['data'];
      })
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SCHEDULE_A_SUCCESS: {
      const newState = produce(state, draftState => {
        draftState.scheduleA.loading = LoadState.Loaded;
        draftState.scheduleA.data = action.payload.data;
      })
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SCHEDULE_A_FAILED: {
      const newState = produce(state, draftState => {
        draftState.scheduleB.loading = LoadState.Failed;
        draftState.scheduleA.data = initialCommAutomoblieSchedule['scheduleA']['data'];
      })
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_SCHEDULE_B_PROGRESS: {
      const newState = produce(state, draftState => {
        draftState.scheduleB.loading = LoadState.InProgress;
        draftState.scheduleB.data = initialCommAutomoblieSchedule['scheduleB']['data'];
      })
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SCHEDULE_B_SUCCESS: {
      const newState = produce(state, draftState => {
        draftState.scheduleB.loading = LoadState.Loaded;
        draftState.scheduleB.data = action.payload.data;
      })
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SCHEDULE_B_FAILED: {
      const newState = produce(state, draftState => {
        draftState.scheduleB.loading = LoadState.Failed;
        draftState.scheduleB.data = initialCommAutomoblieSchedule['scheduleB']['data'];
      })
      return newState;
    }
    
    case FETCH_COMM_AUTOMOBILE_SCHEDULE_C_PROGRESS: {
      const newState = produce(state, draftState => {
        draftState.scheduleC.loading = LoadState.InProgress;
        draftState.scheduleC.data = initialCommAutomoblieSchedule['scheduleC']['data'];
      })
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SCHEDULE_C_SUCCESS: {
      const newState = produce(state, draftState => {
        draftState.scheduleC.loading = LoadState.Loaded;
        draftState.scheduleC.data = action.payload.data;
      })
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SCHEDULE_C_FAILED: {
      const newState = produce(state, draftState => {
        draftState.scheduleC.loading = LoadState.Failed;
        draftState.scheduleC.data = initialCommAutomoblieSchedule['scheduleC']['data'];
      })
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_SCHEDULE_D_PROGRESS: {
      const newState = produce(state, draftState => {
        draftState.scheduleD.loading = LoadState.InProgress;
        draftState.scheduleD.data = initialCommAutomoblieSchedule['scheduleD']['data'];
      })
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SCHEDULE_D_SUCCESS: {
      const newState = produce(state, draftState => {
        draftState.scheduleD.loading = LoadState.Loaded;
        draftState.scheduleD.data = action.payload.data;
      })
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SCHEDULE_D_FAILED: {
      const newState = produce(state, draftState => {
        draftState.scheduleD.loading = LoadState.Failed;
        draftState.scheduleD.data = initialCommAutomoblieSchedule['scheduleD']['data'];
      })
      return newState;
    }


    case FETCH_COMM_AUTOMOBILE_SCHEDULE_A_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.scheduleA.listLoading = LoadState.InProgress;
        draftState.scheduleA.listData = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SCHEDULE_A_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.scheduleA.listLoading = LoadState.Loaded;
        draftState.scheduleA.listData = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SCHEDULE_A_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.scheduleA.listLoading = LoadState.Failed;
        draftState.scheduleA.listData = [];
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_SCHEDULE_B_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.scheduleB.listLoading = LoadState.InProgress;
        draftState.scheduleB.listData = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SCHEDULE_B_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.scheduleB.listLoading = LoadState.Loaded;
        draftState.scheduleB.listData = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SCHEDULE_B_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.scheduleB.listLoading = LoadState.Failed;
        draftState.scheduleB.listData = [];
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_SCHEDULE_C_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.scheduleC.listLoading = LoadState.InProgress;
        draftState.scheduleC.listData = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SCHEDULE_C_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.scheduleC.listLoading = LoadState.Loaded;
        draftState.scheduleC.listData = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SCHEDULE_C_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.scheduleC.listLoading = LoadState.Failed;
        draftState.scheduleC.listData = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SCHEDULE_D_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.scheduleD.listLoading = LoadState.InProgress;
        draftState.scheduleD.listData = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SCHEDULE_D_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.scheduleD.listLoading = LoadState.Loaded;
        draftState.scheduleD.listData = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SCHEDULE_D_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.scheduleD.listLoading = LoadState.Failed;
        draftState.scheduleD.listData = [];
      });
      return newState;
    }
    case CLEAR_COMM_AUTOMOBILE_SCHEDULE: {
      const newState = produce(state, (draftState) => {
        draftState.scheduleA.loading = LoadState.NotLoaded;
        draftState.scheduleA.data = initialCommAutomoblieSchedule.scheduleA.data;
      });
      return newState;
    }
    default: {
      return state;
    }
  }
};

