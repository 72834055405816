import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { PolicyCancellationForm } from "../../Cancellation/PolicyCancellationForm";
import { POLICIES, POLICIES_NAMES } from "../../../../../../constants/enums";

export const LifeInsuranceCancellation: React.FC = () => {
  const { taskCode } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
    taskCode?: string;
  };

  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerPolicyId = data[1];

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(
      `/life-insurance-policy/${customerId}/policy-forms-tab/${customerPolicyId}?tab=0`
    );
  };
  return (
    <>
      {taskCode && (
        <PolicyCancellationForm
          customerId={customerId}
          customerPolicyId={customerPolicyId}
          taskCode={taskCode}
          handleNavigate={handleNavigate}
          agencyBill={null}
          policy={POLICIES_NAMES.LIFE_AND_FINCANCIAL_POLICY}
          lineOfBusiness={POLICIES.LIFE_AND_FINCANCIAL_POLICY}
          subType={null}
          policyNumber={null}
        />
      )}
    </>
  );
};
