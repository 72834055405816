import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { AutomobileGaragePlatesActions } from ".";
import { defaultAutomobileGaragePlatesState } from "./automobileGaragePlates.types";
import {
  CLEAR_AUTOMOBILE_GARAGE_PLATES_STATE,
  FETCH_AUTOMOBILE_GARAGE_PLATES_LIST_FAILED,
  FETCH_AUTOMOBILE_GARAGE_PLATES_LIST_PROGRESS,
  FETCH_AUTOMOBILE_GARAGE_PLATES_LIST_SUCCESS,
} from "./automobileGaragePlatesActions";

export const AutomobileGaragePlatesReducer = (
  state: IStoreState["automobile"]["garagePlate"] = defaultAutomobileGaragePlatesState,
  action: AutomobileGaragePlatesActions
) => {
  switch (action.type) {
    case FETCH_AUTOMOBILE_GARAGE_PLATES_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_GARAGE_PLATES_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }

    case FETCH_AUTOMOBILE_GARAGE_PLATES_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.data = [];
      });
      return newState;
    }

    case CLEAR_AUTOMOBILE_GARAGE_PLATES_STATE: {
      return defaultAutomobileGaragePlatesState;
    }

    default: {
      return state;
    }
  }
};
