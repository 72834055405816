import { action } from "typesafe-actions";
import {
  IAutomobileConsentForm,
  IAutomobileGarageForm,
  IAutomobileLiabilityInsurance,
  IAutomobileLiabilitySlips,
  IAutomobileOCEP16,
  IAutomobileOCEP17,
  IAutomobileOCEP28A,
  IAutomobileRetireeDiscount,
  IAutomobileSharingFillable,
  IAutomobileSupplementaryForm,
} from "./automobileCertificates.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import { ERROR_MESSAGES } from "../../constants/enums";

export const FETCH_AUTOMOBILE_LIABILITY_SLIP_PROGRESS =
  "FETCH_AUTOMOBILE_LIABILITY_SLIP_PROGRESS";
export const FETCH_AUTOMOBILE_LIABILITY_SLIP_SUCCESS =
  "FETCH_AUTOMOBILE_LIABILITY_SLIP_SUCCESS";
export const FETCH_AUTOMOBILE_LIABILITY_SLIP_FAILED =
  "FETCH_AUTOMOBILE_LIABILITY_SLIP_FAILED";

export const fetchAutomobileLiabilitySlipProgress = () =>
  action(FETCH_AUTOMOBILE_LIABILITY_SLIP_PROGRESS);
export const fetchAutomobileLiabilitySlipSuccess = (
  data: IAutomobileLiabilitySlips
) => action(FETCH_AUTOMOBILE_LIABILITY_SLIP_SUCCESS, { data });
export const fetchAutomobileLiabilitySlipFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_LIABILITY_SLIP_FAILED, { errorMessage });

export const addAutomobileLiabilitySlipAsync =
  (
    data: IAutomobileLiabilitySlips,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      if (isDuplicate) {
        await api.post("/policy/edit-policy-auto-liability-slip-certificate", {
          ...data,
          incremental_number: null,
        });
      } else {
        await api.post("/policy/edit-policy-auto-liability-slip-certificate", {
          ...data,
        });
      }

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Liability slip saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const fetchAutomobileLiabilityAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileLiabilitySlipProgress());
      const res = await api.get(
        `/policy/get-policy-auto-liability-slip-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileLiabilitySlips[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileLiabilitySlipSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchAutomobileLiabilitySlipFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchAutomobileLiabilitySlipFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_LIABILITY_SLIP =
  "CLEAR_AUTOMOBILE_LIABILITY_SLIP";
export const clearAutomobileLiabiliySlip = () =>
  action(CLEAR_AUTOMOBILE_LIABILITY_SLIP);

export const FETCH_AUTOMOBILE_LIABILITY_SLIPS_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_LIABILITY_SLIPS_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_LIABILITY_SLIPS_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_LIABILITY_SLIPS_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_LIABILITY_SLIPS_LIST_FAILED =
  "FETCH_AUTOMOBILE_LIABILITY_SLIPS_LIST_FAILED";

export const fetchAutomobileLiabilitySlipsListProgress = () =>
  action(FETCH_AUTOMOBILE_LIABILITY_SLIPS_LIST_PROGRESS);
export const fetchAutomobileLiabilitySlipsListSuccess = (
  data: IAutomobileLiabilitySlips[]
) => action(FETCH_AUTOMOBILE_LIABILITY_SLIPS_LIST_SUCCESS, { data });
export const fetchAutomobileLiabilitySlipsListFailed = () =>
  action(FETCH_AUTOMOBILE_LIABILITY_SLIPS_LIST_FAILED);

export const fetchAutomobileLiabilitySlipsListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileLiabilitySlipsListProgress());
      const res = await api.get(
        `/policy/get-policy-auto-liability-slip-certificate?customer_policy_id=${customerPolicyId}`
      );
      const data: IAutomobileLiabilitySlips[] = res.data.data;
      dispatch(fetchAutomobileLiabilitySlipsListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileLiabilitySlipsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadAutomobileLiabilitySlipsListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-liability-slip-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileLiabilitySlipsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewAutomobileLiabilitySlipsListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-liability-slip-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_liability_slip_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileLiabilitySlipsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_AUTOMOBILE_OCEP16_PROGRESS =
  "FETCH_AUTOMOBILE_OCEP16_PROGRESS";
export const FETCH_AUTOMOBILE_OCEP16_SUCCESS =
  "FETCH_AUTOMOBILE_OCEP16_SUCCESS";
export const FETCH_AUTOMOBILE_OCEP16_FAILED = "FETCH_AUTOMOBILE_OCEP16_FAILED";

export const fetchAutomobileOcep16rogress = () =>
  action(FETCH_AUTOMOBILE_OCEP16_PROGRESS);
export const fetchAutomobileOcep16Success = (data: IAutomobileOCEP16) =>
  action(FETCH_AUTOMOBILE_OCEP16_SUCCESS, { data });
export const fetchAutomobileOcep16Failed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_OCEP16_FAILED, { errorMessage });

export const addAutomobileOcep16sync =
  (
    data: IAutomobileOCEP16,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      if (isDuplicate) {
        await api.post("/policy/edit-policy-auto-opcf-sixteen-certificate", {
          ...data,
          incremental_number: null,
        });
      } else {
        await api.post("/policy/edit-policy-auto-opcf-sixteen-certificate", {
          ...data,
        });
      }

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "OPCF16 saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const fetchAutomobileOcep16Async =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileOcep16rogress());
      const res = await api.get(
        `/policy/get-policy-auto-opcf-sixteen-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileOCEP16[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileOcep16Success({
            ...data[0],
          })
        );
      } else {
        dispatch(fetchAutomobileOcep16Failed(ERROR_MESSAGES.POLICY_NOT_FOUND));
      }
    } catch (err: any) {
      dispatch(fetchAutomobileOcep16Failed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_OCEP16 = "CLEAR_AUTOMOBILE_OCEP16";
export const clearAutomobileOcep16 = () => action(CLEAR_AUTOMOBILE_OCEP16);

export const FETCH_AUTOMOBILE_OCEP16_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_OCEP16_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_OCEP16_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_OCEP16_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_OCEP16_LIST_FAILED =
  "FETCH_AUTOMOBILE_OCEP16_LIST_FAILED";

export const fetchAutomobileOcep16ListProgress = () =>
  action(FETCH_AUTOMOBILE_OCEP16_LIST_PROGRESS);
export const fetchAutomobileOcep16ListSuccess = (data: IAutomobileOCEP16[]) =>
  action(FETCH_AUTOMOBILE_OCEP16_LIST_SUCCESS, { data });
export const fetchAutomobileOcep16ListFailed = () =>
  action(FETCH_AUTOMOBILE_OCEP16_LIST_FAILED);

export const fetchAutomobileOcep16ListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileOcep16ListProgress());
      const res = await api.get(
        `/policy/get-policy-auto-opcf-sixteen-certificate?customer_policy_id=${customerPolicyId}`
      );
      const data: IAutomobileOCEP16[] = res.data.data;
      dispatch(fetchAutomobileOcep16ListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileOcep16ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const downloadAutomobileOcep16ListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-opcf-sixteen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileOcep16ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewAutomobileOcep16ListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-opcf-sixteen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_opcf_sixteen_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileOcep16ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const FETCH_AUTOMOBILE_OCEP17_PROGRESS =
  "FETCH_AUTOMOBILE_OCEP17_PROGRESS";
export const FETCH_AUTOMOBILE_OCEP17_SUCCESS =
  "FETCH_AUTOMOBILE_OCEP17_SUCCESS";
export const FETCH_AUTOMOBILE_OCEP17_FAILED = "FETCH_AUTOMOBILE_OCEP17_FAILED";

export const fetchAutomobileOcep17rogress = () =>
  action(FETCH_AUTOMOBILE_OCEP17_PROGRESS);
export const fetchAutomobileOcep17Success = (data: IAutomobileOCEP17) =>
  action(FETCH_AUTOMOBILE_OCEP17_SUCCESS, { data });
export const fetchAutomobileOcep17Failed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_OCEP17_FAILED, { errorMessage });

export const addAutomobileOcep17sync =
  (
    data: IAutomobileOCEP17,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      if (isDuplicate) {
        await api.post("/policy/edit-policy-auto-opcf-seventeen-certificate", {
          ...data,
          incremental_number: null,
        });
      } else {
        await api.post("/policy/edit-policy-auto-opcf-seventeen-certificate", {
          ...data,
        });
      }

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "OPCF17 saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const fetchAutomobileOcep17Async =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileOcep17rogress());
      const res = await api.get(
        `/policy/get-policy-auto-opcf-seventeen-certificate?customer_policy_id=${customerPolicyId}&value=${incremental_number}`
      );
      const data: IAutomobileOCEP17[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileOcep17Success({
            ...data[0],
          })
        );
      } else {
        dispatch(fetchAutomobileOcep17Failed(ERROR_MESSAGES.POLICY_NOT_FOUND));
      }
    } catch (err: any) {
      dispatch(fetchAutomobileOcep17Failed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_OCEP17 = "CLEAR_AUTOMOBILE_OCEP17";
export const clearAutomobileOcep17 = () => action(CLEAR_AUTOMOBILE_OCEP17);

export const FETCH_AUTOMOBILE_OCEP17_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_OCEP17_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_OCEP17_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_OCEP17_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_OCEP17_LIST_FAILED =
  "FETCH_AUTOMOBILE_OCEP17_LIST_FAILED";

export const fetchAutomobileOcep17ListProgress = () =>
  action(FETCH_AUTOMOBILE_OCEP17_LIST_PROGRESS);
export const fetchAutomobileOcep17ListSuccess = (data: IAutomobileOCEP17[]) =>
  action(FETCH_AUTOMOBILE_OCEP17_LIST_SUCCESS, { data });
export const fetchAutomobileOcep17ListFailed = () =>
  action(FETCH_AUTOMOBILE_OCEP17_LIST_FAILED);

export const fetchAutomobileOcep17ListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileOcep17ListProgress());
      const res = await api.get(
        `/policy/get-policy-auto-opcf-seventeen-certificate?customer_policy_id=${customerPolicyId}`
      );
      const data: IAutomobileOCEP17[] = res.data.data;
      dispatch(fetchAutomobileOcep17ListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileOcep17ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const downloadAutomobileOcep17ListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-opcf-seventeen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileOcep17ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewAutomobileOcep17ListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-opcf-seventeen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_opcf_seventeen_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileOcep17ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_AUTOMOBILE_OCEP28A_PROGRESS =
  "FETCH_AUTOMOBILE_OCEP28A_PROGRESS";
export const FETCH_AUTOMOBILE_OCEP28A_SUCCESS =
  "FETCH_AUTOMOBILE_OCEP28A_SUCCESS";
export const FETCH_AUTOMOBILE_OCEP28A_FAILED =
  "FETCH_AUTOMOBILE_OCEP28A_FAILED";

export const fetchAutomobileOcep28Arogress = () =>
  action(FETCH_AUTOMOBILE_OCEP28A_PROGRESS);
export const fetchAutomobileOcep28ASuccess = (data: IAutomobileOCEP28A) =>
  action(FETCH_AUTOMOBILE_OCEP28A_SUCCESS, { data });
export const fetchAutomobileOcep28AFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_OCEP28A_FAILED, { errorMessage });

export const addAutomobileOcep28Async =
  (
    data: IAutomobileOCEP28A,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      if (isDuplicate) {
        await api.post(
          "/policy/edit-policy-auto-opcf-twenty-eight-a-certificate",
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          "/policy/edit-policy-auto-opcf-twenty-eight-a-certificate",
          {
            ...data,
          }
        );
      }

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "OPCF28A saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const fetchAutomobileOcep28AAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileOcep28Arogress());
      const res = await api.get(
        `/policy/get-policy-auto-opcf-twenty-eight-a-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileOCEP28A[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileOcep28ASuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(fetchAutomobileOcep28AFailed(ERROR_MESSAGES.POLICY_NOT_FOUND));
      }
    } catch (err: any) {
      dispatch(fetchAutomobileOcep28AFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_OCEP28A = "CLEAR_AUTOMOBILE_OCEP28A";
export const clearAutomobileOcep28A = () => action(CLEAR_AUTOMOBILE_OCEP28A);

export const FETCH_AUTOMOBILE_OCEP28A_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_OCEP28A_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_OCEP28A_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_OCEP28A_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_OCEP28A_LIST_FAILED =
  "FETCH_AUTOMOBILE_OCEP28A_LIST_FAILED";

export const fetchAutomobileOcep28AListProgress = () =>
  action(FETCH_AUTOMOBILE_OCEP28A_LIST_PROGRESS);
export const fetchAutomobileOcep28AListSuccess = (data: IAutomobileOCEP28A[]) =>
  action(FETCH_AUTOMOBILE_OCEP28A_LIST_SUCCESS, { data });
export const fetchAutomobileOcep28AListFailed = () =>
  action(FETCH_AUTOMOBILE_OCEP28A_LIST_FAILED);

export const fetchAutomobileOcep28AListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileOcep28AListProgress());
      const res = await api.get(
        `/policy/get-policy-auto-opcf-twenty-eight-a-certificate?customer_policy_id=${customerPolicyId}`
      );
      const data: IAutomobileOCEP28A[] = res.data.data;
      dispatch(fetchAutomobileOcep28AListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileOcep28AListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const downloadAutomobileOcep28AListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-opcf-twenty-eight-a-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileOcep28AListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewAutomobileOcep28AListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-opcf-twenty-eight-a-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_opcf_twenty_eight_a_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileOcep28AListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS =
  "FETCH_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS";
export const FETCH_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS =
  "FETCH_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS";
export const FETCH_AUTOMOBILE_LIABILITY_INSURANCE_FAILED =
  "FETCH_AUTOMOBILE_LIABILITY_INSURANCE_FAILED";

export const fetchAutomobileLiabilityInsuranceProgress = () =>
  action(FETCH_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS);
export const fetchAutomobileLiabilityInsuranceSuccess = (
  data: IAutomobileLiabilityInsurance
) => action(FETCH_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS, { data });
export const fetchAutomobileLiabilityInsuranceFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_LIABILITY_INSURANCE_FAILED, { errorMessage });

export const addAutomobileLiabilityInsuranceAsync =
  (
    data: IAutomobileLiabilityInsurance,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      if (isDuplicate) {
        await api.post(
          "/policy/edit-policy-auto-add-liability-insurance-certificate",
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          "/policy/edit-policy-auto-add-liability-insurance-certificate",
          {
            ...data,
          }
        );
      }

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Liability insurance saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const fetchAutomobileLiabilityInsuranceAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileLiabilityInsuranceProgress());
      const res = await api.get(
        `/policy/get-policy-auto-add-liability-insurance-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileLiabilityInsurance[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileLiabilityInsuranceSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchAutomobileLiabilityInsuranceFailed(
            ERROR_MESSAGES.POLICY_NOT_FOUND
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchAutomobileLiabilityInsuranceFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_LIABILITY_INSURANCE =
  "CLEAR_AUTOMOBILE_LIABILITY_INSURANCE";
export const clearAutomobileLiabiliyInsurance = () =>
  action(CLEAR_AUTOMOBILE_LIABILITY_INSURANCE);

export const FETCH_AUTOMOBILE_LIABILITY_INSURANCE_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_LIABILITY_INSURANCE_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_LIABILITY_INSURANCE_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_LIABILITY_INSURANCE_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_LIABILITY_INSURANCE_LIST_FAILED =
  "FETCH_AUTOMOBILE_LIABILITY_INSURANCE_LIST_FAILED";

export const fetchAutomobileLiabilityInsuranceListProgress = () =>
  action(FETCH_AUTOMOBILE_LIABILITY_INSURANCE_LIST_PROGRESS);
export const fetchAutomobileLiabilityInsuranceListSuccess = (
  data: IAutomobileLiabilityInsurance[]
) => action(FETCH_AUTOMOBILE_LIABILITY_INSURANCE_LIST_SUCCESS, { data });
export const fetchAutomobileLiabilityInsuranceListFailed = () =>
  action(FETCH_AUTOMOBILE_LIABILITY_INSURANCE_LIST_FAILED);

export const fetchAutomobileLiabilityInsuranceListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileLiabilityInsuranceListProgress());
      const res = await api.get(
        `/policy/get-policy-auto-add-liability-insurance-certificate?customer_policy_id=${customerPolicyId}`
      );
      const data: IAutomobileLiabilityInsurance[] = res.data.data;
      dispatch(fetchAutomobileLiabilityInsuranceListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileLiabilityInsuranceListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

  //----------Consent Form--------------------->

  export const FETCH_AUTOMOBILE_CONSENT_FORM_PROGRESS =
  "FETCH_AUTOMOBILE_CONSENT_FORM_PROGRESS";
 export const FETCH_AUTOMOBILE_CONSENT_FORM_SUCCESS =
   "FETCH_AUTOMOBILE_CONSENT_FORM_SUCCESS";
 export const FETCH_AUTOMOBILE_CONSENT_FORM_FAILED =
   "FETCH_AUTOMOBILE_CONSENT_FORM_FAILED";
 
 export const fetchAutomobileConsentFormProgress = () =>
   action(FETCH_AUTOMOBILE_CONSENT_FORM_PROGRESS);
 export const fetchAutomobileConsentFormSuccess = (data: IAutomobileConsentForm) =>
   action(FETCH_AUTOMOBILE_CONSENT_FORM_SUCCESS, { data });
 export const fetchAutomobileConsentFormFailed = (errorMessage: string) =>
   action(FETCH_AUTOMOBILE_CONSENT_FORM_FAILED, { errorMessage });
 
 export const addAutomobileConsentFormAsync =
   (
     data: IAutomobileConsentForm,
     onCallback: (isSuccess: boolean) => void,
     isDuplicate?: boolean
   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
   async (dispatch, getState) => {
     try {
       dispatch(saveLoaderProgress());
       await api.post(
         "/policy/edit-policy-form-privacy-consent-form",
         {
           ...data,
         }
       );
       onCallback(true);
       dispatch(
         showMessage({
           type: "success",
           message: "Conset Form saved successfully!",
           displayAs: "snackbar",
         })
       );
     } catch (err: any) {
       onCallback(false);
       dispatch(
         showMessage({
           type: "error",
           message: err.response.data.message,
           displayAs: "snackbar",
         })
       );
     } finally {
       dispatch(saveLoaderCompleted());
     }
   };
 
   export const fetchAutomobileConsentFormAsync =
   (
     customerPolicyId: number,
     incremental_number: number
   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
   async (dispatch, getState) => {
     try {
       dispatch(fetchAutomobileConsentFormProgress());
       const res = await api.get(
         `/policy/get-policy-form-privacy-consent-form?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
       );
       const data: IAutomobileConsentForm[] = res.data.data;
       if (data.length > 0) {
         dispatch(
           fetchAutomobileConsentFormSuccess({
             ...data[0],
           })
         );
       } else {
         dispatch(
           fetchAutomobileConsentFormFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
         );
       }
     } catch (err: any) {
       dispatch(fetchAutomobileConsentFormFailed(ERROR_MESSAGES.SERVER_ERROR));
       dispatch(
         showMessage({
           type: "error",
           message: err.response.data.message,
           displayAs: "snackbar",
         })
       );
     }
   };
 
 export const CLEAR_AUTOMOBILE_CONSENT_FORM = "CLEAR_AUTOMOBILE_CONSENT_FORM";
 export const clearAutomobileConsentForm = () =>
   action(CLEAR_AUTOMOBILE_CONSENT_FORM);
 
 export const FETCH_AUTOMOBILE_CONSENT_FORM_LIST_PROGRESS =
 "FETCH_AUTOMOBILE_CONSENT_FORM_LIST_PROGRESS";
 export const FETCH_AUTOMOBILE_CONSENT_FORM_LIST_SUCCESS =
 "FETCH_AUTOMOBILE_CONSENT_FORM_LIST_SUCCESS";
 export const FETCH_AUTOMOBILE_CONSENT_FORM_LIST_FAILED =
   "FETCH_AUTOMOBILE_CONSENT_FORM_LIST_FAILED";
 
 
 export const fetchAutomobileConsentFormListProgress = () =>
   action(FETCH_AUTOMOBILE_CONSENT_FORM_LIST_PROGRESS);
 export const fetchAutomobileConsentFormListSuccess = (
   data: IAutomobileConsentForm[]
 ) => action(FETCH_AUTOMOBILE_CONSENT_FORM_LIST_SUCCESS, { data });
 export const fetchAutomobileConsentFormListFailed = () =>
   action(FETCH_AUTOMOBILE_CONSENT_FORM_LIST_FAILED);
 
 export const fetchAutomobileConsentFormListAsync = (customerPolicyId: number):ThunkAction<void, IStoreState, {}, AnyAction> => 
   async (dispatch, getState) => {
     try {
       dispatch(fetchAutomobileConsentFormListProgress());
       const res = await api.get(`/policy/get-policy-form-privacy-consent-form?customer_policy_id=${customerPolicyId}`);
 
       const data :IAutomobileConsentForm[] =res.data.data;
       dispatch(fetchAutomobileConsentFormListSuccess(data))
     } catch (err: any) {
       dispatch(fetchAutomobileConsentFormListFailed());
       dispatch(
         showMessage({
           type: "error",
           message: err.response.data.message,
           displayAs: "snackbar",
         })
       );
     }
   }
 
   export const downloadAutomobileConsentFormPDFAsync =
   (
     customerPolicyId: number,
     incremental_number: number,
     onCallback: () => void
   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
   async (dispatch, getState) => {
     try {
       const res = await api.get(
         `/policy/get-policy-form-privacy-consent-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
         {
           responseType: "arraybuffer",
         }
       );
 
       const pdfBuffer = res.data;
 
       const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });
 
       const pdfUrl = URL.createObjectURL(pdfBlob);
 
       const link = document.createElement("a");
       link.href = pdfUrl;
       link.download = "downloaded.pdf";
       link.click();
 
       URL.revokeObjectURL(pdfUrl);
       onCallback();
     } catch (err: any) {
       dispatch(fetchAutomobileConsentFormListFailed());
       dispatch(
         showMessage({
           type: "error",
           message: err.response.data.message,
           displayAs: "snackbar",
         })
       );
     }
   };
 
 export const previewAutomobileConsentFormPDFAsync =
   (
     customerPolicyId: number,
     incremental_number: number,
     onCallback: () => void
   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
   async (dispatch, getState) => {
     try {
       const res = await api.get(
         `/policy/get-policy-form-privacy-consent-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_privacy_consent_preview=true`
       );
 
       for (const key in res.data.data) {
         const newTab = window.open("", "_blank");
         if (newTab) {
           newTab.document.write(res.data.data[key]);
           newTab.document.close();
         }
       }
       onCallback();
     } catch (err: any) {
       dispatch(fetchAutomobileConsentFormListFailed());
       dispatch(
         showMessage({
           type: "error",
           message: err.response.data.message,
           displayAs: "snackbar",
         })
       );
     }
   };

   //---------------Retiree Discount-------------------->

   export const FETCH_AUTOMOBILE_RETIREE_DISCOUNT_PROGRESS =
   "FETCH_AUTOMOBILE_RETIREE_DISCOUNT_PROGRESS";
  export const FETCH_AUTOMOBILE_RETIREE_DISCOUNT_SUCCESS =
    "FETCH_AUTOMOBILE_RETIREE_DISCOUNT_SUCCESS";
  export const FETCH_AUTOMOBILE_RETIREE_DISCOUNT_FAILED =
    "FETCH_AUTOMOBILE_RETIREE_DISCOUNT_FAILED";
  
  export const fetchAutomobileRetireeDiscountProgress = () =>
    action(FETCH_AUTOMOBILE_RETIREE_DISCOUNT_PROGRESS);
  export const fetchAutomobileRetireeDiscountSuccess = (data: IAutomobileRetireeDiscount) =>
    action(FETCH_AUTOMOBILE_RETIREE_DISCOUNT_SUCCESS, { data });
  export const fetchAutomobileRetireeDiscountFailed = (errorMessage: string) =>
    action(FETCH_AUTOMOBILE_RETIREE_DISCOUNT_FAILED, { errorMessage });
  
  export const addAutomobileRetireeDiscountAsync =
    (
      data: IAutomobileRetireeDiscount,
      onCallback: (isSuccess: boolean) => void,
      isDuplicate?: boolean
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(saveLoaderProgress());
        await api.post(
          "/policy/edit-policy-form-declaration-for-retiree-discount",
          {
            ...data,
          }
        );
        onCallback(true);
        dispatch(
          showMessage({
            type: "success",
            message: "Conset Form saved successfully!",
            displayAs: "snackbar",
          })
        );
      } catch (err: any) {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      } finally {
        dispatch(saveLoaderCompleted());
      }
    };
  
    export const fetchAutomobileRetireeDiscountAsync =
    (
      customerPolicyId: number,
      incremental_number: number
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchAutomobileRetireeDiscountProgress());
        const res = await api.get(
          `/policy/get-policy-form-declaration-for-retiree-discount?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
        );
        const data: IAutomobileRetireeDiscount[] = res.data.data;
        if (data.length > 0) {
          dispatch(
            fetchAutomobileRetireeDiscountSuccess({
              ...data[0],
            })
          );
        } else {
          dispatch(
            fetchAutomobileRetireeDiscountFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
          );
        }
      } catch (err: any) {
        dispatch(fetchAutomobileRetireeDiscountFailed(ERROR_MESSAGES.SERVER_ERROR));
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      }
    };
  
  export const CLEAR_AUTOMOBILE_RETIREE_DISCOUNT = "CLEAR_AUTOMOBILE_RETIREE_DISCOUNT";
  export const clearAutomobileRetireeDiscount = () =>
    action(CLEAR_AUTOMOBILE_RETIREE_DISCOUNT);
  
  export const FETCH_AUTOMOBILE_RETIREE_DISCOUNT_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_RETIREE_DISCOUNT_LIST_PROGRESS";
  export const FETCH_AUTOMOBILE_RETIREE_DISCOUNT_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_RETIREE_DISCOUNT_LIST_SUCCESS";
  export const FETCH_AUTOMOBILE_RETIREE_DISCOUNT_LIST_FAILED =
    "FETCH_AUTOMOBILE_RETIREE_DISCOUNT_LIST_FAILED";
  
  
  export const fetchAutomobileRetireeDiscountListProgress = () =>
    action(FETCH_AUTOMOBILE_RETIREE_DISCOUNT_LIST_PROGRESS);
  export const fetchAutomobileRetireeDiscountListSuccess = (
    data: IAutomobileRetireeDiscount[]
  ) => action(FETCH_AUTOMOBILE_RETIREE_DISCOUNT_LIST_SUCCESS, { data });
  export const fetchAutomobileRetireeDiscountListFailed = () =>
    action(FETCH_AUTOMOBILE_RETIREE_DISCOUNT_LIST_FAILED);
  
  export const fetchAutomobileRetireeDiscountListAsync = (customerPolicyId: number):ThunkAction<void, IStoreState, {}, AnyAction> => 
    async (dispatch, getState) => {
      try {
        dispatch(fetchAutomobileRetireeDiscountListProgress());
        const res = await api.get(`/policy/get-policy-form-declaration-for-retiree-discount?customer_policy_id=${customerPolicyId}`);
  
        const data :IAutomobileRetireeDiscount[] =res.data.data;
        dispatch(fetchAutomobileRetireeDiscountListSuccess(data))
      } catch (err: any) {
        dispatch(fetchAutomobileRetireeDiscountListFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      }
    }
  
    export const downloadAutomobileRetireeDiscountPDFAsync =
    (
      customerPolicyId: number,
      incremental_number: number,
      onCallback: () => void
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const res = await api.get(
          `/policy/get-policy-form-declaration-for-retiree-discount-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
          {
            responseType: "arraybuffer",
          }
        );
  
        const pdfBuffer = res.data;
  
        const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });
  
        const pdfUrl = URL.createObjectURL(pdfBlob);
  
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "downloaded.pdf";
        link.click();
  
        URL.revokeObjectURL(pdfUrl);
        onCallback();
      } catch (err: any) {
        dispatch(fetchAutomobileRetireeDiscountListFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      }
    };
  
  export const previewAutomobileRetireeDiscountPDFAsync =
    (
      customerPolicyId: number,
      incremental_number: number,
      onCallback: () => void
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const res = await api.get(
          `/policy/get-policy-form-declaration-for-retiree-discount-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_declaration_for_retiree_discount_preview=true`
        );
  
        for (const key in res.data.data) {
          const newTab = window.open("", "_blank");
          if (newTab) {
            newTab.document.write(res.data.data[key]);
            newTab.document.close();
          }
        }
        onCallback();
      } catch (err: any) {
        dispatch(fetchAutomobileRetireeDiscountListFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      }
    };

//----------------------Garage Form ------------------->

    export const FETCH_AUTOMOBILE_GARAGE_FORM_PROGRESS =
 "FETCH_AUTOMOBILE_GARAGE_FORM_PROGRESS";
export const FETCH_AUTOMOBILE_GARAGE_FORM_SUCCESS =
  "FETCH_AUTOMOBILE_GARAGE_FORM_SUCCESS";
export const FETCH_AUTOMOBILE_GARAGE_FORM_FAILED =
  "FETCH_AUTOMOBILE_GARAGE_FORM_FAILED";

export const fetchAutomobileGarageFormProgress = () =>
  action(FETCH_AUTOMOBILE_GARAGE_FORM_PROGRESS);
export const fetchAutomobileGarageFormSuccess = (data: IAutomobileGarageForm) =>
  action(FETCH_AUTOMOBILE_GARAGE_FORM_SUCCESS, { data });
export const fetchAutomobileGarageFormFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_GARAGE_FORM_FAILED, { errorMessage });

export const addAutomobileGarageFormAsync =
  (
    data: IAutomobileGarageForm,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        "/policy/edit-policy-form-alberta-application-garage-form",
        {
          ...data,
        }
      );
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

  export const fetchAutomobileGarageFormAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileGarageFormProgress());
      const res = await api.get(
        `/policy/get-policy-form-alberta-application-garage-form?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileGarageForm[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileGarageFormSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchAutomobileGarageFormFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchAutomobileGarageFormFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_GARAGE_FORM = "CLEAR_AUTOMOBILE_GARAGE_FORM";
export const clearAutomobileGarageForm = () =>
  action(CLEAR_AUTOMOBILE_GARAGE_FORM);

export const FETCH_AUTOMOBILE_GARAGE_FORM_LIST_PROGRESS =
"FETCH_AUTOMOBILE_GARAGE_FORM_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_GARAGE_FORM_LIST_SUCCESS =
"FETCH_AUTOMOBILE_GARAGE_FORM_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_GARAGE_FORM_LIST_FAILED =
  "FETCH_AUTOMOBILE_GARAGE_FORM_LIST_FAILED";


export const fetchAutomobileGarageFormListProgress = () =>
  action(FETCH_AUTOMOBILE_GARAGE_FORM_LIST_PROGRESS);
export const fetchAutomobileGarageFormListSuccess = (
  data: IAutomobileGarageForm[]
) => action(FETCH_AUTOMOBILE_GARAGE_FORM_LIST_SUCCESS, { data });
export const fetchAutomobileGarageFormListFailed = () =>
  action(FETCH_AUTOMOBILE_GARAGE_FORM_LIST_FAILED);

export const fetchAutomobileGarageFormListAsync = (customerPolicyId: number):ThunkAction<void, IStoreState, {}, AnyAction> => 
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileGarageFormListProgress());
      const res = await api.get(`/policy/get-policy-form-alberta-application-garage-form?customer_policy_id=${customerPolicyId}`);

      const data :IAutomobileGarageForm[] =res.data.data;
      dispatch(fetchAutomobileGarageFormListSuccess(data))
    } catch (err: any) {
      dispatch(fetchAutomobileGarageFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  }

  export const downloadAutomobileGarageFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-alberta-application-garage-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileGarageFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewAutomobileGarageFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-alberta-application-garage-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_business_privacy_consent_form_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileGarageFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

  //----------------Ride Sharing-------------->

  export const FETCH_AUTOMOBILE_SHARING_FILLABLE_PROGRESS =
 "FETCH_AUTOMOBILE_SHARING_FILLABLE_PROGRESS";
export const FETCH_AUTOMOBILE_SHARING_FILLABLE_SUCCESS =
  "FETCH_AUTOMOBILE_SHARING_FILLABLE_SUCCESS";
export const FETCH_AUTOMOBILE_SHARING_FILLABLE_FAILED =
  "FETCH_AUTOMOBILE_SHARING_FILLABLE_FAILED";

export const fetchAutomobileSharingFillableProgress = () =>
  action(FETCH_AUTOMOBILE_SHARING_FILLABLE_PROGRESS);
export const fetchAutomobileSharingFillableSuccess = (data: IAutomobileSharingFillable) =>
  action(FETCH_AUTOMOBILE_SHARING_FILLABLE_SUCCESS, { data });
export const fetchAutomobileSharingFillableFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_SHARING_FILLABLE_FAILED, { errorMessage });

export const addAutomobileSharingFillableAsync =
  (
    data: IAutomobileSharingFillable,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        "/policy/edit-policy-form-ride-sharing-fillable",
        {
          ...data,
        }
      );
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

  export const fetchAutomobileSharingFillableAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileSharingFillableProgress());
      const res = await api.get(
        `/policy/get-policy-form-ride-sharing-fillable?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileSharingFillable[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileSharingFillableSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchAutomobileSharingFillableFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchAutomobileSharingFillableFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_SHARING_FILLABLE = "CLEAR_AUTOMOBILE_SHARING_FILLABLE";
export const clearAutomobileSharingFillable = () =>
  action(CLEAR_AUTOMOBILE_SHARING_FILLABLE);

export const FETCH_AUTOMOBILE_SHARING_FILLABLE_LIST_PROGRESS =
"FETCH_AUTOMOBILE_SHARING_FILLABLE_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_SHARING_FILLABLE_LIST_SUCCESS =
"FETCH_AUTOMOBILE_SHARING_FILLABLE_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_SHARING_FILLABLE_LIST_FAILED =
  "FETCH_AUTOMOBILE_SHARING_FILLABLE_LIST_FAILED";


export const fetchAutomobileSharingFillableListProgress = () =>
  action(FETCH_AUTOMOBILE_SHARING_FILLABLE_LIST_PROGRESS);
export const fetchAutomobileSharingFillableListSuccess = (
  data: IAutomobileSharingFillable[]
) => action(FETCH_AUTOMOBILE_SHARING_FILLABLE_LIST_SUCCESS, { data });
export const fetchAutomobileSharingFillableListFailed = () =>
  action(FETCH_AUTOMOBILE_SHARING_FILLABLE_LIST_FAILED);

export const fetchAutomobileSharingFillableListAsync = (customerPolicyId: number):ThunkAction<void, IStoreState, {}, AnyAction> => 
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileSharingFillableListProgress());
      const res = await api.get(`/policy/get-policy-form-ride-sharing-fillable?customer_policy_id=${customerPolicyId}`);

      const data :IAutomobileSharingFillable[] =res.data.data;
      dispatch(fetchAutomobileSharingFillableListSuccess(data))
    } catch (err: any) {
      dispatch(fetchAutomobileSharingFillableListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  }

  export const downloadAutomobileSharingFillablePDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-ride-sharing-fillable-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileSharingFillableListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewAutomobileSharingFillablePDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-ride-sharing-fillable-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_ride_sharing_fillable_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileSharingFillableListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

//---------------Supplementary Form---------------->

export const FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_PROGRESS =
 "FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_PROGRESS";
export const FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_SUCCESS =
  "FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_SUCCESS";
export const FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_FAILED =
  "FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_FAILED";

export const fetchAutomobileSupplementaryFormProgress = () =>
  action(FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_PROGRESS);
export const fetchAutomobileSupplementaryFormSuccess = (data: IAutomobileSupplementaryForm) =>
  action(FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_SUCCESS, { data });
export const fetchAutomobileSupplementaryFormFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_FAILED, { errorMessage });

export const addAutomobileSupplementaryFormAsync =
  (
    data: IAutomobileSupplementaryForm,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        "/policy/edit-policy-form-garage-automobile-supplementary-form",
        {
          ...data,
        }
      );
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

  export const fetchAutomobileSupplementaryFormAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileSupplementaryFormProgress());
      const res = await api.get(
        `/policy/get-policy-form-garage-automobile-supplementary-form?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileSupplementaryForm[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileSupplementaryFormSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchAutomobileSupplementaryFormFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchAutomobileSupplementaryFormFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_SUPPLEMENTARY_FORM = "CLEAR_AUTOMOBILE_SUPPLEMENTARY_FORM";
export const clearAutomobileSupplementaryForm = () =>
  action(CLEAR_AUTOMOBILE_SUPPLEMENTARY_FORM);

export const FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_PROGRESS =
"FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_SUCCESS =
"FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_FAILED =
  "FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_FAILED";


export const fetchAutomobileSupplementaryFormListProgress = () =>
  action(FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_PROGRESS);
export const fetchAutomobileSupplementaryFormListSuccess = (
  data: IAutomobileSupplementaryForm[]
) => action(FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_SUCCESS, { data });
export const fetchAutomobileSupplementaryFormListFailed = () =>
  action(FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_LIST_FAILED);

export const fetchAutomobileSupplementaryFormListAsync = (customerPolicyId: number):ThunkAction<void, IStoreState, {}, AnyAction> => 
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileSupplementaryFormListProgress());
      const res = await api.get(`/policy/get-policy-form-garage-automobile-supplementary-form?customer_policy_id=${customerPolicyId}`);

      const data :IAutomobileSupplementaryForm[] =res.data.data;
      dispatch(fetchAutomobileSupplementaryFormListSuccess(data))
    } catch (err: any) {
      dispatch(fetchAutomobileSupplementaryFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  }

  export const downloadAutomobileSupplementaryFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-garage-automobile-supplementary-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileSupplementaryFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewAutomobileSupplementaryFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-garage-automobile-supplementary-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_garage_automobile_supplementary_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileSupplementaryFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };