import moment, { Moment } from "moment";
import { LoadState } from "../../../constants/enums";

export interface ITaskRiskLocationState {
  addRiskLocation: {
    data: IAddRiskLocation;
    loading: LoadState;
  };
}

export interface IAddRiskLocation {
  task_id: number;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: string | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  interested_party_code: null,
  effective_date_of_change: null,
  ffective_time_of_change: Moment,
  status: null,
  change_details: null,
  file_upload: null
}

export const initialTaskRiskLocationState: ITaskRiskLocationState = {
  addRiskLocation: {
    data: {
      task_id: 0,
      customer_code: null,
      customer_name: null,
      policy_number: null,
      line_of_business: null,
      policy_type: null,
      branch: null,
      broker_code: null,
      insurer: null,
      producer: null,
      csr: null,
    interested_party_code: null,
    effective_date_of_change: null,
    ffective_time_of_change: moment().startOf("day"),
    status: null,
    change_details: null,
    file_upload: null
    },
    loading: LoadState.Loaded,
  },
};
