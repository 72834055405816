import { LoadState } from "../../constants/enums";

export interface IAdditionalInterestState {
  additionalIntrest: {
    data: IAdditionalInterest;
    loading: LoadState;
    saveLoading: boolean;
    error: string | null;
    list: IAdditionalInterest[];
    listLoading: LoadState;
  };
}


export interface IAdditionalInterest {
  additional_interest_id: string | null;
  name: string | null;
  interest: string | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  state_or_province: string | null;
  postal_code: string | null;
  country: string | null;
  status: string;
}

export const initialAdditionalInterestState: IAdditionalInterestState = {
  additionalIntrest: {
    list: [],
    listLoading: LoadState.NotLoaded,
    data: {
      additional_interest_id: null,
      name: null,
      interest: null,
      address: null,
      unit_or_suite: null,
      city: null,
      state_or_province: null,
      postal_code: null,
      country: null,
      status: "Active",
    },
    loading: LoadState.NotLoaded,
    saveLoading: false,
    error: null,
  },
};
