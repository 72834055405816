import { action } from "typesafe-actions";
import {
  IRecievable,
  initialAccountingRecievableState,
} from "./Recievable.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";

export const FETCH_ACCOUNTING_RECIEVABLE_PROGRESS =
  "FETCH_ACCOUNTING_RECIEVABLE_PROGRESS";
export const FETCH_ACCOUNTING_RECIEVABLE_SUCCESS =
  "FETCH_ACCOUNTING_RECIEVABLE_SUCCESS";
export const FETCH_ACCOUNTING_RECIEVABLE_FAILURE =
  "FETCH_ACCOUNTING_RECIEVABLE_FAILURE";

export const fetchAccountingRecievableProgress = () =>
  action(FETCH_ACCOUNTING_RECIEVABLE_PROGRESS);
export const fetchAccountingRecievableSuccess = (data: IRecievable) =>
  action(FETCH_ACCOUNTING_RECIEVABLE_SUCCESS, { data });
export const fetchAccountingRecievableFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_RECIEVABLE_FAILURE, { errorMessage });

export const fetchAccountingRecievableAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingRecievableProgress());

      const res = await api.get(`/accounting/get-account-receivable`);
      const data: IRecievable[] = res.data.data;

      if (data.length > 0) {
        dispatch(fetchAccountingRecievableSuccess(data[0]));
      } else {
        dispatch(
          fetchAccountingRecievableSuccess(
            initialAccountingRecievableState["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchAccountingRecievableFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingRecievableAysnc =
  (
    data: IRecievable,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/accounting/edit-account-receivable", {
        ...data,
      });
      let message = "Receivable saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const FETCH_ACCOUNTING_RECIEVABLE_LIST_PROGRESS =
  "FETCH_ACCOUNTING_RECIEVABLE_LIST_PROGRESS";
export const FETCH_ACCOUNTING_RECIEVABLE_LIST_SUCCESS =
  "FETCH_ACCOUNTING_RECIEVABLE_LIST_SUCCESS";
export const FETCH_ACCOUNTING_RECIEVABLE_LIST_FAILURE =
  "FETCH_ACCOUNTING_RECIEVABLE_LIST_FAILURE";

export const fetchAccountingRecievableListProgress = () =>
  action(FETCH_ACCOUNTING_RECIEVABLE_LIST_PROGRESS);
export const fetchAccountingRecievableListSuccess = (data: IRecievable[]) =>
  action(FETCH_ACCOUNTING_RECIEVABLE_LIST_SUCCESS, { data });
export const fetchAccountingRecievableListFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_RECIEVABLE_LIST_FAILURE, { errorMessage });

export const fetchAccountingRecievableListAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingRecievableListProgress());

      const res = await api.get(`/accounting/get-account-receivable`);
      const data: IRecievable[] = res.data.data;
      dispatch(fetchAccountingRecievableListSuccess(data));
    } catch (err: any) {
      dispatch(
        fetchAccountingRecievableListFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_ACCOUNTING_RECIEVABLE = "CLEAR_ACCOUNTING_RECIEVABLE";
export const clearAccountingRecievable = () =>
  action(CLEAR_ACCOUNTING_RECIEVABLE);
