import { LoadState } from "../../constants/enums";

export interface IEDocsState {
    list: IEDocs[];
    totalRecords: number;
    loading: LoadState;
    eDoc: IEDocs;
    eDocLoading: LoadState;
    error: string | null;
}

export interface IEDocs {
    customer: number | null; 
    document_type: string | null;
    customer_name: string | null;
    policy_number: string | null;
    description: string | null;
    producer: string | null;
    csr: string | null;
    branch: string | null;
    line_of_business: string | null;
    file_attachment: string | null;
    status: string | null;
    comments: string | null;
    date_created: string | null;
    due_date: string | null;
    time: string | null;
    assign_to: string | null;
}

export const initialEDocs: IEDocs = {
    customer: null, 
    document_type: null,
    customer_name: null,
    policy_number: null,
    description:  null,
    producer:  null,
    csr: null,
    branch: null,
    line_of_business: null,
    file_attachment: null,
    comments: null,
    date_created: null,
    due_date: null,
    time: null,
    assign_to: null,
    status: "Close",
}

export const initialEDocsState: IEDocsState = {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    eDoc: initialEDocs,
    eDocLoading: LoadState.NotLoaded,
    error: null,
}