import { FormikConfig, FormikHelpers, FormikValues, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatchWrapper } from "./useDispatch";
import { initiateWorkFlow } from "../redux/common/commonActions";

interface UseTrackedFormikConfig<T> extends Omit<FormikConfig<T>, "onSubmit"> {
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => Promise<void> | void;
  apiUrl: string;
  isUpdate?: boolean;
  primaryFieldName?: string;
  primaryFieldValue?: any;
}

export const useFormikAutomation = <T extends FormikValues>({
  initialValues,
  onSubmit,
  apiUrl,
  isUpdate,
  primaryFieldName,
  primaryFieldValue,
  ...formikConfig
}: UseTrackedFormikConfig<T>) => {
  const [initialData, setInitialData] = useState(initialValues);
  const dispatch = useDispatchWrapper();

  useEffect(() => {
    setInitialData(initialValues);
  }, [initialValues]);

  const formik = useFormik<T>({
    initialValues,
    //enableReinitialize: true,
    onSubmit: async (values, formikHelpers) => {
      try {
        await onSubmit(values, formikHelpers);

        dispatch(
          initiateWorkFlow(
            initialData,
            values,
            apiUrl,
            isUpdate || false,
            primaryFieldName || "",
            primaryFieldValue
          )
        );
      } catch (error) {
        console.error("Error during submission", error);
      }
    },
    ...formikConfig,
  });

  return formik;
};
