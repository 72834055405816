import produce from "immer";
import { IInteracEmailTransferActions } from ".";
import { IStoreState } from "../../initialStoreState";
import {
  CLEAR_ACCOUNTING_INTERAC_EMAIL_TRANSFER,
  FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_FAILURE,
  FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_LIST_FAILURE,
  FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_LIST_PROGRESS,
  FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_LIST_SUCCESS,
  FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_PROGRESS,
  FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_SUCCESS,
} from "./InteracEmailTransferActions";
import { LoadState } from "../../../constants/enums";
import { initialAccountingInteracEmailTransferState } from "./InteracEmailTransfer.types";

export const AccountingIInteracEmailTransferReducer = (
  state: IStoreState["accounting"]["interacEmailTransfer"] = initialAccountingInteracEmailTransferState,
  action: IInteracEmailTransferActions
) => {
  switch (action.type) {
    case FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Loaded;
        draftState.list = data;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_INTERAC_EMAIL_TRANSFER_LIST_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Failed;
        draftState.listError = errorMessage;
      });
      return newState;
    }

    case CLEAR_ACCOUNTING_INTERAC_EMAIL_TRANSFER: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.NotLoaded;
        draftState.data = initialAccountingInteracEmailTransferState.data;
        draftState.error = null;
      });
      return newState;
    }

    default:
      return state;
  }
};
