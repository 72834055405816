import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";
import { ILifeInsurancePolcyComplianceCheckList } from "./lifeInsurancePolicyComplianceCheckList.types";

export const FETCH_LIFE_INSURANCE_COMPLIANCE_CHECKLIST_PROGRESS =
  "FETCH_LIFE_INSURANCE_COMPLIANCE_CHECKLIST_PROGRESS";
export const FETCH_LIFE_INSURANCE_COMPLIANCE_CHECKLIST_SUCCESS =
  "FETCH_LIFE_INSURANCE_COMPLIANCE_CHECKLIST_SUCCESS";
export const FETCH_LIFE_INSURANCE_COMPLIANCE_CHECKLIST_FAILED =
  "FETCH_LIFE_INSURANCE_COMPLIANCE_CHECKLIST_FAILED";

export const fetchLifeInsuranceComplianceCheckListProgress = () =>
  action(FETCH_LIFE_INSURANCE_COMPLIANCE_CHECKLIST_PROGRESS);
export const fetchLifeInsuranceComplianceCheckListSuccess = (
  data: ILifeInsurancePolcyComplianceCheckList
) => action(FETCH_LIFE_INSURANCE_COMPLIANCE_CHECKLIST_SUCCESS, { data });
export const fetchLifeInsuranceComplianceCheckListFailed = (
  errorMessage: string
) => action(FETCH_LIFE_INSURANCE_COMPLIANCE_CHECKLIST_FAILED, { errorMessage });

export const fetchLifeInsuranceComplianceCheckListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchLifeInsuranceComplianceCheckListProgress());
      const res = await api.get(
        `/policy/get-life-policy-compliance-checklist?customer_policy_id=${customerPolicyId}`
      );
      const data: ILifeInsurancePolcyComplianceCheckList[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchLifeInsuranceComplianceCheckListSuccess(data[0]));
      } else {
        dispatch(
          fetchLifeInsuranceComplianceCheckListFailed(
            "Oops! We couldn't find any records associated with your restrutant number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
        //   }
      }
    } catch (err: any) {
      dispatch(
        fetchLifeInsuranceComplianceCheckListFailed(
          "Something went to be wrong!"
        )
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addLifeInsuranceComplianceCheckListAsync =
  (
    data: ILifeInsurancePolcyComplianceCheckList,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post(`/policy/edit-life-policy-compliance-checklist`, {
        ...data
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Compliance Checklist saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const CLEAR_LIFE_INSURANCE_COMPLIANCE_CHECKLIST_STATE =
  "CLEAR_LIFE_INSURANCE_COMPLIANCE_CHECKLIST_STATE";
export const CLEAR_LIFE_INSURANCE_COMPLIANCE_CHECKLIST =
  "CLEAR_LIFE_INSURANCE_COMPLIANCE_CHECKLIST";

export const clearLifeInsuranceCOMPLIANCECheckListState = () =>
  action(CLEAR_LIFE_INSURANCE_COMPLIANCE_CHECKLIST_STATE);

export const cleaLifeInsuranceCOMPLIANCECheckList = () =>
  action(CLEAR_LIFE_INSURANCE_COMPLIANCE_CHECKLIST);

// /policy/edit-life-policy-compliance-checklist
// /policy/get-life-policy-compliance-checklist
