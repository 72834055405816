import moment from "moment";
import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface IAccountingInvoiceInformationState {
  list: IInvoiceInformation[];
  listLoading: LoadState;
  data: IInvoiceInformation;
  loading: LoadState;
  error: string | null;
  totalRecords: number;
  paymentHistory: IInvoicePaymentHistory;
  paymentHistoryLoading: LoadState;
}

export interface IInvoiceDetails {
  key: string;
  type: string | null;
  transaction_type: string | null;
  detail: string | null;
  description: string | null;
  amount: string;
  tax_one_percentage: string;
  tax_one_amount: string;
  tax_two_percentage: string;
  tax_two_amount: string;
  commission_percentage: string;
  commission_amount: string;
  house_percentage: string;
  house_amount: string;
  producer_one_percentage: string;
  producer_one_amount: string;
  producer_two_percentage: string;
  producer_two_amount: string;
  producer_three_percentage: string;
  producer_three_amount: string;
  branch_percentage: string;
  branch_amount: string;
  net_payable_insurer: string;
  total_amount: string;
  status: string;
}

export interface IInvoiceInformation {
  invoice_id: string | null;
  invoice_number: string | null;
  invoice_due_date: string | null;
  payment_terms: string | null;
  accounting_information_id: string | null;
  gl_sales_account_name: string | null;
  invoice_for: string;
  name: string | null;
  code: string | null;
  policy_id: string | null;
  policy_number: string;
  type_of_policy: string;
  policy_transaction_type: string | null;
  policy_comission_code: string | null;
  policy_status: string | null;
  transaction_date: string | null;
  effective_date: string | null;
  default_tax_region: string | null;
  agency_or_direct_bill: string | null;
  insurer_code: string | null;
  branch_code: string | null;
  branch: string | null;
  premium_financed: string | null;
  financed_by: string | null;
  invoice_details: IInvoiceDetails[];
  sub_total_dollar: string | null;
  net_payable_insurer: string;
  tax_one_total: string | null;
  tax_two_total: string | null;
  commission_total: string | null;
  is_mannual_invoice: boolean;
  invoice_notes: string | null;
  invoice_footer_information: string | null;
  total_due_dollar: string | null;
  invoice_status: string | null;
  status: string;
  policy_type: string | null;
  financed_fee: string | null;
  insert_ts?: string | null;
  amount_due: string | null;
  amount_paid: string | null;
}

export interface IInvoicePaymentHistory {
  accn_invoice_information_id: number | null;
  invoice_id: number | null;
  invoice_number: string | null;
  invoice_date: string | null;
  invoice_due_date: string | null;
  invoice_status: string | null;
  payment_terms: string | null;
  accounting_information_id: string | null;
  gl_sales_account_name: string | null;
  gl_sales_account: string | null;
  gl_account_code: string | null;
  invoice_for: string | null;
  name: string | null;
  code: string | null;
  customer_id: string | null;
  policy_id: string | null;
  policy_number: string | null;
  policy_status: string | null;
  transaction_date: string | null;
  policy_transaction_type: string | null;
  effective_date: string | null;
  default_tax_region: string | null;
  agency_or_direct_bill: string | null;
  insurer_code: string | null;
  branch: string | null;
  branch_code: string | null;
  premium_financed: string | null;
  financed_by: string | null;
  sub_total_dollar: string | null;
  total_due_dollar: string | null;
  net_payable_insurer: string | null;
  invoice_notes: string | null;
  invoice_footer_information: string | null;
  type_of_policy: string | null;
  tax_one_total: string | null;
  tax_two_total: string | null;
  commission_total: string | null;
  is_mannual_invoice: string | null;
  policy_type: string | null;
  financed_fee: string | null;
  amount_due: string | null;
  amount_paid: string | null;
  status: string | null;
  created_by_id: string | null;
  modified_by_id: string | null;
  create_ts: string | null;
  insert_ts: string | null;
  payment_paid: string | null;
  remaining_amount_due: string | null;
  payment_details: IInvoiceHistoryPayment[];
  invoice_details: IInvoiceDetails[];
}

export interface IInvoiceHistoryPayment {
  accn_invoice_payment_transaction_items_id: string | null;
  invoice_id: string | null;
  invoice_number: string | null;
  accn_transaction_id: string | null;
  payment_number: string | null;
  entry_type: string | null;
  gl_accounts: string | null;
  invoice_payment_gl_account_name: string | null;
  date: string | null;
  batch_number: string | null;
  reciept_number: string | null;
  payment_for: string | null;
  customer_code: string | null;
  policy_number: string | null;
  payment_type: string | null;
  payment_currency: string | null;
  payment_amount: string | null;
  service_fee: string | null;
  payment_method: string | null;
  bank: string | null;
  net_payable: string | null;
  total_due_dollar: string | null;
  amount_due: string | null;
  payment_paid: string | null;
  payment_id: string | null;
  total_paid: string | null;
  is_partial_payment: string | null;
  status: string | null;
  created_by_id: string | null;
  modified_by_id: string | null;
  create_ts: string | null;
  insert_ts: string | null;
  actual_amount_due: string | null;
  invoice_due_date: string | null;
  transaction_type: string | null;
  gl_account_code: string | null;
  amount: string | null;
  payment_terms: string | null;
  customer_name: string | null;
  policy_type: string | null;
  policy_transaction_type: string | null;
  policy_status: string | null;
  transaction_date: string | null;
  effective_date: string | null;
  default_tax_region: string | null;
  agency_or_direct_bill: string | null;
  premium_financed: string | null;
  financed_by: string | null;
  financed_fee: string | null;
  producer_one_id: string | null;
  producer_one_code: string | null;
  producer_two_id: string | null;
  producer_two_code: string | null;
  producer_three_id: string | null;
  producer_three_code: string | null;
  csr_code: string | null;
  branch_code: string | null;
  insurer_code: string | null;
  tax_one_percentage: string | null;
  tax_one_amount: string | null;
  tax_two_percentage: string | null;
  tax_two_amount: string | null;
  commission_percentage: string | null;
  commission_amount: string | null;
  house_percentage: string | null;
  house_amount: string | null;
  producer_one_percentage: string | null;
  producer_one_amount: string | null;
  producer_two_percentage: string | null;
  producer_two_amount: string | null;
  producer_three_percentage: string | null;
  producer_three_amount: string | null;
  branch_percentage: string | null;
  branch_amount: string | null;
  total_amount: string | null;
  invoice_total_due_dollar: string | null;
}

export const initialAccountingInvoiceInformationState: IAccountingInvoiceInformationState =
  {
    list: [],
    listLoading: LoadState.NotLoaded,
    data: {
      invoice_id: null,
      invoice_number: null,
      invoice_due_date: moment().format("MM-DD-YYYY").toString(),
      payment_terms: "Due on Receipt",
      accounting_information_id: null,
      gl_sales_account_name: null,
      name: null,
      code: null,
      invoice_for: "Customer",
      policy_id: null,
      policy_number: "",
      type_of_policy: "",
      policy_comission_code: null,
      is_mannual_invoice: false,
      policy_transaction_type: null,
      policy_status: null,
      transaction_date: moment().format("MM-DD-YYYY").toString(),
      effective_date: null,
      default_tax_region: null,
      agency_or_direct_bill: null,
      insurer_code: null,
      branch_code: null,
      branch: null,
      premium_financed: null,
      financed_by: null,
      invoice_status: null,
      invoice_details: [
        {
          key: getUniqueId(),
          type: null,
          transaction_type: null,
          detail: null,
          description: null,
          amount: "",
          tax_one_percentage: "",
          tax_one_amount: "",
          tax_two_percentage: "",
          tax_two_amount: "",
          commission_percentage: "",
          commission_amount: "",
          house_percentage: "",
          house_amount: "",
          producer_one_percentage: "",
          producer_one_amount: "",
          producer_two_percentage: "",
          producer_two_amount: "",
          producer_three_percentage: "",
          producer_three_amount: "",
          branch_percentage: "",
          branch_amount: "",
          net_payable_insurer: "",
          total_amount: "",
          status: "ACTIVE",
        },
      ],
      sub_total_dollar: "0",
      net_payable_insurer: "0",
      tax_one_total: "0",
      tax_two_total: "0",
      commission_total: "0",
      invoice_notes: null,
      invoice_footer_information: null,
      total_due_dollar: "0",
      status: "ACTIVE",
      financed_fee: null,
      policy_type: null,
      amount_due: null,
      amount_paid: null,
    },
    loading: LoadState.NotLoaded,
    totalRecords: 0,
    error: null,
    paymentHistory: {
      invoice_status: null,
      accn_invoice_information_id: null,
      invoice_id: null,
      invoice_number: null,
      invoice_date: null,
      invoice_due_date: null,
      payment_terms: null,
      accounting_information_id: null,
      gl_sales_account_name: null,
      gl_sales_account: null,
      gl_account_code: null,
      invoice_for: null,
      name: null,
      code: null,
      customer_id: null,
      policy_id: null,
      policy_number: null,
      policy_status: null,
      transaction_date: null,
      policy_transaction_type: null,
      effective_date: null,
      default_tax_region: null,
      agency_or_direct_bill: null,
      insurer_code: null,
      branch: null,
      branch_code: null,
      premium_financed: null,
      financed_by: null,
      sub_total_dollar: null,
      total_due_dollar: null,
      net_payable_insurer: null,
      invoice_notes: null,
      invoice_footer_information: null,
      type_of_policy: null,
      tax_one_total: null,
      tax_two_total: null,
      commission_total: null,
      is_mannual_invoice: null,
      policy_type: null,
      financed_fee: null,
      amount_due: null,
      amount_paid: null,
      status: null,
      created_by_id: null,
      modified_by_id: null,
      create_ts: null,
      insert_ts: null,
      payment_paid: null,
      remaining_amount_due: null,
      payment_details: [],
      invoice_details: [],
    },
    paymentHistoryLoading: LoadState.NotLoaded,
  };
