import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { CommAutomobileGarageApplicationActions } from ".";
import { defaultCommAutomobileGarageApplicationState } from "./commAutoGarageApplication.types";
import { CLEAR_COMM_AUTOMOBILE_GARAGE_APPLICATION_STATE, FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_FAILED, FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_LIST_FAILED, FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_LIST_PROGRESS, FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_LIST_SUCCESS, FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_PROGRESS, FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_SUCCESSS } from "./commAutoGarageApplicationActions";

export const CommAutomobileGarageApplicationReducer = (
  state: IStoreState["commAuto"]["garageApplication"] = defaultCommAutomobileGarageApplicationState,
  action: CommAutomobileGarageApplicationActions
) => {
  switch (action.type) {
    case FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.InProgress;
        draftState.data =
        defaultCommAutomobileGarageApplicationState;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_SUCCESSS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_FAILED: {
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.Failed;
        draftState.data =
        defaultCommAutomobileGarageApplicationState;
      });
      return newState;
    }

    
    case FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.InProgress;
        draftState.listData = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Loaded;
        draftState.listData = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Failed;
        draftState.listData = [];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_GARAGE_APPLICATION_STATE: {
      const newState = produce(state, (draftState) => {
        draftState.data = defaultCommAutomobileGarageApplicationState["data"];
        draftState.loading = defaultCommAutomobileGarageApplicationState["loading"];
        draftState.error = defaultCommAutomobileGarageApplicationState["error"];
      })
      return newState;
    }

    default: {
      return state;
    }
  }
};
