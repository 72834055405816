import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import { StandadCard } from "../../../../../../../components/Cards";
import {
  Box,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { CustomTextField } from "../../../../../../../components/formsComponents";
import { useNavigate, useParams } from "react-router-dom";
import { IBreadcrumbProps } from "../../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useFormik } from "formik";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import produce from "immer";
import {
  addLifeInsuranceCoverageNeedAsync,
  fetchLifeInsuranceCoverageNeedAsync,
} from "../../../../../../../redux/lifeInsurancePolicy/lifeInsuranceCoverageNeed/lifeInsuranceCoverageNeedActions";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { FileUpload } from "../../../../../../../components/FileUpload/FileUpload";

export const LifeInsuranceCoverageNeed: React.FC = () => {
  const { customerId, customerPolicyId, interestedNumber } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
    interestedNumber?: number;
  };

  const { loading, data, error } = useSelector(
    (storeState: IStoreState) => storeState.lifeInsurance.coverageNeed
  );
  const [saveLoading, setSaveLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: data,
    validate: (values) => {},
    onSubmit: (values) => {
      if (customerPolicyId && customerId) {
        setSaveLoading(true);
        dispatch(
          addLifeInsuranceCoverageNeedAsync(
            {
              ...values,
              customer_policy_id: customerPolicyId,
            },
            (isSuccess) => {
              if (isSuccess) {
                window.scrollTo(0, 0);
                // navigate(
                //   `/business-policy/liability-extension/${customerId}/${customerPolicyId}`
                // );
              }
              setSaveLoading(false);
            }
          )
        );
      }
    },
  });

  const a = React.useMemo(() => {
    if (values.identify_current_debts_and_financial_needs) {
      return values.identify_current_debts_and_financial_needs.reduce(
        (item, currentValue) => {
          return item + (Number(currentValue.amount) || 0);
        },
        0
      );
    }
    return 0;
  }, [values.identify_current_debts_and_financial_needs]);

  const b = React.useMemo(() => {
    if (values.determine_your_family_s_future_financial_needs) {
      const data = values.determine_your_family_s_future_financial_needs;

      const srNo7Object = data.find((item) => item.SrNo === "7");
      const srNo8Object = data.find((item) => item.SrNo === "8");
      if (
        srNo7Object &&
        srNo8Object &&
        srNo7Object.amount !== null &&
        srNo8Object.amount !== null
      ) {
        const product = Number(srNo7Object.amount) * Number(srNo8Object.amount);
        return product;
      }
    }
    return 0;
  }, [values.determine_your_family_s_future_financial_needs]);

  const c = React.useMemo(() => {
    if (values.deduct_your_assets_and_financial_resources) {
      return values.deduct_your_assets_and_financial_resources.reduce(
        (item, currentValue) => {
          return item + (Number(currentValue.amount) || 0);
        },
        0
      );
    }
    return 0;
  }, [values.deduct_your_assets_and_financial_resources]);

  let total = a + b - c;

  const handleIdentifyCurrentDebtsChange =
    (rowIndex: number, key: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const finalRows = produce(
        values.identify_current_debts_and_financial_needs,
        (draftRows: any) => {
          draftRows[rowIndex][key] = event.target.value;
        }
      );
      setValues({
        ...values,
        identify_current_debts_and_financial_needs: finalRows,
      });
    };

  const handleDetermineYourFamilyChange =
    (rowIndex: number, key: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const finalRows = produce(
        values.determine_your_family_s_future_financial_needs,
        (draftRows: any) => {
          draftRows[rowIndex][key] = event.target.value;
        }
      );
      setValues({
        ...values,
        determine_your_family_s_future_financial_needs: finalRows,
      });
    };

  const handleDetermineYourFamilyChangeSrNoSeven = () => {
    const finalRows = produce(
      values.determine_your_family_s_future_financial_needs,
      (draftRows: any) => {
        draftRows["3"]["amount"] =
          draftRows["1"]["amount"] - draftRows["2"]["amount"];
      }
    );
    setValues({
      ...values,
      determine_your_family_s_future_financial_needs: finalRows,
    });
  };

  React.useMemo(() => {
    handleDetermineYourFamilyChangeSrNoSeven();
  }, [values.determine_your_family_s_future_financial_needs]);

  const handleDeductYourAssetsChange =
    (rowIndex: number, key: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const finalRows = produce(
        values.deduct_your_assets_and_financial_resources,
        (draftRows: any) => {
          draftRows[rowIndex][key] = event.target.value;
        }
      );
      setValues({
        ...values,
        deduct_your_assets_and_financial_resources: finalRows,
      });
    };

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchLifeInsuranceCoverageNeedAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  React.useEffect(() => {
    setValues(data);
  }, [data]);

  return (
    <PageLoader
      loading={loading === LoadState.InProgress}
      error={error ? { message: error } : null}
    >
      <StandadCard sx={{ mt: 2 }}>
        <form onSubmit={handleSubmit}>
          <LabelCard heading="A. Indentify your currents debts and financial needs" />
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              {values.identify_current_debts_and_financial_needs.map(
                (item, index) => {
                  return (
                    <TableRow
                      key={item.key}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      {item.typed === true ? (
                        <TableCell sx={{ width: "70%" }}>
                          {" "}
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography>{item.SrNo}</Typography>
                            <CustomTextField
                              name="item.debt"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.debt}
                              onChange={handleIdentifyCurrentDebtsChange(
                                index,
                                "debt"
                              )}
                            />
                          </Stack>
                        </TableCell>
                      ) : (
                        <TableCell sx={{ width: "70%" }}>
                          {" "}
                          <Typography>
                            {item.SrNo}. {item.debt}
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell>
                        {" "}
                        <CustomTextField
                          name="item.amount"
                          variant="outlined"
                          size="small"
                          type="text"
                          fullWidth
                          value={item.amount}
                          onChange={handleIdentifyCurrentDebtsChange(
                            index,
                            "amount"
                          )}
                        />
                      </TableCell>
                      {/* <TableCell>
                        <FileUpload value={""} />
                      </TableCell> */}
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>

          <Divider sx={{ mt: 2, mb: 2 }} />

          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Typography variant="h4" fontWeight={600}>
                Total
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h4" fontWeight={600}>
                $ {a}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ mt: 2, mb: 2 }} />

          <LabelCard heading="B. Determine your Family's future Income needs after your death" />
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              {values.determine_your_family_s_future_financial_needs.map(
                (item, index) => {
                  return (
                    <TableRow
                      key={item.key}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {item.debt === "" ? (
                        <TableCell sx={{ width: "70%" }}>
                          {" "}
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography>{item.SrNo} </Typography>
                            <CustomTextField
                              name="country"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={""}
                              onChange={handleChange}
                            />
                          </Stack>
                        </TableCell>
                      ) : (
                        <TableCell sx={{ width: "70%" }}>
                          {" "}
                          <Typography>
                            {item.SrNo}. {item.debt}
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell>
                        {" "}
                        <CustomTextField
                          name="item.amount"
                          variant="outlined"
                          size="small"
                          type="text"
                          fullWidth
                          value={item.amount}
                          onChange={handleDetermineYourFamilyChange(
                            index,
                            "amount"
                          )}
                        />
                      </TableCell>
                      {/* <TableCell>
                        <FileUpload value={""} />
                      </TableCell> */}
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>

          <Divider sx={{ mt: 2, mb: 2 }} />

          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Typography variant="h4" fontWeight={600}>
                Capital Required
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h4" fontWeight={600}>
                $ {b}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ mt: 2, mb: 2 }} />

          <LabelCard heading="C. Deduct your assets and financial resources" />
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              {values.deduct_your_assets_and_financial_resources.map(
                (item, index) => {
                  return (
                    <TableRow
                      key={item.key}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {item.debt === "" ? (
                        <TableCell sx={{ width: "70%" }}>
                          {" "}
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography>{item.SrNo} </Typography>
                            <CustomTextField
                              name="country"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={""}
                              onChange={handleChange}
                            />
                          </Stack>
                        </TableCell>
                      ) : (
                        <TableCell sx={{ width: "70%" }}>
                          {" "}
                          <Typography>
                            {item.SrNo}. {item.debt}
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell>
                        {" "}
                        <CustomTextField
                          name="item.amount"
                          variant="outlined"
                          size="small"
                          type="text"
                          fullWidth
                          value={item.amount}
                          onChange={handleDeductYourAssetsChange(
                            index,
                            "amount"
                          )}
                        />
                      </TableCell>
                      {/* <TableCell>
                        <FileUpload value={""} />
                      </TableCell> */}
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>

          <Divider sx={{ mt: 2, mb: 2 }} />

          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Typography variant="h4" fontWeight={600}>
                Total
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h4" fontWeight={600}>
                $ {c}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ mt: 2, mb: 2 }} />

          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Typography variant="h4" fontWeight={600}>
                Your Life Insurance Needs = A+B-C
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h4" fontWeight={600}>
                $ {total}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ mt: 2, mb: 2 }} />

          <Box sx={{ mt: 4 }}>
            <RoleBasedCustomButton
              sx={{ width: "15%" }}
              moduleId={MODULE_IDS.LIFE_AND_FINCANCIAL}
              disabled={saveLoading}
              variant="contained"
              type="submit"
            >
              Save
            </RoleBasedCustomButton>
          </Box>
        </form>
      </StandadCard>
    </PageLoader>
  );
};
