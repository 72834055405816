import produce from "immer";
import { HabitationalPolicyFormActions } from ".";
import { IStoreState } from "../../initialStoreState";
import { initialHabitationalPolicyFormState } from "./HabitationalPolicyForm.types";
import { CLEAR_HABITATIONAL_CONSENT_FORM, CLEAR_HABITATIONAL_DWELLING, CLEAR_HABITATIONAL_DWELLING_DECLARATION, FETCH_HABITATIONAL_CONSENT_FORM_FAILED, FETCH_HABITATIONAL_CONSENT_FORM_LIST_FAILED, FETCH_HABITATIONAL_CONSENT_FORM_LIST_PROGRESS, FETCH_HABITATIONAL_CONSENT_FORM_LIST_SUCCESS, FETCH_HABITATIONAL_CONSENT_FORM_PROGRESS, FETCH_HABITATIONAL_CONSENT_FORM_SUCCESS, FETCH_HABITATIONAL_DWELLING_DECLARATION_FAILED, FETCH_HABITATIONAL_DWELLING_DECLARATION_LIST_FAILED, FETCH_HABITATIONAL_DWELLING_DECLARATION_LIST_PROGRESS, FETCH_HABITATIONAL_DWELLING_DECLARATION_LIST_SUCCESS, FETCH_HABITATIONAL_DWELLING_DECLARATION_PROGRESS, FETCH_HABITATIONAL_DWELLING_DECLARATION_SUCCESS, FETCH_HABITATIONAL_DWELLING_FAILED, FETCH_HABITATIONAL_DWELLING_LIST_FAILED, FETCH_HABITATIONAL_DWELLING_LIST_PROGRESS, FETCH_HABITATIONAL_DWELLING_LIST_SUCCESS, FETCH_HABITATIONAL_DWELLING_PROGRESS, FETCH_HABITATIONAL_DWELLING_SUCCESS } from "./HabitationalPolicyFormActions";
import { LoadState } from "../../../constants/enums";

export const HabitationalPolicyFormReducer = (
  state: IStoreState["habitational"]["policyform"] = initialHabitationalPolicyFormState,
  action: HabitationalPolicyFormActions
) => {
  switch (action.type) {
    case FETCH_HABITATIONAL_CONSENT_FORM_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.consentform.consentLoading = LoadState.InProgress;
        draftState.consentform.consent =
          initialHabitationalPolicyFormState["consentform"]["consent"];
      });
      return newState;
    }
     case FETCH_HABITATIONAL_CONSENT_FORM_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.consentform.consentLoading = LoadState.Loaded;
        draftState.consentform.consent = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_CONSENT_FORM_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.consentform.consentLoading = LoadState.Failed;
        draftState.consentform.consent =
          initialHabitationalPolicyFormState["consentform"]["consent"];
        draftState.consentform.error = errorMessage;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_CONSENT_FORM_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.consentform.loading = LoadState.InProgress;
        draftState.consentform.list = [];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_CONSENT_FORM_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.consentform.loading = LoadState.Loaded;
        draftState.consentform.list = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_CONSENT_FORM_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.consentform.loading = LoadState.Failed;
        draftState.consentform.list = [];
      });
      return newState;
    }

    case CLEAR_HABITATIONAL_CONSENT_FORM: {
      const newState = produce(state, (draftState) => {
        draftState.consentform.consentLoading = LoadState.NotLoaded;
        draftState.consentform.consent =
          initialHabitationalPolicyFormState["consentform"]["consent"];
        draftState.consentform.error = null;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_DWELLING_DECLARATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.dwellingdeclaration.dwellingLoading = LoadState.InProgress;
        draftState.dwellingdeclaration.dwelling =
          initialHabitationalPolicyFormState["dwellingdeclaration"]["dwelling"];
      });
      return newState;
    }
     case FETCH_HABITATIONAL_DWELLING_DECLARATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dwellingdeclaration.dwellingLoading = LoadState.Loaded;
        draftState.dwellingdeclaration.dwelling = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_DWELLING_DECLARATION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dwellingdeclaration.dwellingLoading = LoadState.Failed;
        draftState.dwellingdeclaration.dwelling =
          initialHabitationalPolicyFormState["dwellingdeclaration"]["dwelling"];
        draftState.dwellingdeclaration.error = errorMessage;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_DWELLING_DECLARATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.dwellingdeclaration.loading = LoadState.InProgress;
        draftState.dwellingdeclaration.list = [];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_DWELLING_DECLARATION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dwellingdeclaration.loading = LoadState.Loaded;
        draftState.dwellingdeclaration.list = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_DWELLING_DECLARATION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.dwellingdeclaration.loading = LoadState.Failed;
        draftState.dwellingdeclaration.list = [];
      });
      return newState;
    }

    case CLEAR_HABITATIONAL_DWELLING_DECLARATION: {
      const newState = produce(state, (draftState) => {
        draftState.dwellingdeclaration.dwellingLoading = LoadState.NotLoaded;
        draftState.dwellingdeclaration.dwelling =
          initialHabitationalPolicyFormState["dwellingdeclaration"]["dwelling"];
        draftState.dwellingdeclaration.error = null;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_DWELLING_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.dwelling.dwellingLoading = LoadState.InProgress;
        draftState.dwelling.dwelling =
          initialHabitationalPolicyFormState["dwelling"]["dwelling"];
      });
      return newState;
    }
     case FETCH_HABITATIONAL_DWELLING_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dwelling.dwellingLoading = LoadState.Loaded;
        draftState.dwelling.dwelling = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_DWELLING_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dwelling.dwellingLoading = LoadState.Failed;
        draftState.dwelling.dwelling =
          initialHabitationalPolicyFormState["dwelling"]["dwelling"];
        draftState.dwelling.error = errorMessage;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_DWELLING_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.dwelling.loading = LoadState.InProgress;
        draftState.dwelling.list = [];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_DWELLING_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dwelling.loading = LoadState.Loaded;
        draftState.dwelling.list = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_DWELLING_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.dwelling.loading = LoadState.Failed;
        draftState.dwelling.list = [];
      });
      return newState;
    }

    case CLEAR_HABITATIONAL_DWELLING: {
      const newState = produce(state, (draftState) => {
        draftState.dwelling.dwellingLoading = LoadState.NotLoaded;
        draftState.dwelling.dwelling =
          initialHabitationalPolicyFormState["dwelling"]["dwelling"];
        draftState.dwelling.error = null;
      });
      return newState;
    }
    default: {
      return state;
    }
  }
}