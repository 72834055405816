import produce from "immer";
import { BusinesPolicyPremiumFinanceActions } from ".";
import { IStoreState } from "../../initialStoreState";
import { defaultBusinessPolicyPremiumFinanceState } from "./businessPolicyPremiumFinance.types";
import {
  CLEAR_BUSINES_POLICY_PREMIUM_FINANCE,
  CLEAR_BUSINES_POLICY_PREMIUM_FINANCE_STATE,
  FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_FAILED,
  FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_LIST_FAILED,
  FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_LIST_PROGRESS,
  FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_LIST_SUCCESS,
  FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_PROGRESS,
  FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_SUCCESS,
} from "./businessPolicyPremiumFinanceActions";
import { LoadState } from "../../../constants/enums";

export const businessPolicyPremiumFinanceReducer = (
  state: IStoreState["business"]["premiumFinance"] = defaultBusinessPolicyPremiumFinanceState,
  action: BusinesPolicyPremiumFinanceActions
) => {
  switch (action.type) {
    case FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.data = [];
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.premiumFALoading = LoadState.InProgress;
        draftState.premiumFA =
          defaultBusinessPolicyPremiumFinanceState["premiumFA"];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.premiumFALoading = LoadState.Loaded;
        draftState.premiumFA = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_FAILED: {
      const {errorMessage} = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.premiumFALoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_BUSINES_POLICY_PREMIUM_FINANCE: {
      const newState = produce(state, (draftState) => {
        draftState.premiumFA =
          defaultBusinessPolicyPremiumFinanceState["premiumFA"];
          draftState.error = null;
      });
      return newState;
    }
    case CLEAR_BUSINES_POLICY_PREMIUM_FINANCE_STATE: {
      return defaultBusinessPolicyPremiumFinanceState;
    }
    default: {
      return state;
    }
  }
};
