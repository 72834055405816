import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialCommAutomoblieVehicleInformation } from "./commAutoVehicle.types";
import { CommAutoVehicleAction } from ".";
import { CLEAR_COMM_AUTOMOBILE_ADDITIONAL_INFO, CLEAR_COMM_AUTOMOBILE_INTERESTED_PARTY, CLEAR_COMM_AUTOMOBILE_VEHICLE_INFO, CLEAR_COMM_AUTOMOBILE_VEHICLE_STATE, FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_FAILED, FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_FAILED, FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_PROGRESS, FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_SUCCESS, FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_PROGRESS, FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_SUCCESS, FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_FAILED, FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_LIST_FAILED, FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_LIST_PROGRESS, FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_LIST_SUCCESS, FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_PROGRESS, FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_SUCCESS, FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_FAILED, FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_LIST_FAILED, FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_LIST_PROGRESS, FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_LIST_SUCCESS, FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_PROGRESS, FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_SUCCESS } from "./commAutoVehicleActions";

export const CommAutomobileVehicleReducer = (
  state: IStoreState["commAuto"]["vehicle"] = initialCommAutomoblieVehicleInformation,
  action: CommAutoVehicleAction
) => {
  switch (action.type) {
    case FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.VehicleInformation.loading = LoadState.InProgress;
        draftState.VehicleInformation.data =
        initialCommAutomoblieVehicleInformation["VehicleInformation"]["data"];
        draftState.VehicleInformation.error = null;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.VehicleInformation.loading = LoadState.Loaded;
        draftState.VehicleInformation.data = data;
        draftState.vehicleNumber = data.incremental_number;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.VehicleInformation.loading = LoadState.Failed;
        draftState.VehicleInformation.data =
        initialCommAutomoblieVehicleInformation["VehicleInformation"]["data"];
        draftState.VehicleInformation.error = errorMessage;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.VehicleInformation.vehiclesListLoading = LoadState.InProgress;
        draftState.VehicleInformation.vehiclesList = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.VehicleInformation.vehiclesListLoading = LoadState.Loaded;
        draftState.VehicleInformation.vehiclesList = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_VEHICLE_INFORMATION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.VehicleInformation.vehiclesListLoading = LoadState.Failed;
        draftState.VehicleInformation.vehiclesList = [];
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.additionalInformation.loading = LoadState.InProgress;
        draftState.additionalInformation.data =
        initialCommAutomoblieVehicleInformation["additionalInformation"]["data"];
        draftState.additionalInformation.error = null;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.additionalInformation.loading = LoadState.Loaded;
        draftState.additionalInformation.data = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.additionalInformation.loading = LoadState.InProgress;
        draftState.additionalInformation.data =
        initialCommAutomoblieVehicleInformation["additionalInformation"]["data"];
        draftState.additionalInformation.error = errorMessage;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.additionalInformation.additionalInformationListLoading = LoadState.InProgress;
        draftState.additionalInformation.additionalInformationList = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.additionalInformation.additionalInformationListLoading = LoadState.Loaded;
        draftState.additionalInformation.additionalInformationList = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.additionalInformation.additionalInformationListLoading = LoadState.Failed;
        draftState.additionalInformation.additionalInformationList = [];
      });
      return newState;
    }

    /**--- */
    case FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.interestedParty.loading = LoadState.InProgress;
        draftState.interestedParty.data =
        initialCommAutomoblieVehicleInformation["interestedParty"]["data"];
        draftState.interestedParty.error = null;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.interestedParty.loading = LoadState.Loaded;
        draftState.interestedParty.data = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.interestedParty.loading = LoadState.InProgress;
        draftState.interestedParty.data =
        initialCommAutomoblieVehicleInformation["interestedParty"]["data"];
        draftState.interestedParty.error = errorMessage;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.interestedParty.interestedPartyListLoading =
          LoadState.InProgress;
        draftState.interestedParty.interestedPartyList = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.interestedParty.interestedPartyListLoading =
          LoadState.Loaded;
        draftState.interestedParty.interestedPartyList = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_INTERESTED_PARTY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.interestedParty.interestedPartyListLoading =
          LoadState.Failed;
        draftState.interestedParty.interestedPartyList = [];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_VEHICLE_INFO: {
      const newState = produce(state, (draftState) => {
        draftState.VehicleInformation.loading = LoadState.NotLoaded;
        draftState.VehicleInformation.data =
        initialCommAutomoblieVehicleInformation["VehicleInformation"]["data"];
        draftState.VehicleInformation.error = null;
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_INTERESTED_PARTY: {
      const newState = produce(state, (draftState) => {
        draftState.interestedParty.loading = LoadState.NotLoaded;
        draftState.interestedParty.data =
        initialCommAutomoblieVehicleInformation["interestedParty"]["data"];
        draftState.interestedParty.error = null;
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_ADDITIONAL_INFO: {
      const newState = produce(state, (draftState) => {
        draftState.additionalInformation.loading = LoadState.NotLoaded;
        draftState.additionalInformation.data =
        initialCommAutomoblieVehicleInformation["additionalInformation"]["data"];
        draftState.additionalInformation.error = null;
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_VEHICLE_STATE: {
      return initialCommAutomoblieVehicleInformation;
    }

    default: {
      return state;
    }
  }
};
