import { action } from "typesafe-actions";
import {
  IBusinessPolicyLiability,
  initialBusinessPolicyLiability,
} from "./businessPolicyLiability.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";

export const FETCH_BUSINESS_POLICY_LIABILITY_PROGRESS =
  "FETCH_BUSINESS_POLICY_LIABILITY_PROGRESS";
export const FETCH_BUSINESS_POLICY_LIABILITY_SUCCESS =
  "FETCH_BUSINESS_POLICY_LIABILITY_SUCCESS";
export const FETCH_BUSINESS_POLICY_LIABILITY_FAILED =
  "FETCH_BUSINESS_POLICY_LIABILITY_FAILED";

export const fetchBusinessLiabilityProgress = () =>
  action(FETCH_BUSINESS_POLICY_LIABILITY_PROGRESS);
export const fetchBusineLiabilitySuccess = (data: IBusinessPolicyLiability) =>
  action(FETCH_BUSINESS_POLICY_LIABILITY_SUCCESS, { data });
export const fetchBusinessLiabilityfailed = () =>
  action(FETCH_BUSINESS_POLICY_LIABILITY_FAILED);

export const fetchBusinessLiabilityAsync =
  (
    customerPolicyId: number,
    liabilityNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessLiabilityProgress());
      const res = await api.get(
        `/policy/get-policy-add-liability-insurance-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${liabilityNumber}`
      );
      const data: IBusinessPolicyLiability[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBusineLiabilitySuccess(data[0]));
      } else {
        dispatch(fetchBusineLiabilitySuccess(initialBusinessPolicyLiability));
      }
    } catch (err: any) {
      dispatch(fetchBusinessLiabilityfailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addBusinessLiabilityAsync =
  (
    data: IBusinessPolicyLiability,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post(
          `/policy/edit-policy-add-liability-insurance-certificate`,
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          `/policy/edit-policy-add-liability-insurance-certificate`,
          data
        );
      }
      dispatch(
        showMessage({
          type: "success",
          message: "Liability certificate saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchBusineLiabilitySuccess(data));
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_BUSINESS_POLICY_LIABILITY_LIST_PROGRESS =
  "FETCH_BUSINESS_POLICY_LIABILITY_LIST_PROGRESS";
export const FETCH_BUSINESS_POLICY_LIABILITY_LIST_SUCCESS =
  "FETCH_BUSINESS_POLICY_LIABILITY_LIST_SUCCESS";
export const FETCH_BUSINESS_POLICY_LIABILITY_LIST_FAILED =
  "FETCH_BUSINESS_POLICY_LIABILITY_LIST_FAILED";

export const fetchBusinessLiabilityListProgress = () =>
  action(FETCH_BUSINESS_POLICY_LIABILITY_LIST_PROGRESS);
export const fetchBusinessLiabilityListSuccess = (
  data: IBusinessPolicyLiability[]
) => action(FETCH_BUSINESS_POLICY_LIABILITY_LIST_SUCCESS, { data });
export const fetchBusinessLiabilityListFailed = () =>
  action(FETCH_BUSINESS_POLICY_LIABILITY_LIST_FAILED);

export const fetchBusinessLiabilityListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessLiabilityListProgress());
      const res = await api.get(
        `/policy/get-policy-add-liability-insurance-certificate?customer_policy_id=${customerPolicyId}`
      );
      const data: IBusinessPolicyLiability[] = res.data.data;
      dispatch(fetchBusinessLiabilityListSuccess(data));
    } catch (err: any) {
      dispatch(fetchBusinessLiabilityListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const downloadBusinessLiabilityListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-liability-insurance-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchBusinessLiabilityListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewBusinessLiabilityListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-liability-insurance-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_liability_insurance_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchBusinessLiabilityListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addBusinessLiabilityListAsync =
  (
    liability: IBusinessPolicyLiability,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        `/policy/edit-policy-add-liability-insurance-certificate`,
        {
          ...liability,
        }
      );
      dispatch(
        showMessage({
          type: "success",
          message: "Liability Insurance saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_BUSINESS_POLICY_LIABILITY_STATE =
  "CLEAR_BUSINESS_POLICY_LIABILITY_STATE";

export const clearBusinessPolicyLiabilityState = () =>
  action(CLEAR_BUSINESS_POLICY_LIABILITY_STATE);
