import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { CommAutomobileSupplementActions } from ".";
import { initialCommAutomobileSupplement } from "./commAutoSupplement.types";
import { CLEAR_COMM_AUTOMOBILE_SUPPLEMENT_DETAILS, FETCH_COMM_AUTOMOBILE_SUPPLEMENT_DETAILS_FAILED, FETCH_COMM_AUTOMOBILE_SUPPLEMENT_DETAILS_PROGRESS, FETCH_COMM_AUTOMOBILE_SUPPLEMENT_DETAILS_SUCCESS } from "./commAutoSupplementActions";

export const CommAutomobileSupplementReducer = (
  state: IStoreState["commAuto"]["supplement"] = initialCommAutomobileSupplement,
  action: CommAutomobileSupplementActions
) => {
  switch (action.type) {
    case FETCH_COMM_AUTOMOBILE_SUPPLEMENT_DETAILS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.NotLoaded;
        draftState.data = initialCommAutomobileSupplement["data"];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SUPPLEMENT_DETAILS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SUPPLEMENT_DETAILS_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.data = initialCommAutomobileSupplement["data"];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_SUPPLEMENT_DETAILS: {
      return initialCommAutomobileSupplement;
    }

    default: {
      return state;
    }
  }
};
