import React from "react";
import { Dialog } from "../../../../components/Dialogs/Dialog";
import { Button, Divider, Grid } from "@mui/material";
import {
  StandardCardCell,
  StandardCellList,
} from "../../../../components/Standards";
import { IViewTaskDetailsProps } from "./ViewTaskDetails.types";
import { formatDate } from "../../../../helpers";
import { ViewTaskDetailsLayout } from "../../ViewTaskDetails/ViewTaskDetailsLayout";
import { useNavigate, useParams } from "react-router-dom";
import { StandadCard } from "../../../../components/Cards";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../hooks";
import {
  clearTaskCancel,
  fetchTaskAysnc,
  fetchTaskMultiFileUploadAysnc,
} from "../../../../redux/tasks/tasksActions";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../constants/enums";
import { ITaskFileUploadAttachment } from "../../../../redux/tasks/task.types";
import EditIcon from "@mui/icons-material/Edit";
import { StatusRenderer } from "../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { IBreadcrumbProps } from "../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { Breadcrumb } from "../../../../layout/fullLayout/breadcrumb/Breadcrumb";

export const ViewTaskDetails: React.FC = () => {
  // const { open, taskDetails, onClose } = props;
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      to: "/tasks-list",
      title: "Business Tracker",
    },
    {
      title: "View Task ",
    },
  
  ];


  const { taskCode } = useParams() as { taskCode?: string };

  const { task: taskDetails, taskLoading } = useSelector(
    (storeState: IStoreState) => storeState.tasks.allTasks
  );

  const navigate = useNavigate();
  const dispatch = useDispatchWrapper();
  React.useEffect(() => {
    if (taskCode) {
      dispatch(fetchTaskAysnc(taskCode));
    }
  }, [taskCode]);

  const [attachment, setAttachment] = React.useState<
    ITaskFileUploadAttachment[]
  >([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    if (taskCode) {
      dispatch(
        fetchTaskMultiFileUploadAysnc(taskCode, (isSuccess, data) => {
          if (isSuccess) {
            if (data && data.length > 0) {
              setAttachment(data[0].attachments);
            }
          }
          setLoading(false);
        })
      );
    }
  }, [taskCode]);

  const handleClick = () => {
    if (taskCode) {
      navigate("/tasks/" + taskCode);
    } else {
      navigate("/dashboard");
    }
  };

  React.useEffect(() => {
    return () => {
      dispatch(clearTaskCancel());
    };
  }, []);

  return (
    <PageLoader loading={taskLoading === LoadState.InProgress}>
      <Breadcrumb title="" items={BCrumb}/>
      <StandadCard
          
      >
      <Grid container spacing={1}>
          <Grid item xs={12} md={1}>
            <StandardCardCell
              heading="Task Code"
              data={{
                type: "text",
                value: taskDetails.task_code,
              }}
            />
          </Grid>
          <Grid item xs={12} md={1.5}>
            <StandardCardCell
              heading="Related To"
              data={{
                type: "text",
                value: taskDetails.task_related_to,
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <StandardCardCell
              heading="Customer Name"
              data={{
                type: "text",
                value:
                  taskDetails.related_to_customer_name +
                  ` (${taskDetails.related_to_customer_code})`,
              }}
            />
          </Grid>
          <Grid item xs={12} md={1.5}>
            <StandardCardCell
              heading="Policy Number"
              data={{
                type: "text",
                value: taskDetails.policy_number,
                color: "primary.main",
              }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <StandardCardCell
              heading="Line of Business"
              data={{
                type: "text",
                value: taskDetails.line_of_business,
              }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <StandardCardCell
              heading="Assigned To"
              data={{
                type: "text",
                value: taskDetails.first_name + " " + taskDetails.last_name,
              }}
            />
          </Grid>
          <Grid item xs={12} md={1}>
            <StandardCardCell
              heading="Priority"
              data={{
                type: "text",
                value: taskDetails.task_priority,
                color: "error.main"
              }}
            />
          </Grid>
          <Grid item xs={12} md={1} display={'flex'} alignItems={'center'}>
          <Button
                variant="contained"
                fullWidth
                startIcon={<EditIcon />}
                onClick={handleClick}
              >
                Edit
              </Button>
          </Grid>
        </Grid>
      </StandadCard>
      <StandadCard
       //heading="Task Details"
        sx={{mt: 2}}
  
      >
       
        <Grid container spacing={3}>
          <Grid item xs={12} md={2}>
            <StandardCardCell
              heading="Branch Code"
              data={{
                type: "text",
                value: taskDetails.branch_code,
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <StandardCardCell
              heading="Producer 1"
              data={{
                type: "text",
                value: taskDetails.producer_one_code,
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <StandardCardCell
              heading="Producer 2"
              data={{
                type: "text",
                value: taskDetails.producer_two_code,
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <StandardCardCell
              heading="Producer 3"
              data={{
                type: "text",
                value: taskDetails.producer_three_code,
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <StandardCardCell
              heading="Marketer"
              data={{
                type: "text",
                value: taskDetails.marketer_code,
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <StandardCardCell
              heading="Transaction"
              data={{
                type: "text",
                value: taskDetails.task_type,
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <StandardCardCell
              heading="Sub Type"
              data={{
                type: "text",
                value: taskDetails.task_sub_type,
              }}
            />
          </Grid>
        
          <Grid item xs={12} md={2}>
            <StandardCardCell
              heading="Date Created"
              data={{
                type: "text",
                value: taskDetails.task_created_date,
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <StandardCardCell
              heading="Due Date"
              data={{
                type: "text",
                value: taskDetails.task_due_date,
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <StandardCardCell
              heading="Due Completed"
              data={{
                type: "text",
                value: taskDetails.task_completed_date,
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <StandardCardCell
              heading="Status"
              data={{
                type: "text",
                value: <StatusRenderer status={taskDetails.status}/>,
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <StandardCardCell
              heading="Effective Date"
              data={{
                type: "text",
                value: formatDate(taskDetails.effective_date),
              }}
            />
          </Grid>
        </Grid>

        


      </StandadCard>

      <ViewTaskDetailsLayout
          taskCode={taskDetails.task_code}
          subType={taskDetails.task_sub_type}
          attachment={attachment}
        />
    </PageLoader>
  );
};
