import { LoadState } from "../../../constants/enums";

export interface IAccountingGLAccountsState {
  data: IAccountingGLAccounts;
  loading: LoadState;
  error: string;
}

export interface IAccountingGLAccounts {
  charts_of_accounts_id: string | null;
  agency_bill_receivables: string | null;
  direct_bill_receivables: string | null;
  net_income: string | null;
  retained_earnings: string | null;
  report_heading_account: string | null;
  cash_disbursements_asset_account: string | null;
  agency_bill_trust_account: string | null;
  general_cash_receipts_account: string | null;
  agency_bill_tax_payable_account: string | null;
  agency_bill_tax_two_payable_account: string | null;
  vat_on_direct_bill_commisions_payable_account: string | null;
  vat_on_agency_bill_earned_commissions_expense_account: string | null;
  agency_bill_tax_trust_account: string | null;
  direct_bill_bonus_commision_account: string | null;
  direct_bill_charge_back_commision_account: string | null;
  direct_bill_charge_back_bonus_account: string | null;
  finance_and_service_charge_income_account: string | null;
  automatic_writeoff_waiver_account: string | null;
  status: string;
}

export const initialAccountingGLAccountsState: IAccountingGLAccountsState = {
    data: {
        charts_of_accounts_id: null,
        agency_bill_receivables: null,
        direct_bill_receivables: null,
        net_income: null,
        retained_earnings: null,
        report_heading_account: null,
        cash_disbursements_asset_account: null,
        agency_bill_trust_account: null,
        general_cash_receipts_account: null,
        agency_bill_tax_payable_account: null,
        agency_bill_tax_two_payable_account: null,
        vat_on_direct_bill_commisions_payable_account: null,
        vat_on_agency_bill_earned_commissions_expense_account: null,
        agency_bill_tax_trust_account: null,
        direct_bill_bonus_commision_account: null,
        direct_bill_charge_back_commision_account: null,
        direct_bill_charge_back_bonus_account: null,
        finance_and_service_charge_income_account: null,
        automatic_writeoff_waiver_account: null,
        status: "",
    },
    loading: LoadState.NotLoaded,
    error: "",
}
